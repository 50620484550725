// @flow
import React, { useContext, useState } from 'react';
import { Text, HStack, Spacer, Box } from '@chakra-ui/react';
import { useNavigate, Outlet } from 'react-router-dom';
import { reload } from '@core/Services/Firebase/firebase';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { Icon16CheckCircle } from '@icons/16px';
import {
  BaselaneButton,
  BaselaneDrawer,
  VerifyEmailModal,
  BaselaneBannerNew,
} from '@shared/components';
import { IllustrationOtherWarning } from '@illustrations';
import { EmailOtpProvider } from '@core/contexts/EmailOtpContext';
import UserContext from '@contexts/UserContext';
import { WORKSPACE_OWNER_VERIFY_NAME, WORKSPACE_OWNER_VERIFY_PHONE, USER_WORKSPACE } from '@routes';

type OwnerProfileEmailDrawerProps = { from: String };
const BannerIcon = () => <IllustrationOtherWarning />;
function OwnerProfileEmailDrawer({ from }: OwnerProfileEmailDrawerProps): any {
  const { user, setEmailVerified, refetchUser } = useContext(UserContext);

  const isUserEmailVerified = user?.emailVerified;
  const [showEmailVerifyPopup, setEmailVerifyPopup] = useState(false);
  const { isMax576 } = useBreakPoints();
  const navigate = useNavigate();

  const handleVerifyNowButton = async () => {
    setEmailVerifyPopup(true);
    const emailVerifiedByFirebase = await reload();
    if (emailVerifiedByFirebase) {
      setEmailVerifyPopup(false);
      setEmailVerified(true);
    }
  };

  const onVerifyEmailOtpSuccess = async () => {
    const emailVerifiedByFirebase = await reload();
    if (emailVerifiedByFirebase) {
      setEmailVerifyPopup(false);
      setEmailVerified(true);
      refetchUser();
    }
  };

  const handleDrawerClose = () => {
    navigate(USER_WORKSPACE);
  };
  return (
    <>
      <BaselaneDrawer
        isOpen
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        title="Add your profile details"
        closeEvent={() => handleDrawerClose}
        onOverlayClick={() => handleDrawerClose}
        hideOverlay
        closeOnOverlayClick={false}
        newDesignDrawer
        footer={
          <>
            <BaselaneButton
              size="md"
              variant="outline"
              palette="neutral"
              onClick={() => {
                navigate(`${USER_WORKSPACE}/${WORKSPACE_OWNER_VERIFY_NAME}`);
              }}
            >
              Back
            </BaselaneButton>
            <BaselaneButton
              size="md"
              variant="filled"
              palette="primary"
              width="100%"
              ml={1.5}
              flex={1}
              onClick={() => {
                navigate(WORKSPACE_OWNER_VERIFY_PHONE);
              }}
              isDisabled={!user?.emailVerified}
            >
              Continue
            </BaselaneButton>
          </>
        }
      >
        <HStack
          w="100%"
          position="absolute"
          left="0"
          width="100%"
          top={!isMax576 ? '59px' : '56px'}
        >
          <BaselaneBannerNew
            hasBannerIcon={!isMax576}
            hasActionButton={false}
            body="Before inviting a collaborator, we need a few details on your profile."
            hasCloseButton={false}
            title="Your profile details required"
            variant="warning-medium"
            bannerIconName={BannerIcon}
          />
        </HStack>
        <Box paddingTop="126px" mb={3}>
          <HStack mb={0.5}>
            <Text textStyle="headline-md" color="brand.neutral.900">
              Verify your email address
            </Text>
            <Spacer />
            <Text textStyle="xs" color="brand.neutral.600">
              Step 2 of 3
            </Text>
          </HStack>
          <Text textStyle="sm" color="brand.neutral.700">
            We’ll send you a verification code.
          </Text>
        </Box>
        {!user?.emailVerified ? (
          <BaselaneButton
            size="lg"
            variant="filled"
            palette="primary"
            onClick={handleVerifyNowButton}
          >
            Verify now
          </BaselaneButton>
        ) : (
          <HStack gap={1} color="green.700">
            <Icon16CheckCircle />
            <Text textStyle="sm" color="green.700">
              Email address verified
            </Text>
          </HStack>
        )}
        <Outlet />
      </BaselaneDrawer>

      {!isUserEmailVerified && showEmailVerifyPopup && (
        <EmailOtpProvider>
          <VerifyEmailModal
            email={user?.email}
            onVerifyEmailOtpSuccess={onVerifyEmailOtpSuccess}
            onCloseBtnClick={() => setEmailVerifyPopup(false)}
          />
        </EmailOtpProvider>
      )}
    </>
  );
}

export default OwnerProfileEmailDrawer;
