import React, { useContext } from 'react';
import { Text, HStack, Avatar, Box } from '@chakra-ui/react';
import { Icon16Person } from '@icons/16px';
import UserContext from '@contexts/UserContext';

function LoggedInUserInfo(): any {
  const { user, userPhotoUrl } = useContext(UserContext);
  const { firstName, lastName, email } = user ?? {};
  return (
    <HStack gap="1.5">
      <Avatar
        size="sm"
        src={userPhotoUrl}
        color="brand.darkBlue.900"
        bg="brand.neutral.200"
        icon={<Icon16Person color="#808089" />}
      />
      <Box>
        <Text textStyle="headline-sm" color="brand.neutral.700">{`${firstName || ''} ${
          lastName || ''
        }`}</Text>
        <Text color="brand.neutral.600" textStyle="xs">
          {email}
        </Text>
      </Box>
    </HStack>
  );
}

export default LoggedInUserInfo;
