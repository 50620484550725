import { gql } from '@apollo/client';
import { FRAGMENT_TRANSACTION } from '@core/apollo/queries';

export const CREATE_UPDATE_SPLIT_TX = gql`
  mutation createOrUpdateSplitTx(
    $parentTransactionId: ID!
    $transactionSplitInputs: [TransactionSplitInput!]!
  ) {
    createOrUpdateSplitTx(
      input: {
        parentTransactionId: $parentTransactionId
        transactionSplitInputs: $transactionSplitInputs
      }
    ) {
      id
      splitTransactions {
        ...TransactionObj
      }
    }
  }
  ${FRAGMENT_TRANSACTION}
`;

export const GET_TRANSACTION_DETAILS_INFO_BY_ID = gql`
  query transactionById($id: ID!) {
    transactionById(id: $id) {
      ...TransactionObj
    }
  }
  ${FRAGMENT_TRANSACTION}
`;

export const GET_TRANSACTION_BY_ID = gql`
  query transactionById($id: ID!) {
    transactionById(id: $id) {
      id
      splitTransactions {
        id
        propertyId
        unitId
        amount
        merchantName
        date
        isDeleted
        isDocumentUploaded
        hidden
        tagId
      }
    }
  }
`;

export const MERGE_SPLIT_TRX = gql`
  mutation mergeSplitTransaction($id: ID!) {
    mergeSplitTransaction(id: $id) {
      ...TransactionObj
    }
  }
  ${FRAGMENT_TRANSACTION}
`;

export const UPDATE_TRANSACTION = gql`
  mutation UpdateTransactions($input: [UpdateTransaction]) {
    updateTransactions(input: $input) {
      ...TransactionObj
    }
  }
  ${FRAGMENT_TRANSACTION}
`;
