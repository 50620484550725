import React, { useState } from 'react';
import { Heading, HStack, Flex, Text, VStack, Box } from '@chakra-ui/react';
import { BaselaneButton, BaselaneDivider } from '@shared/components';
import { IllustrationOtherLeaseDocument } from '@illustrations';
import { Icon24TV, Icon24Mappin, Icon24Filter } from '@icons/24px';
import { IconRocketLawyer } from '@icons';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import NewAgreementButton from './components/NewAgreementButton';
import RocketLayerDocsIframe from './CreateLeaseAgreement/components/RocketLayerDocsIframe';

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const { isMax767 } = useBreakPoints();
  if (isMax767) {
    return (
      <VStack w="100%" gap="1.5">
        {children}
      </VStack>
    );
  }
  return (
    <HStack m={3} gap="1.5" align="flex-start">
      {children}
    </HStack>
  );
};

const EmptyState = () => {
  const { isMax767 } = useBreakPoints();
  const [showDocsIframe, setShowDocsIframe] = useState(false);

  return (
    <Box w="100%" minH="240px">
      <VStack gap={isMax767 ? 2 : 5} w="100%" my="5">
        <VStack align="center" w="100%" gap={3}>
          <IllustrationOtherLeaseDocument
            width={isMax767 ? '64' : '80'}
            height={isMax767 ? '64' : '80'}
          />
          <Heading size="headline-2xl">State-specific lease agreements</Heading>
        </VStack>

        <Wrapper>
          <Box
            direction="column"
            bg="brand.darkBlue.100"
            borderColor="brand.blue.100"
            borderRadius="8"
            p="2"
            w="inherit"
          >
            <HStack alignItems="flex-start" gap={2}>
              {isMax767 && (
                <Box marginTop="8px">
                  <Icon24Mappin />
                </Box>
              )}
              <Flex direction="column" gap="0.5">
                {!isMax767 && <Icon24Mappin />}
                <Heading size="headline-md" mt="1">
                  State-specific
                </Heading>
                <Text textStyle="sm" mb="16px">
                  Stay compliant with lawyer-vetted templates for every state.
                </Text>
              </Flex>
            </HStack>
          </Box>
          <Box
            direction="column"
            bg="brand.darkBlue.100"
            borderColor="brand.blue.100"
            borderRadius="8"
            p="2"
            w="inherit"
          >
            <HStack alignItems="flex-start" gap={2}>
              {isMax767 && (
                <Box marginTop="8px">
                  <Icon24Filter />
                </Box>
              )}
              <Flex direction="column" gap="0.5">
                {!isMax767 && <Icon24Filter />}
                <Heading size="headline-md" mt="1">
                  Customizable{' '}
                </Heading>
                <Text textStyle="sm" mb="16px">
                  Personalize terms related to late fees, pets, subletting, utilities, and more.{' '}
                </Text>
              </Flex>
            </HStack>
          </Box>
          <Box
            direction="column"
            bg="brand.darkBlue.100"
            borderColor="brand.blue.100"
            borderRadius="8"
            p="2"
            w="inherit"
          >
            <HStack alignItems="flex-start" gap={2}>
              {isMax767 && (
                <Box marginTop="8px">
                  <Icon24TV />
                </Box>
              )}
              <Flex direction="column" gap="0.5">
                {!isMax767 && <Icon24TV />}
                <Heading size="headline-md" mt="1">
                  100% online
                </Heading>
                <Text textStyle="sm" mb="16px">
                  Easily collect e-signatures and store your fully executed lease on Baselane
                </Text>
              </Flex>
            </HStack>
          </Box>
        </Wrapper>

        <NewAgreementButton />
        <BaselaneButton
          h={0}
          palette="primary"
          size="lg"
          variant="transparent"
          color="brand.blue.800A"
          onClick={() => {
            sendSegmentEvent('lease_agreement_fe_preview_clicked', { in_flow: false });
            setShowDocsIframe(true);
          }}
        >
          Preview template
        </BaselaneButton>
        <BaselaneDivider styles={{ m: '32px 0' }} />

        <Box display="flex" gap={1} flexDirection="row" alignItems="center">
          <Text textStyle="sm" color="brand.neutral.600">
            Powered by
          </Text>
          <Box display="flex" mt="1">
            <IconRocketLawyer />
          </Box>
        </Box>
      </VStack>
      {showDocsIframe && (
        <RocketLayerDocsIframe handleCloseiFrame={setShowDocsIframe} showiFrame={showDocsIframe} />
      )}
    </Box>
  );
};

export default EmptyState;
