import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/client';
import { BaselaneErrorCard, BaselaneSpinner } from '@shared/components';
import useCurrentWorkspace from '@shared/hooks/useCurrentWorkspace';
import { InvoiceProvider } from '@contexts/InvoiceContext';
import ReferralPopup from '@core/components/ReferralPopup';

import { GET_INVOICE_SUMMARY, GET_TENANT_PROFILE, GET_LEASES_DRAFT_DATA } from './queries';
import useLeaseBanks from './hooks/useLeaseBanks';
import LeasesPageResponsiveUI from './LeasesPageResponsiveUI';

function LeasesPage() {
  const { cache } = useApolloClient();

  const [isLeasesPageLoading, setIsLeasesPageLoading] = useState(false);
  // Drafts need to be reset if user goes to URC
  // TODO: NOT ONLY DRAFT, also unit?.lease?.state === 'READY_TO_BE_REVIEWED'
  const { loading: draftsLoading, error: draftsError, data: draftsData } = useQuery(
    GET_LEASES_DRAFT_DATA,
    {
      fetchPolicy: 'cache-and-network',
      variables: { input: { state: ['DRAFT', 'READY_TO_BE_REVIEWED'], page: 1, pageLimit: 1 } },
    }
  );

  const {
    loading: isInvoiceSummaryLoading,
    error: hasInvoiceSummaryError,
    data: invoiceSummaryData,
    refetch: refetchInvoiceSummary,
  } = useQuery(GET_INVOICE_SUMMARY);

  const { workspaceMetadata } = useCurrentWorkspace();
  const { metadata } = workspaceMetadata || {};
  const showReferralModal = metadata?.hideReferralModal === false; // only show modal if flag exists and is false

  // bank data
  const { bankProps, banksError, banksLoading, allBanksError, allBanksLoading } = useLeaseBanks();

  // Get Tenant details
  const { loading: tenantLoading, data: tenantData, error: tenantError } = useQuery(
    GET_TENANT_PROFILE
  );

  // Get draft count and first draft
  const draftCount = draftsData?.leases?.totalCount;
  const firstDraft = draftCount === 1 ? draftsData?.leases?.leases[0].propertyUnitId : null;

  const hasError =
    draftsError || tenantError || banksError || allBanksError || hasInvoiceSummaryError;

  const isLoading =
    isLeasesPageLoading ||
    draftsLoading ||
    tenantLoading ||
    banksLoading ||
    allBanksLoading ||
    isInvoiceSummaryLoading;

  useEffect(() => {
    return () => {
      // erase lease cache so that it reloads on next visit
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'leases' });
      // garbage collect to free up memory
      cache.gc();
    };
  }, []);

  return (
    <>
      {isLoading && (
        <Box>
          <BaselaneSpinner />
        </Box>
      )}
      {hasError && !isLoading && <BaselaneErrorCard />}
      {!hasError && !isLoading && (
        <>
          <InvoiceProvider>
            <LeasesPageResponsiveUI
              {...{
                invoiceSummaryData,
                refetchInvoiceSummary,
                bankProps,
                tenantData,
                draftCount,
                firstDraft,
                setIsLeasesPageLoading,
              }}
            />
          </InvoiceProvider>
          {showReferralModal && <ReferralPopup />}
        </>
      )}
      <Outlet />
    </>
  );
}

export default LeasesPage;
