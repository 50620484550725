export const priorityContainerStyles = {
  w: '80px',
  px: 2,
  textAlign: 'left',
};

export const priorityStyles = {
  textAlign: 'left',
  display: 'inline-block',
  w: 'fit-content',
};

export const conditionStyles = {
  flex: 1,
  textAlign: 'left',
};

export const actionStyles = {
  flex: 1,
  textAlign: 'left',
};

export const ruleClickAreaStyles = {
  borderRadius: 0,
  w: 'full',
  h: 'full',
  p: 0,
  alignItems: 'center',
  gap: 2,
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  my: -2.5,
  py: 2.5,
  _hover: {
    bg: 'transparent',
  },
};
