export const tabContainerStyles = (isMax576: boolean) => ({
  d: 'flex',
  flexDir: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  gap: isMax576 ? 2 : 4,
  px: isMax576 ? 0 : 2,
  h: '62px',
});

export const tenantScreeningsTabsListStyles = (isMax576) => ({
  color: 'brand.neutral.500',
  fontSize: isMax576 ? 'xs' : 'md',
  fontWeight: 'medium',
  px: 0,
  py: 2,
  h: '100%',
  minW: isMax576 ? { base: '60px', xs: 'calc(25% - 16px)' } : 0,
  _selected: {
    color: 'brand.darkBlue.800A',
    borderColor: 'brand.darkBlue.800A',
  },
});

export const pageContainerPadding = (isMax576, isMin899) => {
  if (isMax576) {
    return 2;
  }
  if (isMin899) {
    return 0;
  }
  return 4;
};

export const bannerButtonStyles = {
  bg: 'white',
  border: '1pt solid',
  borderColor: 'brand.darkBlue.200',
  color: 'brand.darkBlue.800A',
};

export const innerContainerStyles = (isMin899) => ({
  innercontainer: {
    flexDir: isMin899 ? 'row' : 'column',
    ...(isMin899 ? {} : { width: '100%', alignItems: 'flex-start' }),
  },
  illustration: {
    alignSelf: 'flex-start',
  },
});

export const emptyImageStyles = {
  w: '240px',
  h: '320px',
  backgroundColor: 'brand.neutral.150',
  border: '1px solid',
  borderColor: 'brand.darkBlue.200',
  borderRadius: '8px',
  textWrap: 'auto',
  whiteSpace: 'pre-wrap',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  direction: 'column',
  alignItems: 'center',
};

export const docDeleteAlert = ({ isMinXL }) => {
  return {
    container: {
      w: isMinXL && '100%',
    },
    button: {
      w: isMinXL && '50%',
    },
  };
};
