import React, { useState } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { Box, HStack, Stack, Text, useToast } from '@chakra-ui/react';
import { IconPencil, IconOfficeBuilding, IconDocument, IconXOutline } from '@icons';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { UPDATE_PROPERTY_DOCUMENT } from '@core/apollo/queries';
import {
  renderPropertyDropdownChildItem,
  renderPropertyDropdownParentItem,
} from '../../../../../helpers/propertiesFilter.helpers';
import BaselaneButton from '../../../../BaselaneButton';
import BaselaneDropdown from '../../../../BaselaneDropdown';
import PlaceholderIcon from '../../../../BaselaneDropdown/components/PlaceholderIcon';
import BaselaneBadge from '../../../../BaselaneBadge';
import { STATUS_BADGE, ACTION_BUTTON } from './helpers/document.helpers';
import { showSuccessToast, showErrorToast } from '../../../helpers/shared.helpers';
import { documentStyles } from './styles/document.styles';

type DocumentProps = {
  doc: Object,
  propertyOptions: Array<Object>,
  setSelectedPropertyDoc: Function,
  handleOpeniFrame: Function,
};

const Document = ({
  doc,
  propertyOptions,
  setSelectedPropertyDoc,
  handleOpeniFrame,
}: DocumentProps) => {
  const { isMinXL } = useBreakPoints();
  const toast = useToast();

  // UPDATE_PROPERTY_DOC
  const [updatePropertyDocument] = useMutation(UPDATE_PROPERTY_DOCUMENT);

  // Styles
  const {
    container,
    dropdowncontainer,
    innercontainer,
    fileiconcontainer,
    titlecontainer,
    title,
    timelapse,
    badge,
    dropdown,
  } = documentStyles({ isMinXL }) ?? {};

  // Local Vars
  const { id, state, propertyId, propertyUnitId, documentName, lastEventTimestamp } = doc ?? {};
  const hasMultipleUnits = propertyId !== propertyUnitId;
  const selectedItemId = hasMultipleUnits ? `${propertyId}-${propertyUnitId}` : propertyId;
  const propertyObj = propertyOptions?.find((property) => property?.id === selectedItemId) ?? null;
  const selectedItemObj = propertyObj
    ? {
        id: propertyObj.id,
        showValueName: propertyObj.showValueName,
      }
    : null;

  const defaultElapsedTime = lastEventTimestamp ? moment(lastEventTimestamp).fromNow(true) : null;
  const { variant: buttonType, palette, text: buttonText, icon: buttonIcon } = ACTION_BUTTON[state];

  // State Vars
  const [selectedItem, setSelectedItem] = useState(selectedItemObj);

  // Functions
  const handleDocumentClick = (e) => {
    const belowDropdownContainer = document.querySelector(`.below-dropdown-${id}`);
    if (belowDropdownContainer?.contains(e?.target)) {
      setSelectedPropertyDoc(doc);
      handleOpeniFrame();
    }
  };

  const handlePropertyChange = (selectedValue, item) => {
    const { isPropertyLevel } = item ?? {};

    const updatedSelectItem = {
      id: selectedValue,
      showValueName: propertyOptions?.find((property) => property?.id === selectedValue)
        ?.showValueName,
    };

    const [pId, uId] = selectedValue?.split('-') ?? [null, null];

    setSelectedItem(updatedSelectItem);
    updatePropertyDocument({
      variables: {
        input: {
          id,
          propertyUnitId: uId,
          propertyId: isPropertyLevel ? pId : null,
        },
      },
      onCompleted: ({ updatePropertyDocument: updatedDoc }) => {
        showSuccessToast(toast);
        setSelectedPropertyDoc(updatedDoc);
      },
      onError: () => {
        showErrorToast(toast);
      },
    });
  };

  const dropdownClasses = [
    'auto-width',
    'disable-max-width',
    'input-form-xl',
    'auto-width-dropdown',
    'medium-font',
    'card-header',
  ];

  return (
    // TODO: Once dropdown component revamp is over, add this card with dropdown as a reusable compponent
    <Stack {...container}>
      <Box {...dropdowncontainer}>
        <BaselaneDropdown
          {...{
            type: 'tier2',
            classNames: [...dropdownClasses],
            data: propertyOptions,
            title: 'Property',
            showValueByFields: ['showValueName'],
            parentItemRenderer: ({ item }) => renderPropertyDropdownParentItem(item),
            childItemRenderer: ({ item }) => renderPropertyDropdownChildItem(item),
            handleSubmit: (selectedValue, item) => {
              handlePropertyChange(selectedValue, item);
            },
            isDisabled: state !== 'DRAFT',
            placeholder: (
              <PlaceholderIcon
                icon={IconXOutline}
                iconProps={{ color: '#92630C' }}
                text="Deleted property"
                styles={{
                  title: {
                    color: 'yellow.900',
                  },
                }}
              />
            ),
            selectedItem: selectedItem?.id ? selectedItem : null,
            hasFilterWrapper: false,
            parentId: 'rocket-lawyer-drawer',
            selectedItemIcon: (
              <Box mr="8px">
                <IconOfficeBuilding w={16} h={16} color="#3A4B5B" />
              </Box>
            ),
            showCustomButtonHandler: true,
            customButtonHandlerProps: selectedItem?.id
              ? {
                  buttonType: 'icon',
                  buttonProps: {
                    variant: 'transparent',
                    palette: 'neutral',
                    icon: IconPencil,
                    iconProps: {
                      color: '#257ED0',
                      width: '11.33px',
                      height: '11.33px',
                    },
                  },
                }
              : {
                  buttonProps: {
                    label: 'Select new property',
                    variant: 'outline',
                    palette: 'neutral',
                  },
                },
            styles: dropdown,
          }}
        />
      </Box>
      <HStack
        {...innercontainer}
        onClick={handleDocumentClick}
        className={`below-dropdown-${id} doc-inner-container`}
        tabIndex="0"
      >
        <HStack>
          <Box {...fileiconcontainer}>
            <IconDocument color="#3A4B5B" />
          </Box>

          <Stack {...titlecontainer}>
            <Text {...title}>{documentName}</Text>
            <HStack m="0 !important">
              <BaselaneBadge type="badge" backgroundStyles={badge} {...STATUS_BADGE[state]} />
              <Text {...timelapse}>
                {state !== 'SIGNING_COMPLETE' ? 'Edited' : 'Signed'} {defaultElapsedTime} ago
              </Text>
            </HStack>
          </Stack>
        </HStack>

        <BaselaneButton
          variant={buttonType}
          palette={palette}
          onClick={(e) => {
            e.stopPropagation();
            handleDocumentClick(e);
          }}
          rightIcon={buttonIcon}
        >
          {buttonText}
        </BaselaneButton>
      </HStack>
    </Stack>
  );
};

export default Document;
