import React, { useContext, useEffect } from 'react';
import TransactionsPage from '@core/components/Transactions';
import TransactionContext from '@contexts/TransactionContext';

type TransactionsWrapperProps = {
  trxFilters: Object,
  filterSummary: any,
  hasNoData: boolean,
  isCashFlowByTypeAnimating: boolean,
  isTableOnly?: boolean,
};

function TransactionsWrapper({
  trxFilters,
  filterSummary,
  hasNoData,
  isCashFlowByTypeAnimating,
  isTableOnly = false,
}: TransactionsWrapperProps) {
  const { filters, setFilters, DEFAULT_FILTERS } = useContext(TransactionContext);

  useEffect(() => {
    setFilters({
      ...DEFAULT_FILTERS,
      filter: { ...filters.filter, ...trxFilters },
    });
  }, [trxFilters]);

  return (
    <TransactionsPage
      styles={{
        transactionsListContainerStyles: { p: 0 },
        filterRowContainerStyles: { px: '0', top: '-20px' },
      }}
      hideCategorizedTrxBanner
      hideFilters
      filterSummary={filterSummary}
      hideFilterSummary={false}
      showDummyData={hasNoData}
      isExternalFilterLoading={isCashFlowByTypeAnimating}
      isTableOnly={isTableOnly}
    />
  );
}

export default TransactionsWrapper;
