import React from 'react';
import { Placeholder } from '@shared/components';
import { DisplayInputWrapper } from '@shared/components/BaselaneDropdown-new/DisplayInputVariations';
import TenantScreeningApplicantOption from './TenantScreeningApplicantOption';

type DisplayInputDefaultProps = {
  handleContentRendererClick: Function,
  showValueByFields: Array<string>,
  selectedOptions: Array<Object>,
  title: string,
  placeholder: string,
  classNames: Array<string>,
};

const TenantScreeningApplicantDisplayInput = ({
  handleContentRendererClick,
  selectedOptions,
  showValueByFields,
  placeholder,
  title,
  classNames,
}: DisplayInputDefaultProps) => {
  return (
    <DisplayInputWrapper
      handleContentRendererClick={handleContentRendererClick}
      classNames={classNames}
    >
      {selectedOptions.length > 0 ? (
        <TenantScreeningApplicantOption
          applicantName={selectedOptions[0]?.name}
          type={selectedOptions[0]?.type}
        />
      ) : (
        <Placeholder placeholder={placeholder} title={title} />
      )}
    </DisplayInputWrapper>
  );
};

export default TenantScreeningApplicantDisplayInput;
