import React, { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { Stack, Spacer } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { BaselaneButton } from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { GET_KYC_URL } from '@core/components/NativeBankingPage/queries';
import userContext from '@contexts/UserContext';
import { UPDATE_USER } from '@core/components/UserProfilePage/queries';
import historyStateStorage from '@core/storage/historyStateStorage';
import { handleFinishLater } from '@core/components/OnboardingTriage/helpers/onboardingFunctions.helpers';
import {
  footerContainerStyles,
  buttonStyles,
} from '@core/components/OnboardingTriage/styles/success.styles';

import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import APYPromotionBanner from './APYPromotionBanner';

type FooterButtonsProps = {
  leftBtn: Object,
  rightBtn: Object,
  bankId: string,
  historyStateData: Object,
  showDAPYBanner: Boolean,
  isFromRC: Boolean,
  userPromoApy: String,
};

const FooterButtons = ({
  leftBtn,
  rightBtn,
  bankId,
  historyStateData,
  showDAPYBanner = false,
  isFromRC,
  userPromoApy,
}: FooterButtonsProps) => {
  const [getKYCUrl] = useLazyQuery(GET_KYC_URL, {
    onCompleted: ({ unitOnBoarding }) => {
      window.open(unitOnBoarding, '_self');
    },
    fetchPolicy: 'no-cache',
  });

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  const { user, refetchUser } = useContext(userContext);
  const [updateUser] = useMutation(UPDATE_USER);
  const navigate = useNavigate();

  const isFromAnotherFlow = historyStateData?.from && typeof historyStateData?.from !== 'string';

  const fromState =
    typeof historyStateData?.from === 'string'
      ? historyStateData?.from
      : historyStateData?.from?.main;

  const {
    text: textLeft = '',
    pathname: pathnameLeft = null,
    segmentEvent: segmentEventLeft = '',
    segmentEventAttributes: segmentEventAttributesLeft = {},
  } = leftBtn ?? {};

  const {
    text: textRight = '',
    pathname: pathnameRight = null,
    segmentEvent: segmentEventRight = '',
    leftIcon: leftIconRightBtn = null,
    rightIcon: rightIconRightBtn = null,
    segmentEventAttributes: segmentEventAttributesRight = {},
  } = rightBtn ?? {};

  const handleRouteChange = (btnPath) => {
    historyStateStorage.write({ from: fromState, params: historyStateData?.from?.params });
    navigate(btnPath);
  };

  const isOnboardingCompleted = !(!user.onboardingCompleted && isFromAnotherFlow);

  const onLeftButtonClick = () => {
    if (segmentEventLeft) {
      sendSegmentEvent(segmentEventLeft, segmentEventAttributesLeft);
    }

    if (pathnameLeft) {
      handleFinishLater({
        user,
        refetchUser,
        updateUser,
        isOnboardingCompleted: textLeft === 'Close' ? true : isOnboardingCompleted,
        handleRouteChange: () => handleRouteChange(pathnameLeft),
      });
    }
  };
  const onRightButtonClick = () => {
    if (segmentEventRight) {
      sendSegmentEvent(segmentEventRight, segmentEventAttributesRight);
    }

    if (pathnameRight) {
      if (textRight === 'Fund Your Account') localStorage.setItem('component-trigger', 'add_funds');
      handleFinishLater({
        user,
        refetchUser,
        updateUser,
        isOnboardingCompleted,
        handleRouteChange: () => handleRouteChange(pathnameRight),
      });
    } else if (textRight === 'Upload documents') {
      updateUser({
        variables: {
          ...user,
          onboardingCompleted: isOnboardingCompleted,
        },
      }).then(() => {
        getKYCUrl({ variables: { bankId } });
      });
    } else if (textRight === 'Contact support') {
      zendeskAPI('messenger', 'open');
    }
  };

  return showDAPYBanner ? (
    <APYPromotionBanner
      {...{
        userPromoApy,
        onRightButtonClick,
        buttonText: textRight,
        buttonLeftIcon: leftIconRightBtn,
        fromState,
        isFromRC,
        onLeftButtonClick,
        leftBtn,
        buttonStyles,
      }}
    />
  ) : (
    <Stack {...footerContainerStyles} mt={0.5} spacing={1.5}>
      {leftBtn && (
        <BaselaneButton
          id={`${textLeft?.toLowerCase()?.replace(/\s/g, '')}-button`}
          variant="outline"
          palette="neutral"
          size="md"
          isFullWidth={isMobile}
          onClick={onLeftButtonClick}
        >
          {textLeft}
        </BaselaneButton>
      )}
      {isMobile && <Spacer />}
      {rightBtn && (
        <BaselaneButton
          id={`${textRight?.toLowerCase()?.replace(/\s/g, '')}-button`}
          variant="filled"
          palette="primary"
          size="md"
          isFullWidth={isMobile}
          leftIcon={leftIconRightBtn}
          rightIcon={rightIconRightBtn}
          onClick={onRightButtonClick}
        >
          {textRight}
        </BaselaneButton>
      )}
    </Stack>
  );
};

export default FooterButtons;
