import { isMobile } from 'react-device-detect';
import userStorage from '@core/storage/userStorage';
import {
  getDeviceType,
  getViewport,
} from '@core/utils/sendSegmentEvent/helpers/sharedAttributes.helpers';
import getSearchParams from './helpers/path.helpers';
import { getEnv } from './helpers/event.helpers';

export const createWorkspaceSegmentData = ({
  workspaceOwner = {},
  activeWorkspaceId,
  workspaceName,
  user = {},
}) => {
  return {
    timestamp: new Date().valueOf(),
    user_id: workspaceOwner?.Id,
    user_name: `${workspaceOwner?.firstName ?? ''} ${workspaceOwner?.lastName ?? ''}`,
    workspace_id: activeWorkspaceId,
    workspace_name: workspaceName,
    action_taker_id: user?.id,
    action_taker_name: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`,
  };
};

const sendSegmentEvent = (eventName, data) => {
  const searchParams = getSearchParams();

  const {
    id: sessionUserId,
    email: sessionEmail,
    phone_Number: sessionPhoneNumber,
    ip: sessionIP,
    gclid: sessionGCLID,
    utm: sessionUTM,
    workspace_owner_id: workspaceOwnerId,
  } = userStorage.read();

  const url =
    searchParams === '?null'
      ? new URL(`${window.location.origin}${window.location.pathname}`)
      : new URL(`${window.location.origin}${window.location.pathname}${searchParams}`);
  window?.history?.replaceState(null, null, url);
  window?.analytics?.track(
    eventName,
    {
      ...data,
      email: sessionEmail,
      env: getEnv(),
      orderId: sessionUserId ? `${eventName}_${sessionUserId}` : '',
      phone_number: sessionPhoneNumber,
      platform: isMobile ? 'mobile' : 'desktop',
      timestamp: new Date().valueOf(), // in milliseconds
      ...getViewport(),
      ...getDeviceType(),
    },
    {
      ip:
        sessionIP ||
        sessionUTM?.unclassified?.ip ||
        sessionUTM?.google?.ip ||
        sessionUTM?.facebook?.ip,
      traits: {
        email: sessionEmail,
        phone_number: sessionPhoneNumber,
        gclid: sessionGCLID,
        utm: sessionUTM,
        user_id: sessionUserId,
        workspace_owner_id: workspaceOwnerId,
      },
    }
  );
};

export default sendSegmentEvent;
