// @flow
import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  HStack,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Text,
  Input,
} from '@chakra-ui/react';
import { FieldArray } from 'formik';
import MaskedInput from 'react-text-mask';
import { currencyMask } from '@core/utils/masks';
import formatCurrency from '@core/utils/formatCurrency';
import { BaselaneButton, BaselaneButtonIcon } from '@shared/components';
import { formErrorStyles, formLabelStyles } from '@shared/styles/input.style';
import { IconEdit, IconCheck, IconWarningCircleOutline } from '@icons';
import { Icon16PlusCircle, Icon16Delete } from '@icons/16px';
import { textStyles2, textStyles } from '../../../styles/invoiceDetails.style';
import { fieldContainer } from '../../../LeaseSection/styles/shared.styles';

type AddNewInvoiceItemProps = {
  description: string,
  editState: boolean,
  values: Object,
  handleChange: Function,
  setFieldValue: Function,
  errors: Object,
  touched: Object,
  dirty: boolean,
  amount: any,
  setIsDirty: Function,
  setTotalAmount: Function,
  getTotalAmount: Function,
  validateForm: Function,
  isFromAddInvoice: boolean,
  descriptionError: boolean,
  setDescriptionError: Function,
  amountError: boolean,
  setAmountError: Function,
};

function AddNewInvoiceItem({
  description,
  editState,
  values,
  handleChange,
  setFieldValue,
  errors,
  touched,
  dirty,
  amount,
  setIsDirty,
  setTotalAmount,
  getTotalAmount,
  validateForm,
  isFromAddInvoice,
  descriptionError,
  setDescriptionError = () => {},
  amountError,
  setAmountError = () => {},
}: AddNewInvoiceItemProps): any {
  useEffect(() => {
    setIsDirty(dirty);
    if (dirty) {
      validateForm();
    }
  }, [`${values.fees}`]);

  return editState ? (
    <FieldArray name="fees">
      {({ remove, push }) => (
        <Box>
          {values.fees?.length > 0 &&
            values.fees.map(
              (fee, i) =>
                (!isFromAddInvoice || (isFromAddInvoice && fee.newFee)) && (
                  <Flex
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    p={isFromAddInvoice ? '16px' : '8px 0'}
                    bg={isFromAddInvoice ? 'brand.neutral.50' : 'none'}
                    w="100%"
                    direction="column"
                  >
                    <HStack
                      justifyContent="space-between"
                      spacing={1}
                      w="100%"
                      alignItems="flex-start"
                    >
                      {fee.newFee ? (
                        <Box w={isFromAddInvoice ? '100%' : 'none'}>
                          {/* Fee Description */}
                          <FormControl
                            {...fieldContainer}
                            mb={isFromAddInvoice ? '8px' : '0'}
                            isInvalid={descriptionError}
                          >
                            {isFromAddInvoice && (
                              <FormLabel {...formLabelStyles.xs} htmlFor={`fees.${i}.description`}>
                                Item Name or Description
                              </FormLabel>
                            )}
                            <Input
                              {...textStyles}
                              bg="brand.neutral.white"
                              h={isFromAddInvoice ? '48px' : '32px'}
                              id={`fees.${i}.description`}
                              name={`fees.${i}.description`}
                              placeholder="Enter Fee Name"
                              value={fee.description}
                              onChange={handleChange}
                              onBlur={(e) => {
                                if (e.target.value) {
                                  setIsDirty(true);
                                }
                                setFieldValue(`fees.[${i}]`, {
                                  ...fee,
                                  description: e.target.value,
                                  paymentName: 'FEE',
                                  itemName: 'FEE',
                                });
                                setDescriptionError(!fee.description.replace(/\s/g, ''));
                                validateForm();
                              }}
                            />
                            <FormErrorMessage {...formErrorStyles} ml="0">
                              <IconWarningCircleOutline color="#C93A3A" />
                              <Text ml="8px">Enter Item Name</Text>
                            </FormErrorMessage>
                          </FormControl>
                        </Box>
                      ) : (
                        <Text {...textStyles}>{fee.description}</Text>
                      )}
                      <Stack direction="row" align="center" w={isFromAddInvoice ? '100%' : 'none'}>
                        {/* Fee Amount */}
                        {fee.edit ? (
                          <FormControl
                            {...fieldContainer}
                            mb={isFromAddInvoice ? '8px' : '0'}
                            isInvalid={amountError}
                          >
                            {isFromAddInvoice && (
                              <FormLabel {...formLabelStyles.xs} htmlFor={`fees.${i}.amount`}>
                                Amount
                              </FormLabel>
                            )}
                            <Input
                              {...textStyles}
                              bg="brand.neutral.white"
                              h={isFromAddInvoice ? '48px' : '32px'}
                              id={`fees.${i}.amount`}
                              name={`fees.${i}.amount`}
                              placeholder="$50.00"
                              as={MaskedInput}
                              mask={currencyMask()}
                              value={fee.amount}
                              onChange={handleChange}
                              onBlur={(e) => {
                                if (e.target.value) {
                                  setIsDirty(true);
                                }
                                setFieldValue(`fees.[${i}]`, {
                                  ...fee,
                                  amount: e.target.value?.replace(/[$|,]/g, ''),
                                  paymentName: 'FEE',
                                  itemName: 'FEE',
                                });
                                setAmountError(
                                  !fee.amount || Number(fee.amount?.replace(/\$|,/g, '')) <= 2
                                );
                                validateForm();
                              }}
                            />
                            <FormErrorMessage {...formErrorStyles} ml="0">
                              <IconWarningCircleOutline color="#C93A3A" />
                              <Text ml="8px">Enter amount greater than $2.00</Text>
                            </FormErrorMessage>
                          </FormControl>
                        ) : (
                          <Text {...textStyles2}>
                            {formatCurrency(fee.amount.replace(/[$|,]/g, '')).inDollars}
                          </Text>
                        )}

                        {!isFromAddInvoice && fee.edit && (
                          <Stack direction="row" mb="auto !important">
                            {fee.description !== 'Monthly Rent' && (
                              <BaselaneButtonIcon
                                variant="outline"
                                palette="neutral"
                                icon={<Icon16Delete />}
                                size="sm"
                                onClick={() => {
                                  remove(i);
                                  const clonedFees = [...values.fees];
                                  clonedFees.splice(i, 1);
                                  const updatedFees = clonedFees.filter((f) => !f.edit);
                                  const total = getTotalAmount(updatedFees, editState, amount);
                                  setTotalAmount(total);
                                }}
                              />
                            )}
                            <BaselaneButtonIcon
                              variant="filled"
                              palette="primary"
                              icon={<IconCheck />}
                              size="sm"
                              isDisabled={
                                fee.description === '' ||
                                !fee.amount ||
                                Number(fee.amount?.replace(/\$|,/g, '')) < 0.5 ||
                                fee.amountError ||
                                fee.descriptionError ||
                                amountError
                              }
                              onClick={() => {
                                const updatedFees = values.fees.filter(
                                  (f, index) => !f.edit || index === i
                                );
                                const total = getTotalAmount(updatedFees, editState, amount);
                                setTotalAmount(total);
                                if (
                                  fee.description.replace(/\s/g, '') &&
                                  fee.amount &&
                                  !(Number(fee.amount?.replace(/\$|,/g, '')) < 0.5)
                                ) {
                                  setAmountError(false);
                                  setDescriptionError(false);
                                  setFieldValue(`fees.[${i}]`, {
                                    ...fee,
                                    edit: false,
                                    newFee: false,
                                  });
                                }
                              }}
                            />
                          </Stack>
                        )}
                        {!fee.edit && (
                          <BaselaneButtonIcon
                            icon={<IconEdit />}
                            variant="transparent"
                            palette="primary"
                            size="sm"
                            onClick={() => setFieldValue(`fees.[${i}]`, { ...fee, edit: true })}
                          />
                        )}
                      </Stack>
                    </HStack>
                    {isFromAddInvoice && fee.edit && (
                      <Stack direction="row" spacing={1} gap="0px" mt="0">
                        <BaselaneButton
                          variant="transparent"
                          palette="primary"
                          pullLeft
                          leftIcon={<Icon16PlusCircle />}
                          size="sm"
                          onClick={() => {
                            setDescriptionError(!fee.description.replace(/\s/g, ''));
                            setAmountError(
                              !fee.amount || Number(fee.amount?.replace(/\$|,/g, '')) < 0.5
                            );
                            if (
                              fee.description.replace(/\s/g, '') &&
                              fee.amount &&
                              Number(fee.amount?.replace(/$|,/g, '')) >= 0.5
                            ) {
                              const updatedFees = values.fees.filter(
                                (f, index) => !f.edit || index === i
                              );
                              const total = getTotalAmount(updatedFees, editState, amount);
                              setTotalAmount(total);
                              setFieldValue(`fees.[${i}]`, {
                                ...fee,
                                edit: false,
                                newFee: false,
                              });
                            }
                            setIsDirty(true);
                          }}
                        >
                          Add
                        </BaselaneButton>
                        {values.fees > 1 && (
                          <BaselaneButton
                            variant="filled"
                            palette="danger"
                            size="lg"
                            styles={{ px: '8px' }}
                            onClick={() => {
                              remove(i);
                              const clonedFees = [...values.fees];
                              clonedFees.splice(i, 1);
                              const updatedFees = clonedFees.filter((f) => !f.edit);
                              const total = getTotalAmount(updatedFees, editState, amount);
                              setTotalAmount(total);
                            }}
                          >
                            Remove
                          </BaselaneButton>
                        )}
                      </Stack>
                    )}
                  </Flex>
                )
            )}
          {description !== 'Security Deposit' &&
          values.fees &&
          !values.fees[values.fees.length - 1]?.edit ? (
            <Stack direction="row" mt="8px" position="relative">
              <BaselaneButton
                variant="transparent"
                palette="primary"
                size="md"
                pullLeft
                leftIcon={<Icon16PlusCircle />}
                onClick={() =>
                  push(
                    isFromAddInvoice
                      ? {
                          newFee: true,
                          description: '',
                          amount: '',
                          edit: true,
                          paymentType: 'ONE_TIME_FEE',
                        }
                      : { newFee: true, description: '', amount: '', edit: true }
                  )
                }
              >
                {isFromAddInvoice ? 'Add Invoice Item' : 'Add New Invoice Item'}
              </BaselaneButton>
              <Text right="0">
                {errors.unitNames && touched.unitNames && <Box as="span">{errors.unitNames}</Box>}
              </Text>
            </Stack>
          ) : null}
        </Box>
      )}
    </FieldArray>
  ) : null;
}

export default AddNewInvoiceItem;
