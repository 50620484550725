// @flow
import React, { useEffect, useRef } from 'react';
import { VStack } from '@chakra-ui/react';

import customTheme from '@theme';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { dragHandleStyles } from './styles/sortableList.styles';

type DragHandleProps = {
  isHandleDrag: Boolean,
  dragControls: Object,
};

// delays dragging to prevent accidental drag
const TOUCH_DRAG_DELAY_MS = 300;

const DragHandle = ({ isHandleDrag, dragControls, ...rest }: DragHandleProps) => {
  const { isMax767 } = useBreakPoints();

  // detects touch device with new media query
  const isTouchDeviceIsPrimayInput = window?.matchMedia('(pointer: coarse)').matches;

  // checks screen size
  const isSmallTouchDevice = isTouchDeviceIsPrimayInput && isMax767;

  const dragHandleRef = useRef(null);

  const fillColor = customTheme.colors.brand.neutral['400'];

  const onPointerDown = (e) => {
    // e.preventDefault(); // prevents selection of text while dragging
    if (isSmallTouchDevice) {
      setTimeout(async () => {
        return dragControls.start(e);
      }, TOUCH_DRAG_DELAY_MS);
    } else {
      return dragControls.start(e);
    }
    return e;
  };

  /**
   * This is a workaround that solves an issue with Framer Motion
   * (the touch events can cancel the drag events in the new versions).
   * we are preventing the touch events from happening on the drag handle.
   * the pointer events work for both mouse and touch devices.
   */
  useEffect(() => {
    const touchHandler: React.TouchEventHandler<HTMLElement> = (e) => e.preventDefault();

    if (dragHandleRef.current) {
      dragHandleRef.current.addEventListener('touchstart', touchHandler, { passive: false });
    }
    return () => {
      if (dragHandleRef.current) {
        dragHandleRef.current.removeEventListener('touchstart', touchHandler, {
          passive: false,
        });
      }
    };
  }, [dragHandleRef]);

  return (
    <VStack
      ref={dragHandleRef}
      className="reorder-handle"
      {...dragHandleStyles}
      {...(isHandleDrag && {
        onPointerDown,
      })}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75H15C15.4142 6.75 15.75 6.41421 15.75 6C15.75 5.58579 15.4142 5.25 15 5.25H1ZM1 9.25C0.585786 9.25 0.25 9.58579 0.25 10C0.25 10.4142 0.585786 10.75 1 10.75H15C15.4142 10.75 15.75 10.4142 15.75 10C15.75 9.58579 15.4142 9.25 15 9.25H1Z"
          fill={fillColor}
        />
      </svg>
    </VStack>
  );
};

export default DragHandle;
