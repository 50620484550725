import React from 'react';

function IconProfileCompleted() {
  return (
    <svg width="31" height="38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.064 15.758c.204-.888.313-1.814.313-2.767 0-3.18-1.232-6.23-3.424-8.479C18.761 2.263 15.788 1 12.688 1 6.233 1 1 6.369 1 12.991c0 5.614 3.76 10.327 8.838 11.632"
        stroke="#000619"
        strokeLinecap="round"
      />
      <path
        d="M12.688 4.598c1.936 0 3.506 1.61 3.506 3.597 0 1.987-1.57 3.597-3.506 3.597-1.937 0-3.507-1.61-3.507-3.597 0-1.987 1.57-3.597 3.507-3.597Z"
        stroke="#000619"
      />
      <path
        d="m19.34 16.767-.27-.564c-.588-1.23-1.807-2.012-3.144-2.014H9.451c-1.356.002-2.59.805-3.168 2.063l-.245.527c-.184.386-.153.843.082 1.2a8.204 8.204 0 0 0 3.69 2.852"
        stroke="#000619"
        strokeLinecap="round"
      />
      <ellipse cx="23" cy="29.5" rx="8" ry="8.5" fill="#8AD95F" />
      <ellipse cx="21" cy="27.5" rx="8" ry="8.5" stroke="#000619" strokeLinecap="round" />
      <path
        d="m19 26.364 1.234 1.515a.133.133 0 0 0 .203.004L23 25"
        stroke="#000619"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default IconProfileCompleted;
