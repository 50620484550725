import React from 'react';
import { Text, Image, Flex } from '@chakra-ui/react';
import InstantQuoteButton from '../InstantQuoteButton';
import bannerImageSrc from '../assets/loans.svg';

import {
  titleFontStyles,
  subTextFontStyles,
  bannerBackgroundStyles,
} from './styles/bannerText.styles';

const Banner = () => {
  return (
    <Flex gap={4} {...bannerBackgroundStyles} m={{ base: 4, lg: 0 }} p={4}>
      {/* Left Part */}
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        gap={2}
        flex={{ base: '0 1 50%', '2lg': '0 1 60%' }}
      >
        <Flex gap={2} direction="column">
          <Text {...titleFontStyles}>Hassle-free, fast rental loans</Text>
          <Text {...subTextFontStyles} mb={2}>
            Fast, flexible loan options designed for everyday real estate investors looking to scale
          </Text>
        </Flex>
        <InstantQuoteButton
          btnVariant="tonal"
          palette="primary"
          btnSize="lg"
          btnText="Get started"
        />
      </Flex>
      {/* Right Part */}
      <Flex
        width={{ md: 'auto', base: '100%' }}
        justifyContent={{ md: 'flex-end', base: 'center' }}
      >
        <Image src={bannerImageSrc} maxWidth="294px" alt="process images" />
      </Flex>
    </Flex>
  );
};

export default Banner;
