import React, { useState, useEffect, useRef } from 'react';
import { Tr, Td } from '@chakra-ui/react';
import { Icon16ChevronRight } from '@icons/16px';
import { clickableStyles, defaultRowStyles, indicatorStyles } from './styles/row.styles';

type BaselaneResponsiveTableRowProps = {
  /**
   * unique identifier for table row
   */
  id?: String,
  /**
   * identifier to differentiate between table row and table header
   */
  isHeader?: Boolean,
  /**
   * Click handler that makes the entire row interactive.
   */
  onClick?: Function,
  /**
   * Style overrides for the row outermost container.
   */
  contentContainerStyles?: Object,
  /**
   * Row children.
   */
  children: React.Node,
  /**
   * A color, when specified shows a highlight on this row.
   */
  indicatorColor?: String,
  /**
   * A color, when specified shows a highlight on this row.
   */
  chevronCellStyles?: Object,
  /**
   * A boolean to decide whether or not we want to show the border bottom of the table row
   */
  showBorder?: boolean,
  /**
   * identifier for whether the row is disabled
   */
  isDisabled?: boolean,
  /**
   * identifier for the cell to ignore hover state
   */
  ignoredId?: string,
};

/**
 * Renders a single responsive row of the table, that contains cells.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveTableRow = ({
  id = '',
  isHeader = false,
  onClick = null,
  contentContainerStyles = {},
  children,
  indicatorColor = 'transparent',
  chevronCellStyles = {},
  showBorder = true,
  isDisabled = false,
  ignoredId = null,
}: BaselaneResponsiveTableRowProps) => {
  const clickableChevronStyles = { ...clickableStyles(onClick), ...chevronCellStyles };
  const trRef = useRef();
  // Row hovered
  const [activeRowKey, setActiveRowKey] = useState(-1);
  const rowIsHovered = id === activeRowKey;
  const rowStyles = {
    ...defaultRowStyles(isHeader, onClick, showBorder, activeRowKey === '-1'),
    ...contentContainerStyles,
  };
  useEffect(() => {
    if (rowIsHovered) {
      trRef.current.classList.add('isHovered');
    } else {
      trRef.current.classList.remove('isHovered');
    }
  }, [activeRowKey]);

  return (
    <Tr
      disabled={isDisabled}
      onMouseOver={(e) => {
        // Don't set hover/active state if hovering ignoredId cell
        if (!e.target.closest(`#${ignoredId}`)) {
          setActiveRowKey(e.target.parentNode.getAttribute('id'));
        } else {
          setActiveRowKey('-1');
        }
      }}
      onMouseLeave={(e) => setActiveRowKey('-1')}
      {...(id ? { id } : {})}
      {...{
        ...rowStyles,
        cursor: onClick && !isDisabled ? 'pointer' : 'normal',
      }}
      onClick={!isDisabled && activeRowKey !== '-1' ? onClick : null}
      ref={trRef}
    >
      {/* this cell is always added to make room for the indicator (ensures cells line up) */}
      <Td {...indicatorStyles(indicatorColor)} />
      {children}
      {/* this cell is always added to make room for the arrow icon, in case the row is clickable (ensures cells line up) */}
      <Td {...clickableChevronStyles}>
        {!isDisabled && onClick && <Icon16ChevronRight color="currentColor" />}
      </Td>
    </Tr>
  );
};

export default BaselaneResponsiveTableRow;
