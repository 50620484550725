// @flow
// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=4763%3A116979
import React, { useContext } from 'react';
import { Stack } from '@chakra-ui/react';

import { BaselaneButtonGroup } from '@shared/components';
import MoveMoneyMenu from '@core/components/Shared/components/MoveMoneyMenu';
import useBreakPoints from '@core/hooks/useBreakPoints';
import AccountDetailsDrawer from '@pages/BankEntity/AccountDetailsDrawer';
import UserAccessContext from '@contexts/UserAccessContext';
import AccountCards from '../AccountCards';
import AccountTabs from '../AccountTabs';
import MoreMenu from '../MoreMenu';
import { mainAccountStyles } from './content.styles';

type MainContentProps = {
  account: Object,
};

const MainContent = ({ account }: MainContentProps) => {
  const { phoneNumber, plaidItemId: itemId, bankId } = account;
  const { authorizedForBanking } = useContext(UserAccessContext);
  const { isMin768: showDesktopView } = useBreakPoints();

  return (
    <>
      <AccountCards />

      {!showDesktopView && (
        <Stack {...mainAccountStyles.mobileContainer}>
          {authorizedForBanking && (
            <BaselaneButtonGroup size="md">
              <MoveMoneyMenu buttonSize="lg" styles={{ button: { flex: '1', width: '100%' } }} />
              <MoreMenu />
            </BaselaneButtonGroup>
          )}
        </Stack>
      )}

      <AccountDetailsDrawer phoneNumber={phoneNumber} itemId={itemId} bankId={bankId} />

      {/* Bottom Section - Transactions + Cards */}
      <AccountTabs />
    </>
  );
};

export default MainContent;
