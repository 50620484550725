import React, { useRef } from 'react';
import { Box, HStack, Spacer, Stack, Text } from '@chakra-ui/react';

import { IconLightBulb } from '@icons';
import { Icon16ChevronRight } from '@icons/16px';
import { BaselaneCardNew, BaselaneButton } from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import useBreakPoints from '@core/hooks/useBreakPoints';

import LearnMoreDrawer from './LearnMoreDrawer';
import { getFormattedPaymentSummary } from './helpers/paymentSummary.helpers';
import { staticPaymentSummaryData } from '../helpers/emptyState.helpers';
import { getPaymentSummaryStyles } from './styles/paymentSummary.styles';

type PaymentSummaryProps = {
  isEmpty: boolean,
  invoiceSummaryData: Object,
  invoiceDrawerActionBtnRef: any,
  setInvoiceFilters: Function,
  handleInvoiceListDrawerOpen: Function,
  getLeaseInvoices: Function,
};

const PaymentSummary = ({
  isEmpty,
  invoiceSummaryData,
  invoiceDrawerActionBtnRef,
  setInvoiceFilters,
  handleInvoiceListDrawerOpen,
  getLeaseInvoices,
}: PaymentSummaryProps) => {
  const { isMinXL } = useBreakPoints();

  const learnMoreDrawerRef = useRef();

  const {
    invoiceCompletedSummary,
    invoiceOutstandingSummary,
    invoiceOverdueSummary,
    invoiceProcessingSummary,
  } = isEmpty ? staticPaymentSummaryData : invoiceSummaryData?.invoiceSummary ?? {};

  const paymentSummaryData = [
    { ...invoiceOutstandingSummary, state: 'PAYMENT_OUTSTANDING' },
    { ...invoiceProcessingSummary, state: 'PAYMENT_PROCESSING' },
    { ...invoiceOverdueSummary, state: 'PAYMENT_OVERDUE' },
    { ...invoiceCompletedSummary, state: 'PAYMENT_COMPLETE' },
  ];

  const handleSelectedFilterClick = (selectedFilters) => {
    const { filters, input, segmentEvent } = selectedFilters ?? {};
    sendSegmentEvent('rc_page_summary_click_widget', { payment_status: segmentEvent });
    setInvoiceFilters(filters);
    handleInvoiceListDrawerOpen({ input, filters });
  };

  const handleLearnMoreClick = () => {
    sendSegmentEvent('rc_page_summary_click_informational_drawer');
    learnMoreDrawerRef.current?.open();
  };

  const {
    paymentSummaryContainerStyles,
    titleStyles,
    paymentItemContainerStyles,
    topPartStyles,
    bottomPartStyles,
  } = getPaymentSummaryStyles(isMinXL);

  return (
    <Stack {...paymentSummaryContainerStyles}>
      {/* Title */}
      <HStack>
        <Text {...titleStyles}>Payment Summary</Text>
        <Spacer />
        <BaselaneButton
          onClick={handleLearnMoreClick}
          variant="transparent"
          palette="primary"
          size="sm"
          leftIcon={<IconLightBulb />}
        >
          {isMinXL ? 'Learn More' : 'Learn About Payment Statuses'}
        </BaselaneButton>
        <LearnMoreDrawer {...{ learnMoreDrawerRef }} />
      </HStack>

      {/* Payment Items */}
      <HStack {...paymentItemContainerStyles}>
        {paymentSummaryData.map((item) => {
          const selectedFilters = getFormattedPaymentSummary(item);
          const { text, timeStatus, amount, count, chip } = selectedFilters;

          return (
            <BaselaneCardNew
              key={text}
              variant="clickable"
              onClick={() => handleSelectedFilterClick(selectedFilters)}
              ref={invoiceDrawerActionBtnRef}
              onMouseOver={() => {
                return getLeaseInvoices({
                  variables: {
                    input: selectedFilters?.input || {
                      dateType: 'DUE_DATE', // It is required.
                    },
                  },
                });
              }}
            >
              <Stack spacing={1.5} width="100%">
                {/* Top Part */}
                <Stack {...topPartStyles.container}>{chip}</Stack>
                {/* Bottom Part */}
                <Stack {...bottomPartStyles.container}>
                  <Text {...bottomPartStyles.timeStatus}>{timeStatus}</Text>
                  <HStack justifyContent="space-between">
                    <Text {...bottomPartStyles.amount.container}>{amount}</Text>
                    <HStack {...bottomPartStyles.numInvoice}>
                      <Text as="span" textAlign="right">
                        {count}
                      </Text>
                      <Box as="span" {...bottomPartStyles.rightArrowContainer}>
                        <Icon16ChevronRight />
                      </Box>
                    </HStack>
                  </HStack>
                </Stack>
              </Stack>
            </BaselaneCardNew>
          );
        })}
      </HStack>
    </Stack>
  );
};

export default PaymentSummary;
