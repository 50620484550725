import React from 'react';
import { Box, FormControl, InputGroup, InputRightAddon, Input } from '@chakra-ui/react';
import { Field } from 'formik';
import { BaselaneFormLabel, BaselaneFormErrorMessage, T1Dropdown } from '@shared/components';
import { Highlighted } from '@core/components/Shared/components/BaselaneDropdown-new/helpers/itemRenderer.helpers';
import { inputGroupStyles, inputRightAddonStyles, inputStyles } from './styles';

const RepeatEveryField = () => {
  const dropdownItemRenderer = ({ item }) => (
    <Box textStyle="sm" color="inherit">
      <Highlighted text={item?.name} />
    </Box>
  );

  // The values of the dropdown.
  const timePeriods = {
    week: { id: 'week', name: 'Week' },
    month: { id: 'month', name: 'Month' },
  };

  return (
    <Field name="recurringTransfer.repeatEvery.value">
      {({ field, form }) => {
        return (
          <FormControl
            isRequired
            isInvalid={
              form.errors?.recurringTransfer?.repeatEvery?.value &&
              form.touched?.recurringTransfer?.repeatEvery?.value
            }
          >
            <BaselaneFormLabel textStyle="sm" htmlFor="recurringTransfer.repeatEvery.value">
              Repeat every
            </BaselaneFormLabel>
            <InputGroup
              role="group"
              {...inputGroupStyles}
              sx={
                form.errors?.recurringTransfer?.repeatEvery?.value &&
                form.touched?.recurringTransfer?.repeatEvery?.value
                  ? // eslint-disable-next-line no-underscore-dangle
                    inputGroupStyles._invalid
                  : null
              }
            >
              <Input
                id="recurringTransfer.repeatEvery.value"
                name="recurringTransfer.repeatEvery.value"
                type="number"
                placeholder="0"
                {...field}
                variant="unstyled"
                {...inputStyles}
                width="64px"
              />
              <InputRightAddon {...inputRightAddonStyles}>
                <T1Dropdown
                  classNames={['input-form-md', 'repeat-every']}
                  selectedItem={
                    timePeriods[form.values?.recurringTransfer?.repeatEvery?.unit ?? 'month']
                  }
                  data={Object.values(timePeriods)}
                  handleSubmit={(selectedItemId) => {
                    form.setFieldValue('recurringTransfer.repeatEvery.unit', selectedItemId);
                  }}
                  showValueByFields={['name']}
                  hideSearch
                  itemRenderer={dropdownItemRenderer}
                />
              </InputRightAddon>
            </InputGroup>
            <BaselaneFormErrorMessage
              isInvalid={
                form.errors?.recurringTransfer?.repeatEvery?.value &&
                form.touched?.recurringTransfer?.repeatEvery?.value
              }
            >
              {form.errors?.recurringTransfer?.repeatEvery?.value}
            </BaselaneFormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default RepeatEveryField;
