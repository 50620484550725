import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useStatsigClient } from '@statsig/react-bindings';
import BaselaneDisclaimer from '@core/components/NativeBankingPage/components/BaselaneDisclaimer';
import { containerStyle } from '@core/components/NativeBankingPage/styles/nativeBanking.styles';
import { disclaimerText } from '@core/components/NativeBankingPage/components/BaselaneDisclaimer/const/disclaimerText';
import DisclaimerText from '@shared/components/DisclaimerText';
import {
  BaselaneApplicationTypePopup,
  BaselaneButton,
  BaselaneEmptyStateCard,
  NoAccessTooltip,
} from '@shared/components';
import CardImage from '@core/components/NativeBankingPage/MainContent/components/Accounts/BaselaneAccounts/EmptyStateView/CardImage';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { TRANSFERS_PAYMENTS, UNIFIED_LB_PERSONAL_INFO } from '@routes';
import historyStateStorage from '@core/storage/historyStateStorage';
import BanksContext from '@contexts/BanksContext';
import UserAccessContext from '@contexts/UserAccessContext';

import { IllustrationBankingNewaccount } from '@illustrations';

import { DYNAMIC_CONFIGS } from '@core/constants/statsigKeys';
import { emptyStateViewStyles } from './styles';

export function EmptyStateView() {
  const { isMin768 } = useBreakPoints();
  const navigate = useNavigate();
  const { isWorkspaceOwner } = useContext(UserAccessContext);
  const { getDynamicConfig } = useStatsigClient();
  const config = getDynamicConfig(DYNAMIC_CONFIGS.APY_INTEREST);

  const {
    isOpen: isBaselaneApplicationTypePopupOpen,
    onOpen: onBaselaneApplicationTypePopupOpen,
    onClose: onBaselaneApplicationTypePopupClose,
  } = useDisclosure();

  const { getKYCUrl } = useContext(BanksContext);

  const openLBFlow = (type) => {
    const pathname = UNIFIED_LB_PERSONAL_INFO;
    const state = { from: `/${TRANSFERS_PAYMENTS}` };

    if (type === 'business') {
      getKYCUrl();
    } else {
      historyStateStorage.write(state);
      navigate(pathname);
    }
  };

  const handleOpenBaselaneAccountClick = () => {
    onBaselaneApplicationTypePopupOpen();
  };

  const {
    conatinerStyles,
    titleTextStyles,
    emptyStateContainerStyles,
    headingTextStyles,
    imageContainerStyles,
    leftElementContainerStyles,
    rightElementContainerStyles,
    subtextStyles,
  } = emptyStateViewStyles;

  return (
    <Stack {...conatinerStyles}>
      <Stack gap={2}>
        <Text {...titleTextStyles}>Get started with Baselane Banking</Text>
        <Stack direction={isMin768 ? 'row' : 'column'} {...emptyStateContainerStyles}>
          <Stack {...leftElementContainerStyles}>
            <Stack gap={1}>
              <IllustrationBankingNewaccount />
              <Text {...headingTextStyles}>Open Baselane Banking Account</Text>
              <Text {...subtextStyles}>Open in minutes. No account fees or minimum balances.</Text>
            </Stack>

            <Box>
              {!isWorkspaceOwner ? (
                <NoAccessTooltip>
                  <BaselaneButton
                    variant="filled"
                    palette="primary"
                    size="md"
                    isDisabled={!isWorkspaceOwner}
                    onClick={handleOpenBaselaneAccountClick}
                  >
                    Open Baselane Banking Account
                  </BaselaneButton>
                </NoAccessTooltip>
              ) : (
                <BaselaneButton
                  variant="filled"
                  palette="primary"
                  size="md"
                  onClick={handleOpenBaselaneAccountClick}
                >
                  Open Baselane Banking Account
                </BaselaneButton>
              )}
            </Box>
            <BaselaneApplicationTypePopup
              handleActionButton={openLBFlow}
              isOpen={isBaselaneApplicationTypePopupOpen}
              onClose={onBaselaneApplicationTypePopupClose}
            />
          </Stack>
          <Box {...rightElementContainerStyles}>
            <Box>
              <BaselaneEmptyStateCard.TitleSection fontSize="sm" mb={1.5}>
                Banking built for rental property finance
              </BaselaneEmptyStateCard.TitleSection>
              <BaselaneEmptyStateCard.BulletPoints
                type="check"
                iconMarginRight={0}
                alignItems="flex-start"
                mb={1.5}
                gap={1}
                _last={{ mb: 0 }}
              >
                <Text>
                  Separate accounts for properties, security deposits, savings buffers & more opened
                  with one click
                </Text>
                <Text>{config?.get('apy') ?? '--'}% APY on savings accounts, FDIC insured</Text>
                <Text>Physical & virtual debit cards with spend controls</Text>
                <Text>unlimited 1% cash back on debit card spend</Text>
              </BaselaneEmptyStateCard.BulletPoints>
            </Box>
          </Box>
          <Stack {...imageContainerStyles}>
            <CardImage />
          </Stack>
        </Stack>
      </Stack>

      <BaselaneDisclaimer
        containerStyle={containerStyle(false)}
        text={<DisclaimerText />}
        showPrivacyPolicy={disclaimerText.banksAndCards.showPrivacyPolicy}
      />
    </Stack>
  );
}
