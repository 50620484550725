import React from 'react';
import { Field } from 'formik';
import { FormControl } from '@chakra-ui/react';
import { BaselaneInput, BaselaneFormErrorMessage } from '@shared/components';

const NumberOfTransfersField = () => {
  return (
    <Field name="recurringTransfer.numberOfTransfers">
      {({ field, form }) => {
        return (
          <FormControl
            m={0}
            isRequired
            isInvalid={
              form.errors?.recurringTransfer?.numberOfTransfers &&
              form.touched?.recurringTransfer?.numberOfTransfers
            }
            maxWidth="150px"
          >
            <BaselaneInput
              type="number"
              size="sm"
              suffix="transfers"
              {...field}
              isDisabled={form.values?.recurringTransfer?.endBy !== 'number-of-transfers'}
              isInvalid={
                form.errors?.recurringTransfer?.numberOfTransfers &&
                form.touched?.recurringTransfer?.numberOfTransfers
              }
            />
            <BaselaneFormErrorMessage
              isInvalid={
                form.errors?.recurringTransfer?.numberOfTransfers &&
                form.touched?.recurringTransfer?.numberOfTransfers
              }
            >
              {form.errors?.recurringTransfer?.numberOfTransfers}
            </BaselaneFormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default NumberOfTransfersField;
