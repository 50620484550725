import React from 'react';
import { Outlet } from 'react-router-dom';
import { UserAccessProvider } from '@core/contexts/UserAccessContext';
import { MobileDropdownPortalPopup } from '../../../Shared/components';

const UserAccessWrapper = (): any => {
  return (
    <UserAccessProvider>
      {/* to contain mobile dropdowns */}
      <MobileDropdownPortalPopup />
      <Outlet />
    </UserAccessProvider>
  );
};

export default UserAccessWrapper;
