import React from 'react';
import { Stack } from '@chakra-ui/react';
import { getPropertyData } from '../../../../../helpers/propertiesFilter.helpers';
import Document from './Document';

type DocumentsProps = {
  documents: Array<Object>,
  sortedProperties: Array<Object>,
  setSelectedPropertyDoc: Function,
  handleOpeniFrame: Function,
};

const Documents = ({
  documents,
  sortedProperties,
  setSelectedPropertyDoc,
  handleOpeniFrame,
}: DocumentsProps) => {
  const propertyOptions = getPropertyData(sortedProperties, true);

  return (
    <Stack gap="16px" mb="16px">
      {documents.map((doc) => {
        return (
          <Document
            key={doc?.id}
            {...{
              doc,
              propertyOptions,
              setSelectedPropertyDoc,
              handleOpeniFrame,
            }}
          />
        );
      })}
    </Stack>
  );
};

export default Documents;
