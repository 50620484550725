import React, { useRef } from 'react';
import { Box, HStack, Input, Stack, Text } from '@chakra-ui/react';
import IconWarningCircleOutline from '@icons/manual/IconWarningCircleOutline';
import useBreakPoints from '@core/hooks/useBreakPoints';
import BaselaneAlert from '../../BaselaneAlert';
import BaselaneButton from '../../BaselaneButton';
import AlertHeader from '../../AlertHeader';
import { updateFileNamePopupStyles } from './styles/updateFileNamePopup.styles';
import { formInputStyles } from '../../../styles/input.style';

type UpdateFileNamePopupProps = {
  selectedFile: Object,
  isFileNameUpdateAlertOpen: boolean,
  handleFileNameUpdateAlertClose: Function,
  handleUploadFile: Function,
  fileNameErrorMsg: String,
};

function UpdateFileNamePopup({
  selectedFile,
  isFileNameUpdateAlertOpen,
  handleFileNameUpdateAlertClose,
  handleUploadFile,
  fileNameErrorMsg,
}: UpdateFileNamePopupProps): any {
  const { isMinXL } = useBreakPoints();
  const cancelRef = useRef();

  // Destructure Imported Styles
  const { error, footer } = updateFileNamePopupStyles ?? {};

  const { name } = selectedFile?.file ?? {};
  const indexOfDot = name?.lastIndexOf('.');
  const fileName = name?.slice(0, indexOfDot);

  const popupHeader = <AlertHeader icon={false} title="Add attachment name." isHorizontalCenter />;

  const popupBody = (
    <Stack>
      <Input id="file-name" type="text" defaultValue={fileName} {...formInputStyles} />
      <HStack {...error.container}>
        {fileNameErrorMsg && (
          <>
            <Box>
              <IconWarningCircleOutline w={13.33} h={13.33} color="#C93A3A" />
            </Box>

            <Text {...error.msg}>{fileNameErrorMsg}</Text>
          </>
        )}
      </HStack>
    </Stack>
  );

  const popupFooter = (
    <HStack {...footer({ isMinXL }).container}>
      <BaselaneButton
        variant="outline"
        palette="neutral"
        size="md"
        onClick={handleFileNameUpdateAlertClose}
      >
        Cancel
      </BaselaneButton>
      <BaselaneButton
        variant="filled"
        palette="primary"
        size="md"
        onClick={() => handleUploadFile()}
      >
        Upload File
      </BaselaneButton>
    </HStack>
  );

  return (
    <BaselaneAlert
      size={isMinXL ? 'xs' : '2xl'}
      showCloseButton
      isCentered
      isOpen={isFileNameUpdateAlertOpen}
      onClose={() => handleFileNameUpdateAlertClose()}
      leastDestructiveRef={cancelRef}
      header={popupHeader}
      body={popupBody}
      footer={popupFooter}
      isMinXL={isMinXL}
    />
  );
}

export default UpdateFileNamePopup;
