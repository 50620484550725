import React from 'react';
import { Text, Icon, Flex, Box, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { IconCheckCircleOutline } from '@icons';
import { Icon16Close } from '@icons/16px';
import { BaselaneButton } from '@shared/components';
import { CREATE_AUTOTAG_RULE, TRANSACTIONS } from '@routes';

type CustomToastProps = {
  toastId: String,
  categoryId?: String,
  propertyId?: String,
  unitId?: String,
  message: String,
  categoryValue: String,
};

const CustomToast = ({
  toastId,
  message,
  categoryId,
  propertyId,
  unitId,
  categoryValue,
}: CustomToastProps) => {
  const toast = useToast();
  const navigate = useNavigate();

  return (
    <Flex
      bg="green.600"
      color="brand.neutral.white"
      p={2}
      borderRadius="md"
      gap={2}
      alignItems="center"
    >
      <Icon as={IconCheckCircleOutline} boxSize={5} />
      <Box>
        <Text fontWeight="bold" textStyle="sm">
          {message}
        </Text>
        <Text textStyle="xs">Create a rule to automatically do this in the future.</Text>
      </Box>
      <BaselaneButton
        size="sm"
        palette="neutral"
        variant="outline"
        onClick={() => {
          navigate(
            { pathname: `${TRANSACTIONS}/${CREATE_AUTOTAG_RULE}` },
            {
              replace: true,
              state: {
                transactionsCategoryIdToPrefill: categoryId,
                categoryValue,
                transactionsPropertyIdToPrefill: propertyId,
                transactionsUnitIdToPrefill: unitId,
              },
            }
          );
        }}
      >
        Create rule
      </BaselaneButton>
      <button
        type="button"
        onClick={() => toast.close(toastId)}
        color="brand.neutral.white"
        style={{ marginTop: -16 }}
      >
        <Icon as={Icon16Close} />
      </button>
    </Flex>
  );
};

export default CustomToast;
