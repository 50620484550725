export const Step = {
  ADD_PROPERTY: 'ADD_PROPERTY',
  DETAILS: 'DETAILS',
  REPORTS: 'REPORTS',
  REVIEW: 'REVIEW',
};

export const REPORT_PRICES = {
  BASIC_REPORTING: 24.99,
  CRIMINAL_REPORT: 5,
  EVICTION_REPORT: 10,
  INCOME_VERIFICATION: 10,
};

export const PRICE_MAP = {
  criminalReport: REPORT_PRICES.CRIMINAL_REPORT,
  evictionReport: REPORT_PRICES.EVICTION_REPORT,
  incomeVerification: REPORT_PRICES.INCOME_VERIFICATION,
};

export const ERROR_MESSAGES = {
  property: 'Please select a property',
  unit: 'Please select a unit',
  rentAmount: 'Please enter rent amount',
  date: 'Please select date',
  invalidDate: 'Date can not be in the past',
  terms: 'Please select all the terms',
  llcName: 'Please enter an LLC name',
  llcOwned: 'Please select a value',
};

export const UNAVAILABLE_REPORT_MESSAGE = 'This report is not supported in your jurisdiction.';
