import React, { useEffect, useState } from 'react';
import { Text, Tbody, VStack, HStack, Skeleton, Box, Stack, Thead } from '@chakra-ui/react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Icon16Info } from '@icons/16px';
import formatCurrency from '@core/utils/formatCurrency';
import useBreakPoints from '@core/hooks/useBreakPoints';
import {
  BaselaneCardStack,
  BaselaneCardNew,
  BaselaneResponsiveCell,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveCellTitle,
} from '@shared/components';
import { TENANT_SCREENING_HOUSING_HISTORY } from '@core/constants/routes';
import {
  getReportByid,
  getHousingType,
  calculateMonthsOfResidency,
  getDatesOfResidency,
  isReportDataInvalid,
} from '../helpers/tenantScreeningReports.helpers';
import { isFieldNotObject } from '../helpers/tenantScreening.helpers';
import ReportHeader from '../components/ReportHeader';
import ReportUnavailableAlert from '../components/ReportUnavailableAlert';
import DocumentDropdownContainer from './components/DocumentDropdownContainer';
import PendingEmptyState from './PendingEmptyState';

const HousingHistory = () => {
  const { isMax767 } = useBreakPoints();
  const tableColumnConfig = {
    columns: [
      {
        isSortable: false,
        styles: {
          w: isMax767 ? '150px' : '320px',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
      {
        isSortable: false,
        styles: {
          w: '100%',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
    ],
  };
  const { verificationId, externalApplicantId } = useOutletContext();
  const { applicationId } = useParams();

  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [leaseDocIndex, setLeaseDocIndex] = useState([]);

  useEffect(() => {
    // there can be multiple docs for each lease
    // so we need to flatten the array of arrays into a single array of indexes within each parent array
    // [ [1, 2, 3], [4, 5, 6] ] => [0, 0] in case of two leases
    setLeaseDocIndex(reportData?.report?.address?.map(() => 0) || []);
  }, [reportData, isLoading]);

  useEffect(() => {
    if (applicationId && externalApplicantId && verificationId) {
      getReportByid(applicationId, externalApplicantId, verificationId)
        .then(({ data }) => {
          if (data?.name === TENANT_SCREENING_HOUSING_HISTORY) setReportData(data);
        })
        .catch((error) => {
          console.error('Error fetching application:', error);
          setReportData([]);
        })
        .finally(() => setIsLoading(false));
    }
  }, [applicationId, externalApplicantId, verificationId]);

  if (reportData?.status === 'pending' || isReportDataInvalid(reportData, verificationId)) {
    return reportData?.status === 'pending' ? (
      <PendingEmptyState />
    ) : (
      <ReportUnavailableAlert isLoading={isLoading} />
    );
  }

  return (
    <>
      <VStack alignItems="flex-start" gap={4}>
        {/* Summary of housing report  */}
        <Stack
          direction={isMax767 ? 'column' : 'row'}
          gap={2}
          justifyContent="space-between"
          width="100%"
        >
          <VStack alignItems="flex-start" gap={0.5}>
            <Text color="brand.neutral.600" textStyle="sm">
              Number of residences
            </Text>
            <Skeleton isLoaded={reportData}>
              <Text color="brand.neutral.900" textStyle="data-xl">
                {reportData?.report?.total_number_of_residences || 0}
              </Text>
            </Skeleton>
          </VStack>
          <VStack alignItems="flex-start" gap={0.5}>
            <Text color="brand.neutral.600" textStyle="sm">
              Total months of residence
            </Text>
            <Skeleton isLoaded={reportData}>
              <Text color="brand.neutral.900" textStyle="data-xl">
                {reportData?.report?.total_months_of_residence || 0}
              </Text>
            </Skeleton>
          </VStack>
          <VStack alignItems="flex-start" gap={0.5}>
            <Text color="brand.neutral.600" textStyle="sm">
              Avg monthly payments
            </Text>
            <Skeleton isLoaded={reportData}>
              <Text color="brand.neutral.900" textStyle="data-xl">
                {formatCurrency(reportData?.report?.average_monthly_payment_amount || 0).inDollars}
              </Text>
            </Skeleton>
          </VStack>
        </Stack>
        {/* Iterate through the addresses */}
        {reportData?.report?.address &&
          reportData?.report?.address?.map((address, leaseIndex) => {
            const housingType = getHousingType(address);

            if (housingType === 'Rent')
              return (
                <BaselaneCardStack
                  key={`${address}`}
                  size="md"
                  items={[
                    {
                      id: 'header',
                      content: (
                        <ReportHeader>
                          <HStack gap={2} justifyContent="space-between" width="100%">
                            <Box>{address.rent?.address}</Box>
                            <Text whiteSpace="nowrap" color="brand.neutral.600" textStyle="sm">
                              {getDatesOfResidency(
                                address.rent.move_in_date,
                                address.rent?.move_out_date,
                                address.rent?.current_residence
                              )}
                            </Text>
                          </HStack>
                        </ReportHeader>
                      ),
                    },
                    {
                      id: `income-report-card-item-data-${uuidv4()}`,
                      style: { borderBottom: '0 !important' }, // overrides last-of-type bottom border
                      content: (
                        <BaselaneResponsiveTable
                          id="review-columns"
                          config={tableColumnConfig}
                          maxW="600px"
                          tableContainerStyles={{
                            overflowY: 'initial',
                            overflowX: 'initial',
                          }}
                        >
                          <Thead>
                            <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                              <BaselaneResponsiveCellTitle
                                title="Documents"
                                titleSize="headline-sm"
                                styles={{ titleTextStyle: { color: 'black' }, p: 0 }}
                                p={0}
                                configIndex={0}
                                colSpan="2" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                              />
                            </BaselaneResponsiveTableRow>
                            <BaselaneResponsiveTableRow
                              chevronCellStyles={{ w: 0 }}
                              contentContainerStyles={{ border: 'none' }}
                            >
                              <BaselaneResponsiveCell configIndex={0} p={0} overflow="visible">
                                <DocumentDropdownContainer
                                  type="income"
                                  items={address?.rent?.lease_document}
                                  parentIndex={leaseIndex}
                                  currentIndex={leaseDocIndex[leaseIndex]}
                                  handleSetCurrentIndex={(value) => {
                                    const newIndex = [...leaseDocIndex];
                                    newIndex[leaseIndex] = value;
                                    setLeaseDocIndex(newIndex);
                                  }}
                                  isEmptyPlaceholderNeeded
                                />
                              </BaselaneResponsiveCell>
                            </BaselaneResponsiveTableRow>
                          </Thead>
                        </BaselaneResponsiveTable>
                      ),
                    },
                    {
                      id: 'data',
                      content: (
                        <BaselaneResponsiveTable
                          id="review-columns"
                          config={tableColumnConfig}
                          maxW="600px"
                          tableContainerStyles={{
                            overflowY: 'initial',
                            overflowX: 'initial',
                          }}
                        >
                          <Tbody>
                            <BaselaneResponsiveTableRows
                              items={[
                                {
                                  heading: 'Monthly payment',
                                  data: formatCurrency(address.rent?.monthly_payment).inDollars,
                                },
                                {
                                  heading: 'Months of residence',
                                  data: calculateMonthsOfResidency(
                                    address.rent?.move_in_date,
                                    address.rent?.move_out_date
                                  ),
                                },
                                {
                                  heading: 'Housing type',
                                  data: address.housing_type,
                                },
                                {
                                  heading: 'Reason for leaving',
                                  data: address.rent?.reason_for_leaving,
                                },
                              ]}
                              customRow
                              renderItem={(row) => {
                                return (
                                  <BaselaneResponsiveTableRow
                                    chevronCellStyles={{ w: 0 }}
                                    contentContainerStyles={{ border: 'none' }}
                                  >
                                    <BaselaneResponsiveCell configIndex={0} p={0}>
                                      <Text as="span" textStyle="sm" color="brand.neutral.600">
                                        {row.heading}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                    <BaselaneResponsiveCell configIndex={1} p={0}>
                                      <Text
                                        textStyle="sm"
                                        color="brand.neutral.900"
                                        style={{ textWrap: 'wrap' }}
                                        w="100%"
                                      >
                                        {isFieldNotObject(row.data) ? row.data : '--'}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                  </BaselaneResponsiveTableRow>
                                );
                              }}
                            />
                            <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                              <BaselaneResponsiveCellTitle
                                title="Landlord"
                                titleSize="headline-sm"
                                styles={{
                                  titleTextStyle: { color: 'brand.neutral.900' },
                                  p: 0,
                                }}
                                p={0}
                                configIndex={0}
                                colSpan="4" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                              />
                            </BaselaneResponsiveTableRow>
                            <BaselaneResponsiveTableRows
                              items={[
                                {
                                  heading: 'Name',
                                  data: address.rent?.landlord_name || '',
                                },
                                {
                                  heading: 'Email',
                                  data: address.rent?.landlord_email || '',
                                },
                                {
                                  heading: 'Phone',
                                  data: address.rent?.landlord_phone || '',
                                },
                                {
                                  heading: 'Address',
                                  data: address?.rent?.address || '',
                                },
                              ]}
                              customRow
                              renderItem={(row) => {
                                return (
                                  <BaselaneResponsiveTableRow
                                    chevronCellStyles={{ w: 0 }}
                                    contentContainerStyles={{ border: 'none' }}
                                  >
                                    <BaselaneResponsiveCell configIndex={0} p={0}>
                                      <Text as="span" textStyle="sm" color="brand.neutral.600">
                                        {row.heading}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                    <BaselaneResponsiveCell configIndex={1} p={0}>
                                      <Text
                                        textStyle="sm"
                                        color="brand.neutral.900"
                                        style={{ textWrap: 'wrap' }}
                                        w="100%"
                                      >
                                        {isFieldNotObject(row.data) ? row.data : '--'}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                  </BaselaneResponsiveTableRow>
                                );
                              }}
                            />
                          </Tbody>
                        </BaselaneResponsiveTable>
                      ),
                    },
                  ]}
                />
              );
            if (housingType === 'Other')
              return (
                <BaselaneCardStack
                  key={`${address}`}
                  size="md"
                  items={[
                    {
                      id: 'header',
                      content: (
                        <ReportHeader>
                          <HStack gap={2} justifyContent="space-between" width="100%">
                            <Box>{address.other?.address}</Box>
                            <Text whiteSpace="nowrap" color="brand.neutral.600" textStyle="sm">
                              {getDatesOfResidency(
                                address.other?.move_in_date,
                                address.other?.move_out_date,
                                address.other?.current_residence
                              )}
                            </Text>
                          </HStack>
                        </ReportHeader>
                      ),
                    },
                    {
                      id: 'data',
                      content: (
                        <BaselaneResponsiveTable
                          id="review-columns"
                          config={tableColumnConfig}
                          tableContainerStyles={{
                            overflowY: 'initial',
                            overflowX: 'initial',
                          }}
                        >
                          <Tbody>
                            <BaselaneResponsiveTableRows
                              items={[
                                {
                                  heading: 'Months of residence',
                                  data: calculateMonthsOfResidency(
                                    address.other?.move_in_date,
                                    address.other?.move_out_date
                                  ),
                                },
                                {
                                  heading: 'Housing type',
                                  data: address.housing_type,
                                },
                                {
                                  heading: 'Reason for leaving',
                                  data: address.other?.reason_for_leaving,
                                },
                              ]}
                              customRow
                              renderItem={(row) => {
                                return (
                                  <BaselaneResponsiveTableRow
                                    chevronCellStyles={{ w: 0 }}
                                    contentContainerStyles={{ border: 'none' }}
                                  >
                                    <BaselaneResponsiveCell configIndex={0} p={0}>
                                      <Text as="span" textStyle="sm" color="brand.neutral.600">
                                        {row.heading}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                    <BaselaneResponsiveCell configIndex={1} p={0}>
                                      <Text
                                        textStyle="sm"
                                        color="brand.neutral.900"
                                        style={{ textWrap: 'wrap' }}
                                        w="100%"
                                      >
                                        {isFieldNotObject(row.data) ? row.data : '--'}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                  </BaselaneResponsiveTableRow>
                                );
                              }}
                            />
                            <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                              <BaselaneResponsiveCellTitle
                                title="Address"
                                titleSize="headline-sm"
                                styles={{
                                  titleTextStyle: { color: 'brand.neutral.900' },
                                  p: 0,
                                }}
                                p={0}
                                configIndex={0}
                                colSpan="4" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                              />
                            </BaselaneResponsiveTableRow>
                            <BaselaneResponsiveTableRows
                              items={[{ heading: 'Street address', data: address?.other?.address }]}
                              customRow
                              renderItem={(row) => {
                                return (
                                  <BaselaneResponsiveTableRow
                                    chevronCellStyles={{ w: 0 }}
                                    contentContainerStyles={{ border: 'none' }}
                                  >
                                    <BaselaneResponsiveCell configIndex={0} p={0}>
                                      <Text as="span" textStyle="sm" color="brand.neutral.600">
                                        {row.heading}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                    <BaselaneResponsiveCell configIndex={1} p={0}>
                                      <Text
                                        textStyle="sm"
                                        color="brand.neutral.900"
                                        style={{ textWrap: 'wrap' }}
                                        w="100%"
                                      >
                                        {isFieldNotObject(row.data) ? row.data : '--'}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                  </BaselaneResponsiveTableRow>
                                );
                              }}
                            />
                          </Tbody>
                        </BaselaneResponsiveTable>
                      ),
                    },
                  ]}
                />
              );

            if (housingType === 'Own my home')
              return (
                <BaselaneCardStack
                  key={`${address}`}
                  size="md"
                  items={[
                    {
                      id: 'header',
                      content: (
                        <ReportHeader>
                          <HStack gap={2} justifyContent="space-between" width="100%">
                            <Box>{address.own_home?.address}</Box>
                            <Text whiteSpace="nowrap" color="brand.neutral.600" textStyle="sm">
                              {getDatesOfResidency(
                                address.own_home?.move_in_date,
                                address.own_home?.move_out_date,
                                address.own_home?.current_residence
                              )}
                            </Text>
                          </HStack>
                        </ReportHeader>
                      ),
                    },
                    {
                      id: 'data',
                      content: (
                        <BaselaneResponsiveTable
                          id="review-columns"
                          config={tableColumnConfig}
                          tableContainerStyles={{
                            overflowY: 'initial',
                            overflowX: 'initial',
                          }}
                        >
                          <Tbody>
                            <BaselaneResponsiveTableRows
                              items={[
                                {
                                  heading: 'Monthly mortage payment',
                                  data: formatCurrency(address.own_home?.monthly_mortgage_payment)
                                    .inDollars,
                                },
                                {
                                  heading: 'Months of residence',
                                  data: calculateMonthsOfResidency(
                                    address.own_home?.move_in_date,
                                    address.own_home?.move_out_date
                                  ),
                                },
                                {
                                  heading: 'Housing type',
                                  data: address.housing_type,
                                },
                                {
                                  heading: 'Reason for leaving',
                                  data: address.own_home?.reason_for_leaving || '',
                                },
                              ]}
                              customRow
                              renderItem={(row) => {
                                return (
                                  <BaselaneResponsiveTableRow
                                    chevronCellStyles={{ w: 0 }}
                                    contentContainerStyles={{ border: 'none' }}
                                  >
                                    <BaselaneResponsiveCell configIndex={0} p={0}>
                                      <Text as="span" textStyle="sm" color="brand.neutral.600">
                                        {row.heading}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                    <BaselaneResponsiveCell configIndex={1} p={0}>
                                      <Text
                                        textStyle="sm"
                                        color="brand.neutral.900"
                                        style={{ textWrap: 'wrap' }}
                                        w="100%"
                                      >
                                        {isFieldNotObject(row.data) ? row.data : '--'}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                  </BaselaneResponsiveTableRow>
                                );
                              }}
                            />
                            <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                              <BaselaneResponsiveCellTitle
                                title="Address"
                                titleSize="headline-sm"
                                styles={{
                                  titleTextStyle: { color: 'brand.neutral.900' },
                                  p: 0,
                                }}
                                p={0}
                                configIndex={0}
                                colSpan="4" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                              />
                            </BaselaneResponsiveTableRow>
                            <BaselaneResponsiveTableRows
                              items={[
                                { heading: 'Street address', data: address?.own_home?.address },
                              ]}
                              customRow
                              renderItem={(row) => {
                                return (
                                  <BaselaneResponsiveTableRow
                                    chevronCellStyles={{ w: 0 }}
                                    contentContainerStyles={{ border: 'none' }}
                                  >
                                    <BaselaneResponsiveCell configIndex={0} p={0}>
                                      <Text as="span" textStyle="sm" color="brand.neutral.600">
                                        {row.heading}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                    <BaselaneResponsiveCell configIndex={1} p={0}>
                                      <Text
                                        textStyle="sm"
                                        color="brand.neutral.900"
                                        style={{ textWrap: 'wrap' }}
                                        w="100%"
                                      >
                                        {isFieldNotObject(row.data) ? row.data : '--'}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                  </BaselaneResponsiveTableRow>
                                );
                              }}
                            />
                          </Tbody>
                        </BaselaneResponsiveTable>
                      ),
                    },
                  ]}
                />
              );

            return null;
          })}

        {reportData && reportData.length === 0 && (
          <BaselaneCardNew size="md" palette="dark" width="100%">
            <HStack spacing={2} width="100%">
              <Icon16Info />
              <Text color="brand.neutral.700" textStyle="sm">
                There is no information to display for this report.
              </Text>
            </HStack>
          </BaselaneCardNew>
        )}
      </VStack>
      <Outlet />
    </>
  );
};

export default HousingHistory;
