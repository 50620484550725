import React, { useContext } from 'react';
import {
  ChakraProvider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import habitatTheme from '@core/themeHabitat';
import UserAccessContext from '@contexts/UserAccessContext';
import { IconEdit } from '@icons';
import { formInputStyles, formLabelStyles, formErrorStyles } from '@shared/styles/input.style';
import { BaselaneButtonIcon } from '@shared/components';
import {
  CancelEditBtn,
  SaveChangesBtn,
} from '@core/components/NativeBankingPage/components/IndividualInputEditButtons';
import { lastFourDigits } from '@core/utils/formatAccountNumber';

type AccountNameProps = {
  accountSubType: 'checking' | 'savings',
  editModeValues: Object,
  accountNumber: string,
  handleEditModeToggleOrSave: Function,
  handleOnEditChange: Function,
  isDisabled: boolean,
};

const AccountName = ({
  accountSubType,
  editModeValues,
  accountNumber,
  handleEditModeToggleOrSave,
  handleOnEditChange,
  isDisabled,
}: AccountNameProps) => {
  const { authorizedForBanking } = useContext(UserAccessContext);

  return (
    <ChakraProvider theme={habitatTheme}>
      <Stack gap={0.75}>
        {editModeValues?.nickname?.isEdit && authorizedForBanking ? (
          <FormControl isInvalid={!editModeValues.nickname.value} isRequired mb="0">
            <FormLabel htmlFor="nickName" {...formLabelStyles.xs}>
              Account nickname
            </FormLabel>
            <HStack spacing={1}>
              <Input
                id="nickname.value"
                name="nickname"
                value={editModeValues.nickname.value}
                onChange={handleOnEditChange}
                {...formInputStyles}
                bg="brand.neutral.white"
                w="100%"
                isRequired
              />
              <CancelEditBtn
                variant="outline"
                palette="neutral"
                size="lg"
                isDisabled={isDisabled}
                onClick={() => handleEditModeToggleOrSave('nickname', false)}
              />
              <SaveChangesBtn
                id="edit-baselane-bank-nickname-save-button"
                variant="filled"
                palette="primary"
                size="lg"
                isDisabled={!editModeValues.nickname.value}
                isLoading={isDisabled}
                onClick={() => handleEditModeToggleOrSave('nickname', false, true)}
              />
            </HStack>
            <FormErrorMessage {...formErrorStyles}>
              <Text>Please enter account name</Text>
            </FormErrorMessage>
          </FormControl>
        ) : (
          <HStack justifyContent="space-between">
            <Stack gap="0">
              <Text textStyle="headline-sm" color="brand.neutral.700" w="100%">
                {editModeValues.nickname.value}
              </Text>
              <Text textStyle="xs" color="brand.neutral.600">
                <Text as="span" textTransform="capitalize">
                  {accountSubType}
                </Text>{' '}
                account - {lastFourDigits(accountNumber)}
              </Text>
            </Stack>
            {authorizedForBanking && (
              <BaselaneButtonIcon
                icon={<IconEdit />}
                variant="transparent"
                palette="neutral"
                size="sm"
                isDisabled={isDisabled}
                id="edit-baselane-bank-nickname"
                onClick={() => handleEditModeToggleOrSave('nickname', true)}
              />
            )}
          </HStack>
        )}
      </Stack>
    </ChakraProvider>
  );
};

export default AccountName;
