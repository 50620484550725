import React, { useRef, useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import MaskedInput from 'react-text-mask';
import { Formik } from 'formik';
import {
  Box,
  HStack,
  Input,
  Select,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { CREATE_MANUAL_BANK_ACCOUNT } from '@core/apollo/queries';
import { USER_PROFILE_UPDATE_ACCOUNTS } from '@core/constants/routes';
import FormLabelWithTooltip from '@pages/LeasesPage/LeaseSection/components/FormLabelWithTooltip';
import { numbersOnlyMask } from '@core/utils/masks';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import BanksContext from '@contexts/BanksContext';
import {
  newDrawerBodyStyles,
  newDrawerFooterStyles,
} from '@shared/components/BaselaneDrawer/styles/drawer.style';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import BaselaneButton from '../BaselaneButton';
import BaselaneDrawer from '../BaselaneDrawer';
import BaselaneMessageCard from '../BaselaneCard/BaselaneMessageCard';
import UnsavedChangesAlert from '../UnsavedChangesAlert';
import BaselaneFormHelperText from '../BaselaneForm/BaselaneFormHelperText';
import BaselaneRoutingNumberField from '../BaselaneRoutingNumberField';
import {
  accountHolderTypeOptions,
  accountTypeOptions,
  accountOwnershipTypeOptions,
  getInitialValues,
  getPurpose,
  addAccountManuallyFormValidation,
  ERROR_MESSAGES,
} from './helpers/addAccountManuallyDrawer.helpers';
import {
  titleStyles,
  descriptionStyles,
  formContainerStyles,
  fieldContainerStyles,
  formLabelWithTooltipStyles,
  formTooltipTextStyles,
  spinnerStyles,
} from './styles/addAccountManuallyDrawer.styles';

type AddAccountManuallyDrawerProps = {
  addAccountManuallyDrawerRef: any,
  handleAddAccountManuallyOnSubmit?: Function,
  handleAddAccountManuallyError?: Function,
  handleAddAccountManuallySuccess: Function,
  from: { section: String },
};

const AddAccountManuallyDrawer = ({
  addAccountManuallyDrawerRef,
  handleAddAccountManuallyOnSubmit = () => {},
  handleAddAccountManuallySuccess = () => {},
  handleAddAccountManuallyError,
  from,
}: AddAccountManuallyDrawerProps) => {
  const { isMinXL, isMax576, isMax768 } = useBreakPoints();

  // New Design drawer
  let responsiveStyleSize = 'min769';
  if (isMax576) responsiveStyleSize = 'max576';
  if (!isMax576 && isMax768) responsiveStyleSize = 'min577max768';

  // Form Ref
  const addAccountManuallyFormikRef = useRef();

  // Unsaved Alert State
  const { isOpen: isDrawerAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  // State vars
  const [hasAPIError, setHasAPIError] = useState(false);
  const [apiErrorTitle, setAPIErrorTitle] = useState('');
  const [apiErrorMessage, setAPIErrorMessage] = useState('');
  const [bankInfoAPIError, setBankInfoAPIError] = useState({ hasError: false, message: '' });

  // Context
  const { banks } = useContext(BanksContext);

  // Local vars
  const {
    DUPLICATE_BANK_ACCOUNT_TITLE,
    DUPLICATE_BANK_ACCOUNT,
    INVALID_INFORMATION_TITLE,
    INVALID_INFORMATION,
  } = ERROR_MESSAGES;

  const { section } = from;
  const showAccountOwnershipType = ['add_funds', 'third_party_accounts'].includes(section);
  const purpose = getPurpose(section);

  // Mutation
  const [createManualBankAccount] = useMutation(CREATE_MANUAL_BANK_ACCOUNT);

  // Toaster
  const toast = useToast();
  const showSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: `Your account has been added successfully.`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const handleCloseAddAccountManuallyDrawer = () => {
    addAccountManuallyDrawerRef?.current?.close();
    setHasAPIError(false);
    setAPIErrorTitle('');
    setAPIErrorMessage('');
    setBankInfoAPIError({ hasError: false, message: '' });
  };

  const checkIsDuplicateAccount = (errors) => {
    return errors?.find(
      (error) => error?.extensions?.exception?.response?.messageCode === 'DUPLICATE_BANK_ACCOUNT'
    );
  };

  const handleAPIError = (error) => {
    if (checkIsDuplicateAccount(error)) {
      setAPIErrorTitle(DUPLICATE_BANK_ACCOUNT_TITLE);
      setAPIErrorMessage(DUPLICATE_BANK_ACCOUNT);
    } else {
      setAPIErrorTitle(INVALID_INFORMATION_TITLE);
      setAPIErrorMessage(INVALID_INFORMATION);
    }
    setHasAPIError(true);
    handleAddAccountManuallyError();
  };

  const handleAPISuccess = (newAccount) => {
    if (!banks || banks?.length === 0) {
      // only trigger on first bank account
      sendSegmentEvent('any_bank_added_fe', {
        title: document.title,
      });
      // reddit tracking
      window?.rdt('track', 'AddToCart');
    }

    showSuccessToast();
    handleCloseAddAccountManuallyDrawer();
    handleAddAccountManuallySuccess(newAccount);
  };

  const handleBankInfoAPIError = (errors) => {
    const isRentCollectionNotSupported = errors.find((error) => {
      const { messageCode } = error?.extensions?.exception ?? {};
      return messageCode === 'BANK_NOT_SUPPORTED_FOR_RENT_COLLECTION';
    });

    if (isRentCollectionNotSupported) {
      setBankInfoAPIError({
        hasError: true,
        message: 'Unfortunately, this institution cannot be used to collect rent with Baselane.',
      });
    }
  };

  const handleBankInfoAPISuccess = () => {
    setBankInfoAPIError({
      hasError: false,
      message: null,
    });
  };

  const handleOnSubmit = (formValues, actions) => {
    handleAddAccountManuallyOnSubmit();

    const variables = {
      ...formValues,
      accountType: formValues.accountType === 'not_sure' ? 'checking' : formValues.accountType,
      purpose:
        formValues.accountOwnershipType === 'THIRD_PARTY'
          ? getPurpose('third_party_accounts')
          : purpose,
      ...((section === 'rent_collection' || section === USER_PROFILE_UPDATE_ACCOUNTS) && {
        accountOwnershipType: 'SELF',
      }),
      ...(section === 'third_party_accounts' && { accountOwnershipType: 'THIRD_PARTY' }),
      ...(section === 'my_accounts' && { accountOwnershipType: formValues.accountOwnershipType }),
    };

    createManualBankAccount({
      variables,
    })
      .then((res) => {
        actions.setSubmitting(false);
        if (res?.errors) {
          handleAPIError(res?.errors);
        } else {
          handleAPISuccess(res?.data?.createManualBankAccount);
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        handleAPIError(error);
        setHasAPIError(true);
      });
  };

  const handleCloseWithoutSaving = () => {
    const isDirty = addAccountManuallyFormikRef.current?.dirty;
    if (isDirty) {
      onAlertOpen();
    } else {
      handleCloseAddAccountManuallyDrawer();
    }
  };

  const handleAlertContinueClick = () => {
    onAlertClose();
    handleCloseAddAccountManuallyDrawer();
  };

  const url = window.location.href;

  const filteredAccountTypeOptions = accountTypeOptions.filter((option) => {
    if (url.includes('unified_rent_collection') && option.id === 'loan') {
      return false; // Exclude the "Loan" option
    }
    return true;
  });

  const { DrawerBody, DrawerFooter } = BaselaneDrawer;

  return (
    <>
      <BaselaneDrawer
        ref={addAccountManuallyDrawerRef}
        title="Add Bank Account Manually"
        closeEvent={handleCloseWithoutSaving}
        onClose={handleCloseWithoutSaving}
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        newDesignDrawer
        newDrawerUseDefaultBodyFooter
      >
        <Formik
          innerRef={addAccountManuallyFormikRef}
          validate={(values) => addAccountManuallyFormValidation(values, bankInfoAPIError)}
          onSubmit={handleOnSubmit}
          initialValues={getInitialValues(showAccountOwnershipType, section)}
          validateOnBlur
          enableReinitialize
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            errors,
            touched,
            dirty,
            isSubmitting,
            isValid,
          }) => (
            <>
              <DrawerBody {...newDrawerBodyStyles[responsiveStyleSize]}>
                {/* Error Message Banner */}
                {hasAPIError && (
                  <BaselaneMessageCard
                    title={apiErrorTitle}
                    isVertical
                    iconName="close"
                    iconColor="red"
                    borderColor="red"
                    text={apiErrorMessage}
                    containerStyles={{ w: '100%', mb: '32px' }}
                    iconContainerStyles={{ w: '20px', ml: '7px', mr: '10px' }}
                  />
                )}

                <Text {...titleStyles}>Enter bank account information</Text>
                <Text {...descriptionStyles}>
                  Enter the bank account type, name, routing & account numbers to add it manually.
                </Text>

                <Box {...formContainerStyles}>
                  {/* Account Ownership */}
                  {showAccountOwnershipType && (
                    <FormControl
                      {...fieldContainerStyles}
                      mb="24px"
                      isInvalid={errors.accountOwnershipType && touched.accountOwnershipType}
                    >
                      <FormLabel
                        htmlFor="accountOwnershipType"
                        {...{ ...formLabelStyles.xs, ...formLabelWithTooltipStyles }}
                      >
                        Who are you paying?
                      </FormLabel>

                      <Select
                        {...formInputStyles}
                        id="accountOwnershipType"
                        name="accountOwnershipType"
                        value={values.accountOwnershipType}
                        onChange={(e) => {
                          setFieldTouched('accountOwnershipType');
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        disabled={section === 'third_party_accounts'}
                      >
                        {!values.accountOwnershipType ? <option>Select type</option> : null}
                        {accountOwnershipTypeOptions.map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            defaultValue={values.accountOwnershipType}
                          >
                            {option.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage {...formErrorStyles}>
                        {errors.accountOwnershipType}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                  {/* Account Holder Type */}
                  <FormControl
                    {...fieldContainerStyles}
                    mb="24px"
                    isInvalid={errors.accountHolderType && touched.accountHolderType}
                  >
                    <FormLabel
                      htmlFor="accountHolderType"
                      {...{ ...formLabelStyles.xs, ...formLabelWithTooltipStyles }}
                    >
                      Account Holder Type
                    </FormLabel>

                    <Select
                      {...formInputStyles}
                      id="accountHolderType"
                      name="accountHolderType"
                      value={values.accountHolderType}
                      onChange={(e) => {
                        setFieldTouched('accountHolderType');
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      {!values.accountHolderType ? (
                        <option>Select account holder type</option>
                      ) : null}
                      {accountHolderTypeOptions.map((option) => (
                        <option
                          key={option.id}
                          value={option.id}
                          defaultValue={values.accountHolderType}
                        >
                          {option.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage {...formErrorStyles}>
                      {errors.accountHolderType}
                    </FormErrorMessage>
                  </FormControl>

                  {/* Account Type */}
                  <FormControl
                    {...fieldContainerStyles}
                    mb="24px"
                    isInvalid={errors.accountType && touched.accountType}
                  >
                    <FormLabel
                      htmlFor="accountType"
                      {...{ ...formLabelStyles.xs, ...formLabelWithTooltipStyles }}
                    >
                      Account Type
                    </FormLabel>

                    <Select
                      {...formInputStyles}
                      id="accountType"
                      name="accountType"
                      value={values.accountType}
                      onChange={(e) => {
                        setFieldTouched('accountType');
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      {!values.accountType ? <option>Select account type</option> : null}
                      {filteredAccountTypeOptions.map((option) => (
                        <option key={option.id} value={option.id} defaultValue={values.accountType}>
                          {option.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage {...formErrorStyles}>{errors.accountType}</FormErrorMessage>
                  </FormControl>

                  {/* Account Holder's Name */}
                  <FormControl
                    {...fieldContainerStyles}
                    mb="24px"
                    isInvalid={errors.fullName && touched.fullName}
                  >
                    <FormLabelWithTooltip
                      htmlFor="fullName"
                      text="Account Holder's Name"
                      tooltipText="Enter the account holder's full name for your bank account. It may be an individual name or a business name."
                      textStyles={formLabelWithTooltipStyles}
                      tooltipTextStyles={formTooltipTextStyles}
                    />

                    <Input
                      {...formInputStyles}
                      id="fullName"
                      name="fullName"
                      value={values.fullName}
                      type="text"
                      onChange={(e) => {
                        setFieldTouched('fullName');
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      maxLength={50}
                    />
                    <FormErrorMessage {...formErrorStyles}>{errors.fullName}</FormErrorMessage>
                  </FormControl>

                  {/* Routing Number */}
                  <HStack
                    flexDir={isMinXL ? 'column' : 'row'}
                    gap={!isMinXL && '16px'}
                    alignItems="flex-start"
                  >
                    <FormControl
                      {...fieldContainerStyles}
                      mb="24px"
                      isInvalid={errors.routingNumber && touched.routingNumber}
                    >
                      <FormLabelWithTooltip
                        htmlFor="routingNumber"
                        text="Routing Number"
                        tooltipText="You can find your routing number at the bottom of a check; there you will see a string of numbers. The routing number is the first nine digits. The following digits are the account number, which can vary in length. The last four digits are the check number."
                        textStyles={formLabelWithTooltipStyles}
                        tooltipTextStyles={formTooltipTextStyles}
                      />

                      <BaselaneRoutingNumberField
                        {...formInputStyles}
                        id="routingNumber"
                        routingNumberFieldName="routingNumber"
                        bankNameFieldName="bankName"
                        accountPurpose={purpose}
                        spinnerStyles={spinnerStyles}
                        setBankInfoError={handleBankInfoAPIError}
                        handleBankInfoAPISuccess={handleBankInfoAPISuccess}
                      />
                      {values?.bankName && values?.bankName !== '' && !errors.routingNumber ? (
                        <BaselaneFormHelperText type="assistive" customStyles={{ mt: 1 }}>
                          {values?.bankName}
                        </BaselaneFormHelperText>
                      ) : null}
                      <FormErrorMessage {...formErrorStyles}>
                        {errors.routingNumber}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      {...fieldContainerStyles}
                      mb="24px !important"
                      ml="0 !important"
                      isInvalid={errors.confirmRoutingNumber && touched.confirmRoutingNumber}
                    >
                      <FormLabel
                        htmlFor="confirmRoutingNumber"
                        {...{ ...formLabelStyles.xs, ...formLabelWithTooltipStyles }}
                      >
                        Confirm Routing Number
                      </FormLabel>

                      <Input
                        {...formInputStyles}
                        id="confirmRoutingNumber"
                        name="confirmRoutingNumber"
                        value={values.confirmRoutingNumber}
                        maxLength="9"
                        type="text"
                        as={MaskedInput}
                        mask={numbersOnlyMask}
                        onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                        onChange={(e) => {
                          setFieldTouched('confirmRoutingNumber');
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      <FormErrorMessage {...formErrorStyles}>
                        {errors.confirmRoutingNumber}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>

                  {/* Account Number */}
                  <HStack
                    flexDir={isMinXL ? 'column' : 'row'}
                    gap={!isMinXL && '16px'}
                    alignItems="flex-start"
                  >
                    <FormControl
                      {...fieldContainerStyles}
                      mb="24px"
                      isInvalid={errors.accountNumber && touched.accountNumber}
                    >
                      <FormLabelWithTooltip
                        htmlFor="accountNumber"
                        text="Account Number"
                        tooltipText="You can find your account number at the bottom of a check; there you will see a string of numbers. The routing number is the first nine digits. The following digits are the account number, which can vary in length. The last four digits are the check number."
                        textStyles={formLabelWithTooltipStyles}
                        tooltipTextStyles={formTooltipTextStyles}
                      />

                      <Input
                        {...formInputStyles}
                        id="accountNumber"
                        name="accountNumber"
                        value={values.accountNumber}
                        type="text"
                        as={MaskedInput}
                        mask={numbersOnlyMask}
                        onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                        onChange={(e) => {
                          setFieldTouched('accountNumber');
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      <FormErrorMessage {...formErrorStyles}>
                        {errors.accountNumber}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      {...fieldContainerStyles}
                      mb="24px !important"
                      ml="0 !important"
                      isInvalid={errors.confirmAccountNumber && touched.confirmAccountNumber}
                    >
                      <FormLabel
                        htmlFor="confirmAccountNumber"
                        {...{ ...formLabelStyles.xs, ...formLabelWithTooltipStyles }}
                      >
                        Confirm Account Number
                      </FormLabel>

                      <Input
                        {...formInputStyles}
                        id="confirmAccountNumber"
                        name="confirmAccountNumber"
                        value={values.confirmAccountNumber}
                        type="text"
                        as={MaskedInput}
                        mask={numbersOnlyMask}
                        onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                        onChange={(e) => {
                          setFieldTouched('confirmAccountNumber');
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      <FormErrorMessage {...formErrorStyles}>
                        {errors.confirmAccountNumber}
                      </FormErrorMessage>
                    </FormControl>
                  </HStack>

                  {/* Account Nickname */}
                  <FormControl
                    {...fieldContainerStyles}
                    mb="24px"
                    isInvalid={errors.nickName && touched.nickName}
                  >
                    <FormLabel
                      htmlFor="nickName"
                      {...{ ...formLabelStyles.xs, ...formLabelWithTooltipStyles }}
                    >
                      Account Nickname
                    </FormLabel>

                    <Input
                      {...formInputStyles}
                      id="nickName"
                      name="nickName"
                      value={values.nickName}
                      type="text"
                      onChange={(e) => {
                        setFieldTouched('nickName');
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage {...formErrorStyles}>{errors.nickName}</FormErrorMessage>
                  </FormControl>
                </Box>
              </DrawerBody>

              {/* Desktop Footer */}
              <DrawerFooter gap="16px" {...newDrawerFooterStyles[responsiveStyleSize]}>
                {!isMinXL && (
                  <BaselaneButton
                    id="cancel-add-manual-bank-account-footer-button"
                    variant="outline"
                    palette="neutral"
                    size="md"
                    onClick={handleCloseWithoutSaving}
                  >
                    Cancel
                  </BaselaneButton>
                )}
                <BaselaneButton
                  id="add-manual-bank-account-footer-button"
                  variant="filled"
                  palette="primary"
                  size="md"
                  onClick={handleSubmit}
                  isDisabled={!dirty || !isValid}
                  isLoading={isSubmitting}
                  styles={{ w: '100%' }}
                >
                  Add Account
                </BaselaneButton>
              </DrawerFooter>
            </>
          )}
        </Formik>
      </BaselaneDrawer>

      <UnsavedChangesAlert
        {...{
          isDrawerAlertOpen,
          onAlertClose,
          onAlertOpen,
          onAlertContinue: (e) => handleAlertContinueClick(),
        }}
      />
    </>
  );
};

export default AddAccountManuallyDrawer;
