import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import {
  panelDescriptionStyles,
  panelFormStyles,
  panelHeaderContainerStyles,
  panelHeaderStyles,
  panelHeaderWrapperStyles,
  tabPanelStyles,
} from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';
import { FormDescription, FormTitle } from '@shared/components/BaselaneFlow';

import { SocialSecurityNumberForm } from '../forms';
import FooterWrapper from '../FooterWrapper';

const SSNRootView = () => {
  const footerWrapperProps = useOutletContext();

  return (
    <Box {...tabPanelStyles}>
      <Box {...panelHeaderWrapperStyles()}>
        <FormTitle
          title="Social Security Number"
          textStyles={panelHeaderStyles(isMobile)}
          containerStyles={panelHeaderContainerStyles(isMobile)}
        />
        <FormDescription styles={panelDescriptionStyles(isMobile)}>
          We need to verify your identity with your Social Security number. This won’t affect your
          credit score.
        </FormDescription>
      </Box>
      <Box {...panelFormStyles}>
        <SocialSecurityNumberForm
          {...{
            socialSecurityNumberRef: footerWrapperProps.formRefs[3],
            formData: footerWrapperProps.formData,
          }}
        />
      </Box>
      <FooterWrapper {...footerWrapperProps} />
    </Box>
  );
};

export default SSNRootView;
