/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Divider, Text, Tbody, Thead } from '@chakra-ui/react';
import {
  BaselaneCardStack,
  BaselaneResponsiveCell,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
} from '@shared/components';
import { formatDate } from '@core/utils/formatDate';
import { isFieldNotObject } from '../../helpers/tenantScreening.helpers';
import ReportHeader from '../../components/ReportHeader';
import Label from './shared/Label';
import Cell from './shared/Cell';
import Value from './shared/Value';

const AccountSummary = ({ accounts = [], collections = [], bankruptcyDetails = [] }) => {
  const tableColumnConfig = {
    columns: [
      {
        isSortable: false,
        styles: {
          w: { base: '50%', sm: '320px' },
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
          overflow: 'visible',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          verticalAlign: 'top',
        },
      },
      {
        isSortable: false,
        styles: {
          w: '100%',
          overflow: 'visible',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
    ],
  };

  const accountsSumTime = accounts.reduce((acc, account) => {
    if (account.date_closed) {
      return acc;
    }
    const accountOpenDate = new Date(account.date_opened);
    const diffTime = Math.abs(new Date() - accountOpenDate);
    return acc + diffTime;
  }, 0);

  const averageAccountAge = new Date(accountsSumTime / accounts.length);
  const years = averageAccountAge.getFullYear() - 1970;
  const months = averageAccountAge.getMonth();
  const days = averageAccountAge.getDate();

  const sortedAccountsByDateOpened = accounts.sort(
    (a, b) => new Date(a.date_opened) - new Date(b.date_opened)
  );

  const oldestAccount = sortedAccountsByDateOpened[0];
  const mostRecentAccount = sortedAccountsByDateOpened[sortedAccountsByDateOpened.length - 1];

  const numberOfRevolvingAccounts = accounts.filter(
    (account) => account.portfolio_type?.code === 'R'
  ).length;

  const numberOfInstallmentAccounts = accounts.filter(
    (account) => account.portfolio_type?.code === 'I'
  ).length;

  const numberOfMortgageAccounts = accounts.filter(
    (account) => account.portfolio_type?.code === 'M'
  ).length;

  const numberOfOtherAccounts =
    accounts.length -
    numberOfRevolvingAccounts -
    numberOfInstallmentAccounts -
    numberOfMortgageAccounts;

  return (
    <BaselaneCardStack
      id="account-summary-card"
      size="md"
      items={[
        {
          id: 'header',
          content: <ReportHeader>Account summary</ReportHeader>,
        },
        {
          id: 'data',
          content: (
            <Box width="100%">
              <Box
                display="flex"
                flexDirection={{ base: 'column', sm: 'row' }}
                paddingY={2}
                gap={3}
              >
                <Cell>
                  <Label>Avg account age</Label>
                  <Value>
                    {years > 0 && `${years} year${years > 1 ? 's' : ''}`}
                    {months > 0 && `, ${months} month${months > 1 ? 's' : ''}`}
                    {days > 0 && `, ${days} day${days > 1 ? 's' : ''}`}
                  </Value>
                </Cell>
                <Cell>
                  <Label>Oldest account</Label>
                  <Value>{oldestAccount?.account_name}</Value>
                  <Text textStyle="sm" color="brand.neutral.600" mt={0.5}>
                    {formatDate(oldestAccount?.date_opened, 'MMM D, yyyy')}
                  </Text>
                </Cell>
                <Cell>
                  <Label>Most recent account</Label>
                  <Value>{mostRecentAccount?.account_name}</Value>
                  <Text textStyle="sm" color="brand.neutral.600" mt={0.5}>
                    {formatDate(mostRecentAccount?.date_opened, 'MMM D, yyyy')}
                  </Text>
                </Cell>
                <Cell>
                  <Label>Number of accounts</Label>
                  <Value>{accounts.length}</Value>
                </Cell>
              </Box>
              <Divider marginY={1} />
              <Box
                display="flex"
                flexDirection={{ base: 'column', sm: 'row' }}
                paddingY={2}
                gap={3}
              >
                <Cell>
                  <Label>Revolving</Label>
                  <Value>{numberOfRevolvingAccounts}</Value>
                </Cell>
                <Cell>
                  <Label>Installments</Label>
                  <Value>{numberOfInstallmentAccounts}</Value>
                </Cell>
                <Cell>
                  <Label>Mortgage</Label>
                  <Value>{numberOfMortgageAccounts}</Value>
                </Cell>
                <Cell>
                  <Label>Other</Label>
                  <Value>{numberOfOtherAccounts}</Value>
                </Cell>
              </Box>
            </Box>
          ),
        },
        {
          id: 'potential-negative-info',
          content: (
            <BaselaneResponsiveTable
              id="potential-negative-info-table"
              config={tableColumnConfig}
              maxW="600px"
              tableContainerStyles={{
                overflowY: 'initial',
                overflowX: 'initial',
              }}
            >
              <Thead>
                <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                  <BaselaneResponsiveCellTitle
                    title="Potential negative info"
                    titleSize="headline-sm"
                    styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                    p={0}
                    configIndex={0}
                    colSpan="2"
                  />
                </BaselaneResponsiveTableRow>
              </Thead>
              <Tbody>
                <BaselaneResponsiveTableRows
                  items={[
                    {
                      heading: '30 day delinquencies',
                      data: accounts.reduce(
                        (acc, account) => acc + account.thirty_days_delinquency,
                        0
                      ),
                    },
                    {
                      heading: '60 day delinquencies',
                      data: accounts.reduce(
                        (acc, account) => acc + account.sixty_days_delinquency,
                        0
                      ),
                    },
                    {
                      heading: '90 day delinquencies',
                      data: accounts.reduce(
                        (acc, account) => acc + account.ninety_days_delinquency,
                        0
                      ),
                    },
                    { heading: 'Bankruptcies', data: bankruptcyDetails.length },
                    { heading: 'Collections', data: collections.length },
                  ]}
                  customRow
                  renderItem={(row) => {
                    return (
                      <BaselaneResponsiveTableRow
                        key={row.heading}
                        chevronCellStyles={{ w: 0 }}
                        contentContainerStyles={{ border: 'none' }}
                      >
                        <BaselaneResponsiveCell configIndex={0} p={0}>
                          <Text as="span" textStyle="sm" color="brand.neutral.700">
                            {row.heading}
                          </Text>
                        </BaselaneResponsiveCell>
                        <BaselaneResponsiveCell configIndex={1} p={0}>
                          <Text textStyle="sm">{isFieldNotObject(row.data) ? row.data : '--'}</Text>
                        </BaselaneResponsiveCell>
                      </BaselaneResponsiveTableRow>
                    );
                  }}
                />
              </Tbody>
            </BaselaneResponsiveTable>
          ),
        },
      ]}
    />
  );
};

export default AccountSummary;
