import React, { useContext, useState } from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';

import { BaselaneAlertNew } from '@shared/components';
import { Icon16ChevronRight, Icon16Info } from '@icons/16px';
import UserContext from '@contexts/UserContext';
import UpdateBankAccountDrawer from '@pages/LeasesPage/panels/components/UpdateBankAccountDrawer';
import { GET_TENANT_PROFILE } from '@pages/LeasesPage/queries';
import { GET_LEASE_BANKS } from '@core/apollo/queries';
import historyStateStorage from '@core/storage/historyStateStorage';
import { DASHBOARD } from '@routes';
import useBreakPoints from '@core/hooks/useBreakPoints';

type UnableToReceiveFundsAlertBannerProps = {
  invoiceSummaryData: Object,
  refetchInvoiceSummary: Function,
};

const UnableToReceiveFundsAlertBanner = ({
  invoiceSummaryData,
  refetchInvoiceSummary,
}: UnableToReceiveFundsAlertBannerProps) => {
  const { isMin768 } = useBreakPoints();

  const { user } = useContext(UserContext);
  const [receivingBankAccountId, setReceivingBankAccountId] = useState(null);

  const {
    invoiceFailedSummary = {},
    invoiceProcessingSummary = {},
    payoutFailedBankAccounts = [],
  } = invoiceSummaryData?.invoiceSummary ?? {};

  const hasUserLevelStripeError =
    (invoiceFailedSummary?.count > 0 || invoiceProcessingSummary?.count > 0) &&
    user?.stripeAccount?.payoutEnabled === false;

  // Get Tenant details
  const { data: tenantData } = useQuery(GET_TENANT_PROFILE);

  const variables = {
    isConnectedBank: true,
    accountType: 'depository',
    isConnectedAccount: true,
    accountStatus: 'Open',
    accountOwnershipType: 'SELF',
  };
  const fetchPolicy = 'network-only';
  const nextFetchPolicy = 'cache-first';
  const queryOptions = {
    fetchPolicy,
    nextFetchPolicy,
    variables,
  };

  const { data: banksData, refetch: refetchBankAccounts } = useQuery(GET_LEASE_BANKS, queryOptions);

  const payoutFailedInvoicesFilteredArray = payoutFailedBankAccounts.filter(
    (pfba) => pfba?.count > 0
  );
  return (
    <>
      {!hasUserLevelStripeError && payoutFailedInvoicesFilteredArray?.length > 0 && (
        <Stack spacing={1} className="here">
          {payoutFailedBankAccounts?.map((failedAcc) => {
            const { id, nickName, payoutFailedInvoice } = failedAcc ?? {};

            return (
              payoutFailedInvoice?.count > 0 && (
                <BaselaneAlertNew
                  key={id}
                  variant="danger"
                  visual="icon"
                  iconName={Icon16Info}
                  isVertical={!isMin768}
                  actionProps={{
                    actionType: 'button',
                    primary: {
                      label: 'Review account',
                      size: 'xs',
                      onClick: () => {
                        historyStateStorage.write({ from: DASHBOARD });
                        setReceivingBankAccountId(id);
                      },
                      styles: {
                        variant: 'transparent',
                        palette: 'neutral',
                        rightIcon: <Icon16ChevronRight />,
                        pullRight: true,
                      },
                    },
                  }}
                  body={
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Text textStyle="sm-heavy" textAlign="left">
                        {nickName} is unable to receive funds
                      </Text>
                    </Stack>
                  }
                />
              )
            );
          })}
        </Stack>
      )}

      {/* Update Receiving BA Drawer */}
      <UpdateBankAccountDrawer
        bankAccountId={receivingBankAccountId}
        setBankAccountId={setReceivingBankAccountId}
        tenantData={tenantData}
        banksData={banksData || {}}
        refetchInvoiceSummary={refetchInvoiceSummary}
        refetchBankAccounts={refetchBankAccounts || {}}
      />
    </>
  );
};

export default UnableToReceiveFundsAlertBanner;
