import React from 'react';
import { Heading, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { BaselaneButton, BaselaneCardNew } from '@core/components/Shared/components';
import { Icon24Details } from '@core/components/Icons/24px';
import { ADD_ACCOUNT } from '@core/constants/routes';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

const EmptyState = (): any => {
  const navigate = useNavigate();

  const handleOpenAddAccount = () => {
    sendSegmentEvent('dashboard_cashflow_emptystate_click_add_account');

    navigate({
      pathname: ADD_ACCOUNT,
    });
  };

  return (
    <BaselaneCardNew
      w="full"
      h="auto"
      alignItems="center"
      flexDirection="column"
      position="relative"
      p={{ md: 3, base: 2 }}
    >
      <VStack my={{ md: 4, base: 0 }} gap={{ base: 1 }}>
        <Icon24Details />
        <Heading mt={1} size="headline-lg">
          Automated bookkeeping & tax prep
        </Heading>
        <Text maxW="480px" textStyle="sm">
          All your rental transactions in one platform. Get full visibility of property performance
          and save time with on demand financial & tax reporting.{' '}
        </Text>
        <BaselaneButton
          variant="tonal"
          mt={2}
          palette="primary"
          size="lg"
          onClick={handleOpenAddAccount}
        >
          Add bank account
        </BaselaneButton>
      </VStack>
    </BaselaneCardNew>
  );
};

export default EmptyState;
