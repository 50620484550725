/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { reactNodeEnv } from '@core/constants/envVars';
import RocketDocument from './RocketDocument';
import { modalStyles } from './styles/rocketLawyeriFrame.styles';

type RocketLawyeriFrameProps = {
  showiFrame: Boolean,
  selectedPropertyDoc: Object,
  isDocReadyToSign: Boolean,
  setIsDocReadyToSign: Function,
  handleCloseiFrame: Function,
};

const RocketLawyeriFrame = ({
  showiFrame,
  selectedPropertyDoc,
  isDocReadyToSign,
  setIsDocReadyToSign,
  handleCloseiFrame,
}: RocketLawyeriFrameProps) => {
  const { binderId, serviceToken, interviewId } = selectedPropertyDoc ?? {};
  const environment =
    reactNodeEnv === 'production' || reactNodeEnv === 'pre-prod' || reactNodeEnv === 'hotfix'
      ? 'live'
      : 'sandbox';
  const { header, closeBtn, content, iframe } = modalStyles ?? {};

  const RLUrl =
    environment === 'sandbox'
      ? `https://document-manager.sandbox.rocketlawyer.com/${binderId}?advancedEdit=enabled&documentFromInterview=true#serviceToken=${serviceToken}`
      : `https://document-manager.rocketlawyer.com/${binderId}?advancedEdit=enabled&documentFromInterview=true#serviceToken=${serviceToken}`;

  const [isEdit, setIsEdit] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  /**
   * NOTE: in order to update specific styles of Rocket Document, we need to fill in a google doc form to request the changes.
   * https://docs.google.com/forms/d/e/1FAIpQLSddJPPg0onclKYf2IIyRehCYwlTtlcogXXXxo0ZlwtZLd3ZZQ/viewform?fbzx=5836941555539130795
   */

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin.includes('rocketlawyer.com')) {
        if (event.data.action === 'changeAnswers') {
          setIsEdit(true);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [iframeLoaded]);

  return (
    <Modal
      isOpen={showiFrame}
      onClose={handleCloseiFrame}
      size="full"
      padding={0}
      overflow="hidden"
    >
      <ModalOverlay />
      <ModalContent {...content} overflow="hidden">
        <ModalHeader {...header}>Lease Document</ModalHeader>
        <ModalCloseButton {...closeBtn} />
        <ModalBody padding={0} {...iframe}>
          {isDocReadyToSign && !isEdit ? (
            <iframe
              id="rocketSignFrame"
              title="rocketSignIFrame"
              onLoad={() => setIframeLoaded(true)}
              src={RLUrl}
              style={{ width: '100%', overflow: 'auto', border: 'none', height: '100%' }}
            ></iframe>
          ) : (
            <RocketDocument {...{ serviceToken, interviewId, setIsDocReadyToSign, setIsEdit }} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RocketLawyeriFrame;
