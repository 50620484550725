import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Skeleton, VStack, useToast } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';
import * as ROUTES from '@routes';
import { GET_TENANT_SCREENINGS } from '@core/apollo/queries/tenantScreening';
import TenantScreeningMain from './TenantScreeningMain';
import TenantScreeningEmptyState from './TenantScreeningEmptyState';

const TenantScreening = () => {
  const { pathname, state } = useLocation();
  const toast = useToast();
  const { createTenantScreeningSuccessful } = state || {};
  const isMainPage = pathname === `/${ROUTES.TENANT_SCREENING}`;
  const isOnboarding = pathname.includes(ROUTES.TENANT_SCREENING_CREATE);
  const [tenantScreenings, setTenantScreenings] = useState(null);

  // need to keep tab status here
  const [tabIndex, setTabIndex] = useState(0);

  // fetch all screenings, active or inactive
  const { data } = useQuery(GET_TENANT_SCREENINGS, {
    fetchPolicy: 'network-only',
    variables: { input: { applicationLinkActive: null } },
  });

  useEffect(() => {
    const { getTenantScreenings: screenings } = data || [];
    if (screenings) setTenantScreenings(screenings);
  }, [data]);

  useEffect(() => {
    if (createTenantScreeningSuccessful) {
      toast({
        position: 'bottom-left',
        title: 'Screening is active',
        description: 'Ready to invite applicants',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [state]);

  return (
    <>
      {(isMainPage || isOnboarding) && (
        <VStack w="100%" alignItems="flex-start" gap={2}>
          <Skeleton isLoaded={tenantScreenings} w="100%" alignItems="flex-start" gap={2}>
            {tenantScreenings?.length > 0 ? (
              <TenantScreeningMain
                tenantScreenings={tenantScreenings}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
              />
            ) : (
              <TenantScreeningEmptyState />
            )}
          </Skeleton>
        </VStack>
      )}
      {(isOnboarding || !isMainPage) && <Outlet />}
    </>
  );
};

export default TenantScreening;
