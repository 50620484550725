import React, { useContext } from 'react';
import CashFlowContext from '@contexts/CashFlowContext';
import { TransactionProvider } from '@contexts/TransactionContext';
import { PRESETS } from '@shared/helpers/cashFlow.helpers';
import { CashflowAnalytics, CashflowStatement, CashflowTaxPackage } from '../TabPanels';

type CashFlowTabPanelProps = {
  type: String,
  setActiveMonth: Function,
  activeMonth: String,
  activePropertyUnit: String,
  setActivePropertyUnit: Function,
  lastPreset: String,
  tabsRef: any,
  hasNoData: boolean,
  lastCategoryPreset: any,
  selectedCustomCategories: Array<any>,
  presetOptions: Array<Object>,
};

function CashFlowTabPanel({
  type,
  setActiveMonth,
  activeMonth,
  activePropertyUnit,
  setActivePropertyUnit,
  lastPreset,
  tabsRef,
  hasNoData,
  lastCategoryPreset,
  selectedCustomCategories,
  presetOptions,
}: CashFlowTabPanelProps) {
  const {
    filters,
    cashFlowLoading,
    cashFlowError,
    cashFlowData,
    cashFlowRefetch,
    categoryOptions,
    dataType,
    cashFlowPropertiesData,
    propertiesTags,
    unitsTags,
  } = useContext(CashFlowContext);

  switch (type) {
    case 'analytics':
      return (
        <TransactionProvider>
          <CashflowAnalytics
            {...{
              filters,
              setActiveMonth,
              activeMonth,
              activePropertyUnit,
              setActivePropertyUnit,
              lastPreset,
              hasNoData,
              loading: cashFlowLoading,
              error: cashFlowError,
              data: cashFlowData,
              refetch: cashFlowRefetch,
              cashFlowPropertiesData,
              dataType,
            }}
          />
        </TransactionProvider>
      );
    case 'cashflow_statement':
      return (
        <TransactionProvider>
          <CashflowStatement
            {...{
              filters,
              setActiveMonth,
              activeMonth,
              activePropertyUnit,
              setActivePropertyUnit,
              lastPreset,
              hasNoData,
              loading: cashFlowLoading,
              error: cashFlowError,
              data: cashFlowData,
              refetch: cashFlowRefetch,
              tabsRef,
              lastCategoryPreset,
              selectedCustomCategories,
              categoryOptions,
              cashFlowPropertiesData,
              dataType,
              propertiesTags,
              unitsTags,
            }}
          />
        </TransactionProvider>
      );
    case 'cashflow_tax_package':
      return (
        <TransactionProvider>
          <CashflowTaxPackage
            {...{
              defaultPresetName: PRESETS.SCHEDULE_E_CATEGORIES,
              presetOptions,
              categoryOptions,
            }}
          />
        </TransactionProvider>
      );
    default:
      return null;
  }
}

export default CashFlowTabPanel;
