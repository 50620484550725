const WARNING_MESSAGE =
  'Support for defaultProps will be removed from function components in a future major release';

export const suppressDefaultPropsWarning = () => {
  const originalError = console.error;

  console.error = (message, ...args) => {
    if (typeof message === 'string' && message.includes(WARNING_MESSAGE)) {
      return;
    }
    originalError.apply(console, [message, ...args]);
  };
};
