import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import formatCurrency from '@core/utils/formatCurrency';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import getTransferType from '../../../helpers/getTransferType';

const useAmountValidation = () => {
  const {
    values,
    setFieldError,
    validateField,
    setFieldValue,
    touched,
    setFieldTouched,
  } = useFormikContext();
  const { bankAccountsById } = useBankAccountsActive({
    filterBySubType: ['checking', 'savings'],
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (values.amount && values.transferFromAccountId && values.depositToAccountId) {
      validateField('amount');

      if (!touched.amount) {
        setFieldTouched('amount', true, false);
      }
    }
  }, [
    values.amount,
    values.transferFromAccountId,
    values.depositToAccountId,
    validateField,
    setFieldTouched,
    touched.amount,
  ]);

  const handleAmountChange = (event) => {
    const { value } = event.target;
    setFieldValue('amount', value, true);
    setFieldTouched('amount', true, true);
  };

  const getTransferLimits = (isExternal, transferType, transferFromAccount, depositToAccount) => {
    if (!isExternal) {
      return {
        dailyLimit: null,
        monthlyLimit: null,
        dailyTotal: null,
        monthlyTotal: null,
      };
    }

    if (transferType === 'TRANSFER_IN') {
      return {
        dailyLimit: depositToAccount?.limits?.dailyDebitLimit || null,
        monthlyLimit: depositToAccount?.limits?.monthlyDebitLimit || null,
        dailyTotal: depositToAccount?.limits?.dailyDebitTotal || 0,
        monthlyTotal: depositToAccount?.limits?.monthlyDebitTotal || 0,
      };
    }

    return {
      dailyLimit: transferFromAccount?.limits?.dailyCreditLimit || null,
      monthlyLimit: transferFromAccount?.limits?.monthlyCreditLimit || null,
      dailyTotal: transferFromAccount?.limits?.dailyCreditTotal || 0,
      monthlyTotal: transferFromAccount?.limits?.monthlyCreditTotal || 0,
    };
  };

  const validateAmount = (value) => {
    let error;

    const { transferFromAccountId, depositToAccountId } = values;

    if (!transferFromAccountId || !depositToAccountId) {
      return error;
    }

    const transferFromAccount = bankAccountsById[transferFromAccountId];
    const depositToAccount = bankAccountsById[depositToAccountId];

    // Cannot validate if accounts not found
    if (!transferFromAccount || !depositToAccount) {
      return error;
    }

    const amount = value ? parseFloat(value?.replace(/,/g, '')) : 0;

    const transferType = getTransferType(transferFromAccount, depositToAccount);

    // Basic validations that apply to all transfers
    if (!amount || amount === 0) {
      error = 'Amount must be greater than $0.00';
      setFieldError('amount', error);
      return error;
    }

    if (amount !== null && amount > transferFromAccount?.availableBalance) {
      error =
        'Insufficient funds to make this transfer, please edit the transfer amount to continue.';
      setFieldError('amount', error);
      return error;
    }

    // Skip ACH validation for internal transfers
    if (transferType === 'INTERNAL_TRANSFER') {
      // Make sure to clear any existing errors
      setFieldError('amount', undefined);
      return error;
    }

    const transferLimits = getTransferLimits(
      false,
      transferType,
      transferFromAccount,
      depositToAccount
    );

    if (!transferLimits) {
      setFieldError('amount', undefined);
      return error;
    }

    const { dailyLimit, monthlyLimit, dailyTotal, monthlyTotal } = transferLimits;

    if (transferType === 'TRANSFER_OUT' && (!dailyLimit || !monthlyLimit)) {
      setFieldError('amount', undefined);
      return error;
    }

    if (transferType === 'TRANSFER_IN' && (!dailyLimit || !monthlyLimit)) {
      setFieldError('amount', undefined);
      return error;
    }

    const dailyTransfer =
      transferType === 'TRANSFER_OUT'
        ? parseFloat(dailyTotal || 0, 10) + parseFloat(amount, 10)
        : parseFloat(dailyTotal || 0, 10) + parseFloat(amount, 10);

    const monthlyTransfer =
      transferType === 'TRANSFER_OUT'
        ? parseFloat(monthlyTotal || 0, 10) + parseFloat(amount, 10)
        : parseFloat(monthlyTotal || 0, 10) + parseFloat(amount, 10);

    let hasError = false;

    if (transferType === 'TRANSFER_OUT') {
      if (dailyTransfer > dailyLimit && monthlyTransfer <= monthlyLimit) {
        error = `This amount cannot be transferred as it exceeds the ${
          formatCurrency(dailyLimit).inDollars
        } daily limit. Initiate a transfer from your external account or contact us for an exception.`;
        hasError = true;
      } else if (dailyTransfer > dailyLimit && monthlyTransfer > monthlyLimit) {
        error = `This amount cannot be transferred as it exceeds the ${
          formatCurrency(dailyLimit).inDollars
        } daily limit. Initiate a transfer from your external account or contact us for an exception.`;
        hasError = true;
      } else if (dailyTransfer <= dailyLimit && monthlyTransfer > monthlyLimit) {
        error = `This amount cannot be transferred as it exceeds the ${
          formatCurrency(monthlyLimit).inDollars
        } monthly limit. Initiate a transfer from your external account or contact us for an exception.`;
        hasError = true;
      }
    } else if (transferType === 'TRANSFER_IN') {
      if (dailyTransfer > dailyLimit && monthlyTransfer <= monthlyLimit) {
        error = `This amount cannot be transferred as it exceeds the ${
          formatCurrency(dailyLimit).inDollars
        } daily limit. Initiate a transfer to your Baselane account or contact us for an exception.`;
        hasError = true;
      } else if (dailyTransfer > dailyLimit && monthlyTransfer > monthlyLimit) {
        error = `This amount cannot be transferred as it exceeds the ${
          formatCurrency(dailyLimit).inDollars
        } daily limit. Initiate a transfer to your Baselane account or contact us for an exception.`;
        hasError = true;
      } else if (dailyTransfer <= dailyLimit && monthlyTransfer > monthlyLimit) {
        error = `This amount cannot be transferred as it exceeds the ${
          formatCurrency(monthlyLimit).inDollars
        } monthly limit. Initiate a transfer to your Baselane account or contact us for an exception.`;
        hasError = true;
      }
    }

    if (hasError) {
      setFieldError('amount', error);
    } else {
      setFieldError('amount', undefined);
    }

    return error;
  };

  return {
    validateAmount,
    handleAmountChange,
  };
};

export default useAmountValidation;
