export const HOME = '/';
export const LOGIN = '/login';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SIGN_UP_AS_RENTER = 'renter_info';
export const EMAIL_EXISTS = 'email_exists';

export const PHONE = '/phone';
export const PHONE_REQUIRED = '/phone_required';
export const FORGOT_PASSWORD = '/forgot_password';
export const RESET_PASSWORD = '/reset_password';

export const MAINTENANCE_REQUEST = 'maintenance_request';

/**
 * Main Entry Points
 */
export const DASHBOARD = '/dashboard';
export const GET_STARTED = '/get_started';

/**
 * CSV Import
 */
export const CSV_IMPORT = 'csv_import';
export const CSV_IMPORT_UPLOAD = 'upload';
export const CSV_IMPORT_REVIEW_COLUMNS = 'review_columns';
export const CSV_IMPORT_MAP_CATEGORIES = 'map_categories';
export const CSV_IMPORT_MAP_PROPERTIES = 'map_properties';
export const CSV_IMPORT_PREVIEW = 'preview';

/**
 * Transactions
 */
export const TRANSACTIONS = '/transactions';
export const TRANSACTION = 'transaction';
export const TRANSACTION_ID = ':transactionId';

/**
 * Analytics
 */
export const ANALYTICS_REPORTING = '/cashflow';
export const CASH_FLOW_STATEMENT = `${ANALYTICS_REPORTING}/statement`;
export const CASH_FLOW_TAX_PACKAGE = `${ANALYTICS_REPORTING}/tax_package`;

export const KPIS = '/kpis';

/**
 * Leases
 */
export const LEASES = '/leases';
export const LEASES_UPDATE_ACCOUNTS = '/leases/updateaccounts';
export const LEASES_QUICKPAY_LEARN_MORE = '/leases/quickpay_learnmore';
/**
 * Properties
 */
export const PROPERTIES = 'properties';

/**
 * Bookkeeping Rules ( Auto Tagging + Manually Defined Rules )
 */
export const BOOKKEEPING_RULES = 'bookkeeping_rules';
export const BOOKKEEPING_RULES_CREATE = 'create';
export const BOOKKEEPING_RULES_ID = 'rule/:ruleId';
export const BOOKKEEPING_RULES_AI_CATEGORIZATION_SETTINGS = `ai_categorization_settings`;
export const BOOKKEEPING_RULES_PROPERTY_RULES = `property_rules`;
/**
 * Tenant Screening
 */
// main - start
export const TENANT_SCREENING = `tenant_screening`;
export const TENANT_SCREENING_CREATE = `create`;
export const TENANT_SCREENING_EDIT = `edit`;
export const TENANT_SCREENING_INVITE = `tenant_screening_invite`;

// selection 1: screeningId
export const TENANT_SCREENING_ID = `:screeningId`;
export const TENANT_SCREENING_APPLICATION_LIST = `${TENANT_SCREENING_ID}`;

// selection 2: applicationId
export const TENANT_SCREENING_APPLICATION = `application`;
export const TENANT_SCREENING_APPLICATION_ID = `:applicationId`;
export const TENANT_SCREENING_REPORT_DETAILS = `${TENANT_SCREENING_APPLICATION}/${TENANT_SCREENING_APPLICATION_ID}`;

// selection 3: applicantId
export const TENANT_SCREENING_APPLICANT = `applicant`;
export const TENANT_SCREENING_APPLICANT_ID = `:applicantId`;
export const TENANT_SCREENING_REPORT_DETAILS_APPLICANT = `${TENANT_SCREENING_REPORT_DETAILS}/${TENANT_SCREENING_APPLICANT}/${TENANT_SCREENING_APPLICANT_ID}`;
export const TENANT_SCREENING_REPORT_DETAILS_NO_APPLICANT_ID = `${TENANT_SCREENING_REPORT_DETAILS}/${TENANT_SCREENING_APPLICANT}`;
export const TENANT_SCREENING_REPORT_DETAILS_DEFAULT = `default`;
export const TENANT_SCREENING_REPORTS_SUMMARY = 'reports_summary';

// selection 4: report type
export const TENANT_SCREENING_RENTAL_APPLICATION = 'rental_application';
export const TENANT_SCREENING_IDENTITY_VERIFICATION = 'identity_verification';
export const TENANT_SCREENING_INCOME_VERIFICATION = 'income_verification';
export const TENANT_SCREENING_HOUSING_HISTORY = 'housing_history';
export const TENANT_SCREENING_CREDIT_REPORT = 'credit_report';
export const TENANT_SCREENING_CRIMINAL_REPORT = 'criminal_report';
export const TENANT_SCREENING_EVICTION_HISTORY = 'eviction_history';
export const TENANT_SCREENING_DISCLOSURE = 'submission_disclosure';
export const TENANT_SCREENING_ADDITIONAL_DOCUMENTS = 'additional_documents';

// application decisions
export const TENANT_SCREENING_APPROVE = 'approve';
export const TENANT_SCREENING_CONDITIONALLY_APPROVE = 'conditionally_approve';
export const TENANT_SCREENING_DECLINE = 'decline';

/**
 * Main Application
 */

export const TENANTS = 'tenants';

export const ADD_ACCOUNT = 'add_account';
export const CREATE_LEASE_DOCUMENT = 'create_lease_document';
export const CREATE_LEASE_AGREEMENT = 'create_lease_agreement';
export const LEASE_AGREEMENT = 'lease_agreement';
export const LEASE_AGREEMENT_DETAILS = `:leaseagreementId`;
export const LEASE_AGREEMENT_TO_LEASE = 'lease_agreement_to_lease';
export const LEASE_TO_LEASE_AGREEMENT = 'lease_to_lease_agreement';
export const NEW_LEASE_AGREEMENT = `new_lease_agreement`;

export const NATIVE_BANK = '/banking';
export const NB_ACCOUNTS = `${NATIVE_BANK}/accounts`;
export const NB_CARDS = `${NATIVE_BANK}/cards`;
export const NB_STATEMENT_DOCUMENTS = `${NATIVE_BANK}/statements`;
export const NB_BANK_ACCOUNT = `${NB_ACCOUNTS}/:bankaccount/`;
export const NB_BANK_ACCOUNT_CARDS = `${NB_ACCOUNTS}/:bankaccount/cards`;
export const NB_TAX_FORMS = `${NATIVE_BANK}/tax_forms`;

export const ORDER_CHECKBOOK = 'order_checkbook';
export const EDIT_ENTITY_ACCOUNT = 'edit_entity';
export const EDIT_ENTITY_ADDRESS = 'edit_entity_address';
export const ADD_VIRTUAL_CARD = 'add_virtual_card';
export const ADD_VIRTUAL_ACCOUNT = 'add_virtual_account';
export const ADD_BASELANE_BANK_ACCOUNT = 'add_baselane_bank_account';
export const REDEEM_CASHBACK = 'redeem_cashback';

export const EXTERNAL_ACCOUNTS = '/externalaccounts';
export const EA_MY_ACCOUNTS = `${EXTERNAL_ACCOUNTS}/my_accounts`;

export const INSURANCE = '/insurance';
export const LOANS = '/loans';
export const PARTNERSHIPS = '/partners';

export const USER_PROFILE = '/profile';
export const USER_COLLABORATOR_INFO = '/collaboratorΙnfo';
export const USER_WORKSPACE = '/workspace';
export const USER_WORKSPACE_INVITECOLLABORATOR = `${USER_WORKSPACE}/InviteCollaborator`;
export const USER_WORKSPACE_MEMBER_DETAILS = `:memberId`;
export const USER_WORKSPACE_UPDATE_COLLABORATOR_ACCESS = `edit`;
export const WORKSPACE_OWNER_VERIFY_NAME = `verify_name`;
export const WORKSPACE_OWNER_VERIFY_EMAIL = `verify_email`;
export const WORKSPACE_OWNER_VERIFY_PHONE = `verify_phone`;
export const USER_PROFILE_UPDATE_ACCOUNTS = 'update_accounts';
export const CREATE_WORKSPACE = `/create_workspace`;
export const REFERRAL = '/referral';
export const UNVERIFIED = '/unverified';
export const VERIFIED = '/verified';

export const ONBOARDING = '/onboarding';
export const ONBOARDING_PROPERTY_SURVEY = `${ONBOARDING}/property_survey`;
export const ONBOARDING_ADD_PROPERTY = `${ONBOARDING}/add_property`;
export const ONBOARDING_TRIAGE = `${ONBOARDING}/selection`;
export const ONBOARDING_GETTING_STARTED = `/getting_started`;
export const ONBOARDING_GS_EASY_STEPS = `${ONBOARDING_GETTING_STARTED}/easy_steps`;
export const ONBOARDING_GS_BEFORE_BEGINNING = `${ONBOARDING_GETTING_STARTED}/before_you_begin`;

export const TRANSFERS_PAYMENTS = `transfers_payments`;
export const TRANSFERS_ACTIVITY = `activity`;
export const TRANSFERS_DETAILS = `:transferId`;
export const TRANSFERS_PAYMENT_METHOD_ACH = `ach`;
export const TRANSFERS_PAYMENT_METHOD_WIRE = `wire`;
export const TRANSFERS_PAYMENT_METHOD_CHECK = `check`;
export const TRANSFERS_PAYMENT_METHOD_DELETE = `deletePaymentMethod`;
export const TRANSFERS_RECIPIENTS = `recipients`;
export const TRANSFERS_RECIPIENT_DELETE = `delete`;
export const TRANSFERS_RECIPIENT_ADD = `add`;
export const TRANSFERS_SCHEDULED = `scheduled_payments`;
export const SCHEDULED_PAYMENT_DETAILS = `:scheduledPaymentId`;
export const SCHEDULED_TRANSFER_DETAILS = `:transferId`;
export const TRANSFERS_RULES = `transfer_rules`;
export const TRANSFERS_RECIPIENTS_DETAILS = `:recipientId`;
export const TRANSFERS_RECIPIENTS_DETAILS_EDIT = `edit`;

export const TRANSFER_FUNDS = 'transfer_funds';
export const SEND_FUNDS = 'send_funds';
export const ADD_FUNDS = 'add_funds';
export const ADD_FUNDS_BANK_TRANSFER = 'bank_transfer';
export const ADD_FUNDS_CHECK_DEPOSIT = 'check_deposit';
export const ADD_FUNDS_TRANSFER_METHOD_SELECTOR = 'transfer_method_selector';
export const ADD_FUNDS_DIGITAL_WALLET = 'digital_wallet';
export const ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS = 'account_routing_numbers';
export const CREATE_AUTOTAG_RULE = 'create_autotag_rule';
export const AUTO_TAG_ONBOARDING = 'enable_categorization_ai';

export const UNIFIED_RENT_COLLECTION = '/unified_rent_collection';
export const UNIFIED_RENT_COLLECTION_SUCCESS = `${UNIFIED_RENT_COLLECTION}_success`;

export const UNIFIED_LANDLORD_BANKING = '/unified_landlord_banking';
export const UNIFIED_LANDLORD_BANKING_SUCCESS = `${UNIFIED_LANDLORD_BANKING}_success`;
export const UNIFIED_LANDLORD_BANKING_FIRST_SUCCESS = `${UNIFIED_LANDLORD_BANKING}_first_success`;
export const UNIFIED_LB_PERSONAL_INFO = `${UNIFIED_LANDLORD_BANKING}/personal_details`;
export const UNIFIED_LB_HOME_ADDRESS = `${UNIFIED_LANDLORD_BANKING}/home_address`;
export const UNIFIED_LB_BUSINESS_INFO = `${UNIFIED_LANDLORD_BANKING}/business_information`;
export const UNIFIED_LB_SSN = `${UNIFIED_LANDLORD_BANKING}/social_security_number`;

export const UNIFIED_BOOKKEEPING_ANALYTICS = '/unified_bookkeeping_analytics';
export const UNIFIED_BA_ACCOUNTS = `${UNIFIED_BOOKKEEPING_ANALYTICS}/add_accounts`;
export const UNIFIED_BA_AUTOTAG = `${UNIFIED_BOOKKEEPING_ANALYTICS}_autotag`;
export const UNIFIED_BA_SUCCESS = `${UNIFIED_BOOKKEEPING_ANALYTICS}_success`;
export const UNIFIED_BA_ALL_SET = `${UNIFIED_BOOKKEEPING_ANALYTICS}_all_set`;

export const PUBLIC_ROUTES = Object.freeze([
  LOGIN,
  SIGN_UP,
  SIGN_IN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
]);

export const SOMETHING_WENT_WRONG = 'something-went-wrong';
export const SESSION_EXPIRED = 'session-expired';
export const LOGIN_ERROR = '/login-error';
export const NO_WORKSPACE_ACCESS = '/no-workspace-access';
export const EXPIRED_INVITATION = '/expired-invitation';
export const ACCESS_DENIED = '/access-denied';

// Auth
export const SIGN_UP_CREATE_ACCOUNT = 'create_account';

// Tenant Screening
export const TENANT_SCREENING_ONBOARDING = 'onboarding';
export const TENANT_SCREENING_PROPERTY = 'property';

// Banking
export const NB_TRANSFER_PAYMENTS = `${NATIVE_BANK}/transfer_payments`;

// External Accounts
export const EA_THIRD_PARTY_ACCOUNTS = `${EXTERNAL_ACCOUNTS}/third_party_accounts`;
