import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';

import AddBaselaneAccount from '@core/components/NativeBankingPage/components/AddBaselaneAccount';
import AddAccountDrawer from '@pages/BankEntity/AddAccount/AddAccountDrawer';
import TransferFundsFeatureGuard from '@shared/components/TransferFundsDrawer/TransferFundsFeatureGuard';
import BankTransfer from '@pages/Transfers/components/AddFunds/components/BankTransfer';
import AddAccountsDrawer from '@shared/components/AddAccountsDrawer';
import TransactionDetailsDrawer from '@core/components/Transactions/transaction-details-drawer/TransactionDetailsDrawer';

import {
  ADD_ACCOUNT,
  ADD_BASELANE_BANK_ACCOUNT,
  ADD_FUNDS_BANK_TRANSFER,
  ADD_VIRTUAL_ACCOUNT,
  TRANSACTIONS,
  TRANSFER_FUNDS,
  TRANSACTION_ID,
  CREATE_AUTOTAG_RULE,
  AUTO_TAG_ONBOARDING,
  BOOKKEEPING_RULES_ID,
} from '@routes';
import CreateAutoTagRuleDrawer from '@core/components/CreateAutoTagRuleDrawer';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import AICategorizationSettings from '@core/pages/AutoTaggingRulesPage/components/AICategorizationRule/AICategorizationSettings';

const TransactionRoutes = ({ from = TRANSACTIONS }: { from: string }) => {
  const { checkGate } = useStatsigClient();

  return (
    <Routes>
      <Route path={`/${TRANSACTION_ID}/`} element={<TransactionDetailsDrawer from={from} />}>
        <Route path={BOOKKEEPING_RULES_ID} element={<CreateAutoTagRuleDrawer />} />
      </Route>
      <Route path={ADD_BASELANE_BANK_ACCOUNT} element={<AddBaselaneAccount from={from} />}>
        <Route
          path={ADD_VIRTUAL_ACCOUNT}
          element={<AddAccountDrawer from={`${TRANSACTIONS}/${ADD_BASELANE_BANK_ACCOUNT}`} />}
        />
      </Route>
      <Route path={TRANSFER_FUNDS} element={<TransferFundsFeatureGuard />} />
      <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
        <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
      </Route>
      <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
      {checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) && (
        <Route path={CREATE_AUTOTAG_RULE} element={<CreateAutoTagRuleDrawer />} />
      )}
      {checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) && (
        <Route path={AUTO_TAG_ONBOARDING} element={<AICategorizationSettings />} />
      )}
    </Routes>
  );
};

export default TransactionRoutes;
