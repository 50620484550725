import React, { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import AddEditProperty from '@pages/PropertiesPage/AddEditProperty';
import PropertySetUp from '@pages/PropertiesResponsivePage/PropertySetUp';
import SuccessDrawer from '@pages/PropertiesPage/SuccessDrawer';

export const getPropertyLink = ({
  key,
  from,
  handleStepUpdate,
  refetchTrackers,
  propertiesProps,
}) => {
  const { propertiesSummaryData, newProperty, setNewProperty, successDrawerRef } =
    propertiesProps ?? {};
  const { propertySummary } = propertiesSummaryData ?? {};
  const { totalProperties = 0, totalUnits = 0 } = propertySummary || {};

  return isMobile ? (
    <PropertySetUp
      key={key}
      from={from}
      handleCreatePropertySuccess={() => handleStepUpdate({ isAPIUpdated: true })}
      handleDrawerClose={() => handleStepUpdate({ isDrawerClosed: true })}
    />
  ) : (
    <Fragment key={key}>
      <AddEditProperty
        key={key}
        totalProperties={totalProperties}
        totalUnits={totalUnits}
        setNewProperty={setNewProperty}
        successDrawerRef={successDrawerRef}
        from={from}
        handleCreatePropertySuccess={refetchTrackers}
      />
      <SuccessDrawer successDrawerRef={successDrawerRef} property={newProperty} />
    </Fragment>
  );
};
