// @flow
import React from 'react';
import { Table, TableContainer } from '@chakra-ui/react';
import { ConfigType } from './types/table.types';
import BaselaneResponsiveTableContext from './BaselaneResponsiveTableContext';

type BaselaneResponsiveTableProps = {
  /**
   * Table children
   */
  children: React.Node,
  /**
   *  Contains props that can be reused throughout children components regarding the table's layout
   *  NOTE: add to config type in the future for any new shared props that's needed
   */
  config: ConfigType,
  /**
   *   Styles/props for table container elements
   */
  tableContainerStyles: Object,
  /**
   *   Styles/props for table element
   */
  tableProps: Object,
};

/**
 * Container wrapper for responsive table. Contains:
 * - Provider: can pass in config to context that can be used in children components
 * - TableContainer: table wrapper from chakra
 * - Table: table tag
 *
 * @component
 * @returns {JSX.Element}
 */
const BaselaneResponsiveTable = ({
  children,
  config = { columns: [] },
  tableContainerStyles = {},
  tableProps = {},
}: BaselaneResponsiveTableProps) => {
  return (
    <BaselaneResponsiveTableContext.Provider value={config}>
      <TableContainer {...{ overflowY: 'initial', overflowX: 'initial', ...tableContainerStyles }}>
        <Table variant="unstyled" {...tableProps} style={{ tableLayout: 'fixed' }}>
          {children}
        </Table>
      </TableContainer>
    </BaselaneResponsiveTableContext.Provider>
  );
};

export default BaselaneResponsiveTable;
