// @flow
import React from 'react';
import { TabPanels, TabPanel } from '@chakra-ui/react';
import { achFeeEnabledFeatureFlag } from '@core/constants/envVars';
import useBreakPoints from '@core/hooks/useBreakPoints';
import TabMembers from './TabMembers';
import BusinessProfile from './BusinessProfile';
import RentCollection from './RentCollection';
import { tabPanelsStyles } from '../../styles/userProfileTabsPanels.styles';

type UserProfileTabsPanelsProps = {
  isTabChange: boolean,
  tabIndex: number,
  setIsDirty: Function,
  setIsTabChange: Function,
  setTabIndex: Function,
};

function UserProfileTabsPanels({
  setIsDirty,
  isTabChange,
  setIsTabChange,
  setTabIndex,
  tabIndex,
}: UserProfileTabsPanelsProps): any {
  const { isMax576 } = useBreakPoints();

  let tabs = [
    { name: 'tabMembers', Component: TabMembers },
    { name: 'businessProfile', Component: BusinessProfile },
  ];

  if (achFeeEnabledFeatureFlag === 'true')
    tabs = [...tabs, { name: 'rentCollection', Component: RentCollection }];

  return (
    <TabPanels {...tabPanelsStyles(isMax576)}>
      {tabs.map(({ name, Component }) => (
        <TabPanel key={name} id={name} h="100%" p={0} m={0}>
          <Component
            tabIndex={tabIndex}
            setIsDirty={setIsDirty}
            isTabChange={isTabChange}
            setIsTabChange={setIsTabChange}
            setTabIndex={setTabIndex}
          />
        </TabPanel>
      ))}
    </TabPanels>
  );
}

export default UserProfileTabsPanels;
