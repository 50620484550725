import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import { ChakraProvider, FormControl, HStack, Text } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_WORKSPACE } from '@core/apollo/queries';
import { useCurrentWorkspace, useUserAccessibleWorkspace } from '@shared/hooks';
import habitatTheme from '@core/themeHabitat';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { Icon16Close, Icon16Check, Icon16Edit } from '@icons/16px';

import {
  BaselaneFormLabel,
  BaselaneInput,
  BaselaneFormErrorMessage,
  BaselaneButtonIcon,
} from '../../../Shared/components';

const WorkspaceName = () => {
  const { activeWorkspaceId, workspaceName } = useCurrentWorkspace();

  const {
    accessibleWorkspaceData,
    refetch: refetchAccessibleWorkspaceData,
  } = useUserAccessibleWorkspace();

  const activeWorkspace = accessibleWorkspaceData.find((w) => w.isActive === true);
  const isOwner = activeWorkspace && activeWorkspace.isOwner;

  const { isMax768: isMobile } = useBreakPoints();
  const [isEditing, setIsEditing] = useState(false);

  const formikRef = useRef();

  const [updateName] = useMutation(UPDATE_USER_WORKSPACE);
  const handleSubmit = (values) => {
    setIsEditing(false);
    updateName({
      variables: {
        workspaceName: values.inputWorkspaceName,
        id: activeWorkspaceId,
      },
    }).then((res) => {
      refetchAccessibleWorkspaceData();
    });
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      formikRef.current.handleSubmit();
    }
  };
  return (
    <ChakraProvider theme={habitatTheme}>
      {!isEditing ? (
        // Display mode: Show text with an "Edit" button
        <HStack m={1.5} spacing={1}>
          <Text textStyle="headline-lg" ml="1.5">
            {workspaceName}
          </Text>
          {isOwner && (
            <BaselaneButtonIcon
              icon={<Icon16Edit />}
              variant="outline"
              palette="primary"
              onClick={() => {
                setIsEditing(true);
              }}
            />
          )}
        </HStack>
      ) : (
        // Edit mode: Show Formik input field with "Save" and "Cancel" buttons
        <Formik
          innerRef={formikRef}
          initialValues={{ inputWorkspaceName: workspaceName }}
          validate={(values) => {
            const errors = {};

            // Custom validation for workspaceName
            if (!values.inputWorkspaceName || values.inputWorkspaceName.trim() === '') {
              errors.inputWorkspaceName = 'Workspace name cannot be empty';
            }

            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, resetForm, errors, touched, submitForm }) => (
            <form>
              <FormControl
                m={0}
                flex="1"
                id="inputWorkspaceName"
                isRequired
                isInvalid={errors.inputWorkspaceName && touched.inputWorkspaceName}
              >
                <BaselaneFormLabel styles={{ w: '100%' }}>Workspace name</BaselaneFormLabel>
                <HStack gap="0.75" w={isMobile ? '100%' : '400px'} m={1.5} ml={0}>
                  <BaselaneInput
                    placeholder="Workspace name"
                    id="inputWorkspaceName"
                    name="inputWorkspaceName"
                    value={values.inputWorkspaceName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    bg="brand.neutral.white"
                    w="100%"
                    isRequired
                    maxLength="50"
                    onKeyDown={handleOnKeyDown}
                  />
                  <BaselaneButtonIcon
                    icon={<Icon16Close />}
                    variant="outline"
                    palette="neutral"
                    onClick={() => setIsEditing(false)}
                  />
                  <BaselaneButtonIcon
                    icon={<Icon16Check />}
                    variant="filled"
                    palette="primary"
                    isDisabled={errors.inputWorkspaceName && touched.inputWorkspaceName}
                    onClick={() => {
                      submitForm();
                    }}
                  />
                </HStack>
                {errors.inputWorkspaceName && touched.inputWorkspaceName && (
                  <BaselaneFormErrorMessage>{errors.inputWorkspaceName}</BaselaneFormErrorMessage>
                )}
              </FormControl>
            </form>
          )}
        </Formik>
      )}
    </ChakraProvider>
  );
};

export default WorkspaceName;
