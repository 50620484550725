import React, { useContext, useRef, useState } from 'react';
import moment from 'moment';
import { Spinner } from '@chakra-ui/react';
import { BaselaneButton } from '@core/components/Shared/components';
import UserContext from '@contexts/UserContext';
import { PRESETS, CATEGORIES } from '@core/components/Shared/helpers/cashFlow.helpers';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import DownloadTrxs from '@core/components/Transactions/components/TransactionsFilters/DownloadTrxs';
import StatementDownload from '../CashflowStatement/components/StatementDownload';
import {
  getStatementData,
  specificallyChosenCategoriesAsColumn,
  calculateTotalsColumn,
  calculateDataColumns,
  generateNullData,
  getDetailsColumns,
  calculateTotalsRow,
} from '../../helpers/cashflow-statement.helpers';

type TaxPackageDownloadProps = {
  selectedCustomCategories: Array<any>,
  categoryOptions: Array<any>,
  taxPackageCashFlowData: Array<any>,
  isTaxPackageCashFlowLoading: boolean,
  categorizedTrxCount: Number,
  uncategorizedTrxCount: Number,
  setHasNoTrxs: Function,
  datePresetFilter: String,
  totalDocuments: Number,
};

function TaxPackageDownload({
  selectedCustomCategories,
  categoryOptions,
  taxPackageCashFlowData,
  isTaxPackageCashFlowLoading,
  categorizedTrxCount,
  uncategorizedTrxCount,
  setHasNoTrxs,
  datePresetFilter,
  totalDocuments,
}: TaxPackageDownloadProps) {
  if (isTaxPackageCashFlowLoading)
    return (
      <BaselaneButton
        leftIcon={<Spinner size="xs" />}
        variant="transparent"
        palette="neutral"
        isDisabled
      >
        Preparing Your Tax Package for Download
      </BaselaneButton>
    );

  // User Context
  const { user } = useContext(UserContext);
  const [isDownloading, setIsDownloading] = useState(false);

  // Vars
  const statementDownloadLinkRef = useRef();
  const currentPreset = PRESETS.SCHEDULE_E_CATEGORIES;
  const headers = specificallyChosenCategoriesAsColumn(
    selectedCustomCategories,
    categoryOptions,
    true,
    CATEGORIES
  );

  const isPreventDownload = categorizedTrxCount === 0 && uncategorizedTrxCount === 0;

  // Helper Func - Trxs
  const handleDownloadClick = () => {
    // Show/Hide error msg on click
    setHasNoTrxs(isPreventDownload);
    sendSegmentEvent('tax_package_download', {
      user_id: user?.id,
      date_filter: datePresetFilter,
    });
  };

  // Helper Func - Statements
  const initialSections = Object.keys(headers).map((title, index) => {
    const headerColumn = [...headers[title]];

    const dataColumns = calculateDataColumns(
      index === 0,
      title,
      headers[title],
      taxPackageCashFlowData
    );
    const totalsColumn = calculateTotalsColumn(title, [...headers[title]], taxPackageCashFlowData);

    return {
      title,
      table: {
        headerColumn,
        dataColumns,
        totalsColumn,
      },
    };
  });

  const sections =
    initialSections.length < 1
      ? generateNullData(taxPackageCashFlowData, categoryOptions)
      : initialSections;

  const [netOperatingIncome, netOperatingCashflow, totalInflowsOutflows] = getDetailsColumns(
    taxPackageCashFlowData
  );

  const calculatedTotals = calculateTotalsRow(sections);

  const filenameSuffix = `-${currentPreset
    ?.toLowerCase()
    ?.split(' ')
    .join('-')
    .split('&')
    .join('and')
    .split('-(noi)')
    .join('')}`;

  const statementData = getStatementData(
    sections,
    netOperatingIncome,
    netOperatingCashflow,
    totalInflowsOutflows,
    calculatedTotals,
    currentPreset
  );

  const todayDate = moment(new Date()).format('YYYYMMDD');

  return (
    statementData && (
      <>
        <DownloadTrxs
          {...{
            showHiddenTransactions: false,
            isPreventDownload,
            filename: `Schedule_E_Transactions_${todayDate}`,
            handlePreventDownloadClick: handleDownloadClick,
            handleExternalDownloadClick: () => {
              setIsDownloading(true);
              statementDownloadLinkRef?.current?.link.click();
            },
            datePresetFilter,
            totalDocuments,
          }}
          externalDownloadIsLoading={isDownloading}
        />

        <StatementDownload
          {...{
            className: 'hidden',
            buttonText: 'Download Tax Package',
            filename: `Schedule_E_Statement_${todayDate}`,
            CSVRef: statementDownloadLinkRef,
            statementData,
            filenameSuffix,
          }}
          handleStatementDownloadClick={(_, done) => {
            done(true);
            setIsDownloading(false);
          }}
        />
      </>
    )
  );
}

export default TaxPackageDownload;
