// @flow
// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=1820%3A83853
import React, { useState, useRef } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useDisclosure, useToast } from '@chakra-ui/react';
import { BaselaneDrawer } from '@shared/components';
import onDrawerClose from '@core/utils/onDrawerClose';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { useUnitOtp } from '@core/contexts/UnitOtpContext';
import Body from './Body';
import UnsavedChangesAlert from '../UnsavedChangesAlert';
import useEntityByIdQuery from '../../shared/useEntityByIdQuery';
import { UPDATE_BANK_ENTITY } from '../../queries';

type EditMailingAddressDrawerProps = {
  from?: String,
};

const EditMailingAddressDrawer = ({ from }: EditMailingAddressDrawerProps) => {
  const { isMax576 } = useBreakPoints();
  const formikRef = useRef(null);
  const { account } = useOutletContext();
  const navigate = useNavigate();
  const { refetchEntity } = useEntityByIdQuery();

  // Alert State
  const { isOpen: isDrawerAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  // Mutation
  const [updateBank] = useMutation(UPDATE_BANK_ENTITY);

  const { bankId, mailingAddress } = account || {};

  // Unit OTP
  const { verifyUnitOtp } = useUnitOtp(bankId);

  // State variables
  const [isDirty, setIsDirty] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [variables, setFormVariables] = useState({});

  // Toast
  const toast = useToast();
  const showToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Mailing address updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const cleanup = () => {
    if (isDirty) setIsDirty(false);
    if (isFormValid) setIsFormValid(false);
    if (isSubmitting) setIsSubmitting(false);
  };

  const closeEvent = () => {
    onDrawerClose(navigate, from);
  };

  const onCloseWithoutSaving = () => {
    if (isDirty) {
      onAlertOpen();
    } else {
      cleanup();
      closeEvent();
    }
  };

  const saveEdits = (values) => {
    updateBank({ ...values }).then(async () => {
      setIsSubmitting(true);
      await refetchEntity();
      setIsSubmitting(false);
      cleanup();
      closeEvent();
      showToast();
    });
  };

  const handleFormVariableUpdate = (newVariables) => {
    setFormVariables({ ...variables, ...newVariables });
  };

  const handleValidation = (values) => {
    const errors = {};

    if (!values?.address?.trim()) {
      errors.address = 'Please enter address';
    }
    if (!values?.city?.trim()) {
      errors.city = 'Please enter city';
    }
    if (!values?.state) {
      errors.state = 'Please enter state';
    }
    if (!values?.zipcode) {
      errors.zipcode = 'Please enter zipcode';
    }
    if (values?.zipcode && values?.zipcode?.length < 5) {
      errors.zipcode = 'Please enter 5-digit zip code';
    }
    if (values?.unitNumber?.length > 0 && values?.unitNumber?.trim().length === 0) {
      errors.unitNumber = 'Unit number cannot be left blank';
    }

    setIsFormValid(Object.keys(errors).length === 0);

    return errors;
  };

  const handleSubmit = (values) => {
    const { address, city, state, unitNumber, zipcode } = values || {};
    const editedVariables = {
      variables: {
        id: bankId,
        mailingAddress: {
          street: address,
          unit: unitNumber,
          postalCode: zipcode,
          city,
          state,
          country: mailingAddress?.country,
        },
      },
    };

    verifyUnitOtp(bankId)
      .then(() => saveEdits(editedVariables))
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const handleAlertContinueClick = (e) => {
    onAlertClose();
    closeEvent();
    cleanup();
  };

  return (
    <>
      <UnsavedChangesAlert
        isDrawerAlertOpen={isDrawerAlertOpen}
        onAlertClose={onAlertClose}
        onAlertOpen={onAlertOpen}
        onAlertContinue={(e) => handleAlertContinueClick(e)}
      />

      <BaselaneDrawer
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        title="Edit mailing address"
        closeOnOverlayClick={false}
        closeEvent={onCloseWithoutSaving}
        onOverlayClick={onCloseWithoutSaving}
        isOpen
        newDesignDrawer
        newDrawerUseDefaultBodyFooter
        hideOverlay
      >
        <Body
          mailingAddress={mailingAddress}
          formikRef={formikRef}
          isDirty={isDirty}
          isFormValid={isFormValid}
          isSubmitting={isSubmitting}
          setIsDirty={setIsDirty}
          handleFormVariableUpdate={handleFormVariableUpdate}
          handleValidation={handleValidation}
          handleSubmit={handleSubmit}
          onClose={onCloseWithoutSaving}
        />
      </BaselaneDrawer>
    </>
  );
};

export default EditMailingAddressDrawer;

EditMailingAddressDrawer.defaultProps = {
  from: null,
};
