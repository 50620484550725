import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { IconArrowRight, IconArrowLeft } from '@icons';
import { footerStyles } from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';
import {
  useTabIndex,
  useSetTabIndex,
  useSetIsPropertyPanelVisible,
  useHasProperties,
  useSetIsAddingNewPropertyPanelVisible,
} from '../../leaseAgreementStore';

type Props = {
  isNewPropertyValid: boolean,
  saveNewProperty: Function,
};

function AddPropertyFooter({ isNewPropertyValid, saveNewProperty }: Props): any {
  const { isMax768: isMobile } = useBreakPoints();

  const tabIndex = useTabIndex();
  const setTabIndex = useSetTabIndex();
  const setIsPropertyPanelVisible = useSetIsPropertyPanelVisible();
  const hasProperties = useHasProperties();
  const setIsAddingNewPropertyPanelVisible = useSetIsAddingNewPropertyPanelVisible();
  const isPreviousDisabled = !hasProperties;
  const handleBackNavigation = () => {
    setIsPropertyPanelVisible(true);
    setIsAddingNewPropertyPanelVisible(false);
    setTabIndex(0);
  };

  return (
    <Flex direction={isMobile ? 'column-reverse' : 'row'} justifyContent="space-between">
      <Flex direction="row" {...footerStyles(isMobile)} marginTop={3}>
        {/* Previous Button */}
        {!isPreviousDisabled && (
          <Box marginRight={1.5} marginTop={tabIndex === 0 ? '0' : 3}>
            <BaselaneButton
              variant="outline"
              palette="neutral"
              size="md"
              leftIcon={<IconArrowLeft />}
              onClick={() => handleBackNavigation()}
            >
              Previous
            </BaselaneButton>
          </Box>
        )}

        {/* Continue Button */}
        <Box marginTop={tabIndex === 0 ? '0' : '24px'} width="100%">
          <BaselaneButton
            variant="filled"
            palette="primary"
            size="md"
            onClick={() => saveNewProperty()}
            rightIcon={<IconArrowRight />}
            id="continue-la-button"
            isDisabled={!isNewPropertyValid}
          >
            Save and continue
          </BaselaneButton>
        </Box>
      </Flex>
    </Flex>
  );
}

export default AddPropertyFooter;
