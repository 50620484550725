import React from 'react';
import { createPortal } from 'react-dom';
import BaselaneFilterCard from '../../BaselaneFilterCard';
import { animationStyles } from '../styles/popup.styles';

type PopupProps = {
  handlePopupClose: Function,
  showMobileDropdownPopupAnimation: boolean,
  showMobileDropdownPopup: boolean,
  children: React.Node,
};

const Popup = ({
  handlePopupClose,
  showMobileDropdownPopupAnimation,
  showMobileDropdownPopup,
  children,
}: PopupProps) => {
  return createPortal(
    <BaselaneFilterCard
      customStyles={{
        popupContainer: {
          overflow: 'hidden',
          height: '100%',
          sx: {
            '@media print': {
              visibility: 'hidden',
            },
          },
        },
        popupInnerContainer: {
          overflow: 'hidden',
          p: '0',
          height: '100%',
          flex: '1 1 auto',
          maxHeight: 'unset',
        },
      }}
      showFooter={false}
      showHeader={false}
      handleFilterPopupClose={handlePopupClose}
      showFilterPopupAnimation={showMobileDropdownPopupAnimation}
      animationStyles={animationStyles}
      showFilterPopup={showMobileDropdownPopup}
    >
      {children}
    </BaselaneFilterCard>,
    document.body
  );
};

export default Popup;
