import React, { PropsWithChildren, ReactElement } from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import { FormDescription, FormTitle } from '@shared/components/BaselaneFlow';

type TabPanelLayoutProps = {
  stepNumber: string,
  title: string,
  description: string | ReactElement,
};

const TabPanelLayout = ({
  stepNumber,
  title,
  description,
  children,
}: PropsWithChildren<TabPanelLayoutProps>) => {
  return (
    <Box pl={5} pt={4} height="calc(100% - 80px)" overflow="hidden" className="panel">
      <Outlet />
      <Text textStyle="xs-heavy" color="brand.neutral.600" textTransform="uppercase" mb={1}>
        Step {stepNumber} of 5
      </Text>
      <Stack pr={5} height="calc(100% - 16px)" overflow="auto">
        <Stack spacing={3} maxWidth="600px">
          <Stack spacing={1}>
            <FormTitle
              title={title}
              containerStyles={{ pb: 0 }}
              textStyles={{ textStyle: 'headline-xl' }}
            />
            <FormDescription
              styles={{
                textStyle: 'sm',
                color: 'brand.neutral.600',
                mb: 0,
              }}
            >
              {description}
            </FormDescription>
          </Stack>
          {children}
        </Stack>
      </Stack>
    </Box>
  );
};

export default TabPanelLayout;
