import { useQuery } from '@apollo/client';
import { GET_CURRENT_WORKSPACE } from '@core/apollo/queries';

const useCurrentWorkspace = () => {
  const {
    loading: isLoadingCurrentWorkspace,
    data,
    refetch: refetchCurrentWorkspace,
    error: errorCurrentWorkspace,
  } = useQuery(GET_CURRENT_WORKSPACE);

  const currentWorkspace = data?.currentWorkspace ?? {};
  const workspaceName = data?.currentWorkspace?.name;
  const activeWorkspaceId = Number(currentWorkspace.id);
  const collaborators =
    data && currentWorkspace?.collaborators.filter((c) => c?.status !== 'REVOKED');
  const workspaceOwner = data?.currentWorkspace?.user;
  const workspaceOwnerId = data?.currentWorkspace?.user?.Id;
  const userKyc = data?.currentWorkspace?.user?.userKyc;
  const userKycStatus = data?.currentWorkspace?.user?.userKycStatus;
  const workspaceMetadata = data?.currentWorkspace?.user?.userMetadata;

  return {
    activeWorkspaceId,
    collaborators,
    currentWorkspace,
    workspaceName,
    isLoadingCurrentWorkspace,
    refetchCurrentWorkspace,
    workspaceMetadata,
    errorCurrentWorkspace,
    workspaceOwner,
    workspaceOwnerId,
    userKyc,
    userKycStatus,
  };
};

export default useCurrentWorkspace;
