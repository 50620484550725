// @flow
import React, { useRef, useState, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { Formik } from 'formik';
import { Stack, Box, Text, Input, FormLabel, useToast } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { useMutation } from '@apollo/client';
import { useStatsigClient } from '@statsig/react-bindings';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { BaselaneButton, BaselaneDivider } from '@shared/components';
import useCurrentWorkspace from '@shared/hooks/useCurrentWorkspace';
import UserAccessContext from '@contexts/UserAccessContext';
import { phoneMaskWithCountryCode } from '@core/utils/masks/index';
import { UPDATE_USER } from '../../../../UserProfilePage/queries/user';
import {
  formLabelStyles,
  formInputStyles,
  errorStyles,
} from '../../../styles/userProfileTabsPanels.styles';

function BusinessProfile(): any {
  const formikRef = useRef();
  const { workspaceOwner, refetchCurrentWorkspace } = useCurrentWorkspace();
  const { isWorkspaceOwner } = useContext(UserAccessContext);
  const { checkGate } = useStatsigClient();
  const isMuaEnabled = checkGate(FEATURE_GATES.MUA_GATE);

  const [updateUser] = useMutation(UPDATE_USER);

  const { businessProfile } = workspaceOwner ?? {};
  const initialFormValues = businessProfile;

  const [isLoading, setIsLoading] = useState(false);
  const [showEmailError, setEmailError] = useState(false);

  const toast = useToast();

  const showSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Business profile saved successfully. ',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Something went wrong. Please try again.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleValidation = (values) => {
    const errors = {};
    setEmailError(false);
    if (
      values.dbaEmail.length > 0 &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.dbaEmail)
    ) {
      errors.dbaEmail = 'Invalid email address';
      setEmailError(true);
    }
    return errors;
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      formikRef.current.handleSubmit();
    }
  };

  const handleFormSubmit = (data) => {
    const { dba, dbaEmail, dbaPhoneNumber } = data;
    setIsLoading(true);
    updateUser({
      variables: {
        ...workspaceOwner,
        businessProfile: { dba, dbaEmail, dbaPhoneNumber },
      },
    })
      .then(() => {
        refetchCurrentWorkspace().then(() => {
          setIsLoading(false);
        });
        showSuccessToast();
      })
      .catch((err) => {
        console.log(err);
        showErrorToast();
      });
  };

  return (
    <Stack {...{ w: '100%', gap: 0, spacing: 0 }} mt={4} px={1.5}>
      <Text textStyle="headline-md" {...{ ml: '1.5' }}>
        Business Profile Displayed to Tenants
      </Text>

      <Text textStyle="sm" {...{ mt: 2, ml: '1.5' }}>
        Information below will be shown to tenants in place of your primary profile information.
      </Text>

      {isMobile && <BaselaneDivider />}
      <Stack spacing="4" {...{ w: isMobile ? '100%' : '30%', mx: '1.5' }}>
        <Formik
          innerRef={formikRef}
          validate={(values) => handleValidation(values)}
          initialValues={initialFormValues}
          onSubmit={handleFormSubmit}
          validateOnChange
          validateOnMount
        >
          {({
            values,
            handleSubmit,
            isValid,
            handleBlur,
            handleChange = () => {},
            errors,
            touched,
          }) => (
            <form style={{ width: '100%' }}>
              <Box>
                <Box position="relative">
                  <FormLabel htmlFor="dba" {...formLabelStyles}>
                    Name (doing business as)
                  </FormLabel>
                  <Input
                    {...formInputStyles}
                    id="dba"
                    name="dba"
                    value={values?.dba}
                    isDisabled={!isWorkspaceOwner && isMuaEnabled}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="John Doe"
                  />
                </Box>
                <Box pt="32px" position="relative">
                  <FormLabel htmlFor="dbaEmail" {...formLabelStyles}>
                    Email
                  </FormLabel>
                  <Input
                    {...formInputStyles}
                    id="dbaEmail"
                    name="dbaEmail"
                    value={values?.dbaEmail}
                    isInvalid={errors.dbaEmail && touched.dbaEmail}
                    isDisabled={!isWorkspaceOwner && isMuaEnabled}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleOnKeyDown}
                    placeholder="johndoe@email.com"
                  />
                  {showEmailError ? <Text {...errorStyles}>{errors.dbaEmail}</Text> : null}
                </Box>
                <Box pt={4} position="relative" mb={1.5}>
                  <FormLabel htmlFor="dbaPhoneNumber" {...formLabelStyles}>
                    Phone Number
                  </FormLabel>
                  <Input
                    {...formInputStyles}
                    as={MaskedInput}
                    mask={phoneMaskWithCountryCode('1')}
                    id="dbaPhoneNumber"
                    name="dbaPhoneNumber"
                    value={values?.dbaPhoneNumber}
                    isDisabled={!isWorkspaceOwner}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="+1 (***) *** ****"
                  />
                </Box>

                <BaselaneButton
                  variant="filled"
                  palette="primary"
                  size="md"
                  isDisabled={(!isValid || !isWorkspaceOwner) && isMuaEnabled}
                  onClick={handleSubmit}
                  isLoading={isLoading}
                >
                  Save
                </BaselaneButton>
              </Box>
            </form>
          )}
        </Formik>
      </Stack>
    </Stack>
  );
}

export default BusinessProfile;
