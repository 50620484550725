import React, { useEffect, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { BaselaneButton } from '@core/components/Shared/components';
import { Icon16Check, Icon16ChevronLeft } from '@icons/16px';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import { useUnitOtp } from '@core/contexts/UnitOtpContext';
import steps from '../steps';
import getTransferType from '../helpers/getTransferType';

type ReviewAndTransferFooterProps = {
  goToStep: Function,
  isLoading: Boolean,
  requireOtp: Boolean,
  setRequireOtp: Function,
};

const ReviewAndTransferFooter = ({
  goToStep,
  isLoading: somethingOnParentIsLoading,
  requireOtp,
  setRequireOtp,
}: ReviewAndTransferFooterProps) => {
  const { values, submitForm } = useFormikContext();
  const { bankAccountsById, loading: activeBankAccountsAreLoading } = useBankAccountsActive({
    filterBySubType: ['checking', 'savings'],
  });
  const transferFromAccount = useMemo(() => bankAccountsById[values.transferFromAccountId], [
    bankAccountsById,
  ]);
  const depositToAccount = useMemo(() => bankAccountsById[values.depositToAccountId], [
    bankAccountsById,
  ]);

  const transferType = getTransferType(transferFromAccount, depositToAccount);

  const bankId =
    transferType === 'TRANSFER_IN'
      ? depositToAccount?.userInstitutionId
      : transferFromAccount?.userInstitutionId;

  const { verifyUnitOtp, forceVerifyUnitOtp, ignore } = useUnitOtp();

  const handleDeposit = () => {
    verifyUnitOtp(bankId).then(submitForm).catch(ignore);
  };

  useEffect(() => {
    if (requireOtp) {
      forceVerifyUnitOtp(bankId).then(submitForm).catch(ignore);
      setRequireOtp(false);
    }
  }, [requireOtp]);

  return (
    <Box display="flex" gap={2} width="100%">
      <BaselaneButton
        variant="outline"
        palette="neutral"
        size="md"
        onClick={() => goToStep(steps.BOOKKEEPING_DETAILS)}
        leftIcon={<Icon16ChevronLeft />}
      >
        Back
      </BaselaneButton>
      <BaselaneButton
        id="transfer-funds-submit-button"
        type="submit"
        isFullWidth
        variant="filled"
        palette="primary"
        size="md"
        leftIcon={<Icon16Check />}
        isLoading={somethingOnParentIsLoading || activeBankAccountsAreLoading}
        onClick={handleDeposit}
      >
        Transfer
      </BaselaneButton>
    </Box>
  );
};

export default ReviewAndTransferFooter;
