import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { GET_TRANSACTIONS_SUMMARY } from '@core/apollo/queries';
import { CASH_FLOW_STATEMENT } from '@routes';
import TransactionContext from '@core/contexts/TransactionContext';
import { BaselaneButton } from '@shared/components';
import onDrawerClose from '@core/utils/onDrawerClose';
import { UPDATE_TRANSACTION } from '../queries/transactions';

const DeleteTransactionAlertDialog = ({ isOpen, onClose, transactionId, from }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { summaryFilter } = useContext(TransactionContext);
  const cancelRef = React.useRef();

  const [updateTransaction, { loading: updateInProgress }] = useMutation(UPDATE_TRANSACTION, {
    update(cache, { data: { updateTransactions } }) {
      const updatedTransaction = updateTransactions[0];

      if (updatedTransaction.isDeleted) {
        const id = cache.identify({
          id: updatedTransaction.id,
          __typename: 'Transaction',
        });
        cache.evict({ id });
        cache.gc();

        // close trx details drawer
        if (location?.pathname.includes(CASH_FLOW_STATEMENT)) {
          navigate(-1);
        } else {
          onDrawerClose(navigate, from);
        }
      }

      if (!updatedTransaction.tagId) {
        const res = cache.readQuery({
          query: GET_TRANSACTIONS_SUMMARY,
          variables: { input: summaryFilter },
        });

        if (res) {
          cache.writeQuery({
            query: GET_TRANSACTIONS_SUMMARY,
            data: {
              transactionSummary: {
                ...res.transactionSummary,
                totalUncategorized: {
                  ...res.transactionSummary.totalUncategorized,
                  count: res.transactionSummary.totalUncategorized.count - 1,
                  absoluteAmount:
                    res.transactionSummary.totalUncategorized.absoluteAmount -
                    Math.abs(updatedTransaction.amount),
                },
              },
            },
            variables: { input: summaryFilter },
          });
        }
      }
    },
    onCompleted: () => onClose(),
  });

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent p={4}>
          <AlertDialogHeader p={0} textStyle="headline-lg">
            Delete transaction?
          </AlertDialogHeader>
          <AlertDialogCloseButton />

          <AlertDialogBody p={0} mt="4px" textStyle="sm">
            This will permanently delete the transaction, and cannot be undone.
          </AlertDialogBody>

          <AlertDialogFooter p={0} mt={3}>
            <BaselaneButton
              width="100%"
              ref={cancelRef}
              onClick={onClose}
              variant="outline"
              size="md"
              isDisabled={updateInProgress}
            >
              Cancel
            </BaselaneButton>
            <BaselaneButton
              width="100%"
              isLoading={updateInProgress}
              variant="filled"
              palette="danger"
              size="md"
              onClick={() => {
                updateTransaction({
                  variables: {
                    input: {
                      id: transactionId,
                      isDeleted: true,
                    },
                  },
                });
              }}
              ml={3}
            >
              Delete
            </BaselaneButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

DeleteTransactionAlertDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  transactionId: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
};

export default DeleteTransactionAlertDialog;
