import React, { useContext, useState, useEffect } from 'react';
import { useToast, Stack, Text, Box } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';

import {
  BaselaneResponsiveCell,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableSpacer,
  BaselaneResponsiveCellAmount,
  BaselaneResponsiveCellInput,
  BaselaneChip,
  BaselaneResponsiveCellTitleContent,
  BaselaneResponsiveCellAmountContent,
  BaselaneTooltip,
  T2WithTitleDropdown,
  T2Dropdown,
  NoPropertyTooltip,
} from '@shared/components';
import CashFlowContext from '@contexts/CashFlowContext';
import formatCurrency from '@core/utils/formatCurrency';
import { GET_TRANSACTIONS } from '@core/apollo/queries';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { getOptionsWithSubCategories } from '@core/components/CashFlowPage/helpers/cashflow.helpers';
import TransactionContext from '@contexts/TransactionContext';
import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';

import {
  getPropertyData,
  renderPropertyDropdownParentItem,
} from '@shared/helpers/propertiesFilter.helpers';

import {
  Icon16CheckCircle,
  Icon16InProgress,
  Icon16Split,
  Icon16Attachment,
  Icon16Hide,
} from '@icons/16px';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { TRANSACTION } from '@routes';
import { truncatedText } from '@shared/styles/text.style';
import { dateFormatter } from '@core/utils/formatDate';
import '@core/styles/reactDatepicker.scss';

import TransactionListItemCheckbox from './TransactionListItemCheckbox';
import {
  DisplayInputTrxCategoryItem,
  DisplayInputTrxCategoryItemContent,
  DisplayInputTrxPropertyItem,
  DisplayInputTrxPropertyItemContent,
} from './DisplayInputVariations';
import { transactionStyles } from './styles/transactionsTable.styles';
import type { Transaction } from './types';
import {
  handleCategorySubmit,
  handlePropertySubmit,
  getOrderedTransactions,
  updateTransactionSummaryData,
} from './helpers/trxItem.helper';
import './styles/transactionsCell.styles.scss';
import CustomToast from './CustomToast';

const dummyDataComponent = (title) => {
  return (
    <BaselaneResponsiveCellTitleContent
      title={title}
      titleSize="sm"
      subtitleTextStyle="xs"
      styles={{
        titleTextStyle: { color: 'brand.neutral.700' },
      }}
    />
  );
};

type TransactionsListItemProps = {
  selectedTransactions: Array<Transaction>,
  transaction: Transaction,
  actionDeselectAll: boolean,
  setActionDeselectAll: Function,
  onSelectAll: Function,
  allSelected: boolean,
  handleSelectedTransactions: Function,
  showDummyData: boolean,
  showHiddenTransactions: boolean,
  showOnlyWithAttachments: boolean,
  index: number,
  isOutsideTrxPage: boolean,
  isTableOnly: boolean,
};

const TransactionsListItem = ({
  transaction,
  selectedTransactions,
  actionDeselectAll,
  setActionDeselectAll,
  onSelectAll,
  allSelected,
  handleSelectedTransactions,
  showDummyData,
  showOnlyWithAttachments,
  showHiddenTransactions,
  index,
  isOutsideTrxPage,
  isTableOnly = false,
}: TransactionsListItemProps) => {
  const { isMin1150 } = useBreakPoints();
  const { checkGate } = useStatsigClient();

  const navigate = useNavigate();
  const location = useLocation();

  const { cashFlowRefetch } = useContext(CashFlowContext);

  // Get values from TransactionContext
  const {
    categoryWithSubOptions,
    updateTransactions,
    refetchTransactionsSummaryData,
    filters,
    propertyIdsMap,
    accountMap,
    categoryMap,
    propertiesData,
    categoryIdsMap,
    summaryFilter,
  } = useContext(TransactionContext);

  // Transaction item properties
  const {
    id,
    date,
    name,
    merchantName,
    amount,
    tagId,
    propertyId,
    unitId,
    bankAccountId,
    pending: isPending,
    note,
    parentTransaction,
    categoryDummyName,
    isDocumentUploaded,
    bankAccountDummyName,
    propertyDummyName,
    hidden: isHidden,
    isReviewedByUser,
    tagIdSource,
    propertyTagIdSource,
  } = transaction || {};

  const { isSplit } = parentTransaction || { isSplit: false };
  const categorySelected = tagId
    ? { id: categoryIdsMap[tagId], name: categoryMap[tagId], rule: tagIdSource }
    : null;

  const [fieldNote, setFieldNote] = useState(note?.text ?? '');

  const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);
  const propertyOptions = getPropertyData(propertiesData);

  const [isUpdateReviewedLoading, setIsUpdateReviewedLoading] = useState(false);

  const { chevron, note: noteCellStyles } = transactionStyles ?? {};

  const selectedDateMonthAndDay = dateFormatter(date, 'MMM d');
  const selectedDateYear = dateFormatter(date, 'yyyy');

  const { isNegative: isFormattedAmountNegative, inDollars: formattedAmount } =
    formatCurrency(amount, true, false, true) || {};

  const isMigration = name?.includes('MIGRATION_OUT');

  const isFromBankMigration = isMigration;

  // original account name display logic
  const getAccountNames = () => {
    const map = accountMap()[bankAccountId];
    const subAccNameTag = (n) => {
      return isFromBankMigration ? `[OLD] ${n}` : n;
    };
    return {
      accountName: typeof map === 'object' ? map.name : map,
      accountNameSubText: typeof map === 'object' ? subAccNameTag(map.subAccountName) : null,
    };
  };

  const { accountName, accountNameSubText } = getAccountNames();

  // Toasters
  const toast = useToast();
  const showSuccessToast = (updatedField) =>
    toast({
      description: updatedField ? `${updatedField} Updated Successfully` : 'Updated Successfully',
      status: 'success',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });
  const showErrorToast = (updatedField) =>
    toast({
      description: updatedField ? `${updatedField} Failed to Update` : 'Failed to Update',
      status: 'error',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  // Update Transaction Fn
  // Note: any changes here to also double check TransactionDetailsDrawer
  async function updateTransaction(
    parameter,
    hasNoteUpdated,
    toastProps,
    onSuccess,
    optimisticResponse
  ) {
    const { showErrToast, showSuccessfulToast, updatedField } = toastProps ?? {};

    await updateTransactions({
      variables: { input: { id, ...parameter } },
      ...(optimisticResponse ? { ...optimisticResponse } : {}),
      update: (cache, { data: { updateTransactions: update } }) => {
        let updatedTransaction = update;
        if (Array.isArray(update)) {
          const [t] = update;
          updatedTransaction = t;
        }

        const { transaction: cachedTransactions } =
          cache.readQuery({
            query: GET_TRANSACTIONS,
            variables: { input: { ...filters } },
          }) ?? {};

        // remove items from end of list if it is greater than the page limit trx
        const orderedTransactions = getOrderedTransactions({
          cachedTransactions,
          filters,
          propertyIdsMap,
          updatedTransaction,
        });

        // account for category change
        updateTransactionSummaryData({ transaction, updatedTransaction, cache, summaryFilter });

        cache.writeQuery({
          query: GET_TRANSACTIONS,
          variables: { input: { ...filters } },
          data: {
            transaction: orderedTransactions,
          },
        });

        // get reviewed totals again in case of isReviewedByUser update
        if (parameter?.isReviewedByUser !== transaction?.isReviewedByUser) {
          refetchTransactionsSummaryData();
        }
      },
      onCompleted: (data) => {
        const hasAutoTaggingFeature = checkGate(FEATURE_GATES.AUTO_TAGGING_GATE);
        const newCategoryId = data?.updateTransactions[0]?.tagId;
        const newPropertyId = data?.updateTransactions[0]?.propertyId;
        const newUnitId = data?.updateTransactions[0]?.unitId;

        const categoryChanged = newCategoryId && newCategoryId !== tagId;
        const propertyChanged =
          (newPropertyId && newPropertyId !== propertyId) || (newUnitId && newUnitId !== unitId);

        if (hasAutoTaggingFeature && (categoryChanged || propertyChanged)) {
          const merchantValue = data?.updateTransactions[0]?.merchantName;
          const descriptionValue = data?.updateTransactions[0]?.description;

          const categoryName = categoryWithSubOptions
            .flatMap(({ items }) => items)
            .find((x) => x.id === newCategoryId)?.name;

          const toastMessage = categoryChanged
            ? `Updated to ${categoryName}`
            : 'Updated Transaction';

          setTimeout(() => {
            const toastId = toast({
              position: 'bottom-left',
              duration: 7000,
              render: () => (
                <CustomToast
                  toastId={toastId}
                  message={toastMessage}
                  categoryId={newCategoryId}
                  categoryValue={merchantValue || descriptionValue}
                  propertyId={newPropertyId?.toString()}
                  unitId={newUnitId?.toString()}
                />
              ),
            });
          }, 1000);
        }

        cashFlowRefetch();
        if (showSuccessfulToast) showSuccessToast(updatedField);
        if (onSuccess) onSuccess();
      },
      onError: () => {
        if (showErrToast) showErrorToast(updatedField);
      },
    });
  }

  // Submit handlers
  function handleNoteSubmit(newNote: string, toastProps = null) {
    if (note?.text?.trim() !== newNote?.trim()) {
      updateTransaction({ note: newNote }, true, toastProps, null, {
        optimisticResponse: { updateTransactions: { ...transaction, note: { text: newNote } } },
      });
    }
  }

  function handleNoteFieldUpdate(newNote: string, toastProps = null) {
    if (fieldNote?.trim() !== newNote?.trim()) {
      setFieldNote(newNote);
      handleNoteSubmit(newNote, toastProps);
    }
  }

  function handleReviewedByUser() {
    setIsUpdateReviewedLoading(true);
    updateTransaction({ isReviewedByUser: !isReviewedByUser }, true, null, null, {
      optimisticResponse: {
        updateTransactions: { ...transaction, isReviewedByUser: !isReviewedByUser },
      },
    }).then(() => setIsUpdateReviewedLoading(false));
  }

  const getSelectedProperty = () => {
    let selectedProperty = null;
    if (propertyId && unitId) {
      selectedProperty = {
        id: `${propertyId}-${unitId}`,
        showValueName: `${propertyIdsMap[`p-${propertyId}`]?.name}, ${
          propertyIdsMap[`u-${unitId}`]?.name
        }`,
        rule: propertyTagIdSource,
      };
    } else if (propertyId && !unitId) {
      selectedProperty = {
        id: `${propertyId}`,
        showValueName: propertyIdsMap[`p-${propertyId}`]?.name,
        rule: propertyTagIdSource,
      };
    }

    return selectedProperty;
  };

  const categoryDropdownCell = (
    <BaselaneResponsiveCell
      stopEventPropagation
      configIndex={6}
      contentContainerStyles={{ display: 'block' }}
      id="category-dropdown-cell"
    >
      {showDummyData ? (
        dummyDataComponent(categoryDummyName)
      ) : (
        <T2WithTitleDropdown
          additionalProps={{ id: 'category-dropdown' }}
          classNames={[
            'border-on-hover',
            'clear-on-hover',
            'carret-on-hover',
            'fixed-width-dropdown',
            'auto-select-input-width',
          ]}
          data={categoryOptions}
          title="Category"
          showValueByFields={['name']}
          placeholder="Select category"
          parentItemRenderer={itemRenderer}
          childItemRenderer={itemRenderer}
          handleSubmit={(newId) =>
            handleCategorySubmit(updateTransaction, transaction, newId, {
              showErrToast: true,
              showSuccessfulToast: false,
              updatedField: 'Category',
            })
          }
          selectedItem={categorySelected}
          CustomDisplayInput={DisplayInputTrxCategoryItem}
        />
      )}
    </BaselaneResponsiveCell>
  );

  const handleOnTrxListItemClick = () => {
    if (isOutsideTrxPage) {
      navigate({
        pathname: `${location?.pathname}/${TRANSACTION}/${transaction.id}`,
        state: { from: location.pathname },
      });
    } else {
      navigate({ pathname: `${location?.pathname}/${transaction.id}` });
    }
  };

  // Update list ui when note is updated from drawer
  useEffect(() => {
    setFieldNote(note?.text);
  }, [note]);

  const descriptionTitle = merchantName || name;

  const noteInputRef = React.useRef(null);

  return (
    <>
      {/* Mobile table row */}
      <BaselaneResponsiveTableRow
        onClick={handleOnTrxListItemClick}
        chevronCellStyles={chevron}
        id={`table-row-${index}`}
        contentContainerStyles={{
          alignItems: 'space-between',
          display:
            (!showHiddenTransactions && transaction.hidden) ||
            (showOnlyWithAttachments && !transaction.isDocumentUploaded)
              ? 'none'
              : { lg: 'none', base: 'table-row' },
        }}
      >
        <BaselaneResponsiveCell
          configIndex={0}
          isVertical
          {...{
            py: 1.5,
            px: 1.5,
            overflow: 'initial',
          }}
          contentContainerStyles={{
            spacing: 0.75,
          }}
        >
          {/* description + amount */}
          <Stack
            direction="row"
            justifyContent="space-between"
            w="100%"
            {...{ overflow: 'initial' }}
          >
            <BaselaneResponsiveCellTitleContent
              title={descriptionTitle}
              rightTitleElement={
                isPending && <BaselaneChip size="sm" label="Pending" variant="status-primary" />
              }
              titleSize="sm-heavy"
              styles={{
                titleTextStyle: { color: 'brand.neutral.700' },
                subtitleTextStyle: { color: 'brand.neutral.600' },
                textContainer: { width: 'auto' },
                titleContainer: { gap: 0.5 },
              }}
            />

            <Stack direction="row" alignItems="center">
              <BaselaneResponsiveCellAmountContent
                leftGraphicElement={
                  isSplit ? (
                    <Box mr={0.5}>
                      <Icon16Split color="neutral.500" />
                    </Box>
                  ) : null
                }
                textStyle="data-sm"
                color={isFormattedAmountNegative ? 'brand.neutral.700' : 'green.800AA'}
              >
                {formattedAmount}
              </BaselaneResponsiveCellAmountContent>
            </Stack>
          </Stack>

          {/* property + date */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            w="100%"
            overflow="initial"
            {...truncatedText}
          >
            {showDummyData ? (
              dummyDataComponent(propertyDummyName)
            ) : (
              <DisplayInputTrxPropertyItemContent
                selectedOptions={getSelectedProperty() ? [getSelectedProperty()] : []}
                placeholder="Property not selected"
                showValueByFields={['showValueName']}
                color="brand.neutral.500"
              />
            )}

            {/* Date */}
            <Text {...{ textStyle: 'sm', color: 'brand.neutral.700' }}>
              {date ? selectedDateMonthAndDay : '-'}
            </Text>
          </Stack>

          {/* category */}
          <Stack direction="row" alignItems="center" w="100%" overflow="initial" {...truncatedText}>
            {showDummyData ? (
              dummyDataComponent(propertyDummyName)
            ) : (
              <DisplayInputTrxCategoryItemContent
                placeholder="Category not selected"
                selectedOptions={categorySelected ? [categorySelected] : []}
                categoryMap={categoryMap}
                color="brand.neutral.500"
              />
            )}
          </Stack>
        </BaselaneResponsiveCell>
      </BaselaneResponsiveTableRow>

      {/* Desktop table row */}
      <BaselaneResponsiveTableRow
        onClick={handleOnTrxListItemClick}
        chevronCellStyles={
          checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) ? { display: 'none' } : chevron
        }
        id={`table-row-${index}`}
        contentContainerStyles={{
          display:
            (!showHiddenTransactions && transaction.hidden) ||
            (showOnlyWithAttachments && !transaction.isDocumentUploaded)
              ? 'none'
              : { lg: 'table-row', base: 'none' },
        }}
        ignoredId="reviewed-cell"
      >
        {!isTableOnly && (
          <TransactionListItemCheckbox
            {...{
              transaction,
              actionDeselectAll,
              setActionDeselectAll,
              onSelectAll,
              allSelected,
              selectedTransactions,
              handleSelectedTransactions,
              index,
            }}
          />
        )}

        {/* Date  */}
        <BaselaneResponsiveCellTitle
          title={date ? selectedDateMonthAndDay : '-'}
          subtitle={date ? selectedDateYear : null}
          titleSize="sm"
          subtitleTextStyle="xs"
          configIndex={1}
          styles={{
            titleTextStyle: { color: 'brand.neutral.700' },
            subtitleTextStyle: { color: 'brand.neutral.600' },
          }}
        />

        {/* Account */}
        {showDummyData ? (
          <BaselaneResponsiveCell configIndex={2}>
            {dummyDataComponent(bankAccountDummyName)}
          </BaselaneResponsiveCell>
        ) : (
          <BaselaneResponsiveCellTitle
            title={accountName || 'Manually Added'}
            subtitle={accountNameSubText}
            titleSize="sm"
            subtitleTextStyle="xs"
            configIndex={2}
            styles={{
              titleTextStyle: { color: 'brand.neutral.700' },
              subtitleTextStyle: { color: 'brand.neutral.600' },
            }}
          />
        )}

        {/* Description */}
        <BaselaneResponsiveCell configIndex={3}>
          <BaselaneResponsiveCellTitleContent
            title={descriptionTitle}
            subtitle={descriptionTitle === name ? null : name}
            rightTitleElement={
              isPending && <BaselaneChip size="sm" label="Pending" variant="status-primary" />
            }
            titleSize="sm-heavy"
            styles={{
              titleTextStyle: { color: 'brand.neutral.700' },
              subtitleTextStyle: { color: 'brand.neutral.600' },
            }}
          />
        </BaselaneResponsiveCell>

        {/* 4. Amount */}
        <BaselaneResponsiveCellAmount
          configIndex={4}
          textStyle="data-sm"
          color={isFormattedAmountNegative ? 'brand.neutral.700' : 'green.800AA'}
          leftGraphicElement={
            (isSplit || isHidden) && (
              <Stack direction="row">
                {isHidden ? <Icon16Hide /> : null}
                {isSplit ? <Icon16Split color="neutral.500" /> : null}
              </Stack>
            )
          }
        >
          {formattedAmount}
        </BaselaneResponsiveCellAmount>

        {/* 5. Spacer */}
        <BaselaneResponsiveTableSpacer configIndex={6} />

        {/* 6. Category / Category & property */}
        {isMin1150 ? (
          categoryDropdownCell
        ) : (
          <BaselaneResponsiveCell
            configIndex={6}
            stopEventPropagation
            isVertical
            id="category-property-cell"
            contentContainerStyles={{
              alignItems: 'flex-start!important',
              overflow: 'hidden',
              spacing: 0.75,
            }}
          >
            <DisplayInputTrxPropertyItemContent
              selectedOptions={getSelectedProperty() ? [getSelectedProperty()] : []}
              placeholder="Property not selected"
              showValueByFields={['showValueName']}
              color="brand.neutral.500"
            />
            <DisplayInputTrxCategoryItemContent
              placeholder="Category not selected"
              selectedOptions={categorySelected ? [categorySelected] : []}
              categoryMap={categoryMap}
              color="brand.neutral.500"
            />
          </BaselaneResponsiveCell>
        )}

        {/* 7. Property placeholder  */}
        {showDummyData ? (
          <BaselaneResponsiveCell stopEventPropagation configIndex={7} p="0">
            {dummyDataComponent(propertyDummyName)}
          </BaselaneResponsiveCell>
        ) : (
          <BaselaneResponsiveCell
            configIndex={7}
            stopEventPropagation
            contentContainerStyles={{ display: 'block' }}
            id="property-dropdown-cell"
          >
            {propertyOptions?.length === 0 ? (
              <NoPropertyTooltip>
                <T2Dropdown
                  classNames={['auto-select-input-width', 'carret-on-hover']}
                  placeholder="Select property"
                  CustomDisplayInput={DisplayInputTrxPropertyItem}
                  isDisabled
                />
              </NoPropertyTooltip>
            ) : (
              <T2Dropdown
                additionalProps={{ id: 'property-dropdown' }}
                classNames={[
                  'border-on-hover',
                  'clear-on-hover',
                  'carret-on-hover',
                  'fixed-width-dropdown',
                  'auto-select-input-width',
                ]}
                data={propertyOptions}
                title="Property"
                placeholder="Select property"
                showValueByFields={['showValueName']}
                parentItemRenderer={({ item }) => renderPropertyDropdownParentItem(item)}
                childItemRenderer={itemRenderer}
                handleSubmit={(newPropertyId) =>
                  handlePropertySubmit(updateTransaction, transaction, newPropertyId, {
                    showErrToast: true,
                    showSuccessfulToast: false,
                    updatedField: 'Property',
                  })
                }
                selectedItem={getSelectedProperty()}
                CustomDisplayInput={DisplayInputTrxPropertyItem}
              />
            )}
          </BaselaneResponsiveCell>
        )}

        {/* 8. Note   */}
        {showDummyData ? (
          <BaselaneResponsiveCell configIndex={7} textStyle="sm">
            {dummyDataComponent(note?.text)}
          </BaselaneResponsiveCell>
        ) : (
          <BaselaneResponsiveCellInput
            ref={noteInputRef}
            name="note"
            stopEventPropagation
            enableReinitialize
            configIndex={8}
            placeholder="Add note"
            value={fieldNote}
            handleStateUpdate={(newNote) =>
              handleNoteFieldUpdate(newNote, {
                showErrToast: true,
                showSuccessfulToast: false,
                updatedField: 'Note',
              })
            }
            handleSubmit={(e) => {
              e.preventDefault();
              noteInputRef.current && noteInputRef.current.blur();
            }}
            rightGraphicElement={isDocumentUploaded ? <Icon16Attachment /> : null}
            styles={
              note?.text
                ? { ...noteCellStyles, ...noteCellStyles.activeText }
                : { ...noteCellStyles, ...noteCellStyles.placeholderText }
            }
          />
        )}

        {/* 9. Amount */}
        {!isTableOnly && (
          <BaselaneResponsiveCellAmount
            configIndex={9}
            textStyle="data-sm"
            color={isFormattedAmountNegative ? 'brand.neutral.700' : 'green.800AA'}
            leftGraphicElement={isSplit ? <Icon16Split color="neutral.500" /> : null}
          >
            {formattedAmount}
          </BaselaneResponsiveCellAmount>
        )}

        {/* 10. Reviewed */}
        {checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) && (
          <>
            {showDummyData && (
              <BaselaneResponsiveCell
                configIndex={10}
                textStyle="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (!isUpdateReviewedLoading) {
                    handleReviewedByUser();
                  }
                }}
                zIndex={9}
                contentContainerStyles={{
                  color: isReviewedByUser ? 'green.800' : 'brand.neutral.500',
                  _hover: {
                    color: isReviewedByUser ? 'green.700' : 'brand.neutral.500',
                  },
                  _active: {
                    color: isReviewedByUser ? 'green.900' : 'brand.neutral.700',
                  },
                }}
              >
                {dummyDataComponent(isReviewedByUser ? 'Reviewed' : 'Not Reviewed')}
              </BaselaneResponsiveCell>
            )}
            {!showDummyData && (
              <BaselaneResponsiveCell
                id="reviewed-cell"
                configIndex={10}
                className="review-cell-hover"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (!isUpdateReviewedLoading) {
                    handleReviewedByUser();
                  }
                }}
                pr="0"
                contentContainerStyles={{
                  pr: '0!important',
                  color: isReviewedByUser ? 'green.800' : 'brand.neutral.500',
                  _hover: {
                    background: 'brand.darkBlue.50',
                    color: isReviewedByUser ? 'green.700' : 'brand.neutral.500',
                    '& > span > div': {
                      bg: isReviewedByUser ? 'green.50' : 'green.50',
                    },
                  },
                  _active: {
                    background: 'brand.darkBlue.100',
                    color: isReviewedByUser ? 'green.900' : 'brand.neutral.700',
                    '& > span > div': {
                      bg: isReviewedByUser ? 'brand.darkBlue.100' : 'green.200',
                    },
                  },
                }}
              >
                {!isReviewedByUser && (
                  <BaselaneTooltip
                    placement="top"
                    label="Click to mark as reviewed"
                    styles={{ marginRight: '24px' }}
                  >
                    <Box
                      width="32px"
                      height="32px"
                      mx="8px"
                      my="14px"
                      borderRadius="50%"
                      bg="brand.darkBlue.100"
                      p="8px"
                    >
                      <Icon16InProgress />
                    </Box>
                  </BaselaneTooltip>
                )}
                {isReviewedByUser && (
                  <BaselaneTooltip
                    placement="top"
                    label="Click to mark as Needs review"
                    styles={{ marginRight: '24px' }}
                  >
                    <Box
                      width="32px"
                      height="32px"
                      mx="8px"
                      my="14px"
                      borderRadius="50%"
                      bg="green.100"
                      p="8px"
                    >
                      <Icon16CheckCircle />
                    </Box>
                  </BaselaneTooltip>
                )}
              </BaselaneResponsiveCell>
            )}
          </>
        )}
      </BaselaneResponsiveTableRow>
    </>
  );
};

export default TransactionsListItem;
