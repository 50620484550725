import React, { useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FormControl, Switch, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';
import { BOOKKEEPING_RULES } from '@routes';
import { FEATURE_GATES } from '@core/constants/statsigKeys';

import UserAccessContext from '@contexts/UserAccessContext';
import {
  BaselaneAutoTagPropertyField,
  BaselaneFormLabel,
  BaselaneToolpop,
} from '@shared/components';
import { Icon16Info } from '@icons/16px';
import { fieldAutotagStyles } from '../styles/form.styles';

const AccountAutoTag = () => {
  const { checkGate } = useStatsigClient();

  const { values, handleChange, setFieldValue, validateForm, dirty } = useFormikContext();
  const { authorizedForBanking } = useContext(UserAccessContext);
  const handleSwitch = (e) => {
    handleChange(e);
    if (!e.target.checked) {
      setFieldValue('autoTag.propertyId', null);
      setFieldValue('autoTag.propertyUnitId', null);
    }
  };

  useEffect(() => {
    if (dirty) {
      validateForm();
    }
  }, [values]);

  return (
    authorizedForBanking && (
      <>
        <FormControl {...fieldAutotagStyles.container}>
          <BaselaneFormLabel htmlFor="autoTag.enabled" display="flex" flexDirection="row">
            Auto-tag all transactions to a property or unit{' '}
            {checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) && (
              <BaselaneToolpop
                ml={1}
                styles={{
                  maxW: '216px',
                }}
                placement="bottom-end"
                trigger={<Icon16Info />}
              >
                <Text textStyle="xs" maxW="216px">
                  Automate your bookkeeping by having all transactions on this account tagged to a
                  specific property. You can customize it further on the{' '}
                  <Link to={`/${BOOKKEEPING_RULES}`} style={{ textDecoration: 'underline' }}>
                    bookkeeping rules page
                  </Link>
                  .
                </Text>
              </BaselaneToolpop>
            )}
          </BaselaneFormLabel>
          <Switch
            id="autoTag.enabled"
            name="autoTag.enabled"
            isChecked={values.autoTag.enabled}
            value={values.autoTag.enabled}
            onChange={handleSwitch}
          />
        </FormControl>
        {values.autoTag.enabled && <BaselaneAutoTagPropertyField isDisabled={false} />}
      </>
    )
  );
};

export default AccountAutoTag;
