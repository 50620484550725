import React from 'react';
import { isMobile } from 'react-device-detect';
import { useMutation } from '@apollo/client';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';
import { Box, HStack, Spacer, Stack, Text, useToast } from '@chakra-ui/react';
import { SEND_REFERRAL_TO_PHONE } from '@core/apollo/queries';
import {
  BaselaneButton,
  BaselaneCard,
  BaselaneDivider,
  BaselaneShareLink,
  BaselaneLink,
} from '@shared/components';
import { useCurrentWorkspace } from '@shared/hooks';
import { IconLogOut } from '@icons';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { infoBoxConfig, TERMS_CONDITIONS_URL } from './helpers/referral.helpers';
import { referralStyles } from './styles/referral.styles';

function Referral() {
  const toast = useToast();
  const { workspaceMetadata } = useCurrentWorkspace();

  const { referralLink } = workspaceMetadata ?? {};
  const { card, container, top, bottom, divider } = referralStyles() ?? {};
  const [sendReferralToPhone] = useMutation(SEND_REFERRAL_TO_PHONE);

  const SMSSuccessToast = () =>
    toast({
      description: 'Text sent to your phone',
      status: 'success',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  const SMSErrorToast = () =>
    toast({
      description: 'Text sending failed. Try again',
      status: 'error',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  const handleButtonClickOnMobile = () => {
    if (navigator?.share) {
      navigator
        .share({
          title: 'Sign up to Baselane',
          url: referralLink,
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // fallback if needed later
    }
  };

  const sendReferralSegmentEvent = (type) => {
    sendSegmentEvent('landlord_referral_click_button', { button_type: type });
  };

  return (
    <BaselaneCard styles={card}>
      <Stack {...container}>
        {/* Top Part */}
        <HStack {...top.container}>
          {/* Left Part */}
          <Stack {...top.left.container}>
            <Text {...top.left.title}>Earn $100 for Every Landlord You Refer</Text>
            <Text {...top.left.description}>
              Invite other landlords and earn $100 when they collect rent into their Baselane
              Banking account. They get $100 too.
            </Text>

            <Box {...top.left.sharecontainer}>
              <BaselaneShareLink
                {...{
                  link: referralLink,
                  text: {
                    default: isMobile ? 'Share' : 'Copy Link',
                    copied: isMobile ? 'Share' : 'Copy Link',
                  },
                  buttonProps: {
                    variant: 'filled',
                    palette: 'primary',
                    size: isMobile ? 'lg' : 'md',
                    icon: {
                      color: '#fff',
                    },
                  },
                  handleButtonClickOnMobile,
                  sendDesktopSegmentEvent: () => sendReferralSegmentEvent('copy-link'),
                }}
              />
            </Box>

            <HStack {...top.left.socialcontainer}>
              <BaselaneButton
                variant="transparent"
                palette="neutral"
                leftIcon={<IconLogOut w={14.17} h={14.17} />}
                onClick={() => {
                  sendReferralSegmentEvent('send_by_text');
                  sendReferralToPhone()
                    .then(() => {
                      SMSSuccessToast();
                    })
                    .catch(() => {
                      SMSErrorToast();
                    });
                }}
                styles={{ ...top.left.sendtophone }}
              >
                Send to your phone
              </BaselaneButton>
              <Spacer {...top.left.spacer} />

              {/* Social Media Links */}
              <HStack m="0 !important">
                <EmailShareButton
                  url={referralLink}
                  beforeOnClick={() => sendReferralSegmentEvent('send_by_email')}
                  subject="🎁 $100 bonus if you join Baselane"
                  body="Want to simplify your rental finances? Check out Baselane - it’s banking,
                        rent collection, bookkeeping and more, all for free. Sign up using my link
                        and we both earn $100."
                >
                  <EmailIcon size={40} round />
                </EmailShareButton>

                <FacebookShareButton
                  url={referralLink}
                  beforeOnClick={() => sendReferralSegmentEvent('facebook')}
                >
                  <FacebookIcon size={40} round />
                </FacebookShareButton>

                <LinkedinShareButton
                  url={referralLink}
                  beforeOnClick={() => sendReferralSegmentEvent('linkedin')}
                  source="Baselane"
                >
                  <LinkedinIcon size={40} round />
                </LinkedinShareButton>
              </HStack>
            </HStack>
          </Stack>

          {/* Right Part */}
          <Box {...top.right} />
        </HStack>

        <BaselaneDivider styles={divider} />

        {/* Bottom Part */}
        <Stack {...bottom.container}>
          <Text {...bottom.title}>How It Works</Text>
          <HStack {...bottom.infobox.container}>
            {infoBoxConfig.map((box) => {
              const { id, step, title, description } = box ?? {};
              return (
                <Box {...bottom.infobox.box} key={id}>
                  <Text {...bottom.infobox.step}>{step}</Text>
                  <Stack {...bottom.infobox.titleContainer}>
                    <Text {...bottom.infobox.title}>{title}</Text>
                    <Text {...bottom.infobox.description}>{description}</Text>
                  </Stack>
                </Box>
              );
            })}
          </HStack>
          <Text {...bottom.disclaimer}>
            To participate in the referral program, you yourself must be collecting rent via
            Baselane into a Baselane Banking account. Referred user has up to 45 days to open a
            Baselane Banking account and collect rent into that account via Baselane. Referral
            bonuses are capped at $1,000 per year.{' '}
            <BaselaneLink variant="primary" href={TERMS_CONDITIONS_URL} isExternal>
              See details
              {/* check  */}
            </BaselaneLink>
          </Text>
        </Stack>
      </Stack>
    </BaselaneCard>
  );
}

export default Referral;
