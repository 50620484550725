import React from 'react';
import { Text, Box, List, ListItem } from '@chakra-ui/react';
import { Icon16Delete } from '@icons/16px';
import IconInfo from '@icons/manual/IconInfo';
import { BaselaneButton, BaselaneTooltip, BaselaneButtonGroup } from '@shared/components';

type DrawerFooterProps = {
  onClose: Function,
  isValid: boolean,
  isDirty: boolean,
  hideFooter: boolean,
  handleSave: Function,
  handleDelete: Function,
  canDeleteProperty: boolean,
  property: Object,
  tabIndex: Number,
  formRef: any,
  shortForm?: Boolean,
  isLoading: Boolean,
};

function DrawerFooter({
  onClose,
  isValid,
  isDirty,
  hideFooter,
  handleSave,
  handleDelete,
  canDeleteProperty,
  property,
  tabIndex,
  formRef,
  shortForm,
  isLoading,
}: DrawerFooterProps) {
  const {
    details: isDetailsDirty,
    financials: isFinancialsDirty,
    currentMarketValue: isCurrentMarketValueDirty,
    currentMortgageBalance: isCurrentMortgageBalanceDirty,
  } = isDirty;

  const isFormDirty =
    isDetailsDirty ||
    isFinancialsDirty ||
    isCurrentMarketValueDirty ||
    isCurrentMortgageBalanceDirty;

  const hideDelete = !property;

  const handleSaveAndClose = () => {
    handleSave();
  };

  const handlePropertySave = () => {
    formRef?.current?.validateForm().then((errors) => {
      formRef?.current.setTouched(errors, true);
      if (isValid && Object.keys(errors).length === 0) {
        handleSaveAndClose();
      }
    });
  };

  const getButtonNameRight = () => {
    let name = 'Save & Close';
    if (!property) {
      name = shortForm ? 'Continue to Rent Collection Set up' : 'Create Property';
    }

    return name;
  };

  const buttonNameRight = getButtonNameRight();
  const buttonNameLeft = 'Cancel';
  const isRightButtonDisabled =
    (property && (!isValid || !isFormDirty)) ||
    (!property && tabIndex === 1 && (!isValid || !isFormDirty));

  // for dynamic tooltip context, more detailed info on why we can't delete
  const hasAUnitALease = property?.units?.filter((u) => u?.status === 'occupied').length > 0;
  const hasAUnitADraft =
    property?.units?.filter((u) => u?.lease?.filter((ls) => ls?.state === 'DRAFT').length > 0)
      .length > 0;
  const hasAUnitATenantScreening =
    property?.units?.filter((u) => u?.tenantScreening?.length > 0).length > 0;

  const deletePropertyTooltip = (
    <Box>
      <Text textStyle="xs" mb="8px">
        To delete the property,
      </Text>
      <List spacing="20px" mb="24px" ml="4px">
        {hasAUnitALease && (
          <ListItem display="flex" gap="8px" mb="-16px">
            <Box as="span" mt="-2px">
              ·
            </Box>
            <Text textStyle="xs" color="brand.white.200" lineHeight="16px">
              End and archive the active rent collection(s)
            </Text>
          </ListItem>
        )}
        {hasAUnitADraft && (
          <ListItem display="flex" gap="8px" mb="-16px">
            <Box as="span" mt="-2px">
              ·
            </Box>
            <Text textStyle="xs" color="brand.white.200" lineHeight="16px">
              Delete the draft rent collection(s)
            </Text>
          </ListItem>
        )}
        {hasAUnitATenantScreening && (
          <ListItem display="flex" gap="8px" mb="-16px">
            <Box as="span" mt="-2px">
              ·
            </Box>
            <Text textStyle="xs" color="brand.white.200" lineHeight="16px">
              Stop accepting applications for the active tenant screening(s)
            </Text>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <BaselaneButtonGroup size="md">
      {!shortForm && (
        <BaselaneButton variant="outline" palette="neutral" size="md" onClick={onClose}>
          {buttonNameLeft}
        </BaselaneButton>
      )}
      <BaselaneButton
        variant="filled"
        palette="primary"
        size="md"
        buttonType="submit"
        isDisabled={isRightButtonDisabled}
        isLoading={isLoading}
        onClick={handlePropertySave}
        id={buttonNameRight === 'Save & Close' ? 'save-property-button' : 'create-property-button'}
      >
        {buttonNameRight}
      </BaselaneButton>
      {!hideDelete && (
        <>
          <BaselaneButton
            isDisabled={!canDeleteProperty}
            leftIcon={<Icon16Delete />}
            size="md"
            variant="transparent"
            palette="danger"
            onClick={handleDelete}
            id="delete-property-button"
          >
            Delete Property
          </BaselaneButton>
          {!hideDelete && !canDeleteProperty && (
            <BaselaneTooltip label={deletePropertyTooltip}>
              <IconInfo width="16" height="16" color="#3A4B5B" />
            </BaselaneTooltip>
          )}
        </>
      )}{' '}
    </BaselaneButtonGroup>
  );
}

DrawerFooter.defaultProps = {
  shortForm: false,
};

export default DrawerFooter;
