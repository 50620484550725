import React, { useState, useMemo, useContext, useEffect } from 'react';
import { PhoneAuthProvider, PhoneMultiFactorGenerator } from 'firebase/auth';
import LogRocket from 'logrocket';
import useTwoFactor from '@core/components/Shared/components/TwoFactorVerificationPopUp/hooks/useTwoFactor';
import { handleOTPPopupClose } from '@shared/helpers/otp.helpers';
import { getPhoneAuthProvider, getRecaptchaVerifier } from '@core/Services/Firebase/firebase';
import { trackUser } from '@core/features/Authentication/helpers/tracking.helpers';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { FIREBASE_AUTH_ERROR_CODES } from './helpers/firebaseContants';

const FirebaseOtpContext = React.createContext({});

// The error the BE sends when the user's OTP has expired
export const OTP_REQUIRED_ERROR_CODE = 'OTP_REQUIRED';

// Arbitrary error value that is passed when the OTP promise is rejected
export const OTP_POPUP_DISMISSED = 'firebase-otp-popup-dismissed';

// Number of times to retry sending the otp SMS if the request fails
export const OTP_SEND_RETRY_COUNT = 5;

export const FirebaseOtpProvider = ({ children }: any) => {
  // Workaround that makes React store the callbacks,
  // rather than treat them as a mutation function.
  // These state variables are used to create a Promise,
  // where the resolve => .then((token) => {}) represents the user succeeding in the OTP verification
  // while the reject => .catch((error) => {}) represents them cancelling out of the OTP flow
  // Actual network or validation errors are handled by the OTP UI itself
  const [resolveOTPFlow, _setResolveOTPFlow] = useState(() => {});
  const setResolveOTPFlow = (resolve) => {
    _setResolveOTPFlow(() => resolve);
  };
  const [rejectOTPFlow, _setRejectOTPFlow] = useState(() => {});
  const setRejectOTPFlow = (reject) => {
    _setRejectOTPFlow(() => reject);
  };

  const [verificationId, setVerificationId] = useState(null);
  const [resolver, setResolver] = useState(null);
  const [phoneInfoOptions, setPhoneInfoOptions] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const { states, stateFunctions } = useTwoFactor();
  const { onOTPPopupOpen, setOTPErrorCode } = stateFunctions;

  const state = useMemo(() => {
    const props = {
      ...states,
      phoneNumber,
      ...stateFunctions,
      getOTP: (newPhoneInfoOptions = null, retrySendOtpCount = OTP_SEND_RETRY_COUNT) => {
        const recaptchaVerifier = getRecaptchaVerifier();
        const phoneAuthProvider = getPhoneAuthProvider();
        phoneAuthProvider
          .verifyPhoneNumber(
            newPhoneInfoOptions || { ...phoneInfoOptions, session: resolver.session },
            recaptchaVerifier
          )
          .then((currentVerificationId) => {
            setVerificationId(currentVerificationId);
            sendSegmentEvent('landlord_signin_otp_triggered', {
              user_type: 'landlord',
              event_source: 'landlord_portal',
            });
          })
          .catch((error) => {
            if (error?.code === FIREBASE_AUTH_ERROR_CODES.INVALID_PHONE_NUMBER) {
              LogRocket.log(
                `FirebaseOtpProvider detected an invalid phone number- ${phoneNumber}. Error was:`,
                error
              );
              setOTPErrorCode(90);
              return;
            }

            if (retrySendOtpCount > 0) {
              LogRocket.log(
                `FirebaseOtpProvider failed to getOtp, trying again. Error was:`,
                error
              );
              try {
                recaptchaVerifier?.clear();
              } catch (clearError) {
                LogRocket.log(
                  `FirebaseOtpProvider failed to clear recaptchaVerifier. Error was:`,
                  JSON.stringify(clearError, null, 2)
                );
              }
              props.getOTP(newPhoneInfoOptions, retrySendOtpCount - 1);
            } else {
              try {
                recaptchaVerifier?.clear();
              } catch (clearError) {
                LogRocket.log(
                  `FirebaseOtpProvider failed to clear recaptchaVerifier. Error was:`,
                  JSON.stringify(clearError, null, 2)
                );
              }
              LogRocket.log(
                `FirebaseOtpProvider failed to getOtp ${OTP_SEND_RETRY_COUNT} times- stopping.  Error was:`,
                error
              );
            }
          });
      },
      /**
       * initiates verfication of entered code
       * @param {*} otpCode
       * @returns
       */
      handleVerifyOnClick: (otpCode) => {
        const credential = PhoneAuthProvider.credential(verificationId, otpCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);
        resolver
          .resolveSignIn(multiFactorAssertion)
          .then((userCredential) => {
            trackUser(userCredential.user, 'landlord_signin_otp_verified');
            // on success
            handleOTPPopupClose(states, stateFunctions);
            // resolve the Promise
            resolveOTPFlow(userCredential);
            setOTPErrorCode(null);
            setResolveOTPFlow(null);
          })
          .catch((error) => {
            setOTPErrorCode(100);
          });
      },
      /**
       * Executes whenever the OTP popup is closed
       * This rejects the promise, which has to be
       * caught using the .catch() block, otherwise it
       * shows up as an error.
       * This allows you to run some code should the
       * user close the OTP popup without completing
       * OTP entry successfully.
       */
      onOTPPopupClose: () => {
        handleOTPPopupClose(states, stateFunctions);
        // reject the Promise
        rejectOTPFlow(OTP_POPUP_DISMISSED);
        setRejectOTPFlow(null);
      },
    };

    /**
     * Starts the OTP flow, by opening the global UnitOTPLayout popup
     */
    const verifyFirebaseOtp = (currentResolver) => {
      const { hints } = currentResolver;
      const phoneHint = hints.find((hint) => hint.factorId === PhoneMultiFactorGenerator.FACTOR_ID);
      // assumption - only phone MFA exists
      if (phoneHint) {
        const { phoneNumber: newPhoneNumber } = phoneHint;
        const newPhoneInfoOptions = {
          multiFactorHint: phoneHint,
          session: currentResolver.session,
        };
        const otpPromise = new Promise((resolve, reject) => {
          setResolveOTPFlow(resolve);
          setRejectOTPFlow(reject);

          setPhoneInfoOptions(newPhoneInfoOptions);
          setPhoneNumber(newPhoneNumber);
          setResolver(currentResolver);
        });
        return otpPromise;
      }
      LogRocket.log(`verifyFirebaseOtp - unsupported firebase MFA method requested`);
      throw new Error('verifyFirebaseOtp - unsupported firebase MFA method requested');
    };

    /**
     * A no-op function that does nothing, that can be used to
     * supply to the .catch() block, as that has to be provided
     * even if you want to disregard the result
     */
    const ignore = () => {
      /* do nothing */
    };

    const newState = {
      // For consumption by UnitOTPLayout to configure the OTP UI
      props,
      // For implementing logic in places where OTP is needed
      verifyFirebaseOtp,
      ignore,
    };
    return newState;
  }, [stateFunctions]);

  useEffect(() => {
    if (phoneNumber && phoneInfoOptions) {
      onOTPPopupOpen();
      state.props.getOTP(phoneInfoOptions);
    }
  }, [phoneNumber, phoneInfoOptions]);

  return <FirebaseOtpContext.Provider value={state}>{children}</FirebaseOtpContext.Provider>;
};

export const FirebaseOtpConsumer = FirebaseOtpContext.Consumer;

// Convenience hook
export const useFirebaseOtp = () => {
  return useContext(FirebaseOtpContext);
};
export default FirebaseOtpContext;
