import React, { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { Box, Flex, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import userContext from '@contexts/UserContext';
import IconKey from '@icons/manual/IconKey';
import { BaselaneCardNew } from '@shared/components';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';
import { headingTextStyles } from './styles/fonts.styles';
import Phone from './Phone';

import {
  onboardingContainerStyles,
  onboardingHeaderStyles,
  onboardingContentContainerStyles,
  onboardingContainerWrapperStyles,
} from '../OnboardingTriage/styles/onboardingTriage.styles';

const Greeting = ({ firstName }: { firstName: String }) => {
  return (
    <Box {...{ mb: '16px !important' }}>
      <Text {...headingTextStyles(isMobile)}>Hi {firstName} &#128075;</Text>
      <Text {...onboardingHeaderStyles()}>Welcome to Baselane!</Text>
    </Box>
  );
};

function PhonePage(): any {
  const { user, refetchUser } = useContext(userContext);
  const { pathname = '' } = useLocation();

  const firstName = user?.firstName?.split(' ')?.[0] || user.firstName;

  return (
    <Box {...onboardingContainerWrapperStyles(pathname)}>
      <Flex {...{ ...onboardingContainerStyles, align: 'center' }}>
        <Stack {...onboardingContentContainerStyles(pathname)}>
          <Box w="100%" px="30px" color="brand.neutral.800A">
            <BaselaneFullLogo />
          </Box>
        </Stack>
        <VStack {...{ alignItems: 'flex-start', px: '20px', mt: '8px' }}>
          <Greeting firstName={firstName} />
          <BaselaneCardNew
            {...{ width: isMobile ? '100%' : { xxs: '100%', md: '648px', base: '100%' } }}
          >
            <Flex direction={isMobile ? 'column' : 'row'}>
              <VStack m={isMobile ? '8px 24px 8px 0' : '2px 24px 0 8px'} alignItems="left">
                <IconKey />
              </VStack>
              <VStack
                alignItems="start"
                pr={isMobile ? '0' : '8px'}
                pb={isMobile ? '0' : '8px'}
                w="100%"
              >
                <Heading as="h2" {...headingTextStyles(isMobile)} textAlign="left">
                  Next, enter your mobile phone number
                </Heading>
                <Phone user={user} refetchUser={refetchUser} />
              </VStack>
            </Flex>
          </BaselaneCardNew>
        </VStack>
      </Flex>
    </Box>
  );
}

export default PhonePage;
