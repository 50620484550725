import React, { useContext, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useShallow } from 'zustand/react/shallow';
import { BaselaneSummaryCard, BaselaneSummaryCardDefaultHeader } from '@shared/components';
import TransactionContext from '@contexts/TransactionContext';
import useCSVImportStore from '@store/CSVImport';
import { GET_DOCUMENT_UPLOAD_URL } from '@core/apollo/queries/document';
import formatBytes from '@core/utils/formatBytes';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

import TabPanelLayout from '../components/TabPanelLayout';
import FooterNavigation from '../components/FooterNavigation';
import ErrorModal from './ErrorModal';
import SuccessModal from './SuccessModal';

const CSVPreview = () => {
  const { handleFooterNavigation } = useOutletContext();
  const { getAccountById } = useContext(TransactionContext);
  const {
    CSVFile,
    CSVFileData,
    CSVHeaderMapping,
    mappedCategories,
    mappedProperties,
    account,
  } = useCSVImportStore(
    useShallow((state) => ({
      CSVFile: state.CSVFile,
      CSVFileData: state.CSVFileData,
      CSVHeaderMapping: state.CSVHeaderMapping,
      mappedCategories: state.mappedCategories,
      mappedProperties: state.mappedProperties,
      account: state.account,
    }))
  );

  const { kiloBytes: sizeInKiloBytes } = formatBytes(CSVFile?.size);

  const [hasGenericError, setGenericError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const bankAccount = getAccountById(account?.id);
  const accountName =
    account?.id === 'other' || account?.id === 'manual'
      ? 'Manually Added'
      : bankAccount?.accountName || bankAccount?.nickName;

  const numberOfRows = CSVFileData.length - 1; // minus header row
  const categoriesMapped = mappedCategories?.filter((cat) => !!cat.baselaneCategory) ?? [];
  const categoryInput =
    categoriesMapped.map((cat) => ({
      baselane: cat.baselaneCategory,
      external: cat.externalCategory,
    })) ?? [];
  const propertiesMapped = mappedProperties?.filter((prop) => !!prop.baselaneProperty) ?? [];
  const propertyInput =
    propertiesMapped?.map((prop) => ({
      baselane: prop.baselaneProperty,
      external: prop.externalProperty,
    })) ?? [];

  const categoryIsMapped =
    Object.keys(CSVHeaderMapping).includes('Category') && CSVHeaderMapping.Category;
  const propertyIsMapped =
    Object.keys(CSVHeaderMapping).includes('Property') && CSVHeaderMapping.Property;
  const summaryList = [
    {
      id: 'csv-summary',
      items: [
        {
          id: 'rows',
          label: 'Number of rows',
          value: numberOfRows,
        },
        {
          id: 'bank',
          label: 'Bank account to tag',
          value: accountName,
        },
        ...(categoryIsMapped && categoriesMapped.length
          ? [
              {
                id: 'categories',
                label: 'Mapped categories',
                value: categoriesMapped.length,
              },
              {
                id: 'unrecognized-categories',
                label: 'Unrecognized categories',
                value: mappedCategories?.length
                  ? mappedCategories.length - categoriesMapped.length
                  : 0,
              },
            ]
          : []),
        ...(propertyIsMapped && propertiesMapped.length
          ? [
              {
                id: 'properties',
                label: 'Mapped properties',
                value: propertiesMapped.length,
              },
              {
                id: 'unrecognized-properties',
                label: 'Unrecognized properties',
                value: mappedProperties?.length
                  ? mappedProperties.length - propertiesMapped.length
                  : 0,
              },
            ]
          : []),
      ],
    },
  ];

  const [generateDocumentDownloadUrl] = useLazyQuery(GET_DOCUMENT_UPLOAD_URL, {
    variables: {
      input: {
        filename: CSVFile?.name,
        fileExtension: 'CSV',
        fileSize: sizeInKiloBytes,
        entityType: 'BANK_ACCOUNT',
        documentUploadPurpose: 'TRANSACTION_IMPORT',
        metadata: {
          transactionImportMetadata: {
            merchantName: CSVHeaderMapping.Description,
            date: CSVHeaderMapping.Date,
            amount: CSVHeaderMapping.Amount,
            bankAccountId: account?.id === 'other' || account?.id === 'manual' ? null : +account.id,
            ...(CSVHeaderMapping.Category
              ? {
                  category: CSVHeaderMapping.Category,
                  categoryInput,
                }
              : {}),
            ...(CSVHeaderMapping.Property
              ? {
                  property: CSVHeaderMapping.Property,
                  propertyInput,
                }
              : {}),
            ...(CSVHeaderMapping.Note ? { notes: CSVHeaderMapping.Note } : {}),
          },
        },
      },
    },
  });

  const uploadCSV = async (url) => {
    let result = null;

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/octet-stream',
          'Content-disposition': `attachment; filename=${CSVFile.name}`,
        },
        body: CSVFile,
      });
      if (response.status === 200) {
        result = response;
      } else {
        setGenericError(true);
      }
    } catch (err) {
      setGenericError(true);
    }

    return result;
  };

  const handleSubmit = async () => {
    setLoading(true);
    sendSegmentEvent('csv_import_upload_started');
    try {
      const getUploadUrl = await generateDocumentDownloadUrl();
      if (getUploadUrl?.errors?.length) {
        setGenericError(true);
        setLoading(false);
      } else {
        const { url } = getUploadUrl?.data?.generateDocumentUploadUrl ?? {};
        if (url) {
          const uploadedDocument = await uploadCSV(url);
          if (uploadedDocument) {
            setSuccess(true);
            setLoading(false);
          }
        }
      }
    } catch (err) {
      setGenericError(true);
      setLoading(false);
    }
  };

  return (
    <TabPanelLayout stepNumber={5} title="Review and import">
      <BaselaneSummaryCard
        isCustom
        header={<BaselaneSummaryCardDefaultHeader title={CSVFile?.name} />}
        list={summaryList}
      />

      <FooterNavigation
        isValid
        handleFooterNavigation={handleFooterNavigation}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />

      <ErrorModal isOpen={hasGenericError} onClose={() => setGenericError(false)} />
      <SuccessModal isOpen={isSuccess} onClose={() => setSuccess(false)} />
    </TabPanelLayout>
  );
};

export default CSVPreview;
