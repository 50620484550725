// @flow
// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=4763%3A116979
import React, { useState, useRef, useContext } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useDisclosure, useToast, ChakraProvider } from '@chakra-ui/react';
import UserAccessContext from '@contexts/UserAccessContext';
import { BaselaneButton, BaselaneButtonGroup, BaselaneDrawer } from '@shared/components';
import TransactionContext from '@contexts/TransactionContext';
import onDrawerClose from '@core/utils/onDrawerClose';
import useBreakPoints from '@core/hooks/useBreakPoints';
import habitatTheme from '@core/themeHabitat';
import { useUnitOtp } from '@core/contexts/UnitOtpContext';
import Body from './Body';
import UnsavedChangesAlert from './UnsavedChangesAlert';
import { drawerFooterStyles } from './styles/body.styles';
import useEntityByIdQuery from '../shared/useEntityByIdQuery';
import { UPDATE_BANK_ENTITY } from '../queries';

type EditMasterAccountDrawerProps = {
  from?: String,
};

const EditMasterAccountDrawer = ({ from }: EditMasterAccountDrawerProps) => {
  const location = useLocation();
  const { pathname = '' } = location;
  const isMailingDrawer = pathname.indexOf('edit_entity_address') !== -1;
  const [isClosingDelay, setIsClosingDelay] = useState(false);
  const { isMax576 } = useBreakPoints();
  const { DrawerFooter } = BaselaneDrawer;
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const { refetchAccountsData } = useContext(TransactionContext);
  const { account } = useOutletContext() ?? {};
  const { refetchEntity } = useEntityByIdQuery();
  const { authorizedForBanking } = useContext(UserAccessContext);

  // Alert State
  const { isOpen: isDrawerAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  // Mutations
  const [updateBank] = useMutation(UPDATE_BANK_ENTITY);

  // Toast
  const toast = useToast();
  const showToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Account updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const { bankId } = account || {};

  const { verifyUnitOtp } = useUnitOtp();

  // State variables
  const [isDirty, setIsDirty] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const closeEvent = () => {
    onDrawerClose(navigate, from);
  };

  const cleanup = () => {
    if (isDirty) setIsDirty(false);
    if (isFormValid) setIsFormValid(false);
    if (isSubmitting) setIsSubmitting(false);
  };

  const saveEdits = (values) => {
    updateBank({ ...values }).then(async () => {
      setIsSubmitting(true);
      await refetchEntity();
      await refetchAccountsData();
      setIsSubmitting(false);
      cleanup();
      closeEvent();
      showToast();
    });
  };

  const onCloseWithoutSaving = () => {
    if (isDirty) {
      onAlertOpen();
    } else {
      cleanup();
      closeEvent();
    }
  };

  const handleValidation = (values) => {
    const errors = {};
    if (values.name === '' || values?.name?.trim() === '') errors.name = 'Please enter a nickname.';

    setIsFormValid(Object.keys(errors).length === 0);

    return errors;
  };

  const handleSubmit = (values) => {
    const editedVariables = {
      variables: {
        id: bankId,
        name: values?.name,
      },
    };

    verifyUnitOtp(bankId)
      .then(() => saveEdits(editedVariables))
      .catch(() => setIsSubmitting(false));
  };

  const handleAlertContinueClick = (e) => {
    onAlertClose();
    closeEvent(e);
    cleanup();
  };

  return (
    <ChakraProvider theme={habitatTheme}>
      <UnsavedChangesAlert
        isDrawerAlertOpen={isDrawerAlertOpen}
        onAlertClose={onAlertClose}
        onAlertOpen={onAlertOpen}
        onAlertContinue={(e) => handleAlertContinueClick(e)}
      />

      <BaselaneDrawer
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        isOpen
        title="Entity details"
        closeEvent={onCloseWithoutSaving}
        onClose={onCloseWithoutSaving}
        newDesignDrawer
        newDrawerUseDefaultBodyFooter
        contentStyles={{ opacity: isMailingDrawer && !isClosingDelay ? '0' : '1' }}
      >
        <Body
          account={account}
          formikRef={formikRef}
          isDirty={isDirty}
          isFormValid={isFormValid}
          setIsDirty={setIsDirty}
          handleValidation={handleValidation}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          onClose={onCloseWithoutSaving}
          setIsClosingDelay={setIsClosingDelay}
        />
        {authorizedForBanking && (
          <DrawerFooter {...drawerFooterStyles}>
            <BaselaneButtonGroup size="md" styles={{ width: '100%' }}>
              <BaselaneButton
                size="md"
                variant="outline"
                palette="neutral"
                onClick={onCloseWithoutSaving}
              >
                Cancel
              </BaselaneButton>
              <BaselaneButton
                id="entity-details-save-nickname"
                size="md"
                variant="filled"
                palette="primary"
                isDisabled={!isFormValid || !isDirty}
                isLoading={isSubmitting}
                onClick={(e) => formikRef.current.handleSubmit(e)}
                width="100%"
              >
                Save changes
              </BaselaneButton>
            </BaselaneButtonGroup>
          </DrawerFooter>
        )}
        <Outlet context={{ account }} />
      </BaselaneDrawer>
    </ChakraProvider>
  );
};

export default EditMasterAccountDrawer;

EditMasterAccountDrawer.defaultProps = {
  from: null,
};
