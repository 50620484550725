import React, { useEffect, useState } from 'react';
import { Text, VStack, Link, Box, Image, List, ListItem, Flex } from '@chakra-ui/react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { TENANT_SCREENING_DISCLOSURE } from '@core/constants/routes';
import { BaselaneAlertNew, BaselaneCardStack } from '@shared/components';
import { Icon16Warning } from '@icons/16px';
import { formatDate } from '@core/utils/formatDate';
import ReportHeader from '../components/ReportHeader';
import { getReportByid, isReportDataInvalid } from '../helpers/tenantScreeningReports.helpers';
import ReportUnavailableAlert from '../components/ReportUnavailableAlert';

const Disclosure = () => {
  const { verificationId, externalApplicantId } = useOutletContext();
  const { applicationId } = useParams();

  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (applicationId && externalApplicantId && verificationId) {
      getReportByid(applicationId, externalApplicantId, verificationId)
        .then(({ data }) => {
          if (data?.name === TENANT_SCREENING_DISCLOSURE) setReportData(data);
        })
        .catch((error) => {
          console.error('Error fetching application:', error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [applicationId, externalApplicantId, verificationId]);

  if (isReportDataInvalid(reportData, verificationId)) {
    return <ReportUnavailableAlert isLoading={isLoading} />;
  }

  return (
    <>
      <VStack alignItems="flex-start" gap={4}>
        <BaselaneCardStack
          id="example-cardstack-medium"
          size="md"
          items={[
            {
              id: 'header',
              content: <ReportHeader>Submission disclosure</ReportHeader>,
            },
            {
              id: 'data',
              content: (
                <Box p="8px">
                  <BaselaneAlertNew
                    variant="neutral"
                    visual="icon"
                    iconName={Icon16Warning}
                    color="#C93A3A"
                    mb="24px"
                    body={
                      <Text textColor="#C93A3A">
                        By submitting my application, I hereby warrant that all statements set forth
                        in this application are true.
                      </Text>
                    }
                  />
                  <List spacing="20px" mb="24px" ml="4px">
                    <ListItem display="flex" gap="8px">
                      <Box as="span" mt="-4px">
                        ·
                      </Box>
                      <Text textStyle="xs">
                        I understand that consumer reports and/or investigative consumer reports may
                        be requested in connection with my prospective or continuing housing or
                        guarantor application with <b>Baselane</b> through Boom Pay, Inc. I
                        understand that these reports may include information concerning my
                        character, employment and income verification, general reputation, personal
                        characteristics, public records information, criminal records information,
                        eduction, qualifications, motor vehicle record, mode of living, credit and
                        indebtedness information, and any other information which may reflect upon
                        my potential for tenancy or guarantor status gathered from any individual,
                        organization, entity, agency, or other source which may have knowledge
                        concerning any such items of information.
                      </Text>
                    </ListItem>
                    <ListItem display="flex" gap="8px">
                      <Box as="span" mt="-4px">
                        ·
                      </Box>
                      <Text textStyle="xs">
                        I understand that <b>Baselane</b> may require verification of income,
                        employment, and housing history in connection with my application. I further
                        understand that income, employment, and housing verification require access
                        to my employment, income, and payroll related information as well as access
                        to my current and former landlords. I authorize and consent to the access
                        to, use, and release of such information by Boom Pay, Inc. for the purpose
                        of providing the verification services in relation to my application and
                        during the course of any ongoing lease obligation arising from such
                        application.
                      </Text>
                    </ListItem>
                    <ListItem display="flex" gap="8px">
                      <Box as="span" mt="-4px">
                        ·
                      </Box>
                      <Text textStyle="xs">
                        I understand that if an investigative consumer report is requested about me,
                        I have the right to make a written request to <b>Baselane</b>, within a
                        reasonable period of time, for complete and accurate disclosure of the
                        nature and scope of the investigation requested.
                      </Text>
                    </ListItem>
                    <ListItem display="flex" gap="8px">
                      <Box as="span" mt="-4px">
                        ·
                      </Box>
                      <Text textStyle="xs">
                        I consent to the delivery of all notices or disclosures required by law via
                        any medium so chosen by <b>Baselane</b> or Boom Pay, Inc., including but not
                        limited to email or other electronic tranxsission. I understand that all
                        notices shall be deemed received upon being sent.
                      </Text>
                    </ListItem>
                    <ListItem display="flex" gap="8px">
                      <Box as="span" mt="-4px">
                        ·
                      </Box>
                      <Text textStyle="xs">
                        By submitting this application, I certify that I have read and fully
                        understand the disclosures and my rights detailed above and authorize Boom
                        Pay, Inc. to obtain, for the purpose of determining my eligibility for
                        initial or continued tenancy or guarantor status, consumer reports and/or
                        investigative consumer reports and to disclose all information obtained by
                        Boom Pay, Inc. to <b>Baselane</b>, its affiliates, and assignees now and in
                        the future. I also certify that I have read and fully understand my rights
                        under the FCRA available at{' '}
                        <Link
                          target="_blank"
                          href="https://files.consumerfinance.gov/f/201504_cfpb_summary_your-rights-under-fcra.pdf"
                          whiteSpace="normal"
                          overflowWrap="break-word"
                          wordBreak="break-word"
                          textDecoration="underline"
                        >
                          https://files.consumerfinance.gov/f/201504_cfpb_summary_your-rights-under-fcra.pdf
                        </Link>
                      </Text>
                    </ListItem>
                  </List>
                  <Box alignSelf="flex-start">
                    <Flex justifyContent="space-between" w="100%" mb="24px">
                      <Text fontWeight="semibold" textStyle="sm">
                        {reportData.report.full_name}
                      </Text>
                      <Text fontWeight="semibold" textStyle="sm">
                        {formatDate(reportData.completed_at, 'MMM DD, YYYY')}
                      </Text>
                    </Flex>
                    <Image
                      src={reportData.report.signature?.asset.url}
                      alt="signature"
                      h="90px"
                      w="175px"
                      fallback={
                        <Box
                          backgroundColor="#f0f1f3"
                          h="90px"
                          w="175px"
                          textStyle="xs"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          color="#666"
                          borderRadius="8px"
                        >
                          No image available
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              ),
            },
          ]}
        />
      </VStack>
      <Outlet />
    </>
  );
};

export default Disclosure;
