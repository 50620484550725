/**
 * Initializes and provides the API for the Zendesk Messenger UI
 */

import React from 'react';
import { ZendeskProvider } from '@core/contexts/ZendeskContext';
/**
 * Initializes and provides the API for the Zendesk Messenger UI
 * @param {*} user - The user to initialize the Zendesk provider with
 * @param {*} children - The children to render inside the Zendesk provider
 * @param {*} handle - The handle identifies different instances of the Zendesk provider for debugging purposes
 * @returns The Zendesk provider
 */
const ZendeskWrapper = ({ user = null, children, handle }: any) => {
  return (
    <ZendeskProvider user={user} handle={handle}>
      {children}
    </ZendeskProvider>
  );
};

export default ZendeskWrapper;
