import React from 'react';
import { HStack, Text, Flex, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { USER_WORKSPACE } from '@routes';
import customTheme from '@core/theme';
import { RESEND_COLLABORATOR_INVITE, GET_CURRENT_WORKSPACE } from '@core/apollo/queries';
import { BaselaneButton, BaselaneCardNew } from '@shared/components';
import { Icon16Info, Icon16Invite } from '@core/components/Icons/16px';

function MemberInvitePending({ memberId }: { memberId: Number }): any {
  const navigate = useNavigate();
  const [resendInvite] = useMutation(RESEND_COLLABORATOR_INVITE, {
    refetchQueries: [GET_CURRENT_WORKSPACE],
  });
  const toast = useToast();
  const showSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Invite Send',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Error sending invite',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleResendInvite = () => {
    resendInvite({
      variables: {
        id: memberId,
      },
    })
      .then((res) => {
        if (res.data) {
          showSuccessToast();
          setTimeout(() => {
            navigate({
              pathname: USER_WORKSPACE,
            });
          }, 2000);
        }

        if (res.errors) {
          showErrorToast();
        }
      })
      .catch((err) => showErrorToast());
  };
  return (
    <BaselaneCardNew palette="primary">
      <HStack spacing={2} alignItems="center" w="100%" justifyContent="space-between">
        <Flex gap={1} alignItems="center">
          <Icon16Info color={customTheme.colors.brand.blue['800A']} />
          <Text textStyle="headline-sm">Invite pending</Text>
        </Flex>

        <BaselaneButton
          palette="neutral"
          variant="outline"
          size="md"
          onClick={handleResendInvite}
          leftIcon={<Icon16Invite />}
        >
          Resend Invite
        </BaselaneButton>
      </HStack>
    </BaselaneCardNew>
  );
}

export default MemberInvitePending;
