import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Approve from '@pages/TenantScreening/TenantScreeningReportDetails/MakeDecision/Approve';
import ConditionallyApprove from '@pages/TenantScreening/TenantScreeningReportDetails/MakeDecision/ConditionallyApprove';
import Decline from '@pages/TenantScreening/TenantScreeningReportDetails/MakeDecision/Decline';
import TenantScreeningCreateEdit from '@core/pages/TenantScreening/TenantScreeningCreateEdit';
import {
  TENANT_SCREENING_ADDITIONAL_DOCUMENTS,
  TENANT_SCREENING_APPLICANT,
  TENANT_SCREENING_APPLICATION_LIST,
  TENANT_SCREENING_APPROVE,
  TENANT_SCREENING_CONDITIONALLY_APPROVE,
  TENANT_SCREENING_CREATE,
  TENANT_SCREENING_EDIT,
  TENANT_SCREENING_CREDIT_REPORT,
  TENANT_SCREENING_CRIMINAL_REPORT,
  TENANT_SCREENING_DECLINE,
  TENANT_SCREENING_DISCLOSURE,
  TENANT_SCREENING_EVICTION_HISTORY,
  TENANT_SCREENING_HOUSING_HISTORY,
  TENANT_SCREENING_IDENTITY_VERIFICATION,
  TENANT_SCREENING_INCOME_VERIFICATION,
  TENANT_SCREENING_INVITE,
  TENANT_SCREENING_RENTAL_APPLICATION,
  TENANT_SCREENING_REPORT_DETAILS_APPLICANT,
  TENANT_SCREENING_REPORT_DETAILS_DEFAULT,
  TENANT_SCREENING_REPORT_DETAILS_NO_APPLICANT_ID,
  TENANT_SCREENING_REPORT_DETAILS,
  TENANT_SCREENING_REPORTS_SUMMARY,
} from '@routes';

import ReportsSummary from '@pages/TenantScreening/Reports/ReportsSummary';
import HousingHistory from '@pages/TenantScreening/Reports/HousingHistory';
import TenantScreeningReportDetails from '@pages/TenantScreening/TenantScreeningReportDetails';
import TenantScreeningApplications from '@pages/TenantScreening/TenantScreeningApplications';
import RentalApplication from '@pages/TenantScreening/Reports/RentalApplication';
import IdentityVerification from '@pages/TenantScreening/Reports/IdentityVerification';
import IncomeReport from '@pages/TenantScreening/Reports/IncomeReport';
import CreditReport from '@core/pages/TenantScreening/Reports/CreditReport';
import CriminalReport from '@pages/TenantScreening/Reports/CriminalReport';
import EvictionReport from '@pages/TenantScreening/Reports/EvictionReport';
import Disclosure from '@pages/TenantScreening/Reports/Disclosure';
import AdditionalDocuments from '@pages/TenantScreening/Reports/AdditionalDocuments';
import TenantScreeningInviteDrawer from '@core/pages/TenantScreening/TenantScreeningInviteDrawer';

const MakeDecisionRoutes = () => (
  <Routes>
    <Route path={TENANT_SCREENING_APPROVE} element={<Approve />} />;
    <Route exact path={TENANT_SCREENING_CONDITIONALLY_APPROVE} element={<ConditionallyApprove />} />
    ;
    <Route exact path={TENANT_SCREENING_DECLINE} element={<Decline />} />
  </Routes>
);
const TenantScreeningRoutes = () => {
  return (
    <Routes>
      <Route path={TENANT_SCREENING_CREATE} element={<TenantScreeningCreateEdit />} />
      <Route path={TENANT_SCREENING_APPLICATION_LIST} element={<TenantScreeningApplications />}>
        <Route path={TENANT_SCREENING_INVITE} element={<TenantScreeningInviteDrawer />} />
        <Route path={TENANT_SCREENING_EDIT} element={<TenantScreeningCreateEdit />} />
        <Route
          path={`${TENANT_SCREENING_REPORT_DETAILS}/${TENANT_SCREENING_REPORTS_SUMMARY}`}
          element={<ReportsSummary />}
        >
          <Route path="*" element={<MakeDecisionRoutes />} />
        </Route>
        <Route
          exact
          path={TENANT_SCREENING_REPORT_DETAILS}
          element={
            <Navigate
              to={`${TENANT_SCREENING_APPLICANT}/${TENANT_SCREENING_REPORT_DETAILS_DEFAULT}`}
            />
          }
        />

        <Route
          exact
          path={TENANT_SCREENING_REPORT_DETAILS_NO_APPLICANT_ID}
          element={<Navigate to={TENANT_SCREENING_REPORT_DETAILS_DEFAULT} />}
        />
        <Route
          path={TENANT_SCREENING_REPORT_DETAILS_APPLICANT}
          element={<TenantScreeningReportDetails />}
        >
          <Route path={TENANT_SCREENING_RENTAL_APPLICATION} element={<RentalApplication />}>
            <Route path="*" element={<MakeDecisionRoutes />} />
          </Route>
          <Route path={TENANT_SCREENING_IDENTITY_VERIFICATION} element={<IdentityVerification />}>
            <Route path="*" element={<MakeDecisionRoutes />} />
          </Route>
          <Route path={TENANT_SCREENING_INCOME_VERIFICATION} element={<IncomeReport />}>
            <Route path="*" element={<MakeDecisionRoutes />} />
          </Route>
          <Route path={TENANT_SCREENING_HOUSING_HISTORY} element={<HousingHistory />}>
            <Route path="*" element={<MakeDecisionRoutes />} />
          </Route>
          <Route path={TENANT_SCREENING_CREDIT_REPORT} element={<CreditReport />}>
            <Route path="*" element={<MakeDecisionRoutes />} />
          </Route>
          <Route path={TENANT_SCREENING_CRIMINAL_REPORT} element={<CriminalReport />}>
            <Route path="*" element={<MakeDecisionRoutes />} />
          </Route>
          <Route path={TENANT_SCREENING_EVICTION_HISTORY} element={<EvictionReport />}>
            <Route path="*" element={<MakeDecisionRoutes />} />
          </Route>
          <Route path={TENANT_SCREENING_DISCLOSURE} element={<Disclosure />}>
            <Route path="*" element={<MakeDecisionRoutes />} />
          </Route>
          <Route path={TENANT_SCREENING_ADDITIONAL_DOCUMENTS} element={<AdditionalDocuments />}>
            <Route path="*" element={<MakeDecisionRoutes />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default TenantScreeningRoutes;
