import React from 'react';
import { Heading, Text, VStack, Box } from '@chakra-ui/react';
import { BaselaneCardNew } from '@shared/components';
import { IllustrationOtherInprogress } from '@illustrations';

type EmptyStateProps = {
  title: String,
  description: String,
  icon: React.Node,
};

const EmptyState = ({
  title = 'Application in progress',
  description = 'Reports will be displayed once the applicant submits their screen.',
  icon = <IllustrationOtherInprogress />,
}: EmptyStateProps) => {
  return (
    <BaselaneCardNew minH="240px" w="full" palette="empty">
      <VStack alignItems="center" w="full" gap={0.5}>
        <Box mb={1.5}>{icon}</Box>
        <Heading size="headline-md">{title}</Heading>
        <Text textStyle="sm">{description}</Text>
      </VStack>
    </BaselaneCardNew>
  );
};

export default EmptyState;
