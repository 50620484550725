// @flow
import React from 'react';
import { HStack } from '@chakra-ui/react';
import { useStatsigClient } from '@statsig/react-bindings';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import ReferralButton from './ReferralButton';
import Profiles from './Profiles';
import Workspace from '../workspace';

const RightHeaderNav = () => {
  const { checkGate } = useStatsigClient();
  const isMuaEnabled = checkGate(FEATURE_GATES.MUA_GATE);

  return (
    <HStack gap={2}>
      <ReferralButton />
      {isMuaEnabled ? <Workspace /> : <Profiles />}
    </HStack>
  );
};

export default RightHeaderNav;
