import React from 'react';
import { Stack, HStack, Text } from '@chakra-ui/react';
import { Placeholder } from '@shared/components';
import { dropdownContentContainerStyles } from '@shared/components/BaselaneDropdown-new/styles/dropdown.styles';
import { truncatedText } from '@shared/styles/text.style';
import formatCurrency from '@core/utils/formatCurrency';

type CustomDropdownDisplayProps = {
  selectedOptions: Array<Object>,
  showValueByFields: Array<String>,
  handleContentRendererClick: Function,
  placeholder: String,
  classNames: Array<String>,
};

const CustomDropdownDisplay = ({
  selectedOptions,
  showValueByFields,
  handleContentRendererClick,
  placeholder,
  classNames,
}: CustomDropdownDisplayProps) => {
  const getValues = () => {
    if (showValueByFields) {
      return selectedOptions.map((value) => {
        const displayedValues = {};
        if (value) {
          showValueByFields.forEach((field) => {
            displayedValues[field] = value[field];
          });
        }
        return displayedValues;
      });
    }

    return selectedOptions;
  };

  const { nickName, availableBalance } = getValues()[0] ?? {};
  const formattedBalance = formatCurrency(availableBalance);

  return (
    <Stack
      id="order-checkbook-charge-account-selection"
      {...dropdownContentContainerStyles(classNames)}
      onClick={handleContentRendererClick}
    >
      {!selectedOptions || !selectedOptions.length ? (
        <Placeholder placeholder={placeholder} />
      ) : (
        <HStack justifyContent="space-between" overflow="hidden">
          <Text color="brand.neutral.900" textAlign="left" {...truncatedText}>
            {nickName}
          </Text>
          <Text color="brand.neutral.500" textAlign="right">
            {formattedBalance?.inDollars}
          </Text>
        </HStack>
      )}
    </Stack>
  );
};

export default CustomDropdownDisplay;
