import React from 'react';
import moment from 'moment';
import { Box, FormControl, FormErrorMessage, FormLabel, useDisclosure } from '@chakra-ui/react';
import { BaselaneSingleDatePicker } from '@shared/components';
import { formatDate } from '@core/utils/formatDate';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { formErrorStyles, formLabelStyles } from '@shared/styles/input.style';
import { truncatedText } from '@shared/styles/text.style';
import { firstDateIsSmaller, getOnDateOptions } from '../../formHelpers/leaseTermForm.helper';
import { fieldContainer } from '../../styles/shared.styles';
import './form-datepicker.scss';

function LeaseStartDateInput({
  name,
  errors,
  touched,
  values,
  setFormVariables,
  setFieldValue,
  isDisabled,
  setRCStartOptions,
  setNoRCStartOptions,
  invoicesForUnit,
  maxYear,
}: any) {
  const hasError = (errors[name] && touched[name]) || (errors[`${name}Required`] && touched[name]);
  const { isMin768 } = useBreakPoints();

  const years = { start: 2000, end: maxYear || 2033 };
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleStartDateCalendarClose = ({ date: startDate, isFromApplyButton }) => {
    if (isMin768 || (!isMin768 && isFromApplyButton)) {
      const leaseStartDate = startDate ? formatDate(startDate, 'YYYY-MM-DD') : null;
      setFieldValue('startDate', startDate);
      // before setting value, see if RC start date has to change as well
      const sortedInvoices = invoicesForUnit
        ?.filter(
          (item) =>
            (item.name === 'RENT' || item.name === 'RENT_AND_FEE') &&
            (item.state === 'UPCOMING' || item.state === 'SCHEDULED' || item.state === 'FAILED')
        )
        ?.sort(
          (a, b) =>
            moment(a?.originalDueDate).format('YYYYMMDD') -
            moment(b?.originalDueDate).format('YYYYMMDD')
        );
      const firstUnpaidInvoiceDate = sortedInvoices && sortedInvoices[0]?.dueDate;
      const firstPossibleStartDate =
        firstUnpaidInvoiceDate && firstDateIsSmaller(startDate, firstUnpaidInvoiceDate)
          ? firstUnpaidInvoiceDate
          : startDate;

      const newRCStartOptions = getOnDateOptions(
        values.dueDays,
        moment(formatDate(firstPossibleStartDate, 'YYYY-MM-DD')),
        moment(formatDate(values.endDate, 'YYYY-MM-DD')),
        false,
        values.isMonthToMonth
      );

      setRCStartOptions(newRCStartOptions);
      setNoRCStartOptions(!newRCStartOptions || newRCStartOptions.length === 0);
      // if the current rc start option is not valid, as in, not in the array of options, we change that as well
      const valid = newRCStartOptions.find(
        (item) => item.dateValue === values.rentCollectionStartDate
      );
      if ((!valid || values?.rentCollectionStartDate === null) && newRCStartOptions[0]?.dateValue) {
        setFieldValue('rentCollectionStartDate', newRCStartOptions[0]?.dateValue);
        setFormVariables({
          startDate: leaseStartDate,
          rentCollectionStartDate: newRCStartOptions[0]?.dateValue,
        });
      } else {
        setFormVariables({ startDate: leaseStartDate });
      }
    }
  };

  const tenYearsAgo = moment().subtract('years', 10);
  const minDate = tenYearsAgo.toDate();

  return (
    <FormControl {...fieldContainer} isInvalid={errors.startDate && touched.startDate}>
      <FormLabel {...formLabelStyles.xs} htmlFor={name}>
        Lease start date
      </FormLabel>
      <Box {...truncatedText} w="100%" zIndex="9">
        <BaselaneSingleDatePicker
          id={name}
          {...{
            name,
            date: values.startDate,
            value: values.startDate ? `${formatDate(values.startDate, 'MMM DD, YYYY')}` : '',
            dateFormat: 'MMM dd, yyyy',
            handleCalendarClose: handleStartDateCalendarClose,
            isDisabled,
            size: 'lg',
            years,
            minDate,
            maxDate: values.endDate,
            isOpen,
            onOpen,
            onClose,
            hideInputIcon: true,
            fixedHeight: true,
            placement: 'bottom-start',
            showInModal: !isMin768,
            className: `form-datepicker ${hasError ? 'has-error' : ''}`,
          }}
        />
      </Box>
      <FormErrorMessage {...formErrorStyles}>{errors.startDate}</FormErrorMessage>
    </FormControl>
  );
}

export default LeaseStartDateInput;
