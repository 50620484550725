import React from 'react';
import BaselaneTooltip from './BaselaneTooltip';

const LimitedAccessTooltip = ({
  children,
  label,
  ...rest
}: {
  children: React.Node,
  label?: String,
}) => {
  return (
    <BaselaneTooltip
      label={label || 'With limited banking access, you cannot open an account or move money.'}
      placement="top"
      styles={{ width: '243px', align: 'left', borderRadius: 'sm' }}
      {...rest}
    >
      {children}
    </BaselaneTooltip>
  );
};

export default LimitedAccessTooltip;
