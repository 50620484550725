import React, { useState, useEffect } from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { currencyMask } from '@core/utils/masks/index';
import { BaselaneDivider } from '@shared/components';
import MonthlyRentAmountInput from '@pages/LeasesPage/LeaseSection/forms/LeaseTermsForm/MonthlyRentAmountInput';
import RentDueDateInput from '@core/pages/LeaseAgreement/CreateLeaseAgreement/components/RentDueDateInput';
import LeaseStartDateInput from '@pages/LeasesPage/LeaseSection/forms/LeaseTermsForm/LeaseStartDateInput';
import useBreakPoints from '@core/hooks/useBreakPoints';
import LeaseEndDateInput from '@pages/LeasesPage/LeaseSection/forms/LeaseTermsForm/LeaseEndDateInput';
import MonthToMonthInput from '@pages/LeasesPage/LeaseSection/forms/LeaseTermsForm/MonthToMonthInput';
import { subheadingTextStyles } from '@pages/LeasesPage/LeaseSection/styles/shared.styles';
import {
  leaseTermsInputWidthContainer,
  leaseTermsDirectionContainerStyles,
} from '@pages/LeasesPage/LeaseSection/styles/leaseTerms.style';
import { panelFormResponsiveStyles } from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';
import Header from '../Header';

function LeaseDetailsFormStep() {
  const isMax768 = useBreakPoints('768px');
  const formik = useFormikContext();
  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    setErrors,
    setTouched,
    initialValues: formInitialValues,
  } = formik;

  const [rcStartOptions, setRCStartOptions] = useState([]);
  const [noRCStartOptions, setNoRCStartOptions] = useState(true);

  const setFormRentDueDate = (newVariables, dirty) => {
    setFieldValue('rentCollectionStartDate', newVariables.rentCollectionStartDate);
  };
  const setFormAmount = (newVariables, dirty) => {
    setFieldValue('monthlyRentAmount', newVariables?.amount);
  };
  const setFormVariables = (newVariables, dirty) => {};
  useEffect(() => {
    setErrors({});
    setTouched({});
  }, []);

  const currentYear = new Date().getFullYear();

  return (
    <Box {...panelFormResponsiveStyles(isMax768)}>
      <Header />
      <Text {...subheadingTextStyles}>Lease term</Text>
      <MonthToMonthInput
        {...{
          name: 'isMonthToMonth',
          errors,
          touched,
          values,
          setFormVariables,
          handleChange,
          handleBlur,
          setFieldTouched,
          setFieldValue,
          setRCStartOptions,
          setNoRCStartOptions,
        }}
      />

      <Stack {...leaseTermsDirectionContainerStyles}>
        <Box {...leaseTermsInputWidthContainer}>
          <LeaseStartDateInput
            {...{
              name: 'startDate',
              errors,
              touched,
              values,
              setFormVariables,
              setFieldTouched,
              setFieldValue,
              rcStartOptions,
              setRCStartOptions,
              setNoRCStartOptions,
              maxYear: currentYear + 5,
            }}
          />
        </Box>

        {/* End date and Month-to-month */}
        <Box {...leaseTermsInputWidthContainer}>
          <LeaseEndDateInput
            {...{
              name: 'endDate',
              errors,
              touched,
              values,
              setFormVariables,
              setFieldTouched,
              setFieldValue,
              rcStartOptions,
              setRCStartOptions,
              setNoRCStartOptions,
              maxYear: currentYear + 5,
            }}
          />
        </Box>
      </Stack>

      <BaselaneDivider
        styles={{
          mt: '16px',
          mb: '32px',
        }}
      />

      <MonthlyRentAmountInput
        {...{
          name: 'monthlyRentAmount',
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldTouched,
          setFormVariables: setFormAmount,
          currencyMask: currencyMask(),
        }}
      />
      <Box mt={2}>
        <RentDueDateInput
          {...{
            name: 'dueDays',
            errors,
            touched,
            values,
            formInitialValues,
            handleChange,
            handleBlur,
            setFieldTouched,
            setFieldValue,
            setFormVariables: setFormRentDueDate,
            currencyMask: currencyMask(),
            rcStartOptions,
            noRCStartOptions,
            setRCStartOptions,
            setNoRCStartOptions,
          }}
        />
      </Box>
    </Box>
  );
}

export default LeaseDetailsFormStep;
