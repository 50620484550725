// @flow
import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import InvoiceContext from '@contexts/InvoiceContext';

import ListHeader from './ListHeader';
import ListItem from './ListItem';
import ListItemMobile from './ListItemMobile';
import ListEmptyState from './ListEmptyState';
import InvoiceDetails from '../InvoiceDetails';
import type { Invoices as InvoicesType } from '../../../types';
import { invoiceTableStyles } from '../../../styles/invoice.styles';

type InvoiceListProps = {
  isLeaseLevel: boolean,
  invoices: InvoicesType,
  refetchLeaseInvoices: Function,
  hasUpdatedInvoice: boolean,
  setHasUpdatedInvoice: Function,
  sortedLeases: Array<Object>,
  hasMultipleUnits: boolean,
};

function InvoiceList({
  isLeaseLevel,
  invoices,
  refetchLeaseInvoices,
  hasUpdatedInvoice,
  setHasUpdatedInvoice,
  sortedLeases,
  hasMultipleUnits,
}: InvoiceListProps): any {
  const navigate = useNavigate();
  const { isMin768 } = useBreakPoints();
  const invoiceDetailsDrawerRef = useRef();
  const invoiceDetailsDrawerActionBtnRef = useRef();

  const {
    invoiceList,
    setInvoiceList,
    selectedInvoiceId,
    setSelectedInvoiceId,
    leaseDetailOpen,
    refetchLeaseInvoices: localRefetchLeaseInvoices,
  } = useContext(InvoiceContext);

  useEffect(() => {
    // Had to come to the last resort and use custom event. When an invoice is created, the invoices list is updated in the context.
    document.addEventListener(
      'invoice-created',
      () => {
        localRefetchLeaseInvoices();
      },
      false
    );

    return () => {
      document.removeEventListener(
        'invoice-created',
        () => {
          localRefetchLeaseInvoices();
        },
        false
      );
    };
  }, []);

  const filters = JSON.parse(localStorage.getItem('filters')) || {};

  const hasNoData = invoices?.length === 0;
  const { container } = invoiceTableStyles();

  const handleInvoiceDetailsDrawerClose = (isFromDeleteInvoice) => {
    localStorage.removeItem('invoice');
    setSelectedInvoiceId(null);
    // when editing Next Payment invoices, we're working on an invoiceList inside NextPayment prop
    if (!leaseDetailOpen && (hasUpdatedInvoice || isFromDeleteInvoice)) {
      refetchLeaseInvoices();
    }

    const { date, dateType, state: filterState, leaseId } = filters;
    if (date || dateType || filterState || leaseId) {
      delete filters?.invoiceId;
      navigate('/leases', { state: { ...filters, noRefresh: true } });
    }
  };

  const mobileInvoiceList = (
    <Box {...container}>
      {hasNoData ? (
        <ListEmptyState />
      ) : (
        invoiceList?.map((invoice) => (
          <ListItemMobile
            key={invoice.id}
            invoice={invoice}
            isLeaseLevel={isLeaseLevel}
            sortedLeases={sortedLeases}
            hasMultipleUnits={hasMultipleUnits}
            invoiceDetailsDrawerRef={invoiceDetailsDrawerRef}
            invoiceDetailsDrawerActionBtnRef={invoiceDetailsDrawerActionBtnRef}
          />
        ))
      )}

      {selectedInvoiceId && (
        <InvoiceDetails
          invoiceDetailsDrawerRef={invoiceDetailsDrawerRef}
          invoiceDetailsDrawerActionBtnRef={invoiceDetailsDrawerActionBtnRef}
          handleInvoiceDetailsDrawerClose={handleInvoiceDetailsDrawerClose}
          hasUpdatedInvoice={hasUpdatedInvoice}
          setHasUpdatedInvoice={setHasUpdatedInvoice}
          setInvoiceList={setInvoiceList}
          refetchLeaseInvoices={refetchLeaseInvoices}
          sortedLeases={sortedLeases}
          hasMultipleUnits={hasMultipleUnits}
          isLeaseLevel={isLeaseLevel}
        />
      )}
    </Box>
  );

  const desktopInvoiceList = (
    <Box {...container}>
      <ListHeader isLeaseLevel={isLeaseLevel} />
      <Box m="0 !important">
        {hasNoData ? (
          <ListEmptyState />
        ) : (
          invoiceList?.map((invoice) => (
            <ListItem
              key={invoice.id}
              invoice={invoice}
              isLeaseLevel={isLeaseLevel}
              sortedLeases={sortedLeases}
              hasMultipleUnits={hasMultipleUnits}
              invoiceDetailsDrawerRef={invoiceDetailsDrawerRef}
              invoiceDetailsDrawerActionBtnRef={invoiceDetailsDrawerActionBtnRef}
            />
          ))
        )}
      </Box>

      {selectedInvoiceId && (
        <InvoiceDetails
          invoiceDetailsDrawerRef={invoiceDetailsDrawerRef}
          invoiceDetailsDrawerActionBtnRef={invoiceDetailsDrawerActionBtnRef}
          handleInvoiceDetailsDrawerClose={handleInvoiceDetailsDrawerClose}
          hasUpdatedInvoice={hasUpdatedInvoice}
          setHasUpdatedInvoice={setHasUpdatedInvoice}
          setInvoiceList={setInvoiceList}
          refetchLeaseInvoices={refetchLeaseInvoices}
          sortedLeases={sortedLeases}
          hasMultipleUnits={hasMultipleUnits}
          isLeaseLevel={isLeaseLevel}
        />
      )}
    </Box>
  );

  return isMin768 ? desktopInvoiceList : mobileInvoiceList;
}

export default InvoiceList;
