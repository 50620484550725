import React from 'react';
import { BaselaneButtonIcon } from '@shared/components';
import { Icon16Edit } from '@icons/16px';

type EditBtnProps = {
  onClick: Function,
  isDisabled: boolean,
  variant: string,
  palette: string,
  size: string,
  id: string,
};

const EditBtn = ({ id, onClick, isDisabled = false, variant, palette, size }: EditBtnProps) => {
  return (
    <BaselaneButtonIcon
      id={id}
      icon={<Icon16Edit />}
      variant={variant}
      palette={palette}
      size={size}
      isDisabled={isDisabled}
      onClick={onClick}
    />
  );
};

export default EditBtn;
