import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import {
  menuItemSubAccountStyles,
  menuItemTextStyles,
} from '@core/components/Transactions/components/TransactionsFilters/styles/transactionsFilters.styles';
import { Highlighted } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';

type AccountDropdownItemProps = {
  item: Object,
  search: string,
};

const renderAccountDropdownItem = ({ item, search }: AccountDropdownItemProps) => {
  return (
    <Stack gap={0} width="100%">
      <Text {...menuItemTextStyles} color="inherit">
        <Highlighted text={item?.bankName} highlight={search} />
      </Text>
      <Text {...menuItemSubAccountStyles}>
        <Highlighted text={item?.account} highlight={search} />
      </Text>
    </Stack>
  );
};

export default renderAccountDropdownItem;
