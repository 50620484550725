// @flow
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';
import { HOME } from '@routes';

type FeatureGateGuardProps = {
  name: String,
};

const FeatureGateGuard = ({ name }: FeatureGateGuardProps): any => {
  const { checkGate } = useStatsigClient();
  return checkGate(name) ? <Outlet /> : <Navigate replace to={HOME} />;
};

export default FeatureGateGuard;
