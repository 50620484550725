import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { BaselaneBannerNew } from '@shared/components';
import { IllustrationOtherSuccess } from '@illustrations';
import customTheme from '@theme';
import { UPDATE_USER_METADATA } from '@core/components/UserProfilePage/queries';

const BannerIcon = () => (
  <IllustrationOtherSuccess color={customTheme.colors.brand.neutral['900']} />
);

const GuideCompleteBanner = ({ metadata }: { metadata: Object }) => {
  const [dismissLoading, setDismissLoading] = useState(false);
  const [updateUser] = useMutation(UPDATE_USER_METADATA);

  const dismissBanner = () => {
    setDismissLoading(true);

    updateUser({
      variables: { metadata: { ...metadata, hideSetupGuideSection: true } },
      update: (cache, { data: { updateUser: updatedUser } }) => {
        cache.modify({
          id: cache.identify(updatedUser),
          fields: {
            userMetadata: () => updatedUser?.userMetadata,
          },
        });
      },
    })
      .then(() => setDismissLoading(false))
      .catch((err) => console.debug(err));
  };

  return (
    <BaselaneBannerNew
      title="Setup guide"
      body="Congrats! You're all set up and ready to go with Baselane."
      btnLabel="Dismiss guide"
      variant="success-medium"
      hasCloseButton={false}
      bannerIconName={BannerIcon}
      onButtonClick={dismissBanner}
      buttonLoading={dismissLoading}
    />
  );
};

export default GuideCompleteBanner;
