// @flow
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TRANSFER_FUNDS, SEND_FUNDS, ADD_FUNDS } from '@routes';
import { Icon16Send, Icon16PlusCircle, Icon16Transfersbetweenaccounts } from '@icons/16px';
import DisableMoveMoney from '@core/components/DisableMoveMoney';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import BaselaneMenu from './BaselaneMenu';

type MoveMoneyMenuProps = {
  buttonSize?: String,
  styles?: Object,
  entryPoint: String, // Page where the menu is being used. Default is 'baselane_banking'.
};

const MoveMoneyMenu = ({
  buttonSize = 'md',
  styles = {},
  entryPoint = 'baselane_banking',
}: MoveMoneyMenuProps) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  return (
    <DisableMoveMoney
      tooltipText="Verify your email to move money"
      styles={styles?.container}
      renderItem={(isDisabled) => (
        <BaselaneMenu
          id="move-money-button"
          label="Move money"
          listItems={[
            {
              'data-cy': 'mmm-send-money-button',
              name: 'Send money',
              description: 'Via ACH, wire, or check',
              onClick: () => {
                navigate({
                  pathname: `${pathname}/${SEND_FUNDS}`,
                  search,
                });
              },
              icon: <Icon16Send />,
            },
            {
              'data-cy': 'mmm-transfer-funds-button',
              name: 'Transfer funds',
              description: 'Between any of your Baselane or external accounts',
              onClick: () => {
                sendSegmentEvent('baselane_banking_transfer_funds_started', {
                  entry_point: entryPoint,
                });
                navigate({
                  pathname: `${pathname}/${TRANSFER_FUNDS}`,
                  search,
                });
              },
              icon: <Icon16Transfersbetweenaccounts />,
            },
            {
              'data-cy': 'mmm-add-funds-button',
              id: 'add_funds',
              name: 'Add funds',
              description: 'From an external account, via check deposit, or more',
              onClick: () => {
                navigate({
                  pathname: `${pathname}/${ADD_FUNDS}`,
                  search,
                });
              },
              icon: <Icon16PlusCircle />,
            },
          ]}
          styles={{
            list: {
              maxWidth: '260px',
              zIndex: 11,
            },
            ...styles,
          }}
          buttonSize={buttonSize}
          isDisabled={isDisabled}
        />
      )}
    />
  );
};

export default MoveMoneyMenu;
