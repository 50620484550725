import React, { useEffect, useState } from 'react';
import { Text, Tbody, Heading, VStack, Box, Flex } from '@chakra-ui/react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import formatCurrency from '@core/utils/formatCurrency';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { formatDate } from '@core/utils/formatDate';
import {
  BaselaneCardStack,
  BaselaneResponsiveCell,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
  BaselaneCardNew,
} from '@shared/components';
import { Icon24Warning } from '@icons/24px';
import { IllustrationCIC } from '@illustrations/manual';
import { TENANT_SCREENING_EVICTION_HISTORY } from '@core/constants/routes';
import { isFieldNotObject } from '../helpers/tenantScreening.helpers';
import { getReportByid, isReportDataInvalid } from '../helpers/tenantScreeningReports.helpers';
import ReportUnavailableAlert from '../components/ReportUnavailableAlert';
import PendingEmptyState from './PendingEmptyState';

const EvictionReport = () => {
  const { isMax767 } = useBreakPoints();
  const { verificationId, externalApplicantId } = useOutletContext();
  const { applicationId } = useParams();

  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (applicationId && externalApplicantId && verificationId) {
      getReportByid(applicationId, externalApplicantId, verificationId)
        .then(({ data }) => {
          if (data?.name === TENANT_SCREENING_EVICTION_HISTORY) setReportData(data);
        })
        .catch((error) => {
          console.error('Error fetching EvictionReport:', error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [applicationId, externalApplicantId, verificationId]);

  const tableColumnConfig = {
    columns: [
      {
        isSortable: false,
        styles: {
          w: isMax767 ? 'auto' : '320px',
          contentContainerStyles: {
            px: 0,
            py: 2,
          },
        },
      },
      {
        isSortable: false,
        styles: {
          contentContainerStyles: {
            px: 0,
            py: 2,
          },
        },
      },
    ],
  };

  if (reportData?.status === 'pending' || isReportDataInvalid(reportData, verificationId)) {
    return reportData?.status === 'pending' ? (
      <PendingEmptyState />
    ) : (
      <ReportUnavailableAlert isLoading={isLoading} />
    );
  }

  return (
    <>
      <VStack alignItems="flex-start" gap={4}>
        <Heading as="h2" size="headline-xl">
          Summary
        </Heading>
        {reportData.map((item) => {
          const {
            address,
            city,
            state,
            zip,
            court,
            plaintiff,
            filling_date: fillingDate,
            case_number: caseNumber,
            notice_type: noticeType,
            judgement_amount: judgementAmount,
          } = item;

          return (
            <BaselaneCardStack
              id="example-cardstack-medium"
              size="md"
              items={[
                {
                  id: 'header',
                  content: (
                    <BaselaneCardNew variant="inner" size="md" palette="dark">
                      <Heading size="headline-md" w="full">
                        <Flex
                          justifyContent="space-between"
                          direction={{ base: 'column', md: 'row' }}
                          gap="16px"
                        >
                          <Flex>
                            <Box as="span" color="red.800" mr={1}>
                              <Icon24Warning />
                            </Box>
                            <Text noOfLines={1} style={{ textTransform: 'capitalize' }}>
                              {address.toLowerCase()}, {city.toLowerCase()}, {state}, {zip}
                            </Text>
                          </Flex>
                          <IllustrationCIC />
                        </Flex>
                      </Heading>
                    </BaselaneCardNew>
                  ),
                },
                {
                  id: 'data',
                  content: (
                    <BaselaneResponsiveTable
                      id="review-columns"
                      config={tableColumnConfig}
                      maxW="600px"
                      tableContainerStyles={{
                        overflowY: 'initial',
                        overflowX: 'initial',
                      }}
                    >
                      <Tbody>
                        <BaselaneResponsiveTableRows
                          items={[
                            {
                              heading: 'Filling date',
                              data: formatDate(fillingDate, 'MMM DD, YYYY'),
                            },
                            {
                              heading: 'Judgement amount',
                              data: formatCurrency(judgementAmount.cents / 100).inDollars,
                            },
                            { heading: 'Court', data: court },
                            { heading: 'Case number', data: caseNumber },
                            { heading: 'Plaintiff', data: plaintiff },
                            { heading: 'Notice type', data: noticeType ?? 'N/A' },
                          ]}
                          customRow
                          renderItem={(row) => {
                            return (
                              <BaselaneResponsiveTableRow
                                key={row.heading}
                                chevronCellStyles={{ w: 0 }}
                                contentContainerStyles={{ border: 'none' }}
                              >
                                <BaselaneResponsiveCell configIndex={0} p={0}>
                                  <Text as="span" textStyle="sm" color="brand.neutral.700">
                                    {row.heading}
                                  </Text>
                                </BaselaneResponsiveCell>
                                <BaselaneResponsiveCell configIndex={1} p={0}>
                                  <Text textStyle="sm" style={{ textTransform: 'capitalize' }}>
                                    {row.data && isFieldNotObject(row.data)
                                      ? row.data.toLowerCase()
                                      : '--'}
                                  </Text>
                                </BaselaneResponsiveCell>
                              </BaselaneResponsiveTableRow>
                            );
                          }}
                        />
                      </Tbody>
                    </BaselaneResponsiveTable>
                  ),
                },
              ]}
            />
          );
        })}
      </VStack>
      <Outlet />
    </>
  );
};

export default EvictionReport;
