import process from 'process';

export const getStatsigEnviroment = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return 'production';
    case 'pre-prod':
      return 'Pre-Prod';
    case 'hotfix':
      return 'Pre-Prod';
    case 'uat':
      return 'UAT';
    case 'develop':
      return 'development';
    default:
      return 'development';
  }
};
