import React from 'react';
import { Route, Routes } from 'react-router-dom';

import TransferFundsFeatureGuard from '@shared/components/TransferFundsDrawer/TransferFundsFeatureGuard';
import AddAccountsDrawer from '@shared/components/AddAccountsDrawer';
import SendFunds from '@pages/Transfers/components/SendFunds';
import AddFunds from '@pages/Transfers/components/AddFunds';
import BankTransfer from '@pages/Transfers/components/AddFunds/components/BankTransfer';
import CheckDepositDrawer from '@pages/Transfers/components/AddFunds/check-deposit/CheckDepositDrawer';
import DigitalWallet from '@pages/Transfers/components/AddFunds/components/DigitalWallet';
import TransferMethodSelector from '@pages/Transfers/components/AddFunds/components/TransferMethodSelector';

import {
  ADD_ACCOUNT,
  ADD_FUNDS,
  ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS,
  ADD_FUNDS_BANK_TRANSFER,
  ADD_FUNDS_CHECK_DEPOSIT,
  ADD_FUNDS_DIGITAL_WALLET,
  ADD_FUNDS_TRANSFER_METHOD_SELECTOR,
  SEND_FUNDS,
  TRANSFER_FUNDS,
} from '@routes';

const MoveMoneyRoutes = ({ from }: { from: string }) => {
  return (
    <Routes>
      <Route path={SEND_FUNDS} element={<SendFunds />} />
      <Route path={TRANSFER_FUNDS} element={<TransferFundsFeatureGuard />} />
      <Route path={ADD_FUNDS} element={<AddFunds />} from={from}>
        <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
          <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
        </Route>
        <Route path={ADD_FUNDS_CHECK_DEPOSIT} element={<CheckDepositDrawer />} />
        <Route
          path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
          element={<DigitalWallet id={parseInt('0', 10)} />}
        />
        <Route path={ADD_FUNDS_DIGITAL_WALLET} element={<DigitalWallet id={parseInt('1', 10)} />}>
          <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
            <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
          </Route>
          <Route path={ADD_FUNDS_CHECK_DEPOSIT} element={<CheckDepositDrawer />} />
        </Route>
        <Route path={ADD_FUNDS_TRANSFER_METHOD_SELECTOR} element={<TransferMethodSelector />} />
      </Route>
    </Routes>
  );
};

export default MoveMoneyRoutes;
