import React from 'react';
import { Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';

import { checkFormCircleStyles } from '../../styles/tabs.styles';
import { isOptionDisabled } from '../helpers/tab.helpers';
import type { FlowPanelProps } from '../../types/FlowTabPanelProps';

type TabNumberProps = {
  menuOptions: Array<FlowPanelProps>,
  option: Object,
  unitState?: string,
  index: Number,
  noBorder?: boolean,
  handleIsOptionDisabled?: Function,
};

function TabNumber({
  menuOptions,
  option,
  unitState = '',
  index,
  noBorder = true,
  handleIsOptionDisabled = null,
}: TabNumberProps): any {
  const isDisabled = handleIsOptionDisabled
    ? handleIsOptionDisabled()
    : isOptionDisabled({ option, index, unitState, menuOptions });
  const bgColorDisabled = isMobile ? 'transparent' : 'brand.darkBlue.200';
  const bgColorEnabled = isMobile ? 'transparent' : 'brand.blue.100';
  const borderColorDisabled = isMobile ? '1px solid brand.darkBlue.200' : 'none';
  const borderColorEnabled = isMobile ? '1px solid brand.blue.700' : 'none';

  return (
    <Box
      {...checkFormCircleStyles(noBorder, isDisabled)}
      bgColor={isDisabled ? bgColorDisabled : bgColorEnabled}
      borderColor={isDisabled ? borderColorDisabled : borderColorEnabled}
    >
      {index + 1}
    </Box>
  );
}

export default TabNumber;
