import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { BaselaneButton, BaselaneDrawer } from '@shared/components';
import { IllustrationOtherIdentityverificationinprogress } from '@illustrations';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { vouchedVerificationInProgressState } from './constants/vouchedVerification.constants';
import {
  textHeadingStyles,
  containerStyles,
  successIconContainerStyles,
  textStyles,
} from './styles/vouchedVerificationResultDrawer.styles';

function VouchedReturnDrawer({
  drawerRef,
  handleCloseAll,
  handleVouchedReturnDrawerClose,
}: {
  drawerRef: any,
  handleCloseAll: Function,
  handleVouchedReturnDrawerClose: Function,
}): any {
  const { isMax767 } = useBreakPoints();
  return (
    <BaselaneDrawer
      title={vouchedVerificationInProgressState.drawerTitle}
      onClose={handleVouchedReturnDrawerClose}
      ref={drawerRef}
      size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
      footer={
        <BaselaneButton variant="tonal" palette="primary" size="lg" onClick={handleCloseAll}>
          Close
        </BaselaneButton>
      }
      newDesignDrawer
    >
      <Stack {...containerStyles}>
        <Stack align="center" spacing={4}>
          <Stack {...successIconContainerStyles}>
            <IllustrationOtherIdentityverificationinprogress />
          </Stack>
          <Stack align="center" spacing={0.4}>
            <Text {...textHeadingStyles}>{vouchedVerificationInProgressState.title}</Text>
            <Text {...textStyles}>{vouchedVerificationInProgressState.textLine1}</Text>
            <Text {...textStyles} mt="24px">
              {vouchedVerificationInProgressState.textLine2}
            </Text>
          </Stack>
          {/* <BaselaneButton variant="tonal" palette="primary" size="lg" onClick={handleCloseAll}>
              Close
            </BaselaneButton> */}
        </Stack>
      </Stack>
    </BaselaneDrawer>
  );
}

export default VouchedReturnDrawer;
