import React from 'react';
import { Text, Stack, Box } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import formatCurrency from '@core/utils/formatCurrency';
import {
  getPropertyName,
  getPropertyAddress,
  getCategoryName,
} from '@core/utils/propertyAndCategoryHelpers';

import { descriptionTextStyles, title2 } from '../../styles/makeTransferOrPaymentDrawer.styles';
import {
  sectionBodyTextSmStyles,
  sectionBodyTextXsStyles,
  sectionTitleTextStyles,
} from './styles/main.styles';

const ReviewMailCheckView = ({
  values,
  propertyOptions,
  categoryOptions,
  transfer,
  categoryIdsMap,
}: ReviewWireViewProps) => {
  const { isMinXL } = useBreakPoints();

  const addressUnit = values?.unit && values.unit !== '' ? `, ${values.unit}` : '';
  const address = values?.address
    ? `${values.address}${addressUnit}, ${values.city}, ${values.state} ${values.zipcode}`
    : '';

  const stripAmount = transfer?.transferAmount?.substring(1)?.replace(/,/g, '');
  const transferAmount = Number(stripAmount);

  const [baselaneBankName = '', baselaneCustomAccountName = ''] =
    transfer?.selectedAccountFrom?.formattedAccountData?.accLabel?.split('>') || [];

  return (
    <Stack spacing={3}>
      <Stack spacing={isMinXL ? 0 : 2}>
        <Text {...title2}>Review & Send check</Text>
        <Text {...{ ...descriptionTextStyles, mb: '5' }}>
          Checks are printed and delivered in 5-8 business days.
        </Text>
      </Stack>
      {/* Amount */}
      <Box>
        <Text {...sectionTitleTextStyles}>Amount</Text>
        <Text {...sectionBodyTextSmStyles}>{formatCurrency(transferAmount).inDollars}</Text>
        <Stack direction="row">
          <Text {...sectionBodyTextXsStyles}>Check Fee - $2.00</Text>
        </Stack>
      </Box>

      <Box>
        <Text {...sectionTitleTextStyles}> Account</Text>
        <Text {...sectionBodyTextSmStyles}>{baselaneBankName?.trim()}</Text>
        <Text {...sectionBodyTextXsStyles}>{baselaneCustomAccountName?.trim()}</Text>
      </Box>
      {/* Recipient */}
      <Box>
        <Text {...sectionTitleTextStyles}>Recipient</Text>
        <Text {...sectionBodyTextSmStyles}>{values?.recipientName}</Text>
        <Text {...sectionBodyTextXsStyles}>{address}</Text>
      </Box>
      {/* Memo */}
      <Box>
        <Text {...sectionTitleTextStyles}>Memo</Text>
        {/* TODO note memo */}
        <Text {...sectionBodyTextSmStyles}> {transfer?.memo ?? '—'}</Text>
      </Box>
      {/* Property Tag */}
      <Box>
        <Text {...sectionTitleTextStyles}>Property Tag</Text>
        {values.propertyId ? (
          <>
            <Text {...sectionBodyTextSmStyles}>
              {getPropertyName(values.propertyId, values.unitId, propertyOptions)}
            </Text>
            <Text {...sectionBodyTextXsStyles}>
              {getPropertyAddress(values.propertyId, propertyOptions)}
            </Text>
          </>
        ) : (
          <Text>&mdash;</Text>
        )}
      </Box>
      {/* Category Tag */}
      <Box>
        <Text {...sectionTitleTextStyles}>Category Tag</Text>
        {values.categoryId ? (
          <Text {...sectionBodyTextSmStyles}>
            {getCategoryName(values.categoryId, categoryOptions, categoryIdsMap)}
          </Text>
        ) : (
          <Text>&mdash;</Text>
        )}
      </Box>
    </Stack>
  );
};

export default ReviewMailCheckView;
