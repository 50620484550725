import React, { useEffect, useState } from 'react';
import { VStack, HStack, Text, Heading, Box, Stack, Skeleton, useToast } from '@chakra-ui/react';
import { useNavigate, generatePath, Outlet } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  BaselaneButtonIcon,
  BaselaneButton,
  BaselaneSortableList,
  BaselaneSortableListItem,
} from '@shared/components';
import { Icon16Plus, Icon16ArrowBack, Icon16ChevronRight } from '@icons/16px';
import {
  BOOKKEEPING_RULES,
  BOOKKEEPING_RULES_ID,
  BOOKKEEPING_RULES_CREATE,
  TRANSACTIONS,
} from '@routes';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { useAutoTagRuleToast } from '@hooks/useAutoTagRuleToast';
import Action from './components/Action';
import Conditions from './components/Conditions';
import AICategorizationRule from './components/AICategorizationRule';
import {
  AI_RULE_HARDCODED,
  isAiCategorizationRule,
} from './components/AICategorizationRule/aiRule.helpers';
import AutoTaggingRulesEmptyState from './components/AutoTaggingRulesEmptyState';

import { GET_AUTOTAG_RULES, REORDER_AUTOTAG_RULES } from './queries/autotag-queries';

import {
  priorityContainerStyles,
  priorityStyles,
  conditionStyles,
  actionStyles,
  ruleClickAreaStyles,
} from './styles/autotaggingRules.styles';

const AutoTaggingRulesPage = () => {
  const [autoTaggingRules, setAutoTaggingRules] = useState(null);

  const { data, loading } = useQuery(GET_AUTOTAG_RULES, { fetchPolicy: 'network-only' });
  const [reorderAutotagRules, { loading: reorderLoading }] = useMutation(REORDER_AUTOTAG_RULES, {
    update: (cache, { data: reorderResult }) => {
      const { reorderAutotagRules: newRules } = reorderResult;

      cache.writeQuery({
        query: GET_AUTOTAG_RULES,
        data: {
          autotagRules: newRules,
        },
      });
    },
  });

  const [aiCategorizationRuleEnabled, setAiCategorizationRuleEnabled] = useState(null);

  const { isMax767, isMax576 } = useBreakPoints();
  const navigate = useNavigate();

  const toast = useToast();

  const showErrorToast = () => {
    toast({
      description: 'Could not reorder rules, please try again.',
      status: 'error',
      isClosable: true,
      position: 'bottom-left',
    });
  };

  useEffect(() => {
    const { autotagRules: loadedAutoTaggingRules } = data || {};
    if (loadedAutoTaggingRules) {
      let rules = loadedAutoTaggingRules;
      if (!loadedAutoTaggingRules.some(isAiCategorizationRule)) {
        setAiCategorizationRuleEnabled(false);
        rules = [
          ...loadedAutoTaggingRules,
          { ...AI_RULE_HARDCODED, rank: loadedAutoTaggingRules.length + 1 },
        ];
      } else {
        const aiRule = loadedAutoTaggingRules.find(isAiCategorizationRule);
        setAiCategorizationRuleEnabled(aiRule.isActive);
      }
      setAutoTaggingRules(rules);
    }
  }, [data]);

  useAutoTagRuleToast();

  return (
    <VStack w="full" alignItems="flex-start" p={4} gap={6}>
      <HStack w="full" justifyContent="flex-start" flexWrap="wrap" gap={4}>
        <HStack {...(isMax576 && { w: 'full' })} flex={1} gap={4}>
          <BaselaneButtonIcon
            icon={<Icon16ArrowBack />}
            onClick={() => {
              navigate(`${TRANSACTIONS}`);
            }}
            palette="neutral"
            size="lg"
            variant="outline"
          />
          <VStack alignItems="flex-start" gap={0}>
            <Heading size="headline-lg">Bookkeeping Rules</Heading>
            <Text textStyle="sm">
              Create custom rules to auto-categorize incoming transactions.
            </Text>
          </VStack>
        </HStack>
        <BaselaneButton
          leftIcon={<Icon16Plus />}
          palette="primary"
          size={isMax576 ? 'md' : 'lg'}
          variant="filled"
          ml="auto"
          flexShrink={0}
          onClick={() => navigate(BOOKKEEPING_RULES_CREATE)}
          w={isMax576 ? 'full' : 'au'}
          {...(isMax576 && { w: 'full' })}
        >
          Create rule
        </BaselaneButton>
      </HStack>
      <VStack w="full" alignItems="flex-start" gap={0}>
        {/*  Headers  */}
        {!isMax767 && (
          <HStack
            w="full"
            alignItems="flex-start"
            gap={2}
            borderBottom="solid 1px"
            borderColor="brand.darkBlue.200"
          >
            <Box w="32px" />
            <Text textStyle="headline-xs" py={1} {...priorityContainerStyles}>
              Priority
            </Text>
            <Text textStyle="headline-xs" py={1} {...conditionStyles}>
              If transaction
            </Text>
            <Text textStyle="headline-xs" py={1} pl={4} {...actionStyles}>
              Then update
            </Text>
            <Box w="60px" />
          </HStack>
        )}
        {/*  Main Rule List  */}
        <Skeleton w="full" isLoaded={!loading}>
          {autoTaggingRules?.length === 1 ? (
            <AutoTaggingRulesEmptyState />
          ) : (
            <BaselaneSortableList
              w="full"
              items={autoTaggingRules?.filter((rule) => !isAiCategorizationRule(rule))}
              setItems={setAutoTaggingRules}
              isHandleDrag
              onReorder={(values) => {
                let rules =
                  values.map((value, index) => ({ ruleId: value.id, rank: index + 1 })) || [];
                const aiRule = autoTaggingRules.find(isAiCategorizationRule);
                if (aiRule) {
                  rules = rules.filter((rule) => rule.ruleId !== aiRule.id);
                }

                reorderAutotagRules({ variables: { rules } })
                  .then((reorderResult) => {
                    if (reorderResult.errors) {
                      showErrorToast();
                    }
                  })
                  .catch(() => {
                    showErrorToast();
                  });
              }}
              renderItem={(item, onTransitionEnd, index) => {
                const { id, rank, conditions, action } = item;
                return (
                  <BaselaneSortableListItem
                    key={id}
                    isHandleDrag
                    item={item}
                    alignItems="center"
                    onTransitionEnd={onTransitionEnd}
                    userSelect="none"
                  >
                    <BaselaneButton
                      variant="transparent"
                      palette="neutral"
                      size="lg"
                      {...ruleClickAreaStyles}
                      onClick={(e) => {
                        const bookkeepingRulesIdPath = generatePath(
                          `/${BOOKKEEPING_RULES}/${BOOKKEEPING_RULES_ID}`,
                          { ruleId: id }
                        );
                        navigate(bookkeepingRulesIdPath);
                      }}
                    >
                      {!isMax767 && (
                        <Box {...priorityContainerStyles}>
                          <Skeleton
                            isLoaded={!reorderLoading}
                            display="inline-block"
                            w="fit-content"
                            {...priorityStyles}
                          >
                            <Text
                              textStyle="headline-md"
                              display="inline-block"
                              w="fit-content"
                              {...priorityStyles}
                            >
                              {rank}
                            </Text>
                          </Skeleton>
                        </Box>
                      )}
                      <Stack flexDirection={isMax767 ? 'column' : 'row'} flex={1} gap={2}>
                        <Conditions
                          parentId={id}
                          {...conditionStyles}
                          w={isMax767 ? 'full' : '50%'}
                          conditions={conditions}
                        />
                        <Action {...actionStyles} w={isMax767 ? 'full' : '50%'} action={action} />
                      </Stack>
                      <Icon16ChevronRight />
                    </BaselaneButton>
                  </BaselaneSortableListItem>
                );
              }}
            />
          )}
        </Skeleton>
        {/*  AI Autotagging Rules  */}
        <Skeleton w="full" isLoaded={!loading}>
          <AICategorizationRule
            aiCategorizationEnabled={aiCategorizationRuleEnabled}
            reorderLoading={reorderLoading}
            rank={autoTaggingRules?.length || 0}
          />
        </Skeleton>
      </VStack>
      <Outlet />
    </VStack>
  );
};

export default AutoTaggingRulesPage;
