/* eslint-disable react/prop-types */
import React from 'react';
import { Text } from '@chakra-ui/react';

const Value = ({ children }) => (
  <Text textStyle="headline-xl" mt={0.5}>
    {children}
  </Text>
);

export default Value;
