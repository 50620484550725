import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useOutletContext, useParams, generatePath, Navigate } from 'react-router-dom';
import { ChakraProvider, useDisclosure } from '@chakra-ui/react';
import { useApolloClient } from '@apollo/client';
import { useShallow } from 'zustand/react/shallow';

import { useStatsigClient } from '@statsig/react-bindings';
import { BaselaneDrawer, UnsavedChangesAlert } from '@shared/components';
import habitatTheme from '@core/themeHabitat';
import useBreakPoints from '@core/hooks/useBreakPoints';
import onDrawerClose from '@core/utils/onDrawerClose';
import useCheckbookStore from '@store/Checkbook';

import { FEATURE_GATES } from '@core/constants/statsigKeys';
import CheckbookSteps from './Steps';
import Success from './Steps/Success';

type CheckbookDrawerProps = { from?: string };

const CheckbookDrawer = ({ from }: CheckbookDrawerProps) => {
  const { cache } = useApolloClient();
  const { account } = useOutletContext() ?? {};
  const navigate = useNavigate();
  const params = useParams();
  const redirectUrl = generatePath(from, params);
  const { isMax576 } = useBreakPoints();

  const { checkGate } = useStatsigClient();

  const [formSuccess, selectedAccount, resetStore] = useCheckbookStore(
    useShallow((state) => [state.formSuccess, state.selectedAccount, state.reset])
  );

  // Alert State
  const { isOpen: isDrawerAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  const [xIdempotencyKey, setXIdempotencyKey] = useState(uuidv4());

  const cleanup = () => {
    if (selectedAccount && selectedAccount?.id) {
      // clear checkbook preview image from cache when drawer is closed, so it forces a new network call when user restarts the flow and selects the same account
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: `checkBookPreviewImage({"bankAccountId":${Number(selectedAccount.id)}})`,
      });
      cache.gc();
    }
    resetStore();
  };

  const onCloseWithoutSaving = () => {
    if (selectedAccount && !formSuccess) {
      onAlertOpen();
    } else {
      handleDrawerClose();
    }
  };

  const handleAlertContinueClick = () => {
    onAlertClose();
    handleDrawerClose();
  };

  const handleDrawerClose = () => {
    setXIdempotencyKey(uuidv4());
    onDrawerClose(navigate, redirectUrl);
    cleanup();
  };

  // if user is deeplinked to drawer with feature flag off, redirect them to banking entity page
  if (!checkGate(FEATURE_GATES.CHECKBOOK_GATE)) {
    return <Navigate to={redirectUrl} replace />;
  }

  return (
    <ChakraProvider theme={habitatTheme}>
      <UnsavedChangesAlert
        isDrawerAlertOpen={isDrawerAlertOpen}
        onAlertClose={onAlertClose}
        onAlertOpen={onAlertOpen}
        onAlertContinue={handleAlertContinueClick}
      />

      <BaselaneDrawer
        isOpen={account && checkGate(FEATURE_GATES.CHECKBOOK_GATE)}
        newDesignDrawer
        newDrawerUseDefaultBodyFooter
        title="Order a checkbook"
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        closeOnOverlayClick={false}
        closeEvent={onCloseWithoutSaving}
        onOverlayClick={onCloseWithoutSaving}
      >
        {formSuccess ? (
          <Success closeEvent={handleDrawerClose} />
        ) : (
          <CheckbookSteps xIdempotencyKey={xIdempotencyKey} />
        )}
      </BaselaneDrawer>
    </ChakraProvider>
  );
};

export default CheckbookDrawer;

CheckbookDrawer.defaultProps = {
  from: null,
};
