import React from 'react';
import { Icon16Check, Icon16ChevronRight, Icon16ChevronLeft } from '@icons/16px';

import { BaselaneButton } from '@shared/components';
import { getBankId } from '@shared/components/TransferFunds/helpers';
import AddBankAccountButton from '@core/components/AddBankAccount';
import { useUnitOtp } from '@core/contexts/UnitOtpContext';

type FooterProps = {
  currentStep: Number,
  totalSteps: Number,
  formikProps: Object,
  banks: Array<Object>,
  accountsMap: Object,
  transferType: String,
  handlePreviousStep: Function,
  handleNextStep: Function,
  handleMakeAnotherTransfer: Function,
  handleDrawerClose: Function,
  showEmptyState: Boolean,
  refetch: Function,
};

const Footer = ({
  currentStep,
  totalSteps,
  formikProps,
  banks,
  accountsMap,
  transferType,
  handlePreviousStep,
  handleNextStep,
  handleMakeAnotherTransfer,
  handleDrawerClose,
  showEmptyState = 0,
  refetch,
}: FooterProps) => {
  const { values, isValid, dirty, resetForm, isSubmitting, handleSubmit } = formikProps;

  // Unit OTP
  const { verifyUnitOtp, ignore } = useUnitOtp();

  const bankId = getBankId({ values, transferType, accountsMap });
  const checkToken = () => {
    verifyUnitOtp(bankId).then(handleSubmit).catch(ignore);
  };

  // footer
  const getFooterForCurrentStep = () => {
    const isReview = totalSteps - currentStep === 1;

    switch (true) {
      case currentStep === 1:
        return showEmptyState ? (
          <AddBankAccountButton
            titleText="Connect External Account"
            size="md"
            variant="filled"
            palette="primary"
            styles={{ w: '100%' }}
            handleSuccessFn={refetch()}
          />
        ) : (
          <>
            <BaselaneButton
              size="md"
              variant="outline"
              palette="neutral"
              onClick={() => handleDrawerClose({ dirty, resetForm })}
            >
              Back
            </BaselaneButton>
            <BaselaneButton
              size="md"
              variant="filled"
              palette="primary"
              width="100%"
              ml={1.5}
              flex={1}
              onClick={handleNextStep}
              rightIcon={<Icon16ChevronRight />}
              isDisabled={!dirty || !isValid}
            >
              Review transfer
            </BaselaneButton>
          </>
        );

      case currentStep > 1 && currentStep < totalSteps:
        return (
          <>
            <BaselaneButton
              size="md"
              variant="outline"
              palette="neutral"
              onClick={handlePreviousStep}
              leftIcon={<Icon16ChevronLeft />}
            >
              Back
            </BaselaneButton>
            <BaselaneButton
              size="md"
              variant="filled"
              palette="primary"
              width="100%"
              ml={1.5}
              flex={1}
              onClick={isReview ? checkToken : handleNextStep}
              leftIcon={isReview ? <Icon16Check /> : null}
              rightIcon={isReview ? null : <Icon16ChevronRight />}
              isLoading={isReview ? isSubmitting : false}
            >
              {isReview ? 'Transfer' : 'Next'}
            </BaselaneButton>
          </>
        );

      default:
        // Note: success screen
        return (
          <>
            <BaselaneButton
              size="md"
              variant="outline"
              palette="neutral"
              onClick={() => handleDrawerClose({ dirty, resetForm })}
            >
              Done
            </BaselaneButton>
            <BaselaneButton
              size="md"
              variant="filled"
              palette="primary"
              width="100%"
              ml={1.5}
              flex={1}
              onClick={handleMakeAnotherTransfer}
            >
              Add more funds
            </BaselaneButton>
          </>
        );
    }
  };

  return getFooterForCurrentStep();
};

export default Footer;
