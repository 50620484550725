import React from 'react';
import { Heading, ListItem, Stack, Box, UnorderedList } from '@chakra-ui/react';
import { useOutletContext } from 'react-router-dom';
import { Formik } from 'formik';
import { useShallow } from 'zustand/react/shallow';

import { BaselaneCardNew, BaselaneLink } from '@shared/components';
import useCSVImportStore from '@store/CSVImport';

import UploadCSVFields from './UploadCSVFields';
import TabPanelLayout from '../components/TabPanelLayout';
import FooterNavigation from '../components/FooterNavigation';

const UploadCSV = () => {
  const {
    handleFooterNavigation,
    handleTabValidation,
    setCSVTabList,
    formikRefs,
  } = useOutletContext();

  const formikRef = formikRefs[0];

  const [account, CSVFile, setUploadCSVValues] = useCSVImportStore(
    useShallow((state) => [state.account, state.CSVFile, state.setUploadCSVValues])
  );

  const description = (
    <Box>
      Import transactions from another accounting software or bank.{' '}
      <BaselaneLink
        href="https://www.baselane.com/CSV-HCA"
        target="_blank"
        size="md"
        isExternal
        variant="primary"
      >
        Learn more
      </BaselaneLink>
    </Box>
  );

  const handleTabFormSubmit = (values) => {
    setUploadCSVValues(values);
  };

  const handleValidation = (values) => {
    const errors = {};

    if (values.CSVFile === null) {
      errors.CSVFile = 'File not uploaded';
    }
    if (values.account === null) {
      errors.account = 'Please select an account';
    }

    // update tab list state
    if (Object.keys(errors).length > 0) {
      handleTabValidation();
    }

    return errors;
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ account, CSVFile }}
      validate={handleValidation}
      onSubmit={handleTabFormSubmit}
      validateOnMount
    >
      {({ isValid, handleSubmit }) => (
        <TabPanelLayout stepNumber={1} title="Upload CSV" description={description}>
          <BaselaneCardNew size="sm" palette="dark">
            <Stack spacing={0.5}>
              <Heading size="headline-sm">CSV file formatting requirements</Heading>
              <UnorderedList textStyle="sm">
                <ListItem>
                  The CSV file should only contain columns with data, each column must have a header
                  name. Remove other data such as titles or summaries before uploading.
                </ListItem>
                <ListItem my={0.5}>
                  Required columns: Date, Description, Amount (header names can vary)
                </ListItem>
                <ListItem>Optional columns: Type/Category, Property, and Notes</ListItem>
              </UnorderedList>
            </Stack>
          </BaselaneCardNew>

          <UploadCSVFields setCSVTabList={setCSVTabList} />

          <FooterNavigation
            handleFooterNavigation={handleFooterNavigation}
            isValid={isValid}
            handleSubmit={handleSubmit}
          />
        </TabPanelLayout>
      )}
    </Formik>
  );
};

export default UploadCSV;
