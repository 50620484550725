import React, { useContext, useMemo } from 'react';
import { FormControl, FormLabel, Input, HStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';

import BaselaneFormErrorMessage from '@shared/components/BaselaneForm/BaselaneFormErrorMessage/index';
import BaselaneAmount from '@shared/components/BaselaneAmount';
import {
  BaselaneButton,
  T1WithTitleDropdown,
  BaselaneCardNew,
  renderAccountDropdownItem,
} from '@shared/components';
import { DisplayInputDefaultAccount } from '@shared/components/BaselaneDropdown-new/DisplayInputVariations';
import TransactionContext from '@core/contexts/TransactionContext';
import { GET_TRANSACTIONS_SUMMARY } from '@core/apollo/queries';

import DateInputField from '../CreateManualTransaction/components/DateInputField';
import useAccounts from './useAccounts';
import { UPDATE_TRANSACTION } from '../queries/transactions';

const EditTransaction = ({ transaction, onSuccess, onCancel }) => {
  const { summaryFilter } = useContext(TransactionContext);
  const { accounts } = useAccounts();

  const [updateTransaction, { loading: updateTransactionInProgress }] = useMutation(
    UPDATE_TRANSACTION,
    {
      update: (cache, { data: { updateTransactions } }) => {
        const updatedTransaction = updateTransactions[0];

        if (!updatedTransaction.tagId) {
          const res = cache.readQuery({
            query: GET_TRANSACTIONS_SUMMARY,
            variables: { input: summaryFilter },
          });

          if (res) {
            cache.writeQuery({
              query: GET_TRANSACTIONS_SUMMARY,
              data: {
                transactionSummary: {
                  ...res.transactionSummary,
                  totalUncategorized: {
                    ...res.transactionSummary.totalUncategorized,
                    count: res.transactionSummary.totalUncategorized.count + 1,
                    absoluteAmount:
                      res.transactionSummary.totalUncategorized.absoluteAmount -
                      transaction.amount +
                      Math.abs(updatedTransaction.amount),
                  },
                },
              },
              variables: { input: summaryFilter },
            });
          }
        }
      },
      onCompleted: () => onSuccess(),
    }
  );

  const accountsList = useMemo(() => {
    return accounts
      .map((group) => group.items)
      .reduce((accumulatedAccounts, items) => [...accumulatedAccounts, ...items], []);
  }, [accounts]);

  const handleFormValidation = (values) => {
    const errors = {};

    if (!values.description) {
      errors.description = 'Description is required';
    }

    if (!values.amount) {
      errors.amount = 'Amount is required';
    }

    if (!values.date) {
      errors.date = 'Date is required';
    }

    return errors;
  };

  const handleFormSubmit = (values) => {
    const amount = Math.abs(parseFloat(values.amount.replace(/\$|,/g, '')));
    let input = [
      {
        id: transaction.id,
        merchantName: values.description,
        amount: values.amountSign === 'in' ? amount : amount * -1,
        date: DateTime.fromJSDate(values.date).toFormat('yyyy-MM-dd'),
        bankAccountId: values.accountId,
      },
    ];

    if (transaction.isExternal && !transaction.isManual) {
      input = [
        {
          id: transaction.id,
          merchantName: values.description,
        },
      ];
    }

    return updateTransaction({ variables: { input } });
  };

  return (
    <BaselaneCardNew palette="dark">
      <Formik
        initialValues={{
          description: transaction.merchantName,
          amount: `$${transaction.amount}`,
          amountSign: transaction.amount > 0 ? 'in' : 'out',
          accountId: transaction?.bankAccountId,
          date: DateTime.fromISO(transaction.date).toJSDate(),
        }}
        validate={handleFormValidation}
        onSubmit={handleFormSubmit}
      >
        {({ values, errors, isSubmitting, handleChange, setFieldValue, handleSubmit, touched }) => {
          return (
            <Form>
              {/* Description */}
              <FormControl isInvalid={errors.description && touched.description} isRequired>
                <FormLabel htmlFor="description">Description</FormLabel>
                <Input
                  id="description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  placeholder="Description"
                />
                <BaselaneFormErrorMessage isInvalid={errors.description && touched.description}>
                  {errors.description}
                </BaselaneFormErrorMessage>
              </FormControl>

              {/* Amount field */}
              <FormControl
                isInvalid={errors.amount && touched.amount}
                isRequired
                isDisabled={!transaction.isManual}
              >
                <FormLabel htmlFor="amount">Amount</FormLabel>
                <BaselaneAmount
                  isInvalid={errors.amount && touched.amount}
                  isDisabled={!transaction.isManual}
                  dropdownProps={{
                    id: 'amountSign',
                    name: 'amountSign',
                    value: values.amountSign,
                  }}
                  inputProps={{
                    id: 'amount',
                    name: 'amount',
                    value: values.amount,
                  }}
                  onChange={handleChange}
                />
                <BaselaneFormErrorMessage isInvalid={errors.amount && touched.amount}>
                  {errors.amount}
                </BaselaneFormErrorMessage>
              </FormControl>

              {/* Account field */}
              <FormControl
                isInvalid={errors.accountId && touched.accountId}
                isDisabled={!transaction.isManual}
                isRequired
              >
                <FormLabel htmlFor="account">Account</FormLabel>
                <T1WithTitleDropdown
                  // Should hide clear button manually when dropdown is disabled...
                  hideClearButton={!transaction.isManual}
                  isDisabled={!transaction.isManual}
                  hasError={errors.accountId && touched.accountId}
                  additionalProps={{ id: 'account' }}
                  classNames={['input-form-md', 'is-full-width', 'account']}
                  data={accounts}
                  searchTerm={['account', 'bankName']}
                  title="Account"
                  showValueByFields={['bankName', 'account']}
                  itemRenderer={renderAccountDropdownItem}
                  selectedItem={
                    // TODO: Here I take for granted that the account with the selected accountId exists.
                    // TODO: Probably make dropdown throw an error if the selected item is not found.
                    // TODO: Accounts from BE have string ids, while bankAccountId from transaction is a number. Fix this?
                    values.accountId
                      ? accountsList.find((account) => account.id === values.accountId.toString())
                      : {
                          id: 'manual',
                          bankName: 'Manually Added',
                          account: 'Manually Added',
                        }
                  }
                  handleSubmit={(selectedAccountId) => {
                    const selectionIsEmptyArray =
                      Array.isArray(selectedAccountId) && !selectedAccountId.length;
                    // TODO: <T1WithTitleDropdown /> emits string, while transaction.bankAccountId is a number. Fix this?
                    // TODO: <T1WithTitleDropdown /> emits empty array on clear selection. Fix this?
                    const newAccountId =
                      selectedAccountId && !selectionIsEmptyArray
                        ? Number(selectedAccountId)
                        : null;
                    setFieldValue('accountId', newAccountId);
                  }}
                  isMulti={false}
                  hasFilterWrapper={false}
                  showDivider
                  parentId="drawer-body"
                  CustomDisplayInput={DisplayInputDefaultAccount}
                />
                <BaselaneFormErrorMessage isInvalid={errors.accountId && touched.accountId}>
                  {errors.accountId}
                </BaselaneFormErrorMessage>
              </FormControl>

              {/* Date field */}
              <FormControl
                isInvalid={errors.date && touched.date}
                isRequired
                variant="isLast"
                isDisabled={!transaction.isManual}
              >
                <FormLabel htmlFor="date">Date</FormLabel>
                <DateInputField
                  isDisabled={!transaction.isManual}
                  id="date"
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
                <BaselaneFormErrorMessage isInvalid={errors.date && touched.date}>
                  {errors.date}
                </BaselaneFormErrorMessage>
              </FormControl>

              <HStack spacing={1.5} mt={2} justifyContent="flex-end">
                {/* Cancel */}
                <BaselaneButton size="md" variant="outline" palette="neutral" onClick={onCancel}>
                  Cancel
                </BaselaneButton>
                {/* Save */}
                <BaselaneButton
                  id="save-transaction-details-button"
                  data-testid="save-transaction-details-button"
                  size="md"
                  variant="filled"
                  palette="primary"
                  isLoading={updateTransactionInProgress}
                  onClick={handleSubmit}
                  isDisabled={isSubmitting}
                >
                  Save
                </BaselaneButton>
              </HStack>
            </Form>
          );
        }}
      </Formik>
    </BaselaneCardNew>
  );
};

EditTransaction.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string,
    merchantName: PropTypes.string,
    amount: PropTypes.number,
    date: PropTypes.string,
    bankAccountId: PropTypes.number,
    isManual: PropTypes.bool,
    isExternal: PropTypes.bool,
    tagId: PropTypes.string,
  }).isRequired,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
};

EditTransaction.defaultProps = {
  onSuccess: () => {},
};

export default EditTransaction;
