import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { Outlet, useOutletContext, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { unionBy } from 'lodash';
import { useStatsigClient } from '@statsig/react-bindings';
import { Icon16Refresh } from '@icons/16px';
import {
  BaselaneButton,
  BaselaneButtonGroup,
  BaselaneButtonIcon,
  BaselaneDivider,
  BaselaneGrid,
  BaselaneRangeDatePicker,
  CurrencyText,
  DisplayInputButton,
  DisplayInputDefaultDate,
  T1Dropdown,
} from '@shared/components';
import MoveMoneyMenu from '@core/components/Shared/components/MoveMoneyMenu';
import * as ROUTES from '@routes';
import SortHeaderItem from '@shared/components/SortTableHeader/components/SortHeaderItem';
import useBreakPoints from '@core/hooks/useBreakPoints';
import EmptyState from '@core/components/Transactions/EmptyState';
import SlLoader from '@core/components/Loader';
import { BaselaneResponsiveCard } from '@core/components/Shared/components/BaselaneResponsiveCard';
import { IconChevronRight } from '@core/components/Icons';
import {
  DATE_OPTIONS,
  getOption,
} from '@core/components/Shared/components/BaselaneDropdown/components/DatePicker/helpers/datepickerDropdown.helpers';
import UserAccessContext from '@contexts/UserAccessContext';
import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { dateData } from '@core/components/Transactions/components/TransactionsFilters/helpers/transactionsFilters.helpers';
import formatDateTwoDigitDay from '@core/utils/formatDateTwoDigitDay';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { TransferOrigin } from './TransferOrigin';
import { StatusElement } from './StatusElement';
import { controlStyles, sortHeaderContainer } from './styles';
import { GET_TRANSFER_LIST } from './query';

const tableHeaders = [
  {
    title: 'Transfer Date',
    field: 'transferDate',
  },
  {
    title: 'From',
    field: 'fromTransferAccountId',
  },
  {
    title: 'To',
    field: 'toTransferAccountId',
  },
  {
    title: 'Type',
    field: 'type',
  },
  {
    title: 'Amount',
    field: 'amount',
  },
  {
    title: 'Status',
    field: 'state',
  },
];

export function Activity() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTransfer, setSelectedTransfer] = useState(undefined);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [sDate, setSDate] = useState(null);
  const [eDate, setEDate] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isMax768 } = useBreakPoints();
  const { authorizedForBanking } = useContext(UserAccessContext);
  const { checkGate } = useStatsigClient();

  const [transfersList, setTransfersList] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (selectedTransfer) {
      navigate({ pathname: `${selectedTransfer.id}`, search: searchParams.toString() });
    }
  }, [selectedTransfer]);

  const pageLimit = 50;
  // GET Transfers
  const { data, loading } = useQuery(GET_TRANSFER_LIST, {
    variables: {
      input: {
        page,
        pageLimit,
        sort: {
          direction: searchParams.get('direction'),
          field: searchParams.get('sort'),
        },
        filter: {
          fromTransferDate: selectedFilters?.dates?.start,
          toTransferDate: selectedFilters?.dates?.end,
        },
      },
    },

    onError: (error) => console.error(error),
  });
  useEffect(() => {
    if (!searchParams.get('page') || !searchParams.get('dir') || !searchParams.get('sort')) {
      setSearchParams({
        page,
        sort: 'transferDate',
        direction: 'DESC',
      });
    }
    if (data && !loading) {
      const newTransferList = unionBy(transfersList, data.transfers.data, 'id');
      setTransfersList(newTransferList);
    }
  }, []);

  useEffect(() => {
    if (data && !loading) {
      const newTransferList = unionBy(transfersList, data.transfers.data, 'id');
      setTransfersList(newTransferList);
    }
  }, [page, data]);

  const totalTransfersCount = data && data?.transfers?.total;
  const showLoadMoreButton = transfersList?.length < totalTransfersCount;
  const handleLoadMore = () => {
    setPage(page + 1);
  };

  const handleDateFilterChange = ({
    startDate: selectedStartDate = null,
    endDate: selectedEndDate = null,
    dateFilter,
  }) => {
    const dateOption = getOption(
      new Date(moment(selectedStartDate)),
      new Date(moment(selectedEndDate))
    );

    setSelectedFilters({
      timePeriod: dateOption,
      dates: {
        start: selectedStartDate ? new Date(moment(selectedStartDate).valueOf()) : null,
        end: selectedEndDate ? new Date(moment(selectedEndDate).valueOf()) : null,
      },
    });
    setSelectedDateFilter(dateFilter);

    if (dateFilter !== 'custom' && sDate !== null && eDate !== null) {
      setSDate(null);
      setEDate(null);
    }
  };

  const getDateSelectedItem = () => {
    if (
      selectedDateFilter === 'Custom' &&
      (selectedFilters?.timePeriod?.from || selectedFilters?.dates?.start) &&
      (selectedFilters?.timePeriod?.to || selectedFilters?.dates?.end)
    )
      return {
        id: 'custom',
        name: `${formatDateTwoDigitDay(
          selectedFilters?.timePeriod?.from ?? selectedFilters?.dates?.start
        )} - ${formatDateTwoDigitDay(
          selectedFilters?.timePeriod?.to ?? selectedFilters?.dates?.end
        )}`,
      };

    if (
      (selectedFilters?.timePeriod?.from || selectedFilters?.dates?.start) &&
      (selectedFilters?.timePeriod?.to || selectedFilters?.dates?.end)
    ) {
      const preset = DATE_OPTIONS.find((dateOpt) => {
        if (selectedDateFilter === null) {
          return dateOpt.label === 'Last 6 Months';
        }
        return dateOpt.label === selectedDateFilter;
      });

      return { id: preset.value, name: preset.label };
    }

    return null;
  };

  if (!checkGate(FEATURE_GATES.TRANSFERS_PAYMENTS_PAGE)) navigate('/', { replace: true });

  return (
    <>
      <Flex {...controlStyles(isMax768)}>
        <Box {...{ minWidth: { base: '200px', lg: '400px' } }}>
          <T1Dropdown
            classNames={[
              'auto-width-dropdown',
              'auto-select-input-width',
              'input-form-md',
              'auto-height',
              'dateFilter-width-dropdown',
            ]}
            title="Date"
            hideClearButton
            showValueByFields={['name']}
            itemRenderer={itemRenderer}
            selectedItem={getDateSelectedItem()}
            hideSearch
            CustomDisplayInput={DisplayInputDefaultDate}
            data={dateData(onOpen, isMax768)}
            handleSubmit={(datePreset) => {
              const datePresetData = DATE_OPTIONS.find((dateOpt) => {
                if (datePreset.length > 0) {
                  return dateOpt.value === datePreset;
                }
                return false;
              });
              handleDateFilterChange(
                datePresetData
                  ? {
                      startDate: datePresetData.from,
                      endDate: datePresetData.to,
                      dateFilter: datePresetData.label,
                    }
                  : {}
              );
            }}
            isMobile={isMax768}
          />
        </Box>

        <BaselaneButtonGroup size="md">
          {authorizedForBanking && <MoveMoneyMenu entryPoint="activity_tab" />}
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size="md"
            onClick={() =>
              navigate({
                pathname: `/${ROUTES.TRANSFERS_PAYMENTS}/${ROUTES.TRANSFERS_RECIPIENTS}/${ROUTES.TRANSFERS_RECIPIENT_ADD}`,
              })
            }
          >
            Add recipient
          </BaselaneButton>
        </BaselaneButtonGroup>
      </Flex>

      {!isMax768 ? (
        <BaselaneGrid
          gap={0}
          gridItems={tableHeaders.map(({ title, field }) => ({
            styles: { p: '12px 24px' },
            element: (
              <SortHeaderItem
                title={title}
                field={field}
                filters={{
                  sort: {
                    field: searchParams.get('sort'),
                    direction: searchParams.get('direction'),
                  },
                }}
                onSort={(sort) => {
                  setSearchParams((params) => {
                    params.set('sort', sort.field);
                    params.set('direction', sort.direction);
                    return params;
                  });
                  setPage(1);
                  setTransfersList([]);
                }}
              />
            ),
          }))}
          gridStyles={{ ...sortHeaderContainer }}
          templateColumns="14% 21% 21% 11% 15% 15% 5%"
        />
      ) : null}

      {loading && <SlLoader />}

      {!loading && transfersList?.length === 0 ? (
        <EmptyState
          noTransactionsFound
          title="No Transfers & Payments Found"
          paragraph="Try changing or clearing your search filters to view more transactions"
        />
      ) : (
        !loading &&
        transfersList?.length > 0 &&
        transfersList?.map(
          (
            {
              amount,
              id,
              transferDate,
              externalId,
              fromTransferAccount,
              metadata,
              status,
              toTransferAccount,
              type,
              typeName,
            },
            index,
            self
          ) =>
            !isMax768 ? (
              <Box key={id}>
                <BaselaneGrid
                  key={id}
                  gridStyles={{
                    textStyle: 'xs',
                    paddingRight: '80px',
                    alignItems: 'center',
                    minH: '64px',
                  }}
                  gridItems={[
                    {
                      element: (
                        <Text p="12px 24px">{moment(transferDate).format('MMM D, YYYY')} </Text>
                      ),
                    },
                    {
                      element: (
                        <TransferOrigin
                          account={fromTransferAccount}
                          externalId={externalId}
                          origin="from"
                          transferType={type}
                        />
                      ),
                    },
                    {
                      element: !toTransferAccount ? (
                        <Box p="12px 24px">
                          <Text>{metadata?.name}</Text>
                        </Box>
                      ) : (
                        <TransferOrigin
                          account={toTransferAccount}
                          externalId={externalId}
                          origin="to"
                          transferType={type}
                        />
                      ),
                    },
                    {
                      element: (
                        <Text p="12px 24px">
                          {type === 'CHECK_PAYMENT' ? 'Mailed Check' : typeName}
                        </Text>
                      ),
                    },
                    {
                      element: (
                        <Box p="12px 24px">
                          <CurrencyText
                            textStyle="sm"
                            color="brand.neutral.700"
                            amount={amount}
                            isRounded={false}
                            isPercentage={false}
                            negativeColor="brand.neutral.700"
                          />
                        </Box>
                      ),
                    },
                    {
                      element: <StatusElement status={status} />,
                    },
                    {
                      element: (
                        <Box p="12px 24px">
                          <BaselaneButtonIcon
                            aria-label="Transfer Details"
                            onClick={() => setSelectedTransfer(self[index])}
                            variant="outline"
                            palette="neutral"
                            size="sm"
                            icon={<IconChevronRight />}
                          />
                        </Box>
                      ),
                    },
                  ]}
                  templateColumns="14% 21% 21% 11% 15% 15% 5%"
                  gap={0}
                />
                <BaselaneDivider styles={{ m: '0' }} />
              </Box>
            ) : (
              <BaselaneResponsiveCard
                key={id}
                heading={
                  <Flex direction="row" justifyContent="space-between" w="100%">
                    <CurrencyText
                      amount={amount}
                      isRounded={false}
                      isPercentage={false}
                      color="brand.neutral.700"
                      negativeColor="brand.neutral.700"
                      textStyle="headline-sm"
                      {...{ m: 0, p: 0 }}
                    />
                    <BaselaneButton
                      aria-label="Transfer Details"
                      onClick={() => setSelectedTransfer(self[index])}
                      variant="transparent"
                      palette="neutral"
                      size="sm"
                      rightIcon={<IconChevronRight />}
                    >
                      Details
                    </BaselaneButton>
                  </Flex>
                }
              >
                {typeName && <Text textStyles="xs">{typeName}</Text>}
                <Flex direction="row" justify="flex-start">
                  <Text textStyles="sm">{moment(transferDate).format('MMM D, YYYY')}</Text>
                  {status && <StatusElement isMobile={isMax768} status={status} />}
                </Flex>
              </BaselaneResponsiveCard>
            )
        )
      )}

      <Box my="4" textAlign="center">
        {showLoadMoreButton && (
          <BaselaneButton
            styles={{ mb: '56px' }}
            size="lg"
            variant="outline"
            palette="neutral"
            onClick={() => {
              handleLoadMore();
            }}
            isLoading={loading}
            leftIcon={<Icon16Refresh />}
          >
            {totalTransfersCount - transfersList.length} more transfers
          </BaselaneButton>
        )}
      </Box>

      <Outlet
        context={{
          transfer: selectedTransfer,
          clearTransfer: () => setSelectedTransfer(undefined),
        }}
      />

      <BaselaneRangeDatePicker
        {...{
          showInModal: true,
          startDate: sDate,
          endDate: eDate,
          years: { start: 2000, end: new Date().getFullYear() },
          isOpen,
          onOpen,
          onClose,
          value:
            sDate && eDate
              ? `${moment(sDate).format('MMM DD, YYYY')} - ${moment(eDate).format('MMM DD, YYYY')}`
              : '',
          handleCalendarClose: ({ startDate, endDate }) => {
            setSDate(startDate);
            setEDate(endDate);
            handleDateFilterChange({ startDate, endDate, dateFilter: 'Custom' });
          },
          CustomDisplayInput: DisplayInputButton,
          customDisplayInputProps: {
            styles: { display: 'none' },
          },
        }}
      />
    </>
  );
}

export function useTransferOutletContext() {
  return useOutletContext();
}
