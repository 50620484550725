import React from 'react';
import { HStack } from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';

type FooterProps = {
  nextLabel: String,
  previousLabel: String,
  onNext: Function,
  onPrevious: Function,
  nextIsDisabled: Boolean,
};

const Footer = ({ nextLabel, previousLabel, onNext, onPrevious, nextIsDisabled }: FooterProps) => {
  return (
    <HStack w="full">
      {!!previousLabel && (
        <BaselaneButton size="md" variant="outline" palette="neutral" onClick={onPrevious}>
          {previousLabel}
        </BaselaneButton>
      )}
      {!!nextLabel && (
        <BaselaneButton
          isDisabled={!onNext}
          size="md"
          variant="filled"
          palette="primary"
          width="100%"
          ml={1.5}
          flex={1}
          onClick={onNext}
          leftIcon={null}
          isLoading={false}
        >
          {nextLabel}
        </BaselaneButton>
      )}
    </HStack>
  );
};

export default Footer;
