import React, { useState, useEffect, useContext } from 'react';
import { Input, FormControl, Stack, Text, HStack, Box } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { BaselaneDropdown, BaselaneMessageCard, BaselaneTooltip } from '@shared/components';
import { IconBank, IconInfo } from '@icons';
import { Icon12Warning } from '@icons/12px';
import stripCurrency from '@core/utils/stripCurrency';
import formatCurrency from '@core/utils/formatCurrency';
import customTheme from '@core/theme';
import BanksContext from '@contexts/BanksContext';
import SlLoader from '@core/components/Loader';
import PlaceholderIcon from '@shared/components/BaselaneDropdown/components/PlaceholderIcon';
import { useScrollToTop } from '@shared/hooks';
import { accountItemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { formLabelStyles, formInputStyles } from '@shared/styles/input.style';
import { GET_TRANSFERS_ACCOUNT_LIST } from '@core/components/NativeBankingPage/queries';
import {
  ACCOUNT_TYPES,
  inputMaskOptions,
} from '@core/components/NativeBankingPage/MainContent/components/BankTransfer/helpers/transfers.helper';
import {
  formatAccount,
  menuOptionsConverter,
} from '@core/components/NativeBankingPage/MainContent/helpers/accounts.shared.helpers';
import { input } from '@core/components/NativeBankingPage/MainContent/components/BankTransfer/styles/bankTransfer.styles';
import ErrorMessage from './ErrorMessage';

type BankTransferProps = {
  transferType: string,
  setIsValidBankTransfer: Function,
  setIsValidAccount: Function,
  setTransfer: Function,
  refetchBankSummary?: Function,
  transferAmount?: string,
  selectedAccountFrom?: Object,
  memo?: string,
  resetForm?: Boolean,
  hasBEError?: Boolean,
  bankTransferDrawerBodyRef?: any,
  setHasBEError?: Function,
  handleBaselaneBankAndRoutingNumClick?: Function,
  handleChange: Function,
};

function BankTransfer({
  transferType,
  setIsValidBankTransfer,
  setIsValidAccount,
  setTransfer,
  refetchBankSummary,
  transferAmount: tAmount,
  selectedAccountFrom: selectedAccFrom,
  memo: message,
  resetForm,
  hasBEError,
  bankTransferDrawerBodyRef,
  setHasBEError,
  handleBaselaneBankAndRoutingNumClick,
  handleChange,
}: BankTransferProps) {
  const { isMinXL } = useBreakPoints();

  const { loading, error, data } = useQuery(GET_TRANSFERS_ACCOUNT_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      transferType,
    },
  });

  const { loading: bankLoading, banks } = useContext(BanksContext);
  const currencyMask = createNumberMask(inputMaskOptions);

  const checkPaymentFee = 2;
  // State Vars
  const [transferAmount, setTransferAmount] = useState(tAmount);
  const [errors, setErrors] = useState();
  const [selectedAccountFrom, setSelectedAccountFrom] = useState(selectedAccFrom);
  const [touchedTransferAmount, setTouchedTransferAmount] = useState(false);
  const [memo, setMemo] = useState(message);
  const [isMemoValid, setIsMemoValid] = useState(true);
  const [menuOptionsFromAccount, setMenuOptionsFromAccount] = useState([]);

  const getBaselaneAccountLimits = () => {
    const baselaneAccounts = banks.reduce((acc, b) => {
      const selectedId = selectedAccountFrom?.bankAccountId;
      // eslint-disable-next-line consistent-return
      b.bankAccounts.forEach((ba) => {
        if (ba.id === selectedId?.toString()) {
          acc.push(ba);
        }

        // eslint-disable-next-line consistent-return
        ba.subAccounts.forEach((sa) => {
          if (sa.id === selectedId?.toString()) {
            acc.push(sa);
          }
        });
      });

      return acc;
    }, []);
    return baselaneAccounts?.[0]?.limits;
  };

  const transferLimits = getBaselaneAccountLimits();
  const dailyCheckPaymentMonthlyLimit = transferLimits?.checkPaymentDailyLimit;
  const monthlyCheckPaymentMonthlyLimit = transferLimits?.checkPaymentMonthlyLimit;

  // finds menu item that matches id
  const getMenuOption = (selectedFromId) => {
    const item = menuOptionsFromAccount.reduce((acc, group) => {
      const getSelectedItem = group.items.find((groupItem) => groupItem.id === selectedFromId);
      if (getSelectedItem) {
        return getSelectedItem;
      }
      return acc;
    }, []);
    return item;
  };

  // applies changes to selected from account
  const updateSelectedFrom = (selectedItem) => {
    if (transferAmount) {
      setTouchedTransferAmount(true);
    }
    setSelectedAccountFrom(selectedItem);
  };

  // From Accounts Validation
  const hasTransferFromMinBalance =
    parseFloat(stripCurrency(selectedAccountFrom?.value)) > checkPaymentFee;

  // From Accounts Validation involving available balance
  // Note: These need to be calculated dynamically since unders some circumstances
  // the value may be updated after initialization (unlike all the other properties of the from account)
  const isBalanceUnavailableFrom = () => {
    return !selectedAccountFrom?.value || selectedAccountFrom?.value === null;
  };

  const isInsufficientFundsFrom = () =>
    stripCurrency(selectedAccountFrom?.value) < parseFloat(checkPaymentFee);

  const isInsufficientFundsFromWarning =
    selectedAccountFrom?.value &&
    parseFloat(stripCurrency(selectedAccountFrom.value)) <
      parseFloat(stripCurrency(transferAmount)) + parseFloat(checkPaymentFee);

  const alphanumericRegex = /^$|^[a-zA-Z0-9\s]+$/;

  const handleValidation = () => {
    const amount = parseFloat(stripCurrency(transferAmount));
    const isMonthlyCheckAmountExceeded = amount > monthlyCheckPaymentMonthlyLimit;
    const isDailyCheckAmountExceeded = amount > dailyCheckPaymentMonthlyLimit;

    let errorMessage = '';

    if (isDailyCheckAmountExceeded) {
      errorMessage = 'Amount exceeds daily limit for this account.';
    } else if (isMonthlyCheckAmountExceeded) {
      errorMessage = 'Amount exceeds monthly limit for this account.';
    } else if (amount <= 0) {
      errorMessage = 'Amount should be greater than 0';
    }

    setErrors(errorMessage);

    setIsValidBankTransfer(
      transferAmount &&
        touchedTransferAmount &&
        isMemoValid &&
        errorMessage === '' &&
        hasTransferFromMinBalance
    );
  };

  useEffect(() => {
    handleValidation();
    const transfer = { transferType };
    if (selectedAccountFrom) transfer.selectedAccountFrom = selectedAccountFrom;
    if (transferAmount) transfer.transferAmount = transferAmount;

    setTransfer((prevState) => ({ ...prevState, ...transfer }));
    setHasBEError(null);
  }, [selectedAccountFrom, transferAmount, memo]);

  useEffect(() => {
    if (resetForm) {
      setSelectedAccountFrom(null);
      setTransferAmount(null);
      setErrors(null);
      setMemo(message);
      setTouchedTransferAmount(false);
    }
  }, [resetForm]);

  useEffect(() => {
    if (data) {
      const accountFrom = data?.transferAccountList?.fromAccount?.find(
        (acc) => acc?.bankAccountId === selectedAccountFrom?.bankAccountId
      );
      const froAccLst = data?.transferAccountList?.fromAccount || [];
      const firstAccountFrom = froAccLst[0];

      setMenuOptionsFromAccount(
        menuOptionsConverter(
          data?.transferAccountList?.fromAccount,
          ACCOUNT_TYPES[transferType]?.from?.isExternal
        )
      );

      if (accountFrom) {
        const formattedAccountFrom = formatAccount(accountFrom, accountFrom?.isExternal);
        setSelectedAccountFrom(formattedAccountFrom);
      } else if (froAccLst?.length === 1 && firstAccountFrom) {
        // if there's one entry only, prefill
        const formattedAccountFrom = formatAccount(firstAccountFrom, firstAccountFrom?.isExternal);
        setSelectedAccountFrom(formattedAccountFrom);
      }
    }
  }, [data]);

  useScrollToTop(bankTransferDrawerBodyRef, hasBEError);

  if (error) {
    return null;
  }

  if (loading || bankLoading) {
    return <SlLoader />;
  }

  const classNames = [
    'input-form-xl',
    'fontSize-sm',
    'auto-width-dropdown',
    'disable-max-width',
    'auto-width',
  ];

  return (
    <Stack spacing={isMinXL ? 2 : 3}>
      {hasBEError && (
        <Box mb={isMinXL ? '8px' : '36px'}>
          <BaselaneMessageCard
            iconName="close"
            iconColor="red"
            borderColor="red"
            title="Failed Transfer"
            text="Failed to create transfer, try again. If problem persists, please contact us."
            iconContainerStyles={{ w: '20px' }}
            containerStyles={{ width: '100%' }}
          />
        </Box>
      )}
      {/* Transfer From */}
      <FormControl
        isInvalid={selectedAccountFrom && (isBalanceUnavailableFrom() || isInsufficientFundsFrom())}
      >
        <HStack mb="1">
          <Text as="label" {...formLabelStyles.xs}>
            Send From
          </Text>
          <Box>
            <BaselaneTooltip
              placement="top"
              label="Each account has daily and monthly limits for check payments. These limits will be verified when the check is deposited."
              innerContainerStyles={{ ml: 'auto', fontSize: '12px' }}
              styles={{ w: '200px' }}
            >
              <IconInfo width="16" height="16" color="#6C7884" />
            </BaselaneTooltip>
          </Box>
        </HStack>

        <BaselaneDropdown
          {...{
            isDisabled: menuOptionsFromAccount.length === 0,
            classNames,
            data: menuOptionsFromAccount,
            parentId: 'mail-checker-transfer-drawer',
            placeholder: <PlaceholderIcon icon={IconBank} text="Select Account" />,
            showValueByFields: ['name'],
            itemRenderer: accountItemRenderer,
            showSelectedRightElement: true,
            selectedRightElementValue: selectedAccountFrom?.value ?? '',
            hasError:
              selectedAccountFrom && (isBalanceUnavailableFrom() || isInsufficientFundsFrom()),
            handleSubmit: (item) => {
              setIsValidBankTransfer(
                transferAmount &&
                  touchedTransferAmount &&
                  isMemoValid &&
                  errors?.length === 0 &&
                  hasTransferFromMinBalance
              );
              const selectedItem = getMenuOption(item);
              updateSelectedFrom(selectedItem);
            },
            selectedItem: selectedAccountFrom,
            hasDropdownClearedExternally: resetForm,
            setHasDropdownClearedExternally: () =>
              setTransfer((prevState) => ({
                transferType: prevState?.transferType,
                transferDate: prevState?.transferType,
              })),
          }}
        />
        {/* Transfer From: Error Msgs */}
        {selectedAccountFrom && hasTransferFromMinBalance && (
          <Text {...{ ...formLabelStyles.xs }} color="brand.neutral.600" align="left" mt="0.75">
            Daily limit:
            {formatCurrency(transferLimits?.checkPaymentDailyLimit).rounded} - Monthly limit:{' '}
            {formatCurrency(transferLimits?.checkPaymentMonthlyLimit).rounded}
          </Text>
        )}

        {selectedAccountFrom && !hasTransferFromMinBalance && (
          <ErrorMessage
            message={`Minimum balance of ${
              formatCurrency(checkPaymentFee).rounded
            } needed to cover the check fee`}
          />
        )}
      </FormControl>
      {/* Transfer Amount */}
      <FormControl {...{ ...input }} isInvalid={errors && touchedTransferAmount}>
        <HStack mb="8px">
          <Text as="label" {...formLabelStyles.xs}>
            Amount
          </Text>
          <Box>
            <BaselaneTooltip
              placement="top"
              label="The amount will be deducted from your account when the recipient deposits the check"
              innerContainerStyles={{ ml: 'auto' }}
              styles={{ w: '200px' }}
            >
              <IconInfo width="16" height="16" color="#6C7884" />
            </BaselaneTooltip>
          </Box>
        </HStack>
        <Input
          placeholder="$"
          as={MaskedInput}
          value={transferAmount}
          name="transferAmount"
          onChange={(e) => {
            const newAmount = e.target.value;
            setTransferAmount(newAmount);
            setTouchedTransferAmount(true);
            handleValidation();
          }}
          onBlur={() => {
            setTouchedTransferAmount(true);
          }}
          mask={currencyMask}
          {...formInputStyles}
        />

        {errors && touchedTransferAmount && <ErrorMessage message={errors} />}

        {/* Transfer From: Error Msgs */}
        {isInsufficientFundsFromWarning && !errors && (
          <HStack mt="0.75">
            <Box as="span" ml="0.75">
              <Icon12Warning color="#986D1D" />
            </Box>
            <Box as="span">
              <Text
                {...{ ...formLabelStyles.xs }}
                color="yellow.800AA"
                align="left"
                lineHeight="16px"
              >
                Insufficient balance to cover the check amount and $2 processing fee. Make sure you
                have sufficient balance when the check is deposited.
              </Text>{' '}
            </Box>
          </HStack>
        )}
      </FormControl>

      <FormControl {...input} isInvalid={!isMemoValid}>
        <HStack mb="1">
          <Text as="label" {...formLabelStyles.xs}>
            Memo (optional)
          </Text>
          <Box>
            <BaselaneTooltip
              placement="top"
              label="A short message to be printed on the memo line of the check. This is typically used to specify the purpose of the check."
              innerContainerStyles={{ ml: 'auto' }}
              styles={{ w: '200px' }}
            >
              <IconInfo width="16" height="16" color="#6C7884" />
            </BaselaneTooltip>
          </Box>
        </HStack>
        <Input
          maxLength="40"
          value={memo}
          onChange={(e) => {
            e.persist();
            setMemo(e.target.value);
            setIsMemoValid(alphanumericRegex.test(e.target.value));
            setTransfer((prevState) => ({ ...prevState, memo: e.target.value }));
          }}
          {...formInputStyles}
        />
        <Text
          {...{ ...formLabelStyles.xs, color: customTheme.colors.brand.darkBlue['300'] }}
          align="right"
          mt="0.75"
        >
          {memo && <>{40 - memo.length} characters</>}
        </Text>
        {!isMemoValid && (
          <ErrorMessage
            containerStyles={{ mt: '-8px' }}
            message="Only alphanumeric characters are allowed"
          />
        )}
      </FormControl>
    </Stack>
  );
}

BankTransfer.defaultProps = {
  transferAmount: '',
  selectedAccountFrom: null,
  memo: '',
  refetchBankSummary: () => {},
  resetForm: false,
  hasBEError: null,
  bankTransferDrawerBodyRef: null,
  setHasBEError: () => {},
  handleBaselaneBankAndRoutingNumClick: () => {},
};

export default BankTransfer;
