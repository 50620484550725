import React from 'react';
import { HStack, useMultiStyleConfig } from '@chakra-ui/react';
import BaselaneCardNew from '../BaselaneCard-new/BaselaneCardNew';

type BaselaneCardStackItem = {
  id: String,
  content: React.ReactNode,
  style?: Object,
};

type BaselaneCardStackProps = {
  id: String,
  items: Array<BaselaneCardStackItem>,
  size?: 'sm' | 'md' | 'lg',
  stackStyles?: Object,
};

const BaselaneCardStack = ({ id, items, size, stackStyles, ...rest }: BaselaneCardStackProps) => {
  const { stackContainer, cardContainer } = useMultiStyleConfig('BaselaneCardStack', {
    size,
  });

  return (
    <BaselaneCardNew
      id={id}
      className="stack-container"
      {...stackContainer}
      variant="normal"
      size={size}
      {...rest}
    >
      {items.map((item, index) => (
        <HStack
          className="card-container"
          key={`card-stack-item-${item?.id}`}
          {...cardContainer}
          style={{ ...stackStyles }}
          {...item.style}
        >
          {item?.content}
        </HStack>
      ))}
    </BaselaneCardNew>
  );
};

BaselaneCardStack.defaultProps = {
  size: 'md',
};

export default BaselaneCardStack;
