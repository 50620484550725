import React from 'react';

import { getOptionsWithSubCategories } from '../../../CashFlowPage/helpers/cashflow.helpers';
import DisplayInputDefaultCategoryItem from '../../../Transactions/DisplayInputVariations/DisplayInputDefaultCategory';
import useBreakPoints from '../../../../hooks/useBreakPoints';
import useTags from '../../hooks/useTags';
import { itemRenderer } from '../BaselaneDropdown/components/helpers/itemRenderer.helpers';
import T2WithTitleDropdown from '../BaselaneDropdown-new/T2-Title/index';

type BaselaneAutoTagCategoryDropdownProps = {
  categoryId: string,
  onChange: Function,
  isDisabled: boolean,
};

const BaselaneAutoTagCategoryDropdown = ({
  categoryId,
  onChange = () => {},
  isDisabled,
}: BaselaneAutoTagCategoryDropdownProps) => {
  const { categoryMap, categoryIdsMap, categoryWithSubOptions } = useTags();
  const { isMax768 } = useBreakPoints();

  const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);

  const onDropdownSubmit = (selectedOption) => {
    onChange(selectedOption);
  };

  const selectedCategory = categoryId
    ? {
        id: categoryIdsMap[categoryId],
        name: categoryMap[categoryId],
      }
    : null;

  return (
    <T2WithTitleDropdown
      additionalProps={{ id: 'autotag-category-dropdown' }}
      classNames={['input-form-lg', 'is-full-width']}
      data={categoryOptions}
      title="Category"
      showValueByFields={['name']}
      parentItemRenderer={itemRenderer}
      childItemRenderer={itemRenderer}
      handleSubmit={onDropdownSubmit}
      isMulti={false}
      hasFilterWrapper={false}
      selectedItem={selectedCategory}
      CustomDisplayInput={DisplayInputDefaultCategoryItem}
      isDisabled={isDisabled}
      isMobile={isMax768}
    />
  );
};

export default BaselaneAutoTagCategoryDropdown;
