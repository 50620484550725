import React from 'react';
import { Text, Box } from '@chakra-ui/react';

function WorkspaceInfo({ workspace }: { workspace: Object }): any {
  return (
    <Box>
      <Text textStyle="sm" color="brand.neutral.700">
        {workspace.name ? `${workspace?.name}'s workspace` : `My workspace`}
      </Text>
      <Text color="brand.neutral.600" textStyle="xs">
        {workspace?.isOwner ? 'Owner' : 'Collaborator'}
      </Text>
    </Box>
  );
}

export default WorkspaceInfo;
