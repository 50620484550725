/* eslint-disable react/prop-types */
import React from 'react';
import { Text, Tbody } from '@chakra-ui/react';
import {
  BaselaneCardStack,
  BaselaneResponsiveCell,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
} from '@shared/components';
import { formatDate } from '@core/utils/formatDate';
import ReportHeader from '../../components/ReportHeader';
import { isFieldNotObject } from '../../helpers/tenantScreening.helpers';

const ConsumerInformation = ({ personalData, consumerInformation }) => {
  const tableColumnConfig = {
    columns: [
      {
        isSortable: false,
        styles: {
          w: { base: '100%', sm: '320px' },
          overflow: 'visible',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
      {
        isSortable: false,
        styles: {
          w: '100%',
          overflow: 'visible',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
    ],
  };

  return (
    <BaselaneCardStack
      id="consumer-information-card"
      size="md"
      items={[
        {
          id: 'header',
          content: <ReportHeader>Consumer information</ReportHeader>,
        },
        {
          id: 'consumer-information',
          content: (
            <BaselaneResponsiveTable
              id="consumer-information-table"
              config={tableColumnConfig}
              maxW="600px"
              tableContainerStyles={{
                overflowY: 'initial',
                overflowX: 'initial',
              }}
            >
              <Tbody>
                <BaselaneResponsiveTableRows
                  items={[
                    { heading: 'Full name', data: personalData?.full_name ?? '--' },
                    { heading: 'SSN', data: personalData?.ssn ?? '--' },
                    {
                      heading: 'File pulled',
                      data: personalData?.file_pulled
                        ? formatDate(personalData?.file_pulled)
                        : '--',
                    },
                    {
                      heading: 'User ID/Member number',
                      data: personalData?.customer_number ?? '--',
                    },
                    {
                      heading: 'Date of birth',
                      data: personalData?.dob ? formatDate(personalData?.dob) : '--',
                    },
                    {
                      heading: 'Other names',
                      data: consumerInformation?.other_names.length
                        ? consumerInformation?.other_names.join(', ')
                        : '--',
                    },
                    { heading: 'Current address', data: consumerInformation?.address1 ?? '--' },
                    {
                      heading: 'Date reported address',
                      data: consumerInformation?.date_reported_address
                        ? formatDate(consumerInformation?.date_reported_address)
                        : '--',
                    },
                    {
                      heading: 'Address variance indicator',
                      data: consumerInformation?.address_variance_indicator ?? '--',
                    },
                    {
                      heading: 'Current phone',
                      data: consumerInformation?.current_phone_number ?? '--',
                    },
                    {
                      heading: 'Date reported phone',
                      data: consumerInformation?.date_reported_phone_number ?? '--',
                    },
                    {
                      heading: 'SSN status',
                      data: consumerInformation?.ssn_status ?? '--',
                    },
                    {
                      heading: 'SSN match flags',
                      data: consumerInformation?.ssn_match_flags ?? '--',
                    },
                    {
                      heading: 'Issue date',
                      data: consumerInformation?.issue_date
                        ? formatDate(consumerInformation?.issue_date)
                        : '--',
                    },
                    {
                      heading: 'Issue state',
                      data: consumerInformation?.issue_state ?? '--',
                    },
                    {
                      heading: 'Death date',
                      data: consumerInformation?.death_date ?? '--',
                    },
                    {
                      heading: 'Date file was established',
                      data: consumerInformation?.date_file_established
                        ? formatDate(consumerInformation?.date_file_established)
                        : '--',
                    },
                    {
                      heading: 'Date of most recent activity',
                      data: consumerInformation?.date_most_recent_activity
                        ? formatDate(consumerInformation?.date_most_recent_activity)
                        : '--',
                    },
                  ]}
                  customRow
                  renderItem={(row) => {
                    return (
                      <BaselaneResponsiveTableRow
                        key={row.heading}
                        chevronCellStyles={{ w: 0 }}
                        contentContainerStyles={{ border: 'none' }}
                      >
                        <BaselaneResponsiveCell configIndex={0} p={0} colspan="2">
                          <Text as="span" textStyle="sm" color="brand.neutral.700">
                            {row.heading}
                          </Text>
                        </BaselaneResponsiveCell>
                        <BaselaneResponsiveCell configIndex={1} p={0}>
                          <Text textStyle="sm">{isFieldNotObject(row.data) ? row.data : '--'}</Text>
                        </BaselaneResponsiveCell>
                      </BaselaneResponsiveTableRow>
                    );
                  }}
                />
              </Tbody>
            </BaselaneResponsiveTable>
          ),
        },
      ]}
    />
  );
};

export default ConsumerInformation;
