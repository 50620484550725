import { truncatedText } from '@core/components/Shared/styles/text.style';

export const documentCardStyles = (isNewDesign) => ({
  card: {
    p: isNewDesign ? '12px 16px' : '16px',
    borderRadius: '6px',
    bg: 'brand.neutral.white',
    borderWidth: '1px',
    borderColor: 'brand.darkBlue.150',
  },
  textcontainer: {
    spacing: 0,
    overflow: 'hidden',
  },
  nameStyle: {
    fontSize: isNewDesign ? 'sm' : 'xs',
    lineHeight: isNewDesign ? '20px' : '14px',
    color: isNewDesign ? 'brand.neutral.900' : 'brand.neutral.700',
    ...truncatedText,
  },
  typeStyle: {
    fontSize: isNewDesign ? 'xs' : '3xs',
    lineHeight: '14px',
    mt: '4px !important',
    textTransform: 'uppercase',
    ...truncatedText,
  },
});
