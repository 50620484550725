import React, { useState } from 'react';
import { Alert, AlertIcon, AlertTitle, AlertDescription, HStack, Stack } from '@chakra-ui/react';
import { Icon12Close } from '@icons/12px';
import { BaselaneButtonIcon } from '../BaselaneButtonIcon';
import BaselaneAlertNewType from './types/baselaneAlert.types';
import { getDynamicIcon, getActionTypeButton } from './helpers/baselaneAlert.helpers';
import { baselaneAlertStyles } from './styles/baselaneAlert.styles';

const BaselaneAlertNew = ({
  title,
  body,
  variant,
  visual,
  iconName,
  actionProps,
  isVertical,
  isTextVertical,
  hasCloseButton,
  onCloseClick,
  customButton,
  styles,
  ...rest
}: BaselaneAlertNewType) => {
  const [isVisible, setIsVisible] = useState(true);

  const actionType = actionProps && 'link' in actionProps ? 'link' : 'button';

  const {
    container,
    innercontainer,
    textcontainer,
    iconcontainer,
    icon,
    illustration,
    closebutton,
  } = baselaneAlertStyles({
    variant,
    actionType,
    isVertical,
    isTextVertical,
  });

  const { illustration: customIllustration, innercontainer: customInnercontainer } = styles || {};

  return (
    isVisible && (
      <Alert {...{ variant: `alert-${variant}` }} style={styles} data-cy="alert" {...rest}>
        <HStack {...container}>
          {visual &&
            (visual === 'illustration' ? (
              <AlertIcon
                as={iconName ?? getDynamicIcon(variant)}
                {...illustration}
                {...customIllustration}
              />
            ) : (
              <Stack {...iconcontainer}>
                <AlertIcon as={iconName} {...icon} />
              </Stack>
            ))}
          <Stack {...innercontainer} {...customInnercontainer}>
            <Stack {...textcontainer}>
              {title && <AlertTitle as="span">{title}</AlertTitle>}
              {body && <AlertDescription>{body}</AlertDescription>}
            </Stack>
            {customButton && customButton}
            {actionProps && getActionTypeButton({ actionType, actionProps, variant, isVertical })}
          </Stack>
        </HStack>

        {hasCloseButton && (
          <BaselaneButtonIcon
            icon={<Icon12Close color="currentColor" />}
            onClick={() => {
              setIsVisible(false);
              if (onCloseClick) {
                onCloseClick();
              }
            }}
            {...closebutton}
          />
        )}
      </Alert>
    )
  );
};

BaselaneAlertNew.defaultProps = {
  title: null,
  body: null,
  variant: 'primary',
  visual: null,
  iconName: null,
  actionProps: undefined,
  isVertical: false,
  isTextVertical: true,
  hasCloseButton: false,
  onCloseClick: null,
};

export default BaselaneAlertNew;
