import React from 'react';
import { useFormikContext } from 'formik';
import { Icon16ChevronRight, Icon16ChevronLeft } from '@icons/16px';
import { BaselaneButton } from '@shared/components';
import { useUnitOtp } from '@core/contexts/UnitOtpContext';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import { useSetCurrentStep, useIsCurrentStepLoading } from '../../sendFundsStore';
import { steps, getBankId } from '../../helpers/formHelpers';

const ReviewPaymentFooter = () => {
  const setCurrentStep = useSetCurrentStep();
  const formik = useFormikContext();

  const { submitForm, values } = formik;
  const isCurrentStepLoading = useIsCurrentStepLoading();
  const handleGoBack = () => {
    setCurrentStep(steps.BOOKKEPPING_DETAILS);
  };

  const { bankAccountsById, loading } = useBankAccountsActive({
    filterBySubType: ['checking'],
    fetchPolicy: 'network-only',
  });

  const bankId = getBankId({
    values,
    accountsMap: bankAccountsById,
  });

  const { verifyUnitOtp, ignore } = useUnitOtp();

  const handleSaveAndContinue = () => {
    if (!bankId) {
      return;
    }

    verifyUnitOtp(bankId).then(submitForm).catch(ignore);
  };

  return (
    <>
      <BaselaneButton
        size="md"
        variant="outline"
        palette="neutral"
        onClick={handleGoBack}
        leftIcon={<Icon16ChevronLeft />}
      >
        Back
      </BaselaneButton>
      <BaselaneButton
        id="save-and-continue-button"
        size="md"
        variant="filled"
        palette="primary"
        width="100%"
        ml={1.5}
        flex={1}
        onClick={handleSaveAndContinue}
        rightIcon={<Icon16ChevronRight />}
        isLoading={isCurrentStepLoading || loading}
        isDisabled={!bankId}
      >
        Save and continue
      </BaselaneButton>
    </>
  );
};
export default ReviewPaymentFooter;
