import React from 'react';
import { Outlet } from 'react-router-dom';
import { FirebaseOtpProvider } from '../../../../contexts/FirebaseOtpContext';

/**
 * This wrapper provides a context for interacting with the Unit OTP UI.
 * It also inserts the needed UI into the page, should it need to be displayed
 * to the user.
 */

const FirebaseOtpWrapper = () => {
  return (
    <FirebaseOtpProvider>
      <Outlet />
    </FirebaseOtpProvider>
  );
};

export default FirebaseOtpWrapper;
