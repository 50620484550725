// 1. Import the extendTheme util - it will merge with the default theme
import { extendTheme } from '@chakra-ui/react';

import textStyles from '@core/theme/text';
import colors from '@core/theme/colors';
import breakpoints from '@core/theme/breakpoints';
import shadows from '@core/theme/shadows';
import typography from '@core/theme/typography';
import spacing from '@core/theme/spacing';
import radii from '@core/theme/radius';
import styles from '@core/theme/styles';

import { Alert } from './theme/component/Alert/alert';
import { Button } from './theme/component/Button/button';
import { Drawer } from './theme/component/drawer';
import { Heading } from './theme/component/heading';

import { Link } from './themeHabitat/component/Link/link';
import { Form } from './themeHabitat/component/form';
import { FormError } from './themeHabitat/component/formError';
import { Input } from './themeHabitat/component/input';
import { Radio } from './themeHabitat/component/radio';
import { Checkbox } from './themeHabitat/component/checkbox';
import { Select } from './themeHabitat/component/select';
import { Tag } from './theme/component/Tag/tag';
import { Textarea } from './themeHabitat/component/textarea';
import { Switch } from './themeHabitat/component/switch';
import { BaselaneCard } from './themeHabitat/component/BaselaneCard/card';
import { BaselaneCardStack } from './themeHabitat/component/BaselaneCardStack/cardStack';
import { Tabs } from './themeHabitat/component/tabs';

const components = {
  Alert,
  Button,
  Link,
  Drawer,
  Form,
  FormError,
  Heading,
  Input,
  Radio,
  Checkbox,
  Select,
  Tag,
  Textarea,
  Switch,
  BaselaneCard,
  BaselaneCardStack,
  Tabs,
};

const themeMap = {
  colors,
  components,
  ...typography,
  shadows,
  breakpoints,
  styles,
  textStyles,
  radii,
  space: spacing.space,
};

const habitatTheme = extendTheme(themeMap);

export default habitatTheme;
