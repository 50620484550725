import { useQuery } from '@apollo/client';
import { GET_PROPERTIES_DROPDOWN_WITH_LEASE_ID } from '@core/apollo/queries';

const useProperties = () => {
  const { data, loading, error } = useQuery(GET_PROPERTIES_DROPDOWN_WITH_LEASE_ID);

  const getUnitIdFromScreeningId = (screeningId, properties) => {
    let unitId = null;
    properties.forEach((property) => {
      const { units } = property || [];
      const unit = units.find((currentUnit) => {
        const { tenantScreening } = currentUnit || [];
        return tenantScreening.find(
          (screening) => Number(screening.tenantScreeningId) === Number(screeningId)
        );
      });
      if (unit) {
        unitId = unit.id;
      }
    });

    return unitId;
  };

  return {
    loading,
    data,
    error,
    getUnitIdFromScreeningId,
  };
};

export default useProperties;
