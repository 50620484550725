import LogRocket from 'logrocket';

export const tabsListItems = ['Active', 'Inactive'];

export const tenantScreeningsListConfig = {
  columns: [
    // 1. Property
    {
      isSortable: false,
      styles: {
        w: { sm: 'auto', base: '40%' },
        display: 'table-cell',
        contentContainerStyles: {
          px: 2,
          py: 2,
        },
      },
    },
    // 2. Available on
    {
      isSortable: false,
      styles: {
        w: { '2lg': '152px', sm: '112px', base: 'none' },
        display: { md: 'table-cell', base: 'none' },
        verticalAlign: { sm: 'middle', base: 'top' },
        align: 'left',
        contentContainerStyles: {
          justifyContent: 'flex-start',
          px: 2,
          py: 2,
        },
      },
    },
    // 3. Listed rent
    {
      isSortable: false,
      styles: {
        w: { '2lg': '152px', sm: '112px', base: 'none' },
        display: { md: 'table-cell', base: 'none' },
        verticalAlign: { sm: 'middle', base: 'top' },
        contentContainerStyles: {
          justifyContent: 'flex-end',
          px: 2,
          py: 2,
        },
      },
    },
    // 3. Applications
    {
      isSortable: false,
      styles: {
        w: { '2lg': 'auto', base: '40%' },
        display: 'table-cell',
        verticalAlign: { sm: 'middle', base: 'top' },
        contentContainerStyles: {
          justifyContent: 'flex-end',
          px: 2,
          py: 2,
        },
      },
    }, // 4. CTA's
    {
      isSortable: false,
      styles: {
        overflow: 'visible',
        w: { '2lg': 'auto', base: 'auto' },
        display: { md: 'table-cell', base: 'none' },
        verticalAlign: { sm: 'middle', base: 'top' },
        contentContainerStyles: {
          justifyContent: 'flex-end',
          px: 2,
          py: 2,
        },
      },
    },
  ],
};

export const tenantScreeningsListTableHeaderItems = [
  {
    key: 'Property',
    label: 'Property',
    field: 'PROPERTY',
    isSortable: false,
  },
  {
    key: 'Available',
    label: 'Available on',
    field: 'AVAILABLE',
    isSortable: false,
  },
  {
    key: 'Listed',
    label: 'Listed rent',
    field: 'LISTED',
    isSortable: false,
  },
  {
    key: 'Applications',
    label: 'Applications',
    field: 'APPLICATIONS',
    isSortable: false,
  },
  {
    key: 'Actions',
    label: '',
    field: 'ACTIONS',
    isSortable: false,
  },
];

export const tenantScreeningByIdListConfig = {
  columns: [
    // 1. Applicants
    {
      isSortable: false,
      styles: {
        w: { sm: 'auto', base: '40%' },
        display: 'table-cell',
        contentContainerStyles: {
          px: 2,
          py: 2,
        },
      },
    },
    // 2. Submission date
    {
      isSortable: true,
      styles: {
        w: { '2lg': 'auto', base: '40%' },
        display: { md: 'table-cell', base: 'none' },
        verticalAlign: { sm: 'middle', base: 'top' },
        align: 'left',
        contentContainerStyles: {
          justifyContent: 'flex-start',
          px: 2,
          py: 2,
        },
      },
    },
    // 3. Status
    {
      isSortable: true,
      styles: {
        w: { '2lg': 'auto', base: '40%' },
        display: 'table-cell',
        verticalAlign: { sm: 'middle', base: 'top' },
        contentContainerStyles: {
          justifyContent: 'flex-start',
          px: 2,
          py: 2,
        },
      },
    },
    // 4. Actions
    {
      isSortable: false,
      styles: {
        overflow: 'visible',
        w: { '2lg': 'auto', base: 'auto' },
        display: { md: 'table-cell', base: 'none' },
        verticalAlign: { sm: 'middle', base: 'top' },
        contentContainerStyles: {
          justifyContent: 'flex-end',
          px: 2,
          py: 2,
        },
      },
    },
  ],
};

export const tenantScreeningByIdListTableHeaderItems = [
  {
    key: 'Applicants',
    label: 'Applicants',
    field: 'applicants',
    isSortable: false,
  },
  {
    key: 'SubmissionDate',
    label: 'Submission date',
    field: 'submissionDate',
    isSortable: true,
  },
  {
    key: 'Status',
    label: 'Status',
    field: 'applicationStatus',
    isSortable: true,
  },
  {
    key: 'Actions',
    label: '',
    field: 'ACTIONS',
    isSortable: false,
  },
];

export const convertTenantScreeningsById = (data) => {
  return data?.applications.map((item) => ({
    applicants: item.applicants.map((applicant) => applicant.applicantName || applicant.emailId),
    submissionDate: item.submissionDate,
    applicationStatus: item.applicationStatus,
    id: item.id,
  }));
};

export const isFieldNotObject = (field) => {
  if (typeof field !== 'object') {
    return true;
  }
  LogRocket.log('field is object or null error', field);
  return false;
};
export const getCallToActionLabel = (application) => {
  const { applications } = application;
  let buttonVariant = 'tonal';
  let label = '';
  if (applications?.length === 0) {
    if (application?.applicationLinkActive) {
      label = 'Invite applicants';
    } else {
      buttonVariant = 'transparent';
      label = 'View details';
    }
  }
  if (applications?.length > 0) {
    if (applications?.filter((item) => item.applicationStatus === 'APPROVED').length > 0) {
      buttonVariant = 'transparent';
      label = 'View details';
    } else {
      buttonVariant = 'tonal';
      label = 'Review';
    }
  }
  return { buttonVariant, buttonLabel: label };
};
