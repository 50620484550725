import { GET_TRANSACTIONS_SUMMARY } from '@core/apollo/queries';
import {
  addPropertyNamesToTransactions,
  addPropertyNameToTransaction,
} from '@core/components/Transactions/helpers/helpers';
import { sortAndOrderTransactions } from '@core/components/Transactions/SplitTransactionsDrawer/helpers/updateCache.helper';

export function handleDateSubmit(
  updateTransaction,
  transaction,
  newDate: string,
  toastProps = null
) {
  updateTransaction({ date: newDate }, toastProps, null, {
    optimisticResponse: { updateTransactions: { ...transaction, date: newDate } },
  });
}

export async function handleCategorySubmit(
  updateTransaction,
  transaction,
  newCategoryId: string,
  toastProps = { showToast: false, updatedField: 'Category' },
  onSuccess = null
) {
  const [parentId = null, subId = null] =
    typeof newCategoryId === 'string' ? newCategoryId.split('-') : [];
  updateTransaction({ tagId: subId ?? parentId }, toastProps, onSuccess, {
    optimisticResponse: { updateTransactions: { ...transaction, tagId: subId ?? parentId } },
  });
}

export function handlePropertySubmit(
  updateTransaction,
  transaction,
  newPropertyId: string,
  toastProps = { showToast: false, updatedField: 'Property' }
) {
  const [newPId = null, newUId = null] =
    typeof newPropertyId === 'string' ? newPropertyId.split('-') : [];
  updateTransaction({ propertyId: newPId, unitId: newUId }, toastProps, null, {
    optimisticResponse: {
      updateTransactions: { ...transaction, propertyId: newPId, unitId: newUId },
    },
  });
}

export const getOrderedTransactions = ({
  cachedTransactions,
  filters,
  propertyIdsMap,
  updatedTransaction,
}) => {
  const updatedTransactionWithPropertyName = addPropertyNameToTransaction(
    updatedTransaction,
    propertyIdsMap
  );

  const transactionsWithProperties = addPropertyNamesToTransactions(
    cachedTransactions,
    propertyIdsMap
  );

  return sortAndOrderTransactions(
    transactionsWithProperties.map((t) =>
      t.id === updatedTransactionWithPropertyName.id ? updatedTransactionWithPropertyName : t
    ),
    filters
  );
};

export const updateTransactionSummaryData = ({
  transaction,
  updatedTransaction,
  cache,
  summaryFilter,
}) => {
  // account for category change
  if (transaction?.tagId !== updatedTransaction.tagId) {
    const res = cache.readQuery({
      query: GET_TRANSACTIONS_SUMMARY,
      variables: { input: summaryFilter },
    });

    if (res) {
      // eslint-disable-next-line
      let absoluteAmount = res.transactionSummary.totalUncategorized.absoluteAmount;
      // eslint-disable-next-line
      let count = res.transactionSummary.totalUncategorized.count;
      if (transaction?.tagId && !updatedTransaction.tagId) {
        absoluteAmount += Math.abs(updatedTransaction.amount);
        count += 1;
      } else if (!transaction?.tagId && updatedTransaction.tagId) {
        absoluteAmount -= Math.abs(updatedTransaction.amount);
        count -= 1;
      }

      cache.writeQuery({
        query: GET_TRANSACTIONS_SUMMARY,
        data: {
          transactionSummary: {
            ...res.transactionSummary,
            totalUncategorized: {
              ...res.transactionSummary.totalUncategorized,
              count,
              absoluteAmount,
            },
          },
        },
        variables: { input: summaryFilter },
      });
    }
  }
};
