export const containerStyles = {
  width: '100%',
  p: { md: 3, base: 2 },
};

export const emptyStateContainerStyles = {
  ...containerStyles,
  height: '100%',
  justifyContent: 'center',
};
