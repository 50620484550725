import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IllustrationBankingNewaccountyellow } from '@illustrations';
import { BaselaneAlertNew, BaselaneButton } from '@shared/components';
import { NB_ACCOUNTS } from '@routes';
import useBreakPoints from '@core/hooks/useBreakPoints';

type AutoTaggingGatingBannerProps = {
  isInDrawer?: Boolean,
  hideButton?: Boolean,
};

const AutoTaggingGatingBanner = ({
  isInDrawer = false,
  hideButton = false,
  ...rest
}: AutoTaggingGatingBannerProps) => {
  const navigate = useNavigate();
  const { isMax767 } = useBreakPoints();

  return (
    <BaselaneAlertNew
      variant="warning"
      visual="illustration"
      iconName={IllustrationBankingNewaccountyellow}
      title="Open a free Baselane Banking account to unlock full access"
      body="You will not be able to use AI categorization or have full access to bookkeeping rules without a Baselane Banking account."
      isVertical={isInDrawer || isMax767}
      customButton={
        !hideButton ? (
          <BaselaneButton
            palette="neutral"
            variant="outline"
            onClick={() =>
              navigate(NB_ACCOUNTS, {
                state: { startWithOpenedAccountTypeModal: true },
              })
            }
            flexShrink={0}
          >
            Get full access
          </BaselaneButton>
        ) : null
      }
      {...rest}
    />
  );
};

export default AutoTaggingGatingBanner;
