/* eslint-disable react/self-closing-comp */
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { reactNodeEnv } from '@core/constants/envVars';
import { modalStyles } from '@shared/components/RocketLawyerDocumentDrawer/components/RocketLawyeriFrame/styles/rocketLawyeriFrame.styles';

type RocketLawyeriFrameProps = {
  showiFrame: Boolean,
  handleCloseiFrame: Function,
  templateId: String,
};

const RocketLayerDocsIframe = ({
  showiFrame,
  handleCloseiFrame,
  templateId = 'a02bda99-cd8d-4175-a4ea-587306d96d81', // Default NY state template id
}: RocketLawyeriFrameProps) => {
  const { header, closeBtn, content, iframe } = modalStyles ?? {};
  const environment =
    reactNodeEnv === 'production' || reactNodeEnv === 'pre-prod' || reactNodeEnv === 'hotfix'
      ? 'live'
      : 'sandbox';
  return (
    <Modal
      isOpen={showiFrame}
      onClose={() => handleCloseiFrame(false)}
      size="full"
      padding={0}
      overflow="hidden"
    >
      <ModalOverlay />
      <ModalContent {...content} overflow="hidden">
        <ModalHeader {...header}>Lease Document</ModalHeader>
        <ModalCloseButton {...closeBtn} />
        <ModalBody padding={0} {...iframe}>
          <iframe
            id="rocketSignIFrame"
            title="rocketSignIFrame"
            src={
              environment === 'sandbox'
                ? `https://api-sandbox.rocketlawyer.com/rocketdoc/v2/templates/${templateId}/preview`
                : `https://api-sandbox.rocketlawyer.com/rocketdoc/v2/templates/${templateId}/preview`
            }
            style={{ width: '100%', height: '100%' }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RocketLayerDocsIframe;
