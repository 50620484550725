export const containerStyles = {
  padding: '16px',
  _first: {
    paddingTop: '8px',
  },
  color: 'brand.neutral.600',
  backgroundColor: 'brand.neutral.white',
  maxWidth: '100%',
  width: '100%',
  align: 'flex-start',
  justify: 'flex-start',
  gap: 0,
  _notLast: {
    boxShadow: 'inset 0px -1px 0px #F0F2F6',
  },
  display: 'flex',
  flexGrow: '1',
};

export const contentContainerStyles = {
  width: '100%',
  align: 'flex-start',
  marginTop: '0 !important',
};

export const headerStyles = {
  width: '100%',
  justify: 'space-between',
};

export const headingContainerStyles = {
  flexGrow: '1',
  justify: 'space-between',
  margin: '0 !important',
  padding: '0',
};

export const headerIconContainerStyles = {
  marginRight: '12px',
  justify: 'center',
  align: 'center',
  display: 'flex',
};

export const headerIconStyles = {
  w: '16',
  h: '16',
};

export const headingStyles = {
  color: 'brand.neutral.700',
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '24px',
  margin: '0',
  padding: '0',
};

export const currencyStyles = {
  color: 'brand.neutral.700',
  textStyle: 'headline-sm',
  margin: '0',
  padding: '0',
};

export const actionLabelStyles = {
  color: 'brand.neutral.600',
  textStyle: 'xs',
  margin: '0',
  padding: '0',
  align: 'center',
};

export const addressTextStyles = {
  textStyle: 'sm',
  w: '100%',
};

export const invoiceCardHeaderStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.700',
  whiteSpace: 'nowrap',
};

export const invoiceCardTextStyles = {
  textStyle: 'headline-lg',
  color: 'brand.neutral.700',
};

export const invoiceListLoadMoreStyles = (isMobile) => ({
  fontSize: 'sm',
  color: 'brand.blue.700',
  marginTop: '8px',
  marginLeft: isMobile ? '8px' : '0px',
});

export const balanceTextStyles = {
  textStyle: 'xs',
  w: '100%',
};

export const propertyDisabledTypeTextStyles = {
  color: 'brand.darkBlue.300',
};

export const transferTypeTextStyles = {
  textStyle: 'xs',
};

export const transferDateTextStyles = {
  textStyle: 'sm',
};

export const unitCardStyles = (noPointer, noMaxHeight, noMaxWidth) => ({
  padding: '16px!important',
  border: '1pt solid',
  borderColor: 'brand.darkBlue.150',
  margin: '8px 0',
  boxShadow: '1px 2px 6px rgba(3, 24, 64, 0.04)',
  cursor: noPointer ? 'default' : 'pointer',
  width: 'unset',
  maxWidth: noMaxWidth ? '100%' : '600px',
  gap: 0,
  maxHeight: noMaxHeight ? '100%' : '80px',
  borderRadius: '4px',
  _notLast: {
    boxShadow: '1px 2px 6px rgba(3, 24, 64, 0.04)',
  },
});
