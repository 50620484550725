import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { Flex, Box, Text, Switch } from '@chakra-ui/react';
import { Highlighted } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { BaselaneButton, BaselaneInput, BaselaneButtonIcon, T1Dropdown } from '@shared/components';
import { Icon16Minus, Icon16PlusCircle } from '@icons/16px/index';
import { textOperators, matchTypeOperators } from './constants';

type DescriptionProps = {
  isDisabled?: boolean,
};

const Description = ({ isDisabled = false }: DescriptionProps) => {
  const { values, setFieldValue } = useFormikContext();
  const shouldShowAnyAllDropdown =
    values.textValues.length > 1 || (values.textValues.length === 1 && values.text);

  const itemRenderer = ({ item, search }) => (
    <Box textStyle="sm" color="inherit">
      <Highlighted text={item?.name} highlight={search} />
    </Box>
  );

  const addText = () => {
    setFieldValue('textValues', [...values.textValues, { id: uuidv4(), text: values.text }]);
    setFieldValue('text', '');
  };

  useEffect(() => {
    if (values.textOperator !== 'contains') {
      setFieldValue('matchType', { id: '1', name: 'ANY of the values', operator: 'OR' });
    }
  }, [values.textOperator]);

  return (
    <Box
      border="1px solid"
      borderColor="brand.darkBlue.200"
      borderRadius="4px"
      background="brand.darkBlue.50"
      p={2}
    >
      <Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Text
            textStyle="sm"
            fontWeight="500"
            color={isDisabled ? 'brand.neutral.400' : 'inherit'}
          >
            Description
          </Text>
          <Switch
            value="some value"
            isDisabled={isDisabled}
            isChecked={values.isDescriptionOpen}
            onChange={() => setFieldValue('isDescriptionOpen', !values.isDescriptionOpen)}
          />
        </Flex>
        {values.isDescriptionOpen && !isDisabled && (
          <Box mt={3}>
            <Flex gap={2} mt={3}>
              <Box w={shouldShowAnyAllDropdown ? '50%' : '100%'}>
                <T1Dropdown
                  classNames={[
                    'input-form-md',
                    'auto-width-dropdown',
                    'select-auto-width',
                    'auto-select-input-width',
                    'input-form-md',
                    'auto-height',
                  ]}
                  hideSearch
                  data={textOperators}
                  showValueByFields={['name']}
                  itemRenderer={itemRenderer}
                  handleSubmit={(value) => {
                    setFieldValue(
                      'textOperator',
                      textOperators.find((item) => item.id === value)
                    );
                  }}
                  selectedItem={textOperators.find((item) => item.id === values.textOperator?.id)}
                />
              </Box>
              {shouldShowAnyAllDropdown && (
                <Box w="50%">
                  <T1Dropdown
                    classNames={[
                      'input-form-md',
                      'auto-width-dropdown',
                      'select-auto-width',
                      'auto-select-input-width',
                      'input-form-md',
                      'auto-height',
                    ]}
                    hideSearch
                    isDisabled={values.textOperator.name !== 'contains'}
                    data={matchTypeOperators}
                    showValueByFields={['name']}
                    itemRenderer={itemRenderer}
                    handleSubmit={(value) => {
                      setFieldValue(
                        'matchType',
                        matchTypeOperators.find((item) => item.id === value)
                      );
                    }}
                    selectedItem={matchTypeOperators.find(
                      (item) => item.id === values.matchType?.id
                    )}
                  />
                </Box>
              )}
            </Flex>
            <Box mt={1}>
              {values.textValues.map((text) => (
                <Flex key={text.id}>
                  <Flex mb={1} h="40px" bg="brand.neutral.white" alignItems="center" w="100%">
                    <BaselaneInput
                      id="text"
                      name="text"
                      value={text.text}
                      size="md"
                      onChange={(event) => {
                        setFieldValue(
                          'textValues',
                          values.textValues
                            .map((item) =>
                              item.id === text.id
                                ? { ...item, text: event.target.value.trim() }
                                : item
                            )
                            .filter((item) => item.text)
                        );
                      }}
                      bg="brand.neutral.white"
                      fontSize="14px"
                    />
                  </Flex>
                  <BaselaneButtonIcon
                    size="md"
                    variant="transparent"
                    palette="danger"
                    onClick={() => {
                      const newValues = values.textValues.filter((d) => d.id !== text.id);
                      setFieldValue('textValues', newValues);
                    }}
                    isLoading={false}
                    isDisabled={false}
                    data-cy="remove-value"
                    icon={<Icon16Minus color="red.800AA" />}
                    ml={0.5}
                  />
                </Flex>
              ))}
              <Flex h="40px" alignItems="center" w="100%">
                <BaselaneInput
                  id="text"
                  name="text"
                  value={values.text}
                  size="md"
                  onChange={(event) => {
                    setFieldValue('text', event.target.value.trim());
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      if (values.text.trim() !== '') {
                        addText();
                      }
                    }
                  }}
                  placeholder="eg. Home depot"
                  bg="brand.neutral.white"
                  fontSize="14px"
                />
                {values.textValues.length > 0 && values.text && (
                  <BaselaneButtonIcon
                    size="md"
                    variant="transparent"
                    palette="danger"
                    onClick={() => {
                      setFieldValue('text', '');
                    }}
                    isLoading={false}
                    isDisabled={false}
                    data-cy="remove-value"
                    icon={<Icon16Minus color="red.800AA" />}
                    ml={0.5}
                  />
                )}
              </Flex>
              <BaselaneButton
                mt={1}
                size="md"
                variant="transparent"
                palette="primary"
                onClick={addText}
                isLoading={false}
                data-cy="add-value"
                leftIcon={<Icon16PlusCircle />}
                isDisabled={!values.text}
              >
                Add value
              </BaselaneButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Description;
