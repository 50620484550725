import { React } from 'react';
import { Stack, Text, useMediaQuery } from '@chakra-ui/react';
import { Icon24AddFunds1 } from '@icons/24px';
import { BaselaneButton, BaselaneButtonIcon } from '@shared/components';
import { InProgress } from '@pages/Dashboard/SetupGuide/GuideTrackers/Steps';
import customTheme from '@theme';
import { triggerBtnLableStyles, triggerBtnWrapperStyles } from './styles/addFundsDrawer.styles';

type TriggerButtonProps = {
  isGetStarted: Boolean,
  getStartedConfig: Object,
  onOpenAddFundsDrawer: Function,
  buttonProps: Object,
  showMobileVersion: Boolean,
};

const TriggerButton = ({
  isGetStarted,
  getStartedConfig,
  onOpenAddFundsDrawer,
  buttonProps,
  showMobileVersion,
}: TriggerButtonProps) => {
  const [showMobileButton] = useMediaQuery(`(max-width: ${customTheme.breakpoints.sm} )`, {
    ssr: false,
  });

  const handleClick = () => {
    return onOpenAddFundsDrawer();
  };

  if (isGetStarted) {
    return (
      <InProgress
        status={getStartedConfig.status}
        handleTrackerInProgStepClick={onOpenAddFundsDrawer}
      />
    );
  }

  if (showMobileButton && showMobileVersion) {
    return (
      <Stack {...triggerBtnWrapperStyles}>
        <BaselaneButtonIcon icon={<Icon24AddFunds1 />} onClick={handleClick} {...buttonProps} />

        <Text {...triggerBtnLableStyles}>Add Funds</Text>
      </Stack>
    );
  }

  return (
    <BaselaneButton onClick={handleClick} {...buttonProps}>
      {buttonProps?.text ?? 'Add Funds'}
    </BaselaneButton>
  );
};

export default TriggerButton;
