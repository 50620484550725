import React from 'react';
import { Box, FormControl, FormLabel, Flex, Input } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { BaselaneButtonToggle, BaselaneInput } from '@shared/components';
import stripCurrency from '@core/utils/stripCurrency';
import { formLabelStyles } from '@shared/styles/input.style';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { currencyMask } from '@core/utils/masks/index';
import { fieldContainer } from '../../../styles/shared.styles';

/**
 * Controls the input mask for the due days
 */
const dueDateInputMaskOptions = {
  prefix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  allowNegative: false,
  integerLimit: 2,
  allowLeadingZeroes: false,
};

/**
 * Controls the input mask for percentages
 */
const percentageInputMaskOptions = {
  prefix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  allowNegative: false,
  integerLimit: 3,
  allowLeadingZeroes: false,
};

/**
 * Pipe function that prevents the entering of '0'
 * @param {string} conformedValue processed string before display
 * @returns
 */

const preventZero = (conformedValue) => {
  if (Number(conformedValue) === 0) return false;
  return conformedValue;
};

/**
 * Pipe function that prevents the entering of more than 100%
 * @param {string} conformedValue processed string before display
 * @returns
 */

const preventGreaterThanMaxPercentage = (conformedValue) => {
  if (Number(conformedValue) > 100) return false;
  return conformedValue;
};

type Props = {
  feeType: any,
  feeAmount: any,
  feePercent: any,
  daysAfterDueDate: any,
  isDueDateDisabled: boolean,
  onFeeTypeChange: Function,
  onFeeAmountChange: Function,
  onFeePercentChange: Function,
  onDueDateChange?: Function,
};

const LateFeeInputPanel = ({
  feeType,
  feeAmount,
  feePercent,
  daysAfterDueDate,
  isDueDateDisabled,
  onFeeTypeChange,
  onFeeAmountChange,
  onFeePercentChange,
  onDueDateChange,
}: Props) => {
  const { isMax767 } = useBreakPoints();
  const isFeeFlat = feeType === 'FLAT';

  const handleFeeInputChange = (e) => {
    if (isFeeFlat) {
      onFeeAmountChange(stripCurrency(e.target.value));
    } else {
      onFeePercentChange(+e.target.value);
    }
  };

  const disabledDueDateInput = (
    <Input
      id="disabled-due-date"
      name="disabled-due-date"
      size="lg"
      fontSize="14px"
      placeholder="per day after the initial fee"
      disabled
      sx={{
        opacity: '1 !important',
        '::placeholder': {
          color: '#6c7884',
        },
      }}
    />
  );

  const dueDateInput = (
    <BaselaneInput
      id="due-date"
      name="due-date"
      value={daysAfterDueDate}
      suffix="day(s) after the due date"
      size="lg"
      fontSize="sm"
      as={MaskedInput}
      mask={createNumberMask(dueDateInputMaskOptions)}
      pipe={preventZero}
      inputGroupProps={{ backgroundColor: 'brand.neutral.white' }}
      onChange={(e) => {
        if (e.target.value) {
          onDueDateChange?.(e.target.value);
        }
      }}
    />
  );

  return (
    <Flex gap={1} flexDirection={isMax767 ? 'column' : 'row'}>
      <FormControl
        maxW={200}
        isRequired
        {...fieldContainer}
        mt={isMax767 && '0px !important'}
        mb={isMax767 && '16px !important'}
      >
        <FormLabel {...formLabelStyles.xs} htmlFor="fee-type">
          Fee type
        </FormLabel>
        <BaselaneButtonToggle
          size="md"
          isFullWidth
          firstLabel="$ Amount"
          secondLabel="% of rent"
          variant="toggle"
          palette="neutral"
          activeButton={feeType === 'FLAT' ? 0 : 1}
          onClick={(value) => {
            onFeeTypeChange(value === 0 ? 'FLAT' : 'PERCENT');
          }}
        />
      </FormControl>
      <FormControl
        flex="0.40"
        isRequired
        {...fieldContainer}
        mt={isMax767 && '0px !important'}
        mb={isMax767 && '16px !important'}
      >
        <FormLabel {...formLabelStyles.xs} htmlFor="fee">
          Fee
        </FormLabel>
        <Box>
          <BaselaneInput
            id="fee"
            name="fee"
            size="lg"
            fontSize="14px"
            placeholder={isFeeFlat ? '$' : ''}
            suffix={isFeeFlat ? '' : '%'}
            value={isFeeFlat ? feeAmount : feePercent}
            as={MaskedInput}
            mask={isFeeFlat ? currencyMask() : createNumberMask(percentageInputMaskOptions)}
            {...(!isFeeFlat && { pipe: preventGreaterThanMaxPercentage })}
            onChange={handleFeeInputChange}
            backgroundColor="brand.neutral.white"
            textAlign={!isFeeFlat ? 'right' : 'left'}
            inputGroupProps={{ backgroundColor: 'brand.neutral.white' }}
            inputMode="decimal"
          />
        </Box>
      </FormControl>
      <FormControl
        flex="1.3"
        isRequired
        {...fieldContainer}
        mt={isMax767 && '0px !important'}
        mb={isMax767 && '16px !important'}
      >
        <FormLabel {...formLabelStyles.xs} htmlFor="due-date">
          Applied
        </FormLabel>
        <Box height="100%">{isDueDateDisabled ? disabledDueDateInput : dueDateInput}</Box>
      </FormControl>
    </Flex>
  );
};

export default LateFeeInputPanel;
