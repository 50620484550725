import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Heading, Text, Skeleton, VStack, HStack, useToast } from '@chakra-ui/react';
import { Formik } from 'formik';
import { CREATE_AUTOTAG_RULE, GET_ACCOUNT_DROPDOWN_BANKS } from '@core/apollo/queries';
import { getConnectedAccountsFilterOptions } from '@core/contexts/TransactionContext/helpers';
import {
  BaselaneDrawer,
  BaselaneCardNew,
  BaselaneButton,
  T1WithTitleDropdown,
  DisplayInputShowingSelectedNumberAccount,
  renderAccountDropdownItem,
} from '@shared/components';
import { ADD_BASELANE_BANK_ACCOUNT } from '@routes';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { GET_PROPERTIES_PROMOTION } from '../../queries/property-queries';
import { GET_AUTOTAG_RULES } from '../../queries/autotag-queries';
import { getActionableProperties } from '../../helpers/autotaggingRules.helpers';

const AutoTaggingPropertyDrawerDrawer = () => {
  const { isMax768 } = useBreakPoints();

  const navigate = useNavigate();

  const formRef = useRef(null);

  // These are the properties that are not linked to a bank account
  const [actionableProperties, setActionableProperties] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [toastIsShowing, setToastIsShowing] = useState(false);
  const [saveQueue, setSaveQueue] = useState(null);
  const [successCount, setSuccessCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);

  const {
    data: propertiesData,
    loading: propertiesDataLoading,
    error: propertiesDataError,
  } = useQuery(GET_PROPERTIES_PROMOTION, {
    fetchPolicy: 'cache-first',
  });

  const {
    data: autoTagRulesData,
    loading: autoTagRulesDataLoading,
    error: autoTagRulesDataError,
  } = useQuery(GET_AUTOTAG_RULES, {
    variables: {
      filter: { isPropertyOrUnitRule: true },
    },
  });

  const { data: banksData, loading: banksDataLoading, error: banksDataError } = useQuery(
    GET_ACCOUNT_DROPDOWN_BANKS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        // only open accounts are shown
        accountStatus: 'Open',
        // exclude manual accounts
        isManualAccount: false,
      },
    }
  );

  // creating or updating a rule needs to also refetch transaction totals
  const [createAutoTagRule] = useMutation(CREATE_AUTOTAG_RULE, {
    refetchQueries: [GET_AUTOTAG_RULES],
  });

  // these are baselane and external accounts, but should not include manually added accounts
  const connectedAccountsFilterOptions = getConnectedAccountsFilterOptions(banksData);

  const toast = useToast();

  const showSomethingWentWrongErrorToast = (closeDrawerOnComplete) => {
    toast({
      description: 'Something went wrong, please try again.',
      status: 'error',
      position: 'bottom-left',
      onCloseComplete: closeDrawerOnComplete
        ? () => {
            setToastIsShowing(false);
            // close the drawer
            navigate('..');
          }
        : undefined,
    });
  };

  const showResultToast = (onCloseComplete) => {
    if (successCount > 0) {
      toast({
        description: `${successCount} rules created successfully`,
        status: 'success',
        position: 'bottom-left',
        onCloseComplete,
      });
    }
    if (errorCount > 0) {
      toast({
        description: `${errorCount} rules could not be created`,
        status: 'error',
        position: 'bottom-left',
        onCloseComplete,
      });
    }
  };

  useEffect(() => {
    if (autoTagRulesData) {
      setActionableProperties(getActionableProperties(propertiesData, autoTagRulesData));
    }
  }, [propertiesData, autoTagRulesData]);

  useEffect(() => {
    if (propertiesDataError || autoTagRulesDataError || banksDataError) {
      setToastIsShowing(true);
    }
  }, [propertiesDataError, autoTagRulesDataError, banksDataError]);

  useEffect(() => {
    if (toastIsShowing) {
      showSomethingWentWrongErrorToast(true);
    }
  }, [toastIsShowing]);

  const isEmptyObject = (object: Object) => Object.keys(object)?.length === 0;

  const handleSave = (values) => {
    const ruleQueue = [];
    Object.keys(values).forEach((propertyId) => {
      const selectedAccounts = values[propertyId];
      selectedAccounts.forEach((account) => {
        ruleQueue.push({
          propertyId,
          accountId: account.id,
        });
      });
    });
    setSaveQueue(ruleQueue);
    setIsSaving(true);
  };

  useEffect(() => {
    if (isSaving && saveQueue && saveQueue.length > 0) {
      const queue = [...saveQueue];
      const rule = queue.pop();
      createAutoTagRule({
        variables: {
          input: {
            action: {
              propertyId: Number(rule.propertyId),
              tagId: '',
              unitId: null,
            },
            applyToTaggedTransactions: false,
            conditions: [
              {
                field: 'BANK_ACCOUNT_ID',
                operator: 'EQUALS',
                type: 'NUMERIC',
                value: Number(rule.accountId),
              },
            ],
          },
        },
      })
        .then(() => {
          setSuccessCount((old) => old + 1);
        })
        .catch((error) => {
          setErrorCount((old) => old + 1);
        })
        .finally(() => {
          setSaveQueue(queue);
        });
    } else if (isSaving) {
      setIsSaving(false);
      setSaveQueue(null);
      formRef.current.resetForm();
      showResultToast(() => {
        setSuccessCount(0);
        setErrorCount(0);
        if (actionableProperties && actionableProperties.length === 0) {
          navigate('..');
        }
      });
    }
  }, [isSaving, saveQueue]);

  return (
    <Formik initialValues={{}} onSubmit={handleSave} innerRef={formRef}>
      {({
        handleSubmit,
        handleReset,
        values,
        errors,
        touched,
        dirty,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <BaselaneDrawer
          title="Connect accounts"
          size="sm"
          isOpen
          onClose={() => {
            if (!isSaving) {
              navigate('..');
            }
          }}
          newDesignDrawer
          footer={
            <HStack w="full" gap={2}>
              <BaselaneButton
                palette="neutral"
                size="md"
                w="full"
                flex={1}
                variant="outline"
                onClick={() => navigate('..')}
                isDisabled={isSaving}
              >
                Cancel
              </BaselaneButton>
              <BaselaneButton
                palette="primary"
                size="md"
                w="full"
                flex={5}
                variant="filled"
                isDisabled={!dirty || isEmptyObject(values) || isSaving}
                isLoading={isSaving}
                onClick={handleSubmit}
              >
                Save
              </BaselaneButton>
            </HStack>
          }
        >
          <VStack gap={2} w="full" alignItems="flex-start">
            <Heading size="headline-md">Your properties</Heading>
            <Text textStyle="sm">These properties are not linked to a banking account.</Text>
            <Skeleton
              display="flex"
              flexDirection="column"
              isLoaded={
                !propertiesDataLoading &&
                propertiesData &&
                !autoTagRulesDataLoading &&
                !!actionableProperties
              }
              w="full"
              minH={
                !propertiesDataLoading &&
                propertiesData &&
                !autoTagRulesDataLoading &&
                !!actionableProperties
                  ? null
                  : '200px'
              }
              gap={2}
            >
              {actionableProperties?.map((property) => (
                <BaselaneCardNew key={property.id} palette="dark" w="full">
                  <VStack gap={2} w="full" alignItems="flex-start">
                    <Text textStyle="headline-sm">{property.name}</Text>
                    <Skeleton isLoaded={!banksDataLoading && banksData} w="full">
                      <T1WithTitleDropdown
                        additionalProps={{ id: 'account-filter-dropdown' }}
                        classNames={['auto-width-dropdown', 'select-auto-width', 'input-form-md']}
                        data={connectedAccountsFilterOptions}
                        searchTerm={['account', 'bankName']}
                        title="Select account"
                        showValueByFields={['bankName', 'account']}
                        itemRenderer={renderAccountDropdownItem}
                        onClearClick={() => {
                          // first clear the array
                          setFieldValue(property.id, []).then(() => {
                            // then delete the key
                            setFieldValue(property.id, undefined);
                          });
                        }}
                        handleSubmit={(selectedAccounts) => {
                          setFieldValue(property.id, selectedAccounts);
                        }}
                        isMulti
                        selectedItem={values[property.id] || []}
                        showDivider
                        CustomDisplayInput={DisplayInputShowingSelectedNumberAccount}
                        hasFilterWrapper
                        isDisabled={isSaving}
                        isMobile={isMax768}
                      />
                    </Skeleton>
                  </VStack>
                </BaselaneCardNew>
              ))}
            </Skeleton>
            <BaselaneButton
              palette="neutral"
              size="md"
              w="full"
              variant="outline"
              isDisabled={isSaving}
              onClick={() => navigate(`../${ADD_BASELANE_BANK_ACCOUNT}`)}
            >
              Add new virtual account
            </BaselaneButton>
          </VStack>
        </BaselaneDrawer>
      )}
    </Formik>
  );
};

export default AutoTaggingPropertyDrawerDrawer;
