import React from 'react';
import { Box, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';
import QuickPayIconOrBadge from '@pages/LeasesPage/components/QuickPayIconOrBadge';
import { BaselaneMessageCard } from '@shared/components';

import useBreakPoints from '@core/hooks/useBreakPoints';
import { invoiceStyles } from '../../../styles/invoice.styles';

type InvoiceDetailsHeaderProps = {
  description: string,
  editState: boolean,
  isOverdue: boolean,
  lateFeeAmount: number,
  daysLate: number,
  invoiceNumber: number,
  leaseTenant: string,
  unitName: string,
  propertyAddress: Object,
  isPropertyDeleted: boolean,
  isMultiUnit: boolean,
  isQuickPay: boolean,
};

const InvoiceDetailsHeader = ({
  description,
  editState,
  isOverdue,
  lateFeeAmount,
  daysLate,
  invoiceNumber,
  leaseTenant,
  unitName,
  propertyAddress,
  isPropertyDeleted,
  isMultiUnit,
  isQuickPay,
}: InvoiceDetailsHeaderProps) => {
  const { isMax767 } = useBreakPoints();

  const tenantFullName = `${leaseTenant?.firstName} ${leaseTenant?.lastName}`;
  const hideForNow = true;

  const { header } = invoiceStyles({ isMax767 });

  return (
    <Stack {...header} justifyContent="space-between" borderBottom="none">
      <Stack {...(editState ? { ...{ p: 0 } } : header.unit)}>
        {!editState ? (
          <Box>
            <Stack {...header.address}>
              {isPropertyDeleted ? (
                <Text {...header.propertydeleted}>Property Deleted</Text>
              ) : (
                <Text>{`${propertyAddress?.address || ''} ${
                  isMultiUnit && propertyAddress?.address ? '·' : ''
                } ${isMultiUnit ? unitName : ''}`}</Text>
              )}
            </Stack>
            <HStack>
              <Text>{tenantFullName}</Text>
              {isQuickPay && <QuickPayIconOrBadge />}
            </HStack>
          </Box>
        ) : (
          <Flex justifyContent="space-between" w="100%">
            <Text {...header.edit}>{description}</Text>
            <Text {...header.invoice}>{`Invoice #${invoiceNumber}`}</Text>
          </Flex>
        )}
      </Stack>
      {isOverdue && lateFeeAmount && !hideForNow && (
        <Box paddingTop={editState ? '0px' : '24px'}>
          <BaselaneMessageCard
            iconName="exclamationtriangle"
            title="Late Fee Applied"
            text={`This invoice is overdue by over ${daysLate} days, a ${
              formatCurrency(lateFeeAmount).inDollars
            } late fee has been added.`}
            borderColor="warningyellow"
            iconColor="warningyellow"
            containerStyles={{ marginBottom: '0px', w: '100%' }}
            isVertical
          />
        </Box>
      )}
    </Stack>
  );
};
export default InvoiceDetailsHeader;
