import React, { useEffect } from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { FormControl, FormErrorMessage, FormLabel, Select } from '@chakra-ui/react';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import {
  getOnDateOptions,
  getDueDateOptions,
} from '@core/pages/LeaseAgreement/CreateLeaseAgreement/helpers/leaseAgreementForm';

type RentDueDateInputProps = {
  name: string,
  errors: Object,
  touched: Object,
  values: Object,
  formInitialValues: Object,
  handleChange: Function,
  handleBlur: Function,
  setFieldTouched: Function,
  setFieldValue: Function,
  setFormVariables: Function,
  isDisabled: boolean,
  noRCStartOptions: boolean,
  setRCStartOptions: Function,
  setNoRCStartOptions: Function,
};

function RentDueDateInput({
  name,

  setFormVariables,
  isDisabled,
  noRCStartOptions,
  setRCStartOptions,
  setNoRCStartOptions,
}: RentDueDateInputProps) {
  const formik = useFormikContext();
  const {
    setFieldValue,
    values,
    setFieldTouched,
    touched,
    errors,
    handleChange,
    handleBlur,
  } = formik;

  const { endDate, dueDays, isMonthToMonth, startDate } = values;

  // TO DO figure out on lod issue
  useEffect(() => {
    let dueDaysInNum = Number(dueDays);
    if (!dueDaysInNum) {
      dueDaysInNum = Number(moment(startDate).format('DD'));
    }

    const firstPossibleStartDate = startDate;
    const newRCStartOptions = getOnDateOptions(
      dueDaysInNum,
      moment(firstPossibleStartDate),
      endDate,
      false,
      isMonthToMonth
    );

    setRCStartOptions(newRCStartOptions);
    setNoRCStartOptions(!newRCStartOptions || newRCStartOptions.length === 0);

    setFormVariables({
      rentCollectionStartDate: newRCStartOptions[0]?.dateValue || values?.startDate,
    });
    setFieldValue('dueDays', dueDaysInNum);
    setFieldTouched('dueDays', true);
    setFieldValue('rentCollectionStartDate', newRCStartOptions[0]?.dateValue);
  }, [startDate, endDate, isMonthToMonth, dueDays]);

  return (
    <FormControl
      mb="0px"
      isInvalid={touched.endDate && touched.startDate && touched.dueDays && errors.dueDays}
    >
      <FormLabel {...formLabelStyles.xs} htmlFor={name}>
        Rent is due on
      </FormLabel>
      <Select
        {...formInputStyles}
        id={name}
        {...{ name }}
        value={dueDays || startDate} // Default to startDate if no value
        onChange={handleChange}
        onBlur={handleBlur}
        p="0"
        color={dueDays ? 'brand.neutral.700' : 'brand.neutral.500'}
        isDisabled={!isMonthToMonth ? !startDate : !startDate && !endDate}
      >
        {getDueDateOptions().map((o) => (
          <option key={o.id} value={o.id}>
            {o.label}
          </option>
        ))}
      </Select>

      <FormErrorMessage {...formErrorStyles}>{errors.dueDays}</FormErrorMessage>

      {noRCStartOptions && touched.endDate && touched.startDate && (
        <FormErrorMessage {...formErrorStyles}>
          No Rent Collection Start date within selected period and due day.
        </FormErrorMessage>
      )}
    </FormControl>
  );
}

export default RentDueDateInput;
