import React, { useCallback, useMemo, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  ChakraProvider,
  Box,
  Text,
  HStack,
  Stack,
  Heading,
  Checkbox,
  useToast,
  Skeleton,
} from '@chakra-ui/react';
import { useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import {
  BaselaneDrawer,
  BaselaneCardNew,
  BaselaneButton,
  BaselaneCardStack,
} from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import habitatTheme from '@core/themeHabitat';
import { LEASE_AGREEMENT } from '@core/constants/routes';
import { GET_LEASES } from '@pages/LeasesPage/queries';
import { GET_TENANT_ACTIVE_PROFILES } from '@pages/TenantsPage/queries';
import { useLeaseAgreement } from './hooks/useLeaseAgreement';
import { LINK_LEASE_AGREEMENT_TO_LEASE } from './queries';
import {
  formatLeaseAgreementDuration,
  getLeasesWithTenantInfo,
} from './helpers/leaseAgreement.helper';

const LeaseAgreementToLeaseDrawer = () => {
  const formikRef = useRef();
  const { isMax576 } = useBreakPoints();
  const navigate = useNavigate();
  const toast = useToast();
  const { leaseagreementId: leaseAgreementIdParam } = useParams();
  const { leaseAgreementId: leaseAgreementIdAttr } = useLocation().state || {};
  const leaseAgreementId = leaseAgreementIdAttr || leaseAgreementIdParam;

  if (!leaseAgreementId) {
    return null;
  }
  const { leaseAgreement: leaseAgreementData } = useLeaseAgreement(leaseAgreementId);
  const { leases: existingLinkedLeases = [] } = leaseAgreementData || {};

  const { data: leasesData, loading: leasesDataLoading } = useQuery(GET_LEASES, {
    variables: {
      input: {
        state: ['SCHEDULED', 'EXPIRED'],
        ...(leaseAgreementData?.tenantProfileId && {
          tenantProfileId: leaseAgreementData.tenantProfileId,
        }),
      },
    },
  });

  const { data: tenantProfilesData, loading: tenantProfileDataLoading } = useQuery(
    GET_TENANT_ACTIVE_PROFILES
  );

  const tenantProfiles = tenantProfilesData?.landLordTenantSummary?.activeTenants;

  const leases = useMemo(() => {
    return getLeasesWithTenantInfo(
      leasesData,
      tenantProfiles,
      leaseAgreementId,
      existingLinkedLeases
    );
  }, [leasesData?.leases?.leases, tenantProfiles, leaseAgreementId, existingLinkedLeases]);

  const [linkLeaseAgreementToLease] = useMutation(LINK_LEASE_AGREEMENT_TO_LEASE);

  const handleCloseDrawer = useCallback(() => {
    navigate(`/${LEASE_AGREEMENT}/${leaseAgreementId}`);
  }, [navigate, leaseAgreementId]);

  return (
    <ChakraProvider theme={habitatTheme}>
      <Formik
        innerRef={(el) => {
          formikRef.current = el;
        }}
        initialValues={{
          selectedLeases: existingLinkedLeases
            ? existingLinkedLeases.map((lease) => ({
                leaseId: lease.id,
                leaseAgreementId,
              }))
            : [],
        }}
        enableReinitialize
        onSubmit={async (values) => {
          const { selectedLeases } = values;

          try {
            const newlySelected = selectedLeases?.filter(
              (selected) => !existingLinkedLeases?.some((item) => item.id === selected.leaseId)
            );
            const unselected = existingLinkedLeases?.filter(
              (item) => !selectedLeases?.some((selected) => selected.leaseId === item.id)
            );

            const result = await linkLeaseAgreementToLease({
              variables: {
                input: {
                  link: newlySelected?.map((lease) => ({
                    leaseAgreementId,
                    leaseId: lease.leaseId,
                  })),
                  unLink: unselected?.map((lease) => ({
                    leaseAgreementId,
                    leaseId: lease.id,
                  })),
                },
              },
              refetchQueries: ['GET_LEASE_AGREEMENT', 'GET_LEASES'],
            });

            if (result?.data?.linkLeaseAgreementToLease) {
              toast({
                position: 'bottom-left',
                description: 'Lease agreement linked successfully!',
                status: 'success',
                duration: 3000,
                isClosable: true,
              });
              navigate(`/${LEASE_AGREEMENT}/${leaseAgreementId}`);
            } else {
              toast({
                position: 'bottom-left',
                description: 'Error linking lease agreement.',
                status: 'error',
                duration: 3000,
                isClosable: true,
              });
            }
          } catch (error) {
            console.error('Error linking lease agreement:', error);
          }
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <BaselaneDrawer
            isOpen
            size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
            title="Lease agreement details"
            closeEvent={handleCloseDrawer}
            closeOnOverlayClick
            newDesignDrawer
            footer={
              <Stack direction="row" gap={2} w="100%">
                <BaselaneButton
                  variant="outline"
                  palette="primary"
                  size="md"
                  onClick={handleCloseDrawer}
                >
                  Cancel
                </BaselaneButton>
                <BaselaneButton
                  variant="filled"
                  palette="primary"
                  size="md"
                  isFullWidth
                  onClick={() => {
                    if (formikRef.current) {
                      formikRef.current.handleSubmit();
                    }
                  }}
                >
                  Save
                </BaselaneButton>
              </Stack>
            }
          >
            <Heading size="headline-md">Attach to rent collection</Heading>

            {leasesDataLoading && tenantProfileDataLoading ? (
              <Skeleton height="20px" width="300px" mb={1.5} />
            ) : (
              <Text textStyle="sm" mb={1.5}>
                {leases?.length} rent collections active on this property
              </Text>
            )}

            {leasesDataLoading && tenantProfileDataLoading ? (
              <Skeleton height="20px" width="300px" mb={1.5} />
            ) : (
              <BaselaneCardStack
                id="lease-agreement-to-lease-drawer-cardstack"
                items={leases.map((lease) => ({
                  id: `lease-${lease.tenantProfileId}`,
                  content: (
                    <BaselaneCardNew variant="innerClickable" size="md">
                      <HStack gap={1} alignItems="flex-start" m={0}>
                        <Box height="100%" mt="2px">
                          <Checkbox
                            name="selectedLeases"
                            value={{ leaseId: lease.leaseId, leaseAgreementId }}
                            isChecked={values?.selectedLeases?.some(
                              (item) => item.leaseId === lease.leaseId
                            )}
                            onChange={(e) => {
                              const { checked } = e.target;
                              setFieldValue(
                                'selectedLeases',
                                checked
                                  ? [
                                      ...(values.selectedLeases || []),
                                      { leaseId: lease.leaseId, leaseAgreementId },
                                    ]
                                  : values?.selectedLeases?.filter(
                                      (item) => item.leaseId !== lease.leaseId
                                    )
                              );
                            }}
                          />
                        </Box>
                        <Stack gap={0} m={0}>
                          {leasesDataLoading ? (
                            <Skeleton />
                          ) : (
                            <Heading size="headline-md">
                              {`${lease.tenantFirstName} ${lease.tenantLastName}`}
                            </Heading>
                          )}
                          {leasesDataLoading ? (
                            <Skeleton />
                          ) : (
                            <Text textStyle="sm">
                              {formatLeaseAgreementDuration(
                                lease.startDate,
                                lease.endDate,
                                lease.leaseType
                              )}
                            </Text>
                          )}
                        </Stack>
                      </HStack>
                    </BaselaneCardNew>
                  ),
                }))}
              />
            )}
          </BaselaneDrawer>
        )}
      </Formik>
    </ChakraProvider>
  );
};

export default LeaseAgreementToLeaseDrawer;
