import { isMobile } from 'react-device-detect';

export const tabListMenu = (menuOptions) => (isMobile ? menuOptions.slice(0, -1) : menuOptions);

const isReadyForReview = (unitState) => unitState === 'READY_TO_BE_REVIEWED';

export const isOptionDisabled = ({ option, index, unitState, menuOptions }) => {
  return (
    (!isReadyForReview(unitState) &&
      (option?.name === 'REVIEW_LEASE' || option?.name === 'REVIEW_LEASE')) ||
    (index > 0 && !menuOptions[index - 1]?.isValid && !menuOptions[index]?.isValid)
  );
};
