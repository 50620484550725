import React from 'react';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import { Box, Flex, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { IconExclamationCircle, IconStatusOverdue, IconCheckCircleOutline } from '@icons';
import { AlertHeader, BaselaneButton, BaselaneSingleDatePicker } from '@shared/components';
import stripCurrency from '@core/utils/stripCurrency';
import formatCurrency from '@core/utils/formatCurrency';
import { formatDate } from '@core/utils/formatDate';
import useBreakPoints from '@core/hooks/useBreakPoints';

import { textStyles, textStyles2, textStyles3, textStyles4 } from '../styles/invoiceDetails.style';

const iconExclamationCircle = <IconExclamationCircle />;

export const getInitialFeesData = (items) =>
  items?.map((item) => ({
    ...item,
    amount: formatCurrency(item.amount).inDollars,
    edit: false,
    newFee: false,
  }));

export const initialValues = (detailsData) => ({
  dueDate: detailsData?.dueDate,
  fees: getInitialFeesData(detailsData?.items),
});

export const initialValuesAddInvoice = (bank) => ({
  dueDate: DateTime.fromJSDate(new Date()).toJSDate(),
  destinationBankAcctId: bank,
  fees: [
    {
      description: '',
      amount: undefined,
      newFee: true,
      edit: true,
      paymentType: 'ONE_TIME_FEE',
    },
  ],
});

const hasDueDateChanged = (detailsData) => {
  return detailsData?.formattedDueDate !== detailsData?.formattedOriginalDueDate;
};

// Render invoice detail view helper functions -----------------------------------------------------
export const getGeneralInfoList = (
  invoiceDetails,
  editState,
  totalAmount,
  dirty,
  setIsDirty,
  values,
  formikRef,
  paymentMethod
) => {
  const { isMin768 } = useBreakPoints();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // InfoList for editState returns a format for BaselaneList, non-editState doesn't
  const infoList = editState
    ? [
        { label: 'Invoice Status', element: invoiceDetails?.stateElement },
        {
          label: 'Amount',
          element: <Text {...textStyles3}>{totalAmount}</Text>,
        },
        {
          label: 'Due Date',
          element: (
            <BaselaneSingleDatePicker
              {...{
                id: 'dueDate',
                name: 'dueDate',
                minDate: DateTime.fromJSDate(new Date()).toJSDate(),
                portalId: 'invoice-body',
                date: values.dueDate,
                value: values.dueDate ? `${formatDate(values.dueDate, 'MMM DD, YYYY')}` : '',
                dateFormat: 'MMM dd, yyyy',
                handleCalendarClose: ({ date: dueDate, isFromApplyButton }) => {
                  if (isMin768 || (!isMin768 && isFromApplyButton)) {
                    formikRef.current?.setFieldValue('dueDate', dueDate);
                    setIsDirty(dirty);
                  }
                },
                size: 'sm',
                years: { start: new Date().getFullYear(), end: 2043 },
                isOpen,
                onOpen,
                onClose,
                hideInputIcon: false,
                fixedHeight: true,
                placement: 'bottom-end',
                showInModal: !isMin768,
              }}
            />
          ),
        },
        {
          label: 'Original Due Date',
          element: (
            <Stack direction="row">
              {invoiceDetails?.latePayment && <IconStatusOverdue />}
              <Box as="span" {...(invoiceDetails?.latePayment && { ml: '6px' })}>
                {invoiceDetails?.formattedOriginalDueDate}
              </Box>
            </Stack>
          ),
          hide: !hasDueDateChanged(invoiceDetails),
        },
        {
          label: 'Invoice Number',
          element: invoiceDetails?.invoiceNumber
            ? `${invoiceDetails?.id}-${invoiceDetails?.invoiceNumber}`
            : `${invoiceDetails?.id}`,
          hide: editState,
        },
      ]
    : [
        {
          id: 0,
          element: (
            <Stack textAlign="left" gap="0" fontSize="12px" lineHeight="16px" key="0">
              <Text>{invoiceDetails?.description}</Text>
              <Text fontSize="24px" lineHeight="32px" fontWeight="500">
                {totalAmount}
              </Text>
              <Stack direction="row">
                <Box as="span" {...(invoiceDetails?.latePayment && { ml: '6px' })}>
                  Due {invoiceDetails?.formattedDueDate}
                </Box>
                {invoiceDetails?.latePayment && <Text>· </Text>}
                {invoiceDetails?.latePayment && <Text {...textStyles4}>Paid late</Text>}
              </Stack>
            </Stack>
          ),
        },
        {
          id: 1,
          element: (
            <Stack textAlign="right" gap="0" key="1">
              <Flex justifyContent="right">{invoiceDetails?.stateElement}</Flex>
              <Text>
                Invoice #
                {invoiceDetails?.invoiceNumber
                  ? `${invoiceDetails?.id}-${invoiceDetails?.invoiceNumber}`
                  : `${invoiceDetails?.id}`}
              </Text>
            </Stack>
          ),
        },
      ];

  if (paymentMethod) {
    let method = '';
    if (paymentMethod === 'CREDIT_CARD') {
      method = 'Card';
    } else if (paymentMethod === 'DEBIT_CARD') {
      method = 'Debit';
    } else if (paymentMethod === 'BANK_ACCOUNT') {
      method = 'ACH';
    }

    infoList.push({
      label: 'Payment Method',
      element: method,
    });
  }

  return infoList;
};

export const getInvoiceDetailsList = (items) =>
  items?.map((item) => ({
    label: item.description,
    element: (
      <Text fontWeight="normal" lineHeight="20px">
        {formatCurrency(item.amount).inDollars}
      </Text>
    ),
  }));

export const getInvoiceChargeList = (
  totalAmount,
  isFeePaidByLandlord,
  applicationFeeChargeToLandlord,
  applicationFeeWaivedChargedReason
) => {
  const chargeList = [
    {
      label: 'Invoice total',
      element: (
        <Text fontWeight="normal" lineHeight="20px">
          {totalAmount}
        </Text>
      ),
    },
  ];

  if (isFeePaidByLandlord) {
    if (
      applicationFeeChargeToLandlord === 0 &&
      (applicationFeeWaivedChargedReason === 'ACH_PROMOTION' ||
        applicationFeeWaivedChargedReason === 'BASELANE_ACCOUNT')
    ) {
      chargeList.push({
        label: 'Tenant ACH payment fee',
        element: (
          <Text fontWeight="normal" lineHeight="20px">
            <Text as="s" color="brand.neutral.600">
              -$2.00
            </Text>{' '}
            {formatCurrency(applicationFeeChargeToLandlord).inDollars}
          </Text>
        ),
      });
    }

    if (applicationFeeChargeToLandlord) {
      chargeList.push({
        label: 'Tenant ACH payment fee',
        element: (
          <Text fontWeight="normal" lineHeight="20px">
            -{formatCurrency(applicationFeeChargeToLandlord).inDollars}
          </Text>
        ),
      });
    }
  }

  return chargeList;
};

export const listItemRenderer = (item) => {
  const { label, element, hide } = item;

  return hide ? null : (
    <Stack
      direction="row"
      key={label}
      w="100%"
      h="32px"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box {...textStyles}>{label}</Box>
      <Box {...textStyles2}>{element}</Box>
    </Stack>
  );
};
//--------------------------------------------------------------------------------------------------

// Alert Helper Functions --------------------------------------------------------------------------
const alertFooterHelper = (
  ref,
  onCloseAlert,
  handleActionButton,
  actionButtonText,
  actionButtonType,
  leftIcon
) => (
  <Stack direction="row">
    <BaselaneButton variant="outline" palette="neutral" size="md" ref={ref} onClick={onCloseAlert}>
      {actionButtonText === 'Exit Without Saving' ? 'Continue Editing' : 'Cancel'}
    </BaselaneButton>
    <BaselaneButton
      variant="filled"
      palette={actionButtonText !== 'Delete Invoice' ? 'primary' : 'danger'}
      size="md"
      type={actionButtonType}
      onClick={handleActionButton}
      leftIcon={leftIcon}
    >
      {actionButtonText}
    </BaselaneButton>
  </Stack>
);

// Alert variable containing information for invoice detail cancel and save
const alertIcon = (alertType) => (alertType === 'modifyAutoPay' ? iconExclamationCircle : null);

const saveAlertBodyText = (alertType) =>
  alertType === 'modifyAutoPay'
    ? 'Your tenant has already scheduled payment for this invoice. Making changes will result in that scheduled payment being canceled. Your tenant will receive notification of the updated invoice and will need to reschedule payment.'
    : 'Your tenant will receive notification of the updated invoice with the changes you made.';

const saveAlertHeader = (alertType) => (
  <AlertHeader title="Are you sure you want to modify this invoice?" icon={alertIcon(alertType)} />
);

const saveAlertFooter = (alertType, handleSave, cancelSaveAlertRef, onSaveAlertClose) => {
  const type = alertType === 'modifyAutoPay' ? 'tertiary' : 'primary';
  return alertFooterHelper(
    cancelSaveAlertRef,
    onSaveAlertClose,
    handleSave,
    'Modify Invoice',
    type
  );
};

export const saveAlertItems = (alertType, handleSave, cancelSaveAlertRef, onSaveAlertClose) => {
  return {
    saveAlertHeader: saveAlertHeader(alertType),
    saveAlertFooter: saveAlertFooter(alertType, handleSave, cancelSaveAlertRef, onSaveAlertClose),
    saveAlertBodyText: saveAlertBodyText(alertType),
  };
};

export const unsavedDetailsAlertFooter = (cancelAlertRef, onAlertClose, handleCancelCloseButton) =>
  alertFooterHelper(
    cancelAlertRef,
    onAlertClose,
    handleCancelCloseButton,
    'Exit Without Saving',
    'primary'
  );

// Delete Alert Items
const deleteAlertHeader = (
  <AlertHeader
    title="Are you sure you want to delete this invoice?"
    icon={iconExclamationCircle}
    iconBgStyles={{ bg: 'red.200' }}
    iconContainerStyles={{ color: 'red.800AA' }}
  />
);

const deleteAlertFooter = (cancelDeleteAlertRef, onDeleteAlertClose, handleDeleteInvoice) =>
  alertFooterHelper(
    cancelDeleteAlertRef,
    onDeleteAlertClose,
    handleDeleteInvoice,
    'Delete Invoice'
  );

const deleteAlertBodyText = (alertType) =>
  alertType === 'modifyAutoPay'
    ? 'Your tenant has already scheduled payment for this invoice. Deleting this invoice will result in that scheduled payment being canceled. Your tenant will receive notification that this invoice has been deleted. This will not affect the other invoices in this lease. This action can not be undone.'
    : 'Your tenant will receive notification that this invoice has been deleted. This will not affect the other invoices in this lease. This action can not be undone.';

export const deleteAlertItems = (
  alertType,
  cancelDeleteAlertRef,
  onDeleteAlertClose,
  handleDeleteInvoice
) => {
  return {
    deleteAlertHeader,
    deleteAlertFooter: deleteAlertFooter(
      cancelDeleteAlertRef,
      onDeleteAlertClose,
      handleDeleteInvoice
    ),
    deleteAlertBodyText: deleteAlertBodyText(alertType),
  };
};

// Mark As Paid Alert Items
const markAsPaidAlertHeader = (
  <AlertHeader
    title="Are you sure you want to mark invoice as paid?"
    icon={iconExclamationCircle}
    iconBgStyles={{ bg: 'brand.blue.100' }}
    iconContainerStyles={{ color: 'brand.blue.700' }}
  />
);

const markAsPaidFooter = (
  cancelMarkAsPaidAlertRef,
  onMarkAsPaidAlertClose,
  handleMarkAsPaidInvoice
) =>
  alertFooterHelper(
    cancelMarkAsPaidAlertRef,
    onMarkAsPaidAlertClose,
    handleMarkAsPaidInvoice,
    'Mark as Paid Invoice',
    'primary',
    <IconCheckCircleOutline w="13" h="13" />
  );

const markAsPaidAlertBodyText =
  "By marking this invoice as paid, you confirm that you have received this payment outside of Baselane' s rent collection. Your tenant will receive a payment confirmation and can no longer pay this invoice via Baselane.";

export const markAsPaidAlertItems = (
  alertType,
  cancelMarkAsPaidAlertRef,
  onMarkAsPaidAlertClose,
  handleMarkAsPaidAlertInvoice
) => {
  return {
    markAsPaidAlertHeader,
    markAsPaidAlertFooter: markAsPaidFooter(
      cancelMarkAsPaidAlertRef,
      onMarkAsPaidAlertClose,
      handleMarkAsPaidAlertInvoice
    ),
    markAsPaidAlertBodyText,
  };
};
// -------------------------------------------------------------------------------------------------

export const formatValuesForInvoicesAPI = (id, values, leaseId) => {
  const { dueDate: invoiceDueDate, fees, destinationBankAcctId } = cloneDeep(values);
  const utcInvoiceDueDate = DateTime.fromJSDate(invoiceDueDate).toISODate();

  const feeItems = fees.map((fee) => {
    // eslint-disable-next-line no-param-reassign
    delete fee.edit;
    // eslint-disable-next-line no-param-reassign
    delete fee.newFee;
    // eslint-disable-next-line no-param-reassign,no-underscore-dangle
    delete fee.__typename;
    // eslint-disable-next-line no-param-reassign,no-underscore-dangle
    delete fee.leaseId;
    // eslint-disable-next-line no-param-reassign,no-underscore-dangle
    delete fee.dueDate;
    if (leaseId && fee.itemName) {
      // eslint-disable-next-line no-param-reassign,no-underscore-dangle
      delete fee.itemName;
    }
    if (leaseId && fee.paymentName) {
      // eslint-disable-next-line no-param-reassign,no-underscore-dangle
      delete fee.paymentName;
    }

    const cleanAmount = stripCurrency(fee.amount);
    return { ...fee, amount: cleanAmount };
  });

  const newInvoice = {
    leaseId,
    destinationBankAcctId,
    dueDate: utcInvoiceDueDate,
    items: feeItems,
  };

  return leaseId
    ? newInvoice
    : {
        id,
        dueDate: utcInvoiceDueDate,
        items: feeItems,
      };
};

const addFees = (fees) =>
  fees.reduce((accFeesAmount, fee) => {
    const { amount: feeAmount } = fee;
    const cleanAmount = stripCurrency(feeAmount);
    return accFeesAmount + cleanAmount;
  }, 0);

const getCalculatedFees = (fees) => {
  const calculateFees = addFees(fees);
  return formatCurrency(calculateFees).inDollars;
};

export const getTotalAmount = (fees, editState, amount) => {
  const total = getCalculatedFees(fees);
  return editState ? total : amount;
};
