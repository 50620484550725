import { create } from 'zustand';

import { initialCSVImportValues } from '@pages/CSVImport/helpers';

const useCSVImportStore = create((set, get) => ({
  // states
  account: initialCSVImportValues.account,
  CSVFile: initialCSVImportValues.CSVFile,
  CSVFileData: initialCSVImportValues.CSVFileData,
  CSVHeaderMapping: initialCSVImportValues.CSVHeaderMapping,
  mappedCategories: initialCSVImportValues.mappedCategories,
  mappedProperties: initialCSVImportValues.mappedProperties,
  propertyListFromCSV: initialCSVImportValues.propertyListFromCSV,
  categoryListFromCSV: initialCSVImportValues.categoryListFromCSV,

  isStep1Dirty: false,
  isStep2Dirty: false,
  isStep3Dirty: false,
  isStep4Dirty: false,

  // setters
  setIsDirty: (valueObj) => set(() => valueObj),
  setUploadCSVValues: (newValues) =>
    set(() => ({
      account: newValues.account,
      CSVFile: newValues.CSVFile,
    })),
  setReviewColumnValues: (mapping, fileData) =>
    set(() => ({
      CSVHeaderMapping: mapping,
      CSVFileData: fileData,
    })),
  setMappedCategoryValues: (newValues) =>
    set(() => ({
      mappedCategories: newValues,
    })),
  setMappedPropertyValues: (newValues) =>
    set(() => ({
      mappedProperties: newValues,
    })),
  setPropertyListFromCSV: (newValues) =>
    set(() => ({
      propertyListFromCSV: newValues,
    })),
  setCategoryListFromCSV: (newValues) =>
    set(() => ({
      categoryListFromCSV: newValues,
    })),
  setToInitialValues: () =>
    set(() => ({
      account: initialCSVImportValues.account,
      CSVFile: initialCSVImportValues.CSVFile,
      CSVFileData: initialCSVImportValues.CSVFileData,
      CSVHeaderMapping: initialCSVImportValues.CSVHeaderMapping,
      mappedCategories: initialCSVImportValues.mappedCategories,
      mappedProperties: initialCSVImportValues.mappedProperties,
      propertyListFromCSV: initialCSVImportValues.propertyListFromCSV,
      categoryListFromCSV: initialCSVImportValues.categoryListFromCSV,
      isStep1Dirty: false,
      isStep2Dirty: false,
      isStep3Dirty: false,
      isStep4Dirty: false,
    })),

  // actions
  onDeleteOfCSVFile: () =>
    set(() => ({
      // reset all state except for account field
      CSVFile: initialCSVImportValues.CSVFile,
      CSVFileData: initialCSVImportValues.CSVFileData,
      CSVHeaderMapping: initialCSVImportValues.CSVHeaderMapping,
      mappedCategories: initialCSVImportValues.mappedCategories,
      mappedProperties: initialCSVImportValues.mappedProperties,
      propertyListFromCSV: initialCSVImportValues.propertyListFromCSV,
      categoryListFromCSV: initialCSVImportValues.categoryListFromCSV,
      isStep2Dirty: false,
      isStep3Dirty: false,
      isStep4Dirty: false,
    })),

  // getters
  getIsFlowDirty: () => {
    const { isStep1Dirty, isStep2Dirty, isStep3Dirty, isStep4Dirty } = get();
    return isStep1Dirty || isStep2Dirty || isStep3Dirty || isStep4Dirty;
  },
}));

export default useCSVImportStore;
