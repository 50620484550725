import moment from 'moment';
import { DateTime } from 'luxon';

/**
 * DO NOT USE THIS, IT IS DEPRECATED
 * Standardized Moment date formatter
 * @param {Date} date JS Date Object
 * @param {String} format Moment format string, defaults to 'MMM dd, yyyy'
 * @returns Moment formatted date string
 */
export const formatDate = (date, format = 'MMM D, YYYY') =>
  moment(date || ''.toString()).format(format);

/**
 * Standardized Luxon date formatter
 * @param {Date} date JS Date Object
 * @param {String} format Luxon format string, defaults to 'MMM dd, yyyy'
 * @returns Luxon formatted date string
 */
export const dateFormatter = (date, format = 'MMM dd, yyyy') => {
  return DateTime.fromISO(date).toFormat(format);
};

export const getOrdinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

/**
 * Converts a db stored GMT date into a JS Date Object
 * @param {*} date date string with no time, in GMT formatted as 'yyyy-mm-dd'
 * @returns JS Date Object based on input
 */
export const fromStoredGMTDate = (date) => {
  return new Date(`${date}T00:00:00`);
};
