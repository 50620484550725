import React, { useEffect, useState, useMemo } from 'react';
import { useCashFlow, useCashFlowProperties, useTags } from '@shared/hooks';
import { defaultFilters, defaultTaxPackageFilters } from '@shared/helpers/cashFlow.helpers';

const CashFlowContext = React.createContext({});

export const CashFlowProvider = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState(defaultFilters);
  const [typeFilters, setTypeFilters] = useState(defaultFilters);
  const [dataType, setDataType] = useState('month');

  const [taxPackageFilters, setTaxPackageFilters] = useState(defaultTaxPackageFilters);

  const [properties, setProperties] = useState([]);
  /* eslint no-underscore-dangle: 0 */
  const propertiesTags = properties
    ?.filter((item) => item.__typename === 'Property')
    ?.map((item) => item.id);
  const unitsTags = properties
    ?.filter((item) => item.__typename === 'PropertyUnit')
    ?.map((item) => item.unitId);
  const [totalPropertiesCount, setTotalPropertiesCount] = useState({ properties: 0, units: 0 });
  const [isConnectBALoading, setIsConnectBALoading] = useState(false);

  /** These values are used to persist input selections across route changes */
  const [persistentTimePeriodDate, setPersistentTimePeriodDate] = useState();
  const [persistentCategoryOptions, setPersistentCategoryOptions] = useState();
  const [persistentHasActiveParentFilter, setPersistentHasActiveParentFilter] = useState();
  const [persistentCategoryPreset, setPersistentCategoryPreset] = useState();
  const [persistentPropertyData, setPersistentPropertyData] = useState();
  const [persistentLastCategoryPreset, setPersistentLastCategoryPreset] = useState();

  /** Collection of setters to nullify when purging persistent data */
  const persistentSetters = [
    setPersistentTimePeriodDate,
    setPersistentCategoryOptions,
    setPersistentHasActiveParentFilter,
    setPersistentCategoryPreset,
    setPersistentPropertyData,
    setPersistentLastCategoryPreset,
  ];

  /** Resets filters and persistent data (this will cause a re-render) */
  const clearPersistentData = () => {
    persistentSetters.forEach((setter) => setter(null));
    setFilters(defaultFilters);
  };

  useEffect(() => {
    if (filters) {
      const { propertyId, unitId } = filters.filter;

      let updatedPropertyId = propertyId || null;
      let updatedUnitId = unitId || null;
      if (dataType === 'property') {
        updatedPropertyId = propertiesTags;
      }
      if (dataType === 'unit') {
        updatedPropertyId = propertiesTags;
        updatedUnitId = unitsTags;
      }
      const updatedFilters = {
        filter: {
          ...filters.filter,
          propertyId: updatedPropertyId,
          unitId: updatedUnitId,
        },
      };
      setTypeFilters(updatedFilters);
    }
  }, [dataType]);

  const {
    cashFlowLoading,
    cashFlowError,
    cashFlowData,
    cashFlowDummyData,
    cashFlowSummaryDummyData,
    cashFlowRefetch,
  } = useCashFlow({
    input: {
      filter: {
        ...filters.filter,
        ...typeFilters.filter,
      },
    },
    dataType,
  });

  const {
    cashFlowPropertiesLoading,
    cashFlowPropertiesError,
    cashFlowPropertiesData,
    cashFlowPropertiesRefetch,
  } = useCashFlowProperties();

  const {
    categoryMap,
    categoryOptions,
    presetOptions,
    tagsLoading,
    categoryWithSubOptions,
    categoryIdsMap,
  } = useTags();

  const state = useMemo(() => {
    const newState = {};

    newState.isLoading = isLoading;
    newState.setIsLoading = setIsLoading;
    newState.filters = filters;
    newState.setFilters = setFilters;
    newState.dataType = dataType;
    newState.setDataType = setDataType;
    newState.defaultFilters = defaultFilters;
    newState.taxPackageFilters = taxPackageFilters;
    newState.setTaxPackageFilters = setTaxPackageFilters;
    newState.defaultTaxPackageFilters = defaultTaxPackageFilters;
    newState.properties = properties;
    newState.setProperties = setProperties;
    newState.totalPropertiesCount = totalPropertiesCount;
    newState.setTotalPropertiesCount = setTotalPropertiesCount;
    newState.isConnectBALoading = isConnectBALoading;
    newState.setIsConnectBALoading = setIsConnectBALoading;
    newState.propertiesTags = propertiesTags;
    newState.unitsTags = unitsTags;

    newState.persist = {
      timePeriodDate: persistentTimePeriodDate,
      setTimePeriodDate: setPersistentTimePeriodDate,
      categoryOptions: persistentCategoryOptions,
      setCategoryOptions: setPersistentCategoryOptions,
      hasActiveParentFilter: persistentHasActiveParentFilter,
      setHasActiveParentFilter: setPersistentHasActiveParentFilter,
      categoryPreset: persistentCategoryPreset,
      setCategoryPreset: setPersistentCategoryPreset,
      propertyData: persistentPropertyData,
      setPropertyData: setPersistentPropertyData,
      lastCategoryPreset: persistentLastCategoryPreset,
      setLastCategoryPreset: setPersistentLastCategoryPreset,
      clearPersistentData,
    };

    newState.cashFlowLoading = cashFlowLoading;
    newState.cashFlowError = cashFlowError;
    newState.cashFlowData = cashFlowData;
    newState.cashFlowDummyData = cashFlowDummyData;
    newState.cashFlowSummaryDummyData = cashFlowSummaryDummyData;
    newState.cashFlowRefetch = cashFlowRefetch;

    newState.cashFlowPropertiesLoading = cashFlowPropertiesLoading;
    newState.cashFlowPropertiesError = cashFlowPropertiesError;
    newState.cashFlowPropertiesData = cashFlowPropertiesData;
    newState.cashFlowPropertiesRefetch = cashFlowPropertiesRefetch;

    newState.categoryMap = categoryMap;
    newState.categoryOptions = categoryOptions;
    newState.presetOptions = presetOptions;
    newState.tagsLoading = tagsLoading;
    newState.categoryWithSubOptions = categoryWithSubOptions;
    newState.categoryIdsMap = categoryIdsMap;

    return newState;
  }, [
    isLoading,
    filters,
    defaultFilters,
    taxPackageFilters,
    defaultTaxPackageFilters,
    properties,
    totalPropertiesCount,
    isConnectBALoading,
    cashFlowLoading,
    cashFlowError,
    cashFlowData,
    cashFlowDummyData,
    cashFlowSummaryDummyData,
    cashFlowPropertiesLoading,
    cashFlowPropertiesError,
    cashFlowPropertiesData,
  ]);

  return <CashFlowContext.Provider value={state}>{children}</CashFlowContext.Provider>;
};
export const CashFlowConsumer = CashFlowContext.Consumer;
export default CashFlowContext;
