import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { GET_TENANT_SCREENING_BY_ID } from '@core/apollo/queries';
import {
  TENANT_SCREENING,
  TENANT_SCREENING_APPLICATION,
  TENANT_SCREENING_REPORTS_SUMMARY,
} from '@core/constants/routes';

const useApplicants = () => {
  const { screeningId, applicationId } = useParams() ?? {};

  const validScreeningId = screeningId ? Number(screeningId) : null;
  const { data: screeningData, loading: screeningDataLoading } = useQuery(
    GET_TENANT_SCREENING_BY_ID,
    {
      fetchPolicy: 'cache-first',
      variables: { id: validScreeningId },
      skip: !validScreeningId,
    }
  );

  const { applications = [], propertyName = '', unitName = '', selectedReports = [] } =
    screeningData?.getTenantScreeningById ?? {};

  const application = useMemo(() => applications.find((app) => app.id === applicationId) ?? {}, [
    applications,
    applicationId,
  ]);

  const applicants = application?.applicants ?? [];

  const applicantOptions = applicants.map((applicantItem) => ({
    id: applicantItem?.id ?? '',
    applicationId: applicantItem?.applicationId ?? '',
    externalId: applicantItem?.externalApplicantId ?? '',
    name: applicantItem?.applicantName ?? '',
    type: applicantItem?.applicantRole
      ? `${applicantItem?.applicantRole[0].toUpperCase()}${applicantItem?.applicantRole.slice(1)}`
      : '',
    submissionDate: applicantItem?.submissionDate,
  }));

  const applicantOptionsWithSummary = useMemo(
    () => [
      {
        id: 'summary',
        externalId: '0',
        name: 'Summary',
        type: `${applicants.length} applicants`,
        route: `/${TENANT_SCREENING}/${screeningId}/${TENANT_SCREENING_APPLICATION}/${applicationId}/${TENANT_SCREENING_REPORTS_SUMMARY}`,
        value: 0,
      },
      ...applicantOptions,
    ],
    [screeningId, applicationId, applicants, applicantOptions]
  );

  return {
    screeningData,
    screeningDataLoading,
    applications,
    propertyName,
    unitName,
    selectedReports,
    application,
    applicants,
    numberOfApplicants: applicants.length,
    applicantOptions,
    applicantOptionsWithSummary,
  };
};

export default useApplicants;
