// referenced in multiple places

export const formStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  mt: '0px !important',
};

export const linkStyles = {
  color: 'brand.primary.500 !important',
  _hover: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};

export const inputStack = {
  alignItems: 'center',
  h: '100%',
  w: { base: '100%' },
  mt: '0px !important',
};

// referenced only in SignUp component

export const signupPageStyles = () => ({
  direction: 'column',
  justifyContent: 'space-between',
  minHeight: '100%',
  bg: 'marketing.midnight.100',
});

export const signupContainerStyles = {
  minWidth: '200px',
};

const largestPadding = '108px';
const maxWidth = '1242px';
export const signupColumnHeaderContainerStyles = {
  pt: {
    base: '16px',
    md: '32px',
  },
  px: {
    base: '16px',
    md: '32px',
    lg: '48px',
    xl: largestPadding,
  },
  alignItems: 'center',
  w: '100%',
  maxW: {
    base: '744px',
    lg: '896px',
    xl: '100%',
    [`2xl`]: `calc( ${maxWidth} + ${largestPadding} + ${largestPadding} )`,
  },
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const signupColumnContainerStyles = {
  px: {
    base: '16px',
    md: '32px',
    lg: '48px',
    xl: largestPadding,
  },
  alignItems: { base: 'center', xl: 'flex-start' },
  w: '100%',
  maxW: {
    base: '744px',
    lg: '896px',
    xl: '100%',
    [`2xl`]: `calc( ${maxWidth} + ${largestPadding} + ${largestPadding} )`,
  },
  flexDirection: { base: 'column', xl: 'row' },
  gap: 0,
};

export const signupReferralGiftBoxStyles = (isDesktop) => ({
  bg: 'marketing.midnight.90',
  borderRadius: '8px',
  p: isDesktop ? '12px 24px 12px 12px' : '12px',
  gap: { base: '16px', md: '24px' },
  mb: '24px !important',
  w: isDesktop ? 'max-content' : '100%',
});

export const signupHeaderLogoStyles = {
  color: 'white',
  w: { base: '100px', md: 'unset' },
  h: 'auto',
  mb: '0',
};

export const signupPrimaryColumnStyles = {
  h: '100%',
  maxW: { base: '100%', xl: '50%' },
  w: '100%',
  boxSizing: 'border-box',
};

export const signupFormContainerStyles = (isReferral) => ({
  w: { base: '800px', xl: '560px' },
  maxW: { base: '100%', xl: 'none' },
  bg: 'white',
  borderRadius: '8px',
  p: isReferral
    ? { base: '24px 16px 16px', lg: '32px 32px 24px' }
    : { base: '20px 16px 16px', lg: '20px 32px 24px' },
  mx: { base: '0px', lg: '0px', xl: '0' },
});

export const signupHeadingStyles = {
  color: 'white',
  fontSize: { base: '4xl', xl: '6xl' },
  lineHeight: { base: '40px', xl: '56px' },
  w: { base: '100%', l: '500px', xl: '564px' },
  maxW: '600px',
  mt: '0px !important',
  fontWeight: '500',
  letterSpacing: '-1.28px',
};

export const signupTextStyles = {
  color: 'brand.neutral.200',
  w: { base: '100%', [`xl`]: '496px' },
  maxW: '600px',
  fontSize: 'xl',
  fontWeight: '100',
  letterSpacing: '-0.24px',
};

export const signupLIghtTextStyles = {
  color: 'brand.neutral.200',
  w: { base: '100%', [`xl`]: '496px' },
  maxW: '600px',
  fontSize: 'xl',
  fontWeight: '100',
  letterSpacing: '-0.24px',
};

export const signupFinePrintContainerStyles = (isDesktop) => ({
  w: '100%',
  m: isDesktop ? '0' : '0 0 32px 0',
  alignItems: 'flex-start',
});

export const signupButtonStyles = {
  position: 'initial',
  w: '100%',
  mt: '24px !important',
  minWidth: '200px',
  h: '48px',
  lineHeight: '24px',
  borderRadius: '4px!important',
  background: 'brand.darkBlue.800A',
  _disabled: {
    background: 'brand.neutral.200',
  },
  _active: {
    background: 'brand.darkBlue.900',
  },
  _hover: {
    background: 'brand.darkBlue.700',
  },
};

export const signupHowToContainerStyles = (isDesktop) => ({
  minWidth: '200px',
  bg: 'brand.darkBlue.50',
  py: isDesktop ? '96px' : '64px',
});

export const signupHowToColumnContainerStyles = {
  px: {
    base: '16px',
    md: '32px',
    lg: '48px',
    xl: largestPadding,
  },
  alignItems: 'start',
  w: '100%',
  maxW: {
    base: '744px',
    lg: '896px',
    xl: '100%',
    [`2xl`]: `calc( ${maxWidth} + ${largestPadding} + ${largestPadding} )`,
  },
  flexDirection: 'column',
  bg: 'brand.darkBlue.50',
};

export const signupHowToTitleStyles = {
  fontSize: { base: '3xl', md: '4xl' },
  lineHeight: { base: '36px', md: '40px' },
  fontWeight: 'semibold',
  color: 'brand.neutral.900',
};

export const signupTermsLinkContainerStyles = {
  direction: 'row',
  mt: '40px !important',
  width: '100%',
  alignSelf: 'left',
  alignItems: 'center',
  gap: 1,
};

export const signupTermsLinkTextStyles = {
  textStyle: 'sm',
  lineHeight: '24px',
  color: 'brand.darkBlue.800A',
  cursor: 'pointer',
  textDecoration: 'underline',
};

export const signupReferralStepStyles = {
  w: '40px',
  minWidth: '40px',
  h: '40px',
  bg: 'brand.darkBlue.900',
  borderRadius: '50px',
  fontSize: 'md',
  fontWeight: 'semibold',
  lineHeight: '24px',
  pt: '8px',
  textAlign: 'center',
  color: 'white',
};

export const signupReferralStepTextStyles = {
  textStyle: 'md',
  color: 'brand.neutral.700',
};

export const signupOnePlatformContainerStyles = {
  minWidth: '200px',
  pt: { base: '64px', md: '96px' },
  pb: { base: '8px', md: '96px' },
  minHeight: 'none',
  bg: 'white',
};

export const signupOnePlatformColumnStyles = {
  flexDirection: 'column',
  bg: 'white',
  px: {
    base: '16px',
    md: '32px',
    lg: '48px',
    xl: largestPadding,
  },
  alignItems: { base: 'center', xl: 'flex-start' },
  w: '100%',
  maxW: {
    base: '744px',
    lg: '896px',
    xl: '100%',
    [`2xl`]: `calc( ${maxWidth} + ${largestPadding} + ${largestPadding} )`,
  },
};

export const signupOnePlatformTitleStyles = {
  fontSize: { base: '3xl', md: '4xl' },
  lineHeight: { base: '36px', md: '40px' },
  fontWeight: 'semibold',
  color: 'brand.neutral.900',
};

export const signupOnePlatformSubtitleStyles = {
  textStyle: 'md',
  color: 'brand.neutral.700',
};

export const signupOnePlatformColorBoxStyles = {
  w: '100%',
  p: { base: '20px', '2xl': '24px' },
  borderRadius: '8px',
  alignItems: 'top',
  gap: '18px',
};

export const signupOnePlatformColorBoxTitleStyles = {
  color: 'brand.neutral.900',
  textStyle: 'headline-lg',
};

export const signupOnePlatformColorBoxTextStyles = {
  textStyle: 'md',
  color: 'brand.neutral.700',
};

export const signupOnePlatformPhotoOverlayStyles = {
  direction: 'column',
  position: 'relative',
  top: '-56px',
  left: '56px',
  bg: 'brand.darkBlue.900',
  w: 'calc(100% - 56px)',
  borderRadius: '8px',
  p: { base: '8px 16px 16px', md: '16px 24px 24px' },
  gap: { base: '16px', md: '24px' },
};

export const signupOnePlatformPhotoOverlayTitleStyles = {
  color: 'white',
  textStyle: 'headline-lg',
  mb: '4px',
  mt: '2px',
};

export const signupOnePlatformPhotoOverlayTextStyles = {
  color: 'white',
  textStyle: 'md',
  padding: '0 !important',
  ml: '36px',
  mb: '8px',
};

export const signupOnePlatformContentContainerStyles = (isDesktop) => ({
  justifyContent: isDesktop ? 'space-around' : 'flex-start',
  w: '100%',
  gap: { base: '16px', '2xl': '24px' },
  mt: '0 !important',
});

export const signinLinkContainerStyles = {
  mt: '16px !important',
  mb: '0px !important',
  alignSelf: 'center',
  fontSize: 'xs',
};

export const signinLinkTextStyles = {
  ...linkStyles,
  color: 'brand.primary.slate.60',
  textDecoration: 'underline',
  ml: '0px !important',
};
