import React, { useState } from 'react';
import { Box, Heading, HStack, InputGroup, Stack, Text, Skeleton } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import {
  BaselaneAlert,
  BaselaneAlertNew,
  BaselaneButton,
  BaselaneFormLabel,
  BaselaneInput,
} from '@shared/components';
import { errorMessages } from '@shared/components/TwoFactorVerificationPopUp/helpers/constant';
import { formInputStyles } from '@shared/styles/input.style';
import customTheme from '@theme';
import { IconCheck, IconExclamationCircle } from '@icons';
import { Icon16Check } from '@icons/16px';
import formatPhoneNumber from '@core/utils/formatPhoneNumber';
import { numOfDigitsMask } from '@core/utils/masks';

type TwoFactorPhonePopUpProps = {
  OTPErrorCode: any,
  setOTPErrorCode: Function,
  phoneNumber: string,
  isOTPPopupOpen: boolean,
  isGetOTPSuccessful: boolean,
  onOTPPopupClose: Function,
  getOTP: Function,
  handleVerifyOnClick: Function,
  isVerifyLoading: boolean,
  APIVerification: string,
  isGetOTPLoading: boolean,
};

/**
 * 2024-07_sign_up_page_experiment
 * Alternate two factor popup for the A/B test
 */
const TwoFactorPhonePopUp = ({
  OTPErrorCode,
  setOTPErrorCode,
  phoneNumber,
  isOTPPopupOpen,
  isGetOTPSuccessful,
  onOTPPopupClose,
  getOTP,
  handleVerifyOnClick,
  isVerifyLoading,
  APIVerification,
  isGetOTPLoading,
}: TwoFactorPhonePopUpProps) => {
  const [otpCode, setOtpCode] = useState('');

  const formattedPhone =
    formatPhoneNumber(phoneNumber) === null
      ? phoneNumber
      : formatPhoneNumber(phoneNumber, false, true);

  const onCloseCancel = () => {
    setOtpCode('');
    onOTPPopupClose();
  };

  const verifiedPhone = APIVerification ? (
    <Skeleton display="inline" isLoaded={!isGetOTPLoading}>
      `xxx xxx ${APIVerification}`{' '}
    </Skeleton>
  ) : (
    formattedPhone
  );

  const popupContent = (
    <Stack spacing={0}>
      <Heading as="h2" mb={4} size="headline-lg" color="brand.neutral.900">
        Verify your phone number to continue
      </Heading>
      <BaselaneAlertNew
        visual="icon"
        iconName={Icon16Check}
        variant="neutral"
        hasCloseButton={false}
        title={`Code sent to ${verifiedPhone}`}
        body={
          <BaselaneButton variant="link" palette="neutral" size="md" onClick={onCloseCancel}>
            Edit number
          </BaselaneButton>
        }
      />
      <Box mt={4}>
        <BaselaneFormLabel textStyle="xs" htmlFor="otp-input">
          Enter code
        </BaselaneFormLabel>
        <InputGroup>
          <BaselaneInput
            {...formInputStyles}
            as={MaskedInput}
            mask={numOfDigitsMask(6)}
            size="lg"
            id="otp-input"
            name="otp-input"
            value={otpCode}
            placeholder="XXXXXX"
            maxLength={6}
            pattern="\d*"
            type="text"
            onChange={(e) => {
              if (OTPErrorCode) setOTPErrorCode(false);
              setOtpCode(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                if (otpCode !== undefined && otpCode?.length === 6) {
                  handleVerifyOnClick(otpCode);
                }
              }
            }}
          />
        </InputGroup>
      </Box>
      {OTPErrorCode && errorMessages[OTPErrorCode] && (
        <HStack mt={0.75}>
          <IconExclamationCircle w="13" h="13" color={customTheme.colors.red['800AA']} />
          <Text color="red.500A" textStyle="xs">
            {errorMessages[OTPErrorCode]}
          </Text>
        </HStack>
      )}
      <Stack direction="row" alignItems="center">
        <BaselaneButton
          id="resend-code-button"
          variant="transparent"
          palette="primary"
          size="md"
          pullLeft
          onClick={() => getOTP()}
        >
          Resend code
        </BaselaneButton>
        {isGetOTPSuccessful && <IconCheck color={customTheme.colors.green['800AA']} />}
      </Stack>
    </Stack>
  );

  const popupFooter = (
    <BaselaneButton
      id="verify-otp-button"
      isDisabled={otpCode === undefined || otpCode?.length < 6}
      isLoading={isVerifyLoading}
      variant="filled"
      palette="primary"
      size="md"
      isFullWidth
      onClick={() => handleVerifyOnClick(otpCode)}
    >
      Continue
    </BaselaneButton>
  );

  return (
    <BaselaneAlert
      showCloseButton
      isOpen={isOTPPopupOpen}
      onClose={onCloseCancel}
      isCentered
      body={popupContent}
      footer={popupFooter}
      size="lg"
      containerStyles={{ p: '24px' }}
      bodyStyles={{ m: 0 }}
      footerStyles={{ mt: '24px' }}
    />
  );
};

export default TwoFactorPhonePopUp;
