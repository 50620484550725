import React from 'react';
import {
  useIsAddTransactionDrawerOpen,
  useToggleAddTransactionDrawer,
} from '@store/Transactions/createTransactionDrawerStore';
import useBulkActionsStore from '@store/Transactions/bulkActionsStore';
import { BaselaneButton } from '@shared/components';
import CreateManualTransactionDrawerNew from './CreateManualTransactionDrawer';

function CreateManualTransactionButtonWrapper(): any {
  const isAddTransactionDrawerOpen = useIsAddTransactionDrawerOpen();
  const toggleAddTransactionDrawer = useToggleAddTransactionDrawer();
  const showBulkActions = useBulkActionsStore((state) => state.showBulkActions);

  return (
    <>
      <BaselaneButton
        size="md"
        variant="filled"
        palette="primary"
        onClick={toggleAddTransactionDrawer}
        isDisabled={showBulkActions}
        id="add-transaction-button"
      >
        Add Transaction
      </BaselaneButton>
      <CreateManualTransactionDrawerNew
        isAddTransactionDrawerOpen={isAddTransactionDrawerOpen}
        toggleAddTransactionDrawer={toggleAddTransactionDrawer}
      />
    </>
  );
}

export default CreateManualTransactionButtonWrapper;
