import React from 'react';
import { isMobile } from 'react-device-detect';
import { Stack } from '@chakra-ui/react';
import StripeButton from '@shared/components/StripeButton';
import KYCFormSubmitted from '@shared/components/BaselaneKYCForm/components/KYCFormSubmitted';
import useBreakPoints from '@core/hooks/useBreakPoints';
import BaselaneDrawer from '../../BaselaneDrawer';
import BaselaneButton from '../../BaselaneButton';

type KYCReturnStatusDrawerProps = {
  kycStatus: string,
  drawerRef: any,
  handleKYCReturnStatusDrawerClose: Function,
};

const KYCReturnStatusDrawer = ({
  kycStatus,
  drawerRef,
  handleKYCReturnStatusDrawerClose,
}: KYCReturnStatusDrawerProps) => {
  const isCentered = kycStatus === 'VOUCH_PENDING';
  const { isMax767 } = useBreakPoints();

  return (
    <BaselaneDrawer
      ref={drawerRef}
      title="Identity Verification"
      closeEvent={handleKYCReturnStatusDrawerClose}
      onClose={handleKYCReturnStatusDrawerClose}
      size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
      footer={
        <Stack direction="row" spacing={2}>
          {!isMobile ||
            (!isCentered && (
              <BaselaneButton
                variant="outline"
                palette="neutral"
                size="md"
                onClick={handleKYCReturnStatusDrawerClose}
              >
                Close
              </BaselaneButton>
            ))}

          {kycStatus === 'ADDITIONAL_INFO' && (
            <StripeButton
              variant="filled"
              palette="primary"
              text="Provide Additional Info"
              size="md"
            />
          )}
        </Stack>
      }
      newDesignDrawer
    >
      <KYCFormSubmitted {...{ kycStatus, isCentered }} />
    </BaselaneDrawer>
  );
};

export default KYCReturnStatusDrawer;
