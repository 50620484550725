import React from 'react';
import { Heading, Text, VStack, Box } from '@chakra-ui/react';
import { BaselaneButton, BaselaneCardNew } from '@shared/components';
import { IllustrationOtherTenantScreening } from '@illustrations';

const EmptyState = ({ active, setTabIndex }: any) => {
  return (
    <BaselaneCardNew minH="240px" w="full" palette="empty">
      <VStack alignItems="center" w="100%" gap={0.5}>
        <Box mb={1.5}>
          <IllustrationOtherTenantScreening />
        </Box>
        <Heading size="headline-md">
          {active ? 'No active tenant screenings' : 'No inactive tenant screenings'}
        </Heading>
        <Text textStyle="sm" mb="16px">
          {active
            ? 'To access screenings where new applications are turned off, go to the Inactive tab.'
            : 'Screenings where new applications are turned off will appear here.'}
        </Text>
        <BaselaneButton
          palette="neutral"
          variant="outline"
          size="md"
          onClick={() => setTabIndex(active ? 1 : 0)}
        >
          {active ? 'View inactive screenings' : 'View active screenings'}
        </BaselaneButton>
      </VStack>
    </BaselaneCardNew>
  );
};

export default EmptyState;
