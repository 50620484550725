import React, { useRef } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  Text,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { LEAVE_WORKSPACE } from '@core/apollo/queries';
import { useCurrentWorkspace, useUserAccessibleWorkspace } from '@shared/hooks';
import { BaselaneButton } from '@shared/components';

type LeaveWorkspaceAlertProps = {
  isAlertOpen: boolean,
  onAlertClose: Function,
  workspaceId: Number,
};

const LeaveWorkspaceAlert = ({
  isAlertOpen,
  onAlertClose,
  workspaceId,
}: LeaveWorkspaceAlertProps) => {
  const alertCancelRef = useRef(null);
  const [leaveWorkspace] = useMutation(LEAVE_WORKSPACE);
  const { refetchCurrentWorkspace } = useCurrentWorkspace();
  const { refetch: refetchAccessibleWorkspaceData } = useUserAccessibleWorkspace();
  const toast = useToast();
  const client = useApolloClient();
  const showSuccessToast = async () =>
    toast({
      position: 'bottom-left',
      description: 'Left workspace',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Error leaving workspace',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleLeaveWorkspace = () => {
    leaveWorkspace({
      variables: {
        workspaceId,
      },
    })
      .then((res) => {
        if (res.data) {
          try {
            client.resetStore();
          } catch (error) {
            console.error('Error clearing Apollo Client store:', error);
          }
          showSuccessToast();
          refetchCurrentWorkspace();
          refetchAccessibleWorkspaceData();
          setTimeout(() => {
            onAlertClose();
          }, 30000);
        }
        if (res.errors) {
          showErrorToast();
        }
      })
      .catch((err) => showErrorToast());
  };
  return (
    <AlertDialog
      leastDestructiveRef={alertCancelRef}
      onClose={onAlertClose}
      isOpen={isAlertOpen}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="sm"
    >
      <AlertDialogOverlay />
      <AlertDialogContent p={4} minHeight="168px" maxHeight="calc(100% - 100px)" overflow="auto">
        <Stack spacing={2} mb="3">
          <Stack spacing={0.5}>
            <AlertDialogHeader p={0}>
              <Text textStyle="headline-lg">Are you sure you want to leave this workspace?</Text>
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody p={0}>
              <Text textStyle="sm">You will immediately lose access to this workspace.</Text>
            </AlertDialogBody>
          </Stack>
          ;
        </Stack>
        <Stack direction="row" w="100%" spacing={1.5}>
          <BaselaneButton
            size="md"
            variant="outline"
            palette="neutral"
            onClick={onAlertClose}
            isFullWidth
          >
            Cancel
          </BaselaneButton>
          <BaselaneButton
            size="md"
            variant="filled"
            palette="danger"
            onClick={handleLeaveWorkspace}
            isFullWidth
          >
            Leave
          </BaselaneButton>
        </Stack>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default LeaveWorkspaceAlert;
