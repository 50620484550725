import React from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  CSV_IMPORT_UPLOAD,
  CSV_IMPORT_REVIEW_COLUMNS,
  CSV_IMPORT_MAP_CATEGORIES,
  CSV_IMPORT_MAP_PROPERTIES,
  CSV_IMPORT_PREVIEW,
} from '@routes';

import CSVImport from '@pages/CSVImport';
import UploadCSV from '@pages/CSVImport/UploadCSV';
import ReviewColumns from '@pages/CSVImport/ReviewColumns';
import MapCategories from '@pages/CSVImport/MapCategories';
import MapProperties from '@pages/CSVImport/MapProperties';
import CSVPreview from '@pages/CSVImport/CSVPreview';
import TransactionWrapper from '../components/wrappers/TransactionWrapper';

const CSVImportRoutes = () => {
  return (
    <Routes>
      <Route element={<TransactionWrapper />}>
        <Route path="*" element={<CSVImport />}>
          <Route path={CSV_IMPORT_UPLOAD} element={<UploadCSV />} />
          <Route path={CSV_IMPORT_REVIEW_COLUMNS} element={<ReviewColumns />} />
          <Route path={CSV_IMPORT_MAP_CATEGORIES} element={<MapCategories />} />
          <Route path={CSV_IMPORT_MAP_PROPERTIES} element={<MapProperties />} />
          <Route path={CSV_IMPORT_PREVIEW} element={<CSVPreview />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default CSVImportRoutes;
