import React from 'react';
import { Table, Thead, Tr, Th, HStack, Text } from '@chakra-ui/react';
import customTheme from '@core/theme';
import {
  highlightedRowStyle,
  highlightedHeaderCellStyle,
  highlightedHeaderFontStyle,
  tableStyles,
} from '../../../styles/cashflowStatement.styles';

type HighlightedRowHeaderProps = {
  title: String,
};

function HighlightedRowHeader({ title }: HighlightedRowHeaderProps) {
  return (
    <Table {...{ ...tableStyles(), position: 'relative', zIndex: '2' }}>
      <Thead>
        <Tr
          {...{
            ...highlightedRowStyle,
            borderRadius: '4px 0 0 4px',
            borderRightWidth: '1px',
            borderRightColor: customTheme.colors.brand.neutral['600'],
            borderRightStyle: 'solid',
          }}
        >
          <Th {...highlightedHeaderCellStyle}>
            <HStack {...{ display: 'flex', justifyContent: 'space-between', w: '100%' }}>
              <Text {...{ ...highlightedHeaderFontStyle, ml: '0px !important', pl: '48px' }}>
                {title}
              </Text>
            </HStack>
          </Th>
        </Tr>
      </Thead>
    </Table>
  );
}

export default HighlightedRowHeader;
