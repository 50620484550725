/* eslint-disable react/prop-types */
import React from 'react';
import { Text } from '@chakra-ui/react';

const Label = ({ children }) => (
  <Text textStyle="sm" color="brand.neutral.600">
    {children}
  </Text>
);

export default Label;
