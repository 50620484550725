import React, { useContext, useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs, TabPanels, TabPanel } from '@chakra-ui/react';
import {
  ANALYTICS_REPORTING,
  CASH_FLOW_STATEMENT,
  CASH_FLOW_TAX_PACKAGE,
} from '@core/constants/routes';
import { getPropertyData } from '@core/components/Shared/helpers/propertiesFilter.helpers';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { BaselaneCard } from '@shared/components';
import CashFlowContext from '@contexts/CashFlowContext';
import { useTransactionsSummary } from '@core/components/Shared/hooks';
import SlLoader from '@core/components/Loader';
import isNotNil from '@core/utils/validators/isNotNil';
import { formatDate } from '@core/utils/formatDate';
import {
  RenderWarningMessage,
  showWarningInDesktop,
  showWarningInMobile,
} from '@core/components/App/RenderWarningMessage';
import { PRESETS, patchPresets, sortPresets } from '@shared/helpers/cashFlow.helpers';
import onDrawerClose from '@core/utils/onDrawerClose';
import { TABS, getPresetSubCategoriesTags } from './helpers/cashflow.helpers';
import CashFlowTabList from './components/CashFlowTabList';
import CashFlowTabPanel from './components/CashFlowTabPanel';
import EmptyState from './components/EmptyState';
import FiltersSection from './FiltersSection';
import type { Property } from '../Properties/types';
import { tabsStyles, tabPanelsStyles, tabPanelStyles } from './styles/tabs.styles';

function CashFlowNewPage() {
  const tabsRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  const isStatementPage = location?.pathname.includes(CASH_FLOW_STATEMENT);
  const isTaxPackagePage = location?.pathname === CASH_FLOW_TAX_PACKAGE;

  const {
    isLoading,
    setIsLoading,
    isConnectBALoading,
    setIsConnectBALoading,
    filters,
    setFilters,
    dataType,
    setDataType,
    setProperties,
    totalPropertiesCount,
    setTotalPropertiesCount,
    cashFlowPropertiesData,
    categoryOptions,
    categoryWithSubOptions,
    categoryMap,
    persist,
    presetOptions: contextPresetOptions,
    cashFlowRefetch,
  } = useContext(CashFlowContext);

  const [hasNoData, setHasNoData] = useState(false);
  const [activeMonth, setActiveMonth] = useState(null);
  const [activePropertyUnit, setActivePropertyUnit] = useState(null);
  const [lastPreset, setLastPreset] = useState(null);
  const [lastCategoryPreset, setLastCategoryPreset] = useState(persist.lastCategoryPreset);
  const [selectedCustomCategories, setSelectedCustomCategories] = useState(null);

  useEffect(() => {
    if (persist?.categoryOptions) {
      setSelectedCustomCategories(persist.categoryOptions);
    } else {
      setSelectedCustomCategories(null);
    }
  }, [persist?.categoryOptions]);

  /**
   * Reset the filters if we navigate to any page other than
   * ANALYTICS_REPORTING or CASH_FLOW_STATEMENT.
   */

  const handleLocationChange = (locationChange) => {
    if (!locationChange.pathname.includes(ANALYTICS_REPORTING)) {
      persist?.clearPersistentData();
    }
  };

  useEffect(() => {
    handleLocationChange(location);
    // no property / unit for TaxPackageTab
    if (location?.pathname === CASH_FLOW_TAX_PACKAGE) {
      setDataType('month');
    }
    setActiveMonth(null);
    setActivePropertyUnit(null);
  }, [location]);

  const {
    totalTransactionCount,
    transactionsSummaryLoading,
    startPollingTransactionsSummary,
    stopPollingTransactionsSummary,
  } = useTransactionsSummary({ input: {} });

  const getTotalCount = (propertyDataOptions) => {
    const totalCount = propertyDataOptions?.reduce(
      (acc, item) => {
        if (item.isChild) acc.uCount += 1;
        else acc.pCount += 1;
        return acc;
      },
      { pCount: 0, uCount: 0 }
    );
    return totalCount;
  };

  function handleSelectTimePeriod(timePeriod: any) {
    setFilters({
      filter: {
        ...filters.filter,
        from: formatDate(timePeriod.from, 'YYYY-MM-DD'),
        to: formatDate(timePeriod.to, 'YYYY-MM-DD'),
      },
    });
  }

  const sendFilterCategorySegmentEvent = (presetCategories, filteredCategories) => {
    const categories = filteredCategories.map((cat) => cat.id);
    sendSegmentEvent('cashflow_filter_category', {
      categories: categories.join(', '),
      preset: presetCategories.name?.toLowerCase() ?? null,
    });
  };

  const sendFilterPropertySegementEvent = () => {
    sendSegmentEvent('cashflow_filter_property', {
      properties_count: totalPropertiesCount.properties,
      units_count: totalPropertiesCount.units,
    });
  };

  function handleSelectProperties(propertyData) {
    const { propertyIds, unitIds } = propertyData;

    const updatedFilters = {
      filter: {
        ...filters.filter,
        propertyId: propertyIds.length > 0 ? propertyIds : null,
        unitId: unitIds.length > 0 ? unitIds : null,
      },
    };
    setFilters(updatedFilters);
    sendFilterPropertySegementEvent({ propertyIds, unitIds });
  }

  function handleSelectPreset(preset) {
    setLastPreset(preset);
  }

  function handleSelectCategoryPreset(preset) {
    setLastCategoryPreset(preset);
    persist.setLastCategoryPreset(preset);
  }

  function handleSelectCategories(presetCategories, filteredCategories, isUncategorized) {
    const updatedFilters =
      presetCategories.name !== null
        ? {
            filter: {
              ...filters.filter,
              tagId: [
                ...presetCategories.tagId,
                ...getPresetSubCategoriesTags(
                  presetCategories,
                  categoryWithSubOptions,
                  categoryMap
                ),
              ],
              uncategorized: isUncategorized,
            },
          }
        : {
            filter: {
              ...filters.filter,
              tagId: filteredCategories.length > 0 ? filteredCategories.map((cat) => cat.id) : null,
              uncategorized: isUncategorized,
            },
          };

    setFilters(updatedFilters);
    sendFilterCategorySegmentEvent(presetCategories, filteredCategories);
  }

  const handleAccountConnectDrawerClose = () => {
    onDrawerClose(navigate, ANALYTICS_REPORTING);
    setIsConnectBALoading(true);

    cashFlowRefetch();
    startPollingTransactionsSummary(1500);
    setTimeout(() => {
      stopPollingTransactionsSummary();
      setIsConnectBALoading(false);
    }, 4500);
  };

  const getDefaultIndex = () => {
    let index = 0;

    if (isStatementPage) index = 1;
    if (isTaxPackagePage) index = 2;

    return index;
  };

  const defaultIndex = getDefaultIndex();

  /**
   * Patching presets with additional data compared to the database.
   * Note: if database is updated, this may be removed.
   */
  const presetOptions = sortPresets(patchPresets(contextPresetOptions));

  const filterComponents = (
    <FiltersSection
      onSelectTimePeriod={handleSelectTimePeriod}
      onSelectProperties={handleSelectProperties}
      onSelectCategories={handleSelectCategories}
      onSelectCategoryPreset={handleSelectCategoryPreset}
      onSelectPreset={handleSelectPreset}
      defaultPresetName={PRESETS.NET_OPERATING_CASHFLOW}
      presetOptions={presetOptions}
      defaultIndex={defaultIndex}
      lastPreset={lastPreset}
      dataType={dataType}
      setDataType={setDataType}
    />
  );

  useEffect(() => {
    if (isNotNil(cashFlowPropertiesData)) {
      const propertiesFromResponse: Property[] = cashFlowPropertiesData.property;
      const propertyDataOptions = getPropertyData(propertiesFromResponse);
      setProperties(propertyDataOptions);

      const { pCount, uCount } = getTotalCount(propertyDataOptions);
      setTotalPropertiesCount({ properties: pCount, units: uCount });
    }
  }, [cashFlowPropertiesData]);

  useEffect(() => {
    if (!activeMonth) {
      setActiveMonth(null);
    }
  }, [activeMonth]);

  useEffect(() => {
    if (!activePropertyUnit) {
      setActivePropertyUnit(null);
    }
  }, [activePropertyUnit]);

  useEffect(() => {
    const loading = !totalTransactionCount && totalTransactionCount !== 0;
    const noData = !loading && totalTransactionCount === 0;
    setIsLoading(loading);
    setHasNoData(noData);
    if (!noData) setIsConnectBALoading(false);
  }, [totalTransactionCount]);

  if (showWarningInMobile(location) || showWarningInDesktop(location)) {
    return <RenderWarningMessage />;
  }

  return (
    <BaselaneCard>
      {/* Checking for categoryOptions to make sure all categories show by default */}
      {isLoading || !categoryOptions || transactionsSummaryLoading ? (
        <SlLoader />
      ) : (
        <Tabs variant="unstyled" {...tabsStyles} defaultIndex={defaultIndex} id="cashflow-tabs">
          {filterComponents}
          <CashFlowTabList />

          <TabPanels {...{ ...tabPanelsStyles }} ref={tabsRef}>
            {TABS.map((tab) => {
              return (
                <TabPanel
                  key={tab.key}
                  {...tabPanelStyles}
                  overflowY={tab.key === 'cashflow_statement' ? 'hidden' : 'auto'}
                  height={tab.key === 'cashflow_statement' ? '100%' : 'auto'}
                >
                  {/** This only renders the contents of a tab panel if we are on
                   the url that displays that tab panel, rather than always rendering
                   all of them, which causes visual glitches, stuttering and slower rendering.
                   Nnrmally tab panels are all under one url, unlike the requirements here.

                   TODO: A future refactor could simplify this. */}
                  {((tab.key === 'analytics' && !isStatementPage && !isTaxPackagePage) ||
                    (tab.key === 'cashflow_statement' && isStatementPage && !isTaxPackagePage) ||
                    (tab.key === 'cashflow_tax_package' &&
                      isTaxPackagePage &&
                      !isStatementPage)) && (
                    <>
                      <CashFlowTabPanel
                        {...{
                          type: tab.key,
                          setActiveMonth,
                          activeMonth,
                          activePropertyUnit,
                          setActivePropertyUnit,
                          lastPreset,
                          tabsRef,
                          hasNoData,
                          lastCategoryPreset,
                          selectedCustomCategories,
                          presetOptions,
                          cashFlowPropertiesData,
                        }}
                      />
                      {hasNoData && (
                        <EmptyState
                          isConnectBALoading={isConnectBALoading}
                          handleAccountConnectDrawerClose={handleAccountConnectDrawerClose}
                          totalTransactionCount={totalTransactionCount}
                          setIsConnectBALoading={setIsConnectBALoading}
                        />
                      )}
                    </>
                  )}
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      )}
    </BaselaneCard>
  );
}

export default CashFlowNewPage;
