import React from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Box, Flex } from '@chakra-ui/react';
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Area,
} from 'recharts';
import { GET_HISTORICAL_BALANCE } from '@core/apollo/queries';
import { BaselaneTitle, EmptyText, CurrencyText } from '@shared/components';
import IconSkeletonLineChart from '@icons/manual/IconSkeletonLineChart';
import formatCurrency from '@core/utils/formatCurrency';
import { tooltipStyles } from '@shared/components/BaselaneTooltip/styles/tooltip.style';
import { BankBalanceGraphData } from './types';

const CHART_WIDTH = '100%';
const CHART_HEIGHT = 230;

const YAXIS_TICK_STYLE = {
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '12px',
};

function CustomYTick({ y, payload }: any) {
  const formattedValue = formatCurrency(payload.value);
  const isNegative = payload.value < 0;
  return (
    <g transform={`translate(25,${y})`}>
      <text
        x={0}
        y={0}
        style={YAXIS_TICK_STYLE}
        textAnchor="middle"
        fill={isNegative ? '#EA6868' : '#3A4B5B'}
      >
        {formattedValue.rounded}
      </text>
    </g>
  );
}

function BankBalancesGraph({ hasNoData }: { hasNoData: boolean }) {
  let formattedData: BankBalanceGraphData[] = [];

  const { data } = useQuery(GET_HISTORICAL_BALANCE, { fetchPolicy: 'no-cache' });

  const rowData = data?.user?.historicalBalances;
  if (rowData && rowData.length > 0) {
    const dateFilter = moment().startOf('month').subtract(5, 'months').valueOf();
    formattedData = rowData
      ? rowData
          .filter((item) => item.date >= dateFilter)
          .sort((a, b) => a.date - b.date)
          .map((item) => ({
            amount: item.amount,
            date: item.date,
          }))
      : [];
  }

  const customTooltip = ({ active, payload }) => {
    // TODO: Better parsing
    // eslint-disable-next-line no-prototype-builtins
    if (active && payload) {
      const { amount, date } = payload[0]?.payload || {};

      return (
        <Flex {...tooltipStyles}>
          <p>{moment.unix(date / 1000).format('MMM DD, YYYY')}</p>
          <Box ml="10px">
            {amount != null ? (
              <CurrencyText
                isRounded={false}
                textStyle="sm"
                color="brand.neutral.white"
                negativeColor="brand.neutral.white"
                amount={amount}
              />
            ) : (
              <EmptyText />
            )}
          </Box>
        </Flex>
      );
    }
    return null;
  };

  const renderCustomAxisTick = (value) => moment.unix(value / 1000).format('MMM YYYY');

  const gradientArea = (
    <defs>
      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
        <stop offset="5%" stopColor="#0167ff" stopOpacity={0.1} />
        <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
      </linearGradient>
    </defs>
  );

  return (
    <Box w="100%" pt="36px">
      <BaselaneTitle title="Balance Snapshot" />
      <Box mx="24px">
        {hasNoData || formattedData.length === 0 ? (
          <Box display="flex" justifyContent="center">
            <IconSkeletonLineChart />
          </Box>
        ) : (
          <ResponsiveContainer width={CHART_WIDTH} height={CHART_HEIGHT}>
            <ComposedChart data={formattedData}>
              {gradientArea}
              <Area
                type="monotone"
                dataKey="amount"
                strokeWidth="2"
                fillOpacity="1"
                fill="url(#colorUv)"
              />
              <CartesianGrid
                vertical={false}
                strokeWidth="1"
                strokeDasharray="4"
                stroke="#C8D0DA"
              />
              <Line type="monotone" dataKey="amount" strokeWidth="4" stroke="#257ED0" dot={false} />
              <YAxis tickCount={6} tickLine={false} axisLine={false} tick={<CustomYTick />} />
              <XAxis
                tickCount={7}
                interval={30}
                dataKey="date"
                stroke="#3A4B5B"
                axisLine={{ stroke: '#C8D0DA', strokeDasharray: '4' }}
                tickFormatter={(tick) => renderCustomAxisTick(tick)}
                tickLine={false}
                tick={{ fontSize: 12, dx: 20 }}
                padding={{ right: 20 }}
              />
              <Tooltip
                content={customTooltip}
                cursor={{ stroke: '#EA6868', strokeWidth: '1', strokeDasharray: '4' }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </Box>
    </Box>
  );
}

export default BankBalancesGraph;
