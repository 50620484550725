import React, { useRef, useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Stack } from '@chakra-ui/react';
import { emailVerificaton } from '@core/Services/CreateAccount';
import { reload } from '@core/Services/Firebase/firebase';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import UserContext from '@contexts/UserContext';
import { IconExclamationCircle } from '@icons';
import { InProgress } from '@pages/Dashboard/SetupGuide/GuideTrackers/Steps';
import { STRIPE_ON_BOARDING } from '@core/apollo/queries/stripe';
import TrackerBox from '../TrackerBox';
import AlertHeader from '../AlertHeader';
import BaselaneAlert from '../BaselaneAlert';
import BaselaneButton from '../BaselaneButton';
import { TrackersConfig } from '../../helpers/onboardingConfig.helper';
import {
  ALERT_TITLE,
  ALERT_BODY,
  ALERT_BUTTON1,
  ALERT_BUTTON2,
} from './constants/stripeButton.constants';
import { iconBgStyles } from './styles/stripeButton.styles';

type StripeButtonProps = {
  variant: string,
  palette: string,
  text: string,
  size: string,
  styles: Object,
  returnUrl: string,
  refreshUrl: string,
  isOnboarding: boolean,
  isGetStarted?: boolean,
  getStartedConfig?: Object,
};

// will eventually be changed to add other verifications
function StripeButton({
  variant = 'outline',
  palette = 'neutral',
  text,
  size = 'md',
  styles = {},
  returnUrl = window.location.href,
  refreshUrl = window.location.href,
  isOnboarding,
  isGetStarted = false,
  getStartedConfig = {},
  ...otherProps
}: StripeButtonProps) {
  const { user, setEmailVerified } = useContext(UserContext);
  const { id, email, stripeAccount } = user;

  const [inProgressLoading, setProgressLoading] = useState(false);

  // email verification alert
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const onAlertClose = () => setIsAlertOpen(false);
  const onAlertOpen = () => setIsAlertOpen(true);
  const cancelRef = useRef();

  const [stripeOnboarding, { data }] = useMutation(STRIPE_ON_BOARDING);

  const handleResendEmailVerificationClick = () => {
    const bodyParam = { email };
    emailVerificaton(bodyParam);
    onAlertClose();
  };

  const handleStripeOnboarding = async () => {
    setProgressLoading(true);
    const emailVerified = await reload();

    if (!emailVerified) {
      onAlertOpen();
      setProgressLoading(false);
      return;
    }

    setEmailVerified(emailVerified);
    stripeOnboarding({
      variables: { refreshUrl, returnUrl },
    });
  };

  if (data) {
    const { url = null } = data.stripeOnBoarding || {};
    if (url !== null) window.location.href = url;
  }

  const sendOnboardingSegmentEvent = () => {
    sendSegmentEvent(TrackersConfig.verify.segmentTrackerId, { landlord_uuid: id });
  };

  if (isOnboarding) {
    const { userKYCStatus } = stripeAccount;
    const isUserVerified = userKYCStatus === 'verified';

    const config = {
      ...TrackersConfig.verify,
      onClickTrackerBox: (e) => {
        handleStripeOnboarding(e);
        sendOnboardingSegmentEvent();
      },
      isCompleted: isUserVerified,
    };
    return <TrackerBox trackerData={config} />;
  }

  const alertFooter = (
    <Stack direction="row">
      <BaselaneButton variant="outline" palette="neutral" size="lg" onClick={onAlertClose}>
        {ALERT_BUTTON1}
      </BaselaneButton>
      <BaselaneButton
        variant="filled"
        palette="danger"
        size="lg"
        onClick={handleResendEmailVerificationClick}
      >
        {ALERT_BUTTON2}
      </BaselaneButton>
    </Stack>
  );

  return (
    <>
      {!isGetStarted ? (
        <BaselaneButton
          {...{ variant, palette, size, styles }}
          onClick={handleStripeOnboarding}
          {...otherProps}
        >
          {text}
        </BaselaneButton>
      ) : (
        <InProgress
          status={getStartedConfig.status}
          handleTrackerInProgStepClick={(e) => handleStripeOnboarding(e)}
          buttonLoading={inProgressLoading}
        />
      )}

      <BaselaneAlert
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        leastDestructiveRef={cancelRef}
        isCentered
        header={
          <AlertHeader
            icon={<IconExclamationCircle w="24" h="24" color="#EA6868" />}
            iconBgStyles={iconBgStyles}
            title={ALERT_TITLE}
          />
        }
        body={ALERT_BODY}
        footer={alertFooter}
        size="2xl"
        showCloseButton
      />
    </>
  );
}

export default StripeButton;
