import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { IconKey } from '@icons';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { BaselaneChip, BaselaneRadio, BaselaneTooltip } from '@shared/components';
import { rentCollectionHelper } from '@shared/components/BaselaneResponsiveCard/helpers/property.helper';
import BaselaneResponsiveCard from '@shared/components/BaselaneResponsiveCard/BaselaneResponsiveCard';
import {
  propertyDisabledTypeTextStyles,
  addressTextStyles,
  unitCardStyles,
} from '@shared/components/BaselaneResponsiveCard/styles/card.style';

const Address = ({ isActivelyCollectingRent, property }: any) => (
  <Text
    noOfLines={[1, 2]}
    {...(isActivelyCollectingRent
      ? { ...addressTextStyles, ...propertyDisabledTypeTextStyles }
      : addressTextStyles)}
  >
    {property.address?.address}, {property.address?.city}, {property.address?.state}{' '}
    {property.address?.zipCode}
  </Text>
);

const UnitName = ({ isActivelyCollectingRent, property }: any) => (
  <Text
    {...(isActivelyCollectingRent
      ? { ...addressTextStyles, ...propertyDisabledTypeTextStyles }
      : addressTextStyles)}
  >
    {property.name}
  </Text>
);

type Props = {
  isOnlyPropertyAndUnit: boolean,
  property: Object,
  bankProps?: Object,
  isUnit?: Boolean,
  isCollectingRent?: Boolean,
  parentProperty?: Object,
  onComplete?: Function,
  onUnitClick?: Function,
  onShowLeaseTerms?: Function,
  value: String,
  setValue: Function,
  selectingUnitPanel?: Boolean,
  selectedProperty?: Object,
  saving: Boolean,
  id?: string,
  clickedPropertyUnit: any,
};

function BaselaneUnitRadioCard({
  isOnlyPropertyAndUnit,
  property,
  bankProps = null,
  isUnit = false,
  isCollectingRent = false,
  parentProperty = null,
  onComplete = null,
  onUnitClick = () => {},
  onShowLeaseTerms = null,
  value,
  setValue,
  selectingUnitPanel = false,
  selectedProperty = {},
  saving,
  id = '',
  clickedPropertyUnit,
  ...rest
}: Props): any {
  const { isMax768: isMobile } = useBreakPoints();
  const { units } = property;
  const { clickedProperty } = clickedPropertyUnit || {};
  const isSingleUnit = units?.length ? units.length < 2 : false;
  let rentStatus = rentCollectionHelper(isSingleUnit ? property.units[0] : property);

  const activeRentCollectionsCount = property?.units?.filter((unit) => unit.status === 'occupied')
    .length;
  const showKeyIcon = activeRentCollectionsCount > 0 && !selectingUnitPanel;
  const childShowKeyIcon = isUnit && property.status === 'occupied';

  const isActivelyCollectingRent = rentStatus === 'occupied';
  // if this is a property, check if one of the units has draft status
  let hasVacantUnits = false;
  if (!isUnit) {
    rentStatus = property.units.find((unit) => unit.lease?.state === 'DRAFT')
      ? 'Resume Draft'
      : null;
    rentStatus = property.units.find((unit) => unit.lease?.state === 'READY_TO_BE_REVIEWED')
      ? 'Resume Draft'
      : rentStatus;
    hasVacantUnits = Boolean(
      property.units.find(
        (unit) =>
          !unit.lease ||
          unit.lease?.state === null ||
          unit.lease?.state === 'DRAFT' ||
          unit.lease?.state === 'READY_TO_BE_REVIEWED'
      )
    );
  }

  return !isUnit ? (
    <>
      {(!selectingUnitPanel || property.id === clickedProperty.id) &&
        hasVacantUnits &&
        (!rentStatus || rentStatus?.toUpperCase() === 'RESUME DRAFT') && (
          <BaselaneResponsiveCard
            id={id}
            {...rest}
            {...unitCardStyles(isMobile, true, isMobile)}
            backgroundColor={
              selectingUnitPanel && property.id === clickedProperty.id
                ? 'brand.darkBlue.100'
                : 'brand.neutral.white'
            }
            onClick={() => onUnitClick(property, property.units[1] ? null : property.units[0])}
          >
            <Flex
              justifyContent="space-between"
              w="100%"
              gap="8px"
              direction={{ base: 'column', sm: 'row' }}
            >
              <Box>
                {(!selectingUnitPanel || property.id !== clickedProperty.id) && (
                  <BaselaneRadio
                    className="custom-radio-without-group"
                    value={property.units[0].id}
                    isChecked={property?.id === clickedProperty?.id}
                    label={
                      property.address && <Address {...{ isActivelyCollectingRent, property }} />
                    }
                    description={property?.units.length > 1 && `${property.units.length} units`}
                  />
                )}
                {selectingUnitPanel && property.id === clickedProperty.id && property.address && (
                  <Address {...{ isActivelyCollectingRent, property }} />
                )}
              </Box>
              <Flex gap="8px">
                {showKeyIcon && (
                  <BaselaneTooltip
                    label={`${activeRentCollectionsCount} active rent collection`}
                    placement="top"
                  >
                    <BaselaneChip
                      variant="simple-success"
                      size="sm"
                      label={activeRentCollectionsCount}
                      leftIcon={IconKey}
                    />
                  </BaselaneTooltip>
                )}
                {rentStatus && (!selectingUnitPanel || property.id !== clickedProperty.id) && (
                  <BaselaneChip isMobile label={rentStatus} variant="simple-primary" size="sm" />
                )}
              </Flex>
            </Flex>
          </BaselaneResponsiveCard>
        )}
      {!isSingleUnit &&
        selectingUnitPanel &&
        selectedProperty.id === property.id &&
        units.map((unit) => (
          <BaselaneUnitRadioCard
            key={`unit-card-${unit.id}`}
            id={`unit-card-${unit.id}`}
            isCollectingRent={isActivelyCollectingRent}
            bankProps={bankProps}
            property={unit}
            parentProperty={property}
            isUnit
            onUnitClick={onUnitClick}
            {...unitCardStyles(isMobile, false, isMobile)}
            onComplete={onComplete}
            onShowLeaseTerms={onShowLeaseTerms}
            value={value}
            setValue={setValue}
          />
        ))}
    </>
  ) : (
    (!rentStatus || rentStatus?.toUpperCase() === 'RESUME DRAFT') && (
      <BaselaneResponsiveCard
        id={id}
        {...rest}
        {...unitCardStyles(isMobile, false, isMobile)}
        style={{ cursor: isActivelyCollectingRent || saving ? null : 'pointer' }}
        onClick={() =>
          isActivelyCollectingRent || saving ? null : onUnitClick(parentProperty, property)
        }
      >
        <Flex justifyContent="space-between" w="100%">
          <Box>
            <BaselaneRadio
              className="custom-radio-without-group"
              value={property.id}
              isChecked={value === property?.id}
            >
              {property.name && <UnitName {...{ isActivelyCollectingRent, property }} />}
            </BaselaneRadio>
          </Box>

          <Flex gap="8px">
            {childShowKeyIcon && (
              <BaselaneTooltip
                label={`${activeRentCollectionsCount} active rent collection`}
                placement="top"
              >
                <BaselaneChip
                  variant="simple-success"
                  size="sm"
                  label={activeRentCollectionsCount}
                  leftIcon={IconKey}
                />
              </BaselaneTooltip>
            )}
            {rentStatus && (
              <BaselaneChip isMobile label={rentStatus} variant="simple-primary" size="sm" />
            )}
          </Flex>
        </Flex>
      </BaselaneResponsiveCard>
    )
  );
}

export default BaselaneUnitRadioCard;
