export const getTotalNumberOfAccounts = (banks) =>
  banks?.reduce((acc, bank) => {
    const { subAccounts = [], accountStatus } = bank.bankAccounts?.[0] ?? {};
    if (accountStatus !== 'Closed') {
      const connectedSubAccounts = subAccounts.filter(
        (subAcc) => subAcc.accountStatus !== 'Closed'
      );
      // + 1 is the bank account it comes with
      return acc + connectedSubAccounts.length + 1;
    }

    return acc;
  }, 0);

export const getTotalNumberAvailableBalance = (bankEntityInfo) => {
  const { availableBalance = 0, subAccounts = [] } = bankEntityInfo ?? {};
  const sumOfSubAccountBalances = subAccounts.reduce((subAcc, subAccBank) => {
    return subAcc + subAccBank.availableBalance;
  }, 0);

  return availableBalance + sumOfSubAccountBalances;
};
