import React from 'react';

type IconBankPlusNewProps = { w?: Number, h?: Number };

function IconBankPlusNew({ w, h }: IconBankPlusNewProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon">
        <path
          id="Icon_2"
          d="M12.7677 22.1999C12.2905 22.1999 6.64749 22.1999 3.48894 22.1999C3.40624 22.1999 3.36489 22.1999 3.3299 22.1987C2.28027 22.1619 1.43789 21.3195 1.40113 20.2699C1.3999 20.2349 1.3999 20.1935 1.3999 20.1108V20.1108V19.1967V19.1967C1.3999 18.2043 2.20435 17.3999 3.19668 17.3999H12.9999M3.79991 8.61893V17.2666M8.59991 8.61893C8.59991 8.61893 8.59991 13.7485 8.59991 17.3999M14.1999 8.61893L14.1999 15.3999M18.9999 8.61893V13.0094M19.0944 2.93881L12.0275 1.52542C11.7931 1.47854 11.6759 1.45509 11.5577 1.44574C11.4527 1.43743 11.3472 1.43743 11.2422 1.44574C11.1239 1.45509 11.0067 1.47854 10.7723 1.52542L3.70537 2.93881C3.04397 3.07109 2.71328 3.13723 2.44915 3.27994C1.98735 3.52947 1.64216 3.95054 1.48805 4.4523C1.3999 4.73929 1.3999 5.07653 1.3999 5.75102V5.75102C1.3999 6.55907 1.3999 6.96309 1.51494 7.28746C1.71641 7.85552 2.16332 8.30242 2.73137 8.50389C3.05574 8.61893 3.45976 8.61893 4.26781 8.61893H18.532C19.34 8.61893 19.7441 8.61893 20.0684 8.50389C20.6365 8.30242 21.0834 7.85552 21.2849 7.28746C21.3999 6.96309 21.3999 6.55907 21.3999 5.75102V5.75102C21.3999 5.07653 21.3999 4.73929 21.3118 4.4523C21.1576 3.95054 20.8125 3.52947 20.3507 3.27994C20.0865 3.13723 19.7558 3.07109 19.0944 2.93881Z"
          stroke="#000619"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle id="Shadow" cx="22.1998" cy="22.1998" r="6.4" fill="#FCE3A3" />
        <path
          id="Vector 1282"
          d="M20.6001 18.2V22.2M22.6001 20.2H18.6001"
          stroke="#000619"
          strokeLinecap="round"
        />
        <circle
          id="Ellipse 1400"
          cx="20.5997"
          cy="20.6"
          r="6.4"
          stroke="#000619"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}

IconBankPlusNew.defaultProps = {
  w: 29,
  h: 29,
};

export default IconBankPlusNew;
