// @flow
import React, { useState, useRef, useContext } from 'react';
import { Formik } from 'formik';
import { ChakraProvider, Stack, useToast, HStack, Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { USER_WORKSPACE } from '@routes';
import { useCurrentWorkspace } from '@shared/hooks';
import useBreakPoints from '@core/hooks/useBreakPoints';
import habitatTheme from '@core/themeHabitat';
import {
  BaselaneButton,
  BaselaneDrawer,
  BaselaneBannerNew,
  TwoFactorVerificationPopUp,
  useTwoFactor,
} from '@shared/components';
import { Icon16ChevronLeft } from '@icons/16px';
import { EmailOtpProvider } from '@core/contexts/EmailOtpContext';
import UserContext from '@contexts/UserContext';
import { createWorkspaceSegmentData } from '@core/utils/sendSegmentEvent';
import { INVITE_COLLABORATOR } from '@core/apollo/queries/workspace';

import { invitationSteps } from '../../helpers/workspace.helpers';
import InviteCollaboratorDetails from './InviteCollaboratorDetails';
import InviteCollaboratorAccess from './InviteCollaboratorAccess';

type InviteCollaboratorDrawerProps = { from: String };

function InviteCollaboratorDrawer({ from }: InviteCollaboratorDrawerProps): any {
  // Workspace and User contexts
  const {
    refetchCurrentWorkspace,
    activeWorkspaceId,
    workspaceName,
    workspaceOwner = {},
  } = useCurrentWorkspace();

  const { user } = useContext(UserContext);

  // Form and state management
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const initialValues = { email: '', authorizedForBanking: '-1' };

  const [inviteCollaborator] = useMutation(INVITE_COLLABORATOR);
  const [currentStep, setCurrentStep] = useState(invitationSteps.COLLABORATOR_DETAILS);
  const [showServerError, setShowServerError] = useState(false);
  const [bodyText, setBodyText] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
  const [phoneNumberIsNotVoip, setPhoneNumberIsNotVoip] = useState(false);

  const { isMax576 } = useBreakPoints();

  // Toast configuration
  const toast = useToast();
  const showToast = (description, status) =>
    toast({
      position: 'bottom-left',
      description,
      status,
      duration: 3000,
      isClosable: true,
    });

  const { states, stateFunctions } = useTwoFactor(false, true);
  const { OTPErrorCode } = states;
  const {
    onOTPPopupOpen,
    onOTPPopupClose,
    setOTPErrorCode,
    handleSendText,
    handleVerifyOtp,
  } = stateFunctions;

  // Form validation
  const handleValidation = (values) => {
    const errors = {};
    if (!values.email) errors.email = "Please enter collaborator's email";
    if (user?.email.trim().toLowerCase() === values.email.trim().toLowerCase()) {
      errors.email = 'You cannot invite yourself as a collaborator.';
    }
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (values.authorizedForBanking === '-1') {
      errors.authorizedForBanking = 'You need to choose banking access';
    }

    if (errors.lenght > 0) setOTPErrorCode(false);
    return errors;
  };

  // Handle OTP sending and verifying
  const handleSendTextSuccess = () => {
    setOTPErrorCode(false);
    onOTPPopupOpen();
    setPhoneNumberIsNotVoip(true);
    setOtpSent(true);
  };

  const handleSendTextFail = () => {
    setShowServerError(true);
    setIsLoading(false);
    setBodyText('Error on phone confirmation. Please try again later');
  };

  const handleVerifyOtpSuccess = () => {
    setPhoneNumberVerified(true);
    if (!OTPErrorCode) handleFormSubmit(formikRef?.current?.values);
  };

  // Two-Factor OTP properties
  const twoFactorVerificationProps = {
    ...states,
    ...stateFunctions,
    getOTP: () => handleSendText(user.phoneNumber, true, handleSendTextSuccess, handleSendTextFail),
    phoneNumber: user.phoneNumber,
    handleVerifyOnClick: (otpCode) =>
      handleVerifyOtp({ recipient: user.phoneNumber, code: otpCode }, handleVerifyOtpSuccess),
  };

  // Form submission handler
  const handleFormSubmit = (values) => {
    inviteCollaborator({
      variables: {
        input: {
          email: values.email,
          authorizedForBanking: values.authorizedForBanking === '1',
        },
      },
    })
      .then((res) => {
        if (res.data) {
          showToast('Invited collaborator', 'success');
          refetchCurrentWorkspace();
          setIsLoading(false);
          handleDrawerClose();
        } else if (res.errors) {
          setBodyText(getErrorMessage(res.errors[0]?.message));
          setShowServerError(true);
          onOTPPopupClose();
          showToast('Error inviting collaborator', 'error');
          setIsLoading(false);
        }
      })
      .catch(() => {
        showToast('Error inviting collaborator', 'error');
        handleDrawerClose();
      });
  };

  // Error message helper
  const getErrorMessage = (message) => {
    if (message?.includes('as it is associated with a Tenant account.')) {
      return 'This email is registered as a tenant on Baselane and cannot be invited as a collaborator. Please go back and enter a different email.';
    }
    if (message?.includes('is already associated as a collaborator for user')) {
      return 'The email you entered has already been invited to your workspace. Please go back and enter a different email.';
    }
    return '';
  };

  // Drawer close handler
  const handleDrawerClose = () => {
    resetForm();
    navigate(USER_WORKSPACE);
    setShowServerError(false);
  };

  const resetForm = () => setCurrentStep(invitationSteps.COLLABORATOR_DETAILS);

  // Submission click handler
  const onClickSubmit = (e) => {
    setIsLoading(true);
    setShowServerError(false);
    setBodyText('');
    if (otpSent || (phoneNumberVerified && phoneNumberIsNotVoip && !OTPErrorCode)) {
      handleFormSubmit(e);
    } else {
      handleSendText(user.phoneNumber, false, handleSendTextSuccess, handleSendTextFail);
    }
  };
  return (
    <ChakraProvider theme={habitatTheme}>
      <EmailOtpProvider>
        <Formik
          innerRef={formikRef}
          validateOnChange
          validateOnBlur
          validateOnMount
          initialValues={initialValues}
          validate={handleValidation}
          onSubmit={onClickSubmit}
        >
          {(formikProps) => {
            const { dirty, values, isValid, touched, errors, handleSubmit } = formikProps;
            return (
              <BaselaneDrawer
                isOpen
                size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
                title="Invite collaborator"
                closeEvent={handleDrawerClose}
                onOverlayClick={handleDrawerClose}
                closeOnOverlayClick={false}
                newDesignDrawer
                footer={
                  currentStep === invitationSteps.COLLABORATOR_DETAILS ? (
                    <>
                      <BaselaneButton
                        size="md"
                        variant="outline"
                        palette="neutral"
                        onClick={() => handleDrawerClose({ dirty, resetForm })}
                      >
                        Cancel
                      </BaselaneButton>
                      <BaselaneButton
                        size="md"
                        variant="filled"
                        palette="primary"
                        width="100%"
                        ml={1.5}
                        flex={1}
                        onClick={() => {
                          setCurrentStep(invitationSteps.COLLABORATOR_ACCESS);
                        }}
                        isDisabled={!values?.email || (errors.email && touched.email)}
                        segmentEventName="workspace_fe_invite_collaborator_step_1_completed"
                        segmentEventData={{
                          ...createWorkspaceSegmentData(
                            workspaceOwner,
                            activeWorkspaceId,
                            workspaceName,
                            user
                          ),
                          collaborator_email: values?.email,
                        }}
                      >
                        Continue
                      </BaselaneButton>
                    </>
                  ) : (
                    <>
                      <BaselaneButton
                        size="md"
                        variant="outline"
                        palette="neutral"
                        onClick={() => {
                          setShowServerError(false);
                          setIsLoading(false);
                          setCurrentStep(invitationSteps.COLLABORATOR_DETAILS);
                        }}
                        leftIcon={<Icon16ChevronLeft />}
                      >
                        Back
                      </BaselaneButton>
                      <BaselaneButton
                        size="md"
                        variant="filled"
                        palette="primary"
                        width="100%"
                        ml={1.5}
                        flex={1}
                        onClick={handleSubmit}
                        isLoading={isLoading}
                        isDisabled={
                          !values?.authorizedForBanking ||
                          (!isValid && !touched.authorizedForBanking)
                        }
                      >
                        Invite
                      </BaselaneButton>
                    </>
                  )
                }
              >
                <Stack gap={1.5} mb={3}>
                  <HStack
                    w="100%"
                    position="absolute"
                    left="0"
                    width="100%"
                    top={!isMax576 ? '59px' : '56px'}
                  >
                    <BaselaneBannerNew
                      hasBannerIcon={!isMax576}
                      hasActionButton={false}
                      hasActionLink
                      body="Starting Q1 2025, you will be billed $5/month for each active collaborator in your workspace."
                      linkLabel="Learn more"
                      onLinkClick={() => {
                        window.open(
                          'https://support.baselane.com/hc/en-us/articles/29873891217307-How-to-Manage-Multi-User-Access-in-Your-Baselane-Workspace',
                          '_blank'
                        );
                      }}
                      hasCloseButton={false}
                      title="Free beta until Q1 2025"
                      variant="primary-medium"
                      linkActionStyles={{ whiteSpace: 'nowrap' }}
                    />
                  </HStack>
                  <Box paddingTop="126px">
                    {currentStep === invitationSteps.COLLABORATOR_DETAILS ? (
                      <InviteCollaboratorDetails />
                    ) : (
                      <InviteCollaboratorAccess
                        showServerError={showServerError}
                        bodyText={bodyText}
                      />
                    )}
                  </Box>

                  <TwoFactorVerificationPopUp {...twoFactorVerificationProps} />
                </Stack>
              </BaselaneDrawer>
            );
          }}
        </Formik>
      </EmailOtpProvider>
    </ChakraProvider>
  );
}

export default InviteCollaboratorDrawer;
