import React, { useState, useEffect } from 'react';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import T1 from '@shared/components/BaselaneDropdown-new/T1';
import { Icon24VideoPlay2 } from '@icons/24px';
import { Icon16Clear, Icon16Check } from '@icons/16px';
import { DocumentCard, DisplayInputWrapper, Placeholder } from '@shared/components';
import { emptyImageStyles } from '../../styles/tenantScreening.styles';

type DocumentDropdownContainerProps = {
  type: string,
  items: Object,
  parentIndex?: number,
  currentIndex: number,
  handleSetCurrentIndex: Function,
  isEmptyPlaceholderNeeded: boolean,
};

type OptionProps = {
  item: Object,
};

const Option = ({ item }: OptionProps) => {
  return (
    <HStack w="full">
      {item?.status && item?.status === 'failed' ? <Icon16Clear color="#ea6868" /> : null}
      {item?.status && item?.status !== 'failed' ? <Icon16Check color="#398311" /> : null}
      <Text whiteSpace="nowrap" textStyle="sm">
        {item?.name}
      </Text>
    </HStack>
  );
};

type DisplayInputProps = {
  handleContentRendererClick: Function,
  showValueByFields: Array<string>,
  selectedOptions: Array<Object>,
  title: string,
  placeholder: string,
  classNames: Array<string>,
};

const DisplayInput = ({
  handleContentRendererClick,
  selectedOptions,
  showValueByFields,
  placeholder,
  title,
  classNames,
}: DisplayInputProps) => {
  return (
    <DisplayInputWrapper
      handleContentRendererClick={handleContentRendererClick}
      classNames={classNames}
      overflow="visible"
    >
      {selectedOptions.length > 0 ? (
        <Option item={selectedOptions[0]} />
      ) : (
        <Placeholder placeholder={placeholder} title={title} />
      )}
    </DisplayInputWrapper>
  );
};

const DocumentDropdownContainer = ({
  type,
  items,
  parentIndex = 0,
  currentIndex,
  handleSetCurrentIndex,
  isEmptyPlaceholderNeeded = false,
}: DocumentDropdownContainerProps) => {
  const [hasError, setHasError] = useState(false);

  const documentExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'];
  const videoExtensions = ['mp4', 'webm'];
  // we can use this if we want to limit extensions
  // const imageExtensions = ['jpg', 'jpeg', 'png', 'webp'];

  const handleDownload = (url) => {
    window.open(url, '_blank', 'noopener');
  };

  const handleError = () => {
    setHasError(true);
  };

  // Item extension determination, documentimage or movie
  const getUrlExtension = (url) => {
    return url?.split(/[#?]/)[0]?.split('.')?.pop()?.trim();
  };

  const currentExtension = getUrlExtension(items?.[currentIndex]?.asset?.url);

  const getFallbackMessage = () => {
    if (videoExtensions.includes(currentExtension)) {
      return 'No video available';
    }
    if (documentExtensions.includes(currentExtension)) {
      return 'No document available';
    }
    return 'No image available';
  };

  const renderFallback = () => <Box {...emptyImageStyles}>{getFallbackMessage()}</Box>;

  // Reset error state when index or currentIndex changes
  useEffect(() => {
    setHasError(false);
  }, [currentIndex]);

  // Add useEffect to set initial index
  useEffect(() => {
    if (items?.length && currentIndex === undefined) {
      handleSetCurrentIndex(0);
    }
  }, [items, currentIndex, handleSetCurrentIndex]);

  if (!items?.length) {
    if (isEmptyPlaceholderNeeded) {
      return (
        <Box {...emptyImageStyles} color="brand.neutral.600">
          No data available
        </Box>
      );
    }
    return null;
  }

  const getItemTypeName = (extension) => {
    if (videoExtensions.includes(extension)) return 'Video';
    if (documentExtensions.includes(extension)) return 'Document';
    return 'Image';
  };

  const dropdownItems = items?.map((item, itemIndex) => {
    const extension = item?.asset?.extension || getUrlExtension(item?.asset?.url);
    const itemType = getItemTypeName(extension);
    return {
      id: itemIndex,
      name: `${itemType} ${itemIndex + 1}`,
      url: item?.asset?.url,
    };
  });

  // Update selectedItem to use actualCurrentIndex
  const selectedItem = {
    id: currentIndex,
    name: `${getItemTypeName(currentExtension)} ${currentIndex + 1}`,
  };

  const itemRenderer = ({ item }) => {
    return <Option item={item} />;
  };

  // Update video content rendering
  const renderVideoContent = () => (
    <Box
      id={`videoPlayer-download-click-box-${type}-${parentIndex}-${currentIndex}`}
      borderRadius="8px"
      overflow="hidden"
      w="240px"
      h="320px"
      onClick={() => handleDownload(items?.[currentIndex]?.asset?.url)}
    >
      <Box {...emptyImageStyles} position="absolute" zIndex="0" />
      <Flex {...emptyImageStyles} position="absolute" zIndex="8" backgroundColor="transparent">
        <Icon24VideoPlay2 pointerEvents="none" />
      </Flex>
      <Box
        position="relative"
        width="240px"
        height="320px"
        zIndex="5"
        backgroundColor="brand.neutral.150"
        borderRadius="8px"
        border="1px solid"
        borderColor="brand.darkBlue.200"
      >
        <video
          id={`videoPlayer-${type}-${parentIndex}-${currentIndex}`}
          style={{
            width: '240px',
            height: '320px',
            overflow: 'hidden',
            borderRadius: '8px',
            border: '1px solid',
            borderColor: '#DADFE6',
          }}
        >
          <track kind="captions" />
          <source src={items?.[currentIndex]?.asset?.url} type="video/mp4" />
          <source src={items?.[currentIndex]?.asset?.url} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </Box>
    </Box>
  );

  // Update renderContent to use new video rendering
  const renderContent = () => {
    if (!items?.[currentIndex]?.asset?.url || hasError) {
      return renderFallback();
    }

    if (videoExtensions.includes(currentExtension)) {
      return renderVideoContent();
    }

    if (documentExtensions.includes(currentExtension)) {
      return (
        <DocumentCard
          key={items?.[currentIndex]?.asset?.id}
          doc={{
            id: items?.[currentIndex]?.asset?.id,
            name: items?.[currentIndex]?.asset?.filename,
            fileExtension: items?.[currentIndex]?.asset?.extension,
            asset_url: items?.[currentIndex]?.asset?.url,
          }}
          isNewDesign
        />
      );
    }

    return (
      <Box
        as="img"
        src={items?.[currentIndex]?.asset?.url}
        alt=""
        w="100%"
        h="100%"
        objectFit="cover"
        borderRadius="8px"
        border="1px solid"
        borderColor="brand.darkBlue.200"
        cursor="pointer"
        onClick={() => handleDownload(items?.[currentIndex]?.asset?.url)}
        onError={handleError}
      />
    );
  };

  return (
    <Box mb="16px" mt="32px">
      {items?.length > 1 && (
        <Box w="200px" mb="16px" overflow="visible" position="relative" zIndex="30">
          <T1
            classNames={[
              'auto-width-dropdown',
              'is-full-width',
              'auto-height',
              'input-form-md',
              'select-auto-width',
            ]}
            hideSearch
            data={dropdownItems}
            selectedItem={selectedItem}
            itemRenderer={itemRenderer}
            CustomDisplayInput={DisplayInput}
            handleSubmit={(value) => handleSetCurrentIndex(value)}
          />
        </Box>
      )}
      <Box
        id={`${type}-pictures-container`}
        position="relative"
        zIndex="20"
        w={documentExtensions.includes(currentExtension) ? '300px' : '240px'}
        h={documentExtensions.includes(currentExtension) ? 'auto' : '320px'}
      >
        {renderContent()}
      </Box>
    </Box>
  );
};

export default DocumentDropdownContainer;
