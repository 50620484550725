import React, { useState } from 'react';
import { Box, HStack, Stack, Text, Skeleton } from '@chakra-ui/react';
import { formatPhoneNumber } from '@pages/LeasesPage/helpers/unit.helpers';
import { IconCheck, IconExclamationCircle } from '@icons';
import customTheme from '@theme';
import { errorFormFontStyles } from '@shared/styles/font.style';
import BaselaneAlert from '../BaselaneAlert';
import BaselaneButton from '../BaselaneButton';
import SingleInputComponent from './SingleInputComponent';
import { errorMessages } from './helpers/constant';
import { titleStyles } from '../../styles/popup.styles';
import { footerContentSectionStyles, popupFooterContainerStyles } from './styles/twofactor.styles';

type TwoFactorVerificationPopUpProps = {
  OTPErrorCode: any,
  setOTPErrorCode: Function,
  setHasOTPCodeResent: Function,
  isOTPPopupOpen: boolean,
  isGetOTPSuccessful: boolean,
  onOTPPopupClose: Function,
  getOTP: Function,
  handleVerifyOnClick: Function,
  phoneNumber: string,
  bankId?: String,
  isVerifyLoading: boolean,
  hideNumber?: Boolean,
  customContent?: Object,
  APIVerification: string,
  isGetOTPLoading: boolean,
};

const TwoFactorVerificationPopUp = ({
  OTPErrorCode,
  setOTPErrorCode,
  setHasOTPCodeResent,
  isOTPPopupOpen,
  isGetOTPSuccessful,
  onOTPPopupClose,
  getOTP,
  handleVerifyOnClick,
  bankId = null,
  phoneNumber = false,
  isVerifyLoading,
  hideNumber,
  customContent = {},
  APIVerification,
  isGetOTPLoading,
}: TwoFactorVerificationPopUpProps) => {
  const [otpCode, setOtpCode] = useState('');

  const formattedPhone =
    formatPhoneNumber(phoneNumber) === null ? phoneNumber : formatPhoneNumber(phoneNumber, true);

  const popupContent = (
    <Stack spacing={0.5}>
      <Box as="span">
        <Text {...titleStyles} pb={2}>
          {customContent?.title || 'Mobile Phone Verification'}
        </Text>
        {customContent?.description ? (
          <Text textStyle="sm">{customContent?.description}</Text>
        ) : (
          <Text>
            Enter the code sent to{' '}
            <Skeleton as="span" display="inline" isLoaded={!isGetOTPLoading}>
              {!hideNumber ? (
                <Box as="span" fontWeight="medium">
                  {bankId ? `(XXX) XXX-${APIVerification}` : formattedPhone}{' '}
                </Box>
              ) : (
                <Box as="span">your registered phone number</Box>
              )}
            </Skeleton>
            .
          </Text>
        )}

        <SingleInputComponent
          isDisabled={OTPErrorCode === 90}
          onChangeEvent={(code) => {
            if (OTPErrorCode) setOTPErrorCode(false);

            setOtpCode(code);

            if (code.length !== 6) {
              setOTPErrorCode('invalid_length');
            }
          }}
          onKeyDownEnter={() => {
            if (otpCode !== undefined && otpCode?.length === 6) {
              // Only verify if there are no errors and OTP length is correct
              handleVerifyOnClick(otpCode);
            }
          }}
        />
      </Box>
      {OTPErrorCode && errorMessages[OTPErrorCode] && (
        <HStack>
          <IconExclamationCircle w="13" h="13" color={customTheme.colors.red['800AA']} />
          <Text {...errorFormFontStyles}>{errorMessages[OTPErrorCode]}</Text>
        </HStack>
      )}
    </Stack>
  );

  const onPopUpClose = () => {
    if (OTPErrorCode) setOTPErrorCode(false);
    onOTPPopupClose();
  };

  const popupFooter = (
    <Stack direction="row" {...popupFooterContainerStyles}>
      <Stack direction="row" {...footerContentSectionStyles}>
        <BaselaneButton
          id="resend-code-button"
          variant="transparent"
          palette="primary"
          size="sm"
          pullLeft
          isDisabled={OTPErrorCode === 90}
          onClick={() => {
            // unit otp call
            if (bankId) {
              getOTP({ variables: { bankId: Number(bankId) } })
                .then(({ data }) => {
                  if (data.unitAPIVerification) {
                    setHasOTPCodeResent(true);
                  }
                })
                .catch(() => setOTPErrorCode(200));
            } else {
              // other otp function call
              getOTP();
            }
          }}
        >
          Resend Code
        </BaselaneButton>
        {isGetOTPSuccessful && <IconCheck color={customTheme.colors.green['800AA']} />}
      </Stack>
      <Stack direction="row" spacing={2} {...footerContentSectionStyles}>
        <BaselaneButton variant="outline" palette="neutral" size="md" onClick={onPopUpClose}>
          Cancel
        </BaselaneButton>
        <BaselaneButton
          id="verify-otp-button"
          isDisabled={otpCode === undefined || otpCode?.length < 6 || OTPErrorCode === 90}
          isLoading={isVerifyLoading}
          variant="filled"
          palette="primary"
          size="md"
          onClick={() => handleVerifyOnClick(otpCode)}
        >
          Verify
        </BaselaneButton>
      </Stack>
    </Stack>
  );

  return (
    <BaselaneAlert
      showCloseButton
      isOpen={isOTPPopupOpen}
      onClose={onPopUpClose}
      isCentered
      body={popupContent}
      footer={popupFooter}
      size="xl"
      containerStyles={{ p: '32px' }}
      footerStyles={{ m: '8px 0' }}
    />
  );
};

export default TwoFactorVerificationPopUp;
