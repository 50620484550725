import { create } from 'zustand';

const useLeaseAgreementStore = create((set) => ({
  leaseAgreementId: null,
  setLeaseAgreementId: (newValue) => set({ leaseAgreementId: newValue }),
  leaseAgreementState: null,
  setLeaseAgreementState: (newValue) => set({ leaseAgreementState: newValue }),
  leaseAgreementPaymentStatus: null,
  setLeaseAgreementPaymentStatus: (newValue) => set({ leaseAgreementPaymentStatus: newValue }),
  completedTags: [],
  setCompletedTags: (newValue) => set({ completedTags: newValue }),
  totalSteps: 5,
  tabIndex: 0,
  setTabIndex: (newValue) => set({ tabIndex: newValue }),
  selectedPropertyObject: {},
  setSelectedPropertyObject: (newValue) => set({ selectedPropertyObject: newValue }),
  isPropertyPanelVisible: true,
  setIsPropertyPanelVisible: (newValue) => set({ isPropertyPanelVisible: newValue }),
  isUnitPanelVisible: false,
  setIsUnitPanelVisible: (newValue) => set({ isUnitPanelVisible: newValue }),
  isTenantPanelVisible: false,
  setIsTenantPanelVisible: (newValue) => set({ isTenantPanelVisible: newValue }),
  isLeaseTermPanelVisible: false,
  setIsLeaseTermPanelVisible: (newValue) => set({ isLeaseTermPanelVisible: newValue }),
  isReviewLeasePanelVisible: false,
  setIsReviewLeasePanelVisible: (newValue) => set({ isReviewLeasePanelVisible: newValue }),
  isDraftLeasePanelVisible: false,
  setIsDraftLeasePanelVisible: (newValue) => set({ isDraftLeasePanelVisible: newValue }),
  isPropertyStepValid: false,
  setIsPropertyStepValid: (newValue) => set({ isPropertyStepValid: newValue }),
  isUnitStepValid: false,
  setIsUnitStepValid: (newValue) => set({ isUnitStepValid: newValue }),
  isTenantStepValid: false,
  setIsTenantStepValid: (newValue) => set({ isTenantStepValid: newValue }),
  isLeaseTermStepValid: false,
  setIsLeaseTermStepValid: (newValue) => set({ isLeaseTermStepValid: newValue }),
  isReviewLeaseStepValid: false,
  setIsReviewLeaseStepValid: (newValue) => set({ isReviewLeaseStepValid: newValue }),
  isDraftLeaseStepValid: false,
  setIsDraftLeaseStepValid: (newValue) => set({ isDraftLeaseStepValid: newValue }),
  isAddingNewPropertyPanelVisible: false,
  setIsAddingNewPropertyPanelVisible: (newValue) =>
    set({ isAddingNewPropertyPanelVisible: newValue }),
  hasProperties: false,
  setHasProperties: (newValue) => set({ hasProperties: newValue }),
  isNewTenant: false,
  setIsNewTenant: (newValue) => set({ isNewTenant: newValue }),
  existingTenantsEmails: [],
  setExistingTenantsEmails: (newValue) => set({ existingTenants: newValue }),
  hostedPageId: null,
  setHostedPageId: (newValue) => set({ hostedPageId: newValue }),
  showServerTenantMessage: false,
  setShowServerTenantMessage: (newValue) => set({ showServerTenantMessage: newValue }),
}));

export const useLeaseAgreementId = () => useLeaseAgreementStore((state) => state.leaseAgreementId);
export const useSetLeaseAgreementId = () =>
  useLeaseAgreementStore((state) => state.setLeaseAgreementId);

export const useTabIndex = () => useLeaseAgreementStore((state) => state.tabIndex);
export const useSetTabIndex = () => useLeaseAgreementStore((state) => state.setTabIndex);
export const useTotalSteps = () => useLeaseAgreementStore((state) => state.totalSteps);
export const useSelectedPropertyObject = () =>
  useLeaseAgreementStore((state) => state.selectedPropertyObject);
export const useSetSelectedPropertyObject = () =>
  useLeaseAgreementStore((state) => state.setSelectedPropertyObject);
export const useIsUnitPanelVisible = () =>
  useLeaseAgreementStore((state) => state.isUnitPanelVisible);
export const useSetIsUnitPanelVisible = () =>
  useLeaseAgreementStore((state) => state.setIsUnitPanelVisible);
export const useIsPropertyPanelVisible = () =>
  useLeaseAgreementStore((state) => state.isPropertyPanelVisible);
export const useSetIsPropertyPanelVisible = () =>
  useLeaseAgreementStore((state) => state.setIsPropertyPanelVisible);
export const useIsTenantPanelVisible = () =>
  useLeaseAgreementStore((state) => state.isTenantPanelVisible);
export const useSetIsTenantPanelVisible = () =>
  useLeaseAgreementStore((state) => state.setIsTenantPanelVisible);
export const useIsLeaseTermPanelVisible = () =>
  useLeaseAgreementStore((state) => state.isLeaseTermPanelVisible);
export const useSetIsLeaseTermPanelVisible = () =>
  useLeaseAgreementStore((state) => state.setIsLeaseTermPanelVisible);
export const useIsReviewLeasePanelVisible = () =>
  useLeaseAgreementStore((state) => state.isReviewLeasePanelVisible);
export const useSetIsReviewLeasePanelVisible = () =>
  useLeaseAgreementStore((state) => state.setIsReviewLeasePanelVisible);
export const useIsDraftLeasePanelVisible = () =>
  useLeaseAgreementStore((state) => state.isDraftLeasePanelVisible);
export const useSetIsDraftLeasePanelVisible = () =>
  useLeaseAgreementStore((state) => state.setIsDraftLeasePanelVisible);
export const useIsPropertyStepValid = () =>
  useLeaseAgreementStore((state) => state.isPropertyStepValid);
export const useSetIsPropertyStepValid = () =>
  useLeaseAgreementStore((state) => state.setIsPropertyStepValid);
export const useIsUnitStepValid = () => useLeaseAgreementStore((state) => state.isUnitStepValid);
export const useSetIsUnitStepValid = () =>
  useLeaseAgreementStore((state) => state.setIsUnitStepValid);
export const useIsTenantStepValid = () =>
  useLeaseAgreementStore((state) => state.isTenantStepValid);
export const useSetIsTenantStepValid = () =>
  useLeaseAgreementStore((state) => state.setIsTenantStepValid);
export const useIsLeaseTermStepValid = () =>
  useLeaseAgreementStore((state) => state.isLeaseTermStepValid);
export const useSetIsLeaseTermStepValid = () =>
  useLeaseAgreementStore((state) => state.setIsLeaseTermStepValid);
export const useIsReviewLeaseStepValid = () =>
  useLeaseAgreementStore((state) => state.isReviewLeaseStepValid);
export const useSetIsReviewLeaseStepValid = () =>
  useLeaseAgreementStore((state) => state.setIsReviewLeaseStepValid);
export const useIsDraftLeaseStepValid = () =>
  useLeaseAgreementStore((state) => state.isDraftLeaseStepValid);
export const useSetIsDraftLeaseStepValid = () =>
  useLeaseAgreementStore((state) => state.setIsDraftLeaseStepValid);
export const useIsAddingNewPropertyPanelVisible = () =>
  useLeaseAgreementStore((state) => state.isAddingNewPropertyPanelVisible);
export const useSetIsAddingNewPropertyPanelVisible = () =>
  useLeaseAgreementStore((state) => state.setIsAddingNewPropertyPanelVisible);
export const useHasProperties = () => useLeaseAgreementStore((state) => state.hasProperties);
export const useSetHasProperties = () => useLeaseAgreementStore((state) => state.setHasProperties);
export const useIsNewTenant = () => useLeaseAgreementStore((state) => state.isNewTenant);
export const useSetIsNewTenant = () => useLeaseAgreementStore((state) => state.setIsNewTenant);
export const useLeaseAgreementState = () =>
  useLeaseAgreementStore((state) => state.leaseAgreementState);
export const useSetLeaseAgreementState = () =>
  useLeaseAgreementStore((state) => state.setLeaseAgreementState);
export const useLeaseAgreementPaymentStatus = () =>
  useLeaseAgreementStore((state) => state.leaseAgreementPaymentStatus);
export const useSetLeaseAgreementPaymentStatus = () =>
  useLeaseAgreementStore((state) => state.setLeaseAgreementPaymentStatus);
export const useCompletedTags = () => useLeaseAgreementStore((state) => state.completedTags);
export const useSetCompletedTags = () => useLeaseAgreementStore((state) => state.setCompletedTags);
export const useExistingTenantsEmails = () =>
  useLeaseAgreementStore((state) => state.existingTenantsEmails);
export const useSetExistingTenantsEmails = () =>
  useLeaseAgreementStore((state) => state.setExistingTenantsEmails);
export const useHostedPageId = () => useLeaseAgreementStore((state) => state.hostedPageId);
export const useSetHostedPageId = () => useLeaseAgreementStore((state) => state.setHostedPageId);
export const useShowServerTenantMessage = () =>
  useLeaseAgreementStore((state) => state.showServerTenantMessage);
export const useSetShowServerTenantMessage = () =>
  useLeaseAgreementStore((state) => state.setShowServerTenantMessage);
export default useLeaseAgreementStore;

export const resetStore = () => {
  useLeaseAgreementStore.getState().setLeaseAgreementId(null);
  useLeaseAgreementStore.getState().setLeaseAgreementState(null);
  useLeaseAgreementStore.getState().setLeaseAgreementPaymentStatus(null);
  useLeaseAgreementStore.getState().setCompletedTags([]);
  useLeaseAgreementStore.getState().setHostedPageId(null);
  useLeaseAgreementStore.getState().setSelectedPropertyObject({});
  useLeaseAgreementStore.getState().setIsPropertyPanelVisible(true);
  useLeaseAgreementStore.getState().setIsUnitPanelVisible(false);
  useLeaseAgreementStore.getState().setIsTenantPanelVisible(false);
  useLeaseAgreementStore.getState().setIsLeaseTermPanelVisible(false);
  useLeaseAgreementStore.getState().setIsReviewLeasePanelVisible(false);
  useLeaseAgreementStore.getState().setIsDraftLeasePanelVisible(false);
  useLeaseAgreementStore.getState().setIsPropertyStepValid(false);
  useLeaseAgreementStore.getState().setIsUnitStepValid(false);
  useLeaseAgreementStore.getState().setIsTenantStepValid(false);
  useLeaseAgreementStore.getState().setIsLeaseTermStepValid(false);
  useLeaseAgreementStore.getState().setIsReviewLeaseStepValid(false);
  useLeaseAgreementStore.getState().setIsDraftLeaseStepValid(false);
  useLeaseAgreementStore.getState().setIsAddingNewPropertyPanelVisible(false);
  useLeaseAgreementStore.getState().setHasProperties(false);
  useLeaseAgreementStore.getState().setIsNewTenant(false);
  useLeaseAgreementStore.getState().setExistingTenantsEmails([]);
  useLeaseAgreementStore.getState().setTabIndex(0);
  useLeaseAgreementStore.getState().setShowServerTenantMessage(false);
};
