import React, { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import T1 from '@shared/components/BaselaneDropdown-new/T1';
import TenantScreeningReportDisplayInput from './TenantScreeningReportDisplayInput';
import TenantScreeningReportOption from './TenantScreeningReportOption';
import { REPORT_TYPE_ROUTE } from '../helpers/tenantScreeningDetail.helpers';

type ApplicantDropdownProps = {
  id: string,
  reportOptions: Array,
  selectedReport: Object,
  verifications: Array,
};

const ReportDropdown = ({
  id = 'report-dropdown',
  reportOptions,
  selectedReport,
  verifications,
  ...rest
}: ApplicantDropdownProps): ReactNode => {
  const { isMin768, isLargerThan1280 } = useBreakPoints();

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const reportItemRenderer = ({ item }) => {
    return <TenantScreeningReportOption reportItem={item} />;
  };

  const handleReportSelect = (reportKey) => {
    // grab current url, without report name at the end
    let pathnamePrefix = pathname.split('/');
    pathnamePrefix.pop();
    pathnamePrefix = pathnamePrefix.join('/');

    // append new selected report id to it
    const selectedReportPage = `${pathnamePrefix}/${REPORT_TYPE_ROUTE[reportKey]}`;

    navigate({
      pathname: selectedReportPage,
    });
  };

  return isLargerThan1280 ? (
    <Box
      position="sticky"
      top="0"
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      {reportOptions.map((report) => (
        <Box
          key={uuidv4()}
          fontSize="sm"
          p={1}
          lineHeight="24px"
          borderRadius="6px"
          id={report?.id}
          onClick={() => handleReportSelect(report?.id)}
          backgroundColor={report?.id === selectedReport?.id ? 'brand.blue.100' : 'transparent'}
          _hover={{ backgroundColor: 'brand.blue.100', cursor: 'pointer' }}
        >
          {report.name}
        </Box>
      ))}
    </Box>
  ) : (
    <Box
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      <T1
        id={id}
        classNames={['new', 'auto-width-dropdown', 'is-full-width', 'auto-height', 'input-form-md']}
        data={reportOptions}
        itemRenderer={reportItemRenderer}
        handleSubmit={(value) => handleReportSelect(value)}
        hideSearch
        showValueByFields={['name', 'type']}
        selectedItem={selectedReport}
        CustomDisplayInput={TenantScreeningReportDisplayInput}
        isMobile={!isMin768}
        {...rest}
      />
    </Box>
  );
};

export default ReportDropdown;
