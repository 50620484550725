export const PayeeType = {
  INDIVIDUAL: 'INDIVIDUAL',
  BUSINESS: 'BUSINESS',
};

export const AccountType = {
  NOT_SURE: 'not_sure',
  CHECKING: 'checking',
  SAVINGS: 'savings',
  LOAN: 'loan',
};

export const PayeePaymentMethodType = {
  ACH: 'ACH',
  WIRE_TRANSFER: 'WIRE_TRANSFER',
  CHECK_PAYMENT: 'CHECK_PAYMENT',
};

export const PayeePaymentMethodKey = {
  ACH: 'achPaymentMethods',
  WIRE_TRANSFER: 'wirePaymentMethods',
  CHECK_PAYMENT: 'checkPaymentMethods',
};
