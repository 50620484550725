import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Link,
  Text,
} from '@chakra-ui/react';

import { useStatsigClient } from '@statsig/react-bindings';
import useBreakPoints from '@core/hooks/useBreakPoints';
import TierInfo from '@core/components/NativeBankingPage/Summary/TierInfo';
import formatCurrency from '@core/utils/formatCurrency';
import SlLoader from '@core/components/Loader';
import { BaselaneAlertNew } from '@shared/components';
import { Icon16Info } from '@icons/16px';
import { ADD_BASELANE_BANK_ACCOUNT, NB_ACCOUNTS } from '@routes';
import UserAccessContext from '@contexts/UserAccessContext';
import BanksContext from '@contexts/BanksContext';
import { DYNAMIC_CONFIGS } from '@core/constants/statsigKeys';
import SetUpRentCollectionBanner from './components/SetUpRentCollectionBanner';
import MonthlyOverview from './components/MonthlyOverview';
import AddFunds from './AddFunds';
import LearnMorePromoAndEmptyStateBanner from './LearnMorePromoAndEmptyStateBanner';

import { learnMoreModalStyles } from './styles/learnMoreModal.styles';
import { getApyLearnMoreConfig } from './helpers/learnMoreTierConfig.helpers';
import { getOverviewData } from './helpers/learnMoreActivityConfig.helpers';
import { doesUserHaveSavingsAccount } from './helpers/util.helpers';

type LearnMoreModalProps = {
  tiersData: Array<Object>,
  loading: Boolean,
  baselaneBankBalance: Number,
  onCloseBtnClick: Function,
  isRentCollectionStarted: Boolean,
  hasCollectedRentWithinNeededPeriod: Boolean,
  showLearnMoreModal: Boolean,
  isUserOnPromo: Boolean,
  isEmptyState: Boolean,
  isLargerThan576: Boolean,
  lastMonthActivity: Object,
  isUserInLastMonthOfPromo: Boolean,
  userPromoApy: String,
  isUserEligibleForPromo: String,
  currentApy: String,
  isUserOnMaxTier: Boolean,
  userPromoEndDate: String,
};

const LearnMoreModal = ({
  tiersData,
  loading,
  baselaneBankBalance,
  onCloseBtnClick,
  isRentCollectionStarted,
  hasCollectedRentWithinNeededPeriod,
  showLearnMoreModal,
  isUserOnPromo,
  isEmptyState,
  isLargerThan576,
  lastMonthActivity,
  isUserInLastMonthOfPromo,
  userPromoApy,
  isUserEligibleForPromo,
  currentApy,
  isUserOnMaxTier,
  userPromoEndDate,
}: LearnMoreModalProps) => {
  const { banks } = useContext(BanksContext);

  const { getDynamicConfig } = useStatsigClient();
  const config = getDynamicConfig(DYNAMIC_CONFIGS.APY_INTEREST);

  const navigate = useNavigate();

  const { isMinXL } = useBreakPoints();

  const hasSavingsAccount = doesUserHaveSavingsAccount(banks);

  // https://www.figma.com/file/hXwyzjPmAwKAncs7qYsINL/Sandbox?node-id=15879%3A322632&mode=dev
  const [apyLearnMoreConfig, setApyLearnMoreConfig] = useState(
    getApyLearnMoreConfig({
      tiersData,
      baselaneBankBalance,
      hasCollectedRentWithinNeededPeriod,
      isUserOnPromo,
      isEmptyState,
      isUserInLastMonthOfPromo,
      isMinXL,
    })
  );

  const activeConfig = apyLearnMoreConfig?.find((c) => c.isActive);
  const activeConfigIndex = apyLearnMoreConfig?.findIndex((c) => c.isActive);
  const { minValue: nextTierMinValue = 0 } = apyLearnMoreConfig[activeConfigIndex + 1] ?? {};

  const [overViewData, setOverViewData] = useState(
    getOverviewData({
      isMinXL,
      baselaneBankBalance,
      hasCollectedRentWithinNeededPeriod,
      activeConfig,
      lastMonthActivity,
      currentApy,
      tiersData,
      isUserOnPromo,
      userPromoApy,
      isUserInLastMonthOfPromo,
    })
  );

  // Update tier config as balance updates or if user receives rent payment with baselane bank
  useEffect(() => {
    if (tiersData && tiersData?.length > 0) {
      setApyLearnMoreConfig(
        getApyLearnMoreConfig({
          tiersData,
          baselaneBankBalance,
          hasCollectedRentWithinNeededPeriod,
          isUserOnPromo,
          isEmptyState,
          isUserInLastMonthOfPromo,
          isMinXL,
        })
      );
    }
  }, [
    baselaneBankBalance,
    hasCollectedRentWithinNeededPeriod,
    isUserOnPromo,
    tiersData,
    isEmptyState,
    isUserInLastMonthOfPromo,
    isMinXL,
  ]);

  // update current and last month's activity summaries
  useEffect(() => {
    if (tiersData && tiersData.length > 0) {
      setOverViewData(
        getOverviewData({
          isMinXL,
          baselaneBankBalance,
          hasCollectedRentWithinNeededPeriod,
          activeConfig,
          lastMonthActivity,
          currentApy,
          tiersData,
          isUserOnPromo,
          userPromoApy,
          isUserInLastMonthOfPromo,
        })
      );
    }
  }, [
    baselaneBankBalance,
    hasCollectedRentWithinNeededPeriod,
    tiersData,
    apyLearnMoreConfig,
    lastMonthActivity,
    currentApy,
    isUserOnPromo,
    userPromoApy,
    isMinXL,
    isUserInLastMonthOfPromo,
  ]);

  const { modal, content } = learnMoreModalStyles ?? {};
  const modalTitle = isMinXL ? modal.mobileTitle : modal.title;
  const { authorizedForBanking } = useContext(UserAccessContext);
  return (
    <Modal
      isCentered
      isOpen={showLearnMoreModal}
      onClose={onCloseBtnClick}
      padding="0"
      {...modal.body}
    >
      <ModalOverlay />
      <ModalContent {...modal.content}>
        <ModalHeader {...modalTitle}>Save more, earn more</ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={0}>
          {!isEmptyState && !isUserOnPromo && (
            <Text textStyle="sm" mt={1} mb={2.5}>
              Earn up to {config?.get('apy') ?? '--'}% APY on your savings accounts.
            </Text>
          )}

          {(isEmptyState || isUserOnPromo) && (
            <LearnMorePromoAndEmptyStateBanner
              {...{
                isUserOnPromo,
                isEmptyState,
                userPromoApy,
                isUserEligibleForPromo,
                userPromoEndDate,
              }}
            />
          )}
          {!isEmptyState && !hasSavingsAccount && (
            <BaselaneAlertNew
              isVertical
              visual="icon"
              iconName={Icon16Info}
              variant="primary"
              title="Add a savings account to earn APY"
              body="APY is calculated based on the total balance across all accounts, but only savings accounts earn APY."
              actionProps={
                authorizedForBanking
                  ? {
                      primary: {
                        label: 'Add savings account',
                        onClick: () => {
                          onCloseBtnClick();
                          navigate({ pathname: `${NB_ACCOUNTS}/${ADD_BASELANE_BANK_ACCOUNT}` });
                        },
                      },
                    }
                  : {}
              }
            />
          )}

          {/* Current Month's activity */}
          {!isEmptyState && <MonthlyOverview content={overViewData.currentMonthActivity} />}

          {/* Add Funds banner */}
          {!isEmptyState && !isUserOnPromo && !isUserOnMaxTier && (
            <AddFunds
              {...{
                onCloseBtnClick,
                amount: formatCurrency(nextTierMinValue - baselaneBankBalance).inDollars,
                baselaneBankBalance,
                hasCollectedRentWithinNeededPeriod,
                currentApy,
                activeConfig,
              }}
            />
          )}

          {loading ? (
            <SlLoader />
          ) : (
            <TierInfo
              {...{
                apyLearnMoreConfig,
                isEmptyState,
                isUserOnPromo,
                isUserInLastMonthOfPromo,
                userPromoEndDate,
              }}
            />
          )}

          {/* RC banner */}
          {!isRentCollectionStarted &&
            !isUserOnPromo &&
            !isEmptyState &&
            !isUserOnMaxTier &&
            isLargerThan576 && (
              <SetUpRentCollectionBanner
                {...{
                  isRentCollectionStarted,
                  hasCollectedRentWithinNeededPeriod,
                  activeConfig,
                }}
              />
            )}

          {/* Last Month's activity - show starting second month of promo */}
          {lastMonthActivity && <MonthlyOverview content={overViewData.lastMonthActivity} />}

          {!isEmptyState && (
            <>
              <Box {...content.disclaimer}>
                *Receive higher APY if you have collected rent into a Baselane Banking account via
                Baselane&apos;s Rent Collection tool in last 3 months (not applicable if you&apos;re
                already earning the maximum APY)
              </Box>
              <Box {...content.disclaimer}>
                &dagger;Next month&apos;s APY will be determined by the current month&apos;s ending
                balance and rent collection activity.{' '}
                <Link
                  href="https://support.baselane.com/hc/en-us/articles/25483536991003-What-is-the-APY-and-interest-rate-on-my-account-How-is-it-determined-and-calculated"
                  isExternal
                >
                  <u>Read more details here</u>
                </Link>
              </Box>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LearnMoreModal;
