import React, { useState, useRef, useEffect, useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import {
  BaselaneDivider,
  AgreeToTerms,
  BaselaneDrawer,
  BaselaneButton,
  BaselaneAlertNew,
  LimitedAccessTooltip,
} from '@shared/components';
import BanksContext from '@contexts/BanksContext';
import { Icon16ChevronLeft, Icon16Info } from '@icons/16px';
import { CREATE_BASELANE_SUBACCOUNT } from '@core/components/NativeBankingPage/queries';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import UserAccessContext from '@contexts/UserAccessContext';
import { useUnitOtp } from '@core/contexts/UnitOtpContext';
import { GET_BANK_ACCOUNTS } from '@core/pages/AutoTaggingRulesPage/queries/condition-queries';
import AccountFieldName from './AccountFieldName';
import AccountAutoTag from './AccountAutoTag';

type CreateAccountProps = {
  backEvent: Function,
  successEvent: Function,
  setIsDirty: Function,
  accountOption: 'checking' | 'savings',
  selectedBankAccount: Object | undefined,
};

const CreateAccount = ({
  backEvent,
  successEvent,
  setIsDirty,
  accountOption,
  selectedBankAccount,
}: CreateAccountProps) => {
  const { account, handleCreateSuccess, refetch } = useOutletContext() ?? {};
  const { id, plaidItemId: itemId, bankId } = account ?? selectedBankAccount ?? {};
  const { authorizedForBanking } = useContext(UserAccessContext);
  const { refetchBankSummary } = useContext(BanksContext);

  const [createBaselaneSubAccount] = useMutation(CREATE_BASELANE_SUBACCOUNT, {
    refetchQueries: [GET_BANK_ACCOUNTS],
  });

  const formikRef = useRef(null);
  const { isMax576 } = useBreakPoints();

  const [termsChecked, setTermsChecked] = useState(false);
  const [hasSubmissionError, setSubmissionError] = useState(false);
  const [xIdempotencyKey, setXIdempotencyKey] = useState(uuidv4());
  const { DrawerBody, DrawerFooter } = BaselaneDrawer;

  const initialAccountValues = {
    plaidItemId: itemId,
    parentAccountId: Number(id),
    nickName: null,
    accountSubType: accountOption,
    autoTag: {
      enabled: false,
      propertyId: null,
      propertyUnitId: null,
    },
  };

  // Unit OTP
  const { verifyUnitOtp, forceVerifyUnitOtp, isOtpRequired } = useUnitOtp();

  const handleValidation = (values) => {
    const errors = {};

    if (!values.nickName || values.nickName === '') {
      errors.nickName = 'Please enter a nickname.';
    }

    if (values.autoTag.enabled === true && !values.autoTag.propertyId) {
      errors.autoTag = 'Please select a property.';
    }

    return errors;
  };

  const handleSubmit = (values, setSubmitting) => {
    setSubmitting(true);
    verifyUnitOtp(bankId)
      .then(() => handleCreateBaselaneSubAccount(values, setSubmitting))
      .catch(() => setSubmitting(false));
  };

  /**
   * Work-around to allow dirty state to be set for parent from
   * within the Formik context below.
   */
  const handleDirty = (dirty) => {
    setTimeout(() => setIsDirty(dirty), 0);
  };

  const handleCreateBaselaneSubAccount = (values, setSubmitting) => {
    setSubmissionError(false);
    createBaselaneSubAccount({
      context: {
        headers: {
          'x-idempotency-key': xIdempotencyKey,
        },
      },
      variables: values,
    })
      .then((response) => {
        setSubmitting(false);
        if (response.errors && response.errors?.length) {
          if (isOtpRequired(response.errors)) {
            setSubmitting(true);
            forceVerifyUnitOtp(bankId)
              .then(() => handleCreateBaselaneSubAccount(values, setSubmitting))
              .catch(() => setSubmitting(false));
          } else {
            setSubmissionError(true);
            setSubmitting(false);
          }
        } else {
          setSubmissionError(false);
          sendSegmentEvent('baselane_banking_add_virtual_account_flow_success', {
            type: values.accountSubType,
          });
          successEvent(response.data?.createBaselaneSubAccount?.id);
          refetchBankSummary();
          if (refetch) refetch();
          if (handleCreateSuccess) handleCreateSuccess();
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setSubmissionError(true);
      });
  };

  useEffect(() => {
    setXIdempotencyKey(uuidv4());
  }, []);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={initialAccountValues}
      validate={(values) => handleValidation(values)}
      onSubmit={(values, actions) => handleCreateBaselaneSubAccount(values, actions.setSubmitting)}
    >
      {({ dirty, values, isValid, isSubmitting, setSubmitting }) => {
        handleDirty(dirty);

        return (
          <>
            <DrawerBody p={isMax576 ? 2 : 3} display="flex" flexDirection="column">
              {hasSubmissionError && (
                <Box mb={3}>
                  <BaselaneAlertNew
                    variant="danger"
                    title="Failed to create account"
                    body="Please try again, or contact support if the issue persists."
                    visual="icon"
                    iconName={Icon16Info}
                  />
                </Box>
              )}

              <AccountFieldName />
              <BaselaneDivider />
              <AccountAutoTag />
              <Box mt="auto">
                <AgreeToTerms
                  onChange={() => setTermsChecked(!termsChecked)}
                  isChecked={termsChecked}
                  isSavings={accountOption === 'savings'}
                />
              </Box>
            </DrawerBody>

            <DrawerFooter
              px={isMax576 ? 2 : 3}
              py={2}
              boxShadow="none"
              borderTop="1px solid"
              borderColor="brand.darkBlue.200"
            >
              <BaselaneButton
                size="md"
                variant="outline"
                palette="neutral"
                leftIcon={<Icon16ChevronLeft />}
                onClick={() => backEvent()}
              >
                Back
              </BaselaneButton>
              {!authorizedForBanking ? (
                <LimitedAccessTooltip>
                  <BaselaneButton
                    id="create-sub-account-button"
                    size="md"
                    ml="1.5"
                    isFullWidth
                    variant="filled"
                    palette="primary"
                    isDisabled={!isValid || !dirty || !termsChecked || !authorizedForBanking}
                    isLoading={isSubmitting}
                    onClick={() => handleSubmit(values, setSubmitting)}
                  >
                    Create account
                  </BaselaneButton>
                </LimitedAccessTooltip>
              ) : (
                <BaselaneButton
                  id="create-sub-account-button"
                  size="md"
                  ml="1.5"
                  isFullWidth
                  variant="filled"
                  palette="primary"
                  isDisabled={!isValid || !dirty || !termsChecked}
                  isLoading={isSubmitting}
                  onClick={() => handleSubmit(values, setSubmitting)}
                >
                  Create account
                </BaselaneButton>
              )}
            </DrawerFooter>
          </>
        );
      }}
    </Formik>
  );
};

export default CreateAccount;
