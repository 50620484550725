/* eslint-disable no-underscore-dangle */
import React, { useRef, useState, useContext } from 'react';
import { useOutletContext, useNavigate, useParams, generatePath } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';
import { BaselaneDrawer } from '@shared/components';
import BanksContext from '@contexts/BanksContext';
import { CREATE_BASELANE_SUBACCOUNT } from '@core/components/NativeBankingPage/queries';
import onDrawerClose from '@core/utils/onDrawerClose';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { useUnitOtp } from '@core/contexts/UnitOtpContext';
import VirtualAccountCreatedScreen from './SubAccountCreatedScreen';
import UnsavedChangesAlert from '../UnsavedChangesAlert';
import AddVirtualDrawerBody from './Body';

type AddVirtualAccountDrawerProps = {
  from?: String,
};

const AddVirtualAccountDrawer = ({ from }: AddVirtualAccountDrawerProps) => {
  const { isMax576 } = useBreakPoints();
  const formikRef = useRef(null);
  const navigate = useNavigate();
  const params = useParams();

  const { account, handleCreateSuccess } = useOutletContext() ?? {};

  const { id, plaidItemId: itemId, bankId } = account ?? {};
  const parentAccountId = Number(id);

  const { refetchBankSummary } = useContext(BanksContext);

  // Alert State
  const { isOpen: isDrawerAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  // Mutations
  const [createBaselaneSubAccount] = useMutation(CREATE_BASELANE_SUBACCOUNT);

  // Unit OTP
  const { verifyUnitOtp, forceVerifyUnitOtp, isOtpRequired, ignore } = useUnitOtp();

  // State variables
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showSubAccountCreatedSuccessScreen, setShowSubAccountCreatedSuccessScreen] = useState(
    false
  );
  const [showErrorBanner, setShowErrorBanner] = useState(false);

  // Local variables
  const initialAccountValues = { name: '', autoTag: { enabled: false } };

  const title = 'Add Virtual Account';

  const onClose = () => {
    if (from) {
      const redirectUrl = generatePath(from, params);
      onDrawerClose(navigate, redirectUrl);
    } else {
      onDrawerClose(navigate, null);
    }
  };

  const cleanup = () => {
    if (isDirty) setIsDirty(false);
    if (isFormValid) setIsFormValid(false);
    setIsSubmitting(false);
    setShowErrorBanner(false);
    if (showSubAccountCreatedSuccessScreen) {
      setShowSubAccountCreatedSuccessScreen(false);
    }
  };

  const handleFormUpdate = (inputName, values, dirty) => {
    setIsDirty(dirty);
    setIsFormValid(formikRef?.current?.isValid);
    if (showErrorBanner) {
      setShowErrorBanner(false);
    }
  };

  const handleCreateBaselaneSubAccount = (values) => {
    const variables = {
      ...values,
      accountSubType: 'checking',
    };

    createBaselaneSubAccount({ variables }).then(({ errors }) => {
      if (errors && errors.length > 0) {
        if (isOtpRequired(errors)) {
          forceVerifyUnitOtp(bankId)
            .then(() => handleCreateBaselaneSubAccount(values))
            .catch(ignore);
          setIsSubmitting(false);
          return;
        }
        setShowErrorBanner(true);
        setIsSubmitting(false);
      } else {
        cleanup();
        setShowSubAccountCreatedSuccessScreen(true);
        if (handleCreateSuccess) handleCreateSuccess();
      }
    });
  };

  const handleOnSubmit = (values) => {
    const { name: nickName, autoTag } = values;

    if (autoTag !== null) {
      if (!autoTag.enabled) {
        autoTag.propertyId = null;
        autoTag.propertyUnitId = null;
      }
    }

    const valuesToSave = { nickName, autoTag, parentAccountId, plaidItemId: itemId };

    verifyUnitOtp(bankId)
      .then(() => handleCreateBaselaneSubAccount(valuesToSave))
      .catch(ignore);
  };

  const handleValidation = (values) => {
    const errors = {};
    if (values.name === '') errors.name = 'Please enter a nickname.';

    return errors;
  };

  const onCloseWithoutSaving = () => {
    if (isDirty) {
      onAlertOpen();
    } else {
      cleanup();
      refetchBankSummary();
      onClose();
    }
  };

  const handleAlertContinueClick = (e) => {
    onAlertClose();
    onClose();
    cleanup();
    if (showSubAccountCreatedSuccessScreen) setShowSubAccountCreatedSuccessScreen(false);
  };

  return (
    <>
      <UnsavedChangesAlert
        isDrawerAlertOpen={isDrawerAlertOpen}
        onAlertClose={onAlertClose}
        onAlertOpen={onAlertOpen}
        onAlertContinue={(e) => handleAlertContinueClick(e)}
      />

      <BaselaneDrawer
        title={title}
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        closeOnOverlayClick={false}
        closeEvent={onCloseWithoutSaving}
        onOverlayClick={onCloseWithoutSaving}
        isOpen
        newDesignDrawer
        newDrawerUseDefaultBodyFooter
      >
        {showSubAccountCreatedSuccessScreen ? (
          <VirtualAccountCreatedScreen
            onClose={onClose}
            refetchBankSummary={refetchBankSummary}
            showSubAccountCreatedSuccessScreen={showSubAccountCreatedSuccessScreen}
            setShowSubAccountCreatedSuccessScreen={setShowSubAccountCreatedSuccessScreen}
          />
        ) : (
          <AddVirtualDrawerBody
            isDirty={isDirty}
            formikRef={formikRef}
            initialAccountValues={initialAccountValues}
            handleValidation={handleValidation}
            handleOnSubmit={handleOnSubmit}
            handleFormUpdate={handleFormUpdate}
            isFormValid={isFormValid}
            onCloseWithoutSaving={onCloseWithoutSaving}
            isSubmitting={isSubmitting}
            showErrorBanner={showErrorBanner}
          />
        )}
      </BaselaneDrawer>
    </>
  );
};

export default AddVirtualAccountDrawer;

AddVirtualAccountDrawer.defaultProps = {
  from: null,
};
