// @flow
import React, { useEffect } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { BaselaneEmptyStateCard, BaselaneErrorCard, BaselaneTooltip } from '@shared/components';
// NOTE: Uncomment below once you want to bring back rest of the tabs
// import KPIsSummaryWrapper from './KPIsSummaryWrapper';
import { PROPERTIES } from '@routes';
import useBreakPoints from '@core/hooks/useBreakPoints';
import {
  tabListStyles,
  tabStyles,
  tabPanelsStyles,
  tabWrapperStyles,
} from '@shared/styles/tab.styles';

import { KPI_QUERY } from './queries';
import type { Property } from '../Properties/types';
import { buildPresentationKPIsTabs } from './utilities';
import type { PresentationKPIsTab } from './types';
import AssetValueSummaryWrapper from './AssetValueSummaryWrapper';

function KPIsTabs({
  filters,
  aggregatingProperties,
  properties,
}: {
  filters: any,
  aggregatingProperties: boolean,
  properties: Property[],
}): any {
  const { isMin768 } = useBreakPoints();
  const { loading, error, data, refetch } = useQuery(KPI_QUERY, { variables: { input: filters } });

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  if (loading) return <div>Loading...</div>;

  if (error && !data) {
    // Added !data until BE KPI_QUERY is fixed
    if (error.message.includes('Failed to fetch')) {
      return <BaselaneErrorCard style={{ mt: '48px !important', mb: '48px !important' }} />;
    }
    return null;
  }

  const presentationKPIsTabs: PresentationKPIsTab[] = buildPresentationKPIsTabs(data, properties);

  const mode = aggregatingProperties ? 'kpisByPropertyAggregate' : 'kpisByProperty';

  function isAssetValueTab(tab): boolean {
    return tab.tabName === 'Asset Value';
  }

  /* NOTE: Uncomment below once you want to bring back rest of the tabs
    function hasPercentages(tab): boolean {
      return tab.tabName === 'ROE' || tab.tabName === 'CAP Rate' || tab.tabName === 'Cash-on-Cash';
    } */

  return (
    <Tabs>
      <TabList variant="unstyled" {...tabListStyles}>
        {presentationKPIsTabs.map((tab) => {
          const hideTab = +tab.order > 0;
          const TabComponent = (
            <Tab
              key={tab.order}
              {...{ ...tabStyles(isMin768), top: '0 !important' }}
              isDisabled={hideTab}
            >
              {tab.tabName}
            </Tab>
          );

          return hideTab ? (
            <BaselaneTooltip
              label="Coming Soon"
              aria-label="Coming Soon"
              key={tab.order}
              innerContainerStyles={{
                ...tabWrapperStyles(isMin768),
                _first: { ml: '16px !important' },
              }}
            >
              {TabComponent}
            </BaselaneTooltip>
          ) : (
            TabComponent
          );
        })}
      </TabList>
      <TabPanels {...tabPanelsStyles}>
        {presentationKPIsTabs.map((tab) => {
          if (properties.length === 0) {
            return (
              <TabPanel key={tab.order}>
                <BaselaneEmptyStateCard
                  title="Automate Your Property Metrics"
                  styles={{ boxShadow: 'none' }}
                >
                  <BaselaneEmptyStateCard.Paragraph>
                    Baselane will automatically calculate key metrics to help you assess the
                    performance of property in real-time.
                  </BaselaneEmptyStateCard.Paragraph>
                  <BaselaneEmptyStateCard.Paragraph>
                    To get started, add a property and its financial information.
                  </BaselaneEmptyStateCard.Paragraph>
                  <BaselaneEmptyStateCard.Button
                    route={PROPERTIES}
                    variant="filled"
                    palette="primary"
                  >
                    Add Property
                  </BaselaneEmptyStateCard.Button>
                </BaselaneEmptyStateCard>
              </TabPanel>
            );
          }

          return (
            <TabPanel key={tab.order} {...{ p: '0 !important' }}>
              {tab[mode].map((dataItem) => {
                return (
                  isAssetValueTab(tab) && (
                    <AssetValueSummaryWrapper
                      presentationKPIsByProperty={dataItem}
                      key={dataItem.order}
                    />
                  )
                );
                /* NOTE: Uncomment below once you want to bring back rest of the tabs */
                /* isAssetValueTab(tab) ? (
                      <AssetValueSummaryWrapper
                        presentationKPIsByProperty={dataItem}
                        key={dataItem.order}
                      />
                    ) : (
                      <KPIsSummaryWrapper
                        tabData={dataItem}
                        key={dataItem.order}
                        hasPercentages={hasPercentages(tab)}
                      />
                    ) */
              })}
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
}

export default KPIsTabs;
