// baselane field configuration
export const getBaselaneFields = (fieldNameMatchConfig) => [
  {
    name: 'Date',
    match: fieldNameMatchConfig.get('Date').join(' '), // 'date,time',
    required: true,
    type: Date,
    validator: (data) => {
      return !!data;
    },
  },
  {
    name: 'Description',
    match: fieldNameMatchConfig.get('Description').join(' '), // 'description,merchant,desc,name',
    required: true,
    type: String,
    validator: (data) => {
      return !!data;
    },
  },
  {
    name: 'Amount',
    match: fieldNameMatchConfig.get('Amount').join(' '), // 'amount,price,cost,in/out,debit/credit',
    required: true,
    type: Number,
    validator: (data) => {
      return !!data;
    },
  },
  {
    name: 'Category',
    match: fieldNameMatchConfig.get('Category').join(' '), // 'category,cat,tag',
    required: false,
    type: String,
    validator: (data) => {
      return !!data;
    },
    tooltip:
      'If the CSV has any unrecognized values, you can map them to Baselane categories in the next step.',
  },
  {
    name: 'Property',
    match: fieldNameMatchConfig.get('Property').join(' '), // 'property,prop,address,location',
    required: false,
    type: String,
    validator: (data) => {
      return !!data;
    },
    tooltip:
      'Property values in the CSV cannot be mapped to Baselane properties at the moment. But they will be copied over to the notes field for your reference.',
  },
  {
    name: 'Note',
    match: fieldNameMatchConfig.get('Note').join(' '), // 'note,notes,comments,annotation',
    required: false,
    type: String,
    validator: (data) => {
      return !!data;
    },
  },
];

// table column configuration
export const tableColumnConfig = () => ({
  columns: [
    // 0. Baselane field
    {
      isSortable: false,
      styles: {
        w: '146px',
        contentContainerStyles: {
          px: 1.5,
          py: 2,
        },
      },
    },
    // 1. CSV Column
    {
      isSortable: false,
      styles: {
        w: '230px',
        contentContainerStyles: {
          px: 1.5,
          py: 2,
        },
      },
    },
    // 2. Example Preview
    {
      isSortable: false,
      styles: {
        contentContainerStyles: {
          px: 1.5,
          py: 2,
        },
      },
    },
  ],
});
