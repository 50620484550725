import { gql } from '@apollo/client';

export const GET_TENANT_SCREENING_COMPLIANCE_RULES = gql`
  query getTenantScreeningComplianceRules {
    getTenantScreeningComplianceRules {
      id
      state
      county
      basePrice
      reportsAvailabilityDetails {
        criminalReport {
          available
          price
          attestationRequired
        }
        evictionHistory {
          available
          price
          attestationRequired
        }
        incomeVerification {
          available
          price
          attestationRequired
        }
        creditHistory {
          available
          price
          attestationRequired
        }
      }
    }
  }
`;

export const GET_TENANT_SCREENING_APPLICATION_TEMPLATES = gql`
  query getTenantScreeningApplicationTemplates {
    getTenantScreeningApplicationTemplates {
      applicationTemplateId
      criminalReport
      evictionHistory
      incomeVerification
    }
  }
`;

export const CREATE_TENANT_SCREENING = gql`
  mutation createTenantScreening($input: TenantScreeningInput!) {
    createTenantScreening(input: $input) {
      id
      applicationLinkTinyUrl
      applicationLinkActive
      leaseAvailableDate
      amount
      address
      unitName
      propertyName
      noOfApplications
      applications {
        id
        applicationStatus
        submissionDate
        applicants {
          emailId
          applicantStatus
          applicantName
          primaryApplicant
          applicantRole
          submissionDate
        }
      }
    }
  }
`;

export const EDIT_TENANT_SCREENING = gql`
  mutation EditTenantScreening($id: Float!, $input: TenantScreeningEditInput!) {
    editTenantScreening(id: $id, input: $input) {
      id
      applicationLinkTinyUrl
      applicationLinkActive
      leaseAvailableDate
      amount
      address
      unitName
      propertyName
      noOfApplications
      applications {
        id
        submissionDate
        applicationStatus
        applicants {
          emailId
          applicantStatus
          applicantName
          primaryApplicant
          applicantRole
          submissionDate
        }
      }
      selectedReports
    }
  }
`;

export const GET_TENANT_SCREENINGS = gql`
  query GetTenantScreenings($input: TenantScreeningFilter!) {
    getTenantScreenings(input: $input) {
      id
      applicationLinkTinyUrl
      applicationLinkActive
      leaseAvailableDate
      amount
      unitName
      propertyName
      noOfApplications
      noOfSubmittedApplications
      applications {
        applicationStatus
      }
    }
  }
`;

export const GET_TENANT_SCREENING_BY_ID = gql`
  query GetTenantScreeningById($id: Float!, $input: TenantScreeningSortInput) {
    getTenantScreeningById(id: $id, input: $input) {
      id
      applicationLinkTinyUrl
      applicationLinkActive
      leaseAvailableDate
      amount
      address
      unitName
      propertyName
      noOfApplications
      noOfSubmittedApplications
      isPropertyDeleted
      isUnitDeleted
      selectedReports
      llcName
      applications {
        id
        submissionDate
        decisionDate
        applicationStatus
        userConsent {
          report
          attested
        }
        applicants {
          id
          externalApplicantId
          emailId
          applicantStatus
          applicantName
          primaryApplicant
          applicantRole
          submissionDate
          phoneNumber
        }
      }
    }
  }
`;

export const INVITE_TENANT_SCREENING_APPLICANT = gql`
  mutation inviteTenantScreeningApplicant($input: TenantScreeningApplicantInput!) {
    inviteTenantScreeningApplicant(input: $input) {
      emailId
      applicantStatus
      applicantName
      submissionDate
    }
  }
`;

export const TURN_OFF_TENANT_SCREENING = gql`
  mutation processApplicationLink(
    $tenantScreeningId: Float!
    $input: TenantScreeningApplicationLinkInput!
  ) {
    processApplicationLink(tenantScreeningId: $tenantScreeningId, input: $input) {
      id
      applicationLinkTinyUrl
      applicationLinkActive
    }
  }
`;

export const PROCESS_APPLICATION_DECISION = gql`
  mutation processApplicationDecision(
    $applicationId: Float!
    $input: TenantScreeningApplicationDecisionInput!
  ) {
    processApplicationDecision(applicationId: $applicationId, input: $input) {
      id
    }
  }
`;

export const ATTEST_SCREENING_REPORTS = gql`
  mutation attestTenantScreeningReports(
    $applicationId: Float!
    $input: TenantScreeningConsentInput!
  ) {
    attestTenantScreeningReports(applicationId: $applicationId, input: $input) {
      id
    }
  }
`;
