import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_LEASE_AGREEMENTS } from '../queries';

const useLeaseAgreements = () => {
  const { data, loading, error, refetch } = useQuery(GET_LEASE_AGREEMENTS, {});

  const leaseAgreements = useMemo(() => {
    const agreements = data?.leaseAgreements || [];
    return [...agreements].sort((a, b) => b.id - a.id);
  }, [data]);
  return {
    leaseAgreements,
    loading,
    error,
    refetch,
  };
};

export default useLeaseAgreements;
