import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FieldArray } from 'formik';
import { Box, HStack, Input, List, ListItem, Text } from '@chakra-ui/react';
import { Icon16Delete } from '@icons/16px';
import { BaselaneButton, BaselaneButtonIcon, BaselaneTooltip } from '@shared/components';
import { formLabelStyles } from '@shared/styles/input.style';
import { formSubLabelStyles, formInputStyles, formErrorStyles } from '../../../styles/form.style';

type MultipleUnitsProps = {
  values: Object,
  errors: Object,
  touched: Object,
  isValid: boolean,
  dirty: boolean,
  handleChange: Function,
  handleBlur: Function,
  isDirty: boolean,
  setIsDirty: Function,
  setIsValid: Function,
  setFormVariables: Function,
  setFieldValue: Function,
  setFieldTouched: Function,
};

function MultipleUnits({
  values,
  errors,
  touched,
  isValid,
  dirty,
  handleChange,
  isDirty,
  setIsDirty,
  setIsValid,
  setFormVariables,
  setFieldValue,
  setFieldTouched,
}: MultipleUnitsProps) {
  const [hasPressedDelete, setHasPressedDelete] = useState(false);

  useEffect(() => {
    setFormVariables({ unitNames: values.unitNames });
    setIsValid(isValid);
    setIsDirty({ ...isDirty, ...{ details: dirty } });
  }, [values.unitNames]);

  return (
    <FieldArray name="unitNames">
      {({ remove, push }) => (
        <Box>
          <HStack>
            <Text {...formLabelStyles.xs}>Unit Number or Name</Text>
            <Text {...formSubLabelStyles}>
              The unit number or name will be visible to your Tenants
            </Text>
          </HStack>
          {values.unitType === 'multiple' &&
            values.unitNames.length > 0 &&
            values.unitNames.map((unitName, i) => {
              const isDeleteDisabled =
                unitName.tenantScreening?.length > 0 ||
                unitName.hasLease ||
                (unitName.id && !unitName.isUnitDeletable) ||
                values.unitNames.length === 1;
              const deletePropertyTooltip = (
                <Box>
                  <Text textStyle="xs" mb="8px">
                    To delete the unit,
                  </Text>
                  <List spacing="20px" mb="24px" ml="4px">
                    {unitName.hasLease && (
                      <ListItem display="flex" gap="8px" mb="-16px">
                        <Box as="span" mt="-2px">
                          ·
                        </Box>
                        <Text textStyle="xs" color="brand.white.200" lineHeight="16px">
                          End and archive the active rent collection(s)
                        </Text>
                      </ListItem>
                    )}
                    {unitName.lease?.filter((ls) => ls?.state === 'DRAFT').length > 0 && (
                      <ListItem display="flex" gap="8px" mb="-16px">
                        <Box as="span" mt="-2px">
                          ·
                        </Box>
                        <Text textStyle="xs" color="brand.white.200" lineHeight="16px">
                          Delete the draft rent collection(s)
                        </Text>
                      </ListItem>
                    )}
                    {unitName.tenantScreening?.length > 0 && (
                      <ListItem display="flex" gap="8px" mb="-16px">
                        <Box as="span" mt="-2px">
                          ·
                        </Box>
                        <Text textStyle="xs" color="brand.white.200" lineHeight="16px">
                          Stop accepting applications for the active tenant screening(s)
                        </Text>
                      </ListItem>
                    )}
                  </List>
                </Box>
              );
              return (
                // eslint-disable-next-line react/no-array-index-key
                <HStack key={i} py="8px">
                  <Input
                    {...formInputStyles}
                    id={`unitNames.${i}.name`}
                    name={`unitNames.${i}.name`}
                    placeholder={i < 2 ? `e.g. Unit ${i === 0 ? 'A' : 'B'}` : ''} // Show placeholder only for the first couple
                    value={unitName.name}
                    onChange={(e) => {
                      setFieldValue(`unitNames.${i}.name`, unitName.name);
                      setFieldTouched(`unitNames`, true);
                      handleChange(e);
                    }}
                  />
                  <BaselaneTooltip
                    label={deletePropertyTooltip}
                    styles={{ isDisabled: !isDeleteDisabled, isOpen: undefined }}
                  >
                    <BaselaneButtonIcon
                      id={`unit-${i}-delete-buttonsh`}
                      variant="outline"
                      palette="neutral"
                      isDisabled={isDeleteDisabled}
                      icon={<Icon16Delete />}
                      onClick={() => {
                        setHasPressedDelete(true);
                        remove(i);
                      }}
                    />
                  </BaselaneTooltip>
                </HStack>
              );
            })}
          <HStack pt="8px" pb={isMobile ? '32px' : '0'} position="relative">
            <BaselaneButton
              variant="outline"
              palette="neutral"
              onClick={() => {
                push({
                  name:
                    values.unitNames.length < 26 && !hasPressedDelete
                      ? `Unit ${String.fromCharCode(65 + values.unitNames.length)}`
                      : '',
                });
              }}
              isDisabled={errors.unitNames}
            >
              Add New
            </BaselaneButton>
            <Text {...formErrorStyles} right="0">
              {errors.unitNames || (touched.unitNames && <Box as="span">{errors.unitNames}</Box>)}
            </Text>
          </HStack>
        </Box>
      )}
    </FieldArray>
  );
}

export default MultipleUnits;
