import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { SignJWT } from 'jose';
import LogRocket from 'logrocket';
import { LOGIN, SIGN_UP } from '@core/constants/routes';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { zendeskUserKey } from '@core/constants/envVars';

/**
 * Authenticates zendesk client with user data
 * @param {*} user Baselane user data
 * @returns zendesk token
 */
const authenticateZenDesk = async (user) => {
  const payload = {
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    email_verified: true,
    scope: 'user',
    external_id: user.id,
  };
  const encryptedZendeskKey = new TextEncoder().encode(zendeskUserKey);
  return new SignJWT(payload) // details to encode in the token
    .setProtectedHeader({
      alg: 'HS256',
      typ: 'JWT',
      kid: 'app_664fa7a8edd6bd94be567e7f',
    }) // algorithm
    .sign(encryptedZendeskKey); // secretKey generated from previous step
};

export const ZendeskContext = createContext();

/**
 * custom use hook for accessing the zendesk API
 * @returns
 */
export const useZendeskAPI = () => {
  const zendeskAPI = useContext(ZendeskContext);
  return zendeskAPI;
};

type ZendeskProviderProps = {
  user?: Object,
  handle?: string,
};

export const ZendeskProvider = ({
  user = null,
  handle,
  children,
}: PropsWithChildren<ZendeskProviderProps>) => {
  const { isMax768: isMobile } = useBreakPoints();
  const [isZendeskLoggedIn, setIsZendeskLoggedIn] = useState(false);
  const [zendeskToken, setZendeskToken] = useState(null);

  // Feature flag for Zendesk
  const [isZenDeskHide, setIsZenDeskHide] = useState(true);
  const [isZenDeskHideChecked, setIsZenDeskHideChecked] = useState(false);

  const location = useLocation();

  // Note: Exceptions for messenger bubble
  // Mobile hides the bubble on all routes except the following
  const alwaysShowOnMobileRoutes = [LOGIN];
  const isAlwaysShowOnMobile = alwaysShowOnMobileRoutes.includes(location.pathname);
  // Desktop shows the bubble on all routes except the following
  const alwaysHideOnDesktopRoutes = [SIGN_UP];
  const isAlwaysHideOnDesktop = alwaysHideOnDesktopRoutes.includes(location.pathname);

  const hideMessengerLauncher =
    (!isAlwaysShowOnMobile && isMobile) || (isAlwaysHideOnDesktop && !isMobile) || isZenDeskHide;

  /**
   *  Ada chatbot state variables
   */
  const [isAdaEmbedInitialized, setIsAdaEmbedInitialized] = useState(false);
  const [isAdaChatOpen, setIsAdaChatOpen] = useState(false);
  const { adaEmbed } = window || {};

  const hideZendeskMessenger = () => {
    zendeskAPI('messenger', 'hide');
    zendeskAPI('messenger:on', 'open', () => {
      zendeskAPI('messenger', 'show');
    });
    zendeskAPI('messenger:on', 'close', () => {
      if (!hideMessengerLauncher) {
        zendeskAPI('messenger', 'show');
      } else {
        zendeskAPI('messenger', isZenDeskHide ? 'hide' : 'close');
      }
    });
  };

  // https://developer.zendesk.com/api-reference/widget-messaging/web/core/
  const zendeskAPI = useMemo(() => {
    if (window?.zE) {
      return (...args) => {
        if (args[0] === 'hideZendeskBubble' && args[1] === true) {
          setIsZenDeskHide(true);
        } else if (args[0] === 'hideZendeskBubble' && args[1] === false) {
          setIsZenDeskHide(false);
        } else {
          window.zE(...args);
        }
      };
    }

    return () => {
      console.warn('Zendesk is not initialized yet.');
    };
  }, [window.zE]);

  useEffect(() => {
    if (window.zE && !isZendeskLoggedIn && user?.id && !zendeskToken) {
      setZendeskToken(authenticateZenDesk(user));
    }
  }, [window.zE, user]);

  useEffect(() => {
    if (zendeskToken && !isZendeskLoggedIn) {
      zendeskAPI('messenger', 'loginUser', (callback) => {
        callback(zendeskToken);
        setIsZendeskLoggedIn(true);
      });
    }
  }, [zendeskToken]);

  useEffect(() => {
    if (hideMessengerLauncher) {
      hideZendeskMessenger();
    } else if (!isZenDeskHideChecked) {
      setTimeout(() => {
        setIsZenDeskHideChecked(true);
      }, 2000);
    } else {
      setIsZenDeskHide(false);
      zendeskAPI('messenger', 'show');
      zendeskAPI('messenger:on', 'close', () => {
        zendeskAPI('messenger', 'show');
      });
    }
  }, [hideMessengerLauncher, isZenDeskHide, isZenDeskHideChecked]);

  /**
   * Ada chatbot implementation
   * Documentation: https://developers.ada.cx/chat/web/sdk-api-reference
   */

  /**
   * Shows or hides the Ada bubble based on the current state
   * does nothing if the chat is open
   * @param {boolean} isOpen - whether the chat modal is open
   */
  const evaluateAdaChatState = (isOpen) => {
    if (!isOpen && isAdaEmbedInitialized) {
      const shouldHideAdaMessenger =
        (!isAlwaysShowOnMobile && isMobile) ||
        (isAlwaysHideOnDesktop && !isMobile) ||
        !isZenDeskHide;

      const adaBubble = document.getElementById('ada-button-frame');
      if (shouldHideAdaMessenger) {
        adaBubble.style.display = 'none';
      } else {
        adaBubble.style.display = 'block';
      }
    }
  };

  /**
   * if any of the state variables change, evaluate the Ada chat state
   */
  useEffect(() => {
    evaluateAdaChatState(isAdaChatOpen);
  }, [
    isAlwaysShowOnMobile,
    isMobile,
    isAlwaysHideOnDesktop,
    isZenDeskHide,
    isAdaEmbedInitialized,
    isAdaChatOpen,
  ]);

  /**
   * Sets the meta fields for the Ada chatbot
   * Only runs if the user is logged in and ada is initialized
   * Note: should only run if the user's id changes
   * */
  useEffect(() => {
    if (user?.id && isAdaEmbedInitialized && adaEmbed) {
      adaEmbed.setMetaFields({
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        role: 'landlord',
        createdDate: user.createdAt,
      });
    }
  }, [user?.id, isAdaEmbedInitialized, adaEmbed]);

  /**
   * Initializes Ada and catches any 'already initialized' errors
   * Sets state as being initialized
   * */
  const initializeAda = () => {
    adaEmbed
      ?.start({
        handle: 'baselane',
        toggleCallback: (isOpen) => {
          setIsAdaChatOpen(isOpen);
          evaluateAdaChatState(isOpen);
        },
      })
      .catch((e) => {
        if (
          e.name === 'AdaEmbedError' &&
          e.message.includes('Ada Embed has already been started')
        ) {
          // do nothing, this only happens when ada is already initialized
          // should theoretically never happen
        } else {
          // log all other errors
          LogRocket.log('Error starting Ada', e);
        }
      })
      .finally(() => {
        setIsAdaEmbedInitialized(true);
      });
  };

  /* Initializes Ada on component mount
   * Cleans up Ada on component unmount
   * */
  useEffect(() => {
    // Only initialize Ada if it's not already initialized
    if (!isAdaEmbedInitialized) {
      initializeAda();
    }

    /**
     * Cleans up Ada when the component unmounts,
     * that way anonymous version can initialize if user
     * logged out
     * */
    return () => {
      adaEmbed?.stop();
    };
  }, []);

  return <ZendeskContext.Provider value={zendeskAPI}>{children}</ZendeskContext.Provider>;
};
