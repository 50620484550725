// @flow
import React, { useState } from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import { isUndefined } from 'lodash';
import { tooltipStyles } from './styles/tooltip.style';

type BaselaneTooltipProps = {
  children: any,
  label: string,
  noArrow: boolean,
  placement: string,
  isOpen: Boolean,
  styles: Object,
  innerContainerStyles?: Object,
  id: string,
};

function BaselaneTooltip({
  children,
  label,
  noArrow = false,
  placement,
  isOpen: isTooltipOpen = false,
  styles = {},
  innerContainerStyles = {},
  id,
  ...rest
}: BaselaneTooltipProps): any {
  const [isOpen, setIsOpen] = useState(isTooltipOpen);

  const tooltipStyle = { ...tooltipStyles, ...styles };
  const tooltipPlacement = placement || 'bottom';

  const computedProperties = {};

  if (!isUndefined(isOpen)) {
    // Adding `isOpen` property conditionally.
    // When added, it puts tooltip in controlled mode.
    // (Controlled mode means it will open/close manually, not automatically!)
    computedProperties.isOpen = isOpen;
  }
  return (
    <Tooltip
      label={label}
      aria-label={label}
      hasArrow={!noArrow}
      placement={tooltipPlacement}
      {...computedProperties}
      {...tooltipStyle}
    >
      <Box
        id={id ?? 'tooltip'}
        as="span"
        {...{ ml: '-2px !important', display: 'block', cursor: 'pointer', ...innerContainerStyles }}
        onMouseEnter={() => {
          setIsOpen((prevState) => !prevState);
        }}
        onMouseLeave={() => {
          if (isOpen) {
            setIsOpen(false);
          }
        }}
        onClick={() => {
          setIsOpen((prevState) => !prevState);
        }}
        {...rest}
      >
        {children}
      </Box>
    </Tooltip>
  );
}

export default BaselaneTooltip;
