import React, { useEffect, useState, useContext } from 'react';
import { cloneDeep } from 'lodash';
import { Box, Tbody } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { NetworkStatus } from '@apollo/client';
import { BaselaneResponsiveTable, BaselaneResponsiveTableRows } from '@shared/components';
import TransactionContext from '@contexts/TransactionContext';
import useBreakPoints from '@core/hooks/useBreakPoints';
import TransactionsDummyLoader from './components/TransactionsDummyLoader';
import EmptyState from './EmptyState';
import TransactionsListItem from './TransactionsListItem';
import type { Transaction } from './types';
import TransactionsListHeader from './TransactionsListHeader';
import { buildPresentationProperties, createPropertyIdsMap } from './helpers/propertyInput.helper';
import { config } from './helpers/transactionsTable.helpers';
import { BaselaneResponsiveTableCaption } from '../Shared/components/BaselaneResponsiveTable';
import TransactionsListLoadMore from './components/TransactionsListLoadMore';

type TransactionsListProps = {
  showHiddenTransactions: boolean,
  showOnlyWithAttachments: boolean,
  onSort: Function,
  onLoadMore: Function,
  showUncategorized: boolean,
  handleSelectedTransactions: Function,
  selectedTransactions: Array<Transaction>,
  actionDeselectAll: boolean,
  setActionDeselectAll: Function,
  setSelectedTransactions: Function,
  setHasManualTransactions: Function,
  externalFilters?: Object,
  transactions: Array<Transaction>,
  setTransactions: Function,
  getTransactions: Function,
  transactionsLoading: boolean,
  data: Array<Transaction>,
  error: boolean,
  networkStatus: String,
  refetch: Function,
  refetchAccountsData: Function,
  setHasLoadedMore: Function,
  hasLoadedMore: boolean,
  showDummyData?: boolean,
  isOutsideTrxPage: boolean,
  setShowImportTrxModal: Function,
  isTableOnly?: boolean,
};
const TransactionList = ({
  showHiddenTransactions,
  showOnlyWithAttachments,
  onSort,
  onLoadMore,
  showUncategorized,
  handleSelectedTransactions,
  selectedTransactions,
  actionDeselectAll,
  setActionDeselectAll,
  setSelectedTransactions,
  setHasManualTransactions,
  externalFilters = ({}: { ... }),
  transactions,
  setTransactions,
  getTransactions,
  transactionsLoading,
  data,
  error,
  networkStatus,
  refetch,
  refetchAccountsData,
  setHasLoadedMore,
  hasLoadedMore,
  showDummyData = false,
  isOutsideTrxPage,
  setShowImportTrxModal,
  isTableOnly = false,
}: TransactionsListProps) => {
  const currentUrl = useLocation();

  const { isMin899, isMin1150 } = useBreakPoints();

  const [allSelected, setAllSelected] = useState(false);

  const {
    totalTransactionCount,
    uncategorizedCount,
    filters,
    setFilters,
    setPropertyIdsMap,
    propertiesData,
    transactionsDummyData,
    refetchConnectedAccountsData,
    isTransactionSummaryOrAccountsLoading,
    hasUpdatedSplitTrx,
    setHasUpdatedSplitTrx,
  } = useContext(TransactionContext);

  const { bankAccountId = '' } = externalFilters;
  const refetchTransactions = () => (refetch ? refetch() : getTransactions());
  const [hasMoreTransactions, setHasMoreTransactions] = useState(false);

  useEffect(() => {
    refetchTransactions();
    refetchAccountsData();
    refetchConnectedAccountsData();
  }, []);

  useEffect(() => {
    refetchTransactions();
  }, [filters]);

  useEffect(() => {
    refetchTransactions();
    setFilters({ ...filters, filter: { ...filters.filter, ...externalFilters } });
  }, [bankAccountId?.toString()]);

  useEffect(() => {
    refetchTransactions();
    refetchAccountsData();
  }, [totalTransactionCount]);

  const settingVariables = networkStatus === NetworkStatus.setVariables;
  useEffect(() => {
    if (settingVariables) {
      return;
    }

    if (filters.page > 1) {
      setHasLoadedMore(false);
      let clonedTransactions = cloneDeep(transactions);

      // if:   loading from 'Load More' button
      // else: loading from filters/update trxs
      if (hasLoadedMore) {
        (data?.transaction || []).forEach((tx) => {
          const foundTxIndex = transactions.findIndex((item) => item.id === tx.id);
          if (foundTxIndex === -1) {
            clonedTransactions.push({ ...tx, isChecked: allSelected });
          } else {
            clonedTransactions[foundTxIndex] = { ...tx };
          }
        });
      } else {
        clonedTransactions = cloneDeep(data?.transaction);
      }

      const updatedTransactions = clonedTransactions.map((t) => {
        const foundSelected = selectedTransactions.filter((st) => st.id === t.id).length > 0;
        return { ...t, isChecked: foundSelected };
      });

      const total = showUncategorized ? uncategorizedCount : totalTransactionCount;
      const hasMoreTrxs =
        updatedTransactions &&
        updatedTransactions.length < total &&
        updatedTransactions.length >= filters.pageLimit &&
        data.transaction.length === filters.pageLimit;

      setHasMoreTransactions(hasMoreTrxs);

      setTransactions(updatedTransactions);
      handleSelectedTransactions(updatedTransactions.filter((t) => t.isChecked));
      if (allSelected) {
        handleSelectedTransactions(clonedTransactions);
      }
    } else {
      const mappedWithCheckedtransactions = (data?.transaction || []).map((t) => ({
        ...t,
        isChecked: false,
      }));

      const total = showUncategorized ? uncategorizedCount : totalTransactionCount;
      const hasMoreTrxs =
        mappedWithCheckedtransactions &&
        mappedWithCheckedtransactions.length < total &&
        mappedWithCheckedtransactions.length >= filters.pageLimit &&
        data.transaction.length === filters.pageLimit;
      setHasMoreTransactions(hasMoreTrxs);
      setTransactions(mappedWithCheckedtransactions || []);
    }

    if (showDummyData && data?.length === 0) setTransactions(transactionsDummyData.transaction);
  }, [data, totalTransactionCount]);

  useEffect(() => {
    const hasManualTransactions = transactions?.find((transaction) => transaction.isManual);
    if (hasManualTransactions) setHasManualTransactions(true);
    else setHasManualTransactions(false);
  }, [transactions]);

  const remainingTransactionsCount =
    transactions && totalTransactionCount > transactions.length
      ? totalTransactionCount - transactions.length
      : 0;

  useEffect(() => {
    if (hasUpdatedSplitTrx) {
      if (totalTransactionCount > filters.pageLimit && remainingTransactionsCount > 0) {
        setHasMoreTransactions(true);
      }
      setHasUpdatedSplitTrx(false);
    }
  }, [hasUpdatedSplitTrx]);

  const handleSelectAllVisible = (all) => {
    if (all) {
      setAllSelected(true);
      const updatedTransactions = transactions.map((t) => ({
        ...t,
        isChecked: true,
      }));
      handleSelectedTransactions(updatedTransactions);
      setTransactions(updatedTransactions);
    } else {
      handleSelectedTransactions([]);
      setAllSelected(false);
    }
  };

  const handleLoadMore = () => {
    onLoadMore();
  };

  useEffect(() => {
    if (actionDeselectAll) {
      setActionDeselectAll(true);
      setAllSelected(false);
      handleSelectAllVisible(false);
    }
  }, [actionDeselectAll]);

  useEffect(() => {
    if (propertiesData.length > 0) {
      const presentationProperties = buildPresentationProperties(propertiesData);
      const idsMap = createPropertyIdsMap(presentationProperties);
      setPropertyIdsMap(idsMap);
    }
  }, [propertiesData]);

  if (error) {
    return <div>Server error</div>;
  }

  const { pathname } = currentUrl;
  const noTransactionsFound = pathname.includes('/banking')
    ? (totalTransactionCount > 0 && transactions?.length === 0) || totalTransactionCount === 0
    : totalTransactionCount > 0 && transactions?.length === 0;

  if ((transactionsLoading || isTransactionSummaryOrAccountsLoading) && filters.page === 1) {
    return (
      <TransactionsDummyLoader
        filters={filters}
        onSort={onSort}
        onSelectAll={handleSelectAllVisible}
        actionDeselectAll={actionDeselectAll}
        setActionDeselectAll={setActionDeselectAll}
        selectedTransactions={selectedTransactions}
        totalVisible={transactions?.length}
      />
    );
  }

  return (
    <Box>
      <BaselaneResponsiveTable
        id="trx-list"
        config={config(isMin1150, isTableOnly)}
        tableContainerStyles={{
          overflowY: 'initial',
          overflowX: 'initial',
          w: isMin899 && '100%',
          borderRadius: '8px',
          bg: 'white',
        }}
      >
        <BaselaneResponsiveTableCaption placement="bottom" textAlign="center" {...{ p: 3, m: 0 }}>
          <TransactionsListLoadMore
            hasMoreTransactions={hasMoreTransactions}
            handleLoadMore={handleLoadMore}
            transactionsLoading={transactionsLoading}
            remainingTransactionsCount={remainingTransactionsCount}
          />
        </BaselaneResponsiveTableCaption>
        {isMin899 && (
          <TransactionsListHeader
            filters={filters}
            onSort={onSort}
            onSelectAll={handleSelectAllVisible}
            actionDeselectAll={actionDeselectAll}
            setActionDeselectAll={setActionDeselectAll}
            selectedTransactions={selectedTransactions}
            totalVisible={transactions?.length}
            isTableOnly={isTableOnly}
          />
        )}
        {(showDummyData || transactions.length > 0) && (
          <Tbody>
            <BaselaneResponsiveTableRows
              customRow
              items={transactions}
              renderItem={(transaction, index) => {
                return (
                  <TransactionsListItem
                    transaction={transaction}
                    selectedTransactions={selectedTransactions}
                    setSelectedTransactions={setSelectedTransactions}
                    actionDeselectAll={actionDeselectAll}
                    setActionDeselectAll={setActionDeselectAll}
                    onSelectAll={handleSelectAllVisible}
                    allSelected={allSelected}
                    handleSelectedTransactions={handleSelectedTransactions}
                    showDummyData={showDummyData}
                    showOnlyWithAttachments={showOnlyWithAttachments}
                    showHiddenTransactions={showHiddenTransactions}
                    index={index}
                    isOutsideTrxPage={isOutsideTrxPage}
                    isTableOnly={isTableOnly}
                  />
                );
              }}
            />
          </Tbody>
        )}
      </BaselaneResponsiveTable>

      {transactions.length === 0 &&
        !(transactionsLoading || isTransactionSummaryOrAccountsLoading) && (
          <EmptyState
            noTransactionsFound={noTransactionsFound}
            title="No Transactions Found"
            paragraph="Try changing or clearing your search filters to view more transactions"
            styles={isMin899 ? { p: '32px', h: 'auto' } : {}}
            isDirectToPlaid
            onAddedPlaidSuccessfully={refetchTransactions}
            setShowImportTrxModal={setShowImportTrxModal}
          />
        )}
    </Box>
  );
};

export default TransactionList;
