import React from 'react';
import { Box, Heading, FormControl, FormLabel, Text, Flex } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { useFormikContext } from 'formik';
import { BaselaneButtonToggle, BaselaneInput } from '@shared/components';
import stripCurrency from '@core/utils/stripCurrency';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { Icon12Info } from '@core/components/Icons/12px';
import { formLabelStyles } from '@shared/styles/input.style';
import { currencyMask } from '@core/utils/masks/index';
import formatCurrency from '@core/utils/formatCurrency';
import { fieldContainer } from '../../../styles/shared.styles';

/**
 * Controls the input mask for percentages
 */
const percentageInputMaskOptions = {
  prefix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  allowNegative: false,
  integerLimit: 3,
  allowLeadingZeroes: false,
};

/**
 * Pipe function that prevents the entering of more than 100%
 * @param {string} conformedValue processed string before display
 * @returns
 */

const preventGreaterThanMaxPercentage = (conformedValue) => {
  if (Number(conformedValue) > 100) return false;
  return conformedValue;
};

type Props = {
  monthlyFeeBasedOnRent: any,
};

const MonthlyMaximum = ({ monthlyFeeBasedOnRent }: Props) => {
  const { isMax767 } = useBreakPoints();
  const { values, errors, setFieldValue } = useFormikContext();
  const isFeeFlat = values.feeCapType === 'FLAT';

  const handleMaximumFeeChange = (e) => {
    if (isFeeFlat) {
      setFieldValue('feeCapAmount', stripCurrency(e.target.value));
    } else {
      setFieldValue('feeCapPercent', e.target.value);
    }
  };

  return (
    <Box mt={3}>
      <Heading size="headline-sm" mb={2}>
        Maximum late fees per month (optional)
      </Heading>
      <Flex gap={2} flexDirection={isMax767 ? 'column' : 'row'}>
        <FormControl
          {...fieldContainer}
          mt={isMax767 && '0px !important'}
          mb={isMax767 && '16px !important'}
          w="auto"
          maxW={200}
        >
          <FormLabel {...formLabelStyles.xs} htmlFor="maximum-type">
            Limit type
          </FormLabel>
          <BaselaneButtonToggle
            size="md"
            firstLabel="$ Amount"
            secondLabel="% of rent"
            variant="toggle"
            palette="neutral"
            activeButton={isFeeFlat ? 0 : 1}
            onClick={(value) => {
              setFieldValue('feeCapType', value === 0 ? 'FLAT' : 'PERCENT');
            }}
            isFullWidth
          />
        </FormControl>
        <FormControl
          {...fieldContainer}
          mt={isMax767 && '0px !important'}
          mb={isMax767 && '16px !important'}
          maxWidth={isMax767 ? 'none !important' : '96px !important'}
        >
          <FormLabel {...formLabelStyles.xs} htmlFor="monthly-maximum-fee" whiteSpace="nowrap">
            Monthly maximum
          </FormLabel>

          <BaselaneInput
            id="monthly-maximum-fee"
            name="monthly-maximum-fee"
            size="lg"
            fontSize="14px"
            placeholder={isFeeFlat ? '$' : ''}
            suffix={isFeeFlat ? '' : '%'}
            value={isFeeFlat ? values.feeCapAmount : values.feeCapPercent}
            as={MaskedInput}
            mask={isFeeFlat ? currencyMask() : createNumberMask(percentageInputMaskOptions)}
            {...(!isFeeFlat && { pipe: preventGreaterThanMaxPercentage })}
            onChange={handleMaximumFeeChange}
            bg="brand.neutral.white"
            inputGroupProps={{ backgroundColor: 'brand.neutral.white' }}
          />
          {!isFeeFlat && monthlyFeeBasedOnRent ? (
            <Text textStyle="sm" textAlign="right" mt={0.5}>
              = {formatCurrency(monthlyFeeBasedOnRent).inDollars}
            </Text>
          ) : null}
        </FormControl>
      </Flex>

      {errors.initialExceedsMaximum && (
        <Flex gap={0.5} alignItems="center" mt={2} mb={2} color="#C93A3A">
          <Icon12Info />
          <Text textStyle="xs">{errors.initialExceedsMaximum}</Text>
        </Flex>
      )}
    </Box>
  );
};

export default MonthlyMaximum;
