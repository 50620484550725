import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { FormControl, useDisclosure } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import useValidDates from '@hooks/useValidDates';
import { BaselaneSingleDatePicker, BaselaneFormErrorMessage } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';

const EndDateDatepicker = () => {
  const { isMin768 } = useBreakPoints();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { excludedDates, getValidDate, endDate } = useValidDates();
  const { values, setFieldValue } = useFormikContext();
  const [minDate, setMinDate] = React.useState(endDate);

  useEffect(() => {
    const endDateIsBeforeStartDate =
      DateTime.fromJSDate(values?.recurringPayment?.endDate).diff(
        DateTime.fromJSDate(values?.recurringPayment?.startDate),
        'days'
      ).days < 0;

    const isSameDay = DateTime.fromJSDate(values?.recurringPayment?.startDate).hasSame(
      DateTime.fromJSDate(values?.recurringPayment?.endDate),
      'day'
    );

    // Caclulating a new valid end date. Set min date in any case.
    const newEndDate = getValidDate(
      DateTime.fromJSDate(values?.recurringPayment?.startDate).plus({ days: 1 })
    ).toJSDate();
    setMinDate(newEndDate);

    if (endDateIsBeforeStartDate || isSameDay) {
      // Setting the new end date.
      setFieldValue('recurringPayment.endDate', newEndDate);
    }
  }, [values?.recurringPayment?.startDate]);

  const isWeekday = (date) => {
    const day = date.getDay(date);
    return day !== 0 && day !== 6;
  };

  return (
    <Field name="recurringPayment.endDate">
      {({ field, form }) => {
        return (
          <FormControl
            m={0}
            isRequired
            isInvalid={
              form.errors?.recurringPayment?.endDate &&
              (form.touched?.recurringPayment?.endDate || form.touched?.recurringPayment?.endBy)
            }
            maxWidth="150px"
          >
            <BaselaneSingleDatePicker
              id="recurringPayment.endDate"
              name="End date"
              minDate={minDate}
              date={field.value}
              value={
                field.value
                  ? DateTime.fromJSDate(field.value).toFormat('MMM dd yyyy')
                  : DateTime.fromJSDate(minDate).toFormat('MMM dd yyyy')
              }
              dateFormat="MMM dd yyyy"
              handleCalendarClose={({ date, isFromApplyButton }) => {
                if (isMin768 || (!isMin768 && isFromApplyButton)) {
                  const newDate = date ?? minDate;
                  form.setFieldValue('recurringPayment.endDate', newDate);
                  // Setting the field as touched explicitly.
                  form.setTouched({
                    ...form.touched,
                    recurringPayment: { ...form.touched?.recurringPayment, endDate: true },
                  });
                }
              }}
              size="sm"
              years={{ start: new Date().getFullYear(), end: new Date().getFullYear() + 5 }}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              hideInputIcon={false}
              isDisabled={form.values?.recurringPayment?.endBy !== 'date'}
              fixedHeight
              placement="bottom-end"
              showInModal={!isMin768}
              filterDate={isWeekday}
              excludedDates={excludedDates}
              hideClearButton
            />
            <BaselaneFormErrorMessage
              isInvalid={
                form.errors?.recurringPayment?.endDate &&
                (form.touched?.recurringPayment?.endDate || form.touched?.recurringPayment?.endBy)
              }
            >
              {form.errors?.recurringPayment?.endDate}
            </BaselaneFormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default EndDateDatepicker;
