export const tabsMenuListItemContainerStyle = (isMobile) => ({
  width: isMobile ? '33%' : '100%',
  justifyContent: isMobile ? 'space-evenly' : 'flex-start',
  p: isMobile ? '0' : '8px',
  h: isMobile ? '32px' : '40px',
  fontSize: 'sm',
  boxShadow: 'none !important',
  lineHeight: isMobile ? '32px' : '20px',
  borderRadius: '4px',
  mt: isMobile ? '24px' : '0',
  _selected: { bg: 'brand.blue.50' },
  _focus: { boxShadow: 'none' },
  _notFirst: {
    mt: isMobile ? '24px' : '24px !important',
  },
  _last: {
    width: isMobile ? '32px !important' : '100%',
  },
});

export const tabsMenuListItemContainerStyleColors = (isDisabled, isValidEnabled, isMobile) => {
  if (isDisabled) {
    return {
      _disabled: {
        opacity: 1,
      },
      color: 'brand.neutral.500',
      _hover: {
        color: 'brand.neutral.500',
        cursor: 'not-allowed',
      },
      _last: {
        width: isMobile ? '32px' : '100%',
      },
    };
  }
  if (isValidEnabled) {
    return {
      color: 'green.800AA',
      _hover: {
        bg: isMobile ? 'transparent' : 'brand.blue.50',
        cursor: 'pointer',
        color: 'green.800AA',
      },
      _selected: {
        bg: isMobile ? 'transparent' : 'brand.blue.50',
        cursor: 'pointer',
      },
    };
  }
  return {
    color: 'brand.blue.700',
    _hover: {
      bg: 'brand.blue.50',
      cursor: 'pointer',
      color: 'brand.blue.700',
    },
    _selected: {
      bg: isMobile ? 'transparent' : 'brand.blue.50',
      cursor: 'pointer',
      color: 'brand.blue.700',
    },
  };
};

export const dashStylesValid = {
  display: 'flex',
  borderColor: 'green.500A',
};

export const checkFormIconStyles = (isMobile) => ({
  width: isMobile ? '32px' : '24px',
  height: isMobile ? '32px' : '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid orange',
  borderRadius: '50%',
});

export const checkFormCircleStyles = (noBorder, isDisabled) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: noBorder ? '24px' : '20px',
    height: noBorder ? '24px' : '20px',
    borderRadius: '50%',
    textStyle: 'xs-heavy',
    bgColor: noBorder ? 'brand.blue.100' : 'transparent',
    border: noBorder ? 'none' : '2px solid',
    color: isDisabled ? 'brand.neutral.500' : 'inherit',
  };
};

export const checkValidFormCircleStyles = {
  width: '32px',
  height: '32px',
  border: '1px solid',
  borderColor: 'brand.blue.700',
  borderRadius: '50%',
};

export const tabsStyles = (isMobile) => ({
  p: isMobile ? '0' : '40px 0 32px 32px',
  position: 'relative',
  overflowY: 'auto !important',
  minHeight: isMobile ? '0' : '100%',
});

export const tabListStyles = (isMobile) => ({
  w: isMobile ? '100%' : '280px',
  minW: isMobile ? '100%' : '280px',
  h: isMobile ? '64px' : 'calc(100% - 59px)',
});

export const formWrapper = (isMobile) => ({
  p: isMobile ? '0 24px' : 'auto',
});
