import React from 'react';
import moment from 'moment';
import { Box, Stack, Text } from '@chakra-ui/react';

import useBreakPoints from '@core/hooks/useBreakPoints';
import formatCurrency from '@core/utils/formatCurrency';
import IconLockSimple from '@icons/manual/IconLockSimple';
import {
  blockContainerStyles,
  bonusStyles,
  connectorStyles,
  containerStyles,
  productStyles,
  titleStyles,
} from '@core/components/NativeBankingPage/Summary/styles/tier.styles';
import { getIcon } from './helpers/learnMoreActivityConfig.helpers';

type tiersDataProps = {
  apyLearnMoreConfig: Array<Object>,
  hasCollectedRentWithinNeededPeriod: Boolean,
  isEmptyState: Boolean,
  isUserOnPromo: Boolean,
  isUserInLastMonthOfPromo: Boolean,
  userPromoEndDate: Date,
};

const TierInfo = ({
  apyLearnMoreConfig,
  isEmptyState,
  isUserOnPromo,
  isUserInLastMonthOfPromo,
  userPromoEndDate,
}: tiersDataProps) => {
  const { isMinXL } = useBreakPoints();
  return (
    <Stack spacing="0">
      {apyLearnMoreConfig.map((item, index) => {
        const shouldShowBlockInMobile =
          apyLearnMoreConfig[index - 1] && apyLearnMoreConfig[index - 1].isActive;
        const hideConnector = index === apyLearnMoreConfig.length - 1;
        const hideInfoForLastTierMobile =
          isMinXL && index === apyLearnMoreConfig.length - 1 && item.isActive;
        const showPromoStateInfo =
          isUserOnPromo &&
          !isUserInLastMonthOfPromo &&
          index === apyLearnMoreConfig.length - 1 &&
          userPromoEndDate;

        return (
          <Stack
            direction="row"
            {...containerStyles(
              isMinXL,
              index,
              apyLearnMoreConfig,
              shouldShowBlockInMobile,
              isEmptyState
            )}
            key={`tier-${item?.product?.value}`}
          >
            {!hideInfoForLastTierMobile && (
              <Box bgColor="brand.neutral.white">{getIcon(item.isActive, item.isCompleted)}</Box>
            )}
            <Stack
              direction={!isMinXL ? 'row' : 'column'}
              alignItems={isMinXL ? 'flex-start' : 'center'}
              w="100%"
            >
              <Box flexBasis={isMinXL ? 'auto' : '30%'}>
                <Text
                  {...titleStyles}
                  textDecoration={showPromoStateInfo ? 'line-through' : 'none'}
                  color={showPromoStateInfo ? 'brand.neutral.600' : 'brand.neutral.900'}
                >
                  {item?.text}
                </Text>
                {(!isMinXL || showPromoStateInfo) && (
                  <Text textStyle="xs" color="brand.neutral.600">
                    {showPromoStateInfo
                      ? `Through ${moment(userPromoEndDate).format('MMM YYYY')}`
                      : 'Total Balance'}
                  </Text>
                )}
              </Box>

              {/* Tier Blocks Start */}
              <Stack direction="row" {...blockContainerStyles(isMinXL)}>
                <Stack
                  w={!hideInfoForLastTierMobile ? item.width : '100%'}
                  {...productStyles(item, isMinXL)}
                >
                  <Text fontWeight="medium">
                    {formatCurrency(item.product.value, true).noDollar}%
                  </Text>
                  <Text>{item.product.subText}</Text>
                </Stack>
                {item.bonus?.[0] && (
                  <Stack {...bonusStyles(item, isMinXL)}>
                    <Text fontWeight="medium">
                      +{formatCurrency(item.bonus[0].value, true).noDollar}%
                    </Text>
                    <Stack direction="row" align="center" spacing="4px">
                      <Text>{item.bonus[0].subText}</Text>
                      {!item.bonus[0].isActive && (
                        <Box>
                          <IconLockSimple />
                        </Box>
                      )}
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Box
              {...connectorStyles(
                apyLearnMoreConfig?.[index + 1]?.isCompleted ||
                  apyLearnMoreConfig?.[index + 1]?.isActive ||
                  false,
                isMinXL || (isMinXL && !isUserOnPromo)
                  ? shouldShowBlockInMobile || hideConnector
                  : hideConnector
              )}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};

export default TierInfo;
