import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';
import { useStatsigClient } from '@statsig/react-bindings';
import UserAccessContext from '@contexts/UserAccessContext';
import { NATIVE_BANK, NB_CARDS } from '@routes';
import ActivationSuccessPopup from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/ActivatePopup/ActivationSuccessPopup';
import AddFundsDrawer from '@core/components/NativeBankingPage/MainContent/components/AddFundsDrawer';
import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import { DYNAMIC_CONFIGS } from '@core/constants/statsigKeys';
import Tracker from './Tracker';
import { InProgress } from './Steps';
import Card from './Card';
import { getTrackerData, getLinkStatus } from '../helpers/banking.helpers';
import { GET_BANK_TRACKER } from '../queries';

type BankingProps = {
  data: Object,
  refetchTrackers: Function,
  kycProps: Object,
  onBaselaneApplicationTypePopupOpen: Function,
};

const Banking = ({
  data,
  refetchTrackers,
  kycProps,
  onBaselaneApplicationTypePopupOpen,
}: BankingProps) => {
  const navigate = useNavigate();
  const { isWorkspaceOwner, authorizedForBanking } = useContext(UserAccessContext);
  const { getDynamicConfig } = useStatsigClient();
  const config = getDynamicConfig(DYNAMIC_CONFIGS.APY_INTEREST);

  // Activation Success Popup
  const {
    isOpen: isActivationSuccessPopupOpen,
    onOpen: onActivationSuccessPopupOpen,
    onClose: onActivationSuccessPopupClose,
  } = useDisclosure();

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  const skipAllQueries = !data?.length;

  const skipCardQuery =
    data?.findIndex((step) => {
      return step.name === 'ACTIVATE_DEBIT_CARD' && step.state !== 'NOT_STARTED';
    }) >= 0;

  const { loading, error, data: qData } = useQuery(GET_BANK_TRACKER, {
    variables: {
      page: 1,
      pageSize: 1,
    },
    fetchPolicy: 'cache-and-network',
    skip: skipAllQueries || skipCardQuery,
  });

  const { getKYCUrl } = kycProps ?? {};

  const awaitingDocAccounts = qData?.bankList?.filter(
    (bank) => bank.unitApplicationStatus === 'AWAITING_DOCUMENTS'
  );

  const pendingAccounts = qData?.bankList?.filter(
    (bank) => bank.unitApplicationStatus === 'IN_PROGRESS'
  );

  const hasSingleAwaitingDocAccount = awaitingDocAccounts?.length === 1;
  const hasPendingAccount = pendingAccounts?.length > 0;

  const [showSteps, setShowSteps] = useState(false);
  const trackerData = getTrackerData(hasPendingAccount, config?.get('apy'), authorizedForBanking);
  const LINKS = getLinkStatus(trackerData.elements);

  const getOpenBaselaneBankingLink = (status, state) => {
    switch (state) {
      case 'not_started':
        return (
          <InProgress
            status={status}
            handleTrackerInProgStepClick={onBaselaneApplicationTypePopupOpen}
            key={`open_baselane_banking_landlordbanking_${state}`}
          />
        );

      case 'in_progress':
        return (
          <InProgress
            status={status}
            handleTrackerInProgStepClick={
              hasPendingAccount ? null : onBaselaneApplicationTypePopupOpen
            }
            key={`open_baselane_banking_landlordbanking_${state}`}
          />
        );

      case 'warning':
        return hasSingleAwaitingDocAccount ? (
          <InProgress
            status={status}
            handleTrackerInProgStepClick={() =>
              getKYCUrl({
                variables: { bankId: awaitingDocAccounts[0].id },
              })
            }
            key={`open_baselane_banking_landlordbanking_${state}`}
          />
        ) : (
          <InProgress
            status={status}
            handleTrackerInProgStepClick={() => navigate(NATIVE_BANK)}
            key={`open_baselane_banking_landlordbanking_${state}`}
          />
        );

      case 'alert':
        return (
          <InProgress
            status={status}
            handleTrackerInProgStepClick={() => zendeskAPI('messenger', 'open')}
            key={`open_baselane_banking_landlordbanking_${state}`}
          />
        );

      case 'completed':
        return (
          <InProgress status={status} key={`open_baselane_banking_landlordbanking_${state}`} />
        );

      default:
        return null;
    }
  };

  const getFundYourAccountLink = (status, state) => {
    switch (state) {
      case 'not_started':
        return authorizedForBanking ? (
          <AddFundsDrawer
            isGetStarted
            getStartedConfig={{ status }}
            key={`fund_your_account_landlordbanking_${state}`}
          />
        ) : null;

      case 'completed':
        return (
          <InProgress status={status} key={`open_baselane_banking_landlordbanking_${state}`} />
        );

      default:
        return null;
    }
  };

  const getActivateDebitCardLink = (status, state) => {
    const { cardSummary } = qData ?? {};
    const physicalCard = cardSummary?.data?.[0] ?? {};

    switch (state) {
      case 'not_started':
      case 'in_progress':
        if (cardSummary?.total > 1) {
          return (
            <InProgress
              status={status}
              handleTrackerInProgStepClick={() => navigate(NB_CARDS)}
              key={`open_baselane_banking_landlordbanking_${state}`}
            />
          );
        }

        if (cardSummary?.total === 1) {
          return (
            <Card
              key={physicalCard?.id}
              card={physicalCard}
              onActivationSuccessPopupOpen={() => {
                onActivationSuccessPopupOpen();
                refetchTrackers();
              }}
              getStartedStatus={status}
            />
          );
        }

        return null;

      case 'completed':
        return (
          <InProgress status={status} key={`open_baselane_banking_landlordbanking_${state}`} />
        );

      default:
        return null;
    }
  };

  const getLinkTo = (step, state) => {
    const status = state === 'disabled' ? LINKS[step].not_started : LINKS[step][state];
    switch (step) {
      case 'OPEN_BASELANE_BANKING':
        return getOpenBaselaneBankingLink(status, state);

      case 'FUND_YOUR_ACCOUNT':
        return getFundYourAccountLink(status, state);

      case 'ACTIVATE_DEBIT_CARD':
        return getActivateDebitCardLink(status, state);

      default:
        return null;
    }
  };

  return (
    <>
      <Tracker
        tracker={{ data: trackerData, getLinkTo }}
        steps={data}
        isLoading={loading}
        hasError={error}
        showSteps={showSteps}
        setShowSteps={setShowSteps}
        key="banking"
        showTooltip={!isWorkspaceOwner}
      />
      <ActivationSuccessPopup
        isActivationSuccessPopupOpen={isActivationSuccessPopupOpen}
        onActivationSuccessPopupClose={onActivationSuccessPopupClose}
      />
    </>
  );
};

export default Banking;
