import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { Icon16InProgress, Icon16CheckCircle } from '@icons/16px';

export const reviewStatusOptions = [
  {
    id: 'needs_review',
    name: 'Needs review',
    icon: <Icon16InProgress color="var(--chakra-colors-gray-500)" />,
  },
  {
    id: 'reviewed',
    name: 'Reviewed',
    icon: <Icon16CheckCircle color="var(--chakra-colors-green-500)" />,
  },
];

export const reviewStatusItemRenderer = ({ item, search }) => (
  <HStack textStyle="sm" color="inherit" spacing={2}>
    <Box>{item?.icon}</Box>
    <Box>{item?.name}</Box>
  </HStack>
);

export const selectedReviewStatusItemRenderer = ({ item }) => (
  <HStack textStyle="sm" color="inherit" marginLeft={1.5}>
    <Box>{item?.icon}</Box>
    <Box color={item?.id === 'no_review_option_selected' ? 'gray.500' : 'inherit'}>
      {item?.name}
    </Box>
  </HStack>
);
