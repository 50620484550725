import { useStatsigClient } from '@statsig/react-bindings';
import { FEATURE_GATES } from '@core/constants/statsigKeys';

export const getTabsListItems = () => {
  const { checkGate } = useStatsigClient();
  const isMuaEnabled = checkGate(FEATURE_GATES.MUA_GATE);

  let tabsListItems = ['Profile', 'Business Profile', 'Password', 'Rent Collection'];
  if (isMuaEnabled) tabsListItems = ['Profile', 'Password'];

  return tabsListItems;
};
