import { DateTime } from 'luxon';
import SortFieldEnum from '@shared/components/SortTableHeader/models/SortFieldEnum';
import { dateFormatter, getOrdinal } from '@core/utils/formatDate';

export const config = {
  columns: [
    // 1. Recipient
    {
      isSortable: true,
      styles: {
        w: { lg: '220px', md: '196px', base: '100%' },
        px: 1.5,
      },
    },
    // 2. Payment method
    {
      isSortable: false,
      styles: { w: '180px', px: 1.5, display: { '2lg': 'table-cell', base: 'none' } },
    },
    // 3. Schedule
    {
      isSortable: true,
      styles: {
        w: { lg: 'auto', md: '180px' },
        display: { md: 'table-cell', base: 'none' },
      },
    },
    // 4. Amount
    {
      isSortable: true,
      styles: {
        w: { '2lg': '180px', base: '120px' },
        display: { sm: 'table-cell', base: 'none' },
        contentContainerStyles: {
          justifyContent: 'flex-end',
          gap: '0.5',
        },
      },
    },
    // 5. Status
    {
      isSortable: false,
      styles: {
        w: { '2lg': '240px', base: '180px' },
        contentContainerStyles: {
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          gap: '0.5',
        },
      },
    },
  ],
};

export const tableHeaderItems = [
  {
    key: 'recipient',
    label: 'Recipient',
    field: 'name',
    isSortable: true,
  },
  {
    key: 'paymentMethods',
    label: 'Type',
    field: 'TYPE',
  },
  {
    key: 'schedule',
    label: 'Schedule',
    field: 'transferDate',
    isSortable: true,
  },
  {
    key: 'amount',
    label: 'Amount',
    field: SortFieldEnum.Amount,
    isSortable: true,
  },
  {
    key: 'status',
    label: 'Status',
    field: 'Status',
  },
];

export const PAYMENT_METHOD_TYPE = {
  ACH: 'ACH',
  CHECK_PAYMENT: 'Check',
  WIRE_TRANSFER: 'Wire',
};

export const DEFAULT_SORT_FIELD = 'name';
export const DEFAULT_SORT_DIR = 'DESC';

export const getPaymentTypeName = (typeName) => {
  switch (typeName) {
    case 'Wire Transfer':
      return 'Wire';

    case 'Check Payment':
      return 'Check';

    default:
      return typeName;
  }
};

export const formatTranferType = ({
  paymentMethodType,
  isMobile = false,
  isTransfer = false,
  toTransferAccountId,
}) => {
  const paymentMethodMap = {
    ACH: 'ACH',
    INTERNAL_TRANSFER: 'Internal transfer',
    TRANSFER_IN: 'Transfer in',
    TRANSFER_OUT: toTransferAccountId ? 'Transfer out' : 'ACH',
    WIRE_TRANSFER: 'Wire',
    CHECK_PAYMENT: 'Check',
  };

  const formattedPaymentMethod = paymentMethodMap[paymentMethodType] || paymentMethodType;

  return isMobile ? `${formattedPaymentMethod} to` : formattedPaymentMethod;
};

export const typeOfTransferFormatter = (payment) => {
  const typeToPrefixMap = {
    TRANSFER_IN: 'Transfer between',
    TRANSFER_OUT: payment.toTransferAccountId ? 'Transfer between' : 'ACH to',
    CHECK_PAYMENT: 'Mail a check to',
    WIRE_TRANSFER: 'Wire to',
    INTERNAL_TRANSFER: 'Transfer between',
  };

  return typeToPrefixMap[payment.type] || '';
};

export const describePaymentSchedule = (t, recurrencePattern) => {
  const type = recurrencePattern?.type;
  const monthlyByDate = recurrencePattern?.monthlyByDate;
  const dayOfWeek = recurrencePattern?.daysOfWeek;

  let capitalizedWeekday = '';
  if (dayOfWeek) {
    capitalizedWeekday = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1).toLowerCase();
  }

  if (t === 'One-Time') {
    return 'One-time';
  }

  if (type === 'MONTHLY') {
    const intervalDescription =
      recurrencePattern?.interval > 1
        ? `Every ${recurrencePattern?.interval} months on`
        : 'Monthly on';
    const dateDescription = recurrencePattern?.lastDayOfMonth
      ? 'last day'
      : `the ${getOrdinal(monthlyByDate)}`;
    return `${intervalDescription} ${dateDescription}`;
  }

  // In this case the payment is recurring and weekly.
  const intervalDescription =
    recurrencePattern?.interval > 1 ? `Every ${recurrencePattern?.interval} weeks on` : 'Weekly on';
  return `${intervalDescription} ${capitalizedWeekday}`;
};

export const describePaymentScheduleForMobile = (type = '', recurrencePattern = '', date = '') => {
  const formattedDate = date ? DateTime.fromISO(date).toFormat('MMM dd, yyyy') : 'Invalid date';

  let description = `Once on ${formattedDate}`;
  if (recurrencePattern) {
    description = `Next Payment: ${formattedDate}`;
  }

  return description;
};

export const describePaymentDate = (transferDate, nextPaymentDate) => {
  if (nextPaymentDate) {
    return `Next payment: ${dateFormatter(nextPaymentDate)}`;
  }
  return dateFormatter(transferDate);
};

export const paymentStatusFormatter = (type, totalProcessedTransfers, totalNumberOfPayments) => {
  let formattedStatus = 'Scheduled';
  if (type !== 'One-Time') {
    formattedStatus = totalNumberOfPayments
      ? `${totalProcessedTransfers} of ${totalNumberOfPayments} processed`
      : `${totalProcessedTransfers} of ♾️ processed`;
  }
  return formattedStatus;
};

export const getPayee = (data, isOneTimePayment) => {
  const metadataName = data?.metadata?.payeeName;

  if (isOneTimePayment) {
    return metadataName;
  }

  const payeeName = data?.payeePaymentMethod?.payee?.name;
  return payeeName || metadataName;
};

export const getRecurrance = (transfer, isOneTimePayment) => {
  let recurrance = '';
  const isMonthly = transfer?.recurrencePattern?.type === 'MONTHLY';

  if (isMonthly) {
    const monthDate = transfer?.recurrencePattern?.monthlyByDate;

    let monthDateLabel = '';
    switch (monthDate) {
      case 1:
        monthDateLabel = 'the 1st';
        break;
      case 2:
        monthDateLabel = 'the 2nd';
        break;
      case 3:
        monthDateLabel = 'the 3rd';
        break;
      case 21:
        monthDateLabel = 'the 21st';
        break;
      case 22:
        monthDateLabel = 'the 22nd';
        break;
      case 23:
        monthDateLabel = 'the 23rd';
        break;
      default:
        monthDateLabel = `the ${monthDate}th`;
    }

    if (transfer?.recurrencePattern?.lastDayOfMonth) {
      monthDateLabel = 'last day';
    }

    const frequencyOfRepetitionLabel =
      transfer?.recurrencePattern?.interval > 1
        ? `Every ${transfer.recurrencePattern?.interval} months on`
        : 'Monthly on';

    recurrance = `${frequencyOfRepetitionLabel} ${monthDateLabel}`;
  } else {
    const weekday = transfer?.recurrencePattern?.daysOfWeek;
    let capitalizedWeekday = '';
    if (weekday) {
      capitalizedWeekday = weekday.charAt(0).toUpperCase() + weekday.slice(1).toLowerCase();
    }

    const frequencyOfRepetition = transfer?.recurrencePattern?.interval;
    const frequencyOfRepetitionLabel =
      frequencyOfRepetition > 1 ? `Every ${frequencyOfRepetition} weeks on` : 'Weekly on';

    recurrance = `${frequencyOfRepetitionLabel} ${capitalizedWeekday}`;
  }

  return isOneTimePayment
    ? `Once on ${DateTime.fromISO(transfer?.transferDate).toFormat('MMM d, yyyy')}`
    : recurrance;
};
