import React from 'react';
import { isMobile } from 'react-device-detect';
import { TabList } from '@chakra-ui/react';

import { tabListStyles, formWrapper } from '../styles/tabs.styles';

type TabListComponentProps = {
  children: any,
  styles?: Object,
  isSticky?: boolean,
};

function TabListComponent({ children, styles = {}, isSticky = false }: TabListComponentProps): any {
  const stickyStyles = isSticky ? { position: 'sticky', top: 0, zIndex: 1000 } : {};
  return (
    <TabList {...tabListStyles(isMobile)} {...formWrapper(isMobile)} {...styles} {...stickyStyles}>
      {children}
    </TabList>
  );
}

export default TabListComponent;
