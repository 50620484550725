import React from 'react';
import { ChakraProvider, CSSReset, GlobalStyle } from '@chakra-ui/react';
import { BaselaneErrorBoundary } from '@shared/components';
import fonts from '@core/components/Fonts';
import customTheme from '@theme';

import FingerprintWrapper from './FingerprintWrapper';

type AppWrapperProps = {
  children: any,
};

const AppWrapper = ({ children }: AppWrapperProps) => {
  return (
    <FingerprintWrapper>
      <ChakraProvider theme={customTheme}>
        <CSSReset />
        <GlobalStyle styles={fonts} />
        <BaselaneErrorBoundary>{children}</BaselaneErrorBoundary>
      </ChakraProvider>
    </FingerprintWrapper>
  );
};

export default AppWrapper;
