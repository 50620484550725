import React, { useEffect, useState } from 'react';
import {
  Text,
  Tbody,
  Thead,
  Heading,
  VStack,
  HStack,
  Flex,
  Box,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { TENANT_SCREENING_IDENTITY_VERIFICATION } from '@core/constants/routes';
import {
  BaselaneAlertNew,
  BaselaneCardStack,
  BaselaneChip,
  BaselaneResponsiveCell,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
  BaselaneCardNew,
  DisplayInputWrapper,
  Placeholder,
} from '@shared/components';
import T1 from '@shared/components/BaselaneDropdown-new/T1';
import {
  IllustrationOtherWarning,
  IllustrationOtherIdentityverificationfailed,
} from '@illustrations';
import { Icon16Info, Icon16Clear, Icon16Check } from '@icons/16px';
import { Icon24VideoPlay2 } from '@icons/24px';
import { getReportByid, isReportDataInvalid } from '../helpers/tenantScreeningReports.helpers';
import ReportHeader from '../components/ReportHeader';
import ReportUnavailableAlert from '../components/ReportUnavailableAlert';
import PendingEmptyState from './PendingEmptyState';

type OptionProps = {
  item: Object,
};

const Option = ({ item }: OptionProps) => {
  return (
    <HStack w="full">
      {item?.status === 'failed' ? (
        <Icon16Clear color="#ea6868" />
      ) : (
        <Icon16Check color="#398311" />
      )}
      <Text whiteSpace="nowrap" textStyle="sm">
        {item?.name}
      </Text>
    </HStack>
  );
};

type DisplayInputProps = {
  handleContentRendererClick: Function,
  showValueByFields: Array<string>,
  selectedOptions: Array<Object>,
  title: string,
  placeholder: string,
  classNames: Array<string>,
};

const DisplayInput = ({
  handleContentRendererClick,
  selectedOptions,
  showValueByFields,
  placeholder,
  title,
  classNames,
}: DisplayInputProps) => {
  return (
    <DisplayInputWrapper
      handleContentRendererClick={handleContentRendererClick}
      classNames={classNames}
    >
      {selectedOptions.length > 0 ? (
        <Option item={selectedOptions[0]} />
      ) : (
        <Placeholder placeholder={placeholder} title={title} />
      )}
    </DisplayInputWrapper>
  );
};

const IdentityVerification = () => {
  const { verificationId, externalApplicantId } = useOutletContext();
  const { applicationId } = useParams();
  const { isMax767 } = useBreakPoints();

  const [documentIndex, setDocumentIndex] = useState(0);
  const [selfieIndex, setSelfieIndex] = useState(0);
  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [videoPlayer, setVideoPlayer] = useState(null);
  const [isVideoPlayerOn, setIsVideoPlayerOn] = useState(false);

  useEffect(() => {
    if (applicationId && externalApplicantId && verificationId) {
      setIsLoading(true);
      getReportByid(applicationId, externalApplicantId, verificationId)
        .then(({ data }) => {
          if (data?.name === TENANT_SCREENING_IDENTITY_VERIFICATION) setReportData(data);
        })
        .catch((error) => {
          console.error('Error fetching Identity Report:', error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [applicationId, externalApplicantId, verificationId]);

  useEffect(() => {
    // make sure we setVideoPlayer when video is loaded
    // Play / pause.
    if (!videoPlayer) {
      setVideoPlayer(document.getElementById('videoPlayer'));
    }
  }, [document, selfieIndex, videoPlayer, setVideoPlayer, reportData]);

  const handleClickVideo = (vp) => {
    const isPlaying =
      vp.currentTime > 0 && !vp.paused && !vp.ended && vp.readyState > vp.HAVE_CURRENT_DATA;
    if (isPlaying) {
      try {
        setIsVideoPlayerOn(false);
        vp.pause();
      } catch (e) {
        console.log('video pause failed');
      }
    } else if (!isPlaying) {
      try {
        setIsVideoPlayerOn(true);
        vp.play();
      } catch (e) {
        console.log('video play failed');
      }
    }
  };

  useEffect(() => {
    // make sure we reload when video is loaded
    if (videoPlayer) {
      // Play / pause.
      videoPlayer.removeEventListener('click', () => handleClickVideo(videoPlayer));
      videoPlayer.addEventListener('click', () => handleClickVideo(videoPlayer));
    }
  }, [document, videoPlayer, selfieIndex]);

  if (reportData?.status === 'pending' || isReportDataInvalid(reportData, verificationId)) {
    return reportData?.status === 'pending' ? (
      <PendingEmptyState />
    ) : (
      <ReportUnavailableAlert isLoading={isLoading} />
    );
  }

  const {
    status,
    report: {
      phone = '',
      first_name: firstName = '',
      last_name: lastName = '',
      dob = '',
      address1 = '',
      city = '',
      country = '',
      zip = '',
      state = '',
      ssn = '',
      name_summary: nameSummary,
      dob_summary: dobSummary,
      phone_summary: phoneSummary,
      address_summary: addressSummary,
      ssn_summary: ssnSummary,
      selfie_check_status: selfieCheckStatus,
      documents,
      selfie_check: selfieCheck,
    },
  } = reportData;

  const summaries = [nameSummary, dobSummary, phoneSummary, addressSummary, ssnSummary];
  const reportsMatch = summaries.every((summary) => summary === 'match');
  const currentDocument = documents?.[documentIndex] ?? {};
  const documentVerificationSucceeded = documents?.some(
    (document) => document?.status === 'success' || document?.status === 'manually_approved'
  );
  const selfies = selfieCheck?.selfies;
  const selfieMatchesDocument = selfies?.[selfieIndex]?.analysis?.document_comparison === 'match';

  const createTopBanner = (value) => {
    switch (value) {
      case 'finished_with_warnings':
        return (
          <BaselaneAlertNew
            body="Applicant has been instructed to contact support to resolve their failed identity verification."
            hasCloseButton={false}
            iconName={IllustrationOtherWarning}
            title="Identity verification passed with warnings"
            variant="warning"
            visual="illustration"
          />
        );
      case 'failed':
        return (
          <BaselaneAlertNew
            body="AApplicant has been instructed to contact support to resolve their failed identity verification."
            hasCloseButton={false}
            iconName={IllustrationOtherIdentityverificationfailed}
            title="Identity verification failed"
            variant="danger"
            visual="illustration"
          />
        );
      default:
        return null;
    }
  };

  const createRecordChip = (value) => {
    switch (value) {
      case 'match':
        return <BaselaneChip variant="simple-success" size="sm" label="Match" />;
      case 'no_match':
        return <BaselaneChip variant="simple-danger" size="sm" label="Failed" />;
      default:
        return <BaselaneChip variant="simple-neutral" size="sm" label="No data" />;
    }
  };

  const getDocumentName = (value) => {
    switch (value) {
      case 'drivers_license':
        return "Driver's License";
      case 'id_card':
        return 'ID Card';
      case 'passport':
        return 'Passport';
      case 'residence_permit_card':
        return 'Residence Permit Card';
      case 'resident_card':
        return 'Resident Card';
      case 'visa':
        return 'Visa';
      default:
        return 'Unknown Document';
    }
  };

  const getDocumentationChip = (value) => {
    switch (value) {
      case 'match':
        return <BaselaneChip variant="simple-success" size="sm" label="Match" />;
      case 'partial_match':
        return <BaselaneChip variant="simple-warning" size="sm" label="Partial match" />;
      case 'no_match':
        return <BaselaneChip variant="simple-danger" size="sm" label="No match" />;
      default:
        return <BaselaneChip variant="simple-neutral" size="sm" label="No data" />;
    }
  };

  const getImageQualityChip = (value) => {
    switch (value) {
      case 'high':
        return <BaselaneChip variant="simple-success" size="sm" label="High" />;
      case 'medium':
        return <BaselaneChip variant="simple-warning" size="sm" label="Medium" />;
      default:
        return <BaselaneChip variant="simple-danger" size="sm" label="Low" />;
    }
  };

  const getExpirationDateChip = (value) => {
    switch (value) {
      case 'not_expired':
        return <BaselaneChip variant="simple-success" size="sm" label="Not expired" />;
      case 'expired':
        return <BaselaneChip variant="simple-danger" size="sm" label="Expired" />;
      default:
        return <BaselaneChip variant="simple-neutral" size="sm" label="No data" />;
    }
  };

  const getSelfieChip = () => {
    if (!selfies) {
      return <BaselaneChip variant="simple-success" size="sm" label="No input" />;
    }
    if (selfieMatchesDocument) {
      return <BaselaneChip variant="simple-success" size="sm" label="Match" />;
    }
    return <BaselaneChip variant="simple-danger" size="sm" label="No match" />;
  };

  const getDocumentsVerificationChip = () => {
    const someSucceeded = documents?.some(
      (document) => document?.status === 'success' || document?.status === 'manually_approved'
    );
    if (someSucceeded) {
      return <BaselaneChip variant="simple-success" size="sm" label="Match" />;
    }
    return <BaselaneChip variant="simple-danger" size="sm" label="Failed" />;
  };

  const documentDropdownItems = documents?.map((document) => ({
    id: document?.attempt,
    name: `Attempt ${document?.attempt}`,
    status: document?.status,
  }));

  const selfieDropdownItems = selfies?.map((selfie) => ({
    id: selfie?.attempt,
    name: `Attempt ${selfie?.attempt}`,
    status: selfie?.status,
  }));

  const itemRenderer = ({ item }) => {
    return <Option item={item} />;
  };

  const selectedDocumentItem = {
    id: currentDocument?.attempt,
    name: `Attempt ${currentDocument?.attempt}`,
    status: currentDocument?.status,
  };

  const selectedSelfieItem = {
    id: selfies?.[selfieIndex]?.attempt,
    name: `Attempt ${selfies?.[selfieIndex]?.attempt}`,
    status: selfies?.[selfieIndex]?.status,
  };

  const tableColumnConfig = {
    columns: [
      {
        isSortable: false,
        styles: {
          w: isMax767 ? 'auto' : '320px',
          contentContainerStyles: {
            px: 0,
            py: 2,
          },
          overflow: 'visible',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          verticalAlign: 'top',
        },
      },
      {
        isSortable: false,
        styles: {
          overflow: 'visible',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 0,
            py: 2,
          },
        },
      },
    ],
  };

  // Selfie extension determination, image or movie
  const getUrlExtension = (url) => {
    return url?.split(/[#?]/)[0]?.split('.')?.pop()?.trim();
  };
  let currentSelfieExtension = getUrlExtension(
    selfies?.[selfieIndex]?.assets?.liveness?.asset?.url
  );
  if (['mp4', 'webm', 'jpeg', 'jpg', 'png'].indexOf(currentSelfieExtension) === -1) {
    currentSelfieExtension = selfies?.[selfieIndex]?.assets?.liveness?.asset?.extension;
  }

  return (
    <>
      <VStack alignItems="flex-start" gap={4}>
        {createTopBanner(status)}
        <Box>
          <Flex h="36px">
            <Text
              textStyle="sm"
              w={{ base: '164px', xs: '160px', sm: '270px', md: '320px' }}
              flexShrink={0}
              mr="4"
              whiteSpace="normal"
              overflow="visible"
              wordBreak="break-word"
            >
              Records match verification
            </Text>
            <Box w="100%">
              {reportsMatch ? (
                <BaselaneChip variant="simple-success" size="sm" label="Success" />
              ) : (
                <BaselaneChip variant="simple-danger" size="sm" label="Failed" />
              )}
            </Box>
          </Flex>
          <Flex h="36px">
            <Text
              textStyle="sm"
              w={{ base: '164px', xs: '160px', sm: '270px', md: '320px' }}
              flexShrink={0}
              mr="4"
              whiteSpace="normal"
              overflow="visible"
              wordBreak="break-word"
            >
              Documents verification
            </Text>
            <Box w="100%">{getDocumentsVerificationChip()}</Box>
          </Flex>
          <Flex h="36px">
            <Text
              textStyle="sm"
              w={{ base: '164px', xs: '160px', sm: '270px', md: '320px' }}
              flexShrink={0}
              mr="4"
              whiteSpace="normal"
              overflow="visible"
              wordBreak="break-word"
            >
              Selfie check
            </Text>
            <Box w="100%">
              {selfieCheckStatus === 'success' ? (
                <BaselaneChip variant="simple-success" size="sm" label="Success" />
              ) : (
                <BaselaneChip variant="simple-danger" size="sm" label="Failed" />
              )}
            </Box>
          </Flex>
        </Box>

        {/* Self reported information */}
        <BaselaneCardStack
          id="self-reported-card"
          size="md"
          stackStyles={{ border: 'none' }}
          items={[
            {
              id: 'self-reported-header',
              content: <ReportHeader>Self-reported information</ReportHeader>,
            },
            {
              id: 'self-reported-basic-info',
              content: (
                <BaselaneResponsiveTable
                  id="review-columns"
                  config={tableColumnConfig}
                  maxW="600px"
                  tableContainerStyles={{
                    overflowY: 'initial',
                    overflowX: 'initial',
                  }}
                >
                  <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                    <BaselaneResponsiveCellTitle
                      title="Basic information"
                      titleSize="headline-md"
                      styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                      p={0}
                      configIndex={0}
                      colSpan="2"
                    />
                  </BaselaneResponsiveTableRow>

                  <Tbody>
                    <BaselaneResponsiveTableRows
                      items={[
                        { heading: 'Full name', data: `${firstName} ${lastName}` },
                        { heading: 'Phone', data: phone },
                        { heading: 'Birthday', data: dob },
                        { heading: 'SSN', data: ssn },
                      ]}
                      customRow
                      renderItem={(row) => {
                        return (
                          <BaselaneResponsiveTableRow
                            key={row.heading}
                            chevronCellStyles={{ w: 0 }}
                            contentContainerStyles={{ border: 'none' }}
                          >
                            <BaselaneResponsiveCell configIndex={0} p={0}>
                              <Text as="span" textStyle="sm" color="brand.neutral.700">
                                {row.heading}
                              </Text>
                            </BaselaneResponsiveCell>
                            <BaselaneResponsiveCell configIndex={1} p={0}>
                              <Text textStyle="sm">{row.data}</Text>
                            </BaselaneResponsiveCell>
                          </BaselaneResponsiveTableRow>
                        );
                      }}
                    />
                  </Tbody>
                </BaselaneResponsiveTable>
              ),
            },
            {
              id: 'self-reported-address',
              content: (
                <BaselaneResponsiveTable
                  id="review-columns"
                  config={tableColumnConfig}
                  maxW="600px"
                  tableContainerStyles={{
                    overflowY: 'initial',
                    overflowX: 'initial',
                  }}
                >
                  <Thead>
                    <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                      <BaselaneResponsiveCellTitle
                        title="Address"
                        titleSize="headline-md"
                        styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                        p={0}
                        configIndex={0}
                        colSpan="2"
                      />
                    </BaselaneResponsiveTableRow>
                  </Thead>
                  <Tbody>
                    <BaselaneResponsiveTableRows
                      items={[
                        { heading: 'Street address', data: address1 },
                        { heading: 'City', data: city },
                        { heading: 'State/Province/Region', data: state },
                        { heading: 'Zip Postal code', data: zip },
                        { heading: 'Country', data: country },
                      ]}
                      customRow
                      renderItem={(row) => {
                        return (
                          <BaselaneResponsiveTableRow
                            key={row.heading}
                            chevronCellStyles={{ w: 0 }}
                            contentContainerStyles={{ border: 'none' }}
                          >
                            <BaselaneResponsiveCell configIndex={0} p={0}>
                              <Text as="span" textStyle="sm" color="brand.neutral.700">
                                {row.heading}
                              </Text>
                            </BaselaneResponsiveCell>
                            <BaselaneResponsiveCell configIndex={1} p={0}>
                              <Text textStyle="sm">{row.data}</Text>
                            </BaselaneResponsiveCell>
                          </BaselaneResponsiveTableRow>
                        );
                      }}
                    />
                  </Tbody>
                </BaselaneResponsiveTable>
              ),
            },
          ]}
        />
        {/* Records match verification */}
        <BaselaneCardStack
          id="records-match-card"
          size="md"
          items={[
            {
              id: 'records-match-header',
              content: (
                <BaselaneCardNew
                  variant="inner"
                  size="md"
                  palette={reportsMatch ? 'dark' : 'warning'}
                >
                  <Heading
                    size="headline-md"
                    w="full"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Flex gap="8px" alignItems="center">
                      <Box>Records match verification</Box>
                      <Tooltip
                        placement="right-start"
                        hasArrow
                        label="Verifies a user's name, address, date of birth, phone number and ID number (such as SSN number) against available records"
                      >
                        <Icon16Info />
                      </Tooltip>
                    </Flex>
                    {!reportsMatch && (
                      <BaselaneChip variant="simple-danger" size="sm" label="Failed" />
                    )}
                  </Heading>
                </BaselaneCardNew>
              ),
            },
            {
              id: 'records-match-data',
              content: (
                <BaselaneResponsiveTable
                  id="review-columns"
                  config={tableColumnConfig}
                  maxW="600px"
                  tableContainerStyles={{
                    overflowY: 'initial',
                    overflowX: 'initial',
                  }}
                >
                  <Tbody>
                    <BaselaneResponsiveTableRows
                      items={[
                        {
                          heading: 'Name',
                          subheading: `${firstName} ${lastName}`,
                          data: createRecordChip(nameSummary),
                        },
                        {
                          heading: 'Date of birth',
                          subheading: dob,
                          data: createRecordChip(dobSummary),
                        },
                        {
                          heading: 'Phone',
                          subheading: phone,
                          data: createRecordChip(phoneSummary),
                        },
                        {
                          heading: 'Address',
                          subheading: address1,
                          data: createRecordChip(addressSummary),
                        },
                        {
                          heading: 'Tax ID Number (SSN)',
                          subheading: ssn,
                          data: createRecordChip(ssnSummary),
                        },
                      ]}
                      customRow
                      renderItem={(row) => {
                        return (
                          <BaselaneResponsiveTableRow
                            key={row.heading}
                            chevronCellStyles={{ w: 0 }}
                            contentContainerStyles={{ border: 'none' }}
                          >
                            <BaselaneResponsiveCell configIndex={0} p={0}>
                              <VStack alignItems="flex-start" gap="0px">
                                <Text
                                  as="span"
                                  textStyle="md"
                                  color="brand.neutral.700"
                                  fontWeight="medium"
                                >
                                  {row.heading}
                                </Text>
                                <Text as="span" textStyle="sm" color="brand.neutral.700">
                                  {row.subheading}
                                </Text>
                              </VStack>
                            </BaselaneResponsiveCell>
                            <BaselaneResponsiveCell configIndex={1} p={0}>
                              <Text textStyle="sm">{row.data}</Text>
                            </BaselaneResponsiveCell>
                          </BaselaneResponsiveTableRow>
                        );
                      }}
                    />
                  </Tbody>
                </BaselaneResponsiveTable>
              ),
            },
          ]}
        />
        {/* Document verification */}
        <BaselaneCardStack
          id="document-verification-card"
          size="md"
          stackStyles={{ border: 'none' }}
          {...{ w: '100%' }}
          items={[
            {
              id: 'document-verification-header',
              content: (
                <BaselaneCardNew
                  variant="inner"
                  size="md"
                  palette={documentVerificationSucceeded ? 'dark' : 'warning'}
                >
                  <Heading
                    size="headline-md"
                    w="full"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Flex gap="8px" alignItems="center">
                      <Box>Document verification</Box>
                      <Tooltip
                        placement="right-start"
                        hasArrow
                        label="Document verification prompts the user to upload an image of an identity document. Plaid will use anti-fraud checks to verify that the document appears to be legitimate and is unexpired. Plaid will also verify the date of birth and name on the document against the data provided by the user"
                      >
                        <Icon16Info />
                      </Tooltip>
                    </Flex>
                    {!documentVerificationSucceeded && (
                      <BaselaneChip variant="simple-danger" size="sm" label="Failed" />
                    )}
                  </Heading>
                </BaselaneCardNew>
              ),
            },
            {
              id: 'document-verification-images',
              content: (
                <div>
                  <Box w="200px" display={documents?.length > 1 ? 'block' : 'none'} mb="16px">
                    <T1
                      classNames={[
                        'auto-width-dropdown',
                        'is-full-width',
                        'auto-height',
                        'input-form-md',
                        'select-auto-width',
                      ]}
                      hideSearch
                      data={documentDropdownItems}
                      selectedItem={selectedDocumentItem}
                      itemRenderer={itemRenderer}
                      CustomDisplayInput={DisplayInput}
                      handleSubmit={(value) => {
                        setDocumentIndex(value - 1);
                      }}
                    />
                  </Box>
                  <Flex gap="16px" direction={{ base: 'column', md: 'row' }}>
                    <Box border="1px solid #e2e8f0" borderRadius="8px">
                      <Box style={{ width: '320px', height: '200px' }}>
                        <Image
                          alt=""
                          src={currentDocument?.assets?.original_front?.asset.url}
                          w="100%"
                          h="100%"
                          borderTopLeftRadius="8px"
                          borderTopRightRadius="8px"
                          fallback={
                            <Box
                              backgroundColor="#f0f1f3"
                              w="100%"
                              h="100%"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              color="#666"
                            >
                              No image available
                            </Box>
                          }
                        />
                      </Box>
                      <Text textStyle="sm" padding="12px 0px" textAlign="center">
                        Front of document
                      </Text>
                    </Box>
                    <Box border="1px solid #e2e8f0" borderRadius="8px">
                      <Box style={{ width: '320px', height: '200px' }}>
                        <Image
                          alt=""
                          src={currentDocument?.assets?.original_back?.asset.url}
                          w="100%"
                          h="100%"
                          borderTopLeftRadius="8px"
                          borderTopRightRadius="8px"
                          fallback={
                            <Box
                              backgroundColor="#f0f1f3"
                              w="100%"
                              h="100%"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              color="#666"
                            >
                              No image available
                            </Box>
                          }
                        />
                      </Box>
                      <Text textStyle="sm" padding="12px 0px" textAlign="center">
                        Back of document
                      </Text>
                    </Box>
                  </Flex>
                </div>
              ),
            },
            {
              id: 'document-verification-extracted-info',
              content: (
                <BaselaneResponsiveTable
                  id="review-columns"
                  config={tableColumnConfig}
                  maxW="600px"
                  tableContainerStyles={{
                    overflowY: 'initial',
                    overflowX: 'initial',
                  }}
                >
                  <Thead>
                    <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                      <BaselaneResponsiveCellTitle
                        title={
                          <Flex gap="8px" alignItems="center">
                            Extracted information
                            <Tooltip
                              placement="right-start"
                              hasArrow
                              label="Data extracted from a user-submitted document"
                            >
                              <Icon16Info />
                            </Tooltip>
                          </Flex>
                        }
                        titleSize="headline-md"
                        styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                        p={0}
                        configIndex={0}
                        colSpan="2"
                      />
                    </BaselaneResponsiveTableRow>
                  </Thead>
                  <Tbody>
                    <BaselaneResponsiveTableRows
                      items={[
                        {
                          heading: 'Extracted document type',
                          data: getDocumentName(currentDocument?.extracted_data?.category),
                        },
                        {
                          heading: 'Country of issue',
                          data: currentDocument?.extracted_data?.issuing_region,
                        },
                        {
                          heading: 'Region of issue',
                          data: currentDocument?.extracted_data?.issuing_region,
                        },
                        {
                          heading: 'Date of birth',
                          data: currentDocument?.extracted_data?.date_of_birth,
                        },
                        {
                          heading: 'Document number',
                          data: currentDocument?.extracted_data?.id_number,
                        },
                        {
                          heading: 'Expiration date',
                          data: currentDocument?.extracted_data?.expiration_date,
                        },
                      ]}
                      customRow
                      renderItem={(row) => {
                        return (
                          <BaselaneResponsiveTableRow
                            key={row.heading}
                            chevronCellStyles={{ w: 0 }}
                            contentContainerStyles={{ border: 'none' }}
                          >
                            <BaselaneResponsiveCell configIndex={0} p={0}>
                              <Text as="span" textStyle="sm" color="brand.neutral.700">
                                {row.heading}
                              </Text>
                            </BaselaneResponsiveCell>
                            <BaselaneResponsiveCell configIndex={1} p={0}>
                              <Text textStyle="sm">{row.data}</Text>
                            </BaselaneResponsiveCell>
                          </BaselaneResponsiveTableRow>
                        );
                      }}
                    />
                  </Tbody>
                </BaselaneResponsiveTable>
              ),
            },
            {
              id: 'document-verification-fraud-analysis',
              content: (
                <BaselaneResponsiveTable
                  id="review-columns"
                  config={tableColumnConfig}
                  maxW="600px"
                  tableContainerStyles={{
                    overflowY: 'initial',
                    overflowX: 'initial',
                  }}
                >
                  <Thead>
                    <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                      <BaselaneResponsiveCellTitle
                        title={
                          <Flex gap="8px" alignItems="center">
                            Fraud analysis
                            <Tooltip
                              placement="right-start"
                              hasArrow
                              label="High level descriptions of how the associated document was processed. If a document fails verification, the details in the analysis object should help clarify why the document was rejected"
                            >
                              <Icon16Info />
                            </Tooltip>
                          </Flex>
                        }
                        titleSize="headline-md"
                        styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                        p={0}
                        configIndex={0}
                        colSpan="2"
                      />
                    </BaselaneResponsiveTableRow>
                  </Thead>
                  <Tbody>
                    <BaselaneResponsiveTableRows
                      items={[
                        {
                          heading: 'Authenticity',
                          data: getDocumentationChip(currentDocument?.analysis?.authenticity),
                        },
                        {
                          heading: 'Image quality',
                          data: getImageQualityChip(currentDocument?.analysis?.image_quality),
                        },
                        {
                          heading: 'Name matches ID',
                          data: getDocumentationChip(
                            currentDocument?.analysis?.extracted_data?.name
                          ),
                        },
                        {
                          heading: 'Date of birth matches ID',
                          data: getDocumentationChip(
                            currentDocument?.analysis?.extracted_data?.date_of_birth
                          ),
                        },
                        {
                          heading: 'Expiration date',
                          data: getExpirationDateChip(
                            currentDocument?.analysis?.extracted_data?.expiration_date
                          ),
                        },
                        {
                          heading: 'Issuing country',
                          data: getDocumentationChip(
                            currentDocument?.analysis?.extracted_data?.issuing_country
                          ),
                        },
                      ]}
                      customRow
                      renderItem={(row) => {
                        return (
                          <BaselaneResponsiveTableRow
                            key={row.heading}
                            chevronCellStyles={{ w: 0 }}
                            contentContainerStyles={{ border: 'none' }}
                          >
                            <BaselaneResponsiveCell configIndex={0} p={0}>
                              <Text as="span" textStyle="sm" color="brand.neutral.700">
                                {row.heading}
                              </Text>
                            </BaselaneResponsiveCell>
                            <BaselaneResponsiveCell configIndex={1} p={0}>
                              <Text textStyle="sm">{row.data}</Text>
                            </BaselaneResponsiveCell>
                          </BaselaneResponsiveTableRow>
                        );
                      }}
                    />
                  </Tbody>
                </BaselaneResponsiveTable>
              ),
            },
          ]}
        />
        {/* Selfie match with id */}
        <BaselaneCardStack
          id="selfie-match-with-id-card"
          size="md"
          w="100%"
          stackStyles={{ border: 'none' }}
          items={[
            {
              id: 'selfie-match-with-id-header',
              content: (
                <BaselaneCardNew
                  variant="inner"
                  size="md"
                  w="100%"
                  palette={selfieMatchesDocument || !selfies ? 'dark' : 'warning'}
                >
                  <Heading
                    size="headline-md"
                    w="full"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box>Selfie match with id</Box>
                    {getSelfieChip()}
                  </Heading>
                </BaselaneCardNew>
              ),
            },
            {
              id: 'selfie',
              content: (
                <div>
                  <Box w="200px" display={selfies?.length > 1 ? 'block' : 'none'} mb="16px">
                    <T1
                      classNames={[
                        'auto-width-dropdown',
                        'is-full-width',
                        'auto-height',
                        'input-form-md',
                        'select-auto-width',
                      ]}
                      hideSearch
                      data={selfieDropdownItems}
                      selectedItem={selectedSelfieItem}
                      itemRenderer={itemRenderer}
                      CustomDisplayInput={DisplayInput}
                      handleSubmit={(value) => {
                        setSelfieIndex(value - 1);
                        setVideoPlayer(null);
                        setIsVideoPlayerOn(false);
                      }}
                    />
                  </Box>
                  {['mp4', 'webm'].indexOf(currentSelfieExtension) === -1 && (
                    <Box style={{ width: '240px', height: '320px' }}>
                      <Image
                        alt=""
                        src={selfies?.[selfieIndex]?.assets?.liveness?.asset?.url}
                        w="100%"
                        h="100%"
                        objectFit="cover"
                        borderRadius="8px"
                        fallback={
                          <Box
                            backgroundColor="#f0f1f3"
                            w="100%"
                            h="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            color="#666"
                            borderRadius="8px"
                          >
                            Image not available
                          </Box>
                        }
                      />
                    </Box>
                  )}
                  {['mp4', 'webm'].indexOf(currentSelfieExtension) > -1 && (
                    <Box
                      style={{
                        width: '240px',
                        height: '320px',
                        borderRadius: '8px',
                        overflow: 'hidden',
                      }}
                    >
                      <Flex
                        position="absolute"
                        width="240px"
                        height="320px"
                        direction="column"
                        justifyContent="center"
                        textAlign="center"
                        zIndex="10"
                        pointerEvents="none"
                        id="video-shim"
                        alignItems="center"
                      >
                        {!isVideoPlayerOn && <Icon24VideoPlay2 pointerEvents="none" />}
                      </Flex>
                      <video id="videoPlayer" width="240" height="320">
                        <track kind="captions" />
                        <source
                          src={selfies?.[selfieIndex]?.assets?.liveness?.asset?.url}
                          type="video/mp4"
                        />
                        <source
                          src={selfies?.[selfieIndex]?.assets?.liveness?.asset?.url}
                          type="video/webm"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </Box>
                  )}
                </div>
              ),
            },
          ]}
        />
      </VStack>
      <Outlet />
    </>
  );
};

export default IdentityVerification;
