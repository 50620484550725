import React, { useContext, useRef, useState } from 'react';
import { Box, Flex, Stack, Text, useDisclosure } from '@chakra-ui/react';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { IllustrationBankingAddexternalAccount } from '@illustrations';
import UserContext from '@contexts/UserContext';
import { BaselaneButton, BaselaneCard, BaselaneExternalBankingTypePopup } from '@shared/components';
import {
  cardBodyStyles,
  cardContainerStyles,
  cardTitleStyles,
  iconContainerTopStyles,
  cardLinkBoxStyles,
} from '@shared/styles/typePopupButtons.styles';

type ExternalBankingCardProps = {
  addAccountManuallyDrawerRef: any,
  setShowReceivingBAEmptyState: Function,
  setHasConnectedBank: Function,
  refetchBankAccounts: Function,
  linkedAccountsDrawerOnClose?: Function,
  banksCount: Object,
};

const ExternalBankingCard = ({
  addAccountManuallyDrawerRef,
  setShowReceivingBAEmptyState,
  setHasConnectedBank,
  refetchBankAccounts,
  linkedAccountsDrawerOnClose = {},
  banksCount,
}: ExternalBankingCardProps) => {
  const { user } = useContext(UserContext);
  const {
    isOpen: isExternalBankingTypePopupOpen,
    onOpen: onExternalBankingTypePopupOpen,
    onClose: onExternalBankingTypePopupClose,
  } = useDisclosure();

  const [isVisibilityHidden, setIsVisibilityHidden] = useState(!isExternalBankingTypePopupOpen);
  const addExternalAccountDrawerRef = useRef();

  const handleActionButton = (type) => {
    if (type === 'manual') {
      onExternalBankingTypePopupClose();
      addAccountManuallyDrawerRef?.current?.open();
    }
  };

  const handleOpenExternalBankingPopup = () => {
    sendSegmentEvent(
      `${user.onboardingCompleted ? 'baselane' : 'onboarding'}_rc_click_connect_bank_account`,
      {
        landlord_uuid: user.id,
        landlord_name: user.firstName,
        landlord_email: user.email,
        bank_type: 'external',
        external_account_count: banksCount?.external,
      }
    );
    onExternalBankingTypePopupOpen();
    setIsVisibilityHidden(false);
  };

  const addAccountProps = {
    isFromLease: true,
    isDirectToPlaid: true,
    hideButton: true,
    helperFunction: () => {
      setHasConnectedBank(true);
      setShowReceivingBAEmptyState(false);
    },
    handleAddAccountSuccessCallback: () => setIsVisibilityHidden(true),
    refetchLeaseBankAccounts: refetchBankAccounts,
    addExternalAccountDrawerRef,
  };

  return (
    <>
      <BaselaneExternalBankingTypePopup
        handleActionButton={handleActionButton}
        isOpen={isExternalBankingTypePopupOpen}
        onClose={onExternalBankingTypePopupClose}
        overlayStyles={{ visibility: isVisibilityHidden && 'hidden' }}
        containerStyles={{ visibility: isVisibilityHidden && 'hidden' }}
        addAccountProps={addAccountProps}
        linkedAccountsDrawerOnClose={linkedAccountsDrawerOnClose}
      />

      <BaselaneCard styles={cardContainerStyles}>
        <Box {...iconContainerTopStyles}>
          <IllustrationBankingAddexternalAccount />
        </Box>
        <Stack alignItems="flex-start" flexDirection="column">
          <Box>
            <Text {...cardTitleStyles}>Connect External Account</Text>
            <Text {...cardBodyStyles}>
              Add an external account with bank credentials or manually using account/routing
              number.
            </Text>
          </Box>
          <Flex direction="column" justifyContent="flex-start">
            <Box {...cardLinkBoxStyles} w="100%">
              <BaselaneButton
                variant="transparent"
                palette="primary"
                id="external-account-button"
                onClick={handleOpenExternalBankingPopup}
              >
                See connection options
              </BaselaneButton>
            </Box>
          </Flex>
        </Stack>
      </BaselaneCard>
    </>
  );
};

export default ExternalBankingCard;
