import React, { useEffect } from 'react';
import { useField } from 'formik';
import { BaselaneButtonToggle } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { useSetIsOneTimePayment } from '../../../sendFundsStore';

const ScheduledPaymentTypeToggle = () => {
  const { isMax576 } = useBreakPoints();
  const setIsOneTimePayment = useSetIsOneTimePayment();
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField('scheduledPaymentType');

  useEffect(() => {
    // TODO: Currently the [isOneTimePayment] is used extensively in the feature. Consider refactoring to use the form field [scheduledPaymentType] instead.
    setIsOneTimePayment(field.value === 'one-time');
  }, [field.value]);

  return (
    <BaselaneButtonToggle
      isFullWidth
      firstLabel="One-time payment"
      secondLabel="Recurring payment"
      firstId="one-time-payment-toggle-button"
      secondId="recurring-payment-toggle-button"
      activeButton={field.value === 'recurring' ? 1 : 0}
      onClick={(value) => helpers.setValue(value === 1 ? 'recurring' : 'one-time')}
      size={isMax576 ? 'md' : 'lg'}
      rightIsDisabled={false}
      rightTooltipText={false}
    />
  );
};

export default ScheduledPaymentTypeToggle;
