import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  HStack,
  Stack,
  Text,
  Tbody,
  VStack,
  Skeleton,
  useToast,
} from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { useLazyQuery, useMutation } from '@apollo/client';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import {
  BaselaneAlertNew,
  BaselaneButton,
  BaselaneButtonIcon,
  BaselaneConditionalTooltip,
  BaselaneDivider,
  BaselaneMenu,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableHeader,
  BaselaneResponsiveSortableTableHeading,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveCell,
} from '@shared/components';
import { Icon16Edit, Icon16Info, Icon16VideoPause, Icon16VideoPlay } from '@icons/16px';
import { Icon24ArrowBack } from '@icons/24px';
import { IllustrationOtherInsights } from '@illustrations';
import {
  TENANT_SCREENING,
  TENANT_SCREENING_APPLICANT,
  TENANT_SCREENING_APPLICATION,
  TENANT_SCREENING_INVITE,
  TENANT_SCREENING_EDIT,
  TENANT_SCREENING_REPORTS_SUMMARY,
} from '@routes';
import formatCurrency from '@core/utils/formatCurrency';
import { dateFormatter } from '@core/utils/formatDate';
import {
  GET_TENANT_SCREENINGS,
  GET_TENANT_SCREENING_BY_ID,
  TURN_OFF_TENANT_SCREENING,
} from '@core/apollo/queries/tenantScreening';
import EmptyState from './EmptyState';
import StatusChip from '../components/StatusChip';
import ApprovedBannerActions from '../components/ApprovedBannerActions';
import { innerContainerStyles } from '../styles/tenantScreening.styles';
import {
  tenantScreeningByIdListConfig,
  tenantScreeningByIdListTableHeaderItems,
  convertTenantScreeningsById,
} from '../helpers/tenantScreening.helpers';
import { getCallToActionLabel } from '../helpers/tenantScreeningDetail.helpers';

const NEXT_STEPS_BANNER_DAYS = 60;

const TenantScreeningApplications = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { screeningId } = params;
  const { state, pathname } = location || {};
  const { editTenantScreeningSuccessful } = state || {};
  const [dataFromUrl, setDataFromUrl] = useState(location.state);
  const [applications, setApplications] = useState([]);
  const [tenantScreeningById, setTenantScreeningById] = useState(null);

  const [sortOptions, setSortOptions] = useState({
    sort: {
      field: 'applicationStatus',
      direction: 'ASC',
    },
  });
  const { isMax576, isMin768, isMin899 } = useBreakPoints();

  // call to BE to get the applications data
  const [getTenantScreeningsById, { data }] = useLazyQuery(GET_TENANT_SCREENING_BY_ID);

  // turn tenant screening on or off for applications
  const [toggleTenantScreeningOnOff] = useMutation(TURN_OFF_TENANT_SCREENING, {
    refetchQueries: [
      {
        query: GET_TENANT_SCREENINGS,
        variables: {
          input: {},
        },
      },
    ],
  });

  const isReportDetailPage = location.pathname.includes(TENANT_SCREENING_APPLICANT);
  const isScreeningReportSummaryPage = location.pathname.includes(TENANT_SCREENING_REPORTS_SUMMARY);

  const handleApplicationClick = (application) => {
    if (!application?.id) {
      return;
    }

    const safePathname = pathname ?? window.location.pathname;
    const basePath = safePathname.endsWith('/') ? safePathname : `${safePathname}/`;

    const targetPath = `${basePath}${TENANT_SCREENING_APPLICATION}/${application.id}${
      (application?.applicants?.length ?? 0) > 1 ? `/${TENANT_SCREENING_REPORTS_SUMMARY}` : ''
    }`;

    if (!targetPath) {
      return;
    }

    navigate({ pathname: targetPath });
  };

  const handleToggleApplicationsOnOff = (tenantScreeningId, currentStatus) => {
    toggleTenantScreeningOnOff({
      variables: {
        tenantScreeningId: Number(tenantScreeningId),
        input: {
          active: !currentStatus,
        },
      },
    });
  };

  const handleInvite = () => {
    sendSegmentEvent('tenant_screening_fe_invite_applicants_clicked', {
      from: location.pathname,
    });
    navigate(
      {
        pathname: `${TENANT_SCREENING_INVITE}`,
      },
      { state: dataFromUrl }
    );
  };

  // init with first data call and each sortOptions change
  useEffect(() => {
    getTenantScreeningsById({
      fetchPolicy: 'network-only',
      variables: {
        id: Number(screeningId),
        input: sortOptions,
      },
    });
  }, [sortOptions]);

  useEffect(() => {
    if (data) {
      const screeningById = data?.getTenantScreeningById;
      setTenantScreeningById(screeningById);
      setApplications(convertTenantScreeningsById(screeningById) || []);
    }
  }, [data]);

  // init with first data call
  useEffect(() => {
    setDataFromUrl(state);
  }, []);

  useEffect(() => {
    if (editTenantScreeningSuccessful) {
      toast({
        position: 'bottom-left',
        description: 'Changes saved.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [state]);

  const isPropertyUnitDeleted =
    data?.getTenantScreeningById?.isPropertyDeleted || data?.getTenantScreeningById?.isUnitDeleted;

  const showNextStepsBanner =
    !isPropertyUnitDeleted &&
    data?.getTenantScreeningById?.applications.filter((application) => {
      return (
        NEXT_STEPS_BANNER_DAYS -
          Math.floor(
            DateTime.now().diff(DateTime.fromISO(application?.decisionDate), 'days').days
          ) >
        0
      );
    }).length > 0;

  return !isReportDetailPage && !isScreeningReportSummaryPage ? (
    <>
      <VStack
        alignItems="flex-start"
        w="full"
        gap={4}
        px={isMin899 ? '0' : '16px'}
        pb="32px"
        mt={isMin899 ? '0' : '2'}
      >
        <HStack gap={3} w="full" flexWrap="wrap">
          <Skeleton isLoaded={tenantScreeningById}>
            <BaselaneButtonIcon
              icon={<Icon24ArrowBack />}
              onClick={() => {
                navigate(`/${TENANT_SCREENING}`);
              }}
              palette="neutral"
              size="lg"
              variant="outline"
            />
          </Skeleton>
          <Box minW={isMax576 ? 'full' : 'auto'}>
            <HStack justifyContent="space-between">
              <Box alignSelf="flex-start">
                <Skeleton isLoaded={tenantScreeningById}>
                  <Heading size="headline-lg" as="h3">
                    {tenantScreeningById?.propertyName}
                  </Heading>
                  {tenantScreeningById?.propertyName !== tenantScreeningById?.unitName && (
                    <Text>{tenantScreeningById?.unitName}</Text>
                  )}
                </Skeleton>
              </Box>
              <Box ml="32px">
                <Skeleton isLoaded={tenantScreeningById}>
                  <Text textStyle="xs" fontWeight="500">
                    Available on
                  </Text>
                  <Text>{dateFormatter(tenantScreeningById?.leaseAvailableDate)}</Text>
                </Skeleton>
              </Box>
              <Box ml="32px">
                <Skeleton isLoaded={tenantScreeningById}>
                  <Text textStyle="xs" fontWeight="500">
                    Listed rent
                  </Text>
                  <Text>{formatCurrency(tenantScreeningById?.amount).inDollars}</Text>
                </Skeleton>
              </Box>
            </HStack>
          </Box>
          <Box ml="auto" minW={isMin899 ? 'auto' : 'full'}>
            <HStack>
              <Skeleton isLoaded={tenantScreeningById}>
                <BaselaneConditionalTooltip
                  condition={isPropertyUnitDeleted || !tenantScreeningById?.applicationLinkActive}
                  tooltipText={
                    <Stack spacing="0">
                      <Text fontWeight="bold">New applications turned off</Text>
                      <Text>
                        To invite applicants, turn on new applications from the Edit menu.
                      </Text>
                    </Stack>
                  }
                  placement="bottom"
                  childIsTarget
                >
                  <BaselaneButton
                    size="lg"
                    width={isMin899 ? '100%' : '100%'}
                    onClick={handleInvite}
                    palette="primary"
                    variant="filled"
                    isDisabled={
                      isPropertyUnitDeleted || !tenantScreeningById?.applicationLinkActive
                    }
                  >
                    Invite applicants
                  </BaselaneButton>
                </BaselaneConditionalTooltip>
              </Skeleton>
              <Box width="118px">
                <Skeleton isLoaded={tenantScreeningById}>
                  <BaselaneMenu
                    label="Edit"
                    buttonSize="lg"
                    buttonPalette="neutral"
                    buttonVariant="outline"
                    isDisabled={isPropertyUnitDeleted}
                    listItems={[
                      {
                        name: 'Edit',
                        onClick: () =>
                          navigate({
                            pathname: `${TENANT_SCREENING_EDIT}`,
                          }),
                        icon: (
                          <Box color="green.800">
                            <Icon16Edit />
                          </Box>
                        ),
                      },
                      {
                        name: tenantScreeningById?.applicationLinkActive
                          ? 'Turn off new applications'
                          : 'Turn on new applications',
                        onClick: () =>
                          handleToggleApplicationsOnOff(
                            tenantScreeningById?.id,
                            tenantScreeningById?.applicationLinkActive
                          ),
                        icon: (
                          <Box color="green.800">
                            {tenantScreeningById?.applicationLinkActive ? (
                              <Icon16VideoPause />
                            ) : (
                              <Icon16VideoPlay />
                            )}
                          </Box>
                        ),
                      },
                    ]}
                    styles={{ button: { ml: 'auto' } }}
                  />
                </Skeleton>
              </Box>
            </HStack>
          </Box>
        </HStack>
        {isPropertyUnitDeleted && (
          <BaselaneAlertNew
            variant="neutral"
            visual="icon"
            iconName={Icon16Info}
            title="Property / unit deleted"
            bg="brand.darkBlue.050"
            mb="8px"
            body={
              <Text textColor="brand.darkBlue.050">
                You cannot make any changes to the screening setup.
              </Text>
            }
          />
        )}
        {showNextStepsBanner && (
          <BaselaneAlertNew
            hasCloseButton={false}
            iconName={IllustrationOtherInsights}
            body={
              <Stack flexDirection="column">
                <Box>
                  <Text textStyle="headline-sm">Next steps with your approved tenant</Text>
                </Box>
                <Box>
                  <Text>
                    Quickly create and send a lease document for signing, or invite the tenant to
                    pay rent.
                  </Text>
                </Box>
              </Stack>
            }
            variant="primary"
            visual="illustration"
            isTextVertical={false}
            mb={2}
            styles={innerContainerStyles(isMin899)}
            customButton={<ApprovedBannerActions screeningId={screeningId} />}
          />
        )}
        <Box w="100%">
          <BaselaneDivider styles={{ mt: '1', mb: '6' }} />
          {!tenantScreeningById || applications.length < 1 ? (
            <Skeleton isLoaded={tenantScreeningById}>
              <EmptyState
                handleEmptyStateAction={
                  tenantScreeningById?.applicationLinkActive
                    ? handleInvite
                    : () =>
                        handleToggleApplicationsOnOff(
                          tenantScreeningById?.id,
                          tenantScreeningById?.applicationLinkActive
                        )
                }
                active={tenantScreeningById?.applicationLinkActive}
              />
            </Skeleton>
          ) : (
            <>
              <Heading size="headline-lg" as="h3">
                {tenantScreeningById?.noOfSubmittedApplications} Applications
              </Heading>
              <BaselaneResponsiveTable id="member-list" config={tenantScreeningByIdListConfig}>
                <BaselaneResponsiveTableHeader
                  items={tenantScreeningByIdListTableHeaderItems}
                  chevronCellStyles={{ display: { sm: 'table-cell', base: 'none' } }}
                  renderItem={(item, index) => {
                    return !item?.isSortable ? (
                      <BaselaneResponsiveTableHeading
                        key={item.key}
                        index={index}
                        title={item.label}
                      />
                    ) : (
                      <BaselaneResponsiveSortableTableHeading
                        key={item.key}
                        index={index}
                        title={item.label}
                        field={item.field}
                        filters={{
                          sort: {
                            field: sortOptions?.sort?.field,
                            direction: sortOptions?.sort?.direction,
                          },
                        }}
                        onSort={(sort) => {
                          setSortOptions({ sort });
                        }}
                      />
                    );
                  }}
                />
                <Tbody>
                  <BaselaneResponsiveTableRows
                    customRow
                    items={applications}
                    renderItem={(application, index) => {
                      const isActionDisabled = (status) =>
                        status === 'INVITED' || status === 'IN_PROGRESS';
                      return (
                        <BaselaneResponsiveTableRow
                          onClick={() =>
                            isActionDisabled(application.applicationStatus)
                              ? null
                              : handleApplicationClick(application)
                          }
                          {...{
                            _hover: {
                              cursor: isActionDisabled(application.applicationStatus)
                                ? 'default'
                                : 'pointer',
                              backgroundColor: isActionDisabled(application.applicationStatus)
                                ? 'brand.neutral.050'
                                : 'transparent',
                            },
                          }}
                          id={`table-row-${index}`}
                          chevronCellStyles={{
                            display: isActionDisabled(application.applicationStatus)
                              ? 'none'
                              : { sm: 'table-cell', base: 'none' },
                          }}
                          isDisabled={isActionDisabled(application.applicationStatus)}
                        >
                          <BaselaneResponsiveCell
                            configIndex={0}
                            p={0}
                            styles={{
                              justifyContent: 'flex-start',
                            }}
                            {...{
                              h: '72px',
                            }}
                          >
                            <VStack gap="0" spacing={0} alignItems="flex-start">
                              <Text
                                as="div"
                                textStyle="sm"
                                fontWeight="500"
                                color="brand.neutral.900"
                              >
                                {application?.applicants.join(', ')}
                              </Text>
                            </VStack>
                          </BaselaneResponsiveCell>

                          {/* Submission Date */}
                          {isMin768 && (
                            <BaselaneResponsiveCell configIndex={1} p={0} color="brand.neutral.900">
                              <Text textStyle="sm">
                                {application?.submissionDate
                                  ? dateFormatter(application?.submissionDate)
                                  : '--'}
                              </Text>
                            </BaselaneResponsiveCell>
                          )}

                          {/* Status */}
                          <BaselaneResponsiveCell configIndex={2} p={0} color="brand.neutral.900">
                            {application?.applicationStatus ? (
                              <StatusChip status={application?.applicationStatus} />
                            ) : (
                              <Text textStyle="sm">--</Text>
                            )}
                          </BaselaneResponsiveCell>

                          {/* Actions */}
                          {isMin768 && !isActionDisabled(application.applicationStatus) && (
                            <BaselaneResponsiveCell configIndex={3} p={0}>
                              <HStack justifyContent="flex-end">
                                <BaselaneButton
                                  onClick={() => {}}
                                  palette="primary"
                                  size="md"
                                  variant={getCallToActionLabel(application).buttonVariant}
                                >
                                  {getCallToActionLabel(application).buttonLabel}
                                </BaselaneButton>
                              </HStack>
                            </BaselaneResponsiveCell>
                          )}
                        </BaselaneResponsiveTableRow>
                      );
                    }}
                  />
                </Tbody>
              </BaselaneResponsiveTable>
            </>
          )}
        </Box>
      </VStack>
      <Outlet />
    </>
  ) : (
    <Outlet />
  );
};

export default TenantScreeningApplications;
