// @flow
import { React } from 'react';
import { Text, Stack, RadioGroup, ChakraProvider, Box } from '@chakra-ui/react';
import habitatTheme from '../../../../themeHabitat';
import {
  radioGroupTitleStyles,
  radioGroupDescriptionStyles,
  radioGroupHelpTextStyles,
} from './styles/radioGroup.styles';

// https://www.figma.com/file/IzR58By56T8Dvo2tLLPCbP/%F0%9F%8C%80-*Baselane---Design-System-(Habitat)?type=design&node-id=1100-43724&mode=design&t=ZUxH6qjBdtkjNTBn-4

type BaselaneRadioGroupProps = {
  name: string,
  title?: string,
  description?: string,
  helpText?: string,
  direction: 'row' | 'column',
  defaultValue: string,
  onChange?: Function,
  isRequired: Boolean,
  groupStyles?: Object,
};

function BaselaneRadioGroup({
  title = null,
  description = null,
  helpText = null,
  name,
  onChange = () => {},
  isRequired = false,
  direction = 'column',
  children,
  groupStyles = {},
  ...rest
}: PropsWithChildren<BaselaneRadioGroupProps>): any {
  return (
    <ChakraProvider theme={habitatTheme}>
      {title && (
        <Text {...radioGroupTitleStyles}>
          {title}
          {isRequired && (
            <Box as="span" ml={0.5} color="red.500">
              *
            </Box>
          )}
        </Text>
      )}
      {description && (
        <Text {...radioGroupDescriptionStyles}>
          {description}
          {isRequired && !title && (
            <Box as="span" ml={0.5} color="red.500">
              *
            </Box>
          )}
        </Text>
      )}
      {children ? (
        <RadioGroup name={name} onChange={onChange} {...rest}>
          <Stack spacing={1} my={1} direction={direction} {...groupStyles}>
            {children}
          </Stack>
        </RadioGroup>
      ) : null}
      {helpText && <Text {...radioGroupHelpTextStyles}>{helpText}</Text>}
    </ChakraProvider>
  );
}

export default BaselaneRadioGroup;
