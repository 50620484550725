// @flow
import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import {
  CartesianGrid,
  XAxis,
  Bar,
  ResponsiveContainer,
  YAxis,
  Rectangle,
  Tooltip,
  Line,
  ComposedChart,
} from 'recharts';

import { v4 as uuidv4 } from 'uuid';
import { Box, Flex, useDimensions, useMediaQuery } from '@chakra-ui/react';
import CurrencyText from '@shared/components/CurrencyText';
import isNotNil from '@core/utils/validators/isNotNil';
import { tooltipStyles } from '@shared/components/BaselaneTooltip/styles/tooltip.style';
import customTheme from '@core/theme';

import useBreakPoints from '@core/hooks/useBreakPoints';
import { minWidth } from '@core/hooks/useBreakPoints/helpers/breakpoints.helpers';
import { getMonthYearInName } from '../../helpers/cashflow.helpers';
import type { BarChartDataSet } from '../../../CashFlow/types';
import {
  graphicsDefaultColors,
  yTickStyle,
  xTickStyle,
  scrollingContainerStyle,
} from './styles/CashFlowCompositeChart.styles';

type CustomTooltipProps = {
  active: Object,
  payload: Array,
  graphicsColors: Object,
};

const CustomTooltip = ({ active, payload, graphicsColors }: CustomTooltipProps) => {
  if (active && payload) {
    const month = payload[0]?.payload?.monthLabel;
    const values = [];

    payload.forEach((item) => {
      const { payload: payloadObject, name } = item;
      values.push({
        amount: payloadObject[name],
        color: graphicsColors[name],
      });
    });
    return (
      <Flex {...tooltipStyles}>
        <p>{month}</p>
        <Box ml="10px">
          {values.map((value) =>
            values.length > 1 ? (
              <Flex key={uuidv4()} alignItems="center">
                <Box
                  w="8px"
                  h="8px"
                  background={value.color}
                  borderRadius="50%"
                  m="0 4px 4px 4px"
                />
                <CurrencyText
                  key={uuidv4()}
                  isRounded={false}
                  textStyle="sm"
                  color="brand.neutral.white"
                  amount={value.amount}
                />
              </Flex>
            ) : (
              <CurrencyText
                key={uuidv4()}
                isRounded={false}
                textStyle="sm"
                color="brand.neutral.white"
                amount={value.amount}
              />
            )
          )}
        </Box>
      </Flex>
    );
  }
  return null;
};

export function CustomYTick({ y, payload }: any) {
  const isNegative = payload.value < 0;
  const formattedValue = isNegative ? -payload.value : payload.value;
  const { isMin768 } = useBreakPoints();

  return (
    <g transform={`translate(20,${y})`} width="50">
      <text
        x={40}
        y={0}
        style={yTickStyle(!isMin768)}
        textAnchor="end"
        fill={customTheme.colors.brand.neutral['600']}
      >
        {isNegative ? `-$${formattedValue}` : `$${formattedValue}`}
      </text>
    </g>
  );
}

function CustomXTick({ x, y, payload }: any) {
  const [month, year] = payload.value.split(' ');
  const { isMin768 } = useBreakPoints();
  const axisTransform = isMin768
    ? `translate(${x} ${y + 8})`
    : `translate(${x} ${y + 8}) rotate(-90 0 0)`;
  return (
    <g transform={axisTransform}>
      <text style={xTickStyle(!isMin768)}>{month}</text>
      <text dy="1.2em" style={xTickStyle(!isMin768)}>
        {year}
      </text>
    </g>
  );
}

export function CustomDot({
  cx,
  cy,
  stroke,
  strokeWidth,
  fill,
  radius = '4',
  highlightFill,
  payload,
  currentItem,
}: any) {
  // Note: Keep in mind that CSS borders are rendered on the outside of the radius (as in the Legene Dots)
  // while in SVG the stroke is always rendered in the middle of the radius, so the radius is
  // half the stroke thickness larger to compensate
  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={radius}
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill={currentItem === payload.discriminator || currentItem === null ? highlightFill : fill}
      />
    </g>
  );
}

export function CustomBar(props: any) {
  const { x, y, width, height, value, graphicsColors, config } = props;
  const isNegative = parseInt(value, 10) < 0;

  return isNegative ? (
    <Rectangle
      fill={graphicsColors[config.values.barTwo]}
      x={x}
      y={y}
      radius={[2, 2, 0, 0]}
      width={width}
      height={height}
    />
  ) : (
    <Rectangle
      fill={graphicsColors[config.values.barOne]}
      x={x}
      y={y}
      radius={[2, 2, 0, 0]}
      width={width}
      height={height}
    />
  );
}

export function DynamicBar(props: any) {
  const {
    isValueOne,
    x,
    y,
    width,
    height,
    discriminator,
    currentItem,
    graphicsColors,
    config,
  } = props;

  return !isValueOne ? (
    <Rectangle
      fill={
        discriminator.toString() === currentItem?.toString() || currentItem === null
          ? graphicsColors[config.values.barTwo]
          : customTheme.colors.brand.darkBlue['100']
      }
      x={x}
      y={y}
      radius={[2, 2, 0, 0]}
      width={width}
      height={height}
    />
  ) : (
    <Rectangle
      fill={
        discriminator.toString() === currentItem?.toString() || currentItem === null
          ? graphicsColors[config.values.barOne]
          : customTheme.colors.brand.darkBlue['250']
      }
      x={x}
      y={y}
      radius={[2, 2, 0, 0]}
      width={width}
      height={height}
    />
  );
}

export type SharpSVGGradientFillProps = {
  id: String,
  color: String,
  highlightColor: String,
  highlightStartPercentage: String,
  highlightEndPercentage: String,
};

export const SharpSVGGradientFill = ({
  id,
  color,
  highlightColor,
  highlightStartPercentage,
  highlightEndPercentage,
}: SharpSVGGradientFillProps) => {
  return (
    <linearGradient id={id}>
      <stop offset="0%" stopColor={color} />
      <stop offset={`${highlightStartPercentage}%`} stopColor={color} />
      <stop offset={`${highlightStartPercentage}%`} stopColor={highlightColor} />
      <stop offset={`${highlightEndPercentage}%`} stopColor={highlightColor} />
      <stop offset={`${highlightEndPercentage}%`} stopColor={color} />
      <stop offset="100%" stopColor={color} />
    </linearGradient>
  );
};

// These represent the available graphical bars, lines that
// can be populated with data
type CashFlowCompositeChartSupportedElements = {
  barOne?: String,
  barTwo?: String,
  lineOne?: String,
};

// Configuration object to set data sources and colours
export type CashFlowCompositeChartConfig = {
  values: CashFlowCompositeChartSupportedElements,
  colors?: CashFlowCompositeChartSupportedElements,
};

type CashFlowCompositeChartProps = {
  dataset: Array<BarChartDataSet>,
  handleSubFiltersUpdate?: Function,
  filters: any,
  config: CashFlowCompositeChartConfig,
  isZero?: boolean,
  maxNumber?: number,
  dataPointsInViewport?: number,
  isDashboardWidget?: boolean,
  dataType?: String,
};

// set DEBUG to true to console.debug() most pertinent data
const DEBUG = false;
function CashFlowCompositeChart({
  dataset,
  handleSubFiltersUpdate = () => {},
  filters,
  config,
  isZero = false,
  maxNumber = 0,
  dataPointsInViewport = 6,
  isDashboardWidget = false,
  dataType,
  ...rest
}: CashFlowCompositeChartProps): any {
  const graphicsColors = {
    [config.values.barOne]: config.colors?.barOne || graphicsDefaultColors.barOne,
    [config.values.barTwo]: config.colors?.barTwo || graphicsDefaultColors.barTwo,
    [config.values.lineOne]: config.colors?.lineOne || graphicsDefaultColors.lineOne,
  };

  const scrollingContainerRef = useRef();

  const dimensions = useDimensions(scrollingContainerRef, true);

  const [percentage, setCurrentPercentage] = useState(0);

  const dataPointCount = Math.min(dataPointsInViewport, dataset.length);

  // if there is only one month, pre-select it,
  // otherwise use null
  const [currentMonth, setCurrentMonth] = useState(
    dataPointCount === 1 ? dataset[0].discriminator : null
  );

  useEffect(() => {
    setCurrentMonth(dataPointCount === 1 ? dataset[0].discriminator : null);
  }, [filters]);

  const chartHeight = '100%';
  const chartWidth = ((dimensions?.paddingBox?.width || 0) / dataPointCount) * dataset.length || 0;
  const gradientIncrement = 100 / (dataset.length - 1);
  const highlightGradientId = `lineHighlight-${uuidv4()}`;

  const [isAnimationActive, setIsAnimationActive] = useState(true);

  const [isMin899] = useMediaQuery(minWidth('lg'), { ssr: false });

  if (DEBUG) {
    console.debug(CashFlowCompositeChart.name, {
      dataset,
      config,
      graphicsColors,
      dataPointCount,
      currentMonth,
      dimensions,
      chartHeight,
      chartWidth,
      percentage,
      gradientIncrement,
      highlightGradientId,
    });
  }

  function handleChartClick(event) {
    // prevents non-interactive element clicks from altering the chart view
    if (!event || event === {}) return;

    const clickedMonth = event?.activePayload && event?.activePayload[0]?.payload.discriminator;

    if (isNotNil(clickedMonth)) {
      // Flow issue cannot resolve the isNotNil check
      handleSubFiltersUpdate({
        from: moment(clickedMonth).startOf('month').format('YYYY-MM-DD'),
        to: moment(clickedMonth).endOf('month').format('YYYY-MM-DD'),
        dateSelected: {
          date: clickedMonth,
          dateFormatted: getMonthYearInName(clickedMonth),
        },
      });
    }

    if (clickedMonth !== currentMonth) {
      setIsAnimationActive(false);
      setCurrentMonth(clickedMonth);
      const index = dataset.findIndex((datum) => datum.discriminator === clickedMonth);
      setCurrentPercentage((1 - (dataset.length - index - 1) / (dataset.length - 1)) * 100);
    } else {
      // toggles back to the default state
      setCurrentMonth(null);
      const { from, to } = filters.filter;
      handleSubFiltersUpdate({
        from,
        to,
        dateSelected: {
          date: null,
          dateFormatted: null,
        },
      });
    }
  }

  function domain() {
    if (maxNumber <= 0 || maxNumber === null) {
      return ['auto', 0];
    }
    return [0, 'auto'];
  }

  useEffect(() => {
    handleSubFiltersUpdate({});
  }, [dataType]);

  return (
    <Box id="scrolling-container-ref" ref={scrollingContainerRef} {...scrollingContainerStyle}>
      <ResponsiveContainer width={chartWidth} height={chartHeight} minHeight={168} {...rest}>
        <ComposedChart
          data={dataset}
          barGap={0}
          barCategoryGap="8px"
          margin={{
            top: 20,
            right: 0,
            left: 20,
            bottom: 0, // account for xaxis labels
          }}
          cursor="pointer"
          onClick={isDashboardWidget ? () => {} : handleChartClick}
        >
          <defs>
            <SharpSVGGradientFill
              id={highlightGradientId}
              color={customTheme.colors.brand.neutral['500']}
              highlightColor={customTheme.colors.red['500A']}
              highlightStartPercentage={
                currentMonth && percentage ? percentage - gradientIncrement : 0
              }
              highlightEndPercentage={currentMonth && percentage ? percentage : 100}
            />
          </defs>
          <CartesianGrid vertical={false} stroke={customTheme.colors.brand.darkBlue['100']} />
          {config.values.barTwo === '' ? (
            <Bar
              dataKey={config.values.barOne}
              barSize={isDashboardWidget ? 16 : 32}
              shape={<CustomBar graphicsColors={graphicsColors} config={config} />}
            />
          ) : (
            [
              <Bar
                key={`barOne-${uuidv4()}`}
                dataKey={config.values.barOne}
                barSize={isDashboardWidget ? 16 : 32}
                radius={[2, 2, 0, 0]}
                fill={graphicsColors[config.values.barOne]}
                shape={
                  <DynamicBar
                    isValueOne
                    {...{ currentItem: currentMonth, graphicsColors, config }}
                  />
                }
                isAnimationActive={isAnimationActive}
              />,
              <Bar
                key={`barTwo-${uuidv4()}`}
                dataKey={config.values.barTwo}
                barSize={isDashboardWidget ? 16 : 32}
                radius={[2, 2, 0, 0]}
                fill={graphicsColors[config.values.barTwo]}
                shape={
                  <DynamicBar
                    isValueOne={false}
                    {...{ currentItem: currentMonth, graphicsColors, config }}
                  />
                }
                isAnimationActive={isAnimationActive}
              />,
            ]
          )}
          <XAxis
            orientation="bottom"
            dataKey="monthLabel"
            axisLine={{ stroke: customTheme.colors.brand.darkBlue['100'] }}
            tickLine={false}
            tick={<CustomXTick />}
            padding="gap"
            interval={0}
          />
          <Line
            key={`lineOne-${uuidv4()}`}
            type="linear"
            dataKey={config.values.lineOne}
            isAnimationActive={isAnimationActive}
            onAnimationEnd={() => {
              setIsAnimationActive(false);
            }}
            dot={
              <CustomDot
                currentItem={currentMonth}
                stroke={customTheme.colors.brand.darkBlue['100']}
                fill={customTheme.colors.brand.neutral['600']}
                highlightFill={customTheme.colors.red['500A']}
                strokeWidth={isMin899 ? 2 : 1}
                radius={isMin899 ? 4 : 2}
              />
            }
            activeDot={
              <CustomDot
                currentItem={currentMonth}
                stroke={customTheme.colors.brand.darkBlue['100']}
                fill={customTheme.colors.brand.neutral['600']}
                highlightFill={customTheme.colors.red['500A']}
                strokeWidth={isMin899 ? 2 : 1}
                radius={isMin899 ? 4 : 2}
              />
            }
            stroke={`url(#${highlightGradientId})`}
            strokeWidth="0.5"
            strokeDasharray="2 1"
          />
          {isZero ? (
            <YAxis
              interval={0}
              tickCount={0}
              tickLine={false}
              axisLine={false}
              ticks={[0]}
              tick={<CustomYTick />}
            />
          ) : (
            <YAxis
              interval={0}
              tickCount={isDashboardWidget ? 4 : 6}
              type="number"
              domain={domain()}
              tickLine={false}
              axisLine={false}
              tick={<CustomYTick />}
              height={120}
            />
          )}
          <Tooltip content={<CustomTooltip graphicsColors={graphicsColors} />} cursor={false} />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default CashFlowCompositeChart;
