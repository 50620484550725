// @flow
import moment from 'moment';
import { formatDate } from '@core/utils/formatDate';
import { TimePeriod } from '../types';

const TimeFilterService = (): any => {
  const getFormattedDate = (numberOfMonths: number): string => {
    const to = moment();
    const from = moment().subtract(numberOfMonths, 'months');
    const isTheSameYear = to.isSame(from, 'year');
    const fromFormat = isTheSameYear ? 'MMM' : 'MMM YYYY';
    return `${moment().subtract(numberOfMonths, 'months').format(fromFormat)} - ${moment().format(
      'MMM YYYY'
    )}`;
  };
  const getTimePeriods = (): any => {
    const lastYear = moment().subtract(1, 'year');

    return {
      ThisMonth: {
        order: 1,
        name: 'This Month',
        formattedDate: moment().format('MMM YYYY'),
        from: moment().startOf('month').format(),
        to: moment().format(),
      },
      LastMonth: {
        order: 2,
        name: 'Last Month',
        formattedDate: moment().subtract(1, 'month').format('MMM YYYY'),
        from: moment().subtract(1, 'month').startOf('month').format(),
        to: moment().subtract(1, 'month').endOf('month').startOf('day').format(),
      },
      Last3Months: {
        order: 3,
        name: 'Last 3 Months',
        formattedDate: getFormattedDate(3),
        from: moment().subtract(2, 'months').startOf('month').format(),
        to: moment().format(),
      },
      Last6Months: {
        order: 4,
        name: 'Last 6 Months',
        formattedDate: getFormattedDate(6),
        from: moment().subtract(5, 'months').startOf('month').format(),
        to: moment().format(),
      },
      Last12Months: {
        order: 5,
        name: 'Last 12 Months',
        formattedDate: getFormattedDate(12),
        from: moment().subtract(11, 'months').startOf('month').format(),
        to: moment().format(),
      },
      YearToDate: {
        order: 6,
        name: 'Year To Date',
        from: moment().startOf('year').format(),
        to: moment().format(),
        formattedDate: null,
      },
      LastYear: {
        order: 7,
        name: `${lastYear.format('YYYY')} (Jan-Dec)` || 'Last Year',
        from: moment().subtract(1, 'year').startOf('year').format(),
        to: moment().subtract(1, 'year').endOf('year').format(),
        formattedDate: null,
      },
      SelectDate: {
        order: 8,
        name: 'Custom Date Range',
        from: moment().format(),
        to: moment().format(),
        formattedDate: null,
      },
    };
  };
  return {
    getFormattedDate,
    getTimePeriods,
  };
};

const timeFilterService = TimeFilterService();
const timePeriods = timeFilterService.getTimePeriods();

export const DEFAULT_TIME_PERIOD = TimePeriod.Last6Months;
export const defaultTimePeriod: any = timePeriods[DEFAULT_TIME_PERIOD];

export const DEFAULT_TAX_PACKAGE_TIME_PERIOD = TimePeriod.LastYear;
export const defaultTaxPackageTimePeriod: any = timePeriods[DEFAULT_TAX_PACKAGE_TIME_PERIOD];

/**
 * Note: These are the currently hardcoded presets, that may
 * make their way into the db eventually. They are in the specific
 * order we want the presets to appear in the menu.
 */
export const PRESETS = Object.freeze({
  NOI: 'Net Operating Income (NOI)',
  NET_OPERATING_CASHFLOW: 'Net Operating Cashflow',
  TOTAL_INFLOWS_OUTFLOWS: 'Total Inflows & Outflows',
  SCHEDULE_E_CATEGORIES: 'Schedule E Categories',
  CUSTOM: 'Custom',
});

/**
 * Descriptions attached to each preset, used for tooltip text.
 */
export const PRESET_DESCRIPTIONS = Object.freeze({
  [PRESETS.NOI]: `This report view selects all categories for Revenue and Operating Expenses, excludes uncategorized transactions.`,
  [PRESETS.NET_OPERATING_CASHFLOW]: `This report view selects all categories for Revenue, Operating Expenses, Mortgage & Loan Payments, Capital Expenditures, and all uncategorized transactions.`,
  [PRESETS.TOTAL_INFLOWS_OUTFLOWS]: `This report view selects all categories including uncategorized.`,
  [PRESETS.SCHEDULE_E_CATEGORIES]: `This report view selects all ‘Schedule E’ categories (Form 1040) - line 3 to 19`,
});

/**
 * Array of additional presets to append to the ones coming
 * from the database.
 */
export const PRESET_ADDONS = Object.freeze([
  {
    __typename: 'Preset',
    name: PRESETS.CUSTOM,
    tagId: [],
    uncategorized: false,
  },
]);

/**
 * Patches on extra data to the presets that come from the database.
 * @param {array} existingPresets The initial presets, without anything changed.
 * @returns The presets, with the augmented data as expressed in PRESET_ADDONS.
 */
export const patchPresets = (existingPresets) => {
  if (!existingPresets) return existingPresets;
  const patchedPresets = [...existingPresets, ...PRESET_ADDONS];
  return patchedPresets.map((preset) => {
    switch (preset.name) {
      case 'All Categories':
        return { ...preset, name: PRESETS.TOTAL_INFLOWS_OUTFLOWS };
      case 'Operating Cashflow':
        return { ...preset, name: PRESETS.NET_OPERATING_CASHFLOW };
      default:
        return preset;
    }
  });
};

/**
 * Reorders presets to match the key order of PRESTS.
 * @param {array} unsortedPresets The presets in whatever current order they are in.
 * @returns The presets ordered to match the order described by the keys in PRESETS.
 */
export const sortPresets = (unsortedPresets) => {
  const sortedPresets = [];
  Object.keys(PRESETS).forEach((presetKey) => {
    sortedPresets.push(unsortedPresets?.find((unsorted) => unsorted.name === PRESETS[presetKey]));
  });
  return sortedPresets;
};

/**
 * Determines the correct animation variant to use, based on
 * preset state.
 *
 * @param {Object} presetSelected The current preset that is applied
 * @param {boolean} isInitializing Applies the final frame of the animation if true (force open or force close).
 * @param {Function}
 * @returns String representing the name of the animation to activate.
 */
export const calculateAnimationVariant = (presetSelected, isInitializing) => {
  if (presetSelected?.name === PRESETS.CUSTOM) {
    if (isInitializing) {
      return 'beginOpened';
    }
    return 'open';
  }

  if (isInitializing) {
    return 'beginClosed';
  }
  return 'close';
};

/**
 * Note: These should match top level category names coming
 * back from the database. They are in the specific
 * order we want the categories to appear in the statement.
 */
export const CATEGORIES = Object.freeze({
  REVENUE: `Revenue`,
  OPERATING_EXPENSES: `Operating Expenses`,
  LOAN_PAYMENTS_AND_CAPEX: `Loan Payments & Capex`,
  UNCATEGORIZED: `Uncategorized`,
  PROPERTY_TRANSACTIONS: `Property Transactions`,
  TRANSFERS_AND_OTHER: `Transfers & Other`,
});

export const defaultFilters = {
  filter: {
    from: formatDate(defaultTimePeriod.from, 'YYYY-MM-DD'),
    to: formatDate(defaultTimePeriod.to, 'YYYY-MM-DD'),
    propertyId: null,
    unitId: null,
    tagId: null,
    uncategorized: false,
  },
};

export const defaultTaxPackageFilters = {
  filter: {
    from: formatDate(defaultTaxPackageTimePeriod.from, 'YYYY-MM-DD'),
    to: formatDate(defaultTaxPackageTimePeriod.to, 'YYYY-MM-DD'),
    propertyId: null,
    unitId: null,
    tagId: null,
    uncategorized: true,
  },
};

export const dashboardFilters = {
  filter: {
    from: formatDate(defaultTimePeriod.from, 'YYYY-MM-DD'),
    to: formatDate(defaultTimePeriod.to, 'YYYY-MM-DD'),
    propertyId: null,
    unitId: null,
    tagId: null,
    uncategorized: true,
  },
};
