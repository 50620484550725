// @flow
import React from 'react';
import { VStack } from '@chakra-ui/react';
import { containerStyles, contentContainerStyles } from './styles/card.style';
import BaselaneResponsiveCardHeader, {
  ActionHandler,
  ActionRoute,
} from './components/BaselaneResponsiveCardHeader';

type Props = {
  icon?: Object,
  iconSize?: number,
  heading: String,
  action?: ActionHandler | ActionRoute,
  children?: any,
  id?: string,
};

function BaselaneResponsiveCard({
  icon = null,
  iconSize = '16',
  action = null,
  children = null,
  id = '',
  heading,
  ...rest
}: Props): any {
  return (
    <VStack id={id} {...containerStyles} {...rest}>
      <BaselaneResponsiveCardHeader {...{ heading, icon, action }} />
      <VStack {...contentContainerStyles} pl={icon ? `${Number(iconSize) + 12}px !important` : '0'}>
        {children}
      </VStack>
    </VStack>
  );
}

export default BaselaneResponsiveCard;
