import React, { useContext, useRef, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Box, Heading, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useStatsigClient } from '@statsig/react-bindings';
import { UNIFIED_LB_PERSONAL_INFO, PARTNERSHIPS } from '@routes';
import { IconLightning } from '@icons';
import { GET_KYC_URL } from '@core/components/NativeBankingPage/queries';
import { GET_PROPERTY_SUMMARY } from '@core/apollo/queries';
import useSuccessPropertyDrawer from '@pages/PropertiesPage/SuccessDrawer/hooks/useSuccessPropertyDrawer';
import {
  BaselaneApplicationTypePopup,
  BaselaneButton,
  BaselaneCardNew,
  InsuranceBanner,
  LLCBanner,
  RocketLawyerBanner,
} from '@shared/components';
import UserContext from '@contexts/UserContext';
import historyStateStorage from '@core/storage/historyStateStorage';

import KYCReturnStatusDrawer from '@shared/components/BaselaneKYCForm/components/KYCReturnStatusDrawer';
import VouchedIdentificationDrawer from '@shared/components/VouchedIdentificationDrawer';
import useReturnStatus from '@shared/components/BaselaneKYCForm/hooks/useReturnStatus';
import BanksContext from '@contexts/BanksContext';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import RentCollection from '@pages/Dashboard/SetupGuide/GuideTrackers/RentCollection';
import Banking from '@pages/Dashboard/SetupGuide/GuideTrackers/Banking';
import BookKeeping from '@pages/Dashboard/SetupGuide/GuideTrackers/BookKeeping';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import HowToGuides from './components/HowToGuides';
import QuickActions from './components/QuickActions';
import KYCVerifyIdentity from './components/KYCVerifyIdentity';
import TenantScreeningBanner from './components/TenantScreeningBanner';

import {
  mainContainerStyles,
  titleContainerStyles,
  titleStyles,
  subTitleContainerStyles,
  subTitleStyles,
  trackersContainerStyles,
  twoColumnContainerStyles,
} from './styles/getStarted.styles';
import useCurrentWorkspace from '../Shared/hooks/useCurrentWorkspace';

const GetStarted = () => {
  const { isMax768: isMobile } = useBreakPoints();

  const navigate = useNavigate();
  const location = useLocation();
  const updatedStepRef = useRef();

  const { hasActiveBaselaneAccounts } = useContext(BanksContext);

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  const { checkGate } = useStatsigClient();

  // Application Type Selector Popup
  const {
    isOpen: isBaselaneApplicationTypePopupOpen,
    onOpen: onBaselaneApplicationTypePopupOpen,
    onClose: onBaselaneApplicationTypePopupClose,
  } = useDisclosure();

  const {
    kycReturnStatusDrawerRef,
    handleKYCReturnStatusDrawerOpen,
    handleKYCReturnStatusDrawerClose,
  } = useReturnStatus();
  const vouchedDrawerRef = useRef();

  // User Data from UserContext
  const { user, refetchUser, userMetadata } = useContext(UserContext);
  const { userKycStatus, userKyc } = useCurrentWorkspace();
  const { bankingTracker, rentCollectionTracker, bookkeepingTracker } = userMetadata ?? {};

  /**
   * 1. Lazy Query: getKYCUrl
   * 2. kycProps
   */
  const [getKYCUrl] = useLazyQuery(GET_KYC_URL, {
    onCompleted: ({ unitOnBoarding: unitBankUrl }) => window.open(unitBankUrl, '_self'),
    onError: (error) => console.error(error),
    fetchPolicy: 'no-cache',
  });
  const kycProps = {
    getKYCUrl,
  };

  /**
   * 1. Properties Data from getProperties Query
   * 2. propertiesProps
   */
  const {
    error: propertiesSummaryError,
    loading: propertiesSummaryLoading,
    data: propertiesSummaryData,
  } = useQuery(GET_PROPERTY_SUMMARY);
  const { newProperty, setNewProperty, successDrawerRef } = useSuccessPropertyDrawer();
  const propertiesProps = {
    propertiesSummaryError,
    propertiesSummaryLoading,
    propertiesSummaryData,
    setNewProperty,
    successDrawerRef,
    newProperty,
  };

  const handleStepUpdate = ({ isAPIUpdated = false, isDrawerClosed = false }) => {
    if (isAPIUpdated) {
      updatedStepRef.current = {
        ...updatedStepRef.current,
        isAPIUpdated: true,
      };
    }
    if (isDrawerClosed) {
      updatedStepRef.current = {
        ...updatedStepRef.current,
        isDrawerClosed: true,
      };
    }

    if (updatedStepRef.current?.isAPIUpdated && updatedStepRef.current?.isDrawerClosed) {
      refetchUser();
      updatedStepRef.current = {};
    }
  };

  /**
   * Function gets triggered from...
   * 1. onClick of 'Open a Baselane Banking account' from Landlord Banking tracker
   * 2. onClick of 'Open a Baselane Banking account' from Quick Actions
   */
  const openLBFlow = (type) => {
    const pathname = UNIFIED_LB_PERSONAL_INFO;
    const state = { from: location?.pathname };

    if (type === 'business') {
      getKYCUrl();
    } else {
      historyStateStorage.write(state);
      navigate(pathname);
    }
  };

  const styles = {
    mainContainer: mainContainerStyles(isMobile),
    titleContainer: titleContainerStyles(isMobile),
    subTitleContainer: subTitleContainerStyles(isMobile),
    subTitle: subTitleStyles(isMobile),
    trackersContainer: trackersContainerStyles(isMobile),
    titleStyles,
    twoColumnContainerStyles: twoColumnContainerStyles(isMobile),
  };

  useEffect(() => {
    refetchUser();
    if (isMobile) zendeskAPI('messenger', 'hide');
  }, []);

  return (
    <Box {...styles.mainContainer}>
      <Outlet
        context={{
          isEmptyState:
            bookkeepingTracker?.[1]?.state === 'NOT_STARTED' || !hasActiveBaselaneAccounts,
        }}
      />
      {/* Title */}
      <Box {...styles.titleContainer}>
        {isMobile && <Text {...styles.titleStyles}>Let&apos;s get started, {user.firstName}</Text>}
        <HStack {...styles.subTitleContainer}>
          {!isMobile && <IconLightning />}
          <Text {...styles.subTitle}>It&apos;s time to set up your Baselane account</Text>
        </HStack>
      </Box>
      {/* Trackers */}
      <HStack {...styles.trackersContainer}>
        <Banking
          data={bankingTracker}
          {...{ refetchUser, kycProps, onBaselaneApplicationTypePopupOpen }}
        />
        <RentCollection
          data={rentCollectionTracker}
          {...{ userKycStatus, handleStepUpdate, refetchUser, propertiesProps, userKyc, user }}
        />
        <BookKeeping
          data={bookkeepingTracker}
          {...{ handleStepUpdate, refetchUser, propertiesProps }}
        />
      </HStack>

      <Stack
        {...(!isMobile && styles.twoColumnContainerStyles)}
        spacing={0}
        direction={!isMobile ? 'row' : 'column'}
      >
        {/* Insurance Banner */}
        <InsuranceBanner isNarrowOnly />
        {/* Rocket Lawyer Banner */}
        <RocketLawyerBanner m={isMobile ? '0 0 24px 0 !important' : '24px 0 24px 0 !important'} />
      </Stack>

      <Stack
        {...(!isMobile && styles.twoColumnContainerStyles)}
        spacing={0}
        direction={!isMobile ? 'row' : 'column'}
      >
        {/* Tenant Screening Affiliate Banner */}
        <TenantScreeningBanner mt="0 !important" />
        {/* LLC Banner */}
        <LLCBanner isNarrowOnly m="0 0 24px 0 !important" />
      </Stack>

      {checkGate(FEATURE_GATES.PARTNERSHIPS_PAGE) && (
        <Stack mb={3}>
          <BaselaneCardNew size={isMobile ? 'md' : 'lg'} palette="primary">
            <Stack spacing={2}>
              <Stack spacing={0.5}>
                <Heading size="headline-xl">
                  Grow your rental business with the help of trusted Baselane partners
                </Heading>
                <Text textStyle="sm">
                  We&apos;ve hand selected partners that can help you save time, money and grow your
                  portfolio.
                </Text>
              </Stack>
              <Box>
                <BaselaneButton
                  palette="primary"
                  variant="filled"
                  size="md"
                  onClick={() => {
                    sendSegmentEvent('temp_partnerships_page_entry_point_clicked', {
                      target: 'get-started-banner',
                    });
                    navigate({ pathname: PARTNERSHIPS });
                  }}
                >
                  Explore partners
                </BaselaneButton>
              </Box>
            </Stack>
          </BaselaneCardNew>
        </Stack>
      )}

      <Stack {...styles.twoColumnContainerStyles} direction={!isMobile ? 'row' : 'column'}>
        {/* How to Guides */}
        <HowToGuides />
        {/* Quick Actions */}
        <QuickActions
          {...{
            refetchUser,
            handleStepUpdate,
            propertiesProps,
            onBaselaneApplicationTypePopupOpen,
          }}
        />
      </Stack>
      <KYCVerifyIdentity
        vouchedDrawerRef={vouchedDrawerRef}
        handleKYCReturnStatusDrawerOpen={handleKYCReturnStatusDrawerOpen}
      />

      <KYCReturnStatusDrawer
        kycStatus={userKycStatus}
        drawerRef={kycReturnStatusDrawerRef}
        handleKYCReturnStatusDrawerClose={handleKYCReturnStatusDrawerClose}
      />

      <VouchedIdentificationDrawer
        {...{
          userKyc,
          user,
          refetchUser,
          vouchedDrawerRef,
        }}
      />

      <BaselaneApplicationTypePopup
        handleActionButton={openLBFlow}
        isOpen={isBaselaneApplicationTypePopupOpen}
        onClose={onBaselaneApplicationTypePopupClose}
      />
    </Box>
  );
};

export default GetStarted;
