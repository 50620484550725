import React from 'react';
import { Skeleton, Text } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';
import BaselaneSummaryCardDefaultHeader from './BaselaneSummaryCardDefaultHeader';
import { baselaneSummaryCardStyles } from '../styles/baselaneSummaryCard.styles';

type BaselaneSummaryCardAmountHeaderProps = {
  title: string | React.Node,
  subtitle?: string | React.Node,
  prefix?: string | React.Node,
  addon?: React.Node,
  amount: string | Number | React.Node,
  styles?: Object,
  isLoading?: boolean,
};

const BaselaneSummaryCardAmountHeader = ({
  title,
  subtitle = null,
  prefix = null,
  addon = null,
  amount,
  styles = {},
  isLoading = false,
}: BaselaneSummaryCardAmountHeaderProps) => {
  const { amountStyles } = baselaneSummaryCardStyles;

  return (
    <BaselaneSummaryCardDefaultHeader
      isLoading={isLoading}
      title={title}
      subtitle={subtitle}
      prefix={prefix}
      addon={addon}
      rightElem={
        <Skeleton isLoaded={!isLoading}>
          <Text {...{ ...amountStyles, ...styles?.amount }}>
            {formatCurrency(amount).inDollars}
          </Text>
        </Skeleton>
      }
    />
  );
};

export default BaselaneSummaryCardAmountHeader;
