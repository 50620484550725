import React from 'react';
import { Box, Spacer, Text, Flex, Image } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import {
  BaselaneSummaryCard,
  BaselaneSummaryCardAmountHeader,
  BaselaneSummaryCardDefaultHeader,
} from '@shared/components';
import useProperties from '../../../../../../features/Property/useProperties';
import useCategories from '../../../../../../features/Category/useCategories';
import subcategoryImageUri from '../../../../assets/subcategory.svg';

const ReviewAndDeposit = () => {
  const { values } = useFormikContext();
  const { propertiesByIdForDropdownById } = useProperties();
  const { categoriesForDropdownById } = useCategories();
  const { bankAccountsById } = useBankAccountsActive();

  const selectedProperty = propertiesByIdForDropdownById[values.propertyId];

  const selectedCategory = categoriesForDropdownById[values.categoryId];

  const shouldShowBookkeepingDetails = selectedProperty || selectedCategory || values.notes;

  const buildBookkeeingDetailsListItems = () => {
    const items = [];

    if (selectedProperty) {
      const [parentPropertyId, unitId] = values.propertyId.split('-');
      const parentProperty = propertiesByIdForDropdownById[parentPropertyId];

      items.push({
        label: 'Property',
        value: unitId ? `${parentProperty.name} · ${selectedProperty.name}` : selectedProperty.name,
      });
    }

    if (selectedCategory) {
      const [parentCategoryId, subCategoryId] = values.categoryId.split('-');
      const parentCategory = categoriesForDropdownById[parentCategoryId];
      items.push({
        label: 'Category',
        value: subCategoryId ? parentCategory.name : selectedCategory.name,
        subtext: subCategoryId ? (
          <Box as="p" display="flex">
            <Image src={subcategoryImageUri} alt="An arrow that points to subcategory" />
            <Text textStyle="xs">{selectedCategory.name}</Text>
          </Box>
        ) : null,
      });
    }

    if (values.notes) {
      items.push({
        label: 'Notes',
        value: values.notes,
      });
    }

    return items;
  };

  return (
    <Box>
      <Flex>
        <Text textStyle="md-heavy">Review and deposit</Text>
        <Spacer />
        <Text textStyle="xs">Step 4 of 4</Text>
      </Flex>
      <Box mt={0.5}>
        <Text textStyle="sm">Your funds will be available within 3 business days.</Text>
      </Box>
      {/* Deposit check */}
      <Box mt={2}>
        <BaselaneSummaryCard
          isCustom
          header={<BaselaneSummaryCardAmountHeader title="Deposit check" amount={values.amount} />}
          list={[
            {
              items: [
                {
                  label: 'Deposit to',
                  value: bankAccountsById[values.accountId]?.nickName,
                  subtext: bankAccountsById[values.accountId]?.name,
                },
              ],
            },
          ]}
        />
      </Box>
      {/* Bookkeeping details */}
      {shouldShowBookkeepingDetails ? (
        <Box mt={2}>
          <BaselaneSummaryCard
            isCustom
            header={<BaselaneSummaryCardDefaultHeader title="Bookkeeping details" />}
            list={[
              {
                items: buildBookkeeingDetailsListItems(),
              },
            ]}
          />
        </Box>
      ) : null}
      {/* Front of check preview */}
      <Box mt={2}>
        <Text textStyle="xs">Front of check</Text>
        <Image mt={1} borderRadius="6px" src={values.frontOfCheck} alt="Front of check" />
      </Box>
      {/* Back of check preview */}
      <Box mt={2}>
        <Text textStyle="xs">Back of check</Text>
        <Image mt={1} borderRadius="6px" src={values.backOfCheck} alt="Back of check" />
      </Box>
    </Box>
  );
};

export default ReviewAndDeposit;
