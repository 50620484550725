import React, { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import { Heading, Stack, Text, Flex, Box, useDisclosure } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import {
  BaselaneAlert,
  BaselaneButton,
  BaselaneLink,
  BaselaneCardNew,
  BaselaneChip,
  BaselaneAlertNew,
  BaselaneButtonGroup,
  BaselaneButtonIcon,
} from '@shared/components';
import { Icon16ChevronRight } from '@icons/16px';
import { Icon24CheckCircle } from '@icons/24px';
import { IllustrationBankingApplicationawaitingdocuments } from '@illustrations';
import LandlordMissingDataTaxModal from './LandlordDataMissingTaxModal';
import useTaxData from '../hooks/useTaxData';
import { calculateDaysToExpire } from '../helpers/taxmodal.helpers';

type LandlordTaxModalProps = {
  isOpen: boolean,
  onClose: () => void,
  handleLandlordTaxModalClick: () => void,
  handleLandlordTaxModalRemindMeLaterClick: () => void,
  expirationDay: DateTime,
  overdueDay: DateTime,
};

const LandlordTaxModal = ({
  isOpen,
  onClose,
  handleLandlordTaxModalClick,
  handleLandlordTaxModalRemindMeLaterClick,
  expirationDay,
  overdueDay,
}: LandlordTaxModalProps) => {
  const cancelRef = useRef();
  const { isMin768: isDesktop } = useBreakPoints();
  const url =
    'https://support.baselane.com/hc/en-us/articles/25483500489755-What-is-a-1099-K-form-Does-Baselane-provide-me-with-one';
  const [entityData, setEntityData] = useState({});

  // Landlord Missing Data Tax Alert State
  const {
    isOpen: isLandlordMissingDataTaxModalOpen,
    onOpen: onLandlordMissingDataTaxModalOpen,
    onClose: onLandlordMissingDataTaxModalClose,
  } = useDisclosure();

  const { taxData } = useTaxData();

  const completeRequiredData = (entity) => {
    setEntityData(entity);
    onLandlordMissingDataTaxModalOpen();
  };

  const hasMissingData = taxData?.length > 0;

  const today = DateTime.now();

  const daysToExpire = calculateDaysToExpire(today, expirationDay);

  const isOverdue = today > expirationDay;
  const isExpired = today > overdueDay;

  return (
    <BaselaneAlert
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      isCentered
      showCloseButton
      size={isDesktop ? '2xl' : 'sm'}
      body={
        <>
          <LandlordMissingDataTaxModal
            isOpen={isLandlordMissingDataTaxModalOpen}
            onClose={onLandlordMissingDataTaxModalClose}
            entity={entityData}
          />
          {/* Header Section */}
          <Flex gap="1">
            <Heading size="headline-lg" color="brand.neutral.900">
              Information required for 1099-K
            </Heading>
            {isDesktop && !isExpired && (
              <BaselaneChip
                label={isOverdue ? 'Overdue' : `${daysToExpire} days left`}
                leftIcon={null}
                onClick={null}
                onDeleteClick={null}
                size="sm"
                variant={isOverdue ? 'simple-danger' : 'simple-warning'}
              />
            )}
          </Flex>
          {!isDesktop && !isExpired && (
            <BaselaneChip
              label={isOverdue ? 'Overdue' : `${daysToExpire} days left`}
              leftIcon={null}
              onClick={null}
              onDeleteClick={null}
              size="sm"
              variant={isOverdue ? 'simple-danger' : 'simple-warning'}
              mt="8px"
            />
          )}
          <Text textStyle="sm" color="brand.neutral.700" mt="0.5">
            To generate accurate 1099-K form(s) for your 2024 rental income, we need a few
            additional details about the external accounts you have used to collect rent this year.
          </Text>
          <BaselaneLink size="sm" variant="primary" href={url} isExternal target="_blank">
            How is this used?
          </BaselaneLink>
          <Heading size="headline-md" color="brand.neutral.900" mt="3" mb="1">
            Entities requiring information
          </Heading>

          {/* Conditional Content */}
          {hasMissingData ? (
            taxData?.map((entity) => (
              <BaselaneCardNew
                key={uuidv4()}
                onClick={() => completeRequiredData(entity)}
                palette="light"
                variant="normal"
                size="m"
                mb="1"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  w="100%"
                  height="72px"
                  gap="1.5"
                  mx="2"
                  mt="0 !important"
                >
                  <Flex h="100%" alignItems="center" gap={1.5} maxWidth="370px">
                    <IllustrationBankingApplicationawaitingdocuments />
                    <Heading size="headline-sm" color="brand.neutral.900">
                      {entity.entityName}
                    </Heading>
                  </Flex>
                  {isDesktop ? (
                    <BaselaneButton
                      buttonType=""
                      label="Standard Button"
                      onClick={() => completeRequiredData(entity)}
                      palette="primary"
                      rightIcon={null}
                      size="md"
                      variant="filled"
                    >
                      Complete requirements
                    </BaselaneButton>
                  ) : (
                    <BaselaneButtonIcon
                      variant="link"
                      palette="neutral"
                      size="sm"
                      icon={<Icon16ChevronRight />}
                      onClick={() => completeRequiredData(entity)}
                    />
                  )}
                </Stack>
              </BaselaneCardNew>
            ))
          ) : (
            <Box mt="3">
              <BaselaneAlertNew
                body="You can close the dialog now."
                hasCloseButton={false}
                iconName={Icon24CheckCircle}
                onCloseClick={function noRefCheck() {}}
                title="All done!"
                variant="success"
                visual="icon"
              />
            </Box>
          )}
        </>
      }
      footer={
        <Stack
          direction={!isDesktop ? 'row' : 'column'}
          w="100%"
          spacing={isDesktop ? '8px' : '8px'}
        >
          <BaselaneButtonGroup
            size="md"
            styles={{ minW: '100%', gap: 1.5, justifyContent: 'flex-start' }}
          >
            <BaselaneButton
              size="md"
              variant={hasMissingData ? 'transparent' : 'filled'}
              palette="primary"
              width="auto"
              onClick={() => handleLandlordTaxModalRemindMeLaterClick()}
              id="remind-me-later"
            >
              {hasMissingData ? 'Remind me later' : 'Close'}
            </BaselaneButton>
          </BaselaneButtonGroup>
        </Stack>
      }
      bodyStyles={{ m: '0' }}
      footerStyles={{ mt: isDesktop ? '3' : '0' }}
      containerStyles={{
        m: '0 !important',
        p: isDesktop ? '32px!important' : '16px!important',
        borderRadius: '12px',
      }}
    />
  );
};

export default LandlordTaxModal;
