import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';
import userContext from '@contexts/UserContext';
import { useCurrentWorkspace } from '@shared/hooks';
import {
  ONBOARDING_ADD_PROPERTY,
  ONBOARDING_PROPERTY_SURVEY,
  ONBOARDING_TRIAGE,
  UNIFIED_LANDLORD_BANKING,
  UNIFIED_RENT_COLLECTION,
  UNIFIED_BOOKKEEPING_ANALYTICS,
} from '@routes';
import { GET_PROPERTIES_SIMPLE } from '@core/apollo/queries';

import { FEATURE_GATES } from '@core/constants/statsigKeys';
import OnboardingContentView from './OnboardingTriageContentView';
import {
  onboardingContainerStyles,
  onboardingContainerWrapperStyles,
} from './styles/onboardingTriage.styles';

function OnboardingTriage(): any {
  const { checkGate } = useStatsigClient();

  const { user } = useContext(userContext);
  const { workspaceMetadata } = useCurrentWorkspace();
  const { onboardingCompleted = false, role } = user || {};
  const { onboardingTriageSelection = null } = workspaceMetadata?.metadata || {};
  const { onboardingSurvey } = workspaceMetadata || {};
  const continueFlow = onboardingTriageSelection !== null;

  const { pathname = '' } = useLocation();
  const navigate = useNavigate();

  const { data: propertyData, loading } = useQuery(GET_PROPERTIES_SIMPLE);

  // redirect users to correct page based on data
  useEffect(() => {
    const numberOfProperties = propertyData?.property?.length;
    if (propertyData?.property) {
      if (onboardingTriageSelection === 'rentCollection') {
        navigate(UNIFIED_RENT_COLLECTION);
      } else if (onboardingTriageSelection === 'banking') {
        navigate(UNIFIED_LANDLORD_BANKING);
      } else if (onboardingTriageSelection === 'bookkeeping') {
        navigate(UNIFIED_BOOKKEEPING_ANALYTICS);
      } else if (!workspaceMetadata?.onboardingSurvey) {
        navigate(ONBOARDING_PROPERTY_SURVEY);
      } else if (
        numberOfProperties === 0 &&
        checkGate(FEATURE_GATES.ONBOARDING_ADD_PROPERTY_GATE)
      ) {
        navigate(ONBOARDING_ADD_PROPERTY);
      } else if (numberOfProperties > 0 || !checkGate(FEATURE_GATES.ONBOARDING_ADD_PROPERTY_GATE)) {
        navigate(ONBOARDING_TRIAGE);
      }
    } else if (pathname === ONBOARDING_PROPERTY_SURVEY && onboardingSurvey) {
      navigate(ONBOARDING_TRIAGE);
    }
  }, [propertyData?.property, workspaceMetadata?.onboardingSurvey]);

  return role !== 'GUEST' && !onboardingCompleted && !loading && !continueFlow ? (
    <Box {...onboardingContainerWrapperStyles(pathname)}>
      <Flex {...onboardingContainerStyles}>
        <OnboardingContentView />
      </Flex>
    </Box>
  ) : null;
}

export default OnboardingTriage;
