import { gql } from '@apollo/client';

export const ORDER_CHECKBOOK = gql`
  mutation OrderCheckbook(
    $bankAccountId: ID!
    $feeFromBankAccountId: ID!
    $recipientAddress: UnitAddressInput!
  ) {
    orderCheckBook(
      input: {
        bankAccountId: $bankAccountId
        feeFromBankAccountId: $feeFromBankAccountId
        recipientAddress: $recipientAddress
      }
    ) {
      id
      bankAccountId
      orderedAt
      externalId
      status
      startNumber
      endNumber
    }
  }
`;
