import { gql } from '@apollo/client';
import { FRAGMENT_CARD_DATA_IN_ACCOUNT, FRAGMENT_CARD_STATUS } from './fragments';

export const GET_BANK_SUMMARY = gql`
  query BankSummary(
    $isConnectedBank: Boolean
    $isConnectedAccount: Boolean
    $accountType: AccountType
    $isImporting: Boolean
    $isExternal: Boolean
    $isManualAccount: Boolean
  ) {
    bankSummary(
      input: {
        isConnectedBank: $isConnectedBank
        isConnectedAccount: $isConnectedAccount
        accountType: $accountType
        isImporting: $isImporting
        isExternal: $isExternal
        isManualAccount: $isManualAccount
      }
    ) {
      availableCreditCardBalance
      availableDepositoryAccountBalance
      baselaneAccount
      connectedAccount
      baselaneBankBalance
      lifetimeCashback
      lifetimeInterest
      monthlyInflow
      monthlyOutflow
      currentApy
      receivedSuccessfulRentPayment
      transferInProgress
      lastMonthActivity {
        rentCollection
        totalBalance
      }
      bank {
        id
        userId
        plaidInstitutionName
        plaidItemId
        inTransitBalance
        isConnected
        rentCollectionSupported
        isExternal
        logo
        name
        createdAt
        url
        inTransitBalance
        pendingReceivedPayments
        unitAccount {
          id
          isHidden
          phoneNumber
          unitApplicationStatus
          applicationType
          applicationFormId
          userId
          legalBusinessName
          legalAddress {
            city
            country
            postalCode
            state
            street
            unit
          }
          mailingAddress {
            city
            country
            postalCode
            state
            street
            unit
          }
        }
        bankAccounts {
          id
          availableBalance
          accountNumber
          nickName
          accountName
          accountType
          accountSubType
          createdAt
          updatedAt
          currentBalance
          isNewlyAdded
          isConnected
          isExternal
          connectionState
          revokedByUser
          accountStatus
          routingNumber
          transferSupported
          rentCollectionSupported
          transactionSupported
          brbMigration
          bankPartner
          limits {
            cardDailyDepositLimit
            cardDailyDepositTotal
            cardDailyPurchaseLimit
            cardDailyPurchaseTotal
            cardDailyTransactionLimit
            cardDailyTransactionTotal
            cardDailyWithdrawalLimit
            checkDepositDailyLimit
            checkDepositDailyTotal
            checkDepositMonthlyLimit
            checkDepositMonthlyTotal
            checkPaymentDailyLimit
            checkPaymentMonthlyLimit
            checkPaymentDailyTotal
            checkPaymentMonthlyTotal
            dailyCreditTotal
            monthlyCreditTotal
            dailyDebitTotal
            monthlyDebitTotal
            dailyCreditLimit
            monthlyCreditLimit
            dailyDebitLimit
            monthlyDebitLimit
          }
          stripeBankAccountId
          routingNumber
          cards {
            ...CardDataInAccount
            ...CardStatus
          }
          bankAccountMetadata {
            payoutFailedReason {
              failureCode
              failureMessage
            }
            lastPayoutFailed
          }
          subAccounts {
            id
            availableBalance
            accountNumber
            nickName
            accountName
            accountType
            accountSubType
            createdAt
            updatedAt
            currentBalance
            isConnected
            isExternal
            connectionState
            revokedByUser
            accountStatus
            routingNumber
            routingNumber
            stripeBankAccountId
            brbMigration
            bankPartner
            cards {
              ...CardDataInAccount
              ...CardStatus
            }
            bankAccountMetadata {
              payoutFailedReason {
                failureCode
                failureMessage
              }
              lastPayoutFailed
            }
            autoTag {
              enabled
              propertyUnitId
              propertyId
            }
            importTransaction {
              enabled
              importTransactionType
              updatedAt
            }
            linkedFor {
              linkFor
              property {
                id
                propertyUnitId
              }
            }
            limits {
              cardDailyDepositLimit
              cardDailyDepositTotal
              cardDailyPurchaseLimit
              cardDailyPurchaseTotal
              cardDailyTransactionLimit
              cardDailyTransactionTotal
              cardDailyWithdrawalLimit
              checkDepositDailyLimit
              checkDepositDailyTotal
              checkDepositMonthlyLimit
              checkDepositMonthlyTotal
              checkPaymentDailyLimit
              checkPaymentMonthlyLimit
              checkPaymentDailyTotal
              checkPaymentMonthlyTotal
              dailyCreditTotal
              monthlyCreditTotal
              dailyDebitTotal
              monthlyDebitTotal
              dailyCreditLimit
              monthlyCreditLimit
              dailyDebitLimit
              monthlyDebitLimit
            }
            checkBookAvailability {
              isAvailable
              latestOrderDate
              nextOrderDate
              lastCheckNumber
            }
          }
          autoTag {
            enabled
            propertyUnitId
            propertyId
          }
          importTransaction {
            enabled
            importTransactionType
            updatedAt
          }
          linkedFor {
            linkFor
            property {
              id
              propertyUnitId
            }
          }
          checkBookAvailability {
            isAvailable
            latestOrderDate
            nextOrderDate
            lastCheckNumber
          }
        }
      }
    }
  }
  ${FRAGMENT_CARD_DATA_IN_ACCOUNT}
  ${FRAGMENT_CARD_STATUS}
`;

export const GET_BANK_EXT_CONNECTION_STATE = gql`
  query Bank(
    $isConnectedBank: Boolean
    $isConnectedAccount: Boolean
    $accountType: AccountType
    $isImporting: Boolean
    $isExternal: Boolean
    $isManualAccount: Boolean
  ) {
    bank(
      input: {
        isConnectedBank: $isConnectedBank
        isConnectedAccount: $isConnectedAccount
        accountType: $accountType
        isImporting: $isImporting
        isExternal: $isExternal
        isManualAccount: $isManualAccount
      }
    ) {
      id
      bankAccounts {
        id
        connectionState
        isConnected
      }
    }
  }
`;

export const GET_BANK_SUMMARY_CURRENT_APY = gql`
  query BankSummary {
    bankSummary {
      currentApy
    }
  }
`;

export const GET_BANK_LIST = gql`
  query BankList {
    bankList {
      id
      name
      userId
      applicationType
      unitApplicationStatus
      unitApplicationId
      applicationFormId
      availableBalance
      isHidden
      bankAccountId
      bankAccountStatus
    }
  }
`;

export const UPDATE_BANK_ACCOUNT = gql`
  mutation updateBankAccount($input: BankAccountInput!) {
    updateBankAccount(input: $input) {
      id
    }
  }
`;

export const UPDATE_MULITPLE_BANK_ACCOUNTS = gql`
  mutation updateMultipleBankAccount($input: [BankAccountInput]) {
    updateMultipleBankAccount(input: $input) {
      id
      availableBalance
      accountNumber
      nickName
      accountName
      accountType
      accountSubType
      createdAt
      updatedAt
      currentBalance
      isConnected
      isExternal
      connectionState
      revokedByUser
      accountStatus
      routingNumber
      stripeBankAccountId
      cards {
        ...CardDataInAccount
        ...CardStatus
      }
      bankAccountMetadata {
        payoutFailedReason {
          failureCode
          failureMessage
        }
        lastPayoutFailed
      }
      subAccounts {
        id
        availableBalance
        accountNumber
        routingNumber
        nickName
        accountName
        accountType
        accountSubType
        createdAt
        updatedAt
        currentBalance
        isConnected
        isExternal
        connectionState
        revokedByUser
        accountStatus
        routingNumber
        stripeBankAccountId
        cards {
          ...CardDataInAccount
          ...CardStatus
        }
        bankAccountMetadata {
          payoutFailedReason {
            failureCode
            failureMessage
          }
          lastPayoutFailed
        }
        autoTag {
          enabled
          propertyUnitId
          propertyId
        }
        importTransaction {
          enabled
          importTransactionType
          updatedAt
        }
        linkedFor {
          linkFor
          property {
            id
            propertyUnitId
          }
        }
      }
      autoTag {
        enabled
        propertyUnitId
        propertyId
      }
      importTransaction {
        enabled
        importTransactionType
        updatedAt
      }
      linkedFor {
        linkFor
        property {
          id
          propertyUnitId
        }
      }
    }
  }
  ${FRAGMENT_CARD_DATA_IN_ACCOUNT}
  ${FRAGMENT_CARD_STATUS}
`;

export const CLOSE_BASELANE_SUBACCOUNT = gql`
  mutation closeBaselaneSubAccount($id: ID!, $plaidItemId: String!) {
    closeBaselaneSubAccount(id: $id, plaidItemId: $plaidItemId) {
      id
      availableBalance
      accountNumber
      nickName
      accountName
      accountType
      accountSubType
      createdAt
      updatedAt
      currentBalance
      isConnected
      isExternal
      accountStatus
      routingNumber
      stripeBankAccountId
      cards {
        ...CardDataInAccount
        ...CardStatus
      }
      bankAccountMetadata {
        payoutFailedReason {
          failureCode
          failureMessage
        }
        lastPayoutFailed
      }
      autoTag {
        enabled
        propertyUnitId
        propertyId
      }
      importTransaction {
        enabled
        importTransactionType
        updatedAt
      }
      linkedFor {
        linkFor
        property {
          id
          propertyUnitId
        }
      }
    }
  }
  ${FRAGMENT_CARD_DATA_IN_ACCOUNT}
  ${FRAGMENT_CARD_STATUS}
`;

export const DELETE_DRAFT = gql`
  mutation deleteDraftBaselaneAccountApplication($id: Float!) {
    deleteDraftBaselaneAccountApplication(id: $id)
  }
`;

export const RESYNC_BANK_ACCOUNT_BALANCE = gql`
  mutation resyncBankAccountBalance($id: ID!) {
    resyncBankAccountBalance(id: $id) {
      availableBalance
    }
  }
`;
