import React from 'react';
import { Stack, Text, Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import IconExclamationCircle from '@icons/manual/IconExclamationCircle';
import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import BaselaneButton from '../BaselaneButton';
import { BaselaneCard } from '../BaselaneCard';
import {
  containerStyle,
  msgContainerStyle,
  circleStyle,
  iconCardStyle,
  textBoldStyle,
  textSmallStyle,
} from './styles/errorcard.style';
import './styles/errorcard.style.scss';

function BaselaneErrorCard({
  title,
  subtitle1,
  subtitle2,
  btn1Label,
  handleBtn1Click,
  handleBtn2Click,
  btn2Label,
  hasError,
  errorMessage,
  onSuccess,
  successMessage,
  style,
}: {
  title?: string,
  subtitle1?: string,
  subtitle2?: string,
  btn1Label?: string,
  handleBtn1Click?: mixed,
  handleBtn2Click?: mixed,
  btn2Label?: string,
  hasError?: boolean,
  errorMessage?: string,
  onSuccess?: boolean,
  successMessage?: string,
  style: Object,
}): any {
  const containerStyles = { ...containerStyle, ...style };

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  return (
    <BaselaneCard styles={containerStyles}>
      <Box {...msgContainerStyle}>
        <Box {...circleStyle}>
          <Box as="span" {...iconCardStyle} className="error-icon-wrapper">
            <IconExclamationCircle />
          </Box>
        </Box>
        <Text {...textBoldStyle}>{title}</Text>
        <Box mb="48px">
          {subtitle1 && <Text {...textSmallStyle}>{subtitle1}</Text>}
          {subtitle2 && <Text {...textSmallStyle}>{subtitle2}</Text>}
        </Box>
        <Stack spacing="28px" direction={!isMobile ? 'row' : 'column'}>
          <BaselaneButton variant="filled" palette="primary" size="lg" onClick={handleBtn1Click}>
            {btn1Label}
          </BaselaneButton>
          <BaselaneButton
            variant="tonal"
            palette="primary"
            size="lg"
            onClick={
              handleBtn2Click === ''
                ? () => {
                    zendeskAPI('messenger', 'open');
                  }
                : handleBtn2Click
            }
          >
            {btn2Label}
          </BaselaneButton>
        </Stack>

        {hasError && (
          <Text color="red.500A" textStyle="xs" mt="20px">
            {errorMessage}
          </Text>
        )}
        {onSuccess && (
          <Text color="brand.neutral.600" textStyle="xs" mt="20px">
            {successMessage}
          </Text>
        )}
      </Box>
    </BaselaneCard>
  );
}

BaselaneErrorCard.defaultProps = {
  title: 'Something Went Wrong',
  subtitle1: "We're sorry. We're having an issue.",
  subtitle2: 'Please try again or select an option below to get you back on track',
  btn1Label: 'Go to Dashboard',
  handleBtn1Click: () => {
    window.location.href = `/dashboard`;
  },
  handleBtn2Click: '',
  btn2Label: 'Contact Support',
  hasError: false,
  errorMessage: '',
  onSuccess: false,
  successMessage: '',
};

export default BaselaneErrorCard;
