/* eslint-disable react/prop-types */
import React from 'react';
import { Text, Tbody } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import {
  BaselaneCardStack,
  BaselaneResponsiveCell,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
} from '@shared/components';
import formatCurrency from '@core/utils/formatCurrency';
import ReportHeader from '../../components/ReportHeader';
import { isFieldNotObject } from '../../helpers/tenantScreening.helpers';

const CollectionsSummary = ({ collections = [] }) => {
  const { isMax767 } = useBreakPoints();
  const tableColumnConfig = {
    columns: [
      {
        isSortable: false,
        styles: {
          w: isMax767 ? '150px' : '320px',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
      {
        isSortable: false,
        styles: {
          w: '100%',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
    ],
  };

  if (!collections.length) {
    return (
      <BaselaneCardStack
        id="no-collections-summary-data-card"
        size="md"
        width="100%"
        items={[
          {
            id: 'header',
            content: <ReportHeader>Collections summary</ReportHeader>,
          },
          {
            id: 'no-collections-summary-statement',
            content: <Text textStyle="sm">No data available</Text>,
          },
        ]}
      />
    );
  }

  const originalAmount = collections.reduce(
    (acc, collection) => acc + Number(collection.original_amount?.cents) / 100,
    0
  );

  const balance = collections.reduce(
    (acc, collection) => acc + Number(collection.balance?.cents) / 100,
    0
  );

  return (
    <BaselaneCardStack
      id="collections-summary-card"
      key="collections-summary-card"
      size="md"
      items={[
        {
          id: 'header',
          content: <ReportHeader>Collections summary</ReportHeader>,
        },
        {
          id: 'collections-summary-card-body',
          content: (
            <BaselaneResponsiveTable
              id="review-columns"
              config={tableColumnConfig}
              maxW="600px"
              tableContainerStyles={{
                overflowY: 'initial',
                overflowX: 'initial',
              }}
            >
              <Tbody>
                <BaselaneResponsiveTableRows
                  items={[
                    {
                      heading: 'Collections',
                      data: collections.length ?? '--',
                    },
                    {
                      heading: 'Client name',
                      data: collections[0].client?.name ?? '--',
                    },
                    {
                      heading: 'Original amount',
                      data: formatCurrency(originalAmount).inDollars ?? '--',
                    },
                    {
                      heading: 'Balance',
                      data: formatCurrency(balance).inDollars ?? '--',
                    },
                  ]}
                  customRow
                  renderItem={(row) => {
                    return (
                      <BaselaneResponsiveTableRow
                        key={row.heading}
                        chevronCellStyles={{ w: 0 }}
                        contentContainerStyles={{ border: 'none' }}
                      >
                        <BaselaneResponsiveCell configIndex={0} p={0}>
                          <Text as="span" textStyle="sm" color="brand.neutral.700">
                            {row.heading}
                          </Text>
                        </BaselaneResponsiveCell>
                        <BaselaneResponsiveCell configIndex={1} p={0}>
                          <Text textStyle="sm">{isFieldNotObject(row.data) ? row.data : '--'}</Text>
                        </BaselaneResponsiveCell>
                      </BaselaneResponsiveTableRow>
                    );
                  }}
                />
              </Tbody>
            </BaselaneResponsiveTable>
          ),
        },
      ]}
    />
  );
};

export default CollectionsSummary;
