import React from 'react';
import { Center, HStack, Text } from '@chakra-ui/react';
import { IconDotsCircleHorizontal, IconNoteList } from '@icons';
import {
  Icon12Check,
  Icon12Close,
  Icon12Dashboard,
  Icon12InProgress,
  Icon12Overdue,
  Icon12Calendar,
} from '@icons/12px';

const getPaymentFilterItem = (key) => {
  switch (key) {
    case 'PAYMENT_COMPLETE':
      return {
        icon: <Icon12Check />,
        text: 'Completed',
      };

    case 'PAYMENT_PROCESSING':
      return {
        icon: <Icon12InProgress />,
        text: 'Processing',
      };

    case 'PAYMENT_OUTSTANDING':
      return {
        icon: <Icon12Calendar />,
        text: 'Upcoming',
      };

    case 'PAYMENT_FAILED':
      return {
        icon: <Icon12Close />,
        text: 'Failed',
      };

    case 'PAYMENT_OVERDUE':
      return {
        icon: <Icon12Overdue />,
        text: 'Overdue',
      };

    case 'ALL':
      return {
        icon: <Icon12Dashboard />,
        text: 'All invoices',
      };

    default:
      return null;
  }
};

const getDateTypeFilterItem = (key) => {
  switch (key) {
    case 'DUE_DATE':
      return {
        icon: <IconNoteList />,
        text: 'View by Due Date',
      };

    case 'PAYMENT_DATE':
      return {
        icon: <IconNoteList />,
        text: 'View by Payment Date',
      };

    default:
      return null;
  }
};

const getDateFilterItem = (key) => {
  const defaultDateFilterItem = {
    icon: <Icon12Calendar color="#6C7884" />,
    text: '',
  };

  switch (key) {
    case 'THIS_MONTH':
      return {
        ...defaultDateFilterItem,
        text: 'This Month',
      };

    case 'LAST_MONTH':
      return {
        ...defaultDateFilterItem,
        text: 'Last Month',
      };

    case 'NEXT_MONTH':
      return {
        ...defaultDateFilterItem,
        text: 'Next Month',
      };

    case 'CUSTOM':
      return {
        icon: <IconDotsCircleHorizontal w={13.33} h={13.33} color="#6C7884" />,
        text: 'Custom',
      };

    case 'ALL':
      return {
        ...defaultDateFilterItem,
        text: 'All Time',
      };

    default:
      return null;
  }
};

export const getFilterItem = (type, filter, filterStyles) => {
  let filterData;
  switch (type) {
    case 'payment':
      filterData = getPaymentFilterItem(filter);
      break;

    case 'dateType':
      filterData = getDateTypeFilterItem(filter);
      break;

    case 'date':
      filterData = getDateFilterItem(filter);
      break;

    default:
      break;
  }

  return (
    <HStack key={type} spacing={0} {...{ ...filterStyles.datetypefilter({ filterType: type }) }}>
      <Center {...filterStyles.icon}>{filterData?.icon}</Center>
      <Text {...filterStyles.text({ filterType: type })}>{filterData?.text}</Text>
    </HStack>
  );
};
