import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Avatar, HStack, Stack, Tbody, Text } from '@chakra-ui/react';
import * as ROUTES from '@routes';
import {
  BaselaneButton,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveCell,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTableRow,
} from '@core/components/Shared/components';
import DisableMoveMoney from '@core/components/DisableMoveMoney';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import customTheme from '@theme';
import UserAccessContext from '@contexts/UserAccessContext';
import { Icon16Send, Icon16Person, Icon16Property } from '@icons/16px';
import formatCurrency from '@core/utils/formatCurrency';
import { formatDate } from '@core/utils/formatDate';
import { getPaymentTypeName, PAYMENT_METHOD_TYPE } from '../helpers';
import { recipientsTableBodyStyles } from '../styles';

type TableBodyProps = { recipients: Array<Object>, handleEditRecipientClick: Function };

export function TableBody({ recipients, handleEditRecipientClick }: TableBodyProps) {
  const {
    sharedCellStyles,
    nameStyles,
    paymentMethodsStyles,
    lastPaidStyles,
  } = recipientsTableBodyStyles;

  const navigate = useNavigate();
  const currLocation = useLocation();
  const { authorizedForBanking } = useContext(UserAccessContext);
  return (
    recipients?.length > 0 && (
      <Tbody>
        <BaselaneResponsiveTableRows
          customRow
          items={recipients}
          renderItem={(recipient, index) => {
            const paymentMethodsMap = Object.values(recipient.paymentMethods);

            const paymentMethodsList = paymentMethodsMap.reduce((result, paymentMethod) => {
              if (paymentMethod?.type) {
                return result.concat(PAYMENT_METHOD_TYPE[paymentMethod.type]);
              }
              return result;
            }, []);

            return (
              <BaselaneResponsiveTableRow
                onClick={() => handleEditRecipientClick(recipient)}
                id={`table-row-${index}`}
              >
                {/* Name */}
                <BaselaneResponsiveCellTitle
                  title={recipient.name}
                  graphicElement={
                    <Avatar
                      {...nameStyles.avatar}
                      size="sm"
                      icon={
                        recipient.type === 'INDIVIDUAL' ? (
                          <Icon16Person color={customTheme.colors.brand.neutral['600']} />
                        ) : (
                          <Icon16Property color={customTheme.colors.brand.neutral['600']} />
                        )
                      }
                    />
                  }
                  configIndex={0}
                  styles={{
                    ...nameStyles.styles,
                  }}
                  {...sharedCellStyles}
                />

                {/* Payment Methods */}
                <BaselaneResponsiveCell configIndex={1} {...sharedCellStyles}>
                  <HStack>
                    <Text {...paymentMethodsStyles.text}>
                      {paymentMethodsList.sort().join(', ')}
                    </Text>
                  </HStack>
                </BaselaneResponsiveCell>

                {/* Last Paid */}
                <BaselaneResponsiveCell configIndex={2} {...sharedCellStyles}>
                  {recipient?.lastTransfer?.id ? (
                    <Stack {...lastPaidStyles.container}>
                      <Text {...lastPaidStyles.text}>
                        {formatDate(recipient.lastTransfer?.transferDate)}
                      </Text>

                      <Text {...lastPaidStyles.subtext}>
                        {formatCurrency(recipient.lastTransfer?.amount).inDollars} &middot;{' '}
                        {getPaymentTypeName(recipient.lastTransfer?.typeName)}
                      </Text>
                    </Stack>
                  ) : (
                    <Text {...lastPaidStyles.text}>No payments</Text>
                  )}
                </BaselaneResponsiveCell>

                {/*  Send Money */}
                <BaselaneResponsiveCell configIndex={3} {...sharedCellStyles}>
                  {authorizedForBanking && (
                    <DisableMoveMoney
                      tooltipText="Verify your email to send money"
                      renderItem={(isDisabled) => (
                        <BaselaneButton
                          variant="tonal"
                          palette="primary"
                          size="md"
                          leftIcon={<Icon16Send />}
                          isDisabled={isDisabled}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate({
                              pathname: `${currLocation.pathname}/${recipient?.id}/${ROUTES.SEND_FUNDS}`,
                            });
                            sendSegmentEvent('baselane_banking_send_funds_started', {
                              entry_point: 'recipient_list',
                            });
                          }}
                        >
                          Send money
                        </BaselaneButton>
                      )}
                    />
                  )}
                </BaselaneResponsiveCell>
              </BaselaneResponsiveTableRow>
            );
          }}
        />
      </Tbody>
    )
  );
}
