import React from 'react';

import useBreakPoints from '@core/hooks/useBreakPoints';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

const PageHeader = () => {
  const { isMin768 } = useBreakPoints();

  return isMin768 ? <DesktopHeader /> : <MobileHeader />;
};

export default PageHeader;
