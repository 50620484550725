export type MenuState = {
  label: string,
  id: number,
  isValid: boolean,
  name: string,
};

export const menuOptions = ({
  isUnitStepValid,
  isPropertyStepValid,
  isLeaseTermStepValid,
  isTenantStepValid,
  isReviewLeaseStepValid,
  isDraftLeaseStepValid,
  selectedPropertyObject,
}) => {
  return [
    {
      label: 'Select rental property',
      id: 0,
      isValid: selectedPropertyObject?.units?.length > 1 ? isUnitStepValid : isPropertyStepValid,
      isDisabled: isDraftLeaseStepValid,
    },
    {
      label: 'Add lease details',
      id: 1,
      isValid: isLeaseTermStepValid,
      name: 'LEASE_TERM',
      isDisabled:
        selectedPropertyObject?.units?.length > 1
          ? !isUnitStepValid || isDraftLeaseStepValid
          : !isPropertyStepValid || isDraftLeaseStepValid,
    },
    {
      label: 'Add tenant details',
      id: 2,
      isValid: isTenantStepValid,
      name: 'TENANT',
      isDisabled: !isLeaseTermStepValid || isDraftLeaseStepValid,
    },
    {
      label: 'Review order',
      id: 3,
      isValid: isReviewLeaseStepValid,
      name: 'REVIEW_LEASE',
      isDisabled: !isTenantStepValid || isDraftLeaseStepValid,
    },
    {
      label: 'Draft lease',
      id: 4,
      isValid: isDraftLeaseStepValid,
      name: 'DRAFT_LEASE',
      isDisabled: !isReviewLeaseStepValid,
    },
  ];
};
