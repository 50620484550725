export const getInvoicesForUnit = (invoices) => invoices?.filter((item) => !item.isHidden);

export const getPropertyPropsForInvoiceList = ({ leaseId, sortedLeases }) => {
  let props = {};

  const selectedLease = sortedLeases.find((lease) => lease?.id === leaseId);

  if (selectedLease) {
    const { quickPay } = selectedLease;
    const isQuickPay = quickPay?.isQuickPayEligible || quickPay?.isQuickPayEnabled;
    props = {
      propertyName: selectedLease.propertyName,
      propertyAddress: selectedLease.propertyAddress,
      unitName: selectedLease.propertyUnitName,
      hasMultipleUnits: selectedLease.totalUnits > 1,
      tenantId: selectedLease.tenantProfileId,
      receivingAccountId: selectedLease.rentAndFeesBankAccountId,
      isQuickPay,
    };
  }

  return props;
};
