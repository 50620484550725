import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Heading,
  HStack,
  Image,
  Skeleton,
  Text,
  Tbody,
  Thead,
  Tooltip,
  VStack,
  Stack,
} from '@chakra-ui/react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import formatCurrency from '@core/utils/formatCurrency';
import { dateFormatter } from '@core/utils/formatDate';
import formatPhoneNumber from '@core/utils/formatPhoneNumber';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { TENANT_SCREENING_INCOME_VERIFICATION } from '@core/constants/routes';

import {
  BaselaneCardStack,
  BaselaneResponsiveCell,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
} from '@shared/components';
import { Icon16Info } from '@icons/16px';
import plaid from '../TenantScreeningEmptyState/assets/plaid.svg';
import ReportHeader from '../components/ReportHeader';
import { isFieldNotObject } from '../helpers/tenantScreening.helpers';
import { getReportByid, isReportDataInvalid } from '../helpers/tenantScreeningReports.helpers';
import ReportUnavailableAlert from '../components/ReportUnavailableAlert';
import DocumentDropdownContainer from './components/DocumentDropdownContainer';
import PendingEmptyState from './PendingEmptyState';

const IncomeReport = () => {
  const { isMax767 } = useBreakPoints();
  const tableColumnConfig = {
    columns: [
      {
        isSortable: false,
        styles: {
          w: isMax767 ? '150px' : '320px',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
            whiteSpace: 'normal',
          },
        },
      },
      {
        isSortable: false,
        styles: {
          w: '100%',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
            whiteSpace: 'normal',
          },
        },
      },
    ],
  };
  const { verificationId, secondaryVerificationId, externalApplicantId } = useOutletContext();
  const { applicationId } = useParams();

  const [reportData, setReportData] = useState(null);
  const [secondaryReportData, setSecondaryReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [selfReportedIncomeDocIndex, setSelfReportedIncomeDocIndex] = useState(0);

  const titleCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  useEffect(() => {
    if (applicationId && externalApplicantId && verificationId) {
      getReportByid(applicationId, externalApplicantId, verificationId)
        .then(({ data }) => {
          if (data?.name === TENANT_SCREENING_INCOME_VERIFICATION) setReportData(data);
        })
        .catch((error) => {
          console.error('Error fetching application:', error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [applicationId, externalApplicantId, verificationId]);

  useEffect(() => {
    if (applicationId && externalApplicantId && secondaryVerificationId) {
      getReportByid(applicationId, externalApplicantId, secondaryVerificationId)
        .then(({ data }) => {
          setSecondaryReportData(data);
        })
        .catch((error) => {
          console.error('Error fetching application:', error);
        });
    }
  }, [applicationId, externalApplicantId, secondaryVerificationId]);

  const reportDataUsed = reportData; // use mock here if you import it

  if (reportData?.status === 'pending' || isReportDataInvalid(reportData, verificationId)) {
    return reportData?.status === 'pending' ? (
      <PendingEmptyState />
    ) : (
      <ReportUnavailableAlert isLoading={isLoading} />
    );
  }

  // the verified income reports can have multiple bank sources, each with multiple income sources
  // i.e. Tartan -> salary, royalties - Chase bank -> interest, pension, etc
  const bankIncomeArray = [];
  const verifiedIncomeSource = secondaryReportData?.report?.bank_income || [
    secondaryReportData?.report,
  ];
  verifiedIncomeSource?.forEach((item) => {
    item?.items?.forEach((subItem) => {
      const subItemSources = subItem.bank_income_sources || subItem.payroll_income;
      subItemSources?.forEach((subSubItem) => {
        bankIncomeArray.push(
          {
            id: `income-report-card-item-data-${uuidv4()}`,
            style: { borderBottom: '0 !important' }, // overrides last-of-type bottom border
            content: (
              <BaselaneResponsiveTable
                id="review-columns"
                config={tableColumnConfig}
                maxW="600px"
                tableContainerStyles={{
                  overflowY: 'initial',
                  overflowX: 'initial',
                }}
              >
                <Tbody>
                  <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                    <BaselaneResponsiveCellTitle
                      title="Income source"
                      titleSize="headline-sm"
                      styles={{ titleTextStyle: { color: 'black' }, p: 0 }}
                      p={0}
                      configIndex={0}
                      colSpan="2" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                    />
                  </BaselaneResponsiveTableRow>
                </Tbody>
              </BaselaneResponsiveTable>
            ),
          },
          {
            id: `income-report-card-item-data-${uuidv4()}`,
            style: { borderBottom: '0 !important' }, // overrides last-of-type bottom border
            content: (
              <Stack w="100%" px="8px" alignItems="start" direction={{ base: 'row', sm: 'column' }}>
                <VStack alignItems="flex-start" gap={0.5} w="50%">
                  <Text color="brand.neutral.600" textStyle="sm">
                    Source
                  </Text>
                  <Skeleton isLoaded={reportData}>
                    <Text color="brand.neutral.900" textStyle="data-xl">
                      {subSubItem?.income_category?.toUpperCase()}
                      {subItem?.payroll_income && 'Payroll'}
                    </Text>
                  </Skeleton>
                  {subSubItem?.income_category?.toUpperCase() !== 'SALARY' && (
                    <Text color="brand.neutral.600" textStyle="sm">
                      Other
                    </Text>
                  )}
                </VStack>
                <VStack alignItems="flex-start" gap={0.5} w="50%">
                  <Text color="brand.neutral.600" textStyle="sm">
                    Date range
                  </Text>
                  <Skeleton isLoaded={reportData}>
                    <Text color="brand.neutral.900" textStyle="data-xl">
                      {`${dateFormatter(subSubItem?.start_date)} - ${dateFormatter(
                        subSubItem?.end_date
                      )}`}
                    </Text>
                  </Skeleton>
                </VStack>
              </Stack>
            ),
          },
          {
            id: `income-report-card-item-data-${uuidv4()}`,
            style: { borderBottom: '0 !important' }, // overrides last-of-type bottom border
            content: <Divider color="#e2e8f0" />,
          },
          {
            id: `income-report-card-item-data-${uuidv4()}`,
            style: { borderBottom: '0 !important' }, // overrides last-of-type bottom border
            content: (
              <HStack w="100%" px="8px" alignItems="start">
                <VStack alignItems="flex-start" gap={0.5} w="50%">
                  <Text color="brand.neutral.600" textStyle="sm">
                    Gross monthly income
                  </Text>
                  <Skeleton isLoaded={reportData}>
                    <Text color="brand.neutral.900" textStyle="data-xl">
                      {formatCurrency(subSubItem.gross_monthly_income_amount || 0).inDollars}
                    </Text>
                  </Skeleton>
                </VStack>
                <VStack alignItems="flex-start" gap={0.5} w="50%">
                  <Text color="brand.neutral.600" textStyle="sm">
                    Net monthly income
                  </Text>
                  <Skeleton isLoaded={reportData}>
                    <Text color="brand.neutral.900" textStyle="data-xl">
                      {formatCurrency(subSubItem.net_monthly_income_amount || 0).inDollars}
                    </Text>
                  </Skeleton>
                </VStack>
              </HStack>
            ),
          },
          {
            id: `income-report-card-item-data-${uuidv4()}`,
            style: { borderBottom: '0 !important' }, // overrides last-of-type bottom border
            content: (
              <BaselaneResponsiveTable
                id="review-columns"
                config={tableColumnConfig}
                maxW="600px"
                tableContainerStyles={{
                  overflowY: 'initial',
                  overflowX: 'initial',
                }}
              >
                <Tbody>
                  <BaselaneResponsiveTableRows
                    items={[
                      { heading: 'Income description', data: subSubItem?.income_description },
                      { heading: 'Income category', data: subSubItem?.income_category },
                      {
                        heading: 'Employer name',
                        data:
                          subSubItem?.employer?.name ||
                          (subSubItem?.pay_stubs && subSubItem?.pay_stubs[0]?.employer?.name) ||
                          '--',
                      },
                      { heading: 'Income start date', data: dateFormatter(subSubItem?.start_date) },
                      { heading: 'Income end date', data: dateFormatter(subSubItem?.end_date) },
                      { heading: 'Pay frequency', data: subSubItem?.pay_frequency },
                      { heading: 'Transaction count', data: 0 },
                      {
                        heading: 'Monthly net income',
                        data: subSubItem?.net_monthly_income_amount
                          ? formatCurrency(subSubItem?.net_monthly_income_amount).inDollars
                          : '--',
                      },
                      {
                        heading: 'Monthly gross income',
                        data: subSubItem?.gross_monthly_income_amount
                          ? formatCurrency(subSubItem?.gross_monthly_income_amount).inDollars
                          : '--',
                      },
                      {
                        heading: 'Forecasted monthly net income',
                        data: subSubItem?.forecasted_net_monthly_income
                          ? formatCurrency(
                              Number(subSubItem?.forecasted_net_monthly_income?.cents) / 100
                            ).inDollars
                          : '--',
                      },
                      {
                        heading: 'Next payment date',
                        data: subSubItem?.next_payment_date
                          ? dateFormatter(subSubItem?.next_payment_date)
                          : '--',
                      },
                    ]}
                    customRow
                    renderItem={(row) => {
                      return (
                        <BaselaneResponsiveTableRow
                          key={row.heading}
                          chevronCellStyles={{ w: 0 }}
                          contentContainerStyles={{ border: 'none' }}
                        >
                          <BaselaneResponsiveCell configIndex={0} p={0}>
                            <Text as="span" textStyle="sm" color="brand.neutral.600">
                              {row.heading}
                            </Text>
                          </BaselaneResponsiveCell>
                          <BaselaneResponsiveCell configIndex={1} p={0}>
                            <Text textStyle="sm" color="brand.neutral.900" w="100%">
                              {isFieldNotObject(row.data) ? row.data : '--'}
                            </Text>
                          </BaselaneResponsiveCell>
                        </BaselaneResponsiveTableRow>
                      );
                    }}
                  />
                </Tbody>
              </BaselaneResponsiveTable>
            ),
          },
          {
            id: `income-report-card-item-data-${uuidv4()}`,
            style: { borderBottom: '0 !important' }, // overrides last-of-type bottom border
            content: (
              <BaselaneResponsiveTable
                id="review-columns"
                config={tableColumnConfig}
                maxW="600px"
                tableContainerStyles={{
                  overflowY: 'initial',
                  overflowX: 'initial',
                }}
              >
                <Tbody>
                  <BaselaneResponsiveTableRows
                    items={[
                      {
                        heading: 'Verified via bank account',
                        textStyles: { color: 'black', textStyle: 'headline-sm' },
                        rowStyles: { contentContainerStyles: {} },
                      },
                      {
                        heading: 'Bank account',
                        data: subSubItem?.official_name || subSubItem?.name,
                      },
                      { heading: 'Institution', data: subItem?.institution_name },
                      {
                        heading: 'Names',
                        data: subSubItem?.owners?.map((owner) => owner.names.join(', ')),
                      },
                      {
                        heading: 'Phone numbers',
                        data: subSubItem?.owners?.map((owner) =>
                          owner.phone_numbers
                            .map(
                              (phoneNumber) =>
                                `${formatPhoneNumber(phoneNumber.data)} ${phoneNumber.type}\n`
                            )
                            .join(', ')
                        ),
                      },
                      {
                        heading: 'Emails',
                        data: subSubItem?.owners?.map((owner) =>
                          owner.emails.map((email) => `${email.data}`).join(', ')
                        ),
                      },
                      {
                        heading: 'Account holder address',
                        data: subSubItem?.owners
                          ?.map((owner) =>
                            owner.addresses.map(
                              (address) =>
                                ` ${address.data.street} ${address.data.city} ${address.data.region} ${address.data.postal_code} ${address.data.country}`
                            )
                          )
                          .join(',\n&nbsp; \n'),
                      },
                    ]}
                    customRow
                    renderItem={(row) => {
                      return (
                        <BaselaneResponsiveTableRow
                          key={row.heading}
                          chevronCellStyles={{ w: 0 }}
                          contentContainerStyles={{ border: 'none' }}
                          {...row.rowStyles}
                        >
                          <BaselaneResponsiveCell configIndex={0} p={0} {...row.cellStyles}>
                            <Text
                              as="span"
                              textStyle="sm"
                              color="brand.neutral.600"
                              {...row.textStyles}
                            >
                              {row.heading}
                            </Text>
                          </BaselaneResponsiveCell>
                          <BaselaneResponsiveCell configIndex={1} p={0}>
                            <Text
                              textStyle="sm"
                              style={{ textWrap: 'wrap' }}
                              color="brand.neutral.900"
                              w="100%"
                            >
                              {isFieldNotObject(row.data) ? row.data : '--'}
                            </Text>
                          </BaselaneResponsiveCell>
                        </BaselaneResponsiveTableRow>
                      );
                    }}
                  />
                </Tbody>
              </BaselaneResponsiveTable>
            ),
          }
        );
      });
    });
  });

  // Extract offer letter files from income sources and format them to match document assets structure
  const offerLetterFiles =
    reportData?.report?.income_sources
      ?.filter((source) => source?.offer_letter?.offer_letter_file)
      ?.map((source) => ({
        asset: {
          id: uuidv4(),
          url: source.offer_letter.offer_letter_file.asset.url,
          filename: source.offer_letter.offer_letter_file.asset.filename,
          extension: source.offer_letter.offer_letter_file.asset.extension,
        },
      })) || [];

  const combinedDocuments = [
    ...(secondaryReportData?.report?.document?.assets || []),
    ...offerLetterFiles,
  ];

  // the self reported income reports can have multiple income sources
  // i.e. Tartan -> salary, royalties - Chase bank -> interest, pension, etc
  const selfReportedIncomeArray = [];
  if (combinedDocuments.length > 0) {
    selfReportedIncomeArray.push({
      id: `income-report-card-item-data-${uuidv4()}`,
      style: { borderBottom: '0 !important' }, // overrides last-of-type bottom border
      content: (
        <BaselaneResponsiveTable
          id="review-columns"
          config={tableColumnConfig}
          maxW="600px"
          tableContainerStyles={{
            overflowY: 'initial',
            overflowX: 'initial',
          }}
        >
          <Thead>
            <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
              <BaselaneResponsiveCellTitle
                title="Documents"
                titleSize="headline-sm"
                styles={{ titleTextStyle: { color: 'black' }, p: 0 }}
                p={0}
                configIndex={0}
                colSpan="2" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
              />
            </BaselaneResponsiveTableRow>
            <BaselaneResponsiveTableRow
              chevronCellStyles={{ w: 0 }}
              contentContainerStyles={{ border: 'none' }}
            >
              <BaselaneResponsiveCell configIndex={0} p={0} overflow="visible">
                <DocumentDropdownContainer
                  type="self_reported_income"
                  items={combinedDocuments}
                  currentIndex={selfReportedIncomeDocIndex}
                  handleSetCurrentIndex={(value) => setSelfReportedIncomeDocIndex(value)}
                />
              </BaselaneResponsiveCell>
            </BaselaneResponsiveTableRow>
          </Thead>
        </BaselaneResponsiveTable>
      ),
    });
  }
  reportData?.report?.income_sources?.forEach((item) => {
    selfReportedIncomeArray.push(
      {
        id: `income-report-card-item-data-${uuidv4()}`,
        style: { borderBottom: '0 !important' }, // overrides last-of-type bottom border
        content: (
          <BaselaneResponsiveTable
            id="review-columns"
            config={tableColumnConfig}
            maxW="600px"
            tableContainerStyles={{
              overflowY: 'initial',
              overflowX: 'initial',
            }}
          >
            <Thead>
              <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                <BaselaneResponsiveCellTitle
                  title="Income source"
                  titleSize="headline-sm"
                  styles={{ titleTextStyle: { color: 'black' }, p: 0 }}
                  p={0}
                  configIndex={0}
                  colSpan="2" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                />
              </BaselaneResponsiveTableRow>
            </Thead>
          </BaselaneResponsiveTable>
        ),
      },
      {
        id: `income-report-card-item-data-${uuidv4()}`,
        style: { borderBottom: '0 !important' }, // overrides last-of-type bottom border
        content: (
          <Stack w="100%" px="8px" alignItems="start" direction={{ base: 'column', sm: 'row' }}>
            <VStack alignItems="flex-start" gap={0.5} w="50%">
              <Text color="brand.neutral.600" textStyle="sm">
                Source
              </Text>
              <Skeleton isLoaded={reportData}>
                <Text color="brand.neutral.900" textStyle="data-xl">
                  {item?.employment?.company_name?.toUpperCase()}
                  {item?.self_employed?.company_name?.toUpperCase()}
                  {item?.offer_letter?.company_name?.toUpperCase()}
                  {item?.other_income?.paid_by?.toUpperCase()}
                </Text>
              </Skeleton>
              <Text color="brand.neutral.600" textStyle="sm">
                {item?.income_source?.toUpperCase()}
              </Text>
            </VStack>
            <VStack alignItems="flex-start" gap={0.5} w="50%">
              <Text color="brand.neutral.600" textStyle="sm">
                Date range
              </Text>
              <Skeleton isLoaded={reportData}>
                <Text color="brand.neutral.900" textStyle="data-xl">
                  {item?.employment &&
                    `${dateFormatter(item?.employment?.start_date)} - ${
                      item?.employment?.end_date
                        ? dateFormatter(item?.employment?.end_date)
                        : 'Present'
                    }`}
                  {item?.self_employed &&
                    `${dateFormatter(item?.self_employed?.start_date)} - ${
                      item?.self_employed?.end_date
                        ? dateFormatter(item?.self_employed?.end_date)
                        : 'Present'
                    }`}
                  {item?.offer_letter &&
                    `${dateFormatter(item?.offer_letter?.start_date)} - ${
                      item?.offer_letter?.end_date
                        ? dateFormatter(item?.offer_letter?.end_date)
                        : 'Present'
                    }`}
                  {item?.other_income &&
                    `${dateFormatter(item?.other_income?.start_date)} - ${
                      item?.other_income?.end_date
                        ? dateFormatter(item?.other_income?.end_date)
                        : 'Present'
                    }`}
                </Text>
              </Skeleton>
            </VStack>
            <VStack alignItems="flex-start" gap={0.5} w="50%">
              <Text color="brand.neutral.600" textStyle="sm">
                Gross monthly income
              </Text>
              <Skeleton isLoaded={reportData}>
                <Text color="brand.neutral.900" textStyle="data-xl">
                  {formatCurrency(Number(item?.monthly_income?.cents) / 100 || 0).inDollars}
                </Text>
              </Skeleton>
            </VStack>
          </Stack>
        ),
      },
      {
        id: `income-report-card-item-data-${uuidv4()}`,
        style: { borderBottom: '0 !important' }, // overrides last-of-type bottom border
        content: (
          <BaselaneResponsiveTable
            id="review-columns"
            config={tableColumnConfig}
            maxW="600px"
            tableContainerStyles={{
              overflowY: 'initial',
              overflowX: 'initial',
            }}
          >
            <Thead>
              <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                <BaselaneResponsiveCellTitle
                  title={item.other_income ? 'Other income details' : 'Employment details'}
                  titleSize="headline-sm"
                  styles={{ titleTextStyle: { color: 'black' }, p: 0 }}
                  p={0}
                  configIndex={0}
                  colSpan="2" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                />
              </BaselaneResponsiveTableRow>
            </Thead>
            <Tbody>
              <BaselaneResponsiveTableRows
                items={[
                  {
                    heading:
                      item?.employment?.company_name ||
                      item?.self_employed?.company_name ||
                      item?.offer_letter?.company_name
                        ? 'Company name'
                        : 'Paid by',
                    data:
                      item?.employment?.company_name ||
                      item?.self_employed?.company_name ||
                      item?.offer_letter?.company_name ||
                      item?.other_income?.paid_by ||
                      '--',
                  },
                  {
                    heading: item?.other_income ? 'Income description' : 'Job title',
                    data:
                      item?.employment?.job_title ||
                      item?.self_employed?.job_title ||
                      item?.offer_letter?.job_title ||
                      item?.other_income?.income_description ||
                      '--',
                  },
                  {
                    heading: 'Start date',
                    data:
                      item?.employment?.start_date ||
                      item?.self_employed?.start_date ||
                      item?.offer_letter?.start_date ||
                      item?.other_income?.start_date
                        ? dateFormatter(
                            item?.employment?.start_date ||
                              item?.self_employed?.start_date ||
                              item?.offer_letter?.start_date ||
                              item?.other_income?.start_date
                          )
                        : '--',
                  },
                  {
                    heading: 'End date',
                    data:
                      item?.employment?.end_date ||
                      item?.self_employed?.end_date ||
                      item?.offer_letter?.end_date ||
                      item?.other_income?.end_date
                        ? dateFormatter(
                            item?.employment?.end_date ||
                              item?.self_employed?.end_date ||
                              item?.offer_letter?.end_date ||
                              item?.other_income?.end_date
                          )
                        : '--',
                  },
                ]}
                customRow
                renderItem={(row) => {
                  return (
                    <BaselaneResponsiveTableRow
                      key={row.heading}
                      chevronCellStyles={{ w: 0 }}
                      contentContainerStyles={{ border: 'none' }}
                    >
                      <BaselaneResponsiveCell configIndex={0} p={0}>
                        <Text as="span" textStyle="sm" color="brand.neutral.600">
                          {row.heading}
                        </Text>
                      </BaselaneResponsiveCell>
                      <BaselaneResponsiveCell configIndex={1} p={0}>
                        <Text textStyle="sm" color="brand.neutral.900">
                          {isFieldNotObject(row.data) ? row.data : '--'}
                        </Text>
                      </BaselaneResponsiveCell>
                    </BaselaneResponsiveTableRow>
                  );
                }}
              />
            </Tbody>
          </BaselaneResponsiveTable>
        ),
      }
    );
    if (item.employment || item.offer_letter) {
      const usedItem = item.employment || item.offer_letter;
      selfReportedIncomeArray.push({
        id: `income-report-card-item-data-${uuidv4()}`,
        style: { borderBottom: '0 !important' }, // overrides last-of-type bottom border
        content: (
          <BaselaneResponsiveTable
            id="review-columns"
            config={tableColumnConfig}
            maxW="600px"
            tableContainerStyles={{
              overflowY: 'initial',
              overflowX: 'initial',
            }}
          >
            <Thead>
              <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                <BaselaneResponsiveCellTitle
                  title="Supervisor"
                  titleSize="headline-sm"
                  styles={{ titleTextStyle: { color: 'black' }, p: 0 }}
                  p={0}
                  configIndex={0}
                  colSpan="2" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                />
              </BaselaneResponsiveTableRow>
            </Thead>
            <Tbody>
              <BaselaneResponsiveTableRows
                items={[
                  {
                    heading: 'Name',
                    data: usedItem?.supervisor?.first_name
                      ? `${usedItem?.supervisor?.first_name} ${usedItem?.supervisor?.last_name}`
                      : '--',
                  },
                  {
                    heading: 'Phone',
                    data: formatPhoneNumber(usedItem?.supervisor?.supervisor_phone) || '--',
                  },
                  { heading: 'Email', data: usedItem?.supervisor?.supervisor_email || '--' },
                ]}
                customRow
                renderItem={(row) => {
                  return (
                    <BaselaneResponsiveTableRow
                      key={row.heading}
                      chevronCellStyles={{ w: 0 }}
                      contentContainerStyles={{ border: 'none' }}
                    >
                      <BaselaneResponsiveCell configIndex={0} p={0}>
                        <Text as="span" textStyle="sm" color="brand.neutral.600">
                          {row.heading}
                        </Text>
                      </BaselaneResponsiveCell>
                      <BaselaneResponsiveCell configIndex={1} p={0}>
                        <Text textStyle="sm" color="brand.neutral.900">
                          {isFieldNotObject(row.data) ? row.data : '--'}
                        </Text>
                      </BaselaneResponsiveCell>
                    </BaselaneResponsiveTableRow>
                  );
                }}
              />
            </Tbody>
          </BaselaneResponsiveTable>
        ),
      });
    }
    selfReportedIncomeArray.push({
      id: `income-report-card-item-data-${uuidv4()}`,
      style: { borderBottom: '0 !important' }, // overrides last-of-type bottom border
      content: (
        <BaselaneResponsiveTable
          id="review-columns"
          config={tableColumnConfig}
          maxW="600px"
          tableContainerStyles={{
            overflowY: 'initial',
            overflowX: 'initial',
          }}
        >
          <Tbody>
            <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
              <BaselaneResponsiveCellTitle
                title="Pre-tax income"
                titleSize="headline-sm"
                styles={{ titleTextStyle: { color: 'black' }, p: 0 }}
                p={0}
                configIndex={0}
                colSpan="2" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
              />
            </BaselaneResponsiveTableRow>
            <BaselaneResponsiveTableRows
              items={[
                {
                  heading: 'Annual income',
                  data:
                    item?.employment?.pre_tax_income?.income_amount ||
                    item?.self_employed?.pre_tax_income?.income_amount ||
                    item?.offer_letter?.pre_tax_income?.income_amount ||
                    item?.other_income?.pre_tax_income?.income_amount,
                },
              ]}
              customRow
              renderItem={(row) => {
                return (
                  <BaselaneResponsiveTableRow
                    key={row.heading}
                    chevronCellStyles={{ w: 0 }}
                    contentContainerStyles={{ border: 'none' }}
                  >
                    <BaselaneResponsiveCell configIndex={0} p={0}>
                      <Text as="span" textStyle="sm" color="brand.neutral.600">
                        {row.heading}
                      </Text>
                    </BaselaneResponsiveCell>
                    <BaselaneResponsiveCell configIndex={1} p={0}>
                      <Text textStyle="sm" color="brand.neutral.900">
                        {row.data && isFieldNotObject(row.data)
                          ? formatCurrency(row.data).inDollars
                          : '--'}
                      </Text>
                    </BaselaneResponsiveCell>
                  </BaselaneResponsiveTableRow>
                );
              }}
            />
          </Tbody>
        </BaselaneResponsiveTable>
      ),
    });
  });

  return (
    <>
      <VStack alignItems="flex-start" gap={4}>
        {/* Summary of verified income report  */}
        {secondaryReportData?.name === 'verified_income_verification' && (
          <>
            <Heading as="h2" size="headline-sm">
              Verified income
            </Heading>
            <Stack
              gap={2}
              width="100%"
              alignItems="start"
              direction={{ base: 'column', sm: 'row' }}
            >
              <VStack alignItems="flex-start" gap={0.5} w="33%">
                <Text color="brand.neutral.600" textStyle="sm">
                  Gross monthly income
                </Text>
                <Skeleton isLoaded={reportData}>
                  <Text color="brand.neutral.900" textStyle="data-xl">
                    {
                      formatCurrency(
                        Number(secondaryReportData?.report.gross_monthly_income?.cents) / 100 || 0
                      ).inDollars
                    }
                  </Text>
                </Skeleton>
              </VStack>
              <VStack alignItems="flex-start" gap={0.5} w="33%">
                <Text color="brand.neutral.600" textStyle="sm">
                  Net monthly income
                </Text>
                <Skeleton isLoaded={reportData}>
                  <Text color="brand.neutral.900" textStyle="data-xl">
                    {
                      formatCurrency(
                        Number(secondaryReportData?.report.net_monthly_income?.cents) / 100 || 0
                      ).inDollars
                    }
                  </Text>
                </Skeleton>
              </VStack>
              <VStack alignItems="flex-start" gap={0.5} w="33%">
                <Text color="brand.neutral.600" textStyle="sm">
                  Debt to income ratio
                </Text>
                <Skeleton isLoaded={reportData}>
                  <Text color="brand.neutral.900" textStyle="data-xl">
                    {secondaryReportData?.report.debt_income_ratio
                      ? `${secondaryReportData?.report.debt_income_ratio}%`
                      : '--'}
                  </Text>
                </Skeleton>
              </VStack>
            </Stack>
            <Stack
              gap={2}
              width="100%"
              alignItems="start"
              direction={{ base: 'column', sm: 'row' }}
            >
              <VStack alignItems="flex-start" gap={0.5} w="33%">
                <Text color="brand.neutral.600" textStyle="sm">
                  Forecasted net monthly income
                </Text>
                <Skeleton isLoaded={reportData}>
                  <Text color="brand.neutral.900" textStyle="data-xl">
                    {
                      formatCurrency(
                        Number(secondaryReportData?.report.forecasted_net_monthly_income?.cents) /
                          100 || 0
                      ).inDollars
                    }
                  </Text>
                </Skeleton>
              </VStack>
              <VStack alignItems="flex-start" gap={0.5} w="33%">
                <Text color="brand.neutral.600" textStyle="sm">
                  Income sources
                </Text>
                <Skeleton isLoaded={reportData}>
                  <Text color="brand.neutral.900" textStyle="data-xl">
                    {secondaryReportData?.report?.income_source_type === 'payroll'
                      ? '1'
                      : secondaryReportData?.report.income_count || '--'}
                  </Text>
                </Skeleton>
              </VStack>
              <VStack alignItems="flex-start" gap={0.5} w="33%">
                <Text color="white" textStyle="sm">
                  Empty
                </Text>
                <Skeleton isLoaded={reportData}>
                  <Text color="white" textStyle="data-xl">
                    Empty
                  </Text>
                </Skeleton>
              </VStack>
            </Stack>
          </>
        )}
        {/* Summary of self reported income report  */}
        {reportDataUsed?.name === 'income_verification' && (
          <>
            <Heading as="h2" size="headline-sm">
              Self reported income
            </Heading>
            <Stack
              gap={2}
              width="100%"
              alignItems="start"
              direction={{ base: 'column', sm: 'row' }}
            >
              <VStack alignItems="flex-start" gap={0.5} w="33%">
                <Text color="brand.neutral.600" textStyle="sm">
                  Gross monthly income
                </Text>
                <Skeleton isLoaded={reportData}>
                  <Text color="brand.neutral.900" textStyle="data-xl">
                    {
                      formatCurrency(Number(reportDataUsed?.report.current_income?.cents) / 100)
                        .inDollars
                    }
                  </Text>
                </Skeleton>
              </VStack>
              <VStack alignItems="flex-start" gap={0.5} w="33%">
                <Text color="brand.neutral.600" textStyle="sm">
                  Income sources
                </Text>
                <Skeleton isLoaded={reportData}>
                  <Text color="brand.neutral.900" textStyle="data-xl">
                    {reportDataUsed?.report.income_sources?.length || '--'}
                  </Text>
                </Skeleton>
              </VStack>
              <VStack alignItems="flex-start" gap={0.5} w="33%">
                <Text color="brand.neutral.600" textStyle="sm">
                  Monthly assistance amount
                </Text>
                <Skeleton isLoaded={reportData}>
                  <Text color="brand.neutral.900" textStyle="data-xl">
                    {secondaryReportData?.report?.housing_assistance_program?.voucher_amount
                      ? formatCurrency(
                          Number(
                            secondaryReportData?.report?.housing_assistance_program?.voucher_amount
                              .cents
                          ) / 100
                        ).inDollars
                      : '--'}
                  </Text>
                </Skeleton>
              </VStack>
            </Stack>
          </>
        )}
        {bankIncomeArray?.length > 0 && (
          <BaselaneCardStack
            id={`income-report-card-stack-${uuidv4()}`}
            key={uuidv4()}
            size="md"
            items={[
              {
                id: 'header',
                content: (
                  <ReportHeader>
                    <HStack justifyContent="space-between">
                      <Box>Verified income sources</Box>
                      <Image src={plaid} alt="Plaid" />
                    </HStack>
                  </ReportHeader>
                ),
              }, // from below needs to be a map of bank_income or even any other kind of array
            ].concat(bankIncomeArray)}
          />
        )}
        {(secondaryReportData?.report?.net_income_summary ||
          verifiedIncomeSource?.filter((item) => item?.net_income_summary?.length > 0)?.length >
            0) &&
          (
            [{ net_income_summary: secondaryReportData?.report?.net_income_summary }] ||
            verifiedIncomeSource ||
            []
          )?.map((item) => (
            <BaselaneCardStack
              id={`income-report-card-stack-${uuidv4()}`}
              key={uuidv4()}
              size="md"
              items={[
                {
                  id: 'header',
                  content: (
                    <ReportHeader>
                      <HStack justifyContent="space-between">
                        <Box>Verified net earnings</Box>
                        <Image src={plaid} alt="Plaid" />
                      </HStack>
                    </ReportHeader>
                  ),
                },
                {
                  id: `income-report-card-item-data-${uuidv4()}`,
                  style: { borderBottom: '0 !important' }, // overrides last-of-type bottom border
                  content: (
                    <BaselaneResponsiveTable
                      id="review-columns"
                      config={tableColumnConfig}
                      maxW="600px"
                      tableContainerStyles={{
                        overflowY: 'initial',
                        overflowX: 'initial',
                      }}
                    >
                      <Thead>
                        <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                          <BaselaneResponsiveCellTitle
                            title="Date"
                            titleSize="xs-heavy"
                            styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                            p={0}
                            configIndex={0}
                            colSpan="1" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                          />
                          <BaselaneResponsiveCellTitle
                            title="Income source description"
                            titleSize="xs-heavy"
                            styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                            p={0}
                            configIndex={0}
                            colSpan="1" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                          />
                          <BaselaneResponsiveCellTitle
                            title="Net income"
                            titleSize="xs-heavy"
                            styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                            p={0}
                            configIndex={0}
                            colSpan="1" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                          />
                        </BaselaneResponsiveTableRow>
                      </Thead>
                      <Tbody>
                        <BaselaneResponsiveTableRows
                          items={item?.net_income_summary?.flatMap((stub) =>
                            stub?.sources?.map((source) => ({
                              heading: source.pay_date,
                              description: source.description,
                              amount: source.net_pay?.cents,
                            }))
                          )}
                          customRow
                          renderItem={(row) => {
                            return (
                              <BaselaneResponsiveTableRow
                                key={row.heading}
                                chevronCellStyles={{ w: 0 }}
                                contentContainerStyles={{ border: 'none' }}
                              >
                                <BaselaneResponsiveCell configIndex={0} p={0}>
                                  <Text as="span" textStyle="sm" color="brand.neutral.700">
                                    {row.heading ? dateFormatter(row.heading) : '--'}
                                  </Text>
                                </BaselaneResponsiveCell>
                                <BaselaneResponsiveCell configIndex={1} p={0} verticalAlign="top">
                                  <Text textStyle="sm" color="brand.neutral.700">
                                    {row.description || '--'}
                                  </Text>
                                </BaselaneResponsiveCell>
                                <BaselaneResponsiveCell configIndex={1} p={0} verticalAlign="top">
                                  <Text textStyle="sm" color="brand.neutral.700">
                                    {row.amount
                                      ? formatCurrency(Number(row.amount) / 100).inDollars
                                      : '--'}
                                  </Text>
                                </BaselaneResponsiveCell>
                              </BaselaneResponsiveTableRow>
                            );
                          }}
                        />
                      </Tbody>
                    </BaselaneResponsiveTable>
                  ),
                },
              ]}
            />
          ))}
        {selfReportedIncomeArray?.length > 0 && (
          <BaselaneCardStack
            id={`income-report-card-stack-${uuidv4()}`}
            key={uuidv4()}
            size="md"
            items={[
              {
                id: 'header',
                content: (
                  <ReportHeader>
                    <HStack justifyContent="space-between">
                      <Box>Self-reported income sources</Box>
                      <Image src={plaid} alt="Plaid" />
                    </HStack>
                  </ReportHeader>
                ),
              }, // from below needs to be a map of bank_income or even any other kind of array
            ].concat(selfReportedIncomeArray)}
          />
        )}
        <BaselaneCardStack
          id={`income-report-card-stack-${uuidv4()}`}
          key={uuidv4()}
          size="md"
          items={[
            {
              id: 'header',
              content: (
                <ReportHeader>
                  <HStack justifyContent="flext-start">
                    <Box>Projected debt to income ratio</Box>
                    <Tooltip
                      styles={{ height: '20px' }}
                      innerContainerStyles={{ height: '20px' }}
                      label="Projected debt to income ratio = (Monthly debt ÷ Net monthly income) × 100"
                      aria-label="Projected debt to income ratio = (Monthly debt ÷ Net monthly income) × 100"
                    >
                      <Icon16Info />
                    </Tooltip>
                  </HStack>
                </ReportHeader>
              ),
            },
            {
              id: `income-report-card-item-data-${uuidv4()}`,
              style: { borderBottom: '0 !important' }, // overrides last-of-type bottom border
              content: (
                <Stack
                  w="100%"
                  marginTop="24px"
                  px="8px"
                  alignItems="start"
                  direction={{ base: 'column', sm: 'row' }}
                >
                  <VStack alignItems="flex-start" gap={0.5} w="50%">
                    <Text color="brand.neutral.600" textStyle="sm">
                      Monthly debt
                    </Text>
                    <Skeleton isLoaded={reportData}>
                      <Text color="brand.neutral.900" textStyle="data-xl">
                        {
                          formatCurrency(
                            Number(secondaryReportData?.report?.total_monthly_debt?.cents) / 100
                          ).inDollars
                        }
                      </Text>
                    </Skeleton>
                  </VStack>
                  <VStack alignItems="flex-start" gap={0.5} w="50%">
                    <Text color="brand.neutral.600" textStyle="sm">
                      Net monthly income
                    </Text>
                    <Skeleton isLoaded={reportData}>
                      <Text color="brand.neutral.900" textStyle="data-xl">
                        {secondaryReportData?.report?.net_monthly_income
                          ? formatCurrency(
                              (secondaryReportData?.report?.net_monthly_income.cents ?? 0) / 100
                            ).inDollars
                          : '--'}
                      </Text>
                    </Skeleton>
                  </VStack>
                  <VStack alignItems="flex-start" gap={0.5} w="50%">
                    <Text color="brand.neutral.600" textStyle="sm">
                      Debt to income ratio
                    </Text>
                    <Skeleton isLoaded={reportData}>
                      <Text color="brand.neutral.900" textStyle="data-xl">
                        {secondaryReportData?.report?.debt_income_ratio
                          ? `${secondaryReportData?.report?.debt_income_ratio}%`
                          : '--'}
                      </Text>
                    </Skeleton>
                  </VStack>
                </Stack>
              ),
            },
            {
              id: `income-report-card-item-data-${uuidv4()}`,
              style: { borderBottom: '0 !important' }, // overrides last-of-type bottom border
              content: (
                <BaselaneResponsiveTable
                  id="review-columns"
                  config={tableColumnConfig}
                  maxW="600px"
                  tableContainerStyles={{
                    overflowY: 'initial',
                    overflowX: 'initial',
                  }}
                >
                  <Thead>
                    <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                      <BaselaneResponsiveCellTitle
                        title="Monthly debt includes"
                        titleSize="headline-sm"
                        styles={{ titleTextStyle: { color: 'black' }, p: 0 }}
                        p={0}
                        configIndex={0}
                        colSpan="2" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                      />
                    </BaselaneResponsiveTableRow>
                  </Thead>
                  <Tbody>
                    <BaselaneResponsiveTableRows
                      items={secondaryReportData?.report?.debts.map((debt) => ({
                        heading: titleCase(debt.name),
                        subHeading: debt.description,
                        data: debt.amount.cents / 100,
                      }))}
                      customRow
                      renderItem={(row) => {
                        return (
                          <BaselaneResponsiveTableRow
                            key={row.heading}
                            chevronCellStyles={{ w: 0 }}
                            contentContainerStyles={{ border: 'none' }}
                          >
                            <BaselaneResponsiveCell configIndex={0} p={0}>
                              <VStack alignItems="left">
                                <Text as="span" textStyle="md" color="black" fontWeight="500">
                                  {row.heading.replace(/_/g, ' ')}
                                </Text>
                                <Text as="span" textStyle="sm" color="brand.neutral.600">
                                  {row.subHeading}
                                </Text>
                              </VStack>
                            </BaselaneResponsiveCell>
                            <BaselaneResponsiveCell configIndex={1} p={0} verticalAlign="top">
                              <Text textStyle="sm" color="brand.neutral.900">
                                {row.data && isFieldNotObject(row.data)
                                  ? formatCurrency(row.data).inDollars
                                  : '--'}
                              </Text>
                            </BaselaneResponsiveCell>
                          </BaselaneResponsiveTableRow>
                        );
                      }}
                    />
                  </Tbody>
                </BaselaneResponsiveTable>
              ),
            },
          ]}
        />
      </VStack>
      <Outlet />
    </>
  );
};

export default IncomeReport;
