import { Box, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { BaselaneCardNew } from '@shared/components';
import { Icon24Document, Icon24LineChart, Icon24Person } from '@icons/24px';

const BasicReporting = () => {
  return (
    <Box mb={1} id="basic-reporting">
      <BaselaneCardNew bg="brand.darkBlue.100" borderColor="brand.darkBlue.100" id="first" mb={1}>
        <HStack alignItems="flex-start" gap={2}>
          <Box w="24px">
            <Icon24Document />
          </Box>
          <VStack alignItems="flex-start">
            <Heading as="h3" size="headline-sm">
              Rental application
            </Heading>
            <Text textStyle="xs" color="brand.neutral.600">
              Includes self-reported information on housing history, dependents, pets, vehicles,
              smoking, and more.
            </Text>
          </VStack>
        </HStack>
      </BaselaneCardNew>
      <BaselaneCardNew bg="brand.darkBlue.100" borderColor="brand.darkBlue.100" id="second" mb={1}>
        <HStack alignItems="flex-start" gap={2}>
          <Box w="24px">
            <Icon24Person />
          </Box>
          <VStack alignItems="flex-start">
            <Heading as="h3" size="headline-sm">
              Identity verification
            </Heading>
            <Text textStyle="xs" color="brand.neutral.600">
              Verify applicant&apos;s identity (including photo ID) and avoid fraud.
            </Text>
          </VStack>
        </HStack>
      </BaselaneCardNew>
      <BaselaneCardNew bg="brand.darkBlue.100" borderColor="brand.darkBlue.100" id="third" mb={1}>
        <HStack alignItems="flex-start" gap={2}>
          <Box w="24px">
            <Icon24LineChart />
          </Box>
          <VStack alignItems="flex-start">
            <Heading as="h3" size="headline-sm">
              Credit report
            </Heading>
            <Text textStyle="xs" color="brand.neutral.600">
              Instantly receive full Equifax credit report, with payment history and balance across
              credit accounts.
            </Text>
          </VStack>
        </HStack>
      </BaselaneCardNew>
    </Box>
  );
};

export default BasicReporting;
