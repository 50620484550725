import React from 'react';
import { Box, HStack, List, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import { IconCheck, IconLightBulb } from '@icons';

import {
  titleStyles,
  questionStyles,
  answerStyles,
  orderedListStyles,
  checkListStyles,
  checkListItemStyles,
  checkedListItemIconContainerStyles,
  checkListItemTextContainerStyles,
  checkListItemBoldStyles,
  bannerContainerStyles,
  bannerMessageStyles,
  bannerTitleStyles,
} from '../../styles/howToGuidesDrawer.styles';

const baselaneBankingDrawerConst = (apyInterestValue) => ({
  mobileTitle: 'How to Guide - Getting Started with Baselane Banking',
  q1: 'How can a Baselane Banking account simplify your rental property finances?',
  q2: 'What are virtual accounts? How do I open one?',
  q3: 'Is my money safe with Baselane?',
  a1:
    'Baselane Banking is built for landlords, with nifty features to make managing your rental property finances easy:',
  a1List: [
    {
      key: '1',
      boldedText: 'Organized accounts',
      description:
        'Accounts for each property, partitionable by unit, for security deposits or savings.',
    },
    {
      key: '2',
      boldedText: 'Spend responsibly',
      description:
        'Physical & virtual debit cards with spend controls and transaction tagging by Schedule.',
    },
    {
      key: '3',
      boldedText: 'More rewarding',
      description: `Up to ${
        apyInterestValue ?? '--'
      }% APY (60x the national average) and unlimited 1% cash back.`,
    },
    {
      key: '4',
      boldedText: 'For all entity types',
      description: 'Open banking accounts for individuals, LLCs, Partnerships, or Corporations.',
    },
    {
      key: '5',
      boldedText: 'Simple and easy',
      description: 'No minimum balances or monthly fees.',
    },
  ],
  a2:
    'Virtual accounts are like sub-accounts, but they have their own account number. Once you open a Baselane Banking account, you can open multiple virtual accounts with a click of a button to help you organize your finances by property or unit, for security deposits or savings.',
  a2List: {
    title: 'To open a virtual account:',
    list: [
      { key: '1', text: 'Go to the Baselane Banking page' },
      {
        key: '2',
        text: 'Click on the arrow located on the right side of your Baselane Banking account',
      },
      {
        key: '3',
        text: 'Click ‘Add Virtual Account’',
      },
      {
        key: '4',
        text: 'All set! No application is needed',
      },
    ],
  },
  a3:
    'All Baselane accounts are secure, and fully FDIC insured for up to $3,000,000. Banking services provided by Thread Bank, Member FDIC.',
  banner: {
    boldedText: 'Pro Tip: Open multiple virtual accounts per property to stay organized.',
    description:
      'For each property, we recommend a series of accounts: 1) a primary operating account, 2) a virtual account to keep security deposits separated, and 3) a virtual account for a cash buffer for vacancies or savings.',
  },
});

export const baselaneBankingDrawerComponents = (isMobile, apyInterestValue) => {
  const {
    mobileTitle,
    q1,
    q2,
    q3,
    a1,
    a1List,
    a2,
    a2List,
    a3,
    banner,
  } = baselaneBankingDrawerConst(apyInterestValue);
  return {
    mobileTitle: <Text {...titleStyles}>{mobileTitle}</Text>,
    q1: <Text {...questionStyles(isMobile, true)}>{q1}</Text>,
    q2: <Text {...questionStyles(isMobile)}>{q2}</Text>,
    q3: <Text {...questionStyles(isMobile)}>{q3}</Text>,
    a1: (
      <Stack {...answerStyles(isMobile)}>
        <Text>{a1}</Text>
        <List {...checkListStyles(isMobile)} spacing={1}>
          {a1List.map((item) => (
            <ListItem key={item.key} {...checkListItemStyles}>
              <Box {...checkedListItemIconContainerStyles}>
                <IconCheck w={16} h={16} color="#257ED0" />
              </Box>

              <Box {...checkListItemTextContainerStyles}>
                <Box as="span" {...checkListItemBoldStyles}>
                  {item.boldedText} -&nbsp;
                </Box>
                {item.description}
              </Box>
            </ListItem>
          ))}
        </List>
      </Stack>
    ),
    a2: (
      <Stack {...answerStyles(isMobile)}>
        <Text>{a2}</Text>
        <Text mt="24px !important">{a2List.title}</Text>
        <OrderedList {...{ ...orderedListStyles(isMobile), mb: isMobile && '0 !important' }}>
          {a2List.list.map((item) => (
            <ListItem key={item.key}>{item.text}</ListItem>
          ))}
        </OrderedList>
      </Stack>
    ),
    a3: (
      <Stack {...answerStyles(isMobile)}>
        <Text>{a3}</Text>
      </Stack>
    ),
    banner: (
      <HStack {...{ ...bannerContainerStyles, mt: !isMobile && '48px !important' }}>
        <IconLightBulb w={24} h={24} color="#3A4B5B" />
        <Box {...bannerMessageStyles}>
          <Text {...bannerTitleStyles}>{banner.boldedText}</Text>
          <Text>{banner.description}</Text>
        </Box>
      </HStack>
    ),
  };
};
