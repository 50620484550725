import * as ROUTES from '@core/constants/routes';

export const getTitleContent = (user) => {
  return {
    signin: { title: 'Sign In' },
    settings: { title: 'Settings' },
    transactions: { title: 'Transactions' },
    get_started: {
      title: user ? `Let's Get Started` : 'Page Not Found',
    },
    cashflow: { title: 'Analytics & Reporting' },
    [`cashflow/statement`]: { title: 'Analytics & Reporting' },
    [`cashflow/tax_package`]: { title: 'Analytics & Reporting' },
    kpis: { title: 'Property Metrics' },
    properties: { title: 'Properties' },
    [`properties/maintenance_request`]: { title: 'Properties' },
    [`properties/tenant_screening_onboarding`]: { title: 'Properties' },
    leases: { title: 'Rent Collection' },
    tenants: { title: 'Tenants' },
    bankcards: { title: 'Banks & Cards' },
    banking: {
      title: 'Baselane Banking',
    },
    externalaccounts: { title: 'External Accounts' },
    [`externalaccounts/my_accounts`]: { title: 'External Accounts' },
    [`externalaccounts/third_party_accounts`]: { title: 'External Accounts' },
    insurance: { title: 'Rental Property Insurance' },
    loans: { title: 'Loans' },
    unverified: { title: 'Verify your Email' },
    profile: { title: 'Profile & Settings' },
    workspace: { title: 'Workspace settings' },
    [`profile/update_accounts`]: { title: 'Profile & Settings' },
    referral: {
      title: 'Referral',
    },
    lease_agreement: {
      title: 'Lease agreement',
    },
    tenant_screening: {
      title: 'Tenant screening',
    },
    [`tenant_screening/onboarding`]: { title: 'Tenant screening' },
    [`tenant_screening/properties`]: { title: 'Tenant screening' },
    [ROUTES.BOOKKEEPING_RULES]: { title: 'Transactions' },
    [`${ROUTES.BOOKKEEPING_RULES}/${ROUTES.BOOKKEEPING_RULES_AI_CATEGORIZATION_SETTINGS}`]: {
      title: 'Transactions',
    },
    [`${ROUTES.BOOKKEEPING_RULES}/${ROUTES.BOOKKEEPING_RULES_PROPERTY_RULES}`]: {
      title: 'Transactions',
    },
    [`${ROUTES.BOOKKEEPING_RULES}/${ROUTES.BOOKKEEPING_RULES_CREATE}`]: {
      title: 'Transactions',
    },
    transfers: { title: 'Transfers & payments' },
    partners: { title: 'Partners' },
    [`partners/maintenance_request`]: { title: 'Partners' },
  };
};
