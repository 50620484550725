// @flow
import React, { useRef, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Stack } from '@chakra-ui/react';
import { BaselaneDrawer } from '@shared/components';
import { GET_INVOICE_LIST } from '@pages/LeasesPage/queries';
import useBreakPoints from '@core/hooks/useBreakPoints';
import InvoiceHeader from './InvoiceHeader';
import FilterBar from './FilterBar';
import InvoiceList from './InvoiceList/index';
import AddInvoice from './AddInvoice';
import InvoiceTotal from './InvoiceList/InvoiceTotal';
import type { Invoices as InvoicesType } from '../../types';
import { drawerStyles } from '../../styles/invoice.styles';
import FilterPopup from './FilterPopup';

type DrawerBodyProps = {
  isLeaseLevel: boolean,
  propertyName: string,
  unitName: string,
  invoices: InvoicesType,
  setInvoiceList: Function,
  invoiceTitle: string,
  invoiceSubTitle: Object,
  invoiceFilters: Object,
  setInvoiceFilters: Function,
  invoiceTotal: Object,
  setInvoiceTotal: Function,
  refetchLeaseInvoices: Function,
  hasUpdatedInvoice: boolean,
  setHasUpdatedInvoice: Function,
  hasMultipleUnits: boolean,
  showFilterPopup: boolean,
  setShowFilterPopup: Function,
  showFilterPopupAnimation: boolean,
  setShowFilterPopupAnimation: Function,
  handleFilterPopupClose: Function,
  bankProps: Object,
  tenantId: string,
  receivingAccountId: string,
  leaseId: string,
  sortedLeases: Array<Object>,
  leaseInvoicesLoading: boolean,
};

function DrawerBody({
  isLeaseLevel,
  propertyName,
  unitName,
  invoices,
  setInvoiceList,
  invoiceTitle,
  invoiceSubTitle,
  invoiceFilters,
  setInvoiceFilters,
  invoiceTotal,
  setInvoiceTotal,
  refetchLeaseInvoices,
  hasUpdatedInvoice,
  setHasUpdatedInvoice,
  hasMultipleUnits,
  showFilterPopup,
  setShowFilterPopup,
  showFilterPopupAnimation,
  setShowFilterPopupAnimation,
  handleFilterPopupClose,
  bankProps,
  tenantId,
  receivingAccountId,
  leaseId,
  sortedLeases,
  leaseInvoicesLoading: dataLoading,
}: DrawerBodyProps): any {
  const { isMin768 } = useBreakPoints();
  const { body } = drawerStyles(isLeaseLevel, isMin768);
  const [hasFilterChanged, setHasFilterChanged] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(dataLoading);
  const addInvoiceDrawerRef = useRef();
  const addInvoiceDrawerActionBtnRef = useRef();

  const { DrawerBody: InvoiceListDrawerBody } = BaselaneDrawer;

  const handleFilterClick = () => {
    setShowFilterPopup(true);
    setShowFilterPopupAnimation(() => ({ slidein: true, slideout: false }));
  };

  const handleAddInvoiceClick = () => {
    addInvoiceDrawerRef.current?.open();
  };

  const handleAddInvoiceDrawerClose = () => {
    addInvoiceDrawerRef.current?.close();
  };

  const [
    getLeaseInvoices,
    { loading: leaseInvoicesLoading, refetch: refetchAdvancedFilterLeaseInvoices },
  ] = useLazyQuery(GET_INVOICE_LIST, {
    fetchPolicy: 'cache-first',
    onCompleted: ({ invoiceList: invoiceListData }) => {
      // this code gets triggered on both filter popup submit + refetch
      const { invoices: invoicesData, amount, count } = invoiceListData ?? {};
      setInvoiceList(invoicesData);
      setInvoiceTotal({ amount, count });
    },
  });

  useEffect(() => {
    setDataLoaded(true);
  }, [dataLoading]);

  return dataLoaded ? (
    <InvoiceListDrawerBody {...body.container} style={{ overflow: 'hidden' }}>
      {isLeaseLevel && (
        <InvoiceHeader
          title={invoiceTitle}
          subTitle={invoiceSubTitle}
          handleAddInvoiceClick={handleAddInvoiceClick}
        />
      )}
      <Stack {...body.bottomcontainer} px="0">
        {!isLeaseLevel && (
          <>
            <Stack>
              <FilterBar {...{ invoiceFilters, handleFilterClick }} />
              <FilterPopup
                {...{
                  invoiceFilters,
                  showFilterPopup,
                  handleFilterPopupClose,
                  showFilterPopupAnimation,
                  setInvoiceList,
                  setInvoiceFilters,
                  setInvoiceTotal,
                  getLeaseInvoices,
                  leaseInvoicesLoading,
                  setHasFilterChanged,
                }}
              />
            </Stack>

            <InvoiceTotal {...{ invoiceTotal }} />
          </>
        )}
        <InvoiceList
          {...{
            isLeaseLevel,
            invoices,
            refetchLeaseInvoices: hasFilterChanged
              ? refetchAdvancedFilterLeaseInvoices
              : refetchLeaseInvoices,
            hasUpdatedInvoice,
            setHasUpdatedInvoice,
            sortedLeases,
            hasMultipleUnits,
          }}
        />

        <AddInvoice
          {...{
            propertyName,
            unitName,
            addInvoiceDrawerRef,
            addInvoiceDrawerActionBtnRef,
            handleAddInvoiceDrawerClose,
            isPropertyDeleted: !propertyName && !unitName,
            isMultiUnit: hasMultipleUnits,
            bankProps,
            tenantId,
            receivingAccountId,
            leaseId,
            refetchLeaseInvoices,
          }}
        />
      </Stack>
    </InvoiceListDrawerBody>
  ) : null;
}

export default DrawerBody;
