/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, useToast } from '@chakra-ui/react';
import { BaselaneButton, BaselaneModal } from '@shared/components';
import onDrawerClose from '@core/utils/onDrawerClose';
import SlLoader from '@core/components/Loader';
import { LEASE_AGREEMENT } from '@routes';
import { IllustrationOtherError2 } from '@illustrations';
import { useDeleteAgreement } from './hooks/useDeleteAgreement';

const DeleteLeaseAgreementModal = ({ leaseAgreementId, handleClose, navigateFrom }) => {
  const navigate = useNavigate();
  const { leaseagreementId } = useParams();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const { handleDeleteLease, loading: deletingLease } = useDeleteAgreement();

  const showDeleteSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Lease agreement deleted successfully.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showDeleteErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Error: Could not delete lease agreement.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleDelete = async () => {
    setIsLoading(true);
    const success = await handleDeleteLease(leaseagreementId);
    setIsLoading(false);

    if (success) {
      showDeleteSuccessToast();
      handleClose();
      onDrawerClose(navigate, `/${LEASE_AGREEMENT}`);
    } else {
      showDeleteErrorToast();
    }
  };

  return (
    <>
      {deletingLease && (
        <Box>
          <SlLoader styles={{ position: 'absolute !important' }} />
        </Box>
      )}
      <BaselaneModal
        closeOnOverlayClick
        onCloseBtnClick={() => {
          navigate('..');
        }}
        defaultOpen
        showCloseButton
        closeBtnId="delete-lease-agreement-close"
        showHeader
        modalTitleStyles={{ fontSize: '20px' }}
        modalTitle="Delete lease agreement?"
        icon={<IllustrationOtherError2 />}
        description="The lease agreement will be deleted permanently and removed from any rent collections it’s attached to."
        showBody={false}
        size="md"
      >
        <Box display="flex" gap="2" direction="row" w="100%">
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size="md"
            onClick={handleClose}
            styles={{
              w: 'full',
            }}
          >
            Cancel
          </BaselaneButton>
          <BaselaneButton
            variant="filled"
            palette="danger"
            size="md"
            isLoading={isLoading}
            onClick={handleDelete}
            styles={{
              w: 'full',
            }}
          >
            Delete
          </BaselaneButton>
        </Box>
      </BaselaneModal>
    </>
  );
};

export default DeleteLeaseAgreementModal;
