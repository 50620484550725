import React from 'react';
import { Outlet } from 'react-router-dom';
import { EmailOtpProvider } from '@core/contexts/EmailOtpContext';

/**
 * Note: This wrapper allows the provider to be used as a route element.
 */

const EmailOtpWrapper = () => {
  return (
    <EmailOtpProvider>
      <Outlet />
    </EmailOtpProvider>
  );
};

export default EmailOtpWrapper;
