export const titleStyles = {
  fontSize: 'lg',
  lineHeight: '26px',
  fontWeight: 'medium',
  color: 'brand.neutral.700',
};

export const formLabelStyles = {
  fontSize: 'xs',
  color: 'brand.neutral.700',
  fontWeight: 'normal',
};

export const formInputStyles = {
  fontSize: 'sm',
  color: 'brand.neutral.700',
  fontWeight: 'normal',
  borderRadius: '4px',
};

export const formContainerStyles = (isMobile) => ({
  w: isMobile ? '100%' : '65%',
  ps: isMobile ? '0px' : '48px',
  pb: isMobile ? '8px' : '0px',
});

export const tabPanelsStyles = (isMax576) => ({
  p: isMax576 ? 0 : 0,
});

export const notificationsStyles = {
  sectionTitleStyles: {
    fontSize: 'md',
    lineHeight: '24px',
    color: 'brand.neutral.700',
  },
  descriptionStyles: {
    textStyle: 'sm',
    color: 'brand.neutral.700',
  },
  switchContainerStyles: {
    w: '200px',
    justifyContent: 'space-between',
  },
  switchLabelStyles: {
    color: 'brand.neutral.700',
    fontSize: 'xs',
    lineHeight: '20px',
    fontWeight: 'medium',
    mr: '0',
  },
};

export const passwordStyles = {
  descriptionStyles: {
    textStyle: 'sm',
    color: 'brand.neutral.700',
    mb: '48px',
  },
};

const badgeIconBgStyles = {
  borderRadius: '50%',
  p: '6px',
  w: '32px',
  h: '32px',
  alignItems: 'center',
  justifyContent: 'center',
};

const badgeIconContainerStyles = {
  bg: 'transparent',
  w: '16px',
  h: '16px',
};

export const badgeIconBgSuccessStyles = {
  ...badgeIconBgStyles,
  bg: 'green.100',
};

export const badgeIconSuccessContainerStyles = {
  ...badgeIconContainerStyles,
  color: 'green.800AA',
};

export const badgeIconBgErrorStyles = {
  ...badgeIconBgStyles,
  bg: 'yellow.100',
};

export const badgeIconErrorContainerStyles = {
  ...badgeIconContainerStyles,
  w: '18px',
  h: '18px',
  color: 'yellow.700',
};

export const titleTextStyles = {
  ml: '16px',
};

export const bodyTextStyles = {
  textStyle: 'sm',
};

export const errorStyles = {
  textStyle: 'xs',
  color: 'red.800AA',
  marginTop: '8px',
  marginLeft: '16px',
};

export const profileDataContainerStyles = (isMobile) => ({
  w: isMobile ? '100%' : '30%',
  pr: isMobile ? '32px' : '48px',
  spacing: isMobile ? '16px' : '32px',
});
