import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import useBreakPoints from '@core/hooks/useBreakPoints';
import {
  menuItemSubAccountStyles,
  menuItemTextStyles,
} from '@core/components/Transactions/components/TransactionsFilters/styles/transactionsFilters.styles';
import { Highlighted } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import T1WithTitle from '../../Shared/components/BaselaneDropdown-new/T1-Title';

type Props = {
  bankProps: Object,
};

const CardDropdown = ({ bankProps }: Props) => {
  const { isMax768 } = useBreakPoints();
  const { setFieldValue, values } = useFormikContext();

  // physical and virtual cards associated with entity accounts
  const accountCards =
    bankProps?.bank?.flatMap((item) => item?.bankAccounts)?.flatMap((account) => account.cards) ||
    [];

  // cards associated with virtual accounts
  const virtualAccountCards =
    bankProps?.bank
      ?.flatMap((item) => item?.bankAccounts)
      ?.flatMap((item) => item?.subAccounts)
      ?.flatMap((account) => account.cards) || [];

  // all cards
  const allCards = [...accountCards, ...virtualAccountCards];

  const options = [
    {
      id: 'baselane cards',
      title: 'Virtual cards',
      items: allCards
        ?.filter((card) => !card?.isPhysical && card?.cardStatus === 'Active')
        ?.map((card) => ({
          isPhysical: card.isPhysical,
          id: card.id,
          nickname: card.nickname,
          nameOnCard: card.nameOnCard,
          externalId: card.externalId,
          last4Digits: card.last4Digits,
        })),
    },
    {
      id: 'baselane cards',
      title: 'Physical cards',
      items: allCards
        ?.filter((card) => card?.isPhysical && card?.cardStatus === 'Active')
        ?.map((card) => ({
          isPhysical: card.isPhysical,
          id: card.id,
          nickname: card.nickname,
          nameOnCard: card.nameOnCard,
          externalId: card.externalId,
          last4Digits: card.last4Digits,
        })),
    },
  ];

  const renderAccountDropdownItem = ({ item, search }: AccountDropdownItemProps) => {
    return (
      <Stack gap={0} width="100%">
        <Text {...menuItemTextStyles} color="inherit">
          <Highlighted text={item?.nickname || item?.nameOnCard} highlight={search} />
        </Text>
        <Text {...menuItemSubAccountStyles}>
          <Highlighted
            text={`${item?.isPhysical ? 'Physical' : 'Virtual'} card - ${item?.last4Digits}`}
            highlight={search}
          />
        </Text>
      </Stack>
    );
  };

  const selectedItem = options
    ?.flatMap((option) => option?.items)
    ?.find((item) => item?.id === values?.cardId?.toString());

  return (
    <T1WithTitle
      additionalProps={{ id: 'account-dropdown' }}
      classNames={[
        'input-form-md',
        'auto-width-dropdown',
        'select-auto-width',
        'auto-select-input-width',
        'input-form-md',
        'auto-height',
      ]}
      data={options}
      selectedItem={selectedItem}
      searchTerm={['nickname', 'last4Digits', 'nameOnCard']}
      showValueByFields={['nickname', 'last4Digits']}
      itemRenderer={renderAccountDropdownItem}
      handleSubmit={(value) => {
        setFieldValue('cardId', value);
      }}
      isMulti={false}
      hasFilterWrapper={false}
      hasCheckboxes={false}
      showDivider={false}
      showTitleInSearch={false}
      hideClearButton
      // parentId={isFromResponsiveMode ? 'drawer-body' : 'page-feature'}
      placeholder="Select card"
      title="card"
      dropdownPosition="bottom"
      isMobile={isMax768}
    />
  );
};

export default CardDropdown;
