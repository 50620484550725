import React, { useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import IconVerifyIdentity from '@icons/manual/IconVerifyIdentity';
import BaselaneKYCForm from '@shared/components/BaselaneKYCForm';
import KYCReturnStatusDrawer from '@shared/components/BaselaneKYCForm/components/KYCReturnStatusDrawer';
import useReturnStatus from '@shared/components/BaselaneKYCForm/hooks/useReturnStatus';
import VouchedIdentificationDrawer from '@shared/components/VouchedIdentificationDrawer';
import useBreakPoints from '@core/hooks/useBreakPoints';
import UserAccessContext from '@contexts/UserAccessContext';
import { IllustrationOtherIdentityverificationfailed } from '@illustrations';
import { BaselaneAlertNew, BaselaneButton, BaselaneMessageCard } from '../../components';

type KYCBannerProps = {
  user: Object,
  userKyc: Object,
  userKycStatus: string,
  refetchUser: Function,
};

const KYCBanner = ({ user, userKyc, userKycStatus, refetchUser, ...rest }: KYCBannerProps) => {
  const { isWorkspaceOwner } = useContext(UserAccessContext);
  const ownerTitle =
    userKycStatus === 'VOUCH_PENDING'
      ? 'Identity verification not completed'
      : 'Verify your identity';
  const collaboratorTitle = 'Identity verification required for workspace owner';
  const description =
    userKycStatus === ' '
      ? 'You will need to upload ID before you can collect rent on Baselane'
      : 'To collect rent or view tenant screening reports, please verify your identity.';
  const cta = userKycStatus === 'VOUCH_PENDING' ? 'Verify ID' : 'View details';
  const collaboratorsDescription =
    'To collect rent or view tenant screening reports, the workspace owner needs to verify their identity.  ';
  const location = useLocation();
  const { pathname } = location;

  const isKYCSubmitted = userKycStatus !== 'UNVERIFIED' && userKycStatus !== null;
  const { isMin768: isDesktop } = useBreakPoints();

  const isDashboardPage = pathname.includes('dashboard');
  const isTransactionsPage = pathname.includes('transactions');

  const {
    kycReturnStatusDrawerRef,
    handleKYCReturnStatusDrawerClose,
    handleKYCReturnStatusDrawerOpen,
  } = useReturnStatus();

  const vouchedDrawerRef = useRef(null);

  const handleVouchedDrawerOpen = () => {
    vouchedDrawerRef?.current?.open();
  };

  const kycForProps = {
    button: {
      variant: 'filled',
      palette: 'primary',
      text: isKYCSubmitted ? 'View details' : 'Verify Identity',
      leftIcon: isKYCSubmitted ? null : <IconVerifyIdentity />,
    },
    user,
    userKyc,
    refetchUser,
    kycStatus: userKycStatus,
    handleKYCReturnStatusDrawerOpen,
    handleVouchedDrawerOpen,
  };

  const handleLearnMoreOpen = () => {
    if (userKycStatus === 'VOUCH_PENDING') {
      handleVouchedDrawerOpen();
    } else {
      handleKYCReturnStatusDrawerOpen();
    }
  };

  const rightElement = isKYCSubmitted ? (
    <BaselaneButton {...kycForProps.button} onClick={handleLearnMoreOpen}>
      {cta}
    </BaselaneButton>
  ) : (
    <BaselaneKYCForm {...kycForProps} />
  );

  const bottomElement = isKYCSubmitted ? (
    <Box width="100%" alignItems="left">
      <BaselaneButton {...kycForProps.button} onClick={handleLearnMoreOpen}>
        {cta}
      </BaselaneButton>
    </Box>
  ) : (
    <Box width="100%" alignItems="left">
      <BaselaneKYCForm {...kycForProps} />
    </Box>
  );

  return (
    <Box {...rest}>
      {isDesktop ? (
        <Box mb={4}>
          <BaselaneAlertNew
            variant="danger"
            visual="illustration"
            iconName={IllustrationOtherIdentityverificationfailed}
            title={isWorkspaceOwner ? ownerTitle : collaboratorTitle}
            body={isWorkspaceOwner ? description : collaboratorsDescription}
            customButton={isWorkspaceOwner && rightElement}
          />
        </Box>
      ) : (
        <BaselaneMessageCard
          title={isWorkspaceOwner ? ownerTitle : collaboratorTitle}
          borderColor="secondaryred"
          iconName=""
          isVertical
          containerStyles={{
            background: 'red.200',
            marginBottom: isDashboardPage || isTransactionsPage ? '16px!important' : '0px',
            width: '100%',
          }}
          text={isWorkspaceOwner ? description : collaboratorsDescription}
          bottomElement={isWorkspaceOwner ? bottomElement : null}
        />
      )}
      <KYCReturnStatusDrawer
        kycStatus={userKycStatus}
        drawerRef={kycReturnStatusDrawerRef}
        handleKYCReturnStatusDrawerClose={handleKYCReturnStatusDrawerClose}
      />
      <VouchedIdentificationDrawer
        {...{
          userKyc,
          user,
          refetchUser,
          vouchedDrawerRef,
        }}
      />
    </Box>
  );
};

export default KYCBanner;
