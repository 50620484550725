import { gql } from '@apollo/client';

export const CASHFLOW_QUERY = gql`
  query CashFlow($input: CashFlowInput) {
    cashFlow(input: $input) {
      totalDocuments
      summary {
        netCashFlow
        totalExpenseAmount
        totalRevenueAmount
        categorizedTrxCount
        uncategorizedTrxCount
      }
      byMonth {
        date
        income
        expense
      }
      byCategory {
        name
        subCategories {
          name
          type
          amount
          subCategories {
            name
            type
            amount
          }
        }
        amount
      }
      byCategoryMonth {
        date
        cashFlowStatement {
          netOperatingIncome
          netCashFlow
          totalInflowOutflow
        }
        categories {
          name
          subCategories {
            name
            type
            amount
            subCategories {
              name
              type
              amount
            }
          }
          amount
        }
      }
    }
  }
`;

export const CASHFLOW_DASHBOARD_CARD = gql`
  query CashFlow($input: CashFlowInput) {
    cashFlow(input: $input) {
      summary {
        netCashFlow
        totalExpenseAmount
        totalRevenueAmount
        categorizedTrxCount
        uncategorizedTrxCount
      }
      byMonth {
        date
        income
        expense
      }
    }
  }
`;

export const CASHFLOW_SUMMARY_QUERY = gql`
  query CashFlowSummary($input: CashFlowInput) {
    cashFlow(input: $input) {
      summary {
        netCashFlow
        totalExpenseAmount
        totalRevenueAmount
      }
    }
  }
`;

export const GET_PRESETS = gql`
  query Presets {
    preset {
      name
      tagId
      uncategorized
    }
  }
`;

export const PROPERTY_CASHFLOW_QUERY = gql`
  query PropertyCashFlow($input: CashFlowInput) {
    propertyCashFlow(input: $input) {
      totalDocuments
      summary {
        netCashFlow
        totalExpenseAmount
        totalRevenueAmount
        categorizedTrxCount
        uncategorizedTrxCount
      }
      byProperty {
        propertyId
        income
        expense
      }
      byCategory {
        name
        subCategories {
          name
          type
          amount
          subCategories {
            name
            type
            amount
          }
        }
        amount
      }
      byCategoryProperty {
        propertyId
        cashFlowStatement {
          netOperatingIncome
          netCashFlow
          totalInflowOutflow
        }
        categories {
          name
          subCategories {
            name
            type
            amount
            subCategories {
              name
              type
              amount
            }
          }
          amount
        }
      }
    }
  }
`;

export const PROPERTY_UNIT_CASHFLOW_QUERY = gql`
  query PropertyUnitCashFlow($input: CashFlowInput) {
    propertyUnitCashFlow(input: $input) {
      totalDocuments
      summary {
        netCashFlow
        totalExpenseAmount
        totalRevenueAmount
        categorizedTrxCount
        uncategorizedTrxCount
      }
      byPropertyUnit {
        propertyUnitId
        propertyId
        propertyUnitUniqueId
        income
        expense
      }
      byCategory {
        name
        subCategories {
          name
          type
          amount
          subCategories {
            name
            type
            amount
          }
        }
        amount
      }
      byCategoryPropertyUnit {
        propertyUnitId
        propertyId
        propertyUnitUniqueId
        cashFlowStatement {
          netOperatingIncome
          netCashFlow
          totalInflowOutflow
        }
        categories {
          name
          subCategories {
            name
            type
            amount
            subCategories {
              name
              type
              amount
            }
          }
          amount
        }
      }
    }
  }
`;
