import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { sharedSizeStyles } from './input';

/**
 * This overrides Chakra's default <Select /> styles.
 *
 * Note: _field_ sets the styles for the actual select.
 * _icon_ sets the styles for the icon, usually a chevron.
 *
 * see: node_modules/@chakra-ui/theme/src/components/select.ts for implementation details
 */

const parts = ['field', 'icon']; // Define the parts of the Select component
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

export const Select = defineMultiStyleConfig({
  baseStyle: {
    field: {
      paddingInlineStart: 1,
      color: 'brand.neutral.900',
      border: '1px solid',
      background: 'white',
      borderColor: 'brand.darkBlue.200',
      _invalid: {
        boxShadow: 'none',
        _hover: {
          borderColor: 'red.400',
          boxShadow: 'none',
        },
        _active: {
          boxShadow: `0px 0px 0px 2px #E9F1FF inset`,
          borderColor: 'red.400',
        },
        _focusVisible: {
          boxShadow: `0px 0px 0px 2px #E9F1FF inset`,
          borderColor: 'red.400',
        },
      },
      _placeholder: { color: 'brand.neutral.500' },
      _hover: {
        borderColor: 'brand.darkBlue.300',
      },
      _active: {
        borderColor: 'brand.blue.700',
      },
      _focus: {
        borderColor: 'brand.blue.700',
        boxShadow: `0px 0px 0px 2px #E9F1FF inset`,
      },
      _focusVisible: {
        boxShadow: `0 0 0 2px #E9F1FF inset`,
      },
      _disabled: {
        color: 'brand.neutral.400',
        background: 'brand.neutral.150',
        borderColor: 'brand.neutral.250',
        cursor: 'not-allowed',
        opacity: '1',
        _hover: {
          borderColor: 'brand.neutral.250',
        },
        _active: {
          borderColor: 'brand.neutral.250',
        },
        _focus: {
          boxShadow: 'none',
        },
        _focusVisible: {
          boxShadow: 'none',
        },
      },
    },
  },
  sizes: {
    lg: {
      field: {
        px: sharedSizeStyles.lg.px,
        fontSize: '14px',
        fontWeight: '100',
      },
    },
    md: {
      field: {
        px: sharedSizeStyles.md.px,
        fontSize: '12px',
        fontWeight: '100',
      },
    },
    sm: {
      field: {
        px: sharedSizeStyles.sm.px,
        paddingInlineEnd: 1,
        fontSize: '10px',
        fontWeight: '100',
      },
    },
    xs: {
      field: {
        px: sharedSizeStyles.xs.px,
        paddingInlineEnd: 1,
        fontWeight: '100',
      },
    },
  },
});
