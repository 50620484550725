import { gql } from '@apollo/client';

export const GET_LEASES_SUMMARY = gql`
  query Leases($input: LeaseFilter!) {
    leases(input: $input) {
      totalCount
      leases {
        id
        propertyUnitId
      }
    }
  }
`;
