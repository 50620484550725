import { gql } from '@apollo/client';

export const USER_KYC = gql`
  mutation userKYC(
    $firstName: String
    $middleName: String
    $lastName: String
    $dob: String
    $dobDay: String
    $dobMonth: String
    $dobYear: String
    $ssn: String
    $ssnLast4: String
    $ip: String
    $userAgent: String
    $address: UserAddress
    $iovation: String
  ) {
    userKYC(
      input: {
        firstName: $firstName
        middleName: $middleName
        lastName: $lastName
        dob: $dob
        dobDay: $dobDay
        dobMonth: $dobMonth
        dobYear: $dobYear
        ssn: $ssn
        ssnLast4: $ssnLast4
        ip: $ip
        userAgent: $userAgent
        address: $address
        iovation: $iovation
      }
    ) {
      id
      userKycStatus
    }
  }
`;
export const UPDATE_VOUCHED = gql`
  mutation updateVouched(
    $vouchedToken: String
    $vouchedEntityId: String
    $vouchedKycStatus: String
  ) {
    updateVouched(
      input: {
        vouchedToken: $vouchedToken
        vouchedEntityId: $vouchedEntityId
        vouchedKycStatus: $vouchedKycStatus
      }
    ) {
      id
    }
  }
`;
