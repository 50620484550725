import React, { useContext } from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import { useStatsigClient } from '@statsig/react-bindings';
import { BaselaneCardStack, BaselaneDrawer, BaselaneCardNew } from '@shared/components';
import BankEntityContext from '@contexts/BankEntityContext';
import { IconTransfersPayments, IconAddFundsToo } from '@icons';
import { Icon16ChevronRight } from '@icons/16px';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

import { DYNAMIC_CONFIGS } from '@core/constants/statsigKeys';
import {
  optionsCardStyles,
  optionsTitleStyles,
  optionsDescriptionStyles,
  optionsIconStyles,
} from './styles/accountOptions.styles';

type AccountOptionsProps = {
  selectOption: Function,
  selectedBankAccount: Object | undefined,
};

const AccountOptions = ({ selectOption, selectedBankAccount }: AccountOptionsProps) => {
  const { DrawerBody } = BaselaneDrawer;
  const { isMax576 } = useBreakPoints();
  const { account } = useContext(BankEntityContext);

  const { getDynamicConfig } = useStatsigClient();
  const config = getDynamicConfig(DYNAMIC_CONFIGS.APY_INTEREST);

  const { bankName } = account ?? selectedBankAccount ?? {};

  const accountOptions = [
    {
      id: 'add-checking-account-card',
      option: 'checking',
      icon: <IconTransfersPayments />,
      title: 'Checking account',
      description:
        'Made for managing your day-to-day finances. Great for payments, collecting rent & debit cards.',
    },
    {
      id: 'add-savings-account-card',
      option: 'savings',
      icon: <IconAddFundsToo />,
      title: 'Savings account',
      description: (
        <Text as="span">
          Made for growing your money with up to {config?.get('apy') ?? '--'}% APY. Unlimited free
          internal transfers, not eligible for payments.
        </Text>
      ),
    },
  ];

  const handleAccountSelection = (option) => {
    sendSegmentEvent('baselane_banking_add_virtual_account_flow_complete_type_selection', {
      type: option,
    });
    selectOption(option);
  };

  const accountCards = accountOptions.map((acc) => ({
    id: acc.id,
    content: (
      <BaselaneCardNew
        id={acc.id}
        variant="innerClickable"
        {...optionsCardStyles(acc.isDisabled)}
        onClick={() => handleAccountSelection(acc.option)}
      >
        <Box>
          <Box {...(acc.isDisabled && { filter: 'grayscale(1)' })}>{acc.icon}</Box>
          <Box>
            <Text {...optionsTitleStyles(acc.isDisabled)}>{acc.title}</Text>
            <Text {...optionsDescriptionStyles(acc.isDisabled)}>{acc.description}</Text>
          </Box>
        </Box>
        <Box {...optionsIconStyles}>
          <Icon16ChevronRight />
        </Box>
      </BaselaneCardNew>
    ),
  }));

  return (
    <DrawerBody p={isMax576 ? 2 : 3}>
      <Heading size="headline-md" mb={3}>
        {bankName}
      </Heading>
      <Heading as="h3" size="sm" mb={1}>
        Select a virtual account type
      </Heading>
      <BaselaneCardStack items={accountCards} />
    </DrawerBody>
  );
};

export default AccountOptions;
