import React from 'react';

import { useStatsigClient } from '@statsig/react-bindings';
import DashboardPageOld from '@core/components/Dashboard';
import DashboardPage from '@pages/Dashboard';
import { CashFlowProvider } from '@contexts/CashFlowContext';
import { FEATURE_GATES } from '@core/constants/statsigKeys';

/**
 * Component that renders old ui for dashboard if gate is false, otherwise renders the new dashboard ui.
 * @returns {JSX.Element}
 */
const DashboardFeatureGateGuard = () => {
  const { checkGate } = useStatsigClient();
  return checkGate(FEATURE_GATES.DASHBOARD_GATE) ? (
    <DashboardPage />
  ) : (
    <CashFlowProvider>
      <DashboardPageOld />
    </CashFlowProvider>
  );
};

export default DashboardFeatureGateGuard;
