import { useQuery } from '@apollo/client';
import { GET_USER_CURRENT_APY } from '@core/apollo/queries';

const useCurrentApy = () => {
  const { loading: userLoading, data: userData } = useQuery(GET_USER_CURRENT_APY, {
    fetchPolicy: 'cache-first',
  });

  const {
    user: {
      userMetadata: { currentApy },
    },
  } = userData || {
    user: {
      userMetadata: { currentApy: null },
    },
  };

  return {
    loading: userLoading,
    currentApy,
  };
};

export default useCurrentApy;
