import React from 'react';
import { Stack } from '@chakra-ui/react';
import { Icon16Delete } from '@icons/16px';
import { BaselaneButton } from '@shared/components';
import { IconEdit, IconCheckCircleOutline } from '@icons';

import useBreakPoints from '@core/hooks/useBreakPoints';

type InvoiceDetailsActionsProps = {
  showEditButton: boolean,
  setEditState: Function,
  editState: boolean,
  setTabIndex: Function,
  tabIndex: number,
  setLastTabIndex: Function,
  onMarkAsPaidOpen: Function,
  onDeleteAlertOpen: Function,
  isMarkedAsPaid: boolean,
  hasDelete: boolean,
};

const InvoiceDetailsActions = ({
  showEditButton,
  setEditState,
  editState,
  setTabIndex,
  tabIndex,
  setLastTabIndex,
  onMarkAsPaidOpen,
  onDeleteAlertOpen,
  isMarkedAsPaid,
  hasDelete,
}: InvoiceDetailsActionsProps) => {
  const { isMinXL } = useBreakPoints();

  return (
    <Stack justifyContent="space-between" borderBottom="none">
      {showEditButton ? null : (
        <Stack direction="row" paddingBottom={isMinXL ? '12px' : '0px'}>
          {!isMarkedAsPaid && (
            <BaselaneButton
              variant="outline"
              palette="neutral"
              size="md"
              leftIcon={<IconCheckCircleOutline w={16} h={16} />}
              onClick={onMarkAsPaidOpen}
              isFullWidth
            >
              Mark as Paid
            </BaselaneButton>
          )}
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size="md"
            leftIcon={<IconEdit w={16} h={16} />}
            onClick={() => {
              setTabIndex(1);
              setLastTabIndex(tabIndex);
              setEditState(!editState);
            }}
            isFullWidth
          >
            Edit Invoice
          </BaselaneButton>
          {hasDelete && (
            <BaselaneButton
              variant="outline"
              palette="danger"
              size="md"
              leftIcon={<Icon16Delete />}
              onClick={onDeleteAlertOpen}
              isFullWidth
            >
              Delete Invoice
            </BaselaneButton>
          )}
        </Stack>
      )}
    </Stack>
  );
};
export default InvoiceDetailsActions;
