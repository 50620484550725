import React from 'react';
import { Link } from '@chakra-ui/react';

export const AuthenticationError = {
  wrongpassword: 'auth/wrong-password',
  usernotfound: 'auth/user-not-found',
  invalidemail: 'auth/invalid-email',
  credential: 'auth/invalid-credential',
  toomanyrequests: 'auth/too-many-requests',
  emailalreadyinuse: 'auth/email-already-in-use',
  userdisabled: 'auth/user-disabled',
  tenant: 'baselane/tenant',
  generic: 'baselane/generic',
  nopassword: 'baselane/no-password',
  nosuchuser: 'google-sso/no-such-use',
};

export const errorMessages = {
  [AuthenticationError.wrongpassword]: 'Your credentials are incorrect. Please try again.',
  [AuthenticationError.nopassword]: 'Please enter a password.',
  [AuthenticationError.usernotfound]: 'Your credentials are incorrect. Please try again.',
  [AuthenticationError.invalidemail]: 'Please enter a valid email.',
  [AuthenticationError.toomanyrequests]:
    'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
  [AuthenticationError.emailalreadyinuse]: 'This email is already registered with Baselane.',
  [AuthenticationError.userdisabled]: 'This account has been disabled. Please contact support.',
  [AuthenticationError.generic]:
    'Something went wrong :( Please try again later or contact customer support.',
  [AuthenticationError.tenant]: (
    <>
      It looks like you are a tenant. Please &nbsp;
      <Link href="https://tenant.baselane.com/" textDecoration="underline" isExternal>
        sign in here
      </Link>
    </>
  ),
  [AuthenticationError.nosuchuser]: 'There is no user record corresponding to this email.',
};

export const emailAlreadyInUse = (errorMessage: string) => {
  if (errorMessage.startsWith('User with email') && errorMessage.includes('already exists')) {
    return true;
  }
  return false;
};

export const userDidNotExist = (errorMessage: string) => {
  if (errorMessage.startsWith('Landlord with id') && errorMessage.includes('does not exist')) {
    return true;
  }
  return false;
};

export const isMessageCardError = (errors) => {
  return (
    (errors?.email && errors?.email === errorMessages[AuthenticationError.emailalreadyinuse]) ||
    errors?.signUp
  );
};
