import React, { useEffect, useState } from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { BaselaneAlertNew } from '@shared/components';
import { Icon16ChevronRight, Icon16Info } from '@icons/16px';
import { EXTERNAL_ACCOUNTS } from '@routes';
import getPluralizedNoun from '@core/utils/getPluralizedNoun';
import { GET_BANK_EXT_CONNECTION_STATE } from '@core/components/NativeBankingPage/queries';
import useBreakPoints from '@core/hooks/useBreakPoints';

const DisconnectedAccountsAlertBanner = () => {
  const navigate = useNavigate();

  const { isMax767 } = useBreakPoints();

  const [numberOfDisconnectedAccounts, setNumberOfDisconnectedAccounts] = useState(0);

  // Banking Data
  const { data } = useQuery(GET_BANK_EXT_CONNECTION_STATE, {
    variables: {
      isExternal: true,
      isManualAccount: false,
      isConnectedAccount: true,
      isConnectedBank: true,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data) {
      const { bank: banks } = data || {};

      const disconnected =
        banks.reduce((currentTotalDisconnected, bank) => {
          const { bankAccounts } = bank || {};
          return (
            currentTotalDisconnected +
              bankAccounts.filter((account) => {
                return account?.connectionState !== 'CONNECTED';
              }).length || 0
          );
        }, 0) || 0;

      setNumberOfDisconnectedAccounts(disconnected);
    }
  }, [data]);

  return (
    numberOfDisconnectedAccounts > 0 && (
      <BaselaneAlertNew
        variant="danger"
        visual="icon"
        iconName={Icon16Info}
        actionProps={{
          actionType: 'button',
          primary: {
            label: 'View accounts',
            size: 'xs',
            onClick: () => navigate({ pathname: EXTERNAL_ACCOUNTS }),
            styles: {
              variant: 'transparent',
              palette: 'neutral',
              rightIcon: <Icon16ChevronRight />,
              pullRight: true,
            },
          },
        }}
        isVertical={isMax767}
        body={
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Text textStyle="sm-heavy" textAlign="left">
              {getPluralizedNoun(numberOfDisconnectedAccounts, 'external account')} disconnected
            </Text>
          </Stack>
        }
      />
    )
  );
};

export default DisconnectedAccountsAlertBanner;
