/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import { BaselaneCardStack } from '@shared/components';
import ReportHeader from '../../components/ReportHeader';
import equifaxImage from './assets/equifax.svg';

const Marker = ({ score, color }) => (
  <Box display="flex" flexDirection="column" id="marker" alignItems="flex-end">
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box px={1.5} py={1} flex={1} bg={color} w="56px" h="40px" borderRadius={4}>
        <Text textStyle="headline-md" color="white">
          {score}
        </Text>
      </Box>
      <Box
        width={0}
        height={0}
        borderLeft="6px solid transparent"
        borderRight="6px solid transparent"
        borderTop="6px solid"
        borderTopColor={color}
        alignSelf="center"
      />
    </Box>
  </Box>
);

const CreditScore = ({ creditScore }) => {
  const [status, setStatus] = React.useState('good');

  React.useEffect(() => {
    if (creditScore < 580) {
      setStatus('poor');
    } else if (creditScore < 670) {
      setStatus('fair');
    } else if (creditScore < 740) {
      setStatus('good');
    } else if (creditScore < 800) {
      setStatus('veryGood');
    } else {
      setStatus('excellent');
    }
  }, [creditScore]);

  // relative credit score so it's easier to place the marker correctly
  const relCredScore = creditScore - 300;

  return (
    <BaselaneCardStack
      id="credit-score"
      size="md"
      items={[
        {
          id: 'header',
          content: (
            <Box display="flex" justifyContent="space-between" width="100%">
              <ReportHeader>Credit Score</ReportHeader>
              <Image src={equifaxImage} alt="Equifax logo" />
            </Box>
          ),
        },
        {
          id: 'content',
          content: (
            <Box display="flex" flexDirection="column" width="100%" gap={1}>
              <Box display="flex" gap={0.25}>
                <Box flex={((status === 'poor' ? relCredScore : 279) * 5) / 550}>
                  {status === 'poor' ? <Marker score={creditScore} color="red.700" /> : null}
                </Box>
                <Box flex={((status === 'fair' ? relCredScore : 89) * 5) / 550}>
                  {status === 'fair' ? <Marker score={creditScore} color="yellow.600" /> : null}
                </Box>
                <Box flex={((status === 'good' ? relCredScore : 69) * 5) / 550}>
                  {status === 'good' ? <Marker score={creditScore} color="green.400" /> : null}
                </Box>
                <Box flex={((status === 'veryGood' ? relCredScore : 59) * 5) / 550}>
                  {status === 'veryGood' ? <Marker score={creditScore} color="green.700" /> : null}
                </Box>
                <Box
                  flex={
                    ((status === 'excellent' ? relCredScore : 50 + 279 - relCredScore) * 5) / 550
                  }
                >
                  {status === 'excellent' ? <Marker score={creditScore} color="green.900" /> : null}
                </Box>
              </Box>
              <Box display="flex" gap={0.25}>
                <Box h={4} flex={(279 * 5) / 550} borderLeftRadius={10} bg="red.700" />
                <Box h={4} flex={(89 * 5) / 550} bg="yellow.600" />
                <Box h={4} flex={(69 * 5) / 550} bg="green.400" />
                <Box h={4} flex={(59 * 5) / 550} bg="green.700" />
                <Box h={4} flex={(50 * 5) / 550} borderRightRadius={10} bg="green.900" />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Text textStyle="headline-lg" color="red.700">
                  300
                </Text>
                <Text textStyle="headline-lg" color="green.900">
                  850
                </Text>
              </Box>
            </Box>
          ),
        },
      ]}
    />
  );
};

export default CreditScore;
