import { gql } from '@apollo/client';

export const CREATE_OR_UPDATE_LEASE_AGREEMENT = gql`
  mutation createOrUpdateLeaseAgreement(
    $id: ID
    $propertyUnitId: ID
    $propertyId: ID
    $tenantProfileId: ID
    $tenantProfileMetadata: JSON
    $leaseType: LeaseType
    $startDate: String
    $endDate: String
    $amount: Float
    $rentCollectionStartDate: String
    $archived: Boolean
    $isAlreadySigned: Boolean
    $documentName: String
    $hostedPageId: String
    $inputType: LeaseAgreementInputType!
  ) {
    createOrUpdateLeaseAgreement(
      input: {
        id: $id
        propertyUnitId: $propertyUnitId
        propertyId: $propertyId
        tenantProfileId: $tenantProfileId
        tenantProfileMetadata: $tenantProfileMetadata
        leaseType: $leaseType
        startDate: $startDate
        endDate: $endDate
        amount: $amount
        rentCollectionStartDate: $rentCollectionStartDate
        archived: $archived
        isAlreadySigned: $isAlreadySigned
        documentName: $documentName
        hostedPageId: $hostedPageId
        inputType: $inputType
      }
    ) {
      id
      propertyUnitId
      tenantProfileId
      tenantProfileMetadata
      userId
      leaseType
      startDate
      endDate
      rentCollectionStartDate
      state
      completedTags
      archived
      amount
      serviceToken
      binderId
      interviewId
      ownerUpId
      templateId
      propertyId
      documentName
      lastEditedAt
      version
      propertyName
      propertyUnitName
      isArchived
      paymentStatus
    }
  }
`;

const LEASE_FIELDS = gql`
  fragment LeaseFields on LeaseAgreement {
    id
    propertyUnitId
    tenantProfileId
    tenantProfileMetadata
    userId
    leaseType
    startDate
    endDate
    rentCollectionStartDate
    lastEditedAt
    state
    completedTags
    archived
    amount
    propertyUnit {
      id
      name
      propertyName
      description
      status
      rentStatus
      address
      isMultiUnitProperty
    }
    paymentStatus
    documentName
    propertyName
    propertyUnitName
    isArchived
    leases {
      id
      startDate
      endDate
      state
    }
  }
`;

export const GET_LEASE_AGREEMENTS = gql`
  query getLeaseAgreements {
    leaseAgreements {
      ...LeaseFields
    }
  }
  ${LEASE_FIELDS}
`;

export const GET_LEASE_AGREEMENT = gql`
  query getLeaseAgreement($id: ID!) {
    leaseAgreement(id: $id) {
      userId
      leaseType
      startDate
      endDate
      state
      completedTags
      archived
      rentCollectionStartDate
      amount
      tenantProfileId
      propertyUnitId
      propertyId
      paymentStatus
      tenantProfileMetadata
      id
      serviceToken
      binderId
      interviewId
      ownerUpId
      documentName
      lastEditedAt
      isMigrated
      propertyUnit {
        propertyName
        name
      }
      leases {
        id
        state
        tenantProfile {
          firstName
          lastName
        }
        startDate
        endDate
      }
      paymentStatus
      manuallyUploaded
    }
  }
`;

export const DELETE_LEASE_AGREEMENT = gql`
  mutation deleteLeaseAgreement($id: ID!) {
    deleteLeaseAgreement(id: $id)
  }
`;

export const LINK_LEASE_AGREEMENT_TO_LEASE = gql`
  mutation linkLeaseAgreementToLease($input: LinkLeaseAgreementsWithLeasesInput!) {
    linkLeaseAgreementToLease(input: $input) {
      linked {
        leaseAgreementId
        leaseId
      }
      unLinked {
        leaseAgreementId
        leaseId
      }
      errors
    }
  }
`;

export const CHARGEBEE_URL = gql`
  query GenerateChargeCheckoutUrl($checkoutUrlInput: CheckoutChargeUrlInput!) {
    generateChargeCheckoutUrl(checkoutUrlInput: $checkoutUrlInput)
  }
`;

export const SIGN_LEASE_AGREEMENT = gql`
  mutation signLeaseAgreement($id: ID!) {
    signLeaseAgreement(id: $id) {
      userId
      leaseType
      startDate
      endDate
      state
      completedTags
      archived
      rentCollectionStartDate
      amount
      tenantProfileId
      propertyUnitId
      tenantProfileMetadata
      id
      serviceToken
      binderId
    }
  }
`;
