import React from 'react';
import { Box, Heading, HStack, Text } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon16ChevronRight, Icon16Check, Icon16Warning } from '@icons/16px';
import { BaselaneChip } from '@shared/components/';
import {
  TENANT_SCREENING,
  TENANT_SCREENING_APPLICANT,
  TENANT_SCREENING_APPLICATION,
} from '@core/constants/routes';

type SummaryDetail = {
  text?: string,
  size: 'small' | 'large',
  isHeading?: boolean,
  chipText?: string,
  value?: string,
};

type SummaryCardProps = {
  id: string,
  title: string,
  details?: SummaryDetail[],
  route?: string,
  applicationId?: string,
  applicantId?: string,
};

const SummaryCard = ({
  id,
  title,
  details = [],
  route = '',
  applicationId = '',
  applicantId = '',
}: SummaryCardProps) => {
  const navigate = useNavigate();
  const { screeningId = '' } = useParams();

  const handleTitleClick = (url: string) => {
    if (!screeningId || !applicationId || !applicantId) {
      console.warn('Missing params for navigation:', { screeningId, applicationId, applicantId });
      return;
    }

    const newPath = `/${TENANT_SCREENING}/${screeningId}/${TENANT_SCREENING_APPLICATION}/${applicationId}/${TENANT_SCREENING_APPLICANT}/${applicantId}/${url}`;
    navigate(newPath);
  };

  const getVariant = (chipText = '') => {
    const variantMap: Record<string, string> = {
      passed: 'simple-success',
      verified: 'simple-success',
      match: 'simple-success',
      finished_with_warnings: 'simple-warning',
      failed: 'simple-danger',
    };
    return variantMap[chipText.toLowerCase()] || 'simple-danger';
  };

  const getLabel = (chipText = '') => {
    const labelMap: Record<string, string> = {
      passed: 'Passed',
      verified: 'Verified',
      identityVerificationStatus: 'Passed',
      finished_with_warnings: 'Warning',
      failed: 'Failed',
    };
    return labelMap[chipText.toLowerCase()] || 'Failed';
  };

  const getLeftIcon = (chipText = '') => {
    const iconMap: Record<string, any> = {
      passed: Icon16Check,
      verified: Icon16Check,
      finished_with_warnings: Icon16Warning,
    };
    return iconMap[chipText.toLowerCase()] || null;
  };

  return (
    <Box w="100%">
      <HStack gap="0.5">
        <Heading
          size="sm"
          mb="0.5"
          color="brand.neutral.600"
          fontWeight="500"
          onClick={route ? () => handleTitleClick(route) : undefined}
          style={{ cursor: route ? 'pointer' : 'default' }}
        >
          {title}
        </Heading>
        {route && (
          <Box marginBottom="4px" color="brand.neutral.600">
            <Icon16ChevronRight />
          </Box>
        )}
      </HStack>
      {details.length > 0 ? (
        details.map((item, index) => (
          <Box key={uuidv4()}>
            {item.chipText ? (
              <BaselaneChip
                leftIcon={getLeftIcon(item.chipText)}
                variant={getVariant(item.chipText)}
                size="lg"
                label={getLabel(item.chipText)}
              />
            ) : (
              <HStack>
                {item.text && (
                  <Text
                    textStyle={item.isHeading ? 'data-xl' : 'sm'}
                    my="2px"
                    color={
                      item.text === '0' || item.text === '--' || item.text === 'Expired'
                        ? 'brand.neutral.500'
                        : 'brand.neutral.600'
                    }
                  >
                    {item.text}
                  </Text>
                )}
                <Text
                  textStyle={item.isHeading ? 'data-xl' : 'sm'}
                  my="2px"
                  color={
                    item.value === '0' || item.value === '--' || item.value === 'Expired'
                      ? 'brand.neutral.500'
                      : 'brand.neutral.900'
                  }
                >
                  {item.value ?? ''}
                </Text>
              </HStack>
            )}
          </Box>
        ))
      ) : (
        <Text color="brand.neutral.500">No details available</Text>
      )}
    </Box>
  );
};

export default SummaryCard;
