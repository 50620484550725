// @flow
import React, { useEffect } from 'react';
import { ChakraProvider, Text, Stack, useDisclosure, Box } from '@chakra-ui/react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { Icon12Info } from '@icons/12px';
import { Icon16Edit, Icon16Clear } from '@icons/16px';
import habitatTheme from '@core/themeHabitat';
import onDrawerClose from '@core/utils/onDrawerClose';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';
import { useCurrentWorkspace } from '@shared/hooks';
import { USER_WORKSPACE } from '@routes';
import {
  BaselaneSummaryCard,
  BaselaneSummaryCardDefaultHeader,
  BaselaneToolpop,
  BaselaneButtonGroup,
  BaselaneButton,
  BaselaneLink,
} from '@shared/components';
import RevokeInviteAlert from './components/RevokeInviteAlert';
import RemoveMemberAlert from './components/RemoveMemberAlert';
import MemberInviteExpired from './MemberInviteExpired';
import MemberInvitePending from './MemberInvitePending';

type MemberDetailsDrawerProps = { from: String };

function MemberDetailsDrawer({ from }: MemberDetailsDrawerProps): any {
  const { memberId } = useParams();
  const { collaborators } = useCurrentWorkspace();

  const member =
    memberId !== 'null'
      ? collaborators?.find((m) => m.id === Number(memberId))
      : collaborators?.find((m) => m.isOwner === true);

  const isOwner = member?.isOwner;
  const {
    isOpen: isRevokeAccessAlertOpen,
    onOpen: onRevokeAccessAlertOpen,
    onClose: onRevokeAccessAlertClose,
  } = useDisclosure();
  const {
    isOpen: isRemoveMemberAlertOpen,
    onOpen: onRemoveMemberAlertOpen,
    onClose: onRemoveMemberAlertClose,
  } = useDisclosure();
  const { isMax576 } = useBreakPoints();
  const navigate = useNavigate();
  const handleEditAccess = () => {
    navigate('edit');
  };
  const hasDisplayName = member?.firstName && member?.lastName;

  const displayName = hasDisplayName
    ? `${member.firstName} ${member.lastName}`.trim()
    : member?.lastName || member?.email || '';

  const tooltipText1 =
    'View accounts and balances, and tag transactions, but cannot move money, access card details, or make account changes.';
  const tooltipText2 =
    'Move money, manage cards, and open new virtual accounts, but cannot open accounts for a new entity.';
  const accessDescription = () => {
    return (
      <Stack direction="row" alignItems="center">
        <Text textStyle="sm">
          {member?.authorizedForBanking ? 'Standard access' : 'Limited Access'}
        </Text>
        <BaselaneToolpop trigger={<Icon12Info />} placement="top">
          <Box textAlign="left">
            <Text textStyle="sm">{member?.authorizedForBanking ? tooltipText2 : tooltipText1}</Text>
            <BaselaneLink
              target="_blank"
              size="sm"
              variant="neutral"
              href="https://support.baselane.com/hc/en-us/articles/29873891217307-How-to-Manage-Multi-User-Access-in-Your-Baselane-Workspace"
            >
              Learn more
            </BaselaneLink>
          </Box>
        </BaselaneToolpop>
      </Stack>
    );
  };

  useEffect(() => {
    const isMember = collaborators?.find((m) => m.id === memberId || m.id === null); // Null id is for workspace owners due to BE limitation

    if (!isMember) {
      navigate(USER_WORKSPACE);
    }
  }, []);

  return (
    <ChakraProvider theme={habitatTheme}>
      <BaselaneDrawer
        isOpen
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        title="Member details"
        closeEvent={() => onDrawerClose(navigate, from)}
        onOverlayClick={() => onDrawerClose(navigate, from)}
        closeOnOverlayClick={false}
        newDesignDrawer
      >
        <Stack gap={3}>
          {member?.status === 'INVITE_EXPIRED' && (
            <MemberInviteExpired memberId={Number(member?.id)} />
          )}
          {member?.status === 'INVITE_SENT' && (
            <MemberInvitePending memberId={Number(member?.id)} />
          )}
          <BaselaneSummaryCard
            isCustom
            header={
              <BaselaneSummaryCardDefaultHeader
                title={displayName}
                subtitle={hasDisplayName ? member?.email : ''}
              />
            }
            list={[
              {
                id: 'member-detail-id',
                items: [
                  {
                    id: 'role-id',
                    label: 'Role',
                    value: isOwner ? 'Owner' : 'Collaborator',
                  },
                  !isOwner
                    ? {
                        id: 'banking-access-id',
                        label: 'Banking access',
                        value: accessDescription(),
                      }
                    : {},
                ],
              },
            ]}
          />
          {!isOwner && member?.status === 'ACCEPTED' && (
            <BaselaneButtonGroup size="md">
              <BaselaneButton
                variant="outline"
                palette="primary"
                label="Edit access"
                leftIcon={<Icon16Edit />}
                size="md"
                styles={{ w: '50%' }}
                onClick={handleEditAccess}
              >
                Edit access
              </BaselaneButton>
              <BaselaneButton
                variant="outline"
                palette="danger"
                leftIcon={<Icon16Clear />}
                size="md"
                styles={{ w: '50%' }}
                onClick={onRemoveMemberAlertOpen}
              >
                Remove member
              </BaselaneButton>
            </BaselaneButtonGroup>
          )}
          {(member?.status === 'INVITE_EXPIRED' || member?.status === 'INVITE_SENT') && (
            <BaselaneButton
              variant="outline"
              palette="danger"
              leftIcon={<Icon16Clear />}
              size="md"
              onClick={onRevokeAccessAlertOpen}
            >
              Revoke invite
            </BaselaneButton>
          )}
        </Stack>
      </BaselaneDrawer>
      <Outlet />
      <RevokeInviteAlert
        isAlertOpen={isRevokeAccessAlertOpen}
        onAlertClose={onRevokeAccessAlertClose}
        memberId={member?.id}
      />
      <RemoveMemberAlert
        isAlertOpen={isRemoveMemberAlertOpen}
        onAlertClose={onRemoveMemberAlertClose}
        memberId={member?.id}
      />
    </ChakraProvider>
  );
}

export default MemberDetailsDrawer;
