import { useEffect, useState } from 'react';
import useCurrentWorkspace from '@shared/hooks/useCurrentWorkspace';
import { STATE_ENUM } from '@pages/Dashboard/SetupGuide/helpers/trackers.helpers';

const useGuideComplete = () => {
  const { isLoadingCurrentWorkspace, workspaceMetadata } = useCurrentWorkspace();
  const { metadata, bankingTracker, rentCollectionTracker, bookkeepingTracker } =
    workspaceMetadata ?? {};

  const [isLoading, setLoading] = useState(true);
  const [isGuideCompleted, setGuideCompleted] = useState(false);

  const checkStepsCompleted = (tracker) => {
    let completed = true;
    tracker?.forEach((step) => {
      if (STATE_ENUM[step.state] !== 'completed') {
        completed = false;
      }
    });
    return completed && tracker?.length;
  };

  useEffect(() => {
    const bankComplete = checkStepsCompleted(bankingTracker);
    const rentComplete = checkStepsCompleted(rentCollectionTracker);
    const bookkeepingComplete = checkStepsCompleted(bookkeepingTracker);
    setGuideCompleted(bankComplete && rentComplete && bookkeepingComplete);
    setLoading(isLoadingCurrentWorkspace);
  }, [isLoadingCurrentWorkspace]);

  return {
    isGuideLoading: isLoading,
    isGuideComplete: isGuideCompleted,
    isGuideDismissed: metadata?.hideSetupGuideSection === true,
  };
};

export default useGuideComplete;
