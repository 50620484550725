import React, { useState, useEffect, useContext } from 'react';
import { Checkbox } from '@chakra-ui/react';
import { useStatsigClient } from '@statsig/react-bindings';
import {
  BaselaneResponsiveTableHeader,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveSortableTableHeading,
  BaselaneResponsiveTableSpacer,
} from '@shared/components';
import type { SortsAndFilters } from '@shared/types/Sort';
import SortFieldEnum from '@shared/components/SortTableHeader/models/SortFieldEnum';
import BaselaneResponsiveTableContext from '@shared/components/BaselaneResponsiveTable/BaselaneResponsiveTableContext';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import useBreakPoints from '@core/hooks/useBreakPoints';
import type { Transaction } from './types';

type TransactionsListHeaderProps = {
  filters: SortsAndFilters,
  onSort: Function,
  onSelectAll: Function,
  actionDeselectAll: boolean,
  setActionDeselectAll: Function,
  selectedTransactions: Array<Transaction>,
  totalVisible: number,
  isTableOnly: boolean,
};

const TransactionsListHeader = ({
  filters,
  onSort,
  onSelectAll,
  actionDeselectAll,
  setActionDeselectAll,
  selectedTransactions,
  totalVisible,
  isTableOnly = false,
}: TransactionsListHeaderProps) => {
  const { isMin1150 } = useBreakPoints();
  const { checkGate } = useStatsigClient();

  const [allSelected, setAllSelected] = useState(false);
  const isIndeterminate = selectedTransactions.length > 0 && !allSelected;
  const checkboxComponent = () => (
    <Checkbox
      {...{
        className: 'primary-500',
        isChecked: allSelected,
        isIndeterminate,
        onChange: (e) => handleSelectAllVisible(e),
      }}
    />
  );

  const { columns: headerItems } = useContext(BaselaneResponsiveTableContext);

  const tableHeaderItems = [
    { key: 'checkbox', label: '', component: checkboxComponent() },
    {
      key: 'date',
      label: 'Date',
      field: SortFieldEnum.Date,
    },
    {
      key: 'account',
      label: 'Account',
      field: SortFieldEnum.Account,
    },
    {
      key: 'description',
      label: 'Description',
      field: SortFieldEnum.Merchant,
    },
    {
      key: 'amount',
      label: 'Amount',
      field: SortFieldEnum.Amount,
    },
    {
      key: 'spacer',
    },
    {
      key: 'category',
      label: isMin1150 ? 'Category' : 'Property & Category',
      field: SortFieldEnum.Category, // how to sort  here?
    },
    {
      key: 'property',
      label: 'Property',
      field: SortFieldEnum.Property,
    },
    {
      key: 'note',
      label: 'Note',
      field: SortFieldEnum.Notes,
    },
    {
      key: 'amountTablet',
      label: 'Amount',
      field: SortFieldEnum.Amount,
    },
  ];
  if (checkGate(FEATURE_GATES.AUTO_TAGGING_GATE)) {
    tableHeaderItems.push({
      key: 'reviewed',
      label: 'Reviewed',
    });
  }

  const handleSelectAllVisible = (e) => {
    const isChecked = e.target.checked;
    if (isChecked && !isIndeterminate) {
      setActionDeselectAll(false);
    } else {
      setActionDeselectAll(true);
    }
    onSelectAll(isChecked);
    setAllSelected(isChecked);
  };

  useEffect(() => {
    if (actionDeselectAll) {
      setAllSelected(false);
      onSelectAll(false);
    }
  }, [actionDeselectAll]);

  useEffect(() => {
    if (selectedTransactions.length !== totalVisible) {
      setAllSelected(false);
    }
    if (selectedTransactions.length === totalVisible && selectedTransactions.length !== 0) {
      setAllSelected(true);
    }
  }, [selectedTransactions]);

  return (
    <BaselaneResponsiveTableHeader
      items={tableHeaderItems}
      renderItem={(item, index) => {
        switch (item?.key) {
          case 'spacer':
            return <BaselaneResponsiveTableSpacer key={item.key} index={index} />;
          default:
            return !headerItems?.[index]?.isSortable ? (
              <BaselaneResponsiveTableHeading key={item.key} index={index} title={item.label}>
                {item?.component}
              </BaselaneResponsiveTableHeading>
            ) : (
              <BaselaneResponsiveSortableTableHeading
                key={item.key}
                index={index}
                title={item.label}
                field={item.field}
                filters={filters}
                onSort={onSort}
              />
            );
        }
      }}
      chevronCellStyles={checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) ? { display: 'none' } : {}}
    />
  );
};

export default TransactionsListHeader;
