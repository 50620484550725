/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import {
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Spacer,
  Box,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import MaskedInput from 'react-text-mask';
import { Icon16PlusCircle } from '@icons/16px';
import { BaselaneButton, BaselaneButtonGroup, BaselaneSingleDatePicker } from '@shared/components';
import { currencyMask } from '@core/utils/masks';
import stripCurrency from '@core/utils/stripCurrency';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { formatDate } from '@core/utils/formatDate';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import {
  additionalFeesValidation,
  feesFormInitialValues,
} from '../../formHelpers/depositAndFeesForm.helper';
import DisplayFee from './DisplayFee';
import ToggleWrapper from '../../components/ToggleWrapper';
import { feeBox, fieldContainer } from '../../styles/shared.styles';

type Field = {
  label: string,
  type: string,
};
type Fields = {
  [key: string]: Field,
};

type AdditionalFeesProps = {
  sectionInfo: Array<Fields>,
  sectionId: string,
  handleChange: Function,
  setFieldValue: Function,
  setFormVariables: Function,
  isAdditionalFeesDb: Array<Object>,
  values: Object,
  additionalFeeFormRef: any,
  unifiedRC: boolean,
};

function AdditionalFeesSection({
  sectionInfo = [],
  sectionId = '',
  handleChange = () => {},
  setFieldValue = () => {},
  setFormVariables = () => {},
  isAdditionalFeesDb = [],
  values = {},
  additionalFeeFormRef = null,
  unifiedRC = true,
}: AdditionalFeesProps) {
  const { isMin768 } = useBreakPoints();
  const years = { start: 2022, end: 2043 };
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCalendarClose = ({ date: dueDate, isFromApplyButton }) => {
    if (isMin768 || (!isMin768 && isFromApplyButton)) {
      additionalFeeFormRef.current?.setFieldValue('dueDate', dueDate);
    }
  };

  const fixedDbFees = isAdditionalFeesDb.filter((f) => f.frequency === 'FIXED');
  const additionalFixedFeesFields = values.additionalFeesFields.filter(
    (f) => f.frequency === 'FIXED'
  );

  const undeletedFees = isAdditionalFeesDb.filter((f) => f.frequency === 'FIXED' && !f.delete);
  const feesExist = undeletedFees?.length > 0;
  const [showAdditionalFees, setShowAdditionalFees] = useState(feesExist);
  const [showAddFeeForm, setShowAddFeeForm] = useState(false);

  const handleSetShowAdditionalFees = (show) => {
    setShowAdditionalFees(show);
    setShowAddFeeForm(!feesExist && show);
  };

  const handleFormSubmit = (formValues, actions) => {
    const payments = {
      name: 'FEE',
      description: formValues.description,
      amount: stripCurrency(formValues.amount),
      frequency: 'FIXED',
      enabled: true,
    };

    const dueDate = moment(formValues.dueDate).format('YYYY-MM-DD');
    setFormVariables({
      payments: [{ ...payments, dueDate }],
    });

    actions.setSubmitting(false);
    actions.resetForm();
    setShowAddFeeForm(false);
  };

  const handleFeeDelete = (fee) => {
    const deletedPayment = { ...fee, delete: true };
    if (additionalFixedFeesFields.length === 1 && fixedDbFees.length === 1) {
      setShowAddFeeForm(true);
    } else {
      setShowAddFeeForm(false);
    }
    setFormVariables({ payments: [deletedPayment] });
  };

  useEffect(() => {
    if (feesExist !== showAdditionalFees) {
      setShowAdditionalFees(feesExist);
    }
  }, [feesExist]);

  useEffect(() => {
    if (fixedDbFees.length !== additionalFixedFeesFields.length)
      setFieldValue('additionalFeesFields', isAdditionalFeesDb);
  });

  return (
    <ToggleWrapper key="additional_fees_container" showBottomBorder={false} isNested>
      {(!showAdditionalFees || !showAddFeeForm) && undeletedFees.length < 1 && (
        <ToggleWrapper.Toggle
          direction="row"
          key="additional_fees_switch"
          {...{
            title: sectionInfo.title.label,
            htmlFor: sectionId,
            labelStyles: {},
            switchProps: {
              id: sectionId,
              isDisabled: feesExist,
              onClick: (e) => {
                handleSetShowAdditionalFees(!showAdditionalFees);
                handleChange(e);
              },
            },
            hideToggle: true,
          }}
        />
      )}
      {showAdditionalFees && (
        <ToggleWrapper.Content styles={{ spacing: '16px', mt: '0', mb: '16px' }}>
          <DisplayFee {...{ additionalFeesFields: fixedDbFees, handleFeeDelete, unifiedRC }} />

          {showAddFeeForm ? (
            <Formik
              innerRef={additionalFeeFormRef}
              validate={(formValues) => additionalFeesValidation(formValues)}
              initialValues={feesFormInitialValues}
              onSubmit={handleFormSubmit}
              validateOnBlur
            >
              {({
                values: formValues,
                handleSubmit,
                isSubmitting,
                handleChange: handleFeeChange,
                handleBlur: handleFeeBlur,
                errors,
                touched,
              }) => {
                const hasError =
                  (errors.dueDate && touched.dueDate) || (errors.dueDate && touched.dueDate);

                return (
                  <Flex {...feeBox}>
                    <Stack
                      direction={!isMobile ? 'row' : 'column'}
                      spacing="16px"
                      id="additional-fees-box"
                    >
                      {/* Fee Name */}
                      <FormControl
                        {...fieldContainer}
                        mb="10px"
                        isInvalid={errors.description && touched.description}
                      >
                        <FormLabel {...formLabelStyles.xs} htmlFor={sectionInfo.description.label}>
                          {sectionInfo.description.label}
                        </FormLabel>
                        <Input
                          {...formInputStyles}
                          {...sectionInfo.description.props}
                          id="description"
                          name="description"
                          value={formValues.description}
                          maxLength="32"
                          type="text"
                          onChange={handleFeeChange}
                          onBlur={handleFeeBlur}
                          bg="brand.neutral.white"
                        />
                        <FormErrorMessage {...formErrorStyles}>
                          {errors.description}
                        </FormErrorMessage>
                      </FormControl>

                      {/* Fee Amount */}
                      <FormControl
                        {...fieldContainer}
                        isInvalid={errors.amount && touched.amount}
                        mt={isMobile && '0px !important'}
                        mb={isMobile && '16px !important'}
                      >
                        <FormLabel {...formLabelStyles.xs} htmlFor={sectionInfo.amount.label}>
                          {sectionInfo.amount.label}
                        </FormLabel>
                        <Box>
                          <Input
                            {...formInputStyles}
                            {...sectionInfo.amount.props}
                            id="amount"
                            name="amount"
                            value={formValues.amount}
                            as={MaskedInput}
                            mask={currencyMask()}
                            onChange={handleFeeChange}
                            onBlur={handleFeeBlur}
                            placeholder="$"
                            inputMode="decimal"
                            bg="brand.neutral.white"
                          />
                          <FormErrorMessage {...formErrorStyles}>{errors.amount}</FormErrorMessage>
                        </Box>
                      </FormControl>
                    </Stack>
                    <Box>
                      <Stack spacing="16px">
                        {/* Fee Due Date */}
                        <FormControl
                          {...fieldContainer}
                          mb="8px"
                          w={isMobile ? '100%' : 'calc(50% - 8px)'}
                          isInvalid={errors.dueDate && touched.dueDate}
                        >
                          <FormLabel {...formLabelStyles.xs} htmlFor={sectionInfo.dueDate.label}>
                            {sectionInfo.dueDate.label}
                          </FormLabel>
                          <Spacer />
                          <Box bg="brand.neutral.white">
                            <BaselaneSingleDatePicker
                              {...{
                                ...sectionInfo.dueDate.props,
                                date: formValues.dueDate,
                                value: formValues.dueDate
                                  ? `${formatDate(formValues.dueDate, 'MMM DD, YYYY')}`
                                  : '',
                                dateFormat: 'MMM dd, yyyy',
                                handleCalendarClose,
                                size: 'lg',
                                minDate: moment().toDate(),
                                years,
                                isOpen,
                                onOpen,
                                onClose,
                                hideInputIcon: true,
                                fixedHeight: true,
                                placement: 'bottom-start',
                                showInModal: !isMin768,
                                className: `form-datepicker ${hasError ? 'has-error' : ''}`,
                              }}
                            />
                          </Box>
                          <FormErrorMessage {...formErrorStyles}>{errors.dueDate}</FormErrorMessage>
                        </FormControl>
                      </Stack>
                    </Box>
                    <BaselaneButtonGroup size="sm">
                      <BaselaneButton
                        variant="filled"
                        palette="primary"
                        size="sm"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        Save Fee
                      </BaselaneButton>
                      <BaselaneButton
                        variant="transparent"
                        palette="danger"
                        size="sm"
                        onMouseDown={() => {
                          setShowAddFeeForm(false);
                          if (!feesExist) {
                            handleSetShowAdditionalFees(false);
                          }
                        }}
                        id="cancel_fee_button"
                      >
                        Cancel
                      </BaselaneButton>
                    </BaselaneButtonGroup>
                  </Flex>
                );
              }}
            </Formik>
          ) : (
            <Box m="0 !important">
              <BaselaneButton
                variant="transparent"
                palette="primary"
                pullLeft
                size="md"
                onClick={() => {
                  setShowAddFeeForm(true);
                }}
                leftIcon={<Icon16PlusCircle />}
              >
                Add Another Fee
              </BaselaneButton>
            </Box>
          )}
        </ToggleWrapper.Content>
      )}
    </ToggleWrapper>
  );
}

export default AdditionalFeesSection;
