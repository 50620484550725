import React from 'react';
import { Tab } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import {
  tabsMenuListItemContainerStyle,
  tabsMenuListItemContainerStyleColors,
} from '../../styles/tabs.styles';
import { isOptionDisabled } from '../helpers/tab.helpers';
import type { FlowPanelProps } from '../../types/FlowTabPanelProps';

type TabComponentProps = {
  children: any,
  menuOptions: Array<FlowPanelProps>,
  option: Object,
  index: Number,
  unitState?: string,
  showDisabledStyles?: Boolean,
  isValidEnabled?: Boolean,
};

function TabComponent({
  children,
  menuOptions,
  option,
  index,
  unitState = '',
  showDisabledStyles = null,
  isValidEnabled = null,
}: TabComponentProps): any {
  const isDisabledParam =
    showDisabledStyles ?? isOptionDisabled({ option, index, unitState, menuOptions });
  const isValidEnabledParam =
    isValidEnabled ??
    (option?.isValid && !isOptionDisabled({ option, index, unitState, menuOptions }));

  return (
    <Tab
      {...tabsMenuListItemContainerStyle(isMobile)}
      {...tabsMenuListItemContainerStyleColors(isDisabledParam, isValidEnabledParam, isMobile)}
      key={option?.id}
      isDisabled={isDisabledParam}
    >
      {children}
    </Tab>
  );
}

export default TabComponent;
