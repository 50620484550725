import React, { useEffect, useState, useRef } from 'react';
import { Box, Flex, Heading, Stack, Text, useMediaQuery } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStatsigClient } from '@statsig/react-bindings';
import {
  BaselaneHeaderCard,
  BaselaneButton,
  BaselaneEmptyStateCard,
  BaselaneOccupationStatus,
  InsuranceBanner,
} from '@shared/components';
import { Icon16ChevronRight } from '@icons/16px';
import { MAINTENANCE_REQUEST } from '@routes';

import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import PropertyHeader from './PropertyHeader';
import PropertyFinancialDetails from './PropertyFinancialDetails';
import PropertySummary from './PropertySummary';
import { mobilePropertyTypeOptions } from './helpers/detail.helper';
import {
  propertyCardContentStyles,
  propertyCardHeaderTitleStyles,
  emptyStateCardStyles,
  emptyStateCardHeaderStyles,
  emptyStateCardButtonStyles,
  propertyCardContainerStyles,
  propertyCardCustomHeaderStyles,
  propertyCardHeaderStyles,
} from './styles/responsive.style';
import PropertyDetails from './PropertyDetails';
import PropertySetUp from './PropertySetUp';

function PropertyList({
  hasProperties,
  sortedProperties,
  numProperties,
  numUnits,
  numVacantUnits,
}: {
  hasProperties: Boolean,
  sortedProperties: Object,
  numProperties: Number,
  numUnits: Number,
  numVacantUnits: Number,
}): any {
  const { checkGate } = useStatsigClient();

  const navigate = useNavigate();

  const propertyStepMenuRef = useRef();

  const { state } = useLocation();
  const startNewPropertyFlow = state?.startNewPropertyFlow;

  const [isDesktop] = useMediaQuery('(min-width: 899px)', { ssr: false });
  const [property, setProperty] = useState(null);
  const [showFinancialDetails, setShowFinancialDetails] = useState(false);

  const setFinancialDetailsVisible = () => {
    setShowFinancialDetails(true);
  };
  const selectProperty = (chosenProperty) => {
    setProperty(chosenProperty);
  };
  const deselectProperty = () => {
    setProperty(null);
  };

  const propertyCardHeaderTitle = (title) => (
    <Text {...propertyCardHeaderTitleStyles(isDesktop)}>{title}</Text>
  );

  const propertyCardContent = (item) => (
    <Box pb="16px" color="brand.neutral.600">
      <Flex direction="column">
        <Box>
          {item?.address?.address}, {item?.address?.unit ? `${item?.address?.unit}, ` : ''}
        </Box>
        <Box>
          {item?.address?.city}, {item?.address?.state} {item?.address?.zipCode}
        </Box>
      </Flex>
      <Flex direction="row" h="40px" pt="16px">
        <Box m="2px 8px 0 0">{mobilePropertyTypeOptions(item?.type || 'Other')}</Box>
        <Box fontWeight="medium">{item?.type}</Box>
        <BaselaneOccupationStatus property={item} />
      </Flex>
    </Box>
  );

  const propertyCardHeaderLink = (propertyInfo) => (
    <Flex direction="row">
      <BaselaneButton
        variant="transparent"
        palette="primary"
        onClick={() => {
          selectProperty(propertyInfo);
        }}
        rightIcon={<Icon16ChevronRight />}
      >
        Details
      </BaselaneButton>
    </Flex>
  );

  const onOpenStepMenu = () => {
    propertyStepMenuRef?.current?.open();
  };

  useEffect(() => {
    // catch all for property deletes
    if (property && !sortedProperties.some((item) => item.id === property?.id)) {
      setProperty(null);
    }
  }, [sortedProperties, property]);

  useEffect(() => {
    // starts new property flow, when coming from
    // rent collection "Start'" with no properties
    if (startNewPropertyFlow) onOpenStepMenu();
  }, [startNewPropertyFlow]);

  return !property ? (
    <>
      {/* The header */}
      <PropertyHeader
        title="Properties"
        hasProperties={hasProperties}
        onOpenStepMenu={onOpenStepMenu}
      />

      <Box>
        {/* Insurance Banner */}
        <Box px={2}>
          <InsuranceBanner isNarrowOnly={false} />
        </Box>
        {hasProperties && (
          <>
            <PropertySummary
              numProperties={numProperties}
              numUnits={numUnits}
              numVacantUnits={numVacantUnits}
            />

            <Stack px={2} pb={3} spacing={3}>
              <Stack direction="row" spacing={2}>
                <BaselaneButton
                  palette="primary"
                  variant="filled"
                  onClick={onOpenStepMenu}
                  styles={{ flexGrow: 1 }}
                >
                  Add Property
                </BaselaneButton>
                {checkGate(FEATURE_GATES.LATCHEL_GATE) && (
                  <BaselaneButton
                    palette="neutral"
                    variant="outline"
                    onClick={() => {
                      sendSegmentEvent('maintenance_click_button');
                      navigate({ pathname: MAINTENANCE_REQUEST });
                    }}
                  >
                    Book a repair
                  </BaselaneButton>
                )}
              </Stack>
            </Stack>
            <Box>
              <Heading p={2} size="headline-lg">
                List of Properties
              </Heading>
              {sortedProperties.map((item) => (
                <BaselaneHeaderCard
                  containerStyles={propertyCardContainerStyles}
                  customHeaderStyles={propertyCardCustomHeaderStyles(isDesktop)}
                  contentStyles={propertyCardContentStyles(isDesktop)}
                  headerLeft={propertyCardHeaderTitle(item.name)}
                  headerRight={propertyCardHeaderLink(item)}
                  headerStyles={propertyCardHeaderStyles}
                  key={item.id}
                >
                  {propertyCardContent(item)}
                </BaselaneHeaderCard>
              ))}
            </Box>
          </>
        )}
        {!hasProperties && (
          <BaselaneEmptyStateCard
            title="Add Your First Property"
            styles={emptyStateCardStyles}
            headerStyles={emptyStateCardHeaderStyles}
          >
            <BaselaneEmptyStateCard.Paragraph style={{ textAlign: 'left' }}>
              Adding a property is essential to organizing your property finances, collecting rent,
              enabling automated property metrics, and finding savings for your property.
            </BaselaneEmptyStateCard.Paragraph>
            <BaselaneButton
              {...emptyStateCardButtonStyles}
              palette="primary"
              variant="filled"
              onClick={onOpenStepMenu}
            >
              Set Up Your First Property
            </BaselaneButton>
          </BaselaneEmptyStateCard>
        )}
        <PropertySetUp
          sortedProperties={sortedProperties}
          numProperties={numProperties}
          numUnits={numUnits}
          propertyStepMenuRef={propertyStepMenuRef}
        />
      </Box>
    </>
  ) : (
    <>
      {!showFinancialDetails && (
        <PropertyDetails
          property={property}
          deselectProperty={deselectProperty}
          setFinancialDetailsVisible={setFinancialDetailsVisible}
        />
      )}
      {showFinancialDetails && (
        <PropertyFinancialDetails
          property={property}
          hasProperties={hasProperties}
          setProperty={setProperty}
        />
      )}
    </>
  );
}

export default PropertyList;
