import { USER_PROFILE_UPDATE_ACCOUNTS } from '@core/constants/routes';

export const addAccountManuallyFormValidation = (values, bankInfoAPIError) => {
  // remove leading and trailing spaces from each value
  const trimmedValues = Object.entries(values)
    .map((field) => {
      const fieldArr = field;
      fieldArr[1] = fieldArr[1].trim();
      return fieldArr;
    })
    .reduce((accum, [key, value]) => {
      // eslint-disable-next-line no-param-reassign
      accum[key] = value;
      return accum;
    }, {});

  const {
    accountHolderType,
    accountType,
    fullName,
    routingNumber,
    confirmRoutingNumber,
    accountNumber,
    confirmAccountNumber,
    nickName,
    accountOwnershipType,
  } = trimmedValues ?? {};

  const hasMatchingRoutingNumber = routingNumber === confirmRoutingNumber;
  const hasMatchingAccountNumber = accountNumber === confirmAccountNumber;

  const errors = {};

  // form fields always shown in the form
  if (!accountHolderType || accountHolderType === '') {
    errors.accountHolderType = 'Please select account holder type';
  }

  if (!accountType || accountType === '') {
    errors.accountType = 'Please select account type';
  }

  if (!fullName || fullName === '') {
    errors.fullName = "Please enter account holder's name";
  }

  if (!routingNumber || routingNumber === '') {
    errors.routingNumber = 'Please enter routing number';
  }

  if (!confirmRoutingNumber || confirmRoutingNumber === '') {
    errors.confirmRoutingNumber = 'Please enter routing number';
  }

  if (routingNumber && routingNumber.length === 9 && bankInfoAPIError.hasError) {
    errors.routingNumber = bankInfoAPIError.message;
  }

  if (routingNumber && routingNumber.length < 9) {
    errors.routingNumber = 'Enter a valid domestic routing number';
  }

  if (routingNumber && confirmRoutingNumber && !hasMatchingRoutingNumber) {
    errors.confirmRoutingNumber = 'Routing numbers do not match';
  }

  if (!accountNumber || accountNumber === '') {
    errors.accountNumber = 'Please enter account number';
  }

  if (!confirmAccountNumber || confirmAccountNumber === '') {
    errors.confirmAccountNumber = 'Please enter account number';
  }

  if (accountNumber && confirmAccountNumber && !hasMatchingAccountNumber) {
    errors.confirmAccountNumber = 'Account numbers do not match';
  }

  if (!nickName || nickName === '') {
    errors.nickName = 'Please enter account nickname';
  }

  // form fields sometimes shown in the form - optional
  if (
    'accountOwnershipType' in trimmedValues &&
    (!accountOwnershipType || accountOwnershipType === '')
  ) {
    errors.accountOwnershipType = 'Please select type';
  }

  return errors;
};

export const accountHolderTypeOptions = [
  { id: 'INDIVIDUAL', name: 'Individual' },
  { id: 'BUSINESS', name: 'Business' },
];

export const accountTypeOptions = [
  { id: 'savings', name: 'Savings' },
  { id: 'checking', name: 'Checking' },
  { id: 'loan', name: 'Loan' },
  { id: 'not_sure', name: "I'm not sure" },
];

export const accountOwnershipTypeOptions = [
  { id: 'SELF', name: 'Myself or Related Account' },
  { id: 'THIRD_PARTY', name: '3rd Party Account (e.g. vendor, contractor, etc.)' },
];

export const ERROR_MESSAGES = {
  DUPLICATE_BANK_ACCOUNT_TITLE: 'Account already added',
  DUPLICATE_BANK_ACCOUNT:
    'You’ve already added an account with this account number and routing number.',
  INVALID_INFORMATION_TITLE: 'An error occured during account verification',
  INVALID_INFORMATION: 'Please make sure that the bank information is correct.',
};

export const getInitialValues = (showAccountOwnershipType, page) => {
  const initialValues = {
    accountHolderType: '',
    accountType: '',
    fullName: '',
    routingNumber: '',
    confirmRoutingNumber: '',
    accountNumber: '',
    confirmAccountNumber: '',
    nickName: '',
  };

  if (showAccountOwnershipType) {
    initialValues.accountOwnershipType = '';
  }

  if (page === 'third_party_accounts') {
    initialValues.accountOwnershipType = 'THIRD_PARTY';
  }

  // Landlord tries to add account manually from "External accounts" page.
  if (page === 'my_accounts') {
    initialValues.accountOwnershipType = 'SELF'; // In "Add Manual Account" flow, accountHolderType is always defaults to "SELF".
  }

  return initialValues;
};

export const getPurpose = (page) => {
  switch (page) {
    case 'add_funds':
      return 'SEND_FUND';

    case 'rent_collection':
      return 'RENT_COLLECTION';

    case 'third_party_accounts':
      return 'SEND_FUND';

    case USER_PROFILE_UPDATE_ACCOUNTS:
      return 'RENT_COLLECTION';

    default:
      return null;
  }
};
