export const getAmountColor = (text) => {
  switch (text) {
    case 'Processing':
      return 'brand.blue.800A';
    case 'Completed':
      return 'green.800AA';
    case 'disabled':
      return 'brand.neutral.500';
    default:
      return 'brand.neutral.700';
  }
};

export const getTotalNumberOfUnits = (leases) => {
  const leasesWithUnits = leases
    ?.filter((lease) => {
      return lease?.propertyUnitId;
    })
    .map((lease) => lease.propertyUnitId);

  const uniquePropertyUnitIds = Array.from(new Set(leasesWithUnits));

  return uniquePropertyUnitIds.length;
};
