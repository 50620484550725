/* eslint-disable react/prop-types */
import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  useToast,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Modal,
  Text,
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@apollo/client';
import SlLoader from '@core/components/Loader';
import { BaselaneButton } from '@shared/components';
import {
  CANCEL_UPCOMING_PAYMENT,
  GET_SCHEDULED_PAYMENTS,
  CANCEL_RECURRING_PAYMENTS,
} from '@core/apollo/queries';
import onDrawerClose from '@core/utils/onDrawerClose';
import { useUnitOtp } from '@core/contexts/UnitOtpContext';

const CancelTransferModal = ({
  handleClose,
  navigateFrom,
  transferId,
  recurringPaymentId,
  bankId,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const showCancelTransferErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: `Error, transfer could not be canceled.`,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const showCancelTransferSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: transferId ? `Canceled transfer` : 'Canceled transfers',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  // Unit OTP
  const { forceVerifyUnitOtp, isOtpRequired } = useUnitOtp();

  const [xIdempotencyKey, setXIdempotencyKey] = useState(uuidv4());

  const typeOfMutation = transferId ? CANCEL_UPCOMING_PAYMENT : CANCEL_RECURRING_PAYMENTS;

  const refetchQueries = [GET_SCHEDULED_PAYMENTS];

  // We have to refetch the recurring payment details only if we are canceling a single recurring transfer, not the whole series.
  if (!recurringPaymentId) {
    refetchQueries.push('scheduledRecurringPaymentDetails');
  }

  const [cancelPayment, { loading: cancelingPayment }] = useMutation(typeOfMutation, {
    refetchQueries,
  });

  const startCancelProcess = () => {
    setIsLoading(true);
    const id = transferId ? +transferId : +recurringPaymentId; // The BE accepts id as a number.
    cancelPayment({
      context: {
        headers: {
          'x-idempotency-key': xIdempotencyKey,
        },
      },
      variables: { id },
    })
      .then((res) => {
        if (!res?.errors) {
          showCancelTransferSuccessToast();
        } else if (isOtpRequired(res.errors)) {
          forceVerifyUnitOtp(bankId)
            .then(startCancelProcess)
            .catch(() => {
              setXIdempotencyKey(uuidv4());
              setIsLoading(false);
            });
          return;
        } else {
          showCancelTransferErrorToast();
        }
        setTimeout(() => {
          onDrawerClose(navigate, navigateFrom);
          setIsLoading(false); // Reset loading state after drawer closes
        }, 1000); // Consider finding a better solution
        handleClose();
        setXIdempotencyKey(uuidv4());
      })
      .catch((err) => {
        showCancelTransferErrorToast();
        handleClose();
        setXIdempotencyKey(uuidv4());
      });
  };

  return (
    <>
      {cancelingPayment && (
        <Box>
          <SlLoader styles={{ position: 'absolute !important' }} />
        </Box>
      )}
      <Modal isOpen isCentered onClose={() => handleClose()} w="352px">
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader p={0} textStyle="headline-lg">
            <Text textStyle="headline-lg">
              {recurringPaymentId ? 'Cancel scheduled transfer?' : 'Cancel recurring transfer?'}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <Text textStyle="sm" mt={0.5}>
              {!recurringPaymentId
                ? 'This will cancel the scheduled transfer immediately.'
                : 'This will cancel all upcoming transfers in the series, but not the past or processing transfers.'}
            </Text>
          </ModalBody>
          <ModalFooter gap={2} p={0} mt={3}>
            <BaselaneButton
              variant="outline"
              palette="neutral"
              size="md"
              onClick={() => handleClose()}
              styles={{
                w: 'full',
              }}
            >
              Back
            </BaselaneButton>
            <BaselaneButton
              variant="filled"
              palette="danger"
              size="md"
              isLoading={isLoading}
              onClick={startCancelProcess}
              styles={{
                w: 'full',
              }}
            >
              {transferId ? 'Cancel transfer' : 'Cancel transfers'}
            </BaselaneButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CancelTransferModal;
