import { gql } from '@apollo/client';

export const GET_BANK_BALANCE_SUMMARY = gql`
  query BALANCE {
    user {
      id
      cashBalance {
        expense
        income
        totalCashBalance
      }
    }
  }
`;

export const GET_HISTORICAL_BALANCE = gql`
  query BALANCE {
    user {
      id
      historicalBalances {
        amount
        date
      }
    }
  }
`;
