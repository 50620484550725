import React, { useContext, useState, useRef, useImperativeHandle } from 'react';
import { Formik } from 'formik';
import { forwardRef, useDisclosure } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { BaselaneDrawer } from '@shared/components';
import TransactionContext from '@contexts/TransactionContext';
import UserContext from '@contexts/UserContext';
import { getPropertyData } from '@shared/helpers/propertiesFilter.helpers';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { GET_TRANSFERS } from '@core/components/NativeBankingPage/queries';
import { getOptionsWithSubCategories } from '@core/components/CashFlowPage/helpers/cashflow.helpers';
import UnsavedChangesAlert from '../UnsavedChangesAlert';
import MailCheckDrawerFooter from './MailCheckDrawerFooter';
import handleFormViews from './helpers/views.helpers';
import { INITIATE_CHECK_PAYMENT } from '../DomesticWireDrawer/queries/transfers';
import { handleValidation } from './helpers/validation.helpers';
import { footerConfig } from './helpers/config.helpers';
import {
  formatDataForApi,
  propertyAndCategoryInitialValues,
  recipientDetailsInitialValues,
} from './helpers/data.helpers';

import { drawerBodyStyles } from './styles/main.styles';

type MailCheckDrawerProps = {
  makeTransferOrPaymentDrawerRef: any,
  isMailCheckDrawerOpen: Boolean,
  mailCheckDrawerRef: any,
  onCloseMailCheckDrawer: Function,
  paymentMethod: string,
  setIsValidAccount: Function,
  setIsValidBankTransfer: Function,
  transfer: Object,
  setTransfer: Function,
  isValidBankTransfer: boolean,
  onAlertOpen: Function,
  checkToken: Function,
};

const MailCheckDrawer = forwardRef(
  (
    {
      onCloseMailCheckDrawer,
      isMailCheckDrawerOpen,
      makeTransferOrPaymentDrawerRef,
      mailCheckDrawerRef,
      onCloseDomesticWireDrawer,
      paymentMethod,
      setIsValidAccount,
      setIsValidBankTransfer,
      transfer,
      setTransfer,
      isValidBankTransfer,
      checkToken,
    }: MailCheckDrawerProps,
    ref
  ) => {
    const { isMinXL } = useBreakPoints();

    // Alert State
    const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

    const {
      setShowMobileDropdownPopup,
      showMobileDropdownPopup,
      setShowMobileDropdownPopupAnimation,
    } = useContext(UserContext);

    const { propertiesData, categoryMap, categoryWithSubOptions, categoryIdsMap } = useContext(
      TransactionContext
    );
    const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);

    const [currentStep, setCurrentStep] = useState(1);
    const TOTAL_STEPS = 4;

    const [error, setError] = useState(null);
    const [xIdempotencyKey, setXIdempotencyKey] = useState(uuidv4());

    const mailCheckFormikRef = useRef(null);

    // TODO initialMailCheckTransfer
    const [initiateCheckPayment, { client }] = useMutation(INITIATE_CHECK_PAYMENT, {
      refetchQueries: [GET_TRANSFERS],
    });

    const handlePropertySubmit = (newPropertyId: string) => {
      const [newPId, newUId] = newPropertyId.split('-');
      mailCheckFormikRef?.current?.setFieldValue('propertyId', newPId);
      mailCheckFormikRef?.current?.setFieldValue('unitId', newUId);
      mailCheckFormikRef?.current?.setFieldValue('propertyUnitId', newPropertyId);
    };

    function handleCategorySubmit(newCategoryId: number, dropDown) {
      const [parentId, subId] = (newCategoryId ?? '').split('-');
      mailCheckFormikRef?.current?.setFieldValue('categoryId', subId ?? parentId);
      setTimeout(() => {
        dropDown('close');
      }, 10);
    }

    const propertyOptions = getPropertyData(propertiesData);

    const validate = (values, stepNumber) => {
      mailCheckFormikRef?.current?.validateForm({ ...values, stepNumber });
    };

    const reinitialize = () => {
      const initialValues = {
        ...recipientDetailsInitialValues,
        ...propertyAndCategoryInitialValues,
      };
      mailCheckFormikRef?.current?.resetForm({ values: initialValues });
      setIsValidBankTransfer(false);
      setTransfer({});
      validate(initialValues, 1);
      setCurrentStep(1);
      setXIdempotencyKey(uuidv4());
    };

    const closeAndReinitialize = () => {
      reinitialize();
      onCloseMailCheckDrawer();
    };

    const handleOnDrawerClose = () => {
      if (mailCheckFormikRef?.current?.dirty) {
        onAlertOpen();
      } else {
        closeAndReinitialize();
      }
    };

    const validateNextClick = (nextStepNumber) => {
      const { values = {} } = mailCheckFormikRef?.current || {};
      mailCheckFormikRef?.current?.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          setCurrentStep(nextStepNumber);
          sendSegmentEvent(
            `baselane_banking_complete_check_mail_transfer_step${nextStepNumber - 1}`
          );
          validate(values, nextStepNumber);
        } else {
          mailCheckFormikRef?.current.setTouched(errors, true);
        }
      });
    };

    const handleErrorRedirect = (step) => {
      if (step) {
        setCurrentStep(step);
      }
    };

    const handleMailCheckTransferSubmit = () => {
      const valuesToInitialize = {
        ...(mailCheckFormikRef?.current?.values ?? {}),
        transfer,
      };
      const variables = formatDataForApi(valuesToInitialize);
      initiateCheckPayment({
        context: {
          headers: {
            'x-idempotency-key': xIdempotencyKey,
          },
        },
        variables,
      })
        .then((res) => {
          if (res.errors) {
            setError(res.errors);
          } else {
            setCurrentStep(5);
            client.refetchQueries();
          }
        })
        .catch((err) => {
          setError(err);
        });
    };

    useImperativeHandle(ref, () => ({
      handleMailCheckTransferSubmit,
    }));

    const onNextClicked = () => {
      switch (currentStep) {
        case 1:
          validateNextClick(2);
          return;
        case 2:
          validateNextClick(3);
          break;
        case 3:
          validateNextClick(4);
          break;
        default:
      }
    };

    const onPrevClicked = () => {
      const { values = {} } = mailCheckFormikRef?.current || {};

      switch (currentStep) {
        case 2:
          setCurrentStep(1);
          validate(values, 1);
          return;
        case 3:
          setCurrentStep(2);
          validate(values, 2);
          break;
        case 4:
          setCurrentStep(3);
          validate(values, 3);
          break;
        case 5:
          setCurrentStep(4);
          break;
        default:
        // do nothing
      }
    };

    const resetApiErrors = () => {
      if (error) {
        setError(null);
      }
    };

    const { DrawerBody } = BaselaneDrawer;
    return (
      <>
        <UnsavedChangesAlert
          {...{
            isAlertOpen,
            onAlertClose,
            rightButtonEvent: () => {
              closeAndReinitialize();
              onAlertClose();
            },
          }}
        />

        <BaselaneDrawer
          ref={mailCheckDrawerRef}
          title="Mail a check"
          size="md"
          isOpen={isMailCheckDrawerOpen}
          finalFocusRef={mailCheckDrawerRef}
          closeEvent={handleOnDrawerClose}
          onClose={handleOnDrawerClose}
        >
          <Formik
            innerRef={mailCheckFormikRef}
            initialValues={{
              ...propertyAndCategoryInitialValues,
              ...recipientDetailsInitialValues,
            }}
            validate={(values) => handleValidation(values, currentStep, resetApiErrors)}
            validateOnBlur
            enableReinitialize
            validateOnMount
          >
            {({ isValid, values, touched, errors, handleChange, handleBlur }) => (
              <>
                <DrawerBody {...drawerBodyStyles(isMinXL)} id="check-mail-transfer-drawer">
                  {handleFormViews({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    totalSteps: TOTAL_STEPS,
                    currentStep,
                    paymentMethod,
                    setIsValidAccount,
                    setIsValidBankTransfer,
                    setTransfer,
                    propertyOptions,
                    categoryOptions,
                    categoryIdsMap,
                    categoryMap,
                    handlePropertySubmit,
                    handleCategorySubmit,
                    handleErrorRedirect,
                    transfer,
                    error,
                    setShowMobileDropdownPopup,
                    setShowMobileDropdownPopupAnimation,
                    showMobileDropdownPopup,
                  })}
                </DrawerBody>
                <MailCheckDrawerFooter
                  {...{
                    isRightBtnDisabled:
                      !isValid ||
                      (currentStep === 2 && !isValidBankTransfer) ||
                      (currentStep === 4 && error),
                    ...footerConfig({
                      onPrevClicked,
                      onNextClicked,
                      onCloseMailCheckDrawer,
                      makeTransferOrPaymentDrawerRef,
                      handleMailCheckTransferSubmit,
                      reinitialize,
                      isValid,
                      handleOnDrawerClose,
                      checkToken,
                    })[currentStep],
                  }}
                />
              </>
            )}
          </Formik>
        </BaselaneDrawer>
      </>
    );
  }
);

export default MailCheckDrawer;
