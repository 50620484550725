import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { BaselanePropertyUnitAddress } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { invoiceStyles } from '../../../styles/invoice.styles';

type AddInvoiceHeaderProps = {
  leaseTenant: string,
  propertyName: string,
  unitName: string,
  isPropertyDeleted: boolean,
  isMultiUnit: boolean,
};

const AddInvoiceHeader = ({
  leaseTenant,
  propertyName,
  unitName,
  isPropertyDeleted,
  isMultiUnit,
}: AddInvoiceHeaderProps) => {
  const { isMax767 } = useBreakPoints();

  const tenantFullName = `${leaseTenant?.firstName} ${leaseTenant?.lastName}`;

  const { header } = invoiceStyles({ isMax767 });

  return (
    <Stack {...header} justifyContent="space-between" borderBottom="none" p="0 24px 24px">
      <Stack p="0">
        <Box>
          <Stack minH="64px">
            {isPropertyDeleted ? (
              <Text {...header.propertydeleted}>Property Deleted</Text>
            ) : (
              <BaselanePropertyUnitAddress
                {...{
                  propertyName,
                  unitName: isMultiUnit ? unitName : null,
                  propertyAddress: null,
                  tenantFullName,
                }}
              />
            )}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};
export default AddInvoiceHeader;
