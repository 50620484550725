// 1. Import the extendTheme util - it will merge with the default theme
import { extendTheme } from '@chakra-ui/react';

import textStyles from '@core/theme/text';
import colors from '@core/theme/colors';
import breakpoints from '@core/theme/breakpoints';
import shadows from '@core/theme/shadows';
import typography from '@core/theme/typography';
import spacing from '@core/theme/spacing';
import radii from '@core/theme/radius';
import styles from '@core/theme/styles';

import { Button } from './theme/component/Button/button';
import { Accordion } from './theme/component/accordion';
import { Alert } from './theme/component/Alert/alert';
import { Drawer } from './theme/component/drawer';
import { FormLabel } from './theme/component/form-label';
import { Heading } from './theme/component/heading';
import { Input } from './theme/component/input';
import { Modal } from './theme/component/modal';
import { Select } from './theme/component/select';
import { Table } from './theme/component/table';
import { Tabs } from './theme/component/tabs';
import { Tag } from './theme/component/Tag/tag';
import { Textarea } from './theme/component/textarea';
import { Link } from './theme/component/Link/link';
import { Stepper } from './theme/component/Stepper/stepper';
import { Skeleton } from './theme/component/skeleton';

import { BaselaneCard } from './themeHabitat/component/BaselaneCard/card';
import { BaselaneCardStack } from './themeHabitat/component/BaselaneCardStack/cardStack';

const components = {
  Accordion,
  Alert,
  Button,
  Drawer,
  FormLabel,
  Heading,
  Input,
  Modal,
  Select,
  Table,
  Tabs,
  Tag,
  Textarea,
  Link,
  Stepper,
  BaselaneCard,
  BaselaneCardStack,
  Skeleton,
};

const themeMap = {
  colors,
  components,
  ...typography,
  shadows,
  breakpoints,
  styles,
  textStyles,
  radii,
  space: spacing.space,
};

const customTheme = extendTheme(themeMap);

export default customTheme;
