export const conditionKeys = {
  AMOUNT_NEGATIVE: 'AMOUNT_NEGATIVE',
  AMOUNT: 'AMOUNT',
  BANK_ACCOUNT_ID: 'BANK_ACCOUNT_ID',
  BETWEEN: 'BETWEEN',
  EQUALS: 'EQUALS',
  EXACT: 'EXACT',
  GREATER_THAN: 'GREATER_THAN',
  LESS_THAN: 'LESS_THAN',
  NUMERIC: 'NUMERIC',
  PARTIAL: 'PARTIAL',
  STARTS_WITH: 'STARTS_WITH',
  TEXT_SEARCH: 'TEXT_SEARCH',
  VIRTUAL_CARD_ID: 'VIRTUAL_CARD_ID',
};

export const operatorMap = {
  [conditionKeys.PARTIAL]: 'contains',
  [conditionKeys.STARTS_WITH]: 'starts with',
  [conditionKeys.EXACT]: 'exactly matches',
  [conditionKeys.EQUALS]: 'is',
  [conditionKeys.GREATER_THAN]: 'is greater than',
  [conditionKeys.LESS_THAN]: 'is less than',
  [conditionKeys.BETWEEN]: 'is between',
};

export const fieldMap = {
  [conditionKeys.TEXT_SEARCH]: 'Description',
  [conditionKeys.VIRTUAL_CARD_ID]: 'Card',
  [conditionKeys.BANK_ACCOUNT_ID]: 'Account',
  [conditionKeys.AMOUNT]: 'Amount in',
  [conditionKeys.AMOUNT_NEGATIVE]: 'Amount out',
};

export const cardName = (virtualCards, value) => {
  const currentCard = virtualCards.find((card) => card.id === `${value}`);
  return `${currentCard?.nickname || currentCard?.accountName} - ${currentCard?.last4Digits}`;
};

export const bankAccountName = (bankAccounts, value) => {
  const currentAccount = bankAccounts.find((account) => account.id === `${value}`);
  return currentAccount?.nickName || currentAccount?.accountName;
};

export const getActionableProperties = (propertiesData, autoTagRulesData) => {
  const autoTagRulesForPropertiesWithAccounts = autoTagRulesData.autotagRules.filter((rule) =>
    rule.conditions.some((condition) => condition.field === 'BANK_ACCOUNT_ID')
  );

  const propertyIdsWithAccounts = autoTagRulesForPropertiesWithAccounts.map(
    ({ action: { propertyId } }) => propertyId
  );

  const propertiesWithoutRules = propertiesData?.property?.filter(
    (property) => !propertyIdsWithAccounts?.includes(property.id)
  );

  return propertiesWithoutRules;
};
