import process from 'process';
import React, { useEffect, useState, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { Stack, Text, useToast } from '@chakra-ui/react';
import { getTokenData } from '@shared/helpers/otp.helpers';
import UserAccessContext from '@contexts/UserAccessContext';
import { IconEye, IconLock, IconVisa } from '@icons';
import { BaselaneButton } from '@shared/components';
import SlLoader from '@core/components/Loader';
import BaselaneIconLogo from '@core/assets/BaselaneIconLogo';

import { useUnitOtp } from '@core/contexts/UnitOtpContext';
import CopyWrapper from './CopyWrapper';
import {
  cardContainerStyles,
  cardLabelStyles,
  cardValueStyles,
  copyButtonStyles,
  iconContainerStyles,
  loaderStyles,
} from './styles/cardui.styles';

type CardUIProps = {
  card: string,
  bankId: string,
  phoneNumber: string,
};

const CardUI = ({ card, bankId, phoneNumber }: CardUIProps) => {
  const { externalId: id, cardStatus, expirationDate } = card ?? {};
  // two factor verification states
  const { verifyUnitOtp, ignore } = useUnitOtp();

  const { authorizedForBanking } = useContext(UserAccessContext);

  const [showCardDetails, setShowCardDetails] = useState(false);
  const [cardNumberFrame, setCardNumberFrame] = useState(null);
  const [cvv2Frame, setCvv2Frame] = useState(null);
  const [showCardDetailsLoader, setShowCardDetailsLoader] = useState({
    cvv2: true,
    cardNumber: true,
  });

  // Toast
  const toast = useToast();
  const showToast = () =>
    toast({
      position: isMobile ? 'bottom' : 'bottom-left',
      description: 'Copied to clipboard',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const vaultId =
    process.env.REACT_APP_NODE_ENV === 'production' ||
    process.env.REACT_APP_NODE_ENV === 'pre-prod' ||
    process.env.REACT_APP_NODE_ENV === 'hotfix'
      ? 'tnt8w6nrmbu'
      : 'tntazhyknp1';

  const enviroment =
    process.env.REACT_APP_NODE_ENV === 'production' ||
    process.env.REACT_APP_NODE_ENV === 'pre-prod' ||
    process.env.REACT_APP_NODE_ENV === 'hotfix'
      ? 'live'
      : 'sandbox';

  const initShow = () => {
    // setup vgs show + get necessary information for requests
    const show = window.VGSShow.create(vaultId).setEnvironment(enviroment);

    const { sensitive } = getTokenData(bankId);
    const customerToken = sensitive?.token ?? '';
    const cardId = id;

    // Card Number
    const cardNumberIframe = show.request({
      name: 'data-text',
      method: 'GET',
      path: `/cards/${cardId}/secure-data/pan`,
      headers: {
        Authorization: `Bearer ${customerToken}`,
      },
      serializers: [show.SERIALIZERS.replace('(\\d{4})(\\d{4})(\\d{4})(\\d{4})', '$1 $2 $3 $4')],
      htmlWrapper: 'text',
      jsonPathSelector: 'data.attributes.pan',
    });

    cardNumberIframe.on('requestSuccess', () => {
      setTimeout(() => {
        setShowCardDetailsLoader((prevState) => ({ ...prevState, cardNumber: false }));
      }, 2);
    });

    cardNumberIframe.render('#cardNumber', cardValueStyles);
    setCardNumberFrame(cardNumberIframe);

    const cardNumberCopyBtn = show.copyFrom(
      cardNumberIframe,
      {
        text: '❏',
        serializers: [show.SERIALIZERS.replace(' ', '')],
      },
      (status) => {
        if (status === 'success') {
          showToast();
        }
      }
    );

    cardNumberCopyBtn.render('#cardNumberCopy', copyButtonStyles);

    // CVV2
    const cvv2iframe = show.request({
      name: 'data-text',
      method: 'GET',
      path: `/cards/${cardId}/secure-data/cvv2`,
      headers: {
        Authorization: `Bearer ${customerToken}`,
      },
      htmlWrapper: 'text',
      jsonPathSelector: 'data.attributes.cvv2',
    });

    cvv2iframe.on('requestSuccess', () => {
      setTimeout(() => {
        setShowCardDetailsLoader((prevState) => ({ ...prevState, cvv2: false }));
      }, 2);
    });

    cvv2iframe.render('#cvv2', cardValueStyles);
    setCvv2Frame(cvv2iframe);

    const cvvCopyBtn = show.copyFrom(
      cvv2iframe,
      {
        text: '❏',
        serializers: [show.SERIALIZERS.replace(' ', '')],
      },
      (status) => {
        if (status === 'success') {
          showToast();
        }
      }
    );

    cvvCopyBtn.render('#cvvCopy', copyButtonStyles);
  };

  const removeIFrameListeners = () => {
    if (cardNumberFrame && cvv2Frame) {
      cardNumberFrame.off('requestSuccess');
      cvv2Frame.off('requestSuccess');
    }
  };
  const cleanup = () => {
    removeIFrameListeners();
    setShowCardDetails(false);
    setShowCardDetailsLoader({
      cvv2: true,
      cardNumber: true,
    });
    setCardNumberFrame(null);
    setCvv2Frame(null);
  };

  useEffect(() => {
    if (showCardDetails && cardStatus === 'Active') {
      initShow();
    } else if (showCardDetails || cardNumberFrame || cvv2Frame) {
      cleanup();
    }
  }, [showCardDetails, cardStatus]);

  useEffect(() => {
    return () => {
      // as component dismounts clean up vgs items
      cleanup();
    };
  }, []);

  const [year, month] = expirationDate.split('-');
  const expiryDate = `${month}/${year.substring(2)}`;

  const handleCardStatus = () => {
    switch (cardStatus) {
      case 'Active':
        return showCardDetails ? (
          <>
            <Stack direction="row" {...iconContainerStyles}>
              <BaselaneIconLogo />
              <IconVisa />
            </Stack>

            <SlLoader styles={loaderStyles(showCardDetailsLoader)} />

            <Stack
              justifyContent="space-between"
              visibility={
                showCardDetailsLoader.cvv2 && showCardDetailsLoader.cardNumber
                  ? 'hidden'
                  : 'visible'
              }
            >
              <Stack spacing="2px">
                <Text {...cardLabelStyles}>Card Number</Text>
                <CopyWrapper id="cardNumber">
                  <Stack direction="row" id="credit-card-number-show">
                    <div id="cardNumber" className="credit-card-number-show" />
                    <div id="cardNumberCopy" className="vgs-copy" />
                  </Stack>
                </CopyWrapper>
              </Stack>
              <Stack direction="row" spacing="27px">
                <Stack spacing="2px">
                  <Text {...cardLabelStyles}>Expiry Date</Text>
                  <CopyWrapper id="expiryDate" showToast={showToast}>
                    <Text id="expiryDate" {...cardValueStyles}>
                      {expiryDate}
                    </Text>
                  </CopyWrapper>
                </Stack>
                <Stack spacing="2px">
                  <Text {...cardLabelStyles}>CVV</Text>
                  <CopyWrapper id="cvv2">
                    <Stack direction="row">
                      <div id="cvv2" className="credit-card-cvv-show" />
                      <div id="cvvCopy" className="vgs-copy" />
                    </Stack>
                  </CopyWrapper>
                </Stack>
              </Stack>
            </Stack>
          </>
        ) : (
          <Stack align="center" justify="center" h="100%">
            {authorizedForBanking && (
              <BaselaneButton
                variant="outline"
                palette="neutral"
                rightIcon={<IconEye width={22} height={18} />}
                onClick={() => {
                  verifyUnitOtp(bankId)
                    .then(() => setShowCardDetails(true))
                    .catch(ignore);
                }}
                styles={{ pr: '13px' }}
              >
                Show Card Details
              </BaselaneButton>
            )}
          </Stack>
        );
      case 'Frozen':
        return (
          <Stack direction="row" align="center">
            <IconLock w={15} h={16} />
            <Text>Card Locked</Text>
          </Stack>
        );
      case 'SuspectedFraud':
        return (
          <Stack direction="row" align="center">
            <IconLock w={15} h={16} />
            <Text>Card Suspected Fraud</Text>
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <Stack className="vgs" {...cardContainerStyles(cardStatus, showCardDetails)}>
      {handleCardStatus()}
    </Stack>
  );
};

export default CardUI;
