import React from 'react';
import { useQuery } from '@apollo/client';
import { Skeleton, useDisclosure } from '@chakra-ui/react';
import ActivatePopup from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/ActivatePopup';
import { useUnitOtp } from '@core/contexts/UnitOtpContext';

import { GET_BANK_FOR_CARD } from '../queries';

import { InProgress } from './Steps';

type CardProps = {
  card: Object,
  onActivationSuccessPopupOpen: Function,
  getStartedStatus: String,
};

function Card({ card, onActivationSuccessPopupOpen, getStartedStatus }: CardProps) {
  const { loading: isBankLoading, data: bankData } = useQuery(GET_BANK_FOR_CARD, {
    variables: {
      accountId: card?.bankAccountId,
    },
    skip: !card || !card?.bankAccountId,
  });

  const bank = bankData?.bank ? bankData?.bank[0] : {};
  const bankAccount = bank?.bankAccounts ? bank?.bankAccounts[0] : {};

  const bankInfo = {
    ...bank,
    ...bankAccount,
    bankId: bank?.id ?? '',
    phoneNumber: bank?.unitAccount?.phoneNumber ?? '',
    bankName: bank?.name ?? '',
  };

  const { bankId } = bankInfo;

  // Alert State
  const {
    isOpen: isActivatePopupOpen,
    onOpen: onActivatePopupOpen,
    onClose: onActivatePopupClose,
  } = useDisclosure();

  const { verifyUnitOtp, ignore } = useUnitOtp();

  return (
    <>
      <Skeleton isLoaded={!isBankLoading}>
        <InProgress
          status={getStartedStatus}
          handleTrackerInProgStepClick={(e) => {
            e.stopPropagation();
            verifyUnitOtp(bankId).then(onActivatePopupOpen).catch(ignore);
          }}
        />
      </Skeleton>

      <ActivatePopup
        card={card}
        bankInfo={{ bankId }}
        isActivatePopupOpen={isActivatePopupOpen}
        onActivatePopupClose={onActivatePopupClose}
        onActivationSuccessPopupOpen={onActivationSuccessPopupOpen}
      />
    </>
  );
}

export default Card;
