import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Flex, Box, Text, Heading, HStack, Stack, Spinner } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import {
  BaselaneCardNew,
  BaselaneCardStack,
  BaselaneDivider,
  BaselaneButton,
} from '@shared/components';
import { UNIFIED_RENT_COLLECTION, LEASE_AGREEMENT_TO_LEASE } from '@core/constants/routes';
import { Icon16ChevronRight, Icon16PlusCircle } from '@icons/16px';
import { Icon24Key } from '@icons/24px';
import { IllustrationRCSetuprentcollection } from '@illustrations';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { GET_LEASES } from '@pages/LeasesPage/queries';
import { useLeaseAgreement } from '../hooks/useLeaseAgreement';
import { GET_LEASE_AGREEMENT } from '../queries';
import { formatLeaseAgreementDuration } from '../helpers/leaseAgreement.helper';

function LeaseAgreementNextStep() {
  const navigate = useNavigate();
  const location = useLocation();

  const entryPoint = location?.pathname?.split('/')?.pop();

  const { leaseagreementId: leaseAgreementId } = useParams();

  // the data we need to refetch after close the drawer
  const {
    leaseAgreement: leaseAgreementData,
    refetch: leaseAgreementDataRefetch,
    loading: leaseAgreementDataLoading,
  } = useLeaseAgreement(leaseAgreementId || '');

  const [, setStableLeaseData] = useState(null);

  const { data } = useQuery(GET_LEASE_AGREEMENT, {
    variables: { id: leaseAgreementId },
    skip: !leaseAgreementId,
  });

  const hasAssignedLease = Boolean((leaseAgreementData?.leases || []).length);

  const { data: leasesData } = useQuery(GET_LEASES, {
    variables: {
      input: {
        state: ['SCHEDULED', 'EXPIRED'],
        ...(leaseAgreementData?.tenantProfileId && {
          tenantProfileId: leaseAgreementData.tenantProfileId,
        }),
      },
    },
  });

  const hasLeases = Boolean(leasesData?.leases?.leases?.length);
  const items = [];

  if (hasAssignedLease) {
    leaseAgreementData?.leases?.forEach((lease) => {
      items.push({
        content: (
          <BaselaneCardNew
            onClick={() => {
              navigate(LEASE_AGREEMENT_TO_LEASE);
            }}
            size="md"
            variant="innerClickable"
          >
            <HStack gap={2} alignItems="flex-start">
              <Box>
                <Icon24Key />
              </Box>
              <Stack gap={0.5}>
                <Heading size="headline-md">
                  {lease.tenantProfile?.firstName || ''} {lease.tenantProfile?.lastName || ''}
                </Heading>
                <Text textStyle="xs">
                  {formatLeaseAgreementDuration(lease.startDate || '', lease.endDate || '')}
                </Text>
              </Stack>
            </HStack>
          </BaselaneCardNew>
        ),
        id: `lease-details-${lease.id}`,
      });
    });
  } else {
    items.push({
      content: (
        <BaselaneCardNew
          onClick={() => {
            sendSegmentEvent('lease_agreement_fe_rc_setup_started', { entry_point: entryPoint });
            navigate(UNIFIED_RENT_COLLECTION);
          }}
          size="md"
          variant="innerClickable"
        >
          <HStack gap={2} alignItems="flex-start">
            <Box>
              <IllustrationRCSetuprentcollection />
            </Box>
            <Stack gap={0.5}>
              <Heading size="headline-md">Set up rent collection</Heading>
              <Text textStyle="sm">
                Fast & free rent collection with automated invoicing, reminders & late fees.
              </Text>
            </Stack>
            <Box height="100%" alignSelf="center">
              <Icon16ChevronRight />
            </Box>
          </HStack>
        </BaselaneCardNew>
      ),
      id: 'rent-collection',
    });

    if (hasLeases) {
      items.push({
        content: (
          <BaselaneCardNew
            onClick={() => {
              sendSegmentEvent('lease_agreement_fe_rc_link_started', { entry_point: entryPoint });
              navigate(LEASE_AGREEMENT_TO_LEASE);
            }}
            size="md"
            variant="innerClickable"
          >
            <HStack gap={2} alignItems="flex-start">
              <Box height="100%">
                <IllustrationRCSetuprentcollection />
              </Box>
              <Stack gap={0.5}>
                <Heading size="headline-md">Attach lease to existing rent collection</Heading>
                <Text textStyle="sm">
                  If you&quot;ve already set up rent collection, you can attach it to the lease
                  agreement to easily access them later.
                </Text>
              </Stack>
              <Box height="100%" alignSelf="center">
                <Icon16ChevronRight />
              </Box>
            </HStack>
          </BaselaneCardNew>
        ),
        id: 'link-lease-agreement-to-rent-collection',
      });
    }
  }

  useEffect(() => {
    if (data?.leaseAgreement) {
      setStableLeaseData(data.leaseAgreement);
    }
  }, [data]);

  useEffect(() => {
    leaseAgreementDataRefetch();
  }, [location]);

  return leaseAgreementDataLoading ? (
    <Stack gap={2} alignItems="center">
      <Spinner />
    </Stack>
  ) : (
    <Stack gap={2} alignItems="flex-start">
      <BaselaneDivider my="4" />
      <Heading size="headline-lg" w="full" mt="0">
        {!hasAssignedLease ? (
          'Next step'
        ) : (
          <Flex justifyContent="space-between">
            Attached to rent collections
            <BaselaneButton
              variant="transparent"
              palette="primary"
              size="md"
              onClick={() => {
                navigate(LEASE_AGREEMENT_TO_LEASE);
              }}
            >
              Edit
            </BaselaneButton>
          </Flex>
        )}
      </Heading>
      <BaselaneCardStack w="full" id="example-cardstack-medium" items={items} />
      {hasAssignedLease && (
        <BaselaneButton
          leftIcon={<Icon16PlusCircle />}
          variant="transparent"
          palette="primary"
          size="md"
          onClick={() => {
            sendSegmentEvent('lease_agreement_fe_rc_setup_started', { entry_point: entryPoint });
            navigate(UNIFIED_RENT_COLLECTION);
          }}
        >
          Set up new rent collection
        </BaselaneButton>
      )}
    </Stack>
  );
}

export default LeaseAgreementNextStep;
