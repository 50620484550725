import customTheme from '@core/theme';

export const baseStyles = (type) => {
  const styles = { mt: 0.75 };
  if (type === 'assistive') styles.color = `${customTheme.colors.brand.neutral[600]}`;
  if (type === 'warning') styles.color = `${customTheme.colors.yellow['800AA']}`;
  if (type === 'success') styles.color = `${customTheme.colors.green['800AA']}`;
  if (type === 'error') styles.color = `${customTheme.colors.red['800AA']}`;
  return styles;
};

export const iconStyles = {
  verticalAlign: 'middle',
  marginRight: '0.5',
  display: 'inline-block',
};

export const textStyles = {
  verticalAlign: 'middle',
  textStyle: 'xs',
};
