/**
 * Initializes and provides the API for the Zendesk Messenger UI
 */
import React, { PropsWithChildren } from 'react';
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import { fingerPrintAPIKey, fingerPrintAPIEndpoint } from '@core/constants/envVars';

type FingerprintWrapperProps = {
  userId?: String,
};

const FingerprintWrapper = ({
  userId = null,
  children,
}: PropsWithChildren<FingerprintWrapperProps>) => {
  const fingerPrintOptions = {
    apiKey: fingerPrintAPIKey,
    endpoint: fingerPrintAPIEndpoint,
    scriptUrlPattern: `${fingerPrintAPIEndpoint}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
  };
  return <FpjsProvider loadOptions={fingerPrintOptions}>{children}</FpjsProvider>;
};

export default FingerprintWrapper;
