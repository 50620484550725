import React, { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import {
  CREATE_AUTOTAG_RULE,
  UPDATE_AUTOTAG_RULE,
  GET_TRANSACTIONS_SUMMARY,
} from '@core/apollo/queries';
import { GET_AUTOTAG_RULES } from '@core/pages/AutoTaggingRulesPage/queries/autotag-queries';
import Drawer from './Drawer';
import { buildAutoTagRuleInput } from './utils';

const initialValues = {
  text: '',
  textValues: [],
  accountId: '',
  cardId: '',
  accountCard: 'ACCOUNT',
  amountValue: '',
  amountSecondaryValue: '',
  moneyType: 'money-in',
  categoryId: '',
  propertyId: '',
  textOperator: { id: '1', name: 'contains', operator: 'PARTIAL' },
  matchType: { id: '1', name: 'ANY of the values', operator: 'OR' },
  amountOperator: { id: '1', name: 'equals', operator: 'EQUALS' },
  updateExistingTransactions: false,
  isDescriptionOpen: false,
  isAmountOpen: false,
  isAccountCardOpen: false,
  isCategoryOpen: false,
  isPropertyOpen: false,
  isPreviewTransactionsModalOpen: false,
};

const CreateAutoTagRuleDrawer = () => {
  const navigate = useNavigate();
  const formikRef = useRef(null);

  const params = useParams();
  const isEditDrawer = params?.ruleId;

  // creating or updating a rule needs to also refetch transaction totals
  const [createAutoTagRule, { loading: isCreating }] = useMutation(CREATE_AUTOTAG_RULE, {
    refetchQueries: [GET_AUTOTAG_RULES, GET_TRANSACTIONS_SUMMARY],
  });
  const [updateAutoTagRule, { loading: isUpdating }] = useMutation(UPDATE_AUTOTAG_RULE, {
    refetchQueries: [GET_AUTOTAG_RULES, GET_TRANSACTIONS_SUMMARY],
  });

  const handleCreateAutoTagRule = () => {
    const input = buildAutoTagRuleInput(formikRef?.current?.values);

    handleMutation(
      isEditDrawer ? updateAutoTagRule : createAutoTagRule,
      {
        input: isEditDrawer ? { id: params?.ruleId, ...input.input } : input.input,
      },
      {
        createAutoTagRuleSuccessful: !isEditDrawer,
        editAutoTagRuleSuccessful: isEditDrawer,
      }
    );
  };

  const handleMutation = (mutationFn, variables, navState) => {
    mutationFn({ variables })
      .then((res) => {
        if (res.data) {
          navigate(
            { pathname: '..' },
            {
              replace: true,
              state: navState,
            }
          );
        }
        if (res.errors) {
          navigate(
            { pathname: '..' },
            {
              replace: true,
              state: { errorOccurred: true },
            }
          );
        }
      })
      .catch((err) => console.log('err', err));
  };

  return (
    <Formik initialValues={initialValues} innerRef={formikRef} onSubmit={handleCreateAutoTagRule}>
      <Drawer
        loading={isCreating || isUpdating}
        isEditDrawer={isEditDrawer}
        ruleId={params?.ruleId}
      />
    </Formik>
  );
};

export default CreateAutoTagRuleDrawer;
