import process from 'process';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  defaultDataIdFromObject,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import clearLocationState from '@core/utils/clearLocationState';
import previousPageStorage from '@core/storage/previousPageStorage';
import { manageStorageAfterLogout } from '@core/storage/helpers/cleanup.helpers';
import getSearchParams from '@core/utils/sendSegmentEvent/helpers/path.helpers';
import inActivityLogoutStorage from '@core/storage/inActivityLogoutStorage';
import {
  LOGIN,
  SESSION_EXPIRED,
  HOME,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SIGN_UP,
  VERIFIED,
  UNVERIFIED,
} from '@routes';
import { initializeDatadogLogs } from '@core/datadog/datadogConfig';

export const isPublicPage = (location, publicPages) => {};

initializeDatadogLogs();

const URI = process.env.REACT_APP_ORCHESTRATION_URL;

/**
 * Creates the auth link
 * Adds the headers and credentials to the request
 */
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
    credentials: 'include',
  };
});

const defaultOptions = {
  watchQuery: {
    errorPolicy: 'all',
  },
  query: {
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

export const noSessionCookieSignature = {
  result: {
    messageCode: 'UNAUTHORIZED_ACCESS',
  },
  statusCode: 401,
};

/**
 * Creates the error link
 * Handles request errors
 */

// eslint-disable-next-line consistent-return
const authErrorLink = onError(({ graphQLErrors, networkError }) => {
  // this is the signature that is used to identify
  // the state of a missing session cookie
  // it can be made more specific or general as needed

  const { pathname, search } = window.location;
  // Redirects users that do not have access to the current workspace
  const accessForbidden =
    networkError?.statusCode === 403 &&
    networkError?.result?.messageCode === 'WORKSPACE_ACCESS_FORBIDDEN';
  if (accessForbidden) {
    if (pathname.toString() !== '/access-denied') window.location.href = '/access-denied';
  }

  const noSession =
    networkError?.result?.messageCode === noSessionCookieSignature.result.messageCode &&
    networkError?.statusCode === noSessionCookieSignature.statusCode;

  if (noSession) {
    if (
      !pathname.includes(LOGIN) &&
      !pathname.includes(FORGOT_PASSWORD) &&
      !pathname.includes(RESET_PASSWORD) &&
      !pathname.includes(SIGN_UP) &&
      !pathname.includes(VERIFIED) &&
      !pathname.includes(UNVERIFIED) &&
      // url variable triggers
      !search.includes('mode=verifyEmail') &&
      !search.includes('mode=resetPassword') &&
      !search.includes('activateaccount')
    ) {
      // save last location for when user logs back in
      const searchParams = getSearchParams(true);
      previousPageStorage.write(window.location.pathname + searchParams);
      // delete any data that was stored in location, just in case
      clearLocationState();

      // set inActivityLogoutStorage to 'true'
      inActivityLogoutStorage.write();

      // delete local storage (other than specificically flagged items)
      manageStorageAfterLogout();

      // redirect to login
      window.location.href = pathname.toString() === HOME ? LOGIN : `${LOGIN}/${SESSION_EXPIRED}`;
    }
  }
  if (graphQLErrors) {
    graphQLErrors.map(({ extensions, message, locations, path }) => {
      const error = new Error(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      );
      if (
        extensions?.code === 'WORKSPACE_ACCESS_FORBIDDEN' ||
        extensions?.code === 'WORKSPACE_SESSION_EXPIRED'
      ) {
        console.error(error);
        window.location.href = '/access-denied';
      }

      return console.error(error);
    });
  }

  if (networkError) {
    console.error(new Error(`[Network error]: ${networkError}`));
  }
});

/**
 * Removes the __typename from the variables
 */

const removeTypenameLink = removeTypenameFromVariables();

/**
 * Creates the http link
 */
const httpLink = createHttpLink({
  uri: URI,
  credentials: 'include',
});

/**
 * Note: These are the global cache rules
 */
export const inMemoryCache = new InMemoryCache({
  // for migration it is possible for ids of fromTransferAccount and toTransferAccount to be the same
  typePolicies: {
    TransferAccount: { keyFields: ['id', 'accountNumber'] },
    LandlordTenants: { merge: true },
    Query: {
      // Query-specific rules
      fields: {
        leases: {
          keyArgs: ['input', ['state', 'tenantProfileId']],
          merge(existing, incoming) {
            if (!existing) return incoming;
            if (!incoming) return existing;
            const { leases, ...rest } = incoming;
            const result = rest;
            const nonDuplicateLeases = leases.filter(
              (lease) =>
                // eslint-disable-next-line no-underscore-dangle
                !existing.leases.some((existingLease) => existingLease.__ref === lease.__ref)
            );
            result.leases = [...existing.leases, ...nonDuplicateLeases];
            return result;
          },
        },
        transactions: {
          keyArgs: ['input', ['filter', 'sort', 'page', 'pageLimit']],
        },
        cardSummary: {
          keyArgs: ['input', ['filter']],
        },
        cashFlow: {
          keyArgs: ['input', ['filter']],
        },
        getTenantScreenings: {
          keyArgs: ['input', ['applicationLinkActive']],
        },
      },
    },
  },
  dataIdFromObject(responseObject) {
    // eslint-disable-next-line no-underscore-dangle
    switch (responseObject.__typename) {
      case 'ScheduledPayments':
        return `ScheduledPayment:${responseObject.transferId}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
});

const apolloClient = new ApolloClient({
  link: from([authLink, authErrorLink, removeTypenameLink, httpLink]),
  cache: inMemoryCache,
  defaultOptions,
  credentials: 'include',
});

export default apolloClient;
