import React from 'react';
import { Text, Flex, Box } from '@chakra-ui/react';
import { Icon16Check } from '@icons/16px';

const ApplicationsCell = ({ application }: { application: Object }) => {
  const { applications, noOfSubmittedApplications } = application;

  const getApplicationStatus = () => {
    let approvedStatusCount = 0;
    let readyForReviewStatusCount = 0;
    let text = '';
    applications.forEach((item) => {
      if (
        item?.applicationStatus === 'APPROVED' ||
        item?.applicationStatus === 'APPROVED_CONDITIONALLY'
      ) {
        approvedStatusCount += 1;
      }
      if (item?.applicationStatus === 'PENDING_DECISION') {
        readyForReviewStatusCount += 1;
      }
    });
    if (approvedStatusCount > 0) {
      text = `${approvedStatusCount} approved`;
    }
    if (readyForReviewStatusCount > 0 && approvedStatusCount === 0) {
      text = `${readyForReviewStatusCount} ready for review`;
    }
    if (readyForReviewStatusCount === 0 && approvedStatusCount === 0) {
      text = `${readyForReviewStatusCount} ready for review`;
    }
    return { text, approvedStatusCount, readyForReviewStatusCount };
  };
  const getNumOfApplications = () => {
    let text = '';
    if (applications?.length > 0) {
      if (applications?.length === getApplicationStatus().readyForReviewStatusCount) {
        text = `${applications?.length} ready for review`;
      } else {
        text = `${applications?.length} applications`;
      }
    }
    if (applications?.length === 0) {
      text = `${noOfSubmittedApplications} applications`;
    }
    if (
      applications?.length === 1 &&
      getApplicationStatus().readyForReviewStatusCount !== applications?.length
    ) {
      text = `${applications?.length} application`;
    }
    return text;
  };
  return (
    <Flex direction="column" gap={0} alignItems="flex-end">
      {applications?.length > 0 && (
        <Text
          textStyle="sm"
          color={
            getApplicationStatus().approvedStatusCount > 0 ||
            getApplicationStatus().readyForReviewStatusCount > 0
              ? 'brand.neutral.900'
              : 'brand.neutral.500'
          }
        >
          {getApplicationStatus().approvedStatusCount > 0 && (
            <Box
              as="span"
              display="inline-block"
              marginRight={1}
              verticalAlign="middle"
              lineHeight="20px"
            >
              <Icon16Check />
            </Box>
          )}
          <Box as="span" display="inline-block" verticalAlign="middle" lineHeight="20px">
            {getApplicationStatus().text}
          </Box>
        </Text>
      )}
      <Text textStyle="xs" color="brand.neutral.500">
        {getNumOfApplications()}
      </Text>
    </Flex>
  );
};

export default ApplicationsCell;
