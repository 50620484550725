// @flow
import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { FormControl, HStack, Text, Spacer, Stack } from '@chakra-ui/react';
import { BaselaneFormErrorMessage, BaselaneInput, BaselaneFormLabel } from '@shared/components';
import { invitationSteps } from '../../helpers/workspace.helpers';

type InviteCollaboratorDetailsProps = { from: String };

function InviteCollaboratorDetails({ from }: InviteCollaboratorDetailsProps): any {
  const formik = useFormikContext();
  const { values, setFieldTouched, handleChange, handleBlur, errors, touched } = formik;
  const [currentStep] = useState(invitationSteps.COLLABORATOR_DETAILS);
  const totalSteps = 2;

  return (
    <>
      <HStack>
        <Text textStyle="headline-md" color="brand.neutral.900">
          Collaborator detail
        </Text>
        <Spacer />
        <Text textStyle="xs" color="brand.neutral.600">
          {`${currentStep} of ${totalSteps}`}
        </Text>
      </HStack>

      <Stack>
        <FormControl id="invite-collaborator" isInvalid={errors.email && touched.email} isRequired>
          <BaselaneFormLabel>Collaborator email</BaselaneFormLabel>
          <BaselaneInput
            size="lg"
            id="email"
            name="email"
            value={values?.email}
            type="text"
            onChange={(e) => {
              handleChange(e);
            }}
            onBlur={(e) => {
              setFieldTouched('email');
              handleBlur(e);
            }}
            maxLength={50}
          />
          <BaselaneFormErrorMessage isInvalid={errors.email && touched.email}>
            {errors.email}
          </BaselaneFormErrorMessage>
        </FormControl>
      </Stack>
    </>
  );
}

export default InviteCollaboratorDetails;
