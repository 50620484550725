import React, { useEffect, useState } from 'react';
import { Heading, Text } from '@chakra-ui/react';
import { BaselaneCardNew } from '@shared/components';
import { getApplicantDisplayName } from '../../helpers/tenantScreeningDetail.helpers';

type ApplicationDetailsProps = {
  applicants: Array,
  applicant: Object,
  propertyName: String,
  unitName: String,
};

const ApplicationDetails = ({
  applicants,
  applicant,
  propertyName,
  unitName,
}: ApplicationDetailsProps) => {
  const [applicantDisplayName, setApplicantDisplayName] = useState();

  // generate display name
  useEffect(() => {
    setApplicantDisplayName(getApplicantDisplayName(applicants, applicant));
  }, [applicants, applicant]);

  return (
    <BaselaneCardNew w="full" flexDirection="column" alignItems="flex-start">
      <Heading as="h3" size="headline-sm">
        {applicantDisplayName}
      </Heading>
      <Text textStyle="sm">
        {propertyName}
        {!!unitName && propertyName !== unitName && ` · ${unitName}`}
      </Text>
    </BaselaneCardNew>
  );
};

export default ApplicationDetails;
