// @flow
import React from 'react';
import { TabList, Tab, Text, Box, HStack } from '@chakra-ui/react';
import IconCheckCircleOutline from '@icons/manual/IconCheckCircleOutline';
import {
  drawerMenuListItemContainerStyle,
  drawerMenuListItemContainerStyleColors,
  tabListStyles,
  checkFormCircleStyles,
  tabItemStyles,
} from '../styles/drawer.style';

function DrawerList({ detailsComplete }: { detailsComplete: boolean }): any {
  return (
    <TabList {...tabListStyles}>
      <Box id="edit-property-details-tab" {...drawerMenuListItemContainerStyle}>
        <Tab {...tabItemStyles} {...drawerMenuListItemContainerStyleColors(detailsComplete)}>
          <HStack w="100%" spacing="10px">
            {detailsComplete ? (
              <Box w="20px" h="20px">
                <IconCheckCircleOutline />
              </Box>
            ) : (
              <Box {...checkFormCircleStyles()} bg="brand.blue.100">
                1
              </Box>
            )}
            <Text>Property Details</Text>
          </HStack>
        </Tab>
      </Box>
      <Box id="edit-property-financial-tab" {...drawerMenuListItemContainerStyle}>
        <Tab {...tabItemStyles} {...drawerMenuListItemContainerStyleColors()}>
          <HStack w="100%" spacing="10px">
            <Box {...checkFormCircleStyles()} bg="brand.blue.100">
              2
            </Box>
            <Text>Financial Information (Optional)</Text>
          </HStack>
        </Tab>
      </Box>
      {/* <Tab {...drawerMenuListItemContainerStyle}>Documents</Tab> */}
    </TabList>
  );
}

export default DrawerList;
