import React, { useRef } from 'react';
import { Box, FormControl, FormErrorMessage, Select, Stack, Text } from '@chakra-ui/react';
import { AddAccountManuallyDrawer, BaselaneButton } from '@shared/components';
import { IconWarningCircleOutline } from '@icons';
import { Icon16PlusCircle } from '@icons/16px';
import { formErrorStyles, formInputStyles } from '@shared/styles/input.style';
import ReceivingBankAccountEmptyState from '../../../../../LeaseSection/forms/ReceivingBankAccountForm/ReceivingBankAccountEmptyState';
import { BA_ERROR_ENUMS } from '../../../../../helpers/sharedBAErrors.helpers';
import { receivingBankAccountStyles } from './styles/receivingBankAccount.styles';

type ReceivingBankAccountProps = {
  filteredAccounts: Array<Object>,
  setHasConnectedBank: Function,
  showReceivingBAEmptyState: boolean,
  setShowReceivingBAEmptyState: Function,
  setHideSaveChangesBtn: Function,
  newBankAccountId: String,
  setNewBankAccountId: Function,
  selectedBankAccountError: String,
  setSelectedBankAccountError: Function,
  refetchBankAccounts: Function,
  failedBankAccountId: String,
};

function ReceivingBankAccount({
  filteredAccounts,
  setHasConnectedBank,
  showReceivingBAEmptyState,
  setShowReceivingBAEmptyState,
  setHideSaveChangesBtn,
  newBankAccountId,
  setNewBankAccountId,
  selectedBankAccountError,
  setSelectedBankAccountError,
  refetchBankAccounts,
  failedBankAccountId,
}: ReceivingBankAccountProps) {
  const addAccountManuallyDrawerRef = useRef();

  const handleLinkedAccountsDrawerOnClose = () => {
    setShowReceivingBAEmptyState(false);
    setHideSaveChangesBtn(false);
  };

  const handleAddAccountManuallySuccess = () => {
    refetchBankAccounts();
    setShowReceivingBAEmptyState(false);
    setHideSaveChangesBtn(false);
  };

  const handleBankAccountChange = (baID) => {
    setNewBankAccountId(baID);
    const selectedAccData = filteredAccounts.find((ba) => ba.id === baID);
    const { bankAccountMetadata } = selectedAccData ?? {};
    const { lastPayoutFailed, payoutFailedReason } = bankAccountMetadata ?? {};
    setSelectedBankAccountError(
      lastPayoutFailed ? BA_ERROR_ENUMS[payoutFailedReason?.failureCode] : null
    );
  };

  const { title } = receivingBankAccountStyles ?? {};

  return showReceivingBAEmptyState ? (
    <>
      <ReceivingBankAccountEmptyState
        {...{
          addAccountManuallyDrawerRef,
          setHasConnectedBank,
          setShowReceivingBAEmptyState,
          refetchBankAccounts,
          failedBankAccountId,
          isFromUpdateAccount: true,
        }}
        linkedAccountsDrawerOnClose={handleLinkedAccountsDrawerOnClose}
      />
      <AddAccountManuallyDrawer
        {...{
          addAccountManuallyDrawerRef,
          handleAddAccountManuallySuccess,
          from: { section: 'rent_collection' },
        }}
      />
    </>
  ) : (
    <Stack gap="8px" m="0 !important">
      <Text {...title}>
        Select another account for the invoices and rent collections linked to this account
      </Text>

      <FormControl isInvalid={selectedBankAccountError} m="0 !important">
        <Select
          {...formInputStyles}
          value={newBankAccountId}
          onChange={(e) => {
            handleBankAccountChange(e.target.value);
          }}
        >
          <option key="dummy" value="" defaultValue={newBankAccountId}>
            Select another account
          </option>
          {filteredAccounts?.map((a) => (
            <option key={a.id} value={a.id} defaultValue={newBankAccountId}>
              {a.nickName || `${a.name} ${a.number}`}
            </option>
          ))}
        </Select>

        {selectedBankAccountError && (
          <FormErrorMessage {...formErrorStyles} ml="0 !important" gap="8px">
            <IconWarningCircleOutline color="#C93A3A" />
            <Text>{selectedBankAccountError}</Text>
          </FormErrorMessage>
        )}
      </FormControl>

      <Box>
        <BaselaneButton
          variant="transparent"
          palette="primary"
          size="md"
          pullLeft
          leftIcon={<Icon16PlusCircle />}
          onClick={() => {
            setShowReceivingBAEmptyState(true);
            setHideSaveChangesBtn(true);
          }}
        >
          Add another bank account
        </BaselaneButton>
      </Box>
    </Stack>
  );
}

export default ReceivingBankAccount;
