import { gql } from '@apollo/client';

export const GET_TAX_FORMS = gql`
  query tax {
    taxForms {
      id
      bankId
      taxYear
      taxFormType
      externalFormId
      entityName
    }
  }
`;
