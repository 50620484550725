import { gql } from '@apollo/client';

export const SEND_OTP_REGISTERED_USER = gql`
  mutation SendOtpRegisteredUser($channel: String!, $operation: String) {
    sendOtpRegisteredUser(channel: $channel, operation: $operation) {
      sendCodeAttempts
      status
    }
  }
`;

export const VERIFY_OTP_REGISTERED_USER = gql`
  mutation VerifyOtpRegisteredUser($channel: String!, $code: String!, $operation: String) {
    verifyOtpRegisteredUser(channel: $channel, code: $code, operation: $operation)
  }
`;
