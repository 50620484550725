import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { SEND_OTP_REGISTERED_USER, VERIFY_OTP_REGISTERED_USER } from '@core/apollo/queries';
import { otpOperations } from '@shared/components/TwoFactorVerificationPopUp/helpers/constant';
import { SEND_OTP, VERIFY_OTP } from '../queries';

function useTwoFactor(onBoardingFlow = false, inviteCollaborator = false) {
  const [isGetOTPSuccessful, setIsGetOTPSuccessful] = useState(false);
  const [hasOTPCodeResent, setHasOTPCodeResent] = useState(false);
  const [OTPErrorCode, setOTPErrorCode] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const [suppressResendSuccess, setSuppressResendSuccess] = useState(false);
  const [APIVerification, setAPIVerification] = useState(false);
  const [isGetOTPLoading, setIsGetOTPLoading] = useState(false);

  // for the onboarding flow, the query also checks unique phone number
  // for other OTP (no phone check) it just does the OTP code texting
  const [sendOtp] = useMutation(onBoardingFlow ? SEND_OTP : SEND_OTP_REGISTERED_USER);
  const [verifyOtp] = useMutation(onBoardingFlow ? VERIFY_OTP : VERIFY_OTP_REGISTERED_USER);

  // two-factor popup states
  const {
    isOpen: isOTPPopupOpen,
    onOpen: onOTPPopupOpen,
    onClose: onOTPPopupClose,
  } = useDisclosure();

  useEffect(() => {
    if (hasOTPCodeResent) {
      if (!suppressResendSuccess) {
        setIsGetOTPSuccessful(true);
      }

      setTimeout(() => {
        setIsGetOTPSuccessful(false);
        setHasOTPCodeResent(false);
      }, 2500);
    }
  }, [hasOTPCodeResent]);

  // twilio otp call
  const handleSendText = (
    recipient,
    isResendingCode,
    handleSendTextSuccess = () => {},
    handleSendTextFail = () => {},
    showResendSuccess = false
  ) => {
    setSuppressResendSuccess(showResendSuccess);
    setOTPErrorCode(false);
    sendOtp({
      variables: onBoardingFlow
        ? {
            recipient,
          }
        : {
            channel: 'sms',
            ...(inviteCollaborator && { operation: otpOperations.INVITE_COLLABORATOR }),
          },
    })
      .then(({ data, errors }) => {
        // TODO: This handling of error codes should be refactored to encompass all possibilities of where this is used
        if (errors) {
          const { messageCode: code = 0 } = errors[0]?.extensions?.exception?.response || {};
          const errorCodes = [
            'VOIP_NUMBER',
            'NON_US_NUMBER',
            'DUPLICATE_PHONE_NUMBER',
            'TOO_MANY_OTP_REQUESTS',
          ];
          if (errorCodes.includes(code)) {
            if (code === 'TOO_MANY_OTP_REQUESTS') {
              handleSendTextFail(errors[0]?.extensions?.exception?.response?.message);
              setOTPErrorCode(501);
              return;
            }
            handleSendTextFail(errors[0]?.extensions?.exception?.response?.message);
          } else {
            handleSendTextFail('Error on phone confirmation. Please try again later');
          }

          if (errors[0]?.extensions?.code === 'INTERNAL_SERVER_ERROR') {
            if (errors[0]?.message?.includes('Too many OTP requests')) {
              handleSendTextFail('Error on phone confirmation. Please try again later');
              setOTPErrorCode(501);
              return;
            }
          }
          setOTPErrorCode(400);

          return;
        }

        handleSendTextSuccess();

        if (
          (data?.sendOtp?.status && isResendingCode) ||
          (data?.sendOtpRegisteredUser?.status && isResendingCode)
        ) {
          setHasOTPCodeResent(true);

          if (onBoardingFlow) {
            sendSegmentEvent('onboarding_phonenumber_click_resend');
          }
        }
      })
      .catch((error) => {
        console.error(error);
        setOTPErrorCode(500);
      });
  };

  // twilio otp call
  const handleVerifyOtp = ({ recipient, code }, handleVerifyOtpSuccess = () => {}) => {
    if (onBoardingFlow) {
      sendSegmentEvent('onboarding_phonenumber_click_verify');
    }

    verifyOtp(
      onBoardingFlow
        ? { variables: { recipient, code, operation: otpOperations.REGISTER_PHONE_NUMBER } }
        : {
            variables: {
              channel: 'sms',
              code,
              ...(inviteCollaborator && { operation: otpOperations.INVITE_COLLABORATOR }),
            },
          }
    )
      .then(({ data, errors }) => {
        if (errors) {
          setOTPErrorCode(500);
          return;
        }

        if (data?.verifyOtp === 'true' || data?.verifyOtpRegisteredUser === 'true') {
          handleVerifyOtpSuccess();
        } else {
          setOTPErrorCode(100);
        }
      })
      .catch((error) => {
        console.log(error);
        setOTPErrorCode(500);
      });
  };

  const states = {
    isOTPPopupOpen,
    isGetOTPSuccessful,
    hasOTPCodeResent,
    OTPErrorCode,
    isVerifyLoading,
    APIVerification,
    isGetOTPLoading,
  };

  const stateFunctions = {
    onOTPPopupOpen,
    onOTPPopupClose,
    setIsGetOTPSuccessful,
    setHasOTPCodeResent,
    setOTPErrorCode,
    setIsVerifyLoading,
    handleSendText,
    handleVerifyOtp,
    setAPIVerification,
    setIsGetOTPLoading,
  };

  return {
    states,
    stateFunctions,
  };
}

export default useTwoFactor;
