import React from 'react';

function IconProfileInProgress() {
  return (
    <svg width="31" height="39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="23.208" cy="28.779" rx="7.792" ry="7.994" fill="#8CCFFF" />
      <path
        d="M27.593 22.084a7.911 7.911 0 0 0-3.947-2.944 7.673 7.673 0 0 0-4.872 0 7.792 7.792 0 0 0-3.914 2.944 8.104 8.104 0 0 0-1.445 4.749m14.178-4.75.077-2.937m-.077 2.938-2.959-.388M14.642 31.129a8.049 8.049 0 0 0 4.007 2.915c1.6.52 3.312.51 4.89-.026a7.709 7.709 0 0 0 3.883-2.959 7.99 7.99 0 0 0 1.377-4.747M14.642 31.13l2.922.333m-2.922-.333.694 3.34"
        stroke="#000619"
        strokeLinecap="round"
      />
      <path
        d="M24.064 15.758c.204-.888.313-1.814.313-2.767 0-3.18-1.232-6.23-3.424-8.479C18.761 2.263 15.788 1 12.688 1 6.233 1 1 6.369 1 12.991c0 5.614 3.76 10.327 8.838 11.632"
        stroke="#000619"
        strokeLinecap="round"
      />
      <path
        d="M12.688 4.598c1.936 0 3.506 1.61 3.506 3.597 0 1.987-1.57 3.597-3.506 3.597-1.937 0-3.507-1.61-3.507-3.597 0-1.987 1.57-3.597 3.507-3.597Z"
        stroke="#000619"
      />
      <path
        d="m19.34 16.767-.27-.564c-.588-1.23-1.807-2.012-3.144-2.014H9.451c-1.356.002-2.59.805-3.168 2.063l-.245.527c-.184.386-.153.843.082 1.2a8.204 8.204 0 0 0 3.69 2.852"
        stroke="#000619"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default IconProfileInProgress;
