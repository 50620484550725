import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import userContext from '@contexts/UserContext';
import { EmailOtpProvider } from '@core/contexts/EmailOtpContext';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { NB_ACCOUNTS, TRANSFERS_PAYMENTS } from '@routes';
import { Icon16ArrowForward } from '@icons/16px';
import historyStateStorage from '@core/storage/historyStateStorage';
import HeaderNavWrapper from '@shared/layouts/HeaderNavWrapper';
import { useCurrentWorkspace } from '@shared/hooks';
import {
  handleFinishLater,
  handleUserUpdate,
} from '@core/components/OnboardingTriage/helpers/onboardingFunctions.helpers';
import { BaselaneButton } from '@shared/components';
import { UPDATE_USER } from '@core/components/UserProfilePage/queries';
import HeaderContent from '@pages/UnifiedLandlordBanking/HeaderContent';

import SuccessUI from './SuccessUI';
import SuccessUIB from './SuccessUIB';

type SuccessFlowProps = {
  status: string,
  banksListData: Array<object>,
  setIsUserPolling: Function,
  bankData: Array<object>,
  baselaneBankBalance: number,
};

const SuccessFlow = ({
  status,
  banksListData,
  setIsUserPolling,
  bankData,
  baselaneBankBalance,
}: SuccessFlowProps) => {
  const { isMinXL, isMax576: showMobileUI } = useBreakPoints();

  const {
    user,
    refetchUser,
    user: { userPromotion },
    startUserPolling,
    stopUserPolling,
  } = useContext(userContext);
  const { workspaceMetadata } = useCurrentWorkspace();
  const { bankMigrationConsent, bankMigrationState } = workspaceMetadata;

  const isThreadUser =
    bankMigrationConsent === null ||
    bankMigrationConsent === undefined ||
    bankMigrationState === 'COMPLETED';

  const navigate = useNavigate();

  const [showDAPYBanner, setShowDAPYBanner] = useState(false);

  const [updateUser] = useMutation(UPDATE_USER);

  const dapyPromotion = (userPromotion ?? [])?.find((p) => p.promotion.type === 'DAPY');
  const userPromoApy = dapyPromotion?.isExecuted && dapyPromotion?.promotion?.dapyProduct?.apyValue;

  // if user opened lb flow from another flow from state will be an object
  const historyStateData = historyStateStorage.read('baselane-state');

  const fromStateFlow =
    typeof historyStateData?.from === 'string'
      ? historyStateData?.from
      : historyStateData?.from?.flow;
  const isFromRC = fromStateFlow === '/unified_rent_collection' || fromStateFlow === '/leases';

  // Also, note that if we are coming from Unified Rent Collection or the Leases page,
  // we show the old SuccessUI component
  const successFlow = status === 'COMPLETED' && !isFromRC;

  useEffect(() => {
    // on load of this success state, flag the user as having completed onboarding
    if (successFlow && !user?.onboardingCompleted) {
      handleUserUpdate({
        user,
        refetchUser,
        updateUser,
        isOnboardingCompleted: true,
      });
    }
  }, [successFlow, user?.onboardingCompleted]);

  useEffect(() => {
    const allCompletedBaselaneAccounts = banksListData?.bankSummary?.bank
      .map((bankItem) => ({
        bankName: bankItem.name,
        isBankConnected: bankItem.isConnected,
        isBankExternal: bankItem.isExternal,
        ...bankItem,
      }))
      .flat()
      .filter((bankItem) => bankItem?.unitAccount?.unitApplicationStatus === 'COMPLETED');

    setShowDAPYBanner(
      allCompletedBaselaneAccounts?.length === 1 &&
        dapyPromotion?.isExecuted &&
        isThreadUser &&
        !successFlow
    );
  }, [banksListData?.bankSummary?.bank, dapyPromotion?.isExecuted]);

  useEffect(() => {
    if (!dapyPromotion?.isExecuted || dapyPromotion?.isExecuted === false) {
      startUserPolling(5000);

      setTimeout(() => {
        setIsUserPolling(false);
        stopUserPolling();
      }, 5000);
    }
  }, []);

  // only continue polling for user query if baselane bank length is greater than one because we only need to show banner
  // for first baselane bank created
  useEffect(() => {
    if (dapyPromotion?.isExecuted || banksListData?.bankSummary?.bank?.length > 2) {
      setIsUserPolling(false);
      stopUserPolling();
    }
  }, [dapyPromotion?.isExecuted, stopUserPolling, banksListData?.bankSummary?.bank]);

  const handleRouteChange = (btnPath) => {
    historyStateStorage.write({ from: fromStateFlow });
    navigate(btnPath);
  };

  const onButtonClick = ({ route = NB_ACCOUNTS }) => {
    handleFinishLater({
      user,
      refetchUser,
      updateUser,
      isOnboardingCompleted: true,
      handleRouteChange: () => handleRouteChange(route),
    }).then(() => {
      // Force a refresh when navigating back to banking page so it can grab fresh data
      // This should be reworked so that the cache is being updated on application created
      if (route) navigate(0); // TODO temporary
    });
  };
  const getAPYContinueButton = () => {
    return (
      showDAPYBanner &&
      !isFromRC && (
        <BaselaneButton
          variant="transparent"
          palette="neutral"
          onClick={onButtonClick}
          size="md"
          segmentEventName="baselane_banking_approved_click_explore_banking"
          segmentEventData={{ promotion: dapyPromotion?.isExecuted }}
        >
          Explore Baselane Banking
        </BaselaneButton>
      )
    );
  };

  const getSkipToBaselaneButton = () => {
    let whereToLabel = 'Baselane Banking';
    let route = NB_ACCOUNTS;

    if (fromStateFlow === `/${TRANSFERS_PAYMENTS}`) {
      whereToLabel = 'Transfers & Payments';
      route = `/${TRANSFERS_PAYMENTS}`;
    }

    return (
      <BaselaneButton
        variant="outline"
        palette="neutral"
        onClick={() => onButtonClick({ route })}
        size="md"
        segmentEventName={`${
          user?.onboardingCompleted ? 'baselane' : 'onboarding'
        }_approval_version_${user?.onboardingCompleted ? null : 'b'}_click_cta`}
        segmentEventData={{ action: 'skip' }}
        rightIcon={<Icon16ArrowForward />}
      >
        Skip
        {`${!showMobileUI ? ` to ${whereToLabel}` : ''}`}
      </BaselaneButton>
    );
  };

  return (
    <HeaderNavWrapper
      hideBackButton
      hideFinishLater
      headerContent={
        <HeaderContent applicationStatus={status}>
          {successFlow ? getSkipToBaselaneButton() : getAPYContinueButton()}
        </HeaderContent>
      }
      // TODO: to be removed once flow is refactored to not use device detect
      isMobile={isMinXL}
    >
      {!successFlow ? (
        <SuccessUI
          bankData={bankData}
          baselaneBankBalance={baselaneBankBalance}
          showDAPYBanner={showDAPYBanner}
          isFromRC={isFromRC}
          userPromoApy={userPromoApy}
        />
      ) : (
        <EmailOtpProvider>
          <SuccessUIB />
        </EmailOtpProvider>
      )}
    </HeaderNavWrapper>
  );
};

export default SuccessFlow;
