import React from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import BaselaneMenu from '@shared/components/BaselaneMenu';
import BaselaneConditionalTooltip from '@shared/components/BaselaneConditionalTooltip';
import { Icon16Check, Icon16Warning, Icon16Close } from '@icons/16px';
import {
  TENANT_SCREENING_APPROVE,
  TENANT_SCREENING_DECLINE,
  TENANT_SCREENING_CONDITIONALLY_APPROVE,
} from '@routes';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

type MakeDecisionProps = {
  isDecisionPossible: Boolean,
  verificationId: String,
  tooltipText: String,
  screeningId: String,
};

const MakeDecision = ({
  isDecisionPossible,
  verificationId,
  tooltipText,
  screeningId,
}: MakeDecisionProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <BaselaneConditionalTooltip
      condition={!isDecisionPossible}
      tooltipText={tooltipText}
      childIsTarget
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      <BaselaneMenu
        isDisabled={!isDecisionPossible}
        label="Make decision"
        buttonSize="lg"
        listItems={[
          {
            name: 'Approve',
            onClick: () => {
              sendSegmentEvent('tenant_screening_fe_make_decision_clicked', {
                tenantScreeningId: screeningId,
                decision: 'Approve',
              });
              navigate(
                { pathname: `${pathname}/${TENANT_SCREENING_APPROVE}` },
                { state: { verificationId } }
              );
            },
            icon: (
              <Box color="green.800">
                <Icon16Check />
              </Box>
            ),
          },
          {
            name: 'Conditionally approve',
            onClick: () => {
              sendSegmentEvent('tenant_screening_fe_make_decision_clicked', {
                tenantScreeningId: screeningId,
                decision: 'Conditionally approve',
              });
              navigate(
                { pathname: `${pathname}/${TENANT_SCREENING_CONDITIONALLY_APPROVE}` },
                { state: { verificationId } }
              );
            },
            icon: (
              <Box color="yellow.800">
                <Icon16Warning />
              </Box>
            ),
            description: 'Select to learn more',
          },
          {
            name: 'Decline',
            onClick: () => {
              sendSegmentEvent('tenant_screening_fe_make_decision_clicked', {
                tenantScreeningId: screeningId,
                decision: 'Decline',
              });
              navigate(
                { pathname: `${pathname}/${TENANT_SCREENING_DECLINE}` },
                { state: { verificationId } }
              );
            },
            icon: (
              <Box color="red.800">
                <Icon16Close />
              </Box>
            ),
          },
        ]}
        styles={{ button: { ml: 'auto' } }}
      />
    </BaselaneConditionalTooltip>
  );
};

export default MakeDecision;
