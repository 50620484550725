// @flow
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Box, HStack, Stack, Spacer, Text, Tbody, VStack, useDisclosure } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { USER_WORKSPACE_INVITECOLLABORATOR } from '@routes';
import {
  BaselaneButton,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableHeader,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveCell,
  BaselaneSpinner,
  BaselaneChip,
  BaselaneToolpop,
  BaselaneLink,
} from '@shared/components';
import { useCurrentWorkspace, useUserAccessibleWorkspace } from '@shared/hooks';
import customTheme from '@core/theme';
import { Icon16Plus } from '@icons/16px';
import UserContext from '@contexts/UserContext';
import { createWorkspaceSegmentData } from '@core/utils/sendSegmentEvent';
import LeaveWorkspaceAlert from '../../components/LeaveWorkspaceAlert';
import AddProfileDetailsAlert from '../../components/VerifyWorkspaceOwner/AddProfileDetailsAlert';
import {
  membersListConfig,
  membersListTableHeaderItems,
  getMemberStatus,
  getMemberName,
} from '../../../helpers/workspace.helpers';

function TabMembers(): any {
  const {
    activeWorkspaceId,
    collaborators,
    isLoadingCurrentWorkspace,
    currentWorkspace,
    workspaceName,
    workspaceOwner,
  } = useCurrentWorkspace();

  const { accessibleWorkspaceData } = useUserAccessibleWorkspace();

  const activeWorkspace = accessibleWorkspaceData.find((w) => Number(w.id) === activeWorkspaceId);
  const isOwner = activeWorkspace && activeWorkspace.isOwner;

  const [sortedCollaborators, setSortedCollaborators] = useState([]);
  const {
    isOpen: isLeaveWorkspaceAlertOpen,
    onOpen: onLeaveWorkspaceAlertOpen,
    onClose: onLeaveWorkspaceAlertClose,
  } = useDisclosure();
  const {
    isOpen: isVerifyAlertOpen = true,
    onOpen: onVerifyAlertOnOpen,
    onClose: onVerifyAlertClose,
  } = useDisclosure();

  const ownerNote =
    'Note: As a workspace owner, only you can invite, remove, or edit permissions for your workspace members.';
  const collaboratorNote =
    ' Note: As a collaborator, you do not have member management permissions. Only the workspace owner can invite, remove, or edit permissions for other workspace members.';

  const navigate = useNavigate();
  const [isNavigating, setIsNavigating] = useState(false);
  const { isMax767 } = useBreakPoints();
  const { user } = useContext(UserContext);
  const verifiedUser =
    user?.emailVerified && user?.phoneNumberVerified && user?.firstName && user?.lastName;

  const handleInviteCollaborator = () => {
    if (!verifiedUser) {
      onVerifyAlertOnOpen();
    } else {
      navigate(USER_WORKSPACE_INVITECOLLABORATOR);
    }
  };
  const handleMember = (m) => {
    if (!isNavigating) {
      setIsNavigating(true);
      navigate(`${m.id}`);
      setTimeout(() => setIsNavigating(false), 1500); // Adjust based on typical navigation time
    }
  };

  const handleLeaveWorkspace = () => {
    onLeaveWorkspaceAlertOpen();
  };

  useEffect(() => {
    if (!isLoadingCurrentWorkspace && collaborators?.length) {
      const currentUserCollaborator = collaborators.find((c) => c.email === user?.email);

      // If the current user exists in collaborators, move them to the front
      if (currentUserCollaborator) {
        const updatedCollaborators = [
          currentUserCollaborator,
          ...collaborators.filter((c) => c.email !== user?.email), // All other collaborators except the current user
        ];

        setSortedCollaborators(updatedCollaborators);
      }
    }
  }, [isLoadingCurrentWorkspace, currentWorkspace]);

  const getRowBgColor = (member) => {
    let bgColor = '';
    if (member?.status === 'INVITE_EXPIRED') {
      bgColor = `${customTheme.colors.red[50]} !important`;
    }
    if (!isOwner) {
      bgColor = `transparent !important`;
    }
    return bgColor;
  };

  return (
    <>
      <Stack
        {...{ justifyContent: 'space-between', w: '100%', gap: 0, spacing: 0 }}
        mt={4}
        px={1.5}
      >
        {isOwner && !isLoadingCurrentWorkspace && !isMax767 && (
          <HStack {...{ ml: '1.5' }}>
            <HStack>
              <Text textStyle="headline-md">Your members</Text>
              <Box mt="7px">
                <BaselaneToolpop
                  trigger={
                    <BaselaneChip
                      label="Free Beta"
                      onDeleteClick={null}
                      size="sm"
                      variant="simple-primary"
                    />
                  }
                  placement="top"
                >
                  <>
                    <Text textStyle="xs">
                      Starting Q1 2025, you will be billed $5/month for each active collaborator in
                      your workspace.
                    </Text>
                    <BaselaneLink
                      size="sm"
                      variant="neutral"
                      target="_blank"
                      href="https://support.baselane.com/hc/en-us/articles/29873891217307-How-to-Manage-Multi-User-Access-in-Your-Baselane-Workspace"
                    >
                      Learn more
                    </BaselaneLink>
                  </>
                </BaselaneToolpop>
              </Box>
            </HStack>
            <Spacer />
            <BaselaneButton
              variant="filled"
              palette="primary"
              size="md"
              leftIcon={<Icon16Plus />}
              onClick={handleInviteCollaborator}
              segmentEventName="workspace_fe_invite_collaborator_started"
              segmentEventData={{
                ...createWorkspaceSegmentData(
                  workspaceOwner,
                  activeWorkspaceId,
                  workspaceName,
                  user
                ),
                email_verified: user?.emailVerified,
                phone_number_verified: user?.phoneNumberVerified,
                first_name: user?.firstName,
                last_name: user?.lastName,
              }}
            >
              Invite collaborator
            </BaselaneButton>
          </HStack>
        )}

        {/* For mobile */}
        {isOwner && !isLoadingCurrentWorkspace && isMax767 && (
          <Box {...{ ml: '1.5' }}>
            <Text textStyle="headline-md">Your members</Text>
            <HStack>
              <Box mt="7px">
                <BaselaneToolpop
                  trigger={
                    <BaselaneChip
                      label="Free Beta"
                      onDeleteClick={null}
                      size="sm"
                      variant="simple-primary"
                    />
                  }
                  placement="top"
                >
                  <>
                    <Text textStyle="xs">
                      Starting Q1 2025, you will be billed $5/month for each active collaborator in
                      your workspace.
                    </Text>
                    <BaselaneLink
                      size="sm"
                      variant="neutral"
                      target="_blank"
                      href="https://support.baselane.com/hc/en-us/articles/29873891217307-How-to-Manage-Multi-User-Access-in-Your-Baselane-Workspace"
                    >
                      Learn more
                    </BaselaneLink>
                  </>
                </BaselaneToolpop>
              </Box>
              <Spacer />
              <BaselaneButton
                variant="filled"
                palette="primary"
                size="md"
                leftIcon={<Icon16Plus />}
                onClick={handleInviteCollaborator}
              >
                Invite collaborator
              </BaselaneButton>{' '}
            </HStack>
          </Box>
        )}

        <Text textStyle="sm" {...{ mt: 2, mb: 4, ml: '1.5' }}>
          {isOwner ? ownerNote : collaboratorNote}
        </Text>
        {isLoadingCurrentWorkspace ? (
          <BaselaneSpinner />
        ) : (
          <BaselaneResponsiveTable id="member-list" config={membersListConfig}>
            <BaselaneResponsiveTableHeader
              items={membersListTableHeaderItems}
              chevronCellStyles={{ display: { sm: 'table-cell', base: 'none' } }}
              renderItem={(item, index) => {
                return (
                  <BaselaneResponsiveTableHeading key={item.key} index={index} title={item.label} />
                );
              }}
            />
            <Tbody>
              <BaselaneResponsiveTableRows
                customRow
                items={sortedCollaborators}
                renderItem={(member, index) => {
                  return (
                    <BaselaneResponsiveTableRow
                      onClick={() => (isOwner ? handleMember(member) : null)}
                      id={`table-row-${index}`}
                      chevronCellStyles={{
                        display: { sm: isOwner ? 'table-cell' : 'none', base: 'none' },
                      }}
                      contentContainerStyles={{
                        bgColor: getRowBgColor(member),
                      }}
                      indicatorColor={
                        member?.status === 'INVITE_EXPIRED'
                          ? customTheme.colors.red['500A']
                          : 'transparent'
                      }
                    >
                      <BaselaneResponsiveCell
                        configIndex={0}
                        styles={{
                          justifyContent: 'flex-start',
                        }}
                        {...{
                          h: '72px',
                          _hover: { cursor: isOwner ? 'pointer' : 'default' },
                        }}
                      >
                        <VStack gap="0" spacing={0} alignItems="flex-start">
                          <Text as="div" textStyle="headline-md" color="brand.neutral.900">
                            {getMemberName(member?.firstName, member?.lastName) ?? member.email}
                            {index === 0 && ` (You)`}
                          </Text>
                          <Text textStyle="sm" color="brand.neutral.600">
                            {getMemberName(member?.firstName, member?.lastName)
                              ? member.email
                              : null}
                          </Text>
                          {isMax767 && (
                            <Text textStyle="sm" mt="0.75" color="brand.neutral.900">
                              {member.isOwner ? 'Owner' : 'Collaborator'}
                            </Text>
                          )}
                        </VStack>
                      </BaselaneResponsiveCell>
                      {/* Role */}
                      <BaselaneResponsiveCell
                        configIndex={1}
                        color="brand.neutral.900"
                        {...{
                          _hover: { cursor: isOwner ? 'pointer' : 'default' },
                        }}
                      >
                        <Text textStyle="sm">{member.isOwner ? 'Owner' : 'Collaborator'}</Text>
                      </BaselaneResponsiveCell>

                      {/* Status */}
                      <BaselaneResponsiveCell
                        configIndex={2}
                        color="brand.neutral.900"
                        {...{
                          _hover: { cursor: isOwner ? 'pointer' : 'default' },
                        }}
                      >
                        <Text textStyle="sm">{getMemberStatus(member.status)}</Text>
                      </BaselaneResponsiveCell>
                    </BaselaneResponsiveTableRow>
                  );
                }}
              />
            </Tbody>
          </BaselaneResponsiveTable>
        )}

        {!isOwner && !isLoadingCurrentWorkspace && (
          <Box m="1.5" display="flex">
            <BaselaneButton
              variant="outline"
              palette="danger"
              size="md"
              onClick={() => handleLeaveWorkspace()}
            >
              Leave workspace
            </BaselaneButton>
          </Box>
        )}
      </Stack>

      <LeaveWorkspaceAlert
        isAlertOpen={isLeaveWorkspaceAlertOpen}
        onAlertClose={onLeaveWorkspaceAlertClose}
        onDelete={handleLeaveWorkspace}
        workspaceId={activeWorkspaceId}
      />

      {isVerifyAlertOpen && (
        <AddProfileDetailsAlert onClose={onVerifyAlertClose} isOpen={isVerifyAlertOpen} />
      )}
      <Outlet />
    </>
  );
}

export default TabMembers;
