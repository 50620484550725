import React, { useRef, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  ChakraProvider,
  Flex,
  FormControl,
  FormErrorMessage,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import useBreakPoints from '@core/hooks/useBreakPoints';
import UserContext from '@contexts/UserContext';
import { BaselaneButton, BaselaneInput, BaselaneFormLabel } from '@shared/components';
import habitatTheme from '@core/themeHabitat';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';
import { DASHBOARD } from '@routes';
import { UPDATE_USER } from '@core/components/UserProfilePage/queries';

function InfoPage(): any {
  const navigate = useNavigate();

  const { user, refetchUser } = useContext(UserContext);
  const { firstName, lastName, onboardingCompleted } = user ?? {};

  const [updateUser] = useMutation(UPDATE_USER);

  const toast = useToast();

  const showSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Profile info saved successfully. ',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Something went wrong. Please try again.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const formikRef = useRef();
  const initialValues = { firstName, lastName };

  const handleValidation = (values) => {
    const { firstName: firstNameSubmitted, lastName: lastNameSubmitted } = values;
    const errors = {};

    if (!firstNameSubmitted || firstNameSubmitted.trim() === '') {
      errors.firstName = 'Please enter first name';
    }
    if (!lastNameSubmitted || lastNameSubmitted.trim() === '') {
      errors.lastName = 'Please enter last name';
    }

    return errors; // Return the errors object to allow Formik to use it for validation
  };

  const onSubmit = (data) => {
    const { firstName: name, lastName: surname } = data ?? {};

    updateUser({
      variables: {
        firstName: name,
        lastName: surname,
        onboardingCompleted,
      },
    })
      .then((res) => {
        showSuccessToast();
        refetchUser().then(() => navigate(DASHBOARD));
      })
      .catch((err) => {
        console.log(err);
        showErrorToast();
      });
  };

  const { isMax767 } = useBreakPoints();

  const alternatePhoneContainerStyles = {
    backgroundColor: 'brand.neutral.white',
    minHeight: '100vh',
    height: '100vh',
    width: '100%',
    direction: 'column',
    alignItems: 'center',
  };

  const alternatePhoneContentContainerStyles = () => ({
    alignItems: 'center',
    w: '100%',
    px: 4,
    py: 2,
    position: 'relative',
    justifyContent: 'space-between',
    mb: isMobile ? 5 : '60px',
  });

  return (
    <Box>
      <Flex {...alternatePhoneContainerStyles}>
        <Stack {...alternatePhoneContentContainerStyles(isMax767)}>
          <Box w="100%" color="brand.neutral.800A">
            <BaselaneFullLogo />
          </Box>
        </Stack>
        <VStack {...{ px: '20px', mt: '8px', w: '640px' }}>
          <Box {...{ mb: '16px !important', align: 'center', w: '100%' }}>
            <Text textStyle="headline-xl" mb="4">
              Enter your name to join workspace{' '}
            </Text>
            <Text textStyle="md">
              You’re almost there. Please enter your name to join workspace.
            </Text>
          </Box>
          <ChakraProvider theme={habitatTheme}>
            <Formik
              innerRef={formikRef}
              validateOnChange
              validateOnBlur
              initialValues={initialValues}
              validate={handleValidation}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({
                values,
                dirty,
                resetForm,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldTouched,
                isValid,
              }) => (
                <Stack w="100%" spacing={1}>
                  <FormControl
                    id="first-name"
                    isRequired
                    isInvalid={errors.firstName && touched.firstName}
                  >
                    <BaselaneFormLabel textStyle="sm">First name</BaselaneFormLabel>
                    <BaselaneInput
                      size="lg"
                      id="firstName"
                      name="firstName"
                      value={values.firstName}
                      placeholder="Enter first name"
                      type="text"
                      onChange={(e) => {
                        setFieldTouched('firstName');
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      maxLength={50}
                    />
                    <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="first-name"
                    isRequired
                    isInvalid={errors.lastName && touched.lastName}
                  >
                    <BaselaneFormLabel textStyle="sm">Last name</BaselaneFormLabel>
                    <BaselaneInput
                      size="lg"
                      id="lastName"
                      name="lastName"
                      value={values.lastName}
                      placeholder="Enter last name"
                      type="text"
                      onChange={(e) => {
                        setFieldTouched('lastName', true); // Properly setting the touched field
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                  </FormControl>

                  <BaselaneButton
                    size="md"
                    width="100%"
                    variant="filled"
                    palette="primary"
                    onClick={() => onSubmit(values)}
                    isDisabled={!isValid || !values.firstName || !values.lastName}
                  >
                    Join workspace
                  </BaselaneButton>
                </Stack>
              )}
            </Formik>
          </ChakraProvider>
        </VStack>
      </Flex>
    </Box>
  );
}

export default InfoPage;
