import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Text } from '@chakra-ui/react';
import { PROPERTIES } from '@routes';
import useBreakPoints from '@core/hooks/useBreakPoints';
import BaselaneButton from '../../../BaselaneButton';
import { addPropertyStyles } from './styles/addProperty.styles';

const AddProperty = () => {
  const { isMinXL } = useBreakPoints();

  const { container, title, description } = addPropertyStyles({ isMinXL }) ?? {};

  const navigate = useNavigate();

  return (
    <Stack {...container}>
      <Text {...title}>Add A Property To Create Lease</Text>
      <Text {...description}>
        To create a lease document, please add one or more of your properties.
      </Text>
      <BaselaneButton
        variant="filled"
        palette="primary"
        size="lg"
        onClick={() => {
          navigate(`/${PROPERTIES}`);
        }}
      >
        Add Property
      </BaselaneButton>
    </Stack>
  );
};

export default AddProperty;
