// @flow
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { HStack } from '@chakra-ui/react';
import IconSkeletonCircle1 from '@icons/manual/IconSkeletonCircle1';
import IconSkeletonBar1 from '@icons/manual/IconSkeletonBar1';
import { skeletonRowStyles } from './styles/table.style';

function SkeletonTable(): any {
  const renderSkeletonGridContent = (i, key) => {
    return i === 0 ? (
      <HStack key={key}>
        <IconSkeletonCircle1 />
        <IconSkeletonBar1 width="160" mr="8px" />
      </HStack>
    ) : (
      <IconSkeletonBar1 key={key} width="160" mr="8px" _last={{ mr: '0' }} />
    );
  };
  return Array(3)
    .fill(0)
    .map(() => {
      const itemKey = uuidv4();
      return (
        <HStack key={itemKey} {...skeletonRowStyles} {...{ _last: { borderBottomWidth: '0px' } }}>
          {Array(3)
            .fill(0)
            .map((row, i) => {
              const key = uuidv4();
              return renderSkeletonGridContent(i, key);
            })}
        </HStack>
      );
    });
}

export default SkeletonTable;
