import React from 'react';
import { useStatsigClient } from '@statsig/react-bindings';
import { ONBOARDING_CARDS } from '@core/components/OnboardingTriage/helpers/onboardingTriageHelper';
import { onboardingCardWrapperStyles } from '@core/components/OnboardingTriage/styles/onboardingTriage.styles';
import { HOME } from '@routes';
import { IllustrationOtherTenantScreening } from '@illustrations';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import CardOption from './CardOption';

interface OnboardingOptionsProps {
  handleActionButton: Function;
  isLoading: boolean;
}

const OnboardingOptions = ({ handleActionButton, isLoading }: OnboardingOptionsProps) => {
  const currentOnboardingCards = [...ONBOARDING_CARDS];
  const { checkGate } = useStatsigClient();

  const tenantScreeningCard = {
    id: 'tenant_screening-option',
    title: 'Screen tenants',
    description:
      'Get the most accurate background check, income and ID verification for your applicants.',
    segmentEvent: 'tenant_screening',
    buttonText: 'Get Started',
    linkRoute: HOME,
    action: 'screen-tenants',
    image: <IllustrationOtherTenantScreening />,
  };

  if (checkGate(FEATURE_GATES.TENANT_SCREENING_GATE)) {
    currentOnboardingCards.push(tenantScreeningCard);
  }

  return currentOnboardingCards.map((card) => {
    return (
      <CardOption
        key={card.id}
        card={card}
        onClick={(e) => handleActionButton(e, card)}
        styles={onboardingCardWrapperStyles()}
        isLoading={isLoading}
      />
    );
  });
};

export default OnboardingOptions;
