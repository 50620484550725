import React from 'react';
import { Text } from '@chakra-ui/react';
import IconCheck from '@icons/manual/IconCheck';
import { AlertHeader, BaselaneAlert, BaselaneButton } from '@shared/components';

type ActivationSuccessPopupProps = {
  isActivationSuccessPopupOpen: boolean,
  onActivationSuccessPopupClose: Function,
};

const ActivationSuccessPopup = ({
  isActivationSuccessPopupOpen,
  onActivationSuccessPopupClose,
}: ActivationSuccessPopupProps) => {
  const title = (
    <>
      Your debit card has been activated <br /> and it is ready to be used!
    </>
  );
  const activationSuccessPopupContent = (
    <Text>
      Baselane provides you with complete control over your debit card. You will be able to define
      spending limits and change the card name.
    </Text>
  );

  const activationSuccessPopupFooter = (
    <BaselaneButton
      id="activation-success-popup-button"
      variant="outline"
      palette="neutral"
      size="md"
      onClick={onActivationSuccessPopupClose}
      styles={{ alignSelf: 'flex-end' }}
    >
      Close
    </BaselaneButton>
  );

  return (
    <BaselaneAlert
      showCloseButton
      isOpen={isActivationSuccessPopupOpen}
      onClose={onActivationSuccessPopupClose}
      isCentered
      header={
        <AlertHeader
          icon={<IconCheck />}
          title={title}
          iconBgStyles={{ bg: 'green.100' }}
          iconContainerStyles={{
            color: 'green.800AA',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      }
      body={activationSuccessPopupContent}
      footer={activationSuccessPopupFooter}
      size="xl"
    />
  );
};

export default ActivationSuccessPopup;
