import {
  formatPhoneNumber,
  getLeaseDuration,
  getMonthlyRentAndFees,
  getTenantDetails,
} from '@pages/LeasesPage/helpers/unit.helpers';

export const getLeaseInfo = (property, tenants) => {
  const { units, address: propertyAddress } = property;
  const { lease = [], name } = units?.[0] ?? [];

  // ASSUMPTION: the most recent lease is at the end of the array
  const newLeaseId = lease.length - 1 || 0;

  const { endDate = '', startDate = '', leaseType } = lease?.[newLeaseId] ?? {};
  const leaseDuration = getLeaseDuration({ endDate, startDate, leaseType });
  const monthlyRentAndFees = getMonthlyRentAndFees(lease?.[newLeaseId]?.payments);

  const { firstName = '', lastName = '', phoneNumber = '', email = '' } =
    getTenantDetails(tenants, lease[newLeaseId]?.tenantProfileId, lease[newLeaseId]) || {};

  const tenantName = `${firstName} ${lastName}`;

  const address = propertyAddress
    ? `${propertyAddress.address}, ${!propertyAddress.unit ? '' : `${propertyAddress.unit}, `} ${
        propertyAddress.city
      }, ${propertyAddress.state} ${propertyAddress.zipCode}`
    : '';

  const tenantNumber = formatPhoneNumber(phoneNumber);

  return {
    unit: name,
    address,
    tenantName,
    tenantNumber,
    tenantEmail: email,
    leaseDuration,
    monthlyRentAndFees,
  };
};

export const getPropertyWithCompletedLease = (properties, unitId) =>
  properties.reduce((propertyWithLease, propertyInfo) => {
    let completedLease = propertyInfo.units.find(
      (unit) =>
        unit?.lease && (unit?.lease?.state === 'SCHEDULED' || unit?.lease?.state === 'EXPIRED')
    );

    if (unitId) {
      completedLease = propertyInfo.units.find((unit) => unit.id === unitId);
    }

    const clonedProperty = { ...propertyInfo };

    if (completedLease) {
      clonedProperty.units = [{ ...completedLease }];
      return clonedProperty;
    }

    return propertyWithLease;
  }, {});
