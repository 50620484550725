import React, { useState } from 'react';
import {
  Stack,
  FormControl,
  Flex,
  Heading,
  DrawerBody,
  DrawerFooter,
  useMediaQuery,
  ChakraProvider,
  Input,
  Box,
  useToast,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  BaselaneDrawer,
  BaselaneFileUploader,
  BaselaneFormLabel,
  BaselaneRadioGroup,
  BaselaneRadio,
  BaselaneSingleDatePicker,
  BaselaneButton,
  DocumentCard,
  BaselaneModal,
  handleUploadAndSaveImageAttachments,
  getQueryDownloadUrl,
} from '@shared/components';
import {
  DELETE_DOCUMENT,
  GET_DOCUMENT_UPLOAD_URL,
  GET_DOCUMENT_DOWNLOAD_URL,
} from '@core/apollo/queries';
import onDrawerClose from '@core/utils/onDrawerClose';
import useProperties from '@core/features/Property/useProperties';
import BaselanePropertySelect from '@core/components/Shared/components/BaselanePropertySelect';
import habitatTheme from '@core/themeHabitat';
import { CREATE_OR_UPDATE_LEASE_AGREEMENT, GET_LEASE_AGREEMENTS } from '../../queries';

const LEASE_TYPE = {
  fixed: 'fixed',
  monthly: 'month-to-month',
};

const NewLeaseAgreement = () => {
  const { isMax576 } = useMediaQuery();

  const { properties, loading } = useProperties();

  const [getDocumentUploadUrl] = useLazyQuery(GET_DOCUMENT_UPLOAD_URL, {
    fetchPolicy: 'network-only',
  });

  const [getDocumentDownloadUrl] = useLazyQuery(GET_DOCUMENT_DOWNLOAD_URL, {
    fetchPolicy: 'network-only',
  });

  const [deleteUserDocument] = useMutation(DELETE_DOCUMENT);
  const [
    createOrUpdateLeaseAgreement,
    { loading: createOrUpdateLeaseAgreementLoading, error: createOrUpdateLeaseAgreementError },
  ] = useMutation(CREATE_OR_UPDATE_LEASE_AGREEMENT, {
    refetchQueries: [GET_LEASE_AGREEMENTS],
  });

  const navigate = useNavigate();

  const [showDocumentTitleModal, setShowDocumentTitleModal] = useState(false);
  const [fileProps, setFileProps] = useState(null);
  const [documentTitle, setDocumentTitle] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);

  const initialFormValues = {
    isSigned: 'true',
    selectedProperty: '',
    selectedUnit: '',
    leaseTerm: LEASE_TYPE.fixed,
    startDate: null,
    endDate: null,
  };

  const handleUploadFile = (setFieldValue) => {
    handleUploadAndSaveImageAttachments({
      ...fileProps,
      getDocumentUploadUrl,
      documentTitle,
      entityData: {
        entityType: 'LEASE_AGREEMENT',
        entityDate: DateTime.now().toISO(),
        documentUploadPurpose: 'LEASE_AGREEMENT',
      },
      onUploadSuccess: ({ newDocument }) => {
        setUploadedFile(newDocument);
      },
    });
  };

  const toast = useToast();

  const isSaveDisabled = (values) => {
    return (
      !uploadedFile ||
      !values?.isSigned ||
      !values?.selectedProperty ||
      (properties
        .find((p) => p.id === values.selectedProperty)
        ?.units?.filter((unitId) => unitId !== values.selectedUnit).length > 1 &&
        !values?.selectedUnit) ||
      !values?.leaseTerm ||
      !values?.startDate ||
      (values?.leaseTerm !== LEASE_TYPE.monthly && !values?.endDate)
    );
  };
  return (
    <BaselaneDrawer
      size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
      title="Lease agreement"
      isOpen
      closeEvent={() => {
        if (uploadedFile?.id) {
          deleteUserDocument({
            variables: {
              id: uploadedFile.id,
            },
          });
        }
        onDrawerClose(navigate);
      }}
      newDesignDrawer
    >
      <ChakraProvider theme={habitatTheme}>
        <Formik initialValues={initialFormValues} onSubmit={() => {}}>
          {({ values, setFieldValue }) => (
            <Stack h="100%" alignItems="stretch">
              {showDocumentTitleModal && (
                <BaselaneModal
                  defaultOpen={showDocumentTitleModal}
                  hideButton
                  showHeader
                  noIcon
                  showFooter
                  showCloseButton
                  modalTitle="Document title"
                  modalContentStyles={{ alignItems: 'flex-start', mb: 2, p: 0 }}
                  modalTitleStyles={{
                    fontSize: '24px',
                  }}
                  modalBodyStyles={{
                    px: 0,
                    w: '100%',
                  }}
                  modalFooterStyles={{
                    py: 1.5,
                  }}
                  onCloseBtnClick={() => {
                    setFileProps(null);
                    setShowDocumentTitleModal(false);
                  }}
                  primaryButton={{
                    label: 'Upload File',
                    props: {
                      onClick: () => {
                        handleUploadFile(setFieldValue);
                        setShowDocumentTitleModal(false);
                      },
                    },
                  }}
                  secondaryButton={{
                    label: 'Cancel',
                    props: {
                      onClick: () => {
                        setFileProps(null);
                        setShowDocumentTitleModal(false);
                        if (uploadedFile.id) {
                          deleteUserDocument({
                            variables: {
                              id: uploadedFile.id,
                            },
                          });
                        }
                      },
                    },
                  }}
                >
                  <Box mx={3}>
                    <Input
                      type="text"
                      value={documentTitle}
                      onChange={(e) => {
                        setDocumentTitle(e.target.value);
                      }}
                    />
                  </Box>
                </BaselaneModal>
              )}

              <DrawerBody p={0}>
                {uploadedFile ? (
                  <DocumentCard
                    isNewDesign
                    doc={uploadedFile}
                    handleDeleteDocAlertOpen={() => {
                      deleteUserDocument({
                        variables: {
                          id: uploadedFile.id,
                        },
                      });

                      setFileProps(null);
                      setDocumentTitle('');
                      setUploadedFile(null);
                    }}
                    getDownloadUrl={async () =>
                      getQueryDownloadUrl(uploadedFile.id, getDocumentDownloadUrl)
                    }
                    documentTitle={documentTitle}
                  />
                ) : (
                  <BaselaneFileUploader
                    allowedFileType={{ enum: ['PDF'], text: ['PDF'] }}
                    accept=".pdf"
                    handleFile={(props) => {
                      setFileProps(props);
                      // eslint-disable-next-line react/prop-types
                      setDocumentTitle(props.file.name.split('.pdf')[0]);
                      setShowDocumentTitleModal(true);
                    }}
                  />
                )}
                <Stack mt={2}>
                  <FormControl isRequired>
                    <BaselaneFormLabel>Is the lease agreement already signed?</BaselaneFormLabel>
                    <BaselaneRadioGroup
                      id="isSigned"
                      name="isSigned"
                      value={values?.isSigned}
                      onChange={(value) => setFieldValue('isSigned', value)}
                    >
                      <Flex gap={2}>
                        <BaselaneRadio key="1" label="Yes" value="true" name="isSigned" />
                        <BaselaneRadio key="2" label="No" value="false" name="isSigned" />
                      </Flex>
                    </BaselaneRadioGroup>
                  </FormControl>
                  <BaselanePropertySelect properties={properties} loading={loading} isRequired />
                  <Heading size="md" fontWeight={500}>
                    Lease term
                  </Heading>
                  <FormControl isRequired>
                    <BaselaneRadioGroup
                      id="leaseTerm"
                      name="leaseTerm"
                      onChange={(value) => setFieldValue('leaseTerm', value)}
                      value={values?.leaseTerm}
                    >
                      <Flex gap={2}>
                        <BaselaneRadio
                          key="1"
                          label="Fixed Term"
                          value={LEASE_TYPE.fixed}
                          name="leaseTerm"
                        />
                        <BaselaneRadio
                          key="2"
                          label="Month-to-Month"
                          value={LEASE_TYPE.monthly}
                          name="leaseTerm"
                        />
                      </Flex>
                    </BaselaneRadioGroup>
                  </FormControl>
                  <Flex gap={2}>
                    <FormControl isRequired>
                      <BaselaneFormLabel>Lease start date</BaselaneFormLabel>
                      <BaselaneSingleDatePicker
                        id="startDate"
                        name="startDate"
                        placeholder="Select date"
                        classNames={['input-form-md', 'auto-select-input-width']}
                        date={values?.startDate}
                        onChange={(value) => setFieldValue('startDate', value)}
                        placement="bottom-end"
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <BaselaneFormLabel>Lease end date</BaselaneFormLabel>
                      <BaselaneSingleDatePicker
                        id="endDate"
                        name="endDate"
                        placeholder="Select date"
                        classNames={['input-form-md', 'auto-select-input-width']}
                        date={values?.endDate}
                        onChange={(value) => setFieldValue('endDate', value)}
                        placement="bottom-end"
                        isDisabled={values?.leaseTerm === LEASE_TYPE.monthly}
                      />
                    </FormControl>
                  </Flex>
                </Stack>
              </DrawerBody>
              <DrawerFooter p={0}>
                <Stack w="100%" flexDirection="row" justifyContent="space-between">
                  <BaselaneButton
                    w="25%"
                    variant="outline"
                    palette="neutral"
                    size="md"
                    onClick={() => {
                      if (uploadedFile) {
                        deleteUserDocument({
                          variables: {
                            id: uploadedFile.id,
                          },
                        });
                      }

                      onDrawerClose(navigate, '..');
                    }}
                  >
                    Cancel
                  </BaselaneButton>

                  <BaselaneButton
                    w="75%"
                    variant="filled"
                    palette="primary"
                    size="md"
                    isLoading={createOrUpdateLeaseAgreementLoading}
                    isDisabled={isSaveDisabled(values)}
                    onClick={() => {
                      try {
                        const property = properties.find((p) => p.id === values.selectedProperty);

                        const unitId = values.selectedUnit || property.units[0].id;

                        const propertyUnitName = properties
                          .find((p) => p.id === values.selectedProperty)
                          ?.units.find((u) => u.id === unitId)?.name;

                        createOrUpdateLeaseAgreement({
                          variables: {
                            id: uploadedFile.documentEntityId,
                            inputType: 'UPLOAD',
                            propertyId: values.selectedProperty,
                            propertyUnitId:
                              values.selectedUnit ||
                              properties.find((p) => p.id === values.selectedProperty).units[0].id,
                            leaseType: values.leaseTerm === LEASE_TYPE.fixed ? 'FIXED' : 'MONTHLY',
                            startDate: DateTime.fromJSDate(values.startDate).toFormat('yyyy-MM-dd'),
                            endDate: values.endDate
                              ? DateTime.fromJSDate(values.endDate).toFormat('yyyy-MM-dd')
                              : null,
                            documentName: `${property.name} - ${propertyUnitName} - ${documentTitle}`,
                            isAlreadySigned: values.isSigned === 'true',
                            state:
                              values.isSigned === 'true' ? 'SIGNING_COMPLETE' : 'READY_TO_SIGN',
                          },
                        }).then(() => {
                          onDrawerClose(navigate);
                        });

                        if (createOrUpdateLeaseAgreementError) {
                          toast({
                            title: 'Error uploading lease',
                            status: 'error',
                            duration: 3000,
                            isClosable: true,
                            position: 'bottom-left',
                          });
                        } else {
                          // Show toast after drawer starts closing
                          setTimeout(() => {
                            toast({
                              title: 'Lease uploaded',
                              status: 'success',
                              duration: 3000,
                              isClosable: true,
                              position: 'bottom-left',
                            });
                          }, 1000);
                        }
                      } catch (e) {
                        toast({
                          title: 'Error uploading lease',
                          status: 'error',
                          duration: 3000,
                          isClosable: true,
                          position: 'bottom-left',
                        });
                      }
                    }}
                  >
                    Save
                  </BaselaneButton>
                </Stack>
              </DrawerFooter>
            </Stack>
          )}
        </Formik>
      </ChakraProvider>
    </BaselaneDrawer>
  );
};

export default NewLeaseAgreement;
