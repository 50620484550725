import React from 'react';
import IconProperties from '@icons/manual/IconProperties';
import IconKey from '@icons/manual/IconKey';
import IconDollarCircleOutline from '@icons/manual/IconDollarCircleOutline';

const customTrackerStyles = {
  width: { xl: '100%', lg: '23.5%' },
  height: { xl: '176px', lg: '92px' },
  padding: { xl: '24px', lg: '16px' },
  cursor: 'pointer',
  display: { xl: 'initial', lg: 'flex' },
  flexDirection: { xl: 'initial', lg: 'column' },
  justifyContent: { xl: 'initial', lg: 'center' },
  m: '0 0 8px',
  p: '20px 16px 22px',
  background: 'brand.blue.50',
  border: '1px solid #0968BF',
  boxSizing: 'border-box',
  boxShadow: '1px 2px 6px rgba(3, 24, 64, 0.04)',
  borderRadius: '6px',
};
const customCompletedTrackerStyles = {
  border: '1px solid ',
  borderColor: 'brand.blue.700',
  borderRadius: '6px',
  width: { xl: '100%', lg: '23.5%' },
  height: { xl: '176px', lg: '92px' },
  padding: { xl: '24px', lg: '16px' },
  cursor: 'pointer',
  display: { xl: 'initial', lg: 'flex' },
  flexDirection: { xl: 'initial', lg: 'column' },
  justifyContent: { xl: 'initial', lg: 'center' },
  bg: 'brand.darkBlue.800A',
  m: '0 24px 8px',
  p: '20px 0 22px',
};

const customTitleStyles = {
  fontWeight: 'normal',
  fontSize: 'xs',
  lineHeight: '20px',
};

export const TrackersConfig = {
  properties: {
    title: 'Add property',
    icon: <IconProperties h="20" w="20" />,
    position: 0,
    hasButton: true,
    isDisabled: false,
    isCompleted: false,
    customTrackerStyles,
    customCompletedTrackerStyles,
    showMoreFields: false,
    customTitleStyles,
    canEditBox: true,
  },
  financials: {
    title: 'Add property Financials',
    icon: <IconDollarCircleOutline h="20" w="20" />,
    position: 1,
    hasButton: true,
    isDisabled: true,
    isCompleted: false,
    customTrackerStyles,
    customCompletedTrackerStyles,
    showMoreFields: false,
    customTitleStyles,
    canEditBox: true,
  },
  rentcollection: {
    title: 'SET UP RENT COLLECTION',
    icon: <IconKey h="20" w="20" />,
    position: 2,
    hasButton: true,
    isDisabled: true,
    isCompleted: false,
    customTrackerStyles,
    customCompletedTrackerStyles,
    showMoreFields: false,
    customTitleStyles,
  },
  verify: {},
};
