import React, { useEffect, useState } from 'react';
import { FormControl, Skeleton, useToast, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import LogRocket from 'logrocket';
import BaselaneModal from '@shared/components/BaselaneModal';
import BaselaneButton from '@shared/components/BaselaneButton';
import BaselaneSwitch from '@shared/components/BaselaneSwitch';
import BaselaneRadioGroup from '@shared/components/BaselaneRadioGroup';
import BaselaneRadio from '@shared/components/BaselaneRadio';
import useCurrentWorkspace from '@shared/hooks/useCurrentWorkspace';
import IconSparkles from '@icons/manual/IconSparkles';
import theme from '@core/theme';
import AutoTaggingGatingBanner from '@core/components/Shared/layouts/Banner/AutoTaggingGatingBanner';
import { GET_BANKS } from '@core/apollo/queries';
import { UPDATE_USER_METADATA } from '@core/components/UserProfilePage/queries';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { NB_ACCOUNTS } from '@core/constants/routes';
import {
  CREATE_AUTOTAG_LLM,
  GET_AUTOTAG_RULES,
  UPDATE_AUTOTAG_LLM,
} from '../../../queries/autotag-queries';
import { CATEGORIZATION_TYPE, isAiCategorizationRule } from '../aiRule.helpers';

const AICategorizationSettings = () => {
  const { workspaceMetadata } = useCurrentWorkspace();
  const { metadata } = workspaceMetadata || {};

  const [updateUserMetadata] = useMutation(UPDATE_USER_METADATA);

  const { data: banksData, loading: banksLoading } = useQuery(GET_BANKS, {
    fetchPolicy: 'cache-first',
  });

  const [hasBaselaneAccounts, setHasBaselaneAccounts] = useState();
  // Fetch rules from cache, as we need the AI rule for settings
  const { data, loading } = useQuery(GET_AUTOTAG_RULES, { fetchPolicy: 'cache-first' });

  const [createAutoTagLLMRule, { loading: isCreating }] = useMutation(CREATE_AUTOTAG_LLM, {
    refetchQueries: [GET_AUTOTAG_RULES],
  });
  const [updateAutoTagLLMRule, { loading: isUpdating }] = useMutation(UPDATE_AUTOTAG_LLM, {
    refetchQueries: [GET_AUTOTAG_RULES],
  });

  // a value if null indicates that the rule is not yet created
  const [categorizationAiRuleId, setCategorizationRuleId] = useState(null);
  const [categorizationAiEnabled, setCategorizationAiEnabled] = useState(null);
  const [categorizationType, setCategorizationType] = useState(
    CATEGORIZATION_TYPE.NEW_TRANSACTIONS_ONLY
  );

  const navigate = useNavigate();

  const toast = useToast();

  const showErrorToast = () => {
    toast({
      description: 'Could not save settings, please try again.',
      status: 'error',
      isClosable: true,
      position: 'bottom-left',
    });
  };

  const updateMetadataOnFirstUse = (selection) => {
    updateUserMetadata({
      variables: { metadata: { ...metadata, hasSeenAutoTagPromo: true } },
      update: (cache, { data: { updateUser: updatedUser } }) => {
        cache.modify({
          id: cache.identify(updatedUser),
          fields: {
            metadata: () => updatedUser?.metadata,
          },
        });
      },
    })
      .then(() => {
        // this is meant to open the success modal if on the transactions page
        // so the user can navigate to the auto tag rules page
        // it is ignored on the auto tag rules page
        navigate('..', {
          state: {
            openSuccessModal: true,
            modalState: selection,
          },
        });
      })
      .catch((error) =>
        LogRocket.log(`AICategorizationSettings: Error updating user metadata: ${error}`)
      );
  };

  /** This closes the modal or displays an error toast */
  const updateUIAfterSettingsSave = (operation: Promise, values: Object) => {
    operation
      .then((updateData) => {
        if (!updateData.errors) {
          const { hasSeenAutoTagPromo } = metadata || {};
          if (!hasSeenAutoTagPromo) {
            // marks the setting as having been used at least once
            updateMetadataOnFirstUse(values.categorizationType);
          } else {
            // normal flow, just close the modal
            navigate('..');
          }
        } else {
          showErrorToast();
        }
      })
      .catch((error) => {
        showErrorToast();
      });
  };

  const handleSaveSettings = (values) => {
    if (values.categorizationAiEnabled && !categorizationAiRuleId) {
      updateUIAfterSettingsSave(
        createAutoTagLLMRule({
          variables: {
            applyToTaggedTransactions: !!(
              values.categorizationType === CATEGORIZATION_TYPE.NEW_AND_UNCATEGORIZED
            ),
          },
        }),
        values
      );
    } else if (categorizationAiRuleId) {
      updateUIAfterSettingsSave(
        updateAutoTagLLMRule({
          variables: {
            id: categorizationAiRuleId,
            isActive: values.categorizationAiEnabled,
            applyToTaggedTransactions:
              values.categorizationType === CATEGORIZATION_TYPE.NEW_AND_UNCATEGORIZED,
          },
        }),
        values
      );
    } else {
      // this should never happen in theory
      showErrorToast();
      LogRocket.log(`AICategorizationSettings: unhandled case that should never occur`);
    }
  };

  useEffect(() => {
    if (data) {
      const { autotagRules } = data;
      const aiRule = autotagRules.find(isAiCategorizationRule);
      if (aiRule) {
        setCategorizationAiEnabled(aiRule.isActive);
        setCategorizationRuleId(aiRule.id);
        setCategorizationType(null);
      } else {
        setCategorizationAiEnabled(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (banksData) {
      const connectedBaselaneAccounts = banksData?.bank?.filter((ba) => {
        const { isConnected, isExternal, unitAccount } = ba;
        return isConnected && !isExternal && unitAccount?.unitApplicationStatus === 'COMPLETED';
      });
      if (connectedBaselaneAccounts.length > 0) {
        setHasBaselaneAccounts(true);
      } else {
        setHasBaselaneAccounts(false);
      }
    }
  }, [banksData]);

  return (
    <BaselaneModal
      closeOnOverlayClick
      onCloseBtnClick={() => {
        navigate('..');
      }}
      defaultOpen
      showCloseButton
      closeBtnId="autotag-modal2-close"
      showFooter={false}
      showHeader
      modalHeadingStyles={{ px: 5, pb: 0, pt: 10 }}
      modalTitleStyles={{ fontSize: '24px' }}
      modalTitle="Categorization AI"
      icon={<IconSparkles color={theme.colors.brand.blue[300]} />}
      description="Get all your transactions automatically categorized using Baselane’s AI. Bookkeeping
            rules will be prioritized over AI categorization."
      showBody={false}
      size="2xl"
      modalBodyStyles={{
        px: 5,
        py: 5,
      }}
    >
      <Skeleton
        display="flex"
        flexDirection="column"
        isLoaded={categorizationAiEnabled !== null && !banksLoading}
        alignItems="flex-start"
        gap={4}
      >
        <VStack alignItems="flex-start" gap={0.5} justifyContent="flex-start">
          {!hasBaselaneAccounts && (
            <AutoTaggingGatingBanner
              hideButton
              mt={2}
              styles={{
                innercontainer: {
                  textAlign: 'left',
                },
              }}
            />
          )}
        </VStack>
        <Formik
          initialValues={{
            categorizationAiEnabled,
            categorizationType,
          }}
          onSubmit={handleSaveSettings}
        >
          {({
            handleSubmit,
            handleReset,
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (
            <>
              <Skeleton
                display="flex"
                flexDirection="column"
                isLoaded={!loading}
                alignItems="flex-start"
                gap={2}
              >
                <Form>
                  <VStack alignItems="flex-start" gap={0} my={0}>
                    <BaselaneSwitch
                      label="Enable Categorization AI"
                      onChange={() => {
                        setFieldValue('categorizationAiEnabled', !values.categorizationAiEnabled);
                      }}
                      value={values.categorizationAiEnabled}
                      isChecked={values.categorizationAiEnabled}
                      mb={0}
                      htmlFor="categorizationAiEnabled"
                      isDisabled={!hasBaselaneAccounts}
                    />
                    {values.categorizationAiEnabled && (
                      <FormControl id="categorizationAiEnabled">
                        <BaselaneRadioGroup
                          defaultValue={CATEGORIZATION_TYPE.NEW_TRANSACTIONS_ONLY}
                          id="typeGroup"
                          name="typeGroup"
                          onChange={(selectedCategorizationType) => {
                            setFieldValue('categorizationType', selectedCategorizationType);
                          }}
                          value={
                            values.categorizationType ||
                            (!categorizationAiRuleId || !categorizationAiEnabled
                              ? CATEGORIZATION_TYPE.NEW_TRANSACTIONS_ONLY
                              : '')
                          }
                          ml={6}
                          groupStyles={{ py: 0, mb: 0 }}
                        >
                          <BaselaneRadio
                            label="Only apply to new transactions"
                            value={CATEGORIZATION_TYPE.NEW_TRANSACTIONS_ONLY}
                            name={CATEGORIZATION_TYPE.NEW_TRANSACTIONS_ONLY}
                            id={CATEGORIZATION_TYPE.NEW_TRANSACTIONS_ONLY}
                            htmlFor={CATEGORIZATION_TYPE.NEW_TRANSACTIONS_ONLY}
                          />
                          <BaselaneRadio
                            label="Apply to new and existing uncategorized transactions"
                            value={CATEGORIZATION_TYPE.NEW_AND_UNCATEGORIZED}
                            name={CATEGORIZATION_TYPE.NEW_AND_UNCATEGORIZED}
                            id={CATEGORIZATION_TYPE.NEW_AND_UNCATEGORIZED}
                            htmlFor={CATEGORIZATION_TYPE.NEW_AND_UNCATEGORIZED}
                          />
                        </BaselaneRadioGroup>
                      </FormControl>
                    )}
                  </VStack>
                </Form>
              </Skeleton>
              {hasBaselaneAccounts ? (
                <BaselaneButton
                  size="lg"
                  variant="filled"
                  palette="primary"
                  w="full"
                  id="autotag-modal2-save"
                  isDisabled={!dirty && categorizationAiRuleId}
                  onClick={handleSubmit}
                  isLoading={isCreating || isUpdating}
                >
                  Save settings
                </BaselaneButton>
              ) : (
                <BaselaneButton
                  size="lg"
                  variant="filled"
                  palette="primary"
                  w="full"
                  onClick={() => {
                    navigate(NB_ACCOUNTS, {
                      state: { startWithOpenedAccountTypeModal: true },
                    });
                    sendSegmentEvent('autotagging_gtm_modal_ai_settings_complete', {});
                  }}
                >
                  Get full access
                </BaselaneButton>
              )}
            </>
          )}
        </Formik>
      </Skeleton>
    </BaselaneModal>
  );
};

export default AICategorizationSettings;
