import React from 'react';
import IconCheckedFancy from '@icons/manual/IconCheckedFancy';
import IconWarningTriangleYellow from '@icons/manual/IconWarningTriangleYellow';
import { Icon16ChevronRight } from '@icons/16px';

const iconWarningConfig = {
  icon: <IconWarningTriangleYellow w="40" h="40" />,
};

export const MODAL_STATUS = Object.freeze({
  CSV_DOWNLOAD: {
    icon: <IconCheckedFancy w="40" h="40" />,
    state: 'CSV_DOWNLOAD',
    title: 'CSV downloaded',
    description: 'Would you like to download the attachments for these transactions?',
    leftButtonText: 'Download attachments',
    leftButtonProps: {
      variant: 'tonal',
      palette: 'primary',
      size: 'md',
      isFullWidth: true,
      rightIcon: <Icon16ChevronRight />,
    },
  },
  EMAIL_SENT: {
    icon: <IconCheckedFancy w="40" h="40" />,
    state: 'EMAIL_SENT',
    title: 'We’ve emailed you the download link',
    description:
      'It may take a few minutes to arrive, depending on the size of the attachments. If you do not see it after some time, please check your spam folder or contact support.',
    leftButtonText: 'Done',
    leftButtonProps: {
      variant: 'filled',
      palette: 'primary',
      size: 'md',
      isFullWidth: true,
    },
  },
  TOO_MANY_REQUESTS: {
    ...iconWarningConfig,
    state: 'TOO_MANY_REQUESTS',
    title: 'Too many requests',
    description:
      'You’ve downloaded the tax package too many times recently. Please try again later.',
    leftButtonText: 'Close',
    leftButtonProps: { variant: 'outline', palette: 'neutral', size: 'md', isFullWidth: true },
  },
  REQUEST_FAILED: {
    ...iconWarningConfig,
    state: 'REQUEST_FAILED',
    title: 'Request failed',
    description:
      'Something went wrong when attempting to email you the attachments. Please try again later. If the issue persists, please contact support.',
    leftButtonText: 'Close',
    leftButtonProps: { variant: 'outline', palette: 'neutral', size: 'md', isFullWidth: true },
    rightButtonText: 'Retry',
    rightButtonProps: { variant: 'filled', palette: 'primary', size: 'md', isFullWidth: true },
  },
});
