// eslint-disable-next-line import/no-extraneous-dependencies
import { datadogLogs } from '@datadog/browser-logs';
import userStorage from '@core/storage/userStorage';

export function initializeDatadogLogs() {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    forwardErrorsToLogs: true,
    version: process.env.REACT_APP_VERSION,
    env: process.env.REACT_APP_NODE_ENV,
    service: 'portal',
  });
}

const originalConsoleLog = console.log;
console.log = (...params) => {
  datadogLogs.logger.log(params?.[0], { params }, 'info');
  originalConsoleLog(...params);
};

const originalConsoleError = console.error;
console.error = (...params) => {
  const userData = getUserData();
  datadogLogs.logger.error(params?.[0], { params, userData }, params?.[0]);
  originalConsoleError(...params);
};

function getUserData() {
  try {
    const { id, email } = userStorage.read();
    return { id, email };
  } catch (error) {
    return {};
  }
}
