import React from 'react';
import { VStack, Text, Skeleton } from '@chakra-ui/react';
import { useTags } from '@shared/hooks';
import useBreakPoints from '@core/hooks/useBreakPoints';
import useProperties from '../../../features/Property/useProperties';

type ActionProps = {
  action: Object,
};

const Action = ({ action, ...rest }: ActionProps) => {
  const { tagId, propertyId, unitId } = action;
  const { categoryMap, tagsLoading, presetsLoading } = useTags();
  const { properties, loading } = useProperties();
  const { isMax767 } = useBreakPoints();

  const property = properties.find((propertyData) => propertyData.id === propertyId);
  const propertyName = property?.name;
  const unit = property?.units.find((unitData) => unitData.id === unitId);
  const unitName = unit?.name;

  const itemProps = {
    as: 'span',
    textStyle: isMax767 ? 'headline-sm' : 'headline-md',
    color: isMax767 ? 'brand.neutral.600' : 'auto',
    whiteSpace: 'wrap',
  };

  return (
    <Skeleton
      as={VStack}
      isLoaded={!loading && !tagsLoading && !presetsLoading}
      {...rest}
      gap={1.5}
      alignItems="flex-start"
      justifyContent="center"
    >
      {tagId && (
        <Text textStyle={isMax767 ? 'sm' : 'md'}>
          {isMax767 && <Text as="span">Update</Text>} <Text {...itemProps}>Category</Text> to{' '}
          <Text {...itemProps}>{categoryMap[tagId]}</Text>
        </Text>
      )}
      {propertyName && (
        <Text textStyle={isMax767 ? 'sm' : 'md'}>
          {isMax767 && <Text as="span">Update</Text>} <Text {...itemProps}>Property</Text> to{' '}
          <Text {...itemProps}>
            {propertyName}
            {unitName ? `, ${unitName}` : ''}
          </Text>
        </Text>
      )}
    </Skeleton>
  );
};

export default Action;
