import colors from '@core/theme/colors';

export const modalContentStyles = (isLandscape) => ({
  bg: 'brand.neutral.white',
  borderRadius: '12px',
  w: { xxs: '275px', xs: '320px', sm: '472px', md: '630px', lg: '780px' },
  boxShadow: 'none',
  m: { xxs: '0', xs: '0', sm: '0' },
  maxHeight: isLandscape ? '100%' : 'auto',
  overflowY: 'auto',
  p: 0,
});

export const modalHeaderStyles = {
  color: 'brand.neutral.700',
  fontSize: '2xl',
  lineHeight: '32px',
};

export const modalBodyStyles = {
  color: 'brand.neutral.600',
  fontSize: 'sm',
  lineHeight: '20px',
};

export const modalFooterStyles = {
  borderTop: '1px solid #DADFE6',
  flexDirection: 'row',
  gap: 1.5,
};

export const primaryBtnStyles = {
  bg: colors.brand.darkBlue['800A'],
  color: colors.brand.neutral.white,

  _hover: {
    backgroundColor: colors.brand.darkBlue[600],
  },
};

export const secondaryBtnStyles = {
  bg: colors.brand.neutral.white,
  color: colors.brand.darkBlue['800A'],
  border: `1px solid ${colors.brand.darkBlue[200]}`,
};
