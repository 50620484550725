import React from 'react';
import { Text, ChakraProvider } from '@chakra-ui/react';
import { BaselaneCardNew } from '@shared/components';
import { sortWithString } from '@core/utils/sort';
import habitatTheme from '@core/themeHabitat';
import BaselaneRadioCardList from '@shared/components/BaselaneRadioCardList';
import { formatAddress } from '../../helpers/leaseAgreementForm';

type SelectPropertyProps = {
  sortedProperties: Array,
  selectedProperty: String,
  selectedUnit: String,
  setSelectedUnit: Function,
};

const PropertyUnitFormStep = ({
  sortedProperties,
  selectedProperty,
  selectedUnit,
  setSelectedUnit,
}: SelectPropertyProps) => {
  const propertyObj = sortedProperties.find((property) => property.id === selectedProperty?.id);
  const { address: addressObj, units } = propertyObj ?? {};
  const propertyAddress = formatAddress(addressObj);
  const sortedUnits = sortWithString(units, 'name');

  const handleUnitClick = (unit) => {
    setSelectedUnit(unit?.id);
  };

  return (
    <>
      {/* Selected Property Address */}
      <BaselaneCardNew size="md" palette="dark" mb={1}>
        <Text>{propertyAddress}</Text>
      </BaselaneCardNew>
      {/* List of Units */}
      <ChakraProvider theme={habitatTheme}>
        <BaselaneRadioCardList
          {...{
            list: sortedUnits,
            onListItemClick: handleUnitClick,
            showValueBy: 'name',
            selectedListItem: selectedUnit,
            name: 'Unit',
            search: '',
            showSearch: false,
          }}
        />
      </ChakraProvider>
    </>
  );
};

export default PropertyUnitFormStep;
