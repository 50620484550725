import React, { useState, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import { ChakraProvider, Box, Spinner, Flex, useToast } from '@chakra-ui/react';
import { sortWithString } from '@core/utils/sort';
import { panelFormResponsiveStyles } from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';
import habitatTheme from '@core/themeHabitat';
import useBreakPoints from '@core/hooks/useBreakPoints';
import BaselaneResponsiveCard from '@shared/components/BaselaneResponsiveCard/BaselaneResponsiveCard';
import AddPropertyFormLayout from '@pages/PropertiesPage/AddPropertyFormLayout';
import {
  GET_PROPERTIES,
  CREATE_PROPERTY_SIMPLE,
  FRAGMENT_PROPERTY_SIMPLE,
} from '@core/apollo/queries';
import { Icon16PlusCircle } from '@icons/16px';
import { BaselaneButton } from '@shared/components';
import { unitCardStyles } from '@shared/components/BaselaneResponsiveCard/styles/card.style';
import {
  getInitialValues,
  handlePropertySave,
  setFormData,
} from '@pages/PropertiesPage/helpers/detail.helper';
import PropertyCard from '../PropertyCard';
import Header from '../Header';
import {
  useSetSelectedPropertyObject,
  useSelectedPropertyObject,
  useIsUnitPanelVisible,
  useSetIsPropertyStepValid,
  useSetIsUnitStepValid,
  useSetIsAddingNewPropertyPanelVisible,
  useSetHasProperties,
  useIsAddingNewPropertyPanelVisible,
  useHasProperties,
} from '../../leaseAgreementStore';
import PropertyUnitFormStep from './PropertyUnitFormStep';
import AddPropertyFooter from '../Footer/AddPropertyFooter';

const PropertyFormStep = () => {
  const { isMax768: isMobile } = useBreakPoints();
  const cardStyles = unitCardStyles(isMobile, true, isMobile);
  const formik = useFormikContext();
  const { setFieldValue, values } = formik;
  const setSelectedPropertyObject = useSetSelectedPropertyObject();
  const selectedPropertyObject = useSelectedPropertyObject();
  const isUnitPanelVisible = useIsUnitPanelVisible();
  const setIsPropertyStepValid = useSetIsPropertyStepValid();
  const setIsUnitStepValid = useSetIsUnitStepValid();
  const setHasProperties = useSetHasProperties();
  const hasProperties = useHasProperties();
  // Add new property panel
  const setIsAddingNewPropertyPanelVisible = useSetIsAddingNewPropertyPanelVisible();
  const isAddingNewPropertyPanelVisible = useIsAddingNewPropertyPanelVisible();
  // Succes toast
  const toast = useToast();
  const handleOnShowSuccessToast = (isPropertySaved, newProperty) => {
    if (isPropertySaved) {
      // refetch properties
      refetch();
      handleSelectedProperty({
        address: {
          address: variables.address,
          city: variables.city,
          state: variables.state,
          zip: variables.zip,
        },
        units: newProperty.units,
        id: newProperty.id,
      });
      setIsPropertyStepValid(true);
      setIsAddingNewPropertyPanelVisible(false);
      setFieldValue('propertyId', newProperty.id);
      setFieldValue(
        'propertyUnitId',
        newProperty.units[0]?.length === 1 ? newProperty.units[0].id : null
      );
    }
    return toast({
      position: 'bottom-left',
      description: 'Property created successfully',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleOnShowErrorToast = () => {
    return toast({
      position: 'bottom-left',
      description: 'Something went wrong adding the property',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  };

  const [createProperty] = useMutation(CREATE_PROPERTY_SIMPLE, {
    update: (cache, { data: { createProperty: newProperty } }) => {
      const newPropertyRef = cache.writeFragment({
        data: newProperty,
        fragment: FRAGMENT_PROPERTY_SIMPLE,
      });
      cache.modify({
        fields: {
          property(existingProperties = []) {
            return [...existingProperties, newPropertyRef];
          },
        },
      });
    },
  });
  const newPropertyInitialValues = () => getInitialValues(null, true);
  const [variables, setVariables] = useState(newPropertyInitialValues());
  const [isNewPropertyValid, setIsNewPropertyValid] = useState(false);
  const [hasEnteredAddress, setHasEnteredAddress] = useState(false);
  const setNewPropertyFormVariables = (newVariables) =>
    setFormData(newVariables, setVariables, variables);
  const formRef = useRef();

  // Query for landlord's properties
  const {
    error: propertiesError,
    loading: propertiesLoading,
    data: properties,
    refetch,
  } = useQuery(GET_PROPERTIES);
  // Data TODO: sort properties
  const [sortedProperties, setSortedProperties] = useState(
    sortWithString(properties?.property || [], 'name')
  );
  useEffect(() => {
    if (properties?.property?.length === 0) {
      setIsAddingNewPropertyPanelVisible(true);
      setHasProperties(false);
    } else if (properties?.property?.length > 0) {
      setIsAddingNewPropertyPanelVisible(false);
      setHasProperties(!propertiesLoading && !propertiesError && properties?.property?.length > 0);
      setSortedProperties(sortWithString(properties?.property || [], 'name'));
    }
  }, [propertiesLoading, propertiesError, properties]);

  const [clickedPropertyId, setClickedPropertyId] = useState(formik?.values?.propertyId || '');

  const handleSelectedProperty = (property) => {
    setClickedPropertyId(property.id);
    setSelectedPropertyObject(property);
    setFieldValue('propertyId', property.id);
    if (property.id) {
      setIsPropertyStepValid(true);
    } else {
      setIsPropertyStepValid(false);
    }

    setClickedPropertyId(property.id);
  };

  const handleSelectedUnit = (unitId) => {
    if (unitId) {
      setIsUnitStepValid(true);
      setFieldValue('propertyUnitId', unitId);
    } else {
      setIsUnitStepValid(false);
    }
  };

  useEffect(() => {
    setSortedProperties(sortWithString(properties?.property || [], 'name'));
  }, [properties, propertiesLoading]);

  const handleIsPropertyValid = (val) => {
    setIsNewPropertyValid(val);
  };

  const handleSave = () => {
    handlePropertySave({
      createProperty,
      property: null,
      variables,
      handleOnShowSuccessToast,
      handleOnShowErrorToast,
    });
  };
  useEffect(() => {
    if (variables.address !== '') {
      const pAddress = variables.address?.trim();
      setNewPropertyFormVariables({ propertyName: pAddress });
    }
  }, [variables.address]);

  useEffect(() => {
    if (values.propertyId && properties) {
      setSelectedPropertyObject(
        properties?.property?.find(
          (property) =>
            property.id === values.propertyId ||
            property.units?.some((unit) => unit.id === values.unitId)
        )
      );
    }
  }, [values.propertyId, values.propertyUnitId, properties]);

  const renderContent = () => {
    if (isUnitPanelVisible && !propertiesLoading && !propertiesError) {
      return (
        <Box {...panelFormResponsiveStyles(isMobile)}>
          <Header hasProperties={hasProperties} isUnitPanelVisible={isUnitPanelVisible} />
          <PropertyUnitFormStep
            sortedProperties={sortedProperties || []}
            selectedProperty={selectedPropertyObject}
            selectedUnit={values?.propertyUnitId}
            setSelectedUnit={handleSelectedUnit}
          />
        </Box>
      );
    }
    if (isAddingNewPropertyPanelVisible) {
      return (
        <ChakraProvider theme={habitatTheme}>
          <Box {...panelFormResponsiveStyles(isMobile)}>
            <Header hasProperties={false} />
            <AddPropertyFormLayout
              shortForm
              {...{
                showTitle: false,
                initialValues: newPropertyInitialValues(),
                setFormVariables: setNewPropertyFormVariables,
                formRef,
                property: null,
                hasEnteredAddress,
                setHasEnteredAddress,
                setIsValid: handleIsPropertyValid,
              }}
            />
            <AddPropertyFooter
              isNewPropertyValid={isNewPropertyValid}
              saveNewProperty={handleSave}
            />
          </Box>
        </ChakraProvider>
      );
    }
    return (
      <ChakraProvider theme={habitatTheme}>
        <Box {...panelFormResponsiveStyles(isMobile)}>
          <Header hasProperties={hasProperties} />
          {propertiesLoading || propertiesError ? (
            <Flex justifyContent="center" alignItems="center" h="100%" color="brand.blue.600">
              <Spinner />
            </Flex>
          ) : (
            sortedProperties &&
            sortedProperties?.length >= 1 &&
            sortedProperties?.map((property) => (
              <BaselaneResponsiveCard
                {...cardStyles}
                onClick={() => handleSelectedProperty(property)}
              >
                <Flex
                  justifyContent="space-between"
                  w="100%"
                  gap="8px"
                  direction={{ base: 'column', sm: 'row' }}
                >
                  <Box>
                    <PropertyCard
                      propertyId={property.id}
                      propertyAddress={property?.address}
                      propertyUnitsLength={property?.units?.length}
                      selectedPropertyId={clickedPropertyId}
                    />
                  </Box>
                </Flex>
              </BaselaneResponsiveCard>
            ))
          )}
          <BaselaneButton
            id="unified-rc-add-property"
            pullLeft
            variant="transparent"
            palette="primary"
            leftIcon={<Icon16PlusCircle />}
            size="md"
            onClick={() => {
              setIsAddingNewPropertyPanelVisible(true);
            }}
          >
            {sortedProperties[0] ? 'Add another property' : 'Add a property'}
          </BaselaneButton>
        </Box>
      </ChakraProvider>
    );
  };

  return <Box>{renderContent()}</Box>;
};

export default PropertyFormStep;
