import { gql } from '@apollo/client';

export const GET_BANK_ACCOUNTS = gql`
  query BankAccounts {
    bankAccounts(input: {}) {
      id
      nickName
      accountName
      subAccounts {
        id
        nickName
        accountName
      }
      cards {
        id
        nickname
        last4Digits
        accountName
      }
    }
  }
`;
