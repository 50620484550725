// @flow
import React, { useState, useContext, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { useStatsigClient } from '@statsig/react-bindings';
import { useLazyQuery, useMutation } from '@apollo/client';
import { BaselaneButton, BaselaneButtonIcon } from '@shared/components';
import { CASH_FLOW_TAX_PACKAGE } from '@core/constants/routes';
import { GET_SLIM_TRANSACTIONS, CREATE_TAX_PACKAGE } from '@core/apollo/queries';
import UserContext from '@contexts/UserContext';
import TransactionContext from '@contexts/TransactionContext';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { Icon16Download } from '@icons/16px';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { trackUser } from '@features/Authentication/helpers/tracking.helpers';
import DownloadTaxPackageModal from './DownloadTaxPackageModal';
import { csvDataMapper, csvHeaders } from '../helpers/transactionsFilters.helpers';
import { MODAL_STATUS } from '../helpers/downloadModal.helpers';

type Props = {
  showHiddenTransactions: boolean,
  filename?: String,
  handleExternalCallBack?: Function,
  handlePreventDownloadClick?: Function,
  handleExternalDownloadClick?: Function,
  isPreventDownload?: boolean,
  datePresetFilter: String,
  children?: any,
  totalDocuments: Number,
  externalDownloadIsLoading?: boolean,
  hideButton: Boolean,
};

function DownloadTrxs({
  showHiddenTransactions,
  filename = 'Transactions',
  handleExternalCallBack = () => {},
  handlePreventDownloadClick = () => {},
  handleExternalDownloadClick = () => {},
  isPreventDownload = false,
  datePresetFilter,
  children = null,
  totalDocuments,
  externalDownloadIsLoading = false,
  hideButton,
}: Props): any {
  const { pathname } = useLocation();
  const { checkGate } = useStatsigClient();
  const { user } = useContext(UserContext);

  const { filters, categoryMap, propertyMap, accountMap } = useContext(TransactionContext);

  const newFilters = { ...filters, page: null, pageLimit: null }; // To get all transactions
  const CSVLinkRef = useRef();

  // Mutation
  const [createTaxPackage] = useMutation(CREATE_TAX_PACKAGE);

  const { filter } = filters;

  const [trxIds, setTrxIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalStatus, setModalStatus] = useState(MODAL_STATUS.CSV_DOWNLOAD);

  const clickDownloadHelper = () => {
    CSVLinkRef?.current?.link.click();
    trackUser(user, 'transactions_download');
    handleExternalCallBack();
  };

  const [downloadCsv, { data: slimTransactionsData, loading: downloadCsvIsLoading }] = useLazyQuery(
    GET_SLIM_TRANSACTIONS,
    {
      variables: { input: newFilters },
      onCompleted: (data) => {
        if (data) {
          const idArray = data?.transaction?.map((t) => t.id);
          setTrxIds(idArray);
          clickDownloadHelper();
        }
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const handleSendAttachemnts = () => {
    const variables = {
      input: {
        trxIds,
        fromDate: filter?.from,
        toDate: filter?.to,
      },
    };

    createTaxPackage({
      variables,
    })
      .then((res) => {
        setModalStatus(MODAL_STATUS.EMAIL_SENT);
        sendSegmentEvent('tax_package_download', {
          user_id: user?.id,
          date_filter: datePresetFilter,
        });
        if (res?.errors) {
          if (
            res?.errors?.find((error) => error?.message?.includes('Too many tax package requests'))
          ) {
            setModalStatus(MODAL_STATUS.TOO_MANY_REQUESTS);
          } else {
            setModalStatus(MODAL_STATUS.REQUEST_FAILED);
          }
        }
      })
      .catch((error) => {
        setModalStatus(MODAL_STATUS.REQUEST_FAILED);
        setShowModal(false);
      });
  };

  const handleRetry = () => {
    handleSendAttachemnts();
  };

  const onCloseBtnClick = () => {
    setShowModal(false);
    setModalStatus(MODAL_STATUS.CSV_DOWNLOAD);
  };

  const handleDownloadCSV = async () => {
    if (isPreventDownload) {
      handlePreventDownloadClick();
    } else {
      await downloadCsv();
      handleExternalDownloadClick();
    }
  };

  const handleDownloadTaxPackage = async () => {
    await handleDownloadCSV();
    setShowModal(true);
  };

  const handleTrxExport = () => {
    if (!isPreventDownload) sendSegmentEvent('export_transactions_csv');
    handleDownloadCSV();
  };

  const leftButtonEvent = () => {
    if (modalStatus.state === 'CSV_DOWNLOAD') {
      handleSendAttachemnts();
    } else {
      onCloseBtnClick();
    }
  };

  const tooltipText =
    'You have no Schedule E transactions for the selected time period and properties';

  useEffect(() => {
    if (hideButton) {
      handleTrxExport();
    }
  }, [hideButton]);

  return (
    <>
      {pathname === CASH_FLOW_TAX_PACKAGE && !hideButton && (
        <BaselaneButton
          size="sm"
          leftIcon={<Icon16Download />}
          variant="filled"
          palette="primary"
          isLoading={downloadCsvIsLoading || externalDownloadIsLoading}
          onClick={totalDocuments > 0 ? handleDownloadTaxPackage : handleDownloadCSV}
          isDisabled={isPreventDownload}
          tooltipText={isPreventDownload && tooltipText}
        >
          Download Tax Package
        </BaselaneButton>
      )}
      {pathname !== CASH_FLOW_TAX_PACKAGE && checkGate(FEATURE_GATES.CSV_GATE) && !hideButton && (
        <BaselaneButton
          size="md"
          variant="outline"
          palette="neutral"
          isLoading={downloadCsvIsLoading || externalDownloadIsLoading}
          isDisabled={isPreventDownload}
          onClick={handleTrxExport}
          {...(!checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) && { ml: 'auto' })}
        >
          Export
        </BaselaneButton>
      )}
      {pathname !== CASH_FLOW_TAX_PACKAGE && !checkGate(FEATURE_GATES.CSV_GATE) && !hideButton && (
        <BaselaneButtonIcon
          size="md"
          icon={<Icon16Download color="#001843" />}
          variant="outline"
          palette="neutral"
          isLoading={downloadCsvIsLoading || externalDownloadIsLoading}
          onClick={handleDownloadCSV}
        />
      )}

      {slimTransactionsData && (
        <>
          <CSVLink
            style={{ display: 'none' }}
            headers={csvHeaders}
            asyncOnClick
            data={csvDataMapper(
              slimTransactionsData?.transaction,
              categoryMap,
              accountMap,
              propertyMap,
              showHiddenTransactions
            )}
            ref={CSVLinkRef}
            filename={`${filename}.csv`}
          >
            .
          </CSVLink>
          {children}
        </>
      )}

      {showModal && (
        <DownloadTaxPackageModal
          isOpen={showModal}
          onClose={onCloseBtnClick}
          onCloseBtnClick={onCloseBtnClick}
          icon={modalStatus?.icon}
          title={modalStatus?.title}
          description={modalStatus?.description}
          leftButtonEvent={leftButtonEvent}
          rightButtonEvent={handleRetry}
          leftButtonText={modalStatus?.leftButtonText}
          rightButtonText={modalStatus?.rightButtonText}
          leftButtonProps={modalStatus?.leftButtonProps}
          rightButtonProps={modalStatus?.rightButtonProps}
        />
      )}
    </>
  );
}

export default DownloadTrxs;
