import { React, useEffect, useState } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { useApolloClient, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { BaselaneSpinner } from '@shared/components';
import { LEASES, UNIFIED_RENT_COLLECTION } from '@core/constants/routes';
import {
  generalRedirectToURC,
  specificRedirectToURC,
} from '@core/pages/UnifiedRentCollection/UnifiedLeaseUI/helpers/unifiedLeaseFlow.helpers';
import formatCurrency from '@core/utils/formatCurrency';
import { GET_LEASES } from '../queries';
import LeasesResponsiveList from '../LeasesResponsiveList';
import {
  FailedPaymentBanner,
  InvoicesFailedBanner,
  LeaseDraftBanner,
  StripeErrorBanner,
} from '../Banners';
import { sendRentCollectionStartedSegmentEvent } from '../hooks/useCreateLease';
import { staticLeasesData } from '../helpers/leasesEmptyState.helpers';
import EmptyState from '../EmptyState';
import ButtonLoadMore, { LEASES_PAGE_LIMIT } from './components/ButtonLoadMore';
import UpdateBankAccountDrawer from './components/UpdateBankAccountDrawer';

type LeasesActiveProps = {
  id: string,
  sortedLeases: Array<Object>,
  setSortedLeases: Function,
  bankProps: Object,
  refetchInvoiceSummary: Function,
  tenantData: Object,
  invoiceListDrawerRef: Object,
  setSelectedLeaseId: Function,
  selectedLeaseId: String,
  draftCount: Number,
  firstDraft: String,
  invoiceFailedSummary: Object,
  hasUserLevelStripeError: Boolean,
  payoutFailedBankAccounts: Array<Any>,
  getPropertyPicker: Function,
  setIsLeasesPageLoading: Function,
};

function LeasesActive({
  id = 'active-leases',
  sortedLeases,
  setSortedLeases,
  bankProps,
  refetchInvoiceSummary,
  tenantData,
  invoiceListDrawerRef,
  setSelectedLeaseId,
  selectedLeaseId,
  draftCount,
  firstDraft,
  invoiceFailedSummary,
  hasUserLevelStripeError,
  payoutFailedBankAccounts,
  getPropertyPicker,
  setIsLeasesPageLoading = () => {},
  ...rest
}: LeasesActiveProps): any {
  const [totalCount, setTotalCount] = useState(0);
  const [isBeginnerEmpty, setIsBeginnerEmpty] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [receivingBankAccountId, setReceivingBankAccountId] = useState(null);

  const { loading, data, refetch, fetchMore } = useQuery(GET_LEASES, {
    fetchPolicy: 'cache-first',
    variables: { input: { state: ['SCHEDULED'], page: 1, pageLimit: LEASES_PAGE_LIMIT } },
  });

  const navigate = useNavigate();

  const { cache } = useApolloClient();

  const startRentCollection = (newLease) => {
    const search = `?unit=${firstDraft}`;
    const state = { from: LEASES };
    const pathname = UNIFIED_RENT_COLLECTION;
    if (!firstDraft || newLease) {
      generalRedirectToURC({ navigate, pathname, state });
    } else {
      specificRedirectToURC({ navigate, pathname, state, search });
    }
    sendRentCollectionStartedSegmentEvent(cache);
  };

  const handleInvoiceListDrawerOpen = ({ filters }) => {
    const { date, dateType, state, leaseId } = filters ?? {};
    if (date || dateType || state || leaseId) {
      const filterObject = {};
      if (leaseId) filterObject.leaseId = leaseId;
      if (date) filterObject.date = date;
      if (dateType) filterObject.dateType = dateType;
      if (state) filterObject.state = state;
      navigate('/leases', { state: filterObject });
    }
  };

  useEffect(() => {
    if (data && !loading) {
      const {
        leases: { leases },
      } = data || {};
      setSortedLeases([...leases]);
      setIsBeginnerEmpty(data?.leaseDetail?.isEmpty);
      setIsEmpty(leases?.length === 0);
      setTotalCount(data?.leases?.totalCount || 0);
    }
  }, [data]);

  return (
    <>
      {/* main list loader */}
      {loading && !sortedLeases && <BaselaneSpinner mt={10} />}

      {/* onboarding empty state */}
      {isBeginnerEmpty && !loading && (
        <>
          <EmptyState {...{ getPropertyPicker }} />
          <LeasesResponsiveList
            {...{
              bankProps,
              refetchInvoiceSummary,
              tenantData,
              sortedLeases: staticLeasesData,
              invoiceListDrawerRef,
              setSelectedLeaseId,
              selectedLeaseId,
              tabName: 'Active',
              refetch,
            }}
          />
        </>
      )}

      {/* regular empty state */}
      {isEmpty && !loading && !isBeginnerEmpty && (
        <>
          {hasUserLevelStripeError && <StripeErrorBanner />}

          {/* Open Drafts Blue Info Bar */}
          {!!draftCount && <LeaseDraftBanner {...{ draftCount, startRentCollection }} />}
          <VStack w="100%" h="174px" justifyContent="space-around" textAlign="center">
            <Text textStyle="headline-md" color="brand.neutral.700" mt="24px">
              No Active Rent Collections
            </Text>
            <Text textStyle="sm" mb="24px">
              Once a new rent collection is created, it will appear here.
            </Text>
          </VStack>
        </>
      )}

      {/* Non-Empty State */}

      {/* Failed Invoices Banner */}
      {!isEmpty && !!invoiceFailedSummary?.count && (
        <InvoicesFailedBanner
          {...{
            failedAmount: formatCurrency(invoiceFailedSummary?.amount).inDollars,
            failedCount: invoiceFailedSummary?.count,
            showFailedInvoices: () =>
              handleInvoiceListDrawerOpen({
                filters: { dateType: 'DUE_DATE', state: 'PAYMENT_FAILED' },
              }),
          }}
        />
      )}
      {!isEmpty && hasUserLevelStripeError && <StripeErrorBanner />}
      {!isEmpty &&
        !hasUserLevelStripeError &&
        payoutFailedBankAccounts?.map((failedAcc) => {
          const { payoutFailedInvoice } = failedAcc ?? {};
          return (
            payoutFailedInvoice?.count > 0 && (
              <FailedPaymentBanner
                {...{
                  key: failedAcc?.id,
                  account: failedAcc,
                  setReceivingBankAccountId,
                }}
              />
            )
          );
        })}
      {!isEmpty && !!draftCount && <LeaseDraftBanner {...{ draftCount, startRentCollection }} />}
      {!isEmpty && !isBeginnerEmpty && (
        <>
          <LeasesResponsiveList
            sortedLeases={sortedLeases}
            bankProps={bankProps}
            refetchInvoiceSummary={refetchInvoiceSummary}
            tenantData={tenantData}
            invoiceListDrawerRef={invoiceListDrawerRef}
            setSelectedLeaseId={setSelectedLeaseId}
            selectedLeaseId={selectedLeaseId}
            tabName="Active"
            refreshLeases={refetch}
            leasesAreArchived
          />
          <ButtonLoadMore
            totalCount={totalCount}
            currentCount={sortedLeases?.length}
            fetchMore={fetchMore}
            leaseState={['SCHEDULED']}
          />
        </>
      )}
      {/* Update Receiving BA Drawer */}
      <UpdateBankAccountDrawer
        bankAccountId={receivingBankAccountId}
        setBankAccountId={setReceivingBankAccountId}
        tenantData={tenantData}
        banksData={bankProps?.banksData || {}}
        refreshLeases={refetch}
        refetchInvoiceSummary={refetchInvoiceSummary}
        refetchBankAccounts={bankProps?.refetchBankAccounts || {}}
        setIsLeasesPageLoading={setIsLeasesPageLoading}
      />
    </>
  );
}

export default LeasesActive;
