import { dateFormatter } from '@core/utils/formatDate';

export const ONGOING = 'Ongoing';
export const leaseAgreementsListConfig = {
  columns: [
    // 1. Property
    {
      isSortable: false,
      styles: {
        w: { lg: 'auto', base: '50%' },
        display: 'table-cell',
        contentContainerStyles: {
          px: 2,
          py: 2,
        },
      },
    },
    // 2. Lease duration
    {
      isSortable: false,
      styles: {
        w: { lg: '332px', md: 'none' },
        display: { md: 'table-cell', base: 'none' },
        verticalAlign: { sm: 'middle', base: 'top' },
        align: 'right',
        contentContainerStyles: {
          justifyContent: 'flex-start',
          px: 2,
          py: 2,
        },
      },
    },
    // 3. Status
    {
      isSortable: false,
      styles: {
        w: { md: '152px', base: '40%' },
        display: { md: 'table-cell' },
        verticalAlign: { sm: 'middle', base: 'top' },
        align: { md: 'left', base: 'right' },
        contentContainerStyles: {
          justifyContent: { md: 'flex-start', base: 'flex-end' },
          px: 2,
          py: 2,
        },
      },
    },
    // 4. CTA
    {
      isSortable: false,
      styles: {
        w: 'auto',
        display: { lg: 'table-cell', md: 'none' },
        verticalAlign: { sm: 'middle', base: 'top' },
        align: 'left',
        contentContainerStyles: {
          justifyContent: 'flex-end',
          px: 2,
          py: 2,
        },
      },
    },
  ],
};

export const leaseAgreementsListTableHeaderItems = (isMin768) => [
  {
    key: 'Property',
    label: 'Property',
    field: 'PROPERTY',
    isSortable: false,
  },
  {
    key: 'Lease duration',
    label: 'Lease duration',
    field: 'LEASE_DURATION',
    isSortable: false,
  },
  {
    key: 'Status',
    label: 'Status',
    field: 'STATUS',
    isSortable: false,
  },
  ...(isMin768
    ? [
        {
          key: 'CTA',
          label: '',
          field: 'CTA',
          isSortable: false,
        },
      ]
    : []),
];

export const documentStateVariants = {
  STARTED: 'simple-neutral',
  DRAFT: 'simple-neutral',
  READY_TO_CUSTOMIZE: 'simple-neutral',
  COMPLETED: 'simple-success',
  CANCELED: 'simple-danger',
  SIGNING_COMPLETE: 'simple-success',
  SIGNING_IN_PROGRESS: 'simple-primary',
  READY_TO_SIGN: 'simple-primary',
};

export const getLeaseButtonConfig = (state) => {
  switch (state) {
    case 'DRAFT':
      return {
        label: 'Edit draft',
        variant: 'tonal',
        palette: 'primary',
      };
    case 'READY_TO_CUSTOMIZE':
      return {
        label: 'Customize',
        variant: 'tonal',
        palette: 'primary',
      };
    case 'READY_TO_SIGN':
      return {
        label: 'Start signing',
        variant: 'tonal',
        palette: 'primary',
      };
    case 'SIGNING_IN_PROGRESS':
      return {
        label: 'View lease',
        variant: 'transparent',
        palette: 'primary',
      };
    case 'SIGNING_COMPLETE':
      return {
        label: 'View lease',
        variant: 'transparent',
        palette: 'primary',
      };
    default:
      return {
        label: 'View lease',
        variant: 'tranparent',
        palette: 'primary',
      };
  }
};

export const formatLeaseAgreementDuration = (startDate, endDate, leaseType) => {
  if (!startDate && !endDate) return '';
  if (!startDate) return `- ${endDate ? dateFormatter(endDate) : ''}`;
  if (leaseType === 'MONTHLY') return `${dateFormatter(startDate)} - ${ONGOING}`;
  if (!endDate) return `${dateFormatter(startDate)} -`;
  return `${dateFormatter(startDate)} - ${dateFormatter(endDate)}`;
};

export const formatStateLabel = (state) => {
  return state
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/^\w/, (c) => c.toUpperCase());
};

export const getDisplayNameLabel = (agreement) => {
  return (
    agreement?.documentName ||
    (agreement?.propertyName && agreement?.propertyUnitName && agreement?.tenantProfileMetadata
      ? `${agreement?.propertyName} - ${agreement?.propertyUnitName} - ${agreement?.tenantProfileMetadata}`
      : agreement?.propertyName || '')
  );
};

export const getLeasesWithTenantInfo = (
  leasesData,
  tenantProfiles,
  leaseAgreementId,
  existingLinkedLeases
) => {
  return (
    leasesData?.leases?.leases
      ?.map((lease) => {
        const tenant = tenantProfiles?.find((t) => t.id === lease.tenantProfileId);
        if (!tenant) return null;

        return {
          leaseAgreementId,
          leaseId: lease.id,
          tenantProfileId: lease.tenantProfileId,
          propertyUnitId: lease.propertyUnitId,
          startDate: lease.startDate,
          endDate: lease.endDate,
          tenantFirstName: tenant?.firstName,
          tenantLastName: tenant?.lastName,
          isInitiallySelected: existingLinkedLeases?.some((item) => item.id === lease?.id),
        };
      })
      ?.filter(Boolean)
      ?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate)) || []
  );
};
