import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import LogRocket from 'logrocket';
import ServiceBell from '@servicebell/widget';
import { useStatsigClient } from '@statsig/react-bindings';
import { useUserAccessibleWorkspace, useCurrentWorkspace } from '@shared/hooks';
import {
  latchelPartnerKey,
  serviceBellApiKey,
  redditPixelId,
  reactNodeEnv,
} from '@core/constants/envVars';
import UserContext from '@contexts/UserContext';
import { AnalyticsProvider } from '@contexts/AnalyticsContext';
import { BaselaneSpinner } from '@core/components/Shared/components';
import ZendeskWrapper from './ZendeskWrapper';

/**
 * Note: The purpose of this component is to intialize any scripts or providers
 * that behave differently when the user is logged in.
 *
 * Current Scripts: Zendesk, Statsig, Reddit, Logrocket and ServiceBell
 */

const InitializeWithUser = () => {
  const { user } = useContext(UserContext);
  const { client: statsigClient } = useStatsigClient();
  const { isLoading: accessibleWorkspaceDataIsLoading } = useUserAccessibleWorkspace();
  const { isLoadingCurrentWorkspace } = useCurrentWorkspace();

  const { email, firstName, lastName, id: userId } = user || {};
  const displayName = `${firstName || ''} ${lastName || ''}`;
  // ensures the useEffect below only executes once
  const [initializedWithUser, setInitializedWithUser] = useState(false);

  useEffect(() => {
    if (user && !initializedWithUser) {
      LogRocket.identify(userId, {
        name: displayName,
        email,
        role: 'LANDLORD',
      });

      // OMEGA-685: Service Bell
      ServiceBell('init', serviceBellApiKey);
      window.addEventListener('sb:initialized', () => {
        ServiceBell('identify', userId, {
          displayName,
          email,
        });
      });

      /**
       * Reddit tracking initialization
       */
      if (window.rdt && redditPixelId) {
        // reddit tracking with basic info
        window.rdt('init', redditPixelId, {
          email,
          externalId: userId,
        });
      }

      /**
       * Add user identifier to initialized statsig client
       */
      const { user: statsigUser } = statsigClient.getContext();
      if (user.id !== statsigUser.userID) {
        (async () => {
          await statsigClient.updateUserAsync({ ...statsigUser, userID: user.id });
        })();
      }
      setInitializedWithUser(true);
    }
  }, [user]);

  // Initialize Latchel SDK with key. Initializing here so it only happens one time.
  useEffect(() => {
    const LatchelSandbox = 'https://staging-sdk.lvn-stage.com/demo/bundle.js';
    const LatchelDefault = 'https://sdk.latchel-cd.app/bundle.js';

    const script = document.createElement('script');
    script.src = reactNodeEnv === 'production' ? LatchelDefault : LatchelSandbox;
    script.type = 'text/javascript';
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (window.LatchelPartnerSDK) {
      window.LatchelPartnerSDK.init(latchelPartnerKey);
    }
  }, [window.LatchelPartnerSDK]);

  return !initializedWithUser || accessibleWorkspaceDataIsLoading || isLoadingCurrentWorkspace ? (
    <BaselaneSpinner />
  ) : (
    <ZendeskWrapper user={user} handle="withUser">
      <AnalyticsProvider>
        <Outlet />
      </AnalyticsProvider>
    </ZendeskWrapper>
  );
};

export default InitializeWithUser;
