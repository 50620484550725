import React from 'react';
import { isMobile } from 'react-device-detect';
import { Tabs } from '@chakra-ui/react';

import { tabsStyles } from '../styles/tabs.styles';

type TabsComponentProps = {
  children: any,
  tabIndex: Number,
  styles?: Object,
  handleTabChange: Function,
  orientation?: string,
};

// TODO: to add variant for this style of tab when we work on adding override styles for tab components
function TabsComponent({
  children,
  tabIndex,
  styles = {},
  handleTabChange,
  orientation = 'vertical',
}: TabsComponentProps): any {
  return (
    <Tabs
      orientation={orientation}
      variant="unstyled"
      index={tabIndex}
      onChange={handleTabChange}
      {...tabsStyles(isMobile)}
      {...styles}
    >
      {children}
    </Tabs>
  );
}

export default TabsComponent;
