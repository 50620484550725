import React, { useState, useMemo, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import useTwoFactor from '@core/components/Shared/components/TwoFactorVerificationPopUp/hooks/useTwoFactor';
import {
  setTokenData,
  getIsTokenExpired,
  getIsTokenValid,
  getUnitOTPVerification,
  handleOTPPopupClose,
  handleOTPPopupOpen,
  handleNBOnVerifyClick,
  getTokenData,
} from '@shared/helpers/otp.helpers';
import useBankSummary from '../../hooks/useBankSummary';
import { USER_SENSITIVE_TOKEN } from '../../components/NativeBankingPage/queries';

const UnitOtpContext = React.createContext({});

// The error the BE sends when the user's OTP has expired
export const OTP_REQUIRED_ERROR_CODE = 'OTP_REQUIRED';

// Arbitrary error value that is passed when the OTP promise is rejected
export const OTP_POPUP_DISMISSED = 'unit-otp-popup-dismissed';

export const UnitOtpProvider = ({ children }: any) => {
  const [bankId, setBankId] = useState(null);

  // Workaround that makes React store the callbacks,
  // rather than treat them as a mutation functions.
  // These state variables are used to create a Promise,
  // where the resolve => .then((token) => {}) represents the user succeeding in the OTP verification
  // while the reject => .catch((error) => {}) represents them cancelling out of the OTP flow
  const [resolveOTPFlow, _setResolveOTPFlow] = useState(() => {});
  const setResolveOTPFlow = (resolve) => {
    _setResolveOTPFlow(() => resolve);
  };
  const [rejectOTPFlow, _setRejectOTPFlow] = useState(() => {});
  const setRejectOTPFlow = (reject) => {
    _setRejectOTPFlow(() => reject);
  };

  const { banksById } = useBankSummary();
  const { states, stateFunctions } = useTwoFactor();
  const { setOTPErrorCode } = stateFunctions;
  const [getOTP] = getUnitOTPVerification(setOTPErrorCode);

  const [getSensitiveToken] = useLazyQuery(USER_SENSITIVE_TOKEN, {
    fetchPolicy: 'network-only',
    errorPolicy: 'none',
  });

  const state = useMemo(() => {
    const isTokenExpired = getIsTokenExpired(bankId);
    const isTokenValid = getIsTokenValid(bankId);

    const isOtpRequired = (errors) => {
      if (Array.isArray(errors)) {
        return errors.some((error) => error?.extensions?.code === OTP_REQUIRED_ERROR_CODE);
      }
      if (typeof errors === 'object') {
        return errors?.message === OTP_REQUIRED_ERROR_CODE;
      }
      return false;
    };

    const props = {
      ...states,
      ...stateFunctions,
      getOTP,
      bankId,
      phoneNumber: banksById[bankId]?.unitAccount?.phoneNumber,
      /**
       * initiates verfication of entered code
       * @param {*} otpCode
       * @returns
       */
      handleVerifyOnClick: (otpCode) => {
        return handleNBOnVerifyClick(otpCode, bankId, async () => {
          let unitAPISensitiveTokenResponse;
          try {
            unitAPISensitiveTokenResponse = await getSensitiveToken({
              variables: { otpCode, bankId: Number(bankId) },
            });
            if (unitAPISensitiveTokenResponse?.data) {
              const { unitAPISensitiveToken } = unitAPISensitiveTokenResponse.data;
              const isCurrentTokenValid = getIsTokenValid(unitAPISensitiveToken);
              if (isCurrentTokenValid) {
                setTokenData(unitAPISensitiveToken, bankId);
                handleOTPPopupClose(states, stateFunctions);
                // resolve the Promise, with the token as a parameter
                resolveOTPFlow(unitAPISensitiveToken);
                setOTPErrorCode(null);
                setResolveOTPFlow(null);
                return;
              }
            }
            setOTPErrorCode(100);
          } catch (e) {
            setOTPErrorCode(100);
          }
        });
      },
      /**
       * Executes whenever the OTP popup is closed
       * This rejects the promise, which has to be
       * caught using the .catch() block, otherwise it
       * shows up as an error.
       * This allows you to run some code should the
       * user close the OTP popup without completing
       * OTP entry successfully.
       */
      onOTPPopupClose: () => {
        handleOTPPopupClose(states, stateFunctions);
        // reject the Promise
        rejectOTPFlow(OTP_POPUP_DISMISSED);
        setRejectOTPFlow(null);
      },
    };

    /**
     * Starts the OTP flow, by opening the global UnitOTPLayout popup
     * @param {String} currentBankId The bankId for the account that is being
     * operated on (which account depends on the operation)
     * @param {Boolean} forceOtp this flag is needed to force the OTP, for example
     * when the backend requires an OTP to occur, regardless of expiry time of token
     * @returns {Promise} resolves (with optional token parameter) when user enters
     * the correct OTP code; rejects when the user exits the OTP UI without entering
     * correct code.
     */
    const verifyUnitOtp = (currentBankId, forceOtp = false) => {
      setBankId(currentBankId);
      const otpPromise = new Promise((resolve, reject) => {
        if (getIsTokenExpired(currentBankId) || forceOtp) {
          setResolveOTPFlow(resolve);
          setRejectOTPFlow(reject);
          handleOTPPopupOpen(getOTP, currentBankId, stateFunctions);
        } else {
          resolve(getTokenData(currentBankId));
        }
      });
      return otpPromise;
    };

    /**
     * Shorthand alias for the verifyUnitOtp function, with forceOtp set to true
     * @param {String} currentBankId The bankId for the account that is being
     * operated on (which account depends on the operation)
     * @returns {Promise} resolves when user enters the correct OTP code, rejects
     * when the user exits the OTP UI without entering correct code
     */
    const forceVerifyUnitOtp = (currentBankId) => {
      return verifyUnitOtp(currentBankId, true);
    };

    /**
     * A no-op function that does nothing, that can be used to
     * supply to the .catch() block, as that has to be provided
     * even if you want to disregard the result
     */
    const ignore = () => {
      /* do nothing */
    };

    const newState = {
      // For consumption by UnitOTPLayout to configure the OTP UI
      props,
      // For implementing logic in places where OTP is needed
      isTokenExpired,
      isTokenValid,
      setBankId,
      verifyUnitOtp,
      forceVerifyUnitOtp,
      ignore,
      isOtpRequired,
    };
    return newState;
  }, [bankId, stateFunctions]);

  return <UnitOtpContext.Provider value={state}>{children}</UnitOtpContext.Provider>;
};

export const UnitOtpConsumer = UnitOtpContext.Consumer;

// Convenience hook
export const useUnitOtp = () => {
  return useContext(UnitOtpContext);
};
export default UnitOtpContext;
