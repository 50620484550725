import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  Text,
  Stack,
  Box,
} from '@chakra-ui/react';
import { WORKSPACE_OWNER_VERIFY_NAME } from '@routes';
import { BaselaneButton } from '@shared/components';
import IconProfileInProgress from '@icons/manual/IconProfileInProgress';

const AddProfileDetailsAlert = ({ onClose, isOpen }: { onClose: Function, isOpen: Boolean }) => {
  const alertCancelRef = useRef(null);
  const navigate = useNavigate();

  const handleOwnerVerification = () => {
    onClose();
    navigate(WORKSPACE_OWNER_VERIFY_NAME);
  };
  return (
    <AlertDialog
      leastDestructiveRef={alertCancelRef}
      onClose={onClose}
      isOpen
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="sm"
    >
      <AlertDialogOverlay />
      <AlertDialogContent p={4} minHeight="168px" maxHeight="calc(100% - 100px)" overflow="auto">
        <Stack spacing={2} mb="3">
          <Stack spacing={0.5}>
            <Box mb={2}>
              <IconProfileInProgress />
            </Box>
            <AlertDialogHeader p={0}>
              <Text textStyle="headline-md">Add profile details to invite collaborators</Text>
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody p={0}>
              <Text textStyle="sm">
                To invite a collaborator, add a few missing details on your profile.
              </Text>
            </AlertDialogBody>
          </Stack>
          ;
        </Stack>
        <Stack direction="row" w="100%" spacing={1.5}>
          <BaselaneButton
            size="md"
            variant="filled"
            palette="primary"
            onClick={handleOwnerVerification}
            isFullWidth
          >
            Add profile details
          </BaselaneButton>
        </Stack>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AddProfileDetailsAlert;
