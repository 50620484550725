import React, { useRef } from 'react';
import { useMutation } from '@apollo/client';
import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  Text,
  Stack,
  Box,
} from '@chakra-ui/react';
import { useCurrentWorkspace } from '@shared/hooks';
import { UPDATE_USER_METADATA } from '@core/components/UserProfilePage/queries';
import { Icon16Newwindow } from '@icons/16px';
import IconLimitedBankingAccess from '@icons/manual/IconLimitedBankingAccess';
import BaselaneButton from '../BaselaneButton';
import BaselaneLink from '../BaselaneLink';

const BaselaneLimitedBankingAccessAlert = ({ onClose }: { onClose: Function }) => {
  const [updateUserMetadata] = useMutation(UPDATE_USER_METADATA);
  const {
    activeWorkspaceId,
    isLoadingCurrentWorkspace,
    workspaceMetadata,
    refetchCurrentWorkspace,
  } = useCurrentWorkspace();
  const { metadata } = workspaceMetadata || {};
  const alertCancelRef = useRef(null);
  const url =
    'https://support.baselane.com/hc/en-us/articles/29873891217307-How-to-Manage-Multi-User-Access-in-Your-Baselane-Workspace';

  const handleConfirmation = async () => {
    await updateUserMetadata({
      variables: { metadata: { ...metadata, hideBankingAccessModal: activeWorkspaceId } },
      update: (cache, { data: { updateUser: updatedUser } }) => {
        cache.modify({
          id: cache.identify(updatedUser),
          fields: {
            metadata: () => updatedUser?.metadata,
          },
        });
      },
    })
      .then(() => {
        refetchCurrentWorkspace();
      })
      .catch((err) => console.debug(err));
  };

  return (
    <AlertDialog
      leastDestructiveRef={alertCancelRef}
      onClose={handleConfirmation}
      isOpen={metadata?.hideBankingAccessModal !== activeWorkspaceId && !isLoadingCurrentWorkspace}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="sm"
    >
      <AlertDialogOverlay />
      <AlertDialogContent p={4} minHeight="168px" maxHeight="calc(100% - 100px)" overflow="auto">
        <Stack spacing={2} mb="3">
          <Stack spacing={0.5}>
            <Box mb={2}>
              <IconLimitedBankingAccess />
            </Box>
            <AlertDialogHeader p={0}>
              <Text textStyle="headline-md">You have limited banking access in this workspace</Text>
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody p={0}>
              <Text textStyle="sm">
                You can view accounts and balances, and tag transactions, but cannot move money,
                access card details, or make account changes.
              </Text>
              <BaselaneLink
                size="sm"
                variant="primary"
                rightIcon={<Icon16Newwindow />}
                href={url}
                isExternal
                target="_blank"
              >
                Learn more
              </BaselaneLink>
            </AlertDialogBody>
          </Stack>
          ;
        </Stack>
        <Stack direction="row" w="100%" spacing={1.5}>
          <BaselaneButton
            size="md"
            variant="filled"
            palette="primary"
            onClick={() => handleConfirmation()}
            isFullWidth
          >
            Got it
          </BaselaneButton>
        </Stack>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default BaselaneLimitedBankingAccessAlert;
