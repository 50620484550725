import React, { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { HStack, Heading, ChakraProvider, useDisclosure } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';

import HeaderNavWrapper from '@shared/layouts/HeaderNavWrapper';
import { BaselaneButton } from '@shared/components';
import { CSV_IMPORT, CSV_IMPORT_UPLOAD, TRANSACTIONS } from '@routes';
import habitatTheme from '@core/themeHabitat';
import useCSVImportStore from '@store/CSVImport';

import CSVFlow from './CSVFlow';
import CancelCSVImportPopup from './CancelCSVImportPopup';

const CSVImport = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { getIsFlowDirty, setToInitialValues } = useCSVImportStore(
    useShallow((state) => ({
      getIsFlowDirty: state.getIsFlowDirty,
      setToInitialValues: state.setToInitialValues,
    }))
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  // if users refresh page or get timed out bring users to first step again
  useEffect(() => {
    if (!location.pathname.includes(`/${CSV_IMPORT}/${CSV_IMPORT_UPLOAD}`)) {
      navigate(`/${CSV_IMPORT}/${CSV_IMPORT_UPLOAD}`, { replace: true });
    }
  }, []);

  const handleCancelImportClose = () => {
    setToInitialValues();
    onClose();
    navigate(location?.state?.from ?? TRANSACTIONS);
  };

  return (
    <>
      {/* NOTE: If in the future we need to access this page from another page we can pass in from: /pathname in the location state. */}
      {/* So if when users click cancel it will redirect to that page instead of defaulting to TRX page */}
      {/* See inside CancelCSVImportPopup for the nav logic if user chooses to Cancel import */}
      <CancelCSVImportPopup
        isOpen={isOpen}
        onClose={onClose}
        handleCancelImportClose={handleCancelImportClose}
      />

      <HeaderNavWrapper
        hideBackButton
        hideFinishLater
        headerContent={
          <HStack justifyContent="space-between" width="100%">
            <Heading size="headline-lg">Import transactions</Heading>
            <BaselaneButton
              variant="transparent"
              palette="neutral"
              size="md"
              pullRight
              onClick={() => {
                if (getIsFlowDirty()) {
                  onOpen();
                } else {
                  handleCancelImportClose();
                }
              }}
            >
              Cancel
            </BaselaneButton>
          </HStack>
        }
      >
        <ChakraProvider theme={habitatTheme}>
          <CSVFlow />
        </ChakraProvider>
      </HeaderNavWrapper>
    </>
  );
};

export default CSVImport;
