import { NavLink } from 'react-router-dom';
import * as ROUTES from '@routes';
import icons from './icons';

export const groupOneNavElements = [
  {
    id: 'nav-get-started',
    Icon: icons.get_started,
    label: 'Getting started',
    props: {
      to: ROUTES.GET_STARTED,
      as: NavLink,
    },
  },
  {
    id: 'nav-dashboard',
    Icon: icons.dashboard,
    label: 'Dashboard',
    props: {
      to: ROUTES.DASHBOARD,
      as: NavLink,
    },
  },
];

export const groupTwoNavElements = [
  {
    id: 'nav-banking',
    Icon: icons.banks_cards,
    label: 'Baselane Banking',
    props: {
      to: ROUTES.NATIVE_BANK,
      as: NavLink,
    },
  },
  {
    id: 'nav-external-accounts',
    Icon: icons.external_accounts,
    label: 'External accounts',
    props: {
      to: ROUTES.EXTERNAL_ACCOUNTS,
      as: NavLink,
    },
  },
];

export const transfersPayments = {
  id: 'nav-transfers',
  Icon: icons.transfers,
  label: 'Transfers & payments',
  props: {
    to: ROUTES.TRANSFERS_PAYMENTS,
    as: NavLink,
  },
};

export const groupThreeNavElements = [
  {
    id: 'nav-transactions',
    Icon: icons.transactions,
    label: 'Transactions',
    props: {
      to: ROUTES.TRANSACTIONS,
      as: NavLink,
    },
  },
  {
    id: 'nav-analytics',
    Icon: icons.analytics_reporting,
    label: 'Analytics & reporting',
    props: {
      to: ROUTES.ANALYTICS_REPORTING,
      as: NavLink,
    },
  },
  {
    id: 'nav-property-analytics',
    Icon: icons.property_analytics,
    label: 'Property metrics',
    props: {
      to: ROUTES.KPIS,
      as: NavLink,
    },
  },
];

export const groupFourNavElements = [
  {
    id: 'nav-properties',
    Icon: icons.properties,
    label: 'Properties',
    props: {
      to: ROUTES.PROPERTIES,
      as: NavLink,
    },
  },
  {
    id: 'nav-tenant-screening',
    Icon: icons.tenant_screening,
    label: 'Tenant screening',
    props: {
      to: ROUTES.TENANT_SCREENING,
      as: NavLink,
    },
  },
  {
    id: 'nav-lease-documents-old',
    Icon: icons.lease_documents,
    label: 'Lease documents',
    props: {
      to: `${ROUTES.DASHBOARD}/${ROUTES.CREATE_LEASE_DOCUMENT}`,
      as: NavLink,
    },
  },
  {
    id: 'nav-lease-documents',
    Icon: icons.lease_documents,
    label: 'Lease agreements',
    props: {
      to: ROUTES.LEASE_AGREEMENT,
      as: NavLink,
    },
  },
  {
    id: 'nav-leases',
    Icon: icons.leases,
    label: 'Rent collection',
    props: {
      to: `${ROUTES.LEASES}?leaseId=all`,
      as: NavLink,
    },
  },
  {
    id: 'nav-tenants',
    Icon: icons.tenants,
    label: 'Tenants',
    props: {
      to: ROUTES.TENANTS,
      as: NavLink,
    },
  },
];

export const groupFiveNavElements = [
  {
    id: 'nav-partners',
    Icon: icons.partners,
    label: 'All partners',
    props: {
      to: ROUTES.PARTNERSHIPS,
      as: NavLink,
    },
  },
  {
    id: 'nav-insurance',
    Icon: icons.insurance,
    label: 'Insurance',
    props: {
      to: ROUTES.INSURANCE,
      as: NavLink,
    },
  },
  {
    id: 'nav-loans',
    Icon: icons.loans,
    label: 'Loans',
    props: {
      to: ROUTES.LOANS,
      as: NavLink,
    },
  },
];
