import { achFeeEnabledFeatureFlag } from '@core/constants/envVars';

import banking from '@core/components/OnboardingTriage/assets/banking.png';
import banking2x from '@core/components/OnboardingTriage/assets/banking@2x.png';
import rentCollection from '@core/components/OnboardingTriage/assets/rentcollection.png';
import rentCollection2x from '@core/components/OnboardingTriage/assets/rentcollection@2x.png';
import bookkeeping from '@core/components/OnboardingTriage/assets/bookkeeping.png';
import bookkeeping2x from '@core/components/OnboardingTriage/assets/bookkeeping@2x.png';
import tenant from '@core/components/OnboardingTriage/assets/tenant.png';
import tenant2x from '@core/components/OnboardingTriage/assets/tenant@2x.png';

export const getTabsListItems = () => {
  let tabsListItems = ['Members', 'Business Profile'];
  if (achFeeEnabledFeatureFlag === 'true') tabsListItems = [...tabsListItems, 'Rent Collection'];

  return tabsListItems;
};

export const membersListConfig = {
  columns: [
    // 1. Name
    {
      isSortable: false,
      styles: {
        w: { sm: 'auto', base: '60%' },
        display: 'table-cell',
      },
    },
    // 2. Role
    {
      isSortable: false,
      styles: {
        w: { '2lg': '320px', base: '176px' },
        align: 'left',
        contentContainerStyles: {
          justifyContent: 'flex-start',
        },
        display: { md: 'table-cell', base: 'none' },
      },
    },
    // 3. Status
    {
      isSortable: false,
      styles: {
        w: { '2lg': '152px', sm: '112px', base: '40%' },
        display: 'table-cell',
        verticalAlign: { sm: 'middle', base: 'top' },
        contentContainerStyles: {
          justifyContent: 'flex-end',
        },
      },
    },
  ],
};

export const membersListTableHeaderItems = [
  {
    key: 'Member',
    label: 'Member',
    field: 'MEMBER',
    isSortable: false,
  },
  {
    key: 'Role',
    label: 'Role',
    field: 'ROLE',
    isSortable: true,
  },
  {
    key: 'Status',
    label: 'Status',
    field: 'STATUS',
    isSortable: true,
  },
];

export const invitationSteps = {
  COLLABORATOR_DETAILS: 1,
  COLLABORATOR_ACCESS: 2,
};

export const getMemberStatus = (status) => {
  if (typeof status === 'undefined' || status === null) {
    return '';
  }

  const statusMap = {
    INVITE_SENT: 'Invite pending',
    INVITE_EXPIRED: 'Invite expired',
    ACCEPTED: 'Active',
    REVOKED: 'Invite revoked',
  };

  return statusMap[status] || '';
};

export const getMemberName = (firstName, lastName) => {
  const name = [firstName, lastName]
    .filter((value) => value != null && value !== '') // More specific filter
    .join(' ')
    .trim();

  return name.length > 0 ? name : null;
};

export const welcomeCardArray = [
  {
    id: 1,
    icon: banking,
    iconLarge: banking2x,
    alt: 'banking',
  },
  {
    id: 2,
    icon: rentCollection,
    iconLarge: rentCollection2x,
    alt: 'rent Collection',
  },
  {
    id: 3,
    icon: bookkeeping,
    iconLarge: bookkeeping2x,
    alt: 'bookkeeping',
  },
  {
    id: 4,
    icon: tenant,
    iconLarge: tenant2x,
    alt: 'tenant',
  },
];
