import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, GridItem, HStack, Stack, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import formatCurrency from '@core/utils/formatCurrency';
import { formatDate } from '@core/utils/formatDate';
import { Icon16ChevronRight } from '@icons/16px';
import InvoiceContext from '@contexts/InvoiceContext';
import QuickPayIconOrBadge from '@pages/LeasesPage/components/QuickPayIconOrBadge';

import { INVOICE_STATUS_TYPES } from '../../../constants';
import type { Invoices as InvoicesType } from '../../../types';
import {
  invoicePaymentDateStyles,
  statusLabelStyles,
  invoiceTableStyles,
} from '../../../styles/invoice.styles';

type ListItemProps = {
  invoice: InvoicesType,
  isLeaseLevel: boolean,
  sortedLeases: Array<Object>,
  hasMultipleUnits: boolean,
  index: Number,
  invoiceDetailsDrawerRef: any,
  invoiceDetailsDrawerActionBtnRef: any,
};

function ListItem({
  invoice,
  isLeaseLevel,
  sortedLeases,
  hasMultipleUnits,
  index,
  invoiceDetailsDrawerRef,
  invoiceDetailsDrawerActionBtnRef,
}: ListItemProps): any {
  const navigate = useNavigate();

  const { selectedInvoiceId, setSelectedInvoiceId } = useContext(InvoiceContext);
  const filters = JSON.parse(localStorage.getItem('filters')) || {};

  const {
    id,
    invoiceNumber,
    state,
    dueDate,
    originalDueDate,
    description,
    amount,
    isQuickPay,
    propertyUnit: { propertyId, propertyName, unitId, unitName },
  } = invoice;

  const paymentDate = invoice?.invoiceMarkedPaid
    ? new Date(Number(invoice?.invoiceMarkedPaidTimestamp)).toISOString()
    : invoice?.invoiceCharge?.paymentDate;
  const paymentTriggerType = invoice?.invoiceCharge?.paymentTriggerType;

  const pDate = DateTime.fromISO(paymentDate).startOf('day').toString();
  const dDate = DateTime.fromISO(dueDate).startOf('day').toString();

  const isDeleted = state === 'DELETED';
  const isCompleted = state === 'COMPLETED';
  const isScheduled = state === 'SCHEDULED';
  const isProcessed = state === 'PROCESSING';

  const isPropertyDeleted = !propertyId && !unitId;
  const isMultiUnit = isLeaseLevel
    ? hasMultipleUnits
    : sortedLeases?.find((lease) => lease.id === invoice.leaseId && lease.totalUnits > 1);

  const { row } = invoiceTableStyles(isLeaseLevel, isDeleted);

  const latePayment = isCompleted && pDate > dDate;
  const showPaymentDate = isCompleted && !!paymentDate;
  const showProcessedBagde = isCompleted || isProcessed;
  const showAutoPayLabel = isScheduled && paymentTriggerType === 'autoPay';

  const handleInvoiceDetailsDrawerOpen = () => {
    setSelectedInvoiceId(id);
    invoiceDetailsDrawerRef.current?.open();
  };

  const { icon, label, color } = INVOICE_STATUS_TYPES[state] ?? {};

  const paymentType = (
    <>
      <HStack>
        <Text {...row.truncated}>{description}</Text>
        {isQuickPay && (
          <QuickPayIconOrBadge
            isIconOnly
            isQuickPayProcessed={showProcessedBagde && isQuickPay}
            isQuickPayEnabled
          />
        )}
      </HStack>
      <Box {...{ ...row.subitem, ...row.truncated }}>
        {invoiceNumber ? `#${id}-${invoiceNumber}` : `#${id}`}
      </Box>
    </>
  );

  const formattedInvoiceDetails = {
    ...invoice,
    amount: formatCurrency(amount).inDollars,
    formattedDueDate: formatDate(dueDate),
    formattedOriginalDueDate: formatDate(originalDueDate),
    paymentType,
    paymentDate: formatDate(paymentDate, 'MMM D, YYYY'),
    state: showAutoPayLabel ? 'AUTOPAY' : state,
    stateElement: (
      <HStack alignItems="center">
        <Box>{icon}</Box>
        <Stack>
          <Text {...statusLabelStyles(color, isDeleted)}>
            {!showAutoPayLabel ? label : 'Auto-Pay'}
          </Text>
        </Stack>
      </HStack>
    ),
    latePayment,
    isQuickPaid: invoice?.isQuickPay,
  };

  const formattedDueDate = {
    date: formatDate(dueDate, 'MMM D'),
    year: formatDate(dueDate, 'YYYY'),
  };

  useEffect(() => {
    if (
      selectedInvoiceId &&
      selectedInvoiceId === id &&
      invoiceDetailsDrawerRef.current &&
      invoiceDetailsDrawerRef.current.isOpen === false
    ) {
      invoiceDetailsDrawerRef.current?.open();
      filters.invoiceId = id;
      filters.noRefresh = true;
      navigate('/leases', { state: filters });
    } else if (!selectedInvoiceId && invoiceDetailsDrawerRef?.current?.isOpen) {
      invoiceDetailsDrawerRef.current?.close();
    }
  }, [selectedInvoiceId, invoiceDetailsDrawerRef, invoiceDetailsDrawerRef.current?.isOpen]);

  return (
    <Grid
      ref={invoiceDetailsDrawerActionBtnRef}
      id={`invoice-${index}`}
      onClick={handleInvoiceDetailsDrawerOpen}
      {...row}
    >
      {/* Property */}
      {!isLeaseLevel && (
        <GridItem {...row.item('property')}>
          <Text {...row.property(isMultiUnit)}>
            {isPropertyDeleted ? 'Property Deleted' : propertyName}
          </Text>
          {isMultiUnit && !isPropertyDeleted && <Text {...row.subitem}>{unitName}</Text>}
        </GridItem>
      )}
      {/* Payment Type */}
      <GridItem id="payment-type" {...row.item('description')}>
        {formattedInvoiceDetails.paymentType}
      </GridItem>
      {/* Due Date */}
      <GridItem id="due-date" {...row.item('dueDate')}>
        <Text>{formattedDueDate.date}</Text>
        <Text {...row.subitem}>{formattedDueDate.year}</Text>
      </GridItem>
      {/* Amount */}
      <GridItem id="amount" {...row.item('amount')}>
        <Text>{formattedInvoiceDetails.amount}</Text>
        {latePayment && <Text {...row.latefee}>Paid Late</Text>}
      </GridItem>
      {/* Spacer */}
      {isLeaseLevel && <GridItem {...row.item('spacer')} />}
      {/* Payment Status */}
      <GridItem {...row.item('state')} id="payment-status">
        <HStack gap="0.75">
          <Box>{icon}</Box>
          <Box>
            <Text {...statusLabelStyles(color, isDeleted)}>
              {!showAutoPayLabel ? label : 'Auto-Pay'}
            </Text>
            {showPaymentDate && (
              <Text {...invoicePaymentDateStyles(isDeleted)}>
                {formatDate(paymentDate, 'MMM DD, YYYY')}
              </Text>
            )}
          </Box>
        </HStack>
      </GridItem>
      {/* Right Chevron Icon */}
      <GridItem {...row.item('details')}>
        <Box className="icon-chevron-right">
          <Icon16ChevronRight />
        </Box>
      </GridItem>
    </Grid>
  );
}

export default ListItem;
