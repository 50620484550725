import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  Box,
  Flex,
  Spacer,
  Stack,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useApolloClient, useQuery } from '@apollo/client';

import { LEASES, UNIFIED_RENT_COLLECTION } from '@routes';
import InvoiceContext from '@contexts/InvoiceContext';
import UserContext from '@contexts/UserContext';
import { BaselaneBankingBanner, BaselaneButton } from '@shared/components';
import {
  generalRedirectToURC,
  specificRedirectToURC,
} from '@pages/UnifiedRentCollection/UnifiedLeaseUI/helpers/unifiedLeaseFlow.helpers';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

import { tabStyles, tabListInternalStyles, tabContainerStyles } from '@shared/styles/tab.styles';

import PaymentSummary from './PaymentSummary';
import Invoices from './InvoicesSection';

import {
  tabPanelStyles,
  titleStyles,
  titleContainerStyles,
  baselaneBannerStyles,
} from './styles/leaseResponsive.style';

import { sendRentCollectionStartedSegmentEvent } from './hooks/useCreateLease';
import { getFormattedInput } from './InvoicesSection/DrawerBody/FilterPopup/helpers/filterPopup.helpers';
import LeasesActive from './panels/LeasesActive';
import LeasesExpired from './panels/LeasesExpired';
import LeasesArchived from './panels/LeasesArchived';
import { GET_LEASE_DETAIL_BASIC } from './queries';

type LeasesPageResponsiveUIProps = {
  invoiceSummaryData: Object,
  refetchInvoiceSummary: Function,
  bankProps: Object,
  tenantData: Object,
  draftCount: number,
  firstDraft: string,
  setIsLeasesPageLoading: Function,
};

function LeasesPageResponsiveUI({
  invoiceSummaryData,
  refetchInvoiceSummary,
  bankProps,
  tenantData,
  draftCount,
  firstDraft,
  setIsLeasesPageLoading,
}: LeasesPageResponsiveUIProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMinXL, isMin768 } = useBreakPoints();

  const [tabIndex, setTabIndex] = useState(0);
  const [stateRemovedQueryParams, setStateRemovedQueryParams] = useState(false);
  const [isBeginnerEmpty, setIsBeginnerEmpty] = useState(false);

  const { loading: loadingDetails, data: dataDetails } = useQuery(GET_LEASE_DETAIL_BASIC, {
    fetchPolicy: 'cache-first',
  });

  const { baselaneConnectedAccounts } = bankProps ?? {};
  const hasBaselaneBank = baselaneConnectedAccounts.length > 0;

  const {
    leaseInvoicesLoading,
    leaseInvoicesError,
    refetchLeaseInvoices,
    getLeaseInvoices,
    getLease,
    invoiceList,
    invoiceFilters,
    invoiceTotal,
    setInvoiceList,
    setInvoiceFilters,
    setInvoiceTotal,
    selectedInvoiceId,
    setSelectedInvoiceId,
    selectedLease,
    selectedLeaseId,
    setSelectedLeaseId,
    setNextPaymentsClicked,
    selectedProperty,
  } = useContext(InvoiceContext);

  const { user } = useContext(UserContext);
  const [sortedLeases, setSortedLeases] = useState([]);

  const { payoutFailedBankAccounts, invoiceFailedSummary, invoiceProcessingSummary } =
    invoiceSummaryData?.invoiceSummary ?? {};

  const hasUserLevelStripeError =
    (invoiceFailedSummary?.count > 0 || invoiceProcessingSummary?.count > 0) &&
    user?.stripeAccount?.payoutEnabled === false;

  const invoiceListDrawerRef = useRef();
  const invoiceDrawerActionBtnRef = useRef();

  const [isDesktop] = useMediaQuery('(min-width: 899px)', { ssr: false });

  // Get lease details if selected or queried or stored
  const { propertyName, propertyUnitName } = selectedLease || {};

  const { cache } = useApolloClient();

  const startRentCollection = (newLease) => {
    const search = `?unit=${firstDraft}`;
    const state = { from: LEASES };
    const pathname = UNIFIED_RENT_COLLECTION;
    if (!firstDraft || newLease) {
      generalRedirectToURC({ navigate, pathname, state });
    } else {
      specificRedirectToURC({ navigate, pathname, state, search });
    }
    sendRentCollectionStartedSegmentEvent(cache);
  };

  const refetchLease = () => {
    getLease();
  };

  const setFiltersToLocalStorage = (filterObject) => {
    localStorage.setItem('filters', JSON.stringify(filterObject));
  };

  const handleInvoiceListDrawerOpen = ({ filters }) => {
    const { date, dateType, state, leaseId } = filters ?? {};
    if (date || dateType || state || leaseId) {
      const filterObject = {};
      if (leaseId) filterObject.leaseId = leaseId;
      if (date) filterObject.date = date;
      if (dateType) filterObject.dateType = dateType;
      if (state) filterObject.state = state;
      navigate('/leases', { state: filterObject });
    }
  };

  const handleInvoiceListDrawerClose = () => {
    // from payments, so only next payments invoices are loaded
    setNextPaymentsClicked(false);
    // format object for local storage with only queried filters
    if (selectedLeaseId) {
      const filterObject = { leaseId: selectedLeaseId };
      setFiltersToLocalStorage(filterObject);
      navigate('/leases', { state: filterObject });
    } else {
      localStorage.removeItem('filters');
      navigate('/leases');
    }
    invoiceListDrawerRef.current?.close();
  };

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    const queryDate = params.get('date');
    const queryDateType = params.get('dateType');
    const queryState = params.get('state');
    const queryLeaseId = params.get('leaseId');
    const queryInvoiceId = params.get('invoiceId');

    // separate, for opening the lease terms on the lease details page
    const queryLeaseTerms = params.get('leaseTerms');
    if (queryLeaseId && queryLeaseTerms) {
      localStorage.setItem('leaseTerms', 'true');
    }

    // if we removed all query params, and the "stateRemovedQueryParams" is true, ignore
    if (!stateRemovedQueryParams) {
      const filtersInfo = JSON.parse(localStorage.getItem('filters'));
      const {
        date: dateInfo,
        dateType: dateTypeInfo,
        state: stateInfo,
        leaseId: leaseIdInfo,
        invoiceId: invoiceIdInfo,
      } = filtersInfo ?? {};

      const date = queryDate || dateInfo;
      const dateType = queryDateType || dateTypeInfo;
      const state = queryState || stateInfo;
      const leaseId = queryLeaseId || leaseIdInfo;
      const invoiceId = queryInvoiceId || invoiceIdInfo;

      // locally store filterObject or leaseId for automatic lease drawer opening
      if (
        (queryDate !== dateInfo ||
          queryDateType !== dateTypeInfo ||
          queryState !== stateInfo ||
          queryLeaseId !== leaseIdInfo ||
          queryInvoiceId !== invoiceIdInfo) &&
        queryLeaseId !== 'all'
      ) {
        // format object for history state with only queried filters
        const filterObject = {};
        if (date) filterObject.date = date;
        if (dateType) filterObject.dateType = dateType;
        if (state) filterObject.state = state;
        if (leaseId) filterObject.leaseId = leaseId;
        if (invoiceId) filterObject.invoiceId = invoiceId;

        // is this necessarY?
        if (location.pathname.toLowerCase() === '/leases/updateaccounts') {
          navigate('/leases/updateaccounts', { state: filterObject });
          return;
        }

        if (location.pathname.toLowerCase() === '/leases/quickpay_learnmore') {
          navigate('/leases/quickpay_learnmore', { state: filterObject });
          return;
        }

        navigate('/leases', { replace: true, state: filterObject });
      } else if (
        (!dateType && !date && !state && !leaseId && !invoiceId) ||
        queryLeaseId === 'all'
      ) {
        navigate('/leases', { replace: true });
        localStorage.removeItem('filters');
        localStorage.removeItem('leaseTerms');
      }
      setStateRemovedQueryParams(true);
    }
  }, [location?.search]);

  useEffect(() => {
    const { date, dateType, state, leaseId, invoiceId, noRefresh, noOpen } = location?.state ?? {};

    if (!invoiceId) {
      setNextPaymentsClicked(false);
    }
    if (stateRemovedQueryParams) {
      // format object for local storage with only queried filters
      const filterObject = {};
      if (date) filterObject.date = date;
      if (dateType) filterObject.dateType = dateType;
      if (state) filterObject.state = state;
      if (leaseId && leaseId !== 'all') filterObject.leaseId = leaseId;
      if (invoiceId) filterObject.invoiceId = invoiceId;
      setFiltersToLocalStorage(filterObject);
      // set invoice Filters
      setInvoiceFilters({
        state,
        dateType,
        date,
      });
      // format object for getLeaseInvoices input with only queried filters
      const inputObject = {};
      if (date) inputObject.selectedDateRange = date;
      inputObject.selectedViewBy = dateType ?? 'DUE_DATE'; // It is required.
      if (state) inputObject.selectedPaymentStatus = state;
      const input = getFormattedInput(inputObject);
      input.leaseId = leaseId;
      if (date || dateType || state) {
        if (!noRefresh) {
          getLeaseInvoices({ variables: { input } });
        }
        if (!noOpen) {
          // open invoice list drawer
          invoiceListDrawerRef.current?.open();
        }
      }
      if (leaseId && !date && !dateType && !state) {
        // no filters, make sure invoice list is closed, select lease id to open rc detail drawer
        setSelectedLeaseId(leaseId);
        invoiceListDrawerRef.current?.close();
        getLeaseInvoices({
          variables: { input: { dateType: 'DUE_DATE', leaseId: selectedLeaseId } },
        });
      }
      if (invoiceId) {
        setSelectedInvoiceId(invoiceId);
      } else {
        setSelectedInvoiceId(null);
      }
      if (!leaseId) {
        setSelectedLeaseId(null);
      }
      if (!date && !dateType && !state && !leaseId && !invoiceId) {
        localStorage.removeItem('filters');
        invoiceListDrawerRef.current?.close();
      }
    }
  }, [location?.state]);

  useEffect(() => {
    if (dataDetails?.leaseDetail?.isEmpty) {
      setIsBeginnerEmpty(dataDetails?.leaseDetail?.isEmpty);
    }
  }, [dataDetails?.leaseDetail?.isEmpty]);

  const getPropertyPicker = (showInEmptyState = false) => {
    return (
      <BaselaneButton
        variant="filled"
        palette="primary"
        size={showInEmptyState ? 'lg' : 'md'}
        onClick={() => {
          if (showInEmptyState) {
            sendSegmentEvent('add_rent_collection_started', {
              entry_point: 'baselane_rent_collection',
            });
          }
          startRentCollection(true);
        }}
      >
        Set Up Rent Collection
      </BaselaneButton>
    );
  };

  return (
    // Adding isMobile check to apply paddings for mobile devices above 899px
    <Stack p={isDesktop && !isMobile ? '0' : '16px 16px'} position="relative">
      {!loadingDetails && (
        <PaymentSummary
          {...{
            isEmpty: isBeginnerEmpty,
            invoiceSummaryData,
            setInvoiceFilters,
            invoiceDrawerActionBtnRef,
            handleInvoiceListDrawerOpen,
            getLeaseInvoices,
          }}
        />
      )}

      {/* Baselane Banking Promotion Banner */}
      {!hasBaselaneBank && (
        <BaselaneBankingBanner
          isNarrowOnly
          styles={{
            buttoncard: baselaneBannerStyles({ isMinXL }).buttoncard,
          }}
        />
      )}

      <Flex {...titleContainerStyles(isDesktop)}>
        <Text {...titleStyles}>Rent Collection</Text>
        <Spacer />
        <BaselaneButton
          id="set-up-rc-button"
          variant="filled"
          palette="primary"
          size="md"
          onClick={() => startRentCollection(true)}
        >
          Set Up Rent Collection
        </BaselaneButton>
      </Flex>

      {/* New RC Bottom Page with Tabs and new RC Card list */}
      <Box {...tabContainerStyles}>
        <Tabs
          index={tabIndex}
          orientation="horizontal"
          variant="unstyled"
          onChange={(index) => {
            setSortedLeases([]);
            setTabIndex(index);
          }}
          isLazy
        >
          <TabList {...tabListInternalStyles()}>
            <Tab id="leases-tab-active" key="leases-tab-active" {...tabStyles(isMin768)}>
              Active
            </Tab>
            <Tab id="leases-tab-expired" key="leases-tab-expired" {...tabStyles(isMin768)}>
              Expired
            </Tab>
            <Tab id="leases-tab-archived" key="leases-tab-archived" {...tabStyles(isMin768)}>
              Archived
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel {...tabPanelStyles} id="leases-panel-active" key="leases-panel-active">
              <LeasesActive
                bankProps={bankProps}
                refetchInvoiceSummary={refetchInvoiceSummary}
                tenantData={tenantData}
                invoiceListDrawerRef={invoiceListDrawerRef}
                setSelectedLeaseId={setSelectedLeaseId}
                selectedLeaseId={selectedLeaseId}
                draftCount={draftCount}
                firstDraft={firstDraft}
                invoiceFailedSummary={invoiceFailedSummary}
                hasUserLevelStripeError={hasUserLevelStripeError}
                payoutFailedBankAccounts={payoutFailedBankAccounts}
                getPropertyPicker={getPropertyPicker}
                setIsLeasesPageLoading={setIsLeasesPageLoading}
                sortedLeases={sortedLeases}
                setSortedLeases={setSortedLeases}
              />
            </TabPanel>
            <TabPanel {...tabPanelStyles} id="leases-panel-expired" key="leases-panel-expired">
              <LeasesExpired
                bankProps={bankProps}
                refetchInvoiceSummary={refetchInvoiceSummary}
                tenantData={tenantData}
                invoiceListDrawerRef={invoiceListDrawerRef}
                setSelectedLeaseId={setSelectedLeaseId}
                selectedLeaseId={selectedLeaseId}
                setIsLeasesPageLoading={setIsLeasesPageLoading}
                sortedLeases={sortedLeases}
                setSortedLeases={setSortedLeases}
              />
            </TabPanel>
            <TabPanel {...tabPanelStyles} id="leases-panel-archived" key="leases-panel-archived">
              <LeasesArchived
                bankProps={bankProps}
                refetchInvoiceSummary={refetchInvoiceSummary}
                tenantData={tenantData}
                invoiceListDrawerRef={invoiceListDrawerRef}
                setSelectedLeaseId={setSelectedLeaseId}
                selectedLeaseId={selectedLeaseId}
                setIsLeasesPageLoading={setIsLeasesPageLoading}
                sortedLeases={sortedLeases}
                setSortedLeases={setSortedLeases}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      {/* Invoices List Drawer */}
      <Invoices
        {...{
          isLeaseLevel: !!selectedLeaseId,
          propertyName,
          unitName: propertyUnitName,
          handleInvoiceListDrawerClose,
          leaseInvoicesLoading,
          leaseInvoicesError,
          invoiceList,
          setInvoiceList,
          refetchLeaseInvoices,
          refetchLease,
          refetchInvoiceSummary,
          invoiceListDrawerRef,
          invoiceDrawerActionBtnRef,
          invoiceFilters,
          setInvoiceFilters,
          invoiceTotal,
          setInvoiceTotal,
          sortedLeases,
          hasMultipleUnits: selectedProperty?.units.length > 1,
          isDontCleanAtClose: true,
          selectedInvoiceId,
          setSelectedInvoiceId,
          bankProps,
          tenantData,
        }}
      />
    </Stack>
  );
}

export default LeasesPageResponsiveUI;
