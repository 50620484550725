import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Stack,
  Text,
  Input,
  FormControl,
  Spinner,
  ChakraProvider,
  Flex,
} from '@chakra-ui/react';
import { sortBy } from 'lodash';
import MaskedInput from 'react-text-mask';
import { useQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import habitatTheme from '@core/themeHabitat';
import {
  BaselaneMessageCard,
  BaselaneButtonToggle,
  BaselaneButton,
  BaselaneFormLabel,
  BaselaneFormErrorMessage,
} from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import ExistingTenantSelect from '@pages/LeasesPage/LeaseSection/forms/TenantDetailsForm/ExistingTenantSelect';
import ExistingTenantForm from '@pages/LeasesPage/LeaseSection/forms/TenantDetailsForm/ExistingTenantForm';
import UserContext from '@contexts/UserContext';
import { phoneNumberMask } from '@core/utils/masks';
import { panelFormResponsiveStyles } from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';
import { GET_TENANT_ACTIVE_PROFILES } from '@pages/TenantsPage/queries/index';
import {
  useSetIsNewTenant,
  useSetExistingTenantsEmails,
  useShowServerTenantMessage,
} from '../../leaseAgreementStore';
import Header from '../Header';

function TenantFormStep() {
  const showServerTenantMessage = useShowServerTenantMessage();
  const isMax768 = useBreakPoints('768px');
  const { user } = useContext(UserContext);
  const formik = useFormikContext();
  const { errors, touched, values, handleChange, setFieldTouched, setFieldValue } = formik;

  const setIsNewTenant = useSetIsNewTenant();

  // Get active tenants from server
  const { loading: tenantLoading, data: tenantData, error: tenantError } = useQuery(
    GET_TENANT_ACTIVE_PROFILES,
    {
      fetchPolicy: 'network-only',
    }
  );
  const { landLordTenantSummary = [] } = tenantData || {};
  const { activeTenants: landlordTenants = [] } = landLordTenantSummary;

  const existingTenantsSorted = sortBy(landlordTenants, ['firstName', 'lastName']);
  const existingEmails = landlordTenants?.map((item) => item.email);

  const tenantPanelTab = landlordTenants?.length > 0 ? 1 : 0;
  const [existingTenants, setExistingTenants] = useState(existingTenantsSorted);
  const setExistingTenantsEmails = useSetExistingTenantsEmails();
  const [existingTenantVisibleValue, setExistingTenantVisibleValue] = useState(tenantPanelTab);

  useEffect(() => {
    if (tenantData) {
      setExistingTenantsEmails(existingEmails);
      setExistingTenants(
        sortBy(tenantData?.landLordTenantSummary?.activeTenants, ['firstName', 'lastName'])
      );
      if (tenantData?.landLordTenantSummary?.activeTenants?.length >= 1) {
        setExistingTenantVisibleValue(1);
        setIsNewTenant(false);
      }
      if (tenantData?.landLordTenantSummary?.activeTenants?.length === 0) {
        setIsNewTenant(true);
      }
    }
  }, [tenantData]);

  return (
    <Box {...panelFormResponsiveStyles(isMax768)}>
      <Header />
      {tenantLoading || tenantError ? (
        <Flex justifyContent="center" alignItems="center" h="100%" color="brand.blue.600">
          <Spinner />
        </Flex>
      ) : (
        <Box>
          <>
            {landlordTenants.length > 0 && user?.onboardingCompleted && (
              <Stack direction="row" pb="32px">
                <BaselaneButtonToggle
                  isFullWidth
                  size="lg"
                  firstLabel="New Tenant"
                  secondLabel="Existing Tenant"
                  firstId="newtenant"
                  secondId="existingtenant"
                  activeButton={existingTenantVisibleValue}
                  onClick={(value) => {
                    setExistingTenantVisibleValue(value);
                    setIsNewTenant(value === 0);
                  }}
                />
              </Stack>
            )}

            {existingTenantVisibleValue === 0 ? (
              <ChakraProvider theme={habitatTheme}>
                <Stack spacing={0}>
                  <Stack spacing="16px">
                    <FormControl
                      mb={0}
                      isInvalid={
                        errors.tenantProfileMetadata?.firstName &&
                        touched.tenantProfileMetadata?.firstName
                      }
                    >
                      <BaselaneFormLabel htmlFor="tenantProfileMetadata.firstName">
                        First name
                      </BaselaneFormLabel>
                      <Input
                        size="lg"
                        id="tenantProfileMetadata.firstName"
                        name="tenantProfileMetadata.firstName"
                        value={values.tenantProfileMetadata.firstName}
                        onChange={handleChange}
                        placeholder="e.g. John"
                        onBlur={() => setFieldTouched('tenantProfileMetadata.firstName', true)}
                      />
                      <BaselaneFormErrorMessage>
                        {errors.tenantProfileMetadata?.firstName}
                      </BaselaneFormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        errors.tenantProfileMetadata?.lastName &&
                        touched.tenantProfileMetadata?.lastName
                      }
                    >
                      <BaselaneFormLabel htmlFor="tenantProfileMetadata.lastName">
                        Last name
                      </BaselaneFormLabel>
                      <Input
                        size="lg"
                        id="tenantProfileMetadata.lastName"
                        name="tenantProfileMetadata.lastName"
                        value={values.tenantProfileMetadata.lastName}
                        onChange={handleChange}
                        placeholder="e.g. Smith"
                        onBlur={() => setFieldTouched('tenantProfileMetadata.lastName', true)}
                      />
                      <BaselaneFormErrorMessage>
                        {errors.tenantProfileMetadata?.lastName}
                      </BaselaneFormErrorMessage>
                    </FormControl>
                  </Stack>
                  <Stack spacing="16px">
                    <FormControl
                      mb={0}
                      isInvalid={
                        errors.tenantProfileMetadata?.email && touched.tenantProfileMetadata?.email
                      }
                    >
                      <BaselaneFormLabel htmlFor="tenantProfileMetadata.email">
                        Email
                      </BaselaneFormLabel>

                      <Input
                        size="lg"
                        id="tenantProfileMetadata.email"
                        name="tenantProfileMetadata.email"
                        value={values.tenantProfileMetadata?.email?.toLowerCase()}
                        onChange={(e) => {
                          handleChange(e);
                          const newEmail = e.target.value;
                          const isAlreadyExistingEmail = existingEmails.includes(newEmail);
                          if (isAlreadyExistingEmail) {
                            setFieldTouched('email', true, false);
                          }
                        }}
                        onBlur={() => setFieldTouched('tenantProfileMetadata.email', true)}
                        placeholder="e.g. johnsmith@gmail.com"
                      />
                      <BaselaneFormErrorMessage>
                        <Text as="span">{errors.tenantProfileMetadata?.email}</Text>
                        {errors.tenantProfileMetadata?.email ===
                          'Email matches existing tenant.' && (
                          <BaselaneButton
                            onClick={() => {
                              setExistingTenantVisibleValue(1);
                              setIsNewTenant(false);
                            }}
                            variant="link"
                            palette="danger"
                            styles={{ ml: 0.75 }}
                          >
                            Select the existing tenant
                          </BaselaneButton>
                        )}
                      </BaselaneFormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        errors.tenantProfileMetadata?.phoneNumber &&
                        touched.tenantProfileMetadata?.phoneNumber
                      }
                    >
                      <BaselaneFormLabel htmlFor="tenantProfileMetadata.phoneNumber">
                        Mobile phone (optional, needed to send SMS invite)
                      </BaselaneFormLabel>

                      <Input
                        size="lg"
                        as={MaskedInput}
                        mask={phoneNumberMask}
                        id="tenantProfileMetadata.phoneNumber"
                        name="tenantProfileMetadata.phoneNumber"
                        value={values.tenantProfileMetadata.phoneNumber}
                        placeholder="e.g. (555) 555-5555"
                        type="tel"
                        onChange={handleChange}
                      />
                      <BaselaneFormErrorMessage>
                        {errors.tenantProfileMetadata?.phoneNumber}
                      </BaselaneFormErrorMessage>
                    </FormControl>
                  </Stack>
                </Stack>
              </ChakraProvider>
            ) : (
              <>
                <ExistingTenantSelect
                  tenantProfileId={values.tenantProfileId}
                  handleTenantIdChange={(e) => {
                    setFieldValue('tenantProfileId', e.target.value);
                  }}
                  existingTenants={existingTenants}
                />
                <ExistingTenantForm
                  tenantProfileId={values.tenantProfileId}
                  existingTenants={existingTenants}
                />
              </>
            )}
            {/* TODO: Test this case */}
            {showServerTenantMessage ? (
              <BaselaneMessageCard
                iconName="exclamationcircle"
                borderColor="blue"
                iconColor="blue"
                text="This email account is already associated with a Landlord account. Please use an alternative email to create a Tenant."
                containerStyles={{ mb: '30px' }}
              />
            ) : null}
          </>
        </Box>
      )}
    </Box>
  );
}

export default TenantFormStep;
