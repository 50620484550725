import React from 'react';
import { useFormikContext } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { Icon16ChevronRight, Icon16ChevronLeft } from '@icons/16px';
import formatCurrency from '@core/utils/formatCurrency';
import { TENANT_SCREENING, TENANT_SCREENING_EDIT } from '@core/constants/routes';
import { Step, REPORT_PRICES, PRICE_MAP } from '../tenantScreening.helpers';

type FooterProps = {
  currentStep: string,
  setCurrentStep: Function,
  setShowSubmitError: Function,
  isLoading: Boolean,
  isEditDrawer: Boolean,
  propertyFormIsValid: Boolean,
  handleSaveProperty: Function,
  setShowCancellationSurvey: Function,
  hideCancelTenantScreeningSurvey: Boolean,
};

const Footer = ({
  currentStep,
  setCurrentStep,
  setShowSubmitError,
  isLoading,
  isEditDrawer,
  propertyFormIsValid,
  handleSaveProperty,
  setShowCancellationSurvey,
  hideCancelTenantScreeningSurvey,
}: FooterProps) => {
  const navigate = useNavigate();
  const isEditDrawerOpen = useLocation().pathname.includes(TENANT_SCREENING_EDIT);
  const { values, isValid, dirty, submitForm } = useFormikContext();
  const { criminalReport, evictionReport, incomeVerification } = values;

  const getNextButtonTitle = () => {
    if (currentStep === Step.ADD_PROPERTY) {
      return 'Save & continue';
    }
    if (currentStep === Step.DETAILS) {
      return 'Next';
    }
    if (currentStep === Step.REPORTS) {
      return 'Next';
    }
    if (isEditDrawer) {
      return 'Save changes';
    }
    return 'Start screening';
  };

  const handleNextStep = () => {
    if (currentStep === Step.DETAILS) {
      if (isValid && dirty) {
        setCurrentStep(Step.REPORTS);
        sendSegmentEvent('tenant_screening_fe_setup_step_1_completed', {});
        setShowSubmitError(false);
      }
    }
    if (currentStep === Step.REPORTS) {
      if (isValid && dirty) {
        setCurrentStep(Step.REVIEW);
        sendSegmentEvent('tenant_screening_fe_setup_step_2_completed');
        setShowSubmitError(false);
      }
    }
    if (currentStep === Step.REVIEW) {
      submitForm();
    }
    if (currentStep === Step.ADD_PROPERTY) {
      handleSaveProperty();
    }
  };

  const handlePreviousStep = () => {
    // If edit drawer is open, just simply close the drawer without navigating to "/tenant_screening".
    if (currentStep === Step.DETAILS && isEditDrawerOpen) {
      navigate('..');
      return;
    }

    if (currentStep === Step.ADD_PROPERTY) {
      navigate('..');
      return;
    }
    if (currentStep === Step.DETAILS) {
      if (hideCancelTenantScreeningSurvey) {
        navigate(`/${TENANT_SCREENING}`);
      } else {
        setShowCancellationSurvey(true);
      }
    }
    if (currentStep === Step.REPORTS) {
      setCurrentStep(Step.DETAILS);
    }
    if (currentStep === Step.REVIEW) {
      setCurrentStep(Step.REPORTS);
    }
  };

  const calculateTotalCost = () => {
    let totalCost = REPORT_PRICES.BASIC_REPORTING;
    totalCost += criminalReport ? PRICE_MAP.criminalReport : 0;
    totalCost += evictionReport ? PRICE_MAP.evictionReport : 0;
    totalCost += incomeVerification ? PRICE_MAP.incomeVerification : 0;
    return formatCurrency(totalCost).inDollars;
  };

  const handleIsDisabled = () => {
    if (currentStep === Step.ADD_PROPERTY && !propertyFormIsValid) return true;
    if (currentStep === Step.DETAILS && !isValid) return true;
    if (
      currentStep === Step.REVIEW &&
      !isEditDrawer &&
      (!values.term_one || !values.term_two || !values.term_three)
    )
      return true;
    return false;
  };

  return (
    <VStack w="full" alignItems="flex-start" gap={2}>
      {currentStep === Step.REPORTS && (
        <HStack w="full" alignItems="flex-start">
          <Text textStyle="sm">Cost per application (paid by applicant)</Text>
          <Text ml="auto" textStyle="headline-lg" data-cy="cost">
            {calculateTotalCost()}
          </Text>
        </HStack>
      )}
      <HStack w="full">
        <BaselaneButton
          size="md"
          variant="outline"
          palette="neutral"
          onClick={handlePreviousStep}
          leftIcon={
            currentStep !== Step.DETAILS &&
            currentStep !== Step.ADD_PROPERTY && <Icon16ChevronLeft />
          }
          data-cy="cancel"
        >
          {currentStep === Step.DETAILS || currentStep === Step.ADD_PROPERTY ? 'Cancel' : 'Back'}
        </BaselaneButton>
        <BaselaneButton
          size="md"
          variant="filled"
          palette="primary"
          width="100%"
          ml={1.5}
          flex={1}
          onClick={handleNextStep}
          leftIcon={null}
          rightIcon={currentStep !== Step.REVIEW && <Icon16ChevronRight />}
          isLoading={isLoading}
          isDisabled={handleIsDisabled()}
          data-cy="next"
        >
          {getNextButtonTitle()}
        </BaselaneButton>
      </HStack>
    </VStack>
  );
};

export default Footer;
