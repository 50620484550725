import React, { useRef, forwardRef } from 'react';
import { Box, InputGroup, Input, InputRightElement, Stack, Text } from '@chakra-ui/react';
import { Icon16Calendar } from '@icons/16px';
import { Icon12Close } from '@icons/12px';
import { datePickerInputStyles } from '../styles/datePickerInput.styles';
import { DatePickerInputProps } from '../types/datePickerInput.types';

const DatePickerInput = forwardRef(
  (
    {
      id,
      name,
      onBlur,
      onClick,
      onChange,
      onFocus,
      onKeyDown,
      placeholder,
      readOnly,
      disabled,
      required,
      size,
      tabIndex,
      title,
      value,
      className,
      autoFocus,
      autoComplete,
      label,
      hideInputIcon = false,
      hideClearButton,
      handleClearAndApply = () => {},
    }: DatePickerInputProps,
    ref
  ) => {
    const defaultRef = useRef();
    const inputRef = ref || defaultRef;

    const props = {
      id,
      name,
      onBlur,
      onClick,
      onChange,
      onFocus,
      onKeyDown,
      placeholder,
      readOnly,
      disabled,
      required,
      size,
      tabIndex,
      title,
      value,
      className,
      autoFocus,
      autoComplete,
    };

    const hasDatesSelected = readOnly && value;

    const handleClearClick = (e) => {
      e.stopPropagation();
      if (handleClearAndApply) {
        handleClearAndApply({
          startDate: null,
          endDate: null,
          isFromApplyButton: true,
        });
      }
    };

    const { input, iconContainer, closeicon, calendaricon, inputlabel } =
      datePickerInputStyles({ size, disabled }) ?? {};

    return readOnly ? (
      <Stack m="0 !important">
        {label && <Text {...inputlabel}>{label}</Text>}
        <InputGroup>
          <Input
            {...{
              ...props,
              onTouchEnd: disabled ? () => {} : onClick,
              ref: inputRef,
              value,
              className: `baselane-datepicker-input ${className}`,
              type: 'text',
              ...input,
            }}
            readOnly
          />
          {!hideInputIcon && (
            <InputRightElement {...iconContainer} pr={2} onClick={onClick}>
              {hasDatesSelected && !hideClearButton && (
                <Box {...closeicon} onClick={handleClearClick}>
                  <Icon12Close />
                </Box>
              )}
              <Box {...calendaricon}>
                <Icon16Calendar />
              </Box>
            </InputRightElement>
          )}
        </InputGroup>
      </Stack>
    ) : (
      <Stack>
        {label && <Text {...inputlabel}>{label}</Text>}
        <InputGroup>
          <Input
            {...{
              ...props,
              onTouchEnd: onClick,
              ref: inputRef,
              type: 'text',
              className: `baselane-datepicker-input ${className}`,
              ...input,
            }}
          />
          {!hideInputIcon && (
            <InputRightElement {...iconContainer} pr={2} onClick={onClick}>
              <Box {...calendaricon}>
                <Icon16Calendar />
              </Box>
            </InputRightElement>
          )}
        </InputGroup>
      </Stack>
    );
  }
);

export default DatePickerInput;
