import React from 'react';
import { isMobile } from 'react-device-detect';
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';
import {
  Box,
  FormLabel,
  FormControl,
  Stack,
  Text,
  FormErrorMessage,
  Input,
  VStack,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { formLabelStyles, formErrorStyles, formInputStyles } from '@shared/styles/input.style';
import IconProfileCard from '@icons/manual/IconProfileCard';
import BaselaneStatesSelect from '../../BaselaneStatesSelect';
import BaselaneDivider from '../../BaselaneDivider';
import BaselaneAutoCompleteAddress from '../../BaselaneAutoCompleteAddress';
import BaselaneMessageCard from '../../BaselaneCard/BaselaneMessageCard';
import { zipcodeMask, ssnMask } from '../../../../../utils/masks';
import { initialValues, handleValidation } from '../helpers/kycForm.helpers';
import {
  formTitleStyles,
  formDescriptionStyles,
  formInlineItemStyles,
  formDisclaimerStyles,
} from '../styles/kycForm.styles';
import '../styles/kycForm.styles.scss';

type BaselaneKYCFormProps = {
  formikRef: any,
  handleFormVariableUpdate: Function,
  setIsDirty: Function,
  setIsValid: Function,
  setPhoneNumber: Function,
  showErrorBanner: boolean,
  setShowErrorBanner: Function,
};

const BaselaneKYCForm = ({
  formikRef,
  handleFormVariableUpdate,
  setIsDirty,
  setIsValid,
  showErrorBanner,
  setShowErrorBanner,
}: BaselaneKYCFormProps) => {
  const dobMask = [/[0-1]/, /[0-9]/, '-', /[0-3]/, /[0-9]/, '-', /\d/, /\d/, /\d/, /\d/];

  return (
    <>
      {/* Form title */}
      <Box>
        {showErrorBanner && (
          <BaselaneMessageCard
            hasCloseButton
            borderColor="red"
            iconColor="red"
            iconName="exclamationcircle"
            onCloseButtonClick={() => setShowErrorBanner(false)}
            text="Error processing information provided, please double check information below and submit again. If problem persists, please contact support."
            containerStyles={{ mb: '24px' }}
          />
        )}
        <IconProfileCard />
        <Text {...formTitleStyles}>Verify Your Identity</Text>
        <Text {...formDescriptionStyles}>
          To collect rent through Baselane, we require you to verify your identity.
        </Text>
        <Text {...formDescriptionStyles}>
          Please use your personal information to fill out the following form, do not use business
          names, addresses, or EINs.
        </Text>
      </Box>

      {/* Form */}
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors = handleValidation(values);
          if (isEmpty(errors)) {
            setIsValid(true);
          } else {
            setIsValid(false);
          }
          return errors;
        }}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
      >
        {({ values, handleChange, handleBlur, isValid, dirty, errors, touched }) => (
          <VStack gap={2}>
            {/* First Name */}
            <FormControl isInvalid={errors.firstName && touched.firstName} isRequired>
              <FormLabel htmlFor="firstName" {...formLabelStyles.sm}>
                Legal First Name
              </FormLabel>
              <Input
                {...formInputStyles}
                id="firstName"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  handleFormVariableUpdate({ firstName: values.firstName });
                  setIsValid(isValid);
                  setIsDirty(dirty);
                }}
              />

              <FormErrorMessage {...formErrorStyles}>{errors.firstName}</FormErrorMessage>
            </FormControl>

            {/* Middle Name */}
            <FormControl>
              <FormLabel htmlFor="lastName" {...formLabelStyles.sm}>
                Legal Middle Name (if applicable)
              </FormLabel>
              <Input
                {...formInputStyles}
                id="middleName"
                name="middleName"
                value={values.middleName}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  handleFormVariableUpdate({ middleName: values.middleName });
                  setIsDirty(dirty);
                }}
              />
            </FormControl>

            {/* Last Name */}
            <FormControl isInvalid={errors.lastName && touched.lastName} isRequired>
              <FormLabel htmlFor="lastName" {...formLabelStyles.sm}>
                Legal Last Name
              </FormLabel>
              <Input
                {...formInputStyles}
                id="lastName"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  handleFormVariableUpdate({ lastName: values.lastName });
                  setIsValid(isValid);
                  setIsDirty(dirty);
                }}
              />

              <FormErrorMessage {...formErrorStyles}>{errors.lastName}</FormErrorMessage>
            </FormControl>

            <BaselaneDivider my={1} />

            {/* DOB */}
            <FormControl isRequired isInvalid={errors.dob && touched.dob}>
              <FormLabel htmlFor="dob" {...formLabelStyles.sm}>
                Date of Birth (MM-DD-YYYY)
              </FormLabel>
              <Input
                {...formInputStyles}
                id="dob"
                name="dob"
                value={values.dob}
                as={MaskedInput}
                mask={dobMask}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  handleFormVariableUpdate({ dob: values.dob });
                  setIsValid(isValid);
                  setIsDirty(dirty);
                }}
              />
              <FormErrorMessage {...formErrorStyles}>{errors.dob}</FormErrorMessage>
            </FormControl>

            {/* SSN */}
            <FormControl isRequired isInvalid={errors.ssn && touched.ssn}>
              <FormLabel htmlFor="ssn" {...formLabelStyles.sm}>
                SSN
              </FormLabel>
              <Input
                {...formInputStyles}
                id="ssn"
                name="ssn"
                value={values.ssn}
                as={MaskedInput}
                mask={ssnMask}
                onChange={handleChange}
                onBlur={(e) => {
                  const ssn = values.ssn.replace(/[^0-9]/g, '');

                  handleBlur(e);
                  handleFormVariableUpdate({ ssn });
                  setIsValid(isValid);
                  setIsDirty(dirty);
                }}
                placeholder="xxx-xx-xxxx"
              />
              <FormErrorMessage {...formErrorStyles}>{errors.ssn}</FormErrorMessage>
            </FormControl>

            <BaselaneDivider my={1} />

            {/* Address 1 */}
            <FormControl isRequired>
              <BaselaneAutoCompleteAddress
                {...{
                  label: 'Address 1',
                  values,
                  errors,
                  touched,
                  isValid,
                  handleChange,
                  formErrorStyles,
                  handleBlur: (e) => {
                    handleBlur(e);
                    setIsDirty(dirty);
                    setIsValid(isValid);
                  },
                  setFormVariables: () =>
                    handleFormVariableUpdate({
                      address: values.address,
                      unitNumber: values.unitNumber,
                      city: values.city,
                      zipcode: values.zipcode,
                      state: values.state,
                    }),
                  setIsDirty: () => setIsDirty(dirty),
                  setIsValid: () => setIsValid(isValid),
                  formInputStyles,
                  formLabelStyles: formLabelStyles.sm,
                }}
              />
            </FormControl>

            {/* Address 2 */}
            <FormControl>
              <FormLabel htmlFor="unitNumber" {...formLabelStyles.sm}>
                Address 2
              </FormLabel>
              <Input
                {...formInputStyles}
                id="unitNumber"
                name="unitNumber"
                value={values.unitNumber}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  handleFormVariableUpdate({ unitNumber: values.unitNumber });
                  setIsValid(isValid);
                  setIsDirty(dirty);
                }}
                placeholder="e.g. Floor 1"
              />
            </FormControl>

            {/* Address 3 */}
            <Stack direction="row">
              <FormControl
                {...formInlineItemStyles(isMobile).firstItem}
                isInvalid={errors.city && touched.city}
                isRequired
              >
                <FormLabel htmlFor="city" {...formLabelStyles.sm}>
                  City
                </FormLabel>
                <Input
                  {...formInputStyles}
                  id="city"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={(e) => {
                    handleBlur(e);
                    handleFormVariableUpdate({ city: values.city });
                    setIsValid(isValid);
                    setIsDirty(dirty);
                  }}
                />

                <FormErrorMessage {...formErrorStyles}>{errors.city}</FormErrorMessage>
              </FormControl>

              <FormControl
                {...formInlineItemStyles(isMobile).secondItem}
                isInvalid={errors.zipcode && touched.zipcode}
                isRequired
              >
                <FormLabel htmlFor="zipcode" {...formLabelStyles.sm}>
                  Zip Code
                </FormLabel>
                <Input
                  {...formInputStyles}
                  id="zipcode"
                  name="zipcode"
                  value={values.zipcode}
                  onChange={handleChange}
                  onBlur={(e) => {
                    handleBlur(e);
                    handleFormVariableUpdate({ zipcode: values.zipcode });
                    setIsValid(isValid);
                    setIsDirty(dirty);
                  }}
                  as={MaskedInput}
                  mask={zipcodeMask}
                />

                <FormErrorMessage {...formErrorStyles}>{errors.zipcode}</FormErrorMessage>
              </FormControl>

              {/* utilize property select for state, different order than it is in property *thinking face*  */}
              <FormControl
                {...formInlineItemStyles(isMobile).thirdItem}
                isInvalid={errors.state && touched.state}
                isRequired
              >
                <FormLabel htmlFor="state" {...formLabelStyles.sm}>
                  State
                </FormLabel>
                <BaselaneStatesSelect
                  id="state"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  onBlur={(e) => {
                    handleBlur(e);
                    handleFormVariableUpdate({ state: values.state });
                    setIsValid(isValid);
                    setIsDirty(dirty);
                  }}
                  placeholder="Select"
                />

                <FormErrorMessage {...formErrorStyles}>{errors.state}</FormErrorMessage>
              </FormControl>
            </Stack>
            {/* Disclaimer */}
            <Text {...formDisclaimerStyles}>
              Baselane does not store this information, nor will it share this information with your
              tenants. Identity verification will not impact your credit score.
            </Text>
          </VStack>
        )}
      </Formik>
    </>
  );
};

export default BaselaneKYCForm;
