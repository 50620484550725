import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { orderBy } from 'lodash';
import { Text, Stack, Spacer } from '@chakra-ui/react';
import {
  BaselaneAccordion,
  BaselaneDivider,
  BaselaneList,
  BaselaneListItem,
  CurrencyText,
} from '@shared/components';
import { listItemValueStyles } from '@shared/components/BaselaneList/styles/list.style';
import isNumber from '@core/utils/validators/isNumber';
import { fontStyle5, accordionText1, accordionText2 } from '@shared/styles/font.style';
import AddEditProperty from './AddEditProperty';

type PropertyProps = { property: Array<Object>, totalProperties: Number, totalUnits: Number };

function Property({ property = {}, totalProperties, totalUnits }: PropertyProps) {
  const {
    address,
    marketPrice,
    mortgageBalance: mortgageBalanceData,
    purchasePrice,
    units,
    type,
    name,
  } = property;
  const [latestMarketValue] = orderBy(marketPrice, ['date'], ['desc']);
  const [latestMortgageBalance] = orderBy(mortgageBalanceData, ['date'], ['desc']);
  const marketValue = latestMarketValue ? latestMarketValue.price : null;
  const mortgageBalance = latestMortgageBalance ? latestMortgageBalance.balance : null;

  const list = [
    {
      label: 'Market Value',
      value: marketValue,
    },
    {
      label: 'Purchase Price',
      value: purchasePrice,
    },
    {
      label: 'Appreciation',
      value: marketValue && purchasePrice && marketValue - purchasePrice,
    },
  ];
  const list2 = [
    {
      label: 'Mortgage Balance',
      value: mortgageBalance,
    },
    {
      label: 'Home Equity',
      value: marketValue && mortgageBalance && marketValue - mortgageBalance,
    },
  ];

  const listItemRenderer = (item) => {
    const key = uuidv4();
    const valueProps = {
      amount: item.value,
      isRounded: true,
      ...listItemValueStyles,
    };
    const value = isNumber(item.value) ? <CurrencyText {...valueProps} /> : <div>&mdash;</div>;

    return (
      <BaselaneListItem
        key={key}
        label={item.label}
        value={value}
        containerStyle={{ _notFirst: { mt: '12px !important' } }}
        labelStyle={{ mr: '8px' }}
      />
    );
  };

  const accordionHeader = (
    <Stack direction="row" w="100%" alignItems="center">
      <Stack direction="row">
        <Text {...accordionText1}>{name}</Text>
        {address === null ? null : (
          <Text {...accordionText2}>
            {address.address}, {!address.unit ? '' : `${address.unit}, `} {address.city},{' '}
            {address.state} {address.zipCode}
          </Text>
        )}
      </Stack>
      <Spacer />
      <Stack direction="row">
        <Text>{type} -</Text>
        <Text>
          {units.length} {units.length === 1 ? 'Unit' : 'Units'}
        </Text>
      </Stack>
      <AddEditProperty
        totalProperties={totalProperties}
        totalUnits={totalUnits}
        property={property}
        buttonLabel="Details"
        variant="outline"
        palette="neutral"
      />
    </Stack>
  );

  const accordionContent = (
    <Stack direction="row" justifyContent="space-between">
      <Stack flex="0 1 15%">
        <Text {...fontStyle5}>Units</Text>
        {units.map((unit) => (
          <Text key={unit.id}>
            {unit.name} {unit.description === null ? '' : `- ${unit.description}`}
          </Text>
        ))}
      </Stack>
      <Stack direction="row" flex="1">
        <BaselaneDivider styles={{ mx: '40px' }} isVertical />
        <Stack direction="row" flex="1" justifyContent="space-between" w="100%">
          <Stack w="100%" pr="80px">
            <Text {...fontStyle5}>Property Financials</Text>
            <BaselaneList data={list} itemRenderer={listItemRenderer} />
          </Stack>
          <Stack w="100%" pt="32px">
            <BaselaneList data={list2} itemRenderer={listItemRenderer} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <BaselaneAccordion
      id={property.name?.replace(/\s/g, '')}
      containerStyles={{
        m: '24px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#E5E9EF !important',
        borderRadius: '4px',
      }}
      contentStyles={{ borderColor: 'brand.darkBlue.150', p: '24px 24px 24px 60px' }}
      headerElements={accordionHeader}
      contentElements={accordionContent}
    />
  );
}

export default Property;
