import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import useEmblaCarousel from 'embla-carousel-react';

import { BaselaneButton } from '@shared/components';
import BankEntityContext from '@contexts/BankEntityContext';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

import MobileAccountsFilter from '../MobileAccountsFilter';
import SummaryCard from '../SummaryCard';
import { updateVisibleDots } from './carousel.helpers';
import { DotButton, PrevButton, NextButton } from './DotsButton';

import './styles/container.scss';
import './styles/embla.scss';

type CardCarouselProps = {
  cards: Array<React.Node>,
  updateActiveFilter: Function,
  mobileCardsData: Array<Object>,
  totalBalance: Number,
};

const CardCarousel = ({
  cards,
  updateActiveFilter,
  mobileCardsData,
  totalBalance,
}: CardCarouselProps) => {
  const { isPortrait } = useMobileOrientation();
  const { isMinXL } = useBreakPoints();

  const { allBankAccountIdsFilter, setActiveFilter } = useContext(BankEntityContext);

  const allowDrag = isMobile && isPortrait;
  const [emblaRef, emblaApi] = useEmblaCarousel({
    draggable: allowDrag,
  });

  // carousel states
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [visibleDots, setVisibleDots] = useState([0, 1, 2, 3, 4]);
  const [hideAllDots, setHideAllDots] = useState(false);

  // filter popup states
  const [showFilterPopup, setShowFilterPopup] = useState(false);

  const handleFilterPopupOpen = () => {
    sendSegmentEvent('baselane_banking_click_account_selector');
    setShowFilterPopup(true);
  };

  const allMobileCardsData =
    cards.length > 2 ? [{ isSummaryCard: true }].concat(mobileCardsData) : mobileCardsData;

  const allCards =
    cards.length > 2
      ? [
          <SummaryCard
            totalBalance={totalBalance}
            cards={cards}
            handleFilterPopupOpen={handleFilterPopupOpen}
          />,
        ].concat(cards)
      : cards;

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  const handleFilterUpdate = (selectedAccount) => {
    if (
      selectedAccount.key === 'addsubaccountnonemptystate' ||
      selectedAccount.key === 'cardsummary' ||
      selectedAccount.key === null
    ) {
      setActiveFilter({ bankAccountId: allBankAccountIdsFilter });
    } else {
      updateActiveFilter(selectedAccount.key);
    }
  };

  const onSelect = useCallback(() => {
    if (!emblaApi) return;

    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    if (hideAllDots) setHideAllDots(false);
    handleFilterUpdate(allCards[emblaApi.selectedScrollSnap()]);
    updateVisibleDots({
      emblaApi,
      visibleDots,
      setVisibleDots,
      cards: allCards,
    });
  }, [emblaApi, selectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);
    // eslint-disable-next-line consistent-return
    return () => {
      emblaApi.off('select', onSelect);
      emblaApi.off('reInit', onSelect);
    };
  }, [emblaApi, setScrollSnaps, onSelect]);

  // resetting carousel if user adds/deletes a card
  useEffect(() => {
    if (!emblaApi) return;

    if (allCards.length) {
      emblaApi.reInit();
      setScrollSnaps(emblaApi.scrollSnapList());
      handleFilterUpdate(allCards[emblaApi.selectedScrollSnap()]);
      updateVisibleDots({
        emblaApi,
        visibleDots,
        setVisibleDots,
        cards: allCards,
        cardListUpdated: true,
      });
    }
  }, [allCards.length]);

  let title = allCards.length > 2 ? 'Summary' : 'Accounts';
  if (allCards.length > 2 && selectedIndex > 0) {
    title = 'Accounts';
  }

  return (
    <>
      <Box className="carousel">
        <HStack justifyContent="space-between" position="relative" mx="16px">
          <Text color="brand.neutral.700" textStyle="headline-sm">
            {title}
          </Text>
          <BaselaneButton variant="transparent" palette="primary" onClick={handleFilterPopupOpen}>
            View all
          </BaselaneButton>
        </HStack>
        <Box className="carousel__container">
          <Box className={isMinXL ? 'embla__mobile' : 'embla'}>
            <Box className="embla__viewport" ref={emblaRef}>
              <Box className="embla__container">
                {allCards.map((card, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Box className="embla__slide" key={index}>
                    {card}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          {!allowDrag && (
            <>
              {/* // TO DO WITH BASELANE BUTTON */}
              <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
              <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
            </>
          )}
        </Box>
        <Box
          className={isMinXL ? 'embla__mobile__dots' : 'embla__dots'}
          // when using mobile account selection all dots will be hidden to recalculate
          // the dots that should be visible
          display={hideAllDots ? 'none' : 'flex'}
        >
          {scrollSnaps.map((_, index) => (
            <DotButton
              // TO DO WITH BASELANE BUTTON
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              index={index}
              numberOfItems={allCards.length}
              selectedIndex={selectedIndex}
              visibleDots={visibleDots}
            />
          ))}
        </Box>
      </Box>
      <MobileAccountsFilter
        selectedIndex={selectedIndex}
        mobileCardsData={allMobileCardsData}
        emblaApi={emblaApi}
        setHideAllDots={setHideAllDots}
        showFilterPopup={showFilterPopup}
        setShowFilterPopup={setShowFilterPopup}
      />
    </>
  );
};

export default CardCarousel;
