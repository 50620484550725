/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Box,
  Spacer,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import formatCurrency from '@core/utils/formatCurrency';
import useGenerateRecurringPayments from './useGenerateRecurringPayments';
import buildUpcomingPaymentsCalculationParams from './buildUpcomingPaymentsCalculationParams';
import UpcomingPaymentsList from './UpcomingPaymentsList';
import {
  useSetIsCurrentStepDisabled,
  useUpdateUpcomingPayments,
} from '../../../../../sendFundsStore';

const UpcomingPaymentsAccordion = ({ subheader, ...rest }) => {
  const { values, errors } = useFormikContext();
  const thereAreValidationErrors = errors?.recurringPayment;
  const { generateRecurringPayments } = useGenerateRecurringPayments();
  const [recurringPayments, setRecurringPayments] = useState(null);
  const [debouncedValues, setDebouncedValues] = useState(values);
  const upcomingPaymentsCalculationParams = useMemo(
    () => buildUpcomingPaymentsCalculationParams(debouncedValues),
    [debouncedValues]
  );
  const amount = values?.amount ? parseFloat(values.amount?.replace(/,/g, '')) : 0;
  const setIsCurrentStepDisabled = useSetIsCurrentStepDisabled();
  const updateUpcomingPayments = useUpdateUpcomingPayments();

  // The values in form in some cases are changing too fast (e.g. when user types in the amount field).
  // We need to debounce the values to avoid unnecessary API calls.
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValues(values);
    }, 660);

    return () => {
      clearTimeout(timeout);
    };
  }, [values]);

  useEffect(() => {
    if (thereAreValidationErrors) {
      return;
    }
    generateRecurringPayments({
      variables: {
        input: upcomingPaymentsCalculationParams,
      },
    }).then((response) => {
      setRecurringPayments(response?.data?.generateRecurringPayment ?? null);
      updateUpcomingPayments(response?.data?.generateRecurringPayment?.transfers ?? null);
    });
  }, [generateRecurringPayments, upcomingPaymentsCalculationParams]);

  const untilUserCancels = values.recurringPayment?.endBy === 'manual';

  useEffect(() => {
    if (recurringPayments?.transfers.length === 0) {
      setIsCurrentStepDisabled(true);
    }
    if (recurringPayments?.transfers.length > 0) {
      setIsCurrentStepDisabled(false);
    }
  }, [recurringPayments]);

  const numberOfUpcomingPayments = recurringPayments?.transfers?.filter(
    (transfer) => transfer.state === 'UPCOMING'
  ).length;

  const thereAreNoResults =
    Array.isArray(recurringPayments?.transfers) && numberOfUpcomingPayments === 0;

  return (
    <Accordion allowToggle {...rest}>
      <AccordionItem border={0}>
        <AccordionButton p={0} _hover={{ bg: 'transparent', width: '100%' }}>
          <Box display="flex" flexDirection="column" width="100%">
            {/* Accordion header */}
            <Box display="flex" py={1}>
              <Text
                textStyle="md-heavy"
                textAlign="left"
                color={thereAreNoResults ? 'red.800AA' : 'inherit'}
              >
                {untilUserCancels
                  ? 'Upcoming payments'
                  : `${numberOfUpcomingPayments} upcoming payments`}
              </Text>
              <AccordionIcon
                ml={1}
                height="24px"
                width="24px"
                color={thereAreNoResults ? 'red.800AA' : 'inherit'}
              />
              <Spacer />
              {untilUserCancels ? (
                <Text color={thereAreNoResults ? 'red.800AA' : 'inherit'} textStyle="md-heavy">
                  ♾️
                </Text>
              ) : (
                <Text
                  color={thereAreNoResults ? 'red.800AA' : 'inherit'}
                  textStyle="md-heavy"
                  textAlign="right"
                >
                  {formatCurrency(numberOfUpcomingPayments * amount).inDollars}
                </Text>
              )}
            </Box>
            {/* Accordion subheader */}
            {subheader ? (
              <Text textAlign="left" textStyle="xs">
                {subheader}
              </Text>
            ) : null}
          </Box>
        </AccordionButton>
        <AccordionPanel p="16px 0" display="flex" flexDirection="column" gap={1}>
          <UpcomingPaymentsList payments={recurringPayments} />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default UpcomingPaymentsAccordion;
