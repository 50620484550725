import React, { useEffect } from 'react';
import { Box, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { BaselaneCardNew, BaselaneChip, BaselaneSwitch, BaselaneSpinner } from '@shared/components';
import { Icon24Dollar, Icon24Security, Icon24Warning } from '@icons/24px';
import formatCurrency from '@core/utils/formatCurrency';
import { GET_PROPERTIES_DROPDOWN_WITH_LEASE_ID } from '@core/apollo/queries';
import { REPORT_PRICES, UNAVAILABLE_REPORT_MESSAGE } from '../tenantScreening.helpers';
import useTenantScreeningComplianceRules from '../../hooks/useTenantScreeningComplianceRules';

type EnhancedReportingProps = {
  isEditDrawer: Boolean,
};
const EnhancedReporting = ({ isEditDrawer }: EnhancedReportingProps) => {
  const { values, setFieldValue } = useFormikContext();
  const { criminalReport, evictionReport, incomeVerification } = values;

  const { loading, data: rules } = useTenantScreeningComplianceRules();
  const { data: allProperties } = useQuery(GET_PROPERTIES_DROPDOWN_WITH_LEASE_ID);
  const selectedProperty = allProperties.property.find((p) => p.id === values?.selectedProperty)
    .address;

  const getReports = () => {
    const countyRules = rules?.getTenantScreeningComplianceRules?.find(
      (rule) => rule.state === selectedProperty.state && rule.county === selectedProperty.city
    );
    if (countyRules) {
      return countyRules?.reportsAvailabilityDetails;
    }
    const stateRules = rules?.getTenantScreeningComplianceRules?.find(
      (rule) => rule.state === selectedProperty.state && rule.county === null
    );
    if (stateRules) {
      return stateRules?.reportsAvailabilityDetails;
    }

    return {
      criminalReport: { available: true },
      incomeVerification: { available: true },
      evictionHistory: { available: true },
    };
  };

  const reports = getReports();

  useEffect(() => {
    if (!isEditDrawer) {
      setFieldValue('criminalReport', reports.criminalReport.available);
      setFieldValue('evictionReport', reports.evictionHistory.available);
      setFieldValue('incomeVerification', reports.incomeVerification.available);
    }
  }, [rules, isEditDrawer]);

  if (loading) return <BaselaneSpinner />;

  const cardConfigs = [
    {
      id: 'income-verification',
      icon: <Icon24Dollar />,
      title: 'Income verification',
      description: `Verify applicant income with paystubs and bank transaction data that can't be forged.`,
      price: REPORT_PRICES.INCOME_VERIFICATION,
      fieldName: 'incomeVerification',
      value: incomeVerification,
      available: reports.incomeVerification.available,
    },
    {
      id: 'eviction',
      icon: <Icon24Warning />,
      title: 'Eviction report',
      description:
        'Inform yourself from the largest database of housing court records, updated daily.',
      price: REPORT_PRICES.EVICTION_REPORT,
      fieldName: 'evictionReport',
      value: evictionReport,
      available: reports.evictionHistory.available,
    },
    {
      id: 'criminal',
      icon: <Icon24Security />,
      title: 'Criminal report',
      description:
        'Access national & local databases of 1.8 billion criminal records across 2,500+ jurisdictions.',
      price: REPORT_PRICES.CRIMINAL_REPORT,
      fieldName: 'criminalReport',
      value: criminalReport,
      available: reports.criminalReport.available,
    },
  ];

  return (
    <VStack alignItems="flex-start" gap={1} w="100%">
      {cardConfigs.map((config) => (
        <BaselaneCardNew
          key={config.id}
          w="100%"
          id={config.id}
          bg="brand.darkBlue.100"
          borderColor="brand.darkBlue.100"
        >
          <HStack alignItems="flex-start" gap={2} w="100%">
            <Box w="24px" opacity={!config.available ? '0.6' : '1'}>
              {config.icon}
            </Box>
            <VStack alignItems="flex-start" opacity={!config.available ? '0.6' : '1'}>
              <Heading as="h3" size="headline-sm">
                {config.title}{' '}
                <BaselaneChip
                  label={formatCurrency(config.price).rounded}
                  size="sm"
                  variant="simple-neutral"
                  ml="auto"
                />
              </Heading>
              <Text textStyle="xs" color="brand.neutral.600">
                {!config.available ? UNAVAILABLE_REPORT_MESSAGE : config.description}
              </Text>
            </VStack>
            <Box w="40px" ml="auto">
              <BaselaneSwitch
                mr={0}
                htmlFor={config.fieldName}
                value={config.value}
                isChecked={config.value}
                onChange={() => setFieldValue(config.fieldName, !config.value)}
                disabled={!config.available}
              />
            </Box>
          </HStack>
        </BaselaneCardNew>
      ))}
    </VStack>
  );
};

export default EnhancedReporting;
