import React, { useEffect } from 'react';

import { useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import { getPropertyData } from '@shared/helpers/propertiesFilter.helpers';
import { GET_PROPERTIES_DROPDOWN } from '@core/apollo/queries';
import AutoTagCommonUI from './components/AutoTagCommonUI';

type BaselaneAutoTagPropertyFieldProps = {
  fieldname: String,
  onChange: Function,
  isDisabled: boolean,
};

const BaselaneAutoTagPropertyField = ({
  fieldname = 'autoTag',
  onChange = () => {},
  isDisabled,
}: BaselaneAutoTagPropertyFieldProps) => {
  const { values, setFieldValue, setFieldTouched, validateForm, dirty } = useFormikContext();

  const { data } = useQuery(GET_PROPERTIES_DROPDOWN);

  const propertyOptions = data ? getPropertyData(data?.property) : [];

  const getSelectedProperty = (formValues) => {
    const {
      autoTag: { propertyId, propertyUnitId },
    } = formValues || {};
    const matchId = propertyUnitId ? `${propertyId}-${propertyUnitId}` : propertyId;
    return propertyOptions.find((option) => option.id === matchId);
  };

  const onDropdownSubmit = (selectedOption = '') => {
    const [propertyId, unitId] =
      (selectedOption && selectedOption.split && selectedOption.split('-')) || [];
    setFieldValue(`${fieldname}.propertyId`, propertyId || null);
    setFieldValue(`${fieldname}.propertyUnitId`, unitId || null);
    setFieldTouched(fieldname, true);
    onChange(selectedOption);
  };

  useEffect(() => {
    if (values && dirty) {
      validateForm();
    }
  }, [values]);

  return (
    <AutoTagCommonUI
      selectedItem={getSelectedProperty(values)}
      onDropdownSubmit={onDropdownSubmit}
      isDisabled={isDisabled}
    />
  );
};

export default BaselaneAutoTagPropertyField;
