import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, HStack, Text } from '@chakra-ui/react';
import { BaselaneButtonIcon } from '@shared/components';
import { SectionWrapper } from '@shared/layouts';
import { Icon16ChevronDown, Icon16ChevronUp, Icon16Rocket } from '@icons/16px';

import GuideTrackers from './GuideTrackers';
import { GET_WORKSPACE_USER_METADATA } from './queries';

const SetupGuide = () => {
  const [isCollapsed, setCollapsed] = useState(true);
  const toggleCollapse = () => {
    setCollapsed(!isCollapsed);
    localStorage.setItem('guideCollapsed', !isCollapsed);
  };

  const { data: workspace, refetch: refetchTrackers } = useQuery(GET_WORKSPACE_USER_METADATA, {
    fetchPolicy: 'cache-and-network', // cache-and-network to ensure they see the most up-to-date data on every page visit
  });
  const { userMetadata: workspaceMetadata } = workspace?.currentWorkspace?.user ?? {};

  useEffect(() => {
    // default set to true in case there is a delay with retrieving from localStorage
    // as it's less jarring to see it open than it is to see it close after a split second
    const isUserCollapsed = localStorage.getItem('guideCollapsed') ?? true;
    setCollapsed(isUserCollapsed === 'true');
  }, []);

  return (
    <SectionWrapper
      py={{ base: isCollapsed ? 1 : 3, md: isCollapsed ? 1 : 4 }}
      borderBottom="1px solid"
      borderColor="brand.neutral.200"
      background={isCollapsed ? 'brand.darkBlue.50' : 'brand.blue.50'}
      cursor={isCollapsed ? 'pointer' : 'default'}
      _hover={{ background: isCollapsed && 'brand.darkBlue.100' }}
      _focus={{ background: isCollapsed && 'brand.darkBlue.100' }}
      onClick={() => (isCollapsed ? toggleCollapse() : null)}
      tabIndex={isCollapsed ? 1 : 0}
    >
      <HStack justifyContent="space-between" alignItems={isCollapsed ? 'center' : 'flex-start'}>
        <Box>
          <HStack>
            <Icon16Rocket />
            <Text textStyle={isCollapsed ? 'headline-md' : 'headline-lg'} ml={0.5}>
              Setup guide
            </Text>
          </HStack>
          {!isCollapsed && (
            <Text mt={1} textStyle="sm">
              It&apos;s time to set up your Baselane account.
            </Text>
          )}
        </Box>
        <BaselaneButtonIcon
          icon={isCollapsed ? <Icon16ChevronDown /> : <Icon16ChevronUp />}
          size="md"
          variant="transparent"
          palette="neutral"
          onClick={() => toggleCollapse()}
        />
      </HStack>

      {!isCollapsed && (
        <GuideTrackers
          bankingTracker={workspaceMetadata?.bankingTracker ?? []}
          rentCollectionTracker={workspaceMetadata?.rentCollectionTracker ?? []}
          bookkeepingTracker={workspaceMetadata?.bookkeepingTracker ?? []}
          refetchTrackers={refetchTrackers}
        />
      )}
    </SectionWrapper>
  );
};

export default SetupGuide;
