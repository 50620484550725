import React, { ReactNode } from 'react';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { BaselaneCardNew } from '@core/components/Shared/components';
import { Icon16ChevronRight } from '@core/components/Icons/16px';
import useBreakPoints from '@core/hooks/useBreakPoints';

type DashboardPartnerCardProps = {
  Icon: ReactNode,
  title: String,
  body: String,
  onClick: Function,
};

const DashboardPartnerCard = ({
  Icon,
  title,
  body,
  onClick,
  ...rest
}: DashboardPartnerCardProps) => {
  const { isMin768 } = useBreakPoints();

  return (
    <BaselaneCardNew
      variant="clickable"
      palette="light"
      onClick={onClick}
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      size="md"
      gap={2}
    >
      <Box w="40px" alignSelf="flex-start">
        <Icon />
      </Box>
      <VStack alignItems="flex-start" gap={0.5}>
        <Heading size="headline-md">{title}</Heading>
        <Text textStyle="sm">{body}</Text>
      </VStack>
      {!isMin768 && (
        <Box w="16px" h="full" justifyContent="center">
          <Icon16ChevronRight />
        </Box>
      )}
    </BaselaneCardNew>
  );
};

export default DashboardPartnerCard;
