import React from 'react';
import { useFormikContext } from 'formik';
import { Text } from '@chakra-ui/react';

import {
  BaselaneResponsiveCell,
  BaselaneResponsiveTableRow,
  NoPropertyTooltip,
  T2Dropdown,
} from '@shared/components';
import { propertyDropdownParentItemRenderer } from '@shared/helpers/propertiesFilter.helpers';
import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { DisplayInputDefaultPropertyItem } from '@core/components/Transactions/DisplayInputVariations';
import useIsFlowDirty from '@pages/CSVImport/hooks/useIsFlowDirty';

type MapPropertyRowProps = {
  index: number,
  externalProperty: string,
  baselaneProperty: string,
  propertiesForDropdown: Array<Object>,
  propertiesByIdForDropdownById: Object,
};

const MapPropertyRow = ({
  index,
  externalProperty,
  baselaneProperty,
  propertiesForDropdown,
  propertiesByIdForDropdownById,
}: MapPropertyRowProps) => {
  const { values, setFieldValue } = useFormikContext();

  const propertySelected = (value) => {
    return propertiesForDropdown?.find((option) => option.name === value);
  };

  useIsFlowDirty();

  return (
    <BaselaneResponsiveTableRow showBorder={index < values.length - 1}>
      <BaselaneResponsiveCell>
        <Text
          textStyle="sm"
          color="brand.neutral.700"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          title={externalProperty}
        >
          {externalProperty}
        </Text>
      </BaselaneResponsiveCell>
      <BaselaneResponsiveCell overflow="initial" contentContainerStyles={{ display: 'block' }}>
        {propertiesForDropdown.length > 0 ? (
          <T2Dropdown
            additionalProps={{
              id: 'propertyId',
            }}
            classNames={['fixed-width-dropdown', 'auto-select-input-width', 'input-form-sm']}
            data={propertiesForDropdown}
            searchTerm={['name']}
            title="Property"
            showValueByFields={['name']}
            placeholder="Select property"
            parentItemRenderer={propertyDropdownParentItemRenderer}
            childItemRenderer={itemRenderer}
            handleSubmit={(newId) => {
              setFieldValue(index, {
                ...values[index],
                baselaneProperty: propertiesByIdForDropdownById?.[newId]?.name ?? null,
              });
            }}
            selectedItem={propertySelected(baselaneProperty)}
            isMulti={false}
            hasFilterWrapper={false}
            CustomDisplayInput={DisplayInputDefaultPropertyItem}
          />
        ) : (
          <NoPropertyTooltip>
            <T2Dropdown
              isDisabled
              additionalProps={{
                id: 'propertyId',
              }}
              classNames={['is-full-width']}
              data={propertiesForDropdown}
              title="Property"
              placeholder="Select property"
              CustomDisplayInput={DisplayInputDefaultPropertyItem}
            />
          </NoPropertyTooltip>
        )}
      </BaselaneResponsiveCell>
    </BaselaneResponsiveTableRow>
  );
};

export default MapPropertyRow;
