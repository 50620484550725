import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useStatsigClient } from '@statsig/react-bindings';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { NickName, ImportTrxs } from './FormControls';
import BookkeepingRulesSection from '../../../BankEntity/AccountDetailsDrawer/AutoTagging/BookkeepingRulesSection';
import AutoTagDropdown from '../../../BankEntity/AccountDetailsDrawer/AutoTagging/AutoTagDropdown';

type EditAccountFormProps = {
  handleFormUpdate: Function,
  importTransactionLabelRef: any,
  setIsImportingAlertOpen: Function,
  setIsCheckedDuplicateTransactions?: Function,
  setIsImportDisabledAlertOpen?: Function,
  isPlaidAccount?: boolean,
  isInitialImportTransactionTypeNull?: boolean,
  setIsDeleteImportedTransactionsAlertOpen?: Function,
};

const EditAccountForm = ({
  handleFormUpdate,
  importTransactionLabelRef,
  setIsImportingAlertOpen,
  setIsCheckedDuplicateTransactions = null,
  setIsImportDisabledAlertOpen,
  isPlaidAccount = false,
  isInitialImportTransactionTypeNull = false,
  setIsDeleteImportedTransactionsAlertOpen = () => {},
}: EditAccountFormProps) => {
  const { checkGate } = useStatsigClient();

  const {
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    dirty,
    touched,
    errors,
    setFieldTouched,
    // handleSave,
  } = useFormikContext();

  const captureAutoTag = (selection) => {
    const { propertySelection } = selection || {};
    const [propertyId = null, propertyUnitId = null] =
      typeof propertySelection === 'string'
        ? propertySelection.split('-')
        : propertySelection || [];

    const newValues = {
      propertyId: propertyId ? Number(propertyId) : null,
      propertyUnitId: propertyUnitId ? Number(propertyUnitId) : null,
      enabled: !!propertyId || !!propertyUnitId,
    };
    setFieldValue('autoTag', newValues);
    setFieldTouched('autoTag', true);

    // handleFormUpdate('autoTag', newValues, true);
  };

  useEffect(() => {
    if (touched?.autoTag) {
      handleFormUpdate('autoTag', values, true);
    }
  }, [touched, values]);

  return (
    <>
      <NickName
        label="External Account Nickname"
        {...{
          values,
          errors,
          handleFormUpdate,
          handleBlur,
          handleChange,
          dirty,
        }}
      />
      {isPlaidAccount && (
        <>
          <ImportTrxs
            {...{
              values,
              errors,
              handleFormUpdate,
              handleBlur,
              handleChange,
              setFieldValue,
              setIsImportingAlertOpen,
              setIsCheckedDuplicateTransactions,
              setIsImportDisabledAlertOpen,
              importTransactionLabelRef,
              dirty,
              isInitialImportTransactionTypeNull,
              setIsDeleteImportedTransactionsAlertOpen,
            }}
          />

          {checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) ? (
            <BookkeepingRulesSection
              account={{ id: values?.id }}
              values={{
                propertyId: values?.autoTag?.propertyId || null,
                unitId: values?.autoTag?.propertyUnitId || null,
              }}
              handleSave={captureAutoTag}
              autoSave={false}
            />
          ) : (
            <AutoTagDropdown
              values={{
                propertyId: values?.autoTag?.propertyId || null,
                unitId: values?.autoTag?.propertyUnitId || null,
              }}
              handleSave={captureAutoTag}
              headingIsLabel
              mt={4}
            />
          )}
        </>
      )}
    </>
  );
};

export default EditAccountForm;
