import React, { useRef, useState, useEffect } from 'react';
import Select from 'react-dropdown-select';
import { Box, Stack, Text } from '@chakra-ui/react';
import { truncatedText } from '@shared/styles/text.style';
import FilterWrapper from '../FilterWrapper';
import CustomCategoryDropdown from './components/CustomCategoryDropdown';
import {
  customDropdownHandleRenderer,
  getMultiSelectedItems,
  onHandleKeyDownFn,
} from '../helpers/dropdown.shared.helpers';
import { dropdownContentContainerStyles } from '../styles/dropdown.styles';
import '../styles/dropdown.styles.scss';

type T1WithTitleProps = {
  classNames: Array<string>,
  data: Array<Object>,
  title: string,
  showTitleInSearch: boolean,
  searchTitle: String,
  itemRenderer: Function,
  isMulti: boolean,
  isDisabled: boolean,
  searchTerm: Array<string>,
  selectedItem: Object,
  handleSubmit: Function,
  showValueByFields: Array<string>,
  hasFilterWrapper: boolean,
  filterCustomComponent: any,
  hasActiveParentFilter: boolean,
  setHasActiveParentFilter: Function,
  optionStyles: Object,
  parentId: any,
  portalRef: any,
  hasDropdownClearedExternally: boolean,
  setHasDropdownClearedExternally: Function,
  clearButtonText: string,
  placeholder: string,
  isClearSelectedAndStaged: boolean,
  onClearClick: Function,
  dropdownPosition: string,
  showCustomCategories: boolean,
  animationVariant: String,
  handleDropdownClose: Function,
  onClearRef: any,
  hideSearch: Boolean,
  showSelectedRightElement: boolean,
  selectedRightElementValue: string,
  hasError: boolean,
  styles: Object,
  onDropdownOpen: Function,
  hasCheckboxes: Boolean,
  additionalProps: Object,
};

const T1WithTitle = ({
  classNames = ['fixed-width-dropdown'],
  data,
  title,
  showTitleInSearch = true,
  searchTitle = null,
  itemRenderer,
  isMulti = false,
  isDisabled = false,
  searchTerm = ['name'],
  selectedItem = null,
  handleSubmit = () => {},
  showValueByFields,
  hasFilterWrapper = false,
  filterCustomComponent = false,
  hasActiveParentFilter = false,
  setHasActiveParentFilter = () => {},
  optionStyles = {},
  parentId = 'page-wrapper',
  portalRef = false,
  hasDropdownClearedExternally = false,
  setHasDropdownClearedExternally = () => {},
  clearButtonText = 'Clear',
  placeholder = null,
  isClearSelectedAndStaged = false,
  onClearClick = null,
  dropdownPosition = 'auto',
  showCustomCategories = null,
  animationVariant = null,
  handleDropdownClose = () => {},
  onClearRef = null,
  hideSearch = false,
  showSelectedRightElement = false,
  selectedRightElementValue = '',
  hasError = false,
  styles = {},
  onDropdownOpen = () => {},
  hasCheckboxes = false,
  additionalProps = {},
}: T1WithTitleProps) => {
  const searchRef = useRef();
  const dropdownElementRef = useRef();
  const selectRef = useRef();

  let defaultValue = [];
  if (selectedItem) defaultValue = isMulti ? selectedItem : [selectedItem];

  const [selectedOptions, setSelectedOptions] = useState(defaultValue);
  const [selectedStagedOptions, setSelectedStagedOptions] = useState(defaultValue);
  const [isInvalidInput, setIsInvalidInput] = useState(false);
  const [customSearch, setCustomSearch] = useState(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [calculatedHeight, setCalculatedHeight] = useState(0);
  const [hasExternalClearButton, setHasExternalClearButton] = useState(false);

  const customContentRenderer = ({ state, methods }) => {
    const handleContentRendererClick = () => {
      if (state?.dropdown) {
        methods?.dropDown('close');
      }
    };

    const getValues = () =>
      selectedOptions.map((value) => value[showValueByFields[0]] || value[showValueByFields[1]]);

    /**
     * Displayed value on the dropdown- either a title or comma-separated
     * list of selected options.
     */
    let values =
      selectedOptions.length > 0 ? (
        <Text {...truncatedText}>
          {title === 'Category' || title === 'Custom' || title === 'Account'
            ? getValues().join(', ')
            : title}
        </Text>
      ) : (
        <Box className="placeholder" {...truncatedText}>
          {placeholder ?? title}
        </Box>
      );

    if (selectedOptions.length > 0 && showSelectedRightElement) {
      values = (
        <Stack direction="row" justifyContent="space-between">
          <Text {...truncatedText}>{getValues().join(', ')}</Text>
          <Text px="20px">{selectedRightElementValue}</Text>
        </Stack>
      );
    }

    return (
      <Box
        {...{ ...dropdownContentContainerStyles, ...styles?.dropdownContentContainerStyles }}
        onClick={handleContentRendererClick}
        data-cy={title || placeholder}
      >
        {values}
      </Box>
    );
  };

  const handleBlurOnToggle = (id) => {
    const elem = Array.from(dropdownElementRef.current.childNodes).find((c) => c.id === id);
    elem.blur();
  };

  const handleListItemClick = (item, e) => {
    const itemToToggle = selectedStagedOptions.find((opt) => opt.id === item.id);

    if (hasActiveParentFilter && showCustomCategories === null) {
      setHasActiveParentFilter(false);
    }

    if (isMulti) {
      const itemsSelected = getMultiSelectedItems({ itemToToggle, item, selectedStagedOptions });
      setSelectedStagedOptions(itemsSelected);
      // NOTE: To fix updated styles not getting applied right away,
      //       blur the item to make it lose focus if user deselects it (only for click event)
      if (e?.type === 'click' && itemToToggle) handleBlurOnToggle(item.id);
    } else if (!itemToToggle) {
      setSelectedStagedOptions([item]);
      setSelectedOptions([item]);
      handleSubmit(item.id);
    }
  };

  const handleCheckboxListItemClick = (item, e) => {
    handleListItemClick(item, e);
  };

  const customDropdownRenderer = ({ props, state, methods }) => {
    const { options } = props ?? {};
    const { search } = state ?? {};
    const { setSearch: _setSearch, dropDown } = methods ?? {};

    const setSearch = (event: any) => {
      setCustomSearch(event.target.value);
      _setSearch(event);
    };

    const handleClearClick = () => {
      if (customSearch !== null) {
        setSearch({ target: { value: '' } });
      }
      setHasActiveParentFilter(false);

      setSelectedStagedOptions([]);
      if (isClearSelectedAndStaged) {
        setSelectedOptions(selectedStagedOptions);
      }

      if (onClearClick) {
        onClearClick();
      }
    };

    const handleApplyClick = () => {
      if (isMulti) setSelectedOptions(selectedStagedOptions);
      if (showCustomCategories) setScrollTop(dropdownElementRef.current.scrollTop);
      dropDown('close');
      handleSubmit(selectedStagedOptions);
    };

    const commonProps = {
      options,
      selectedStagedOptions,
      selectedOptions,
      optionStyles,
      itemRenderer,
      title,
      showTitleInSearch,
      searchTitle,
      searchTerm,
      search,
      setSearch,
      searchRef,
      hasFilterWrapper,
      showCustomCategories,
      dropdownElementRef,
      onListItemClick: hasCheckboxes ? handleCheckboxListItemClick : handleListItemClick,
      animationVariant,
      calculatedHeight,
      setScrollTop,
      scrollTop,
      hideSearch,
      hasCheckboxes,
    };

    return hasFilterWrapper ? (
      <FilterWrapper
        ref={onClearRef}
        {...{
          title,
          isMulti,
          selectedStagedOptions,
          filterCustomComponent,
          onClear: handleClearClick,
          onApply: handleApplyClick,
          clearButtonText,
          animationVariant,
          isInvalidInput,
          hideClearButton: hasExternalClearButton,
        }}
      >
        <CustomCategoryDropdown {...commonProps} />
      </FilterWrapper>
    ) : (
      <CustomCategoryDropdown {...commonProps} />
    );
  };

  const handleClearAndApply = () => {
    setSelectedStagedOptions([]);
    setSelectedOptions([]);
  };

  const handleKeyDownFn = (eventResponse) => {
    const setOptions = (results, id) => {
      const option = results.reduce((acc, opt) => {
        opt.items.forEach((item) => {
          if (item.id === id) {
            acc.itemSelected = item;
          }
        });

        return acc;
      }, {});
      handleListItemClick(option.itemSelected);
    };

    const helpers = { dropdownElementRef, searchRef, setOptions };
    onHandleKeyDownFn(eventResponse, helpers);
  };

  const calculateDropdownSize = () => {
    const selectDropdown = document.querySelector('.react-dropdown-select-dropdown');
    if (!selectDropdown) return;
    const selectElem = selectDropdown.parentElement;
    const parentElem = document.querySelector(`#${parentId}`);
    if (!parentElem) return;

    // If there isn't enough space on the right, open the dropdown to the left
    const availableWidth = parentElem.clientWidth;
    const availableSpaceOnLeft = selectElem.offsetLeft;
    const availableSpaceForDropdown = availableWidth - availableSpaceOnLeft;
    const dropdownWidth = selectDropdown.clientWidth;

    // If there isn't enough space on the bottom/top, adjust the height of the dropdown
    const availableHeight = parentElem.clientHeight;
    const selectHeight = selectElem.offsetHeight;
    const availableSpaceOnTopForParent = parentElem.getBoundingClientRect().top;
    const availableSpaceOnTopForSelect = selectElem.getBoundingClientRect().top;
    const availableSpaceOnTop = availableSpaceOnTopForSelect - availableSpaceOnTopForParent;
    const availableSpaceBetweenSelectAndDropdown =
      selectDropdown.offsetTop - selectElem.clientHeight;

    const availableSpaceOnBottomForDropdown =
      availableHeight - availableSpaceOnTop - selectHeight - availableSpaceBetweenSelectAndDropdown;
    const availableSpaceOnTopForDropdown =
      availableHeight -
      availableSpaceOnBottomForDropdown -
      selectHeight -
      availableSpaceBetweenSelectAndDropdown;
    const dropdownHeight = selectDropdown.clientHeight;

    const positionName =
      Array.from(selectDropdown.classList).find((classname) => classname.includes('position')) ??
      '';
    const position = positionName.includes('top') ? 'top' : 'bottom';

    if (availableSpaceForDropdown < dropdownWidth) {
      selectDropdown.style.right = '0';
      selectDropdown.style.left = 'auto';
    }

    if (position === 'bottom' && availableSpaceOnBottomForDropdown < dropdownHeight) {
      selectDropdown.style.minHeight = `${availableSpaceOnBottomForDropdown}px`;
      selectDropdown.style.height = `${availableSpaceOnBottomForDropdown}px`;

      setCalculatedHeight(availableSpaceOnBottomForDropdown);
    } else if (position === 'top' && availableSpaceOnTopForDropdown < dropdownHeight) {
      selectDropdown.style.minHeight = `${availableSpaceOnTopForDropdown}px`;
      selectDropdown.style.height = `${availableSpaceOnTopForDropdown}px`;

      setCalculatedHeight(availableSpaceOnTopForDropdown);
    } else {
      setCalculatedHeight(dropdownHeight);
    }

    // focus on search if is not in mobile
    if (!portalRef) searchRef.current?.focus();

    setSelectedStagedOptions(selectedOptions);
  };

  useEffect(() => {
    if (hasDropdownClearedExternally) {
      handleClearAndApply();
      setHasDropdownClearedExternally(false);
    }
  }, [hasDropdownClearedExternally]);

  useEffect(() => {
    setSelectedStagedOptions(defaultValue);
    if (selectedItem?.id && !isMulti) setSelectedOptions([selectedItem]);
  }, [selectedItem]);

  useEffect(() => {
    setIsInvalidInput(selectedStagedOptions.length === 0);
  }, [selectedStagedOptions]);

  useEffect(() => {
    if (!searchRef.current) return;
    requestAnimationFrame(() => {
      searchRef.current.focus();
    });
  }, [customSearch]);

  useEffect(() => {
    if (onClearRef !== null) {
      setHasExternalClearButton(true);
    }
  }, [onClearRef]);

  return (
    <Select
      ref={selectRef}
      className={`tier1-title-dropdown ${hasError ? 'input-invalid ' : ''}${classNames.join(' ')}`}
      dropdownPosition={dropdownPosition}
      options={data}
      values={selectedOptions}
      disabled={isDisabled}
      dropdownGap={0}
      closeOnSelect={!isMulti}
      multi={isMulti}
      backspaceDelete={false}
      dropdownRenderer={customDropdownRenderer}
      contentRenderer={customContentRenderer}
      dropdownHandleRenderer={customDropdownHandleRenderer}
      onDropdownOpen={() => {
        onDropdownOpen();
        calculateDropdownSize();
      }}
      onDropdownClose={(event) => {
        handleDropdownClose(event);
      }}
      handleKeyDownFn={handleKeyDownFn}
      portal={portalRef}
      style={styles}
      additionalProps={additionalProps}
    />
  );
};

export default T1WithTitle;
