import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import OnboardingTriageCards from '@core/components/OnboardingTriage/OnboardingTriageCards';
import EasyStepsUI from '@core/components/OnboardingTriage/EasyStepsUI';
import BeforeBeginningUI from '@core/components/OnboardingTriage/BeforeBeginningUI';
import BankAccountsView from '@pages/UnifiedBookkeepingAnalytics/UnifiedBookkeepingAnalyticsUI/BookkeepingAnalyticsFormTabs/BankAccountsView';
import AddProperty from '@core/components/OnboardingTriage/AddProperty';
import PropertySurvey from '@core/components/OnboardingTriage/PropertySurvey';
import PhonePage from '@core/components/PhonePage';

import {
  OnboardingContentWrapper,
  OnboardingTriage,
  UnifiedLandlordBankingSuccess,
  UnifiedLandlordBankingUI,
  UnifiedBookkeepingAnalyticsUI,
  AutotaggingSetup,
  UnifiedBookkeepingAnalyticsSuccess,
  UnifiedBookkeepingAnalyticsAllSet,
} from '@core/components/OnboardingTriage/onboardingComponents';
import BankingFirstSuccessFlow from '@pages/UnifiedLandlordBanking/UnifiedLandlordBankingSuccess/BankingFirstSuccessFirst';

import UnifiedLeaseUI from '@pages/UnifiedRentCollection/UnifiedLeaseUI';
import UnifiedLeaseSuccess from '@pages/UnifiedRentCollection/UnifiedLeaseSuccess';
import PersonalInfoRootView from '@pages/UnifiedLandlordBanking/UnifiedLandlordBankingUI/BankingFormTabs/PersonalInfoRootView';
import HomeAddressRootView from '@pages/UnifiedLandlordBanking/UnifiedLandlordBankingUI/BankingFormTabs/HomeAddressRootView';
import BusinessInfoRootView from '@pages/UnifiedLandlordBanking/UnifiedLandlordBankingUI/BankingFormTabs/BusinessInfoRootView';
import SSNRootView from '@pages/UnifiedLandlordBanking/UnifiedLandlordBankingUI/BankingFormTabs/SSNRootView';
import AddVirtualAccountDrawer from '@pages/BankEntity/AddAccount/AddVirtualAccountDrawer';
import AddPropertyView from '@pages/UnifiedBookkeepingAnalytics/UnifiedBookkeepingAnalyticsUI/BookkeepingAnalyticsFormTabs/AddPropertyView';

import AddAccountsDrawer from '@shared/components/AddAccountsDrawer';
import {
  PHONE,
  ONBOARDING,
  ONBOARDING_ADD_PROPERTY,
  ONBOARDING_GETTING_STARTED,
  ONBOARDING_GS_BEFORE_BEGINNING,
  ONBOARDING_GS_EASY_STEPS,
  ONBOARDING_PROPERTY_SURVEY,
  ONBOARDING_TRIAGE,
  UNIFIED_BA_ACCOUNTS,
  UNIFIED_BOOKKEEPING_ANALYTICS,
  UNIFIED_LB_BUSINESS_INFO,
  UNIFIED_LB_HOME_ADDRESS,
  UNIFIED_LB_PERSONAL_INFO,
  UNIFIED_LB_SSN,
  UNIFIED_BA_ALL_SET,
  UNIFIED_BA_SUCCESS,
  UNIFIED_BA_AUTOTAG,
  UNIFIED_RENT_COLLECTION_SUCCESS,
  UNIFIED_RENT_COLLECTION,
  UNIFIED_LANDLORD_BANKING,
  UNIFIED_LANDLORD_BANKING_SUCCESS,
  ADD_VIRTUAL_ACCOUNT,
  ADD_FUNDS_BANK_TRANSFER,
  ADD_ACCOUNT,
  ANALYTICS_REPORTING,
  UNIFIED_LANDLORD_BANKING_FIRST_SUCCESS,
} from '@routes';

import { UnitOtpLayoutWrapper } from '@core/components/Shared/layouts';
import BankWrapper from '../components/wrappers/BankWrapper';
import OTPWrapper from '../components/wrappers/EmailOtpWrapper';
import TransactionWrapper from '../components/wrappers/TransactionWrapper';
import BankTransfer from '../../../pages/Transfers/components/AddFunds/components/BankTransfer';
import FeatureGateGuard from '../components/guards/FeatureGateGuard';
import UnitOtpWrapper from '../components/wrappers/UnitOtpWrapper';

const OnboardingFlowsRoutes = () => {
  return (
    <Routes>
      <Route exact path={PHONE} element={<PhonePage />} />
      <Route exact path={ONBOARDING} element={<OnboardingTriage />}>
        <Route exact path={ONBOARDING_PROPERTY_SURVEY} element={<PropertySurvey />} />
        <Route element={<FeatureGateGuard name="onboarding-add-property-gate" />}>
          <Route exact path={ONBOARDING_ADD_PROPERTY} element={<AddProperty />} />
        </Route>
        <Route exact path={ONBOARDING_TRIAGE} element={<OnboardingTriageCards />} />
      </Route>
      <Route exact path={ONBOARDING_GETTING_STARTED} element={<OnboardingContentWrapper />}>
        <Route
          exact
          path={ONBOARDING_GETTING_STARTED}
          element={
            <Navigate to={{ pathname: ONBOARDING_GS_EASY_STEPS, search: window.location.search }} />
          }
        />
        <Route exact path={ONBOARDING_GS_EASY_STEPS} element={<EasyStepsUI />} />
        <Route exact path={ONBOARDING_GS_BEFORE_BEGINNING} element={<BeforeBeginningUI />} />
      </Route>
      <Route
        exact
        path={UNIFIED_BA_ALL_SET}
        element={<UnifiedBookkeepingAnalyticsAllSet key={UNIFIED_BA_ALL_SET} />}
      />
      <Route
        exact
        path={UNIFIED_BA_SUCCESS}
        element={<UnifiedBookkeepingAnalyticsSuccess key={UNIFIED_BA_SUCCESS} />}
      />
      <Route
        exact
        path={UNIFIED_RENT_COLLECTION_SUCCESS}
        element={<UnifiedLeaseSuccess key={UNIFIED_RENT_COLLECTION_SUCCESS} />}
      />
      <Route element={<BankWrapper />}>
        <Route exact path={UNIFIED_RENT_COLLECTION} element={<UnifiedLeaseUI />} />
        <Route element={<OTPWrapper />}>
          <Route
            path={UNIFIED_LANDLORD_BANKING}
            element={<UnifiedLandlordBankingUI key={UNIFIED_LANDLORD_BANKING} />}
          >
            <Route exact path={UNIFIED_LB_PERSONAL_INFO} element={<PersonalInfoRootView />} />
            <Route exact path={UNIFIED_LB_HOME_ADDRESS} element={<HomeAddressRootView />} />
            <Route exact path={UNIFIED_LB_BUSINESS_INFO} element={<BusinessInfoRootView />} />
            <Route exact path={UNIFIED_LB_SSN} element={<SSNRootView />} />
          </Route>
        </Route>
        <Route path={UNIFIED_BOOKKEEPING_ANALYTICS} element={<UnifiedBookkeepingAnalyticsUI />}>
          <Route exact path={UNIFIED_BOOKKEEPING_ANALYTICS} element={<AddPropertyView />} />
          <Route exact path={UNIFIED_BA_ACCOUNTS} element={<BankAccountsView />} />
        </Route>
        <Route element={<TransactionWrapper />}>
          <Route
            exact
            path={UNIFIED_BA_AUTOTAG}
            element={<AutotaggingSetup key={UNIFIED_BA_AUTOTAG} />}
          />
          <Route
            path={UNIFIED_LANDLORD_BANKING_SUCCESS}
            element={<UnifiedLandlordBankingSuccess key={UNIFIED_LANDLORD_BANKING_SUCCESS} />}
          >
            <Route element={<UnitOtpWrapper />}>
              <Route element={<UnitOtpLayoutWrapper />}>
                <Route path={ADD_VIRTUAL_ACCOUNT} element={<AddVirtualAccountDrawer />} />
                <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
                  <Route
                    path={ADD_ACCOUNT}
                    element={<AddAccountsDrawer from={ANALYTICS_REPORTING} />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route
            path={UNIFIED_LANDLORD_BANKING_FIRST_SUCCESS}
            element={<BankingFirstSuccessFlow key={UNIFIED_LANDLORD_BANKING_FIRST_SUCCESS} />}
          >
            <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
              <Route
                path={ADD_ACCOUNT}
                element={<AddAccountsDrawer from={`${ANALYTICS_REPORTING}`} />}
              />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default OnboardingFlowsRoutes;
