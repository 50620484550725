import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from '@shared/hooks/useSessionStorage';
import { IllustrationOtherInsights } from '@illustrations';
import { BaselaneAlertNew, BaselaneButton } from '@shared/components';
import { BOOKKEEPING_RULES, BOOKKEEPING_RULES_PROPERTY_RULES } from '@routes';
import useBreakPoints from '@core/hooks/useBreakPoints';

type AutoTaggingPropertyBannerProps = {
  unlinkedPropertiesCount: number,
};

const AutoTaggingPropertyBanner = ({
  unlinkedPropertiesCount,
  ...rest
}: AutoTaggingPropertyBannerProps) => {
  const navigate = useNavigate();
  const { isMax767 } = useBreakPoints();
  const [isDismissed, setIsDismissed] = useSessionStorage('auto-tagging-property-banner', false);
  return (
    !isDismissed && (
      <BaselaneAlertNew
        variant="neutral"
        visual="illustration"
        iconName={IllustrationOtherInsights}
        title="Bookkeeping tip: Accounts by property"
        body={`You have ${unlinkedPropertiesCount} properties not linked to dedicated banking accounts. Automate your bookkeeping by creating property-specific Baselane virtual banking accounts.`}
        isVertical={isMax767}
        hasCloseButton
        onCloseClick={() => {
          // hides banner for the rest of the session
          setIsDismissed(true);
        }}
        customButton={
          <BaselaneButton
            palette="neutral"
            variant="outline"
            onClick={() => navigate(`/${BOOKKEEPING_RULES}/${BOOKKEEPING_RULES_PROPERTY_RULES}`)}
            flexShrink={0}
          >
            Review properties
          </BaselaneButton>
        }
        {...rest}
      />
    )
  );
};

export default AutoTaggingPropertyBanner;
