import { gql } from '@apollo/client';

// Fragments
export const FRAGMENT_USER_METADATA_BASIC = gql`
  fragment UserMetadataBasic on User {
    id
    metadata
  }
`;

export const FRAGMENT_USER_METADATA = gql`
  fragment UserMetadata on User {
    userMetadata {
      id
      referralLink
      propertyDocumentConfig
      metadata
      bankingTracker {
        name
        order
        state
      }
      rentCollectionTracker {
        name
        order
        state
      }
      bookkeepingTracker {
        name
        order
        state
      }
      bankMigrationState
      bankMigrationConsent
      bankMigrationConsentTimestamp
      dapyMigrationState
      serverTime
      onboardingSurvey
      invoiceProcessingFeePaidBy
    }
  }
`;

export const FRAGMENT_USER_METADATA_SAVINGS = gql`
  fragment UserMetadataSavings on User {
    userMetadata {
      id
      eligibleProductHistory
      currentApy
    }
  }
`;

export const FRAGMENT_UTM = gql`
  fragment Utm on User {
    utm {
      signUp {
        utmSource
        utmMedium
        utmCampaign
        fbc
        irClickId
        fbp
      }
      mostRecent {
        utmSource
        utmMedium
        utmCampaign
        fbc
        irClickId
        fbp
      }
    }
  }
`;

export const FRAGMENT_USER_USERMETADATA_INVOICE_FEE_PAID_BY = gql`
  fragment UserInvoiceFeePaidByUserMetadata on User {
    userMetadata {
      id
      invoiceProcessingFeePaidBy
    }
  }
`;

export const FRAGMENT_USER_PROMOTION = gql`
  fragment UserPromotion on User {
    userPromotion {
      id
      isExecuted
      startDate
      endDate
      promotion {
        type
        feeType
        startDate
        endDate
        dapyProduct {
          apyValue
        }
      }
    }
  }
`;

export const FRAGMENT_STRIPE_ACCOUNT = gql`
  fragment StripeAccount on User {
    stripeAccount {
      id
      accountType
      accountPendingRequirement
      userPendingRequirement
      userKYCStatus
      payoutEnabled
      state
    }
  }
`;

// Queries + Mutations
export const CREATE_USER = gql`
  mutation CreateUser(
    $firstName: String
    $lastName: String
    $utmInputs: [UtmInput]
    $inviteCode: String
    $phoneNumber: String
    $requestId: String
  ) {
    createUser(
      requestId: $requestId
      input: {
        firstName: $firstName
        lastName: $lastName
        utmInputs: $utmInputs
        phoneNumber: $phoneNumber
      }
      inviteCode: $inviteCode
    ) {
      id
      firstName
      lastName
      phoneNumber
      email
    }
  }
`;

// Queries + Mutations
export const CREATE_USER_DEFERRED = gql`
  mutation CreateUser(
    $utmInputs: [UtmInput]
    $inviteCode: String
    $phoneNumber: String
    $requestId: String
  ) {
    createUser(
      requestId: $requestId
      input: { utmInputs: $utmInputs, phoneNumber: $phoneNumber }
      inviteCode: $inviteCode
    ) {
      id
      email
    }
  }
`;

export const GET_USER = gql`
  query User {
    user {
      id
      firstName
      lastName
      email
      isFraud
      role
      businessProfile
      provider
      phoneNumber
      phoneNumberVerified
      emailVerified
      onboardingCompleted
      userKycStatus
      userKyc {
        vouchedToken
        vouchedKycStatus
      }
      createdAt
      ...UserPromotion
      ...UserMetadata
      ...Utm
      ...StripeAccount
    }
  }
  ${FRAGMENT_USER_METADATA}
  ${FRAGMENT_USER_PROMOTION}
  ${FRAGMENT_UTM}
  ${FRAGMENT_STRIPE_ACCOUNT}
`;

// Note: this should become the user query once
// savings is merged
export const GET_USER_SAVINGS = gql`
  query User {
    user {
      id
      firstName
      lastName
      email
      isFraud
      role
      businessProfile
      provider
      phoneNumber
      phoneNumberVerified
      emailVerified
      onboardingCompleted
      userKycStatus
      userKyc {
        vouchedToken
        vouchedKycStatus
      }
      createdAt
      ...UserPromotion
      ...UserMetadata
      ...Utm
      ...StripeAccount
      ...UserMetadataSavings
    }
  }
  ${FRAGMENT_USER_METADATA}
  ${FRAGMENT_USER_PROMOTION}
  ${FRAGMENT_UTM}
  ${FRAGMENT_STRIPE_ACCOUNT}
  ${FRAGMENT_USER_METADATA_SAVINGS}
`;

export const GET_USER_CURRENT_APY = gql`
  query User {
    user {
      ...UserMetadataSavings
    }
  }
  ${FRAGMENT_USER_METADATA_SAVINGS}
`;

// Note: this will be removed after login refactor
export const GET_USER_BASIC = gql`
  query User {
    user {
      id
      email
      firstName
      lastName
      userMetadata {
        bankMigrationState
      }
      onboardingCompleted
    }
  }
`;

export const GET_USER_METADATA_PROMOTION = gql`
  query User {
    user {
      id
      ...UserPromotion
      ...UserMetadataBasic
      ...UserInvoiceFeePaidByUserMetadata
    }
  }
  ${FRAGMENT_USER_METADATA_BASIC}
  ${FRAGMENT_USER_USERMETADATA_INVOICE_FEE_PAID_BY}
  ${FRAGMENT_USER_PROMOTION}
`;

export const FRAGMENT_LANDLORD_LOGIN_USER = gql`
  fragment LandlordLoginUserFields on User {
    id
    uid
    email
  }
`;

export const LANDLORD_LOGIN = gql`
  ${FRAGMENT_LANDLORD_LOGIN_USER}
  mutation LandlordLogin($requestId: String, $utmInputs: [UtmInput], $inviteCode: String) {
    landlordLogin(requestId: $requestId, utmInputs: $utmInputs, inviteCode: $inviteCode) {
      ...LandlordLoginUserFields
    }
  }
`;
