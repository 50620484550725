import React from 'react';
import { useStatsigClient } from '@statsig/react-bindings';
import { BaselaneAlertNew } from '@shared/components';
import { Icon16Lightbulb } from '@icons/16px';
import { DYNAMIC_CONFIGS } from '@core/constants/statsigKeys';

type UpdateReceivingAccAlertProps = {
  handleUpdateRecievingAccClick: Function,
};

const UpdateReceivingAccAlert = ({
  handleUpdateRecievingAccClick,
}: UpdateReceivingAccAlertProps) => {
  const { getDynamicConfig } = useStatsigClient();
  const config = getDynamicConfig(DYNAMIC_CONFIGS.APY_INTEREST);

  return (
    <BaselaneAlertNew
      title="Supercharge your rent collection with Baselane Banking"
      body={`Rent is being deposited into your external account. Switch rent collection into your Baselane Banking account for free ACH payments, automated bookkeeping, ${
        config?.get('apy') ?? '--'
      }% APY, virtual cards, and more.`}
      visual="icon"
      iconName={Icon16Lightbulb}
      isVertical
      actionProps={{
        secondary: {
          label: 'Update receiving account',
          onClick: handleUpdateRecievingAccClick,
        },
      }}
    />
  );
};

export default UpdateReceivingAccAlert;
