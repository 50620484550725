import {
  ANALYTICS_REPORTING,
  BOOKKEEPING_RULES_AI_CATEGORIZATION_SETTINGS,
  BOOKKEEPING_RULES_PROPERTY_RULES,
  BOOKKEEPING_RULES,
  CASH_FLOW_STATEMENT,
  CASH_FLOW_TAX_PACKAGE,
  DASHBOARD,
  EA_MY_ACCOUNTS,
  EA_THIRD_PARTY_ACCOUNTS,
  EXTERNAL_ACCOUNTS,
  FORGOT_PASSWORD,
  GET_STARTED,
  HOME,
  INSURANCE,
  KPIS,
  LEASES,
  LOANS,
  LOGIN,
  NATIVE_BANK,
  NB_ACCOUNTS,
  NB_CARDS,
  NB_STATEMENT_DOCUMENTS,
  NB_TAX_FORMS,
  NB_TRANSFER_PAYMENTS,
  PHONE,
  PROPERTIES,
  REFERRAL,
  RESET_PASSWORD,
  SIGN_IN,
  SIGN_UP_CREATE_ACCOUNT,
  SIGN_UP,
  TENANT_SCREENING_ONBOARDING,
  TENANT_SCREENING_PROPERTY,
  TENANT_SCREENING,
  TENANTS,
  TRANSACTIONS,
  TRANSFERS_ACTIVITY,
  TRANSFERS_PAYMENTS,
  TRANSFERS_RECIPIENTS,
  USER_PROFILE_UPDATE_ACCOUNTS,
  USER_PROFILE,
} from '@routes';

export const TITLE = 'Baselane';

export const TITLES_MAP = [
  {
    path: HOME,
    title: '',
  },
  {
    path: LOGIN,
    title: 'Sign In',
  },
  {
    path: SIGN_IN,
    title: 'Sign In',
  },
  {
    path: SIGN_UP,
    title: 'Sign Up',
  },
  {
    path: PHONE,
    title: 'Secure Account',
  },
  {
    path: `${SIGN_UP}/${SIGN_UP_CREATE_ACCOUNT}`,
    title: 'Create Account',
  },
  {
    path: FORGOT_PASSWORD,
    title: 'Forgot Password',
  },
  {
    path: RESET_PASSWORD,
    title: 'Reset Password',
  },
  {
    path: DASHBOARD,
    title: 'Dashboard',
  },
  {
    path: TRANSACTIONS,
    title: 'Transactions',
  },
  {
    path: GET_STARTED,
    title: 'Get Started',
  },
  {
    path: `${TRANSFERS_PAYMENTS}/${TRANSFERS_ACTIVITY}`,
    title: 'Transfer & Payments | Activity',
  },
  {
    path: `${TRANSFERS_PAYMENTS}/${TRANSFERS_RECIPIENTS}`,
    title: 'Transfer & Payments | Recipients',
  },
  {
    path: ANALYTICS_REPORTING,
    title: 'Analytics & Reporting',
  },
  {
    path: CASH_FLOW_STATEMENT,
    title: 'Analytics & Reporting',
  },
  {
    path: CASH_FLOW_TAX_PACKAGE,
    title: 'Analytics & Reporting',
  },
  {
    path: KPIS,
    title: 'Property Metrics',
  },
  {
    path: LEASES,
    title: 'Rent Collection',
  },
  {
    path: PROPERTIES,
    title: 'Properties',
  },
  {
    path: TENANT_SCREENING,
    title: 'Tenant screening',
  },
  {
    path: `${TENANT_SCREENING}/${TENANT_SCREENING_ONBOARDING}`,
    title: 'Tenant screening',
  },
  {
    path: `${TENANT_SCREENING}/${TENANT_SCREENING_PROPERTY}`,
    title: 'Tenant screening',
  },
  {
    path: TENANTS,
    title: 'Tenants',
  },
  {
    path: `${NATIVE_BANK}/cards`,
    title: 'Banks & Cards',
  },
  {
    path: REFERRAL,
    title: 'Referral',
  },
  {
    path: USER_PROFILE,
    title: 'Profile & Settings',
  },
  {
    path: USER_PROFILE_UPDATE_ACCOUNTS,
    title: 'Profile & Settings',
  },
  {
    path: NATIVE_BANK,
    title: 'Baselane Banking',
  },
  {
    path: NB_ACCOUNTS,
    title: 'Baselane Banking',
  },
  {
    path: NB_CARDS,
    title: 'Baselane Banking',
  },
  {
    path: NB_TRANSFER_PAYMENTS,
    title: 'Baselane Banking',
  },
  {
    path: NB_STATEMENT_DOCUMENTS,
    title: 'Baselane Banking',
  },
  {
    path: NB_TAX_FORMS,
    title: 'Baselane Banking',
  },
  {
    path: EXTERNAL_ACCOUNTS,
    title: 'External Accounts',
  },
  {
    path: EA_MY_ACCOUNTS,
    title: 'External Accounts',
  },
  {
    path: EA_THIRD_PARTY_ACCOUNTS,
    title: 'External Accounts',
  },
  {
    path: INSURANCE,
    title: 'Rental Property Insurance',
  },
  {
    path: BOOKKEEPING_RULES,
    title: 'Transactions',
  },
  {
    path: `${BOOKKEEPING_RULES}/${BOOKKEEPING_RULES_AI_CATEGORIZATION_SETTINGS}`,
    title: 'Transactions',
  },
  {
    path: `${BOOKKEEPING_RULES}/${BOOKKEEPING_RULES_PROPERTY_RULES}`,
    title: 'Transactions',
  },
  {
    path: LOANS,
    title: 'Loans',
  },
];
