import React from 'react';
import { FormControl, Select, Text, VStack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import {
  BaselaneResponsiveCell,
  BaselaneFormLabel,
  BaselaneResponsiveTableRow,
} from '@shared/components';

import useIsFlowDirty from '@pages/CSVImport/hooks/useIsFlowDirty';
import { selectInputStyles } from './select.styles';

type ReviewRowProps = {
  name: String,
  isRequired: Boolean,
  tooltip?: String,
  mapping: Object,
  setMapping: Function,
  csvHeaders: Array,
  previewRow: Array,
  handleUpdateInformationForCategoryAndPropertySteps: Function,
};

const ReviewRow = ({
  name,
  isRequired,
  tooltip = null,
  mapping,
  setMapping,
  csvHeaders,
  previewRow,
  handleUpdateInformationForCategoryAndPropertySteps,
}: ReviewRowProps) => {
  const { values, handleChange, errors, setFieldValue, validateForm } = useFormikContext();

  const mappedValue = name in mapping ? mapping[name] : '';
  const mappedIndex = csvHeaders.indexOf(mappedValue);

  const updateMapping = (baselaneField, csvColumnHeader) => {
    const cleanedUpMapping = { ...mapping };
    // remove any existing instances of the csv column header,
    // so they're mutually exclusive in the mapping
    for (const field in cleanedUpMapping) {
      if (cleanedUpMapping[field] === csvColumnHeader) {
        delete cleanedUpMapping[field];
        setFieldValue(field, '', true).then(() => {
          // ensures that the "unset" dropdown is validated, in case
          // it's one of the required ones.
          validateForm();
        });
      }
    }

    // add the new selected mapping
    const updatedMapping = { ...cleanedUpMapping, [baselaneField]: csvColumnHeader };
    setMapping(updatedMapping);
    handleUpdateInformationForCategoryAndPropertySteps(updatedMapping);
  };

  const onColumnChange = (e) => {
    updateMapping(e.target.name, e.target.value);
    handleChange(e);
  };

  useIsFlowDirty();

  return (
    <FormControl
      as={BaselaneResponsiveTableRow}
      isRequired={isRequired}
      isInvalid={!!errors[name]}
      contentContainerStyles={{
        _hover: {
          color: 'brand.neutral.500',
        },
      }}
    >
      <BaselaneResponsiveCell configIndex={0} p={0}>
        <VStack alignItems="center">
          <BaselaneFormLabel p="0" m="0" tooltipText={tooltip}>
            <Text as="span" textStyle="sm" color="brand.neutral.700">
              {name}
            </Text>
          </BaselaneFormLabel>
        </VStack>
      </BaselaneResponsiveCell>
      <BaselaneResponsiveCell configIndex={1} p={0}>
        <Select
          {...selectInputStyles}
          id={`csv-column-${name}`}
          name={name}
          value={values[name]}
          onChange={onColumnChange}
          placeholder="Select"
        >
          {csvHeaders.map((header) => {
            return (
              <option key={header} value={header}>
                {header}
              </option>
            );
          })}
        </Select>
      </BaselaneResponsiveCell>
      <BaselaneResponsiveCell configIndex={2} p={0}>
        <Text textStyle="sm" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
          {previewRow[mappedIndex] || ''}
        </Text>
      </BaselaneResponsiveCell>
    </FormControl>
  );
};

export default ReviewRow;
