import React, { useEffect, useRef } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';

import { useStatsigClient } from '@statsig/react-bindings';
import * as ROUTES from '@routes';
import useBreakPoints from '@core/hooks/useBreakPoints';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';
import BaselaneIconLogo from '@core/assets/BaselaneIconLogo';
import onNavMenuClickEvents from '@core/components/NavigationAuth/helpers/navEvent.helper';

import { FEATURE_GATES } from '@core/constants/statsigKeys';
import NavList from './NavList';
import {
  logoContainerStyles,
  logoInnerContainerStyles,
  navBarContainerStyles,
  navBarSettings,
  logoFlexStyles,
} from './styles/nav.styles';

function NavigationAuth() {
  const location = useLocation();
  const { pathname = '' } = location;
  const navElement = useRef(null);
  const { isOpen = false, onOpen, onClose } = useDisclosure();
  const { isLargerThan1537 } = useBreakPoints();
  const isTenantScreeningHeader = pathname.includes(ROUTES.TENANT_SCREENING);
  const { checkGate } = useStatsigClient();

  let timer;

  const openMenu = () => {
    timer = setTimeout(() => onOpen(), 100);
  };

  const closeMenu = () => {
    clearTimeout(timer);
    onClose();
  };

  useEffect(() => {
    const { current } = navElement;
    if (current !== null && !isLargerThan1537) {
      current.addEventListener('mouseenter', openMenu);
      current.addEventListener('mouseleave', closeMenu);
      return () => {
        current.removeEventListener('mouseenter', openMenu);
        current.removeEventListener('mouseleave', closeMenu);
      };
    }
    return () => {};
  }, [isLargerThan1537]);

  return (
    <Box
      {...navBarSettings(isOpen)}
      ref={navElement}
      sx={
        isTenantScreeningHeader && {
          '@media print': {
            display: 'none',
          },
        }
      }
    >
      <Flex {...logoFlexStyles(isOpen)}>
        {/* Logo */}
        <RouterLink to={ROUTES.HOME}>
          <Flex {...logoContainerStyles}>
            <Flex {...logoInnerContainerStyles}>
              {isOpen || isLargerThan1537 ? <BaselaneFullLogo /> : <BaselaneIconLogo />}
            </Flex>
          </Flex>
        </RouterLink>
      </Flex>
      <Flex {...navBarContainerStyles(isOpen)}>
        <NavList
          isOpen={isOpen}
          showTransfersPage={checkGate(FEATURE_GATES.TRANSFERS_PAYMENTS_PAGE)}
          onClick={(label) => {
            onNavMenuClickEvents(label);
          }}
        />
      </Flex>
    </Box>
  );
}

export default NavigationAuth;
