import {
  Icon16Building,
  Icon16Check,
  Icon16Close,
  Icon16Document,
  Icon16DocumentCheck2,
  Icon16Dollar,
  Icon16DoubleArrowRight,
  Icon16InProgress,
  Icon16LineChart,
  Icon16Person,
  Icon16PlusCircle,
  Icon16Security,
  Icon16Warning,
} from '@icons/16px';

import {
  TENANT_SCREENING_RENTAL_APPLICATION,
  TENANT_SCREENING_IDENTITY_VERIFICATION,
  TENANT_SCREENING_INCOME_VERIFICATION,
  TENANT_SCREENING_HOUSING_HISTORY,
  TENANT_SCREENING_CREDIT_REPORT,
  TENANT_SCREENING_CRIMINAL_REPORT,
  TENANT_SCREENING_EVICTION_HISTORY,
  TENANT_SCREENING_DISCLOSURE,
  TENANT_SCREENING_ADDITIONAL_DOCUMENTS,
} from '@routes';

/**
 * Report Type Keys
 */
export const REPORT_TYPE_KEYS = {
  RENTAL_APPLICATION: 'rentalApplication',
  IDENTITY_VERIFICATION: 'identityVerification',
  INCOME_VERIFICATION: 'incomeVerification',
  HOUSING_HISTORY: 'housingHistory',
  CREDIT_REPORT: 'creditHistory',
  CRIMINAL_REPORT: 'criminalReport',
  EVICTION_HISTORY: 'evictionHistory',
  DISCLOSURE: 'disclosure',
  ADDITIONAL_DOCUMENTS: 'additionalDocuments',
};

/**
 * Used to signal there is no verification for a selected report type
 */
export const VERIFICATION_ID_UNAVAILABLE = 'VERIFICATION_ID_UNAVAILABLE';

/**
 * Default reports- these are always in the menu
 */
export const DEFAULT_REPORTS = [
  REPORT_TYPE_KEYS.RENTAL_APPLICATION,
  REPORT_TYPE_KEYS.IDENTITY_VERIFICATION,
  REPORT_TYPE_KEYS.CREDIT_REPORT,
  REPORT_TYPE_KEYS.HOUSING_HISTORY,
  REPORT_TYPE_KEYS.DISCLOSURE,
  REPORT_TYPE_KEYS.ADDITIONAL_DOCUMENTS,
];

/**
 * Desired report order in the report dropdown
 */
export const REPORT_MENU_ORDER = [
  REPORT_TYPE_KEYS.RENTAL_APPLICATION,
  REPORT_TYPE_KEYS.IDENTITY_VERIFICATION,
  REPORT_TYPE_KEYS.CREDIT_REPORT,
  REPORT_TYPE_KEYS.INCOME_VERIFICATION,
  REPORT_TYPE_KEYS.HOUSING_HISTORY,
  REPORT_TYPE_KEYS.EVICTION_HISTORY,
  REPORT_TYPE_KEYS.CRIMINAL_REPORT,
  REPORT_TYPE_KEYS.DISCLOSURE,
  REPORT_TYPE_KEYS.ADDITIONAL_DOCUMENTS,
];

/**
 * Report types
 */
export const REPORT_TYPES = {
  [REPORT_TYPE_KEYS.IDENTITY_VERIFICATION]: 'Identity verification',
  [REPORT_TYPE_KEYS.INCOME_VERIFICATION]: 'Income report',
  [REPORT_TYPE_KEYS.HOUSING_HISTORY]: 'Housing history',
  [REPORT_TYPE_KEYS.CREDIT_REPORT]: 'Credit report',
  [REPORT_TYPE_KEYS.CRIMINAL_REPORT]: 'Criminal report',
  [REPORT_TYPE_KEYS.EVICTION_HISTORY]: 'Eviction report',
  [REPORT_TYPE_KEYS.DISCLOSURE]: 'Disclosure',
  [REPORT_TYPE_KEYS.ADDITIONAL_DOCUMENTS]: 'Additional documents',
  [REPORT_TYPE_KEYS.RENTAL_APPLICATION]: 'Rental application',
};

/**
 * Associates report types with routes
 */
export const REPORT_TYPE_ROUTE = {
  [REPORT_TYPE_KEYS.RENTAL_APPLICATION]: TENANT_SCREENING_RENTAL_APPLICATION,
  [REPORT_TYPE_KEYS.IDENTITY_VERIFICATION]: TENANT_SCREENING_IDENTITY_VERIFICATION,
  [REPORT_TYPE_KEYS.INCOME_VERIFICATION]: TENANT_SCREENING_INCOME_VERIFICATION,
  [REPORT_TYPE_KEYS.HOUSING_HISTORY]: TENANT_SCREENING_HOUSING_HISTORY,
  [REPORT_TYPE_KEYS.CREDIT_REPORT]: TENANT_SCREENING_CREDIT_REPORT,
  [REPORT_TYPE_KEYS.CRIMINAL_REPORT]: TENANT_SCREENING_CRIMINAL_REPORT,
  [REPORT_TYPE_KEYS.EVICTION_HISTORY]: TENANT_SCREENING_EVICTION_HISTORY,
  [REPORT_TYPE_KEYS.DISCLOSURE]: TENANT_SCREENING_DISCLOSURE,
  [REPORT_TYPE_KEYS.ADDITIONAL_DOCUMENTS]: TENANT_SCREENING_ADDITIONAL_DOCUMENTS,
};

/**
 * Default report type to show if none are selected
 */
export const DEFAULT_REPORT_ROUTE = REPORT_TYPE_ROUTE[REPORT_TYPE_KEYS.RENTAL_APPLICATION];

/**
 * Associates report types with icons
 */
export const REPORT_TYPE_ICON = {
  [REPORT_TYPE_KEYS.RENTAL_APPLICATION]: Icon16Document,
  [REPORT_TYPE_KEYS.IDENTITY_VERIFICATION]: Icon16Person,
  [REPORT_TYPE_KEYS.INCOME_VERIFICATION]: Icon16Dollar,
  [REPORT_TYPE_KEYS.HOUSING_HISTORY]: Icon16Building,
  [REPORT_TYPE_KEYS.CREDIT_REPORT]: Icon16LineChart,
  [REPORT_TYPE_KEYS.CRIMINAL_REPORT]: Icon16Security,
  [REPORT_TYPE_KEYS.EVICTION_HISTORY]: Icon16Warning,
  [REPORT_TYPE_KEYS.DISCLOSURE]: Icon16DocumentCheck2,
  [REPORT_TYPE_KEYS.ADDITIONAL_DOCUMENTS]: Icon16PlusCircle,
};

/**
 * Report & Application Status Keys
 */
export const REPORT_STATUS_KEYS = {
  IN_PROGRESS: 'IN_PROGRESS',
  INVITED: 'INVITED',
  SUBMITTED: 'SUBMITTED',
  COMPLETED: 'COMPLETED',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  APPROVED_CONDITIONALLY: 'APPROVED_CONDITIONALLY',
  PENDING_DECISION: 'PENDING_DECISION',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

/**
 * Report & Application statues strings
 */
export const REPORT_STATUS = {
  [REPORT_STATUS_KEYS.IN_PROGRESS]: 'In progress',
  [REPORT_STATUS_KEYS.INVITED]: 'Invited',
  [REPORT_STATUS_KEYS.SUBMITTED]: 'Submitted',
  [REPORT_STATUS_KEYS.COMPLETED]: 'Submitted',
  [REPORT_STATUS_KEYS.APPROVED]: 'Approved',
  [REPORT_STATUS_KEYS.APPROVED_CONDITIONALLY]: 'Approved',
  [REPORT_STATUS_KEYS.DECLINED]: 'Declined',
  [REPORT_STATUS_KEYS.PENDING_DECISION]: 'Ready to review',
  [REPORT_STATUS_KEYS.ACTIVE]: 'Active',
  [REPORT_STATUS_KEYS.INACTIVE]: 'Inactive',
};

/**
 * Associates report & application statuses with icons
 */
export const REPORT_STATUS_ICON = {
  [REPORT_STATUS_KEYS.IN_PROGRESS]: Icon16InProgress,
  [REPORT_STATUS_KEYS.INVITED]: Icon16InProgress,
  [REPORT_STATUS_KEYS.SUBMITTED]: Icon16Check,
  [REPORT_STATUS_KEYS.COMPLETED]: Icon16Check,
  [REPORT_STATUS_KEYS.APPROVED]: Icon16Check,
  [REPORT_STATUS_KEYS.APPROVED_CONDITIONALLY]: Icon16Warning,
  [REPORT_STATUS_KEYS.DECLINED]: Icon16Close,
  [REPORT_STATUS_KEYS.PENDING_DECISION]: Icon16DoubleArrowRight,
  [REPORT_STATUS_KEYS.ACTIVE]: null,
  [REPORT_STATUS_KEYS.INACTIVE]: null,
};

/**
 * Associates report & application statuses with alert styles
 */
export const REPORT_STATUS_VARIANT = {
  [REPORT_STATUS_KEYS.IN_PROGRESS]: 'simple-neutral',
  [REPORT_STATUS_KEYS.INVITED]: 'simple-neutral',
  [REPORT_STATUS_KEYS.SUBMITTED]: 'simple-success',
  [REPORT_STATUS_KEYS.COMPLETED]: 'simple-success',
  [REPORT_STATUS_KEYS.APPROVED]: 'simple-success',
  [REPORT_STATUS_KEYS.APPROVED_CONDITIONALLY]: 'simple-warning',
  [REPORT_STATUS_KEYS.DECLINED]: 'simple-danger',
  [REPORT_STATUS_KEYS.PENDING_DECISION]: 'simple-primary',
  [REPORT_STATUS_KEYS.ACTIVE]: 'simple-success',
  [REPORT_STATUS_KEYS.INACTIVE]: 'simple-danger',
};

/**
 * Generates display name for applicants
 * @param {Array} applicants array of applicant objects associated with an application
 * @param {Object} applicant the current applicant object selected
 * @param {Boolean} truncateLongName if set to true, will show one name with +<count> of applicants
 * @returns display name string
 */
export const getApplicantDisplayName = (applicants, applicant, truncateLongName = false) => {
  if (applicants?.length > 1) {
    if (truncateLongName) {
      return applicants?.reduce((nameString, currentApplicant) => {
        if (nameString.length > 0) {
          return `${nameString}, ${currentApplicant.applicantName}`;
        }
        return `${currentApplicant.applicantName}`;
      }, '');
    }
    return `${applicant?.name} +${applicants.length - 1}`;
  }
  return applicant?.applicantName;
};

function isReportEnabled(id, obj) {
  return id && obj && obj[id] === true;
}

function isRuleForAttestationRequired(state, city, report, data) {
  const countyRules = data?.find(
    (rule) => (rule.state === state && rule?.county?.includes(city)) || rule?.county === city
  );

  if (countyRules) {
    return countyRules?.reportsAvailabilityDetails[report].attestationRequired === true;
  }
  const stateRules = data?.find((rule) => rule.state === state && rule.county === null);

  if (stateRules) {
    return stateRules?.reportsAvailabilityDetails[report].attestationRequired === true;
  }
  return false;
}

export const isAttestationRequired = (screeningData, rules, selectedReport, hasAttested) => {
  const propertyState = screeningData?.getTenantScreeningById?.address?.state;
  const city = screeningData?.getTenantScreeningById?.address?.city;
  const screeningAvailableReports = {
    ...screeningData?.getTenantScreeningById?.selectedReports,
    ...{ creditHistory: true },
  };

  if (hasAttested) {
    return false;
  }
  // Check if for the selected report that the landlord wants to view, an attestation is required for the state that the screening's property belongs
  if (isReportEnabled(selectedReport?.id, screeningAvailableReports)) {
    if (
      isRuleForAttestationRequired(
        propertyState,
        city,
        selectedReport?.id,
        rules?.getTenantScreeningComplianceRules
      )
    ) {
      return true;
    }
    return false;
  }
  return false;
};

const rules = {
  states: {
    NJ: {
      text:
        'I attest that I have conditionally approved this applicant before viewing their criminal report.',
      report: 'CRIMINAL_REPORT',
    },
    CA: {
      text:
        'I attest that this applicant does not have a voucher, and that they have not requested their credit report not be used.',
      report: 'CREDIT_REPORT',
    },
    CO: {
      text:
        'I attest that this applicant does not have a voucher, and that they have not requested their credit report not be used.',
      report: 'CREDIT_REPORT',
    },
    IL: {
      text:
        'I attest that I have conditionally approved this applicant before viewing their criminal report.',
      report: 'CRIMINAL_REPORT',
    },
  },
  cities: {
    'Ann Arbor': {
      text:
        'I attest that I have conditionally approved this applicant before viewing their criminal report.',
      report: 'CRIMINAL_REPORT',
    },
    Detroit: {
      text:
        'I attest that I have conditionally approved this applicant before viewing their criminal report.',
      report: 'CRIMINAL_REPORT',
    },
    Washington: {
      text:
        'I attest that I have conditionally approved this applicant before viewing their criminal report.',
      report: 'CRIMINAL_REPORT',
    },
    Berkeley: {
      inherits: 'CA',
    },
    Oakland: {
      inherits: 'CA',
    },
    Richmond: {
      inherits: 'CA',
      additional: {
        text:
          'I attest that I have conditionally approved this applicant before viewing their criminal report.',
        report: 'CRIMINAL_REPORT',
      },
    },
    'San Francisco': {
      inherits: 'CA',
      additional: {
        text:
          'I attest that I have conditionally approved this applicant before viewing their criminal report.',
        report: 'CRIMINAL_REPORT',
      },
    },
    'New York City': {
      inherits: 'NY',
      additional: {
        text:
          'I attest that I have conditionally approved this applicant before viewing their criminal report.',
        report: 'CRIMINAL_REPORT',
      },
    },
    'New York': {
      inherits: 'NY',
      additional: {
        text:
          'I attest that I have conditionally approved this applicant before viewing their criminal report.',
        report: 'CRIMINAL_REPORT',
      },
    },
    Seattle: {
      additional: {
        text:
          'I attest that I have conditionally approved this applicant before viewing their criminal report.',
        report: 'CRIMINAL_REPORT',
      },
    },
    Philadelphia: {
      text: 'I attest that I will not reject this applicant based on their credit report.',
      report: 'CREDIT_REPORT',
    },
  },
};
const getApprovalText = (state, city, reportType) => {
  const stateRule = rules.states[state];
  const cityRule = city ? rules.cities[city] : null;

  if (cityRule) {
    // Handle inheritance from state rules
    if (cityRule.inherits) {
      const inheritedRule = rules.states[cityRule.inherits];
      if (inheritedRule?.report === reportType) {
        return cityRule.additional?.report === reportType
          ? cityRule.additional.text
          : inheritedRule.text;
      }
    }

    // If no inheritance, return city-specific rule
    if (cityRule.report === reportType) {
      return cityRule.text;
    }
    if (cityRule.additional?.report === reportType) {
      return cityRule.additional.text;
    }
  }

  // Fall back to state rule
  if (stateRule?.report === reportType) {
    return stateRule.text;
  }

  // If no matching rule is found
  return 'No applicable rule found for the given inputs.';
};

export const getAttestationText = (report, address) => {
  const { state, city } = address;
  const { type: reportType } = report;
  const approvalText = getApprovalText(state, city, reportType);
  return approvalText;
};

export const getCallToActionLabel = (application) => {
  const { applicationStatus } = application;
  let buttonVariant = 'tonal';
  let label = '';
  if (applicationStatus === 'PENDING_DECISION') {
    if (application?.applicationLinkActive) {
      label = 'Invite applicants';
    } else {
      buttonVariant = 'transparent';
      label = 'View details';
    }
  }
  if (applicationStatus === 'APPROVED') {
    buttonVariant = 'transparent';
    label = 'View details';
  } else {
    buttonVariant = 'tonal';
    label = 'Review';
  }
  return { buttonVariant, buttonLabel: label };
};
