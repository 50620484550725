import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';

import useCSVImportStore from '@store/CSVImport';
import { getCurrentTabIndex } from '@pages/CSVImport/helpers';

const useIsFlowDirty = () => {
  const location = useLocation();
  const { dirty } = useFormikContext();
  const [
    // dirty state
    isStep1Dirty,
    isStep2Dirty,
    isStep3Dirty,
    isStep4Dirty,
    setIsDirty,
  ] = useCSVImportStore(
    useShallow((state) => [
      state.isStep1Dirty,
      state.isStep2Dirty,
      state.isStep3Dirty,
      state.isStep4Dirty,
      state.setIsDirty,
    ])
  );

  const currentTabIndex = getCurrentTabIndex(location);
  const dirtyMapper = {
    0: { key: 'isStep1Dirty', isDirty: isStep1Dirty },
    1: { key: 'isStep2Dirty', isDirty: isStep2Dirty },
    2: { key: 'isStep3Dirty', isDirty: isStep3Dirty },
    3: { key: 'isStep4Dirty', isDirty: isStep4Dirty },
    4: { key: '', isDirty: false, setIsDirty: () => {} },
  };

  useEffect(() => {
    const { key, isDirty } = dirtyMapper[currentTabIndex];
    if (isDirty !== dirty && key) {
      setIsDirty({ [key]: dirty });
    }
  }, [dirty]);
};

export default useIsFlowDirty;
