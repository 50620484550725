import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, HStack, Text } from '@chakra-ui/react';
import { useApolloClient } from '@apollo/client';
import IconPresent from '@icons/manual/IconPresent';
import { UNIFIED_RENT_COLLECTION } from '@routes';
import { sendRentCollectionStartedSegmentEvent } from '@pages/LeasesPage/hooks/useCreateLease';
import historyStateStorage from '@core/storage/historyStateStorage';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { getActiveBonusObject } from '@core/components/NativeBankingPage/Summary/helpers/util.helpers';
import formatCurrency from '@core/utils/formatCurrency';
import { badgeButtonContainerStyles, offTextStyles } from './styles/rcbadgebtn.styles';

type SetUpRentCollectionBadgeButtonProps = {
  config: Object,
};

const SetUpRentCollectionBadgeButton = ({ config }: SetUpRentCollectionBadgeButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const historyState = location?.state ?? { from: location.pathname };
  const { cache } = useApolloClient();

  const activeConfig = config?.find((c) => c.isActive);
  const activeConfigBonus = getActiveBonusObject(activeConfig);

  return (
    <HStack
      {...badgeButtonContainerStyles}
      onClick={() => {
        sendSegmentEvent('baselane_banking_home_apy_click_setup_rc');
        historyStateStorage.write(historyState);
        navigate(UNIFIED_RENT_COLLECTION);
        sendRentCollectionStartedSegmentEvent(cache);
      }}
    >
      <IconPresent />{' '}
      <Text>
        Set up rent collection{' '}
        <Box as="span" {...offTextStyles}>
          get extra {formatCurrency(activeConfigBonus?.value ?? 0, true).noDollar}%
        </Box>
      </Text>
    </HStack>
  );
};

export default SetUpRentCollectionBadgeButton;
