import { useMutation } from '@apollo/client';
import { DELETE_LEASE_AGREEMENT, GET_LEASE_AGREEMENTS } from '../queries/index';

export const useDeleteAgreement = () => {
  const [deleteLeaseAgreement, { loading, error }] = useMutation(DELETE_LEASE_AGREEMENT, {
    refetchQueries: [{ query: GET_LEASE_AGREEMENTS }], // Refresh the lease list after deletion
  });

  const handleDeleteLease = async (leaseAgreementId) => {
    try {
      await deleteLeaseAgreement({ variables: { id: Number(leaseAgreementId) } });
      return true;
    } catch (err) {
      return false;
    }
  };

  return { handleDeleteLease, loading, error };
};
