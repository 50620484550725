// @flow
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, HStack, Image, Stack, Text } from '@chakra-ui/react';
import * as ROUTES from '@routes';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { LEASES_UPDATE_ACCOUNTS } from '@routes';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { GET_LEASE_METADATA } from '@pages/LeasesPage/queries';
import BaselaneDrawer from '../BaselaneDrawer';
import BaselaneDivider from '../BaselaneDivider';
import UpdateReceivingAccountsAlert from '../UpdateReceivingAccountsAlert';
import { getLearnMoreDrawerStyles } from './styles/learnMoreDrawer.styles';
import quickpayImageOne from './assets/quickpayImageOne.svg';
import quickpayImageTwo from './assets/quickpayImageTwo.svg';
import quickPayTenantEligible from './assets/quickPayTenantEligible.svg';
import quickPayTenantEnabled from './assets/quickPayTenantEnabled.svg';

const QuickPayLearnMoreDrawer = () => {
  const { isMax767 } = useBreakPoints();
  const navigate = useNavigate() || {};
  const location = useLocation() || {};
  const { state } = location || {};

  const { from, originalState } = state || {};
  const { DrawerBody } = BaselaneDrawer;

  const handleCloseLearnMoreDrawer = () => {
    const { invoiceId, leaseId } = originalState || {};
    let pathname = `${from || ROUTES.LEASES}?leaseId=`;
    if (invoiceId && leaseId) {
      pathname = `${from || ROUTES.LEASES}?leaseId=${leaseId}&invoiceId=${invoiceId}`;
    } else if (leaseId) {
      pathname = `${from || ROUTES.LEASES}?leaseId=${leaseId}`;
    } else if (invoiceId) {
      pathname = `${from || ROUTES.LEASES}?invoiceId=${invoiceId}`;
    }

    navigate(pathname, {
      state: originalState,
    });
  };

  // Destructure Imported Styles
  const {
    drawer,
    content: { titlesection, bodysection },
  } = getLearnMoreDrawerStyles();

  const {
    loading: isLeaseMetaDataLoading,
    error: hasLeaseMetaDataError,
    data: leaseMetaData,
  } = useQuery(GET_LEASE_METADATA, {
    fetchPolicy: 'cache-and-network',
  });
  const { leaseCollectingRentOutsideBaselane } = leaseMetaData?.leaseMetaData ?? {};
  const numberOfLeasesCollectingRentOutsideBaselane = leaseCollectingRentOutsideBaselane?.length;
  const hasLeaseCollectingRentOutsideBaselane = numberOfLeasesCollectingRentOutsideBaselane > 0;

  const handleUpdateReceivingAccountsClick = () => {
    sendSegmentEvent('quickpay_drawer_click_update_receiving_accounts');
    navigate(
      {
        pathname: LEASES_UPDATE_ACCOUNTS,
      },
      { state: { leaseIds: leaseCollectingRentOutsideBaselane } }
    );
  };

  return (
    <BaselaneDrawer
      title="QuickPay"
      size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
      contentStyles={isMax767 && { top: 'auto !important' }}
      closeEvent={handleCloseLearnMoreDrawer}
      isOpen
      newDesignDrawer
    >
      <DrawerBody {...drawer}>
        <>
          {/* Content Title/Description */}
          <Stack {...titlesection.container}>
            <Text {...titlesection.title}>What is QuickPay?</Text>
            <Text {...titlesection.description}>
              QuickPay is a Beta feature that Baselane is testing so that you can receive rent
              within 2 business days (rather than standard payments that arrive 4-5 business days
              later).
            </Text>
          </Stack>

          {/* Example Images */}
          <Stack gap="16px">
            <Image
              src={quickpayImageOne}
              alt="Baselane, the solution for Landlords AND Tenants."
              w="100%"
              borderRadius="8px"
            />
            <Image
              src={quickpayImageTwo}
              alt="Baselane, the solution for Landlords AND Tenants."
              w="100%"
              borderRadius="8px"
            />
          </Stack>

          <BaselaneDivider />

          {/* Content Title/Description */}
          <Stack {...titlesection.container}>
            <Text {...titlesection.title}>How do I get QuickPay?</Text>
            <Text {...titlesection.description}>
              QuickPay is enabled for eligible tenant rent payments when deposited into a Baselane
              Banking account.
            </Text>
            {!isLeaseMetaDataLoading &&
              !hasLeaseMetaDataError &&
              hasLeaseCollectingRentOutsideBaselane && (
                <UpdateReceivingAccountsAlert
                  {...{
                    title: `${numberOfLeasesCollectingRentOutsideBaselane} unit(s) collecting rent into an external account`,
                    body: 'Switch them into a Baselane Banking account to get QuickPay.',
                    handleUpdateReceivingAccountsClick,
                    numberOfLeasesCollectingRentOutsideBaselane,
                  }}
                />
              )}
          </Stack>

          <BaselaneDivider />

          {/* Content Title/Description */}
          <Stack {...titlesection.container} gap="16px">
            <Text {...titlesection.title}>Which tenant payments are processed with QuickPay?</Text>
            <Box>
              <Text {...bodysection.bodytext}>
                Baselane uses a number of factors to determine if a tenant&rsquo;s payment is
                eligible for QuickPay, including their payment method, balance at time of payment
                (if available), and payment history.
              </Text>
              <Text {...bodysection.bodytext}>
                If a tenant is eligible for QuickPay, you will see a lightning bolt next to their
                name. It will be grey or green based on whether YOU are eligible as well.
              </Text>
            </Box>

            {/* Example Images */}
            <HStack>
              <Image
                src={quickPayTenantEligible}
                alt="Baselane, the solution for Landlords AND Tenants."
                w="49%"
                borderRadius="8px"
              />
              <Image
                src={quickPayTenantEnabled}
                alt="Baselane, the solution for Landlords AND Tenants."
                w="49%"
                borderRadius="8px"
              />
            </HStack>
          </Stack>
        </>
      </DrawerBody>
    </BaselaneDrawer>
  );
};

export default QuickPayLearnMoreDrawer;
