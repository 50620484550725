import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { generalRedirectToURC } from '@pages/UnifiedRentCollection/UnifiedLeaseUI/helpers/unifiedLeaseFlow.helpers';
import {
  CREATE_LEASE_DOCUMENT,
  DASHBOARD,
  TENANT_SCREENING,
  UNIFIED_RENT_COLLECTION,
} from '@routes';
import { bannerButtonStyles } from '../styles/tenantScreening.styles';
import useProperties from '../hooks/useProperties';

type ApprovedBannerActionsProps = {
  screeningId: String,
};

const ApprovedBannerActions = ({ screeningId }: ApprovedBannerActionsProps) => {
  const navigate = useNavigate();
  const { isMax576 } = useBreakPoints();

  const [unitId, setUnitId] = useState(null);
  const { data: propertyData, getUnitIdFromScreeningId } = useProperties();

  useEffect(() => {
    if (propertyData) {
      const properties = propertyData?.property ?? [];
      const currentUnitId = getUnitIdFromScreeningId(screeningId, properties);
      if (currentUnitId) {
        setUnitId(currentUnitId);
      }
    }
  }, [propertyData]);

  return (
    <Stack
      direction={isMax576 ? 'column' : 'row'}
      {...(isMax576 ? { width: '100%' } : {})}
      gap="1.5"
    >
      <BaselaneButton
        styles={bannerButtonStyles}
        onClick={() => {
          const state = { from: TENANT_SCREENING };
          const pathname = `${DASHBOARD}/${CREATE_LEASE_DOCUMENT}`;
          generalRedirectToURC({ navigate, pathname, state });
          sendSegmentEvent('tenant_screening_fe_rocket_lawyer_clicked');
        }}
      >
        Create & sign lease
      </BaselaneButton>
      <BaselaneButton
        styles={bannerButtonStyles}
        onClick={() => {
          const state = { from: `../${TENANT_SCREENING}` };
          const pathname = `${UNIFIED_RENT_COLLECTION}${unitId ? `?unit=${unitId}` : ''}`;
          generalRedirectToURC({ navigate, pathname, state });
          sendSegmentEvent('tenant_screening_fe_rc_setup_clicked');
        }}
      >
        Set up rent collection
      </BaselaneButton>
    </Stack>
  );
};

export default ApprovedBannerActions;
