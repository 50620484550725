import React, { useEffect } from 'react';
import { Stack } from '@chakra-ui/react';
import { useApolloClient, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';

import { BaselaneCardNew, BaselaneDivider } from '@shared/components';
import useScheduledPayments from '@pages/Transfers/components/ScheduledPayments/helpers/useScheduledPayments';
import {
  DEFAULT_SORT_DIR,
  DEFAULT_SORT_FIELD,
} from '@pages/Transfers/components/ScheduledPayments/helpers/helpers';

import EmptyState from './EmptyState';
import HeaderInfo from './HeaderInfo';
import AccountsInfo from './AccountsInfo';
import ScheduledPaymentsAlertBanner from './ScheduledPaymentsAlertBanner';
import TransactionInfo from './TransactionInfo';
import { GET_BANK_SUMMARY, GET_CARD_SUMMARY } from './queries/bank';
import { containerStyles } from './styles';

const BaselaneBankingCard = () => {
  const { cache: apolloCache } = useApolloClient();

  // Banking Data
  const { data: bankData, loading: bankDataLoading } = useQuery(GET_BANK_SUMMARY, {
    variables: {
      isConnectedBank: true,
      isConnectedAccount: true,
      isExternal: false,
      isManualAccount: false,
      accountStatus: 'Open',
    },
  });
  const bankSummary = bankData?.bankSummary ?? {};
  const { bank: banks = [] } = bankSummary;

  const { data: cardData, loading: cardDataLoading } = useQuery(GET_CARD_SUMMARY);
  const { total: totalNumberOfCards = 0 } = cardData?.cardSummary ?? {};

  // Transfers Data
  const DEFAULT_SORT_OPTIONS = {
    sort: {
      field: DEFAULT_SORT_FIELD,
      direction: DEFAULT_SORT_DIR,
    },
  };
  const { data } = useScheduledPayments(DEFAULT_SORT_OPTIONS);
  const { scheduledPayments = [] } = data ?? {};
  const afterWeekDate = DateTime.now().plus({ days: 7 }).toFormat('yyyy-MM-dd');
  const numberOfScheduledPaymentsThisWeek =
    scheduledPayments.length > 0
      ? scheduledPayments.filter((sp) => {
          return sp.transferDate <= afterWeekDate;
        }).length
      : 0;

  useEffect(() => {
    return () => {
      // erase lease cache so that it reloads on next visit
      apolloCache.evict({
        id: 'ROOT_QUERY',
        fieldName:
          'bankSummary({"input":{"isConnectedAccount":true,"isConnectedBank":true,"isExternal":false,"isManualAccount":false}})',
      });
      apolloCache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'cardSummary',
      });
      apolloCache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'transaction',
      });
      // garbage collect to free up memory
      apolloCache.gc();
    };
  }, []);

  return banks.length === 0 && !bankDataLoading && !cardDataLoading ? (
    <EmptyState />
  ) : (
    <BaselaneCardNew {...containerStyles}>
      <Stack spacing={2} width="100%">
        <HeaderInfo
          bankSummary={bankSummary}
          totalNumberOfCards={totalNumberOfCards}
          bankDataLoading={bankDataLoading}
        />
        <AccountsInfo
          bankSummary={bankSummary}
          totalNumberOfCards={totalNumberOfCards}
          bankDataLoading={bankDataLoading}
          cardDataLoading={cardDataLoading}
        />
        {numberOfScheduledPaymentsThisWeek > 0 && (
          <ScheduledPaymentsAlertBanner
            numberOfScheduledPayments={numberOfScheduledPaymentsThisWeek}
          />
        )}
        <BaselaneDivider styles={{ my: 1 }} />
        <TransactionInfo />
      </Stack>
    </BaselaneCardNew>
  );
};

export default BaselaneBankingCard;
