import { gql } from '@apollo/client';

export const GET_TDATA_RECORD = gql`
  query GetTDataRecord {
    getTDataRecord {
      id
      userId
      internalUserId
      entityId
      entityName
      data {
        legalName
        ein
        address {
          street
          unit
          city
          state
          postalCode
          country
        }
      }
      isCompleted
    }
  }
`;

export const CAPTURE_USER_TDATA = gql`
  mutation CaptureUserTData($input: TaxDataInputRecord!, $entityId: ID) {
    captureUserTData(input: $input, entityId: $entityId) {
      id
      userId
      internalUserId
      entityId
      entityName
      data {
        legalName
        ein
        address {
          street
          unit
          city
          state
          postalCode
          country
        }
      }
      isCompleted
    }
  }
`;
