import { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import userContext from '@contexts/UserContext';
import { UPDATE_USER } from '@core/components/UserProfilePage/queries';
import { handleUserUpdate } from '@core/components/OnboardingTriage/helpers/onboardingFunctions.helpers';

const useHandleTenantScreeningOption = () => {
  const { user, refetchUser } = useContext(userContext);
  const [updateUser] = useMutation(UPDATE_USER);
  const [isLoading, setIsLoading] = useState(false);

  const handleTenantScreeningOption = async () => {
    setIsLoading(true);
    await handleUserUpdate({
      user: {
        ...user,
        metadata: {
          ...user?.metadata,
          onboardingTriageSelection: 'tenantScreening',
        },
      },
      refetchUser,
      updateUser,
      isOnboardingCompleted: true,
    });
    setIsLoading(false);
  };

  return { handleTenantScreeningOption, isLoading };
};

export default useHandleTenantScreeningOption;
