// @flow
import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import * as ROUTES from '@routes';
import UserContext from '@contexts/UserContext';
import { auth } from '@core/Services/Firebase/firebase';
import { useUserAccessibleWorkspace } from '@shared/hooks';
import SlLoader from '../../../Loader';

const AuthenticationGuard = ({ ...rest }): any => {
  // Redirect for guests with missing name/surname
  const {
    loading: userLoading,
    user,
    onboardingConnectAccount,
    onboardingBaselaneAccount,
  } = useContext(UserContext);

  const { onboardingCompleted, role } = user || {};
  const currLocation = useLocation();
  const { pathname } = currLocation;
  const onboardingPage = pathname.includes('onboarding');
  const gettingStarted = pathname.includes('getting_started');

  const {
    accessibleWorkspaceData,
    isLoading: accessibleWorkspaceDataIsLoading,
  } = useUserAccessibleWorkspace();

  // 2024-07_sign_up_page_experiment

  if (userLoading) return <SlLoader />;

  // No auth or user, go to login
  if (!user || !auth) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  // Redirect for collaborators (guests or landlords with more than 1 workspace) without first last name
  if (
    pathname !== ROUTES.USER_COLLABORATOR_INFO &&
    (role === 'GUEST' || (role !== 'GUEST' && accessibleWorkspaceData?.length > 1)) &&
    (!user.firstName || !user.lastName)
  ) {
    return <Navigate to={ROUTES.USER_COLLABORATOR_INFO} />;
  }

  // Make CREATE_WORKSPACE not accessible if you are a landlord
  if (pathname === ROUTES.CREATE_WORKSPACE && role === 'LANDLORD') {
    return <Navigate to={ROUTES.HOME} />;
  }

  if (role === 'GUEST') {
    if (!accessibleWorkspaceDataIsLoading && accessibleWorkspaceData.length === 0) {
      if (pathname !== ROUTES.NO_WORKSPACE_ACCESS) {
        return <Navigate to={ROUTES.NO_WORKSPACE_ACCESS} />;
      }
    }

    if (
      pathname.includes(ROUTES.ONBOARDING) ||
      pathname.includes(ROUTES.ONBOARDING_GETTING_STARTED)
    ) {
      return <Navigate to={ROUTES.DASHBOARD} />;
    }

    if (
      pathname === ROUTES.NO_WORKSPACE_ACCESS &&
      !accessibleWorkspaceDataIsLoading &&
      accessibleWorkspaceData.length > 0
    ) {
      return <Navigate to={ROUTES.DASHBOARD} />;
    }
  }

  if (
    role === 'LANDLORD' &&
    onboardingCompleted &&
    !onboardingConnectAccount &&
    !onboardingBaselaneAccount &&
    (onboardingPage || gettingStarted) &&
    pathname !== ROUTES.HOME
  ) {
    // Redirect for users that just completed onboarding
    return <Navigate to={ROUTES.HOME} />;
  }

  if (
    role === 'LANDLORD' &&
    !onboardingCompleted &&
    !pathname.includes(ROUTES.ONBOARDING) &&
    !pathname.includes(ROUTES.PHONE) &&
    !pathname.includes(ROUTES.ONBOARDING_GETTING_STARTED) &&
    !pathname.includes('unified') &&
    // ISSUE: When user is on /verified page, this code fires and redirects user to /onboarding.
    // Probably a Context API updates, refreshes routes component and checking a guarded route as a result :(
    pathname !== ROUTES.VERIFIED
  ) {
    return <Navigate to={ROUTES.ONBOARDING} />;
  }
  return user ? <Outlet /> : null;
};

export default AuthenticationGuard;
