import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { IconArrowRight, IconArrowLeft } from '@icons';
import { footerStyles } from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';
import {
  useTabIndex,
  useSetTabIndex,
  useSetIsTenantPanelVisible,
  useSetIsDraftLeasePanelVisible,
  useIsPropertyStepValid,
  useIsLeaseTermStepValid,
  useIsTenantStepValid,
  useIsReviewLeaseStepValid,
} from '../../leaseAgreementStore';

function ReviewLeaseFooter(): any {
  const { isMax768: isMobile } = useBreakPoints();
  const tabIndex = useTabIndex();
  const setTabIndex = useSetTabIndex();
  const setIsTenantPanelVisible = useSetIsTenantPanelVisible();
  const setIsDraftLeasePanelVisible = useSetIsDraftLeasePanelVisible();
  const isPropertyStepValid = useIsPropertyStepValid();
  const isLeaseTermStepValid = useIsLeaseTermStepValid();
  const isTenantStepValid = useIsTenantStepValid();
  const isReviewLeaseStepValid = useIsReviewLeaseStepValid();
  const handleBackNavigation = () => {
    setIsTenantPanelVisible(true);
    setTabIndex(2);
  };
  const handleForwardNavigation = () => {
    if (
      isPropertyStepValid &&
      isLeaseTermStepValid &&
      isTenantStepValid &&
      isReviewLeaseStepValid
    ) {
      setIsDraftLeasePanelVisible(true);
      setTabIndex(4);
    }
  };
  return (
    <Flex direction={isMobile ? 'column-reverse' : 'row'} justifyContent="space-between">
      <Flex direction="row" {...footerStyles(isMobile)} marginTop="0px">
        <Box marginRight={1.5} marginTop={tabIndex === 0 ? '0' : 3}>
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size="md"
            leftIcon={<IconArrowLeft />}
            onClick={() => handleBackNavigation()}
          >
            Previous
          </BaselaneButton>
        </Box>
        <Box marginTop={tabIndex === 0 ? '0' : '24px'} width="100%">
          <BaselaneButton
            variant="filled"
            palette="primary"
            size="md"
            onClick={() => handleForwardNavigation()}
            rightIcon={<IconArrowRight />}
            isDisabled={!isReviewLeaseStepValid}
          >
            Continue
          </BaselaneButton>
        </Box>
      </Flex>
    </Flex>
  );
}

export default ReviewLeaseFooter;
