import React, { useRef, useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChakraProvider, Skeleton, Box, Heading, Text, useToast } from '@chakra-ui/react';
import stripCurrency from '@core/utils/stripCurrency';
import { BaselaneDrawer, BaselaneAlertNew, CancelSurvey } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import habitatTheme from '@core/themeHabitat';
import { formatDate } from '@core/utils/formatDate';
import { Icon16Info } from '@icons/16px';
import {
  CREATE_TENANT_SCREENING,
  EDIT_TENANT_SCREENING,
  GET_TENANT_SCREENINGS,
  GET_TENANT_SCREENING_BY_ID,
} from '@core/apollo/queries/tenantScreening';
import { UPDATE_USER_METADATA } from '@core/components/UserProfilePage/queries';
import { TENANT_SCREENING, TENANT_SCREENING_EDIT } from '@core/constants/routes';
import {
  GET_PROPERTIES_DROPDOWN_WITH_LEASE_ID,
  CREATE_PROPERTY_SIMPLE,
  FRAGMENT_PROPERTY_SIMPLE,
} from '@core/apollo/queries';
import UserContext from '@contexts/UserContext';
import useProperties from '@features/Property/useProperties';
import AddPropertyFormLayout from '@pages/PropertiesPage/AddPropertyFormLayout';
import {
  getInitialValues,
  handlePropertySave,
  setFormData,
} from '@pages/PropertiesPage/helpers/detail.helper';
import Details from './Details';
import Footer from './Footer';
import Reports from './Reports';
import ReviewScreening from './ReviewScreening';
import { Step, ERROR_MESSAGES } from './tenantScreening.helpers';

const surveyConfig = {
  question: 'What prevented you from completing setup?',
  answers: [
    { value: 'rental_details', label: "I don't have the rental details available" },
    { value: 'expensive', label: "It's too expensive" },
    { value: 'not_ready', label: "I don't want to start screening applicants yet" },
    { value: 'error', label: 'There was an error during setup' },
    { value: 'explore', label: 'I want to explore other parts of the app first' },
  ]
    .sort(() => Math.random() - 0.5)
    .concat([{ value: 'other', label: 'Other' }]),
};

const TenantScreeningDrawer = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();
  const { isMax576 } = useBreakPoints();
  const formikRef = useRef(null);
  const { properties, loading } = useProperties();
  const [currentStep, setCurrentStep] = useState(
    properties?.length ? Step.DETAILS : Step.ADD_PROPERTY
  );
  const [showSubmitError, setShowSubmitError] = useState(false);
  const isEditDrawer = pathname.includes(TENANT_SCREENING_EDIT);
  const editScreeningId = params.screeningId;

  // Cancellation survey
  const { userMetadata } = useContext(UserContext);
  const { metadata } = userMetadata ?? {};
  const { hideCancelTenantScreeningSurvey } = metadata ?? {};
  const [updateUser] = useMutation(UPDATE_USER_METADATA);
  const [showCancellationSurvey, setShowCancellationSurvey] = useState(false);
  const [dismissLoading, setDismissLoading] = useState(false);

  const handleCloseSurvey = (navLink = '..') => {
    setDismissLoading(true);
    // whether user skipped or submitted, we set hideCancelTenantScreeningSurvey to true
    updateUser({
      variables: { metadata: { ...metadata, hideCancelTenantScreeningSurvey: true } },
      update: (cache, { data: { updateUser: updatedUser } }) => {
        cache.modify({
          id: cache.identify(updatedUser),
          fields: {
            userMetadata: () => updatedUser?.userMetadata,
          },
        });
      },
    })
      .then(() => {
        setDismissLoading(false);
        setShowCancellationSurvey(false);
        navigate(navLink);
      })
      .catch((err) => console.debug(err));
  };

  const [createTenantScreening, { loading: isCreating }] = useMutation(CREATE_TENANT_SCREENING, {
    refetchQueries: [
      {
        query: GET_TENANT_SCREENINGS,
        variables: {
          input: { applicationLinkActive: null },
        },
      },
      {
        query: GET_PROPERTIES_DROPDOWN_WITH_LEASE_ID,
      },
    ],
  });

  const [editTenantScreening, { loading: isEditing }] = useMutation(EDIT_TENANT_SCREENING, {
    refetchQueries: [
      {
        query: GET_TENANT_SCREENINGS,
        variables: { input: { applicationLinkActive: null } },
      },
      {
        skip: !Number(editScreeningId),
        fetchPolicy: 'network-only',
        query: GET_TENANT_SCREENING_BY_ID,
        variables: { id: Number(editScreeningId), input: {} },
      },
      {
        query: GET_PROPERTIES_DROPDOWN_WITH_LEASE_ID,
      },
    ],
  });

  const handleValidation = (values) => {
    const errors = {};
    if (!values.selectedProperty) {
      errors.selectedProperty = ERROR_MESSAGES.property;
    }
    if (!values.rentAmount) {
      errors.rentAmount = ERROR_MESSAGES.rentAmount;
    }
    if (!values.date) {
      errors.date = ERROR_MESSAGES.date;
    }
    if (values?.llcOwned === '-1' || values?.llcOwned === -1 || !values?.llcOwned) {
      errors.llcOwned = ERROR_MESSAGES.llcOwned;
    }
    if (values?.llcOwned === 'yes' && !values?.llcName) {
      errors.llcName = ERROR_MESSAGES.llcName;
    }
    return errors;
  };

  const initialValues = {
    selectedProperty: '',
    selectedUnit: '',
    rentAmount: null,
    date: new Date(),
    criminalReport: true,
    evictionReport: true,
    incomeVerification: true,
    term_one: false,
    term_two: false,
    term_three: false,
    llcOwned: '-1', // No default value selected as per requirement
    llcName: '',
  };

  const handleCreateTenantScreening = () => {
    setShowSubmitError(false);
    const formValues = formikRef?.current?.values;

    const createInput = {
      propertyId: Number(formValues?.selectedProperty),
      unitId: Number(formValues?.selectedUnit) || null,
      leaseAvailableDate: formatDate(formValues.date, 'YYYY-MM-DD'),
      amount: stripCurrency(formValues?.rentAmount),
      selectedReports: {
        criminalReport: formValues?.criminalReport,
        evictionHistory: formValues?.evictionReport,
        incomeVerification: formValues?.incomeVerification,
      },
      llcName: formValues.llcOwned === 'yes' ? formValues?.llcName : null,
    };

    const editInput = {
      leaseAvailableDate: formatDate(formValues.date, 'YYYY-MM-DD'),
      amount: stripCurrency(formValues?.rentAmount),
      selectedReports: {
        criminalReport: formValues?.criminalReport,
        evictionHistory: formValues?.evictionReport,
        incomeVerification: formValues?.incomeVerification,
      },
      llcName: formValues.llcOwned === 'yes' ? formValues?.llcName : null,
    };

    if (isEditDrawer) {
      editTenantScreening({
        variables: {
          id: Number(editScreeningId),
          input: editInput,
        },
      })
        .then((res) => {
          if (res.errors) {
            setShowSubmitError(true);
          } else {
            navigate(
              {
                pathname: '..',
              },
              {
                replace: true,
                state: {
                  editTenantScreeningSuccessful: true,
                },
              }
            );
          }
        })
        .catch((err) => {
          setShowSubmitError(true);
        });
    } else {
      createTenantScreening({
        variables: {
          input: createInput,
        },
      })
        .then((res) => {
          if (res.errors) {
            setShowSubmitError(true);
          } else {
            // navigate to that particular new screening
            handleCloseSurvey(
              {
                pathname: `/${TENANT_SCREENING}/${res?.data?.createTenantScreening?.id}`,
              },
              {
                replace: true,
                state: {
                  createTenantScreeningSuccessful: true,
                },
              }
            );
          }
        })
        .catch((err) => {
          setShowSubmitError(true);
        });
    }
  };
  const [createProperty] = useMutation(CREATE_PROPERTY_SIMPLE, {
    update: (cache, { data: { createProperty: newProperty } }) => {
      const newPropertyRef = cache.writeFragment({
        data: newProperty,
        fragment: FRAGMENT_PROPERTY_SIMPLE,
      });
      cache.modify({
        fields: {
          property(existingProperties = []) {
            return [...existingProperties, newPropertyRef];
          },
        },
      });
    },
  });

  const propertyInitialValues = () => getInitialValues(null, true);
  const [hasEnteredAddress, setHasEnteredAddress] = useState(false);
  const [variables, setVariables] = useState(propertyInitialValues());
  const [isValid, setIsValid] = useState(false);
  const setFormVariables = (newVariables) => setFormData(newVariables, setVariables, variables);
  const formRef = useRef();
  const [showServerError, setShowServerError] = useState();

  const handleIsPropertyValid = (val) => {
    setIsValid(val);
  };
  useEffect(() => {
    if (variables.address !== '') {
      const pAddress = variables.address?.trim();
      setFormVariables({ propertyName: pAddress });
    }
  }, [variables.address]);

  useEffect(() => {
    if (showServerError) {
      setShowServerError(false);
    }
  }, [variables]);

  const setNewPropertyAsSelected = (propertyResponse) => {
    if (propertyResponse?.units[0]?.length >= 1) {
      return;
    }
    formikRef?.current?.setFieldValue('selectedProperty', propertyResponse?.id);
  };

  // Succes toast
  const toast = useToast();
  const handleOnShowSuccessToast = (isAddNewPropertyForm, propertyResponse) => {
    setNewPropertyAsSelected(propertyResponse);
    return toast({
      position: 'bottom-left',
      description: isAddNewPropertyForm
        ? 'Property created successfully'
        : 'Property details updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleOnShowErrorToast = () => {
    setShowServerError(true);
  };

  const handleSave = () => {
    handlePropertySave({
      createProperty,
      property: null,
      variables,
      handleOnShowSuccessToast,
      handleOnShowErrorToast,
      onClose: () => {
        setCurrentStep(Step.DETAILS);
        setShowServerError(false);
      },
    });
  };

  const handleOnDrawerClose = () => {
    if (hideCancelTenantScreeningSurvey) {
      navigate('..');
    } else {
      setShowCancellationSurvey(true);
    }
  };

  const renderBody = () => {
    if (loading) return <Skeleton />;
    switch (currentStep) {
      case Step.ADD_PROPERTY:
        return (
          <Box>
            <Heading as="h2" size="headline-md" textAlign="left" mb={0.5}>
              Enter your rental property address
            </Heading>
            <Text
              {...{
                textStyle: 'sm',
                color: 'brand.neutral.700',
                mb: '4',
              }}
            >
              You will be able to edit this information and add more properties later.
            </Text>
            {showServerError && (
              <Box mb={4}>
                <BaselaneAlertNew
                  variant="danger"
                  visual="icon"
                  iconName={Icon16Info}
                  title="Failed to create property"
                  body="Please try again, or contact support if the issue persists."
                />
              </Box>
            )}
            <AddPropertyFormLayout
              shortForm
              {...{
                showTitle: false,
                initialValues: propertyInitialValues(),
                setFormVariables,
                formRef,
                property: null,
                hasEnteredAddress,
                setHasEnteredAddress,
                setIsValid: handleIsPropertyValid,
              }}
            />
          </Box>
        );
      case Step.DETAILS:
        return (
          <Details
            isEditDrawer={isEditDrawer}
            editScreeningId={editScreeningId}
            setShowCancellationSurvey={setShowCancellationSurvey}
          />
        );
      case Step.REPORTS:
        return (
          <Reports
            isEditDrawer={isEditDrawer}
            setShowCancellationSurvey={setShowCancellationSurvey}
          />
        );
      case Step.REVIEW:
        return (
          <ReviewScreening
            isEditDrawer={isEditDrawer}
            showErrorBanner={showSubmitError}
            setShowCancellationSurvey={setShowCancellationSurvey}
          />
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    if (!loading && properties?.length) {
      setCurrentStep(Step.DETAILS);
    }
  }, [loading]);
  return (
    <ChakraProvider theme={habitatTheme}>
      <Formik
        innerRef={formikRef}
        validateOnChange
        validateOnBlur
        validateOnMount
        initialValues={initialValues}
        validate={handleValidation}
        onSubmit={handleCreateTenantScreening}
      >
        {(formikProps) => {
          return (
            <BaselaneDrawer
              title="Tenant screening"
              size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
              isOpen
              onClose={() => handleOnDrawerClose()}
              newDesignDrawer
              footer={
                <Footer
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  setShowSubmitError={setShowSubmitError}
                  isLoading={isCreating || isEditing}
                  isEditDrawer={isEditDrawer}
                  propertyFormIsValid={isValid}
                  handleSaveProperty={handleSave}
                  setShowCancellationSurvey={setShowCancellationSurvey}
                  hideCancelTenantScreeningSurvey={hideCancelTenantScreeningSurvey}
                />
              }
            >
              <Skeleton isLoaded={!loading}>
                {renderBody()}
                <CancelSurvey
                  header="Before you go..."
                  surveyConfig={surveyConfig}
                  segmentEvent="tenant_screening_exit_survey"
                  isOpen={showCancellationSurvey}
                  onClose={() => setShowCancellationSurvey(false)}
                  setIsOpen={setShowCancellationSurvey}
                  onSubmit={handleCloseSurvey}
                  buttonLoading={dismissLoading}
                />
              </Skeleton>
            </BaselaneDrawer>
          );
        }}
      </Formik>
    </ChakraProvider>
  );
};

export default TenantScreeningDrawer;
