import { useQuery } from '@apollo/client';
import { GET_TAGS, GET_PRESETS } from '@core/apollo/queries';
import type { BaseModel, GroupedBaseModel } from '@shared/types';
import turnBaseModelArrayToMap from '@core/utils/turnBaseModelArrayToMap';
import getBaseModel from '../../../utils/baseModel';

const useTags = (options) => {
  const { loading: tagsLoading, error: tagsError, data: tags } = useQuery(GET_TAGS, options);
  const { loading: presetsLoading, error: presetsError, data: presets } = useQuery(GET_PRESETS);

  const categoryOptions: Array<GroupedBaseModel> = (tags?.tag || []).map(({ type, subType }) => {
    const items: Array<BaseModel> = subType.map(getBaseModel);

    return { title: type, items };
  });

  const categoryWithSubOptions: Array<GroupedBaseModel> = (tags?.tag || []).map(
    ({ type, subType }) => {
      const items: Array<BaseModel> = subType.reduce((acc, { id, name, subType: sType }) => {
        const mainCategoryObj = {
          id,
          name,
          hasChildren: sType?.length > 0,
          searchValues: [name],
        };

        const ssTypeOptions = sType.map((ssType) => {
          mainCategoryObj.searchValues = mainCategoryObj.searchValues.concat(ssType.name);
          return {
            ...ssType,
            isChild: true,
            searchValues: [name, ssType.name],
            parentId: id,
            id: ssType.id,
          };
        });

        return acc.concat([mainCategoryObj, ...ssTypeOptions]);
      }, []);

      return { title: type, items };
    }
  );

  const allCategories = categoryWithSubOptions.reduce((acc, { items }) => acc.concat(items), []);
  const categoryMap = turnBaseModelArrayToMap(allCategories);
  const categoryIdsMap = (tags?.tag || []).reduce((acc, { subType }) => {
    subType.forEach(({ id, subType: sType }) => {
      acc[id] = id;
      sType.forEach(({ id: sId }) => {
        acc[sId] = `${id}-${sId}`;
      });
    });
    return acc;
  }, {});
  const { preset: presetOptions } = presets ?? {};

  return {
    allCategories,
    tagsLoading,
    tagsError,
    tags,
    presetsLoading,
    presetsError,
    presetOptions,
    categoryOptions,
    categoryMap,
    categoryWithSubOptions,
    categoryIdsMap,
  };
};

export default useTags;
