// @flow
import React from 'react';
import { Divider } from '@chakra-ui/react';
import { getDividerStyle } from './styles/divider.style';

type BaselaneDividerProps = { styles?: Object, isVertical?: boolean };

function BaselaneDivider({ styles = {}, isVertical = false, ...rest }: BaselaneDividerProps): any {
  const orientation = isVertical ? 'vertical' : 'horizontal';
  const dividerStyle = getDividerStyle(orientation);
  const dividerStyles = { ...dividerStyle, ...styles, ...rest };

  return <Divider orientation={orientation} {...dividerStyles} />;
}

export default BaselaneDivider;
