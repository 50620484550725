// @flow
import React, { useContext } from 'react';
import { max } from 'lodash';
import { Box, Stack } from '@chakra-ui/react';
import { NoDataPlaceholder } from '@shared/components';
import CashFlowContext from '@contexts/CashFlowContext';
import { getMaxNumberBy } from '@core/utils/getNumber';
import transformToBarChartDataset from './services/transformToBarChartDataset';
import transformToPropertyUnitBarChartDataset from './services/transformToPropertyUnitBarChartDataset';
import NewKPIs from './components/NewKPIs';
import CashFlowCompositeChart from './components/CashFlowCompositeChart';
import CashFlowCompositeChartByPropertyUnit from './components/CashFlowCompositeChart/CashFlowCompositeChartByPropertyUnit';
import { barChartContainerStyles } from './styles/cashflowDataWrapper.styles';

type CashFlowDataWrapperProps = {
  handleSubFiltersUpdate: Function,
  onSelectMonth: Function,
  selectedMonth: string,
  onSelectPropertyUnit: Function,
  selectedPropertyUnit: string,
  hasNoData: boolean,
  lastPreset?: String,
  isDashboard?: boolean,
  totalUncategorizedUnhiddenTxCount?: Number,
  dataType: string,
  cashFlowPropertiesData: Array<Object>,
};
function CashFlowDataWrapper({
  handleSubFiltersUpdate,
  onSelectMonth,
  selectedMonth,
  onSelectPropertyUnit,
  selectedPropertyUnit,
  hasNoData,
  lastPreset,
  isDashboard,
  totalUncategorizedUnhiddenTxCount,
  dataType,
  cashFlowPropertiesData,
}: CashFlowDataWrapperProps): any {
  const { filters, cashFlowData, cashFlowDummyData } = useContext(CashFlowContext);
  const data = hasNoData ? cashFlowDummyData : cashFlowData;
  let bySomething = [];
  if (dataType === 'month') {
    bySomething = data?.cashFlow?.byMonth;
  } else if (dataType === 'property') {
    bySomething = data?.cashFlow?.byProperty;
  } else if (dataType === 'unit') {
    bySomething = data?.cashFlow?.byPropertyUnit;
  }
  const cashFlow = isDashboard ? bySomething.slice(-6) : bySomething;
  const dataset =
    dataType === 'month'
      ? transformToBarChartDataset(cashFlow)
      : transformToPropertyUnitBarChartDataset(cashFlow, cashFlowPropertiesData, dataType);

  let barChart = <NoDataPlaceholder small />;
  if (dataset) {
    const maxNumberRevenue = getMaxNumberBy(dataset, 'revenues');
    const maxNumberExpense = getMaxNumberBy(dataset, 'expenses');
    const maxNumberNetIncome = getMaxNumberBy(dataset, 'netIncome');
    const maxNumber = max([maxNumberRevenue, maxNumberExpense, maxNumberNetIncome]);

    // this eliminates the "ghost" date when picking "Last Month"
    let processedDataset = dataset;
    if (lastPreset === 'Last Month' && dataset.length > 1) {
      processedDataset = [dataset[0]];
    }

    if (dataType === 'month') {
      barChart = (
        <CashFlowCompositeChart
          dataset={processedDataset}
          filters={filters}
          handleSubFiltersUpdate={handleSubFiltersUpdate}
          onSelectMonth={onSelectMonth}
          selectedMonth={selectedMonth}
          config={{
            values: {
              barOne: 'revenues',
              barTwo: 'expenses',
              lineOne: 'netIncome',
            },
          }}
          isZero={maxNumber === 0}
          maxNumber={maxNumber}
          dataPointsInViewport={6}
        />
      );
    }
    if (dataType === 'property') {
      barChart = (
        <CashFlowCompositeChartByPropertyUnit
          dataset={processedDataset}
          filters={filters}
          handleSubFiltersUpdate={handleSubFiltersUpdate}
          onSelectPropertyUnit={onSelectPropertyUnit}
          selectedPropertyUnit={selectedPropertyUnit}
          config={{
            values: {
              barOne: 'revenues',
              barTwo: 'expenses',
              lineOne: 'netIncome',
            },
          }}
          isZero={maxNumber === 0}
          maxNumber={maxNumber}
          dataPointsInViewport={6}
          dataType={dataType}
        />
      );
    }
    if (dataType === 'unit') {
      barChart = (
        <CashFlowCompositeChartByPropertyUnit
          dataset={processedDataset}
          filters={filters}
          handleSubFiltersUpdate={handleSubFiltersUpdate}
          onSelectPropertyUnit={onSelectPropertyUnit}
          selectedPropertyUnit={selectedPropertyUnit}
          config={{
            values: {
              barOne: 'revenues',
              barTwo: 'expenses',
              lineOne: 'netIncome',
            },
          }}
          isZero={maxNumber === 0}
          maxNumber={maxNumber}
          dataPointsInViewport={6}
          dataType={dataType}
        />
      );
    }
  }

  return (
    <Stack flex="1" px={isDashboard && hasNoData && '24px'}>
      <NewKPIs
        {...{
          filters,
          dataset,
          selectedMonth,
          isDashboard,
          hasNoData,
          totalUncategorizedUnhiddenTxCount,
        }}
      />
      <Box {...barChartContainerStyles(isDashboard)}>{barChart}</Box>
    </Stack>
  );
}

CashFlowDataWrapper.defaultProps = {
  lastPreset: null,
  isDashboard: false,
  totalUncategorizedUnhiddenTxCount: null,
};

export default CashFlowDataWrapper;
