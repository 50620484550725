import { useMediaQuery } from '@chakra-ui/react';
import { maxWidth, minWidth } from './helpers/breakpoints.helpers';

const useBreakPoints = () => {
  const [isLargerThan1537] = useMediaQuery('(min-width: 1537px)', { ssr: false });
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)', { ssr: false });
  const [isMin1440] = useMediaQuery(minWidth('2xl'), { ssr: false });
  const [isMin1150] = useMediaQuery(minWidth('2lg'), { ssr: false });
  const [isMin899] = useMediaQuery(minWidth('lg'), { ssr: false });
  const [isMin768] = useMediaQuery(minWidth('md'), { ssr: false }); // this is wrong, should be 769
  const [isMax768] = useMediaQuery(maxWidth('md'), { ssr: false });
  const [isMax767] = useMediaQuery('(max-width: 767px)', { ssr: false });
  const [isMin577] = useMediaQuery(minWidth('sm'), { ssr: false });
  const [isMax576] = useMediaQuery(maxWidth('sm'), { ssr: false });
  const [isMaxHeight720] = useMediaQuery('(max-height: 720px)', { ssr: false });

  // To be phased out. Do not use this anymore use any breakpoints above.
  const isMinXL = !isMin899;

  return {
    isMinXL,
    isMin768,
    isMax768,
    isMax767,
    isMin577,
    isMax576,
    isMin899,
    isMin1150,
    isMin1440,
    isLargerThan1537,
    isLargerThan1280,
    isMaxHeight720,
  };
};

export default useBreakPoints;
