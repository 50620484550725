import React from 'react';
import { Skeleton } from '@chakra-ui/react';
import { Icon16Info } from '@core/components/Icons/16px';
import { BaselaneAlertNew } from '@core/components/Shared/components';

type ReportUnavailableAlertProps = {
  isLoading: Boolean,
};

const ReportUnavailableAlert = ({ isLoading }: ReportUnavailableAlertProps) => {
  return (
    <Skeleton isLoaded={!isLoading} minH="320px">
      <BaselaneAlertNew
        iconName={Icon16Info}
        visual="icon"
        body="There is no information to display for this report."
        variant="neutral"
      />
    </Skeleton>
  );
};

export default ReportUnavailableAlert;
