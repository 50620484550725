import React from 'react';
import { BaselaneChip } from '@shared/components';
import { Icon16Info } from '@icons/16px';
import {
  REPORT_STATUS,
  REPORT_STATUS_ICON,
  REPORT_STATUS_VARIANT,
} from '../helpers/tenantScreeningDetail.helpers';

type StatusChipProps = {
  status: String,
};

const StatusChip = ({ status }: StatusChipProps) => {
  const label = REPORT_STATUS[status]
    ? `${REPORT_STATUS[status][0].toUpperCase()}${REPORT_STATUS[status].substring(1)}`
    : REPORT_STATUS[status];
  const icon = REPORT_STATUS_ICON[status];
  const variant = REPORT_STATUS_VARIANT[status];

  return label ? (
    <BaselaneChip leftIcon={icon} label={label} size="sm" variant={variant} />
  ) : (
    <BaselaneChip leftIcon={Icon16Info} label={status} size="sm" variant="simple-neutral" />
  );
};

export default StatusChip;
