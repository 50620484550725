import React, { useContext } from 'react';
import transactionContext from '@contexts/TransactionContext';
import DisplayInputWrapperWithRightButton from '../../../Shared/components/BaselaneDropdown-new/DisplayInputVariations/DisplayInputWrapperWithRightButton';
import DisplayInputDefaultCategoryContent from './DisplayInputDefaultCategoryContent';

type DisplayInputDefaultCategoryItemProps = {
  selectedOptions: Array<Object>,
  handleContentRendererClick: Function,
  placeholder: string,
  title: string,
  handleClearClick: Function,
  classNames: Array<string>,
};

const DisplayInputDefaultCategoryItem = ({
  handleContentRendererClick,
  selectedOptions,
  placeholder,
  title,
  handleClearClick,
  classNames,
}: DisplayInputDefaultCategoryItemProps) => {
  const { categoryMap } = useContext(transactionContext);

  return (
    <DisplayInputWrapperWithRightButton
      {...{
        handleContentRendererClick,
        handleClearClick,
        selectedOptions,
        classNames,
      }}
    >
      <DisplayInputDefaultCategoryContent
        {...{
          placeholder,
          title,
          selectedOptions,
          categoryMap,
        }}
      />
    </DisplayInputWrapperWithRightButton>
  );
};

export default DisplayInputDefaultCategoryItem;
