import React from 'react';
import IconStar from '@icons/manual/IconStar';

import easyStepsRC from '../../assets/easyStepsRC_2x.png';
import easyStepsLB from '../../assets/easyStepsLB_2x.png';
import easyStepsBA from '../../assets/easyStepsBA_2x.png';

const easyStepsInfo = {
  rent_collection: {
    title: 'Automate your rent collection',
    badge: {
      text: 'Takes 2 Minutes',
      color: 'blue',
    },
    image: easyStepsRC,
    steps: [
      {
        icon: <IconStar color="currentColor" />,
        text: 'Stress-free rent collection',
        subText: 'Automated invoicing, payment reminders, and late fees without lifting a finger',
      },
      {
        icon: <IconStar color="currentColor" />,
        text: 'Fast payouts',
        subText: 'Payments are deposited directly into your bank account in as fast as 2 days ',
      },
      {
        icon: <IconStar color="currentColor" />,
        text: 'Convenient for tenants',
        subText:
          'Let your tenants pay rent from any device by ACH bank transfer or credit card, plus they can easily enroll in auto-pay',
      },
    ],
  },
  landlord_banking: {
    title: 'Open a banking account in minutes',
    subMessage: 'No account fees or minimum balance',
    image: easyStepsLB,
    steps: [
      { icon: '1', text: 'Select an account type: individual (sole proprietorship) or business.' },
      {
        icon: '2',
        text: 'Apply for an account by answering some questions about yourself and your business.',
      },
      {
        icon: <IconStar color="currentColor" />,
        text: 'Start banking',
        subText: 'Seamlessly integrated into bookkeeping, reporting, & rent collection.',
      },
    ],
  },
  bookkeeping: {
    title: "Let's automate your bookkeeping & analytics",
    subMessage: 'Maximize profits & save countless hours for free',
    image: easyStepsBA,
    steps: [
      {
        icon: '1',
        text: 'Securely link your bank accounts',
        subText: 'Automatically track your income & expenses',
      },
      { icon: '2', text: 'Easily tag your transactions to Schedule E categories and properties' },
      {
        icon: <IconStar color="currentColor" />,
        text: 'Get automated reporting & cash flow insights',
      },
    ],
  },
};

export default easyStepsInfo;
