import { useState, useCallback } from 'react';

export function validateTransferLimits({
  dailyTotal = 0,
  monthlyTotal = 0,
  dailyLimit,
  monthlyLimit,
  amount = 0,
}) {
  const dailyTransfer = parseFloat(String(dailyTotal)) + parseFloat(String(amount));
  const monthlyTransfer = parseFloat(String(monthlyTotal)) + parseFloat(String(amount));

  if (dailyTransfer > dailyLimit) {
    return {
      hasError: true,
      message: 'Amount exceeds daily limit for this account',
    };
  }

  if (monthlyTransfer > monthlyLimit) {
    return {
      hasError: true,
      message: 'Amount exceeds the monthly limit for this account',
    };
  }

  return { hasError: false, message: '' };
}

export default function useTransferLimitValidation() {
  const [error, setError] = useState({ hasError: false, message: '' });

  const validateLimits = useCallback(
    ({ dailyTotal = 0, monthlyTotal = 0, dailyLimit, monthlyLimit, amount = 0 }) => {
      const result = validateTransferLimits({
        dailyTotal,
        monthlyTotal,
        dailyLimit,
        monthlyLimit,
        amount,
      });

      setError(result);

      return result;
    },
    []
  );

  const clearError = useCallback(() => {
    setError({ hasError: false, message: '' });
  }, []);

  return {
    error,
    validateLimits,
    clearError,
    hasError: error.hasError,
    errorMessage: error.message,
  };
}
