// @flow
// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=1609%3A79975
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import moment from 'moment';
import { cloneDeep, sortBy } from 'lodash';
import { useMutation } from '@apollo/client';
import { Text, Stack, useDisclosure, useToast } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { useStatsigClient } from '@statsig/react-bindings';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { BaselaneDrawer } from '@shared/components';
import stripCurrency from '@core/utils/stripCurrency';
import TransactionContext from '@contexts/TransactionContext';
import { IconExternalBankTransfer, IconInternalBankTransfer, IconMailCheckDeposit } from '@icons';
import IconWireTransfer from '@icons/manual/IconWireTransfer';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { useUnitOtp } from '@core/contexts/UnitOtpContext';
import { CREATE_TRANSFER, GET_TRANSFERS } from '../../../queries';
import { paymentMethods } from './helpers/paymentCards.helpers';
import { accountTypesMapper } from '../BankTransfer/helpers/transfers.helper';
import AddFundsDrawerFooter from '../AddFundsDrawer/AddFundsDrawerFooter';
import SendFundsAndInternalTransferDrawer from './components/SendFundsAndInternalTransferDrawer';
import MailCheckDrawer from './components/MailCheckDrawer';
import DomesticWireDrawer from './components/DomesticWireDrawer';
import UnsavedChangesAlert from './components/UnsavedChangesAlert';
import ButtonMethod from '../ButtonMethod';
import TriggerButton from './TriggerButton';
import { title, drawerBodyStyles } from '../AddFundsDrawer/styles/addFundsDrawer.styles';

type MakeTransferOrPaymentDrawerProps = {
  banks: Object,
  refetchBankSummary: Function,
  buttonProps?: Object,
  showMobileButton?: Boolean,
};

const MakeTransferOrPaymentDrawer = forwardRef(
  (
    { banks, refetchBankSummary, buttonProps, showMobileButton }: MakeTransferOrPaymentDrawerProps,
    ref
  ) => {
    const {
      totalTransactionCount,
      startPollingTransactionsSummary,
      stopPollingTransactionsSummary,
    } = useContext(TransactionContext);

    const { isMinXL } = useBreakPoints();
    const { checkGate } = useStatsigClient();

    // Alert State
    const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

    // State variables
    const [paymentMethod, setPaymentMethod] = useState({});
    const [transfer, setTransfer] = useState({});
    const [isValidAccount, setIsValidAccount] = useState(false);
    const [isValidBankTransfer, setIsValidBankTransfer] = useState(false);
    const [lastTotalTransactionCount, setLastTotalTransactionCount] = useState(
      totalTransactionCount
    );
    const [hasBEError, setHasBEError] = useState(false);
    const [propertyId, setPropertyId] = useState(null);
    const [unitId, setUnitId] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    // Every transaction needs a unique idempotency key. This is used to prevent duplicate transactions.
    // It changes every time a transaction finishes.
    const [xIdempotencyKey, setXIdempotencyKey] = useState(uuidv4());

    // Mutation
    const [createNewTransfer, { loading: createTransferLoading }] = useMutation(CREATE_TRANSFER);

    // Make Transfer/Payment Drawer
    const makeTransferOrPaymentDrawerRef = useRef(null);

    const onOpenMakeTransferOrPaymentDrawer = () => makeTransferOrPaymentDrawerRef?.current.open();
    const onCloseMakeTransferOrPaymentDrawer = () =>
      makeTransferOrPaymentDrawerRef?.current.close();

    // Domestic Wire drawer
    const domesticWireRef = useRef(null);
    const domesticWireDrawerRef = useRef(null);
    const onCloseDomesticWireDrawer = () => {
      refetchBankSummary();
      domesticWireDrawerRef.current.close();
    };
    const onOpenDomesticWireDrawer = () => domesticWireDrawerRef?.current.open();

    const mailCheckRef = useRef(null);
    const mailCheckDrawerRef = useRef();

    const onOpenMailCheckDrawer = () => mailCheckDrawerRef?.current?.open();
    const onCloseMailCheckDrawer = () => mailCheckDrawerRef?.current?.close();

    // Bank Transfer drawer
    const bankTransferDrawerRef = useRef(null);
    const onCloseBankTransferDrawer = () => {
      setXIdempotencyKey(uuidv4());
      if (hasBEError) {
        setHasBEError(false);
      }
      refetchBankSummary();
      bankTransferDrawerRef.current.close();
    };
    const onOpenBankTransferDrawer = () => bankTransferDrawerRef.current.open();

    // Review Transfer Drawer
    const reviewTransferDrawerRef = useRef(null);
    const onCloseReviewTransferDrawer = () => {
      reviewTransferDrawerRef.current.close();
    };

    const onOpenReviewTransferDrawer = () => reviewTransferDrawerRef.current.open();

    // Transfer Confirmation Drawer
    const transferConfirmationDrawerRef = useRef(null);
    const onCloseTransferConfirmationDrawer = () => transferConfirmationDrawerRef.current.close();

    const onOpenTransferConfirmationDrawer = () => transferConfirmationDrawerRef.current.open();

    // Local variables
    const bankId = () => transfer?.selectedAccountFrom?.group?.id;

    // Unit OTP
    const { verifyUnitOtp, ignore } = useUnitOtp();

    // Toast
    const toast = useToast();
    const showErrorToast = () =>
      toast({
        description: `Failed to schedule ${accountTypesMapper[transfer.transferType]} transfer`,
        status: 'error',
        duration: '3000',
        isClosable: true,
        position: 'bottom-left',
      });

    const { DrawerBody } = BaselaneDrawer;

    useEffect(() => {
      if (totalTransactionCount !== lastTotalTransactionCount) {
        stopPollingTransactionsSummary();
        setLastTotalTransactionCount(totalTransactionCount);
        // this will update the balance with the latest transaction result
        refetchBankSummary();
      }
      // make sure if component is destroyed that we stop polling as well
      return () => stopPollingTransactionsSummary();
    }, [totalTransactionCount, lastTotalTransactionCount, setLastTotalTransactionCount]);

    const cleanup = () => {
      setTransfer({});
      setPaymentMethod({});
    };
    const handleBankTransferClose = () => {
      // TODO: Update logic we allow other transfer dates
      if (Object.keys(transfer).length > 2 && transfer?.transferType === 'TRANSFER_OUT') {
        onAlertOpen();
      } else {
        onCloseBankTransferDrawer();
        cleanup();
      }
    };

    const handleCloseAllDrawers = () => {
      setXIdempotencyKey(uuidv4());
      onCloseTransferConfirmationDrawer();
      onCloseReviewTransferDrawer();
      onCloseBankTransferDrawer();
      onCloseMakeTransferOrPaymentDrawer();
      onCloseDomesticWireDrawer();
    };

    const handleNewTransfer = () => {
      const defaultInput = {
        amount: stripCurrency(transfer.transferAmount),
        fromTransferAccountId: transfer.selectedAccountFrom.accountId,
        toTransferAccountId: transfer.selectedAccountTo.accountId,
        transferDate: moment(transfer.transferDate).format('YYYY-MM-DD'),
        type: transfer.transferType,
      };

      const optionalTagging = { tagId: categoryId, propertyId, unitId };

      createNewTransfer({
        context: {
          headers: {
            'x-idempotency-key': xIdempotencyKey,
          },
        },
        variables: {
          input:
            transfer.transferType === 'TRANSFER_OUT'
              ? { ...defaultInput, ...optionalTagging }
              : defaultInput,
        },
        update: (cache, { data: createTransfer, errors }) => {
          const rootObject = cache?.data?.data?.ROOT_QUERY;
          const findTransferProperty = Object.keys(rootObject).filter((i) =>
            i.includes('transfers')
          );

          const variablesArray = findTransferProperty?.map((v) => {
            const firstParenthesis = (v || '').indexOf('(') + 1;
            const lastParenthesis = (v || '').length - 1;
            return JSON.parse(v?.substring(firstParenthesis, lastParenthesis));
          });

          const defaultVariables = {
            input: {
              page: 1,
              pageLimit: 100,
              sort: { direction: 'DESC', field: 'transferDate' },
              filter: {
                fromTransferDate: null,
                toTransferDate: null,
              },
            },
          };

          variablesArray.forEach((vars) => {
            const { transfers } =
              cache.readQuery({
                query: GET_TRANSFERS,
                variables: vars || defaultVariables,
              }) || {};

            if (transfers && !errors) {
              const newT = [...transfers.data, createTransfer?.createTransfer];
              const updatedTransfers = sortBy(cloneDeep(newT), ['transferDate']).reverse();
              cache.writeQuery({
                query: GET_TRANSFERS,
                variables: vars || defaultVariables,
                data: {
                  transfers: { data: updatedTransfers, total: transfers.total },
                },
              });
            }
          });
        },
      }).then(
        (dataResponse) => {
          if (dataResponse?.data && !dataResponse?.errors) {
            onOpenTransferConfirmationDrawer();
            // this will change total transactions, triggering refetch transactions in transactions list
            startPollingTransactionsSummary(2000);
            setTimeout(() => {
              stopPollingTransactionsSummary();
            }, 20000);
          } else {
            showErrorToast();
            setHasBEError(true);
          }
        },
        (err) => {
          console.error(err);
          showErrorToast();
          setHasBEError(true);
        }
      );
    };

    const checkToken = (runSubmit) => {
      verifyUnitOtp(bankId())
        .then(() => {
          if (runSubmit) {
            runSubmit();
          } else if (transfer?.transferType === 'WIRE_TRANSFER') {
            domesticWireRef?.current?.handleWireTransferSubmit();
          } else if (transfer?.transferType === 'CHECK_PAYMENT') {
            mailCheckRef?.current?.handleMailCheckTransferSubmit();
          } else if (transfer?.transferType === 'TRANSFER_OUT') {
            handleNewTransfer();
          }
        })
        .catch(ignore);
    };

    const getIcon = (id) => {
      if (id === 'INTERNAL_TRANSFER') {
        return <IconInternalBankTransfer />;
      }

      if (id === 'TRANSFER_OUT') {
        return <IconExternalBankTransfer />;
      }

      if (id === 'CHECK_MAIL') {
        return <IconMailCheckDeposit />;
      }

      return <IconWireTransfer />;
    };

    const handleExitWithoutSaving = () => {
      onCloseBankTransferDrawer();
      onAlertClose();
      cleanup();
    };

    const sendFundsAndInternalTransferDrawerProps = {
      bankTransferDrawerRef,
      handleBankTransferClose,
      paymentMethod,
      isValidAccount,
      setIsValidAccount,
      setIsValidBankTransfer,
      setTransfer,
      isValidBankTransfer,
      onAlertOpen,
      onCloseReviewTransferDrawer,
      createTransferLoading,
      checkToken,
      transferConfirmationDrawerRef,
      transfer,
      handleCloseAllDrawers,
      cleanup,
      onOpenReviewTransferDrawer,
      reviewTransferDrawerRef,
      refetchBankSummary,
      hasBEError,
      setHasBEError,
      setPropertyId,
      setUnitId,
      setCategoryId,
      onCloseTransferConfirmationDrawer,
    };

    const domesticWireDrawerProps = {
      makeTransferOrPaymentDrawerRef,
      domesticWireDrawerRef,
      onCloseDomesticWireDrawer,
      onOpenDomesticWireDrawer,
      paymentMethod,
      setIsValidAccount,
      setIsValidBankTransfer,
      isValidBankTransfer,
      transfer,
      setTransfer,
      onAlertOpen,
      checkToken,
    };

    const mailCheckDrawerProps = {
      makeTransferOrPaymentDrawerRef,
      mailCheckDrawerRef,
      onOpenMailCheckDrawer,
      onCloseMailCheckDrawer,
      paymentMethod,
      setIsValidAccount,
      setIsValidBankTransfer,
      isValidBankTransfer,
      transfer,
      setTransfer,
      onAlertOpen,
      checkToken,
    };

    useImperativeHandle(ref, () => ({
      onOpenMakeTransferOrPaymentDrawer,
      onOpenBankTransferDrawer,
      onOpenDomesticWireDrawer,
      onOpenMailCheckDrawer,
      setTransfer,
    }));

    const activePaymentMethods = !checkGate(FEATURE_GATES.CHECK_PAYMENT_GATE)
      ? paymentMethods.filter((p) => p.id !== 'CHECK_MAIL')
      : paymentMethods;

    return (
      <>
        <TriggerButton
          {...{
            onOpenMakeTransferOrPaymentDrawer,
            buttonProps,
            showMobileVersion: showMobileButton,
          }}
        />

        <UnsavedChangesAlert
          {...{
            isAlertOpen,
            onAlertClose,
            rightButtonEvent: handleExitWithoutSaving,
          }}
        />

        <BaselaneDrawer
          ref={makeTransferOrPaymentDrawerRef}
          title={isMinXL ? '' : 'Send Funds'}
          size="md"
          isMobileHeader={isMinXL}
          hideBackText={false}
          showBackButton
          hasMobileShadow={false}
          closeEvent={onCloseMakeTransferOrPaymentDrawer}
        >
          <DrawerBody {...drawerBodyStyles(isMinXL)}>
            <Text {...title(isMinXL)} mb="24px">
              {isMinXL ? 'Send Funds or Internal Transfer' : 'Select Type of Transfer'}
            </Text>
            <Stack justifyContent="center" spacing="8px">
              {activePaymentMethods.map((p) => (
                <ButtonMethod
                  key={uuidv4()}
                  {...{
                    handleOnClick: () => {
                      if (p.id === 'WIRE_TRANSFER') {
                        setPaymentMethod(p.id);
                        onOpenDomesticWireDrawer();
                      } else if (p.id === 'CHECK_MAIL') {
                        setPaymentMethod(p.id);
                        onOpenMailCheckDrawer();
                      } else {
                        setPaymentMethod(p.id);
                        onOpenBankTransferDrawer();
                      }
                    },
                    title: p.title,
                    description: p.description,
                    icon: getIcon(p.id),
                  }}
                />
              ))}
              <MailCheckDrawer ref={mailCheckRef} {...mailCheckDrawerProps} />
              <DomesticWireDrawer ref={domesticWireRef} {...domesticWireDrawerProps} />
              <SendFundsAndInternalTransferDrawer
                {...sendFundsAndInternalTransferDrawerProps}
                onClose={() => {
                  setXIdempotencyKey(uuidv4());
                }}
              />
            </Stack>
          </DrawerBody>
          <AddFundsDrawerFooter onCloseAddFundsDrawer={onCloseMakeTransferOrPaymentDrawer} />
        </BaselaneDrawer>
      </>
    );
  }
);
MakeTransferOrPaymentDrawer.defaultProps = {
  buttonProps: { variant: 'filled', palette: 'primary' },
  showMobileButton: false,
};

export default MakeTransferOrPaymentDrawer;
