/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import LogRocket from 'logrocket';
import { DateTime } from 'luxon';
import {
  ChakraProvider,
  Box,
  Text,
  Spacer,
  Divider,
  HStack,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import {
  BaselaneDrawer,
  BaselaneCardNew,
  BaselaneButton,
  BaselaneChip,
  getQueryDownloadUrl,
  DocumentCard,
  BaselaneBannerNew,
} from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { Icon16Delete, Icon16Notes, Icon16Document } from '@icons/16px';
import habitatTheme from '@core/themeHabitat';
import { GET_LEASES } from '@pages/LeasesPage/queries';
import { GET_DOCUMENT_DOWNLOAD_URL, GET_DOCUMENTS } from '@core/apollo/queries';
import { IllustrationRCPaymentCompleted } from '@core/components/Illustrations';
import RocketLawyeriFrame from '@shared/components/RocketLawyerDocumentDrawer/components/RocketLawyeriFrame';
import { CREATE_LEASE_AGREEMENT, LEASE_AGREEMENT } from '@core/constants/routes';
import DisplayName from './components/DisplayName';
import LeaseAgreementNextStep from './components/LeaseAgreementNextStep';
import { useLeaseAgreement } from './hooks/useLeaseAgreement';
import { CHARGEBEE_URL, CREATE_OR_UPDATE_LEASE_AGREEMENT, SIGN_LEASE_AGREEMENT } from './queries';
import {
  documentStateVariants,
  getDisplayNameLabel,
  formatLeaseAgreementDuration,
} from './helpers/leaseAgreement.helper';
import DeleteLeaseAgreementModal from './DeleteLeaseAgreementModal';
import { useSetLeaseAgreementId } from './CreateLeaseAgreement/leaseAgreementStore';

const LeaseAgreementDetailsDrawer = ({ from }) => {
  const { isMax576 } = useBreakPoints();
  const navigate = useNavigate();
  const setLeaseAgreementId = useSetLeaseAgreementId();
  const toast = useToast();
  const { leaseagreementId } = useParams();

  const [enrollmentUrl, setEnrollmentUrl] = useState(null);
  const [generateChargeCheckoutUrl, { loading: isLoadingUrl }] = useLazyQuery(CHARGEBEE_URL);

  const [updateLeaseAgreement] = useMutation(CREATE_OR_UPDATE_LEASE_AGREEMENT);
  const [showiFrame, setShowiFrame] = useState(false);

  const [selectedPropertyDoc, setSelectedPropertyDoc] = useState(null);
  const [signLeaseAgreement] = useMutation(SIGN_LEASE_AGREEMENT);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { leaseAgreement, loading } = useLeaseAgreement(leaseagreementId);

  const [isDocReadyToSign, setIsDocReadyToSign] = useState(
    leaseAgreement?.state === 'READY_TO_SIGN'
  );
  const { data: leaseAgreementDocuments } = useQuery(GET_DOCUMENTS, {
    variables: {
      entityId: leaseagreementId,
    },
  });
  const [getDocumentDownloadUrl] = useLazyQuery(GET_DOCUMENT_DOWNLOAD_URL, {
    fetchPolicy: 'network-only',
  });

  const leaseAgreementDocument = leaseAgreementDocuments?.fetchDocuments[0];

  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure();

  const showEnrollErrorToast = () =>
    toast({
      description: 'Issue updating your data, try again later',
      status: 'error',
      duration: '2000',
      isClosable: true,
      position: !isMax576 ? 'bottom' : 'bottom-left',
    });

  const showChargebeeErrorToast = () =>
    toast({
      description: 'Issue updating your data, try again later',
      status: 'error',
      duration: '2000',
      isClosable: true,
      position: !isMax576 ? 'bottom' : 'bottom-left',
    });

  const showChargebeeSuccessToast = () => {
    const successToastId = 'success-toast';

    if (!toast.isActive(successToastId)) {
      toast({
        id: successToastId,
        description: 'Payment successful',
        status: 'success',
        duration: '2000',
        isClosable: true,
        position: !isMax576 ? 'bottom' : 'bottom-left',
      });
    }
  };

  const chargebeeSite = process.env.REACT_APP_CHARGEBEE_SITE;
  const chargebeePublishableKey = process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY;

  const handleStartSigning = async () => {
    if (
      leaseAgreement?.state === 'READY_TO_SIGN' &&
      leaseAgreement?.paymentStatus !== 'paid' &&
      !leaseAgreement?.isMigrated
    ) {
      setIsModalOpen(true);
      try {
        window.Chargebee.init({
          site: chargebeeSite,
          publishableKey: chargebeePublishableKey,
        });
        const checkout = await window.Chargebee.getInstance().createCheckout({
          url: enrollmentUrl,
          callbacks: {
            error: (error: any) => {
              LogRocket.log(error);
              showChargebeeErrorToast();
            },
            close: () => {
              onModalClose();
            },
            success: (data) => {
              showChargebeeSuccessToast();
              updateLeaseAgreement({
                variables: {
                  id: leaseAgreement?.id,
                  hostedPageId: data,
                  inputType: 'UPLOAD',
                },
              })
                .then((res) => {
                  if (
                    res?.data?.createOrUpdateLeaseAgreement?.paymentStatus === 'paid' &&
                    res?.data?.createOrUpdateLeaseAgreement?.state === 'READY_TO_SIGN'
                  ) {
                    // Call signLeaseAgreemnet
                    signLeaseAgreement({
                      variables: {
                        id: leaseAgreement?.id,
                      },
                    }).catch((error) => {
                      console.error('Sign lease agreement failed:', error);
                    });
                  }
                })
                .catch((error) => console.error('Update lease failed:', error));
            },
          },
        });

        checkout.mount('#checkout').then((res) => console.log('res', res));
      } catch (error) {
        LogRocket.log(error);
      }
    } else {
      setSelectedPropertyDoc(leaseAgreement);
      setShowiFrame(true);
    }
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const handleCloseiFrame = () => {
    setShowiFrame(false);
    setSelectedPropertyDoc(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    setIsDocReadyToSign(leaseAgreement?.state === 'READY_TO_SIGN');
    if (leaseAgreement?.state === 'READY_TO_SIGN') {
      setSelectedPropertyDoc(null);
      generateChargeCheckoutUrl({
        variables: {
          checkoutUrlInput: {
            id: leaseAgreement?.id,
            itemPrice: ['5'],
            layout: 'IN_APP',
          },
        },
      })
        .then((res) => {
          setEnrollmentUrl(res?.data?.generateChargeCheckoutUrl);
        })
        .catch((error) => {
          LogRocket.log(error);
          showEnrollErrorToast();
        });
    }
  }, [leaseAgreement]);

  const getLeaseButtonConfig = (state) => {
    switch (state) {
      case 'DRAFT':
        return {
          label: 'Edit draft',
          variant: 'filled',
          palette: 'primary',
          leftIcon: <Icon16Document />,
        };
      case 'READY_TO_CUSTOMIZE':
        return {
          label: 'Customize lease',
          variant: 'filled',
          palette: 'primary',
          leftIcon: <Icon16Document />,
        };
      case 'READY_TO_SIGN':
        return {
          label: 'Start signing',
          variant: 'filled',
          palette: 'primary',
          leftIcon: <Icon16Notes />,
        };
      case 'SIGNING_IN_PROGRESS':
        return {
          label: 'View lease',
          variant: 'outline',
          palette: 'neutral',
          leftIcon: <Icon16Document />,
        };
      case 'SIGNING_COMPLETE':
        return {
          label: 'View lease',
          variant: 'outline',
          palette: 'neutral',
          leftIcon: <Icon16Document />,
        };
      default:
        return {
          label: 'View lease',
          variant: 'outline',
          palette: 'neutral',
          leftIcon: <Icon16Document />,
        };
    }
  };

  const handleViewLease = () => {
    setIsDocReadyToSign(true);
    setSelectedPropertyDoc(leaseAgreement);
    setShowiFrame(true);
  };

  const handleResumeDraftLeaseAgreemnt = () => {
    setLeaseAgreementId(leaseAgreement?.id);
    navigate(`/${CREATE_LEASE_AGREEMENT}?draftId=${leaseAgreement?.id}`);
  };
  const { label, variant, palette, leftIcon } = getLeaseButtonConfig(leaseAgreement?.state);

  const canDeleteAgreement = leaseAgreement?.state !== 'SIGNING_COMPLETE';

  const { data } = useQuery(GET_LEASES, {
    variables: { input: { state: ['SCHEDULED', 'EXPIRED'] } },
  });

  const { leases } = data?.leases ?? {};
  const hasActiveOrExpiredLeases = leases?.length > 0;

  const showLeaseAttachmentCard =
    (leaseAgreement?.state === 'SIGNING_IN_PROGRESS' ||
      leaseAgreement?.state === 'SIGNING_COMPLETE' ||
      leaseAgreement?.isUploaded) &&
    hasActiveOrExpiredLeases;

  const downloadDocument = ({ url }) => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
  };

  // Handle Download
  const onDownloadDocument = async () => {
    const { generateDocumentDownloadUrl: url } =
      (await getQueryDownloadUrl(leaseAgreementDocument?.id, getDocumentDownloadUrl)) ?? {};

    if (url) {
      downloadDocument({ url });
    }
  };

  return (
    <ChakraProvider theme={habitatTheme}>
      <BaselaneDrawer
        isOpen
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        title="Lease agreement details"
        closeEvent={() => navigate(`/${LEASE_AGREEMENT}`)}
        closeOnOverlayClick
        newDesignDrawer
      >
        <BaselaneCardNew palette="dark" mb={2}>
          <Stack w="100%" gap={1}>
            <DisplayName
              label={getDisplayNameLabel(leaseAgreement)}
              fieldName="documentName"
              initialValue={leaseAgreement?.documentName}
              leaseId={leaseagreementId}
              isLAManuallyUploaded={leaseAgreement?.manuallyUploaded}
            />

            <Divider my={1} opacity={1} color="brand.darkBlue.200" />

            {/* Property Info */}
            <Box display="flex" mt={0}>
              <Text textStyle="xs">Property</Text>
              <Spacer />
              <Text textStyle="sm">{leaseAgreement?.propertyUnit?.propertyName}</Text>
            </Box>

            <Box display="flex" mt={0}>
              <Text textStyle="xs">Unit</Text>
              <Spacer />
              <Text textStyle="sm">{leaseAgreement?.propertyUnit?.name}</Text>
            </Box>

            {!leaseAgreementDocument && (
              <Box display="flex" mt={0}>
                <Text textStyle="xs">Tenant</Text>
                <Spacer />
                <Box flexDirection="column">
                  <Text textStyle="sm" textAlign="right">
                    {leaseAgreement?.tenantProfileMetadata?.firstName}{' '}
                    {leaseAgreement?.tenantProfileMetadata?.lastName}{' '}
                  </Text>
                </Box>
              </Box>
            )}

            <Box display="flex" mt={0}>
              <Text textStyle="xs">Duration</Text>
              <Spacer />

              <Text textStyle="sm">
                <Text textStyle="sm">
                  {formatLeaseAgreementDuration(
                    leaseAgreement?.startDate,
                    leaseAgreement?.endDate,
                    leaseAgreement?.leaseType
                  )}
                </Text>
              </Text>
            </Box>

            <Divider my={1} opacity={1} color="brand.darkBlue.200" />

            <HStack mt="0">
              <Text textStyle="xs">Status</Text>
              <Spacer />
              <Stack gap={0}>
                <BaselaneChip
                  label={leaseAgreement?.state
                    ?.replace(/_/g, ' ')
                    ?.toLowerCase()
                    ?.replace(/^\w/, (c) => c.toUpperCase())}
                  size="sm"
                  variant={documentStateVariants[leaseAgreement?.state] || 'simple-neutral'}
                />
              </Stack>
            </HStack>

            {leaseAgreement?.lastEditedAt && (
              <Box display="flex" mt={0}>
                <Text textStyle="xs">Last edited</Text>
                <Spacer />

                <Text textStyle="sm">
                  <Text textStyle="sm">
                    {DateTime.fromISO(leaseAgreement?.lastEditedAt).toRelative({
                      unit: 'days',
                    })}
                  </Text>
                </Text>
              </Box>
            )}
          </Stack>
        </BaselaneCardNew>

        {leaseAgreementDocument && (
          <Box mb={2}>
            <DocumentCard
              isNewDesign
              doc={leaseAgreementDocument}
              getDownloadUrl={async () =>
                getQueryDownloadUrl(leaseAgreementDocument.id, getDocumentDownloadUrl)
              }
              documentTitle={leaseAgreementDocument.name}
            />
          </Box>
        )}

        {(leaseAgreement?.paymentStatus === 'paid' ||
          leaseAgreement?.paymentStatus === 'success') &&
          !leaseAgreement?.isMigrated && (
            <BaselaneBannerNew
              title="Payment completed"
              body="You've already paid for the e-signing"
              bannerIconName={IllustrationRCPaymentCompleted}
              hasActionButton={false}
              variant="success-medium"
              hasCloseButton={false}
              styles={{
                container: {
                  marginBottom: '16px',
                },
                alert: {
                  borderRadius: '8px',
                },
              }}
            />
          )}

        <HStack display="flex" direction="row" gap="2" w="100%">
          <BaselaneButton
            variant={variant}
            palette={palette}
            size="md"
            leftIcon={leftIcon}
            isDisabled={loading || isLoadingUrl}
            isFullWidth
            w="100%"
            onClick={() => {
              if (label === 'View document') {
                onDownloadDocument();
              } else if (label === 'View lease') {
                handleViewLease();
              } else if (label === 'Edit draft') {
                handleResumeDraftLeaseAgreemnt();
              } else if (label === 'Customize lease' || label === 'Start signing') {
                // TODO : Check what View details is supposed to do
                handleStartSigning();
              }
            }}
          >
            {label}
          </BaselaneButton>
          {canDeleteAgreement && (
            <BaselaneButton
              leftIcon={<Icon16Delete />}
              palette="danger"
              size="md"
              variant="outline"
              onClick={onDeleteAlertOpen}
              isDisabled={loading}
              isFullWidth
            >
              Delete {leaseAgreement?.state === 'DRAFT' ? 'draft' : 'lease'}
            </BaselaneButton>
          )}
        </HStack>
        {showLeaseAttachmentCard && <LeaseAgreementNextStep />}
        {isDeleteAlertOpen && <DeleteLeaseAgreementModal handleClose={onDeleteAlertClose} />}
        <Outlet />
      </BaselaneDrawer>
      <Modal isOpen={isModalOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent alignItems="center" justifyContent="center" height="80%" top="10%">
          <ModalBody id="checkout" w="100%" h="100%" p={0} m={0} />
        </ModalContent>
      </Modal>

      {selectedPropertyDoc && (
        <RocketLawyeriFrame
          {...{
            showiFrame,
            handleCloseiFrame,
            isDocReadyToSign,
            setIsDocReadyToSign,
            selectedPropertyDoc,
          }}
        />
      )}
    </ChakraProvider>
  );
};

export default LeaseAgreementDetailsDrawer;
