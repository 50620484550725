import React, { useContext } from 'react';
import { Heading, Text, Stack } from '@chakra-ui/react';
import { useStatsigClient } from '@statsig/react-bindings';
import { IllustrationBanking } from '@illustrations';
import {
  BaselaneCardNew,
  BaselaneButton,
  BaselaneApplicationTypePopup,
  NoAccessTooltip,
} from '@shared/components';
import UserAccessContext from '@contexts/UserAccessContext';
import { DASHBOARD } from '@routes';

import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { DYNAMIC_CONFIGS } from '@core/constants/statsigKeys';
import { containerStyles } from './styles';
import useStartApplicationFlow from '../useStartApplicationFlow';

const EmptyState = () => {
  const { isOpen, onOpen, onClose, onPopupClick } = useStartApplicationFlow(DASHBOARD);
  const { isWorkspaceOwner } = useContext(UserAccessContext);
  const { getDynamicConfig } = useStatsigClient();
  const config = getDynamicConfig(DYNAMIC_CONFIGS.APY_INTEREST);

  return (
    <>
      <BaselaneCardNew {...containerStyles} height="100%" justifyContent="center">
        <Stack my={{ md: 4, base: 0 }} spacing={2} alignItems="center">
          <IllustrationBanking />
          <Stack spacing={1} alignItems="center" maxWidth="480px">
            <Heading size="headline-lg">Banking built for real estate investors</Heading>
            <Text textStyle="sm" textAlign="center">
              Organize funds with unlimited checking accounts, grow your money with up to{' '}
              {config?.get('apy') ?? '--'}% APY, and automate bills and payments — all with no
              hidden or monthly fees.
            </Text>
            {!isWorkspaceOwner ? (
              <NoAccessTooltip>
                <BaselaneButton
                  variant="filled"
                  palette="primary"
                  size="lg"
                  styles={{ mt: 2 }}
                  isDisabled={!isWorkspaceOwner}
                  onClick={() => {
                    sendSegmentEvent('dashboard_open_bank_account');
                    onOpen();
                  }}
                >
                  Open banking account
                </BaselaneButton>
              </NoAccessTooltip>
            ) : (
              <BaselaneButton
                variant="filled"
                palette="primary"
                size="lg"
                styles={{ mt: 2 }}
                onClick={() => {
                  sendSegmentEvent('dashboard_open_bank_account');
                  onOpen();
                }}
              >
                Open banking account
              </BaselaneButton>
            )}
          </Stack>
        </Stack>
      </BaselaneCardNew>

      <BaselaneApplicationTypePopup
        handleActionButton={onPopupClick}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default EmptyState;
