import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

// Currently we don't have a global toaster and rely on location changes to trigger them
// This is to show toasters from CreateAutoTagRule Drawer in /transactions and /bookkeeping

export const useAutoTagRuleToast = () => {
  const location = useLocation();
  const toast = useToast();

  useEffect(() => {
    if (!location.state) return;

    const {
      createAutoTagRuleSuccessful,
      editAutoTagRuleSuccessful,
      autoTagRuleDeleted,
      errorOccurred,
    } = location.state;

    const commonProps = {
      position: 'bottom-left',
      duration: 3000,
      isClosable: true,
    };

    if (createAutoTagRuleSuccessful) {
      toast({
        description: 'Rule added successfully.',
        status: 'success',
        ...commonProps,
      });
    }

    if (editAutoTagRuleSuccessful) {
      toast({
        description: 'Rule has been updated.',
        status: 'success',
        ...commonProps,
      });
    }

    if (autoTagRuleDeleted) {
      toast({
        description: 'Rule deleted successfully.',
        status: 'success',
        ...commonProps,
      });
    }

    if (errorOccurred) {
      toast({
        description: 'Something went wrong. Try again',
        status: 'error',
        ...commonProps,
      });
    }
  }, [location.state, toast]);
};
