import { React, useRef } from 'react';
import { Box, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { PopupButton } from '@typeform/embed-react';
import { useNavigate } from 'react-router-dom';
import { BaselaneButton, BaselaneCardNew, BaselaneLink } from '@core/components/Shared/components';
import { Icon16ChevronLeft } from '@core/components/Icons/16px';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import useBreakPoints from '@core/hooks/useBreakPoints';
import DeferredSignupPageHeader from './DeferredSignupPageHeader';

const RenterSignUpInfo = (): any => {
  const typeformRef = useRef();
  const navigate = useNavigate();
  const { isMin899 } = useBreakPoints();
  return (
    <VStack>
      <DeferredSignupPageHeader>
        {isMin899 && (
          <Text>
            Already have an account?{' '}
            <BaselaneLink to="https://tenant.baselane.com/login">
              <Text as="span" textStyle="md-heavy">
                Sign in
              </Text>
            </BaselaneLink>
          </Text>
        )}
      </DeferredSignupPageHeader>
      <HStack px={2} pt={isMin899 ? 10 : 5}>
        <VStack maxW="460px" gap={4}>
          <BaselaneButton
            variant="transparent"
            palette="primary"
            size="lg"
            leftIcon={<Icon16ChevronLeft />}
            onClick={() => {
              navigate(-1);
            }}
            alignSelf="flex-start"
          >
            Back to landlord signup
          </BaselaneButton>
          <Heading size="2xl" fontWeight="semibold" textAlign="center">
            To sign up as a renter, you need an invitation from your landlord
          </Heading>
          <BaselaneCardNew palette="dark" flexDirection="column" gap={0.5}>
            <Heading as="h3" size="md-heavy" textAlign="center">
              Have you already been invited?
            </Heading>
            <Text textStyle="sm" textAlign="center">
              Please check your email or texts for an invitation with instructions to set up your
              account.
            </Text>
          </BaselaneCardNew>
          <VStack gap={2.5}>
            <Heading as="h3" size="md-heavy" textAlign="center">
              Looking to get your landlord on Baselane?
            </Heading>
            <BaselaneButton
              variant="filled"
              palette="primary"
              size="lg"
              onClick={() => {
                typeformRef?.current?.open();
              }}
            >
              Share Baselane with your landlord
            </BaselaneButton>
          </VStack>
        </VStack>
      </HStack>
      {/* This is a hidden Typeform Embed button, so that we can
          use a properly styled BaselaneButton to open the Typeform overlay */}
      <Box display="none" appearance="none" aria-hidden>
        <PopupButton
          ref={typeformRef}
          id="WEzrIIvM"
          onReady={() => {
            sendSegmentEvent('signup_click_share_with_landlord');
          }}
        />
      </Box>
    </VStack>
  );
};

export default RenterSignUpInfo;
