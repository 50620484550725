/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Divider } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';
import Cell from './shared/Cell';
import Label from './shared/Label';
import Value from './shared/Value';

const Scores = ({
  creditScore = '--',
  pastDueAccounts,
  balancePastDue,
  balanceOfCollections,
  bankruptcy = '--',
  debtBalance,
}) => {
  return (
    <Box width="100%">
      <Box display="flex" flexDirection={{ base: 'column', sm: 'row' }} paddingY={2} gap={3}>
        <Cell>
          <Label>Credit score</Label>
          <Value>{creditScore}</Value>
        </Cell>
        <Cell>
          <Label>Past due accounts</Label>
          <Value>{pastDueAccounts ? `${pastDueAccounts}%` : '--'}</Value>
        </Cell>
        <Cell>
          <Label>Balance past due</Label>
          <Value>{balancePastDue ? formatCurrency(balancePastDue).inDollars : '--'}</Value>
        </Cell>
      </Box>
      <Divider marginY={1} />
      <Box display="flex" flexDirection={{ base: 'column', sm: 'row' }} paddingY={2} gap={3}>
        <Cell>
          <Label>Balance of collections</Label>
          <Value>
            {balanceOfCollections ? formatCurrency(balanceOfCollections, true).inDollars : '--'}
          </Value>
        </Cell>
        <Cell>
          <Label>Bankruptcy</Label>
          <Value>{bankruptcy}</Value>
        </Cell>
        <Cell>
          <Label>Debt balance</Label>
          <Value>{debtBalance ? formatCurrency(debtBalance).inDollars : '--'}</Value>
        </Cell>
      </Box>
    </Box>
  );
};

export default Scores;
