import { useLazyQuery } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { GET_KYC_URL } from '@core/components/NativeBankingPage/queries';
import { UNIFIED_LB_PERSONAL_INFO } from '@routes';
import historyStateStorage from '@core/storage/historyStateStorage';

const useStartApplicationFlow = (from): any => {
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [getKYCUrl] = useLazyQuery(GET_KYC_URL, {
    onCompleted: ({ unitOnBoarding }) => window.open(unitOnBoarding, '_self'),
    onError: (kycUrlError) => console.error(kycUrlError),
    fetchPolicy: 'no-cache',
  });

  const onPopupClick = (type) => {
    if (type === 'business') {
      getKYCUrl();
    } else {
      historyStateStorage.write({ from });
      navigate(UNIFIED_LB_PERSONAL_INFO);
    }
  };

  return { isOpen, onOpen, onClose, onPopupClick };
};

export default useStartApplicationFlow;
