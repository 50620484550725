import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HStack, Flex, Box, Text, Checkbox } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { BaselaneButton } from '@shared/components';

type FooterProps = {
  loading: Boolean,
};

const Footer = ({ loading }: FooterProps) => {
  const navigate = useNavigate();
  const { values, submitForm, setFieldValue } = useFormikContext();

  const descriptionExits =
    values.isDescriptionOpen && (values.textValues.length > 0 || values.text);
  const amountExits =
    values.isAmountOpen &&
    (values.amountOperator.operator === 'BETWEEN'
      ? values.amountSecondaryValue && values.amountValue
      : values.amountValue);
  const accountOrCardExists = values.isAccountCardOpen && (values.accountId || values.cardId);
  const categoryExists = values.isCategoryOpen && values.categoryId;
  const propertyExits = values.isPropertyOpen && values.propertyId;

  const isEnabled =
    (descriptionExits || amountExits || accountOrCardExists) && (categoryExists || propertyExits);

  return (
    <Box>
      <Flex gap="8px" mb={2} fontSize="14px">
        <Checkbox
          className="primary-500"
          isChecked={values.updateExistingTransactions}
          size="md"
          onChange={() =>
            setFieldValue('updateExistingTransactions', !values.updateExistingTransactions)
          }
        />
        <Text width="100%" fontWeight="500" color={isEnabled ? 'inherit' : 'brand.neutral.400'}>
          Update all existing transactions.{' '}
          <BaselaneButton
            palette="primary"
            variant="link"
            size="md"
            isDisabled={!isEnabled}
            color={isEnabled ? 'brand.blue.800A' : 'brand.neutral.400'}
            onClick={() => setFieldValue('isPreviewTransactionsModalOpen', true)}
          >
            Preview transactions
          </BaselaneButton>
        </Text>
      </Flex>
      <HStack w="full">
        <BaselaneButton
          size="md"
          variant="outline"
          palette="neutral"
          onClick={() => navigate('..')}
          data-cy="cancel"
        >
          Cancel
        </BaselaneButton>
        <BaselaneButton
          size="md"
          variant="filled"
          palette="primary"
          width="100%"
          ml={1.5}
          flex={1}
          onClick={submitForm}
          isLoading={loading}
          isDisabled={!isEnabled || loading}
          data-cy="next"
        >
          Save
        </BaselaneButton>
      </HStack>
    </Box>
  );
};

export default Footer;
