import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { isMobile } from 'react-device-detect';
import { Box, Stack, Text } from '@chakra-ui/react';

import { ApplicationTypeButtons } from '@shared/components';
import userContext from '@contexts/UserContext';
import useOnboardingStore from '@store/Onboarding';
import { UPDATE_USER } from '@core/components/UserProfilePage/queries';
import { GET_KYC_URL } from '@core/components/NativeBankingPage/queries';
import {
  handleFinishLater,
  handleGetUserInfo,
  handleRouteChange,
} from '@core/components/OnboardingTriage/helpers/onboardingFunctions.helpers';

import {
  applicationContainerStyles,
  applicationSubTextStyles,
  applicationTitleStyles,
} from '../styles/unifiedBankingFlow.styles';

type ApplicationTypeProps = {
  handleSolePropContinue: Function,
  setApplicationType: Function,
};

function ApplicationType({
  handleSolePropContinue,
  setApplicationType,
}: ApplicationTypeProps): any {
  const { user, refetchUser, setOnboardingBaselaneAccount } = useContext(userContext);
  const navigate = useNavigate();

  const { selection: onboardingTriageSelection } = useOnboardingStore();

  const [updateUser] = useMutation(UPDATE_USER);
  const [getKYCUrl] = useLazyQuery(GET_KYC_URL, {
    onCompleted: ({ unitOnBoarding }) => {
      window.open(unitOnBoarding, '_self');
    },
    fetchPolicy: 'no-cache',
  });

  const handleActionButton = (type) => {
    if (type === 'sole') {
      setApplicationType('sole');
      handleSolePropContinue();
    } else if (user.onboardingCompleted && type === 'business') {
      // in app - business application
      setApplicationType('business');
      getKYCUrl();
    } else {
      // onboarding flow - business application
      setApplicationType('business');

      if (onboardingTriageSelection === 'bookkeeping') {
        getKYCUrl({
          variables: {
            isBankingFirst: onboardingTriageSelection === 'bookkeeping',
          },
        });
      } else {
        handleFinishLater({
          user: handleGetUserInfo(user, 'landlord_banking'),
          refetchUser,
          updateUser,
          handleRouteChange: () => {
            handleRouteChange(navigate, 'landlord_banking');
          },
          setFlowTrue: () => {
            setOnboardingBaselaneAccount(true);
          },
        });
      }
    }
  };

  return (
    <Stack {...applicationContainerStyles}>
      <Box mt={isMobile ? '0' : '50px'}>
        <Text {...applicationTitleStyles}>Select an account type</Text>
        <Text {...applicationSubTextStyles}>
          Opening a Baselane Banking account takes less than 3 minutes.
        </Text>
      </Box>
      <ApplicationTypeButtons
        handleActionButton={handleActionButton}
        buttonStyles={{ maxWidth: '600px', width: isMobile ? 'auto' : '600px' }}
      />
    </Stack>
  );
}

export default ApplicationType;
