import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Heading, ChakraProvider } from '@chakra-ui/react';
import {
  ADD_FUNDS_BANK_TRANSFER,
  ADD_FUNDS_CHECK_DEPOSIT,
  ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS,
  ADD_FUNDS_DIGITAL_WALLET,
} from '@routes';
import useBreakPoints from '@core/hooks/useBreakPoints';
import habitatTheme from '@core/themeHabitat';
import { Icon24Transfer, Icon24Accounts, Icon24Mobile, Icon24CheckPayment } from '@icons/24px';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';
import BaselaneCardStack from '@shared/components/BaselaneCardStack';
import TransferCard from './components/TransferCard';

type AddFundsProps = { from?: string };
const AddFunds = ({ from = null }: AddFundsProps) => {
  const { isMax576 } = useBreakPoints();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location ?? {};

  const handleCloseDrawer = () => {
    const previousRoute = pathname.slice(0, pathname.lastIndexOf('/'));
    navigate({ pathname: previousRoute, search });
  };

  const itemsRecommendedMethods = [
    {
      id: 'bank-transfer',
      content: (
        <TransferCard
          icon={<Icon24Transfer />}
          title="Bank transfer"
          description="Deposits in 3-5 business days"
          onClick={() => {
            navigate({
              pathname: ADD_FUNDS_BANK_TRANSFER,
            });
          }}
        />
      ),
    },
    {
      id: 'mobile-check-deposit',
      content: (
        <TransferCard
          icon={<Icon24CheckPayment />}
          title="Mobile check deposit"
          description="Deposits in 3 business days"
          onClick={() =>
            navigate({
              pathname: ADD_FUNDS_CHECK_DEPOSIT,
            })
          }
        />
      ),
    },
  ];
  const itemsMoreMethods = [
    {
      id: 'account-routing-numbers',
      content: (
        <TransferCard
          icon={<Icon24Accounts />}
          title="Account & Routing numbers"
          description="Use another bank to initiate a Wire or ACH transfer to Baselane"
          onClick={() => {
            navigate({
              pathname: ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS,
            });
          }}
        />
      ),
    },
    {
      id: 'digital-wallet',
      content: (
        <TransferCard
          icon={<Icon24Mobile />}
          title="Paypal / Venmo"
          description="Transfer funds into your Baselane account from your preferred digital wallet"
          onClick={() => {
            navigate({
              pathname: ADD_FUNDS_DIGITAL_WALLET,
            });
          }}
        />
      ),
    },
  ];

  return (
    <ChakraProvider theme={habitatTheme}>
      <BaselaneDrawer
        isOpen
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        title="Add funds"
        closeEvent={handleCloseDrawer}
        onOverlayClick={handleCloseDrawer}
        closeOnOverlayClick={false}
        newDesignDrawer
      >
        <Heading as="h3" size="headline-md" fontWeight="medium" mb={2}>
          Recommended methods
        </Heading>
        <BaselaneCardStack items={itemsRecommendedMethods} />
        <Heading as="h3" size="headline-md" fontWeight="medium" mt={3} mb={2}>
          More methods
        </Heading>{' '}
        <BaselaneCardStack items={itemsMoreMethods} />
        <Outlet />
      </BaselaneDrawer>
    </ChakraProvider>
  );
};

export default AddFunds;
