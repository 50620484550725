import { gql } from '@apollo/client';

export const GET_BANK_SUMMARY = gql`
  query BankSummary(
    $isConnectedBank: Boolean
    $isConnectedAccount: Boolean
    $accountType: AccountType
    $isImporting: Boolean
    $isExternal: Boolean
    $isManualAccount: Boolean
    $accountStatus: BankAccountStatus
  ) {
    bankSummary(
      input: {
        isConnectedBank: $isConnectedBank
        isConnectedAccount: $isConnectedAccount
        accountType: $accountType
        isImporting: $isImporting
        isExternal: $isExternal
        isManualAccount: $isManualAccount
        accountStatus: $accountStatus
      }
    ) {
      baselaneBankBalance
      bank {
        id
        name
        unitAccount {
          applicationType
        }
        bankAccounts {
          id
          availableBalance
          accountStatus
          subAccounts {
            id
            accountStatus
            availableBalance
          }
        }
      }
    }
  }
`;

export const GET_CARD_SUMMARY = gql`
  query CardSummary($page: Float, $pageSize: Float, $filter: CardFilter) {
    cardSummary(input: { page: $page, pageSize: $pageSize, filter: $filter }) {
      cardFinancials {
        dailySpent
        mtdSpent
        ytdCashBack
        dailySpent
      }
      total
    }
  }
`;
