import React, { useRef } from 'react';
import { FormControl } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { Icon16PlusCircle } from '@icons/16px';
import useBreakPoints from '@core/hooks/useBreakPoints';
import {
  BaselaneFormLabel,
  BaselaneFormErrorMessage,
  BaselaneFormHelperText,
  BaselaneInput,
  T1WithTitleDropdown,
} from '@shared/components';
import DropDownDisplayInputWithOutRightButton from '@shared/components/BaselaneDropdown-new/DisplayInputVariations/DropDownDisplayInputWithOutRightButton';
import {
  currencyMask,
  renderAccountDropdownItemWithBalance,
  handleFromAccountHandleSubmit,
  handleToAccountHandleSubmit,
} from '@shared/components/TransferFunds/helpers';
import { formStyles } from '@shared/components/TransferFunds/styles';
import AddAccount from '@core/components/NativeBankingPage/MainContent/components/Accounts/components/AddAccount';

type Step1Props = {
  formikProps: Object,
  setTransferType: Function,
  getUpdateLinkToken: Function,
  fromDropdownList: Array,
  formattedPlaidAcc: Array,
  formattedBaselaneAcc: Array,
  accountsMap: Object,
  refetch: Function,
  state: Object,
};

const Step1 = ({
  formikProps,
  setTransferType,
  getUpdateLinkToken,
  formattedPlaidAcc,
  fromDropdownList,
  formattedBaselaneAcc,
  accountsMap,
  refetch,
  state,
}: Step1Props) => {
  const { isMax576 } = useBreakPoints();

  const sharedDropdownProps = {
    placeholder: 'Select',
    title: 'Account',
    searchTerm: ['name', 'nickName'],
    showValueByFields: ['name', 'nickName'],
    itemRenderer: renderAccountDropdownItemWithBalance,
    isMulti: false,
    hasFilterWrapper: false,
    hasCheckboxes: false,
    showDivider: false,
    CustomDisplayInput: DropDownDisplayInputWithOutRightButton,
    parentId: 'drawer-body',
    isMobile: isMax576,
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
  } = formikProps;

  const { controlStyles } = formStyles;
  const addExternalAccountDrawerRef = useRef();
  const isFromAddFunds = true;

  const toDropdownList = formattedBaselaneAcc.map((acc) => {
    const items = acc?.items.filter((item) => item.accountSubType !== 'savings');
    return {
      ...acc,
      items,
    };
  });

  return (
    <>
      {/* Transfer From */}
      <FormControl
        id="add-funds-transfer-from-account"
        isInvalid={errors.fromTransferAccountId && touched.fromTransferAccountId}
        isRequired
        {...controlStyles}
      >
        <BaselaneFormLabel textStyle="sm" htmlFor="fromTransferAccountId">
          Transfer from
        </BaselaneFormLabel>
        <T1WithTitleDropdown
          name="fromTransferAccountId"
          additionalProps={{ id: 'transfer-funds-transfer-from-dropdown' }}
          classNames={[
            'input-form-lg',
            'auto-select-input-width',
            ...(errors.fromTransferAccountId && touched.fromTransferAccountId
              ? ['input-invalid']
              : []),
          ]}
          data={formattedPlaidAcc}
          selectedItem={accountsMap[values.fromTransferAccountId]}
          handleSubmit={(selectedValue) => {
            handleFromAccountHandleSubmit({
              values,
              selectedValue,
              setFieldTouched,
              setFieldValue,
              accountsMap,
              setTransferType,
              getUpdateLinkToken,
            });
          }}
          placeholder="Select Account"
          title={sharedDropdownProps.title}
          searchTerm={sharedDropdownProps.searchTerm}
          showValueByFields={sharedDropdownProps.showValueByFields}
          itemRenderer={sharedDropdownProps.itemRenderer}
          isMulti={sharedDropdownProps.isMulti}
          hasFilterWrapper={sharedDropdownProps.hasFilterWrapper}
          hasCheckboxes={sharedDropdownProps.hasCheckboxes}
          showDivider={sharedDropdownProps.showDivider}
          CustomDisplayInput={sharedDropdownProps.CustomDisplayInput}
          parentId={sharedDropdownProps.parentId}
        />

        <BaselaneFormErrorMessage
          isInvalid={errors.fromTransferAccountId && touched.fromTransferAccountId}
        >
          {errors.fromTransferAccountId}
        </BaselaneFormErrorMessage>

        <AddAccount
          buttonProps={{
            text: 'Connect an external account',
            variant: 'transparent',
            palette: 'primary',
            size: 'md',
            leftIcon: <Icon16PlusCircle />,
            styles: { mt: 1 },
          }}
          addExternalAccountDrawerRef={addExternalAccountDrawerRef}
          isFromAddFunds={isFromAddFunds}
          loaderStyles={{
            w: '0px !important',
            h: '0px !important',
            top: '50%',
            left: '50%',
          }}
          refetchTransferAccountsList={refetch}
        />
      </FormControl>

      {/* Deposit To */}
      <FormControl
        id="transfer-funds-deposit-to-account"
        isInvalid={errors.toTransferAccountId && touched.toTransferAccountId}
        isRequired
        {...controlStyles}
      >
        <BaselaneFormLabel textStyle="sm" htmlFor="toTransferAccountId">
          Deposit to
        </BaselaneFormLabel>
        <T1WithTitleDropdown
          name="toTransferAccountId"
          additionalProps={{ id: 'transfer-funds-deposit-to-dropdown' }}
          classNames={[
            'input-form-lg',
            'auto-select-input-width',
            ...(errors.toTransferAccountId && touched.toTransferAccountId ? ['input-invalid'] : []),
          ]}
          data={toDropdownList}
          selectedItem={accountsMap[values.toTransferAccountId]}
          handleSubmit={(selectedValue) => {
            handleToAccountHandleSubmit({
              values,
              selectedValue,
              setFieldTouched,
              setFieldValue,
              accountsMap,
              setTransferType,
              getUpdateLinkToken,
            });
          }}
          placeholder="Select Account"
          title={sharedDropdownProps.title}
          searchTerm={sharedDropdownProps.searchTerm}
          showValueByFields={sharedDropdownProps.showValueByFields}
          itemRenderer={sharedDropdownProps.itemRenderer}
          isMulti={sharedDropdownProps.isMulti}
          hasFilterWrapper={sharedDropdownProps.hasFilterWrapper}
          hasCheckboxes={sharedDropdownProps.hasCheckboxes}
          showDivider={sharedDropdownProps.showDivider}
          CustomDisplayInput={sharedDropdownProps.CustomDisplayInput}
          parentId={sharedDropdownProps.parentId}
        />

        {!errors.toTransferAccountId &&
          (accountsMap[values.toTransferAccountId]?.isExternal ||
            accountsMap[values.toTransferAccountId]?.provider === 'MANUAL') && (
            <BaselaneFormHelperText type="isError" customStyles={{ color: 'brand.neutral.600' }}>
              External transfers will take 3 business days
            </BaselaneFormHelperText>
          )}
        <BaselaneFormErrorMessage
          isInvalid={errors.toTransferAccountId && touched.toTransferAccountId}
        >
          {errors.toTransferAccountId}
        </BaselaneFormErrorMessage>
      </FormControl>
      {/* Amount */}
      <FormControl
        id="add-funds-amount"
        isInvalid={errors.amount && touched.amount}
        isRequired
        {...controlStyles}
      >
        <BaselaneFormLabel textStyle="sm" htmlFor="amount">
          Amount
        </BaselaneFormLabel>
        <BaselaneInput
          id="amount"
          name="amount"
          value={values.amount}
          size="lg"
          prefix="$"
          as={MaskedInput}
          mask={currencyMask}
          onChange={(e) => {
            setFieldTouched('amount');
            handleChange(e);
          }}
          isInvalid={errors.amount && touched.amount}
          onBlur={handleBlur}
        />
        <BaselaneFormErrorMessage isInvalid={errors.amount && touched.amount}>
          {errors.amount}
        </BaselaneFormErrorMessage>
      </FormControl>
    </>
  );
};

export default Step1;
