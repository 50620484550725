// @flow
import React, { useRef, useContext } from 'react';
import { Formik } from 'formik';
import {
  ChakraProvider,
  Text,
  useToast,
  HStack,
  Box,
  Spacer,
  FormControl,
  FormErrorMessage,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { WORKSPACE_OWNER_VERIFY_EMAIL, USER_WORKSPACE } from '@routes';
import useBreakPoints from '@core/hooks/useBreakPoints';
import habitatTheme from '@core/themeHabitat';
import {
  BaselaneButton,
  BaselaneDrawer,
  BaselaneFormLabel,
  BaselaneInput,
  BaselaneAlert,
  AlertHeader,
  AlertFooter,
  BaselaneBannerNew,
} from '@shared/components';
import UserContext from '@contexts/UserContext';
import { UPDATE_USER } from '@core/components/UserProfilePage/queries';
import { Icon16ChevronRight } from '@icons/16px';
import { IllustrationOtherWarning } from '@illustrations';
import useCurrentWorkspace from '@core/components/Shared/hooks/useCurrentWorkspace';

type OwnerProfileNameDrawerProps = { from: String };
const BannerIcon = () => <IllustrationOtherWarning />;

function OwnerProfileNameDrawer({ from }: OwnerProfileNameDrawerProps): any {
  const unsavedChangesAlertRef = useRef();
  const { user, refetchUser } = useContext(UserContext);
  const [updateUser] = useMutation(UPDATE_USER);
  const { userKycStatus } = useCurrentWorkspace();
  const { isMax576 } = useBreakPoints();
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const initialValues = { firstName: user?.firstName || '', lastName: user?.lastName || '' };

  // unsaved changes alert
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  // Toast
  const toast = useToast();
  const showSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Profile updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Error updating profile',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleValidation = (values) => {
    const errors = {};

    if (!values.firstName || values.firstName === '') {
      errors.firstName = 'Please enter your first name';
    }

    if (!values.lastName || values.lastName === '') {
      errors.lastName = 'Please enter your last name';
    }
    return errors;
  };

  const handleDrawerClose = () => {
    navigate(USER_WORKSPACE);
  };

  const handleCloseWithoutSaving = (touched) => {
    const isFormTouched = Object.keys(touched).length > 0;
    if (isFormTouched) {
      onAlertOpen();
    } else {
      handleDrawerClose();
    }
  };

  const handleSubmit = (values) => {
    const isUpdated = JSON.stringify(values) !== JSON.stringify(initialValues);
    if (!isUpdated) {
      navigate(WORKSPACE_OWNER_VERIFY_EMAIL);
    } else {
      updateUser({
        variables: {
          firstName: values?.firstName,
          lastName: values?.lastName,
        },
      })
        .then((res) => {
          if (res.data) {
            showSuccessToast();
            refetchUser().then(() => navigate(WORKSPACE_OWNER_VERIFY_EMAIL));
          }

          if (res.errors) {
            showErrorToast();
            handleDrawerClose();
          }
        })
        .catch((err) => {
          showErrorToast();
          handleDrawerClose();
        });
    }
  };

  return (
    <ChakraProvider theme={habitatTheme}>
      <Formik
        innerRef={formikRef}
        validateOnChange
        validateOnBlur
        validateOnMount
        initialValues={initialValues}
        validate={handleValidation}
      >
        {(formikProps) => {
          const { values, touched, errors, setFieldTouched, handleChange } = formikProps;

          return (
            <BaselaneDrawer
              isOpen
              size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
              title="Add your profile details"
              closeEvent={() => handleCloseWithoutSaving(touched)}
              onOverlayClick={() => handleCloseWithoutSaving(touched)}
              closeOnOverlayClick={false}
              newDesignDrawer
              footer={
                <>
                  <BaselaneButton
                    size="md"
                    variant="outline"
                    palette="neutral"
                    onClick={() => handleCloseWithoutSaving(touched)}
                  >
                    Cancel
                  </BaselaneButton>
                  <BaselaneButton
                    size="md"
                    variant="filled"
                    palette="primary"
                    width="100%"
                    ml={1.5}
                    flex={1}
                    onClick={() => handleSubmit(values)}
                    rightIcon={<Icon16ChevronRight />}
                    isDisabled={
                      !values?.lastName ||
                      (errors.lastName && touched.lastName) ||
                      !values?.firstName ||
                      (errors.firstName && touched.firstName)
                    }
                  >
                    Save and Continue
                  </BaselaneButton>
                </>
              }
            >
              <HStack
                w="100%"
                position="absolute"
                left="0"
                width="100%"
                top={!isMax576 ? '59px' : '56px'}
              >
                <BaselaneBannerNew
                  hasBannerIcon={!isMax576}
                  hasActionButton={false}
                  body="Before inviting a collaborator, we need a few details on your profile."
                  hasCloseButton={false}
                  title="Your profile details required"
                  variant="warning-medium"
                  bannerIconName={BannerIcon}
                />
              </HStack>
              <Box paddingTop="126px" mb={3}>
                <HStack mb={0.5}>
                  <Text textStyle="headline-md" color="brand.neutral.900">
                    Enter your name
                  </Text>
                  <Spacer />
                  <Text textStyle="xs" color="brand.neutral.600">
                    Step 1 of 3
                  </Text>
                </HStack>
              </Box>
              <>
                <FormControl
                  id="first-name"
                  isRequired
                  isInvalid={errors.firstName && touched.firstName}
                >
                  <BaselaneFormLabel textStyle="sm">First name</BaselaneFormLabel>
                  <BaselaneInput
                    size="md"
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    placeholder="Your first name"
                    type="text"
                    maxLength={50}
                    onChange={(e) => {
                      setFieldTouched('firstName');
                      handleChange(e);
                    }}
                    isDisabled={userKycStatus === 'APPROVED' && user?.firstName}
                  />
                  <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                </FormControl>
                <FormControl
                  id="last-name"
                  isRequired
                  isInvalid={errors.lastName && touched.lastName}
                >
                  <BaselaneFormLabel textStyle="sm">Last name</BaselaneFormLabel>
                  <BaselaneInput
                    size="md"
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    placeholder="Your last name"
                    type="text"
                    onChange={(e) => {
                      setFieldTouched('lastName');
                      handleChange(e);
                    }}
                    isDisabled={userKycStatus === 'APPROVED' && user?.lastName}
                  />
                  <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                </FormControl>
              </>
              <Outlet />
            </BaselaneDrawer>
          );
        }}
      </Formik>
      <BaselaneAlert
        leastDestructiveRef={unsavedChangesAlertRef}
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        header={<AlertHeader title="You Have Unsaved Changes" />}
        body="Are you sure you want to exit without saving?"
        footer={<AlertFooter leftButtonEvent={onAlertClose} rightButtonEvent={handleDrawerClose} />}
      />
    </ChakraProvider>
  );
}

export default OwnerProfileNameDrawer;
