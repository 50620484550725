import React, { PropsWithChildren } from 'react';
import { Box } from '@chakra-ui/react';
import { sectionWrapperStyles } from './section.styles';

/**
 * Simple wrapper component that applies standard left/right responsive padding
 * Intended use: full-width sections, full page
 * @param children
 * @param props
 * @returns {JSX.Element}
 */
const SectionWrapper = ({ children, ...props }: PropsWithChildren) => (
  <Box {...sectionWrapperStyles} {...props}>
    {children}
  </Box>
);

export default SectionWrapper;
