import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { isMobile } from 'react-device-detect';
import { Box, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { useStatsigClient } from '@statsig/react-bindings';
import { BaselaneButton, BaselaneButtonIcon, BaselaneDrawer } from '@shared/components';
import TransactionContext from '@contexts/TransactionContext';
import UserAccessContext from '@contexts/UserAccessContext';
import { getPropertyData } from '@shared/helpers/propertiesFilter.helpers';
import { IconChevronRight } from '@icons';
import { UPDATE_CARD } from '@core/components/NativeBankingPage/queries';
import {
  handleEditModeChange,
  handleEditModeState,
} from '@core/components/NativeBankingPage/components/IndividualInputEditButtons';
import { getOptionsWithSubCategories } from '@core/components/CashFlowPage/helpers/cashflow.helpers';
import UserContext from '@contexts/UserContext';
import useBreakPoints from '@core/hooks/useBreakPoints';

import { FEATURE_GATES } from '@core/constants/statsigKeys';
import BookkeepingRulesSection from '@pages/BankEntity/AccountDetailsDrawer/AutoTagging/BookkeepingRulesSection';
import LockCard from './LockCard';
import TerminateCard from './TerminateCard';
import CardUI from './CardUI';
import TaggingDropdowns from './TaggingDropdowns';
import NicknameSection from './NicknameSection';
import SpendingSection from './SpendingSection';
import LinkedAccountSection from './LinkedAccountSection';
import ConfirmEditLimitChangesAlert from './ConfirmEditLimitChangesAlert';
import { CARD_STATUS } from '../helpers/card.helper';
import {
  drawerSectionStyles,
  drawerCardNumberStyles,
  drawerLabelStyles,
  drawerValueStyles,
  drawerLabel2Styles,
  drawerValue3Styles,
  drawerSection2Styles,
  drawerOpenDetailsStyles,
} from './styles/cardDetailsDrawer.styles';

type CardDetailsProps = {
  hideTriggerButton: boolean,
  card: Object,
  bankInfo: Object,
  startOpened: Boolean,
  cardDetailsDrawerRef: any,
  onOpenCardDetailsDrawer: Function,
  onCloseCardDetailsDrawer: Function,
  setViewCardDetailsInfo: Function,
  cardFilters: Function,
};

function CardDetails({
  hideTriggerButton = false, // TODO: remove once new cards table code is also added
  card,
  bankInfo,
  startOpened = false,
  cardDetailsDrawerRef,
  onOpenCardDetailsDrawer,
  onCloseCardDetailsDrawer,
  setViewCardDetailsInfo = () => {},
  cardFilters = null,
}: CardDetailsProps) {
  const { checkGate } = useStatsigClient();
  const [updateCard, { loading: updateCardLoading }] = useMutation(UPDATE_CARD);

  const {
    setShowMobileDropdownPopup,
    showMobileDropdownPopup,
    setShowMobileDropdownPopupAnimation,
  } = useContext(UserContext);

  const { authorizedForBanking } = useContext(UserAccessContext);

  // information used for tagging dropdowns (category + property)
  const { propertiesData, categoryMap, categoryIdsMap, categoryWithSubOptions } = useContext(
    TransactionContext
  );
  const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);
  const propertyOptions = getPropertyData(propertiesData);

  // variables extracted from card object to be used
  const {
    id = '',
    billingAddress = null,
    last4Digits = '',
    accountName = '',
    nameOnCard = '',
    cardFinancials = null,
    cardStatus = '',
    limit = null,
    nickname = '',
    propertyId,
    unitId,
    tagId: categoryId,
    isPhysical: isPhysicalCard,
  } = card ?? {};

  const {
    bankId,
    phoneNumber = '',
    bankName: masterAccountName,
    accountNumber: bankAccountNumber,
    availableBalance: bankAccountBalance,
    autoTag: bankAccountAutoTag,
    limits: bankAccountLimits,
  } = bankInfo ?? {};

  /*
   * Further extracting information from variables. Billing address comes back as string.
   * In future maybe this can be optimized to return object if possible.
   */
  const parsedBillingAddress = billingAddress ? JSON.parse(billingAddress) : '';
  const { city, postalCode, state, street, street2 } = parsedBillingAddress ?? {};
  const { mtdSpent } = cardFinancials ?? {};

  // set up values for card details form => editModeValues variable state
  const formattedPropertyId = propertyId?.toString() ?? propertyId;
  const puid = propertyId && unitId ? `${propertyId}-${unitId}` : formattedPropertyId;
  const initialValues = () => ({
    propertyId: formattedPropertyId,
    unitId: unitId?.toString() ?? unitId,
    propertyUnitId: propertyId ? puid : null,
    categoryId,
    nickname: { isEdit: false, value: nickname ?? accountName },
    spendingLimit: {
      isEdit: false,
      amount: limit?.amount ?? 0,
      frequency: limit?.frequency ?? 'DAILY',
    },
  });

  const [editModeValues, setEditModeValues] = useState(initialValues);

  // to update the initial values when drawer is opened because initially it was set
  //  as users go to entity page
  useEffect(() => {
    if (card) {
      setEditModeValues(initialValues());
    }
  }, [card]);

  // Confirm edit limit changes alert
  const {
    isOpen: isConfirmEditLimitPopupOpen,
    onOpen: onConfirmEditLimitPopupOpen,
    onClose: onConfirmEditLimitPopupClose,
  } = useDisclosure();

  useEffect(() => {
    if (startOpened) onOpenCardDetailsDrawer();
  }, [startOpened]);

  // Toasts to show on success and error when users edits card details
  const toast = useToast();
  const showSuccessToast = (message) =>
    toast({
      position: isMobile ? 'bottom' : 'bottom-left',
      description: message,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showErrorToast = () =>
    toast({
      position: isMobile ? 'bottom' : 'bottom-left',
      description: 'Something went wrong',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  // set new data for property tagging and call mutation to update data
  const handlePropertySubmit = (newPropertyId: string) => {
    const [newPId, newUId] = newPropertyId?.length > 0 ? newPropertyId.split('-') : [];

    updateCard({
      variables: {
        id,
        tags: { propertyId: newPId, unitId: newUId, tagId: editModeValues.categoryId },
      },
    }).then(({ errors }) => {
      if (errors?.length > 0) {
        showErrorToast();
      } else {
        setEditModeValues((prevState) => ({
          ...prevState,
          propertyId: newPId,
          unitId: newUId,
          propertyUnitId: newPropertyId,
        }));
        showSuccessToast('Property Auto-Tagging Updated Successfully');
      }
    });
  };

  // set new data for category tagging and call mutation to update data
  const handleCategorySubmit = (newCategoryId: number, dropDown = () => {}) => {
    const [parentId, subId] = newCategoryId?.length > 0 ? newCategoryId.split('-') : [];

    updateCard({
      variables: {
        id,
        tags: {
          tagId: subId ?? parentId,
          propertyId: editModeValues.propertyId,
          unitId: editModeValues.unitId,
        },
      },
    }).then(({ errors }) => {
      if (errors?.length > 0) {
        showErrorToast();
      } else {
        setEditModeValues((prevState) => ({
          ...prevState,
          categoryId: subId ?? parentId,
        }));
        showSuccessToast('Category Auto-Tagging Updated Successfully');
        dropDown('close');
      }
    });
  };

  // on change of any editable field in card details gets updated in this function
  const handleOnEditChange = (e) => {
    handleEditModeChange(e, setEditModeValues);
  };

  // helper function to help toggle the state of the inputs that are editable
  const handleEditModeToggle = (inputName, shouldToggleOn, shouldSave) => {
    handleEditModeState(inputName, shouldToggleOn, shouldSave, setEditModeValues, initialValues());
  };

  const handleUpdateCard = (inputName, shouldToggleOn, shouldSave) => {
    /*
     * on click of check icon in edit mode run update mutation to update card information
     * that was being edited
     */
    const variables = {
      id,
      ...(!isPhysicalCard
        ? {
            limits: {
              amount: editModeValues.spendingLimit.amount,
              frequency: editModeValues.spendingLimit.frequency,
            },
          }
        : {}),
      nickname: editModeValues.nickname.value,
    };

    if (!isPhysicalCard && inputName === 'spendingLimit') {
      variables.limits = {
        amount: editModeValues.spendingLimit.amount,
        frequency: editModeValues.spendingLimit.frequency,
      };
    }

    if (inputName === 'nickname') {
      variables.nickname = editModeValues.nickname.value;
    }

    updateCard({
      variables,
    })
      .then(({ errors }) => {
        if (errors?.length > 0) {
          showErrorToast();
        } else {
          handleEditModeToggle(inputName, shouldToggleOn, shouldSave);

          const successMessage = `${
            inputName === 'spendingLimit' ? 'Spending Limit' : 'Nickname'
          } Updated Successfully`;
          showSuccessToast(successMessage);
        }

        if (isConfirmEditLimitPopupOpen) {
          onConfirmEditLimitPopupClose();
        }
      })
      .catch(() => showErrorToast());
  };

  // toggles state of the inputs that are editable and saves when shouldSave is true
  const handleEditModeToggleOrSave = (inputName, shouldToggleOn, shouldSave) => {
    if (shouldToggleOn) {
      handleEditModeToggle(inputName, shouldToggleOn, shouldSave);
    } else if (shouldSave && !shouldToggleOn) {
      if (inputName === 'spendingLimit') {
        onConfirmEditLimitPopupOpen();
      } else {
        handleUpdateCard(inputName, shouldToggleOn, shouldSave);
      }
    } else {
      handleEditModeToggle(inputName, shouldToggleOn, shouldSave);
    }
  };

  const handleDrawerClose = () => {
    onCloseCardDetailsDrawer();
    setEditModeValues(initialValues);
  };

  // card status description
  const { description: warningCardMessage } = CARD_STATUS[cardStatus] ?? {};

  const { isMax767 } = useBreakPoints();

  return (
    <>
      {isMobile ? (
        <BaselaneButtonIcon
          variant="tonal"
          palette="primary"
          styles={{ ...drawerOpenDetailsStyles, display: hideTriggerButton ? 'none' : 'flex' }}
          icon={<IconChevronRight w="9" h="16" color="#3A4B5B" />}
          onClick={(e) => {
            e.stopPropagation();
            onOpenCardDetailsDrawer();
          }}
          isDisabled={
            isPhysicalCard && (cardStatus === 'Inactive' || cardStatus === 'ClosedByCustomer')
          }
        />
      ) : (
        <BaselaneButton
          variant="tonal"
          palette="primary"
          styles={{ ...drawerOpenDetailsStyles, display: hideTriggerButton ? 'none' : 'flex' }}
          onClick={(e) => {
            e.stopPropagation();
            onOpenCardDetailsDrawer();
          }}
          isDisabled={
            isPhysicalCard && (cardStatus === 'Inactive' || cardStatus === 'ClosedByCustomer')
          }
        >
          View Details
        </BaselaneButton>
      )}

      <BaselaneDrawer
        ref={cardDetailsDrawerRef}
        title="Card Details"
        closeEvent={handleDrawerClose}
        isMobileHeader={isMobile}
        showBackButton={isMobile}
        size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
        footer={
          <BaselaneButton
            id="footer-card-drawer-close-button"
            variant="outline"
            palette="neutral"
            size="md"
            onClick={handleDrawerClose}
          >
            Close
          </BaselaneButton>
        }
        newDesignDrawer
      >
        <Stack id="tagging">
          <Box {...drawerSectionStyles}>
            <Stack spacing={1}>
              <NicknameSection
                {...{
                  editModeValues,
                  handleEditModeToggleOrSave,
                  handleOnEditChange,
                  cardStatus,
                }}
              />

              <Text {...drawerCardNumberStyles}>
                {isPhysicalCard ? 'Physical' : 'Virtual'} Card - {last4Digits}
              </Text>
            </Stack>
          </Box>

          {/* This Month's Amount Spent + Total Limits + Progress bar */}
          <SpendingSection
            {...{
              editModeValues,
              mtdSpent,
              isPhysicalCard,
              accountName,
              last4Digits: bankAccountNumber?.slice(-4),
              bankAccountBalance,
              handleOnEditChange,
              handleEditModeToggleOrSave,
              bankAccountLimits,
              cardStatus,
            }}
          />

          {/* Card UI + Card Info */}
          <Stack
            direction={!isMobile ? 'row' : 'column'}
            {...(isMobile ? {} : drawerSectionStyles)}
            spacing="32px"
          >
            <Box>
              <CardUI {...{ card, bankId, phoneNumber }} />
              {/* warning message of card status on mobile */}
              {warningCardMessage && isMobile && (
                <Text {...drawerLabelStyles} color="red.500A">
                  {warningCardMessage}
                </Text>
              )}
            </Box>
            <Box>
              <Stack
                direction={isMobile ? 'row' : 'column'}
                {...drawerSection2Styles}
                justifyContent={isMobile ? 'space-between' : 'initial'}
              >
                <Text {...drawerLabelStyles}>Cardholder Name</Text>
                <Text {...(isMobile ? drawerValue3Styles : drawerValueStyles)}>{nameOnCard}</Text>
              </Stack>
              <Stack
                direction={isMobile ? 'row' : 'column'}
                {...drawerSection2Styles}
                justifyContent={isMobile ? 'space-between' : 'initial'}
                pb={isMobile ? '20px' : '0'}
              >
                <Text {...drawerLabelStyles}>Billing Address</Text>
                {billingAddress && street ? (
                  <Box>
                    <Text {...(isMobile ? drawerValue3Styles : drawerValueStyles)}>
                      {street}
                      {!street2 ? '' : `, ${street2}`}
                    </Text>
                    <Text {...(isMobile ? drawerValue3Styles : drawerValueStyles)}>
                      {city}, {state} {postalCode}
                    </Text>
                  </Box>
                ) : (
                  <Text {...drawerValueStyles}>&mdash;</Text>
                )}
              </Stack>

              {isMobile && (
                <LinkedAccountSection
                  {...{
                    masterAccountName,
                    accountName,
                    bankAccountNumber,
                    bankAccountBalance,
                  }}
                />
              )}
            </Box>
          </Stack>

          {/* warning message of card status on desktop */}
          {warningCardMessage && !isMobile && (
            <Box {...drawerSectionStyles}>
              <Text {...drawerLabelStyles} color="red.500A">
                {warningCardMessage}
              </Text>
            </Box>
          )}

          {checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) ? (
            <BookkeepingRulesSection
              card={card}
              values={editModeValues}
              handleSave={(values) => {
                const { propertySelection, categorySelection } = values;
                if (propertySelection) {
                  handlePropertySubmit(propertySelection);
                }
                if (categorySelection) {
                  handleCategorySubmit(categorySelection);
                }
              }}
              autoSave
              showCategory
              mb={2}
            />
          ) : (
            <TaggingDropdowns
              {...{
                propertyOptions,
                categoryOptions,
                categoryMap,
                categoryIdsMap,
                setShowMobileDropdownPopup,
                setShowMobileDropdownPopupAnimation,
                showMobileDropdownPopup,
                values: editModeValues,
                handlePropertySubmit,
                handleCategorySubmit,
                isCardStatusLocked: cardStatus === 'Frozen' || cardStatus === 'SuspectedFraud',
                bankAccountAutoTag,
              }}
            />
          )}

          {!isMobile && (
            <LinkedAccountSection
              {...{
                masterAccountName,
                accountName,
                bankAccountNumber,
                bankAccountBalance,
              }}
            />
          )}

          {/* Lock Card */}
          {authorizedForBanking && (
            <Box {...drawerSectionStyles}>
              <Stack
                direction={isMobile ? 'row' : 'column'}
                justifyContent="space-between"
                spacing={isMobile ? 6 : 2}
              >
                <Box>
                  <Text {...drawerLabel2Styles} fontWeight={isMobile ? 'normal' : 'medium'}>
                    Lock Card
                  </Text>
                  <Text {...drawerLabelStyles} mb={isMobile && '1.5'}>
                    Lock this card to automatically decline all transactions. Unlock at any time.
                  </Text>
                </Box>
                <LockCard {...{ bankId, card, phoneNumber, setViewCardDetailsInfo }} />
              </Stack>
            </Box>
          )}

          {/* Terminate Card */}
          {authorizedForBanking && !isPhysicalCard && (
            <Box {...drawerSectionStyles}>
              <Stack
                direction={!isMobile ? 'row' : 'column'}
                justifyContent="space-between"
                spacing={isMobile ? 6 : 2}
              >
                <Box>
                  <Text {...drawerLabel2Styles} fontWeight={isMobile ? 'normal' : 'medium'}>
                    Terminate Card
                  </Text>
                  <Text {...drawerLabelStyles} mb={isMobile && '1.5'}>
                    Disable this card for future use. This action cannot be undone.
                  </Text>
                </Box>
                <TerminateCard {...{ bankId, card, phoneNumber, cardFilters, handleDrawerClose }} />
              </Stack>
            </Box>
          )}
        </Stack>
      </BaselaneDrawer>

      <ConfirmEditLimitChangesAlert
        {...{
          isConfirmEditLimitPopupOpen,
          onConfirmEditLimitPopupClose,
          handleUpdateCard,
          updateCardLoading,
        }}
      />
    </>
  );
}

export default CardDetails;
