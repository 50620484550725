// @flow
import React from 'react';
import { Icon16Lightbulb } from '@icons/16px';
import BaselaneAlertNew from './BaselaneAlert-new';

type UpdateReceivingAccountsAlertProp = {
  handleUpdateReceivingAccountsClick: Function,
  numberOfLeasesCollectingRentOutsideBaselane: Number,
  title: String,
  body: String,
};

function UpdateReceivingAccountsAlert({
  handleUpdateReceivingAccountsClick,
  numberOfLeasesCollectingRentOutsideBaselane,
  title,
  body,
}: UpdateReceivingAccountsAlertProp): any {
  return (
    <BaselaneAlertNew
      title={title}
      body={body}
      visual="icon"
      iconName={Icon16Lightbulb}
      isVertical
      actionProps={{
        secondary: {
          label: 'Update receiving accounts',
          onClick: handleUpdateReceivingAccountsClick,
        },
      }}
    />
  );
}

export default UpdateReceivingAccountsAlert;
