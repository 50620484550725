import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Flex, Spacer } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';

import { BaselaneHorizontalStepper } from '@shared/components';
import { useCurrentWorkspace } from '@shared/hooks';
import HeaderContentIconWrapper from '@shared/layouts/HeaderNavWrapper/HeaderContentIconWrapper';
import { UNIFIED_BA_ALL_SET, UNIFIED_BA_AUTOTAG, UNIFIED_BA_SUCCESS } from '@routes';
import userContext from '@contexts/UserContext';

const HeaderContent = ({ onboardingCompleted }: { onboardingCompleted: string }) => {
  const { skippedBankingFirst } = useContext(userContext);
  const { workspaceMetadata } = useCurrentWorkspace();
  const { bankingTracker } = workspaceMetadata || {};

  const location = useLocation();

  const steps = [{ title: 'Banking' }, { title: 'Bookkeeping' }];

  const isBankingFinished = (bankingTracker || [])[0]?.state === 'COMPLETED';

  let hContent = <HeaderContentIconWrapper />;

  if (!onboardingCompleted) {
    hContent = (
      <>
        {!isMobile && <HeaderContentIconWrapper flex="1" />}
        <Flex flex="1" flexBasis={0} justifyContent={{ md: 'center', base: 'flex-start' }}>
          <BaselaneHorizontalStepper
            steps={steps}
            activeStep={1}
            isActiveStep={(index) => index === 1}
            isCompleteStep={(index) => {
              if (skippedBankingFirst && !isBankingFinished && index === 0) {
                return false;
              }
              if (
                [UNIFIED_BA_ALL_SET, UNIFIED_BA_SUCCESS, UNIFIED_BA_AUTOTAG].includes(
                  location.pathname
                )
              ) {
                return index <= 1;
              }
              return index < 1;
            }}
            overflowX="initial"
            isSpreadInMobile={false}
            isExpandedInMobile={false}
          />
        </Flex>
        {location.pathname === UNIFIED_BA_ALL_SET && <Spacer flex={1} />}
      </>
    );
  }

  return hContent;
};

export default HeaderContent;
