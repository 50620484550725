import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';
import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import { FEATURE_GATES } from '@core/constants/statsigKeys';

const EmptyPage = () => {
  const zendeskAPI = useZendeskAPI();
  const { checkGate } = useStatsigClient();

  useEffect(() => {
    if (checkGate(FEATURE_GATES.HIDE_ZENDESK_BUBBLE)) {
      zendeskAPI('hideZendeskBubble', true);
    } else {
      zendeskAPI('hideZendeskBubble', false);
    }
  }, []);

  return (
    <Box h="100%">
      <Outlet />
    </Box>
  );
};

export default EmptyPage;
