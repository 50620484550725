import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { Icon16Present } from '@icons/16px';
import { REFERRAL } from '@core/constants/routes';
import useBreakPoints from '@core/hooks/useBreakPoints';
import BaselaneButton from '../../../BaselaneButton';

const ReferralButton = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const url = 'https://baselane.typeform.com/refer-landlord';
  const { isMin768 } = useBreakPoints();

  const label = !isMin768 ? '$100' : 'Refer & earn $100';

  const handleReferralClick = () => {
    navigate(REFERRAL);
  };

  return (
    <>
      <BaselaneButton
        leftIcon={<Icon16Present />}
        variant="tonal"
        palette="primary"
        size="md"
        onClick={() => {
          handleReferralClick();
        }}
        segmentEventName="landlord_click_referral_button"
      >
        {label}
      </BaselaneButton>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        size="full"
        padding={0}
      >
        <ModalOverlay />
        <ModalContent height="100vh">
          <ModalCloseButton color={isMobile ? 'brand.neutral.white' : 'initial'} />
          <ModalBody padding={0}>
            <iframe
              title="refer-landlord"
              src={url}
              width="100%"
              height="100%"
              style={{ border: 'none' }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReferralButton;
