import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Flex, HStack, Link, Text } from '@chakra-ui/react';

import { useStatsigClient } from '@statsig/react-bindings';
import { BaselaneList } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { HOME, GET_STARTED, DASHBOARD, ANALYTICS_REPORTING } from '@routes';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import {
  groupOneNavElements,
  groupTwoNavElements,
  groupThreeNavElements,
  groupFourNavElements,
  groupFiveNavElements,
  transfersPayments,
} from './navElements';
import {
  linkStyles,
  navItemContainerStyles,
  navBarIconContainerStyles,
  navTextStyles,
  navGroupTitleStyles,
} from './styles/nav.styles';

type NavListProps = {
  isOpen: boolean,
  onClick?: Function,
  isMobileNav?: boolean,
  showTransfersPage?: boolean,
};

function NavList({
  isOpen,
  onClick = null,
  isMobileNav = false,
  showTransfersPage = false,
}: NavListProps) {
  const { checkGate } = useStatsigClient();

  const currLocation = useLocation();
  const { pathname } = currLocation;
  const { isLargerThan1537 } = useBreakPoints();

  if (showTransfersPage && groupTwoNavElements.length === 2) {
    groupTwoNavElements.splice(1, 0, transfersPayments);
  }

  const listItemRenderer = (item) => {
    const { Icon, label, props, id = '' } = item;
    const key = label;
    const itemLabel = label.toLowerCase();

    const iconProps =
      itemLabel === 'insurance' || itemLabel === 'loans'
        ? { color: 'currentColor', w: '20', h: '20' }
        : {};

    if (label === 'Profile') {
      return null;
    }

    if (label === 'Analytics & Reporting') {
      return null;
    }

    if (label === 'Property metrics' && !checkGate(FEATURE_GATES.PROPERTY_METRICS_PAGE_GATE)) {
      return null;
    }

    if (label === 'Tenant screening' && !checkGate(FEATURE_GATES.TENANT_SCREENING_GATE)) {
      return null;
    }

    if (label === 'All partners' && !checkGate(FEATURE_GATES.PARTNERSHIPS_PAGE)) {
      return null;
    }

    if (
      (checkGate(FEATURE_GATES.LEASE_AGREEMENT_PAGE) && id === 'nav-lease-documents-old') ||
      (!checkGate(FEATURE_GATES.LEASE_AGREEMENT_PAGE) && id === 'nav-lease-documents')
    ) {
      return null;
    }

    const highlightGetStartedNav =
      id === 'nav-get-started' && (pathname === HOME || pathname.includes(GET_STARTED));

    const highlightedDashboardNav =
      id === 'nav-dashboard' && (pathname === HOME || pathname.includes(DASHBOARD));

    const highlightedHomeNav = checkGate(FEATURE_GATES.DASHBOARD_GATE)
      ? highlightedDashboardNav
      : highlightGetStartedNav;

    return (
      <Link
        key={key}
        {...props}
        {...linkStyles}
        onClick={(e) => {
          if (pathname.includes(ANALYTICS_REPORTING) && label === 'Analytics & reporting') {
            e.preventDefault();
            e.stopPropagation();
            return;
          }
          onClick(label);
        }}
        id={id}
        className={`nav-item ${highlightedHomeNav ? 'active' : ''}`}
      >
        <HStack {...navItemContainerStyles}>
          <Box {...navBarIconContainerStyles}>
            <Icon {...iconProps} />
          </Box>
          <Text {...navTextStyles(isOpen)}>{label}</Text>
        </HStack>
      </Link>
    );
  };

  const navGroupTitleSharedStyles = navGroupTitleStyles({
    isOpen: isMobileNav ? isOpen : isOpen || isLargerThan1537,
  });
  const navGroupTitleRenderer = ({ title }) => <Text {...navGroupTitleSharedStyles}>{title}</Text>;

  const navGroups = [
    {
      id: 'group1',
      group: groupOneNavElements.filter((group) =>
        checkGate(FEATURE_GATES.DASHBOARD_GATE) ? group.id !== 'nav-get-started' : group
      ),
      title: null,
    },
    {
      id: 'group2',
      group: groupTwoNavElements,
      title: navGroupTitleRenderer({ title: 'Banking' }),
    },
    {
      id: 'group3',
      group: groupThreeNavElements,
      title: navGroupTitleRenderer({ title: 'Bookkeeping & analytics' }),
    },
    {
      id: 'group4',
      group: groupFourNavElements,
      title: navGroupTitleRenderer({ title: 'Property management' }),
    },
    {
      id: 'group5',
      group: groupFiveNavElements,
      title: navGroupTitleRenderer({ title: 'Partners' }),
    },
  ];

  return (
    <Flex flexDirection="column" width="100%" gap={2} mb={1.5}>
      {navGroups.map((navGroup) => (
        <BaselaneList
          key={navGroup.id}
          data={navGroup.group}
          itemRenderer={listItemRenderer}
          listStyles={{ spacing: 0 }}
          title={navGroup.title}
        />
      ))}
    </Flex>
  );
}

export default NavList;
