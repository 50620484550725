import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';
import { formatDate } from '@core/utils/formatDate';
import IconWarningTriangleYellow from '@icons/manual/IconWarningTriangleYellow';

type UnableToOrderCheckbookAlertProps = {
  isDrawerAlertOpen: boolean,
  onAlertClose: Function,
  nextOrderDate: string,
};

const UnableToOrderCheckbookAlert = ({
  isDrawerAlertOpen,
  onAlertClose,
  nextOrderDate,
}: UnableToOrderCheckbookAlertProps) => {
  const alertCancelRef = useRef(null);
  const formatNextOrderDate = formatDate(nextOrderDate, 'MMM D, YYYY');

  return (
    <AlertDialog
      leastDestructiveRef={alertCancelRef}
      onClose={onAlertClose}
      isOpen={isDrawerAlertOpen}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="sm"
    >
      <AlertDialogOverlay />
      <AlertDialogContent p={4}>
        <Stack spacing={2}>
          <AlertDialogCloseButton />
          <IconWarningTriangleYellow />
          <Stack spacing={0.5}>
            <AlertDialogHeader p={0}>
              <Text textStyle="headline-lg">Unable to order another checkbook</Text>
            </AlertDialogHeader>
            <AlertDialogBody p={0}>
              <Stack textStyle="sm">
                <Text>
                  You can&apos;t reorder checks for accounts for which you&apos;ve ordered a
                  checkbook in the last 15 days.
                </Text>
                <Text>You can order another checkbook on {formatNextOrderDate}.</Text>
              </Stack>
            </AlertDialogBody>
          </Stack>
        </Stack>
        <AlertDialogFooter p={0} mt={3}>
          <BaselaneButton
            size="md"
            variant="filled"
            palette="primary"
            onClick={onAlertClose}
            isFullWidth
            id="unable-to-order-checkbook-got-it-button"
          >
            Got it
          </BaselaneButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UnableToOrderCheckbookAlert;
