import React, { useContext } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import {
  ADD_FUNDS,
  ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS,
  ADD_FUNDS_BANK_TRANSFER,
  ADD_FUNDS_CHECK_DEPOSIT,
  ADD_FUNDS_DIGITAL_WALLET,
  ADD_FUNDS_TRANSFER_METHOD_SELECTOR,
  NATIVE_BANK,
  PHONE_REQUIRED,
  SEND_FUNDS,
  TRANSFER_FUNDS,
} from '@routes';
import BanksContext from '@contexts/BanksContext';
import UserContext from '@core/contexts/UserContext';

/**
 * Redirect logic for users who had completed test variant of the 2024-07_sign_up_page_experiment
 */
const SignupTestRedirectLogic = () => {
  const { hasActiveBaselaneAccounts } = useContext(BanksContext);
  const { emailVerified, user } = useContext(UserContext);
  const currLocation = useLocation();
  const { pathname } = currLocation;

  const moveMoneyRoutes = [
    ADD_FUNDS,
    ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS,
    ADD_FUNDS_BANK_TRANSFER,
    ADD_FUNDS_CHECK_DEPOSIT,
    ADD_FUNDS_DIGITAL_WALLET,
    ADD_FUNDS_TRANSFER_METHOD_SELECTOR,
    SEND_FUNDS,
    TRANSFER_FUNDS,
  ];

  const moveMoneyPathIndex = moveMoneyRoutes.findIndex((route) => {
    return pathname.includes(route);
  });

  /**
   * this covers the cases where user's business bank application is approved but the phone number submitted did not pass validation (ie VOIP)
   * FE won't know the reason for the validation failing, and their application may take a while to be approved
   * in order to capture that scenario, we check whether the user has an active bank account but no phone number
   */
  if (pathname !== PHONE_REQUIRED && !user?.phoneNumber && hasActiveBaselaneAccounts) {
    return <Navigate to={PHONE_REQUIRED} />;
  }

  /**
   * this handles the redirect logic when user ends up with a deep link to Move Money paths
   * redirect them back to Banking page to verify their email through the banner
   */
  if (moveMoneyPathIndex >= 0 && !emailVerified) {
    return <Navigate to={NATIVE_BANK} />;
  }

  return <Outlet />;
};

export default SignupTestRedirectLogic;
