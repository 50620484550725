import React, { useState, useEffect, useRef, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { cloneDeep } from 'lodash';
import { useLazyQuery, useMutation, useApolloClient, useQuery } from '@apollo/client';
import { useStatsigClient } from '@statsig/react-bindings';
import { Form, Formik } from 'formik';
import { Outlet, generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  FormControl,
  FormLabel,
  Spacer,
  Stack,
  Text,
  Textarea,
  HStack,
  ChakraProvider,
  Box,
  Divider,
  useToast,
  Skeleton,
} from '@chakra-ui/react';

import {
  AlertHeader,
  BaselaneAlert,
  BaselaneButton,
  BaselaneDrawer,
  BaselaneMessageCard,
  NoPropertyTooltip,
  T1Dropdown,
  T2Dropdown,
  T2WithTitleDropdown,
  DocumentCard,
  getQueryDownloadUrl,
} from '@shared/components';

import useBreakPoints from '@core/hooks/useBreakPoints';
import {
  GET_DOCUMENTS,
  DELETE_DOCUMENT,
  GET_TRANSACTIONS,
  GET_DOCUMENT_DOWNLOAD_URL,
} from '@core/apollo/queries';
import { IconExclamationCircle } from '@icons';
import { NATIVE_BANK, BOOKKEEPING_RULES_ID, CASH_FLOW_STATEMENT } from '@routes';
import {
  getPropertyData,
  renderPropertyDropdownParentItem,
} from '@shared/helpers/propertiesFilter.helpers';
import {
  buildPresentationProperties,
  createPropertyIdsMap,
} from '@core/components/Transactions/helpers/propertyInput.helper';
import TransactionContext from '@contexts/TransactionContext';
import CashFlowContext from '@contexts/CashFlowContext';

import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { GET_TRANSACTION_DETAILS_INFO_BY_ID } from '@core/components/Transactions/queries';
import habitatTheme from '@core/themeHabitat';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import onDrawerClose from '@core/utils/onDrawerClose';
import { getOptionsWithSubCategories } from '@core/components/CashFlowPage/helpers/cashflow.helpers';
import { findAccount } from '@core/components/Transactions/SplitTransactionsDrawer/helpers/transactionsListItem.helper';
import {
  Icon12Sparkle,
  Icon12Check,
  Icon12DoubleArrowRight,
  Icon12Recurring,
} from '@core/components/Icons/12px';

import {
  DisplayInputDefaultPropertyItem,
  DisplayInputDefaultCategoryItem,
} from '../DisplayInputVariations';
import {
  reviewStatusOptions,
  reviewStatusItemRenderer,
  selectedReviewStatusItemRenderer,
} from '../DisplayInputVariations/reviewStatusVariations';
import SplitTransactionsDrawer from '../SplitTransactionsDrawer';
import TransactionDataUpdate from '../TransactionDataUpdate';
import AutoSave from '../components/AutoSave';
import FileUploader from '../components/FileUploader';
import { getTransactionById } from '../helpers/getTransactionById.helper';
import {
  getOrderedTransactions,
  handleCategorySubmit,
  handleDateSubmit,
  handlePropertySubmit,
  updateTransactionSummaryData,
} from '../helpers/trxItem.helper';
import {
  textAreaStyles,
  transactionDetailsDrawerStyles,
} from '../styles/transactionDetailsDrawer.style';
import PreviewTransaction from './PreviewTransaction';
import TransactionActions from './TransactionActions';
import EditTransaction from './EditTransaction';
import CustomToast from '../CustomToast';

type TransactionDetailsDrawerProps = {
  from: string,
};

function TransactionDetailsDrawer({ from: fromPath }: TransactionDetailsDrawerProps): any {
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;
  const { checkGate } = useStatsigClient();
  const { bankaccount } = useParams();
  const accountId = bankaccount?.split('_')?.[1];
  const from = accountId ? `${fromPath}/baselane_${accountId}` : fromPath;

  const { transactionId } = useParams();

  const [isOnEditMode, setIsOnEditMode] = useState(false);

  const { cashFlowRefetch } = useContext(CashFlowContext);

  const {
    categoryWithSubOptions,
    updateTransactions,
    refetchTransactionsSummaryData,
    filters,
    propertyIdsMap,
    setPropertyIdsMap,
    allAccountsMap,
    categoryMap,
    propertiesData,
    categoryIdsMap,
    summaryFilter,
  } = useContext(TransactionContext);

  const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);
  const propertyOptions = getPropertyData(propertiesData);

  const { isMinXL, isMax576, isMax768 } = useBreakPoints();
  const { cache } = useApolloClient();

  const splitTrxRef = useRef(null);
  const formikRef = useRef();
  const btnRef = useRef();
  const cancelRef = useRef();

  // Grab transaction information
  const { data: transactionData, loading: transactionDataLoading } = useQuery(
    GET_TRANSACTION_DETAILS_INFO_BY_ID,
    {
      variables: { id: transactionId },
    }
  );
  const transaction = transactionData?.transactionById ?? {};

  const account = findAccount(transaction?.bankAccountId, allAccountsMap);
  const isReviewedByUser = transaction?.isReviewedByUser ?? false;

  const [isUpdateReviewedLoading, setIsUpdateReviewedLoading] = useState(false);

  /**
   * Split Transactions
   * If the transaction opened is a split transaction
   * it will use the parentId to grab the necessary information
   */
  const [
    transactionById,
    {
      data: { transactionById: parentTransaction = {} } = {},
      loading: parentTransactionDataLoading,
    },
  ] = getTransactionById({
    id: transaction?.parentId,
  });

  const initialSplitTransactions = parentTransaction?.splitTransactions || [];
  const [splitTransactions, setSplitTransactions] = useState(initialSplitTransactions);

  // Documents
  const [getdocuments] = useLazyQuery(GET_DOCUMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: { entityId: transaction?.id },
  });
  const [getDocumentDownloadUrl] = useLazyQuery(GET_DOCUMENT_DOWNLOAD_URL, {
    fetchPolicy: 'cache-and-network',
  });
  const [deleteDocument, { loading: isDeleteDocLoading }] = useMutation(DELETE_DOCUMENT);

  const [isDeleteDocAlertOpen, setIsDeleteDocAlertOpen] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [documents, setDocuments] = useState([]);

  const defaultElapsedTime = transaction?.note?.date
    ? DateTime.fromISO(transaction?.note?.date).toRelative()
    : null;
  const [elapsedTime, setElapsedTime] = useState(defaultElapsedTime);

  // Toasters
  const toast = useToast();
  const showSuccessToast = (updatedField) =>
    toast({
      description: updatedField ? `${updatedField} Updated Successfully` : 'Updated Successfully',
      status: 'success',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });
  const showErrorToast = (updatedField) =>
    toast({
      description: updatedField ? `${updatedField} Failed to Update` : 'Failed to Update',
      status: 'error',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  const getSelectedInitialProperty = () => {
    let selectedProperty = null;
    if (transaction?.propertyId && transaction?.unitId) {
      selectedProperty = {
        id: `${transaction?.propertyId}-${transaction?.unitId}`,
        showValueName: `${propertyIdsMap[`p-${transaction?.propertyId}`]?.name}, ${
          propertyIdsMap[`u-${transaction?.unitId}`]?.name
        }`,
      };
    } else if (transaction?.propertyId && !transaction?.unitId) {
      selectedProperty = {
        id: `${transaction?.propertyId}`,
        showValueName: propertyIdsMap[`p-${transaction?.propertyId}`]?.name,
      };
    }

    return selectedProperty;
  };

  const { id: propertyId = null, showValueName: propertyName = '' } =
    getSelectedInitialProperty() ?? {};
  const initialValues = {
    note: transaction?.note?.text || '',
    hidden: transaction?.hidden,
    categoryId: transaction?.tagId,
    propertyId,
    propertyName,
  };

  const handleDeleteDocAlertOpen = ({ id }) => {
    setSelectedDocId(id);
    setIsDeleteDocAlertOpen(true);
  };

  const handleDeleteDocAlertClose = () => {
    setSelectedDocId(null);
    setIsDeleteDocAlertOpen(false);
  };

  const handleClose = () => {
    if (location?.pathname.includes(CASH_FLOW_STATEMENT)) {
      navigate(-1);
    } else {
      onDrawerClose(navigate, from);
    }
    const { note: updatedNote = '' } = formikRef.current?.values ?? {};
    if (updatedNote !== initialValues.note) {
      formikRef.current?.submitForm();
    }
  };

  const handleDeleteDocInCache = ({ deleteUserDocument }) => {
    cache.updateQuery(
      {
        query: GET_TRANSACTIONS,
        variables: { input: { ...filters, filter: { ...filters.filter } } },
      },
      (data) => {
        const { transaction: transactionsInCache } = data ?? {};
        const clonedTrxs = cloneDeep(transactionsInCache);
        const updatedTrx = clonedTrxs.map((trx) => {
          const clonedTrx = cloneDeep(trx);
          if (trx.id === transaction?.id) {
            const updatedDocuments = documents?.filter((doc) => doc.id !== deleteUserDocument?.id);
            setDocuments(updatedDocuments);

            if (!updatedDocuments?.length) {
              clonedTrx.isDocumentUploaded = false;
            }
          }
          return clonedTrx;
        });

        return {
          transaction: updatedTrx,
        };
      }
    );
  };

  const onDeleteDocSuccss = ({ deleteUserDocument }) => {
    handleDeleteDocInCache({ deleteUserDocument });
    handleDeleteDocAlertClose();
  };

  const handleGetDocs = async () => {
    const documentsData = await getdocuments();
    const { fetchDocuments } = documentsData?.data ?? {};
    setDocuments(fetchDocuments);
  };

  const handleDeleteDoc = async () => {
    const deletedDocumentData = await deleteDocument({ variables: { id: selectedDocId } });
    const { data: deletedDocData } = deletedDocumentData ?? {};
    const { deleteUserDocument } = deletedDocData ?? {};
    onDeleteDocSuccss({ deleteUserDocument });
  };

  const { id, date, isDocumentUploaded } = transaction ?? {};

  useEffect(() => {
    if (transaction?.parentId) {
      transactionById();
    }
  }, [transaction?.parentId]);

  useEffect(() => {
    if (transaction?.id) {
      handleGetDocs();
    }
  }, [transaction?.id]);

  useEffect(() => {
    if (parentTransaction?.splitTransactions) {
      setSplitTransactions(parentTransaction?.splitTransactions || []);
    }
  }, [parentTransaction]);

  useEffect(() => {
    if (propertiesData.length > 0) {
      const presentationProperties = buildPresentationProperties(propertiesData);
      const idsMap = createPropertyIdsMap(presentationProperties);
      setPropertyIdsMap(idsMap);
    }
  }, [propertiesData]);

  // Destructure Imported Styles
  const { bannerStyle, fileUploadStyle, docDeleteAlert } = transactionDetailsDrawerStyles ?? {};

  const deleteDocAlertFooter = (
    <HStack {...docDeleteAlert({ isMinXL }).container}>
      <BaselaneButton
        variant="outline"
        palette="neutral"
        size="lg"
        onClick={handleDeleteDocAlertClose}
      >
        Cancel
      </BaselaneButton>
      <BaselaneButton
        variant="filled"
        palette="danger"
        size="lg"
        onClick={handleDeleteDoc}
        isLoading={isDeleteDocLoading}
      >
        Delete
      </BaselaneButton>
    </HStack>
  );

  const getSelectedProperty = (values) => {
    const { propertyId: pId, propertyName: showValueName } = values;

    let selected = null;
    if (showValueName) {
      selected = { id: pId, showValueName };
    }
    return selected;
  };

  const propertyDropdownProps = (values: Object, setFieldValue: Function) => ({
    additionalProps: { id: 'property-dropdown', name: 'property' },
    classNames: ['input-form-md', 'is-full-width'],
    data: propertyOptions,
    placeholder: 'Select property',
    title: 'Property',
    showValueByFields: ['showValueName'],
    parentItemRenderer: ({ item }) => renderPropertyDropdownParentItem(item),
    childItemRenderer: itemRenderer,
    handleSubmit: (selectedValue) => {
      const selectedPropertyName = propertyOptions.find((option) => option.id === selectedValue)
        ?.showValueName;
      setFieldValue('propertyId', selectedValue);
      setFieldValue('propertyName', selectedPropertyName ?? '');
      handlePropertySubmit(updateTransaction, transaction, selectedValue, {
        showErrToast: true,
        showSuccessfulToast: true,
        updatedField: 'Property',
      });
    },
    isMulti: false,
    hasFilterWrapper: false,
    selectedItem: getSelectedProperty(values),
    hideSearch: isMobile,
    parentId: 'drawer-body',
    CustomDisplayInput: DisplayInputDefaultPropertyItem,
    isMobile: isMax768,
  });

  const getSelectedCategory = (values) => {
    return values?.categoryId && values?.categoryId !== ''
      ? {
          id: categoryIdsMap[values.categoryId],
          name: categoryMap[values.categoryId],
          rule: transaction?.tagIdSource,
        }
      : null;
  };
  const getCategoryPlaceholder = (values) => {
    return !values?.categoryId || values?.categoryId === '' ? 'Select category' : '';
  };

  const handleCategoryDropdownSubmit = (selectedValue, setFieldValue) => {
    if (selectedValue.length === 0) {
      setFieldValue('categoryId', null);
    } else {
      const [parentId, subId] = selectedValue.split('-');
      setFieldValue('categoryId', subId ?? parentId);
    }

    handleCategorySubmit(updateTransaction, transaction, selectedValue, {
      showErrToast: true,
      showSuccessfulToast: true,
      updatedField: 'Category',
    });
  };
  const categoryDropdownProps = (values: Object, setFieldValue: Function) => {
    const [parentId = null, subId = null] = categoryIdsMap?.[values.categoryId] ?? [];

    return {
      additionalProps: { id: 'category-dropdown' },
      classNames: [parentId && subId ? 'input-form-lg' : 'input-form-md', 'is-full-width'],
      data: categoryOptions,
      title: 'Category',
      showValueByFields: ['name'],
      placeholder: getCategoryPlaceholder(values),
      parentItemRenderer: itemRenderer,
      childItemRenderer: itemRenderer,
      handleSubmit: (selectedValue) => handleCategoryDropdownSubmit(selectedValue, setFieldValue),
      selectedItem: getSelectedCategory(values),
      isMulti: false,
      hasFilterWrapper: false,
      CustomDisplayInput: DisplayInputDefaultCategoryItem,
      isMobile: isMax768,
    };
  };

  // Note: any changes here to also double check TransactionListItem
  async function updateTransaction(parameter, toastProps, onSuccess, optimisticResponse) {
    const { showErrToast, updatedField } = toastProps ?? {};
    await updateTransactions({
      variables: { input: { id, ...parameter } },
      ...(optimisticResponse ? { ...optimisticResponse } : {}),
      update: (apolloCache, { data: { updateTransactions: update } }) => {
        let updatedTransaction = update;
        if (Array.isArray(update)) {
          const [t] = update;
          updatedTransaction = t;
        }

        // Update elapsedTime for notes
        if (parameter?.note) {
          const days = DateTime.fromISO(updatedTransaction?.note?.date).toRelative();
          setElapsedTime((prevState) => {
            if (days === null || days === undefined) {
              return prevState;
            }
            return days;
          });
        }

        const { transaction: cachedTransactions } =
          cache.readQuery({
            query: GET_TRANSACTIONS,
            variables: { input: { ...filters } },
          }) ?? {};

        // remove items from end of list if it is greater than the page limit trx
        const orderedTransactions = getOrderedTransactions({
          cachedTransactions,
          filters,
          propertyIdsMap,
          updatedTransaction,
        });

        // account for category change
        updateTransactionSummaryData({ transaction, updatedTransaction, cache, summaryFilter });

        cache.writeQuery({
          query: GET_TRANSACTIONS,
          variables: { input: { ...filters } },
          data: {
            transaction: orderedTransactions,
          },
        });

        // get reviewed totals again in case of isReviewedByUser update
        if (parameter?.isReviewedByUser !== transaction?.isReviewedByUser) {
          refetchTransactionsSummaryData();
        }
      },
      onCompleted: (data) => {
        const tagId = transaction?.tagId;
        const unitId = transaction?.unitId;
        const newCategoryId = data?.updateTransactions[0]?.tagId;
        const newPropertyId = data?.updateTransactions[0]?.propertyId;
        const newUnitId = data?.updateTransactions[0]?.unitId;
        const hasAutoTaggingFeature = checkGate(FEATURE_GATES.AUTO_TAGGING_GATE);

        const categoryChanged = newCategoryId && newCategoryId !== tagId;
        const propertyChanged =
          (newPropertyId && newPropertyId !== propertyId) || (newUnitId && newUnitId !== unitId);

        const shouldShowCreateRulePromoToaster =
          hasAutoTaggingFeature && (categoryChanged || propertyChanged);

        if (shouldShowCreateRulePromoToaster) {
          const merchantValue = data?.updateTransactions[0]?.merchantName;
          const descriptionValue = data?.updateTransactions[0]?.description;

          const categoryName = categoryWithSubOptions
            .flatMap(({ items }) => items)
            .find((x) => x.id === newCategoryId)?.name;

          const toastMessage = categoryChanged
            ? `Updated to ${categoryName}`
            : 'Updated Transaction';

          // The timeout is necessary because the transactions page is somewhat slow, causing the toaster to appear delayed when opening
          setTimeout(() => {
            const toastId = toast({
              position: 'bottom-left',
              duration: 7000,
              render: () => (
                <CustomToast
                  toastId={toastId}
                  message={toastMessage}
                  categoryId={newCategoryId}
                  categoryValue={merchantValue || descriptionValue}
                  propertyId={newPropertyId?.toString()}
                  unitId={newUnitId?.toString()}
                />
              ),
            });
          }, 1000);
        } else {
          showSuccessToast?.(updatedField);
        }

        onSuccess?.();
        cashFlowRefetch?.();
      },
      onError: () => {
        if (showErrToast) showErrorToast(updatedField);
      },
    });
  }

  function handleReviewedByUser() {
    setIsUpdateReviewedLoading(true);
    updateTransaction({ isReviewedByUser: !isReviewedByUser }, true, null, null, {
      optimisticResponse: {
        updateTransactions: { ...transaction, isReviewedByUser: !isReviewedByUser },
      },
    }).then(() => setIsUpdateReviewedLoading(false));
  }

  // Submit handlers
  function handleMerchantSubmit(newMerchantName: string) {
    if (transaction?.merchantName?.trim() !== newMerchantName?.trim()) {
      updateTransaction({ merchantName: newMerchantName }, null, null, {
        optimisticResponse: {
          updateTransactions: {
            ...transaction,
            merchantName: newMerchantName,
          },
        },
      });
    }
  }

  function handleNoteSubmit(newNote: string, toastProps = null) {
    if (transaction?.note?.text?.trim() !== newNote?.trim()) {
      updateTransaction({ note: newNote }, toastProps, null, {
        optimisticResponse: { updateTransactions: { ...transaction, note: { text: newNote } } },
      });
    }
  }

  const isLoading = transactionDataLoading || parentTransactionDataLoading;

  // Auto Tagging icons and labels
  const hasAutoTaggingFeature = checkGate(FEATURE_GATES.AUTO_TAGGING_GATE);

  const getRuleIconAndLabel = (source) => {
    switch (source) {
      case 'MANUAL':
        return { label: 'Tagged manually', icon: <Icon12Check /> };
      case 'AI':
      case 'SYSTEM':
        return { label: 'Tagged by Categorization AI', icon: <Icon12Sparkle /> };
      case 'CUSTOM_RULE':
        return { label: 'Tagged by custom rule', icon: <Icon12DoubleArrowRight /> };
      case 'TRANSFER':
        return { label: 'Tagged by recurring transfer setup', icon: <Icon12Recurring /> };
      default:
        return { label: '', icon: null };
    }
  };

  const { label: categoryRuleLabel, icon: categoryRuleIcon } = getRuleIconAndLabel(
    transaction?.tagIdSource
  );
  const { label: propertyRuleLabel, icon: propertyRuleIcon } = getRuleIconAndLabel(
    transaction?.propertyTagIdSource
  );

  return (
    <ChakraProvider theme={habitatTheme}>
      <BaselaneDrawer
        isOpen
        finalFocusRef={btnRef}
        title="Transaction details"
        onClose={handleClose}
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        newDesignDrawer
      >
        {isOnEditMode ? (
          <EditTransaction
            transaction={transaction}
            onCancel={() => {
              setIsOnEditMode(false);
            }}
            onSuccess={() => {
              setIsOnEditMode(false);
            }}
          />
        ) : (
          <>
            <PreviewTransaction transaction={transaction} account={account} isLoading={isLoading} />
            <TransactionActions
              transaction={transaction}
              isOnEditMode={isOnEditMode}
              onEdit={() => {
                setIsOnEditMode(true);
              }}
              onSplit={() => {
                splitTrxRef?.current?.open();
              }}
              from={from}
              isLoading={isLoading}
            />
          </>
        )}

        <Box paddingY={1} mt={2}>
          <Divider borderColor="brand.darkBlue.200" />
        </Box>

        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={() => {
            const { note: newNote } = formikRef.current?.values || '';
            handleNoteSubmit(newNote);
            formikRef.current?.setSubmitting(false);
          }}
          enableReinitialize
        >
          {({ values, handleChange, handleBlur, setFieldValue }) => (
            <Form noValidate>
              {/* Split Trx Message Card */}
              {transaction?.parentId && (
                <BaselaneMessageCard
                  iconName="info"
                  iconColor="blue"
                  borderColor="blue"
                  title="This transaction is part of a split transaction"
                  isMinXL={isMinXL}
                  styles={{ ...bannerStyle({ isMinXL }) }}
                />
              )}

              {/* Category */}
              <FormControl>
                <FormLabel htmlFor="category">Category</FormLabel>
                <Skeleton isLoaded={!isLoading}>
                  <T2WithTitleDropdown {...categoryDropdownProps(values, setFieldValue)} />
                </Skeleton>
                {hasAutoTaggingFeature && (
                  <HStack spacing={0.5} mt={0.5}>
                    <Box color="brand.neutral.600">{categoryRuleIcon}</Box>
                    <Text fontSize="xs" color="brand.neutral.600">
                      {categoryRuleLabel === 'Tagged by custom rule' ? (
                        <>
                          Tagged by{' '}
                          <Text
                            as="span"
                            textDecoration="underline"
                            cursor="pointer"
                            onClick={() => {
                              const bookkeepingRulesIdPath = generatePath(
                                `${BOOKKEEPING_RULES_ID}`,
                                {
                                  ruleId: transaction.tagRuleId,
                                }
                              );
                              navigate(bookkeepingRulesIdPath);
                            }}
                          >
                            custom rule
                          </Text>
                        </>
                      ) : (
                        categoryRuleLabel
                      )}
                    </Text>
                  </HStack>
                )}
              </FormControl>

              {/* Property */}
              <FormControl>
                <FormLabel htmlFor="property">Property</FormLabel>
                {propertyOptions?.length === 0 && !isLoading ? (
                  <NoPropertyTooltip>
                    <T2Dropdown {...propertyDropdownProps(values, setFieldValue)} isDisabled />
                  </NoPropertyTooltip>
                ) : (
                  <Skeleton isLoaded={!isLoading}>
                    <T2Dropdown {...propertyDropdownProps(values, setFieldValue)} />
                  </Skeleton>
                )}
                {hasAutoTaggingFeature && (
                  <HStack spacing={0.5} mt={0.5}>
                    <Box color="brand.neutral.600">{propertyRuleIcon}</Box>
                    <Text fontSize="xs" color="brand.neutral.600">
                      {propertyRuleLabel === 'Tagged by custom rule' ? (
                        <>
                          Tagged by{' '}
                          <Text
                            as="span"
                            textDecoration="underline"
                            cursor="pointer"
                            onClick={() => {
                              const bookkeepingRulesIdPath = generatePath(
                                `${BOOKKEEPING_RULES_ID}`,
                                {
                                  ruleId: transaction.propertyRuleId,
                                }
                              );
                              navigate(bookkeepingRulesIdPath);
                            }}
                          >
                            custom rule
                          </Text>
                        </>
                      ) : (
                        propertyRuleLabel
                      )}
                    </Text>
                  </HStack>
                )}
              </FormControl>

              {/* isReviewedByUser */}
              {checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) && (
                <FormControl>
                  <FormLabel htmlFor="isReviewedByUser">Reviewed</FormLabel>
                  <Skeleton isLoaded={!isLoading}>
                    <T1Dropdown
                      id="reviewed-by-user-dropdown"
                      type="tier1"
                      classNames={['input-form-md', 'is-full-width']}
                      hideSearch
                      showValueByFields={['name']}
                      handleSubmit={(value) => {
                        if (isUpdateReviewedLoading) return;
                        setIsUpdateReviewedLoading(true);
                        handleReviewedByUser(value);
                      }}
                      data={reviewStatusOptions}
                      itemRenderer={reviewStatusItemRenderer}
                      CustomDisplayInput={() =>
                        selectedReviewStatusItemRenderer({
                          item: reviewStatusOptions[isReviewedByUser ? 1 : 0],
                        })
                      }
                      selectedItem={reviewStatusOptions[isReviewedByUser ? 1 : 0]}
                      isMobile={isMax768}
                    />
                  </Skeleton>
                </FormControl>
              )}

              {/* Notes */}
              <FormControl mt={2}>
                <HStack>
                  <FormLabel htmlFor="note">Notes</FormLabel>
                  <Spacer />
                  <Skeleton isLoaded={!isLoading}>
                    <AutoSave formikRef={formikRef} debounceMs={2000} elapsedTime={elapsedTime} />
                  </Skeleton>
                </HStack>

                <Skeleton isLoaded={!isLoading}>
                  <Textarea
                    id="note"
                    name="note"
                    placeholder="Add Note..."
                    value={values.note}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    {...textAreaStyles}
                  />
                </Skeleton>
              </FormControl>

              <Box paddingY={1} mt={2}>
                <Divider borderColor="brand.darkBlue.200" />
              </Box>

              {/* File Upload */}
              <Stack mt={2}>
                <FileUploader
                  entityId={id}
                  entityDate={date}
                  documents={documents}
                  setDocuments={setDocuments}
                  filters={filters}
                />

                {/* Document List */}
                {isDocumentUploaded && (
                  <Stack {...fileUploadStyle({ isMinXL }).documents.container}>
                    <Text {...fileUploadStyle({ isMinXL }).documents.title}>Saved Attachments</Text>
                    {documents?.map((doc) => (
                      <DocumentCard
                        key={doc.id}
                        doc={doc}
                        handleDeleteDocAlertOpen={handleDeleteDocAlertOpen}
                        getDownloadUrl={async () =>
                          getQueryDownloadUrl(doc.id, getDocumentDownloadUrl)
                        }
                      />
                    ))}
                  </Stack>
                )}
              </Stack>

              {/* Updated Trx Data: Show if Merchant Name or Date's updated */}
              {!transaction?.pending && (
                <TransactionDataUpdate
                  transaction={transaction}
                  handleMerchantSubmit={handleMerchantSubmit}
                  handleDateSubmit={(newDate) =>
                    handleDateSubmit(updateTransaction, transaction, newDate)
                  }
                  isLoading={isLoading}
                />
              )}
            </Form>
          )}
        </Formik>

        <SplitTransactionsDrawer
          splitTrxRef={splitTrxRef}
          transaction={transaction}
          splitTransactions={splitTransactions}
          setSplitTransactions={setSplitTransactions}
          isLoading={parentTransactionDataLoading}
          propertyOptions={propertyOptions}
          from={from}
        />

        <BaselaneAlert
          size={isMinXL ? 'xs' : '2xl'}
          showCloseButton
          isCentered
          isOpen={isDeleteDocAlertOpen}
          onClose={() => handleDeleteDocAlertClose()}
          leastDestructiveRef={cancelRef}
          header={
            <AlertHeader
              title="Are you sure you want to delete the attachment?"
              icon={<IconExclamationCircle w={20} h={20} color="#EA6868" />}
              iconBgStyles={{ bg: 'red.100' }}
              isHorizontalCenter
            />
          }
          footer={deleteDocAlertFooter}
          isMinXL={isMinXL}
        />
      </BaselaneDrawer>
      {!pathname.includes(NATIVE_BANK) && <Outlet />}
    </ChakraProvider>
  );
}

export default TransactionDetailsDrawer;
