import { useMutation, useQuery } from '@apollo/client';
import LogRocket from 'logrocket';
import {
  CREATE_AUTOTAG_RULE,
  UPDATE_AUTOTAG_RULE,
  DELETE_AUTOTAG_RULE,
} from '@core/apollo/queries/autoTag';
import { conditionKeys } from '@pages/AutoTaggingRulesPage/helpers/autotaggingRules.helpers';
import { GET_AUTOTAG_RULES } from '../../../../AutoTaggingRulesPage/queries/autotag-queries';

type AutoTaggingProps = {
  accountId?: Number,
  cardId?: Number,
};

const useSaveAutoTagPropertyRule = ({ accountId, cardId }: AutoTaggingProps) => {
  const invalidPropCombination = (!accountId && !cardId) || (!!accountId && !!cardId);

  // retrieve all autoTag rules that have a propertyId or unitId
  const { data: autoTagRulesData, loading: isLoadingAutoTagRules } = useQuery(GET_AUTOTAG_RULES, {
    skip: invalidPropCombination,
    variables: {
      filter: {
        ...(accountId && { bankAccountId: Number(accountId) }),
        ...(cardId && { virtualCardId: Number(cardId) }),
        isPropertyOrUnitRule: !!accountId,
      },
    },
    fetchPolicy: 'no-cache',
  });

  // creating or updating a rule needs to also refetch transaction totals
  const [createAutoTagRule, { loading: isCreating }] = useMutation(CREATE_AUTOTAG_RULE, {
    skip: invalidPropCombination,
    refetchQueries: [GET_AUTOTAG_RULES],
  });

  const [updateAutoTagRule, { loading: isUpdating }] = useMutation(UPDATE_AUTOTAG_RULE, {
    skip: invalidPropCombination,
    refetchQueries: [GET_AUTOTAG_RULES],
  });

  const [deleteAutoTagRule, { loading: isDeleting }] = useMutation(DELETE_AUTOTAG_RULE, {
    skip: invalidPropCombination,
    refetchQueries: [GET_AUTOTAG_RULES],
  });

  if (invalidPropCombination) {
    LogRocket.log(`useSaveAutoTagPropertyRule received invalid props`, { accountId, cardId });
  }

  const { autotagRules } = autoTagRulesData || {};
  // filter the rules to only include the ones that are tied to the account
  const propertyRulesForAccount = autotagRules?.filter((rule) =>
    rule.conditions.find(
      (condition) =>
        condition?.type === conditionKeys.NUMERIC &&
        condition?.field === conditionKeys.BANK_ACCOUNT_ID &&
        Number(condition?.value) === Number(accountId)
    )
  );

  const propertyRulesForCard = autotagRules?.filter((rule) =>
    rule?.conditions?.find(
      (condition) =>
        condition?.type === conditionKeys.NUMERIC &&
        condition?.field === conditionKeys.VIRTUAL_CARD_ID &&
        Number(condition.value) === Number(cardId)
    )
  );

  const applicableRules = accountId ? propertyRulesForAccount : propertyRulesForCard;

  const isComplexOrMultipleRules =
    // multiple rules found
    applicableRules?.length > 1 ||
    // multiple conditions found
    applicableRules?.some((rule) => rule.conditions.length > 1) ||
    // actions other than propertyId or unitId found (only for accounts)
    (applicableRules?.some((rule) => !!rule.action.tagId) && !cardId);

  const saveAutoTagPropertyRule = ({ propertySelection, categorySelection }) => {
    const [propertyId, propertyUnitId] =
      typeof propertySelection === 'string'
        ? propertySelection.split('-')
        : propertySelection || [];

    const [tagId, subTagId] =
      typeof categorySelection === 'string'
        ? categorySelection.split('-')
        : categorySelection || [];

    if (!isComplexOrMultipleRules) {
      // find existing autoTag rule for this account
      if (applicableRules?.length === 1) {
        // update the rule with the new propertyId and propertyUnitId
        const existingRule = applicableRules[0];

        // if no propertyId and no propertyUnitId, remove the rule
        // Assumption: this rule does not have any other conditionsm because of !isComplexOrMultipleRules
        if (
          (propertySelection && !propertyId && !propertyUnitId && !existingRule?.action?.tagId) ||
          (categorySelection &&
            !tagId &&
            !subTagId &&
            !existingRule?.action?.propertyId &&
            !existingRule?.action?.unitId) ||
          // if both propertySelection and categorySelection are empty arrays, delete the rule
          (propertySelection &&
            categorySelection &&
            !propertyId &&
            !propertyUnitId &&
            !tagId &&
            !subTagId)
        ) {
          return deleteAutoTagRule({
            variables: { id: Number(existingRule.id) },
          }).catch((error) => {
            LogRocket.log('deleteAutoTagRule: Error deleting autoTag rule', error);
          });
        }
        // otherwise update the existing rule with the new propertyId and propertyUnitId
        return updateAutoTagRule({
          variables: {
            input: {
              ...existingRule,
              rank: undefined,
              id: Number(existingRule.id),
              applyToTaggedTransactions: false,
              action: {
                ...existingRule.action,
                ...(propertySelection && { propertyId: propertyId ? Number(propertyId) : '' }),
                ...(propertySelection && { unitId: propertyUnitId ? Number(propertyUnitId) : '' }),
                ...(categorySelection && {
                  tagId: tagId ?? subTagId ? Number(tagId || subTagId) : '',
                }),
              },
            },
          },
        }).catch((error) => {
          LogRocket.log('saveAutoTagPropertyRule: Error updating autoTag rule', error);
        });
      }
      // if no matching rule is found, create a new one instead
      return createAutoTagRule({
        variables: {
          input: {
            action: {
              propertyId: propertyId ? Number(propertyId) : '',
              tagId: tagId ?? subTagId ? Number(tagId || subTagId) : '',
              unitId: propertyUnitId ? Number(propertyUnitId) : '',
            },
            applyToTaggedTransactions: false,
            conditions: accountId
              ? [
                  {
                    type: conditionKeys.NUMERIC,
                    field: conditionKeys.BANK_ACCOUNT_ID,
                    operator: conditionKeys.EQUALS,
                    value: Number(accountId),
                  },
                ]
              : [
                  {
                    type: conditionKeys.NUMERIC,
                    field: conditionKeys.VIRTUAL_CARD_ID,
                    operator: conditionKeys.EQUALS,
                    value: Number(cardId),
                  },
                ],
          },
        },
      }).catch((error) => {
        LogRocket.log('saveAutoTagPropertyRule: Error creating autoTag rule', error);
      });
    }
    return null;
  };

  return {
    loading: isCreating || isUpdating || isDeleting || isLoadingAutoTagRules,
    saveAutoTagPropertyRule,
    applicableRules,
    isComplexOrMultipleRules,
  };
};

export default useSaveAutoTagPropertyRule;
