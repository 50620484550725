import React, { useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  Text,
  Stack,
  Box,
} from '@chakra-ui/react';
import { USER_WORKSPACE_INVITECOLLABORATOR } from '@routes';
import UserContext from '@contexts/UserContext';
import { createWorkspaceSegmentData } from '@core/utils/sendSegmentEvent';
import { useCurrentWorkspace } from '@shared/hooks';
import { BaselaneButton } from '@shared/components';
import { Icon16Plus } from '@icons/16px';
import IconProfileCompleted from '@icons/manual/IconProfileCompleted';

const InviteCollaboratorAlert = ({ onClose, isOpen }: { onClose: Function, isOpen: Boolean }) => {
  const { user } = useContext(UserContext);
  const { activeWorkspaceId, workspaceName, workspaceOwner } = useCurrentWorkspace();

  const alertCancelRef = useRef(null);
  const navigate = useNavigate();

  const handleInviteCollaborator = () => {
    onClose();
    navigate(USER_WORKSPACE_INVITECOLLABORATOR);
  };

  return (
    <AlertDialog
      leastDestructiveRef={alertCancelRef}
      onClose={onClose}
      isOpen
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="sm"
    >
      <AlertDialogOverlay />
      <AlertDialogContent p={4} minHeight="168px" maxHeight="calc(100% - 100px)" overflow="auto">
        <Stack spacing={2} mb="3">
          <Stack spacing={0.5}>
            <Box mb={2}>
              <IconProfileCompleted />
            </Box>
            <AlertDialogHeader p={0}>
              <Text textStyle="headline-md">You&apos;re all set to invite collaborators</Text>
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody p={0}>
              <Text textStyle="sm">
                Congrats! You have completed all the steps and can now invite collaborators to your
                workspace.
              </Text>
            </AlertDialogBody>
          </Stack>
          ;
        </Stack>
        <Stack direction="row" w="100%" spacing={1.5}>
          <BaselaneButton
            size="md"
            variant="filled"
            palette="primary"
            onClick={handleInviteCollaborator}
            leftIcon={<Icon16Plus />}
            isFullWidth
            segmentEventName="workspace_fe_more_info_completed"
            segmentEventData={{
              ...createWorkspaceSegmentData(workspaceOwner, activeWorkspaceId, workspaceName, user),
              email_verified: user?.emailVerified,
              phone_number_verified: user?.phoneNumberVerified,
              first_name: user?.firstName,
              last_name: user?.lastName,
            }}
          >
            Invite Collaborator
          </BaselaneButton>
        </Stack>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default InviteCollaboratorAlert;
