import moment from 'moment';
import hasOnlyWhitespace from '@core/utils/hasOnlyWhitespace';
import stripCurrency from '@core/utils/stripCurrency';
import { formatDate } from '@core/utils/formatDate';

export const initialFormValues = {
  id: null,
  propertyId: null,
  propertyUnitId: null,
  tenantProfileId: null,
  leaseType: null,
  startDate: '',
  endDate: '',
  dueDays: 0,
  completedTags: [],
  archived: null,
  monthlyRentAmount: null,
  isMonthToMonth: false,
  rentCollectionStartDate: '',
  inputType: 'STANDARD',
  hostedPageId: null,
  tenantProfileMetadata: {
    phoneNumber: '',
  },
};

export const formatAddress = (addressObj) => {
  const { address, city, unit, state, zipCode } = addressObj ?? {};
  const propertyUnit = unit ? `${unit},` : '';
  const propertyAddress = `${address}, ${propertyUnit} ${city}, ${state} ${zipCode}`;

  return propertyAddress;
};

export const getOnDateOptions = (number, startDate, endDate, fullArray, isMonthToMonth) => {
  const options = [];
  const currentMomentDate = moment(new Date().toDateString());
  const year = currentMomentDate.format('YYYY');
  // use this year's first possible date as calcStartDate (for it has 31 days)
  const calcStartDate = moment(`${year}-01-${Number(number) < 10 ? '0' : ''}${number}`);
  for (let i = 0; i < 99; i += 1) {
    const dateValue = calcStartDate.clone().add(i, 'month');
    if (dateValue >= currentMomentDate && dateValue >= startDate) {
      const label = dateValue.format('MMM D, YYYY');
      if (isMonthToMonth || dateValue <= endDate) {
        options.push({ dateValue: formatDate(moment(dateValue), 'YYYY-MM-DD'), label });
      } else if (
        Number(number) === 31 &&
        Number(moment(endDate).format('MM')) === Number(dateValue.format('MM')) &&
        (Number(moment(endDate).format('DD')) === 30 || Number(moment(endDate).format('DD')) === 29)
      ) {
        options.push({
          dateValue: formatDate(moment(endDate), 'YYYY-MM-DD'),
          label: moment(endDate).format('MMM D, YYYY'),
        });
      }
      if (
        (options.length === 12 && !fullArray) ||
        (endDate && !isMonthToMonth && dateValue >= endDate)
      ) {
        break;
      }
    }
  }

  return options;
};

export const getDueDateOptions = (startDate, endDate, isMonthToMonth) => {
  let options = [];

  // Parse the start and end dates as moment objects
  const start = moment(startDate, 'YYYY-MM-DD', true); // Strict parsing to ensure valid date
  const end = endDate ? moment(endDate, 'YYYY-MM-DD', true) : null;

  // Get the month and year of the start date
  const startMonth = start.month();
  const startYear = start.year();
  const startDay = start.date();

  // Set end day based on endDate or default it to 31st for month-to-month cases
  // eslint-disable-next-line no-nested-ternary
  const endDay = isMonthToMonth && !end ? 31 : end ? end.date() : startDay;

  // If no endDate is provided, treat it as the same month as startDate
  const endMonth = end ? end.month() : startMonth;
  const endYear = end ? end.year() : startYear;

  // If start and end are in the same month, return only those days
  if (startMonth === endMonth && startYear === endYear) {
    options = [];
    // eslint-disable-next-line no-plusplus
    for (let day = startDay; day <= endDay; day++) {
      options.push({ id: day, label: `${getOrdinalSuffix(day)}` });
    }
  } else {
    options = [];
    // If start and end span across different months (same year or different year)
    // eslint-disable-next-line no-plusplus
    for (let day = 1; day <= 31; day++) {
      options.push({ id: day, label: `${getOrdinalSuffix(day)}` });
    }
  }

  return options;
};

// Helper function to get ordinal suffix (e.g., 1st, 2nd, 3rd)
const getOrdinalSuffix = (day) => {
  if (day >= 11 && day <= 13) return `${day}th day of the month`;
  switch (day % 10) {
    case 1:
      return `${day}st day of the month`;
    case 2:
      return `${day}nd day of the month`;
    case 3:
      return `${day}rd day of the month`;
    default:
      return `${day}th day of the month`;
  }
};

export const handleTenantStepValidation = (values, emails, landlordEmail) => {
  const filterPhoneNumber = values?.tenantProfileMetadata?.phoneNumber?.replace(/[^0-9]/g, '');

  const errors = {
    tenantProfileMetadata: {}, // Initialize the nested object
  };
  if (!/^[A-Z0-9'-\s]+$/i.test(values.tenantProfileMetadata.lastName)) {
    errors.tenantProfileMetadata.lastName = "Only alphanumeric characters, - and ' allowed";
  }
  if (
    !values.tenantProfileMetadata.lastName ||
    values.tenantProfileMetadata.lastName === '' ||
    hasOnlyWhitespace(values.tenantProfileMetadata.lastName)
  ) {
    errors.tenantProfileMetadata.lastName = 'Please enter last name';
  }
  if (!/^[A-Z0-9'-\s]+$/i.test(values.tenantProfileMetadata.firstName)) {
    errors.tenantProfileMetadata.firstName = "Only alphanumeric characters, - and ' allowed";
  }
  if (
    !values.tenantProfileMetadata.firstName ||
    values.tenantProfileMetadata.firstName === '' ||
    hasOnlyWhitespace(values.tenantProfileMetadata.firstName)
  ) {
    errors.tenantProfileMetadata.firstName = 'Please enter first name';
  }
  if (
    values?.tenantProfileMetadata?.phoneNumber &&
    values?.tenantProfileMetadata?.phoneNumber !== '' &&
    filterPhoneNumber.length < 10
  ) {
    errors.tenantProfileMetadata.phoneNumber = 'Invalid phone number';
  }

  if (!values?.tenantProfileMetadata?.email) {
    errors.tenantProfileMetadata.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.tenantProfileMetadata.email)) {
    errors.tenantProfileMetadata.email = 'Invalid email address';
  } else if (values.tenantProfileMetadata.email === landlordEmail) {
    errors.tenantProfileMetadata.email = 'You cannot invite yourself as a Tenant';
  } else if (emails.includes(values.tenantProfileMetadata.email)) {
    errors.tenantProfileMetadata.email = 'Email matches existing tenant.';
  }

  return Object.keys(errors.tenantProfileMetadata).length === 0 ? {} : errors;
};

// const getIsValidDueDate = (number, startDate, endDate, isMonthToMonth) => {
//   let currentDate = moment(startDate);
//   const stopDate = isMonthToMonth ? moment(currentDate).add(2, 'years') : moment(endDate);
//   let validDate = false;
//   while (currentDate <= stopDate) {
//     // gets complex as "Last day" is set as number 31 and 29, 30 and 31 are not in dropdown
//     if (
//       Number(number) === Number(moment(currentDate).format('DD')) ||
//       (Number(number) === 31 && [29, 30, 31].indexOf(Number(moment(currentDate).format('DD'))) > -1)
//     ) {
//       validDate = true;
//     }
//     currentDate = moment(currentDate).add(1, 'days');
//   }
//   return validDate;
// };

export const handleLeaseTermValidation = (values) => {
  const errors = {};

  if (!values.monthlyRentAmount || values.monthlyRentAmount === '') {
    errors.monthlyRentAmount = 'Please enter monthly rent amount';
  }
  if (stripCurrency(values.monthlyRentAmount) <= 2) {
    errors.monthlyRentAmount = 'Amount must be greater than $2.00';
  }
  if (!values.dueDays || values.dueDays === '' || values.dueDays === '0') {
    errors.dueDays = 'Please select rent due date';
  }
  if (!values.startDate || values.startDate === '') {
    errors.startDate = 'Please select lease start date';
  }
  // const validDueDate = getIsValidDueDate(
  //   values.dueDays,
  //   values.startDate,
  //   values.endDate,
  //   values.isMonthToMonth
  // );

  // if (!values.rentCollectionStartDate) {
  //   errors.dueDays = 'No Rent Collection Start date within selected period and due day.';
  // } else {
  //   delete errors.dueDays;
  // }
  if (!values.isMonthToMonth && (!values.endDate || values.endDate === '')) {
    errors.endDate = 'Please select lease end date';
  }

  if ((!values.isMonthToMonth && values.endDate) || values.isMonthToMonth) {
    delete errors.endDate;
  }

  return errors;
};
