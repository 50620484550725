import React from 'react';
import { isMobile } from 'react-device-detect';
import { Stack, Spacer, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { useApolloClient, useMutation } from '@apollo/client';
import { useStatsigClient } from '@statsig/react-bindings';
import { AlertHeader, BaselaneAlert, BaselaneButton } from '@shared/components';
import { IconExclamationCircle } from '@icons';
import { GET_CARD_SUMMARY, TERMINATE_CARD } from '@core/components/NativeBankingPage/queries';
import { FRAGMENT_CARD_STATUS } from '@core/components/NativeBankingPage/queries/fragments';

import { useUnitOtp } from '@core/contexts/UnitOtpContext';
import useSaveAutoTagPropertyRule from '@pages/BankEntity/AccountDetailsDrawer/AutoTagging/hooks/useSaveAutoTagPropertyRule';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { CARD_STATUS } from '../helpers/card.helper';
import { alertPopupIconBgStyles, alertPopupIconContainerStyles } from '../styles/lockCard.styles';

type TerminateCardProps = {
  bankId: string,
  card: Object,
  phoneNumber: string,
  cardFilters: Function,
  handleDrawerClose: Function,
};

const TerminateCard = ({
  bankId,
  card,
  phoneNumber,
  cardFilters,
  handleDrawerClose,
}: TerminateCardProps) => {
  const { checkGate } = useStatsigClient();
  const { cache } = useApolloClient();
  const { cardStatus, id } = card ?? {};
  const { saveAutoTagPropertyRule } = useSaveAutoTagPropertyRule({ cardId: id });

  const [terminateCard, { loading }] = useMutation(TERMINATE_CARD, {
    refetchQueries: [
      {
        query: GET_CARD_SUMMARY,
        variables: {
          page: 1,
          pageSize: 99,
          filter: {
            ...(cardFilters ? cardFilters() : {}),
            includeHiddenCards: false,
          },
        },
        options: {
          awaitRefetchQueries: true,
        },
      },
    ],
  });

  const cardId = Number(id);
  const { isTerminateButtonDisabled } = CARD_STATUS[cardStatus] ?? {};

  // Lock Card pop status
  const {
    isOpen: isTerminateCardPopupOpen,
    onOpen: onTerminateCardPopupOpen,
    onClose: onTerminateCardPopupClose,
  } = useDisclosure();

  // Unit OTP
  const { verifyUnitOtp, ignore } = useUnitOtp();

  // Toasts
  const toast = useToast();
  const showSuccessToast = () =>
    toast({
      position: isMobile ? 'bottom' : 'bottom-left',
      description: 'Card Terminated Successfully',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showErrorToast = () =>
    toast({
      position: isMobile ? 'bottom' : 'bottom-left',
      description: 'Something went wrong',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleLockCardClick = () => {
    terminateCard({ variables: { cardId } })
      .then(({ data, errors }) => {
        if (data) {
          if (checkGate(FEATURE_GATES.AUTO_TAGGING_GATE)) {
            // trigger rule deletion
            saveAutoTagPropertyRule({
              propertySelection: [],
              categorySelection: [],
            });
          }
          onTerminateCardPopupClose();
          showSuccessToast();
          cache.writeFragment({
            id: cache.identify(card),
            fragment: FRAGMENT_CARD_STATUS,
            data: {
              __typename: 'Card',
              cardStatus: 'ClosedByCustomer',
            },
          });
          handleDrawerClose();
        } else if (errors?.length > 0) {
          showErrorToast();
          onTerminateCardPopupClose();
        }
      })
      .catch((error) => console.error(error));
  };

  const title = <>Are you sure you want to terminate this card?</>;

  const terminateCardPopupContent = (
    <Text>
      Terminating this card is non reversible and all future transactions will be declined. You will
      be able to access your past transactions.
    </Text>
  );

  const activatePopupFooter = (
    <Stack direction="row" justify="space-between" w="100%">
      <Spacer />
      <Stack direction="row" align="center" spacing={2}>
        <BaselaneButton
          variant="outline"
          palette="neutral"
          size="md"
          onClick={onTerminateCardPopupClose}
        >
          Cancel
        </BaselaneButton>
        <BaselaneButton
          id="terminate-card-popup-button"
          variant="filled"
          palette="danger"
          size="md"
          onClick={handleLockCardClick}
          isLoading={loading}
        >
          Terminate Card
        </BaselaneButton>
      </Stack>
    </Stack>
  );

  return (
    <>
      <BaselaneButton
        id="terminate-card-button"
        variant="outline"
        palette="danger"
        size={isMobile ? 'md' : 'sm'}
        isDisabled={isTerminateButtonDisabled}
        isFullWidth={isMobile}
        onClick={(e) => {
          e.stopPropagation();
          verifyUnitOtp(bankId).then(onTerminateCardPopupOpen).catch(ignore);
        }}
      >
        Terminate Card
      </BaselaneButton>

      <BaselaneAlert
        showCloseButton
        header={
          <AlertHeader
            icon={<IconExclamationCircle />}
            title={title}
            iconBgStyles={alertPopupIconBgStyles}
            iconContainerStyles={alertPopupIconContainerStyles}
          />
        }
        isOpen={isTerminateCardPopupOpen}
        onClose={onTerminateCardPopupClose}
        isCentered
        body={terminateCardPopupContent}
        footer={activatePopupFooter}
        size="2xl"
      />
    </>
  );
};

export default TerminateCard;
