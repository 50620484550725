import React from 'react';
import { HStack, Text } from '@chakra-ui/react';

type TenantScreeningApplicantDefaultProps = {
  applicantName: String,
  type: String,
};

const TenantScreeningApplicantOption = ({
  applicantName,
  type,
}: TenantScreeningApplicantDefaultProps) => (
  <HStack w="full">
    <Text whiteSpace="nowrap" textStyle="sm">
      {applicantName}
    </Text>{' '}
    <Text ml="auto" textStyle="sm" color="brand.neutral.500">
      {type}
    </Text>
  </HStack>
);

export default TenantScreeningApplicantOption;
