// @flow
import React, { useEffect, useState } from 'react';
import { Box, Popover, PopoverArrow, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { toolpopStyles } from './styles/toolpop.styles';

type BaselaneToolpopProps = {
  children: any,
  label: string,
  noArrow?: boolean,
  placement: string,
  styles?: Object,
  id: string,
  isDisabled?: boolean,
  trigger: Object,
};

function BaselaneToolpop({
  children,
  label,
  noArrow,
  placement,
  styles,
  id,
  isDisabled,
  trigger,
  ...rest
}: BaselaneToolpopProps): any {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isOverTooltip, setIsOverTooltip] = useState(false);
  const [isTriggerCheck, setIsTriggerCheck] = useState(false);

  const handleHoverOverTooltip = (isHovering) => {
    if (isHovering) {
      setIsTooltipOpen(true);
    } else {
      setIsTooltipOpen(true);
      setTimeout(() => {
        setIsTriggerCheck(true);
      }, 1000);
    }
  };

  const handleHoverOverOpenTooltip = (isHovering) => {
    setIsOverTooltip(isHovering);
  };

  useEffect(() => {
    if (isTriggerCheck && isOverTooltip) {
      setIsTriggerCheck(false);
      setIsOverTooltip(true);
      setTimeout(() => {
        setIsTriggerCheck(true);
      }, 2000);
    } else if (isTriggerCheck) {
      setIsTriggerCheck(false);
      setIsTooltipOpen(false);
    }
  }, [isTriggerCheck]);

  const toolpopStyle = { ...toolpopStyles, ...styles };
  const tooltipPlacement = placement || 'bottom-start';

  return (
    <Popover
      label={label}
      aria-label={label}
      hasArrow={!noArrow}
      placement={tooltipPlacement}
      isDisabled={isDisabled}
      id={id}
      {...toolpopStyle}
      isOpen={isOverTooltip || isTooltipOpen}
    >
      <PopoverTrigger>
        <Box
          onMouseLeave={() => (isDisabled ? {} : handleHoverOverTooltip(false))}
          onMouseEnter={() => (isDisabled ? {} : handleHoverOverTooltip(true))}
          {...rest}
        >
          {trigger}
        </Box>
      </PopoverTrigger>
      <PopoverContent
        bg="brand.neutral.700"
        color="white"
        p="8px 16px"
        fontSize="sm"
        onMouseLeave={() => (isDisabled ? {} : handleHoverOverOpenTooltip(false))}
        onMouseEnter={() => (isDisabled ? {} : handleHoverOverOpenTooltip(true))}
        maxWidth="fit-content"
      >
        <PopoverArrow bg="brand.neutral.700" />
        <Box onClick={() => setIsTooltipOpen(false)}>{children}</Box>
      </PopoverContent>
    </Popover>
  );
}

BaselaneToolpop.defaultProps = {
  noArrow: false,
  styles: ({}: { ... }),
  isDisabled: false,
};

export default BaselaneToolpop;
