// @flow
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';
import UserContext from '@contexts/UserContext';
import {
  Icon16Person,
  Icon16Logout,
  Icon16Newwindow,
  Icon16Chatbot,
  Icon16Lightbulb,
} from '@icons/16px';

import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import useLogout from '@hooks/useLogout';
import BaselaneMenu from '../../../BaselaneMenu';

const Profiles = () => {
  const { checkGate } = useStatsigClient();

  const { user, userPhotoUrl } = useContext(UserContext);
  const { firstName, lastName, email } = user ?? {};

  const menuLabel = !firstName && !lastName ? email : `${firstName || ''} ${lastName || ''}`;

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  const navigate = useNavigate();
  const { logout } = useLogout();
  const handleProfilesClick = () => {
    navigate('/profile');
  };
  const handleLogoutClick = () => {
    zendeskAPI('messenger', 'logoutUser');
    logout();
  };

  const menuItems = [
    {
      id: 'profile_setting',
      name: 'Profile & settings',
      onClick: handleProfilesClick,
      icon: <Icon16Person />,
    },
    {
      id: 'help_center',
      name: 'Help center',
      as: 'a',
      href: 'https://support.baselane.com/hc/en-us',
      target: '_blank',
      icon: <Icon16Newwindow />,
    },
    {
      id: 'support',
      name: 'Virtual assistant',
      onClick: () => {
        if (checkGate(FEATURE_GATES.HIDE_ZENDESK_BUBBLE)) {
          window.adaEmbed.toggle();
        } else {
          zendeskAPI('messenger', 'open');
        }
      },
      icon: <Icon16Chatbot />,
    },
    {
      id: 'feature_board',
      name: 'Request a feature',
      as: 'a',
      href: 'https://features.baselane.com/',
      target: '_blank',
      icon: <Icon16Lightbulb />,
      hasNew: true,
      hasDivider: true,
    },
    { id: 'log_out', name: 'Log out', onClick: handleLogoutClick, icon: <Icon16Logout /> },
  ];
  return (
    <BaselaneMenu
      listItems={menuItems}
      label={menuLabel}
      profilePhotoUrl={userPhotoUrl}
      placement="bottom-end"
      isProfile
    />
  );
};

export default Profiles;
