import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Image, Stack, Text } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { IconStars } from '@icons';
import { BaselaneBadge } from '@shared/components';
import { getQueryParams } from '@core/Services/Firebase/firebase';

import FDICElement from './components/FDICElement';
import easyStepsInfo from './helpers/easySteps.helpers';
import { blueIconStyles } from './styles/steps.styles';
import {
  bodyTextSmallLightTextStyles,
  bodyTextStyles,
  headingTextStyles,
} from '../styles/fonts.styles';
import { containerPaddingStyles, imageStyles } from './styles/easyUI.styles';
import { contentContainerStyles } from '../styles/intro.styles';

const EasyStepsUI = () => {
  const blueIcon = (element) => (
    <Stack {...blueIconStyles}>
      <Box>{element}</Box>
    </Stack>
  );

  const selection = getQueryParams(window.location, 'selection');
  const { steps = [], title, subMessage, badge, image } =
    easyStepsInfo[selection] || easyStepsInfo.bookkeeping;

  return (
    <Stack {...contentContainerStyles()}>
      {isMobile && <Image src={image} {...imageStyles} />}
      <Stack spacing="4px" {...containerPaddingStyles}>
        <Text {...headingTextStyles}>{title}</Text>

        {subMessage && (
          <Stack direction="row" align="center" spacing="5px" {...bodyTextSmallLightTextStyles}>
            <IconStars />
            <Text>{subMessage}</Text>
          </Stack>
        )}

        {badge && (
          <BaselaneBadge
            type="badge"
            color={badge.color}
            backgroundStyles={{ w: 'fit-content', p: '6px', mt: '16px !important' }}
          >
            <Text>{badge.text}</Text>
          </BaselaneBadge>
        )}
      </Stack>

      <Stack spacing={3} position="relative" {...containerPaddingStyles}>
        {steps.map(({ icon, text, subText }) => (
          <Stack direction="row" align="center" key={uuidv4()}>
            {blueIcon(icon)}
            <Box flex="0 1 auto">
              <Text {...bodyTextStyles}>{text}</Text>
              {subText && (
                <Text {...{ ...bodyTextSmallLightTextStyles, mt: '4px !important' }}>
                  {subText}
                </Text>
              )}
            </Box>
          </Stack>
        ))}
      </Stack>

      {selection === 'landlord_banking' && <FDICElement />}
    </Stack>
  );
};

export default EasyStepsUI;
