import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Divider, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon16ChevronRight, Icon16Info } from '@icons/16px';
import IconArrowDownCircleFilled from '@icons/manual/IconArrowDownCircleFilled';
import IconArrowUpCircleFilled from '@icons/manual/IconArrowUpCircleFilled';

import formatCurrency from '@core/utils/formatCurrency';
import { APY_TIERS } from '@core/components/NativeBankingPage/queries/tiers';
import { BaselaneButton, BaselaneCardNew, BaselaneOverview } from '@shared/components';
import UserContext from '@contexts/UserContext';
import { NB_ACCOUNTS, REDEEM_CASHBACK } from '@routes';
import SetUpRentCollectionBadgeButton from '@core/components/NativeBankingPage/Summary/SetUpRentCollectionBadgeButton';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import LearnMoreModal from './LearnMoreModal';
import NextMonthAPY from './NextMonthAPY';
import APYSectionFooter from './APYSectionFooter';
import PromoTooltip from './PromoTooltip';
import { apyAtomsConfig } from './helpers/APYSectionConfig.helpers';
import { getCurrentAPY, getTierTitle } from './helpers/APYSection.helpers';
import { doesUserHaveSavingsAccount } from './helpers/util.helpers';
import {
  cardSummaryLeftFirstItemStyles,
  cardSummaryOverviewStyles,
  cardSummaryOverviewValueStyles,
  overviewContentStyles,
  overViewFooterContentStyles,
  overViewFooterTitleStyles,
  overviewTitleStyles,
} from './styles/newsummary.styles';

type OverviewProps = {
  baselaneBankBalance: Number,
  ytdCashBack: Number,
  lifetimeCashback: Number,
  monthlyInflow: Number,
  monthlyOutflow: Number,
  lifetimeInterest: Number,
  hasActiveBaselaneAccounts: Boolean,
  isLargerThan899: Boolean,
  isLargerThan576: Boolean,
  isRentCollectionStarted: Boolean,
  hasCollectedRentWithinNeededPeriod: Boolean,
  addFundsRef: any,
  banks: Array<Object>,
  lastMonthActivity: Object,
  currentApy: Number,
};

const Overview = ({
  baselaneBankBalance,
  ytdCashBack,
  lifetimeCashback,
  monthlyInflow,
  monthlyOutflow,
  lifetimeInterest,
  hasActiveBaselaneAccounts,
  isLargerThan899,
  isLargerThan576,
  isRentCollectionStarted,
  hasCollectedRentWithinNeededPeriod,
  addFundsRef,
  banks,
  lastMonthActivity,
  currentApy,
}: OverviewProps) => {
  const navigate = useNavigate();
  const currLocation = useLocation();
  const {
    user: { userPromotion },
  } = useContext(UserContext);
  const { data, loading } = useQuery(APY_TIERS);
  const { tiers: tiersData = [] } = data ?? {};

  // Info needed for apy promo
  const dapyPromotion = (userPromotion ?? [])?.find((p) => p.promotion.type === 'DAPY');
  const isUserOnPromo = dapyPromotion?.isExecuted;
  const todaysDate = new Date().getTime();
  const promoStartDate = new Date(dapyPromotion?.promotion?.startDate);
  const promoEndDate = new Date(dapyPromotion?.promotion?.endDate);
  const userPromoEndDate = new Date(dapyPromotion?.endDate);
  const isUserEligibleForPromo =
    dapyPromotion?.promotion &&
    todaysDate > promoStartDate.getTime() &&
    todaysDate < promoEndDate.getTime();
  const isUserInLastMonthOfPromo = new Date().getMonth() === userPromoEndDate.getMonth();

  const formattedPromoEndDate = () => {
    if (isUserEligibleForPromo && isEmptyState) {
      return moment(todaysDate).add(2, 'months').format('MMM YYYY');
    }
    if (isUserOnPromo) {
      return moment(userPromoEndDate).format('MMM YYYY');
    }
    return null;
  };

  const maxTier = tiersData && tiersData[tiersData.length - 1];
  const userPromoApy = dapyPromotion?.promotion?.dapyProduct?.apyValue ?? maxTier?.product?.value;
  const isEmptyState =
    banks &&
    banks?.filter((b) => !b.isExternal && b.unitAccount?.unitApplicationStatus === 'COMPLETED')
      ?.length === 0;

  const hasSavingsAccount = doesUserHaveSavingsAccount(banks);

  const [config, setConfig] = useState(
    apyAtomsConfig({
      tiersData,
      baselaneBankBalance,
      isRentCollectionStarted,
      hasCollectedRentWithinNeededPeriod,
      isUserOnPromo,
      isEmptyState,
      isUserInLastMonthOfPromo,
    })
  );

  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);

  const activeTier = config?.find((c) => c.isActive);
  const isUserOnMaxTier = maxTier?.product?.value === activeTier?.product?.value;

  // Update tier config as balance updates or if user receives rent payment with baselane bank
  useEffect(() => {
    if (tiersData && tiersData?.length > 0) {
      setConfig(
        apyAtomsConfig({
          tiersData,
          baselaneBankBalance,
          isRentCollectionStarted,
          hasCollectedRentWithinNeededPeriod,
          isUserOnPromo,
          isEmptyState,
          isUserInLastMonthOfPromo,
        })
      );
    }
  }, [
    baselaneBankBalance,
    isRentCollectionStarted,
    hasCollectedRentWithinNeededPeriod,
    tiersData,
    isUserOnPromo,
    isUserInLastMonthOfPromo,
  ]);

  useEffect(() => {
    if (currLocation.search.includes('learnmore=dapy')) {
      setShowLearnMoreModal(true);
      navigate({ pathname: NB_ACCOUNTS });
    }
  }, [currLocation]);

  const valueWrapper = (amount, isSmaller) => {
    return (
      <HStack spacing={0} alignItems="baseline" {...cardSummaryOverviewValueStyles(isSmaller)}>
        <Text fontSize={isSmaller ? 'inherit' : 'lg'}>$</Text>
        <Text>{formatCurrency(amount, true).noDollar}</Text>
      </HStack>
    );
  };

  // Total Balance section
  const overViewFooter = (
    <Flex flexDirection={{ base: 'row', lg: 'column' }} justifyContent="center" w="100%">
      <Box flex={{ base: '1 1 50%', lg: '1 1 auto' }}>
        <Box {...overviewTitleStyles}>Total Balance</Box>
        {valueWrapper(baselaneBankBalance)}
      </Box>
      {isLargerThan576 && (
        <Box flex={{ base: '1 1 50%', lg: '1 1 auto' }}>
          <Text {...overviewTitleStyles} mt={{ base: '0', lg: '24px' }}>
            This Month
          </Text>
          <Stack
            direction="row"
            {...overViewFooterContentStyles}
            align="center"
            spacing="16px"
            h={{ base: '24.5px', lg: 'initial' }}
          >
            <Stack direction="row" align="center" spacing="4px">
              <IconArrowUpCircleFilled color="#56C719" />
              <Text>{formatCurrency(monthlyInflow, true).inDollars}</Text>
            </Stack>
            <Stack direction="row" align="center" spacing="4px">
              <IconArrowDownCircleFilled color="#EA6868" />
              <Text {...overViewFooterContentStyles}>
                {formatCurrency(monthlyOutflow, true).inDollars}
              </Text>
            </Stack>
          </Stack>
        </Box>
      )}
    </Flex>
  );

  // ----- APY section start
  // APY section: above divider
  const APYHeadingNotInEmptyState = !hasSavingsAccount ? 'Savings APY' : 'Current APY';
  const currentAPY = (
    <Box flex="1 1 50%">
      <Text {...overviewTitleStyles}>
        {isEmptyState ? 'Eligible APY' : APYHeadingNotInEmptyState}
      </Text>
      <HStack p={isLargerThan899 ? '0' : '6px 0'}>
        {getCurrentAPY({
          isUserEligibleForPromo,
          isEmptyState,
          isLargerThan899,
          currentApy,
          userPromoApy,
          maxTier,
        })}
        {(isUserOnPromo || (isUserEligibleForPromo && isEmptyState)) && (
          <PromoTooltip
            text={
              <Text>
                {!isEmptyState ? (
                  'Introductory APY valid through '
                ) : (
                  <>
                    <Box as="span">Promo {userPromoApy}</Box>% Base APY through{' '}
                  </>
                )}
                {formattedPromoEndDate()}
              </Text>
            }
          />
        )}
      </HStack>
    </Box>
  );

  const learnMore = (
    <BaselaneButton
      variant="transparent"
      palette="neutral"
      onClick={() => {
        setShowLearnMoreModal(true);
        sendSegmentEvent('baselane_banking_home_apy_click_earn_more');
      }}
    >
      Learn more
    </BaselaneButton>
  );

  const tierTitle = getTierTitle({
    isEmptyState,
    isUserEligibleForPromo,
    isLargerThan899,
    maxTier,
    hasSavingsAccount,
    showNewSavingAccountUI: true,
  });
  // APY section: below divider
  const nextMonthsAPY = (
    <Box lineHeight="16px">
      <HStack justifyContent="space-between">
        {tierTitle && (
          <Text
            {...overviewTitleStyles}
            mt={{ base: '0', lg: '12px' }}
            mb={{ base: '0', lg: '4px' }}
          >
            {tierTitle}
          </Text>
        )}
        {(isLargerThan899 && !isEmptyState) ||
        (isLargerThan899 && isEmptyState && !isUserEligibleForPromo)
          ? learnMore
          : null}
      </HStack>

      <NextMonthAPY
        config={config}
        isEmptyState={isEmptyState}
        isUserEligibleForPromo={isUserEligibleForPromo}
        isLargerThan899={isLargerThan899}
        learnMore={learnMore}
        userPromoApy={userPromoApy}
        maxTier={maxTier}
        formattedPromoEndDate={formattedPromoEndDate}
        hasSavingsAccount={hasSavingsAccount}
        showNewSavingAccountUI
        setShowLearnMoreModal={setShowLearnMoreModal}
      />

      {isLargerThan899 && (
        <APYSectionFooter
          {...{
            config,
            isUserOnPromo,
            isEmptyState,
            isUserEligibleForPromo,
            loading,
            learnMore,
            maxTier,
            userPromoApy,
            currentApy,
            hasCollectedRentWithinNeededPeriod,
            baselaneBankBalance,
            isUserInLastMonthOfPromo,
          }}
        />
      )}

      {/* case for empty state where user is eligible for intro promo */}
      {isLargerThan899 && isEmptyState && isUserEligibleForPromo && learnMore}
    </Box>
  );

  // APY section
  const overViewFooterCurrent = (
    <Box m="0!important" p="0!important" w="100%">
      <HStack align="flex-start" justifyContent="space-between" mb="12px">
        {currentAPY}
        {(hasSavingsAccount || isEmptyState) && (
          <Box display={{ base: 'none', '2lg': 'block' }}>
            <Box {...overviewTitleStyles}>Lifetime Interest</Box>
            {valueWrapper(lifetimeInterest)}
          </Box>
        )}
      </HStack>
      {!hasSavingsAccount && !isEmptyState ? (
        <BaselaneCardNew
          variant="clickable"
          size="md"
          w="100%"
          onClick={() => {
            setShowLearnMoreModal(true);
          }}
        >
          <HStack width="100%" justifyContent="space-between">
            <HStack spacing={2}>
              <Box>
                <Icon16Info />
              </Box>
              <Text textStyle="xs" sx={{ textWrap: 'wrap' }}>
                Add a savings account to earn this APY
              </Text>
            </HStack>
            <Box>
              <Icon16ChevronRight />
            </Box>
          </HStack>
        </BaselaneCardNew>
      ) : (
        <>
          <Divider />
          {nextMonthsAPY}
        </>
      )}
    </Box>
  );
  // ----- APY section end

  // Cashback section
  const overViewFooterCashBack = (
    <Box>
      <Box {...overviewTitleStyles}>Available Cash Back</Box>
      <Stack direction="row" {...overViewFooterTitleStyles} alignItems="center">
        {valueWrapper(ytdCashBack ?? 0, !isLargerThan899)}

        {hasActiveBaselaneAccounts && (
          <BaselaneButton
            variant="transparent"
            palette="neutral"
            onClick={() => navigate({ pathname: `${currLocation.pathname}/${REDEEM_CASHBACK}` })}
          >
            Redeem
          </BaselaneButton>
        )}
      </Stack>

      {isLargerThan899 && (
        <>
          <Box {...overviewTitleStyles} mt="24px">
            Lifetime Cash Back
          </Box>
          <Box {...overViewFooterContentStyles}>
            {formatCurrency(lifetimeCashback, true).inDollars}
          </Box>
        </>
      )}
    </Box>
  );

  // --- start combining sections together for BaselaneOverview component
  const overviewTotalBalance = [
    {
      id: 'total_balance',
      content: overViewFooter,
      style: {
        fontSize: '2xl',
        minWidth: { '2lg': '175px' },
        flex: '1',
        p: '24px 16px 24px 24px',
        alignItems: 'flex-start',
      },
    },
  ];

  const overviewCurrentAPY = [
    {
      id: 'apy',
      content: isLargerThan899 ? overViewFooterCurrent : currentAPY,
      style: {
        minWidth: { '2lg': '175px' },
        flex: '1',
        p: { base: '16px 8px 16px 16px', lg: '24px' },
      },
    },
  ];

  const overviewNextMonthsAPY = [
    {
      id: 'next_apy',
      content: nextMonthsAPY,
      style: {
        minWidth: { '2lg': '175px' },
        flex: isLargerThan576 ? '0 1 33%' : '1',
        p: '16px',
        height: 'unset',
      },
    },
  ];

  const overviewAvailableCashBack = [
    {
      id: 'cashback',
      content: overViewFooterCashBack,
      style: {
        fontSize: '2xl',
        minWidth: { base: 'fit-content', '2lg': '175px' },
        flex: '1',
        p: { base: '16px 8px 16px 16px', lg: '24px 16px 24px 24px' },
      },
    },
  ];

  let responsiveElements = isLargerThan576
    ? overviewAvailableCashBack.concat(overviewCurrentAPY).concat(overviewNextMonthsAPY)
    : overviewCurrentAPY.concat(overviewNextMonthsAPY);

  if (isEmptyState) {
    responsiveElements = isLargerThan899
      ? overviewAvailableCashBack.concat(overviewCurrentAPY).concat(overviewNextMonthsAPY)
      : overviewNextMonthsAPY;
  }

  const responsiveOverview = (
    <Box>
      <Box p="16px">{overViewFooter}</Box>
      <BaselaneOverview
        elements={responsiveElements}
        styles={{ ...cardSummaryOverviewStyles, borderTop: '1px solid #F0F2F6' }}
        customContentContainerStyles={{ width: '100%' }}
      />
    </Box>
  );

  return (
    <>
      {isLargerThan899 &&
        !isRentCollectionStarted &&
        banks?.length > 0 &&
        ((isUserOnPromo && isUserInLastMonthOfPromo) || !isUserOnPromo) &&
        !isUserOnMaxTier &&
        !isEmptyState && <SetUpRentCollectionBadgeButton config={config} />}
      {isLargerThan899 ? (
        <BaselaneOverview
          elements={overviewTotalBalance
            .concat(overviewAvailableCashBack)
            .concat(overviewCurrentAPY)}
          styles={cardSummaryOverviewStyles}
          firstItemStyles={cardSummaryLeftFirstItemStyles}
          customFontStyles={overviewTitleStyles}
          customContentStyles={overviewContentStyles}
          customContentContainerStyles={{
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            height: isLargerThan899 ? '100%' : 'auto',
            width: '100%',
          }}
        />
      ) : (
        responsiveOverview
      )}
      <LearnMoreModal
        tiersData={tiersData}
        loading={loading}
        baselaneBankBalance={baselaneBankBalance}
        shouldBeOpened={showLearnMoreModal}
        onCloseBtnClick={() => {
          setShowLearnMoreModal(false);
        }}
        isRentCollectionStarted={isRentCollectionStarted}
        hasCollectedRentWithinNeededPeriod={hasCollectedRentWithinNeededPeriod}
        addFundsRef={addFundsRef}
        setShowLearnMoreModal={setShowLearnMoreModal}
        showLearnMoreModal={showLearnMoreModal}
        isUserOnPromo={isUserOnPromo}
        isEmptyState={isEmptyState}
        isLargerThan576={isLargerThan576}
        lastMonthActivity={lastMonthActivity}
        isUserInLastMonthOfPromo={isUserInLastMonthOfPromo}
        userPromoApy={userPromoApy}
        isUserEligibleForPromo={isUserEligibleForPromo}
        currentApy={currentApy}
        isUserOnMaxTier={isUserOnMaxTier}
        userPromoEndDate={userPromoEndDate}
      />
    </>
  );
};

export default Overview;
