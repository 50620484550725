import React, { useRef } from 'react';
import { AlertFooter, AlertHeader, BaselaneAlert } from '@shared/components';

type CloseSubAccountAlertProps = {
  canCloseSubAccount: Boolean,
  onCancel: Function,
  onCloseSubAccount: Function,
  isOpen: Boolean,
  loading: Boolean,
};

const CloseSubAccountAlert = ({
  canCloseSubAccount,
  onCancel,
  onCloseSubAccount,
  isOpen,
  loading,
}: CloseSubAccountAlertProps) => {
  const alertCancelRef = useRef(null);

  const alertHeaderText = canCloseSubAccount
    ? 'Are you sure you want to close this virtual account?'
    : 'This virtual account cannot be closed as it has a balance, pending rent collection or scheduled payments/transfers';
  const alertBodyText = canCloseSubAccount
    ? 'This action is not reversible. Transactions will remain visible in the Main Account.'
    : '';

  const alertDrawerCloseFooter = (
    <AlertFooter
      cancelRef={alertCancelRef}
      leftButtonEvent={onCancel}
      leftButtonText="Cancel"
      rightButtonEvent={onCloseSubAccount}
      rightButtonText="Close account"
      rightButtonProps={{
        isDisabled: !canCloseSubAccount,
        variant: 'filled',
        palette: 'danger',
        isLoading: loading,
      }}
    />
  );

  return (
    <BaselaneAlert
      isOpen={isOpen}
      leastDestructiveRef={alertCancelRef}
      onClose={onCancel}
      header={
        <AlertHeader
          title={alertHeaderText}
          iconBgStyles={{ bg: 'red.200' }}
          iconContainerStyles={{ color: 'red.800AA' }}
        />
      }
      body={alertBodyText}
      footer={alertDrawerCloseFooter}
      size={canCloseSubAccount ? 'xl' : '4xl'}
      showCloseButton
    />
  );
};

export default CloseSubAccountAlert;
