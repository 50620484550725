import React from 'react';
import { Field } from 'formik';
import { FormControl, useDisclosure } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import {
  BaselaneFormLabel,
  BaselaneSingleDatePicker,
  BaselaneFormErrorMessage,
} from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import useValidDates from '@hooks/useValidDates';

const StartDateDatepicker = () => {
  const { isMin768 } = useBreakPoints();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { startDate, excludedDates } = useValidDates();

  const isWeekday = (date) => {
    const day = date.getDay(date);
    return day !== 0 && day !== 6;
  };

  return (
    <Field name="recurringTransfer.startDate">
      {({ field, form }) => {
        return (
          <FormControl
            isRequired
            isInvalid={
              form.errors?.recurringTransfer?.startDate &&
              form.touched?.recurringTransfer?.startDate
            }
          >
            <BaselaneFormLabel textStyle="sm" htmlFor="recurringTransfer.startDate">
              Start date
            </BaselaneFormLabel>
            <BaselaneSingleDatePicker
              id="recurringTransfer.startDate"
              name="Start date"
              minDate={startDate}
              date={field.value}
              value={
                field.value ? DateTime.fromJSDate(field.value).toFormat('MMM d, yyyy') : undefined
              } // Why? datepicker supposed to take care of the format but in mobile it breaks :/              dateFormat="MMM dd yyyy"
              handleCalendarClose={({ date, isFromApplyButton }) => {
                if (isMin768 || (!isMin768 && isFromApplyButton)) {
                  form.setFieldValue('recurringTransfer.startDate', date);
                }
              }}
              size="lg"
              years={{ start: new Date().getFullYear(), end: new Date().getFullYear() + 5 }}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              hideInputIcon={false}
              fixedHeight
              placement="bottom-end"
              showInModal={!isMin768}
              filterDate={isWeekday}
              excludeDates={excludedDates}
              hideClearButton
            />
            <BaselaneFormErrorMessage
              isInvalid={
                form.errors?.recurringTransfer?.startDate &&
                form.touched?.recurringTransfer?.startDate
              }
            >
              {form.errors?.recurringTransfer?.startDate}
            </BaselaneFormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default StartDateDatepicker;
