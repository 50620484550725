import React, { useState } from 'react';
import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import stripCurrency from '@core/utils/stripCurrency';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import PAYMENT_TYPE from '../../../models/PaymentTypeEnum';
import { fieldContainer } from '../../styles/shared.styles';
import { formValidation } from '../../formHelpers/leaseTermForm.helper';

type MonthlyRentAmountInputProps = {
  name: string,
  errors: Object,
  touched: Object,
  values: Object,
  currencyMask: Function,
  handleChange: Function,
  handleBlur: Function,
  setFieldTouched: Function,
  setFormVariables: Function,
  rentId: string,
  rentName: string,
};

function MonthlyRentAmountInput({
  name,
  errors: initialErrors,
  touched,
  values,
  currencyMask,
  handleChange,
  handleBlur,
  setFieldTouched,
  setFormVariables,
  rentId,
  rentName,
}: MonthlyRentAmountInputProps) {
  const [errors, setErrors] = useState(initialErrors);
  const handleChangeMonthlyAmount = (e) => {
    setFieldTouched('monthlyRentAmount', true);
    const amount = stripCurrency(e?.target?.value);
    setFormVariables({
      payments: [{ id: rentId, name: PAYMENT_TYPE[rentName], amount }],
    });

    setErrors(
      formValidation({
        ...values,
        monthlyRentAmount: amount,
      })
    );

    handleChange(e);
  };

  return (
    <FormControl
      {...fieldContainer}
      mb="0px"
      isInvalid={errors.monthlyRentAmount && touched.monthlyRentAmount}
    >
      <FormLabel {...formLabelStyles.xs} htmlFor={name}>
        Rent amount
      </FormLabel>
      <Input
        {...formInputStyles}
        id={name}
        {...{ name }}
        placeholder="$"
        as={MaskedInput}
        mask={currencyMask}
        value={values.monthlyRentAmount}
        onChange={handleChangeMonthlyAmount}
        onBlur={handleBlur}
        inputMode="decimal"
      />
      <FormErrorMessage {...formErrorStyles}>{errors.monthlyRentAmount}</FormErrorMessage>
    </FormControl>
  );
}

export default MonthlyRentAmountInput;
