import { gql } from '@apollo/client';

// Fragments
export const FRAGMENT_PROPERTY_ADDRESS = gql`
  fragment PropertyAddressObj on Property {
    id
    name
    address
  }
`;

export const FRAGMENT_PROPERTY_DROPDOWN = gql`
  fragment PropertyDropdownObj on Property {
    id
    name
    address
    units {
      id
      name
    }
  }
`;
export const FRAGMENT_PROPERTY_DROPDOWN_WITH_LEASE_ID = gql`
  fragment PropertyDropdownWithLeaseIdObj on Property {
    id
    name
    address
    llcName
    units {
      id
      name
      status
      tenantScreening {
        tenantScreeningId
      }
      lease {
        id
      }
    }
  }
`;

export const FRAGMENT_PROPERTY_INSURANCE = gql`
  fragment PropertyInsuranceObj on Property {
    id
    name
    address
    type
    annualPropertyInsurance
    units {
      id
    }
  }
`;

export const FRAGMENT_PROPERTY_LEASE = gql`
  fragment PropertyLeaseObj on Property {
    id
    name
    units {
      id
      name
      status
      lease {
        id
        startDate
        endDate
        paymentState
        leaseType
      }
    }
  }
`;

export const FRAGMENT_PROPERTY_SIMPLE = gql`
  fragment PropertySimpleObj on Property {
    id
    name
    units {
      id
      name
    }
  }
`;

export const FRAGMENT_PROPERTY = gql`
  fragment PropertyObj on Property {
    id
    name
    address
    type
    purchasePrice
    purchaseDate
    closingCosts
    mortgageDownpayment
    mortgageTerm
    marketPrice
    mortgageBalance
    annualPropertyTaxes
    annualPropertyInsurance
    zillowMarketPrice
    units {
      id
      name
      description
      status
      lease {
        id
        state
      }
      isUnitDeletable
      tenantScreening {
        tenantScreeningId
      }
    }
  }
`;

// Queries + Mutations
export const GET_PROPERTY_SUMMARY = gql`
  query PropertySummary {
    propertySummary {
      totalProperties
      totalUnits
      vacantUnits
    }
  }
`;

export const GET_PROPERTIES_ADDRESS = gql`
  query Property {
    property {
      ...PropertyAddressObj
    }
  }
  ${FRAGMENT_PROPERTY_ADDRESS}
`;

export const GET_PROPERTIES_DROPDOWN = gql`
  query Property {
    property {
      ...PropertyDropdownObj
    }
  }
  ${FRAGMENT_PROPERTY_DROPDOWN}
`;

export const GET_PROPERTIES_DROPDOWN_WITH_LEASE_ID = gql`
  query Property {
    property {
      ...PropertyDropdownWithLeaseIdObj
    }
  }
  ${FRAGMENT_PROPERTY_DROPDOWN_WITH_LEASE_ID}
`;

export const GET_PROPERTIES_INSURANCE = gql`
  query Property {
    property {
      ...PropertyInsuranceObj
    }
  }
  ${FRAGMENT_PROPERTY_INSURANCE}
`;

export const GET_PROPERTIES_LEASE = gql`
  query Property {
    property {
      ...PropertyLeaseObj
    }
  }
  ${FRAGMENT_PROPERTY_LEASE}
`;

export const GET_PROPERTIES_SIMPLE = gql`
  query Property {
    property {
      ...PropertySimpleObj
    }
  }
  ${FRAGMENT_PROPERTY_SIMPLE}
`;

export const GET_PROPERTIES = gql`
  query Property {
    property {
      ...PropertyObj
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const CREATE_PROPERTY_SIMPLE = gql`
  mutation createProperty(
    $type: String!
    $name: String!
    $units: [PropertyUnitInput]!
    $address: PropertyAddressInput!
    $purchasePrice: Float
    $purchaseDate: String
    $closingCosts: Float
    $mortgageDownpayment: Float
    $marketPrice: [MarketPriceInput]
    $mortgageBalance: [MortgageBalanceInput]
    $mortgageTerm: MortgageTerm
    $annualPropertyTaxes: Float
    $annualPropertyInsurance: Float
  ) {
    createProperty(
      input: {
        type: $type
        name: $name
        units: $units
        address: $address
        purchasePrice: $purchasePrice
        purchaseDate: $purchaseDate
        closingCosts: $closingCosts
        mortgageDownpayment: $mortgageDownpayment
        marketPrice: $marketPrice
        mortgageBalance: $mortgageBalance
        mortgageTerm: $mortgageTerm
        annualPropertyTaxes: $annualPropertyTaxes
        annualPropertyInsurance: $annualPropertyInsurance
      }
    ) {
      ...PropertySimpleObj
    }
  }
  ${FRAGMENT_PROPERTY_SIMPLE}
`;

export const CREATE_PROPERTY = gql`
  mutation createProperty(
    $type: String!
    $name: String!
    $units: [PropertyUnitInput]!
    $address: PropertyAddressInput!
    $purchasePrice: Float
    $purchaseDate: String
    $closingCosts: Float
    $mortgageDownpayment: Float
    $marketPrice: [MarketPriceInput]
    $mortgageBalance: [MortgageBalanceInput]
    $mortgageTerm: MortgageTerm
    $annualPropertyTaxes: Float
    $annualPropertyInsurance: Float
  ) {
    createProperty(
      input: {
        type: $type
        name: $name
        units: $units
        address: $address
        purchasePrice: $purchasePrice
        purchaseDate: $purchaseDate
        closingCosts: $closingCosts
        mortgageDownpayment: $mortgageDownpayment
        marketPrice: $marketPrice
        mortgageBalance: $mortgageBalance
        mortgageTerm: $mortgageTerm
        annualPropertyTaxes: $annualPropertyTaxes
        annualPropertyInsurance: $annualPropertyInsurance
      }
    ) {
      ...PropertyObj
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const UPDATE_PROPERTY = gql`
  mutation updateProperty(
    $id: ID!
    $type: String
    $name: String
    $units: [PropertyUnitInput]
    $address: PropertyAddressInput
    $purchasePrice: Float
    $purchaseDate: String
    $closingCosts: Float
    $mortgageDownpayment: Float
    $marketPrice: [MarketPriceInput]
    $mortgageBalance: [MortgageBalanceInput]
    $mortgageTerm: MortgageTerm
    $annualPropertyTaxes: Float
    $annualPropertyInsurance: Float
  ) {
    updateProperty(
      input: {
        id: $id
        type: $type
        name: $name
        units: $units
        address: $address
        purchasePrice: $purchasePrice
        purchaseDate: $purchaseDate
        closingCosts: $closingCosts
        mortgageDownpayment: $mortgageDownpayment
        marketPrice: $marketPrice
        mortgageBalance: $mortgageBalance
        mortgageTerm: $mortgageTerm
        annualPropertyTaxes: $annualPropertyTaxes
        annualPropertyInsurance: $annualPropertyInsurance
      }
    ) {
      ...PropertyObj
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const DELETE_PROPERTY = gql`
  mutation deleteProperty($id: ID!) {
    deleteProperty(id: $id) {
      ...PropertyObj
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const GET_ZILLOW_DATA = gql`
  query zillowData($address: PropertyAddressInput) {
    zillowData(address: $address) {
      zillowMarketPrice
      zillowURL
    }
  }
`;
