import React, { useEffect, useMemo, useRef, useState, PropsWithChildren } from 'react';
import { Tooltip, Box } from '@chakra-ui/react';

import useBreakPoints from '@core/hooks/useBreakPoints';

import { tooltipStyle } from './styles/conditionalTooltip.style';

type BaselaneConditionalTooltipProps = {
  condition: Boolean,
  tooltipText: Any,
  isOpen?: Boolean,
  childIsTarget: Boolean,
  styles?: Object,
  placement?: String,
};

const BaselaneConditionalTooltip = ({
  condition,
  tooltipText,
  isOpen = null,
  childIsTarget = false,
  styles = {},
  children,
  placement = 'top-start',
}: PropsWithChildren<BaselaneConditionalTooltipProps>) => {
  const { isMax576: isMobileUI } = useBreakPoints();
  const [forceOpen, setForceOpen] = useState(false);
  const clickTarget = useRef();

  const hasTooltip = tooltipText && tooltipText?.length;

  // only use the controlled mode when in mobileUI
  const open = useMemo(
    () => ({
      ...(hasTooltip && isMobileUI ? { isOpen: isOpen || forceOpen } : {}),
    }),
    [hasTooltip, isMobileUI, isOpen, forceOpen]
  );

  // handler for when the child is the clickable target
  const onClick = (e) => {
    setForceOpen(true);
  };

  // handler for when the child is the clickable target and user clicks on something else
  const onClickElsewhere = (e) => {
    if (e.target !== clickTarget.current) {
      setForceOpen(false);
    }
  };

  // set up global click handler if needed
  useEffect(() => {
    if (childIsTarget) {
      window.addEventListener('click', onClickElsewhere);
    }

    return () => {
      window.removeEventListener('click', onClickElsewhere);
    };
  }, []);

  const contents = childIsTarget ? (
    <Box ref={clickTarget} onClick={onClick}>
      {children}
    </Box>
  ) : (
    children
  );

  return condition ? (
    <Tooltip
      hasArrow
      label={tooltipText}
      placement={placement}
      {...open}
      {...{ ...tooltipStyle, ...styles }}
    >
      {contents}
    </Tooltip>
  ) : (
    children
  );
};

export default BaselaneConditionalTooltip;
