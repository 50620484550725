import React from 'react';

function IconListUp() {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333984 0.666667C0.333984 0.298477 0.632461 0 1.00065 0H9.66732C10.0355 0 10.334 0.298477 10.334 0.666667C10.334 1.03486 10.0355 1.33333 9.66732 1.33333H1.00065C0.632461 1.33333 0.333984 1.03486 0.333984 0.666667ZM0.333984 3.33333C0.333984 2.96514 0.632461 2.66667 1.00065 2.66667H7.00065C7.36884 2.66667 7.66732 2.96514 7.66732 3.33333C7.66732 3.70152 7.36884 4 7.00065 4H1.00065C0.632461 4 0.333984 3.70152 0.333984 3.33333ZM10.5892 2.71725C10.6672 2.74955 10.7404 2.7973 10.804 2.86052C10.8044 2.86099 10.8049 2.86146 10.8054 2.86193C10.8055 2.86203 10.8056 2.86213 10.8057 2.86224L13.4721 5.5286C13.7324 5.78895 13.7324 6.21106 13.4721 6.4714C13.2117 6.73175 12.7896 6.73175 12.5292 6.4714L11.0007 4.94281V11.3333C11.0007 11.7015 10.7022 12 10.334 12C9.96579 12 9.66732 11.7015 9.66732 11.3333V4.94281L8.13872 6.4714C7.87837 6.73175 7.45626 6.73175 7.19591 6.4714C6.93556 6.21106 6.93556 5.78895 7.19591 5.5286L9.86227 2.86224C9.86237 2.86213 9.86248 2.86203 9.86258 2.86193C9.86305 2.86146 9.86352 2.86099 9.864 2.86052C9.92758 2.7973 10.0007 2.74955 10.0788 2.71725C10.1574 2.68466 10.2436 2.66667 10.334 2.66667C10.4244 2.66667 10.5106 2.68466 10.5892 2.71725ZM0.333984 6C0.333984 5.63181 0.632461 5.33333 1.00065 5.33333H5.00065C5.36884 5.33333 5.66732 5.63181 5.66732 6C5.66732 6.36819 5.36884 6.66667 5.00065 6.66667H1.00065C0.632461 6.66667 0.333984 6.36819 0.333984 6Z"
        fill="#3A4B5B"
      />
    </svg>
  );
}

export default IconListUp;
