import React, { useEffect, useRef, useState } from 'react';
import { Box, Spacer, Stack, Text } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { BaselaneButton, BaselaneDrawer, BaselaneButtonGroup } from '@shared/components';
import BaselaneDisclaimer from '@core/components/NativeBankingPage/components/BaselaneDisclaimer';
import { disclaimerText } from '@core/components/NativeBankingPage/components/BaselaneDisclaimer/const/disclaimerText';
import { IconInitiateABankTransfer, IconChevronRight } from '@icons';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import EmptyStateDrawerContent from '@core/components/NativeBankingPage/MainContent/components/AddFundsDrawer/components/EmptyStateDrawerContent';
import { methods } from '@core/components/NativeBankingPage/MainContent/components/AddFundsDrawer/components/EducationalDrawer/helpers/button.helper';
import AddAccount from '@core/components/NativeBankingPage/MainContent/components/Accounts/components/AddAccount';
import EducationalDrawer from '../EducationalDrawer';
import BankTransfer from '../../../BankTransfer';
import ReviewTransfer from '../../../BankTransfer/ReviewTransfer';
import TransferConfirmation from '../../../BankTransfer/TransferConfirmation';
import ButtonMethod from '../../../ButtonMethod';

import {
  containerStyle,
  disclaimerTextStyles,
  drawerBodyStyles,
  drawerFooterStyles,
  title,
} from '../../styles/addFundsDrawer.styles';

type BankTransferDrawerProps = {
  bankTransferDrawerRef: any,
  reviewTransferDrawerRef: any,
  transferConfirmationDrawerRef: any,
  onOpenBankTransferDrawer: Function,
  onOpenReviewTransferDrawer: Function,
  handleBankTransferClose: Function,
  isValidAccount: Boolean,
  setIsValidAccount: Function,
  setIsValidBankTransfer: Function,
  setTransfer: Function,
  checkToken: Function,
  onCloseReviewTransferDrawer: Function,
  handleCloseAllDrawers: Function,
  isValidBankTransfer: boolean,
  createTransferLoading: boolean,
  transfer: Object,
  cleanUp: Function,
  onCloseTransferConfirmationDrawer: Function,
  emptyStateEducationalDrawerRef: any,
  showEmptyState: boolean,
  refetchBankSummary: Function,
  hasBEError: boolean,
  setHasBEError: Function,
  isOpenExternalAccount: boolean,
  setIsOpenExternalAccount: Function,
  setIsExternalAccountChecked: Function,
  onCloseAddFundsDrawer: Function,
  onClose: Function,
  sendBaselaneBankingSelectAddFundEvent: Function,
  bankTransferDrawerIsOpen: boolean,
  setBankTransferDrawerIsOpen: Function,
};

const BankTransferDrawer = ({
  bankTransferDrawerRef,
  reviewTransferDrawerRef,
  transferConfirmationDrawerRef,
  onOpenBankTransferDrawer,
  onOpenReviewTransferDrawer,
  handleBankTransferClose,
  isValidAccount,
  setIsValidAccount,
  setIsValidBankTransfer,
  setTransfer,
  checkToken,
  onCloseReviewTransferDrawer,
  handleCloseAllDrawers,
  isValidBankTransfer,
  createTransferLoading,
  transfer,
  cleanUp,
  onCloseTransferConfirmationDrawer,
  showEmptyState,
  refetchBankSummary,
  hasBEError,
  setHasBEError,
  isOpenExternalAccount = false,
  setIsOpenExternalAccount = () => {},
  setIsExternalAccountChecked = () => {},
  onCloseAddFundsDrawer = () => {},
  // Callback on closing the drawer.
  onClose = () => {},
  sendBaselaneBankingSelectAddFundEvent,
  bankTransferDrawerIsOpen,
  setBankTransferDrawerIsOpen,
}: BankTransferDrawerProps) => {
  const { isMinXL } = useBreakPoints();
  const addExternalAccountDrawerRef = useRef(null);
  const emptyStateEducationalDrawerRef = useRef(null);
  const bankTransferDrawerBodyRef = useRef(null);

  // keep Linked Account drawer open if empty state changes to account selection drawer
  const [isOpenLinkedAccountDrawer, setIsOpenLinkedAccountDrawer] = useState(false);

  const handleEducationalDrawerClose = () => emptyStateEducationalDrawerRef?.current?.close();
  const handleEducationalDrawerOpen = () => emptyStateEducationalDrawerRef?.current?.open();

  const { DrawerBody, DrawerFooter } = BaselaneDrawer;

  const bankTransferDrawerHeaderProps = {
    title: 'Add Funds',
    hasMobileShadow: isMinXL,
  };

  const handleBaselaneBankAndRoutingNumClick = () => {
    emptyStateEducationalDrawerRef.current?.open();
  };

  useEffect(() => {
    if (hasBEError) {
      onCloseReviewTransferDrawer();
    }
  }, [hasBEError]);

  return (
    <>
      <ButtonMethod
        icon={<IconInitiateABankTransfer />}
        handleOnClick={() => {
          sendBaselaneBankingSelectAddFundEvent();
          setBankTransferDrawerIsOpen(true);
        }}
        {...{
          title: 'INITIATE A BANK TRANSFER',
          description:
            'Use Baselane to initiate an ACH transfer from one of your External Accounts',
        }}
      />

      {/* Bank Transfer Drawer */}
      <BaselaneDrawer
        isOpen={bankTransferDrawerIsOpen}
        ref={bankTransferDrawerRef}
        title="Add Funds"
        size="md"
        closeEvent={handleBankTransferClose}
        onClose={handleBankTransferClose}
        hideOverlay
        {...bankTransferDrawerHeaderProps}
      >
        <DrawerBody
          ref={bankTransferDrawerBodyRef}
          {...drawerBodyStyles(showEmptyState())}
          id="transfer-drawer"
        >
          {showEmptyState() || isOpenExternalAccount ? (
            <EmptyStateDrawerContent {...{ emptyStateEducationalDrawerRef }} />
          ) : (
            <Box>
              <Text {...title(isMinXL)}>Add Funds via Bank Transfer</Text>
              <BankTransfer
                {...{
                  setIsValidAccount,
                  setIsValidBankTransfer,
                  setTransfer,
                  ...transfer,
                  hasBEError,
                  bankTransferDrawerBodyRef,
                  setHasBEError,
                  handleBaselaneBankAndRoutingNumClick,
                }}
                transferType="TRANSFER_IN"
              />
            </Box>
          )}
          <EducationalDrawer
            {...{
              hideButton: true,
              buttonContent: methods[0],
              educationalDrawerRef: emptyStateEducationalDrawerRef,
              handleDrawerClose: handleEducationalDrawerClose,
              handleDrawerOpen: handleEducationalDrawerOpen,
              bankTransferProps: { onCloseBankTransferDrawer: handleBankTransferClose },
            }}
          />
        </DrawerBody>
        {/* Footer add funds > Initiate a Bank Transfer */}
        <DrawerFooter {...drawerFooterStyles(isMinXL)}>
          {showEmptyState() || isOpenExternalAccount || isOpenLinkedAccountDrawer ? (
            <AddAccount
              {...{
                isDirectToPlaid: true,
                hideButton: true,
                linkedAccountsDrawerOnClose: isOpenExternalAccount
                  ? () => {
                      setIsOpenExternalAccount(false);
                      setIsExternalAccountChecked(true);
                      onCloseAddFundsDrawer();
                      setIsOpenLinkedAccountDrawer(false);
                    }
                  : () => {},
                isDirectToPlaidButtonProps: {
                  variant: 'filled',
                  palette: 'primary',
                  size: 'md',
                  styles: isMinXL ? { width: '100%' } : {},
                  containerStyles: { width: '100%' },
                  segmentEvent: () => {
                    sendSegmentEvent('baselane_banking_add_ach_click_add_account', {
                      hasCounterparty: false,
                    });
                  },
                },
                refetchLeaseBankAccounts: refetchBankSummary,
                addExternalAccountDrawerRef,
                setIsOpenLinkedAccountDrawer,
              }}
            />
          ) : (
            <Stack
              w="100%"
              flexDirection={isMinXL ? 'column' : 'row'}
              justifyContent="space-between"
            >
              <BaselaneButtonGroup size="md">
                <BaselaneButton
                  variant="outline"
                  palette="neutral"
                  size="md"
                  onClick={handleBankTransferClose}
                >
                  Cancel
                </BaselaneButton>
                <BaselaneButton
                  size="md"
                  variant="filled"
                  palette="primary"
                  rightIcon={<IconChevronRight />}
                  isDisabled={!isValidBankTransfer || !isValidAccount}
                  onClick={() => {
                    setHasBEError(false);
                    sendSegmentEvent('baselane_banking_add_ach_complete_step1');
                    onOpenReviewTransferDrawer();
                  }}
                >
                  Review Transfer
                </BaselaneButton>
              </BaselaneButtonGroup>
            </Stack>
          )}
        </DrawerFooter>
      </BaselaneDrawer>
      {/* Review Transfer Drawer */}
      <BaselaneDrawer
        ref={reviewTransferDrawerRef}
        title="Add Funds"
        size="md"
        closeEvent={onCloseReviewTransferDrawer}
        hideOverlay
        showBackButton={isMinXL}
        hideBackText
        hasMobileShadow={isMinXL}
        showCloseButton
      >
        <DrawerBody {...drawerBodyStyles(false, isMinXL)}>
          <Text {...{ ...title(isMinXL), mt: isMinXL ? '20px' : 0, mb: isMinXL ? '4px' : '16px' }}>
            Review & Confirm Transfer
          </Text>
          <ReviewTransfer {...{ transfer }} />
          <Spacer />
          <BaselaneDisclaimer
            text={disclaimerText.addFunds.text}
            textStyle={disclaimerTextStyles}
            containerStyle={containerStyle}
            showPrivacyPolicy={disclaimerText.addFunds.showPrivacyPolicy}
          />
        </DrawerBody>

        <DrawerFooter {...drawerFooterStyles(isMinXL)}>
          <Stack w="100%" flexDirection={isMinXL ? 'column' : 'row'} justifyContent="space-between">
            <Stack direction="row" justifyContent="space-between" align="center">
              <BaselaneButton
                variant="outline"
                palette="neutral"
                size="md"
                styles={{
                  width: isMinXL ? '100%' : 'auto',
                }}
                onClick={onCloseReviewTransferDrawer}
              >
                Cancel
              </BaselaneButton>
              <BaselaneButton
                styles={{
                  width: isMinXL ? '100%' : 'auto',
                }}
                variant="filled"
                palette="primary"
                size="md"
                isLoading={createTransferLoading}
                rightIcon={<IconChevronRight />}
                onClick={() => {
                  sendSegmentEvent('baselane_banking_add_ach_complete_review');
                  checkToken();
                }}
              >
                Add Funds
              </BaselaneButton>
            </Stack>
          </Stack>
        </DrawerFooter>
      </BaselaneDrawer>
      {/* Transfer Confirmation Drawer */}
      <BaselaneDrawer
        ref={transferConfirmationDrawerRef}
        title="Add Funds"
        size="md"
        closeEvent={handleCloseAllDrawers}
        hideOverlay
        showBackButton={isMinXL}
        hideBackText
        hasMobileShadow={isMinXL}
        showCloseButton
      >
        <DrawerBody {...drawerBodyStyles(false, isMinXL)}>
          <TransferConfirmation />
        </DrawerBody>

        <DrawerFooter {...drawerFooterStyles(isMinXL)}>
          <Stack w="100%" flexDirection={isMinXL ? 'column' : 'row'} justifyContent="space-between">
            <Stack direction="row" justifyContent="space-between" align="center" m="0">
              <BaselaneButton
                variant="outline"
                palette="neutral"
                size="md"
                onClick={() => {
                  onClose();
                  handleCloseAllDrawers();
                }}
              >
                Close
              </BaselaneButton>

              <BaselaneButton
                variant="filled"
                palette="primary"
                size="md"
                onClick={() => {
                  onClose();
                  onCloseTransferConfirmationDrawer();
                  onCloseReviewTransferDrawer();
                  cleanUp();
                }}
              >
                Add More Funds
              </BaselaneButton>
            </Stack>
          </Stack>
        </DrawerFooter>
      </BaselaneDrawer>
    </>
  );
};

export default BankTransferDrawer;
