/* eslint-disable react/prop-types */
import React from 'react';
import { Text, Tbody, Box, Thead } from '@chakra-ui/react';
import {
  BaselaneCardStack,
  BaselaneResponsiveCell,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveCellTitle,
} from '@shared/components';
import { formatDate } from '@core/utils/formatDate';
import ReportHeader from '../../components/ReportHeader';
import { isFieldNotObject } from '../../helpers/tenantScreening.helpers';

const HistoricalConsumerInformation = ({ historicalConsumerInformation = [] }) => {
  const tableColumnConfig = {
    columns: [
      {
        isSortable: false,
        styles: {
          w: { base: '100%', sm: '320px' },
          overflow: 'visible',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
      {
        isSortable: false,
        styles: {
          w: '100%',
          overflow: 'visible',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
    ],
  };

  if (!historicalConsumerInformation.length) {
    return (
      <BaselaneCardStack
        id="no-historical-consumer-information-data-card"
        size="md"
        width="100%"
        items={[
          {
            id: 'header',
            content: <ReportHeader>Historical consumer information</ReportHeader>,
          },
          {
            id: 'no-historical-consumer-information',
            content: <Text textStyle="sm">No data available</Text>,
          },
        ]}
      />
    );
  }

  return (
    <BaselaneCardStack
      id="historical-consumer-information-card"
      key="historical-consumer-information-card"
      size="md"
      items={[
        {
          id: 'header',
          content: <ReportHeader>Historical consumer information</ReportHeader>,
        },
        {
          id: 'historical-consumer-information-card-body',
          content: (
            <BaselaneResponsiveTable
              id="review-columns"
              config={tableColumnConfig}
              maxW="600px"
              tableContainerStyles={{
                overflowY: 'initial',
                overflowX: 'initial',
              }}
            >
              <Thead>
                <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                  <BaselaneResponsiveCellTitle
                    title="Address reported"
                    titleSize="headline-sm"
                    styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                    p={0}
                    configIndex={0}
                    colSpan="4"
                  />
                </BaselaneResponsiveTableRow>
              </Thead>
              <Tbody>
                <BaselaneResponsiveTableRows
                  items={historicalConsumerInformation
                    .sort(
                      (a, b) => new Date(b.date_first_reported) - new Date(a.date_first_reported)
                    )
                    .map((information) => ({
                      heading: (
                        <Box>
                          <Text textStyle="headline-md">
                            {information.address1}, {information.city}, {information.state}{' '}
                            {information.zip}
                          </Text>
                          <Text textStyle="sm" color="brand.neutral.600">
                            Reported on: {formatDate(information.date_first_reported, 'YYYY-MM-DD')}
                          </Text>
                        </Box>
                      ),
                      data: information.type
                        ? information.type.charAt(0).toUpperCase() + information.type.slice(1) // Capitalize first letter.
                        : '--',
                    }))}
                  customRow
                  renderItem={(row) => {
                    return (
                      <BaselaneResponsiveTableRow
                        key={row.heading}
                        chevronCellStyles={{ w: 0 }}
                        contentContainerStyles={{ border: 'none' }}
                      >
                        <BaselaneResponsiveCell configIndex={0} p={0} colSpan="2">
                          <Text as="span" textStyle="sm" color="brand.neutral.700">
                            {row.heading}
                          </Text>
                        </BaselaneResponsiveCell>
                        <BaselaneResponsiveCell configIndex={1} p={0}>
                          <Text textStyle="sm">{isFieldNotObject(row.data) ? row.data : '--'}</Text>
                        </BaselaneResponsiveCell>
                      </BaselaneResponsiveTableRow>
                    );
                  }}
                />
              </Tbody>
            </BaselaneResponsiveTable>
          ),
        },
      ]}
    />
  );
};

export default HistoricalConsumerInformation;
