import React from 'react';
import { Stack } from '@chakra-ui/react';

import { BaselaneButton, BaselaneDrawer } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import AddAccountTrigger from '@pages/BankEntity/AddAccount/AddAccountTrigger';
import AccountCard from './AccountCard';

type MobileAccountsFilterProps = {
  selectedIndex: Number,
  mobileCardsData: Array<Object>,
  emblaApi: Object,
  setHideAllDots: Function,
  showFilterPopup: Boolean,
  setShowFilterPopup: Function,
};

const MobileAccountsFilter = ({
  selectedIndex,
  mobileCardsData,
  emblaApi,
  setHideAllDots,
  showFilterPopup,
  setShowFilterPopup,
}: MobileAccountsFilterProps) => {
  const { isMax767 } = useBreakPoints();

  const handleFilterClose = () => {
    setShowFilterPopup(false);
  };

  const handleCardClick = (index) => {
    handleFilterClose();
    setTimeout(() => {
      emblaApi.scrollTo(index);
    }, 150);
  };

  return (
    <BaselaneDrawer
      newDesignDrawer
      isOpen={showFilterPopup}
      title="Accounts"
      size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
      onClose={() => {
        handleFilterClose();
        setHideAllDots(false);
      }}
    >
      {selectedIndex < mobileCardsData.length && selectedIndex !== 0 && (
        <BaselaneButton
          variant="transparent"
          palette="primary"
          size="md"
          onClick={() => {
            handleFilterClose();
            setTimeout(() => {
              emblaApi.scrollTo(0);
            }, 150);
          }}
        >
          Clear selection
        </BaselaneButton>
      )}
      <Stack gap="12px" mt="12px">
        {mobileCardsData?.map((c, index) =>
          c?.isSummaryCard ? null : (
            <AccountCard key={c.id} account={c} onCustomClick={() => handleCardClick(index)} />
          )
        )}
        <AddAccountTrigger type="card" />
      </Stack>
    </BaselaneDrawer>
  );
};

export default MobileAccountsFilter;
