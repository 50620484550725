import React from 'react';
import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { formatDate } from '@core/utils/formatDate';
import { IconCalendar, IconChevronRight, IconExclamationCircle } from '@icons';
import { BaselaneCardNew, BaselaneHighlightedDataDisplay } from '@shared/components';
import formatCurrency from '@core/utils/formatCurrency';
import LeaseToLeaseAgreement from '@pages/LeaseAgreement/components/LeaseToLeaseAgreement';
import LeaseSection from './LeaseSection';
import {
  SHORT_DUE_DATE_OPTIONS,
  getFormInitialValues,
  wordifyDate,
} from '../../formHelpers/leaseTermForm.helper';
import { getDepositAndFeesDataFormatted } from '../../formHelpers/depositsFeesReview.helper';
import {
  leaseSectionHeader,
  leaseTermsContainer,
  leaseTermsLargeText,
  leaseSmallText,
  leaseSmallTextBold,
  verticalDashedLine,
  stepCircle,
} from '../../../LeaseResponsiveDetail/styles/leaseResponsiveDetail.styles';

type LeaseTermsPops = {
  lease: Object,
  setTabIndex: Function,
  leaseCreated: boolean,
  id: string,
  viewMode: boolean,
  isInactive: boolean,
  hasTitle: boolean,
};

function LeaseTerms({
  lease,
  setTabIndex,
  leaseCreated,
  id,
  viewMode,
  isInactive,
  hasTitle = true,
}: LeaseTermsPops) {
  const [isDesktop] = useMediaQuery('(min-width: 576px)', { ssr: false });
  const [isSmallTablet] = useMediaQuery('(min-width: 576px)', { ssr: false });
  // TODO: get rid of unit!!!
  const [formInitialValues] = getFormInitialValues({ lease });
  const {
    monthlyRentAmount,
    dueDays,
    startDate,
    rentCollectionStartDate,
    endDate,
    isMonthToMonth,
    hasLateFee,
    initialFeeType,
    initialFeeAmount,
    initialFeePercent,
    initialDueDate,
    incrementAmountType,
    incrementAmount,
    incrementPercent,
    initialFeeEnabled,
    feeCapType,
    feeCapAmount,
    feeCapPercent,
  } = formInitialValues;

  const shortDueDay = SHORT_DUE_DATE_OPTIONS.find((option) => option.id === dueDays)?.label;
  const rDueDay = `${shortDueDay} of every month`;
  const sDate = formatDate(startDate);
  const rsDate = formatDate(rentCollectionStartDate);
  const eDate = isMonthToMonth ? 'None, Month-to-month' : formatDate(endDate);

  const initialFee =
    initialFeeType === 'FLAT'
      ? formatCurrency(initialFeeAmount).inDollars
      : `${initialFeePercent}% rent`;

  const dailyValue = incrementAmountType === 'FLAT' ? incrementAmount : incrementPercent;

  const monthlyFee =
    feeCapType === 'FLAT' ? formatCurrency(feeCapAmount).inDollars : `${feeCapPercent}% rent`;

  const createDailyFeeText = () => {
    if (!dailyValue) {
      return `after ${initialDueDate} days`;
    }
    if (incrementAmountType === 'FLAT') {
      return (
        <span>
          + <b>${dailyValue}</b>/day
        </span>
      );
    }
    return (
      <span>
        + <b>{dailyValue}%</b> daily
      </span>
    );
  };

  // Getting the fees TODO: GET RID OF UNIT!!!
  const { fees } = getDepositAndFeesDataFormatted(lease);

  const recurringMonthlyFees = fees.filter(
    (fee) => fee.frequency.toLowerCase() === 'recurring monthly'
  );

  const title = 'Rent collection terms';

  const leaseTermsSummaryConfig = () => {
    const configuration = {
      items: [
        {
          id: 'start-date-review',
          key: (
            <Flex alignItems="center">
              <Text mr="8px">Lease start date</Text>
            </Flex>
          ),
          value: sDate,
        },
        {
          id: 'end-date-review',
          key: (
            <Flex alignItems="center">
              <Text mr="8px">Lease end date</Text>
            </Flex>
          ),
          value: eDate,
        },
        {
          id: 'rent-amount-review',
          key: 'Monthly rent amount',
          value: monthlyRentAmount,
        },
        {
          id: 'rent-due-on-review',
          key: 'Rent is due on',
          value: rDueDay,
        },
        {
          id: 'recurring-rent-start-review',
          key: 'Recurring rent starts on',
          value: rsDate,
        },
      ],
    };

    return configuration;
  };

  const mobileLeaseTermsSummaryConfig = () => {
    const configuration = {
      items: [
        {
          id: 'start-date-review',
          key: (
            <Flex alignItems="center">
              <Text mr="8px">Lease start date</Text>
            </Flex>
          ),
          value: sDate,
        },
        {
          id: 'end-date-review',
          key: (
            <Flex alignItems="center">
              <Text mr="8px">Lease end date</Text>
            </Flex>
          ),
          value: eDate,
        },
        {
          id: 'rent-amount-review',
          key: 'Monthly rent amount',
          value: monthlyRentAmount,
        },
        {
          id: 'rent-due-on-review',
          key: 'Rent due date',
          value: rDueDay,
        },
        {
          id: 'recurring-rent-start-review',
          key: 'Recurring rent starts on',
          value: rsDate,
        },
      ],
    };

    return configuration;
  };

  const leaseTermsSummarySection = (
    <BaselaneHighlightedDataDisplay
      configuration={!isDesktop ? mobileLeaseTermsSummaryConfig() : leaseTermsSummaryConfig()}
    />
  );

  const recurringMonthlyFeesConfig = {
    title: 'Recurring monthly fees with rent',
    items: recurringMonthlyFees.map((fee) => {
      const { amount, description } = fee;
      return {
        key: description,
        value: amount,
      };
    }),
  };

  const mobileRecurringMonthlyFeesConfig = {
    items: recurringMonthlyFees.map((fee) => {
      const { amount, description } = fee;
      return {
        items: [
          {
            key: description,
            value: amount,
          },
        ],
      };
    }),
  };

  const recurringMonthlyFeesSection = (
    <BaselaneHighlightedDataDisplay
      configuration={!isDesktop ? mobileRecurringMonthlyFeesConfig : recurringMonthlyFeesConfig}
    />
  );

  const lateFeeConfig = {
    title: 'Assess late fee',
    items: [
      {
        key: 'Number of days after rent is due',
        value: initialDueDate,
      },
      {
        key: 'Initial late fee',
        value: initialFee,
      },
      {
        key: 'Daily late fee',
        value: dailyValue ? createDailyFeeText() : '-',
      },
      {
        key: 'Monthly maximum',
        value: monthlyFee !== '$0.00' ? monthlyFee : '-',
      },
    ],
  };

  const mobileLateFeeConfig = {
    items: [
      {
        key: 'Initial late fee',
        value: initialFee,
      },
      {
        key: 'Late fee due date',
        value: initialDueDate,
      },
      {
        key: 'Daily late fee',
        value: dailyValue ? createDailyFeeText() : '-',
      },
      {
        key: 'Monthly maximum',
        value: monthlyFee !== '$0.00' ? monthlyFee : '-',
      },
    ],
  };

  const lateFeeSection = (
    <BaselaneHighlightedDataDisplay
      configuration={!isDesktop ? mobileLateFeeConfig : lateFeeConfig}
    />
  );

  let rightSideWidth = isSmallTablet ? '50%' : '100%';
  if (isInactive) rightSideWidth = 'none';

  return viewMode ? (
    <>
      {hasTitle && (
        <Text {...leaseSectionHeader} mt="12px">
          {title}
        </Text>
      )}
      <BaselaneCardNew
        variant={isInactive ? 'normal' : 'clickable'}
        id="rent-collection-terms-details-button"
        {...leaseTermsContainer(isInactive)}
        onClick={() => !isInactive && setTabIndex(1)}
      >
        <Flex w="100%" direction="row" justifyContent="space-between">
          <Flex
            w="100%"
            direction={isSmallTablet ? 'row' : 'column'}
            justifyContent="space-between"
          >
            <Box w={isSmallTablet && !isInactive ? '50%' : '100%'}>
              <Flex>
                <Box {...stepCircle(true)} />
                <Text {...leaseTermsLargeText}>{wordifyDate(startDate)}</Text>
              </Flex>
              <Box {...verticalDashedLine} />
              <Flex position="relative" top="-22px" mb="-16px">
                <Box {...stepCircle(true)} />
                <Text {...leaseTermsLargeText}>
                  {isMonthToMonth ? 'No end date' : wordifyDate(endDate)}
                </Text>
              </Flex>
              <Text {...leaseSmallText} ml="4px">
                First monthly rent payment on {wordifyDate(rsDate)}
              </Text>
            </Box>
            <Box w={rightSideWidth} mt={isSmallTablet ? '0px' : '16px'} whiteSpace="nowrap">
              <Flex mb="4px" alignItems="center">
                <Text {...leaseTermsLargeText}>{monthlyRentAmount}</Text>
                <Text {...leaseSmallTextBold}>/month</Text>
              </Flex>
              <Flex mt="8px" alignItems="center">
                <Box>
                  <IconCalendar color="#6C7884" />
                </Box>
                <Flex ml="8px">
                  <Text {...leaseSmallText} mr="4px">
                    Due on
                  </Text>
                  <Text {...leaseSmallTextBold}>{shortDueDay}</Text>
                  <Text {...leaseSmallText} ml="4px">
                    of every month
                  </Text>
                </Flex>
              </Flex>
              <Flex mt="6px" alignItems="center">
                <Box m="0 2px 0 1px">
                  <IconExclamationCircle color="#6C7884" w={13.33} h={13.33} />
                </Box>
                <Box {...leaseSmallText} ml="8px">
                  {initialFeeEnabled && (initialFee || dailyValue) ? (
                    <Flex>
                      <Text {...leaseSmallText} mr="4px">
                        Late fee:
                      </Text>
                      <Text {...leaseSmallTextBold}>{initialFee}</Text>
                      <Text {...leaseSmallText} ml="4px">
                        {createDailyFeeText()}
                      </Text>
                    </Flex>
                  ) : (
                    'No late fee'
                  )}
                </Box>
              </Flex>
            </Box>
          </Flex>
          {!isInactive && (
            <Flex direction="column" justifyContent="center">
              <IconChevronRight color="#257ED0" />
            </Flex>
          )}
        </Flex>
      </BaselaneCardNew>
      <LeaseToLeaseAgreement lease={lease} />
    </>
  ) : (
    <LeaseSection {...{ id, title, setTabIndex, leaseCreated }}>
      {leaseTermsSummarySection}
      {!!recurringMonthlyFees.length && recurringMonthlyFeesSection}
      {hasLateFee && lateFeeSection}
    </LeaseSection>
  );
}

export default LeaseTerms;
