// @flow
import React, { useRef, useContext, useState } from 'react';
import { Formik } from 'formik';
import {
  ChakraProvider,
  Text,
  HStack,
  Box,
  Spacer,
  FormControl,
  useDisclosure,
} from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { useNavigate } from 'react-router-dom';
import { USER_WORKSPACE } from '@routes';
import useBreakPoints from '@core/hooks/useBreakPoints';
import habitatTheme from '@core/themeHabitat';
import { EmailOtpProvider } from '@core/contexts/EmailOtpContext';
import {
  BaselaneButton,
  BaselaneDrawer,
  BaselaneFormLabel,
  BaselaneInput,
  BaselaneFormHelperText,
  BaselaneFormErrorMessage,
  TwoFactorVerificationPopUp,
  BaselaneAlert,
  AlertHeader,
  AlertFooter,
  BaselaneBannerNew,
} from '@shared/components';
import useTwoFactor from '@shared/components/TwoFactorVerificationPopUp/hooks/useTwoFactor';
import { formatNumbers } from '@shared/components/BaselaneKYCForm/helpers/kycForm.helpers';
import { phoneMaskWithCountryCode } from '@core/utils/masks';
import UserContext from '@contexts/UserContext';
import { Icon16CheckCircle } from '@icons/16px';
import { IllustrationOtherWarning } from '@illustrations';
import InviteCollaboratorAlert from './InviteCollaboratorAlert';

type OwnerProfilePhoneDrawerProps = { from: String };
const BannerIcon = () => <IllustrationOtherWarning />;

function OwnerProfilePhoneDrawer({ from }: OwnerProfilePhoneDrawerProps): any {
  const unsavedChangesAlertRef = useRef();
  const {
    isOpen: isUnsavedChangesAlertOpen = true,
    onOpen: onUnsavedChangesAlertOnOpen,
    onClose: onUnsavedChangesAlertClose,
  } = useDisclosure();
  const {
    isOpen: isInviteAlertOpen = true,
    onOpen: onInviteAlertOnOpen,
    onClose: onInviteAlertClose,
  } = useDisclosure();
  const { refetchUser, user } = useContext(UserContext);
  const { isMax576 } = useBreakPoints();
  const navigate = useNavigate();
  const formikRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
  const [phoneNumberIsNotVoip, setPhoneNumberIsNotVoip] = useState(false);
  const [customPhoneError, setCustomPhoneError] = useState(null);
  const [submittedPhoneNumber, setSubmittedPhoneNumber] = useState(null);

  const initialFormValues = { phone: '' };

  const handleSendTextSuccess = () => {
    setOTPErrorCode(false);
    onOTPPopupOpen();
    setPhoneNumberIsNotVoip(true);
  };

  const handleSendTextFail = (error) => {
    setCustomPhoneError(error);
  };

  const handleVerifyOtpSuccess = () => {
    setPhoneNumberVerified(true);
    refetchUser().then(() => {
      onOTPPopupClose();
    });
  };

  const handleValidation = (values) => {
    const { phone } = values;
    const errors = {};

    const filterPhoneNumber = phone?.replace(/[^0-9]/g, '');

    if (filterPhoneNumber === '' || filterPhoneNumber?.length < 11) {
      errors.phone = 'Please enter phone number';
      setOTPErrorCode(false);
    } else if (OTPErrorCode === 400) {
      errors.phone = customPhoneError;
    }

    return errors;
  };

  const { states, stateFunctions } = useTwoFactor(true);
  const { OTPErrorCode } = states;
  const {
    onOTPPopupOpen,
    setOTPErrorCode,
    handleSendText,
    handleVerifyOtp,
    onOTPPopupClose,
  } = stateFunctions;

  const twoFactorVerificationProps = {
    ...states,
    ...stateFunctions,
    getOTP: () => handleSendText(submittedPhoneNumber, true, handleSendTextSuccess),
    phoneNumber: formikRef?.current?.values.phone,
    handleVerifyOnClick: (otpCode) =>
      handleVerifyOtp({ recipient: submittedPhoneNumber, code: otpCode }, handleVerifyOtpSuccess),
  };

  const handleFormSubmit = (values) => {
    const formattedPhoneNumber = values?.phone
      ? formatNumbers(values?.phone).numberWithoutSpaces
      : submittedPhoneNumber;
    setSubmittedPhoneNumber(formattedPhoneNumber);

    if (phoneNumberVerified && phoneNumberIsNotVoip) {
      setIsLoading(true);
      refetchUser().then(() => {
        setIsLoading(false);
        onOTPPopupClose();
      });
    } else {
      handleSendText(formattedPhoneNumber, false, handleSendTextSuccess, handleSendTextFail);
    }
  };

  const handleCloseWithoutSaving = (touched, values, initialValues) => {
    const isUpdated = JSON.stringify(values) !== JSON.stringify(initialValues);
    const isFormTouched = Object.keys(touched).length > 0;
    if (isFormTouched && isUpdated && !user?.phoneNumberVerified) {
      onUnsavedChangesAlertOnOpen();
    } else {
      handleDrawerClose();
    }
  };
  const handleDrawerClose = () => {
    navigate(USER_WORKSPACE);
  };

  return (
    <ChakraProvider theme={habitatTheme}>
      <EmailOtpProvider>
        <TwoFactorVerificationPopUp {...twoFactorVerificationProps} />

        <Formik
          innerRef={formikRef}
          validateOnChange
          validateOnBlur
          initialValues={initialFormValues}
          validate={handleValidation}
        >
          {(formikProps) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              isValid,
              handleBlur,
              initialValues,
            } = formikProps;

            return (
              <BaselaneDrawer
                isOpen
                size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
                title="Add your profile details"
                closeEvent={() => handleCloseWithoutSaving(touched, values, initialValues)}
                onOverlayClick={() => handleCloseWithoutSaving(touched, values, initialValues)}
                closeOnOverlayClick={false}
                hideOverlay
                newDesignDrawer
                footer={
                  <BaselaneButton
                    size="md"
                    variant="filled"
                    palette="primary"
                    width="100%"
                    ml={1.5}
                    flex={1}
                    onClick={() => {
                      onInviteAlertOnOpen();
                    }}
                    isDisabled={!user?.phoneNumberVerified}
                  >
                    Done
                  </BaselaneButton>
                }
              >
                <HStack
                  w="100%"
                  position="absolute"
                  left="0"
                  width="100%"
                  top={!isMax576 ? '59px' : '56px'}
                >
                  <BaselaneBannerNew
                    hasBannerIcon={!isMax576}
                    hasActionButton={false}
                    body="Before inviting a collaborator, we need a few details on your profile."
                    hasCloseButton={false}
                    title="Your profile details required"
                    variant="warning-medium"
                    bannerIconName={BannerIcon}
                  />
                </HStack>
                <Box paddingTop="126px" mb={3}>
                  <HStack mb={0.5}>
                    <Text textStyle="headline-md" color="brand.neutral.900">
                      Verify your phone number
                    </Text>
                    <Spacer />
                    <Text textStyle="xs" color="brand.neutral.600">
                      Step 3 of 3
                    </Text>
                  </HStack>
                </Box>
                {user?.phoneNumberVerified ? (
                  <HStack gap={1} color="green.700">
                    <Icon16CheckCircle />
                    <Text textStyle="sm" color="green.700">
                      Phone number verified
                    </Text>
                  </HStack>
                ) : (
                  <>
                    <Text textStyle="headline-sm" color="brand.neutral.900" mb={1}>
                      Enter your mobile phone number
                    </Text>
                    <Text textStyle="sm" color="brand.neutral.700" mb={2}>
                      This number will be used to verify your Baselane logins.
                    </Text>
                    <FormControl
                      isInvalid={(errors.phone && touched.phone) || customPhoneError}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleFormSubmit();
                        }
                      }}
                    >
                      <BaselaneFormLabel htmlFor="phone">
                        Mobile phone (no <abbr title="Voice Over Internet Protocol">VOIP</abbr>)
                      </BaselaneFormLabel>
                      <BaselaneInput
                        as={MaskedInput}
                        mask={phoneMaskWithCountryCode('1')}
                        id="phone"
                        name="phone"
                        value={values.phone}
                        onChange={(event) => {
                          if (customPhoneError) {
                            setCustomPhoneError(null);
                          }
                          handleChange(event);
                        }}
                        onBlur={handleBlur}
                        placeholder="+1 (***) *** ****"
                        pattern="\d*"
                        inputMode="decimal"
                        autoFocus
                      />
                      <BaselaneFormErrorMessage
                        isInvalid={(errors.phone && touched.phone) || customPhoneError}
                        mt={0.75}
                      >
                        <Text textStyle="xs">{errors.phone || customPhoneError}</Text>
                      </BaselaneFormErrorMessage>
                      <BaselaneFormHelperText mb={0}>
                        We&apos;ll send you a verification code.
                      </BaselaneFormHelperText>
                    </FormControl>
                    <BaselaneButton
                      id="send-code-button"
                      variant="filled"
                      palette="primary"
                      size="md"
                      onClick={() => handleFormSubmit(values)}
                      isDisabled={!isValid}
                      isLoading={isLoading}
                    >
                      Send code
                    </BaselaneButton>
                  </>
                )}
              </BaselaneDrawer>
            );
          }}
        </Formik>
      </EmailOtpProvider>
      {isInviteAlertOpen && (
        <InviteCollaboratorAlert onClose={onInviteAlertClose} isOpen={isInviteAlertOpen} />
      )}
      <BaselaneAlert
        leastDestructiveRef={unsavedChangesAlertRef}
        isOpen={isUnsavedChangesAlertOpen}
        onClose={onUnsavedChangesAlertOnOpen}
        header={<AlertHeader title="You Have Unsaved Changes" />}
        body="Are you sure you want to exit without saving?"
        footer={
          <AlertFooter
            leftButtonEvent={onUnsavedChangesAlertClose}
            rightButtonEvent={handleDrawerClose}
          />
        }
      />
    </ChakraProvider>
  );
}

export default OwnerProfilePhoneDrawer;
