import React from 'react';
import { Box, Flex, Skeleton, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import {
  BaselaneChip,
  BaselaneSummaryCard,
  BaselaneSummaryCardAmountHeader,
} from '@shared/components';
import { dateFormatter } from '@core/utils/formatDate';

const Account = ({ account }) => {
  const { accountName, nickName } = account;

  const nickNameElement = nickName ? (
    <Text textStyle="xs" color="brand.neutral.600">
      {nickName}
    </Text>
  ) : null;

  return (
    <Flex direction="column" alignItems="flex-end">
      <Text textStyle="sm">{accountName}</Text>
      {nickNameElement}
    </Flex>
  );
};

Account.propTypes = {
  account: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
    nickName: PropTypes.string,
  }).isRequired,
};

const Name = ({ merchantName, name = null }) => {
  const merchantNameElement = <Text textStyle="md-heavy">{merchantName || name}</Text>;

  const nameElement =
    merchantName && merchantName !== name ? (
      <Text textStyle="xs" color="brand.neutral.600">
        {name}
      </Text>
    ) : null;

  return (
    <Box gap={0}>
      {merchantNameElement}
      {nameElement}
    </Box>
  );
};

Name.propTypes = {
  merchantName: PropTypes.string.isRequired,
  name: PropTypes.string,
};

const PreviewTransaction = ({ transaction, account = null, isLoading = false }) => {
  const header = (
    <BaselaneSummaryCardAmountHeader
      title={
        <Skeleton isLoaded={!isLoading}>
          <Flex gap={1} alignItems="center">
            <Name merchantName={transaction?.merchantName} name={transaction?.name} />
            {transaction?.pending ? (
              <BaselaneChip size="sm" label="Pending" variant="status-primary" />
            ) : null}
          </Flex>
        </Skeleton>
      }
      amount={transaction?.amount}
      isLoading={isLoading}
    />
  );

  const list = [
    {
      items: [
        {
          label: ' Account',
          value: account ? (
            <Skeleton isLoaded={!isLoading}>
              <Account account={account} />
            </Skeleton>
          ) : (
            <Text textStyle="sm">Manually Added</Text>
          ),
        },
        {
          label: 'Date',
          value: (
            <Skeleton isLoaded={!isLoading}>
              <Text textStyle="sm">{dateFormatter(transaction?.date, 'MMM dd, yyyy')}</Text>
            </Skeleton>
          ),
        },
      ],
    },
  ];

  return <BaselaneSummaryCard isCustom header={header} list={list} />;
};

PreviewTransaction.propTypes = {
  transaction: PropTypes.shape({
    merchantName: PropTypes.string,
    name: PropTypes.string,
    amount: PropTypes.number,
    date: PropTypes.string,
    pending: PropTypes.bool,
  }).isRequired,
  account: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
    nickName: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
};

export default PreviewTransaction;
