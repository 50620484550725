import React from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { Field } from 'formik';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import BaselaneFormErrorMessage from '@core/components/Shared/components/BaselaneForm/BaselaneFormErrorMessage';
import { BaselaneInput } from '@shared/components';
import useAmountValidation from './useAmountValidation';

const Amount = () => {
  const { validateAmount, handleAmountChange } = useAmountValidation();
  const currencyMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
    allowDecimal: true,
  });

  return (
    <Field name="amount" validate={validateAmount}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors.amount && form.touched.amount} isRequired mt={3}>
          <FormLabel>Amount</FormLabel>
          <BaselaneInput
            {...field}
            as={MaskedInput}
            placeholder=""
            prefix="$"
            size="lg"
            mask={currencyMask}
            onChange={(e) => {
              handleAmountChange(e);
              field.onChange(e);
            }}
            onBlur={(e) => {
              field.onBlur(e);
              validateAmount(e.target.value);
            }}
          />
          <BaselaneFormErrorMessage>{form.errors.amount}</BaselaneFormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default Amount;
