import React from 'react';

type IconLimitedBankingAccessProps = { w?: Number, h?: Number, color?: string };

function IconLimitedBankingAccess({ w, h, color }: IconLimitedBankingAccessProps) {
  return (
    <svg width="34" height="34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.21 27H3.61c-.568 0-.852 0-1.087-.057a2 2 0 0 1-1.467-1.467C1 25.24 1 24.957 1 24.389v0-1.143 0c0-.229 0-.343.01-.439a2 2 0 0 1 1.797-1.798c.096-.009.21-.009.439-.009H15.5M4 10.024v10.81m6-10.81V21m7-10.976V18.5m6-8.476v5.488m.428-12.526-9.3-1.86a4.155 4.155 0 0 0-.47-.08 2 2 0 0 0-.316 0c-.118.01-.235.033-.47.08l-9.3 1.86c-.916.183-1.374.274-1.717.514a2 2 0 0 0-.688.839C1 4.722 1 5.189 1 6.123v.7c0 1.12 0 1.68.218 2.109a2 2 0 0 0 .874.874c.428.218.988.218 2.108.218h18.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C26 8.504 26 7.944 26 6.824v-.7c0-.935 0-1.402-.167-1.785a2 2 0 0 0-.688-.84c-.343-.239-.801-.33-1.717-.513Z"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 20a4 4 0 0 0-4 4h-1a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2h-1a4 4 0 0 0-4-4Z"
        fill="#8CCFFF"
      />
      <path
        d="M18 24a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H20a2 2 0 0 1-2-2v-6Z"
        stroke="#000619"
      />
      <path d="M25 28v-2" stroke="#000619" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21 22a4 4 0 0 1 8 0h-8Z" stroke="#000619" />
    </svg>
  );
}

IconLimitedBankingAccess.defaultProps = {
  w: 18,
  h: 18,
  color: 'currentColor',
};

export default IconLimitedBankingAccess;
