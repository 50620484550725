// @flow
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, sortBy } from 'lodash';
import { ChakraProvider, Spinner, useDisclosure, useToast } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import onDrawerClose from '@core/utils/onDrawerClose';
import habitatTheme from '@core/themeHabitat';
import {
  GET_BANK_ACCOUNTS_ACTIVE,
  GET_TRANSFERS,
  GET_UPDATE_PLAID_LINK_TOKEN,
  CREATE_TRANSFER,
} from '@core/apollo/queries';
import { NATIVE_BANK } from '@routes';
import TransactionContext from '@contexts/TransactionContext';
import BanksContext from '@contexts/BanksContext';
import {
  RESYNC_BANK_ACCOUNT_BALANCE,
  GET_BANK_SUMMARY,
} from '@core/components/NativeBankingPage/queries';
import { getOptionsWithSubCategories } from '@core/components/CashFlowPage/helpers/cashflow.helpers';
import {
  buildPresentationProperties,
  createPropertyIdsMap,
} from '@core/components/Transactions/helpers/propertyInput.helper';
import { getPropertyData } from '@shared/helpers/propertiesFilter.helpers';

import EducationalDrawer from './components/EducationalDrawer';
import BaselaneDrawer from '../BaselaneDrawer';
import UnsavedChangesAlert from '../UnsavedChangesAlert';
import AddAccountManuallyDrawer from '../AddAccountManuallyDrawer';
import Body from './components/Body';
import Footer from './components/Footer';
import { methods } from './components/EducationalDrawer/helpers/button.helper';
import { initialValues, getDropdownList, getSplitPropertyUnitId } from './helpers';
import { handleValidation } from './validation';
import { spinnerStyles } from './styles';

type TransferFundsProps = { from?: string };
/**
 * @deprecated Use TransferFundsDrawer instead.
 *
 * Still keeping it because some components, styles and helpers are used by other components.
 */
const TransferFunds = ({ from }: TransferFundsProps) => {
  const { isMax576 } = useBreakPoints();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search, state } = location ?? {};
  let delayedRefetchTimeout;

  const handleCancel = () => {
    onDrawerClose(navigate, from);
  };

  useEffect(() => {
    return () => clearTimeout(delayedRefetchTimeout);
  }, []);

  const formikRef = useRef();
  const emptyStateEducationalDrawerRef = useRef(null);
  const addAccountManuallyDrawerRef = useRef(null);

  const handleEducationalDrawerClose = () => emptyStateEducationalDrawerRef?.current?.close();
  const handleEducationalDrawerOpen = () => emptyStateEducationalDrawerRef?.current?.open();

  // Unsaved Alert State
  const { isOpen: isDrawerAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  // Every transaction needs a unique idempotency key. This is used to prevent duplicate transactions.
  // It changes every time a transaction finishes.
  const [xIdempotencyKey, setXIdempotencyKey] = useState(uuidv4());
  const [transferType, setTransferType] = useState();
  const [unsplitTagId, setUnsplitTagId] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 4;

  const initialFormValues = {
    ...initialValues,
    toTransferAccountId: state?.toTransferAccountId || initialValues.toTransferAccountId,
  };

  const { loading, error, data: bankAccountsActiveData, refetch } = useQuery(
    GET_BANK_ACCOUNTS_ACTIVE,
    {
      variables: {
        isConnectedAccount: true,
        accountStatus: 'Open',
      },
      fetchPolicy: 'no-cache',
      onError: (err) => console.error(err),
    }
  );

  const [createTransfer] = useMutation(CREATE_TRANSFER, {
    refetchQueries: [GET_BANK_SUMMARY, GET_BANK_ACCOUNTS_ACTIVE],
  });

  // resyncs the available balance of the account specified
  const [resyncBankAccountBalance] = useMutation(RESYNC_BANK_ACCOUNT_BALANCE, {
    onCompleted: (res) => {
      if (res && res?.resyncBankAccountBalance && !res?.errors) {
        refetch().then(() => formikRef.current?.validateForm());
      }
    },
    onError: (err) => console.error(err),
  });

  const [
    getUpdateLinkToken,
    { data: updateLinkTokenData, loading: isUpdateLinkTokenLoading },
  ] = useLazyQuery(GET_UPDATE_PLAID_LINK_TOKEN, {
    onCompleted: () => formikRef.current?.validateForm(),
  });

  const {
    propertiesData,
    propertyIdsMap,
    setPropertyIdsMap,
    categoryWithSubOptions,
    categoryMap,
    categoryIdsMap,
    startPollingTransactionsSummary,
    stopPollingTransactionsSummary,
  } = useContext(TransactionContext);
  const { loading: bankLoading, banks, refetchBankSummary } = useContext(BanksContext);

  // Toast
  const toast = useToast();
  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Something went wrong. Please try again',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const propertyOptions = getPropertyData(propertiesData);
  const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);

  const accounts = bankAccountsActiveData?.bankAccounts?.filter((account) =>
    ['savings', 'checking'].includes(account.accountSubType)
  );

  const { fromDropdownList, toDropdownList, accountsMap } =
    (accounts && getDropdownList(accounts)) ?? {};

  const handleNextStep = () => setCurrentStep(currentStep + 1);

  const handlePreviousStep = () => setCurrentStep(currentStep - 1);

  const handleMakeAnotherTransfer = () => {
    setXIdempotencyKey(uuidv4());
    setCurrentStep(1);
  };

  const handleDrawerClose = ({ dirty = false, resetForm }) => {
    if (dirty) {
      onAlertOpen();
    } else {
      if (pathname.includes(NATIVE_BANK)) {
        handleCancel();
        resetForm();
        setCurrentStep(1);
      } else {
        const previousRoute = pathname.slice(0, pathname.lastIndexOf('/'));
        navigate({ pathname: previousRoute, search });
      }
      setXIdempotencyKey(uuidv4());
    }
  };

  const handleAlertContinue = ({ resetForm }) => {
    onAlertClose();
    handleDrawerClose({ resetForm });
  };

  const handleSubmit = (values, { resetForm }) => {
    const { propertyId, unitId } = getSplitPropertyUnitId(values.propertyId);

    const input = {
      ...values,
      amount: parseFloat(values.amount?.replace(/,/g, '')), // It comes as string, that may have commas, so we need to convert it to float.
      fromTransferAccountId: accountsMap[values.fromTransferAccountId].transferAccountId,
      toTransferAccountId: accountsMap[values.toTransferAccountId].transferAccountId,
      transferDate: moment(new Date()).format('YYYY-MM-DD'),
      propertyId,
      unitId,
      bookKeepingNote: values.note,
    };

    createTransfer({
      context: {
        headers: {
          'x-idempotency-key': xIdempotencyKey,
        },
      },
      variables: {
        input,
      },
      update: (cache, { data }) => {
        const { createTransfer: updatedTransfer, errors } = data ?? {};
        const rootObject = cache?.data?.data?.ROOT_QUERY;
        const findTransferProperty = Object.keys(rootObject).filter((i) => i.includes('transfers'));

        const variablesArray = findTransferProperty?.map((v) => {
          const firstParenthesis = (v || '').indexOf('(') + 1;
          const lastParenthesis = (v || '').length - 1;
          return JSON.parse(v?.substring(firstParenthesis, lastParenthesis));
        });

        const defaultVariables = {
          input: {
            page: 1,
            pageLimit: 100,
            sort: { direction: 'DESC', field: 'transferDate' },
            filter: {
              fromTransferDate: null,
              toTransferDate: null,
            },
          },
        };

        variablesArray.forEach((vars) => {
          const { transfers } =
            cache.readQuery({
              query: GET_TRANSFERS,
              variables: vars || defaultVariables,
            }) || {};

          if (transfers && !errors) {
            const newT = [...transfers.data, updatedTransfer?.createTransfer];
            const updatedTransfers = sortBy(cloneDeep(newT), ['transferDate']).reverse();
            cache.writeQuery({
              query: GET_TRANSFERS,
              variables: vars || defaultVariables,
              data: {
                transfers: { data: updatedTransfers, total: transfers.total },
              },
            });
          }
        });
      },
    })
      .then(
        (dataResponse) => {
          if (dataResponse?.data && !dataResponse?.errors) {
            // this will change total transactions, triggering refetch transactions in transactions list
            startPollingTransactionsSummary(2000);
            setTimeout(() => {
              stopPollingTransactionsSummary();
            }, 20000);
            handleNextStep();
          } else {
            setCurrentStep(1);
            showErrorToast();
          }
        },
        (err) => {
          console.error(err);
          setCurrentStep(1);
          showErrorToast();
        }
      )
      .finally(() => {
        resetForm();
        setUnsplitTagId(null);
        // Necessary to use timeout. It seems that BE updates after few seconds + too much need to be done to update balances properly.
        delayedRefetchTimeout = setTimeout(() => {
          refetchBankSummary();
          refetch();
        }, 3000);
      });
  };

  const handleAddAccountManuallySuccess = async () => {
    refetchBankSummary();
    refetch();
  };

  const handleOpenManualAccountDrawer = () => {
    addAccountManuallyDrawerRef.current?.open();
  };

  useEffect(() => {
    if (propertiesData.length > 0) {
      const presentationProperties = buildPresentationProperties(propertiesData);
      const idsMap = createPropertyIdsMap(presentationProperties);
      setPropertyIdsMap(idsMap);
    }
  }, [propertiesData]);

  return (
    <ChakraProvider theme={habitatTheme}>
      <Formik
        innerRef={formikRef}
        validateOnChange
        validateOnBlur
        initialValues={initialFormValues}
        validate={(values) =>
          handleValidation({
            formikRef,
            values,
            accountsMap,
            handleEducationalDrawerOpen,
            handleOpenManualAccountDrawer,
            banks,
            refetch,
            updateLinkTokenData,
            isUpdateLinkTokenLoading,
            resyncBankAccountBalance,
          })
        }
        onSubmit={handleSubmit}
      >
        {(formikProps) => {
          const { dirty, resetForm } = formikProps;

          return (
            <>
              <BaselaneDrawer
                isOpen
                size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
                title="Transfer between accounts"
                footer={
                  <Footer
                    {...{
                      currentStep,
                      totalSteps,
                      banks,
                      accountsMap,
                      transferType,
                      handlePreviousStep,
                      handleNextStep,
                      handleMakeAnotherTransfer,
                      handleDrawerClose,
                    }}
                  />
                }
                closeEvent={() => handleDrawerClose({ dirty, resetForm })}
                onOverlayClick={() => handleDrawerClose({ dirty, resetForm })}
                closeOnOverlayClick={false}
                newDesignDrawer
              >
                {(loading || bankLoading) && !error && <Spinner {...spinnerStyles} />}
                {!loading && !bankLoading && !error && (
                  <Body
                    {...{
                      initialValues,
                      currentStep,
                      totalSteps,
                      unsplitTagId,
                      setUnsplitTagId,
                      transferType,
                      setTransferType,
                      getUpdateLinkToken,
                      fromDropdownList,
                      toDropdownList,
                      accountsMap,
                      propertyIdsMap,
                      categoryMap,
                      categoryIdsMap,
                      categoryOptions,
                      propertyOptions,
                    }}
                  />
                )}
              </BaselaneDrawer>
              <UnsavedChangesAlert
                {...{
                  isDrawerAlertOpen,
                  onAlertClose,
                  onAlertContinue: () => {
                    handleAlertContinue({ resetForm });
                  },
                }}
              />
              <EducationalDrawer
                {...{
                  hideButton: true,
                  buttonContent: methods[0],
                  educationalDrawerRef: emptyStateEducationalDrawerRef,
                  handleDrawerClose: handleEducationalDrawerClose,
                  handleDrawerOpen: handleEducationalDrawerOpen,
                }}
              />
              <AddAccountManuallyDrawer
                {...{
                  addAccountManuallyDrawerRef,
                  handleAddAccountManuallySuccess,
                  from: { section: 'add_funds' },
                }}
              />
            </>
          );
        }}
      </Formik>
    </ChakraProvider>
  );
};

TransferFunds.defaultProps = {
  from: null,
};

export default TransferFunds;
