import { gql, useQuery } from '@apollo/client';

const SCHEDULED_RECURRING_PAYMENT_DETAILS = gql`
  query scheduledRecurringPaymentDetails($recurringPaymentId: ID) {
    scheduledRecurringPaymentDetails(recurringPaymentId: $recurringPaymentId) {
      id
      internalUserId
      userId
      type
      fromTransferAccountId
      toTransferAccountId
      fromTransferAccount {
        id
        userId
        name
        accountName
        accountNumber
        permission
        amount
        isExternal
        provider
        institution {
          id
          name
        }
        bankAccountId
        isBankConnected
        counterPartyConnectionHistoryCode
      }
      toTransferAccount {
        id
        userId
        name
        accountName
        accountNumber
        permission
        amount
        isExternal
        provider
        institution {
          id
          name
        }
        bankAccountId
        isBankConnected
        counterPartyConnectionHistoryCode
      }
      payeePaymentMethodId
      payeePaymentMethod {
        achPaymentMethods {
          type
          accountHolderName
          routingNumber
          accountNumber
        }
        wirePaymentMethods {
          accountHolderName
          routingNumber
          accountNumber
          address {
            street
            unit
            city
            state
            postalCode
            country
          }
          notes
        }
        checkPaymentMethods {
          id
          name
          notes
          address {
            street
            unit
            city
            state
            postalCode
            country
          }
        }
        payee {
          id
          name
        }
      }
      wireTransferAccount {
        name
        routingNumber
        accountNumber
        recipientAddress {
          street
          unit
          city
          state
          postalCode
          country
        }
        tagId
        propertyId
        unitId
        description
      }
      amount
      startDate
      endDate
      recurrencePattern {
        type
        interval
        monthlyByDate
        lastDayOfMonth
        daysOfMonth
        daysOfWeek
        numberOfPayments
      }
      transfers {
        id
        transferDate
        amount
        actualDate
        isHoliday
        isWeekend
        state
      }
      nextPaymentDate
      lastPaymentDate
      tagId
      propertyId
      unitId
      nextPaymentAmount
      totalAmount
      totalNumberOfPayments
      numberOfPaymentsProcessed
      numberOfCancelledPayments
      bookKeepingNote
      note
    }
  }
`;

const useScheduledRecurringPaymentDetails = (recurringPaymentId) => {
  const { data, loading, error } = useQuery(SCHEDULED_RECURRING_PAYMENT_DETAILS, {
    variables: { recurringPaymentId },
  });

  return {
    data: data?.scheduledRecurringPaymentDetails,
    loading,
    error,
  };
};

export default useScheduledRecurringPaymentDetails;
