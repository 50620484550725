import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Heading,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  VStack,
  Flex,
  Skeleton,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import {
  PREVIEW_AUTOTAG_TRANSACTIONS,
  PREVIEW_AUTOTAG_RULE_COUNT,
  GET_PROPERTY_NAME,
  GET_TAGS,
} from '@core/apollo/queries';
import { formatDate } from '@core/utils/formatDate';
import { BaselaneButton } from '@shared/components';
import formatCurrency from '@core/utils/formatCurrency';
import { Icon16DoubleArrowRight, Icon16Property, Icon16Allcategories } from '@icons/16px';
import useBreakPoints from '@core/hooks/useBreakPoints';
import Conditions from '@core/pages/AutoTaggingRulesPage/components/Conditions';
import Action from '@core/pages/AutoTaggingRulesPage/components/Action';
import { buildAutoTagRuleInput } from './utils';

const PreviewTransactionsModal = () => {
  const { isMin899, isMax768 } = useBreakPoints();
  const { values, setFieldValue } = useFormikContext();
  const [transactions, setTransactions] = useState([]);
  const [transactionsCount, setTransactionsCount] = useState();

  const input = buildAutoTagRuleInput(values);

  const { data: propertiesData } = useQuery(GET_PROPERTY_NAME);
  const { data: tags } = useQuery(GET_TAGS);

  const [previewTransactions, { loading: isLoading }] = useMutation(PREVIEW_AUTOTAG_TRANSACTIONS);
  const [previewTransactionsCount, { loading: isLoadingCount }] = useMutation(
    PREVIEW_AUTOTAG_RULE_COUNT
  );

  useEffect(() => {
    if (!values.isPreviewTransactionsModalOpen) return;

    const fetchTransactions = async () => {
      try {
        const [{ data: transactionsData }, { data: countData }] = await Promise.all([
          previewTransactions({ variables: { input: input.input } }),
          previewTransactionsCount({ variables: { input: input.input } }),
        ]);

        const properties = propertiesData?.property || [];

        const tagsData = tags?.tag
          ?.flatMap((tag) => tag?.subType)
          .flatMap((item) => {
            const newItem = { id: item.id, name: item.name };
            const hasChildren = item.subType && item.subType.length > 0;
            if (hasChildren) {
              const children = item.subType.map((i) => ({ id: i.id, name: i.name }));
              return [newItem, ...children];
            }
            return newItem;
          });

        const enrichedTransactions =
          transactionsData?.previewAutotagRuleTransactions?.map((item) => ({
            ...item,
            propertyName:
              properties.find((property) => Number(property.id) === item.propertyId)?.name ?? null,
            categoryName: tagsData?.find((tag) => tag.id === item.tagId)?.name ?? null,
          })) || [];

        setTransactions(enrichedTransactions);
        setTransactionsCount(countData?.previewAutotagRuleCount || 0);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, [
    values.isPreviewTransactionsModalOpen,
    propertiesData,
    tags,
    previewTransactions,
    previewTransactionsCount,
  ]);

  const noPropertySelected = (
    <Text textStyle="sm" color="brand.neutral.400" as="span">
      <Flex gap={0.5} alignItems="center" as="span">
        <Icon16Property />
        Property not selected
      </Flex>
    </Text>
  );

  const noCategorySelected = (
    <Text textStyle="sm" color="brand.neutral.400">
      <Flex gap={0.5} alignItems="center" as="span">
        <Icon16Allcategories />
        Category not selected
      </Flex>
    </Text>
  );

  const desktopTable = (
    <Table>
      <Thead>
        <Tr>
          <Th w="72px" textTransform="none" pt={2} pb={2} pl={1} pr={1}>
            Date
          </Th>
          <Th w="364px" textTransform="none" pt={2} pb={2} pl={1} pr={1}>
            Description
          </Th>
          <Th w="212px" textTransform="none" pt={2} pb={2} pl={1} pr={1}>
            Property & Category
          </Th>
          <Th w="112px" textTransform="none" pt={2} pb={2} pl={1} pr={1}>
            Amount
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {transactions.length > 0 ? (
          transactions.slice(0, 50)?.map((transaction) => {
            const {
              id,
              date,
              name,
              merchantName,
              propertyName,
              categoryName,
              amount,
            } = transaction;
            return (
              <Tr key={id}>
                <Td pt={2} pb={2} pl={1} pr={1}>
                  <VStack gap={0} alignItems="flex-start">
                    <Text textStyle="sm">{formatDate(date, 'MMM DD')}</Text>
                    <Text textStyle="xs">{formatDate(date, 'YYYY')}</Text>
                  </VStack>
                </Td>
                <Td pt={2} pb={2} pl={1} pr={1}>
                  <VStack gap={0} alignItems="flex-start">
                    <Text textStyle="sm" fontWeight={600}>
                      {merchantName || name}
                    </Text>
                    {merchantName !== name && <Text textStyle="sm">{name}</Text>}
                  </VStack>
                </Td>
                <Td pt={2} pb={2} pl={1} pr={1}>
                  <VStack gap={0} alignItems="flex-start">
                    {propertyName ? (
                      <Flex gap={0.5} alignItems="center">
                        <Icon16DoubleArrowRight />
                        <Text textStyle="sm">{propertyName}</Text>
                      </Flex>
                    ) : (
                      noPropertySelected
                    )}
                    {categoryName ? (
                      <Flex gap={0.5} alignItems="center">
                        <Icon16DoubleArrowRight />
                        <Text textStyle="sm">{categoryName}</Text>
                      </Flex>
                    ) : (
                      noCategorySelected
                    )}
                  </VStack>
                </Td>
                <Td pt={2} pb={2} pl={1} pr={1}>
                  <Text textStyle="sm" fontWeight={600}>
                    {formatCurrency(amount, true, false, true).inDollars}
                  </Text>
                </Td>
              </Tr>
            );
          })
        ) : (
          <Tr>
            <Td pt={2} pb={2} pl={1} pr={1}>
              <Text textStyle="sm" whiteSpace="nowrap">
                No transactions
              </Text>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );

  const mobileTable = (
    <Box>
      {transactions.length > 0 ? (
        transactions.slice(0, 50)?.map((transaction, index) => {
          const { id, propertyName, categoryName, date, merchantName, name, amount } = transaction;
          return (
            <Box
              key={id}
              p={1}
              borderBottom={index !== transactions.length - 1 ? '1px solid' : 'none'}
              borderColor="brand.darkBlue.100"
            >
              <Flex alignItems="center" justifyContent="space-between" mb={1}>
                <Text textStyle="sm" fontWeight={600} as="span">
                  {merchantName || name}
                </Text>
                <Text textStyle="sm" fontWeight={600} as="span">
                  {formatCurrency(amount, true, false, true).inDollars}
                </Text>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between" mb={0.5}>
                <Text textStyle="sm" as="span">
                  {propertyName || noPropertySelected}
                </Text>
                <Text textStyle="sm" as="span">
                  {formatDate(date, 'MMM DD')}
                </Text>
              </Flex>
              <Text textStyle="sm" as="span">
                {categoryName || noCategorySelected}
              </Text>
            </Box>
          );
        })
      ) : (
        <Box p={1} borderBottom="1px solid" borderColor="brand.darkBlue.100">
          <Text textStyle="sm" whiteSpace="nowrap" as="span">
            No transactions
          </Text>
        </Box>
      )}
    </Box>
  );

  return (
    <Modal
      isOpen={values.isPreviewTransactionsModalOpen}
      isCentered
      onClose={() => {
        setFieldValue('isPreviewTransactionsModalOpen', false);
      }}
    >
      <ModalOverlay />
      <ModalContent minWidth={isMin899 ? '792px' : '100%'}>
        <ModalHeader fontWeight="normal" pt={3} pb={3} pl={isMax768 ? 2 : 4} pr={isMax768 ? 2 : 4}>
          <ModalCloseButton />
          <Heading size="headline-lg" mb={0.5}>
            New rule
          </Heading>
          <Text fontSize="sm">Review the transactions affected by this new rule.</Text>
        </ModalHeader>
        <ModalBody data-cy="popup-body" pl={isMax768 ? 2 : 4} pr={isMax768 ? 2 : 4}>
          <Box borderBottom="1px solid" borderColor="brand.darkBlue.100" mb={3}>
            <Flex
              w="100%"
              pb={1}
              pl={isMax768 ? 0 : 2}
              pr={isMax768 ? 0 : 2}
              borderBottom="1px solid"
              borderColor="brand.darkBlue.200"
            >
              <Text w="50%" textStyle="xs">
                If
              </Text>
              <Text w="50%" textStyle="xs">
                Then update
              </Text>
            </Flex>
            <Flex w="100%" p={2} pl={isMax768 ? 0 : 2} pr={isMax768 ? 0 : 2} gap={1}>
              <Box w="50%">
                <Conditions conditions={input.input.conditions} />
              </Box>
              <Box w="50%">
                <Action action={input.input.action} />
              </Box>
            </Flex>
          </Box>
          <Box>
            <Skeleton isLoaded={!isLoadingCount}>
              <Heading fontWeight="normal" size="headline-sm" mb={2} color="brand.blue.800A">
                This rule applies to {transactionsCount} transactions
                {transactionsCount > 50 && ', displaying the first 50 below'}
              </Heading>
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
              <Box
                border="1px solid"
                borderColor="brand.darkBlue.200"
                borderRadius="4px"
                maxHeight="60vh"
                overflowY="auto"
              >
                {isMin899 ? desktopTable : mobileTable}
              </Box>
            </Skeleton>
          </Box>
        </ModalBody>
        <ModalFooter pt={3} pb={3} pl={isMax768 ? 2 : 4} pr={isMax768 ? 2 : 4}>
          <BaselaneButton
            palette="primary"
            variant="filled"
            w="100%"
            size="lg"
            onClick={() => {
              setFieldValue('isPreviewTransactionsModalOpen', false);
            }}
          >
            Close
          </BaselaneButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PreviewTransactionsModal;
