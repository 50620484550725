import { gql, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import { OTP_REQUIRED_ERROR_CODE } from '@core/contexts/UnitOtpContext';
import { parseTagId } from '../../../../helpers/formHelpers';

const useAddRecurringPayment = (xIdempotencyKey) => {
  const { bankAccountsById } = useBankAccountsActive();
  const ADD_RECURRING_PAYMENT = gql`
    mutation AddRecurringPayment($input: CreateRecurringPaymentsInput!) {
      addRecurringPayment(input: $input) {
        id
        internalUserId
        userId
        type
        fromTransferAccountId
        toTransferAccountId
        fromTransferAccount {
          id
          userId
          name
          accountName
          accountNumber
          permission
          amount
          isExternal
          provider
          institution {
            id
          }
          bankAccountId
          isBankConnected
          counterPartyConnectionHistoryCode
        }
        toTransferAccount {
          id
          userId
          name
          accountName
          accountNumber
          permission
          amount
          isExternal
          provider
          institution {
            id
          }
          bankAccountId
          isBankConnected
          counterPartyConnectionHistoryCode
        }
        payeePaymentMethodId
        wireTransferAccount {
          name
          routingNumber
          accountNumber
          recipientAddress {
            street
            unit
            city
            state
            postalCode
            country
          }
          tagId
          propertyId
          unitId
          description
        }
        amount
        startDate
        endDate
        recurrencePattern {
          type
          interval
          monthlyByDate
          lastDayOfMonth
          daysOfMonth
          daysOfWeek
          numberOfPayments
        }
        transfers {
          transferDate
          amount
          actualDate
          isHoliday
          isWeekend
          state
        }
        nextPaymentDate
        lastPaymentDate
        tagId
        propertyId
        unitId
        nextPaymentAmount
        totalAmount
        totalNumberOfPayments
        numberOfPaymentsProcessed
        numberOfCancelledPayments
      }
    }
  `;

  const [addRecurringPayment, { loading: isRecurringPaymentLoading }] = useMutation(
    ADD_RECURRING_PAYMENT
  );

  const submitForm = async (values) => {
    const [propertyId, unitId] =
      Array.isArray(values.propertyId) || !values.propertyId
        ? [null, null]
        : values.propertyId.split('-');

    const { data, errors } = await addRecurringPayment({
      context: {
        headers: {
          'x-idempotency-key': xIdempotencyKey,
        },
      },
      variables: {
        input: {
          internalUserId: values?.internalUserId, // ?
          userId: values?.userId, // ?
          type: values.paymentMethodType === 'ACH' ? 'TRANSFER_OUT' : values.paymentMethodType,
          fromTransferAccountId: bankAccountsById[values.fromTransferAccountId].transferAccountId,
          toTransferAccountId: values?.toTransferAccountId, // ?
          payeePaymentMethodId: values.paymentMethodId ? +values.paymentMethodId : undefined,
          wireTransferAccount: values?.wireTransferAccount, // ?
          checkPaymentTransferAccount: values?.checkPaymentTransferAccount, // ?
          amount: values?.amount ? parseFloat(values.amount?.replace(/,/g, '')) : 0,
          startDate: DateTime.fromJSDate(values.recurringPayment.startDate).toISODate(),
          endDate:
            values.recurringPayment.endBy === 'date'
              ? DateTime.fromJSDate(values.recurringPayment.endDate).toISODate()
              : undefined,
          recurrencePattern: {
            type: values.recurringPayment.repeatEvery.unit === 'month' ? 'MONTHLY' : 'WEEKLY',
            interval: values.recurringPayment.repeatEvery.value,
            monthlyByDate:
              values.recurringPayment.repeatEvery.unit === 'month' &&
              values.recurringPayment.repeatOnMonthDate !== 'last'
                ? +values.recurringPayment.repeatOnMonthDate
                : undefined,
            lastDayOfMonth:
              values.recurringPayment.repeatEvery.unit === 'month' &&
              values.recurringPayment.repeatOnMonthDate === 'last',
            daysOfWeek:
              values.recurringPayment.repeatEvery.unit === 'week'
                ? values?.recurringPayment?.repeatOnWeekday?.toUpperCase()
                : undefined,
            numberOfPayments:
              values.recurringPayment.endBy === 'numberOfPayments'
                ? values.recurringPayment.numberOfPayments
                : undefined,
          },
          tagId: parseTagId(values.tagId),
          propertyId,
          unitId,
          sameDay: DateTime.now().hasSame(values.recurringPayment.startDate, 'day'),
          bookKeepingNote: values.note ? values.note : undefined,
          note: values.description ? values.description : undefined,
        },
      },
    });

    if (errors) {
      if (errors.some((error) => error?.extensions?.code === OTP_REQUIRED_ERROR_CODE)) {
        throw new Error(OTP_REQUIRED_ERROR_CODE);
      } else {
        throw new Error('Errors initiating check deposit', errors);
      }
    }

    return data;
  };

  return {
    submitForm,
    isRecurringPaymentLoading,
  };
};

export default useAddRecurringPayment;
