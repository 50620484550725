import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Text, Tbody, Thead, VStack } from '@chakra-ui/react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { dateFormatter } from '@core/utils/formatDate';
import formatCurrency from '@core/utils/formatCurrency';
import useBreakPoints from '@core/hooks/useBreakPoints';
import formatPhoneNumber from '@core/utils/formatPhoneNumber';
import { TENANT_SCREENING_RENTAL_APPLICATION } from '@core/constants/routes';
import {
  BaselaneCardStack,
  BaselaneResponsiveCell,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
} from '@shared/components';
import ReportHeader from '../components/ReportHeader';
import { isFieldNotObject } from '../helpers/tenantScreening.helpers';
import { getReportByid, isReportDataInvalid } from '../helpers/tenantScreeningReports.helpers';
import ReportUnavailableAlert from '../components/ReportUnavailableAlert';
import DocumentDropdownContainer from './components/DocumentDropdownContainer';
import PendingEmptyState from './PendingEmptyState';

const RentalApplication = () => {
  const { isMax767 } = useBreakPoints();
  const [petPhotoIndex, setPetPhotoIndex] = useState([]);
  const [esaDocIndex, setEsaDocIndex] = useState([]);
  const tableColumnConfig = {
    columns: [
      {
        isSortable: false,
        styles: {
          w: isMax767 ? '150px' : '320px',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
          verticalAlign: 'top',
        },
      },
      {
        isSortable: false,
        styles: {
          w: '100%',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
          overflow: 'visible',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          verticalAlign: 'top',
        },
      },
    ],
  };

  const { verificationId, externalApplicantId, property } = useOutletContext();
  const { applicationId } = useParams();
  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getAge = (dobString) => {
    if (!dobString) return null;

    try {
      const dob = DateTime.fromISO(dobString);
      if (!dob.isValid) return null;
      const now = DateTime.now();
      return now.diff(dob, 'years').years.toFixed(0);
    } catch (error) {
      console.error('Error parsing date of birth:', error);
      return null;
    }
  };

  const formatAddress = (streetAddress) => {
    const { address: street, city, state, zipCode } = streetAddress;
    const country = 'US';
    const formattedAddress = `${street}, ${city}, ${state}, ${zipCode}, ${country}`;
    return formattedAddress;
  };

  useEffect(() => {
    if (applicationId && externalApplicantId && verificationId) {
      setIsLoading(true);
      getReportByid(applicationId, externalApplicantId, verificationId)
        .then(({ data }) => {
          if (data?.name === TENANT_SCREENING_RENTAL_APPLICATION) setReportData(data);
        })
        .catch((error) => {
          console.error('Error fetching application:', error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [applicationId, externalApplicantId, verificationId]);

  useEffect(() => {
    // there can be multiple pets and multiple photos for each pet
    // so we need to flatten the array of arrays into a single array of indexes within each parent array
    // [ [1, 2, 3], [4, 5, 6] ] => [0, 0] in case of two pets
    // same goes for ESA documents
    setPetPhotoIndex(reportData?.report?.pets?.map(() => 0) || []);
    setEsaDocIndex(reportData?.report?.pets?.map(() => 0) || []);
  }, [reportData, isLoading]);

  if (reportData?.status === 'pending' || isReportDataInvalid(reportData, verificationId)) {
    return reportData?.status === 'pending' ? (
      <PendingEmptyState />
    ) : (
      <ReportUnavailableAlert isLoading={isLoading} />
    );
  }

  const {
    report: { pets },
  } = reportData;

  return (
    <>
      <VStack alignItems="flex-start" gap={4}>
        <BaselaneCardStack
          id="basic-information"
          size="md"
          items={[
            {
              id: 'header',
              content: <ReportHeader>Basic information</ReportHeader>,
            },
            {
              id: 'data',
              content: (
                <BaselaneResponsiveTable
                  id="review-columns"
                  config={tableColumnConfig}
                  maxW="600px"
                  tableContainerStyles={{
                    overflowY: 'initial',
                    overflowX: 'initial',
                  }}
                >
                  <Thead>
                    <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                      <BaselaneResponsiveCellTitle
                        title="Application info"
                        titleSize="headline-sm"
                        styles={{ titleTextStyle: { color: 'black' }, p: 0 }}
                        p={0}
                        configIndex={1}
                        colSpan="2"
                      />
                    </BaselaneResponsiveTableRow>
                  </Thead>
                  <Tbody>
                    <BaselaneResponsiveTableRows
                      items={[
                        {
                          heading: 'Application started',
                          data: dateFormatter(reportData?.created_at, `MMM dd, yyyy 'at' hh:mma`),
                        },
                        {
                          heading: 'Application completed',
                          data: dateFormatter(reportData?.completed_at, `MMM dd, yyyy 'at' hh:mma`),
                        },
                        { heading: 'Group application', data: 'No' },
                      ]}
                      customRow
                      renderItem={(row) => {
                        return (
                          <BaselaneResponsiveTableRow
                            key={row.heading}
                            chevronCellStyles={{ w: 0 }}
                            contentContainerStyles={{ border: 'none' }}
                          >
                            <BaselaneResponsiveCell configIndex={0} p={0}>
                              <Text as="span" textStyle="sm" color="brand.neutral.600">
                                {row.heading}
                              </Text>
                            </BaselaneResponsiveCell>
                            <BaselaneResponsiveCell configIndex={1} p={0}>
                              <Text textStyle="sm" color="brand.neutral.900">
                                {isFieldNotObject(row.data) ? row.data : '--'}
                              </Text>
                            </BaselaneResponsiveCell>
                          </BaselaneResponsiveTableRow>
                        );
                      }}
                    />
                    <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                      <BaselaneResponsiveCellTitle
                        title="Application for"
                        titleSize="headline-sm"
                        styles={{
                          titleTextStyle: { color: 'black' },
                          p: 0,
                        }}
                        p={0}
                        configIndex={1}
                        colSpan="2" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                      />
                    </BaselaneResponsiveTableRow>
                    <BaselaneResponsiveTableRows
                      items={[
                        {
                          heading: 'Property',
                          data: property?.propertyName || '--',
                        },
                        {
                          heading: 'Unit',
                          data: property?.unitName || '--',
                        },
                        {
                          heading: 'Address',
                          data: formatAddress(property?.address) || '--',
                        },
                        {
                          heading: 'Monthly rent',
                          data: formatCurrency(property?.amount).inDollars || '--',
                        },
                        {
                          heading: 'Desired move-in date',
                          data: dateFormatter(property?.moveInDate, `MMM dd, yyyy `) || '--',
                        },
                      ]}
                      customRow
                      renderItem={(row) => {
                        return (
                          <BaselaneResponsiveTableRow
                            key={row.heading}
                            chevronCellStyles={{ w: 0 }}
                            contentContainerStyles={{ border: 'none' }}
                          >
                            <BaselaneResponsiveCell configIndex={0} p={0}>
                              <Text as="span" textStyle="sm" color="brand.neutral.600">
                                {row.heading}
                              </Text>
                            </BaselaneResponsiveCell>
                            <BaselaneResponsiveCell configIndex={1} p={0}>
                              <Text
                                textStyle="sm"
                                color="brand.neutral.900"
                                style={{ textWrap: 'wrap' }}
                                w="100%"
                              >
                                {isFieldNotObject(row.data) ? row.data : '--'}
                              </Text>
                            </BaselaneResponsiveCell>
                          </BaselaneResponsiveTableRow>
                        );
                      }}
                    />
                    <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0, py: 2 }}>
                      <BaselaneResponsiveCellTitle
                        title="Applicant"
                        titleSize="headline-sm"
                        styles={{
                          titleTextStyle: { color: 'black' },
                        }}
                        p={0}
                        configIndex={1}
                        colSpan="2" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                      />
                    </BaselaneResponsiveTableRow>
                    <BaselaneResponsiveTableRows
                      items={[
                        {
                          heading: 'First name',
                          data: reportData?.report?.contact_first_name || '--',
                        },
                        {
                          heading: 'Last name',
                          data: reportData?.report?.contact_last_name || '--',
                        },
                        {
                          heading: 'Middle name',
                          data: reportData?.report?.contact_middle_name || '--',
                        },
                        {
                          heading: 'Date of birth',
                          data:
                            dateFormatter(
                              reportData?.report?.identity_verification?.dob,
                              `MMM dd, yyyy `
                            ) || '--',
                        },
                        {
                          heading: 'Age',
                          data: getAge(reportData?.report?.identity_verification?.dob) || '--',
                        },
                        {
                          heading: 'SSN',
                          data: reportData?.report?.identity_verification?.id_number?.value || '--',
                        },
                        {
                          heading: 'Phone',
                          data: formatPhoneNumber(reportData?.report?.contact_phone_number) || '--',
                        },
                        {
                          heading: 'Email',
                          data: reportData?.report?.contact_email || '--',
                        },
                        {
                          heading: 'Military, first responder, or teacher',
                          data:
                            reportData?.report?.are_you_military_first_responder_teacher || '--',
                        },
                      ]}
                      customRow
                      renderItem={(row) => {
                        return (
                          <BaselaneResponsiveTableRow
                            key={row.heading}
                            chevronCellStyles={{ w: 0 }}
                            contentContainerStyles={{ border: 'none' }}
                          >
                            <BaselaneResponsiveCell configIndex={0} p={0}>
                              <Text as="span" textStyle="sm" color="brand.neutral.600">
                                {row.heading}
                              </Text>
                            </BaselaneResponsiveCell>
                            <BaselaneResponsiveCell configIndex={1} p={0}>
                              <Text
                                textStyle="sm"
                                color="brand.neutral.900"
                                style={{ textWrap: 'wrap' }}
                                w="100%"
                              >
                                {isFieldNotObject(row.data) ? row.data : '--'}
                              </Text>
                            </BaselaneResponsiveCell>
                          </BaselaneResponsiveTableRow>
                        );
                      }}
                    />
                  </Tbody>
                </BaselaneResponsiveTable>
              ),
            },
          ]}
        />

        {reportData?.report?.dependents === 'Yes' ? (
          <BaselaneCardStack
            id="basic-information"
            size="md"
            items={[
              {
                id: 'header',
                content: <ReportHeader>Dependents</ReportHeader>,
              },
              {
                id: 'data',
                content: (
                  <BaselaneResponsiveTable
                    id="review-columns"
                    config={tableColumnConfig}
                    maxW="600px"
                    tableContainerStyles={{
                      overflowY: 'initial',
                      overflowX: 'initial',
                    }}
                  >
                    <Tbody>
                      {reportData?.report?.list_of_dependents &&
                        reportData?.report?.list_of_dependents?.map((dependent, index) => {
                          return (
                            <React.Fragment key={`${dependent.first_name}${dependent.last_name}`}>
                              <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                                <BaselaneResponsiveCellTitle
                                  title={`Person ${index + 1}`}
                                  titleSize="headline-sm"
                                  styles={{ titleTextStyle: { color: 'black' }, p: 0 }}
                                  p={0}
                                  configIndex={1}
                                  colSpan="2" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                                />
                              </BaselaneResponsiveTableRow>

                              <BaselaneResponsiveTableRows
                                items={[
                                  { heading: 'First name', data: dependent.first_name || '--' },
                                  { heading: 'Last name', data: dependent.last_name || '--' },
                                  { heading: 'Date of birth', data: dependent.dob || '--' },
                                  { heading: 'Age', data: getAge(dependent.dob) || '--' },
                                ]}
                                customRow
                                renderItem={(row) => {
                                  return (
                                    <BaselaneResponsiveTableRow
                                      key={row.heading}
                                      chevronCellStyles={{ w: 0 }}
                                      contentContainerStyles={{ border: 'none' }}
                                    >
                                      <BaselaneResponsiveCell configIndex={0} p={0}>
                                        <Text as="span" textStyle="sm" color="brand.neutral.600">
                                          {row.heading}
                                        </Text>
                                      </BaselaneResponsiveCell>
                                      <BaselaneResponsiveCell configIndex={1} p={0}>
                                        <Text textStyle="sm" color="brand.neutral.600">
                                          {isFieldNotObject(row.data) ? row.data : '--'}
                                        </Text>
                                      </BaselaneResponsiveCell>
                                    </BaselaneResponsiveTableRow>
                                  );
                                }}
                              />
                            </React.Fragment>
                          );
                        })}
                    </Tbody>
                  </BaselaneResponsiveTable>
                ),
              },
            ]}
          />
        ) : (
          <BaselaneCardStack
            id="basic-information"
            size="md"
            items={[
              {
                id: 'header',
                content: <ReportHeader>Dependents</ReportHeader>,
              },
              {
                id: 'data',
                content: (
                  <BaselaneResponsiveTable
                    id="review-columns"
                    config={tableColumnConfig}
                    maxW="600px"
                    tableContainerStyles={{
                      overflowY: 'initial',
                      overflowX: 'initial',
                    }}
                  >
                    <Tbody>
                      <BaselaneResponsiveTableRow
                        chevronCellStyles={{ w: 0 }}
                        contentContainerStyles={{ border: 'none' }}
                      >
                        <BaselaneResponsiveCell configIndex={0} p={0}>
                          <Text as="span" textStyle="sm" color="brand.neutral.600">
                            Dependents
                          </Text>
                        </BaselaneResponsiveCell>
                        <BaselaneResponsiveCell configIndex={1} p={0}>
                          <Text textStyle="sm" color="brand.neutral.600">
                            No
                          </Text>
                        </BaselaneResponsiveCell>
                      </BaselaneResponsiveTableRow>
                    </Tbody>
                  </BaselaneResponsiveTable>
                ),
              },
            ]}
          />
        )}

        {reportData?.report?.emergency_contacts && (
          <BaselaneCardStack
            id="basic-information"
            size="md"
            items={[
              {
                id: 'header',
                content: <ReportHeader>Emergency contacts</ReportHeader>,
              },
              {
                id: 'data',
                content: (
                  <BaselaneResponsiveTable
                    id="review-columns"
                    config={tableColumnConfig}
                    maxW="600px"
                    tableContainerStyles={{
                      overflowY: 'initial',
                      overflowX: 'initial',
                    }}
                  >
                    <Tbody>
                      {reportData?.report?.emergency_contacts?.map((contact, index) => {
                        return (
                          <React.Fragment key={`${contact.first_name}${contact.last_name}`}>
                            <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                              <BaselaneResponsiveCellTitle
                                title={`Contact ${index + 1}`}
                                titleSize="headline-sm"
                                styles={{ titleTextStyle: { color: 'black' }, p: 0 }}
                                p={0}
                                configIndex={1}
                                colSpan="2" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                              />
                            </BaselaneResponsiveTableRow>

                            <BaselaneResponsiveTableRows
                              items={[
                                { heading: 'First name', data: contact.first_name || '--' },
                                { heading: 'Last name', data: contact.last_name || '--' },
                                { heading: 'Phone', data: contact.phone_number || '--' },
                                { heading: 'Email', data: contact.email || '--' },
                                { heading: 'Relationship', data: contact.relationship || '--' },
                              ]}
                              customRow
                              renderItem={(row) => {
                                return (
                                  <BaselaneResponsiveTableRow
                                    key={row.heading}
                                    chevronCellStyles={{ w: 0 }}
                                    contentContainerStyles={{ border: 'none' }}
                                  >
                                    <BaselaneResponsiveCell configIndex={0} p={0}>
                                      <Text as="span" textStyle="sm" color="brand.neutral.600">
                                        {row.heading}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                    <BaselaneResponsiveCell configIndex={1} p={0}>
                                      <Text textStyle="sm" color="brand.neutral.900">
                                        {isFieldNotObject(row.data) ? row.data : '--'}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                  </BaselaneResponsiveTableRow>
                                );
                              }}
                            />
                          </React.Fragment>
                        );
                      })}
                    </Tbody>
                  </BaselaneResponsiveTable>
                ),
              },
            ]}
          />
        )}
        {reportData?.report?.do_you_have_pets === 'Yes' ? (
          <BaselaneCardStack
            id="basic-information"
            size="md"
            items={[
              {
                id: 'header',
                content: <ReportHeader>Pets and service animals</ReportHeader>,
              },
              {
                id: 'data',
                content: (
                  <BaselaneResponsiveTable
                    id="review-columns"
                    config={tableColumnConfig}
                    maxW="600px"
                    tableContainerStyles={{
                      overflowY: 'initial',
                      overflowX: 'initial',
                    }}
                  >
                    <Tbody>
                      <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                        <BaselaneResponsiveCellTitle
                          title="Summary"
                          titleSize="headline-sm"
                          styles={{ titleTextStyle: { color: 'black' }, p: 0 }}
                          p={0}
                          configIndex={1}
                          colSpan="2" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                        />
                      </BaselaneResponsiveTableRow>
                      <BaselaneResponsiveTableRows
                        items={[
                          {
                            heading: 'Pets and service animals',
                            data: 'Yes',
                          },
                        ]}
                        customRow
                        renderItem={(row) => {
                          return (
                            <BaselaneResponsiveTableRow
                              key={row.heading}
                              chevronCellStyles={{ w: 0 }}
                              contentContainerStyles={{ border: 'none' }}
                            >
                              <BaselaneResponsiveCell configIndex={0} p={0}>
                                <Text as="span" textStyle="sm" color="brand.neutral.600">
                                  {row.heading}
                                </Text>
                              </BaselaneResponsiveCell>
                              <BaselaneResponsiveCell configIndex={1} p={0}>
                                <Text textStyle="sm" color="brand.neutral.900">
                                  {isFieldNotObject(row.data) ? row.data : '--'}
                                </Text>
                              </BaselaneResponsiveCell>
                            </BaselaneResponsiveTableRow>
                          );
                        }}
                      />
                      {pets?.map((pet, petIndex) => {
                        return (
                          <React.Fragment key={`${pet.type}${pet.name}${uuidv4()}`}>
                            <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                              <BaselaneResponsiveCellTitle
                                title={`Animal ${petIndex + 1}`}
                                titleSize="headline-sm"
                                styles={{ titleTextStyle: { color: 'black' }, p: 0 }}
                                p={0}
                                configIndex={1}
                                colSpan="2" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                              />
                            </BaselaneResponsiveTableRow>
                            {/* Pet picture dropdown */}
                            <BaselaneResponsiveTableRow
                              chevronCellStyles={{ w: 0 }}
                              contentContainerStyles={{ border: 'none' }}
                            >
                              <BaselaneResponsiveCell configIndex={0} p={0} overflow="visible">
                                <DocumentDropdownContainer
                                  type="pet"
                                  items={pets[petIndex]?.photo}
                                  parentIndex={petIndex} // just to make unique ids
                                  currentIndex={petPhotoIndex[petIndex]}
                                  handleSetCurrentIndex={(value) => {
                                    const newPetPhotoIndex = [...petPhotoIndex];
                                    newPetPhotoIndex[petIndex] = value;
                                    setPetPhotoIndex(newPetPhotoIndex);
                                  }}
                                  isEmptyPlaceholderNeeded
                                />
                              </BaselaneResponsiveCell>
                            </BaselaneResponsiveTableRow>
                            <BaselaneResponsiveTableRows
                              items={[
                                { heading: 'Type', data: pet.type || '--' },
                                { heading: 'Breed', data: pet.breed || '--' },
                                { heading: 'Weight', data: pet.weight || '--' },
                                { heading: 'Name', data: pet.name || '--' },
                                { heading: 'Age', data: pet.age || '--' },
                                {
                                  heading: 'Is a service/ESA animal',
                                  data: pet.service_animal ? 'Yes' : 'No',
                                },
                              ]}
                              customRow
                              renderItem={(row) => {
                                return (
                                  <BaselaneResponsiveTableRow
                                    key={row.heading}
                                    chevronCellStyles={{ w: 0 }}
                                    contentContainerStyles={{ border: 'none' }}
                                  >
                                    <BaselaneResponsiveCell configIndex={0} p={0}>
                                      <Text as="span" textStyle="sm" color="brand.neutral.600">
                                        {row.heading}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                    <BaselaneResponsiveCell configIndex={1} p={0}>
                                      <Text textStyle="sm" color="brand.neutral.900">
                                        {isFieldNotObject(row.data) ? row.data : '--'}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                  </BaselaneResponsiveTableRow>
                                );
                              }}
                            />
                            {/* ESA Service document dropdown */}
                            <BaselaneResponsiveTableRow
                              chevronCellStyles={{ w: 0 }}
                              contentContainerStyles={{ border: 'none' }}
                            >
                              <BaselaneResponsiveCell configIndex={0} overflow="visible" p={0}>
                                <DocumentDropdownContainer
                                  type="esa"
                                  items={pets[petIndex]?.supporting_documents}
                                  parentIndex={petIndex} // just to make unique ids
                                  currentIndex={esaDocIndex[petIndex]}
                                  handleSetCurrentIndex={(value) => {
                                    const newEsaDocIndex = [...esaDocIndex];
                                    newEsaDocIndex[petIndex] = value;
                                    setEsaDocIndex(newEsaDocIndex);
                                  }}
                                />
                              </BaselaneResponsiveCell>
                            </BaselaneResponsiveTableRow>
                          </React.Fragment>
                        );
                      })}
                    </Tbody>
                  </BaselaneResponsiveTable>
                ),
              },
            ]}
          />
        ) : (
          <BaselaneCardStack
            id="basic-information"
            size="md"
            items={[
              {
                id: 'header',
                content: <ReportHeader>Pets and service animals</ReportHeader>,
              },
              {
                id: 'data',
                content: (
                  <BaselaneResponsiveTable
                    id="review-columns"
                    config={tableColumnConfig}
                    maxW="600px"
                    tableContainerStyles={{
                      overflowY: 'initial',
                      overflowX: 'initial',
                    }}
                  >
                    <Tbody>
                      <BaselaneResponsiveTableRow
                        chevronCellStyles={{ w: 0 }}
                        contentContainerStyles={{ border: 'none' }}
                      >
                        <BaselaneResponsiveCell configIndex={0} p={0}>
                          <Text as="span" textStyle="sm" color="brand.neutral.600">
                            Pets and service animals
                          </Text>
                        </BaselaneResponsiveCell>
                        <BaselaneResponsiveCell configIndex={1} p={0}>
                          <Text textStyle="sm" color="brand.neutral.900">
                            No
                          </Text>
                        </BaselaneResponsiveCell>
                      </BaselaneResponsiveTableRow>
                    </Tbody>
                  </BaselaneResponsiveTable>
                ),
              },
            ]}
          />
        )}

        {reportData?.report?.do_you_have_vehicles === 'Yes' ? (
          <BaselaneCardStack
            id="basic-information"
            size="md"
            items={[
              {
                id: 'header',
                content: <ReportHeader>Vehicles</ReportHeader>,
              },
              {
                id: 'data',
                content: (
                  <BaselaneResponsiveTable
                    id="review-columns"
                    config={tableColumnConfig}
                    maxW="600px"
                    tableContainerStyles={{
                      overflowY: 'initial',
                      overflowX: 'initial',
                    }}
                  >
                    <Tbody>
                      {reportData?.report?.vehicles?.map((vehicle, index) => {
                        return (
                          <React.Fragment
                            key={`${vehicle.make}${vehicle.model}${vehicle.year}${vehicle.license_plate_number}`}
                          >
                            <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                              <BaselaneResponsiveCellTitle
                                title={`Vehicle ${index + 1}`}
                                titleSize="headline-sm"
                                styles={{ titleTextStyle: { color: 'black' }, p: 0 }}
                                p={0}
                                configIndex={1}
                                colSpan="2" // Note: two cells are added by BaselaneResponsiveTableRow for certain interactive UI elements
                              />
                            </BaselaneResponsiveTableRow>

                            <BaselaneResponsiveTableRows
                              items={[
                                { heading: 'Make', data: vehicle.make || '--' },
                                { heading: 'Model', data: vehicle.model || '--' },
                                { heading: 'Year', data: vehicle.year || '--' },
                                { heading: 'Color', data: vehicle.color || '--' },
                                {
                                  heading: 'License plate number',
                                  data: vehicle.license_plate_number || '--',
                                },
                                {
                                  heading: 'License plate state',
                                  data: vehicle.license_plate_state || '--',
                                },
                              ]}
                              customRow
                              renderItem={(row) => {
                                return (
                                  <BaselaneResponsiveTableRow
                                    key={row.heading}
                                    chevronCellStyles={{ w: 0 }}
                                    contentContainerStyles={{ border: 'none' }}
                                  >
                                    <BaselaneResponsiveCell configIndex={0} p={0}>
                                      <Text as="span" textStyle="sm" color="brand.neutral.600">
                                        {row.heading}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                    <BaselaneResponsiveCell configIndex={1} p={0}>
                                      <Text textStyle="sm" color="brand.neutral.900">
                                        {isFieldNotObject(row.data) ? row.data : '--'}
                                      </Text>
                                    </BaselaneResponsiveCell>
                                  </BaselaneResponsiveTableRow>
                                );
                              }}
                            />
                          </React.Fragment>
                        );
                      })}
                    </Tbody>
                  </BaselaneResponsiveTable>
                ),
              },
            ]}
          />
        ) : (
          <BaselaneCardStack
            id="basic-information"
            size="md"
            items={[
              {
                id: 'header',
                content: <ReportHeader>Vehicles</ReportHeader>,
              },
              {
                id: 'data',
                content: (
                  <BaselaneResponsiveTable
                    config={tableColumnConfig}
                    maxW="600px"
                    tableContainerStyles={{
                      overflowY: 'initial',
                      overflowX: 'initial',
                    }}
                  >
                    <Tbody>
                      <BaselaneResponsiveTableRow
                        chevronCellStyles={{ w: 0 }}
                        contentContainerStyles={{ border: 'none' }}
                      >
                        <BaselaneResponsiveCell configIndex={0} p={0}>
                          <Text as="span" textStyle="sm" color="brand.neutral.600">
                            Vehicles
                          </Text>
                        </BaselaneResponsiveCell>
                        <BaselaneResponsiveCell configIndex={1} p={0}>
                          <Text textStyle="sm" color="brand.neutral.600">
                            No
                          </Text>
                        </BaselaneResponsiveCell>
                      </BaselaneResponsiveTableRow>
                    </Tbody>
                  </BaselaneResponsiveTable>
                ),
              },
            ]}
          />
        )}
      </VStack>
      <Outlet />
    </>
  );
};

export default RentalApplication;
