import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { redditPixelId } from '@core/constants/envVars';
import ZendeskWrapper from './ZendeskWrapper';

/**
 * Note: The purpose of this component is to intialize any scripts or providers
 * that behave differently when the user is not logged in.
 *
 * Current Scripts: Zendesk, Statsig and Reddit
 */

const InitializeAnonymously = () => {
  useEffect(() => {
    /**
     * Reddit tracking initialization (anonymous)
     */
    if (window.rdt && redditPixelId) {
      window.rdt('init', redditPixelId);
    }
  }, []);

  return (
    <ZendeskWrapper handle="anonymous">
      <Outlet />
    </ZendeskWrapper>
  );
};

export default InitializeAnonymously;
