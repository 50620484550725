import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import UserContext from '@contexts/UserContext';
import useBreakPoints from '@core/hooks/useBreakPoints';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';
import IconWarningTriangleYellow from '@icons/manual/IconWarningTriangleYellow';
import { BaselaneButton } from '@shared/components';
import { DASHBOARD, CREATE_WORKSPACE } from '@routes';
import { useCurrentWorkspace } from '@shared/hooks';

const WorkspaceNoAccess = () => {
  const { isMax767 } = useBreakPoints();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const logoContainerStyles = {
    backgroundColor: 'brand.neutral.white',
    minHeight: '100vh',
    height: '100vh',
    width: '100%',
    direction: 'column',
    alignItems: 'center',
  };

  const contentContainerStyles = () => ({
    alignItems: 'center',
    w: '100%',
    px: 4,
    py: 2,
    position: 'relative',
    justifyContent: 'space-between',
    mb: isMax767 ? 5 : '60px',
    borderBottom: '1px',
    borderColor: 'brand.darkBlue.150',
  });
  const { activeWorkspaceId, refetchCurrentWorkspace } = useCurrentWorkspace();

  const handleConvertGuestToLandlord = () => {
    navigate(CREATE_WORKSPACE);
  };

  const title = 'No access to this workspace';
  const subtitle = 'Looks like you don’t have access to this workspace. ';
  const text =
    'Contact the workspace owner to get access or create your own Baselane workspace to manage your rental property finance.';

  if (user?.role === 'LANDLORD' || (user?.role === 'GUEST' && activeWorkspaceId)) {
    navigate(DASHBOARD);
  }

  useEffect(() => {
    refetchCurrentWorkspace();
  }, []);
  return user?.role === 'GUEST' ? (
    <Box>
      <Flex {...logoContainerStyles}>
        <Stack {...contentContainerStyles(isMax767)}>
          <Box w="100%" color="brand.neutral.800A">
            <BaselaneFullLogo />
          </Box>
        </Stack>
        <Stack w="100%" h="50%" align="center" justifyContent="center" mt={6}>
          <Stack
            w="600px"
            align="center"
            justifyContent="center"
            spacing={1.5}
            p={3}
            textAlign="center"
          >
            <IconWarningTriangleYellow />
            <Text textStyle="headline-lg" mt={1} mb={0}>
              {title}
            </Text>
            <Stack gap={0}>
              {' '}
              <Text textStyle="sm" mt="0">
                {subtitle}
              </Text>
              <Text textStyle="sm">{text}</Text>
            </Stack>
            <Box mt={4}>
              <BaselaneButton
                size="md"
                variant="filled"
                palette="primary"
                id="create-workspace"
                isFullWidth={isMax767}
                onClick={handleConvertGuestToLandlord}
              >
                Create your own workspace
              </BaselaneButton>
            </Box>
          </Stack>
        </Stack>
      </Flex>
    </Box>
  ) : null;
};

export default WorkspaceNoAccess;
