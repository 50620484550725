import React from 'react';
import { Stack } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { BaselaneCardNew } from '@shared/components';

import { GET_INVOICE_SUMMARY } from '@pages/LeasesPage/queries';

import HeaderInfo from './HeaderInfo';
import UnableToReceiveFundsAlertBanner from './UnableToReceiveFundsAlertBanner';
import RCStatusTable from './RCStatusTable';
import EmptyState from './EmptyState';
import { GET_LEASES_SUMMARY } from './queries/leases';
import { containerStyles } from './styles';

const RCCard = () => {
  const {
    loading: isInvoiceSummaryLoading,
    data: invoiceSummaryData,
    refetch: refetchInvoiceSummary,
  } = useQuery(GET_INVOICE_SUMMARY);

  const { loading: isLeasesSummaryLoading, data: leaseSummaryData } = useQuery(GET_LEASES_SUMMARY, {
    variables: { input: { state: ['SCHEDULED', 'EXPIRED'] } },
  });

  const { totalCount = 0 } = leaseSummaryData?.leases ?? {};

  return totalCount === 0 && !isLeasesSummaryLoading && !isInvoiceSummaryLoading ? (
    <EmptyState />
  ) : (
    <BaselaneCardNew {...containerStyles}>
      <Stack spacing={3}>
        <HeaderInfo
          isLeasesSummaryLoading={isLeasesSummaryLoading}
          leaseSummaryData={leaseSummaryData}
        />
        <UnableToReceiveFundsAlertBanner
          invoiceSummaryData={invoiceSummaryData}
          refetchInvoiceSummary={refetchInvoiceSummary}
        />
        <RCStatusTable
          isInvoiceSummaryLoading={isInvoiceSummaryLoading}
          invoiceSummaryData={invoiceSummaryData}
        />
      </Stack>
    </BaselaneCardNew>
  );
};

export default RCCard;
