import React from 'react';
import { VStack, Text } from '@chakra-ui/react';
import { listItemStyles } from '@shared/components/BaselaneSortableList/styles/sortableList.styles';

const AutoTaggingRulesEmptyState = () => {
  return (
    <VStack
      {...{
        ...listItemStyles,
        w: 'full',
        pl: 8,
        alignItems: 'flex-start',
        justifyContent: 'center',
        backgroundColor: 'brand.neutral.50',
      }}
    >
      <Text textStyle="md" color="brand.neutral.700">
        You have no rules.
      </Text>
    </VStack>
  );
};

export default AutoTaggingRulesEmptyState;
