import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import customTheme from '@core/theme';
import IconHomeShield from '@icons/manual/IconHomeShield';
import IconArrowRight from '@icons/manual/IconArrowRight';
import { INSURANCE } from '@routes';

import BaselaneBadge from '../BaselaneBadge';
import PoweredByObie from '../../../Dashboard/assets/PoweredByObie.png';
import PoweredByObie2x from '../../../Dashboard/assets/PoweredByObie@2x.png';
import {
  badgeTagStyles,
  badgeButtonStyles,
  badgeButtonIconStyles,
  providerImageStyles,
} from './styles/insuranceBanner.styles';
import BaselaneAdvertisingBanner from '.';

type InsuranceBannerProps = {
  isNarrowOnly: Boolean,
};

const InsuranceBanner = ({ isNarrowOnly, ...rest }: InsuranceBannerProps) => {
  const navigate = useNavigate();

  const goToInsurancePage = () => {
    navigate(INSURANCE);
  };

  const getTopRightContent = () => {
    return (
      <BaselaneBadge
        {...{
          color: 'blue',
          type: 'badge',
          ...badgeTagStyles,
        }}
      >
        Baselane Feature
      </BaselaneBadge>
    );
  };

  const getBottomRightContent = () => {
    return (
      <>
        <BaselaneBadge
          {...{
            color: 'blue',
            type: 'badge',
            ...badgeButtonStyles(false),
          }}
        >
          Get Instant Quote
          <Box as="span" {...badgeButtonIconStyles}>
            <IconArrowRight color={customTheme.colors.brand.blue['700']} />
          </Box>
        </BaselaneBadge>
        <Image
          {...providerImageStyles(isNarrowOnly)}
          src={PoweredByObie}
          srcSet={`${PoweredByObie2x} 2x`}
          alt="Powered by Obie"
        />
      </>
    );
  };

  return (
    <BaselaneAdvertisingBanner
      {...{
        icon: <IconHomeShield />,
        primaryContent: `Landlord Insurance that is right for you`,
        supportingContent: `See how much you could save!`,
        topRightContent: getTopRightContent(),
        bottomRightContent: getBottomRightContent(),
        onClick: goToInsurancePage,
        isBannerClickable: true,
        isNarrowOnly,
        ...rest,
      }}
    />
  );
};

export default InsuranceBanner;
