import { gql } from '@apollo/client';

export const CREATE_AUTOTAG_RULE = gql`
  mutation CreateAutotagRule($input: CreateAutotagRuleInput!) {
    createAutotagRule(input: $input) {
      id
      description
      rank
    }
  }
`;

export const UPDATE_AUTOTAG_RULE = gql`
  mutation UpdateAutotagRule($input: UpdateAutotagRuleInput!) {
    updateAutotagRule(input: $input) {
      id
      description
      rank
    }
  }
`;

export const GET_AUTOTAG_RULE_BY_ID = gql`
  query GetAutoTagRuleById($id: ID!) {
    autotagRule(id: $id) {
      id
      userId
      internalUserId
      description
      rank
      isDeleted
      conditions
      action {
        tagId
        propertyId
        unitId
      }
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_AUTOTAG_RULE = gql`
  mutation DeleteAutoTagRule($id: ID!) {
    deleteAutotagRule(id: $id)
  }
`;

export const PREVIEW_AUTOTAG_TRANSACTIONS = gql`
  mutation PreviewAutotagRuleTransactions($input: CreateAutotagRuleInput!) {
    previewAutotagRuleTransactions(input: $input) {
      id
      createdAt
      updatedAt
      propertyId
      unitId
      tagId
      merchantName
      amount
      date
      name
    }
  }
`;

export const PREVIEW_AUTOTAG_RULE_COUNT = gql`
  mutation PreviewAutotagRuleCount($input: CreateAutotagRuleInput!) {
    previewAutotagRuleCount(input: $input)
  }
`;

export const GET_PROPERTY_NAME = gql`
  query Property {
    property {
      id
      name
    }
  }
`;
