/* eslint-disable react/prop-types */
import React from 'react';
import { Text, Tbody, Td, Th, Thead, Tr, Table, TableContainer } from '@chakra-ui/react';
import { BaselaneCardStack } from '@shared/components';
import formatCurrency from '@core/utils/formatCurrency';
import ReportHeader from '../../components/ReportHeader';

const AccountsOverview = ({ accountOverview = [] }) => {
  if (!accountOverview.length) {
    return (
      <BaselaneCardStack
        id="no-account-data-card"
        size="md"
        width="100%"
        items={[
          {
            id: 'header',
            content: <ReportHeader>Accounts overview</ReportHeader>,
          },
          {
            id: 'no-account',
            content: <Text textStyle="sm">No data available</Text>,
          },
        ]}
      />
    );
  }

  return (
    <BaselaneCardStack
      id="accounts-overview-table"
      size="md"
      width="100%"
      items={[
        {
          id: 'header',
          content: <ReportHeader>Accounts overview</ReportHeader>,
        },
        {
          id: 'accounts-overview-table-body',
          content: (
            <TableContainer width="100%">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th textTransform="none">Open accounts</Th>
                    <Th textTransform="none">Balance</Th>
                    <Th textTransform="none">Past due</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {accountOverview.map((account, index) => (
                    <Tr
                      // NOTE: There is an occurrence of duplicate keys. There were two accounts with the same account number and account type code!
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${account.account_name}-${account.account_number}-${account.account_type.code}-${index}`}
                      fontSize="sm"
                    >
                      <Td>{account.account_name}</Td>
                      <Td>
                        {account.balance
                          ? formatCurrency(account.balance.cents / 100).inDollars
                          : '--'}
                      </Td>
                      <Td>
                        {account.past_due_amount
                          ? formatCurrency(account.past_due_amount.cents / 100).inDollars
                          : '--'}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          ),
        },
      ]}
    />
  );
};

export default AccountsOverview;
