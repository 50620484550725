import { unitApiUrl } from '@core/constants/envVars';

export const getTaxFormPdf = async (taxFormId, token, taxForms, showErrorToast) => {
  try {
    const taxForm = taxForms.find((form) => form.externalFormId === taxFormId);
    if (!taxForm) {
      throw new Error('Tax form not found');
    }

    const response = await fetch(`${unitApiUrl}/tax-forms/${taxForm.externalFormId}/pdf`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to download tax form');
    }

    if (response.status === 200) {
      response.blob().then((blob) => {
        // ref: https://stackoverflow.com/questions/60586001/download-pdf-from-rest-api
        // create a download anchor tag
        const downloadLink = document.createElement('a');
        downloadLink.target = '_blank';
        downloadLink.download = `tax_form_${taxForm.taxYear}.pdf`;

        // create an object URL from the Blob
        const URL = window.URL || window.webkitURL;
        const downloadUrl = URL.createObjectURL(blob);

        // set object URL as the anchor's href
        downloadLink.href = downloadUrl;

        // append the anchor to document body
        document.body.append(downloadLink);

        // fire a click event on the anchor
        downloadLink.click();

        // cleanup: remove element and revoke object URL
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadUrl);
      });
    } else {
      showErrorToast('Failed to download tax form');
    }
  } catch (error) {
    showErrorToast(error);
  }
};
