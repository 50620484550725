export const formTitleStyles = {
  textStyle: 'headline-md',
  my: '24px',
  color: 'brand.neutral.700',
};

export const formDescriptionStyles = {
  textStyle: 'sm',
  mb: '24px',
  color: 'brand.neutral.700',
};

export const formInlineItemStyles = (isMobile) => ({
  firstItem: {
    w: isMobile ? '44%' : '46%',
  },
  secondItem: {
    w: isMobile ? '31%' : '27%',
    ml: isMobile ? '8px !important' : '24px !important',
  },
  thirdItem: {
    w: isMobile ? '25%' : '27%',
    ml: isMobile ? '8px !important' : '24px !important',
  },
});

export const formLabelStyles = {
  fontSize: 'xs',
  lineHeight: '20px',
  fontWeight: 'normal',
  color: 'brand.neutral.700',
};

export const formInputStyles = {
  fontSize: 'sm',
  lineHeight: '20px',
  color: 'brand.neutral.500',
  borderColor: 'brand.darkBlue.250',
  h: '48px',
  p: '12px 16px',
  _hover: {
    color: 'brand.neutral.600',
    borderColor: 'brand.darkBlue.300',
  },
  _focus: {
    color: 'brand.neutral.700',
    borderColor: 'brand.neutral.500',
  },
  _disabled: {
    color: 'brand.darkBlue.300',
    borderColor: 'brand.darkBlue.150',
  },
};

export const formErrorStyles = {
  fontSize: '2xs',
  color: 'red.800AA',
  position: 'absolute',
};

export const formDisclaimerStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.500',
  marginBottom: '32px',
};

export const emailAlertIconBgStyles = {
  bgColor: 'red.200',
};
