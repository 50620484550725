import React, { useContext, useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Stack, Text, useDisclosure } from '@chakra-ui/react';

import { useStatsigClient } from '@statsig/react-bindings';
import {
  BaselaneApplicationTypePopup,
  BaselaneButton,
  BaselaneEmptyStateCard,
  NoAccessTooltip,
} from '@shared/components';
import CardImage from '@core/components/NativeBankingPage/MainContent/components/Accounts/BaselaneAccounts/EmptyStateView/CardImage';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { NATIVE_BANK, UNIFIED_LB_PERSONAL_INFO } from '@routes';
import UserAccessContext from '@contexts/UserAccessContext';
import historyStateStorage from '@core/storage/historyStateStorage';
import BanksContext from '@contexts/BanksContext';
import { IllustrationBankingNewaccount } from '@illustrations';
import { DYNAMIC_CONFIGS } from '@core/constants/statsigKeys';

import {
  emptyStateContainerStyles,
  headingTextStyles,
  imageContainerStyles,
  leftElementContainerStyles,
  rightElementContainerStyles,
  subtextStyles,
} from './styles/emptyState.styles';

const EmptyStateView = () => {
  const location = useLocation();
  const startWithOpenedAccountTypeModal = location?.state?.startWithOpenedAccountTypeModal;

  const { isMin768 } = useBreakPoints();
  const { isWorkspaceOwner } = useContext(UserAccessContext);
  const { getDynamicConfig } = useStatsigClient();
  const config = getDynamicConfig(DYNAMIC_CONFIGS.APY_INTEREST);

  const navigate = useNavigate();

  const {
    isOpen: isBaselaneApplicationTypePopupOpen,
    onOpen: onBaselaneApplicationTypePopupOpen,
    onClose: onBaselaneApplicationTypePopupClose,
  } = useDisclosure();

  const addBaselaneAccountDrawerRef = useRef(null);
  const handleOpenBaselaneAccountDrawer = () => addBaselaneAccountDrawerRef.current.open();
  const { userHasDraftApplication, hasActiveBaselaneAccounts, getKYCUrl } = useContext(
    BanksContext
  );

  const openLBFlow = (type) => {
    const pathname = UNIFIED_LB_PERSONAL_INFO;
    const state = { from: NATIVE_BANK };

    if (type === 'business') {
      getKYCUrl();
    } else {
      historyStateStorage.write(state);
      navigate(pathname);
    }
  };

  const handleOpenBaselaneAccountClick = () => {
    if (hasActiveBaselaneAccounts) {
      handleOpenBaselaneAccountDrawer();
    } else {
      onBaselaneApplicationTypePopupOpen();
    }
  };

  let buttonText = 'Open Baselane Banking Account';
  if (!hasActiveBaselaneAccounts && userHasDraftApplication) {
    buttonText = 'Continue Application';
  }

  useEffect(() => {
    if (startWithOpenedAccountTypeModal) {
      onBaselaneApplicationTypePopupOpen();
    }
  }, [startWithOpenedAccountTypeModal]);

  return (
    <Stack direction={isMin768 ? 'row' : 'column'} {...emptyStateContainerStyles}>
      <Outlet context={{ isEmptyState: true }} />
      <Stack {...leftElementContainerStyles}>
        <Stack spacing="8px">
          <IllustrationBankingNewaccount />
          <Text {...headingTextStyles}>Open Baselane Banking Account</Text>
          <Text {...subtextStyles}>Open in minutes. No account fees or minimum balances.</Text>
        </Stack>

        <Box>
          {!isWorkspaceOwner ? (
            <NoAccessTooltip>
              <BaselaneButton
                variant="filled"
                palette="primary"
                size="lg"
                isDisabled={!isWorkspaceOwner}
                onClick={handleOpenBaselaneAccountClick}
              >
                {buttonText}
              </BaselaneButton>
            </NoAccessTooltip>
          ) : (
            <BaselaneButton
              variant="filled"
              palette="primary"
              size="lg"
              onClick={handleOpenBaselaneAccountClick}
            >
              {buttonText}
            </BaselaneButton>
          )}
        </Box>
        <BaselaneApplicationTypePopup
          handleActionButton={openLBFlow}
          isOpen={isBaselaneApplicationTypePopupOpen}
          onClose={onBaselaneApplicationTypePopupClose}
        />
      </Stack>
      <Box {...rightElementContainerStyles}>
        <Box>
          <BaselaneEmptyStateCard.TitleSection fontSize="sm" mb="12px">
            Banking built for rental property finance
          </BaselaneEmptyStateCard.TitleSection>
          <BaselaneEmptyStateCard.BulletPoints
            type="check"
            iconMarginRight="8px"
            alignItems="flex-start"
            mb="14px"
            _last={{ mb: 0 }}
          >
            <Text>
              Separate accounts for properties, security deposits, savings buffers & more opened
              with one click
            </Text>
            <Text>
              Up to {config?.get('apy') ?? '--'}% APY on savings accounts, FDIC-insured by Thread
              Bank
            </Text>
            <Text>Physical & virtual debit cards with spend controls</Text>
            <Text>Unlimited 1% cash back on debit card spend</Text>
          </BaselaneEmptyStateCard.BulletPoints>
        </Box>
      </Box>
      <Stack {...imageContainerStyles}>
        <CardImage />
      </Stack>
    </Stack>
  );
};

export default EmptyStateView;
