import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { ONBOARDING_GS_EASY_STEPS, TENANT_SCREENING } from '@routes';
import useOnboardingBookkeepingOption from '@hooks/useOnboardingBookkeepingOption';
import BaselaneSpinner from '@shared/components/BaselaneSpinner';
import useHandleTenantScreeningOption from './useHandleTenantScreeningOption';
import OnboardingOptions from './OnboardingOptions';
import OptionCardsScreens from './OptionCardsScreens';

const OnboardingTriageCards = () => {
  const { selection, setSelection } = useOnboardingBookkeepingOption();
  const navigate = useNavigate();
  const { handleTenantScreeningOption, isLoading } = useHandleTenantScreeningOption();

  const handleActionButton = async (e, card) => {
    sendSegmentEvent('onboarding_complete_path_selection', { feature: card.segmentEvent });

    if (card.id === 'bookkeeping-option') {
      sendSegmentEvent('onboarding_path_selection', { feature: 'bookkeeping' });
    } else if (card.id === 'tenant_screening-option') {
      sendSegmentEvent('onboarding_path_selection', { feature: card.segmentEvent });
      await handleTenantScreeningOption();
      navigate({ pathname: `/${TENANT_SCREENING}` });
    }

    if (card.id !== 'bookkeeping-option' && card.id !== 'tenant_screening-option') {
      navigate({ pathname: ONBOARDING_GS_EASY_STEPS, search: `?selection=${card.segmentEvent}` });
    }

    setSelection(card.segmentEvent);
    localStorage.setItem('onboarding-selection', card.segmentEvent);
  };

  return selection === 'bookkeeping' ? (
    <OptionCardsScreens selection={selection} />
  ) : (
    <>
      <OnboardingOptions {...{ handleActionButton }} isLoading={isLoading} />
      {isLoading ? (
        <Box>
          <BaselaneSpinner />
        </Box>
      ) : null}
    </>
  );
};

export default OnboardingTriageCards;
