import React from 'react';
import {
  Stack,
  FormControl,
  FormLabel,
  Checkbox,
  Box,
  Text,
  FormErrorMessage,
  useDisclosure,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import IconInfo from '@icons/manual/IconInfo';
import { BaselaneTooltip, BaselaneSingleDatePicker } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { formatDate } from '@core/utils/formatDate';
import { formErrorStyles, formLabelStyles } from '@shared/styles/input.style';

import ToggleWrapper from '../../components/ToggleWrapper';
import { fieldContainer } from '../../styles/shared.styles';
import { tooltipContainerStyles } from '../../styles/depositsAndFees.styles';

type Field = {
  label: string,
  type: string,
};
type Fields = {
  [key: string]: Field,
};

type LastMonthRentSectionProps = {
  sectionInfo: Array<Fields>,
  sectionId: string,
  lastMonthId: string,
  handleChange: Function,
  setFieldTouched: Function,
  setFieldValue: Function,
  setFormVariables: Function,
  values: Object,
  errors: Object,
  touched: Object,
};

function LastMonthRentSection({
  sectionInfo = [],
  sectionId = '',
  lastMonthId = '',
  handleChange = () => {},
  setFieldTouched = () => {},
  setFieldValue = () => {},
  setFormVariables = () => {},
  values = {},
  errors = {},
  touched = {},
}: LastMonthRentSectionProps) {
  const { isMin768 } = useBreakPoints();
  const years = { start: 2022, end: 2043 };
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCalendarClose = ({ date: dueDate, isFromApplyButton }) => {
    if (isMin768 || (!isMin768 && isFromApplyButton)) {
      setFieldValue('lastMonthRentDueDate', dueDate);
      const lastMonthRentDueDate = dueDate ? formatDate(dueDate, 'YYYY-MM-DD') : null;
      setFormVariables({
        payments: [{ id: lastMonthId, name: 'LAST_MONTH_RENT', dueDate: lastMonthRentDueDate }],
      });
    }
  };

  const titleSwitch = (label) => (
    <ToggleWrapper.Toggle
      key="last_month_rent_switch"
      title={label}
      htmlFor={sectionId}
      switchProps={{
        id: sectionId,
        isChecked: values.lastMonthRent,
        value: values.lastMonthRent,
        onChange: (e) => {
          handleChange(e);
          setFormVariables({
            payments: [
              {
                id: lastMonthId,
                name: 'LAST_MONTH_RENT',
                enabled: e.target.checked,
                dueDate: null,
                paid: false,
              },
            ],
          });

          if (!values.lastMonthRent) {
            setFieldTouched('lastMonthRentDueDate', false);
            setFieldValue('lastMonthRentDueDate', null);
            setFieldTouched('lastMonthRentPaid', false);
            setFieldValue('lastMonthRentPaid', false);
          }
        },
      }}
    />
  );

  const tooltipText = (
    <Text textAlign="center">
      By marking as paid, no invoice to
      <br />
      collect this payment will be generated.
      <br />
      However, a record of this payment will be
      <br /> available for you and your tenant.
    </Text>
  );
  const hasError =
    (errors.lastMonthRentDueDate && touched.lastMonthRentDueDate) ||
    (errors.lastMonthRentDueDateRequired && touched.lastMonthRentDueDate);

  return (
    <ToggleWrapper key="last_month_rent_container" showBottomBorder={false} isNested>
      {titleSwitch(sectionInfo.title.label)}
      {values.lastMonthRent && (
        <ToggleWrapper.Content
          key="last_month_rent_switch_content"
          styles={{ spacing: '0', mt: isMobile ? '16px' : '24px', mb: '0' }}
        >
          {/* Last Month Rent Due Date */}
          <FormControl
            {...fieldContainer}
            w={isMobile ? '100%' : 'calc(50% - 18px)'}
            isInvalid={errors.lastMonthRentDueDate && touched.lastMonthRentDueDate}
          >
            <FormLabel {...formLabelStyles.xs} htmlFor={sectionInfo.dueDate.label}>
              {sectionInfo.dueDate.label}
            </FormLabel>
            <BaselaneSingleDatePicker
              {...{
                ...sectionInfo.dueDate.props,
                date: values.lastMonthRentDueDate,
                value: values.lastMonthRentDueDate
                  ? `${formatDate(values.lastMonthRentDueDate, 'MMM DD, YYYY')}`
                  : '',
                dateFormat: 'MMM dd, yyyy',
                handleCalendarClose,
                size: 'lg',
                years,
                isOpen,
                onOpen,
                onClose,
                hideInputIcon: true,
                fixedHeight: true,
                placement: 'bottom-start',
                showInModal: !isMin768,
                className: `form-datepicker ${hasError ? 'has-error' : ''}`,
              }}
            />
            <FormErrorMessage {...formErrorStyles}>{errors.lastMonthRentDueDate}</FormErrorMessage>
          </FormControl>

          {/* Mark as Paid */}
          <FormControl as={Stack} direction="row" mt={isMobile ? '0' : '8px !important'}>
            {!isMobile && (
              <Box {...tooltipContainerStyles}>
                <BaselaneTooltip label={tooltipText} styles={{ w: '303px' }} placement="top">
                  <IconInfo width="16" height="16" color="#3A4B5B" _hover={{ color: '#192C3E' }} />
                </BaselaneTooltip>
              </Box>
            )}
            {!isMobile && (
              <FormLabel {...formLabelStyles.sm} htmlFor={sectionInfo.paid.label} mb="0">
                {sectionInfo.paid.label}
              </FormLabel>
            )}
            <Checkbox
              as="span"
              {...sectionInfo.paid.props}
              className="primary-500"
              size={isMobile ? 'lg' : 'md'}
              {...sectionInfo.paid.props}
              isChecked={values.lastMonthRentPaid}
              onChange={handleChange}
              onBlur={(e) => {
                const paid = e.target.checked;
                setFormVariables({
                  payments: [
                    {
                      id: lastMonthId,
                      name: 'LAST_MONTH_RENT',
                      paid,
                    },
                  ],
                });
              }}
            />
            {isMobile && (
              <FormLabel {...formLabelStyles.sm} htmlFor={sectionInfo.paid.label} mb="0">
                {sectionInfo.paid.label}
              </FormLabel>
            )}
          </FormControl>
        </ToggleWrapper.Content>
      )}
    </ToggleWrapper>
  );
}

export default LastMonthRentSection;
