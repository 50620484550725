import React from 'react';
import { useToast } from '@chakra-ui/react';

import { BaselaneButton } from '@shared/components';
import { Icon16Link } from '@icons/16px';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

const CopyApplicationLink = ({ url }: { url: String }) => {
  const toast = useToast();

  const showLinkCopiedToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Link copied',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  return (
    <BaselaneButton
      variant="tonal"
      palette="primary"
      size="md"
      leftIcon={<Icon16Link />}
      onClick={() => {
        sendSegmentEvent('tenant_screening_fe_copy_link_clicked', {
          magicLink: url,
        });
        if (navigator && navigator?.clipboard) {
          navigator.clipboard.writeText(url).then(() => {
            showLinkCopiedToast();
          });
        }
      }}
    >
      Copy application link
    </BaselaneButton>
  );
};

export default CopyApplicationLink;
