import process from 'process';
import { DateTime } from 'luxon';
import { dateFormatter } from '@core/utils/formatDate';

// for
import { noSessionCookieSignature } from '@core/apollo/apolloClient';
import { LOGIN, SESSION_EXPIRED } from '@core/constants/routes';
import clearLocationState from '@core/utils/clearLocationState';
import inActivityLogoutStorage from '@core/storage/inActivityLogoutStorage';
import { manageStorageAfterLogout } from '@core/storage/helpers/cleanup.helpers';

import { VERIFICATION_ID_UNAVAILABLE } from './tenantScreeningDetail.helpers';

export async function getReportsByApplicationId(applicationId) {
  if (!applicationId) {
    console.error('Invalid applicationId: A valid application ID must be provided.');
    return { error: 'Invalid applicationId: A valid application ID must be provided.' };
  }

  // Extract the base URL from the environment variable
  const orchestrationUrl = process.env.REACT_APP_ORCHESTRATION_URL?.split('/graphql')?.[0];
  if (!orchestrationUrl) {
    console.error('Configuration error: REACT_APP_ORCHESTRATION_URL is not set or invalid.');
    return { error: 'Configuration error: REACT_APP_ORCHESTRATION_URL is not set or invalid.' };
  }

  const requestUrl = `${orchestrationUrl}/api/tenantScreening/application/${applicationId}`;

  try {
    const response = await fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (!response.ok) {
      const errorMessage = `Request failed with status ${response.status}: ${response.statusText}`;

      if (response.status === noSessionCookieSignature.statusCode) {
        // delete any data that was stored in location, just in case
        clearLocationState();
        // set inActivityLogoutStorage to 'true'
        inActivityLogoutStorage.write();
        // delete local storage (other than specificically flagged items)
        manageStorageAfterLogout();
        // redirect to login
        window.location.href = `${LOGIN}/${SESSION_EXPIRED}`;
      }

      console.error(errorMessage);

      return { error: errorMessage };
    }

    const data = await response.json();
    return { data };
  } catch (error) {
    console.error('Error during fetch operation:', error.message);
    return { error: `Failed to fetch application data. Reason: ${error.message}` };
  }
}

export async function getReportByid(applicationId, applicantId, verificationId) {
  const body = { applicationId, applicantId, verificationId };
  if (!applicationId || !applicantId || !verificationId) {
    console.error(
      'Invalid application data: A valid application ID, applicant ID and verification ID must be provided.'
    );
    return {
      error:
        'Invalid application data: A valid application ID, applicant ID and verification ID must be provided.',
    };
  }

  // Extract the base URL from the environment variable
  const orchestrationUrl = process.env.REACT_APP_ORCHESTRATION_URL?.split('/graphql')?.[0];
  if (!orchestrationUrl) {
    console.error('Configuration error: REACT_APP_ORCHESTRATION_URL is not set or invalid.');
    return { error: 'Configuration error: REACT_APP_ORCHESTRATION_URL is not set or invalid.' };
  }

  const requestUrl = `${orchestrationUrl}/api/tenantScreening/report`;

  try {
    const response = await fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      credentials: 'include',
    });

    if (!response.ok) {
      const errorMessage = `Request failed with status ${response.status}: ${response.statusText}`;
      if (response.status === noSessionCookieSignature.statusCode) {
        // delete any data that was stored in location, just in case
        clearLocationState();
        // set inActivityLogoutStorage to 'true'
        inActivityLogoutStorage.write();
        // delete local storage (other than specificically flagged items)
        manageStorageAfterLogout();
        // redirect to login
        window.location.href = `${LOGIN}/${SESSION_EXPIRED}`;
      }
      console.error(errorMessage);
      return { error: errorMessage };
    }

    const data = await response.json();
    return { data };
  } catch (error) {
    console.error('Error during fetch operation:', error.message);
    return { error: `Failed to fetch report data. Reason: ${error.message}` };
  }
}

export async function getAdditionalDocsByApplicantId(applicantId) {
  if (!applicantId) {
    console.error('Invalid application data: A valid applicant ID must be provided.');
    return {
      error: 'Invalid application data: A valid applicant ID must be provided.',
    };
  }

  // Extract the base URL from the environment variable
  const orchestrationUrl = process.env.REACT_APP_ORCHESTRATION_URL?.split('/graphql')?.[0];
  if (!orchestrationUrl) {
    console.error('Configuration error: REACT_APP_ORCHESTRATION_URL is not set or invalid.');
    return { error: 'Configuration error: REACT_APP_ORCHESTRATION_URL is not set or invalid.' };
  }

  const requestUrl = `${orchestrationUrl}/api/tenantScreening/additionalDocuments/applicant/${applicantId}`;

  try {
    const response = await fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (!response.ok) {
      const errorMessage = `Request failed with status ${response.status}: ${response.statusText}`;
      if (response.status === noSessionCookieSignature.statusCode) {
        // delete any data that was stored in location, just in case
        clearLocationState();
        // set inActivityLogoutStorage to 'true'
        inActivityLogoutStorage.write();
        // delete local storage (other than specificically flagged items)
        manageStorageAfterLogout();
        // redirect to login
        window.location.href = `${LOGIN}/${SESSION_EXPIRED}`;
      }
      console.error(errorMessage);
      return { error: errorMessage };
    }

    const data = await response.json();
    return { data };
  } catch (error) {
    console.error('Error during fetch operation:', error.message);
    return { error: `Failed to fetch report data. Reason: ${error.message}` };
  }
}

export const isReportDataInvalid = (reportData, verificationId) => {
  return (
    !reportData ||
    typeof reportData !== 'object' ||
    Object.keys(reportData).length === 0 ||
    !reportData.report ||
    verificationId === VERIFICATION_ID_UNAVAILABLE
  );
};

export function getHousingType(boomAddress) {
  return boomAddress.housing_type;
}

export function calculateMonthsOfResidency(moveInDate, moveOutDate) {
  const moveOut = moveOutDate ? DateTime.fromISO(moveOutDate) : DateTime.now();
  const totalMonths = DateTime.fromISO(moveOut).diff(DateTime.fromISO(moveInDate), 'months').months;
  return Math.floor(totalMonths);
}

export function getDatesOfResidency(moveInDate, moveOutDate, isCurrentResidency) {
  return `${dateFormatter(moveInDate, 'MMM yyyy')} - ${
    isCurrentResidency ? 'Present' : dateFormatter(moveOutDate, 'MMM yyyy')
  }`;
}
