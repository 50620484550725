import React from 'react';
import { Text, HStack, Stack, Box } from '@chakra-ui/react';
import IconScheduled from '@icons/manual/IconScheduled';
import IconProcessing from '@icons/manual/IconProcessing';
import IconFailed from '@icons/manual/IconFailed';
import IconCompleted from '@icons/manual/IconCompleted';
import IconReturned from '@icons/manual/IconReturned';
import IconCanceled from '@icons/manual/IconCanceled';
import { BaselaneBadge } from '@core/components/Shared/components';

export const STATUS_TYPES = {
  SCHEDULED: {
    label: 'Scheduled',
    drawerLabel: 'Scheduled For',
    icon: IconScheduled,
    color: 'brand.blue.700',
    mobileColor: 'blue',
  },
  PROCESSING: {
    label: 'Processing',
    drawerLabel: 'Processed On',
    icon: IconProcessing,
    color: 'brand.blue.700',
    mobileColor: 'blue',
  },
  FAILED: {
    label: 'Failed',
    drawerLabel: 'Processed On',
    icon: IconFailed,
    color: 'red.800AA',
    mobileColor: 'red',
  },
  COMPLETED: {
    label: 'Completed',
    drawerLabel: 'Transfer Completed On',
    icon: IconCompleted,
    color: 'green.800AA',
    mobileColor: 'green',
  },
  RETURNED: {
    label: 'Returned',
    drawerLabel: 'Returned On',
    icon: IconReturned,
    color: 'red.800AA',
    mobileColor: 'red',
  },
  CANCELED: {
    label: 'Canceled',
    drawerLabel: 'Canceled On',
    icon: IconCanceled,
    color: 'red.800AA',
    mobileColor: 'red',
  },
  IN_TRANSIT: {
    label: 'In Transit',
    drawerLabel: 'In Transit',
    icon: IconProcessing,
    color: 'brand.blue.700',
    mobileColor: 'blue',
  },
  DELIVERED: {
    label: 'Delivered',
    drawerLabel: 'Delivered On',
    icon: IconCompleted,
    color: 'green.800AA',
    mobileColor: 'green',
  },
  DEPOSITED: {
    label: 'Deposited',
    drawerLabel: 'Deposited On',
    icon: IconCompleted,
    color: 'green.800AA',
    mobileColor: 'green',
  },
};

interface StatusElementProps {
  isMobile?: boolean;
  status: string; // keyof typeof STATUS_TYPES
}

export function StatusElement({ isMobile, status }: StatusElementProps) {
  if (!status) return null;

  const { icon: Icon, label, color, mobileColor } = STATUS_TYPES[status];

  if (isMobile) {
    return (
      <BaselaneBadge
        type="badge"
        color={mobileColor}
        text={label}
        backgroundStyles={{ ml: '16px' }}
      />
    );
  }

  return (
    <Box p="12px 24px">
      <HStack alignItems="center" minW="24px" minH="24px">
        <Box minW="24px" minH="24px">
          {status === 'RETURNED' ? <Icon color="#E04141" bgColor="#FDEEED" /> : <Icon />}
        </Box>
        <Stack m="0 0 0 6px">
          <Text color={color}>{label}</Text>
        </Stack>
      </HStack>
    </Box>
  );
}
