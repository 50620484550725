/* eslint-disable react/prop-types */
import React from 'react';
import { Text, Tbody } from '@chakra-ui/react';
import {
  BaselaneCardStack,
  BaselaneResponsiveCell,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
} from '@shared/components';
import { formatDate } from '@core/utils/formatDate';
import ReportHeader from '../../components/ReportHeader';
import { isFieldNotObject } from '../../helpers/tenantScreening.helpers';

const CreditInquiries = ({ creditInquiries = [] }) => {
  const tableColumnConfig = {
    columns: [
      {
        isSortable: false,
        styles: {
          w: { base: '100%', sm: '320px' },
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
      {
        isSortable: false,
        styles: {
          w: '100%',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
    ],
  };

  if (!creditInquiries.length) {
    return (
      <BaselaneCardStack
        id="no-credit-inquiries-data-card"
        size="md"
        width="100%"
        items={[
          {
            id: 'header',
            content: <ReportHeader>Credit inquiries</ReportHeader>,
          },
          {
            id: 'no-credit-inquiries-information',
            content: <Text textStyle="sm">No data available</Text>,
          },
        ]}
      />
    );
  }

  return (
    <BaselaneCardStack
      id="credit-inquiries-card"
      key="credit-inquiries-card"
      size="md"
      items={[
        {
          id: 'header',
          content: <ReportHeader>Credit inquiries</ReportHeader>,
        },
        {
          id: 'credit-inquiries-card-body',
          content: (
            <BaselaneResponsiveTable
              id="review-columns"
              config={tableColumnConfig}
              maxW="600px"
              tableContainerStyles={{
                overflowY: 'initial',
                overflowX: 'initial',
              }}
            >
              <Tbody>
                <BaselaneResponsiveTableRows
                  items={creditInquiries.map((inquiry) => ({
                    heading: inquiry.inquirer,
                    data: formatDate(inquiry.date, 'YYYY-MM-DD'),
                  }))}
                  customRow
                  renderItem={(row) => {
                    return (
                      <BaselaneResponsiveTableRow
                        key={row.heading}
                        chevronCellStyles={{ w: 0 }}
                        contentContainerStyles={{ border: 'none' }}
                      >
                        <BaselaneResponsiveCell configIndex={0} p={0} colspan="2">
                          <Text as="span" textStyle="sm" color="brand.neutral.700">
                            {row.heading}
                          </Text>
                        </BaselaneResponsiveCell>
                        <BaselaneResponsiveCell configIndex={1} p={0}>
                          <Text textStyle="sm">{isFieldNotObject(row.data) ? row.data : '--'}</Text>
                        </BaselaneResponsiveCell>
                      </BaselaneResponsiveTableRow>
                    );
                  }}
                />
              </Tbody>
            </BaselaneResponsiveTable>
          ),
        },
      ]}
    />
  );
};

export default CreditInquiries;
