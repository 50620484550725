export const titleStyles = (isMobile) => ({
  textStyle: isMobile ? 'headline-sm' : 'headline-lg',
  color: isMobile ? 'brand.blue.700' : 'brand.neutral.900',
  pb: isMobile ? '0!important' : '0',
  pl: isMobile ? '24px' : '0',
  mt: isMobile ? '20px' : '0',
});

export const cStyles = (isMobile) => ({
  pb: isMobile ? '0' : '20px',
  pr: '32px',
});
