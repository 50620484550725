import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Box, HStack, Spacer, Stack, Text, Flex } from '@chakra-ui/react';
import IconTimer from '@icons/manual/IconTimer';
import IconCurrentCircle from '@icons/manual/IconCurrentCircle';
import IconChevronRight from '@icons/manual/IconChevronRight';
import { getTrackerElementNodes } from './helpers/trackerBox.helper';
import BaselaneButton from '../BaselaneButton';
import {
  trackerStyles,
  trackerHeaderStyles,
  trackerStepStyles,
  trackerDescriptionStyles,
  trackerTimeStyles,
  completedTrackerStyles,
  trackerStepCompletedStyles,
  trackerHeaderCompletedStyles,
  trackerDescriptionCompletedStyles,
  disabledTrackerHeaderStyles,
  trackerHideBlockStyles,
  swapHeaderItemStyles,
  mobileContentItemStyles,
  trackerLowerBlockStyles,
  showDesktopStyles,
  descriptionHeaderStyles,
  trackerSuccessMessageCompletedStyles,
  mobileContentBoxStyles,
  headerContainerStyles,
  buttonMobileStyles,
  iconMobileStyles,
  startButtonStyles,
} from '../../styles/onboarding.style';
import './styles/trackerBox.styles.scss';

type TrackerBoxProps = {
  trackerData: Object,
};

const TrackerBox = ({ trackerData }: TrackerBoxProps) => {
  const {
    title,
    icon,
    descriptionTitle = null,
    description = '',
    estimatedTime,
    buttonRoute,
    successMessage,
    successMobileDescription,
    isCompleted = false,
    isDisabled = false,
    disabledDescriptionTitle = null,
    disabledDescription,
    onClickTrackerBox = () => {},
    position,
    isFinalStep = false,
    hasButton,
    customTrackerStyles,
    customCompletedTrackerStyles,
    showMoreFields,
    customTitleStyles,
    canEditBox,
  } = trackerData;

  const navigate = useNavigate();
  const trackerBoxRef = useRef(null);

  const openTrackerBox = (e) => {
    if (!isDisabled) {
      onClickTrackerBox(e);
    }
  };

  const handleOnCompletedBoxClick = () => {
    if (canEditBox) {
      openTrackerBox();
    } else {
      navigate(buttonRoute);
    }
  };

  const getCompletedTrackerBox = () => (
    <Box
      {...{
        ...trackerStyles(false, false),
        ...completedTrackerStyles,
        ...customCompletedTrackerStyles,
      }}
      onClick={handleOnCompletedBoxClick}
    >
      <HStack {...{ ...swapHeaderItemStyles, ...mobileContentItemStyles }}>
        <HStack {...{ ...trackerHeaderStyles, ...trackerHeaderCompletedStyles }}>
          {icon}
          <Box {...trackerHideBlockStyles} {...(isMobile && customTitleStyles)}>
            {title?.toUpperCase()}
          </Box>
        </HStack>
        <Spacer {...trackerHideBlockStyles} />
        <Box {...trackerStepCompletedStyles}>
          <IconCurrentCircle bgfill="#56C719" fillColor="#ffffff" bigIcon />
        </Box>
      </HStack>
      {successMessage && (
        <Box {...{ ...trackerDescriptionStyles, ...trackerDescriptionCompletedStyles }}>
          {successMessage}
        </Box>
      )}
      {showMoreFields && (
        <HStack
          {...{
            ...trackerLowerBlockStyles,
            ...mobileContentItemStyles,
            ...showDesktopStyles,
          }}
        >
          <Spacer {...trackerHideBlockStyles} />
        </HStack>
      )}
      {showMoreFields && (
        <Box {...trackerSuccessMessageCompletedStyles}>{successMobileDescription}</Box>
      )}
      {hasButton && (
        <Flex direction="row" {...startButtonStyles}>
          <BaselaneButton variant="outline" palette="neutral" {...buttonMobileStyles}>
            View Details &nbsp; <IconChevronRight w={6} h={10} {...iconMobileStyles} />
          </BaselaneButton>
        </Flex>
      )}
    </Box>
  );

  const addHoverStyles = () => {
    const box = document.getElementById(`onboarding-box-${title}`);
    if (!isDisabled && !isCompleted && box) {
      getTrackerElementNodes(box, true);
    }
  };

  const removeHoverStyles = () => {
    const box = document.getElementById(`onboarding-box-${title}`);
    if (!isDisabled && !isCompleted && box) {
      getTrackerElementNodes(box, false);
    }
  };

  useEffect(() => {
    const { current } = trackerBoxRef;
    if (current !== null) {
      current.addEventListener('mouseover', addHoverStyles);
      current.addEventListener('mouseout', removeHoverStyles);
      return () => {
        current.removeEventListener('mouseover', addHoverStyles);
        current.removeEventListener('mouseout', removeHoverStyles);
      };
    }
    return () => {};
  }, [isCompleted, isDisabled]);

  const getTrackerBox = () => (
    <Box
      className={`onboarding-box ${(isDisabled && 'disabled') || ''}`}
      id={`onboarding-box-${title}`}
      ref={trackerBoxRef}
      onClick={openTrackerBox}
      {...trackerStyles(!isDisabled, isFinalStep)}
      {...(isMobile && customTrackerStyles)}
    >
      <Stack id="onboarding-box-header" {...headerContainerStyles}>
        <HStack {...{ ...swapHeaderItemStyles, ...mobileContentBoxStyles }}>
          <HStack {...trackerHeaderStyles} {...(isDisabled && disabledTrackerHeaderStyles)}>
            {icon}
            <Box {...trackerHideBlockStyles} {...(isMobile && customTitleStyles)}>
              {title?.toUpperCase()}
            </Box>
          </HStack>
          <Spacer {...trackerHideBlockStyles} />
          <Box {...trackerStepStyles} {...(isDisabled && disabledTrackerHeaderStyles)}>
            {position + 1}
          </Box>
        </HStack>
      </Stack>
      {description && (
        <Box id="onboarding-box-body" {...trackerDescriptionStyles}>
          <Text id="onboarding-box-body-header" {...descriptionHeaderStyles}>
            {isDisabled ? disabledDescriptionTitle : descriptionTitle}
          </Text>
          <Text>{isDisabled ? disabledDescription : description}</Text>
        </Box>
      )}
      {estimatedTime && (
        <HStack {...{ ...trackerLowerBlockStyles, ...mobileContentItemStyles }}>
          <Box id="onboarding-box-time" {...trackerHideBlockStyles} ml="0px !important">
            <IconTimer />
            <Box {...trackerTimeStyles}>{`~${estimatedTime} min to complete`}</Box>
          </Box>
        </HStack>
      )}
      {hasButton && (
        <Flex direction="row" {...startButtonStyles}>
          <BaselaneButton variant="transparent" pallete="neutral" isDisabled={isDisabled}>
            Start &nbsp; <IconChevronRight w={6} h={10} />
          </BaselaneButton>
        </Flex>
      )}
    </Box>
  );
  return isCompleted ? getCompletedTrackerBox() : getTrackerBox();
};

export default TrackerBox;
