import {
  UPDATE_PAYEE_PAYMENT_METHOD_WIRE,
  UPDATE_PAYEE_PAYMENT_METHOD_ACH,
  UPDATE_PAYEE_PAYMENT_METHOD_CHECK,
  ADD_PAYEE_PAYMENT_METHOD_CHECK,
  ADD_PAYEE_PAYMENT_METHOD_WIRE,
  ADD_PAYEE_PAYMENT_METHOD_ACH,
} from '@core/apollo/queries';
import { transformRecipientPaymentMethods, steps } from './formHelpers';

export const getMutationName = (selectedPaymentMethod) => {
  let name = ADD_PAYEE_PAYMENT_METHOD_ACH;
  if (selectedPaymentMethod?.type === 'ACH') {
    if (selectedPaymentMethod?.status === 'unsaved') {
      name = ADD_PAYEE_PAYMENT_METHOD_ACH;
    } else {
      name = UPDATE_PAYEE_PAYMENT_METHOD_ACH;
    }
  }
  if (selectedPaymentMethod?.type === 'Wire') {
    if (selectedPaymentMethod?.status === 'unsaved') {
      name = ADD_PAYEE_PAYMENT_METHOD_WIRE;
    } else {
      name = UPDATE_PAYEE_PAYMENT_METHOD_WIRE;
    }
  }
  if (selectedPaymentMethod?.type === 'Check') {
    if (selectedPaymentMethod?.status === 'unsaved') {
      name = ADD_PAYEE_PAYMENT_METHOD_CHECK;
    } else {
      name = UPDATE_PAYEE_PAYMENT_METHOD_CHECK;
    }
  }
  return name;
};

export const setPaymentMethodMutationValues = (newValues, selectedPaymentMethod) => {
  let obj;

  if (selectedPaymentMethod?.type === 'ACH') {
    obj = {
      achPaymentMethods: {
        accountType: newValues?.accountType,
        accountHolderName: newValues?.accountHolderName,
        routingNumber: newValues?.routingNumber,
        accountNumber: newValues?.accountNumber,
      },
    };
    if (selectedPaymentMethod?.status === 'unsaved') {
      obj.achPaymentMethods.type = 'ACH';
      delete obj.achPaymentMethods.bankName;
    } else {
      obj.achPaymentMethods.id = parseFloat(newValues?.paymentMethodId || newValues?.id);
    }
  }
  if (selectedPaymentMethod?.type === 'Wire') {
    const { address, city, state, zipcode, unit } = newValues;

    obj = {
      wirePaymentMethods: {
        address: { street: address, city, state, postalCode: zipcode, country: 'US', unit },
        accountHolderName: newValues?.accountHolderName,
        routingNumber: newValues?.routingNumber,
        accountNumber: newValues?.accountNumber,
      },
    };
    if (selectedPaymentMethod?.status === 'unsaved') {
      obj.wirePaymentMethods.type = 'WIRE_TRANSFER';
      delete obj.wirePaymentMethods.bankName;
    } else {
      obj.wirePaymentMethods.id = parseFloat(newValues?.paymentMethodId || newValues?.id);
    }
  }
  if (selectedPaymentMethod?.type === 'Check') {
    const { address, city, state, zipcode, unit } = newValues;

    obj = {
      checkPaymentMethods: {
        address: { street: address, city, state, postalCode: zipcode, country: 'US', unit },
        name: newValues?.name || '',
      },
    };
    if (selectedPaymentMethod?.status === 'unsaved') {
      obj.checkPaymentMethods.type = 'CHECK_PAYMENT';
      delete obj.checkPaymentMethods.bankName;
    } else {
      obj.checkPaymentMethods.id = parseFloat(newValues?.paymentMethodId || newValues?.id);
    }
  }
  return obj;
};

export const setFormValuesHelper = (
  selectedPaymentMethod,
  res,
  setFieldValue,
  startDate,
  endDate,
  updateRecipient,
  setSelectedPaymentMethod,
  setIsCurrentStepLoading,
  setCurrentStep,
  currentStep,
  recipient,
  serverRecipient,
  updateServerRecipient,
  setTouched,
  isBankingFeatureEnabled
) => {
  const { achPaymentMethods, wirePaymentMethods, checkPaymentMethods } =
    res.data.updatePayeePaymentMethod || res.data.addPayeePaymentMethod;

  // Update server recipient
  const mergedPaymentMethods = {
    ...(serverRecipient?.paymentMethods || {}),
    ...(achPaymentMethods && { achPaymentMethods }),
    ...(wirePaymentMethods && { wirePaymentMethods }),
    ...(checkPaymentMethods && { checkPaymentMethods }),
  };
  const updatedRecipient = {
    ...serverRecipient,
    paymentMethods: { ...mergedPaymentMethods },
  };
  updateServerRecipient(updatedRecipient);

  updateRecipient(transformRecipientPaymentMethods(updatedRecipient, isBankingFeatureEnabled));

  const newPaymentMethodId =
    achPaymentMethods?.id || wirePaymentMethods?.id || checkPaymentMethods?.id;

  const newSelectedPaymentMethod = transformRecipientPaymentMethods(
    updatedRecipient,
    isBankingFeatureEnabled
  )
    ?.paymentMethods?.flatMap((method) => method.items)
    .filter((m) => m?.id === newPaymentMethodId);

  setSelectedPaymentMethod(newSelectedPaymentMethod[0]);
  setFieldValue('paymentMethodId', newPaymentMethodId);
  setIsCurrentStepLoading(false);
  setCurrentStep(steps.PAYMENT_DETAILS);
  setTouched({});
};
