import React, { useEffect, useState } from 'react';
import { Box, Heading, Flex, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import {
  BaselaneButton,
  BaselaneButtonGroup,
  BaselaneDivider,
  BaselaneButtonIcon,
} from '@shared/components';

import ToggleWrapper from '@pages/LeasesPage/LeaseSection/components/ToggleWrapper';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { Icon16Delete } from '@icons/16px';
import formatCurrency from '@core/utils/formatCurrency';
import LateFeePanel from './LateFeeInputPanel';
import MonthlyMaximum from './MonthlyMaximum';
import {
  formValidation,
  getInitialFeeBasedOnRent,
  getDailyFeeBasedOnRent,
  getMonthlyFeeBasedOnRent,
} from '../../../formHelpers/leaseTermForm.helper';
import PAYMENT_TYPE from '../../../../models/PaymentTypeEnum';

type Props = {
  setLateFeePayments: Function,
  onLateFeeValuesChange?: Function,
};

const LateFee = ({ setLateFeePayments, onLateFeeValuesChange }: Props) => {
  const { values, setFieldValue, setValues } = useFormikContext();
  const [showAddLateFeeButton, setShowAddLateFeeButton] = useState(false);
  const [isFeeSetupOpen, setIsFeeSetupOpen] = useState(false);
  const [isDailyFeeSetupOpen, setIsDailyFeeSetupOpen] = useState(false);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const initialFeeBasedOnRent = getInitialFeeBasedOnRent(values);
  const dailyFeeBasedOnRent = getDailyFeeBasedOnRent(values);
  const monthlyFeeBasedOnRent = getMonthlyFeeBasedOnRent(values);

  useEffect(() => {
    // lift stay up to use it in RC drawer which is not inside formikContext
    onLateFeeValuesChange?.(values);
  }, [values]);

  useEffect(() => {
    setShowAddLateFeeButton(!values.initialFeeEnabled);
    setIsSummaryOpen(values.initialFeeEnabled);
  }, [values.initialFeeEnabled]);

  const clearedLateFeeValues = {
    initialFeeType: 'FLAT',
    initialFeeAmount: null,
    initialFeePercent: '',
    initialDueDate: 1,
    incrementAmountType: 'FLAT',
    incrementAmount: null,
    incrementPercent: '',
    feeCapType: 'FLAT',
    feeCapAmount: null,
    feeCapPercent: '',
  };

  const initialFee =
    values.initialFeeType === 'FLAT' ? values.initialFeeAmount : initialFeeBasedOnRent;

  const dailyFee =
    values.incrementAmountType === 'FLAT' ? values.incrementAmount : dailyFeeBasedOnRent;

  const monthlyFee = values.feeCapType === 'FLAT' ? values.feeCapAmount : monthlyFeeBasedOnRent;

  const readyToSave = isDailyFeeSetupOpen ? initialFee && dailyFee : initialFee;

  const initialFeePayment = {
    id: values.lateFeeId,
    name: PAYMENT_TYPE.LATE_FEE,
    enabled: Boolean(initialFee),
    amount: initialFee,
    dueDays: values?.initialDueDate,
    lateFeeMetadata: {
      initialFeeType: values.initialFeeType,
      initialFeeValue:
        values.initialFeeType === 'FLAT' ? values.initialFeeAmount : values.initialFeePercent,
    },
  };
  const dailyFeePayment = {
    id: values.dailyLateFeeId,
    name: PAYMENT_TYPE.DAILY_LATE_FEE,
    enabled: Boolean(dailyFee),
    amount: dailyFee,
    dueDays: null,
    lateFeeMetadata: {
      incrementFeeType: values.incrementAmountType,
      incrementFeeValue:
        values.incrementAmountType === 'FLAT' ? values.incrementAmount : values.incrementPercent,
      feeCapType: values.feeCapType,
      feeCapValue: values.feeCapType === 'FLAT' ? values.feeCapAmount : +values.feeCapPercent,
    },
  };

  const handleReset = () => {
    setValues({
      ...values,
      ...clearedLateFeeValues,
    });
    setShowAddLateFeeButton(true);
    setLateFeePayments([
      { ...initialFeePayment, enabled: false },
      { ...dailyFeePayment, enabled: false },
    ]);
    onLateFeeValuesChange?.(values);
  };

  const handleSave = () => {
    const errors = formValidation(values);
    const isValid = !errors.initialExceedsMaximum && !errors.invalidDueDate;
    if (!isValid) return;

    setLateFeePayments([initialFeePayment, dailyFeePayment]);
    sendSegmentEvent('rc_late_fee_added', {
      late_fee_initial_amount: initialFee,
      late_fee_initial_applied: values?.initialDueDate,
      daily_late_fee: dailyFee,
      late_fee_maximum: monthlyFee,
      entry_point: onLateFeeValuesChange ? 'rc_details' : 'rc_setup_onboarding',
    });
    setIsFeeSetupOpen(false);
    setIsSummaryOpen(true);
    onLateFeeValuesChange?.(values);
  };

  const addFeeButton = (
    <ToggleWrapper.Toggle
      title="Add late fee"
      htmlFor="late-fee"
      hideToggle
      switchProps={{
        id: 'late-fee',
        onClick: () => {
          setIsFeeSetupOpen(true);
          setShowAddLateFeeButton(false);
          setIsSummaryOpen(false);
          setValues({
            ...values,
            ...clearedLateFeeValues,
          });
        },
      }}
    />
  );

  const dailyFeeSetup = (
    <>
      <ToggleWrapper.Toggle
        title="Daily late fees"
        switchProps={{
          id: 'daily-fee-toggle',
          isChecked: isDailyFeeSetupOpen,
          value: isDailyFeeSetupOpen,
          onChange: () => {
            setIsDailyFeeSetupOpen(!isDailyFeeSetupOpen);
            setFieldValue('dailyFeesEnabled', !isDailyFeeSetupOpen);
          },
        }}
      />
      {isDailyFeeSetupOpen ? (
        <Box mt={1}>
          <LateFeePanel
            isDueDateDisabled
            calculatedAmountBasedOnRent={dailyFeeBasedOnRent}
            feeType={values.incrementAmountType}
            feeAmount={values.incrementAmount}
            feePercent={values.incrementPercent}
            onFeeTypeChange={(value) => setFieldValue('incrementAmountType', value)}
            onFeeAmountChange={(value) => setFieldValue('incrementAmount', value)}
            onFeePercentChange={(value) => setFieldValue('incrementPercent', value)}
          />

          <MonthlyMaximum monthlyFeeBasedOnRent={monthlyFeeBasedOnRent} />
        </Box>
      ) : null}
    </>
  );

  const lateFeeSetup = (
    <Box p={2} borderRadius="4px" border="1px solid #DADFE6" background="#FAFBFD">
      <Heading size="headline-sm" mb={2}>
        One-time initial late fee
      </Heading>
      <LateFeePanel
        calculatedAmountBasedOnRent={initialFeeBasedOnRent}
        feeType={values.initialFeeType}
        feeAmount={values.initialFeeAmount}
        feePercent={values.initialFeePercent}
        daysAfterDueDate={values.initialDueDate}
        onDueDateChange={(value) => setFieldValue('initialDueDate', Number(value))}
        onFeeTypeChange={(value) => setFieldValue('initialFeeType', value)}
        onFeeAmountChange={(value) => setFieldValue('initialFeeAmount', value)}
        onFeePercentChange={(value) => setFieldValue('initialFeePercent', value)}
      />
      <BaselaneDivider styles={{ mt: '16px', mb: '8px' }} />
      {dailyFeeSetup}
      <BaselaneDivider styles={{ mt: '8px', mb: '16px' }} />
      <BaselaneButtonGroup size="sm">
        <BaselaneButton
          id="save-fee-button"
          variant="filled"
          palette="primary"
          size="sm"
          onClick={handleSave}
          isDisabled={!readyToSave}
        >
          Save Fees
        </BaselaneButton>
        <BaselaneButton
          id="cancel-fee-button"
          variant="transparent"
          palette="danger"
          size="sm"
          onClick={() => {
            setIsFeeSetupOpen(false);
            setShowAddLateFeeButton(true);
            setValues({
              ...values,
              ...clearedLateFeeValues,
            });
          }}
          styles={{ marginLeft: 1.5 }}
        >
          Cancel
        </BaselaneButton>
      </BaselaneButtonGroup>
    </Box>
  );

  const summary = initialFee && (
    <Flex
      p={2}
      backgroundColor="#E1F4FF"
      border="1px solid #bee5ff"
      borderRadius="4px"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box gap={0.5}>
        <Text textStyle="sm">
          <b>LATE FEE</b>
        </Text>
        {initialFee ? (
          <Text textStyle="sm">
            <b>
              {values.initialFeeType === 'FLAT'
                ? `${formatCurrency(initialFee).inDollars}`
                : `${values.initialFeePercent}% rent`}
            </b>{' '}
            if overdue by {values.initialDueDate} days
          </Text>
        ) : null}
        {dailyFee ? (
          <Text textStyle="sm">
            <b>
              {values.incrementAmountType === 'FLAT'
                ? `+${formatCurrency(dailyFee).inDollars}`
                : `+${values.incrementPercent}% rent`}
            </b>{' '}
            per additional day
          </Text>
        ) : null}
        {monthlyFee ? (
          <Text textStyle="sm">
            up to{' '}
            <b>
              {values.feeCapType === 'FLAT'
                ? `${formatCurrency(monthlyFee).inDollars} per month`
                : `${values.feeCapPercent}% per month`}
            </b>
          </Text>
        ) : null}
      </Box>
      <BaselaneButtonIcon
        variant="outline"
        palette="neutral"
        icon={<Icon16Delete />}
        onClick={handleReset}
      />
    </Flex>
  );

  return (
    <ToggleWrapper showBottomBorder={false}>
      {showAddLateFeeButton && addFeeButton}
      {isFeeSetupOpen && lateFeeSetup}
      {isSummaryOpen && summary}
    </ToggleWrapper>
  );
};

export default LateFee;
