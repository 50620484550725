import {
  getCustomClaims,
  signInWithCustomEmail,
  signInWithGoogle,
  signUpWithCustomEmail,
  getMfaResolver,
} from '@core/Services/Firebase/firebase';
import { orchestrationUrl } from '@core/constants/envVars';

import sendSegmentEvent from '@core/utils/sendSegmentEvent';

import { UserRole } from './constant.helpers';
import { AuthenticationError } from './error.helpers';

const MULTI_FACTOR_AUTH_REQUIRED = 'auth/multi-factor-auth-required';
const INVALID_CREDENTIAL = 'auth/invalid-credential';

type FirebaseSignInProps = {
  email: string,
  password: string,
  verifyFirebaseOtp: Function,
  handleSuccess: Function,
  handleError: Function,
};

/**
 * Signs a user into firebase with email and password.
 */
export const firebaseEmailSignIn = ({
  email,
  password,
  verifyFirebaseOtp,
  handleSuccess,
  handleError,
}: FirebaseSignInProps) => {
  signInWithCustomEmail(email, password)
    .then(async (credential) => {
      getCustomClaims(credential.user).then((claims) => {
        const { role } = claims;
        if (role === UserRole.TENANT) {
          sendSegmentEvent('user_login_on_wrong_portal');
          handleError({ code: AuthenticationError.tenant });
        } else {
          handleSuccess(credential, claims);
        }
      });
    })
    .catch((error) => {
      if (error?.code === INVALID_CREDENTIAL) {
        handleError({ code: INVALID_CREDENTIAL });
      }
      if (error?.code === MULTI_FACTOR_AUTH_REQUIRED) {
        const resolver = getMfaResolver(error);
        verifyFirebaseOtp(resolver)
          .then((resolvedUserCredential) => {
            getCustomClaims(resolvedUserCredential.user).then((claims) => {
              const { role } = claims;
              if (role === UserRole.TENANT) {
                sendSegmentEvent('user_login_on_wrong_portal');
                handleError({ code: AuthenticationError.tenant });
              } else {
                handleSuccess(resolvedUserCredential, claims);
              }
            });
          })
          .catch(() => {
            // do nothing: (user cancelled otp attempt)
          });
      } else {
        handleError(error);
      }
    });
};

type FirebaseGoogleSignInProps = {
  verifyFirebaseOtp: Function,
  handleSuccess: Function,
  handleError: Function,
  handleInvalidCollaboratorEmail: Function,
};

/**
 * Signs a user up on firebase using Google Single Sign On.
 */
export const firebaseGoogleSignIn = ({
  verifyFirebaseOtp,
  handleSuccess,
  handleError,
  handleInvalidCollaboratorEmail,
}: FirebaseGoogleSignInProps) => {
  signInWithGoogle()
    .then((credential) => {
      getCustomClaims(credential.user).then((claims) => {
        handleInvalidCollaboratorEmail(credential.user.email);

        const { role } = claims;
        if (role === UserRole.TENANT) {
          sendSegmentEvent('user_login_on_wrong_portal');
          handleError({ code: AuthenticationError.tenant });
        } else {
          handleSuccess(credential, claims);
        }
      });
    })
    .catch((error) => {
      if (error?.code === MULTI_FACTOR_AUTH_REQUIRED) {
        const resolver = getMfaResolver(error);
        verifyFirebaseOtp(resolver)
          .then((resolvedUserCredential) => {
            getCustomClaims(resolvedUserCredential.user).then((claims) => {
              const { role } = claims;
              if (role === UserRole.TENANT) {
                sendSegmentEvent('user_login_on_wrong_portal');
                handleError({ code: AuthenticationError.tenant });
              } else {
                handleSuccess(resolvedUserCredential, claims);
              }
            });
          })
          .catch(() => {
            // do nothing: (user cancelled otp attempt)
          });
      } else {
        handleError(error);
      }
    });
};

type FirebaseGoogleSignUpProps = {
  email: string,
  password: string,
  handleSuccess: Function,
  handleError: Function,
};
/**
 * Signs a user up on firebase using email and password.
 */
export const firebaseEmailSignUp = ({
  email,
  password,
  handleSuccess,
  handleError,
}: FirebaseGoogleSignUpProps) => {
  signUpWithCustomEmail(email, password)
    .then((credential) => {
      getCustomClaims(credential.user).then((claims) => {
        const { role } = claims;
        if (role === UserRole.TENANT) {
          sendSegmentEvent('user_login_on_wrong_portal');
          handleError({ code: AuthenticationError.tenant });
        } else {
          handleSuccess(credential, claims);
        }
      });
    })
    .catch(handleError);
};

type GenerateSessionCookieProps = {
  token: String,
  requestId: String,
  handleSuccess: Function,
  handleError: Function,
};
/**
 * Generates a session cookie for the user, based on the id token.
 */
export const generateSessionCookie = ({
  token,
  requestId,
  handleSuccess,
  handleError,
}: GenerateSessionCookieProps) => {
  const REACT_APP_ORCHESTRATION_ROOT = orchestrationUrl.split('/graphql').join('');
  const url = `${REACT_APP_ORCHESTRATION_ROOT}/api/sessionLogin`;
  fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      idToken: token,
      requestId,
    }),
  })
    .then((response) => {
      response
        .json()
        .then((data) => {
          if (data?.error) {
            handleError(data?.error);
          } else {
            handleSuccess(data);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    })
    .catch((error) => {
      handleError(error);
    });
};

/* 
  This should not be used, as it lacks the requestId parameter.
  Leaving it in in case we need it again in the future.

type RefreshSessionCookieProps = {
  handleSuccess: Function,
  handleError: Function,
};
export const refreshSessionCookie = ({ handleSuccess, handleError }: RefreshSessionCookieProps) => {
  reloadToken()
    .then((token) => {
      generateSessionCookie({
        token,
        handleSuccess,
        handleError: (error) => handleError(error),
      });
    })
    .catch((error) => {
      LogRocket.log(`refreshSession error: ${JSON.stringify(error)}`);
    });
}; */
