import React, { ReactElement, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { Flex, Heading } from '@chakra-ui/react';

import { BaselaneHorizontalStepper } from '@shared/components';
import { useCurrentWorkspace } from '@shared/hooks';
import HeaderContentIconWrapper from '@shared/layouts/HeaderNavWrapper/HeaderContentIconWrapper';
import useOnboardingBookkeepingOption from '@hooks/useOnboardingBookkeepingOption';
import { UNIFIED_LANDLORD_BANKING_FIRST_SUCCESS, UNIFIED_LANDLORD_BANKING_SUCCESS } from '@routes';
import UserContext from '@contexts/UserContext';

const HeaderContent = ({
  children,
  applicationStatus,
}: {
  children: ReactElement | null,
  applicationStatus: string,
}) => {
  const { selection: onboardingTriageSelection } = useOnboardingBookkeepingOption();
  const location = useLocation();

  const { workspaceMetadata } = useCurrentWorkspace();
  const { user } = useContext(UserContext);
  const { bankingTracker } = workspaceMetadata ?? {};

  const isBookkeepingSelection = onboardingTriageSelection === 'bookkeeping';
  const steps = [{ title: 'Banking' }, { title: 'Bookkeeping' }];

  const bankingFirstSuccessPage = location.pathname === UNIFIED_LANDLORD_BANKING_FIRST_SUCCESS;
  const bankingSuccessPage = location.pathname === UNIFIED_LANDLORD_BANKING_SUCCESS;
  const isBankingFinishedAndApproved =
    (bankingTracker || [])[0]?.state === 'COMPLETED' && applicationStatus === 'COMPLETED';

  if ((isBookkeepingSelection || bankingFirstSuccessPage) && !user.onboardingCompleted) {
    return (
      <>
        {!isMobile && (
          <HeaderContentIconWrapper flex={1} spacing={3}>
            {(bankingSuccessPage || bankingFirstSuccessPage) && (
              <Heading size="headline-lg">Let&apos;s set up your account</Heading>
            )}
          </HeaderContentIconWrapper>
        )}
        <Flex
          flex={1}
          justifyContent={
            bankingSuccessPage || bankingFirstSuccessPage
              ? 'flex-end'
              : { md: 'center', base: 'flex-start' }
          }
        >
          <BaselaneHorizontalStepper
            steps={steps}
            activeStep={0}
            isActiveStep={(index) => index === 0}
            isCompleteStep={(index) => {
              if ((bankingSuccessPage || bankingFirstSuccessPage) && isBankingFinishedAndApproved) {
                return index === 0;
              }
              return false;
            }}
            overflowX="initial"
            isSpreadInMobile={bankingSuccessPage || bankingFirstSuccessPage}
            isExpandedInMobile={bankingSuccessPage || bankingFirstSuccessPage}
          />
        </Flex>
      </>
    );
  }

  return (
    <Flex justifyContent="space-between" width="100%">
      <HeaderContentIconWrapper />
      {children}
    </Flex>
  );
};

export default HeaderContent;
