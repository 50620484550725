import React, { useContext } from 'react';
import { Stack } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { BaselaneButton } from '@shared/components';
import { IconVerifyIdentity } from '@icons';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { handleFinishLater } from '@core/components/OnboardingTriage/helpers/onboardingFunctions.helpers';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import UserContext from '@contexts/UserContext';
import UserAccessContext from '@contexts/UserAccessContext';
import { UPDATE_USER } from '@core/components/UserProfilePage/queries';
import { HOME, LEASES } from '@routes';
import { buttonContainerStyles } from '@pages/UnifiedRentCollection/UnifiedLeaseSuccess/styles/successUI.styles';
import useCurrentWorkspace from '@core/components/Shared/hooks/useCurrentWorkspace';

function SuccessActionButtons() {
  const { isMax768: isMobile } = useBreakPoints();
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname = '' } = useLocation();
  const { user, refetchUser, setKycShowPopup } = useContext(UserContext);
  const { userKycStatus } = useCurrentWorkspace();
  const { isWorkspaceOwner } = useContext(UserAccessContext);

  const [updateUser] = useMutation(UPDATE_USER);

  return (
    <>
      {isMobile && userKycStatus !== 'UNVERIFIED' && (
        <BaselaneButton
          id="back-to-rc-button"
          variant="tonal"
          palette="primary"
          size="lg"
          onClick={() => {
            localStorage.removeItem('unit');
            localStorage.removeItem('tenantInvite');
            localStorage.removeItem('isActiveTenant');
            navigate(LEASES, { state: location?.state });
          }}
        >
          Back to Rent Collection
        </BaselaneButton>
      )}
      {userKycStatus === 'UNVERIFIED' && (
        <Stack {...buttonContainerStyles(isMobile)}>
          <BaselaneButton
            id="do-it-later-button"
            variant={isMobile ? 'transparent' : 'tonal'}
            palette={isMobile ? 'neutral' : 'primary'}
            size="lg"
            onClick={() => {
              sendSegmentEvent(
                `${user?.onboardingCompleted ? 'baselane' : 'onboarding'}_rc_click_verify_later`
              );

              if (user?.onboardingCompleted) {
                navigate(HOME);
              } else {
                handleFinishLater({
                  user,
                  refetchUser,
                  updateUser,
                  handleRouteChange: () => {
                    if (pathname !== HOME) navigate(HOME);
                  },
                });
              }

              localStorage.removeItem('unit');
              localStorage.removeItem('tenantInvite');
              localStorage.removeItem('isActiveTenant');
            }}
          >
            Do it later
          </BaselaneButton>
          {isWorkspaceOwner && (
            <BaselaneButton
              leftIcon={<IconVerifyIdentity />}
              variant="filled"
              palette="primary"
              size="lg"
              onClick={() => {
                sendSegmentEvent(
                  `${
                    user?.onboardingCompleted ? 'baselane' : 'onboarding'
                  }_rc_click_verify_identity`
                );
                handleFinishLater({
                  user,
                  refetchUser,
                  updateUser,
                  setFlowTrue: () => {
                    sessionStorage.setItem('hideOnboardingTracker', true);
                    setKycShowPopup(true);
                  },
                  handleRouteChange: () => {
                    if (pathname !== HOME) navigate(HOME);
                  },
                });

                localStorage.removeItem('unit');
                localStorage.removeItem('tenantInvite');
                localStorage.removeItem('isActiveTenant');
              }}
            >
              Verify Identity
            </BaselaneButton>
          )}
        </Stack>
      )}
    </>
  );
}
export default SuccessActionButtons;
