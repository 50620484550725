import React from 'react';
import { useQuery } from '@apollo/client';
import { Text, HStack, Box } from '@chakra-ui/react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useStatsigClient } from '@statsig/react-bindings';
import { GET_PROPERTY_SUMMARY, GET_PROPERTIES } from '@core/apollo/queries';
import {
  BaselaneAccordionWrapper,
  BaselaneButton,
  BaselaneEmptyStateCard,
  BaselaneErrorCard,
  BaselaneHeaderCard,
  BaselaneOverview,
  InsuranceBanner,
} from '@shared/components';
import { sortWithString } from '@core/utils/sort';
import SlLoader from '@core/components/Loader';
import { MAINTENANCE_REQUEST } from '@routes';

import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import Property from './Property';
import AddEditProperty from './AddEditProperty';
import SuccessDrawer from './SuccessDrawer';
import useSuccessPropertyDrawer from './SuccessDrawer/hooks/useSuccessPropertyDrawer';

function PropertiesPage() {
  const navigate = useNavigate();

  const { checkGate } = useStatsigClient();

  const { error: summaryError, loading: summaryLoading, data: summaryData } = useQuery(
    GET_PROPERTY_SUMMARY
  );
  const { error: propertiesError, loading: propertiesLoading, data: propertiesData } = useQuery(
    GET_PROPERTIES
  );

  const { newProperty, setNewProperty, successDrawerRef } = useSuccessPropertyDrawer();

  if (summaryLoading || propertiesLoading) return <SlLoader />;
  if (summaryError || propertiesError) {
    if (
      summaryError?.message?.includes('Failed to fetch') ||
      propertiesError?.message?.includes('Failed to fetch')
    ) {
      return <BaselaneErrorCard />;
    }
    return null;
  }

  const { propertySummary = {} } = summaryData;
  const { totalProperties = 0, totalUnits = 0, vacantUnits = 0 } = propertySummary || {};
  const { property = [] } = propertiesData ?? {};
  const sortedProperties = sortWithString(property, 'name');

  const vUnits = vacantUnits === null ? 0 : vacantUnits;
  const numberOfProperties = <Text>{totalProperties}</Text>;
  const numberOfUnits = <Text>{totalUnits}</Text>;
  const numberOfVacantUnits = <Text>{vUnits}</Text>;

  const overview = [
    { title: 'Total Properties', content: numberOfProperties, style: { ml: '0 !important' } },
    { title: 'Total Units', content: numberOfUnits, style: { ml: '0 !important' } },
    { title: 'Vacant Units', content: numberOfVacantUnits, style: { ml: '0 !important' } },
  ];
  const title = <Text>Summary</Text>;

  const summary = (
    <HStack spacing={2}>
      <BaselaneOverview
        elements={overview}
        orientation="horizontal"
        position="left"
        styles={{ h: 'auto', width: 'auto' }}
        spacing={6}
      />
      <AddEditProperty
        totalProperties={totalProperties}
        totalUnits={totalUnits}
        setNewProperty={setNewProperty}
        successDrawerRef={successDrawerRef}
      />
      {checkGate(FEATURE_GATES.LATCHEL_GATE) && (
        <BaselaneButton
          palette="neutral"
          variant="outline"
          onClick={() => {
            sendSegmentEvent('maintenance_click_button');
            navigate({ pathname: MAINTENANCE_REQUEST });
          }}
        >
          Book a repair
        </BaselaneButton>
      )}
    </HStack>
  );

  return (
    <Box h="100%">
      {/* Insurance Banner */}
      <InsuranceBanner />
      <SuccessDrawer successDrawerRef={successDrawerRef} property={newProperty} />

      <BaselaneHeaderCard
        containerStyles={{ h: 'auto' }}
        headerLeft={title}
        headerRight={summary}
        headerRightStyles={{ flex: 1, justifyContent: 'flex-end' }}
      >
        {sortedProperties.length > 0 ? (
          <BaselaneAccordionWrapper
            allowToggle
            styles={{
              my: '0px',
              h: '100%',
            }}
          >
            {sortedProperties.map((p) => (
              <Property key={p.id} {...{ totalProperties, totalUnits, property: p }} />
            ))}
          </BaselaneAccordionWrapper>
        ) : (
          <BaselaneEmptyStateCard title="Add Your First Property" styles={{ boxShadow: 'none' }}>
            <BaselaneEmptyStateCard.Paragraph>
              Adding a property is essential to organizing your property finances, collecting rent,
              enabling automated property metrics, and finding savings for your property.
            </BaselaneEmptyStateCard.Paragraph>
            <AddEditProperty
              buttonStyles={{ size: 'lg', ml: 0 }}
              totalProperties={totalProperties}
              totalUnits={totalUnits}
              setNewProperty={setNewProperty}
              successDrawerRef={successDrawerRef}
            />
          </BaselaneEmptyStateCard>
        )}
      </BaselaneHeaderCard>
      <Outlet />
    </Box>
  );
}

export default PropertiesPage;
