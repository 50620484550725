import { DateTime } from 'luxon';

export const calculateDaysToExpire = (today: DateTime, expDay: DateTime): number => {
  const diff = expDay.diff(today, 'days').days;
  return Math.floor(diff); // Round down to the nearest whole number
};

export const calculateDaysOverdue = (today: DateTime, overDay: DateTime): number => {
  const diff = today.diff(overDay, 'days').days;
  return Math.ceil(diff); // Round up for overdue days
};
