import { React } from 'react';
import { Text, Radio, Flex, Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { radioLabelStyles, radioDescriptionStyles } from './styles/radio.styles';

// https://www.figma.com/file/IzR58By56T8Dvo2tLLPCbP/%F0%9F%8C%80-*Baselane---Design-System-(Habitat)?type=design&node-id=1100-43126&mode=design&t=cfj65iXqrJcg7CD4-4

const BaselaneRadio = ({ label = null, description = null, ...props }) => {
  return (
    <Flex alignItems="flex-start" sx={{ '.chakra-radio': { paddingTop: '2px' } }}>
      <Radio {...props} />
      <Box>
        {label && (
          <Text {...radioLabelStyles} textAlign="left">
            {label}
          </Text>
        )}
        {description && <Text {...radioDescriptionStyles}>{description}</Text>}
      </Box>
    </Flex>
  );
};

BaselaneRadio.propTypes = {
  label: PropTypes.node,
  description: PropTypes.node,
  ...Radio.PropTypes,
};

export default BaselaneRadio;
