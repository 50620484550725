// @flow
import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys
);

const baseStyle = definePartsStyle({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  tablist: {
    position: 'relative',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tab: {
    flex: 'none',
    transition: 'all 0.2s',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    width: 'auto',
    _selected: {
      color: 'brand.darkBlue.800A',
      borderColor: 'currentColor',
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
    _active: {
      background: 'transparent',
    },
  },
  tabpanel: {
    pt: 4,
    borderTop: '2px solid',
    borderColor: 'brand.neutral.200',
    marginTop: '-2px',
  },
});

const sizes = {
  md: definePartsStyle((props) => ({
    tab: {
      py: 1,
      px: 1,
      mx: 1,
      ...props.theme.textStyles['button-sm'],
    },
  })),
  lg: definePartsStyle((props) => ({
    tab: {
      py: 2,
      px: 2,
      mx: 2,
      ...props.theme.textStyles['button-md'],
    },
  })),
};

const variants = {
  line: definePartsStyle((props) => ({
    tab: {
      color: 'brand.neutral.500',
      fontWeight: 500,
      borderBottom: '2px solid',
      borderColor: 'transparent',
      marginBottom: '0',
      _selected: {
        color: 'brand.darkBlue.800A',
        borderColor: 'currentColor',
      },
    },
    tablist: {
      borderBottom: 'none',
    },
  })),
  scrollable: definePartsStyle((props) => ({
    root: {
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: props.size === 'md' ? '8px' : '12px',
        background: 'linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)',
        pointerEvents: 'none',
        zIndex: 1,
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        width: props.size === 'md' ? '8px' : '12px',
        background: 'linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)',
        pointerEvents: 'none',
        zIndex: 1,
      },
    },
    tablist: {
      overflowX: 'auto',
      scrollbarWidth: 'none',
      whiteSpace: 'nowrap',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    tab: {
      color: 'brand.neutral.500',
      fontWeight: 500,
      borderBottom: '2px solid',
      borderColor: 'transparent',
      marginBottom: '0',
      _selected: {
        color: 'brand.darkBlue.800A',
        borderColor: 'currentColor',
      },
    },
  })),
};

export const Tabs: any = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'line',
  },
});
