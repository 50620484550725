import React from 'react';
import { useLocation } from 'react-router-dom';
import { BaselaneHeader } from '@shared/components';
import { DASHBOARD, TENANT_SCREENING } from '@routes';
import { getSubTitle, getTitle } from './helpers/pageHeader.helpers';

const PageHeader = ({ user }: { user: Object }) => {
  const location = useLocation();
  const { pathname = '' } = location;
  const getStartedTitle = user
    ? { title: `Let's Get Started${user?.firstName ? `, ${user?.firstName}` : ''}!` }
    : '';
  const dashboardTitle = {
    title: 'Dashboard',
  };
  const homeTitle = getStartedTitle;

  const isDashboardHeader = pathname.includes(DASHBOARD);
  const isTenantScreeningHeader = pathname.includes(TENANT_SCREENING);

  const page = pathname.substring(1, pathname.length);
  const title = getTitle(user, isDashboardHeader, dashboardTitle, homeTitle, page, pathname);
  const subtitle = getSubTitle(isDashboardHeader, dashboardTitle, page);
  return user ? (
    <BaselaneHeader
      title={title}
      subtitle={subtitle}
      sx={
        isTenantScreeningHeader && {
          '@media print': {
            display: 'none',
          },
        }
      }
    />
  ) : null;
};

export default PageHeader;
