import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FormControl, VStack, Flex, Input, Skeleton } from '@chakra-ui/react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_TENANT_SCREENING_BY_ID } from '@core/apollo/queries/tenantScreening';
import { IllustrationOtherTenantScreening } from '@illustrations';
import {
  BaselaneSingleDatePicker,
  BaselaneAlertNew,
  BaselaneFormLabel,
  BaselaneButton,
  BaselaneFormErrorMessage,
  BaselaneDivider,
  BaselaneRadioGroup,
  BaselaneRadio,
} from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { TENANT_SCREENING_APPLICATION_LIST, TENANT_SCREENING, TENANT_SCREENING_ID } from '@routes';
import { dateFormatter, fromStoredGMTDate } from '@core/utils/formatDate';
import BaselanePropertySelect from '@core/components/Shared/components/BaselanePropertySelect';
import useProperties from '../../hooks/useProperties';
import { ERROR_MESSAGES } from '../tenantScreening.helpers';

type DetailsProps = {
  isEditDrawer: boolean,
  editScreeningId: string,
};

const Details = ({ isEditDrawer, editScreeningId }: DetailsProps) => {
  const navigate = useNavigate();
  const {
    values,
    errors,
    touched,
    setFieldTouched,
    handleChange,
    handleBlur,
    setFieldValue,
    setErrors,
  } = useFormikContext();
  const { isMin768 } = useBreakPoints();

  const { data, loading } = useProperties();
  const properties = data?.property ?? [];

  const { data: editData, loading: loadingEditData } = useQuery(GET_TENANT_SCREENING_BY_ID, {
    variables: { id: Number(editScreeningId) },
    skip: !isEditDrawer,
    fetchPolicy: 'no-cache',
  });

  const activeScreenings = () => {
    const property = properties.find((p) => p.id === values?.selectedProperty);
    const isSingleUnit = property?.units.length === 1;
    if (isSingleUnit) return property.units[0].tenantScreening;
    const propertyUnitActiveScreening =
      property?.units.find((u) => u.id === values?.selectedUnit)?.tenantScreening ?? [];

    return propertyUnitActiveScreening;
  };

  const handleGoToScreening = () => {
    const latestScreeningId = activeScreenings()[0].tenantScreeningId;
    navigate(
      `/${TENANT_SCREENING}/${TENANT_SCREENING_APPLICATION_LIST.replace(
        TENANT_SCREENING_ID,
        latestScreeningId
      )}`
    );
    sendSegmentEvent('tenant_screening_fe_go_to_screening_clicked', {
      screeningId: latestScreeningId,
    });
  };

  const currencyMask = createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
  });

  useEffect(() => {
    if (isEditDrawer && editData) {
      const editProperty = properties.find(
        (p) => p.name === editData.getTenantScreeningById.propertyName
      );
      const editUnit = editProperty?.units.find(
        (u) => u.name === editData.getTenantScreeningById.unitName
      );
      const date = editData.getTenantScreeningById.leaseAvailableDate;
      const reports = editData.getTenantScreeningById.selectedReports;
      const { llcName } = editData.getTenantScreeningById;

      setFieldValue('selectedProperty', editProperty?.id, false);
      setFieldValue('selectedUnit', editUnit?.id, false);
      setFieldValue('date', fromStoredGMTDate(date), false);
      setFieldValue('rentAmount', editData.getTenantScreeningById.amount, false);
      setFieldValue('criminalReport', reports.criminalReport, false);
      setFieldValue('evictionReport', reports.evictionHistory, false);
      setFieldValue('incomeVerification', reports.incomeVerification, false);
      if (llcName) {
        setFieldValue('llcOwned', 'yes', false);
        setFieldValue('llcName', llcName, false);
      } else {
        setFieldValue('llcOwned', 'no', false);
      }
      setErrors({});
    }
  }, [editData, isEditDrawer, properties]);

  useEffect(() => {
    if (!isEditDrawer && !loading) {
      const property = properties.find((p) => p.id === values?.selectedProperty) || {};
      const { units } = property || [];

      const hasActiveScreenings = units?.reduce((accumulator, unit) => {
        const { tenantScreening } = unit || {};
        return accumulator || tenantScreening.length > 0;
      }, false);

      if ('llcName' in property && hasActiveScreenings) {
        const { llcName } = property;
        if (llcName !== null) {
          setFieldValue('llcOwned', 'yes', false);
          setFieldValue('llcName', llcName, false);
        } else {
          setFieldValue('llcOwned', 'no', false);
        }
      } else {
        setFieldValue('llcOwned', '-1', false);
        setFieldValue('llcName', '', false);
      }
    }
  }, [values?.selectedProperty, properties, loading]);

  return (
    <VStack gap={3}>
      <BaselanePropertySelect
        properties={properties}
        loading={loading}
        isRequired
        isDisabled={isEditDrawer}
      />
      {activeScreenings()?.length > 0 && !isEditDrawer && (
        <BaselaneAlertNew
          variant="primary"
          title="Screening active"
          body="You have already enabled screening for this unit"
          isVertical
          visual="illustration"
          iconName={IllustrationOtherTenantScreening}
          styles={{
            marginTop: '16px',
          }}
          customButton={
            <BaselaneButton variant="filled" palette="primary" onClick={handleGoToScreening}>
              Go to screening
            </BaselaneButton>
          }
        />
      )}
      <Flex gap={2} w="full">
        <FormControl isRequired variant="isLast" isInvalid={errors.date && touched.date}>
          <BaselaneFormLabel htmlFor="date">Available on</BaselaneFormLabel>
          <Skeleton isLoaded={!loadingEditData && !loading}>
            <BaselaneSingleDatePicker
              {...{
                id: 'date',
                name: 'date',
                minDate: new Date(),
                date: values.date,
                value: values.date ? dateFormatter(values.date) : dateFormatter(new Date()),
                dateFormat: 'MMM dd, yyyy',
                handleCalendarClose: ({ date, isFromApplyButton }) => {
                  if (isMin768 || (!isMin768 && isFromApplyButton)) {
                    setFieldValue('date', date);
                  }
                },
                size: 'lg',
                hideInputIcon: true,
                fixedHeight: true,
                placement: 'bottom-end',
              }}
            />
          </Skeleton>
          <BaselaneFormErrorMessage isInvalid={errors.date && touched.date}>
            {ERROR_MESSAGES.date}
          </BaselaneFormErrorMessage>
        </FormControl>
        <FormControl mb={0} isRequired isInvalid={errors.rentAmount && touched.rentAmount}>
          <BaselaneFormLabel htmlFor="rentAmount">Rent amount</BaselaneFormLabel>
          <Skeleton isLoaded={!loadingEditData && !loading}>
            <Input
              size="lg"
              id="rentAmount"
              name="rentAmount"
              value={values?.rentAmount}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={(e) => {
                setFieldTouched('rentAmount');
                handleBlur(e);
              }}
              as={MaskedInput}
              mask={currencyMask}
              height={12}
              placeholder="$"
            />
          </Skeleton>
          <BaselaneFormErrorMessage isInvalid={errors.rentAmount && touched.rentAmount}>
            {ERROR_MESSAGES.rentAmount}
          </BaselaneFormErrorMessage>
        </FormControl>
      </Flex>
      <BaselaneDivider my={1} />
      <FormControl isRequired isInvalid={errors.llcOwned && touched.llcOwned} m={0}>
        <Skeleton isLoaded={!loadingEditData && !loading}>
          <BaselaneRadioGroup
            isRequired
            name="llcOwnedGroup"
            defaultValue="-1"
            description="Is this property owned by an LLC?"
            direction="row"
            onChange={(e) => {
              setFieldValue('llcOwned', e);
              handleChange(e);
            }}
            onBlur={handleBlur}
            value={values?.llcOwned}
          >
            <BaselaneRadio name="llcOwned" label="Yes" value="yes" />
            <BaselaneRadio name="llcOwned" label="No" value="no" />
          </BaselaneRadioGroup>
        </Skeleton>
      </FormControl>
      {values.llcOwned === 'yes' && (
        <FormControl isRequired isInvalid={errors.llcName && touched.llcName} m={0}>
          <BaselaneFormLabel htmlFor="llcName">LLC name</BaselaneFormLabel>
          <Skeleton isLoaded={!loadingEditData && !loading}>
            <Input
              size="lg"
              id="llcName"
              name="llcName"
              value={values?.llcName}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={(e) => {
                setFieldTouched('llcName');
                handleBlur(e);
              }}
            />
          </Skeleton>
        </FormControl>
      )}
    </VStack>
  );
};

export default Details;
