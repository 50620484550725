import React from 'react';
import { TabList, Tab } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { getTabsListItems } from '../../helpers/userProfilePage.helpers';
import {
  tabContainerStyles,
  userProfileTabsListStyles,
} from '../../styles/userProfileTabsList.style';

function UserProfileTabsList() {
  const { isMax576 } = useBreakPoints();
  const tabsListItems = getTabsListItems();

  return (
    <TabList {...tabContainerStyles(isMax576)}>
      {tabsListItems.map((tab, index) => (
        <Tab key={tab} {...userProfileTabsListStyles(isMax576)}>
          {tab}
        </Tab>
      ))}
    </TabList>
  );
}

export default UserProfileTabsList;
