// @flow
import React from 'react';
import { TabPanels, TabPanel } from '@chakra-ui/react';
import { useStatsigClient } from '@statsig/react-bindings';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import useBreakPoints from '@core/hooks/useBreakPoints';
import BusinessProfile from '@core/components/WorkspacePage/WorkspaceTabs/WorkspaceTabsPanels/BusinessProfile';
import RentCollection from '@core/components/WorkspacePage/WorkspaceTabs/WorkspaceTabsPanels/RentCollection';
import TabProfile from './TabProfile';
import Password from './Password';
import { tabPanelsStyles } from '../../styles/userProfileTabsPanels.styles';

type UserProfileTabsPanelsProps = {
  isTabChange: boolean,
  tabIndex: number,
  setIsDirty: Function,
  setIsTabChange: Function,
  setTabIndex: Function,
};

function UserProfileTabsPanels({
  setIsDirty,
  isTabChange,
  setIsTabChange,
  setTabIndex,
  tabIndex,
}: UserProfileTabsPanelsProps): any {
  const { isMax576 } = useBreakPoints();

  const { checkGate } = useStatsigClient();
  const isMuaEnabled = checkGate(FEATURE_GATES.MUA_GATE);

  let tabs = [
    { name: 'tabProfile', Component: TabProfile },
    { name: 'businessProfile', Component: BusinessProfile },
    { name: 'password', Component: Password },
    { name: 'rentCollection', Component: RentCollection },
  ];

  if (isMuaEnabled)
    tabs = [
      { name: 'tabProfile', Component: TabProfile },
      { name: 'password', Component: Password },
    ];

  return (
    <TabPanels {...tabPanelsStyles(isMax576)}>
      {tabs.map(({ name, Component }) => (
        <TabPanel key={name} id={name} h="100%" p={0}>
          <Component
            tabIndex={tabIndex}
            setIsDirty={setIsDirty}
            isTabChange={isTabChange}
            setIsTabChange={setIsTabChange}
            setTabIndex={setTabIndex}
          />
        </TabPanel>
      ))}
    </TabPanels>
  );
}

export default UserProfileTabsPanels;
