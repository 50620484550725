import React, { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { Input, Stack, Text } from '@chakra-ui/react';
import UserAccessContext from '@contexts/UserAccessContext';
import EditBtn from '../../../../../components/IndividualInputEditButtons/EditBtn';
import CancelEditBtn from '../../../../../components/IndividualInputEditButtons/CancelEditBtn';
import SaveChangesBtn from '../../../../../components/IndividualInputEditButtons/SaveChangesBtn';
import { inputFieldStyles } from './styles/editMode.styles';
import { drawerCardNameStyles } from './styles/cardDetailsDrawer.styles';

type NicknameSectionProps = {
  editModeValues: Object,
  accountName: String,
  handleEditModeToggleOrSave: Function,
  handleOnEditChange: Function,
  cardStatus: string,
};

const NicknameSection = ({
  editModeValues,
  handleEditModeToggleOrSave,
  handleOnEditChange,
  cardStatus,
}: NicknameSectionProps) => {
  const { authorizedForBanking } = useContext(UserAccessContext);

  return editModeValues.nickname.isEdit && authorizedForBanking ? (
    <Stack direction="row">
      <Input
        id="nickname.value"
        name="nickname"
        value={editModeValues.nickname.value}
        onChange={handleOnEditChange}
        isDisabled={!authorizedForBanking}
        {...inputFieldStyles}
        w={isMobile ? '100%' : '50%'}
      />
      <CancelEditBtn
        onClick={() => handleEditModeToggleOrSave('nickname', false)}
        variant="outline"
        palette="neutral"
        size="sm"
      />
      <SaveChangesBtn
        id="edit-card-nickname-save-button"
        variant="filled"
        palette="primary"
        size="sm"
        isDisabled={editModeValues.nickname.value.trim() === ''}
        onClick={() => handleEditModeToggleOrSave('nickname', false, true)}
      />
    </Stack>
  ) : (
    <Stack direction="row" spacing={1}>
      <Text {...drawerCardNameStyles}>{editModeValues.nickname.value}</Text>
      {authorizedForBanking && (
        <EditBtn
          id="edit-card-nickname-button"
          variant="transparent"
          palette="primary"
          size="sm"
          isDisabled={cardStatus === 'Frozen' || cardStatus === 'SuspectedFraud'}
          onClick={() => handleEditModeToggleOrSave('nickname', true)}
        />
      )}
    </Stack>
  );
};

export default NicknameSection;
