import React from 'react';
import { Heading, Text, VStack, Box } from '@chakra-ui/react';
import { BaselaneCardNew } from '@shared/components';
import { IllustrationOtherInprogress } from '@illustrations';

const PendingEmptyState = () => {
  return (
    <BaselaneCardNew minH="240px" w="full" palette="empty">
      <VStack alignItems="center" w="100%" gap={0.5}>
        <Box mb={1.5}>
          <IllustrationOtherInprogress />
        </Box>
        <Heading size="headline-md">Report pending</Heading>
        <Text textStyle="sm" mb="16px">
          Report details will be available shortly
        </Text>
      </VStack>
    </BaselaneCardNew>
  );
};

export default PendingEmptyState;
