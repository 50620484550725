import React from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { REPORT_TYPE_ICON } from '../helpers/tenantScreeningDetail.helpers';

type TenantScreeningReportOptionProps = {
  reportItem: Object,
  optionType: string,
};

const TenantScreeningReportOption = ({
  reportItem,
  optionType,
}: TenantScreeningReportOptionProps) => {
  const { id, name, type } = reportItem || {};
  const Icon = REPORT_TYPE_ICON[id];
  return (
    <HStack w="full" justify={optionType === 'applicant' ? 'space-between' : 'flex-start'}>
      {Icon && <Icon />}
      <Text whiteSpace="nowrap" textStyle="sm" marginLeft="8px">
        {name}
      </Text>
      {optionType === 'applicant' && (
        <Text whiteSpace="nowrap" textStyle="sm" color="brand.neutral.500">
          {type}
        </Text>
      )}
    </HStack>
  );
};

export default TenantScreeningReportOption;
