import React, { useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import { sortBy } from 'lodash';
import MaskedInput from 'react-text-mask';
import { useQuery } from '@apollo/client';
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import ExistingTenantSelect from '@pages/LeasesPage/LeaseSection/forms/TenantDetailsForm/ExistingTenantSelect';
import ExistingTenantForm from '@pages/LeasesPage/LeaseSection/forms/TenantDetailsForm/ExistingTenantForm';
import { BaselaneButtonToggle, BaselaneButton, BaselaneMessageCard } from '@shared/components';
import { phoneNumberMask } from '@core/utils/masks';
import formatTextSpacing from '@core/utils/formatTextSpacing';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import UserContext from '@contexts/UserContext';
import type { PropertyUnitType } from '../../../types';

import {
  tenantFormValidation,
  tenantFormInitialValues,
  resetTenantFormValues,
} from '../../formHelpers/tenantForm.helper';
import { GET_TENANT_ACTIVE_PROFILES } from '../../../queries';
import { fieldContainer } from '../../styles/shared.styles';
import { formContainerStyles } from '../../styles/tenantDetails.styles';

type TenantDetailsFormProps = {
  saveTenantProfileId: Function,
  setFormVariables: Function,
  formVariables: Object,
  unit: PropertyUnitType,
  tenantDetailsRef: any,
  existingTenantState: Object,
  setExistingTenantState: Function,
  tenantPanelIndex: number,
  tabIndex: number,
};

function TenantDetailsForm({
  saveTenantProfileId = () => {},
  setFormVariables = () => {},
  formVariables,
  unit,
  tenantDetailsRef,
  existingTenantState,
  setExistingTenantState = () => {},
  tenantPanelIndex,
  tabIndex,
}: TenantDetailsFormProps): any {
  const existingTenantProfileId = unit?.lease ? unit?.lease.tenantProfileId : null;
  const tenantProfileData = unit?.lease && unit?.lease.tenantProfileMetadata;

  const { loading: tenantLoading, data: tenantData, error: tenantError } = useQuery(
    GET_TENANT_ACTIVE_PROFILES,
    {
      fetchPolicy: 'network-only',
    }
  );
  const { user } = useContext(UserContext);

  const { landLordTenantSummary = [] } = tenantData || {};
  const { activeTenants: landlordTenant = [] } = landLordTenantSummary;
  const existingTenantsSorted = sortBy(landlordTenant, ['firstName', 'lastName']);
  const existingEmails = landlordTenant?.map((item) => item.email);
  const landlordEmail = user.email;

  const [showMessage, setShowMessage] = useState(false);
  const [existingTenants, setExistingTenants] = useState(existingTenantsSorted);
  const [tenantProfileId, setTenantProfileId] = useState(existingTenantProfileId);

  const tenantPanelTab = landlordTenant?.length > 0 ? 1 : 0;
  const [existingTenantVisibleValue, setExistingTenantVisibleValue] = useState(tenantPanelTab);

  const initialValues = tenantFormInitialValues(tenantProfileData);

  function updateTenant(id) {
    const idToUpdate = !id ? null : id;
    setTenantProfileId(idToUpdate);
    saveTenantProfileId(idToUpdate);
    // if we are selecting an existing tenant, set the tenantProfileMetadata (newTenant) to empty
    if (idToUpdate) {
      setFormVariables({ tenantProfileMetadata: null, tenantProfileId: idToUpdate });
    } else {
      setFormVariables({ tenantProfileId: idToUpdate });
    }
  }

  function handleToggleChange(isExistingTenantValue) {
    setExistingTenantState((prevState) => ({
      ...prevState,
      existingTenantToggle: !!isExistingTenantValue,
    }));
    // getting value in onclick of toggle value since it doesn't not get update in time once it arrives here
    if (isExistingTenantValue === 1 && tenantProfileId !== null) {
      updateTenant(tenantProfileId);
    }
  }

  const handleTenantIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = event.target;
    if (value.toLowerCase() === 'select tenant') {
      updateTenant(null);
    } else {
      updateTenant(value);
    }
  };

  const handleSave = (values) => {
    const valueWithNamesTrimmed = { ...values };
    valueWithNamesTrimmed.firstName = formatTextSpacing(values.firstName);
    valueWithNamesTrimmed.lastName = formatTextSpacing(values.lastName);
    setFormVariables({ tenantProfileMetadata: valueWithNamesTrimmed, tenantProfileId: null });
  };

  // used to update local state from updated lease data
  useEffect(() => {
    if (existingTenantProfileId && tenantProfileId !== existingTenantProfileId) {
      updateTenant(existingTenantProfileId);
    }
    const formHasValues = formVariables?.tenantProfileMetadata?.firstName;
    const { firstName, lastName, email } = unit?.lease?.tenantProfileMetadata || {};
    const newTenant = firstName || lastName || email || formHasValues;
    const hasTenants = landlordTenant?.length !== 0;
    const toggleValue =
      (!newTenant && existingTenantProfileId) || (!newTenant && hasTenants) ? 1 : 0;
    if (!tenantLoading) {
      setExistingTenantVisibleValue(toggleValue);
      setExistingTenantState((prevState) => ({
        ...prevState,
        existingTenantToggle: !!toggleValue,
      }));
      if (toggleValue === 1 && existingTenantProfileId) {
        updateTenant(existingTenantProfileId);
      }
    }
    if (newTenant && tabIndex < tenantPanelIndex) {
      // any new tenant values negate existing tenant so we return to new tenant form
      updateTenant(null);
    }
  }, [unit?.lease?.tenantProfileId, tenantLoading, tabIndex]);

  useEffect(() => {
    if (tenantProfileId === 0 || tenantProfileId === null) {
      setExistingTenantState((prevState) => ({ ...prevState, isInvalid: true }));
    } else {
      setExistingTenantState((prevState) => ({ ...prevState, isInvalid: false }));
    }
  }, [tenantProfileId]);

  useEffect(() => {
    if (
      tabIndex === 1 &&
      tenantProfileId &&
      !formVariables.tenantProfileMetadata &&
      !formVariables.tenantProfileId
    ) {
      // when resetting tenant form
      updateTenant(null);
    }
  }, [formVariables]);

  useEffect(() => {
    setExistingTenants(existingTenantsSorted);
  }, []);

  useEffect(() => {
    const newTenantsSorted = sortBy(landlordTenant, ['firstName', 'lastName']);
    setExistingTenants(newTenantsSorted);
  }, [tenantData]);

  if (tenantLoading) return <div> Loading... </div>;
  if (tenantError) return <div> Error! </div>;

  const handleExistingTenantFromNewTenantTab = (email) => {
    const tenant = existingTenants.find((et) => et.email === email);
    updateTenant(tenant.id);
  };

  const handleTenantFormChange = (value, values) => {
    const tenantDetailsFormNotFilled =
      values && Object.values(values).filter((tenantValue) => tenantValue === '');
    const newTenantValues = values?.firstName || values?.lastName || values?.email || values?.phone;
    if (tenantDetailsFormNotFilled?.length === 0 && value === 1) {
      setFormVariables({ tenantProfileMetadata: null });
    } else if (newTenantValues) {
      // if we add any new values, we send them out and negate existing tenant
      const valueWithNamesTrimmed = { ...value };
      valueWithNamesTrimmed.firstName = formatTextSpacing(value.firstName);
      valueWithNamesTrimmed.lastName = formatTextSpacing(value.lastName);
      setFormVariables({ tenantProfileMetadata: valueWithNamesTrimmed, tenantProfileId: null });
      updateTenant(null);
    } else {
      const valueWithNamesTrimmed = { ...value };
      valueWithNamesTrimmed.firstName = formatTextSpacing(value.firstName);
      valueWithNamesTrimmed.lastName = formatTextSpacing(value.lastName);
      setFormVariables({ tenantProfileMetadata: valueWithNamesTrimmed });
    }
  };

  const handleToggleNewOrExistingTenant = (
    value,
    setFieldValue,
    resetForm,
    values,
    isExistingEmail
  ) => {
    setExistingTenantVisibleValue(value);
    handleToggleChange(value);
    setExistingTenantState((prevState) => ({
      ...prevState,
      changeTab: false,
      newTenant: value === 0,
    }));

    if (value === 0) {
      resetForm(resetTenantFormValues);
    }

    if (value === 1 && isExistingEmail) {
      // when user enters existing email from the new tenant form
      handleExistingTenantFromNewTenantTab(values.email);
    } else {
      handleTenantFormChange(value, values);
    }
  };

  return (
    <Box {...formContainerStyles}>
      <Formik
        innerRef={tenantDetailsRef}
        validate={(values) =>
          tenantFormValidation(values, existingEmails, landlordEmail, showMessage, setShowMessage)
        }
        initialValues={initialValues}
        onSubmit={(values) => handleSave(values)}
        validateOnBlur
        enableReinitialize
      >
        {({
          values,
          handleChange,
          setFieldTouched,
          resetForm,
          errors,
          touched,
          setFieldValue,
          handleBlur,
        }) => (
          <>
            {landlordTenant.length > 0 && user?.onboardingCompleted && (
              <Stack direction="row" pb="32px">
                <BaselaneButtonToggle
                  isFullWidth
                  size="lg"
                  firstLabel="New Tenant"
                  secondLabel="Existing Tenant"
                  firstId="newtenant"
                  secondId="existingtenant"
                  activeButton={existingTenantVisibleValue}
                  onClick={(value) =>
                    handleToggleNewOrExistingTenant(value, setFieldValue, resetForm, values)
                  }
                />
              </Stack>
            )}

            {existingTenantVisibleValue === 0 ? (
              <Stack spacing={0}>
                <Stack spacing="16px">
                  <FormControl
                    {...fieldContainer}
                    mb={0}
                    isInvalid={errors.firstName && touched.firstName}
                  >
                    <FormLabel htmlFor="firstName" {...formLabelStyles.xs}>
                      First name
                    </FormLabel>
                    <Input
                      {...formInputStyles}
                      id="firstName"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        handleTenantFormChange({
                          firstName: e.target.value,
                          lastName: values.lastName,
                          email: values.email,
                          phoneNumber: values.phoneNumber,
                        });
                      }}
                      placeholder="e.g. John"
                    />
                    <FormErrorMessage {...formErrorStyles}>{errors.firstName}</FormErrorMessage>
                  </FormControl>

                  <FormControl {...fieldContainer} isInvalid={errors.lastName && touched.lastName}>
                    <FormLabel htmlFor="lastName" {...formLabelStyles.xs}>
                      Last name
                    </FormLabel>
                    <Spacer />
                    <Input
                      {...formInputStyles}
                      id="lastName"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        handleTenantFormChange({
                          lastName: e.target.value,
                          firstName: values.firstName,
                          email: values.email,
                          phoneNumber: values.phoneNumber,
                        });
                      }}
                      placeholder="e.g. Smith"
                    />
                    <FormErrorMessage {...formErrorStyles}>{errors.lastName}</FormErrorMessage>
                  </FormControl>
                </Stack>
                <Stack spacing="16px">
                  <FormControl {...fieldContainer} mb={0} isInvalid={errors.email && touched.email}>
                    <FormLabel htmlFor="email" {...formLabelStyles.xs}>
                      Email
                    </FormLabel>
                    <Spacer />
                    <Input
                      {...formInputStyles}
                      id="email"
                      name="email"
                      value={values.email.toLowerCase()}
                      onChange={(e) => {
                        handleChange(e);
                        const newEmail = e.target.value;
                        const isAlreadyExistingEmail = existingEmails.includes(newEmail);
                        if (isAlreadyExistingEmail) {
                          setFieldTouched('email', true, false);
                        }
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        handleTenantFormChange({
                          email: e.target.value,
                          lastName: values.lastName,
                          firstName: values.firstName,
                          phoneNumber: values.phoneNumber,
                        });
                      }}
                      placeholder="e.g. johnsmith@gmail.com"
                    />
                    <FormErrorMessage {...formErrorStyles}>
                      <Text as="span">{errors.email}</Text>
                      {errors.email === 'Email matches existing tenant.' && (
                        <BaselaneButton
                          onClick={() =>
                            handleToggleNewOrExistingTenant(
                              1,
                              setFieldValue,
                              resetForm,
                              values,
                              true
                            )
                          }
                          variant="link"
                          palette="danger"
                          styles={{ ml: 0.75 }}
                        >
                          Select the existing tenant
                        </BaselaneButton>
                      )}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    {...fieldContainer}
                    isInvalid={errors.phoneNumber && touched.phoneNumber}
                  >
                    <FormLabel htmlFor="phone" {...formLabelStyles.xs}>
                      Mobile phone (optional, needed to send SMS invite)
                    </FormLabel>
                    <Spacer />

                    <Input
                      {...formInputStyles}
                      as={MaskedInput}
                      mask={phoneNumberMask}
                      id="phoneNumber"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      placeholder="e.g. (555) 555-5555"
                      type="tel"
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        handleTenantFormChange({
                          phoneNumber: e.target.value,
                          email: values.email,
                          lastName: values.lastName,
                          firstName: values.firstName,
                        });
                      }}
                    />
                    <FormErrorMessage {...formErrorStyles}>{errors.phoneNumber}</FormErrorMessage>
                  </FormControl>
                </Stack>
              </Stack>
            ) : (
              <>
                <ExistingTenantSelect
                  tenantProfileId={tenantProfileId}
                  handleTenantIdChange={handleTenantIdChange}
                  existingTenants={existingTenants}
                  existingTenantState={existingTenantState}
                />
                <ExistingTenantForm
                  tenantProfileId={tenantProfileId}
                  existingTenants={existingTenants}
                />
              </>
            )}
            {showMessage ? (
              <BaselaneMessageCard
                iconName="exclamationcircle"
                borderColor="blue"
                iconColor="blue"
                text="This email account is already associated with a Landlord account. Please use an alternative email to create a Tenant."
                containerStyles={{ mb: '30px' }}
              />
            ) : null}
          </>
        )}
      </Formik>
    </Box>
  );
}

export default TenantDetailsForm;
