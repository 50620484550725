import React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { Heading, Stack, Text, Box } from '@chakra-ui/react';

import { BaselaneCardNew } from '@shared/components/BaselaneCard-new';
import { useCurrentWorkspace } from '@shared/hooks';
import IllustrationReferral from '@icons/manual/IllustrationReferral';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { REFERRAL } from '@routes';

import BaselaneButton from './BaselaneButton';
import BaselaneShareLink from './BaselaneShareLink';

const TransferReferralSuccessCard = ({ source, ...rest }: { source: string }) => {
  const { workspaceMetadata } = useCurrentWorkspace();
  const { referralLink } = workspaceMetadata ?? {};

  const navigate = useNavigate();

  const handleButtonClickOnMobile = () => {
    if (navigator?.share) {
      navigator
        .share({
          title: 'Sign up to Baselane',
          url: referralLink,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleSegmentEvent = () => {
    sendSegmentEvent('referral_success_drawer_copy_link_clicked', { source });
  };

  return (
    <BaselaneCardNew size="md" variant="normal" palette="primary" width="100%" {...rest}>
      <Stack spacing={2} width="100%">
        <Stack direction="row" spacing={2}>
          <Box>
            <IllustrationReferral />
          </Box>
          <Stack spacing={0.5}>
            <Heading size="headline-md">Refer a landlord and earn $100</Heading>
            <Text textStyle="sm">
              Invite a landlord to sign up with your referral link. You both earn $100 when they
              collect rent into their Baselane Banking account.
            </Text>
            <Box>
              <BaselaneButton
                variant="transparent"
                palette="primary"
                pullLeft
                onClick={() => navigate({ pathname: REFERRAL })}
              >
                Learn more
              </BaselaneButton>
            </Box>
          </Stack>
        </Stack>
        <BaselaneShareLink
          link={referralLink}
          text={{
            default: isMobile ? 'Share' : 'Copy Link',
            copied: isMobile ? 'Share' : 'Copy Link',
          }}
          buttonProps={{
            variant: 'filled',
            palette: 'primary',
            size: 'md',
            icon: {
              color: '#fff',
            },
            flex: '0 0 auto',
          }}
          handleButtonClickOnMobile={handleButtonClickOnMobile}
          sendDesktopSegmentEvent={handleSegmentEvent}
          sendMobileSegmentEvent={handleSegmentEvent}
        />
      </Stack>
    </BaselaneCardNew>
  );
};

export default TransferReferralSuccessCard;
