import React, { useEffect, useState } from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { IconX } from '@icons';
import { Icon16Copy, Icon16Check } from '@icons/16px';
import useBreakPoints from '@core/hooks/useBreakPoints';
import BaselaneGrid from '../../../../BaselaneGrid';
import { bankInfoBoxContainerStyles } from './styles/copy.styles';

type ElementsToCopyProps = {
  bankingAccountInfoList: Array<Object>,
  selectedBaselaneAccount: Function,
};

const ElementsToCopy = ({
  bankingAccountInfoList,
  selectedBaselaneAccount,
}: ElementsToCopyProps) => {
  const { isMin768: showDesktopView } = useBreakPoints();
  const [bankInfoBoxState, setBankInfoBoxState] = useState({});
  const handleCopyToClipboard = (e, elementId) => {
    const elementToCopy = document.getElementById(elementId)?.textContent;

    if (navigator && navigator.clipboard) {
      navigator.clipboard
        .writeText(elementToCopy)
        .then(() => {
          setBankInfoBoxState({
            [elementId]: {
              bgColor: 'green.100',
              _hover: { bgColor: 'green.100' },
              copiedicon: <Icon16Check />,
            },
          });
        })
        .catch(() => {
          setBankInfoBoxState({
            [elementId]: {
              bgColor: 'red.200',
              _hover: { bgColor: 'red.200' },
              copiedicon: <IconX />,
            },
          });
        });
    }
  };

  const getGridItems = () =>
    (bankingAccountInfoList || []).map((item) => {
      const info = selectedBaselaneAccount[item.key];
      const elementId = `account-info-${selectedBaselaneAccount.id}`;
      const elementTextId = `account-info-${selectedBaselaneAccount.id}-${item.key}`;

      return {
        element: (
          <Stack
            key={elementId}
            id={elementId}
            onClick={(e) => handleCopyToClipboard(e, elementTextId)}
            styles={bankInfoBoxContainerStyles}
            direction="row"
            {...bankInfoBoxContainerStyles}
            {...bankInfoBoxState[elementTextId]}
          >
            <Stack direction="column" gap="0">
              <Text textStyle="xs" color="brand.neutral.600">
                {item.label}
              </Text>
              <Text id={elementTextId} textStyle="sm" color="brand.neutral.900" noOfLines={2}>
                {info}
              </Text>
            </Stack>
            <Stack alignSelf="center">
              {bankInfoBoxState[elementTextId]?.copiedicon || <Icon16Copy />}
            </Stack>
          </Stack>
        ),
      };
    });

  useEffect(() => {
    if (Object.keys(bankInfoBoxState).length > 0) {
      setTimeout(() => {
        setBankInfoBoxState({});
      }, 2000);
    }
  }, [bankInfoBoxState]);

  return (
    <BaselaneGrid
      gridItems={getGridItems()}
      templateColumns={!showDesktopView ? '1fr' : '1fr 1fr'}
      templateRows="auto"
      gap={1.5}
      gridStyles={{ mt: 1 }}
    />
  );
};

export default ElementsToCopy;
