import Cookies from 'js-cookie';
import parser from 'ua-parser-js';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import userStorage from '@core/storage/userStorage';
import searchStorage from '@core/storage/searchStorage';
import previousPageStorage from '@core/storage/previousPageStorage';
import inActivityLogoutStorage from '@core/storage/inActivityLogoutStorage';

export type UtmInput = {
  utmSource: string,
  utmMedium: string,
  utmCampaign: string,
  utmTerm: string,
  utmContent: string,
  utmId: string,
  irClickId: string,
  gclId: string,
  partnerNid: string,
  fbc: string,
  fbp: string,
  referralUrl: string,
  ip: string,
  userAgent: string,
  source: string,
  gaClientId: string,
  gaSessionId: string,
  utmUpdatedAt: string,
  utmCreatedAt: string,
};

export const utmAttributesMap = {
  utm_id: 'utmId',
  utm_campaign: 'utmCampaign',
  utm_source: 'utmSource',
  utm_content: 'utmContent',
  utm_term: 'utmTerm',
  utm_medium: 'utmMedium',
  utm_funnel: 'utmFunnel',
  irclickid: 'irClickId',
  partnernid: 'partnerNid',
  gclid: 'gclId',
};

export const getCookie = (name) => {
  return document.cookie.split(';').some((c) => {
    return c.trim().startsWith(`${name}=`);
  });
};

export const deleteCookie = (name, path, domain) => {
  if (getCookie(name)) {
    document.cookie = `${name}=${path ? `;path=${+path}` : ''};${
      domain ? `domain=${domain}` : ''
    };expires=Thu, 01 Jan 1970 00:00:01 GMT";`;
  }
};

export const hasAnyUtm = (queryParams: URLSearchParams) =>
  Object.keys(utmAttributesMap).reduce(
    (compoundingResult, utmKey) => compoundingResult || queryParams.has(utmKey),
    false
  );

export const isEmpty = (object: Object) => Object.keys(object)?.length === 0;

export const getAllUtmData = (ipAddress, urlUTM, eventString) => {
  // find UTM first_touch and last_touch in cookie from website
  const userAttributionTracking = JSON.parse(Cookies.get('userAttributionTracking') || null) || {};
  const usrAttTrLt = userAttributionTracking?.last_touch;
  const usrAttTrFt = userAttributionTracking?.first_touch;

  // facebook
  const fbp = Cookies.get('_fbp') || null;
  const fbc = Cookies.get('_fbc') || null;

  // google
  const gaSessionCookie = Cookies.get('_ga_SVXRZHMJLD');
  const gaSessionId = gaSessionCookie?.split('.')[2];
  const gaClientCookie = Cookies.get('_ga');
  const gaClientId = gaClientCookie?.substring(6);

  // referral
  const referralUrl = Cookies.get('baselaneCookieReferrer') || null;

  // set todays date in case there's no timestamp or utmCreatedAt
  const utmCreatedAt = new Date().toUTCString();
  const newUrlUTM = urlUTM;
  if (urlUTM && !isEmpty(urlUTM) && !urlUTM.utmCreatedAt) newUrlUTM.utmCreatedAt = utmCreatedAt;

  // Get last_touch from url or cookie
  const utms = [];
  const utmObject = {
    ...((urlUTM && !isEmpty(urlUTM)) || eventString !== 'account_created'
      ? newUrlUTM
      : {
          utmId: usrAttTrLt?.utm_id_lt || null,
          utmSource: usrAttTrLt?.utm_source_lt || null,
          utmCampaign: usrAttTrLt?.utm_campaign_lt || null,
          utmMedium: usrAttTrLt?.utm_medium_lt || null,
          utmTerm: usrAttTrLt?.utm_term_lt || null,
          utmContent: usrAttTrLt?.utm_content_lt || null,
          utmFunnel: usrAttTrLt?.utm_funnel_lt || null,
          gclId: usrAttTrLt?.gclid_lt || null,
          utmCreatedAt: usrAttTrLt?.timestamp_lt || utmCreatedAt,
        }),
    ip: ipAddress,
    userAgent: navigator.userAgent || null,
    source: 'COOKIE',
    fbp: fbp || null,
    fbc: fbc || null,
    gaSessionId: gaSessionId || null,
    gaClientId: gaClientId || null,
    referralUrl: referralUrl || null,

    // New Fields, get first_touch from cookie or URL
    ...(eventString === 'account_created'
      ? {
          utmIdFt: usrAttTrFt?.utm_id_ft || urlUTM?.utmId || null,
          utmSourceFt: usrAttTrFt?.utm_source_ft || urlUTM?.utmSource || null,
          utmCampaignFt: usrAttTrFt?.utm_campaign_ft || urlUTM?.utmCampaign || null,
          utmMediumFt: usrAttTrFt?.utm_medium_ft || urlUTM?.utmMedium || null,
          utmTermFt: usrAttTrFt?.utm_term_ft || urlUTM?.utmTerm || null,
          utmContentFt: usrAttTrFt?.utm_content_ft || urlUTM?.utmContent || null,
          utmFunnelFt: usrAttTrFt?.utm_funnel_ft || urlUTM?.utmFunnel || null,
          gclIdFt: usrAttTrFt?.gclid_ft || urlUTM?.gclId || null,
          timestampFt: usrAttTrFt?.timestamp_ft || utmCreatedAt,
        }
      : {}),
  };
  utms.push(utmObject);

  return { utms, utmObject };
};

export const getUrlUtmData = (ip, location) => {
  const queryParams = new URLSearchParams(location.search);

  if (hasAnyUtm(queryParams)) {
    const utmData = {};

    // add all utm keys
    Object.keys(utmAttributesMap).forEach((utmKey) => {
      utmData[utmAttributesMap[utmKey]] = queryParams.get(utmKey);
    });

    const utmDataOut: UtmInput = {
      ...utmData,
      ip,
      userAgent: navigator?.userAgent,
      source: 'URL',
    };

    return utmDataOut;
  }
  return {};
};

export const URL_UTM_KEY = 'urlUTM';

export const clearUrlUtmData = () => {
  localStorage.removeItem(URL_UTM_KEY);
};

export const retrieveStoredUrlUtmData = (clearData) => {
  const urlUTM = JSON.parse(localStorage.getItem(URL_UTM_KEY));
  if (clearData) clearUrlUtmData();
  return urlUTM;
};

export const storeUrlUtmData = (ip, location) => {
  let urlUtmData = getUrlUtmData(ip, location);
  if (urlUtmData === {}) {
    urlUtmData = retrieveStoredUrlUtmData(false);
    if (urlUtmData) {
      // just update ip address if data is gone from url,
      // but already exists in the storage
      urlUtmData.ip = ip;
    }
  }
  localStorage.setItem(URL_UTM_KEY, JSON.stringify(urlUtmData));
};

export const getUtmInputs = (ip, location, eventString): Array<UtmInput> => {
  const storedUrlUtm = retrieveStoredUrlUtmData(false);
  const urlUtm = getUrlUtmData(ip, location);
  const { utms: utmInputs } = getAllUtmData(
    ip,
    // Note: current URL utms will override stored ones
    { ...storedUrlUtm, ...urlUtm },
    eventString
  );
  return utmInputs;
};

export const deleteUtmCookies = () => {
  deleteCookie('baselaneQueryData:facebook');
  deleteCookie('baselaneQueryData:google');
  deleteCookie('baselaneQueryData:Latest');
  deleteCookie('baselaneQueryData:facebook', null, '.baselane.com');
  deleteCookie('baselaneQueryData:google', null, '.baselane.com');
  deleteCookie('baselaneQueryData:Latest', null, '.baselane.com');
};

export const identifyUser = (user) => {
  if (user) {
    const { uid, displayName, email } = user;
    const ua = parser(navigator.userAgent);
    window.analytics.identify(uid, {
      name: displayName,
      email,
      user_type: 'landlord',
      event_source: 'landlord_portal',
      userAgent: navigator.userAgent,
      os: `${ua.os.name} ${ua.os.version}`,
      device: `${ua.device.vendor} ${ua.device.model}`,
    });
  }
};

export const trackUser = (user: firebase.User, event: String, data: any = {}) => {
  if (user) {
    const { uid, displayName, email, utm } = user;
    sendSegmentEvent(event, {
      ...data,
      landlord_uuid: uid,
      landlord_name: displayName,
      landlord_email: email,
      utm,
      user_type: 'landlord',
      event_source: 'landlord_portal',
      orderId: `${event}_${uid}`,
    });
  }
};

export const trackUtmUser = (user: firebase.User, event: string, ipAddress: string, urlUTM) => {
  // Fired by trackLogin only
  const userUtm = { ...user };
  if (userUtm && ipAddress) {
    const { utmObject } = getAllUtmData(ipAddress, urlUTM, event);

    // SAVE COOKIES IN LOCAL STORAGE FOR TRAITS
    // save any found utm objects in user
    const newUtm = { ...userUtm.utm, ...utmObject };
    if (userUtm.utm) {
      delete userUtm.utm;
    }
    userUtm.utm = newUtm;

    const { id, email, phoneNumber, utm } = userUtm;
    const gclid = utm?.google?.gclId || utm?.signUp?.gclId;
    const userInfo = { id, email, phone_Number: phoneNumber, gclid, ip: ipAddress, utm };
    userStorage.write(userInfo);

    // TRACK USER if event provided
    if (event) {
      trackUser(userUtm, event);
    }
  } else if (event) {
    // Signup auto login will do this
    trackUser(user, event);
  }
};

export const trackLogin = (user: firebase.User, ipAddress, urlUTM) => {
  trackUtmUser(user, 'landlord_logged_in', ipAddress, urlUTM);
};

export const trackPreLoginOTP = (user: firebase.User) => {
  trackUser(user, 'landlord_signin_otp_triggered');
};

export const saveSearch = () => {
  const currentSearch = window.location.search?.split('?')?.[1] ?? null;
  // Store any search parameters to local storage. To be used when sending events to segment to provide utm metadata
  searchStorage.write(currentSearch);
};

export const cleanupRedirects = () => {
  previousPageStorage.delete();
  inActivityLogoutStorage.delete();
  localStorage.removeItem('redirectLogin');
  localStorage.removeItem('baselane-state');
};
