import { gql } from '@apollo/client';

export const GET_BANK_TRACKER = gql`
  query getBankTracker($page: Float, $pageSize: Float) {
    cardSummary(input: { page: $page, pageSize: $pageSize }) {
      total
      data {
        id
        externalId
        bankAccountId
        isPhysical
      }
    }
    bankList {
      id
      unitApplicationStatus
    }
  }
`;

export const GET_BANK_FOR_CARD = gql`
  query getBankForCard($accountId: [Float!]) {
    bank(input: { accountId: $accountId }) {
      id
      name
      unitAccount {
        id
        unitApplicationId
        unitApplicationStatus
        phoneNumber
      }
    }
  }
`;

export const GET_WORKSPACE_USER_METADATA = gql`
  query getSetupGuide {
    currentWorkspace {
      id
      user {
        id
        userMetadata {
          bankingTracker {
            name
            order
            state
          }
          rentCollectionTracker {
            name
            order
            state
          }
          bookkeepingTracker {
            name
            order
            state
          }
        }
      }
    }
  }
`;
