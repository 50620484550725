import React from 'react';
import formatCurrency from '@core/utils/formatCurrency';
import { BaselaneHighlightedDataDisplay } from '@shared/components';

type Props = {
  data: Object,
};

const LateFeeSummarySection = ({ data }: Props) => {
  const {
    initialFeeType,
    initialFeeAmount,
    initialFeePercent,
    initialDueDate,
    incrementAmountType,
    incrementAmount,
    incrementPercent,
    feeCapType,
    feeCapAmount,
    feeCapPercent,
  } = data;

  const initialFee =
    initialFeeType === 'FLAT'
      ? formatCurrency(initialFeeAmount).inDollars
      : `${initialFeePercent}% rent`;

  const dailyValue = incrementAmountType === 'FLAT' ? incrementAmount : incrementPercent;

  const monthlyFee =
    feeCapType === 'FLAT' ? formatCurrency(feeCapAmount).inDollars : `${feeCapPercent}% rent`;

  const createDailyFeeText = () => {
    if (!dailyValue) {
      return `after ${initialDueDate} days`;
    }
    if (incrementAmountType === 'FLAT') {
      return (
        <span>
          + <b>${dailyValue}</b>/day
        </span>
      );
    }
    return (
      <span>
        + <b>{dailyValue}%</b> daily
      </span>
    );
  };

  const lateFeeConfig = {
    title: 'Assess late fee',
    items: [
      {
        key: 'Number of days after rent is due',
        value: initialDueDate,
      },
      {
        key: 'Initial late fee',
        value: initialFee,
      },
      {
        key: 'Daily late fee',
        value: dailyValue ? createDailyFeeText() : '-',
      },
      {
        key: 'Monthly maximum',
        value: monthlyFee !== '$0.00' ? monthlyFee : '-',
      },
    ],
    styles: {
      parent: {
        bg: 'brand.blue.100',
        p: '12px 16px 16px',
      },
    },
  };

  return !initialFeeAmount ? null : (
    <BaselaneHighlightedDataDisplay configuration={lateFeeConfig} />
  );
};

export default LateFeeSummarySection;
