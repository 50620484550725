import { React, useEffect, useState } from 'react';
import { Box, Heading, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';
import { useApolloClient, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { BaselaneCardNew, BaselaneButton } from '@shared/components';
import CashFlowCompositeChart from '@core/components/CashFlowPage/components/CashFlowCompositeChart';
import { CASHFLOW_DASHBOARD_CARD, GET_PRESETS } from '@core/apollo/queries';
import { dashboardFilters } from '@shared/helpers/cashFlow.helpers';
import { ANALYTICS_REPORTING } from '@core/constants/routes';
import formatCurrency from '@core/utils/formatCurrency';
import transformToBarChartDataset from '@core/components/CashFlowPage/services/transformToBarChartDataset';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import useBreakPoints from '@core/hooks/useBreakPoints';
import LegendDot from './LegendDot';
import EmptyState from './EmptyState';
import DisconnectedAccountsAlertBanner from './DisconnectedAccountsAlertBanner';

const CashflowCard = (): any => {
  const navigate = useNavigate();

  const { cache: apolloCache } = useApolloClient();
  const { isMin768 } = useBreakPoints();

  const [dataset, setDataset] = useState(null);
  const [netCashFlow, setNetCashFlow] = useState(null);
  const [totalExpenseAmount, setTotalExpenseAmount] = useState(null);
  const [totalRevenueAmount, setTotalRevenueAmount] = useState(null);
  const [noData, setNoData] = useState(false);
  const [filters, setFilters] = useState();

  const { data: presets } = useQuery(GET_PRESETS);

  // skip this until the presets are loaded, otherwise the filters will
  // be different than on the cashflow page chart
  const { loading, data } = useQuery(CASHFLOW_DASHBOARD_CARD, {
    skip: !filters,
    variables: {
      input: {
        filter: filters,
      },
    },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    const filter = { ...dashboardFilters?.filter };
    filter.tagId = presets?.preset[0]?.tagId;
    setFilters(filter);
  }, [presets]);

  useEffect(() => {
    const {
      cashFlow: { byMonth, summary },
    } = data || { cashFlow: {} };

    if (byMonth && byMonth.length > 0) {
      setDataset(transformToBarChartDataset(byMonth));
    }

    if (summary) {
      setNetCashFlow(summary.netCashFlow);
      setTotalExpenseAmount(summary.totalExpenseAmount);
      setTotalRevenueAmount(summary.totalRevenueAmount);
    }

    const { categorizedTrxCount, uncategorizedTrxCount } = summary || {};
    if (categorizedTrxCount + uncategorizedTrxCount === 0) {
      setNoData(true);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      apolloCache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'cashFlow',
      });
      // garbage collect to free up memory
      apolloCache.gc();
    };
  }, []);

  return noData ? (
    <EmptyState />
  ) : (
    <BaselaneCardNew
      w="full"
      h="auto"
      alignItems="flex-start"
      flexDirection="column"
      position="relative"
      gap={{ lg: 3, base: 2 }}
      p={{ lg: 3, base: 2 }}
    >
      <VStack gap={0} w="full" alignItems="flex-start">
        <HStack w="full">
          <Heading size="headline-xl">Cash flow</Heading>
          {isMin768 && (
            <BaselaneButton
              variant="link"
              palette="primary"
              size="lg"
              ml="auto"
              textDecoration="none"
              fontWeight="medium"
              mr={0}
              onClick={() => {
                sendSegmentEvent('temp_view_details');
                navigate({ pathname: ANALYTICS_REPORTING });
              }}
            >
              View details
            </BaselaneButton>
          )}
        </HStack>
        <Text textStyle="sm" color="brand.neutral.700">
          Last 6 months &middot; All properties
        </Text>
      </VStack>
      <DisconnectedAccountsAlertBanner />
      <HStack gap={3}>
        <VStack alignItems="flex-start">
          <Text textStyle="xs">
            <LegendDot innerColor="red.500A" outerColor="brand.neutral.200" />
            Net cash flow
          </Text>
          <Skeleton isLoaded={!loading}>
            <Text textStyle="data-lg" minW={loading ? '6em' : '0'}>
              {netCashFlow < 0 && '-'}
              {formatCurrency(Math.abs(netCashFlow)).rounded}
            </Text>
          </Skeleton>
        </VStack>
        <VStack alignItems="flex-start">
          <Text textStyle="xs">
            <LegendDot innerColor="brand.blue.700" />
            Inflow
          </Text>
          <Skeleton isLoaded={!loading}>
            <Text textStyle="data-lg" minW={loading ? '6em' : '0'}>
              {formatCurrency(Math.abs(totalRevenueAmount)).rounded}
            </Text>
          </Skeleton>
        </VStack>
        <VStack alignItems="flex-start">
          <Text textStyle="xs">
            <LegendDot innerColor="brand.blue.250" />
            Outflow
          </Text>
          <Skeleton isLoaded={!loading}>
            <Text textStyle="data-lg" minW={loading ? '6em' : '0'}>
              {formatCurrency(Math.abs(totalExpenseAmount)).rounded}
            </Text>
          </Skeleton>
        </VStack>
      </HStack>
      <Box id="chart-container" w="full">
        <Skeleton isLoaded={!loading}>
          {dataset ? (
            <CashFlowCompositeChart
              dataset={dataset}
              config={{
                values: {
                  barOne: 'revenues',
                  barTwo: 'expenses',
                  lineOne: 'netIncome',
                },
              }}
              dataPointsInViewport={dataset?.length}
              isDashboardWidget
            />
          ) : (
            <Box w="full" minH="168px" />
          )}
        </Skeleton>
      </Box>
    </BaselaneCardNew>
  );
};

export default CashflowCard;
