import React from 'react';
import { useFormikContext } from 'formik';
import { BaselaneButton } from '@shared/components';
import {} from '../../sendFundsStore';

type SuccessScreenFooterProps = {
  handleMakeAnotherTransfer: Function,
  handleDrawerClose: Function,
};
const SuccessScreenFooter = ({
  handleMakeAnotherTransfer,
  handleDrawerClose,
}: SuccessScreenFooterProps) => {
  const formik = useFormikContext();
  const { resetForm } = formik;

  return (
    <>
      <BaselaneButton
        id="payment-success-done-button"
        size="md"
        variant="outline"
        palette="neutral"
        onClick={() => handleDrawerClose({ resetForm })}
      >
        Done
      </BaselaneButton>
      <BaselaneButton
        id="payment-success-another-payment-button"
        size="md"
        variant="tonal"
        palette="primary"
        width="100%"
        ml={1.5}
        flex={1}
        onClick={() => handleMakeAnotherTransfer(resetForm)}
      >
        Make another payment
      </BaselaneButton>
    </>
  );
};
export default SuccessScreenFooter;
