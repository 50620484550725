// @flow
import React, { useRef, useEffect, useState } from 'react';
import { TabList, Tabs, TabPanels, TabPanel, Tab, Box, ChakraProvider } from '@chakra-ui/react';
import {
  borderSimpleColors,
  borderStatusActiveColors,
} from '@core/theme/component/Tag/tagColorPresets';
import colors from '@core/theme/colors';
import habitatTheme from '../../../../themeHabitat';

type TabItem = {|
  name: string,
  key: string,
  content: React.Node,
|};

type BaselaneTabGroupProps = {|
  tabs: Array<TabItem>,
  size?: 'md' | 'lg',
  isFilled?: boolean,
  onChange?: (index: number) => void,
  defaultIndex?: number,
  isTabsOnly?: boolean,
|};

function BaselaneTabGroup({
  tabs,
  size = 'md',
  isFilled = false,
  onChange,
  defaultIndex,
  isTabsOnly = false,
  ...props
}: BaselaneTabGroupProps): React.Node {
  const tabListRef = useRef<HTMLDivElement | null>(null);
  const [tabIndex, setTabIndex] = useState(defaultIndex);

  useEffect(() => {
    const tabList = tabListRef.current;
    if (!tabList) return undefined;

    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    const handleMouseDown = (e: MouseEvent) => {
      isDown = true;
      tabList.style.cursor = 'grabbing';
      startX = e.pageX - tabList.offsetLeft;
      scrollLeft = tabList.scrollLeft;
    };

    const handleMouseLeave = () => {
      isDown = false;
      tabList.style.cursor = 'grab';
    };

    const handleMouseUp = () => {
      isDown = false;
      tabList.style.cursor = 'grab';
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - tabList.offsetLeft;
      const walk = (x - startX) * 2;
      tabList.scrollLeft = scrollLeft - walk;
    };

    tabList.addEventListener('mousedown', handleMouseDown);
    tabList.addEventListener('mouseleave', handleMouseLeave);
    tabList.addEventListener('mouseup', handleMouseUp);
    tabList.addEventListener('mousemove', handleMouseMove);

    return (): void => {
      tabList.removeEventListener('mousedown', handleMouseDown);
      tabList.removeEventListener('mouseleave', handleMouseLeave);
      tabList.removeEventListener('mouseup', handleMouseUp);
      tabList.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <ChakraProvider theme={habitatTheme}>
      <Tabs
        onChange={onChange}
        index={tabIndex}
        defaultIndex={defaultIndex}
        size={size}
        sx={{
          position: 'relative',
          '& [role="tablist"]': {
            whiteSpace: 'nowrap',
          },
        }}
        {...props}
      >
        <TabList
          ref={tabListRef}
          sx={{
            width: isFilled ? '100%' : 'auto',
            cursor: 'grab',
          }}
        >
          {tabs.map((tab, index) => (
            <Box
              key={tab.key}
              w={isFilled ? '100%' : 'auto'}
              px={size === 'md' ? '8px' : '12px'}
              border="1px dashed transparent"
              borderRadius="4px"
              onClick={() => {
                setTabIndex(index);
                onChange?.(index);
              }}
              sx={{
                '&:has(button:focus-visible)': {
                  border: `1px dashed ${colors.brand.blue['800A']}`,
                },
                'button:focus': {
                  boxShadow: 'none',
                },
              }}
              _hover={{
                borderColor: 'transparent',
                cursor: 'pointer',
                button: {
                  borderColor: borderSimpleColors.neutral,
                },
              }}
              _active={{
                button: {
                  borderColor: borderStatusActiveColors.neutral,
                },
              }}
            >
              <Tab size={size} px={0} m={0} py={size === 'md' ? '12px' : '16px'}>
                {tab.name}
              </Tab>
            </Box>
          ))}
        </TabList>

        <TabPanels height={isTabsOnly ? '16px' : '100%'}>
          {tabs.map((tab) => (
            <TabPanel key={tab.key} borderTopWidth="1px">
              {tab.content}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </ChakraProvider>
  );
}

export default BaselaneTabGroup;
