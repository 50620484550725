import React, { useState, useEffect } from 'react';
import {
  HStack,
  Heading,
  Flex,
  Stack,
  Text,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useToast,
} from '@chakra-ui/react';
import LogRocket from 'logrocket';
import { useLazyQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { useSearchParams } from 'react-router-dom';
import {
  BaselaneCardNew,
  BaselaneButton,
  BaselaneDivider,
  BaselaneBannerNew,
} from '@shared/components';
import { panelFormResponsiveStyles } from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';
import { IconPoweredByRocketLawyer } from '@icons';
import {
  Icon16Link,
  Icon16Document,
  Icon16Legalotherprofessionalfees,
  Icon16Filter,
  Icon16Notes,
} from '@icons/16px';
import RocketLawyeriFrame from '@shared/components/RocketLawyerDocumentDrawer/components/RocketLawyeriFrame/RocketDocument';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { IllustrationOtherSuccess } from '@core/components/Illustrations';
import Header from '../Header';
import { CHARGEBEE_URL } from '../../../queries';
import RocketLayerDocsIframe from '../RocketLayerDocsIframe';
import {
  useLeaseAgreementPaymentStatus,
  useLeaseAgreementId,
  useCompletedTags,
  useSetIsReviewLeaseStepValid,
  useSetHostedPageId,
  useHostedPageId,
  useIsReviewLeaseStepValid,
  useSelectedPropertyObject,
  useTabIndex,
  useSetLeaseAgreementState,
} from '../../leaseAgreementStore';
import { useLeaseAgreement } from '../../../hooks/useLeaseAgreement';

const ReviewOrderStep = () => {
  const { status, submitForm } = useFormikContext();
  const { isMax576, isMax768 } = useBreakPoints();
  const toast = useToast();
  const [showiFrame, setShowiFrame] = useState(false);
  const [isDocReadyToSign, setIsDocReadyToSign] = useState(false);
  const [selectedPropertyDoc, setSelectedPropertyDoc] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDocsIframe, setShowDocsIframe] = useState(false);
  const hostedPageId = useHostedPageId();
  const leaseAgreementPaymentStatus = useLeaseAgreementPaymentStatus();
  const leaseAgreementId = useLeaseAgreementId();
  const completedTags = useCompletedTags();
  const setIsReviewLeaseStepValid = useSetIsReviewLeaseStepValid();
  const setHostedPageId = useSetHostedPageId();
  const isReviewLeaseStepValid = useIsReviewLeaseStepValid();
  const selectedProertyObject = useSelectedPropertyObject();
  const tabIndex = useTabIndex();
  const setLeaseAgreementState = useSetLeaseAgreementState();
  const showEnrollErrorToast = () =>
    toast({
      description: 'Issue updating your data, try again later',
      status: 'error',
      duration: '2000',
      isClosable: true,
      position: !isMax576 ? 'bottom' : 'bottom-left',
    });

  const showChargebeeErrorToast = () =>
    toast({
      description: 'Issue updating your data, try again later',
      status: 'error',
      duration: '2000',
      isClosable: true,
      position: !isMax576 ? 'bottom' : 'bottom-left',
    });

  const showChargebeeSuccessToast = () => {
    toast({
      description: 'Payment successful',
      status: 'success',
      duration: '2000',
      isClosable: true,
      position: !isMax576 ? 'bottom' : 'bottom-left',
    });
  };

  const items = [
    {
      icon: <Icon16Legalotherprofessionalfees />,
      text: `Legally binding and enforceable in the state of ${selectedProertyObject?.address?.state}`,
    },
    {
      icon: <Icon16Filter />,
      text: 'Customize terms related to late fees, pets, subletting, utilities, and more',
    },
    { icon: <Icon16Link />, text: 'E-signing included' },
    { icon: <Icon16Notes />, text: 'Integrated with Baselane&quots rent collection tool' },
  ];
  const chargebeeSite = process.env.REACT_APP_CHARGEBEE_SITE;
  const chargebeePublishableKey = process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY;
  const [enrollmentUrl, setEnrollmentUrl] = useState(null);
  const [generateChargeCheckoutUrl] = useLazyQuery(CHARGEBEE_URL);
  const [searchParams] = useSearchParams();
  const draftId = searchParams.get('draftId');
  const { leaseAgreement, refetch } = useLeaseAgreement(leaseAgreementId || draftId);
  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const handleCloseiFrame = () => {
    setShowiFrame(false);
    setSelectedPropertyDoc(null);
    setIsModalOpen(false);
  };

  const handleStartSigning = async () => {
    if (
      completedTags.includes('LEASE_TERM') &&
      completedTags.includes('TENANT_TAB') &&
      leaseAgreementPaymentStatus !== 'paid'
    ) {
      setIsModalOpen(true);
      try {
        window.Chargebee.init({
          site: chargebeeSite,
          publishableKey: chargebeePublishableKey,
        });
        const checkout = await window.Chargebee.getInstance().createCheckout({
          url: enrollmentUrl,
          callbacks: {
            error: (error: any) => {
              LogRocket.log(error);
              showChargebeeErrorToast();
            },
            close: () => {
              onModalClose();
              refetch();
            },
            success: (data) => {
              showChargebeeSuccessToast();
              setHostedPageId(data);
              submitForm();
            },
          },
        });
        // Mount checkout on the container
        checkout.mount('#checkout').then((res) => console.log('res', res));
      } catch (error) {
        LogRocket.log(error);
      }
    } else {
      setSelectedPropertyDoc(leaseAgreement);
      setShowiFrame(true);
    }
  };
  useEffect(() => {
    if (status?.success && hostedPageId) {
      setShowiFrame(true);
      if (
        status?.success?.paymentStatus === 'paid' &&
        status?.success?.state === 'READY_TO_CUSTOMIZE'
      ) {
        setIsReviewLeaseStepValid(true);
        setLeaseAgreementState(status?.success?.state);
      } else {
        setIsReviewLeaseStepValid(false);
      }
    }
    if (status?.error) {
      console.error('Form submission failed:', status.error);
    }
  }, [status, hostedPageId]);

  useEffect(() => {
    if (
      (leaseAgreement?.state === 'READY_TO_CUSTOMIZE' ||
        leaseAgreement?.state === 'READY_TO_SIGN') &&
      (leaseAgreement?.paymentStatus === 'paid' || leaseAgreement?.isMigrated)
    ) {
      setIsReviewLeaseStepValid(true);
    } else {
      setIsReviewLeaseStepValid(false);
    }
  }, [leaseAgreement]);

  useEffect(() => {
    if (
      completedTags.includes('LEASE_TERM') &&
      completedTags.includes('TENANT_TAB') &&
      tabIndex === 3 &&
      !leaseAgreement?.isMigrated
    ) {
      setSelectedPropertyDoc(null);
      generateChargeCheckoutUrl({
        variables: {
          checkoutUrlInput: {
            id: leaseAgreementId || draftId,
            itemPrice: ['4'],
            layout: 'IN_APP',
          },
        },
      })
        .then((res) => {
          setEnrollmentUrl(res?.data?.generateChargeCheckoutUrl);
        })
        .catch((error) => {
          LogRocket.log(error);
          showEnrollErrorToast();
        });
    }
  }, [completedTags, tabIndex, draftId]);

  return (
    <Box {...panelFormResponsiveStyles(isMax768)}>
      <Header />
      <BaselaneCardNew palette="dark">
        <Flex direction="column" w="100%">
          <HStack justifyContent="space-between" my="1">
            <Heading size="headline-lg" color="brand.neutral.900">
              {selectedProertyObject?.address?.state} lease agreement
            </Heading>
            <IconPoweredByRocketLawyer />
          </HStack>
          <BaselaneDivider my="3" />
          <Stack gap="2">
            <Flex w="100%" direction="column" gap={2}>
              {items.map((item, index) => (
                <Flex align="center">
                  {item.icon}
                  <Text textStyle="sm" color="brand.neutral.700" ml="1.5">
                    {item.text}
                  </Text>
                </Flex>
              ))}
              {isReviewLeaseStepValid && (
                <BaselaneBannerNew
                  title="Payment received"
                  bannerIconName={IllustrationOtherSuccess}
                  hasActionButton={false}
                  variant="success-medium"
                  hasCloseButton={false}
                  styles={{
                    container: {
                      marginBottom: '16px',
                    },
                    alert: {
                      borderRadius: '8px',
                    },
                  }}
                />
              )}
            </Flex>
          </Stack>
          {!isReviewLeaseStepValid && (
            <BaselaneButton
              variant="filled"
              palette="primary"
              size="lg"
              mt="4"
              onClick={handleStartSigning}
            >
              Customize and e-sign for $10
            </BaselaneButton>
          )}
        </Flex>
      </BaselaneCardNew>

      <BaselaneButton
        variant="transparent"
        palette="primary"
        size="md"
        mt="4"
        leftIcon={<Icon16Document />}
        pullLeft
        onClick={() => {
          sendSegmentEvent('lease_agreement_fe_preview_clicked', { in_flow: true });
          setShowDocsIframe(true);
        }}
      >
        Preview lease agreement template
      </BaselaneButton>
      <Modal isOpen={isModalOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent alignItems="center" justifyContent="center" height="80%" top="10%">
          <ModalBody id="checkout" w="100%" h="100%" p={0} m={0} />
        </ModalContent>
      </Modal>
      {showDocsIframe && (
        <RocketLayerDocsIframe
          handleCloseiFrame={setShowDocsIframe}
          showiFrame={showDocsIframe}
          templateId={leaseAgreement?.templateId}
        />
      )}
      {selectedPropertyDoc && (
        <RocketLawyeriFrame
          {...{
            showiFrame,
            handleCloseiFrame,
            isDocReadyToSign,
            setIsDocReadyToSign,
            selectedPropertyDoc,
          }}
        />
      )}
    </Box>
  );
};
export default ReviewOrderStep;
