import React from 'react';
import { Route, Routes } from 'react-router-dom';

import MoveMoneyRoutes from '@core/components/App/routes/MoveMoneyRoutes';
import AddAccountsDrawer from '@shared/components/AddAccountsDrawer';
import TransactionDetailsDrawer from '@core/components/Transactions/transaction-details-drawer/TransactionDetailsDrawer';
import BankTransfer from '@pages/Transfers/components/AddFunds/components/BankTransfer';
import CreateAutoTagRuleDrawer from '@core/components/CreateAutoTagRuleDrawer';
import { RocketLawyerDocumentDrawer } from '@core/components/Shared/components';

import {
  ADD_ACCOUNT,
  DASHBOARD,
  TRANSACTION,
  TRANSACTION_ID,
  ADD_FUNDS_BANK_TRANSFER,
  CREATE_LEASE_DOCUMENT,
  BOOKKEEPING_RULES_ID,
} from '@routes';

const DashboardRoutes = ({ from = DASHBOARD }: { from: string }) => {
  return (
    <Routes>
      <Route path="*" element={<MoveMoneyRoutes from={DASHBOARD} />} />
      <Route
        path={`${TRANSACTION}/${TRANSACTION_ID}`}
        element={<TransactionDetailsDrawer from={from} />}
      >
        <Route path={BOOKKEEPING_RULES_ID} element={<CreateAutoTagRuleDrawer />} />
      </Route>
      <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer from={DASHBOARD} />} />
      <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
        <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
      </Route>
      <Route
        path={CREATE_LEASE_DOCUMENT}
        element={<RocketLawyerDocumentDrawer from={DASHBOARD} />}
      />
    </Routes>
  );
};

export default DashboardRoutes;
