import React, { useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Tbody, Text, useDisclosure } from '@chakra-ui/react';
import { NATIVE_BANK, NB_ACCOUNTS, UNIFIED_LB_PERSONAL_INFO } from '@core/constants/routes';
import historyStateStorage from '@core/storage/historyStateStorage';
import BanksContext from '@contexts/BanksContext';
import formatCurrency from '@core/utils/formatCurrency';
import useBreakPoints from '@core/hooks/useBreakPoints';

import {
  BaselaneResponsiveTableHeader,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveCellText,
  BaselaneResponsiveTable,
  ResponsiveActionButtonWrapper,
  ResponsiveActionButton,
  BaselaneApplicationTypePopup,
} from '@shared/components';
import UserAccessContext from '@contexts/UserAccessContext';
import AccountGraphicElement from './components/AccountGraphicElement';
import { closedBadgeStyles } from '../../styles/accountsTable.style';

type AccountsTableProps = {
  /**
   * @prop {Array} - Account list data to render the Accounts Table.
   */
  accountListData: Array,
};

/**
 * Renders a table of accounts as rows with associated graphics.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const AccountsTable = ({ accountListData }: AccountsTableProps) => {
  const { getKYCUrl, hasActiveBaselaneAccounts } = useContext(BanksContext);
  const { isWorkspaceOwner } = useContext(UserAccessContext);
  const navigate = useNavigate();

  const { isMax576: showMobileUI } = useBreakPoints();

  const {
    isOpen: isBaselaneApplicationTypePopupOpen,
    onOpen: onBaselaneApplicationTypePopupOpen,
    onClose: onBaselaneApplicationTypePopupClose,
  } = useDisclosure();

  const headerItems = [
    { key: 'entity', label: 'Entity' },
    {
      key: 'balance',
      label: 'Balance',
      justifyContent: 'flex-end',
    },
  ];

  const config = {
    columns: [
      {
        styles: {
          display: 'table-cell',
          p: { base: '22px 12px', sm: '8px 12px' },
        },
      },
      {
        styles: {
          display: { base: 'none', sm: 'table-cell' },
          width: '160px',
          justifyContent: 'flex-end',
          p: '8px 12px',
        },
      },
    ],
  };

  const openBankingApplicationFlow = (type) => {
    const pathname = UNIFIED_LB_PERSONAL_INFO;
    if (type === 'business') {
      getKYCUrl();
    } else {
      historyStateStorage.write({ from: NATIVE_BANK });
      navigate(pathname);
    }
  };

  return (
    <>
      <BaselaneResponsiveTable id="accountsListTable" config={config}>
        {!showMobileUI && (
          <BaselaneResponsiveTableHeader
            items={headerItems}
            renderItem={(item, index) => (
              <BaselaneResponsiveTableHeading
                key={item.key}
                index={index}
                title={item.label}
                {...{ height: 'fit-content' }}
                justifyContent={item.justifyContent}
              />
            )}
          />
        )}

        <Tbody>
          <BaselaneResponsiveTableRows
            customRow
            items={accountListData}
            renderItem={({
              name,
              applicationType,
              availableBalance,
              bankAccountStatus,
              bankAccountId,
            }) => {
              const isClosed = bankAccountStatus === 'Closed';
              const totalBalance = formatCurrency(availableBalance).inDollars;

              return (
                /* Using a custom row, in order to set indicator and click handler */
                <BaselaneResponsiveTableRow
                  onClick={
                    !isClosed
                      ? () => {
                          const pathname = `${NB_ACCOUNTS}/baselane_${bankAccountId || 'draft'}`;
                          navigate(pathname);
                        }
                      : null
                  }
                >
                  {/* Account  */}
                  <BaselaneResponsiveCellTitle
                    title={name}
                    subtitle={showMobileUI ? totalBalance : null}
                    graphicElement={
                      !showMobileUI && (
                        <AccountGraphicElement
                          isClosed={isClosed}
                          applicationType={applicationType || null}
                        />
                      )
                    }
                    color="brand.neutral.900"
                    configIndex={0}
                    rightTitleElement={isClosed && <Text {...closedBadgeStyles}>CLOSED</Text>}
                  />
                  {/* Balance  */}
                  <BaselaneResponsiveCellText
                    text={formatCurrency(availableBalance).inDollars}
                    configIndex={1}
                    styles={{ text: { textAlign: 'right' } }}
                  />
                </BaselaneResponsiveTableRow>
              );
            }}
          />
        </Tbody>
      </BaselaneResponsiveTable>
      {/* Add Baselane Account Button */}
      {isWorkspaceOwner && (
        <ResponsiveActionButtonWrapper p="0">
          <ResponsiveActionButton
            iconName={showMobileUI ? 'plus' : 'addDottedCircle'}
            iconSize={showMobileUI ? 'sm' : 'md'}
            onClick={onBaselaneApplicationTypePopupOpen}
            buttonProps={{
              buttonStyles: {
                height: '64px',
                justifyContent: showMobileUI ? 'center' : 'flex-start',
              },
            }}
          >
            <Text color="brand.blue.700">Open account for new entity</Text>
          </ResponsiveActionButton>
        </ResponsiveActionButtonWrapper>
      )}

      <BaselaneApplicationTypePopup
        handleActionButton={openBankingApplicationFlow}
        isOpen={isBaselaneApplicationTypePopupOpen}
        onClose={onBaselaneApplicationTypePopupClose}
      />
      <Outlet context={{ isEmptyState: !hasActiveBaselaneAccounts }} />
    </>
  );
};

export default AccountsTable;
