import React, { useEffect, useContext } from 'react';
import { FormControl, FormLabel, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import {
  BaselaneSimpleProgressBar,
  BaselaneTooltip,
  NoPropertyTooltip,
  T2Dropdown,
  T2WithTitleDropdown,
} from '@shared/components';
import { renderPropertyDropdownParentItem } from '@shared/helpers/propertiesFilter.helpers';
import { IconInfo } from '@icons';
import customTheme from '@core/theme';
import { formLabelStyles } from '@shared/styles/input.style';
import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import {
  DisplayInputDefaultCategoryItem,
  DisplayInputDefaultPropertyItem,
} from '@core/components/Transactions/DisplayInputVariations';
import UserAccessContext from '@contexts/UserAccessContext';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { descriptionStyles, drawerHeaderStyles } from './styles/main.styles';
import { title } from '../AddFundsDrawer/styles/addFundsDrawer.styles';

type PropertyAndCategoryTaggingFormProps = {
  currentStep: number,
  totalSteps: number,
  handlePropertySubmit: Function,
  handleCategorySubmit: Function,
  propertyOptions: Array<Object>,
  propertyOptionsTooltipText?: String,
  elementBelowPropertyDropdown?: any | null,
  categoryOptions: Array<Object>,
  categoryOptionsTooltipText?: String,
  categoryMap?: Object,
  categoryIdsMap?: Object,
  elementBelowCategoryDropdown?: any | null,
  isCategoryDropdownDisabled?: Boolean,
  isPropertyDropdownDisabled?: Boolean,
  transferTypeText?: string,
  values?: Object,
  showFieldsOnly?: Boolean,
  showOptionalText?: Boolean,
  titleStyles: String,
  setShowMobileDropdownPopup: Function,
  setShowMobileDropdownPopupAnimation: Function,
  cleanUpACHDrawer: Function,
};

const PropertyAndCategoryTaggingForm = ({
  currentStep,
  totalSteps,
  handlePropertySubmit,
  handleCategorySubmit,
  propertyOptions,
  propertyOptionsTooltipText,
  elementBelowPropertyDropdown,
  categoryOptions,
  categoryOptionsTooltipText,
  categoryIdsMap,
  categoryMap,
  elementBelowCategoryDropdown,
  isCategoryDropdownDisabled,
  isPropertyDropdownDisabled,
  transferTypeText,
  values,
  showFieldsOnly,
  showOptionalText,
  titleStyles,
  setShowMobileDropdownPopup,
  setShowMobileDropdownPopupAnimation,
  cleanUpACHDrawer,
}: PropertyAndCategoryTaggingFormProps) => {
  const { isMax768, isMinXL } = useBreakPoints();
  const hasNoPropertyOptions = propertyOptions?.length === 0;
  const isPropertyAutoTagDisabled = isPropertyDropdownDisabled || hasNoPropertyOptions;
  const { authorizedForBanking } = useContext(UserAccessContext);
  useEffect(() => {
    return () => {
      if (cleanUpACHDrawer) {
        cleanUpACHDrawer();
      }
    };
  }, []);

  const cTitleStyles = { ...title(isMinXL), ...titleStyles };
  return (
    authorizedForBanking && (
      <VStack spacing={isMinXL ? 0 : 2} w="100%">
        {!showFieldsOnly && (
          <>
            <Stack {...drawerHeaderStyles}>
              <BaselaneSimpleProgressBar
                {...{
                  step: currentStep,
                  totalSteps,
                }}
              />
              <Heading {...cTitleStyles}>Property & Category Tagging (Optional)</Heading>
            </Stack>
            <Text {...descriptionStyles}>
              Tag this {transferTypeText} to a property & category to automate your analytics &
              reporting.
            </Text>
          </>
        )}
        <VStack w="100%" spacing={isMinXL ? 2 : 3}>
          {/* Property Tag */}
          <FormControl isInvalid={false}>
            <FormLabel {...{ ...formLabelStyles.xs, display: 'flex' }}>
              {showFieldsOnly ? `Property Auto-Tagging` : `Property Tag`}
              {propertyOptionsTooltipText && (
                <BaselaneTooltip
                  label={propertyOptionsTooltipText}
                  placement="auto"
                  innerContainerStyles={{
                    ml: '9px !important',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <IconInfo
                    width="16"
                    height="16"
                    color={customTheme.colors.brand.neutral['600']}
                  />
                </BaselaneTooltip>
              )}
              {showOptionalText && (
                <Text as="span" ml="auto">
                  Optional
                </Text>
              )}
            </FormLabel>
            {hasNoPropertyOptions ? (
              <NoPropertyTooltip>
                <T2Dropdown
                  additionalProps={{ id: 'property-dropdown' }}
                  classNames={['input-form-lg', 'is-full-width']}
                  data={propertyOptions}
                  searchTerm="name"
                  title="Property"
                  placeholder="Select Property"
                  showValueByFields={['name']}
                  parentItemRenderer={({ item }) => renderPropertyDropdownParentItem(item)}
                  childItemRenderer={itemRenderer}
                  handleSubmit={handlePropertySubmit}
                  isMulti={false}
                  hasFilterWrapper={false}
                  selectedItem={
                    propertyOptions?.find((pm) => pm.id === values?.propertyUnitId) ?? null
                  }
                  CustomDisplayInput={DisplayInputDefaultPropertyItem}
                  isDisabled={isPropertyAutoTagDisabled}
                  isMobile={isMax768}
                />
              </NoPropertyTooltip>
            ) : (
              <T2Dropdown
                additionalProps={{ id: 'property-dropdown' }}
                classNames={['input-form-lg', 'is-full-width']}
                data={propertyOptions}
                searchTerm="name"
                title="Property"
                placeholder="Select Property"
                showValueByFields={['name']}
                parentItemRenderer={({ item }) => renderPropertyDropdownParentItem(item)}
                childItemRenderer={itemRenderer}
                handleSubmit={handlePropertySubmit}
                isMulti={false}
                hasFilterWrapper={false}
                selectedItem={
                  propertyOptions?.find((pm) => pm.id === values?.propertyUnitId) ?? null
                }
                CustomDisplayInput={DisplayInputDefaultPropertyItem}
                isDisabled={isPropertyAutoTagDisabled}
                isMobile={isMax768}
              />
            )}
            {elementBelowPropertyDropdown}
          </FormControl>

          {/* Category Tag */}
          <FormControl isInvalid={false}>
            <FormLabel {...{ ...formLabelStyles.xs, display: 'flex' }}>
              {showFieldsOnly ? `Category Auto-Tagging` : `Category Tag`}
              {categoryOptionsTooltipText && (
                <BaselaneTooltip
                  label={categoryOptionsTooltipText}
                  placement="auto"
                  innerContainerStyles={{
                    ml: '9px !important',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <IconInfo
                    width="16"
                    height="16"
                    color={customTheme.colors.brand.neutral['600']}
                  />
                </BaselaneTooltip>
              )}
              {showOptionalText && (
                <Text as="span" ml="auto">
                  Optional
                </Text>
              )}
            </FormLabel>
            <T2WithTitleDropdown
              additionalProps={{ id: 'category-dropdown' }}
              classNames={['input-form-lg', 'is-full-width']}
              data={categoryOptions}
              title="Category"
              showValueByFields={['name']}
              parentItemRenderer={itemRenderer}
              childItemRenderer={itemRenderer}
              handleSubmit={(value, item, dropDown) => {
                setShowMobileDropdownPopup(false);
                setShowMobileDropdownPopupAnimation({
                  slidein: false,
                  slideout: true,
                });

                handleCategorySubmit(value, dropDown);
              }}
              isMulti={false}
              hasFilterWrapper={false}
              selectedItem={
                values?.categoryId
                  ? {
                      id: categoryIdsMap[values?.categoryId],
                      name: categoryMap[values?.categoryId],
                    }
                  : null
              }
              CustomDisplayInput={DisplayInputDefaultCategoryItem}
              isDisabled={isCategoryDropdownDisabled}
              isMobile={isMax768}
            />
            {elementBelowCategoryDropdown}
          </FormControl>
        </VStack>
      </VStack>
    )
  );
};

PropertyAndCategoryTaggingForm.defaultProps = {
  transferTypeText: 'check deposit',
  categoryMap: {},
  categoryIdsMap: {},
  propertyOptionsTooltipText: null,
  categoryOptionsTooltipText: null,
  elementBelowPropertyDropdown: null,
  elementBelowCategoryDropdown: null,
  isCategoryDropdownDisabled: false,
  isPropertyDropdownDisabled: false,
  values: {},
  showFieldsOnly: false,
  showOptionalText: false,
};
export default PropertyAndCategoryTaggingForm;
