import React, { useContext, useEffect, useState } from 'react';
import { Form, useFormikContext } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useLocation } from 'react-router-dom';
import { Heading, Text, Skeleton, Stack, Box } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { GET_AUTOTAG_RULE_BY_ID, DELETE_AUTOTAG_RULE, GET_BANKS } from '@core/apollo/queries';
import { GET_AUTOTAG_RULES } from '@core/pages/AutoTaggingRulesPage/queries/autotag-queries';
import {
  GET_BANK_SUMMARY,
  UPDATE_BANK_ACCOUNT,
  UPDATE_CARD,
} from '@core/components/NativeBankingPage/queries';
import { Icon16Delete } from '@icons/16px';
import { IllustrationOtherError1Grey } from '@illustrations';
import { BaselaneDrawer, BaselaneDivider, BaselaneButton } from '@shared/components';
import BanksContext from '@contexts/BanksContext';
import Footer from './Footer';
import Category from './category';
import Property from './property';
import Amount from './Amount';
import AccountCard from './account';
import Description from './Description';
import PreviewTransactionsModal from './PreviewTransactionsModal';
import { textOperators, matchTypeOperators, amountOperators } from './constants';
import AutoTaggingGatingBanner from '../Shared/layouts/Banner/AutoTaggingGatingBanner';

type DrawerProps = {
  loading: Boolean,
  isEditDrawer: Boolean,
  ruleId?: String,
};

const Drawer = ({ loading, isEditDrawer, ruleId }: DrawerProps) => {
  const { data: banksData, loading: banksLoading } = useQuery(GET_BANKS, {
    fetchPolicy: 'cache-first',
  });

  const [hasBaselaneAccounts, setHasBaselaneAccounts] = useState();

  const { isMax576 } = useBreakPoints();
  const navigate = useNavigate();
  const { setFieldValue, values } = useFormikContext();
  const { state } = useLocation() || {};
  const {
    transactionsCategoryIdToPrefill,
    categoryValue,
    transactionsPropertyIdToPrefill,
    transactionsUnitIdToPrefill,
  } = state || {};

  const { data: editData, loading: isEditDataLoading } = useQuery(GET_AUTOTAG_RULE_BY_ID, {
    variables: { id: Number(ruleId) },
    skip: !isEditDrawer,
    fetchPolicy: 'no-cache',
  });

  const [deleteAutoTagRule, { loading: isDeleting }] = useMutation(DELETE_AUTOTAG_RULE, {
    refetchQueries: [GET_AUTOTAG_RULES],
  });

  /** Used to sync the legact auto tag settings with the new auto tag rule */
  const [updateBankAccount] = useMutation(UPDATE_BANK_ACCOUNT, {
    refetchQueries: [GET_BANK_SUMMARY],
  });

  const [updateCard] = useMutation(UPDATE_CARD);

  const { refetchBankSummary } = useContext(BanksContext);

  const loadEditData = ({ autotagRule }) => {
    const { conditions, action } = autotagRule;
    const categoryId = action?.tagId;
    const propertyId = action?.propertyId;
    const unitId = action?.unitId;

    const description = conditions.find(
      (condition) => condition?.type === 'TEXT' && condition?.field === 'TEXT_SEARCH'
    );

    const accountId = conditions.find(
      (condition) => condition?.type === 'NUMERIC' && condition?.field === 'BANK_ACCOUNT_ID'
    )?.value;

    const cardId = conditions.find(
      (condition) => condition?.type === 'NUMERIC' && condition?.field === 'VIRTUAL_CARD_ID'
    )?.value;

    const amount = conditions.find(
      (condition) => condition?.type === 'NUMERIC' && condition?.field === 'AMOUNT'
    );

    if (description) {
      setFieldValue('isDescriptionOpen', true);
      setFieldValue(
        'textOperator',
        textOperators.find((item) => item.operator === description?.operator)
      );

      setFieldValue(
        'matchType',
        matchTypeOperators.find((item) => item.operator === description?.matchType)
      );
      setFieldValue(
        'textValues',
        description?.textValues.map((value) => ({ id: uuidv4(), text: value }))
      );
    }

    if (accountId) {
      setFieldValue('isAccountCardOpen', true);
      setFieldValue('accountId', accountId);
      setFieldValue('accountCard', 'ACCOUNT');
    }

    if (cardId) {
      setFieldValue('isAccountCardOpen', true);
      setFieldValue('cardId', cardId);
      setFieldValue('accountCard', 'VIRTUAL_CARD');
    }

    if (amount) {
      setFieldValue('isAmountOpen', true);
      setFieldValue(
        'moneyType',
        amount.value < 0 || amount.secondaryValue < 0 ? 'money-out' : 'money-in'
      );
      setFieldValue(
        'amountOperator',
        amountOperators.find((item) => item.operator === (amount?.operator || 'EQUALS'))
      );
      setFieldValue('amountValue', Math.abs(amount.value) || 0);
      setFieldValue('amountSecondaryValue', Math.abs(amount.secondaryValue) || 0);
    }

    if (categoryId) {
      setFieldValue('isCategoryOpen', true);
      setFieldValue('categoryId', categoryId);
    }

    if (propertyId) {
      setFieldValue('isPropertyOpen', true);
      setFieldValue('propertyId', unitId ? `${propertyId}-${unitId}` : propertyId);
    }
  };

  useEffect(() => {
    if (isEditDrawer && editData?.autotagRule) {
      loadEditData(editData);
    }
  }, [isEditDrawer, editData]);

  useEffect(() => {
    if (categoryValue) {
      setFieldValue('isDescriptionOpen', true);
      setFieldValue('textValues', [{ id: uuidv4(), text: categoryValue }]);
      setFieldValue(
        'textOperator',
        textOperators.find((item) => item.operator === 'EXACT')
      );
    }
    if (transactionsCategoryIdToPrefill) {
      setFieldValue('isCategoryOpen', true);
      setFieldValue('categoryId', transactionsCategoryIdToPrefill);
    }

    if (transactionsPropertyIdToPrefill) {
      setFieldValue('isPropertyOpen', true);
      setFieldValue(
        'propertyId',
        transactionsUnitIdToPrefill
          ? `${transactionsPropertyIdToPrefill}-${transactionsUnitIdToPrefill}`
          : transactionsPropertyIdToPrefill
      );
    }
  }, [state]);

  useEffect(() => {
    if (banksData) {
      const connectedBaselaneAccounts = banksData?.bank?.filter((ba) => {
        const { isConnected, isExternal, unitAccount } = ba;
        return isConnected && !isExternal && unitAccount?.unitApplicationStatus === 'COMPLETED';
      });
      if (connectedBaselaneAccounts.length > 0) {
        setHasBaselaneAccounts(true);
      } else {
        setHasBaselaneAccounts(false);
      }
    }
  }, [banksData]);

  let title = isEditDrawer ? 'Edit rule' : 'New rule';
  if (editData?.autotagRule?.isDeleted) {
    title = 'Deleted rule';
  }

  const emptyStateTitle = "Rule doesn't exist";
  const emptyStateDescription = 'This rule has either been deleted or you followed a broken link.';

  return (
    <>
      <BaselaneDrawer
        title={title}
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        isOpen
        onClose={() => navigate('..')}
        newDesignDrawer
        footer={editData?.autotagRule?.isDeleted ? null : <Footer loading={loading} />}
      >
        {editData?.autotagRule?.isDeleted ? (
          <Stack spacing={4} align="center" py={8}>
            <Box color="black">
              <IllustrationOtherError1Grey />
            </Box>
            <Stack spacing={1} align="center">
              <Text textStyle="lg" color="brand.neutral.900">
                {emptyStateTitle}
              </Text>
              <Text textStyle="sm" color="black" textAlign="center">
                {emptyStateDescription}
              </Text>
            </Stack>
          </Stack>
        ) : (
          <Skeleton isLoaded={!banksLoading}>
            {!hasBaselaneAccounts && <AutoTaggingGatingBanner isInDrawer mb={2.5} />}
            <Form>
              <Heading size="headline-md" mb={1}>
                If transaction matches...
              </Heading>
              <Skeleton isLoaded={!isEditDataLoading}>
                <Description isDisabled={!hasBaselaneAccounts} />
              </Skeleton>
              <Skeleton isLoaded={!isEditDataLoading}>
                <AccountCard />
              </Skeleton>
              <Skeleton isLoaded={!isEditDataLoading}>
                <Amount isDisabled={!hasBaselaneAccounts} />
              </Skeleton>
              <Text textStyle="sm" mt={2}>
                <b>*All</b> of the selected conditions will be applied.
              </Text>
              <BaselaneDivider />
              <Heading size="headline-md" mb={1}>
                Then update...
              </Heading>
              <Skeleton isLoaded={!isEditDataLoading}>
                <Category isDisabled={!hasBaselaneAccounts} />
              </Skeleton>
              <Skeleton isLoaded={!isEditDataLoading}>
                <Property />
              </Skeleton>
              {isEditDrawer && (
                <BaselaneButton
                  mt={4}
                  size="md"
                  leftIcon={<Icon16Delete />}
                  variant="tonal"
                  palette="danger"
                  isFullWidth
                  isLoading={isDeleting}
                  onClick={() => {
                    deleteAutoTagRule({ variables: { id: ruleId } }).then((data) => {
                      if (
                        values?.accountCard === 'ACCOUNT' &&
                        !!values?.accountId &&
                        !!values?.propertyId
                      ) {
                        // The rule likely exists as an auto tag setting
                        // within the accounts data, so let's sync up the account as well
                        // otherwise UI will show the old auto tag setting
                        updateBankAccount({
                          variables: {
                            input: {
                              id: Number(values?.accountId),
                              autoTag: {
                                enabled: false,
                              },
                            },
                          },
                        }).finally(() => {
                          refetchBankSummary();
                        });
                      } else if (
                        values?.accountCard === 'VIRTUAL_CARD' &&
                        !!values?.cardId &&
                        (!!values?.propertyId || !!values?.categoryId)
                      ) {
                        // The rule likely exists as an auto tag setting
                        // within the accounts data, so let's sync up the account as well
                        updateCard({
                          variables: {
                            id: Number(values?.cardId),
                            tags: {
                              propertyId: null,
                              unitId: null,
                              tagId: null,
                            },
                          },
                        });
                      }

                      if (data.data.deleteAutotagRule) {
                        navigate('..', { replace: true, state: { autoTagRuleDeleted: true } });
                      } else {
                        navigate('..', { replace: true, state: { errorOccurred: true } });
                      }
                    });
                  }}
                >
                  Delete rule
                </BaselaneButton>
              )}
            </Form>
          </Skeleton>
        )}
      </BaselaneDrawer>
      <PreviewTransactionsModal />
    </>
  );
};

export default Drawer;
