import React from 'react';
import IconProperties from '@icons/manual/IconProperties';
import IconBank from '@icons/manual/IconBank';
import IconKey from '@icons/manual/IconKey';
import * as ROUTES from '@routes';

export const TrackersConfig = {
  properties: {
    title: 'Add property',
    icon: <IconProperties h={20} w={20} />,
    description:
      'Add a rental property to collect rent, simplify bookkeeping, assess property performance, and more.',
    successMobileDescription: 'PROPERTY ADDED',
    successMessage: 'Great! You’ve added your first property in Baselane.',
    estimatedTime: 2,
    buttonRoute: ROUTES.PROPERTIES,
    segmentTrackerId: 'onboarding_checklist_add_property',
    position: 0,
  },
  banks: {
    title: 'Add Bank Account',
    icon: <IconBank h={20} w={20} />,
    description:
      'Open a Baselane Banking account or connect an external bank account to collect rent and manage bookkeeping.',
    successMobileDescription: 'BANK ACCOUNT ADDED',
    successMessage:
      'Nice job! You’ve successfully added a bank account, start managing your property finances.',
    estimatedTime: 2,
    buttonRoute: ROUTES.NATIVE_BANK,
    segmentTrackerId: 'onboarding_checklist_add_bank',
    position: 1,
  },
  leases: {
    title: 'Collect Rent',
    icon: <IconKey />,
    descriptionTitle: 'Almost done!',
    description: 'Set up your first lease to receive rent directly into your bank account.',
    disabledDescription: 'You need to complete all previous steps to start collecting rent.',
    estimatedTime: 5,
    buttonRoute: ROUTES.LEASES,
    segmentTrackerId: 'onboarding_checklist_add_lease',
    position: 2,
    isFinalStep: true,
  },
};
