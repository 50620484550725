import React, { useRef, useContext } from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  Text,
  Stack,
  Box,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useApolloClient } from '@apollo/client';
import { useUserAccessibleWorkspace } from '@shared/hooks';
import { DASHBOARD } from '@routes';
import UserContext from '@contexts/UserContext';
import { RESTORE_DEFAULT_WORKSPACE } from '@core/apollo/queries';
import { BaselaneButton } from '@shared/components';
import IconWarningTriangleYellow from '@icons/manual/IconWarningTriangleYellow';

const AccessDeniedAlert = () => {
  const alertCancelRef = useRef(null);
  const { refetch: refetchAccessibleWorkspaceData } = useUserAccessibleWorkspace();

  const { refetchUser } = useContext(UserContext);

  const navigate = useNavigate();
  const [restoreDefaultWorkspace] = useMutation(RESTORE_DEFAULT_WORKSPACE);
  const client = useApolloClient();

  const handleRestoreDefaultWorkspace = () => {
    restoreDefaultWorkspace().then(async () => {
      try {
        await client.resetStore();
      } catch (error) {
        console.error('Error clearing Apollo Client store:', error);
      }
      refetchUser();
      refetchAccessibleWorkspaceData();
      navigate(DASHBOARD);
    });
  };
  return (
    <AlertDialog
      leastDestructiveRef={alertCancelRef}
      isOpen
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="sm"
    >
      <AlertDialogOverlay />
      <AlertDialogContent p={4} minHeight="168px" maxHeight="calc(100% - 100px)" overflow="auto">
        <Stack spacing={2} mb="3">
          <Stack spacing={0.5}>
            <Box mb={2}>
              <IconWarningTriangleYellow />
            </Box>
            <AlertDialogHeader p={0}>
              <Text textStyle="headline-md">No access to this action</Text>
            </AlertDialogHeader>
            <AlertDialogBody p={0}>
              <Text textStyle="sm">
                Please reach out to the owner of this workspace to perform this action.
              </Text>
            </AlertDialogBody>
          </Stack>
          ;
        </Stack>
        <Stack direction="row" w="100%" spacing={1.5}>
          <BaselaneButton
            size="md"
            variant="filled"
            palette="primary"
            onClick={handleRestoreDefaultWorkspace}
            isFullWidth
          >
            Ok
          </BaselaneButton>
        </Stack>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AccessDeniedAlert;
