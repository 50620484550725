// @flow
import React from 'react';
import { Stack } from '@chakra-ui/react';
import IconSkeletonBar1 from '@icons/manual/IconSkeletonBar1';

function SkeletonOverview(): any {
  return (
    <Stack mt="14px !important">
      <IconSkeletonBar1 width="80" />
      <Stack mt="8px !important">
        <IconSkeletonBar1 width="48" />
      </Stack>
    </Stack>
  );
}

export default SkeletonOverview;
