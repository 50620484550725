import React from 'react';
import { BaselaneDrawer } from '@shared/components';
import SuccessDrawerBody from '@pages/PropertiesPage/SuccessDrawer/SuccessDrawerBody';
import useBreakPoints from '@core/hooks/useBreakPoints';

type SuccessDrawerProps = {
  successDrawerRef: any,
  property: Object,
  handleClose?: Function,
};

const SuccessDrawer = ({
  successDrawerRef,
  property,
  handleClose = () => {},
}: SuccessDrawerProps) => {
  const handleDrawerClose = () => {
    handleClose();
    successDrawerRef.current.close();
  };
  const { isMax767 } = useBreakPoints();

  return (
    <BaselaneDrawer
      ref={successDrawerRef}
      title="Add Property"
      size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
      closeEvent={handleDrawerClose}
      newDesignDrawer
    >
      <SuccessDrawerBody {...{ property }} />
    </BaselaneDrawer>
  );
};

export default SuccessDrawer;
