const disabled = {
  _disabled: {
    bg: 'brand.neutral.200',
    color: 'brand.neutral.400',

    _hover: {
      bg: 'brand.neutral.200',
      color: 'brand.neutral.400',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'brand.neutral.200',
    },
    _active: {
      bg: 'brand.neutral.200',
    },
  },
};
const disabledTransparent = {
  _disabled: {
    bg: 'transparent',
    color: 'brand.neutral.400',
    _hover: {
      bg: 'transparent',
      color: 'brand.neutral.400',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'transparent',
    },
    _active: {
      bg: 'transparent',
    },
  },
};
const disabledReverse = {
  _disabled: {
    bg: 'transparent',
    color: 'rgba(255, 255, 255, 0.16)',
    _hover: {
      bg: 'transparent',
      color: 'rgba(255, 255, 255, 0.16)',
    },
    _focus: {
      outlineColor: 'transparent',
      bg: 'transparent',
      color: 'rgba(255, 255, 255, 0.16)',
    },
    _focusVisible: {
      outlineColor: 'transparent',
      bg: 'transparent',
      color: 'rgba(255, 255, 255, 0.16)',
    },
    _active: {
      outlineColor: 'transparent',
      bg: 'transparent',
      color: 'rgba(255, 255, 255, 0.16)',
    },
  },
};
export const colorPreset = {
  primary: {
    bg: 'brand.darkBlue.800A',
    color: 'white',
    _hover: {
      bg: 'brand.darkBlue.700',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'brand.blue.800A',
    },
    _active: {
      bg: 'brand.darkBlue.900',
    },
    ...disabled,
  },
  primaryTonal: {
    bg: 'brand.blue.50',
    color: 'brand.blue.800A',
    _hover: {
      bg: 'brand.blue.100',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'brand.blue.800A',
    },
    _active: {
      bg: 'brand.blue.200',
    },
    ...disabled,
  },
  danger: {
    bg: 'red.800AA',
    color: 'white',
    _hover: {
      bg: 'red.700',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'red.800AA',
    },
    _active: {
      bg: 'red.900',
    },
    ...disabled,
  },
  dangerTonal: {
    bg: 'red.50',
    color: 'red.800AA',
    _hover: {
      bg: 'red.100',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'red.800AA',
    },
    _active: {
      bg: 'red.200',
    },
    ...disabled,
  },
  primaryTransparent: {
    bg: 'transparent',
    color: 'brand.blue.800A',
    _hover: {
      bg: 'brand.blue.50',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'brand.blue.800A',
    },
    _active: {
      bg: 'brand.blue.100',
    },
    ...disabledTransparent,
  },
  neutralTransparent: {
    bg: 'transparent',
    color: 'brand.darkBlue.800A',
    _hover: {
      bg: 'brand.darkBlue.50',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'brand.blue.800A',
    },
    _active: {
      bg: 'brand.darkBlue.100',
    },
    ...disabledTransparent,
  },
  dangerTransparent: {
    bg: 'transparent',
    color: 'red.800AA',
    _hover: {
      bg: 'red.50',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'red.800AA',
    },
    _active: {
      bg: 'red.100',
    },
    ...disabledTransparent,
  },
  successTransparent: {
    bg: 'transparent',
    color: 'green.800AA',
    _hover: {
      bg: 'green.50',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'green.800AA',
    },
    _active: {
      bg: 'green.100',
    },
    ...disabledTransparent,
  },
  warningTransparent: {
    bg: 'transparent',
    color: 'yellow.800AA',
    _hover: {
      bg: 'yellow.50',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'yellow.800AA',
    },
    _active: {
      bg: 'yellow.100',
    },
    ...disabledTransparent,
  },
  neutralOutline: {
    color: 'brand.darkBlue.800A',
    bg: 'white',
    borderColor: 'brand.darkBlue.200',
    _hover: {
      borderColor: 'brand.darkBlue.250',
      bg: 'brand.darkBlue.50',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'brand.blue.800A',
    },
    _active: {
      borderColor: 'brand.darkBlue.250',
      bg: 'brand.darkBlue.100',
    },
    ...disabledTransparent,
  },
  dangerOutline: {
    color: 'red.800AA',
    bg: 'white',
    borderColor: 'red.200',
    _hover: {
      borderColor: 'red.250',
      bg: 'red.50',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'red.800AA',
    },
    _active: {
      borderColor: 'red.250',
      bg: 'red.100',
    },
    ...disabledTransparent,
  },
  primaryLink: {
    bg: 'transparent',
    color: 'brand.blue.800A',
    _hover: {
      bg: 'transparent',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'brand.blue.800A',
    },
    _active: {
      bg: 'transparent',
    },
    ...disabledTransparent,
  },
  neutralLink: {
    bg: 'transparent',
    color: 'brand.darkBlue.800A',
    _hover: {
      bg: 'transparent',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'brand.blue.800A',
    },
    _active: {
      bg: 'transparent',
    },
    ...disabledTransparent,
  },
  dangerLink: {
    bg: 'transparent',
    color: 'red.800AA',
    _hover: {
      bg: 'transparent',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'red.800AA',
    },
    _active: {
      bg: 'transparent',
    },
    ...disabledTransparent,
  },
  successLink: {
    bg: 'transparent',
    color: 'green.800AA',
    _hover: {
      bg: 'transparent',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'green.800AA',
    },
    _active: {
      bg: 'transparent',
    },
    ...disabledTransparent,
  },
  warningLink: {
    bg: 'transparent',
    color: 'yellow.800AA',
    _hover: {
      bg: 'transparent',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'yellow.800AA',
    },
    _active: {
      bg: 'transparent',
    },
    ...disabledTransparent,
  },
  reverseLink: {
    bg: 'transparent',
    color: 'white',
    _hover: {
      bg: 'transparent',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'white',
    },
    _active: {
      bg: 'transparent',
    },
    ...disabledTransparent,
  },
  neutralToggle: {
    bg: 'transparent',
    color: 'brand.darkBlue.800A',
    _hover: {
      bg: 'brand.darkBlue.50',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'brand.blue.800A',
    },
    _active: {
      bg: 'brand.darkBlue.100',
    },
    ...disabledTransparent,
  },
  primaryToggle: {
    bg: 'transparent',
    color: 'brand.darkBlue.800A',
    _hover: {
      bg: 'brand.darkBlue.50',
    },
    _focus: {
      outlineColor: 'transparent',
    },
    _focusVisible: {
      outlineColor: 'brand.blue.800A',
    },
    _active: {
      bg: 'brand.darkBlue.800A',
      color: 'white',
    },
    ...disabledTransparent,
  },
  reverse: {
    bg: 'transparent',
    color: 'rgba(255, 255, 255, 0.75)',
    _hover: {
      color: 'rgba(255, 255, 255, 1)',
      bg: 'rgba(255, 255, 255, 0.08)',
    },
    _focus: {
      outlineColor: 'transparent',
      color: 'rgba(255, 255, 255, 1)',
      bg: 'rgba(255, 255, 255, 0.16)',
    },
    _focusVisible: {
      outlineColor: 'transparent',
      color: 'rgba(255, 255, 255, 1)',
      bg: 'rgba(255, 255, 255, 0.16)',
    },
    _active: {
      outlineColor: 'transparent',
      color: 'rgba(255, 255, 255, 1)',
      bg: 'rgba(255, 255, 255, 0.16)',
    },
    ...disabledReverse,
  },
};
