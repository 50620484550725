import React, { useState, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { BaselaneButton } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { IconArrowRight, IconArrowLeft } from '@icons';
import { footerStyles } from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';
import {
  useTabIndex,
  useSetTabIndex,
  useSetIsLeaseTermPanelVisible,
  useIsNewTenant,
  useIsLeaseTermStepValid,
  useIsPropertyStepValid,
  useSetIsReviewLeasePanelVisible,
} from '../../leaseAgreementStore';

function TenantFormFooter(): any {
  const { isMax768: isMobile } = useBreakPoints();
  const tabIndex = useTabIndex();
  const setTabIndex = useSetTabIndex();
  const isNewTenant = useIsNewTenant();
  const isLeaseTermStepValid = useIsLeaseTermStepValid();
  const isPropertyStepValid = useIsPropertyStepValid();
  const setIsLeaseTermPanelVisible = useSetIsLeaseTermPanelVisible();
  const setIsReviewLeasePanelVisible = useSetIsReviewLeasePanelVisible();
  const { values, submitForm } = useFormikContext();
  const { tenantProfileId } = values || {};

  const handleBackNavigation = () => {
    setIsLeaseTermPanelVisible(true);
    setTabIndex(1);
  };
  const isFormFilled = () => {
    const { firstName, lastName, email } = values?.tenantProfileMetadata || {};

    let hasRequiredFields = false;
    if (isNewTenant) {
      hasRequiredFields = Boolean(firstName && lastName && email);
    } else {
      hasRequiredFields = Boolean(tenantProfileId);
    }
    return hasRequiredFields;
  };

  const [hasRequiredFields, setHasRequiredFields] = useState(isFormFilled());

  const handleForwardNavigation = () => {
    if (isPropertyStepValid && isLeaseTermStepValid && isFormFilled()) {
      setIsReviewLeasePanelVisible(true);
      setTabIndex(3);
      submitForm();
    }
  };
  useEffect(() => {
    setHasRequiredFields(isFormFilled());
  }, [values, isNewTenant]);

  return (
    <Flex direction={isMobile ? 'column-reverse' : 'row'} justifyContent="space-between">
      <Flex direction="row" {...footerStyles(isMobile)} marginTop="0px">
        <Box marginRight={1.5} marginTop={tabIndex === 0 ? '0' : 3}>
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size="md"
            leftIcon={<IconArrowLeft />}
            onClick={() => handleBackNavigation()}
          >
            Previous
          </BaselaneButton>
        </Box>
        <Box marginTop={tabIndex === 0 ? '0' : '24px'} width="100%">
          <BaselaneButton
            variant="filled"
            palette="primary"
            size="md"
            onClick={() => handleForwardNavigation()}
            rightIcon={<IconArrowRight />}
            isDisabled={!hasRequiredFields}
          >
            Continue
          </BaselaneButton>
        </Box>
      </Flex>
    </Flex>
  );
}

export default TenantFormFooter;
