import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Formik } from 'formik';
import { ChakraProvider, Heading, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { BaselaneDrawer, BaselaneAlertNew, UnsavedChangesAlert } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import onDrawerClose from '@core/utils/onDrawerClose';
import { TENANT_SCREENING } from '@routes';
import { Icon16Info } from '@icons/16px';
import themeHabitat from '@core/themeHabitat';
import {
  INVITE_TENANT_SCREENING_APPLICANT,
  GET_TENANT_SCREENINGS,
  GET_TENANT_SCREENING_BY_ID,
} from '@core/apollo/queries/tenantScreening';
import AddApplicantEmails from './AddApplicantEmails';
import CopyApplicationLink from './CopyApplicationLink';
import Footer from './Footer';

type TenantScreeningInviteDrawerProps = {
  from?: string,
};

const TenantScreeningInviteDrawer = ({ from = null }: TenantScreeningInviteDrawerProps) => {
  const { isMax576 } = useBreakPoints();
  const formikRef = useRef();
  const params = useParams();
  const { screeningId } = params;
  const navigate = useNavigate();
  const location = useLocation();
  const [getTenantScreeningsById, { data: screeningData }] = useLazyQuery(
    GET_TENANT_SCREENING_BY_ID
  );
  const [copyLink, setCopyLink] = useState();
  const [headline, setHeadline] = useState();
  const dataFromUrl = location.state;

  useEffect(() => {
    if (!dataFromUrl || dataFromUrl.createTenantScreeningSuccessful) {
      getTenantScreeningsById({
        variables: {
          id: Number(screeningId),
        },
      });

      if (screeningData) {
        const {
          propertyName,
          unitName,
          applicationLinkTinyUrl,
        } = screeningData.getTenantScreeningById;
        setHeadline(`${propertyName}${unitName ? ' - ' : ''} ${unitName}`);
        setCopyLink(applicationLinkTinyUrl);
      }
    } else if (dataFromUrl) {
      const { propertyName, unitName, url } = dataFromUrl;
      setHeadline(`${propertyName}${unitName ? ' - ' : ''} ${unitName}`);
      setCopyLink(url);
    }
  }, [screeningData, dataFromUrl]);

  // Has server error
  const [serverError, setServerError] = useState(false);

  // Toast
  const toast = useToast();

  const successToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Invitation sent',
      status: 'success',
      duration: 2000,
      isClosable: true,
      onCloseComplete: () => {
        setServerError(false);
        onDrawerClose(navigate, `/${TENANT_SCREENING}/${screeningId}`);
      },
    });
  // Mutation
  const [inviteTenantScreeningApplicant] = useMutation(INVITE_TENANT_SCREENING_APPLICANT, {
    refetchQueries: [
      {
        query: GET_TENANT_SCREENINGS,
        variables: { input: {} },
      },
      {
        fetchPolicy: 'network-only',
        query: GET_TENANT_SCREENING_BY_ID,
        variables: { id: Number(screeningId) },
      },
    ],
  });

  const {
    isOpen: isUnsavedChangesAlertOpen,
    onClose: onCloseUnsavedChangesAlert,
    onOpen: onOpenUnsavedChangesAlert,
  } = useDisclosure();

  const handleOnDrawerClose = () => {
    if (formikRef?.current?.dirty) {
      onOpenUnsavedChangesAlert();
    } else {
      navigate('..');
    }
  };

  const handleOnAlertCloseWithoutSaving = () => {
    onCloseUnsavedChangesAlert();
    navigate('..');
  };

  const handleSendInvitations = () => {
    setServerError(false);
    inviteTenantScreeningApplicant({
      variables: {
        input: {
          tenantScreeningId: Number(screeningId),
          emailIds: formikRef?.current?.values?.emails,
        },
      },
    })
      .then(async (data) => {
        const hasError = data?.errors?.length > 0;
        if (hasError) {
          setServerError(true);
        } else {
          successToast();
        }
        formikRef?.current?.resetForm();
      })
      .catch((err) => {
        setServerError(true);
        console.log('err', err);
        formikRef?.current?.resetForm();
      });
  };

  const handleValidation = (values) => {
    const errors = {};

    if (values.emails.length === 0) {
      errors.emails = 'No emails added';
    }

    const emailExists = values.emails.includes(values.enteredEmail);
    // utilize status props in formik so it doesn't clash with onsubmit error check
    if (values.enteredEmail === '') {
      formikRef?.current?.setStatus('noemail');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.enteredEmail)) {
      formikRef?.current?.setStatus('invalid');
    } else if (emailExists) {
      formikRef?.current?.setStatus('duplicate');
    } else {
      formikRef?.current?.setStatus(null);
    }
    return errors;
  };

  return (
    <>
      <UnsavedChangesAlert
        isDrawerAlertOpen={isUnsavedChangesAlertOpen}
        onAlertContinue={handleOnAlertCloseWithoutSaving}
        onAlertClose={onCloseUnsavedChangesAlert}
      />
      <Formik
        innerRef={formikRef}
        initialValues={{ enteredEmail: '', emails: [] }}
        validate={handleValidation}
        onSubmit={handleSendInvitations}
        validateOnChange
        validateOnMount
      >
        <BaselaneDrawer
          isOpen
          newDesignDrawer
          size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
          title="Invite applicants"
          closeEvent={handleOnDrawerClose}
          footer={<Footer handleOnDrawerClose={handleOnDrawerClose} />}
        >
          <ChakraProvider theme={themeHabitat}>
            <Stack spacing={4}>
              {serverError && (
                <BaselaneAlertNew
                  variant="danger"
                  visual="icon"
                  iconName={Icon16Info}
                  title="Invitation failed"
                  body="Please try again, or contact support if the issue persists."
                />
              )}
              <Stack spacing={0.5}>
                {/* TODO to be replaced with actual data */}
                <Heading size="headline-md">{headline}</Heading>
                <Text textStyle="sm">
                  Invite applicants by email or share the application link with them.
                </Text>
              </Stack>
              <Stack spacing={3} alignItems="flex-start">
                <AddApplicantEmails />
                <CopyApplicationLink url={copyLink} />
              </Stack>
            </Stack>
          </ChakraProvider>
        </BaselaneDrawer>
      </Formik>
    </>
  );
};

export default TenantScreeningInviteDrawer;
