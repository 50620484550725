import React from 'react';
import { Text, Box } from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';
import { BaselaneLink, BaselaneAlert, AlertHeader } from '@shared/components';
import * as ROUTES from '@routes';

const GenericAlert = () => {
  const location = useLocation();
  const back = location.pathname.split(`/${ROUTES.SOMETHING_WENT_WRONG}`).join('');
  const navigate = useNavigate();

  return (
    <BaselaneAlert
      showCloseButton
      onClose={() => {
        navigate(back);
      }}
      header={<AlertHeader title="Something went wrong" />}
      isOpen
      isCentered
      body={
        <Box mt={-2}>
          <Text
            {...{
              as: 'span',
              verticalAlign: 'baseline',
            }}
          >
            There was a problem signing in. Please{' '}
          </Text>
          <BaselaneLink
            id="sign-in-error-support-link"
            variant="primary"
            size="md"
            onClick={() => {
              navigate(back, { state: { shouldShowMessenger: true } });
            }}
            styles={{
              as: 'span',
              verticalAlign: 'baseline',
              pl: '-4px',
            }}
          >
            contact support
          </BaselaneLink>
          .
        </Box>
      }
    />
  );
};

export default GenericAlert;
