import { React } from 'react';
import { Text, FormControl } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useStatsigClient } from '@statsig/react-bindings';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { Icon12Info } from '@icons/12px';
import { BaselaneFormLabel, BaselaneFormHelperText } from '@shared/components';
import { steps } from '../../../helpers/formHelpers';

import { useRecipient, useSelectedPaymentMethod, useCurrentStep } from '../../../sendFundsStore';
import RecipientDetailsFields from '../../../../Recipients/components/fields/RecipientDetailsFields';
import PaymentMethodsOptions from './PaymentMethodsOptions';
import RecipientDetailsCard from './RecipientDetailsCard';
import PaymentMethodDetails from './PaymentMethodDetails';

const SelectPaymentMethod = () => {
  const { checkGate } = useStatsigClient();
  const isBankingFeatureEnabled = checkGate(FEATURE_GATES.BANKING_FEATURE_ANNOUNCEMENT_V1);

  const formik = useFormikContext();
  const { errors, touched, values } = formik;
  const recipient = useRecipient();
  const selectedPaymentMethod = useSelectedPaymentMethod();
  const currentStep = useCurrentStep();
  const helperTexts = [
    {
      condition: isBankingFeatureEnabled && values.paymentMethodType === 'WIRE_TRANSFER',
      text: 'Starting Q1 2025, there will be a $15 fee per wire transfer.',
    },
    {
      condition: currentStep === steps.ADD_RECIPIENT,
      text: 'You can add more payment methods to the recipient later.',
    },
  ]
    .filter(({ condition }) => condition)
    .map(({ text }) => text);

  return (
    <>
      {values?.payeeId ? (
        <RecipientDetailsCard recipient={recipient} />
      ) : (
        <RecipientDetailsFields />
      )}
      <FormControl isRequired>
        <BaselaneFormLabel textStyle="sm" htmlFor="paymentMethod">
          Payment method
        </BaselaneFormLabel>

        <PaymentMethodsOptions hasError={errors.paymentMethodId && touched.paymentMethodId} />

        {helperTexts.length > 0 && (
          <BaselaneFormHelperText customIcon={<Icon12Info />}>
            {helperTexts.join(' ')}
          </BaselaneFormHelperText>
        )}
      </FormControl>
      {selectedPaymentMethod?.type && (
        <Text as="h2" textStyle="headline-xs" fontWeight="normal" mb={0.5}>
          {selectedPaymentMethod?.type} details
        </Text>
      )}

      <PaymentMethodDetails />
    </>
  );
};

export default SelectPaymentMethod;
