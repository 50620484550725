// @flow
import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { titleStyles, cStyles } from './styles/formTitle.styles';

type FormTitleProps = { title: string, containerStyles?: Object, textStyles?: Object };

function FormTitle({ title, containerStyles = {}, textStyles = {} }: FormTitleProps) {
  return (
    <Box {...{ ...cStyles(isMobile), ...containerStyles }}>
      <Text {...{ ...titleStyles(isMobile), ...textStyles }}>{title}</Text>
    </Box>
  );
}

export default FormTitle;
