import { useState } from 'react';
/**
 * This is a hook that uses the sessionStorage API to persist a data value
 * across browser reloads.
 *
 * Based on: https://usehooks.com/useLocalStorage/
 *
 * NOTE: DO NOT STORE ANY PERSONALLY IDENTIFIABLE INFORMATION, OR SENSITIVE
 * DATA. THIS IS NOT A SECURE OR TAMPER-PROOF DATA STORE, AND IT SHOULD
 * BE TREATED AS UNTRUSTED.
 *
 * @param {string} key The key to use within the sessionStorage API
 * @param {*} initialValue The initial value the item should start with
 * @returns
 */
export function useSessionStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from session storage by key
      const item = window.sessionStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to session storage
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}
