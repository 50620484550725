import React from 'react';
import { Heading, Text, VStack, Box } from '@chakra-ui/react';
import { IllustrationBankingBankStatements } from '@illustrations';

type EmptyStateProps = {
  title: String,
  description: String,
  icon: React.Node,
};

const EmptyState = ({
  title = 'No tax forms',
  description = 'Baselane only provides a 1099-INT form for each entity that receives over $10 of interest in a calendar year.',
  icon = <IllustrationBankingBankStatements />,
}: EmptyStateProps) => {
  return (
    <VStack alignItems="center" w="full" gap={0.5} py={4}>
      <Box mb={1.5}>{icon}</Box>
      <Heading size="headline-md">{title}</Heading>
      <Text textStyle="sm">{description}</Text>
    </VStack>
  );
};

export default EmptyState;
