/* eslint-disable react/prop-types */
import React from 'react';
import { Text, Tbody, Thead } from '@chakra-ui/react';
import LogRocket from 'logrocket';
import useBreakPoints from '@core/hooks/useBreakPoints';
import {
  BaselaneCardStack,
  BaselaneResponsiveCell,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
} from '@shared/components';
import { formatDate } from '@core/utils/formatDate';
import formatCurrency from '@core/utils/formatCurrency';
import ReportHeader from '../../components/ReportHeader';

const ThirdPartyCollections = ({ collections = [] }) => {
  const { isMax767 } = useBreakPoints();
  const tableColumnConfig = {
    columns: [
      {
        isSortable: false,
        styles: {
          w: isMax767 ? '150px' : '320px',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
      {
        isSortable: false,
        styles: {
          w: '100%',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
    ],
  };

  if (!collections.length) {
    return (
      <BaselaneCardStack
        id="no-third-party-collections-data-card"
        size="md"
        width="100%"
        items={[
          {
            id: 'header',
            content: <ReportHeader>Third party collections</ReportHeader>,
          },
          {
            id: 'no-third-party-collections',
            content: <Text textStyle="sm">No data available</Text>,
          },
        ]}
      />
    );
  }

  const isFieldNotObject = (field) => {
    if (typeof field !== 'object') {
      return true;
    }
    LogRocket.log('field is object or null error', field);
    return false;
  };

  return (
    <>
      {collections.map((collection, index) => (
        <BaselaneCardStack
          id={`collection-card-${index}`}
          // eslint-disable-next-line react/no-array-index-key
          key={`collection-card-${index}`}
          size="md"
          items={[
            {
              id: 'header',
              content: <ReportHeader>Third party collections</ReportHeader>,
            },
            {
              id: 'collection-card-body',
              content: (
                <BaselaneResponsiveTable
                  id="review-columns"
                  config={tableColumnConfig}
                  maxW="600px"
                  tableContainerStyles={{
                    overflowY: 'initial',
                    overflowX: 'initial',
                  }}
                >
                  <Thead>
                    <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                      <BaselaneResponsiveCellTitle
                        title={`Collection ${index + 1}`}
                        titleSize="headline-sm"
                        styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                        p={0}
                        configIndex={0}
                        colSpan="4"
                      />
                    </BaselaneResponsiveTableRow>
                  </Thead>
                  <Tbody>
                    <BaselaneResponsiveTableRows
                      items={[
                        {
                          heading: 'Industry code',
                          data: collection?.industry_code ?? '--',
                        },
                        {
                          heading: 'Customer number',
                          data: collection?.customer_number ?? '--',
                        },
                        {
                          heading: 'Account number',
                          data: collection?.account_number ?? '--',
                        },
                        {
                          heading: 'Client name',
                          data: collection?.client_name ?? '--',
                        },
                        {
                          heading: 'Status',
                          data: collection?.status?.description ?? '--',
                        },
                        {
                          heading: 'Narrative codes',
                          data: collection?.narrative_codes
                            ? collection?.narrative_codes.map((item) => item.description).join(', ')
                            : '--',
                        },
                        {
                          heading: 'Date reported',
                          data: collection?.date_reported
                            ? formatDate(collection?.date_reported, 'YYYY-MM-DD')
                            : '--',
                        },
                        {
                          heading: 'Original amount',
                          data: collection?.original_amount
                            ? formatCurrency(Number(collection?.original_amount?.cents) / 100)
                                .inDollars
                            : '--',
                        },
                        {
                          heading: 'Balance',
                          data: collection?.balance
                            ? formatCurrency(Number(collection?.balance?.cents) / 100).inDollars
                            : '--',
                        },
                        {
                          heading: 'Creditor classification',
                          data: collection?.creditor_classification?.description ?? '--',
                        },
                        {
                          heading: 'Account designator',
                          data: collection?.account_designator?.description ?? '--',
                        },
                      ]}
                      customRow
                      renderItem={(row) => {
                        return (
                          <BaselaneResponsiveTableRow
                            key={row.heading}
                            chevronCellStyles={{ w: 0 }}
                            contentContainerStyles={{ border: 'none' }}
                          >
                            <BaselaneResponsiveCell configIndex={0} p={0}>
                              <Text as="span" textStyle="sm" color="brand.neutral.700">
                                {row.heading}
                              </Text>
                            </BaselaneResponsiveCell>
                            <BaselaneResponsiveCell configIndex={1} p={0}>
                              <Text textStyle="sm">
                                {isFieldNotObject(row.data) ? row.data : '--'}
                              </Text>
                            </BaselaneResponsiveCell>
                          </BaselaneResponsiveTableRow>
                        );
                      }}
                    />
                  </Tbody>
                </BaselaneResponsiveTable>
              ),
            },
          ]}
        />
      ))}
    </>
  );
};

export default ThirdPartyCollections;
