// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { useQuery } from '@apollo/client';
import isNumber from '@core/utils/validators/isNumber';
import { GET_BANK_BALANCE_SUMMARY } from '@core/apollo/queries';
import { BaselaneTitle, BaselaneOverview, CurrencyText, EmptyText } from '@shared/components';
import isNotNil from '@core/utils/validators/isNotNil';
import IconSkeletonBar1 from '@icons/manual/IconSkeletonBar1';
import type { BankBalanceSummaryData } from './types';
import {
  currencyTextStyle,
  customBorderStyles,
  customFontStyles,
  elementStyles,
  firstItemStyles,
} from './styles/responsive.style';

function BankBalancesSummary({ hasNoData }: { hasNoData: boolean }): any {
  const currentMonth = () => {
    const today = new Date();
    return today.toLocaleString('default', { month: 'short' }).toUpperCase();
  };

  const balanceData: BankBalanceSummaryData = {
    currentMonth: currentMonth(),
    totalBalance: 0,
    income: 0,
    expense: 0,
  };

  const { data, error, loading } = useQuery(GET_BANK_BALANCE_SUMMARY);

  if (loading || error) return null;

  const { totalCashBalance, income, expense } = data.user.cashBalance;

  if (isNotNil(totalCashBalance)) balanceData.totalBalance = totalCashBalance;
  if (isNotNil(income)) balanceData.income = income;
  if (isNotNil(expense)) balanceData.expense = expense;

  const emptyAmountBox = (
    <Box>
      <Box display={!isMobile ? 'block' : 'none'}>
        <EmptyText />
      </Box>
      <Box pt="8px">
        <IconSkeletonBar1 height="20" width="72" borderRadius="12px" />
      </Box>
    </Box>
  );

  const total = isNumber(balanceData.totalBalance) ? (
    <CurrencyText amount={balanceData.totalBalance} {...currencyTextStyle(isMobile)} />
  ) : (
    emptyAmountBox
  );

  const debits = isNumber(balanceData.expense) ? (
    <CurrencyText amount={balanceData.expense} {...currencyTextStyle(isMobile)} />
  ) : (
    emptyAmountBox
  );

  const credits = isNumber(balanceData.income) ? (
    <CurrencyText amount={balanceData.income} {...currencyTextStyle(isMobile)} />
  ) : (
    emptyAmountBox
  );

  const formattedCurrentMonth =
    balanceData.currentMonth.charAt(0) + balanceData.currentMonth.slice(1).toLowerCase();

  const overview = [
    { title: 'Total Available Balance', content: total },
    {
      title: !isMobile
        ? `Credits for ${formattedCurrentMonth}`
        : `Credits (${formattedCurrentMonth})`,
      content: credits,
      style: elementStyles(isMobile),
    },
    {
      title: !isMobile
        ? `Debits for ${formattedCurrentMonth}`
        : `Debits (${formattedCurrentMonth})`,
      content: debits,
      style: elementStyles(isMobile),
    },
  ];

  return (
    <>
      <Box display={!isMobile ? 'block' : 'none'}>
        <BaselaneTitle title="Overview" />
      </Box>
      <BaselaneOverview
        customFontStyles={customFontStyles(isMobile)}
        customBorderStyles={customBorderStyles(isMobile)}
        elements={overview}
        firstItemStyles={firstItemStyles(isMobile)}
        hasNoData={hasNoData}
      />
    </>
  );
}

export default BankBalancesSummary;
