import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import HeaderNavWrapper from '@shared/layouts/HeaderNavWrapper';
import { useCurrentWorkspace } from '@shared/hooks';
import UserContext from '@contexts/UserContext';
import OTPContext from '@core/contexts/EmailOtpContext';
import { reload } from '@core/Services/Firebase/firebase';

import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import useOnboardingStore from '@store/Onboarding';
import {
  UNIFIED_LANDLORD_BANKING,
  UNIFIED_LANDLORD_BANKING_FIRST_SUCCESS,
  UNIFIED_LANDLORD_BANKING_SUCCESS,
} from '@routes';

import useIpAddress from '@core/components/Shared/hooks/useIpAddress';
import HeaderContent from '../HeaderContent';
import BankingFormTabs from './BankingFormTabs';
import {
  checkIsSectionValid,
  convertFormVariablesToSubmit,
  convertApplicationDataToFormVariables,
  getTokenizeData,
  sanitizeTokenizedData,
} from './helpers/createApplication.helpers';
import { CREATE_OR_UPDATE_LB_APPLICATION } from './queries/landlordBanking';
import { initialMenuState } from './initialMenuState';

type UnifiedLandlordBankingFlowProps = {
  applicationId: string,
  setApplicationId: Function,
  existingApplicationData: Object,
  banks: Array<Object>,
};

function UnifiedLandlordBankingFlow({
  applicationId,
  setApplicationId,
  existingApplicationData,
  banks,
}: UnifiedLandlordBankingFlowProps): any {
  const { ipAddress } = useIpAddress();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, setEmailVerified, refetchUser } = useContext(UserContext);
  const { workspaceMetadata } = useCurrentWorkspace();

  const { selection: onboardingTriageSelection } = useOnboardingStore();
  const { setIsVerifyEmailOtpLoading, handleSendOtpRegisteredUser } = useContext(OTPContext);
  const [applicationType, setApplicationType] = useState('sole');
  const [applicationMenuOptions, setApplicationMenuOptions] = useState(initialMenuState);
  const [completedTags, setCompletedTags] = useState([]);
  const [tabIndex, setTabIndex] = useState(null);
  const [unitApplicationStatus, setUnitApplicationStatus] = useState(null);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const [isRefetchUserLoading, setIsRefetchUserLoading] = useState(false);
  const [hasSubmitErrors, setHasSubmitErrors] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);
  const [submitErrorsTitle, setSubmitErrorsTitle] = useState('');

  // The initial variables that BE needs but forms here don't supply
  const formatPhone = (phoneNumber) => {
    const countryCodeLength = (phoneNumber?.length || 0) - 10;
    const countryCode =
      phoneNumber?.substring(0, 1) === '+' ? phoneNumber?.substring(1, countryCodeLength) : '1';
    const phone = {
      countryCode,
      number: phoneNumber?.substring(countryCodeLength, phoneNumber?.length),
    };

    return phone;
  };

  const initialVariables = {
    phone: formatPhone(user?.phoneNumber),
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
  };

  // the current form variables
  const [formData, setFormData] = useState(initialVariables);

  // the current BE variables
  const [setLBApplicationData, { loading: submitLBDataLoading }] = useMutation(
    CREATE_OR_UPDATE_LB_APPLICATION
  );

  function saveUnitApplication(
    variablesToSave,
    isCreateApplicationSave,
    isTabClick,
    isTriggeredFromOtpModal,
    onFinishEvent = null,
    index = null,
    newPhone = {} // phone number verified from OTP in a/b test
  ) {
    const result = { ...initialVariables, ...variablesToSave, ...newPhone };

    // state can be behind, otp check finished sets newPhone.phone number, if any
    // but if newPhone.phone has no number, remove newPhone.phone for now
    if (newPhone.phone && newPhone.phone.countryCode && !newPhone.phone.number) {
      delete result.newPhone.phone;
    }

    // always save application to current applicationId if existing or create new
    if (applicationId) {
      result.id = applicationId;
    }

    // add type of mutation, whether this is final submit
    result.action = isCreateApplicationSave ? 'FORM_COMPLETE' : 'FORM_INCOMPLETE';

    if (isCreateApplicationSave) {
      result.iovation = window?.IGLOO?.getThirdPartybb ? window?.IGLOO?.getThirdPartybb() : null;
    }

    getTokenizeData(result)
      .then((tokenizedDataRes) => {
        const tokenizedData = sanitizeTokenizedData(tokenizedDataRes, index);
        setLBApplicationData({
          variables: {
            ...result,
            ...tokenizedData,
          },
          update(cache, { data, errors }) {
            if ((errors?.length === 0 || !errors) && result.action === 'FORM_COMPLETE') {
              // for debugging purposes in live env
              console.log(banks?.length);

              if (!banks || banks?.length === 0) {
                // only trigger on first bank account
                sendSegmentEvent('any_bank_added_fe', {
                  title: document.title,
                });
                // reddit tracking
                window?.rdt('track', 'AddToCart');
              }

              sendSegmentEvent('baselane_bank_app_submitted_fe', {
                title: document.title,
              });
              window?.rdt('track', 'Lead');
            }
          },
        }).then((res) => {
          if (res.errors?.length > 0) {
            if (res.errors[0].message === 'Not Found') {
              // application id not found, return to menu
              navigate(UNIFIED_LANDLORD_BANKING);
            } else if (res.errors[0].message.includes('invalid dateOfBirth')) {
              setHasSubmitErrors(true);
              setSubmitErrors(' \n\u2022 Invalid date of birth');
              setSubmitErrorsTitle('Please fix these errors in your application');
            } else if (res.errors[0].message.includes('/data/attributes/website')) {
              setHasSubmitErrors(true);
              setSubmitErrors(' \n\u2022 Invalid website or social media page');
              setSubmitErrorsTitle('Please fix these errors in your application');
            } else {
              setHasSubmitErrors(true);
              setSubmitErrors('Please try again later or contact support if this issue persists.');
              setSubmitErrorsTitle('Application could not be submitted');
            }
          } else {
            const { id, unitApplicationStatus: status, unitApplicationId: formId } =
              res.data?.createIndividualUnitApplication || {};

            localStorage.setItem('applicationId', id);
            setApplicationId(id);
            setUnitApplicationStatus(status);

            // tab click already sets tab
            if (!isTabClick && !onFinishEvent) {
              if (tabIndex === null) {
                setTabIndex(0);
              } else if (tabIndex < 3) {
                setTabIndex(tabIndex + 1);
              }

              if (isTriggeredFromOtpModal) {
                setIsVerifyEmailOtpLoading(false);
                setShowVerifyPopup(false);
              }

              if (result.action === 'FORM_COMPLETE') {
                localStorage.removeItem('applicationId');

                // set unitApplicationId for Success page
                localStorage.setItem('unitApplicationId', formId);

                if (
                  onboardingTriageSelection === 'bookkeeping' &&
                  status === 'COMPLETED' &&
                  !user.onboardingCompleted
                ) {
                  navigate(UNIFIED_LANDLORD_BANKING_FIRST_SUCCESS);
                } else {
                  navigate(UNIFIED_LANDLORD_BANKING_SUCCESS);
                }
              }
            }

            if (onFinishEvent) onFinishEvent();
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const personalDetailsRef = useRef(null);
  const homeAddressRef = useRef(null);
  const businessInfoRef = useRef(null);
  const SocialSecurityNumberRef = useRef(null);
  const formRefs = [personalDetailsRef, homeAddressRef, businessInfoRef, SocialSecurityNumberRef];

  const validateForm = async (form) => {
    const formErrors = await form?.validateForm().then((errors) => errors);
    return formErrors || {};
  };

  const handleSolePropContinue = async ({
    isCreateApplicationSave = false,
    isTabClick = false,
    onFinishEvent = null,
    isTriggeredFromOtpModal = false,
    newPhone = {}, // phone number verified from OTP in a/b test
  } = {}) => {
    const currentForm = formRefs?.[tabIndex]?.current;
    const formVariables = currentForm?.values || {};
    const validationErrors = await validateForm(currentForm);
    const hasErrors = Object.keys(validationErrors).length > 0;

    // save only when it is for a new draft, if form is dirty or on last tab to submit form
    const shouldSave =
      tabIndex === null ||
      currentForm?.dirty ||
      isCreateApplicationSave ||
      // edge case: if user comes from popup and does not enter in anything and just clicks finish later
      (!!location?.state?.from && !currentForm?.dirty && tabIndex === 0);
    let variablesToSave = convertFormVariablesToSubmit({
      ...formData,
      ...formVariables,
    });

    /**
     * User clicked Finish Later button
     * Save any changes or run finish event
     * Prevent rest of function from firing
     */
    if (onFinishEvent) {
      if (shouldSave) {
        // only want to save if there are no errors on click of finish later button
        variablesToSave = convertFormVariablesToSubmit({
          ...formData,
          ...formVariables,
          ...Object.keys(validationErrors).reduce((accError, key) => {
            if (key !== 'agreeToTerms') {
              // eslint-disable-next-line no-param-reassign
              accError[key] = formData[key];
            }
            return accError;
          }, {}),
        });

        saveUnitApplication(
          variablesToSave,
          isCreateApplicationSave,
          isTabClick,
          isTriggeredFromOtpModal,
          onFinishEvent,
          tabIndex
        );
      } else {
        onFinishEvent();
      }

      return;
    }

    // If there are any validation errors, display errors then prevent rest of function from firing
    if (hasErrors) {
      currentForm.setTouched(validationErrors, true);
      return;
    }

    /**
     * "Continue" button functionality
     * If form has already been filled and no validation errors, route to next step
     * If form needs to be saved, routing is handled in saveUnitApplication fn
     */
    if (!shouldSave && !isTabClick && !hasErrors) {
      // if at final step, remain there.
      const newIndex = tabIndex === 3 ? tabIndex : tabIndex + 1;
      setTabIndex(newIndex);
    }

    if (shouldSave) {
      if (isCreateApplicationSave && !isTriggeredFromOtpModal) {
        if (!user.phoneNumber) {
          setShowVerifyPopup(true);
          handleSendOtpRegisteredUser({ isTriggeredFromModal: false });
        } else {
          saveUnitApplication(
            variablesToSave,
            isCreateApplicationSave,
            isTabClick,
            isTriggeredFromOtpModal,
            onFinishEvent,
            tabIndex,
            newPhone
          );
        }
      } else {
        saveUnitApplication(
          variablesToSave,
          isCreateApplicationSave,
          isTabClick,
          isTriggeredFromOtpModal,
          onFinishEvent,
          tabIndex,
          newPhone
        );
      }
    }
  };

  // for mobile
  function onClickBack() {
    const formVariables = formRefs?.[tabIndex]?.current?.values || {};
    if (tabIndex > 0) {
      setTabIndex(tabIndex - 1);
    } else if (tabIndex === 0) {
      setTabIndex(null);
    }
    if (formRefs?.[tabIndex]?.current?.dirty) {
      saveUnitApplication(
        convertFormVariablesToSubmit({ ...formData, ...formVariables }),
        false,
        true,
        false
      );
    }
  }
  const onVerifyEmailOtpSuccess = async () => {
    const emailVerifiedByFirebase = await reload();
    if (emailVerifiedByFirebase) {
      setEmailVerified(true);
      refetchUser();
      handleSolePropContinue({ isCreateApplicationSave: true, isTriggeredFromOtpModal: true });
    }
  };
  const onVerifyPhoneOtpSuccess = (phone) => {
    const newPhone = { phone: formatPhone(phone) };
    setFormData({ ...formData, ...newPhone });
    handleSolePropContinue({
      isCreateApplicationSave: true,
      isTriggeredFromOtpModal: true,
      newPhone,
    });
  };

  const onVerifyOtpSuccess = (phone) => {
    onVerifyPhoneOtpSuccess(phone);
    onVerifyEmailOtpSuccess();
  };

  useEffect(() => {
    if (ipAddress && !formData.ip) {
      setFormData((prevState) => ({ ...prevState, ip: ipAddress }));
    }
  }, [ipAddress]);

  useEffect(() => {
    // useEffect only fired once (once existing application data is loaded)
    if (existingApplicationData) {
      // using dateOfBirth to see if data has tokenized values
      const isDOBTokenized =
        existingApplicationData?.contact?.dateOfBirth &&
        existingApplicationData?.contact?.dateOfBirth.includes('tok') &&
        existingApplicationData?.contact?.dateOfBirth.length > 10;
      if (existingApplicationData?.contact?.dateOfBirth && !isDOBTokenized) {
        const convertToFormVariables = convertApplicationDataToFormVariables({
          ...initialVariables,
          ...formData,
          ...existingApplicationData,
        });
        const convertToSubmit = convertFormVariablesToSubmit(convertToFormVariables);
        saveUnitApplication(convertToSubmit, false, false, false, null, 0);
      } else {
        setFormData({
          ...initialVariables,
          ...formData,
          ...convertApplicationDataToFormVariables(existingApplicationData),
        });
      }
      setCompletedTags(existingApplicationData?.completedTags);
    }
    // we only want to fire once, on load of existingApplicationData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingApplicationData]);

  useEffect(() => {
    checkIsSectionValid({
      newCompletedTags: formData?.completedTags,
      applicationMenuOptions,
      setCompletedTags,
      setApplicationMenuOptions,
    });
    // if we add applicationMenuOptions, there will be too many renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedTags, formData]);

  useEffect(() => {
    // useEffect effecting routing changes into tabIndex
    if (location.pathname === '/unified_landlord_banking') {
      setTabIndex(null);
    }
    if (location.pathname.includes('personal_details')) {
      setTabIndex(0);
    }
    if (location.pathname.includes('home_address')) {
      setTabIndex(1);
    }
    if (location.pathname.includes('business_information')) {
      setTabIndex(2);
    }
    if (location.pathname.includes('social_security')) {
      setTabIndex(3);
    }
  }, [location.pathname]);

  useEffect(() => {
    // useEffect effecting tabIndex changes into correct routing
    if (
      tabIndex !== null &&
      location.pathname !== applicationMenuOptions?.[tabIndex]?.route &&
      tabIndex < 4
    ) {
      navigate(applicationMenuOptions[tabIndex].route);
    }
    // we ONLY want to fire on tabIndex change, so no extra dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex]);

  useEffect(() => {
    // Makes sure loader shows up continuously from refetch user to submit LB
    if (submitLBDataLoading && isRefetchUserLoading) {
      setIsRefetchUserLoading(false);
    }
  }, [submitLBDataLoading, isRefetchUserLoading]);

  return (
    <HeaderNavWrapper
      onClickBack={onClickBack}
      hideBackButton
      handleFinishEvent={handleSolePropContinue}
      headerContent={
        <HeaderContent
          onboardingTriageSelection={workspaceMetadata?.metadata?.onboardingTriageSelection}
        />
      }
      // TODO: to be removed once flow is refactored to not use device detect
      isMobile={isMobile}
    >
      <BankingFormTabs
        {...{
          formRefs,
          formData,
          tabIndex,
          setTabIndex,
          unitApplicationStatus,
          applicationMenuOptions,
          handleSolePropContinue,
          submitLBDataLoading,
          isRefetchUserLoading,
          applicationType,
          setApplicationType,
          showVerifyPopup,
          setShowVerifyPopup,
          onVerifyOtpSuccess,
          hasSubmitErrors,
          setHasSubmitErrors,
          submitErrors,
          setSubmitErrors,
          submitErrorsTitle,
          setSubmitErrorsTitle,
        }}
      />
    </HeaderNavWrapper>
  );
}

export default UnifiedLandlordBankingFlow;
