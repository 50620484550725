/* eslint-disable react/prop-types */
import React from 'react';
import { Spacer, Stack, HStack, Text } from '@chakra-ui/react';

const ScheduledPaymentsPaymentMethodDetails = ({ details, isOneTimePayment }) => {
  // this is only for one time payments
  const formatAddress = (addressObj) => {
    const { street, city, unit, state, postalCode } = addressObj ?? {};
    const propertyUnit = unit ? `${unit},` : '';
    const propertyAddress = `${street}, ${propertyUnit} ${city}, ${state} ${postalCode}`;

    return propertyAddress;
  };

  const paymentMethodItems = () => {
    let items = [];
    if (details?.type === 'TRANSFER_OUT') {
      items = [
        {
          label: 'Account holder’s name',
          value: !isOneTimePayment
            ? details?.payeePaymentMethod?.achPaymentMethods?.accountHolderName
            : details?.metadata?.name,
        },
        {
          label: 'Routing number',
          value: !isOneTimePayment
            ? details?.payeePaymentMethod?.achPaymentMethods?.routingNumber
            : details?.metadata?.routingNumber,
        },
        {
          label: 'Account number',
          value: !isOneTimePayment
            ? details?.payeePaymentMethod?.achPaymentMethods?.accountNumber
            : details?.metadata?.accountNumber,
        },
      ];
    }
    if (details?.type === 'CHECK_PAYMENT') {
      items = [
        {
          label: 'Recipient’s name',
          value: !isOneTimePayment
            ? details?.payeePaymentMethod?.checkPaymentMethods?.name
            : details?.metadata?.name,
        },
        {
          label: 'Address',
          value: formatAddress(
            !isOneTimePayment
              ? details?.payeePaymentMethod?.checkPaymentMethods?.address
              : details?.metadata?.address
          ),
        },
        {
          label: 'Memo',
          value: !isOneTimePayment ? details?.note : details?.note,
          isHidden: !isOneTimePayment ? !details?.note : !details?.note,
        },
      ];
    }
    if (details?.type === 'WIRE_TRANSFER') {
      items = [
        {
          label: 'Account holder’s name',
          value: !isOneTimePayment
            ? details?.payeePaymentMethod?.wirePaymentMethods?.accountHolderName
            : details?.metadata?.name,
        },
        {
          label: 'Routing number',
          value: !isOneTimePayment
            ? details?.payeePaymentMethod?.wirePaymentMethods?.routingNumber
            : details?.metadata?.routingNumber,
        },
        {
          label: 'Account number',
          value: !isOneTimePayment
            ? details?.payeePaymentMethod?.wirePaymentMethods?.accountNumber
            : details?.metadata?.accountNumber,
        },
        {
          label: 'Address',
          value: formatAddress(
            !isOneTimePayment
              ? details?.payeePaymentMethod?.wirePaymentMethods?.address
              : details?.metadata?.address
          ),
        },
        {
          label: 'Message to recipient',
          value: !isOneTimePayment ? details?.note : details?.note,
          isHidden: !isOneTimePayment ? !details?.note : !details?.note,
        },
      ];
    }
    return items;
  };
  const items = paymentMethodItems();

  return (
    <Stack w="100%" gap={1}>
      {items.map((item, index) => {
        const { label, value, isHidden } = item;
        if (isHidden) return null;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <HStack key={`payment-method-${index}`}>
            <Text textStyle="xs">{label}</Text>
            <Spacer />
            <Stack gap={0}>
              <Text textStyle="sm">{value}</Text>
            </Stack>
          </HStack>
        );
      })}
    </Stack>
  );
};

export default ScheduledPaymentsPaymentMethodDetails;
