import React from 'react';
import { isMobile } from 'react-device-detect';
import { Stack } from '@chakra-ui/react';
import IconCheck from '@icons/manual/IconCheck';
import BaselaneButton from '../../BaselaneButton';

type FooterProps = {
  isDirty: boolean,
  isValid: boolean,
  handleOnNextClick?: Function,
  handleCloseWithoutSaving: Function,
  sendUserKYCLoading: boolean,
  isLoadingSubmition: boolean,
};

const Footer = ({
  isDirty,
  isValid,
  handleOnNextClick = () => {},
  handleCloseWithoutSaving,
  sendUserKYCLoading,
  isLoadingSubmition,
}: FooterProps) => {
  return (
    <Stack direction="row" spacing={2}>
      {!isMobile && (
        <BaselaneButton
          variant="outline"
          palette="neutral"
          size="md"
          onClick={handleCloseWithoutSaving}
        >
          Cancel
        </BaselaneButton>
      )}
      <BaselaneButton
        id="kyc-submit-button"
        variant="filled"
        palette="primary"
        size="md"
        isLoading={sendUserKYCLoading || isLoadingSubmition}
        isDisabled={!isDirty || !isValid}
        onClick={handleOnNextClick}
        leftIcon={<IconCheck />}
      >
        Submit
      </BaselaneButton>
    </Stack>
  );
};

export default Footer;
