import React, { useEffect, useState } from 'react';
import { Heading, Skeleton, Text, useToast, VStack } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { BaselaneDrawer, BaselaneDivider, BaselaneSpinner } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { IllustrationOtherError1 } from '@illustrations';
import { GET_TENANT_SCREENINGS } from '@core/apollo/queries';
import {
  GET_TENANT_SCREENING_BY_ID,
  PROCESS_APPLICATION_DECISION,
} from '@core/apollo/queries/tenantScreening';
import {
  APPLICATION_ACTION,
  getFormattedReasons,
  reasonInitialValues,
} from '../../helpers/tenantScreeningDecision.helpers';

import Footer from './Footer';
import { REPORT_STATUS_KEYS } from '../../helpers/tenantScreeningDetail.helpers';
import MultiReasonForm from './MultiReasonForm';
import ApplicationDetails from './ApplicationDetails';

const Decline = () => {
  const { isMax576 } = useBreakPoints();
  const navigate = useNavigate();

  const [isDeclined, setIsDeclined] = useState(false);

  const params = useParams();
  const { screeningId, applicantId, applicationId } = params;

  const [processApplicationDecision, { loading: loadingDeclined }] = useMutation(
    PROCESS_APPLICATION_DECISION,
    {
      refetchQueries: [
        {
          // reload screenings to update
          query: GET_TENANT_SCREENINGS,
          variables: { input: { applicationLinkActive: null } },
        },
        {
          // reload page in background to update bannner
          fetchPolicy: 'network-only',
          query: GET_TENANT_SCREENING_BY_ID,
          variables: { id: Number(screeningId) },
        },
      ],
    }
  );

  const { data: screeningData, loading } = useQuery(GET_TENANT_SCREENING_BY_ID, {
    fetchPolicy: 'cache-first',
    variables: { id: Number(screeningId), input: {} },
  });

  const {
    getTenantScreeningById: { applications, propertyName, unitName },
  } = screeningData || { getTenantScreeningById: {} };

  const application = applications?.find((app) => app.id === applicationId);

  const { applicants } = application || {};

  const applicant = applicants?.find((applicantItem) => applicantItem?.id === applicantId);

  const toast = useToast();

  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Something went wrong. Please try again.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  // dynamically generated footer, based on step and state of UI
  const renderFooter = (handleSubmit, other, otherReason) => {
    const isDeclineDisabled =
      (!!other && (!otherReason || otherReason.length < 1)) || loadingDeclined;

    return !isDeclined ? (
      <Footer
        previousLabel="Cancel"
        onPrevious={() => navigate('..')}
        nextLabel="Decline"
        onNext={isDeclineDisabled ? null : handleSubmit}
      />
    ) : (
      <Footer previousLabel="Done" onPrevious={() => navigate('..')} />
    );
  };

  // generate display name
  useEffect(() => {
    if (application?.applicationStatus === REPORT_STATUS_KEYS.DECLINED) setIsDeclined(true);
  }, [application]);

  const handleSubmitForm = (values) => {
    // action for declining application
    const action = APPLICATION_ACTION.REJECT;
    // formatted reasons and "other" reason if needed
    const { reason, otherReason } = getFormattedReasons(values);
    // assemble the query inputs
    const input = {
      action,
      reason,
      ...(!!values.other && { otherReason }),
    };

    processApplicationDecision({
      variables: {
        applicationId: Number(applicationId),
        input,
      },
    })
      .then((res) => {
        if (res.errors) {
          showErrorToast();
        } else {
          setIsDeclined(true);
        }
      })
      .catch((err) => {
        showErrorToast();
      });
  };

  return (
    <Formik onSubmit={handleSubmitForm} initialValues={reasonInitialValues}>
      {({ values, handleSubmit }) => (
        <BaselaneDrawer
          key="decline-application-drawer"
          title="Decline application"
          size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
          isOpen
          onClose={() => navigate('..')}
          newDesignDrawer
          footer={renderFooter(handleSubmit, values?.other, values?.otherReason)}
        >
          {!isDeclined ? (
            <>
              {loadingDeclined && <BaselaneSpinner />}
              {!loadingDeclined && (
                <VStack gap={2} alignItems="flex-start">
                  <Heading as="h3" size="headline-md">
                    Review Details
                  </Heading>
                  {/* Application info */}
                  <Skeleton isLoaded={!loading} w="full">
                    <ApplicationDetails
                      applicant={applicant}
                      applicants={applicants}
                      propertyName={propertyName}
                      unitName={unitName}
                    />
                  </Skeleton>
                  <BaselaneDivider my={1} />
                  {/* Reasons for declining form */}
                  <VStack gap={0.5} alignItems="flex-start" justifyContent="flex-start">
                    <Heading as="h3" size="headline-md">
                      Select your reason(s) for declining
                    </Heading>
                    <Text textStyle="sm">
                      The applicant{applicants?.length > 1 ? 's' : ''} will get an email with the
                      selected reasons.
                    </Text>
                  </VStack>
                  <MultiReasonForm />
                </VStack>
              )}
            </>
          ) : (
            // Step 2
            <VStack w="full" h="full" justifyContent="center" gap={1.5}>
              <IllustrationOtherError1 width="64" height="64" />
              <Heading size="headline-xl" mt={1.5}>
                Application declined
              </Heading>
              <Text>
                The applicant{applicants?.length > 1 ? 's' : ''} will be notified by email.
              </Text>
            </VStack>
          )}
        </BaselaneDrawer>
      )}
    </Formik>
  );
};

export default Decline;
