import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import {
  BaselaneButton,
  BaselaneDivider,
  BaselaneDrawer,
  BaselaneMessageCard,
  CurrencyText,
} from '@core/components/Shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { useTransferOutletContext } from './activity';
import { STATUS_TYPES } from './StatusElement';
import { labelText } from './styles';
import { TransferOrigin } from './TransferOrigin';

export function TransferDetails() {
  const { transfer, clearTransfer } = useTransferOutletContext();
  const { isMax576 } = useBreakPoints();
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    clearTransfer();
    navigate(-1);
  };

  if (!transfer) return null;

  const isCheckPayment = transfer?.type === 'CHECK_PAYMENT';
  const number = transfer?.metadata?.checkNumber || '';

  return (
    <BaselaneDrawer
      isOpen
      size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
      title={transfer?.type === 'CHECK_PAYMENT' ? 'Check details ' : 'Transfer Details'}
      footer={
        <BaselaneButton size="md" variant="outline" palette="neutral" onClick={handleDrawerClose}>
          Close
        </BaselaneButton>
      }
      closeEvent={handleDrawerClose}
      onOverlayClick={handleDrawerClose}
      closeOnOverlayClick={false}
      newDesignDrawer
    >
      {transfer.status === 'FAILED' && (
        <Box mb={3}>
          <BaselaneMessageCard
            iconName="close"
            iconColor="red"
            borderColor="red"
            title="Failed Transfer"
            text="Your transfer has failed, please contact support for more details"
            iconContainerStyles={{ w: '20px' }}
            containerStyles={{ width: '100%' }}
          />
        </Box>
      )}

      {isCheckPayment && (
        <Box mb={4}>
          <Text as="strong" textStyle="md-heavy">
            Mailed check{number ? ` #${number}` : ''}
          </Text>
        </Box>
      )}
      <Box mb={4}>
        <Text {...labelText}>From</Text>
        <TransferOrigin
          account={transfer.fromTransferAccount}
          externalId={transfer.externalId}
          origin="from"
          transferType={transfer.type}
          isDetailsView
        />
      </Box>
      {transfer?.toTransferAccount && (
        <Box mb={4}>
          <Text {...labelText}>To</Text>
          <TransferOrigin
            account={transfer.toTransferAccount}
            externalId={transfer.externalId}
            origin="to"
            transferType={transfer.type}
            isDetailsView
          />
        </Box>
      )}
      {isCheckPayment && (
        <Box mb={4}>
          <Text textStyle="sm-heavy">To</Text>
          <Text textStyle="xs" mt="1">
            {transfer?.metadata?.name}
          </Text>
        </Box>
      )}
      <Box mb={4}>
        <Text {...labelText}>Amount</Text>
        <CurrencyText
          amount={transfer.amount}
          isRounded={false}
          isPercentage={false}
          textStyle="sm"
          color="brand.neutral.700"
          negativeColor="brand.neutral.700"
        />
      </Box>
      <BaselaneDivider />
      <Box mb={4}>
        <Text textStyle="xs" color="brand.neutral.600">
          Notes
        </Text>
        <Text mt="8px">{'note' in transfer && transfer.note ? transfer.note : '-'}</Text>
      </Box>
      <BaselaneDivider />
      <Box mb={4}>
        <Text textStyle="xs" color="brand.neutral.600">
          Initiated On
        </Text>
        <Text textStyle="sm" mt="8px">
          {moment(Number(transfer.createdAt)).format('MMM D, YYYY')}
        </Text>
      </Box>
      {transfer.status === 'COMPLETED' && transfer.completedDate && (
        <Box mb={4}>
          <Text textStyle="xs" color="brand.neutral.600">
            {STATUS_TYPES[transfer.status].drawerLabel}
          </Text>

          <Box mt="8px">
            <Text textStyle="sm" mt="8px">
              {moment(transfer.completedDate).format('MMM D, YYYY')}
            </Text>
          </Box>
        </Box>
      )}
    </BaselaneDrawer>
  );
}
