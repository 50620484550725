import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';

import { BaselaneButton, BaselaneButtonGroup } from '@shared/components';
import { IllustrationOtherWarning } from '@illustrations';

type CancelCSVImportPopupProps = {
  isOpen: boolean,
  onClose: Function,
  handleCancelImportClose: Function,
};

const CancelCSVImportPopup = ({
  isOpen,
  onClose,
  handleCancelImportClose,
}: CancelCSVImportPopupProps) => {
  const alertCancelRef = useRef(null);

  return (
    <AlertDialog
      leastDestructiveRef={alertCancelRef}
      isOpen={isOpen}
      isCentered
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="sm"
    >
      <AlertDialogOverlay />
      <AlertDialogContent p={4}>
        <AlertDialogCloseButton />
        <Stack spacing={3}>
          <Stack spacing={2}>
            <IllustrationOtherWarning />
            <Stack spacing={0}>
              <Heading size="headline-lg">Are you sure you want to cancel?</Heading>
              <Text textStyle="sm">
                All the progress will be lost. This action cannot be undone.
              </Text>
            </Stack>
          </Stack>

          <BaselaneButtonGroup size="sm">
            <BaselaneButton
              size="md"
              variant="outline"
              palette="neutral"
              onClick={onClose}
              flex="1"
            >
              Dismiss
            </BaselaneButton>
            <BaselaneButton
              size="md"
              variant="filled"
              palette="primary"
              onClick={handleCancelImportClose}
              flex="1"
            >
              Cancel import
            </BaselaneButton>
          </BaselaneButtonGroup>
        </Stack>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CancelCSVImportPopup;
