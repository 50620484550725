import React from 'react';
import { Box, Divider, Image, Skeleton, Text } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import useProperties from '@features/Property/useProperties';
import { IllustrationOtherTenantScreening } from '@illustrations';
import { BaselaneButton } from '@core/components/Shared/components';
import { Icon24Lightbulb, Icon24Security, Icon24Sync } from '@icons/24px';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import boom from './assets/boom.svg';
import cic from './assets/cic.svg';
import equifax from './assets/equifax.svg';
import plaid from './assets/plaid.svg';
import { TENANT_SCREENING_CREATE } from '../../../constants/routes';

// eslint-disable-next-line react/prop-types
const GreyCard = ({ icon = null, title, text, ...rest }) => {
  return (
    <Box padding="16px" borderRadius="8px" bg="brand.darkBlue.100" {...rest}>
      {icon}
      <Text textStyle="headline-md" mt="12px">
        {title}
      </Text>
      <Text textStyle="sm" fontWeight="100" mt="4px">
        {text}
      </Text>
    </Box>
  );
};

const TenantScreeningEmptyState = () => {
  const navigate = useNavigate();
  const { loading } = useProperties();
  const location = useLocation();

  const handleDownloadSampleReport = () => {
    window.open(
      'https://www.baselane.com/wp-content/uploads/2024/12/Sample-Report.pdf',
      '_blank',
      'noopener'
    );
    sendSegmentEvent('tenant_screening_sample_report_download_clicked', {});
  };

  return (
    <Box
      width="100%" // Maybe we don't need this if parent element handles the positioning.
      display="flex"
      flexDirection="column"
      padding={isMobile ? 2 : 4}
      gap={3}
      alignItems="center"
      id="empty-state"
    >
      <Box
        display="flex"
        flexDirection="column"
        paddingX={isMobile ? 0 : 3}
        paddingY={isMobile ? 0 : 5}
        gap={5}
        alignItems="center"
      >
        {/* Icon + title */}
        <Box display="flex" flexDirection="column" gap={3} alignItems="center">
          <IllustrationOtherTenantScreening />
          <Text textStyle="headline-2xl" textAlign={isMobile ? 'center' : 'left'}>
            Screen tenants with confidence
          </Text>
        </Box>
        {/* Cards */}
        <Box display="flex" flexDirection="column" gap={3} alignItems="center">
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={3}>
            <GreyCard
              icon={<Icon24Lightbulb />}
              title="Get the complete picture"
              text="Full Equifax credit report, and largest database of eviction & criminal history."
              maxWidth={isMobile ? '100%' : '285px'}
            />
            <GreyCard
              icon={<Icon24Security />}
              title="With fraud proof measures"
              text="Bank-verified income and advanced ID checks that can't be forged."
              maxWidth={isMobile ? '100%' : '285px'}
            />
            <GreyCard
              icon={<Icon24Sync />}
              title="Seamlessly integrated"
              text="Sign leases, collect security deposit & rent, and much more — all in one place."
              maxWidth={isMobile ? '100%' : '285px'}
            />
          </Box>
          <Text textStyle="sm">
            <b>Did you know?</b> The average eviction costs $3,500. Protect yourself by screening
            tenants with Baselane.
          </Text>
        </Box>
        {/* Actions */}
        <Box display="flex" flexDirection="column" gap={1} alignItems="center">
          <Skeleton isLoaded={!loading}>
            <BaselaneButton
              palette="primary"
              variant="filled"
              size="lg"
              onClick={() => {
                sendSegmentEvent('tenant_screening_fe_setup_started', {
                  entry_point: location.pathname,
                });
                navigate(TENANT_SCREENING_CREATE);
              }}
            >
              Set up Screening
            </BaselaneButton>
          </Skeleton>
          <BaselaneButton
            palette="primary"
            variant="transparent"
            size="lg"
            onClick={handleDownloadSampleReport}
          >
            Download sample report
          </BaselaneButton>
        </Box>
      </Box>
      <Divider />
      <Box display="flex" gap={3} flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
        <Text textStyle="-md">Powered by</Text>
        {/* Brands row */}
        <Box display="flex" gap={3}>
          <Image src={boom} alt="Boom" />
          <Image src={equifax} alt="Equifax" />
          <Image src={plaid} alt="Plaid" />
          <Image src={cic} alt="CIC" />
        </Box>
      </Box>
    </Box>
  );
};

export default TenantScreeningEmptyState;
