import React from 'react';
import { FormLabel, Box, Switch, FormControl, ChakraProvider } from '@chakra-ui/react';
import themeHabitat from '@core/themeHabitat';
import { descriptionStyles, switchLabelStyles } from './styles/switch.styles';

type BaselaneSwitchProps = {
  label: string,
  description: string,
  isDisabled: boolean,
  isChecked?: boolean,
  htmlFor: string,
};

function BaselaneSwitch({
  label,
  description,
  isDisabled,
  isChecked = false,
  htmlFor,
  ...rest
}: BaselaneSwitchProps) {
  return (
    <ChakraProvider theme={themeHabitat}>
      <FormControl display="flex" alignItems="center" {...rest}>
        <Switch
          {...rest}
          // There is a better way for this after upgrating to new version of chackra
          sx={
            isDisabled
              ? {
                  '.chakra-switch__track .chakra-switch__thumb': {
                    bg: 'brand.neutral.400',
                  },
                }
              : {
                  '.chakra-switch__track[data-focus]': {
                    boxShadow: `0 0 0 1px white inset, 0 0 0 1px #0968BF`,
                  },
                }
          }
          isDisabled={isDisabled}
          isChecked={isChecked}
          id={htmlFor}
          mr={1}
        />
        <FormLabel htmlFor={htmlFor}>
          <Box {...switchLabelStyles(isDisabled)}>{label}</Box>
          {description && <Box {...descriptionStyles(isDisabled)}>{description}</Box>}
        </FormLabel>
      </FormControl>
    </ChakraProvider>
  );
}

export default BaselaneSwitch;
