import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Stack, Text } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { BaselaneBadge, BaselaneButton, BaselaneCard } from '@shared/components';
import { Icon16ChevronRight } from '@icons/16px';
import IconMigrationBannerEmpty from '@icons/manual/IconMigrationBannerEmpty';
import {
  contentContainerStyles,
  contentStyles,
  contentTitleStyles,
  textSemiBoldStyle,
  relativePositionStyling,
} from '../../styles/unitBanner.styles';

type UnitDataBannerProps = {
  daysToExpire: String,
  handleUnitDataPopupClick: Function,
};

const UnitDataBanner = ({ daysToExpire, handleUnitDataPopupClick }: UnitDataBannerProps) => {
  const { isMin768: isDesktop } = useBreakPoints();
  const location = useLocation();
  const isEntitiesPage = location.pathname.includes('banking/accounts/baselane_');

  const isWarning = parseInt(daysToExpire, 10) < 10;
  const bgColor = isWarning ? 'red.900' : 'brand.blue.900';
  const badgeBgColor = isWarning ? 'red.200' : 'brand.blue.100';
  const badgeTextColor = isWarning ? 'red.800' : 'brand.blue.700';

  const daysCopy = parseInt(daysToExpire, 10) === 1 ? 'DAY' : 'DAYS';

  return (
    <Box
      {...relativePositionStyling(isDesktop && !isEntitiesPage)}
      onClick={isDesktop ? () => {} : handleUnitDataPopupClick}
    >
      <BaselaneCard>
        <Stack
          direction="row"
          {...{
            spacing: 0,
            bgColor,
            py: 2,
            px: 3,
            alignItems: 'center',
            mb: '1px',
          }}
        >
          {isDesktop && (
            <Box>
              {isWarning ? (
                <IconMigrationBannerEmpty strokeColor="white" fillColor="#F4B0AF" />
              ) : (
                <IconMigrationBannerEmpty strokeColor="white" fillColor="#8CCFFF" />
              )}
            </Box>
          )}
          <Stack
            direction={isDesktop ? 'row' : 'column'}
            {...contentContainerStyles(bgColor, !isDesktop)}
          >
            <Box {...contentStyles(!isDesktop)}>
              <Stack direction="row" width="100%">
                <Box {...contentTitleStyles(!isDesktop)}>
                  Information required for your Baselane Banking account(s)
                </Box>
                <BaselaneBadge
                  type="badge"
                  text={` ${daysToExpire || '0'} ${daysCopy} LEFT`}
                  backgroundStyles={{ bgColor: badgeBgColor }}
                  fontStyles={{ color: badgeTextColor, fontWeight: 500 }}
                />
              </Stack>
              <Box>
                To prevent your Baselane Banking account(s) from being closed, complete the
                requirements before{' '}
                <Text {...textSemiBoldStyle} as="span">
                  Dec 15, 2023.
                </Text>
              </Box>
            </Box>
            <BaselaneButton
              variant={isDesktop ? 'outline' : 'link'}
              palette="neutral"
              onClick={handleUnitDataPopupClick}
              size={isDesktop ? 'lg' : 'sm'}
              rightIcon={isDesktop ? null : <Icon16ChevronRight />}
              styles={{ flex: '0 0 auto', color: isDesktop ? 'initial' : 'white' }}
            >
              Complete requirements
            </BaselaneButton>
          </Stack>
        </Stack>
      </BaselaneCard>
    </Box>
  );
};

export default UnitDataBanner;
