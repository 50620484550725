import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Box, Image, Stack, Text } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import BaselaneButton from '@shared/components/BaselaneButton';
import BaselaneLink from '@shared/components/BaselaneLink';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';
import BaselaneMessageCard from '@shared/components/BaselaneCard/BaselaneMessageCard';
import { IconDefaultBank } from '@icons';
import {
  newDrawerBodyStyles,
  newDrawerFooterStyles,
} from '@shared/components/BaselaneDrawer/styles/drawer.style';
import LinkedAccount from './LinkedAccount';
import { linkedAccountsTextStyles, textLgMdStyles, textSmMdStyles } from './styles/text.styles';

type LinkedAccountsDrawerProps = {
  linkedAccountsDrawerRef: any,
  connectedExternalBank: Object,
  handleLinkedAccountsDrawerClose: Function,
  isLoading: boolean,
  handleResyncFn: Function,
  reconnectedBankIds: Array,
  setIsLoading: Function,
};

const LinkedAccountsDrawer = ({
  linkedAccountsDrawerRef,
  connectedExternalBank,
  handleLinkedAccountsDrawerClose,
  isLoading,
  handleResyncFn,
  reconnectedBankIds,
  setIsLoading,
}: LinkedAccountsDrawerProps) => {
  const { isMax576, isMax768 } = useBreakPoints();

  // New Design drawer
  let responsiveStyleSize = 'min769';
  if (isMax576) responsiveStyleSize = 'max576';
  if (!isMax576 && isMax768) responsiveStyleSize = 'min577max768';

  const [newlyConnectedBankAccounts, setNewlyConnectedBankAccounts] = useState([]);
  const [hasNotNewlyConnectedBankAccounts, setHasNotNewlyConnectedBankAccounts] = useState(false);

  const { DrawerBody, DrawerFooter } = BaselaneDrawer;
  const { logo = '', url = '', plaidInstitutionName: bankName = '' } = connectedExternalBank ?? {};

  useEffect(() => {
    setNewlyConnectedBankAccounts(
      connectedExternalBank?.bankAccounts?.filter((bankAccounts) => bankAccounts.isNewlyAdded)
    );
    setHasNotNewlyConnectedBankAccounts(
      connectedExternalBank?.bankAccounts?.some((bankAccounts) => !bankAccounts.isNewlyAdded)
    );
  }, [connectedExternalBank]);

  const bankAccNickname = (bankAccount) =>
    bankAccount.nickName ?? `${bankAccount.accountName}-${bankAccount.accountNumber}`;

  const onPlaidConfirmationClose = () => {
    setIsLoading(true);
  };

  return (
    <BaselaneDrawer
      ref={linkedAccountsDrawerRef}
      title="Connect External Account"
      closeEvent={handleLinkedAccountsDrawerClose}
      closeButtonEvent={handleLinkedAccountsDrawerClose}
      closeOnOverlayClick
      showBackButton
      isMobileHeader={isMobile}
      // ensures that this drawer appears overtop of
      // the alerts that spawned it- otherwise the alert
      // prevents interaction with the drawer, and closing it closes this drawer
      overlayStyles={{ zIndex: '10000 !important' }}
      size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
      newDesignDrawer
      newDrawerUseDefaultBodyFooter
    >
      <DrawerBody {...newDrawerBodyStyles[responsiveStyleSize]}>
        <Stack spacing={4}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {logo !== null ? (
              <Image src={`data:image/png;base64,${logo?.toString()}`} alt={bankName} h="40px" />
            ) : (
              <Box>
                <IconDefaultBank w={40} h={40} />
              </Box>
            )}
            <Stack spacing={0.5}>
              <Text {...textLgMdStyles}>{bankName}</Text>
              {url && (
                <BaselaneLink href={url} size="sm" variant="primary" isExternal>
                  {url}
                </BaselaneLink>
              )}
            </Stack>
          </Stack>

          {newlyConnectedBankAccounts?.length > 0 ? (
            <Stack spacing={1}>
              <Text {...textLgMdStyles}>Accounts Linked Successfully</Text>
              {newlyConnectedBankAccounts.map((bAcc) => (
                <LinkedAccount
                  key={bAcc.id}
                  {...{
                    bankAccountNickname: bankAccNickname(bAcc),
                    supportingTrx: bAcc.transactionSupported,
                    supportingTransfer: bAcc.transferSupported,
                    supportingRC: bAcc.rentCollectionSupported,
                    shouldResync: bAcc.connectionState === 'ITEM_LOGIN_REQUIRED',
                  }}
                />
              ))}
            </Stack>
          ) : (
            <BaselaneMessageCard
              title="No new accounts linked"
              iconName="info"
              iconColor="blue"
              borderColor="blue"
              text="All accounts are already linked with Baselane"
              isVertical={isMobile}
            />
          )}

          {hasNotNewlyConnectedBankAccounts && (
            <Stack spacing={1}>
              <Box>
                {!isMobile && <Text {...textSmMdStyles}>Other linked {bankName} accounts</Text>}
                <Text {...linkedAccountsTextStyles}>
                  The following {bankName} accounts are already linked to Baselane
                </Text>
              </Box>
              {connectedExternalBank?.bankAccounts?.map(
                (bAcc) =>
                  !bAcc.isNewlyAdded && (
                    <LinkedAccount
                      key={bAcc.id}
                      state={bAcc.connectionState}
                      {...{
                        bankAccId: bAcc.id,
                        handleResyncFn,
                        onPlaidConfirmationClose,
                        bankAccountNickname: bankAccNickname(bAcc),
                        supportingTrx: bAcc.transactionSupported,
                        supportingTransfer: bAcc.transferSupported,
                        supportingRC: bAcc.rentCollectionSupported,
                        shouldResync:
                          reconnectedBankIds && bAcc.connectionState === 'ITEM_LOGIN_REQUIRED'
                            ? !reconnectedBankIds.includes(bAcc.id)
                            : bAcc.connectionState === 'ITEM_LOGIN_REQUIRED',
                      }}
                    />
                  )
              )}
            </Stack>
          )}
        </Stack>
      </DrawerBody>
      <DrawerFooter {...newDrawerFooterStyles[responsiveStyleSize]}>
        <BaselaneButton
          id="connect-external-account-done-button"
          variant="filled"
          palette="primary"
          size="md"
          onClick={handleLinkedAccountsDrawerClose}
          styles={{ w: '100%' }}
          isLoading={isLoading}
        >
          Done
        </BaselaneButton>
      </DrawerFooter>
    </BaselaneDrawer>
  );
};

export default LinkedAccountsDrawer;
