import React, { useContext } from 'react';
import { Stack, Heading } from '@chakra-ui/react';
import { BaselaneAutoTagPropertyDropdown } from '@shared/components';
import TransactionContext from '@contexts/TransactionContext';
import { getPropertyData } from '@shared/helpers/propertiesFilter.helpers';
import UserAccessContext from '@contexts/UserAccessContext';

type AutoTagDropdownProps = {
  values: Object,
  handleSave: Function,
  isDisabled: boolean,
  headingIsLabel: boolean,
};

const AutoTagDropdown = ({
  values,
  handleSave,
  isDisabled,
  headingIsLabel = false,
  ...rest
}: AutoTagDropdownProps) => {
  const { propertiesData } = useContext(TransactionContext);
  const propertyOptions = getPropertyData(propertiesData);
  const hasNoPropertyOptions = propertyOptions?.length === 0;
  const { authorizedForBanking } = useContext(UserAccessContext);
  return (
    authorizedForBanking && (
      <Stack spacing={2} paddingLeft={hasNoPropertyOptions ? '2px' : '0'} {...rest}>
        <Heading size={headingIsLabel ? 'sm' : 'headline-sm'}>
          Auto-tag all transactions to a property or unit
        </Heading>
        <BaselaneAutoTagPropertyDropdown values={values} isDisabled={false} onChange={handleSave} />
      </Stack>
    )
  );
};

export default AutoTagDropdown;
