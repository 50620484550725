import React from 'react';
import { Text, HStack, Box } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import { GET_PROPERTIES_DROPDOWN } from '@core/apollo/queries';
import { T2Dropdown, DisplayInputWrapperWithRightButton } from '@shared/components';
import {
  getPropertyData,
  renderPropertyDropdownParentItem,
} from '@shared/helpers/propertiesFilter.helpers';
import { Icon16Property } from '@icons/16px';
import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { truncatedText } from '@shared/styles/text.style';
import { containerStyles } from '@shared/components/BaselaneDropdown-new/DisplayInputVariations/Placeholders/styles/placeholder.styles';

type PlaceholderProps = {
  placeholder: string,
  title: string,
  children?: React.Node,
};

const Placeholder = ({ placeholder, title, children = null, ...rest }: PlaceholderProps) => {
  return (
    <HStack className="placeholder-container" {...containerStyles} {...rest}>
      {children}
      <Text {...truncatedText}>{placeholder ?? title}</Text>
    </HStack>
  );
};

type DisplayInputShowingSelectedValueNamesProps = {
  showValueByFields: Array<string>,
  selectedOptions: Array<Object>,
};

const DisplayInputShowingSelectedValueNames = ({
  showValueByFields,
  selectedOptions,
  ...rest
}: DisplayInputShowingSelectedValueNamesProps) => {
  const getValues = () => {
    if (showValueByFields) {
      return selectedOptions.map((value) => {
        if (value) {
          return value[showValueByFields[0]] || value[showValueByFields[1]];
        }
        return '';
      });
    }

    return selectedOptions;
  };

  return (
    <Text color="brand.neutral.900" textAlign="left" {...truncatedText} {...rest}>
      {getValues().join(', ')}
    </Text>
  );
};

type DisplayInputTrxPropertyItemContentProps = {
  selectedOptions: Array<Object>,
  showValueByFields: Array<string>,
  placeholder: string,
  title: string,
  isDisabled: boolean,
};

const DisplayInputTrxPropertyItemContent = ({
  selectedOptions,
  placeholder,
  title,
  showValueByFields,
  isDisabled,
  ...rest
}: DisplayInputTrxPropertyItemContentProps) => {
  return (
    <HStack overflow="hidden">
      {selectedOptions?.length === 0 || !selectedOptions ? (
        <Placeholder
          {...{
            placeholder,
            title,
            color: isDisabled ? 'brand.neutral.400' : 'brand.blue.800A',
            _hover: { color: 'brand.neutral.500' },
            textStyle: 'sm',
            ...rest,
          }}
        >
          <Box>
            <Icon16Property />
          </Box>
        </Placeholder>
      ) : (
        <HStack overflow="hidden">
          <Box color="brand.neutral.500">
            <Icon16Property />
          </Box>
          <DisplayInputShowingSelectedValueNames
            {...{
              showValueByFields,
              selectedOptions,
              textStyle: 'sm',
            }}
          />
        </HStack>
      )}
    </HStack>
  );
};

type DisplayInputTrxPropertyItemProps = {
  selectedOptions: Array<Object>,
  showValueByFields: Array<string>,
  handleContentRendererClick: Function,
  placeholder: string,
  title: string,
  handleClearClick: Function,
  isDisabled: boolean,
  classNames: Array<string>,
};

const DisplayInputTrxPropertyItem = ({
  handleContentRendererClick,
  selectedOptions,
  placeholder,
  title,
  showValueByFields,
  handleClearClick,
  isDisabled,
  classNames,
}: DisplayInputTrxPropertyItemProps) => {
  return (
    <DisplayInputWrapperWithRightButton
      handleContentRendererClick={handleContentRendererClick}
      handleClearClick={handleClearClick}
      selectedOptions={selectedOptions}
      classNames={classNames}
    >
      <DisplayInputTrxPropertyItemContent
        isDisabled={isDisabled}
        selectedOptions={selectedOptions}
        placeholder={placeholder}
        title={title}
        showValueByFields={showValueByFields}
      />
    </DisplayInputWrapperWithRightButton>
  );
};

const PropertyDropdown = () => {
  const { data: propertiesData } = useQuery(GET_PROPERTIES_DROPDOWN);
  const { setFieldValue, values } = useFormikContext();

  const propertyOptions = getPropertyData(propertiesData?.property || []);

  const selectedItem = propertyOptions.find((option) => option.id === values.propertyId);

  return (
    <Box border="1px solid" borderColor="brand.darkBlue.200" borderRadius="4px">
      <T2Dropdown
        additionalProps={{ id: 'property-dropdown' }}
        classNames={[
          'border-on-hover',
          'clear-on-hover',
          'carret-on-hover',
          'fixed-width-dropdown',
          'auto-select-input-width',
          'auto-width-dropdown',
          'select-auto-width',
        ]}
        data={propertyOptions}
        selectedItem={selectedItem}
        title="Property"
        placeholder="Select property"
        showValueByFields={['showValueName']}
        parentItemRenderer={({ item }) => renderPropertyDropdownParentItem(item)}
        childItemRenderer={itemRenderer}
        handleSubmit={(newId) => {
          setFieldValue('propertyId', newId);
        }}
        CustomDisplayInput={DisplayInputTrxPropertyItem}
      />
    </Box>
  );
};

export default PropertyDropdown;
