import React, { useEffect, useState } from 'react';
import { Tbody, Text, Thead } from '@chakra-ui/react';
import { useOutletContext } from 'react-router-dom';
import { usePapaParse } from 'react-papaparse';
import { Formik } from 'formik';
import { useShallow } from 'zustand/react/shallow';
import { useStatsigClient } from '@statsig/react-bindings';
import useCSVImportStore from '@store/CSVImport';

import useBreakPoints from '@core/hooks/useBreakPoints';
import {
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTableRow,
  BaselaneCardNew,
  BaselaneAlertNew,
  BaselaneSpinner,
  BaselaneLink,
} from '@shared/components';

import { DYNAMIC_CONFIGS } from '@core/constants/statsigKeys';
import { getBaselaneFields, tableColumnConfig } from './review.helpers';
import TabPanelLayout from '../components/TabPanelLayout';
import FooterNavigation from '../components/FooterNavigation';
import ReviewRow from './ReviewRow';

const ReviewColumns = () => {
  const { getDynamicConfig } = useStatsigClient();
  const fieldNameMatchConfig = getDynamicConfig(DYNAMIC_CONFIGS.CSV_IMPORT_FIELD_NAMES);
  const baselaneFields = getBaselaneFields(fieldNameMatchConfig);

  const {
    CSVFile,
    CSVFileData,
    CSVHeaderMapping,
    setReviewColumnValues,
    setCategoryListFromCSV,
    setPropertyListFromCSV,
  } = useCSVImportStore(
    useShallow((state) => ({
      CSVFile: state.CSVFile,
      CSVFileData: state.CSVFileData,
      CSVHeaderMapping: state.CSVHeaderMapping,
      setReviewColumnValues: state.setReviewColumnValues,
      setCategoryListFromCSV: state.setCategoryListFromCSV,
      setPropertyListFromCSV: state.setPropertyListFromCSV,
    }))
  );

  const { readRemoteFile } = usePapaParse();
  const { handleFooterNavigation, formikRefs, handleTabValidation } = useOutletContext();
  const formikRef = formikRefs[1];

  const { isMin899 } = useBreakPoints();

  const [csvFileData, setCSVFileData] = useState(null);
  const [reviewRows, setReviewRows] = useState(null);
  const [mapping, setMapping] = useState(null);
  const [csvHeaders, setCSVHeaders] = useState([]);
  const [previewRow, setPreviewRow] = useState([]);

  const handleUniqueItems = (csvColumnIndex) => {
    // extract the column into an array
    const column = csvFileData.map((row) => row[csvColumnIndex]);
    // remove header
    column.shift();
    // find all unique values in array
    return column.reduce((accumulator, current) => {
      if (!accumulator.includes(current) && current?.length > 0) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
  };

  const getCategoriesFromCSV = (updatedMapping) => {
    // get name of category column
    const csvCategoriesColumnName = updatedMapping.Category;
    // find index of the column
    const csvCategoriesColumnIndex = csvFileData[0]?.indexOf(csvCategoriesColumnName);
    // find all unique values in array
    const uniqueCategories = handleUniqueItems(csvCategoriesColumnIndex);
    setCategoryListFromCSV(uniqueCategories);
  };

  const getPropertiesFromCSV = (updatedMapping) => {
    // get name of property column
    const csvPropertiesColumnName = updatedMapping.Property;
    // find index of the column
    const csvPropertiesColumnIndex = csvFileData[0]?.indexOf(csvPropertiesColumnName);
    // find all unique values in array
    const uniqueProperties = handleUniqueItems(csvPropertiesColumnIndex);
    setPropertyListFromCSV(uniqueProperties);
  };

  const handleUpdateInformationForCategoryAndPropertySteps = (updatedMapping) => {
    // updating mapping so tab navigation logic will be able to grab values in time
    setReviewColumnValues(updatedMapping, CSVFileData);
    getCategoriesFromCSV(updatedMapping);
    getPropertiesFromCSV(updatedMapping);
  };

  useEffect(() => {
    if (CSVFile) {
      readRemoteFile(CSVFile, {
        complete: (response) => {
          setCSVFileData([...response.data]);
        },
      });
    }
  }, [CSVFile]);

  useEffect(() => {
    if (!csvFileData) return;

    const csvHeadersInitial = csvFileData[0];
    const previewRowInitial = csvFileData[1];

    let unmatchedHeaders = Object.keys(CSVHeaderMapping).length === 0 ? [...csvHeadersInitial] : [];
    const mappingUpdate = CSVHeaderMapping;

    // initial mapping
    baselaneFields.forEach((field) => {
      [...unmatchedHeaders].forEach((csvHeader) => {
        if (field.match.includes(csvHeader.toLowerCase()) && !mappingUpdate[field.name]) {
          mappingUpdate[field.name] = csvHeader;
          unmatchedHeaders = unmatchedHeaders.filter((header) => header !== csvHeader);
        }
      });
    });

    const rows = baselaneFields.map((item) => {
      const { name, required, tooltip } = item;
      return { name, required, tooltip };
    });

    setReviewRows(rows);
    setCSVHeaders(csvHeadersInitial);
    setPreviewRow(previewRowInitial);
    setMapping(mappingUpdate);
    handleUpdateInformationForCategoryAndPropertySteps(mappingUpdate);
  }, [csvFileData, CSVHeaderMapping]);

  const handleValidation = (values) => {
    const requiredFields = baselaneFields.filter((field) => field.required);
    const errors = {};
    requiredFields.forEach((field) => {
      if (!values[field.name]) {
        errors[field.name] = `Required field, ${field.name}, was not mapped.`;
      }
    });
    // update tab list state
    if (Object.keys(errors).length > 0) {
      handleTabValidation();
    }

    return errors;
  };

  const handleTabFormSubmit = () => {
    setReviewColumnValues(mapping, csvFileData);
  };

  return !mapping ? (
    // Note: form can't be shown until initial mapping is complete
    <BaselaneSpinner />
  ) : (
    <Formik
      innerRef={formikRef}
      validateOnChange
      validateOnBlur
      validateOnMount
      initialValues={mapping}
      validate={handleValidation}
      onSubmit={handleTabFormSubmit}
    >
      {({ isValid, handleSubmit }) => (
        <TabPanelLayout
          stepNumber={2}
          title="Review columns"
          description={
            <Text>Map the CSV columns to the corresponding Baselane transaction fields.</Text>
          }
        >
          <BaselaneAlertNew
            body={
              <Text>
                Make sure Row 1 in the CSV contains the header names.{' '}
                <BaselaneLink
                  href="https://www.baselane.com/CSV-HCA"
                  target="_blank"
                  size="md"
                  isExternal
                  variant="primary"
                >
                  Learn more
                </BaselaneLink>
              </Text>
            }
            title="Not seeing your column headers?"
            variant="neutral"
          />
          <BaselaneCardNew maxW="600px" p={0}>
            <BaselaneResponsiveTable
              id="review-columns"
              config={tableColumnConfig()}
              tableContainerStyles={{
                overflowY: 'initial',
                overflowX: 'initial',
                w: isMin899 && '100%',
              }}
            >
              <Thead>
                <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                  <BaselaneResponsiveCellTitle
                    title="Baselane field"
                    titleSize="headline-xs"
                    styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                    p={0}
                    configIndex={0}
                  />
                  <BaselaneResponsiveCellTitle
                    title="CSV column"
                    titleSize="headline-xs"
                    styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                    p={0}
                    configIndex={1}
                  />
                  <BaselaneResponsiveCellTitle
                    title="First row preview"
                    titleSize="headline-xs"
                    styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                    p={0}
                    configIndex={2}
                  />
                </BaselaneResponsiveTableRow>
              </Thead>
              <Tbody>
                <BaselaneResponsiveTableRows
                  items={reviewRows}
                  customRow
                  renderItem={(row) => {
                    const { name, required, tooltip } = row;
                    return (
                      <ReviewRow
                        key={name}
                        name={name}
                        isRequired={required}
                        tooltip={tooltip}
                        mapping={mapping}
                        setMapping={setMapping}
                        csvHeaders={csvHeaders}
                        previewRow={previewRow}
                        handleUpdateInformationForCategoryAndPropertySteps={
                          handleUpdateInformationForCategoryAndPropertySteps
                        }
                      />
                    );
                  }}
                />
              </Tbody>
            </BaselaneResponsiveTable>
          </BaselaneCardNew>
          <FooterNavigation
            handleFooterNavigation={handleFooterNavigation}
            isValid={isValid}
            handleSubmit={handleSubmit}
          />
        </TabPanelLayout>
      )}
    </Formik>
  );
};

export default ReviewColumns;
