import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import IconBanksAndCards from '@icons/manual/IconBanksAndCards';
import BaselaneLink from '@shared/components/BaselaneLink';
import {
  plaidConfirmationStyles,
  circleStyles,
  iconCardStyles,
  textBoldStyles,
  textStyles,
} from '../styles/addEditBank.styles';

type SetUpThirdPartyViewProps = {
  interactionElements?: any,
  title: any,
  bodyText: string,
  icon: any,
  iconColor: string,
};

function SetUpThirdPartyView({
  title,
  bodyText,
  icon: messageIcon,
  iconColor,
  interactionElements,
}: SetUpThirdPartyViewProps) {
  const icon = messageIcon || <IconBanksAndCards />;

  return (
    <Flex {...plaidConfirmationStyles}>
      <Box alignSelf="flex-start">
        <Box {...circleStyles(iconColor)}>
          <Box as="span" {...iconCardStyles}>
            {icon}
          </Box>
        </Box>
        <Box {...textBoldStyles}>{title}</Box>
        <Box {...textStyles}>{bodyText}</Box>
        {interactionElements}
        <Text mt={3} textStyle="xs">
          By clicking continue, you agree Plaid can use this information to provide its services.
          For more information, please see our{' '}
          <BaselaneLink
            variant="primary"
            size="sm"
            href="https://www.baselane.com/privacy-policy/"
            target="_blank"
          >
            Privacy Policy
          </BaselaneLink>
          . Baselane uses Plaid to enable you to securely connect your accounts. We do not have
          access to, nor do we store any of your bank login credentials. Please make sure you enable
          the pop-up.
        </Text>
      </Box>
    </Flex>
  );
}

SetUpThirdPartyView.defaultProps = {
  interactionElements: null,
};

export default SetUpThirdPartyView;
