import React from 'react';

function IconBankStatements() {
  return (
    <svg width="23" height="29" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.2 3H5C3.89543 3 3 3.89543 3 5V26.6C3 27.7046 3.89543 28.6 5 28.6H5.50435H20.2C21.3046 28.6 22.2 27.7046 22.2 26.6V13.2874V5C22.2 3.89543 21.3046 3 20.2 3Z"
        fill="#F9BC3B"
      />
      <path
        d="M17.4004 1.3999H4.60039C3.48029 1.3999 2.92023 1.3999 2.49241 1.61789C2.11609 1.80964 1.81012 2.1156 1.61838 2.49192C1.40039 2.91974 1.40039 3.4798 1.40039 4.59991V24.4956C1.40039 24.9643 1.40039 25.1986 1.43949 25.3934C1.59817 26.1841 2.21619 26.8021 3.00686 26.9608C3.20168 26.9999 3.43604 26.9999 3.90474 26.9999H17.4004C18.5205 26.9999 19.0805 26.9999 19.5084 26.7819C19.8847 26.5902 20.1907 26.2842 20.3824 25.9079C20.6004 25.4801 20.6004 24.92 20.6004 23.7999V11.6873V4.5999C20.6004 3.4798 20.6004 2.91974 20.3824 2.49192C20.1907 2.1156 19.8847 1.80964 19.5084 1.61789C19.0805 1.3999 18.5205 1.3999 17.4004 1.3999Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.80078 17.3999H18.2008"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.80078 20.6001H18.2008"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.80078 14.1997H18.2008"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.80078 6.1998V6.1998C7.80078 5.53706 7.26352 4.9998 6.60078 4.9998H5.80078M5.80078 4.9998H5.00078C4.33804 4.9998 3.80078 5.53706 3.80078 6.1998V6.1998C3.80078 6.86255 4.33804 7.3998 5.00078 7.3998H6.60078C7.26352 7.3998 7.80078 7.93706 7.80078 8.5998V8.5998C7.80078 9.26255 7.26352 9.7998 6.60078 9.7998H5.80078M5.80078 4.9998V3.7998M5.80078 9.7998H5.00078C4.33804 9.7998 3.80078 9.26255 3.80078 8.5998V8.5998M5.80078 9.7998V10.9998"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default IconBankStatements;
