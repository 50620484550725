import React, { useMemo, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Heading, Box, Stack, Text, HStack } from '@chakra-ui/react';
import {
  BaselaneMenu,
  BaselaneCardNew,
  BaselaneButton,
  BaselaneCardStack,
  BaselaneChip,
} from '@shared/components';
import {
  Icon16Plus,
  Icon16Upload,
  Icon16Link,
  Icon16ChevronDown,
  Icon16ChevronRight,
} from '@icons/16px';
import { Icon24ChevronRight } from '@icons/24px';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import {
  CREATE_LEASE_AGREEMENT,
  NEW_LEASE_AGREEMENT,
  LEASE_AGREEMENT,
  LEASE_TO_LEASE_AGREEMENT,
} from '@core/constants/routes';
import useLeaseAgreements from '../hooks/useLeaseAgreements';
import {
  formatLeaseAgreementDuration,
  documentStateVariants,
} from '../helpers/leaseAgreement.helper';

type LeaseToLeaseAgreementProps = {
  lease: Object,
};

const LeaseToLeaseAgreement = ({ lease }: LeaseToLeaseAgreementProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const entryPoint = location?.pathname?.split('/')?.pop();

  const { tenantProfileId, propertyUnitId, tenantProfile } = lease;

  const { leaseAgreements, refetch: leaseAgreementDataRefetch } = useLeaseAgreements();

  const attachedLeasAgreements = useMemo(() => {
    return leaseAgreements.filter(
      (la) =>
        la.propertyUnitId === propertyUnitId &&
        la.leases?.some((item) => item.id === lease.id) &&
        ['SIGNING_IN_PROGRESS', 'SIGNING_COMPLETE'].includes(la.state)
    );
  }, [leaseAgreements, propertyUnitId]);

  const filteredLeaseAgreementsByPropertyUnitId = useMemo(() => {
    return leaseAgreements.filter(
      (la) =>
        la.propertyUnitId === propertyUnitId &&
        ['SIGNING_IN_PROGRESS', 'SIGNING_COMPLETE'].includes(la.state)
    );
  }, [leaseAgreements, propertyUnitId]);

  useEffect(() => {
    leaseAgreementDataRefetch();
  }, [location]);

  const actionsToLeaseAgreement = [
    {
      name: 'Create new lease agreement',
      onClick: () => {
        sendSegmentEvent('lease_agreement_fe_new_lease_clicked', { entry_point: entryPoint });
        navigate(`/${CREATE_LEASE_AGREEMENT}`);
      },

      icon: (
        <Box>
          <Icon16Plus />
        </Box>
      ),
    },
    {
      name: 'Upload lease agreement',
      onClick: () => {
        sendSegmentEvent('lease_agreement_fe_upload_started', { entry_point: entryPoint });
        navigate(`/${LEASE_AGREEMENT}/${NEW_LEASE_AGREEMENT}`);
      },
      icon: (
        <Box>
          <Icon16Upload />
        </Box>
      ),
    },
  ];

  if (filteredLeaseAgreementsByPropertyUnitId?.length > 0) {
    actionsToLeaseAgreement.push({
      name: 'Attach existing lease agreement',
      onClick: () => {
        sendSegmentEvent('lease_agreement_fe_rc_link_started', { entry_point: entryPoint });
        navigate(`${LEASE_TO_LEASE_AGREEMENT}`, {
          state: { tenantProfileId, propertyUnitId, tenantProfile, leaseId: lease.id },
        });
      },
      icon: (
        <Box>
          <Icon16Link />
        </Box>
      ),
    });
  }

  const items = [];

  // Heading card
  if (attachedLeasAgreements?.length) {
    items.push({
      id: 'first-lease-details-toggle',
      content: (
        <HStack
          onClick={() => setIsOpen(!isOpen)}
          variant="clickable"
          justifyContent="space-between"
          cursor="pointer"
          w="100%"
        >
          <Stack gap={1} direction="row" alignItems="center">
            <Icon24ChevronRight
              style={{
                transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                transition: 'transform 0.2s ease',
              }}
            />
            <Heading size="headline-sm">
              {isOpen
                ? 'Lease agreement'
                : `${filteredLeaseAgreementsByPropertyUnitId?.length} lease agreements`}
            </Heading>
          </Stack>

          <BaselaneButton
            palette="primary"
            size="sm"
            variant="transparent"
            onClick={(e) => {
              e.stopPropagation(); // prevent collapse toggle
              navigate(`${LEASE_TO_LEASE_AGREEMENT}`, {
                state: { tenantProfileId, tenantProfile, leaseId: lease.id, propertyUnitId },
              });
            }}
          >
            Attach/remove
          </BaselaneButton>
        </HStack>
      ),
    });

    // Rest of the cards - only show if open
    if (isOpen) {
      attachedLeasAgreements?.forEach((l) => {
        items.push({
          content: (
            <BaselaneCardNew
              onClick={() => {
                navigate(`${LEASE_TO_LEASE_AGREEMENT}`, {
                  state: { tenantProfileId, tenantProfile, leaseId: lease.id, propertyUnitId },
                });
              }}
              size="md"
              variant="innerClickable"
            >
              <Stack gap={0.5} alignItems="flex-start" w="100%">
                <HStack gap={0} w="100%">
                  <Stack gap={0} w="100%">
                    <Heading size="headline-md">{l.propertyUnit?.propertyName || ''}</Heading>
                    <HStack gap={0.5}>
                      <BaselaneChip
                        label={l?.state
                          ?.replace(/_/g, ' ')
                          ?.toLowerCase()
                          ?.replace(/^\w/, (c) => c.toUpperCase())}
                        size="sm"
                        variant={documentStateVariants[l?.state] || 'simple-neutral'}
                      />
                      <Text textStyle="xs">
                        {formatLeaseAgreementDuration(l.startDate || '', l.endDate || '')}
                      </Text>
                    </HStack>
                  </Stack>
                  <Icon16ChevronRight />
                </HStack>
              </Stack>
            </BaselaneCardNew>
          ),
          id: `lease-details-${lease.id}`,
        });
      });

      // CTA card
      items.push({
        id: 'last-lease-details-cta',
        content: (
          <BaselaneCardNew size="md" variant="inner">
            <BaselaneButton
              onClick={() =>
                navigate(`${LEASE_TO_LEASE_AGREEMENT}`, {
                  state: {
                    tenantProfileId,
                    tenantProfile,
                    leaseId: lease.id,
                    propertyUnitId,
                  },
                })
              }
              palette="primary"
              size="md"
              styles={{ ml: 0 }}
              variant="tonal"
              rightIcon={<Icon16ChevronDown />}
            >
              Add more
            </BaselaneButton>
          </BaselaneCardNew>
        ),
      });
    }
  }

  return (
    <Box mt={2}>
      {attachedLeasAgreements?.length > 0 ? (
        <BaselaneCardStack id="example-cardstack-medium" items={items} />
      ) : (
        <BaselaneMenu
          buttonPalette="primary"
          buttonVariant="tonal"
          size="lg"
          label="Add lease agreement"
          styles={{
            list: {
              maxWidth: '320px',
              zIndex: 11,
            },
          }}
          listItems={actionsToLeaseAgreement}
        />
      )}
    </Box>
  );
};

export default LeaseToLeaseAgreement;
