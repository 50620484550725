import React from 'react';
import { useFormikContext } from 'formik';
import { Flex, Box, Text, Switch, FormControl, Skeleton } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { BaselaneRadioGroup, BaselaneRadio } from '@shared/components';
import { GET_ACCOUNT_DROPDOWN_BANKS_WITH_CARDS } from '@core/apollo/queries';
import AccountDropdown from './AccountDropdown';
import CardDropdown from './CardDropdown';

const AccountCard = () => {
  const { values, setFieldValue } = useFormikContext();

  const { data: banksData, loading: banksDataLoading } = useQuery(
    GET_ACCOUNT_DROPDOWN_BANKS_WITH_CARDS,
    {
      variables: {
        // only open accounts are shown
        accountStatus: 'Open',
        // exclude manual accounts
        isManualAccount: false,
      },
      fetchPolicy: 'no-cache',
    }
  );

  return (
    <Box
      border="1px solid"
      borderColor="brand.darkBlue.200"
      borderRadius="4px"
      background="brand.darkBlue.50"
      p={2}
      mt={2}
    >
      <Skeleton isLoaded={!banksDataLoading}>
        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Text textStyle="sm" fontWeight="500">
              Account/card
            </Text>
            <Switch
              value="some value"
              isChecked={values.isAccountCardOpen}
              onChange={() => setFieldValue('isAccountCardOpen', !values.isAccountCardOpen)}
            />
          </Flex>
        </Box>
        {values.isAccountCardOpen && (
          <Box mt={3}>
            <FormControl id="accountCard">
              <BaselaneRadioGroup
                defaultValue="ACCOUNT"
                id="typeGroup"
                name="typeGroup"
                onChange={(type) => {
                  setFieldValue('accountCard', type);
                  if (type === 'ACCOUNT') {
                    setFieldValue('cardId', null);
                  } else {
                    setFieldValue('accountId', null);
                  }
                }}
                value={values.accountCard === 'ACCOUNT' ? 'ACCOUNT' : 'VIRTUAL_CARD'}
              >
                <Flex gap={2}>
                  <BaselaneRadio key="1" label="Account" value="ACCOUNT" name="accountCard" />
                  <BaselaneRadio key="2" label="Card" value="VIRTUAL_CARD" name="accountCard" />
                </Flex>
              </BaselaneRadioGroup>
            </FormControl>
            {values.accountCard === 'ACCOUNT' ? (
              <AccountDropdown bankProps={banksData} />
            ) : (
              <CardDropdown bankProps={banksData} />
            )}
          </Box>
        )}
      </Skeleton>
    </Box>
  );
};

export default AccountCard;
