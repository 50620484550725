/* eslint-disable react/prop-types */
import React from 'react';
import { Text, Tbody, Thead, Box } from '@chakra-ui/react';
import LogRocket from 'logrocket';
import {
  BaselaneCardStack,
  BaselaneResponsiveCell,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
} from '@shared/components';
import { formatDate } from '@core/utils/formatDate';
import ReportHeader from '../../components/ReportHeader';

const AlertsAndTriggers = ({ alertsAndTriggers }) => {
  const tableColumnConfig = {
    columns: [
      {
        isSortable: false,
        styles: {
          w: { base: '50%', sm: '320px' },
          overflow: 'visible',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
      {
        isSortable: false,
        styles: {
          w: '100%',
          overflow: 'visible',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
    ],
  };

  if (!alertsAndTriggers) {
    return (
      <BaselaneCardStack
        id="no-alerts-and-triggers-card"
        size="md"
        width="100%"
        items={[
          {
            id: 'header',
            content: <ReportHeader>d triggers</ReportHeader>,
          },
          {
            id: 'no-alerts-and-triggers-card-body',
            content: <Text textStyle="sm">No data available</Text>,
          },
        ]}
      />
    );
  }

  const isFieldNotObject = (field) => {
    if (typeof field !== 'object') {
      return true;
    }
    LogRocket.log('field is object or null error', field);
    return false;
  };

  const alertContact = alertsAndTriggers?.alert_contacts[0];

  return (
    <BaselaneCardStack
      id={`alerts-and-triggers-card-${alertsAndTriggers?.case_number}`}
      key={`alerts-and-triggers-card-${alertsAndTriggers?.case_number}`}
      size="md"
      items={[
        {
          id: 'header',
          content: <ReportHeader>Alerts and triggers</ReportHeader>,
        },
        {
          id: 'alerts-and-triggers-card-body',
          content: (
            <Box display="flex" flexDirection="column" gap={3}>
              <BaselaneResponsiveTable
                id="review-columns"
                config={tableColumnConfig}
                maxW="600px"
                tableContainerStyles={{
                  overflowY: 'initial',
                  overflowX: 'initial',
                }}
              >
                <Thead>
                  <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                    <BaselaneResponsiveCellTitle
                      title="Summary"
                      titleSize="headline-sm"
                      styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                      p={0}
                      configIndex={0}
                      colSpan="2"
                    />
                  </BaselaneResponsiveTableRow>
                </Thead>
                <Tbody>
                  <BaselaneResponsiveTableRows
                    items={[
                      {
                        heading: 'Fraud victim indicator',
                        data: alertsAndTriggers?.fraud_victim_indicator ?? '--',
                      },
                      {
                        heading: 'Address discrepancy indicator',
                        data: alertsAndTriggers?.address_discrepancy_indicator ?? '--',
                      },
                    ]}
                    customRow
                    renderItem={(row) => {
                      return (
                        <BaselaneResponsiveTableRow
                          key={row.heading}
                          chevronCellStyles={{ w: 0 }}
                          contentContainerStyles={{ border: 'none' }}
                        >
                          <BaselaneResponsiveCell configIndex={0} p={0} colSpan="2">
                            <Text as="span" textStyle="sm" color="brand.neutral.700">
                              {row.heading}
                            </Text>
                          </BaselaneResponsiveCell>
                          <BaselaneResponsiveCell configIndex={1} p={0} colSpan="2">
                            <Text textStyle="sm">
                              {isFieldNotObject(row.data) ? row.data : '--'}
                            </Text>
                          </BaselaneResponsiveCell>
                        </BaselaneResponsiveTableRow>
                      );
                    }}
                  />
                </Tbody>
              </BaselaneResponsiveTable>
              <BaselaneResponsiveTable
                id="review-columns"
                config={tableColumnConfig}
                maxW="600px"
                tableContainerStyles={{
                  overflowY: 'initial',
                  overflowX: 'initial',
                }}
              >
                <Thead>
                  <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                    <BaselaneResponsiveCellTitle
                      title="Alert contact information"
                      titleSize="headline-sm"
                      styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                      p={0}
                      configIndex={0}
                      colSpan="2"
                    />
                  </BaselaneResponsiveTableRow>
                </Thead>
                <Tbody>
                  <BaselaneResponsiveTableRows
                    items={[
                      {
                        heading: 'Alert type',
                        data: alertContact?.type?.description ?? '--',
                      },
                      {
                        heading: 'Date reported',
                        data: alertContact?.date_reported
                          ? formatDate(alertContact?.date_reported, 'YYYY-MM-DD')
                          : '--',
                      },
                      {
                        heading: 'Date effective',
                        data: alertContact?.date_effective
                          ? formatDate(alertContact?.date_effective, 'YYYY-MM-DD')
                          : '--',
                      },
                      {
                        heading: 'Status',
                        data: alertContact?.status ?? '--',
                      },
                      {
                        heading: 'Phone 1',
                        data: alertContact?.phone_numbers?.length
                          ? alertContact.phone_numbers[0]?.phone_number
                          : '--',
                      },
                      {
                        heading: 'Phone 2',
                        data: alertContact?.phone_numbers?.length
                          ? alertContact.phone_numbers[1]?.phone_number
                          : '--',
                      },
                    ]}
                    customRow
                    renderItem={(row) => {
                      return (
                        <BaselaneResponsiveTableRow
                          key={row.heading}
                          chevronCellStyles={{ w: 0 }}
                          contentContainerStyles={{ border: 'none' }}
                        >
                          <BaselaneResponsiveCell configIndex={0} p={0} colSpan="2">
                            <Text as="span" textStyle="sm" color="brand.neutral.700">
                              {row.heading}
                            </Text>
                          </BaselaneResponsiveCell>
                          <BaselaneResponsiveCell configIndex={1} p={0} colSpan="2">
                            <Text textStyle="sm">
                              {isFieldNotObject(row.data) ? row.data : '--'}
                            </Text>
                          </BaselaneResponsiveCell>
                        </BaselaneResponsiveTableRow>
                      );
                    }}
                  />
                </Tbody>
              </BaselaneResponsiveTable>
            </Box>
          ),
        },
      ]}
    />
  );
};

export default AlertsAndTriggers;
