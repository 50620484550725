import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Stack,
  Heading,
  Text,
  Box,
} from '@chakra-ui/react';
import { CSV_IMPORT, CSV_IMPORT_UPLOAD } from '@routes';
import { BaselaneCardNew, BaselaneLink } from '@shared/components';
import { Icon24AutoRenew } from '@icons/24px';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import useBreakPoints from '@core/hooks/useBreakPoints';
import AddAccount from '@core/components/NativeBankingPage/MainContent/components/Accounts/components/AddAccount';

type ImportTrxModalProps = {
  isAlertOpen: Boolean,
  onAlertClose: Function,
  onAddedPlaidSuccessfully: Function,
};

const ImportTrxModal = ({
  isAlertOpen,
  onAlertClose,
  onAddedPlaidSuccessfully,
}: ImportTrxModalProps) => {
  const addExternalAccountDrawerRef = useRef();
  const alertCancelRef = useRef();
  const { isMin768 } = useBreakPoints();

  const onImportCsv = () => {
    sendSegmentEvent('csv_import_started');
  };

  return (
    <AlertDialog
      leastDestructiveRef={alertCancelRef}
      onClose={onAlertClose}
      isOpen={isAlertOpen}
      isCentered
      size="md"
      trapFocus={false}
    >
      <AlertDialogOverlay />
      <AlertDialogContent minW="327px" maxW="416px" p={{ base: 3, md: 4 }} mx={{ base: 2, md: 0 }}>
        <Stack spacing={3}>
          <AlertDialogCloseButton />
          <AlertDialogHeader p={0}>
            <Heading size="headline-lg">Import transactions</Heading>
          </AlertDialogHeader>
          <Stack spacing={2} color="brand.neutral.900">
            <BaselaneCardNew size="md" width="100%">
              <Stack spacing={1.5} width="100%">
                <Icon24AutoRenew />
                <Box mb={0.5}>
                  <Heading size="headline-sm" mb={0.5}>
                    Auto-import from external account
                  </Heading>
                  <Text textStyle="xs" color="brand.neutral.700">
                    Recommended for automatically importing transactions from an external bank
                    account to Baselane bookkeeping.
                  </Text>
                </Box>
                <AddAccount
                  isDirectToPlaid
                  hideButton
                  isFromLease={false}
                  handleDrawerCloseExternally={onAddedPlaidSuccessfully}
                  addExternalAccountDrawerRef={addExternalAccountDrawerRef}
                  isDirectToPlaidButtonProps={{
                    variant: 'filled',
                    palette: 'primary',
                    styles: { width: '100%' },
                    segmentEvent: () => {
                      sendSegmentEvent('baselane_click_connect_bank_account_fe', {
                        entry_point: 'import_transactions_modal',
                      });
                    },
                  }}
                  loaderStyles={{
                    // override to place the loader at the center
                    // of the screen as the accounts are added/reloaded
                    w: '0px !important',
                    h: '0px !important',
                    top: '50%',
                    left: '50%',
                  }}
                />
              </Stack>
            </BaselaneCardNew>
            <Box>
              <Heading size="headline-sm">Switching from another accounting software?</Heading>
              <Text textStyle="xs" mt={0.5} color="brand.neutral.700">
                Quickly bring over your data using{' '}
                {isMin768 ? (
                  <BaselaneLink
                    variant="primary"
                    to={`/${CSV_IMPORT}/${CSV_IMPORT_UPLOAD}`}
                    onClick={() => onImportCsv()}
                  >
                    CSV Import
                  </BaselaneLink>
                ) : (
                  'CSV Import (only available on desktop)'
                )}
              </Text>
            </Box>
          </Stack>
        </Stack>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ImportTrxModal;
