import React from 'react';
import { isMobile } from 'react-device-detect';
import { Stack } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { BaselaneButton, BaselaneDrawer } from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { IconAccountAndRoutingNumbers, IconChevronLeft, IconPaypalVenmo } from '@icons';
import ButtonMethod from '../../../ButtonMethod';
import { drawerFooterStyles } from '../../styles/addFundsDrawer.styles';

type EducationalDrawerWrapperProps = {
  educationalDrawerRef: any,
  handleDrawerClose: Function,
  handleDrawerOpen: Function,
  buttonContent: Object,
  bankTransferProps: Object,
  hideButton?: boolean,
  isInEmptyState?: boolean,
  children: any,
};

const EducationalDrawerWrapper = ({
  educationalDrawerRef,
  handleDrawerClose,
  handleDrawerOpen,
  buttonContent,
  hideButton,
  isInEmptyState,
  children,
}: EducationalDrawerWrapperProps) => {
  const { DrawerFooter, DrawerBody } = BaselaneDrawer;
  const { isMinXL } = useBreakPoints();

  const handleEducationDrawerClose = () => handleDrawerClose(buttonContent.id);

  const handleEducationDrawerOpen = () => {
    handleDrawerOpen(buttonContent.id);
    sendSegmentEvent(buttonContent.segmentEvent, {
      method: buttonContent.method,
      platform: isMobile ? 'mobile' : 'desktop',
      hasCounterparty: !isInEmptyState,
    });
  };

  const getIcon = () => {
    if (buttonContent.method === 'initiate_ach_transfer') {
      return <IconAccountAndRoutingNumbers />;
    }

    if (buttonContent.method === 'third_party') {
      return <IconPaypalVenmo />;
    }

    return undefined; // Explicit return because of eslint.
  };

  return (
    <>
      {!hideButton && (
        <ButtonMethod
          icon={getIcon()}
          {...{
            handleOnClick: handleEducationDrawerOpen,
            title: buttonContent.title,
            description: buttonContent.description,
          }}
        />
      )}

      <BaselaneDrawer
        ref={educationalDrawerRef}
        title={buttonContent.title}
        closeEvent={handleDrawerClose}
        hideOverlay
        isMobileHeader={isMinXL}
        showBackButton={isMinXL}
      >
        <DrawerBody p="48px 32px">
          <Stack spacing="96px">{children}</Stack>
        </DrawerBody>

        <DrawerFooter {...drawerFooterStyles(isMinXL)}>
          <BaselaneButton
            size="md"
            variant="outline"
            palette="neutral"
            leftIcon={<IconChevronLeft />}
            styles={{
              width: isMinXL ? '100%' : 'auto',
            }}
            onClick={handleEducationDrawerClose}
          >
            Back
          </BaselaneButton>
        </DrawerFooter>
      </BaselaneDrawer>
    </>
  );
};

EducationalDrawerWrapper.defaultProps = { hideButton: false, isInEmptyState: false };

export default EducationalDrawerWrapper;
