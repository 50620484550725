const PAYMENT_TYPE = Object.freeze({
  FEE: 'FEE',
  LATE_FEE: 'LATE_FEE',
  DAILY_LATE_FEE: 'DAILY_LATE_FEE',
  RENT: 'RENT',
  DEPOSIT: 'DEPOSIT',
  PRORATED_RENT: 'PRORATED_RENT',
  LAST_MONTH_RENT: 'LAST_MONTH_RENT',
});

export default PAYMENT_TYPE;
