/* eslint-disable react/prop-types */
import React from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { Field } from 'formik';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import BaselaneFormErrorMessage from '@core/components/Shared/components/BaselaneForm/BaselaneFormErrorMessage';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { BaselaneLink } from '@shared/components';
import { ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS } from '@routes';
import T1WithTitleDropdown from '../../../../BaselaneDropdown-new/T1-Title';
import DropDownDisplayInputWithOutRightButton from '../../../../BaselaneDropdown-new/DisplayInputVariations/DropDownDisplayInputWithOutRightButton';
import BaselaneFormHelperText from '../../../../BaselaneForm/BaselaneFormHelperText';
import useTransferFromAccountValidation from './useTransferFromAccountValidation';
import AccountDropdownItemWithBalanceAndAccountType from '../AccountDropdownItemWithBalanceAndAccountType';
import useTransferFromAccountSubmit from './useTransferFromAccountSubmit';

const TransferFromAccount = ({
  openEducationalDrawer,
  updatedLinkTokenData,
  updateLinkTokenIsLoading,
  updateLinkToken,
}) => {
  const { isMax576 } = useBreakPoints();

  const { fromDropdownList, bankAccountsById } = useBankAccountsActive({
    filterBySubType: ['checking', 'savings'],
  });

  const { validate } = useTransferFromAccountValidation({
    openEducationalDrawer,
    updatedLinkTokenData,
    updateLinkTokenIsLoading,
  });

  const { submitValue } = useTransferFromAccountSubmit({ updateLinkToken });

  return (
    <Field name="transferFromAccountId" validate={validate}>
      {({ field, form }) => {
        const selectedAccount = bankAccountsById[field.value];

        return (
          <FormControl
            isInvalid={form.errors.transferFromAccountId && form.touched.transferFromAccountId}
            isRequired
            mt={3}
          >
            <FormLabel htmlFor="transferFromAccountId">Transfer from</FormLabel>
            <T1WithTitleDropdown
              data={fromDropdownList}
              placeholder="Select"
              title="Account"
              searchTerm={['name', 'nickName']}
              showValueByFields={['name', 'nickName']}
              itemRenderer={AccountDropdownItemWithBalanceAndAccountType}
              isMulti={false}
              hasFilterWrapper={false}
              CustomDisplayInput={DropDownDisplayInputWithOutRightButton}
              parentId="drawer-body"
              isMobile={isMax576}
              showDivider
              name="transferFromAccountId"
              additionalProps={{ id: 'transfer-funds-transfer-from-dropdown' }}
              classNames={[
                'input-form-lg',
                'auto-select-input-width',
                ...(form.errors.transferFromAccountId && form.touched.transferFromAccountId
                  ? ['input-invalid']
                  : []),
              ]}
              selectedItem={selectedAccount}
              handleSubmit={submitValue}
            />
            {!form.errors.transferFromAccountId && selectedAccount?.isExternal && (
              <BaselaneFormHelperText type="isError" customStyles={{ color: 'brand.neutral.600' }}>
                External transfers will take 3 business days
              </BaselaneFormHelperText>
            )}
            {field.value && !selectedAccount?.transferAccountId ? (
              <BaselaneFormHelperText
                type="error"
                customStyles={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
              >
                This account can&apos;t be used to initiate a transfer from Baselane. Use a
                different account or initiate the transfer from your external account using your
                &nbsp;
                <BaselaneLink variant="primary" size="md" to={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}>
                  Baselane Banking account & routing numbers.
                </BaselaneLink>
              </BaselaneFormHelperText>
            ) : null}
            <BaselaneFormErrorMessage>{form.errors.transferFromAccountId}</BaselaneFormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default TransferFromAccount;
