import React, { useContext } from 'react';
import { Stack, Box, useDisclosure, Text } from '@chakra-ui/react';
import moment from 'moment/moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useStatsigClient } from '@statsig/react-bindings';
import UserAccessContext from '@contexts/UserAccessContext';
import { BaselaneApplicationTypePopup, BaselaneButton, NoAccessTooltip } from '@shared/components';
import { UNIFIED_LB_PERSONAL_INFO } from '@routes';
import { Icon16Bolt } from '@icons/16px/index';
import historyStateStorage from '@core/storage/historyStateStorage';
import { GET_KYC_URL } from '@core/components/NativeBankingPage/queries';
import formatCurrency from '@core/utils/formatCurrency';
import { DYNAMIC_CONFIGS } from '@core/constants/statsigKeys';

type LearnMoreEmptyPromoBannerProps = {
  isUserOnPromo: Boolean,
  isEmptyState: Boolean,
  userPromoApy: String,
  isUserEligibleForPromo: Boolean,
  userPromoEndDate: Date,
};

const LearnMorePromoAndEmptyStateBanner = ({
  isUserOnPromo,
  isEmptyState,
  userPromoApy,
  isUserEligibleForPromo,
  userPromoEndDate,
}: LearnMoreEmptyPromoBannerProps) => {
  const { getDynamicConfig } = useStatsigClient();
  const config = getDynamicConfig(DYNAMIC_CONFIGS.APY_INTEREST);
  const { isWorkspaceOwner } = useContext(UserAccessContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [getKYCUrl] = useLazyQuery(GET_KYC_URL, {
    onCompleted: ({ unitOnBoarding: unitBankUrl }) => window.open(unitBankUrl, '_self'),
    onError: (error) => console.error(error),
    fetchPolicy: 'no-cache',
  });

  // TODO: bring popup code into context or create a hook, this is being used in many places
  // Application Type Selector Popup
  const {
    isOpen: isBaselaneApplicationTypePopupOpen,
    onOpen: onBaselaneApplicationTypePopupOpen,
    onClose: onBaselaneApplicationTypePopupClose,
  } = useDisclosure();

  const openLBFlow = (type) => {
    const pathname = UNIFIED_LB_PERSONAL_INFO;
    const state = { from: location.pathname };

    if (type === 'business') {
      getKYCUrl();
    } else {
      historyStateStorage.write(state);
      navigate(pathname);
    }
  };

  return (
    <Stack my="20px">
      {/* Text above banner in empty state */}
      {isEmptyState && (
        <Text color="brand.neutral.800" textStyle="sm">
          {`Earn up to ${
            config?.get('apy') ?? '--'
          }% APY on your savings accounts. Higher balance unlocks higher APY.`}
        </Text>
      )}

      {/* Banner Section for empty state + promo state */}
      {((isEmptyState && isUserEligibleForPromo) || isUserOnPromo) && (
        <Stack
          direction={isUserOnPromo ? 'row' : 'column'}
          p="16px 12px"
          textStyle="sm"
          color="brand.neutral.800"
          bgColor="brand.blue.100"
          borderRadius="4px"
        >
          <Stack direction="row" alignItems="center">
            <Box>
              <Icon16Bolt color="#0968BF" />
            </Box>
            {isUserOnPromo && (
              <Box>
                As a new user, you will receive our maximum APY through{' '}
                {moment(userPromoEndDate).format('MMM YYYY')}.
              </Box>
            )}
            {isEmptyState && (
              <Box>
                Unlock{' '}
                <Box as="span" fontWeight="medium">
                  {formatCurrency(userPromoApy, true).noDollar}%
                </Box>{' '}
                APY for the first 3 months
              </Box>
            )}
          </Stack>

          {isEmptyState && isUserEligibleForPromo && (
            <>
              <Box>
                {!isWorkspaceOwner ? (
                  <NoAccessTooltip>
                    <BaselaneButton
                      variant="tonal"
                      palette="primary"
                      isDisabled={!isWorkspaceOwner}
                      onClick={onBaselaneApplicationTypePopupOpen}
                    >
                      Open Baselane Banking Account
                    </BaselaneButton>
                  </NoAccessTooltip>
                ) : (
                  <BaselaneButton
                    variant="tonal"
                    palette="primary"
                    onClick={onBaselaneApplicationTypePopupOpen}
                  >
                    Open Baselane Banking Account
                  </BaselaneButton>
                )}
              </Box>
              <BaselaneApplicationTypePopup
                handleActionButton={openLBFlow}
                isOpen={isBaselaneApplicationTypePopupOpen}
                onClose={onBaselaneApplicationTypePopupClose}
              />
            </>
          )}
        </Stack>
      )}

      {/* Text below banner if user is on promo state */}
      {isUserOnPromo && (
        <Text color="brand.neutral.800" textStyle="sm">
          After this promo period, APY will be determined by your balance and rent collection, as
          outlined below.
        </Text>
      )}
    </Stack>
  );
};

export default LearnMorePromoAndEmptyStateBanner;
