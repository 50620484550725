import React from 'react';
import { Heading, Stack, Text, Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { useStatsigClient } from '@statsig/react-bindings';
import { BaselaneButton, BaselaneCardNew } from '@shared/components';
import { Icon24Key } from '@icons/24px';
import { UNIFIED_RENT_COLLECTION, TENANT_SCREENING } from '@routes';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { emptyStateContainerStyles } from './styles';

const EmptyState = () => {
  const navigate = useNavigate();
  const { checkGate } = useStatsigClient();

  return (
    <BaselaneCardNew {...emptyStateContainerStyles}>
      <Stack my={{ md: 4, base: 0 }} spacing={2} alignItems="center">
        <Icon24Key />
        <Stack spacing={1} alignItems="center" maxWidth="480px">
          <Heading size="headline-lg">Hassle-free rent collection</Heading>
          <Text textStyle="sm" textAlign="center">
            Save hours each month with recurring rent payments and real time tracking. Increase
            on-time rent with automated reminders & late fees.
          </Text>
          <BaselaneButton
            variant="tonal"
            palette="primary"
            size="lg"
            styles={{ mt: 2 }}
            onClick={() => {
              sendSegmentEvent('add_rent_collection_started', {
                entry_point: 'baselane_dashboard',
              });
              navigate(UNIFIED_RENT_COLLECTION);
            }}
          >
            Set up rent collection
          </BaselaneButton>
          {/* Tenant screening button */}
          {checkGate(FEATURE_GATES.TENANT_SCREENING_GATE) ? (
            <Box display="flex" gap={1} mt={2}>
              <Text textStyle="sm" textAlign="center">
                Looking for a new tenant?
              </Text>
              <BaselaneButton
                variant="link"
                palette="primary"
                size="md"
                onClick={() => {
                  navigate(`/${TENANT_SCREENING}`);
                }}
              >
                Start screening
              </BaselaneButton>
            </Box>
          ) : null}
        </Stack>
      </Stack>
    </BaselaneCardNew>
  );
};

export default EmptyState;
