import React from 'react';
import { ButtonGroup } from '@chakra-ui/react';

type BaselaneButtonGroupProps = (size: string, isReversed: boolean, styles: object) => {};

const BaselaneButtonGroup = ({
  size = 'inherit',
  isReversed = false,
  children,
  styles = {},
}: BaselaneButtonGroupProps) => {
  const spacingMap = {
    inherit: 0, // default Chakra styling adds margin-left: 8px on every child that is :not(:first-child)
    sm: 0.5,
    md: 1,
    lg: 2,
  };
  const spacing = spacingMap[size];

  return isReversed ? (
    <ButtonGroup
      display="flex"
      flexDirection="row-reverse"
      alignContent="flex-start"
      gap={spacing}
      {...styles}
    >
      {children}
    </ButtonGroup>
  ) : (
    <ButtonGroup gap={spacing} {...styles}>
      {children}
    </ButtonGroup>
  );
};

export default BaselaneButtonGroup;
