import React, { ReactComponentElement } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { Icon16ChevronRight } from '@icons/16px';
import BaselaneCardNew from '../../../BaselaneCard-new/BaselaneCardNew';

type BaselaneCardStackLayoutClickableProps = {
  title: string,
  description: string,
  icon: ReactComponentElement,
  onClick: Function,
  id: string,
};

const BaselaneCardStackLayoutClickable = ({
  title,
  description = null,
  icon,
  onClick,
  id = 'card-stack-button',
}: BaselaneCardStackLayoutClickableProps) => {
  return (
    <BaselaneCardNew
      id={id}
      variant="innerClickable"
      size="md"
      onClick={onClick}
      {...{ alignItems: 'flex-start', justifyContent: 'flex-start' }}
    >
      <Box>{icon}</Box>
      <Box flex="3" ml={2} mr={1.5} overflow="hidden">
        <Heading size="headline-sm" mb={0.5} overflow="hidden" textOverflow="ellipsis">
          {title}
        </Heading>
        {description && (
          <Text textStyle="xs" whiteSpace="normal">
            {description}
          </Text>
        )}
      </Box>
      <Flex ml="auto" alignSelf="center">
        <Icon16ChevronRight />
      </Flex>
    </BaselaneCardNew>
  );
};

export default BaselaneCardStackLayoutClickable;
