import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';

import { getQueryParams } from '@core/Services/Firebase/firebase';
import { GET_BANK_SUMMARY } from '@core/components/NativeBankingPage/queries';
import { GET_BANK_UNIT_ACCOUNT } from '@pages/UnifiedLandlordBanking/UnifiedLandlordBankingSuccess/queries/banks';
import { BaselaneErrorCard, ProcessingPopup, useProcessingPopup } from '@shared/components';
import { useCurrentWorkspace } from '@shared/hooks';
import { UNIFIED_LANDLORD_BANKING_SUCCESS } from '@routes';
import userContext from '@contexts/UserContext';

const SuccessWrapper = ({ ChildComponent }: { ChildComponent: ReactElement }) => {
  const { isProcessingPopupOpen, onProcessingPopupClose } = useProcessingPopup(true);
  const { refetchUser } = useContext(userContext);
  const { refetchCurrentWorkspace } = useCurrentWorkspace();

  const location = useLocation();
  const navigate = useNavigate();
  const [isPolling, setIsPolling] = useState(false);
  const [isUserPolling, setIsUserPolling] = useState(false);

  const unitApplicationId =
    getQueryParams(location, 'unitApplicationId') ||
    localStorage.getItem('unitApplicationId') ||
    null;
  localStorage.setItem('unitApplicationId', unitApplicationId);

  const { data, error, loading, startPolling, stopPolling } = useQuery(GET_BANK_UNIT_ACCOUNT, {
    variables: {
      isExternal: false,
      unitApplicationIds: unitApplicationId ? [unitApplicationId] : [],
    },
  });

  // Note: This second query is used to determine how many successfully completed account
  // application the user has. There may be room for optimization by combining with
  // previous query, but in this current state all the logic works appropriately.
  const { data: banksListData } = useQuery(GET_BANK_SUMMARY, {
    fetchPolicy: 'no-cache',
    variables: {
      isExternal: false,
    },
  });

  const { baselaneBankBalance = 0, bank = [] } = data?.bankSummary ?? {};

  const [bankData] = unitApplicationId && bank.length ? bank : bank;

  const { unitAccount } = bankData ?? {};
  const { unitApplicationStatus: status = null } = unitAccount ?? {};

  useEffect(() => {
    if (bank.length === 0 || bank?.[0]?.bankAccounts.length === 0 || bank?.unitAccount === null) {
      setIsPolling(true);
      startPolling(5000);
      setIsUserPolling(true);
      setTimeout(() => {
        setIsPolling(false);
        stopPolling();
      }, 5000);
    }

    // remove query param for unitApplicationId if there
    if (getQueryParams(location, 'unitApplicationId')) {
      navigate({
        pathname: UNIFIED_LANDLORD_BANKING_SUCCESS,
      });
    }
    // Note: intended to only run once on render, hence [] deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bank.length > 0 && bank?.[0]?.bankAccounts.length > 0 && bank?.unitAccount !== null) {
      setIsPolling(false);
      stopPolling();
      refetchUser();
      refetchCurrentWorkspace();
    }
  }, [bank, stopPolling]);

  if (error) return <BaselaneErrorCard />;
  if ((loading || (isPolling && isUserPolling)) && unitApplicationId !== 'null')
    return (
      <ProcessingPopup
        showGraphic={false}
        isProcessingPopupOpen={isProcessingPopupOpen}
        onProcessingPopupClose={onProcessingPopupClose}
      />
    );

  return (
    <ChildComponent
      // used by both banking first and org success screen flows
      status={status}
      banksListData={banksListData}
      // rest is mainly used in SuccessFlow
      setIsUserPolling={setIsUserPolling}
      bankData={bankData}
      baselaneBankBalance={baselaneBankBalance}
    />
  );
};

export default SuccessWrapper;
