import React, { useRef } from 'react';
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';
import {
  Heading,
  Stack,
  ChakraProvider,
  FormControl,
  useToast,
  Text,
  Select,
} from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import habitatTheme from '@core/themeHabitat';
import { IconLock } from '@icons';
import {
  BaselaneAlert,
  BaselaneButton,
  BaselaneFormErrorMessage,
  BaselaneInput,
  BaselaneFormLabel,
  BaselaneButtonGroup,
  BaselaneDivider,
  BaselaneAutoCompleteAddress,
  BaselaneAddress,
} from '@shared/components';
import { zipcodeMask } from '@core/utils/masks';
import useTaxData from '../hooks/useTaxData';

type LandlordDataMissingTaxModalProps = {
  isOpen: Boolean,
  onClose: Function,
  handleLandlordTaxModalClick: Function,
  handleLandlordTaxModalRemindMeLaterClick: Function,
  daysToExpire: Number,
  id: Number,
  entity: Object,
};

const LandlordDataMissingTaxModal = ({
  isOpen,
  onClose,
  handleLandlordTaxModalClick,
  handleLandlordTaxModalRemindMeLaterClick,
  daysToExpire,
  id,
  entity,
}: LandlordDataMissingTaxModalProps) => {
  const cancelRef = useRef();
  const { isMinXL } = useBreakPoints();
  const toast = useToast();
  const showSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Submitted ',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Something went wrong. Please try again.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const formikRef = useRef(null);
  const initialValues = { legalname: entity?.data?.legalName, ein: '', address: '' };

  const { taxRefetchData, captureUserTData } = useTaxData();

  const onSubmit = (data) => {
    const variables = {
      input: {
        legalName: data.legalname,
        ein: data.ein,
        address: {
          street: data.address,
          unit: data.unit,
          city: data.city,
          state: data.state,
          postalCode: data.zipcode,
        },
      },
      entityId: entity.entityId,
    };

    captureUserTData({ variables })
      .then((res) => {
        taxRefetchData();
        showSuccessToast();
        onClose();
      })
      .catch((err) => {
        console.log(err);
        showErrorToast();
        onClose();
      });
  };
  const handleValidation = (values) => {
    const { legalname, ein } = values;
    const errors = {};

    if (!legalname) {
      errors.legalname = 'This field is required';
    }
    if (!ein) {
      errors.ein = 'This field is required';
    } else if (ein.length !== 9 || !/^\d+$/.test(ein)) {
      errors.ein = 'EIN must be 9 characters';
    }

    if (values.address?.trim() === '') {
      errors.address = 'Please enter address';
    }

    if (values.city?.trim() === '') {
      errors.city = 'Please enter city';
    }

    if (values.state === '') {
      errors.state = 'Please enter state';
    }

    if (values.zipcode === '') {
      errors.zipcode = 'Please enter zipcode';
    }

    if (values.zipcode !== '' && values.zipcode?.length < 5) {
      errors.zipcode = 'Please enter 5-digit zip code';
    }

    return errors;
  };

  const einMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  return (
    <BaselaneAlert
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      isCentered
      showCloseButton
      size={isMinXL ? 'sm' : '2xl'}
      body={
        <ChakraProvider theme={habitatTheme}>
          {/* Header Section */}
          <Formik
            innerRef={formikRef}
            validateOnChange
            validateOnBlur
            initialValues={initialValues}
            validate={handleValidation}
            onSubmit={onSubmit}
          >
            {(formikProps) => {
              const {
                values,
                touched,
                errors,
                isValid,
                setFieldTouched,
                handleChange,
                handleBlur,
              } = formikProps;
              return (
                <Stack>
                  <Heading size="headline-lg" mb="3">
                    Entity tax information
                  </Heading>
                  <FormControl
                    id="legal-name"
                    isInvalid={errors.legalname && touched.legalname}
                    isRequired
                  >
                    <BaselaneFormLabel>Legal name</BaselaneFormLabel>
                    <BaselaneInput
                      size="lg"
                      id="legalname"
                      name="legalname"
                      value={values?.legalname}
                      type="text"
                      placeholder="Legal Name"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        setFieldTouched('legalname');
                        handleBlur(e);
                      }}
                      maxLength={50}
                    />
                    <BaselaneFormErrorMessage isInvalid={errors.legalname && touched.legalname}>
                      {errors.legalname}
                    </BaselaneFormErrorMessage>
                  </FormControl>
                  <FormControl id="ein" isInvalid={errors.ein && touched.ein} isRequired>
                    <BaselaneFormLabel>EIN / SSN</BaselaneFormLabel>
                    <BaselaneInput
                      as={MaskedInput}
                      size="lg"
                      id="ein"
                      name="ein"
                      value={values?.ein}
                      type="text"
                      placeholder="xxxxxxxxx"
                      mask={einMask}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        setFieldTouched('ein');
                        handleBlur(e);
                      }}
                    />
                    <BaselaneFormErrorMessage isInvalid={errors.ein && touched.ein}>
                      {errors.ein}
                    </BaselaneFormErrorMessage>
                  </FormControl>
                  <BaselaneDivider styles={{ mt: '1', mb: '1' }} />
                  <Heading size="headline-sm" mb="1.5">
                    Legal address of entity
                  </Heading>

                  <BaselaneAutoCompleteAddress
                    handleBlur={handleBlur}
                    cfieldContainer={{}}
                    touched={touched}
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    showIsRequired
                    placeholder="Street address"
                    showPredictionsAbove
                  />

                  {/* Unit Number */}
                  <FormControl isInvalid={errors.unit && touched.unit} mt="2">
                    <BaselaneFormLabel htmlFor="unitNumber">
                      Apartment, Unit, Suite, or Floor # (Optional)
                    </BaselaneFormLabel>
                    <BaselaneInput
                      size="lg"
                      id="unit"
                      name="unit"
                      value={values?.unit}
                      type="text"
                      placeholder="e.g. Floor 1"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        setFieldTouched('unit');
                        handleBlur(e);
                      }}
                    />
                    <BaselaneFormErrorMessage isInvalid={errors.unit && touched.unit}>
                      {errors.unit}
                    </BaselaneFormErrorMessage>
                  </FormControl>
                  <Stack direction="row" spacing={2}>
                    {/* City */}
                    <FormControl isInvalid={errors.city && touched.city} isRequired>
                      <BaselaneFormLabel htmlFor="city">City</BaselaneFormLabel>
                      <BaselaneInput
                        id="city"
                        name="city"
                        value={values.city}
                        placeholder="City"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={(e) => {
                          setFieldTouched('city');
                          handleBlur(e);
                        }}
                        size="lg"
                      />
                      <BaselaneFormErrorMessage>{errors.city}</BaselaneFormErrorMessage>
                    </FormControl>

                    {/* State */}
                    <FormControl w="50%" isInvalid={errors.state && touched.state} isRequired>
                      <BaselaneFormLabel htmlFor="state">State</BaselaneFormLabel>
                      <Select
                        p="0"
                        id="state"
                        name="state"
                        value={values.state}
                        placeholder="state"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={(e) => {
                          setFieldTouched('state');
                          handleBlur(e);
                        }}
                        size="lg"
                      >
                        <BaselaneAddress.StateOptions />
                      </Select>
                      <BaselaneFormErrorMessage>{errors.state}</BaselaneFormErrorMessage>
                    </FormControl>

                    {/* Zip Code */}
                    <FormControl
                      position="relative"
                      w="50%"
                      isInvalid={errors.zipcode && touched.zipcode}
                      isRequired
                    >
                      <BaselaneFormLabel htmlFor="zipcode">Zip Code</BaselaneFormLabel>
                      <BaselaneInput
                        as={MaskedInput}
                        mask={zipcodeMask}
                        id="zipcode"
                        name="zipcode"
                        value={values.zipcode}
                        placeholder="zip code"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        type="text"
                        onBlur={(e) => {
                          setFieldTouched('zipcode');
                          handleBlur(e);
                        }}
                        size="lg"
                      />
                      <BaselaneFormErrorMessage>{errors.zipcode}</BaselaneFormErrorMessage>
                    </FormControl>
                  </Stack>
                  <Stack direction="row" align="center">
                    <IconLock w={10} h={11} />
                    <Text textStyle="xs">
                      Your information is secured with bank-level 256-bit encryption.
                    </Text>
                  </Stack>
                  <BaselaneButtonGroup
                    size="md"
                    styles={{ minW: '100%', gap: 1.5, justifyContent: 'flex-end' }}
                  >
                    <BaselaneButton
                      size="md"
                      variant="outline"
                      width={isMinXL ? '100%' : 'auto'}
                      onClick={() => onClose()}
                    >
                      Back
                    </BaselaneButton>
                    <BaselaneButton
                      size="md"
                      variant="filled"
                      palette="primary"
                      width={isMinXL ? '100%' : 'auto'}
                      onClick={() => onSubmit(values)}
                      isDisabled={
                        !isValid ||
                        !values.legalname ||
                        !values.ein ||
                        !values.address ||
                        !values.city ||
                        !values.state ||
                        !values.zipcode
                      }
                    >
                      Submit
                    </BaselaneButton>
                  </BaselaneButtonGroup>
                </Stack>
              );
            }}
          </Formik>
        </ChakraProvider>
      }
      bodyStyles={{ m: '0' }}
      footerStyles={{ mt: '3' }}
      containerStyles={{
        m: '0 !important',
        p: isMinXL ? '16px!important' : '32px!important',
        borderRadius: '12px',
      }}
    />
  );
};

export default LandlordDataMissingTaxModal;
