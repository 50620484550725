// @flow
import React, { useContext } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { Box, Tab, Tabs, TabList, TabPanels } from '@chakra-ui/react';
import { NATIVE_BANK } from '@routes';
import BanksContext from '@contexts/BanksContext';
import useBreakPoints from '@core/hooks/useBreakPoints';

import {
  tabContainerStyles,
  tabWrapperVanillaJsStyles,
  tabListStyles,
  tabStyles,
  tabPanelsStyles,
} from '@shared/styles/tab.styles';

import { getTabs } from './helpers/mainContent.helpers';

function MainContent(): any {
  const { isMinXL, isMin768 } = useBreakPoints();
  const { hasActiveBaselaneAccounts, hasVisibleApplications } = useContext(BanksContext);

  const { pathname } = useLocation();

  const { tabsList, tabsIndex } = getTabs(hasActiveBaselaneAccounts, isMinXL) ?? {};

  const margin = {
    base: '0px 16px 16px 16px !important',
    sm: '4px 16px 16px 16px !important',
    md: '8px !important',
    lg: '0px !important',
  };

  const isCardsPage = pathname.includes('/banking/cards');
  let splitPathname = pathname.split('/');
  if (splitPathname.length > 3) {
    splitPathname = splitPathname.slice(0, 3);
  }
  const filteredPathname = splitPathname.join('/');

  return hasActiveBaselaneAccounts || hasVisibleApplications ? (
    <Box
      {...{
        ...tabContainerStyles,
        margin,
      }}
    >
      <Tabs position="relative" isLazy variant="unstyled" index={tabsIndex[filteredPathname] || 0}>
        <TabList
          {...{
            ...tabListStyles(isMin768),
            position: 'relative !important',
            top: 0,
            left: 0,
          }}
        >
          {tabsList.map((tab) => {
            const { index, path, label, isDisabled } = tab ?? {};
            return (
              <NavLink
                data-cy={`cards-tab-link-${index}`}
                key={index}
                to={`${NATIVE_BANK}${path}`}
                {...tabWrapperVanillaJsStyles(isMin768)}
              >
                <Tab
                  key={index}
                  {...{ isDisabled }}
                  {...tabStyles(isMin768)}
                  data-cy={`cards-tab-button-${index}`}
                >
                  {label}
                </Tab>
              </NavLink>
            );
          })}
        </TabList>

        <TabPanels {...{ ...tabPanelsStyles, p: isCardsPage ? '0' : '24px' }}>
          <Outlet />
        </TabPanels>
      </Tabs>
    </Box>
  ) : (
    <Outlet />
  );
}

export default MainContent;
