export const tabContainerStyles = (isMax576: boolean) => ({
  d: 'flex',
  flexDir: 'row',
  justifyContent: isMax576 ? 'center' : 'flex-start',
  alignItems: 'flex-end',
  gap: isMax576 ? 2 : 4,
  px: isMax576 ? 2 : 4,
  h: '62px',
});

export const userProfileTabsListStyles = (isMax576) => ({
  color: 'brand.neutral.500',
  fontSize: isMax576 ? 'xs' : 'md',
  fontWeight: 'medium',
  px: 0,
  py: 2,
  h: '100%',
  minW: isMax576 ? { base: '60px', xs: 'calc(25% - 16px)' } : 0,
  _selected: {
    color: 'brand.blue.700',
    borderColor: 'brand.blue.700',
  },
});
