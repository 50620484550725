import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_BANKS } from '@core/apollo/queries';
import { getConnectedExtManualAccOptions } from '../../../contexts/TransactionContext/helpers';

const sortAccountsById = (accounts) => {
  const accountsById = {};
  accounts.forEach((accountCategory) => {
    accountCategory.items.forEach((account) => {
      accountsById[account.id] = account;
    });
  });
  return accountsById;
};

// TODO: Move it to features/accounts/hooks/useAccounts.js when it will be created.
const useAccounts = ({ isConnectedAccount = true, entityId = null } = {}) => {
  const { loading, error, data } = useQuery(GET_BANKS, {
    variables: {
      isConnectedAccount,
    },
  });

  // TODO: Should move [getConnectedExtManualAccOptions] closer to this hook and make it more generic with explanatory documntation.
  const accounts = useMemo(() => (data ? getConnectedExtManualAccOptions(data, entityId) : []), [
    data,
    entityId,
  ]);

  const accountsById = useMemo(() => sortAccountsById(accounts), [accounts]);

  return {
    loading,
    error,
    accounts,
    accountsById,
  };
};

export default useAccounts;
