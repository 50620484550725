import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_TRANSFERS_ACCOUNT_LIST } from '../components/NativeBankingPage/queries/transfers';
import { ACCOUNT_TYPES } from '../components/NativeBankingPage/MainContent/components/BankTransfer/helpers/transfers.helper';
import { menuOptionsConverter } from '../components/NativeBankingPage/MainContent/helpers/accounts.shared.helpers';

const buildTransferAccountsById = (accounts) => {
  const transferAccountsById = {};
  for (const account of accounts) {
    account.items.forEach((item) => {
      transferAccountsById[item.id] = item;
    });
  }
  return transferAccountsById;
};

const buildTransfersAccountsByBankAccountId = (accounts) => {
  const transferAccountsByBankAccountId = {};
  for (const account of accounts) {
    account.items.forEach((item) => {
      transferAccountsByBankAccountId[item.bankAccountId] = item;
    });
  }
  return transferAccountsByBankAccountId;
};

/**
 * @typedef UseTransfersAccountListParams
 * @type {Object}
 * @property {string} [transferType='TRANSFER_IN'] - The transfer type. NOTE: need more info on that param :/
 */

/**
 * @typedef UseTransfersAccountListResult
 * @type {Object}
 * @property {Error} error - The error, if any.
 * @property {boolean} loading - Indication that the query is loading.
 * @property {Array} transferAccounts - The list of transfer accounts as returned by the query.
 * @property {Object} transferAccountsById - The transfer accounts by id.
 * @property {Object} transferAccountsByBankAccountId - The transfer accounts by bank account id.
 */

/**
 * This hook fetches the transfer accounts and returns them as is and by id.
 * @param {UseTransfersAccountListParams} params - The parameters as described by {@link UseTransfersAccountListParams}.
 * @returns {UseTransfersAccountListResult} The result object as described by {@link UseTransfersAccountListResult}.
 */
const useTransfersAccountList = ({ transferType = 'TRANSFER_IN' } = {}) => {
  const { error, loading, data } = useQuery(GET_TRANSFERS_ACCOUNT_LIST, {
    variables: {
      transferType,
    },
    fetchPolicy: 'network-only',
  });

  const transferAccounts = useMemo(() => {
    if (!data) {
      return [];
    }

    return menuOptionsConverter(
      data?.transferAccountList?.toAccount,
      ACCOUNT_TYPES[transferType]?.to?.isExternal
    );
  }, [data, transferType]);

  const transferAccountsById = useMemo(() => buildTransferAccountsById(transferAccounts), [
    transferAccounts,
  ]);

  const transferAccountsByBankAccountId = useMemo(
    () => buildTransfersAccountsByBankAccountId(transferAccounts),
    [transferAccounts]
  );

  return {
    error,
    loading,
    transferAccounts,
    transferAccountsById,
    transferAccountsByBankAccountId,
  };
};

export default useTransfersAccountList;
