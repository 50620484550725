import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text, Stack, useMediaQuery } from '@chakra-ui/react';
import { BaselaneButton, BaselaneButtonIcon, TrackerBox } from '@shared/components';
import { TrackersConfig } from '@shared/helpers/onboardingConfig.helper';
import { InProgress } from '@pages/Dashboard/SetupGuide/GuideTrackers/Steps';
import { ADD_ACCOUNT } from '@routes';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import UserContext from '@contexts/UserContext';
import customTheme from '@theme';
import { triggerBtnWrapperStyles, triggerBtnLableStyles } from './styles/emptyState';

type AddAccountButtonProps = {
  isOnboarding: boolean,
  isGetStarted: boolean,
  getStartedConfig: Object,
  buttonText: string,
  buttonType: string,
  buttonLeftIcon: Function,
  buttonIsLoading: boolean,
  onOpenAddAccountDrawer: Function,
  hasBankAccounts: boolean,
  showMobileVersion: Boolean,
  palette: string,
  variant: string,
  size: String,
};

const AddAccountButton = ({
  isOnboarding,
  isGetStarted,
  getStartedConfig,
  buttonText,
  buttonType,
  buttonLeftIcon,
  buttonIsLoading = false,
  onOpenAddAccountDrawer,
  hasBankAccounts,
  showMobileVersion,
  palette = 'primary',
  variant = 'filled',
  size,
}: AddAccountButtonProps) => {
  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  const [showMobileButton] = useMediaQuery(`(max-width: ${customTheme.breakpoints.sm} )`, {
    ssr: false,
  });

  const handleAddAccount = () => {
    navigate({
      pathname: ADD_ACCOUNT,
    });
  };

  const sendOnboardingSegmentEvent = () => {
    sendSegmentEvent(TrackersConfig.banks.segmentTrackerId, { landlord_uuid: user.id });
  };

  if (isOnboarding) {
    const config = {
      ...TrackersConfig.banks,
      onClickTrackerBox: (e) => {
        handleAddAccount();
        sendOnboardingSegmentEvent();
      },
      isCompleted: hasBankAccounts,
    };
    return <TrackerBox trackerData={config} />;
  }

  if (isGetStarted) {
    return (
      <InProgress
        status={getStartedConfig.status}
        handleTrackerInProgStepClick={() => handleAddAccount()}
      />
    );
  }

  if (showMobileButton && showMobileVersion) {
    return (
      <Stack {...triggerBtnWrapperStyles}>
        <BaselaneButtonIcon
          palette={palette}
          variant={variant}
          icon={buttonLeftIcon}
          onClick={() => handleAddAccount()}
          isLoading={buttonIsLoading}
          type={buttonType}
          size={size}
        />
        <Text {...triggerBtnLableStyles}>{buttonText}</Text>
      </Stack>
    );
  }
  return (
    <BaselaneButton
      variant={variant}
      palette={palette}
      id="add-bank-account"
      onClick={() => handleAddAccount()}
      size={size}
      leftIcon={buttonLeftIcon}
      isLoading={buttonIsLoading}
    >
      {buttonText}
    </BaselaneButton>
  );
};

export default AddAccountButton;
