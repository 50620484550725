/* eslint-disable react/style-prop-object */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Flex, useDisclosure } from '@chakra-ui/react';
import * as ROUTES from '@routes';
import useBreakPoints from '@core/hooks/useBreakPoints';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';
import BaselaneIconLogo from '@core/assets/BaselaneIconLogo';
import {
  logoContainerStyles,
  logoInnerContainerStyles,
  navBarSettings,
  logoFlexStyles,
} from '../NavigationAuth/styles/nav.styles';

function NavigationNonAuth() {
  const { isOpen = false } = useDisclosure();
  const { isLargerThan1537 } = useBreakPoints();

  return (
    <Flex {...navBarSettings(false)}>
      {/* Logo */}
      <Flex {...logoFlexStyles(isOpen)}>
        {/* Logo */}
        <RouterLink to={ROUTES.HOME}>
          <Flex {...logoContainerStyles}>
            <Flex {...logoInnerContainerStyles}>
              {isOpen || isLargerThan1537 ? <BaselaneFullLogo /> : <BaselaneIconLogo />}
            </Flex>
          </Flex>
        </RouterLink>
      </Flex>
    </Flex>
  );
}

export default NavigationNonAuth;
