import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  HStack,
  Spacer,
  Tab,
  TabList,
  Tabs,
  Text,
  Tbody,
  VStack,
  Skeleton,
} from '@chakra-ui/react';
import { useLazyQuery } from '@apollo/client';
import useBreakPoints from '@core/hooks/useBreakPoints';
import formatCurrency from '@core/utils/formatCurrency';
import { dateFormatter } from '@core/utils/formatDate';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import {
  BaselaneButton,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableHeader,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveCell,
} from '@shared/components';
import { GET_TENANT_SCREENINGS } from '@core/apollo/queries/tenantScreening';
import EmptyState from './EmptyState';
import {
  tabsListItems,
  tenantScreeningsListConfig,
  tenantScreeningsListTableHeaderItems,
  getCallToActionLabel,
} from '../helpers/tenantScreening.helpers';
import {
  tabContainerStyles,
  tenantScreeningsTabsListStyles,
} from '../styles/tenantScreening.styles';
import { TENANT_SCREENING_CREATE } from '../../../constants/routes';
import ApplicationsCell from './ApplicationsCell';

type MainProps = {
  tenantScreenings: Array,
  tabIndex: Number,
  setTabIndex: Function,
};

const TenantScreeningMain = ({ tenantScreenings, tabIndex, setTabIndex }: MainProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isMax576, isMin768, isMin899 } = useBreakPoints();

  const [getTenantScreenings, { loading: reloading }] = useLazyQuery(GET_TENANT_SCREENINGS, {
    fetchPolicy: 'cache-first',
  });

  const tabClickHandler = (index) => {
    setTabIndex(index === 0 ? 1 : 0);
  };

  const handleApplicationClick = (application) => {
    const applicationData = {
      propertyName: application?.propertyName,
      unitName: application?.unitName,
      url: application?.applicationLinkTinyUrl,
    };

    navigate(
      {
        pathname: `${pathname}/${application.id}`,
      },
      { state: applicationData }
    );
  };

  useEffect(() => {
    getTenantScreenings({
      variables: {
        input: { applicationLinkActive: tabIndex === 0 },
      },
    });
  }, [tabIndex]);

  // Workspace roleplay option hardcoded for now
  const isOwner = true;

  const currentTenantScreenings = tenantScreenings.filter(
    (a) => a.applicationLinkActive === (tabIndex === 0)
  );

  return (
    <Tabs index={tabIndex} onChange={() => tabClickHandler(tabIndex)} h="inherit" isLazy w="100%">
      <VStack w="100%" alignItems="flex-start" gap={2} px={isMin899 ? '0' : '16px'} pb="32px">
        <TabList {...tabContainerStyles(isMax576)} w="100%">
          {tabsListItems.map((tab) => (
            <Tab key={tab} {...tenantScreeningsTabsListStyles(isMax576)}>
              {tab}
            </Tab>
          ))}
        </TabList>
        <HStack w="100%" py="32px">
          <Heading size="headline-md">
            {tabIndex === 0 ? 'Accepting new applications' : 'Not accepting new applications'}
          </Heading>
          <Spacer />
          <BaselaneButton
            palette="primary"
            variant="filled"
            size="lg"
            onClick={() => {
              navigate(TENANT_SCREENING_CREATE);
              sendSegmentEvent('tenant_screening_fe_setup_started', {
                entry_point: pathname,
              });
            }}
          >
            Set up screening
          </BaselaneButton>
        </HStack>
        <Box w="100%">
          {tenantScreenings?.filter((item) => item.applicationLinkActive === (tabIndex === 0))
            .length < 1 ? (
            <EmptyState active={tabIndex === 0} setTabIndex={setTabIndex} />
          ) : (
            <Skeleton isLoaded={!reloading}>
              <BaselaneResponsiveTable id="member-list" config={tenantScreeningsListConfig}>
                <BaselaneResponsiveTableHeader
                  items={tenantScreeningsListTableHeaderItems}
                  chevronCellStyles={{ display: { sm: 'table-cell', base: 'none' } }}
                  renderItem={(item, index) => {
                    return (
                      <BaselaneResponsiveTableHeading
                        key={item.key}
                        index={index}
                        title={item.label}
                      />
                    );
                  }}
                />
                <Tbody>
                  <BaselaneResponsiveTableRows
                    customRow
                    items={currentTenantScreenings}
                    renderItem={(application, index) => {
                      return (
                        <BaselaneResponsiveTableRow
                          onClick={() => (isOwner ? handleApplicationClick(application) : null)}
                          id={`table-row-${index}`}
                          chevronCellStyles={{
                            display: { sm: 'table-cell', base: 'none' },
                          }}
                        >
                          <BaselaneResponsiveCell
                            configIndex={0}
                            p={0}
                            styles={{
                              justifyContent: 'flex-start',
                            }}
                            {...{
                              h: '72px',
                              _hover: { cursor: isOwner ? 'pointer' : 'default' },
                            }}
                          >
                            <VStack gap="0" spacing={0} alignItems="flex-start">
                              <Text
                                as="div"
                                textStyle="sm"
                                fontWeight="500"
                                color="brand.neutral.900"
                              >
                                {application?.propertyName}
                              </Text>
                              {application?.unitName &&
                                application?.propertyName !== application?.unitName && (
                                  <Text textStyle="sm" color="brand.neutral.600">
                                    {application?.unitName}
                                  </Text>
                                )}
                            </VStack>
                          </BaselaneResponsiveCell>

                          {/* Lease Available Date */}
                          {isMin768 && (
                            <BaselaneResponsiveCell
                              configIndex={1}
                              p={0}
                              color="brand.neutral.900"
                              {...{
                                _hover: { cursor: isOwner ? 'pointer' : 'default' },
                              }}
                            >
                              <Text textStyle="sm">
                                {dateFormatter(application?.leaseAvailableDate)}
                              </Text>
                            </BaselaneResponsiveCell>
                          )}

                          {/* Listed Rent (Amount) */}
                          {isMin768 && (
                            <BaselaneResponsiveCell
                              configIndex={2}
                              p={0}
                              color="brand.neutral.900"
                              {...{
                                _hover: { cursor: isOwner ? 'pointer' : 'default' },
                              }}
                            >
                              <Text textStyle="sm">
                                {formatCurrency(application?.amount).inDollars}
                              </Text>
                            </BaselaneResponsiveCell>
                          )}

                          {/* Applicants Submitted */}
                          <BaselaneResponsiveCell
                            configIndex={3}
                            p={0}
                            color="brand.neutral.900"
                            {...{
                              _hover: { cursor: isOwner ? 'pointer' : 'default' },
                            }}
                          >
                            <ApplicationsCell application={application} />
                          </BaselaneResponsiveCell>

                          {/* CTA's */}
                          {isMin768 && (
                            <BaselaneResponsiveCell configIndex={4} p={0}>
                              <HStack justifyContent="flex-end">
                                <BaselaneButton
                                  onClick={() => {}}
                                  palette="primary"
                                  size="md"
                                  variant={getCallToActionLabel(application).buttonVariant}
                                >
                                  {getCallToActionLabel(application).buttonLabel}
                                </BaselaneButton>
                              </HStack>
                            </BaselaneResponsiveCell>
                          )}
                        </BaselaneResponsiveTableRow>
                      );
                    }}
                  />
                </Tbody>
              </BaselaneResponsiveTable>
            </Skeleton>
          )}
        </Box>
      </VStack>
    </Tabs>
  );
};

export default TenantScreeningMain;
