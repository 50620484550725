import React from 'react';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import TaxFormsTable from './TaxFormsTable';

const TaxForms = () => {
  return (
    <Box>
      <TaxFormsTable />
      <Outlet />
    </Box>
  );
};

export default TaxForms;
