import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useShallow } from 'zustand/react/shallow';
import { useOutletContext } from 'react-router-dom';
import { ListItem, Stack, Tbody, Text, Thead, UnorderedList } from '@chakra-ui/react';

import {
  BaselaneCardNew,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
} from '@shared/components';
import useCSVImportStore from '@store/CSVImport';
import useCategories from '@features/Category/useCategories';
import { trimAndNormalizeWhitespace } from '@pages/CSVImport/helpers';

import MapCategoryRow from './MapCategoryRow';
import TabPanelLayout from '../components/TabPanelLayout';
import FooterNavigation from '../components/FooterNavigation';

const MapCategories = () => {
  const { categoriesForDropdown, categoriesForDropdownById } = useCategories();
  const { handleFooterNavigation, formikRefs } = useOutletContext();
  const formikRef = formikRefs[2];

  const { categoryListFromCSV, mappedCategories, setMappedCategoryValues } = useCSVImportStore(
    useShallow((state) => ({
      categoryListFromCSV: state.categoryListFromCSV,
      mappedCategories: state.mappedCategories,
      setMappedCategoryValues: state.setMappedCategoryValues,
    }))
  );

  const [initialCategoryData, setInitialCategoryData] = useState(mappedCategories);

  useEffect(() => {
    // map any that match Baselane categories exactly
    const unifiedCategoriesForDropdown = categoriesForDropdown?.reduce((acc, groupOfCategory) => {
      return acc.concat(groupOfCategory.items);
    }, []);

    const uniqueMappedCategories = categoryListFromCSV?.map((categoryCSVName) => {
      const matchedCategory = unifiedCategoriesForDropdown.find(
        (baselaneCategory) =>
          trimAndNormalizeWhitespace(baselaneCategory?.name).toLowerCase() ===
          trimAndNormalizeWhitespace(categoryCSVName).toLowerCase()
      );

      const matchedId = matchedCategory?.id || null;
      const [parentId = null, subId = null] = matchedId ? matchedId.split('-') : [];

      const existingBaselaneCategory = mappedCategories?.find((mappedCategory) => {
        return (
          mappedCategory?.externalCategory === categoryCSVName &&
          mappedCategory?.baselaneCategory !== null
        );
      });

      return {
        externalCategory: categoryCSVName,
        baselaneCategory: mappedCategories
          ? existingBaselaneCategory?.baselaneCategory
          : matchedCategory?.name || null,
        id: categoryCSVName, // used for table row id
        categoryId: mappedCategories
          ? existingBaselaneCategory?.categoryId
          : matchedCategory?.id || null,
        parentId: mappedCategories ? existingBaselaneCategory?.parentId : parentId,
        subId: mappedCategories ? existingBaselaneCategory?.subId : subId,
      };
    });

    setInitialCategoryData(uniqueMappedCategories);
  }, [categoriesForDropdown, categoryListFromCSV]);

  const handleTabFormSubmit = (values) => {
    setMappedCategoryValues(values);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialCategoryData}
      onSubmit={handleTabFormSubmit}
      enableReinitialize
    >
      {({ values, handleSubmit }) => (
        <TabPanelLayout
          stepNumber={3}
          title="Map unrecognized categories (optional)"
          description={
            <Stack spacing={0.5}>
              <Text>
                Some &quot;Category&quot;values in the CSV don’t match Baselane’s categories.
              </Text>
              <UnorderedList spacing={0.5}>
                <ListItem>Select a Baselane category to replace them, or</ListItem>
                <ListItem>
                  Leave it blank, and the original category will be added to the Notes field for
                  reference
                </ListItem>
              </UnorderedList>
            </Stack>
          }
        >
          <BaselaneCardNew maxW="600px" p={0}>
            <BaselaneResponsiveTable
              id="map-categories"
              config={{ columns: [] }}
              tableContainerStyles={{
                overflowY: 'initial',
                overflowX: 'initial',
              }}
            >
              <Thead>
                <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                  <BaselaneResponsiveCellTitle
                    title="CSV category"
                    titleSize="headline-xs"
                    styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                    configIndex={0}
                  />
                  <BaselaneResponsiveCellTitle
                    title="Baselane category"
                    titleSize="headline-xs"
                    styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                    configIndex={1}
                  />
                </BaselaneResponsiveTableRow>
              </Thead>
              <Tbody>
                <BaselaneResponsiveTableRows
                  items={values}
                  customRow
                  renderItem={({ externalCategory }, index) => {
                    return (
                      <MapCategoryRow
                        key={externalCategory}
                        externalCategory={externalCategory}
                        index={index}
                        categoriesForDropdown={categoriesForDropdown}
                        categoriesForDropdownById={categoriesForDropdownById}
                      />
                    );
                  }}
                />
              </Tbody>
            </BaselaneResponsiveTable>
          </BaselaneCardNew>
          <FooterNavigation
            handleFooterNavigation={handleFooterNavigation}
            isValid
            handleSubmit={handleSubmit}
          />
        </TabPanelLayout>
      )}
    </Formik>
  );
};

export default MapCategories;
