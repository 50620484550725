import { gql } from '@apollo/client';

export const GET_PROPERTIES_PROMOTION = gql`
  query Property {
    property {
      id
      name
      address
      units {
        id
        name
        description
      }
    }
  }
`;
