import React from 'react';
import { useFormikContext } from 'formik';
import useBreakPoints from '@core/hooks/useBreakPoints';
import renderAccountDropdownItem from '@shared/components/BaselaneDropdown-new/ReusableDropdowns/AccountDropdown/renderAccountDropdownItem';
import T1WithTitle from '../../Shared/components/BaselaneDropdown-new/T1-Title';

type Props = {
  bankProps: Object,
};

const AccountDropdown = ({ bankProps }: Props) => {
  const { isMax768 } = useBreakPoints();
  const { setFieldValue, values } = useFormikContext();

  const transformBankData = (data) => {
    const result = [
      {
        id: 'baselane_account',
        title: 'Baselane accounts',
        items: [],
      },
      {
        id: 'external_account',
        title: 'External accounts',
        items: [],
      },
    ];

    const allAccounts = data?.flatMap((item) => item.bankAccounts);

    allAccounts?.forEach((account) => {
      const transformedAccount = {
        id: account.id,
        bankName: '',
        account: '',
        accountSubType: account.accountSubType,
        connectionState: account.connectionState,
        stripeBankAccountId: account.stripeBankAccountId,
        bankAccountMetadata: account.bankAccountMetadata,
        isExternal: account.isExternal,
      };

      if (account.isExternal) {
        result[1].items.push({
          ...transformedAccount,
          bankName: account?.nickName || account?.accountName || 'Account',
          account: `${account.accountName} - ${account.accountNumber}`,
        });
      } else {
        result[0].items.push({
          ...transformedAccount,
          bankName: account?.accountName || 'Account',
          account: `${account.nickName} - ${account.accountNumber}`,
        });

        if (account.subAccounts?.length > 0) {
          account.subAccounts.forEach((subAccount) => {
            const transformedSubAccount = {
              id: subAccount.id,
              bankName: '',
              account: '',
              accountSubType: subAccount.accountSubType,
              connectionState: subAccount.connectionState,
              stripeBankAccountId: subAccount.stripeBankAccountId,
              bankAccountMetadata: subAccount.bankAccountMetadata,
              isExternal: subAccount.isExternal,
            };
            result[0].items.push({
              ...transformedSubAccount,
              bankName: subAccount?.accountName || 'Account',
              account: `${subAccount.nickName} - ${subAccount.accountNumber}`,
            });
          });
        }
      }
    });

    result.forEach((category) => {
      category.items.sort((a, b) => a?.bankName?.localeCompare(b?.bankName));
    });

    return result;
  };

  const options = transformBankData(bankProps?.bank);

  const selectedItem = options
    ?.flatMap((option) => option?.items)
    ?.find((item) => item?.id === values?.accountId?.toString());

  return (
    <T1WithTitle
      additionalProps={{ id: 'account-dropdown' }}
      classNames={[
        'input-form-md',
        'auto-width-dropdown',
        'select-auto-width',
        'auto-select-input-width',
        'input-form-md',
        'auto-height',
      ]}
      data={options}
      selectedItem={selectedItem}
      searchTerm={['account', 'bankName']}
      showValueByFields={['bankName', 'account']}
      itemRenderer={renderAccountDropdownItem}
      handleSubmit={(value) => setFieldValue('accountId', value)}
      isMulti={false}
      hasFilterWrapper={false}
      hasCheckboxes={false}
      showDivider={false}
      showTitleInSearch={false}
      hideClearButton
      // parentId={isFromResponsiveMode ? 'drawer-body' : 'page-feature'}
      placeholder="Select account"
      title="account"
      dropdownPosition="bottom"
      isMobile={isMax768}
    />
  );
};

export default AccountDropdown;
