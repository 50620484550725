import React, { useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import stripCurrency from '@core/utils/stripCurrency';
import { BaselaneButton } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { IconArrowRight, IconArrowLeft } from '@icons';
import { footerStyles } from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';
import {
  useTabIndex,
  useSetTabIndex,
  useSetIsPropertyPanelVisible,
  useSetIsTenantPanelVisible,
  useIsPropertyStepValid,
} from '../../leaseAgreementStore';

function LeaseDetailsFormFooter(): any {
  const { isMax768: isMobile } = useBreakPoints();
  const formik = useFormikContext();
  const { submitForm, values } = formik;
  const tabIndex = useTabIndex();
  const setTabIndex = useSetTabIndex();
  const setIsPropertyPanelVisible = useSetIsPropertyPanelVisible();
  const setIsTenantPanelVisible = useSetIsTenantPanelVisible();
  const isPropertyStepValid = useIsPropertyStepValid();
  const handleBackNavigation = () => {
    setIsPropertyPanelVisible(true);
    setTabIndex(0);
  };
  const isFormFilled = () => {
    const { startDate, endDate, monthlyRentAmount, dueDays, isMonthToMonth } = values || {};

    let hasRequiredFields = false;
    if (isMonthToMonth) {
      hasRequiredFields = Boolean(
        startDate && stripCurrency(monthlyRentAmount) > 2 && dueDays !== undefined && dueDays !== ''
      );
    } else {
      hasRequiredFields = Boolean(
        startDate &&
          endDate &&
          stripCurrency(monthlyRentAmount) > 2 &&
          dueDays !== undefined &&
          dueDays !== ''
      );
    }
    return hasRequiredFields;
  };

  const [hasRequiredFields, setHasRequiredFields] = useState(isFormFilled());

  const handleForwardNavigation = () => {
    if (isPropertyStepValid && isFormFilled) {
      submitForm();
      setIsTenantPanelVisible(true);
      setTabIndex(2);
    }
  };
  useEffect(() => {
    setHasRequiredFields(isFormFilled());
  }, [values]);

  return (
    <Flex direction={isMobile ? 'column-reverse' : 'row'} justifyContent="space-between">
      <Flex direction="row" {...footerStyles(isMobile)} marginTop="0px">
        {/* Previous Button */}
        <Box marginRight={1.5} marginTop={tabIndex === 0 ? '0' : 3}>
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size="md"
            leftIcon={<IconArrowLeft />}
            onClick={() => handleBackNavigation()}
          >
            Previous
          </BaselaneButton>
        </Box>

        {/* Continue Button */}
        <Box marginTop={tabIndex === 0 ? '0' : '24px'} width="100%">
          <BaselaneButton
            variant="filled"
            palette="primary"
            size="md"
            onClick={() => handleForwardNavigation()}
            rightIcon={<IconArrowRight />}
            id="continue-la-button"
            isDisabled={!hasRequiredFields}
          >
            Continue
          </BaselaneButton>
        </Box>
      </Flex>
    </Flex>
  );
}

export default LeaseDetailsFormFooter;
