const LENDENCY_ADVANTAGES_PART1 = [
  {
    id: '1',
    text: 'Loan Types: Conventional, DSCR, Fix and Flip, and more',
  },
  {
    id: '2',
    text:
      'All Strategies: We offer financing for long-term rentals, short-term rentals, fix and flip, BRRRR, new construction and portfolio loans',
  },
  {
    id: '3',
    text: 'Available in all states except North and South Dakota',
  },
];
const LENDENCY_ADVANTAGES_PART2 = [
  {
    id: '1',
    text: 'Very competitive rates',
  },
  {
    id: '2',
    text: 'Loan Size: $100K - $3M',
  },
  {
    id: '3',
    text: 'Terms: 10-30 year term fixed rates',
  },
  {
    id: '4',
    text: 'LTV (Loan-to-Value): Up to 80% LTV',
  },
  {
    id: '5',
    text: 'Interest-only options available',
  },
];

export { LENDENCY_ADVANTAGES_PART1, LENDENCY_ADVANTAGES_PART2 };
