import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';
import { IllustrationOtherError1 } from '@illustrations';

type ErrorModalProps = {
  isOpen: Boolean,
  onClose: Function,
};

const ErrorModal = ({ isOpen, onClose }: ErrorModalProps) => {
  const alertCancelRef = useRef(null);

  return (
    <AlertDialog
      leastDestructiveRef={alertCancelRef}
      isOpen={isOpen}
      isCentered
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="sm"
    >
      <AlertDialogOverlay />
      <AlertDialogContent p={4}>
        <AlertDialogCloseButton />
        <Stack spacing={2} mb={3}>
          <IllustrationOtherError1 />
          <Stack spacing={0.25}>
            <Heading size="headline-lg">Something went wrong</Heading>
            <Text textStyle="sm">
              An error occurred while importing transactions. Please try again.
            </Text>
          </Stack>
        </Stack>

        <BaselaneButton size="md" variant="filled" palette="primary" onClick={onClose}>
          Try again
        </BaselaneButton>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ErrorModal;
