import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { LOGIN } from '@routes';
import useInvitationLink from '@pages/SignUpPage/useInvitationLink';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';
import {
  linkStyles,
  signupColumnHeaderContainerStyles,
  signupHeaderLogoStyles,
} from '../styles/signup.styles';
import {
  signUpTestsHeaderStyles,
  signupTestsHeaderContainerStyles,
  signinTestsLinkTextStyles,
} from '../styles/signupTests.styles';

type BaselaneHeaderProps = {
  signUpVariant: String,
};

const BaselaneHeader = ({ signUpVariant }: BaselaneHeaderProps) => {
  const headerStyles = signUpVariant
    ? signupTestsHeaderContainerStyles
    : signupColumnHeaderContainerStyles;

  const { isCollaborator } = useInvitationLink();

  return (
    <Stack {...headerStyles} w="100%">
      <Box {...signupHeaderLogoStyles}>
        <BaselaneFullLogo color="white" />
      </Box>
      {signUpVariant && !isCollaborator && (
        <HStack {...signUpTestsHeaderStyles}>
          <Text display={{ base: 'none', md: 'block' }}>Already have an account?</Text>
          <Text
            {...{
              ...linkStyles,
              ...signinTestsLinkTextStyles,
            }}
          >
            <RouterLink to={LOGIN}>Sign In</RouterLink>
          </Text>
        </HStack>
      )}
    </Stack>
  );
};

export default BaselaneHeader;
