import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, HStack } from '@chakra-ui/react';
import { LEASES_QUICKPAY_LEARN_MORE } from '@routes';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { BaselaneChip, BaselaneBannerNew } from '@shared/components';
import { relativePositionStyling } from '@shared/styles/banner.style';

const QuickPayAnnounceBanner = () => {
  const navigate = useNavigate();
  const { isMin768: isDesktop } = useBreakPoints();

  const title = (
    <HStack>
      <Box>Get QuickPay with Baselane Banking</Box>
      <BaselaneChip variant="simple-primary" label="BETA" size="sm" />
    </HStack>
  );
  const body = 'Receive rent in 2 business days (vs 5 business days).';
  const btnLabel = 'Learn more';

  const onButtonClick = () => {
    sendSegmentEvent('quickpay_click_learn_more');
    navigate({ pathname: LEASES_QUICKPAY_LEARN_MORE });
  };

  const onCloseClick = () => localStorage.setItem('isQuickPayAnnounceBannerClosed', 'true');

  return (
    <BaselaneBannerNew
      title={title}
      body={body}
      btnLabel={btnLabel}
      onButtonClick={onButtonClick}
      onCloseClick={onCloseClick}
      styles={{ ...relativePositionStyling(isDesktop) }}
      isVertical={!isDesktop}
    />
  );
};

export default QuickPayAnnounceBanner;
