import { Box, useMediaQuery } from '@chakra-ui/react';
import { React } from 'react';
import { minWidth } from '@core/hooks/useBreakPoints/helpers/breakpoints.helpers';

type LegendDotProps = {
  innerColor?: string,
  outerColor?: String,
};

const LegendDot = ({
  innerColor = 'red.500A',
  outerColor = 'transparent',
  ...rest
}: LegendDotProps) => {
  const [isMin899] = useMediaQuery(minWidth('lg'), { ssr: false });
  return (
    <Box
      as="span"
      borderRadius="50%"
      borderWidth={isMin899 ? '2px' : '1px'}
      borderStyle="solid"
      w={isMin899 ? '10px' : '5px'}
      h={isMin899 ? '10px' : '5px'}
      borderColor={outerColor}
      bgColor={innerColor}
      display="inline-block"
      mr={1}
      verticalAlign="middle"
      mt="-1px"
      {...rest}
    />
  );
};

export default LegendDot;
