import React, { useContext } from 'react';
import { Stack } from '@chakra-ui/react';

import { BaselaneButtonGroup } from '@shared/components';
import MoveMoneyMenu from '@shared/components/MoveMoneyMenu';
import useBreakPoints from '@core/hooks/useBreakPoints';
import VirtualCardTriggerButton from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/AddVirtualCard/TriggerButton';
import AddAccountTrigger from '@pages/BankEntity/AddAccount/AddAccountTrigger';
import UserAccessContext from '@contexts/UserAccessContext';
import MoreMenu from '@pages/BankEntity/MoreMenu';
import HeaderLeft from './HeaderLeft';

const DesktopPageHeader = () => {
  const { authorizedForBanking } = useContext(UserAccessContext);
  const { isMin1150 } = useBreakPoints();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing="24px"
      flexWrap={!isMin1150 ? 'wrap' : 'nowrap'}
      p="24px 32px 24px 32px"
    >
      <HeaderLeft />
      {authorizedForBanking && (
        <BaselaneButtonGroup>
          <MoveMoneyMenu />
          <AddAccountTrigger size="md" />
          <VirtualCardTriggerButton />
          <MoreMenu />
        </BaselaneButtonGroup>
      )}
    </Stack>
  );
};

export default DesktopPageHeader;
