import React from 'react';
import { Flex, TabPanels, TabPanel, Text, Box, Heading } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import useBreakPoints from '@core/hooks/useBreakPoints';
import {
  BaselaneDivider,
  TabsComponent,
  TabComponent,
  TabListComponent,
  TabNumber,
} from '@shared/components';

import TabIcon from '@shared/components/BaselaneFlow/Tabs/TabComponent/TabIcon';
import {
  tabListStyles,
  tabPanelResponsiveStyles,
  tabsStyles,
  dashStyles,
  panelFormResponsiveStyles,
} from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';
import TenantFormStep from './FormSteps/TenantFormStep';
import PropertyFormStep from './FormSteps/PropertyFormStep';
import LeaseDetailsFormStep from './FormSteps/LeaseDetailsFormStep';
import ReviewOrderStep from './FormSteps/ReviewOrderStep';
import CreateLeaseAgreementStep from './FormSteps/CreateLeaseAgreementStep';
import { menuOptions } from '../helpers/menuState';
import TenantFormFooter from './Footer/TenantFormFooter';
import SelectPropertyFooter from './Footer/SelectProperyFooter';
import LeaseDetailsFormFooter from './Footer/LeaseDetailsFormFooter';
import ReviewLeaseFooter from './Footer/ReviewLeaseFooter';
import {
  useTabIndex,
  useSetTabIndex,
  useIsPropertyPanelVisible,
  useIsLeaseTermPanelVisible,
  useIsTenantPanelVisible,
  useIsReviewLeasePanelVisible,
  useIsDraftLeasePanelVisible,
  useSetIsPropertyPanelVisible,
  useSetIsLeaseTermPanelVisible,
  useSetIsTenantPanelVisible,
  useSetIsReviewLeasePanelVisible,
  useSetIsDraftLeasePanelVisible,
  useIsUnitStepValid,
  useIsPropertyStepValid,
  useIsLeaseTermStepValid,
  useIsTenantStepValid,
  useIsReviewLeaseStepValid,
  useIsDraftLeaseStepValid,
  useSelectedPropertyObject,
  useIsAddingNewPropertyPanelVisible,
  useTotalSteps,
} from '../leaseAgreementStore';

function LeaseAgreementFormTabs() {
  const { isMax576, isMax768 } = useBreakPoints();
  // Zustand Store values
  const tabIndex = useTabIndex();
  const totalSteps = useTotalSteps();
  const setTabIndex = useSetTabIndex();
  const selectedPropertyObject = useSelectedPropertyObject();
  const isUnitStepValid = useIsUnitStepValid();
  const isPropertyStepValid = useIsPropertyStepValid();
  const isLeaseTermStepValid = useIsLeaseTermStepValid();
  const isTenantStepValid = useIsTenantStepValid();
  const isReviewLeaseStepValid = useIsReviewLeaseStepValid();
  const isDraftLeaseStepValid = useIsDraftLeaseStepValid();
  const isPropertyPanelVisible = useIsPropertyPanelVisible();
  const isLeaseTermPanelVisible = useIsLeaseTermPanelVisible();
  const isTenantPanelVisible = useIsTenantPanelVisible();
  const isReviewLeasePanelVisible = useIsReviewLeasePanelVisible();
  const isDraftLeasePanelVisible = useIsDraftLeasePanelVisible();
  const setIsPropertyPanelVisible = useSetIsPropertyPanelVisible();
  const setIsLeaseTermPanelVisible = useSetIsLeaseTermPanelVisible();
  const setIsTenantPanelVisible = useSetIsTenantPanelVisible();
  const setIsReviewLeasePanelVisible = useSetIsReviewLeasePanelVisible();
  const setIsDraftLeasePanelVisible = useSetIsDraftLeasePanelVisible();
  const isAddingNewPropertyPanelVisible = useIsAddingNewPropertyPanelVisible();
  const handleTabChange = (i: number) => {
    setTabIndex(Number(i));
    if (i === 0) {
      setIsPropertyPanelVisible(true);
    } else if (i === 1 && isPropertyStepValid) {
      setIsLeaseTermPanelVisible(true);
    } else if (i === 2 && isLeaseTermStepValid) {
      setIsTenantPanelVisible(true);
    } else if (i === 3 && isTenantStepValid) {
      setIsReviewLeasePanelVisible(true);
    } else if (i === 4 && isReviewLeaseStepValid) {
      setIsDraftLeasePanelVisible(true);
    }
  };

  const stepsValidationValues = {
    selectedPropertyObject,
    isUnitStepValid,
    isPropertyStepValid,
    isLeaseTermStepValid,
    isTenantStepValid,
    isReviewLeaseStepValid,
    isDraftLeaseStepValid,
  };

  const renderTabPanels = () => {
    return menuOptions({ ...stepsValidationValues }).map((option, index) => {
      return (
        <TabPanel key={option.id} {...tabPanelResponsiveStyles(isMax576, isMax768)}>
          {isPropertyPanelVisible && tabIndex === 0 && (
            <>
              <PropertyFormStep />
              <Box {...panelFormResponsiveStyles(isMax768)}>
                {!isAddingNewPropertyPanelVisible && <SelectPropertyFooter />}
              </Box>
            </>
          )}
          {isLeaseTermPanelVisible && tabIndex === 1 && (
            <>
              <LeaseDetailsFormStep />
              <Box {...panelFormResponsiveStyles(isMax768)}>
                <LeaseDetailsFormFooter />
              </Box>
            </>
          )}
          {isTenantPanelVisible && tabIndex === 2 && (
            <>
              <TenantFormStep />
              <Box {...panelFormResponsiveStyles(isMax768)}>
                <TenantFormFooter />
              </Box>
            </>
          )}
          {isReviewLeasePanelVisible && tabIndex === 3 && (
            <>
              <ReviewOrderStep />
              <Box {...panelFormResponsiveStyles(isMax768)}>
                <ReviewLeaseFooter />
              </Box>
            </>
          )}
          {isDraftLeasePanelVisible && tabIndex === 4 && <CreateLeaseAgreementStep />}
        </TabPanel>
      );
    });
  };

  return (
    <TabsComponent
      {...{
        tabIndex,
        styles: tabsStyles,
        handleTabChange,
      }}
      styles={{ minHeight: 'unset', height: '100%', p: 0 }}
    >
      {!isMax768 && (
        <TabListComponent styles={tabListStyles} isSticky>
          {menuOptions({ ...stepsValidationValues }).map((option, index) => (
            <TabComponent
              key={option.id}
              showDisabledStyles={option.isDisabled}
              isValidEnabled={option.isValid}
            >
              {option.isValid && (
                <TabIcon
                  menuOptions={menuOptions({ ...stepsValidationValues })}
                  option={option}
                  index={index}
                  tabIndex={tabIndex}
                  showGreenCircle={option.isValid}
                />
              )}
              {!option.isValid && (
                <TabNumber
                  menuOptions={menuOptions({ ...stepsValidationValues })}
                  option={option}
                  index={index}
                />
              )}
              {isMax768 && index < 5 && <BaselaneDivider styles={{ ...dashStyles }} />}
              {!isMax768 && <Text ml="8px">{option.label}</Text>}
            </TabComponent>
          ))}
        </TabListComponent>
      )}

      <Flex direction="column" justifyContent="left" position="relative">
        <Box
          position="sticky"
          top="0"
          left="0"
          right="0"
          bg="white"
          zIndex="sticky"
          paddingLeft={isMax768 ? '25px' : '42px'}
          paddingTop="45px"
        >
          <Heading size="headline-xs" color="brand.neutral.600">
            {`STEP ${tabIndex + 1} of ${totalSteps}`}
          </Heading>
        </Box>
        <TabPanels>{renderTabPanels()}</TabPanels>
      </Flex>
      <Outlet />
    </TabsComponent>
  );
}

export default LeaseAgreementFormTabs;
