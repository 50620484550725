export const FEATURE_GATES = {
  AUTO_TAGGING_GATE: 'auto-tagging-gate',
  BANKING_FEATURE_ANNOUNCEMENT_V1: 'banking_feature_announcement_v1',
  CHECK_PAYMENT_GATE: 'check_payment_gate',
  CHECKBOOK_GATE: 'checkbook_gate',
  CSV_GATE: 'csv_gate',
  CUSTOMERIO_INTEGRATION_GATE: 'customerio-integration-gate',
  // new feature flag for daily late fee
  DAILY_LATE_FEE_GATE: 'daily-late-fee-gate',
  DASHBOARD_GATE: 'dashboard-gate',
  FINISH_LATER_SURVEY_GATE: 'finish-later-survey-gate',
  HIDE_ZENDESK_BUBBLE: 'hide_zendesk_bubble',
  LATCHEL_GATE: 'latchel-gate',
  MUA_GATE: 'mua',
  ONBOARDING_ADD_PROPERTY_GATE: 'onboarding-add-property-gate',
  ONBOARDING_LEARNING_USER_NEEDS_QUESTION: 'onboarding-learning-user-needs-question',
  ONE_TIME_SCHEDULED_PAYMENT_GATE: 'one_time_scheduled_payment_gate',
  PARTNERSHIPS_PAGE: 'partnerships-page',
  PROPERTY_METRICS_PAGE_GATE: 'property_metrics_page_gate',
  QUICK_PAY_ANNOUNCE_BANNER_GATE: 'quick_pay_announce_banner_gate',
  RECURRING_SCHEDULED_PAYMENT_GATE: 'recurring_scheduled_payment_gate',
  RECURRING_SCHEDULED_TRANSFERS_GATE: 'recurring_scheduled_transfers_gate',
  TENANT_SCREENING_GATE: 'tenant-screening-gate',
  TRANSFERS_PAYMENTS_PAGE: 'transfers_payments_page',
  ONBOARDING_ENTITY_QUESTION: 'onboarding-entity-question',
  LEASE_AGREEMENT_PAGE: 'lease-agreement-page-gate',
  CASHFLOW_BY_PROPERTY_UNIT: 'cashflow-by-property-unit-gate',
};

export const EXPERIMENTS = {
  SIGN_UP_PAGE_EXPERIMENT_2024_07: '2024-07_sign_up_page_experiment',
  RC_ADD_BANK_ACCOUNT_TO_COLLECT_RENT_EXPERIMENT: 'rc-add-bank-account-to-collect-rent',
};

export const DYNAMIC_CONFIGS = {
  APY_INTEREST: 'apy_interest',
  CSV_IMPORT_FIELD_NAMES: 'csv_import_field_names',
};
