import React from 'react';
import moment from 'moment';
import { Text } from '@chakra-ui/react';

import {
  Icon12Check,
  Icon12InProgress,
  Icon12Overdue,
  Icon12Calendar,
  Icon12Close,
} from '@icons/12px';
import { BaselaneChip } from '@shared/components';
import formatCurrency from '@core/utils/formatCurrency';
import getPluralizedNoun from '@core/utils/getPluralizedNoun';

import { getBottomPartStyles } from '../styles/paymentSummary.styles';

const getCurrencyDecimalSeparated = (amount, isMinXL) => {
  const [beforeDecimal, afterDecimal] = amount.split('.');
  const bottomPartStyles = getBottomPartStyles(isMinXL);

  return (
    <>
      <Text as="span" {...bottomPartStyles.amount.beforedecimal}>{`${beforeDecimal}.`}</Text>
      <Text as="span" {...bottomPartStyles.amount.afterdecimal}>
        {afterDecimal}
      </Text>
    </>
  );
};

export const getFormattedPaymentSummary = (summaryItem, isMinXL) => {
  const { state, amount, count } = summaryItem ?? {};

  const formattedAmount = formatCurrency(amount).inDollars;
  const decimalSeparatedAmount = getCurrencyDecimalSeparated(formattedAmount, isMinXL);
  const formattedNumInvoice = getPluralizedNoun(count, 'invoice');

  switch (state) {
    case 'PAYMENT_COMPLETE':
      return {
        chip: (
          <BaselaneChip
            label="Completed"
            size="sm"
            leftIcon={Icon12Check}
            variant="simple-success"
          />
        ),
        text: 'Completed',
        icon: Icon12Check,
        timeStatus: 'This month',
        amount: decimalSeparatedAmount,
        formattedAmount,
        count: formattedNumInvoice,
        filters: { state, dateType: 'PAYMENT_DATE', date: 'THIS_MONTH' },
        total: { amount, count },
        segmentEvent: 'completed',
        input: {
          state,
          dateType: 'PAYMENT_DATE',
          toDate: moment().endOf('month').format('YYYY-MM-DD'),
          fromDate: moment().startOf('month').format('YYYY-MM-DD'),
        },
      };

    case 'PAYMENT_PROCESSING':
      return {
        chip: (
          <BaselaneChip
            label="Processing"
            size="sm"
            leftIcon={Icon12InProgress}
            variant="simple-primary"
          />
        ),
        text: 'Processing',
        icon: Icon12InProgress,
        timeStatus: 'All time',
        amount: decimalSeparatedAmount,
        formattedAmount,
        count: formattedNumInvoice,
        filters: { state, dateType: 'PAYMENT_DATE', date: 'ALL' },
        total: { amount, count },
        segmentEvent: 'processing',
        input: {
          state,
          dateType: 'PAYMENT_DATE',
        },
      };

    case 'PAYMENT_OUTSTANDING':
      return {
        chip: (
          <BaselaneChip
            label="Upcoming"
            size="sm"
            leftIcon={Icon12Calendar}
            iconColor="brand.neutral.500"
          />
        ),
        text: 'Upcoming',
        icon: Icon12Calendar,
        timeStatus: 'This month',
        amount: decimalSeparatedAmount,
        formattedAmount,
        count: formattedNumInvoice,
        filters: { state, dateType: 'DUE_DATE', date: 'THIS_MONTH' },
        total: { amount, count },
        segmentEvent: 'unpaid',
        input: {
          state,
          dateType: 'DUE_DATE',
          toDate: moment().endOf('month').format('YYYY-MM-DD'),
          fromDate: moment().startOf('month').format('YYYY-MM-DD'),
        },
      };

    case 'PAYMENT_OVERDUE':
      return {
        chip: (
          <BaselaneChip
            label="Overdue"
            size="sm"
            leftIcon={Icon12Overdue}
            variant="simple-danger"
          />
        ),
        text: 'Overdue',
        icon: Icon12Overdue,
        timeStatus: 'All time',
        amount: decimalSeparatedAmount,
        formattedAmount,
        count: formattedNumInvoice,
        filters: { state, dateType: 'DUE_DATE', date: 'ALL' },
        total: { amount, count },
        segmentEvent: 'overdue',
        input: {
          state,
          dateType: 'DUE_DATE',
        },
      };

    case 'PAYMENT_FAILED':
      return {
        chip: (
          <BaselaneChip label="Failed" size="sm" leftIcon={Icon12Close} variant="simple-danger" />
        ),
        text: 'Failed',
        icon: Icon12Close,
        timeStatus: 'All time', // Not being displayed in UI
        amount: formatCurrency(amount).inDollars,
        formattedAmount,
        count: getPluralizedNoun(count, 'Failed payment'),
        filters: { state, dateType: 'DUE_DATE', date: 'ALL' },
        total: { amount, count },
        segmentEvent: 'failed',
        input: {
          state,
          dateType: 'DUE_DATE',
        },
      };

    default:
      return {};
  }
};
