import React from 'react';
import { Stack, Text, Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { BaselaneAlertNew } from '@shared/components';
import { Icon16ChevronRight, Icon16Scheduled } from '@icons/16px';
import { TRANSFERS_PAYMENTS, TRANSFERS_SCHEDULED } from '@routes';
import getPluralizedNoun from '@core/utils/getPluralizedNoun';
import useBreakPoints from '@core/hooks/useBreakPoints';

const ScheduledPaymentsAlertBanner = ({
  numberOfScheduledPayments,
}: {
  numberOfScheduledPayments: number,
}) => {
  const { isMin768 } = useBreakPoints();

  const navigate = useNavigate();

  return (
    <Box mt={1}>
      <BaselaneAlertNew
        variant="neutral"
        visual="icon"
        iconName={Icon16Scheduled}
        isVertical={!isMin768}
        actionProps={{
          actionType: 'button',
          primary: {
            label: 'View all',
            size: 'xs',
            onClick: () => navigate({ pathname: `/${TRANSFERS_PAYMENTS}/${TRANSFERS_SCHEDULED}` }),
            styles: {
              variant: 'transparent',
              palette: 'neutral',
              rightIcon: <Icon16ChevronRight />,
              pullRight: true,
            },
          },
        }}
        body={
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Text textStyle="sm-heavy" textAlign="left">
              {getPluralizedNoun(numberOfScheduledPayments, 'scheduled payment')} this week
            </Text>
          </Stack>
        }
      />
    </Box>
  );
};

export default ScheduledPaymentsAlertBanner;
