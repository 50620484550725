import React from 'react';
import { Heading, Skeleton, Stack, Text } from '@chakra-ui/react';

import formatCurrency from '@core/utils/formatCurrency';
import getPluralizedNoun from '@core/utils/getPluralizedNoun';

import { getTotalNumberOfAccounts } from '../helper';

type HeaderInfoProps = {
  bankSummary: Object,
  totalNumberOfCards: number,
  bankDataLoading: boolean,
};

const HeaderInfo = ({ bankSummary, totalNumberOfCards, bankDataLoading }: HeaderInfoProps) => {
  const { baselaneBankBalance = 0, bank: banks = [] } = bankSummary;

  return (
    <Stack spacing={0}>
      <Stack direction="row" justifyContent="space-between">
        <Heading size="headline-xl">Baselane Banking</Heading>
        <Skeleton isLoaded={!bankDataLoading}>
          {banks.length < 6 && (
            <Text textStyle="data-xl">{formatCurrency(baselaneBankBalance).inDollars}</Text>
          )}
        </Skeleton>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Skeleton isLoaded={!bankDataLoading}>
          {banks.length < 6 && (
            <Text textStyle="sm">
              {getPluralizedNoun(getTotalNumberOfAccounts(banks), 'account')} &#x2022;{' '}
              {getPluralizedNoun(totalNumberOfCards, 'card')}
            </Text>
          )}
        </Skeleton>
        {banks.length < 6 && <Text textStyle="sm">Total balance</Text>}
      </Stack>
    </Stack>
  );
};

export default HeaderInfo;
