import React, { useCallback, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { BaselaneButton } from '@core/components/Shared/components';
import { Icon16ChevronRight, Icon16ChevronLeft } from '@icons/16px';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import useBankSummary from '@core/hooks/useBankSummary';
import steps from '../steps';
import TransferScheduleFooter from './TransferScheduleFooter';
import ReviewAndTransferFooter from './ReviewAndTransferFooter';

type TransferFundsDrawerFooterProps = {
  currentStep: Number,
  goToStep: Function,
  isLoading: Boolean,
  requireOtp: Boolean,
  setRequireOtp: Function,
};

const TransferFundsDrawerFooter = ({
  currentStep,
  isLoading,
  goToStep,
  requireOtp = false,
  setRequireOtp,
}: TransferFundsDrawerFooterProps) => {
  const navigate = useNavigate();
  const { values, errors, resetForm } = useFormikContext();
  const { bankAccountsById } = useBankAccountsActive({
    filterBySubType: ['checking', 'savings'],
  });
  const { bankAccountsById: manualAccountsById } = useBankSummary({
    variables: {
      isManualAccount: true,
    },
  });
  const closeDrawer = () => {
    resetForm();
    navigate('..', { relative: 'path' });
  };

  const getAccountType = useCallback(
    (account) => {
      if (account.id in manualAccountsById) {
        return 'Manual';
      }

      if (account?.isExternal) {
        return 'External';
      }

      return 'Baselane';
    },
    [manualAccountsById]
  );

  const resolveNextStep = () => {
    switch (currentStep) {
      case steps.TRANSFER_DETAILS:
        return (
          <Box display="flex" gap={2} width="100%">
            <BaselaneButton variant="outline" palette="neutral" size="md" onClick={closeDrawer}>
              Cancel
            </BaselaneButton>
            <BaselaneButton
              isFullWidth
              variant="filled"
              palette="primary"
              size="md"
              rightIcon={<Icon16ChevronRight />}
              isDisabled={
                !values.amount ||
                !values.transferFromAccountId ||
                !values.depositToAccountId ||
                Object.keys(errors).length > 0
              }
              onClick={() => {
                const transferFromAccount = bankAccountsById[values.transferFromAccountId];
                const depositToAccount = bankAccountsById[values.depositToAccountId];

                sendSegmentEvent('baselane_banking_transfer_funds_step_1_complete', {
                  from_account_type: getAccountType(transferFromAccount),
                  to_account_type: getAccountType(depositToAccount),
                  amount: values.amount,
                });
                goToStep(steps.TRANSFER_SCHEDULE);
              }}
            >
              Next
            </BaselaneButton>
          </Box>
        );

      case steps.TRANSFER_SCHEDULE:
        return <TransferScheduleFooter goToStep={goToStep} />;

      case steps.BOOKKEEPING_DETAILS:
        return (
          <Box display="flex" gap={2} width="100%">
            <BaselaneButton
              variant="outline"
              palette="neutral"
              size="md"
              onClick={() => goToStep(steps.TRANSFER_SCHEDULE)}
              leftIcon={<Icon16ChevronLeft />}
            >
              Back
            </BaselaneButton>
            <BaselaneButton
              id="transfer-funds-next-button"
              isFullWidth
              variant="filled"
              palette="primary"
              size="md"
              rightIcon={<Icon16ChevronRight />}
              onClick={() => {
                sendSegmentEvent('baselane_banking_transfer_funds_step_3_complete', {
                  payment_frequency: values.transferType,
                });
                goToStep(steps.REVIEW_AND_TRANSFER);
              }}
            >
              Next
            </BaselaneButton>
          </Box>
        );

      case steps.REVIEW_AND_TRANSFER:
        return (
          <ReviewAndTransferFooter
            goToStep={goToStep}
            isLoading={isLoading}
            requireOtp={requireOtp}
            setRequireOtp={setRequireOtp}
          />
        );

      case steps.SUCCESS:
        return (
          <Box display="flex" gap={2} width="100%">
            <BaselaneButton variant="outline" palette="neutral" size="md" onClick={closeDrawer}>
              Done
            </BaselaneButton>
            <BaselaneButton
              isFullWidth
              variant="tonal"
              palette="primary"
              size="md"
              onClick={() => {
                resetForm();
                goToStep(steps.TRANSFER_DETAILS);
              }}
            >
              Make another transfer
            </BaselaneButton>
          </Box>
        );

      default:
        return (
          <BaselaneButton variant="outline" onClick={closeDrawer} leftIcon={<Icon16ChevronLeft />}>
            Back
          </BaselaneButton>
        );
    }
  };

  const footer = useMemo(() => resolveNextStep(), [currentStep, values, errors, isLoading]);

  return footer;
};

export default TransferFundsDrawerFooter;
