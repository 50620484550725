export const errorMessages = {
  40: 'Error on phone confirmation. Please try again later',
  90: 'Invalid phone number.',
  80: 'reCAPTCHA was invalid or expired. Please try again.',
  100: 'The code entered is invalid. Please try again.',
  101: 'VOIP numbers are not allowed',
  102: 'Only US numbers are allowed',
  103: 'This number is already in use. Please contact support to reuse this number.',
  200: 'Unable to send verification. Please try to resend or try again later.',
  400: 'Please try again and check to see if the right code is entered.',
  401: 'User can only be linked to one identity for the given provider.',
  402: 'This credential is already associated with a different user account.',
  403: 'To resend a new verification code, please wait 60 seconds',
  500: 'Something went wrong :( Please try again later or contact customer support.',
  501: 'Too many attempts. Please try again later.',
};

export const otpOperations = {
  REGISTER_PHONE_NUMBER: 'REGISTER_PHONE_NUMBER',
  INVITE_COLLABORATOR: 'INVITE_COLLABORATOR',
};
