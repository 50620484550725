// @flow
import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import EmptyStateExternal from '@pages/ExternalAccountsPage/components/MyAccountsPanel/components/ExternalAccounts/components/EmptyState';
import EmptyStateBaselane from '@core/components/NativeBankingPage/MainContent/components/Accounts/BaselaneAccounts/components/EmptyState';
import {
  menuContainerStyles,
  menuInnerContainerStyles,
  noBankAccountsHeaderStyles,
} from './styles/addAccountMenu.styles';

type AddAccountMenuProps = {
  isGetStarted?: Boolean,
  getStartedConfig?: Object,
};

function AddAccountMenu({ isGetStarted, getStartedConfig }: AddAccountMenuProps) {
  const pageTitle = 'Let’s Set Up Your Bank Account';

  return (
    <Box {...menuContainerStyles}>
      <Flex {...menuInnerContainerStyles}>
        <Text {...noBankAccountsHeaderStyles}>{pageTitle}</Text>
        <EmptyStateBaselane
          isGetStarted={isGetStarted}
          getStartedConfig={getStartedConfig}
          skipTitle
        />
        <EmptyStateExternal skipTitle hasNoAccounts />
      </Flex>
    </Box>
  );
}

AddAccountMenu.defaultProps = {
  isGetStarted: false,
  getStartedConfig: {},
};

export default AddAccountMenu;
