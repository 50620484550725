import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { Icon16Accounts } from '@icons/16px';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { truncatedText } from '@shared/styles/text.style';
import Placeholder from '../Placeholders/Placeholder';
import DisplayInputWrapperWithRightButton from '../DisplayInputWrapperWithRightButton';

type DisplayInputShowingSelectedNumberAccountProps = {
  selectedOptions: Array<Object>,
  handleContentRendererClick: Function,
  placeholder: string,
  title: string,
  handleClearClick: Function,
  classNames: Array<string>,
};

const DisplayInputShowingSelectedNumberAccount = ({
  selectedOptions,
  handleContentRendererClick,
  placeholder,
  title,
  handleClearClick,
  classNames,
}: DisplayInputShowingSelectedNumberAccountProps) => {
  const { isMin1150 } = useBreakPoints();

  return (
    <DisplayInputWrapperWithRightButton
      handleContentRendererClick={handleContentRendererClick}
      handleClearClick={handleClearClick}
      selectedOptions={selectedOptions}
      classNames={classNames}
    >
      {selectedOptions?.length === 0 || !selectedOptions ? (
        <Placeholder placeholder={placeholder} title={title}>
          {isMin1150 && <Icon16Accounts />}
        </Placeholder>
      ) : (
        <HStack color="brand.neutral.500" overflow="hidden">
          {isMin1150 && (
            <Box>
              <Icon16Accounts />
            </Box>
          )}

          <Text color="brand.neutral.900" {...truncatedText}>
            {selectedOptions.length || 0} selected
          </Text>
        </HStack>
      )}
    </DisplayInputWrapperWithRightButton>
  );
};

export default DisplayInputShowingSelectedNumberAccount;
