import React, { useEffect, useState } from 'react';
import { FieldArray } from 'formik';
import { Box, HStack, Input, List, ListItem, Text, VStack } from '@chakra-ui/react';
import { Icon16PlusCircle } from '@icons/16px';
import { IconDelete } from '@icons';
import { BaselaneButton, BaselaneButtonIcon, BaselaneTooltip } from '@shared/components';
import { formLabelStyles } from '@shared/styles/input.style';
import {
  formInputStyles,
  formErrorStyles,
  formErrorMessagePosition,
} from '../../../styles/form.style';

type MultipleUnitsProps = {
  values: Object,
  errors: Object,
  touched: Object,
  isValid: boolean,
  dirty: boolean,
  handleChange: Function,
  isDirty: boolean,
  setIsDirty: Function,
  setIsValid: Function,
  setFormVariables: Function,
  setFieldValue: Function,
  setFieldTouched: Function,
};

function MultipleUnits({
  values,
  errors,
  touched,
  isValid,
  dirty,
  handleChange,
  isDirty,
  setIsDirty,
  setIsValid,
  setFormVariables,
  setFieldValue,
  setFieldTouched,
}: MultipleUnitsProps) {
  const [hasPressedDelete, setHasPressedDelete] = useState(false);

  useEffect(() => {
    setFormVariables({ unitNames: values.unitNames });
    setIsValid(isValid);
    setIsDirty({ ...isDirty, ...{ details: dirty } });
  }, [values.unitNames]);

  const deletePropertyTooltip = (
    <Box>
      <Text textStyle="xs" mb="8px">
        To delete the unit,
      </Text>
      <List spacing="20px" mb="24px" ml="4px">
        <ListItem display="flex" gap="8px" mb="-16px">
          <Box as="span" mt="-2px">
            ·
          </Box>
          <Text textStyle="xs" color="brand.white.200" lineHeight="16px">
            End and archive the active rent collection(s)
          </Text>
        </ListItem>
        <ListItem display="flex" gap="8px" mb="-16px">
          <Box as="span" mt="-2px">
            ·
          </Box>
          <Text textStyle="xs" color="brand.white.200" lineHeight="16px">
            Delete the draft rent collection(s)
          </Text>
        </ListItem>
        <ListItem display="flex" gap="8px" mb="-16px">
          <Box as="span" mt="-2px">
            ·
          </Box>
          <Text textStyle="xs" color="brand.white.200" lineHeight="16px">
            Stop accepting applications for the active tenant screening(s)
          </Text>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <FieldArray name="unitNames">
      {({ remove, push }) => (
        <Box>
          <VStack align="left">
            <Text {...formLabelStyles.xs}>Unit Number or Name</Text>
          </VStack>

          {values.unitType === 'multiple' &&
            values.unitNames.length > 0 &&
            values.unitNames.map((unitName, i) => {
              const isDeleteDisabled =
                unitName.hasLease ||
                (unitName.id && !unitName.isUnitDeletable) ||
                values.unitNames.length === 1;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <HStack key={i} py="8px">
                  <Input
                    {...formInputStyles}
                    id={`unitNames.${i}.name`}
                    name={`unitNames.${i}.name`}
                    placeholder={i < 2 ? `e.g. Unit ${i === 0 ? 'A' : 'B'}` : ''} // Show placeholder only for the first couple
                    value={unitName.name}
                    onChange={(e) => {
                      setFieldValue(`unitNames.${i}.name`, unitName.name);
                      setFieldTouched(`unitNames`, true);
                      handleChange(e);
                    }}
                  />
                  <BaselaneTooltip
                    label={deletePropertyTooltip}
                    styles={{ isDisabled: !isDeleteDisabled, isOpen: undefined }}
                  >
                    <BaselaneButtonIcon
                      id={`unit-${i}-delete-buttoun`}
                      variant="outline"
                      palette="neutral"
                      isDisabled={isDeleteDisabled}
                      icon={<IconDelete />}
                      onClick={() => {
                        setHasPressedDelete(true);
                        remove(i);
                      }}
                    />
                  </BaselaneTooltip>
                </HStack>
              );
            })}

          <Text {...formErrorStyles} {...formErrorMessagePosition} right="0">
            {(errors.unitNames || touched.unitNames) && <Box as="span">{errors.unitNames}</Box>}
          </Text>
          <HStack pt="8px" position="relative">
            (
            <BaselaneButton
              leftIcon={<Icon16PlusCircle />}
              variant="transparent"
              palette="primary"
              pullLeft
              size="md"
              onClick={() => {
                push({
                  name:
                    values.unitNames.length < 26 && !hasPressedDelete
                      ? `Unit ${String.fromCharCode(65 + values.unitNames.length)}`
                      : '',
                });
              }}
              isDisabled={errors.unitNames}
            >
              Add another Unit
            </BaselaneButton>
            )
          </HStack>
        </Box>
      )}
    </FieldArray>
  );
}

export default MultipleUnits;
