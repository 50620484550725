// @flow
import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Grid,
  GridItem,
  HStack,
  Spacer,
  Text,
} from '@chakra-ui/react';
import IconChevronDown from '@icons/manual/IconChevronDown';
import IconChevronUp from '@icons/manual/IconChevronUp';
import IconCircle from '@icons/manual/IconCircle';
import IconCircleOutline from '@icons/manual/IconCircleOutline';
import IconCircleDashed from '@icons/manual/IconCircleDashed';

import formatUTCToLocalDate from '@core/utils/formatUTCToLocalDate';
import {
  invoiceTimelineStyles,
  accordionStyles,
  accordionItemStyles,
  accordionButtonStyles,
  gridStyles,
  timeStyles,
  dotStyles,
  mainContentStyles,
  connectorStyles,
} from './styles/invoiceTimeline.styles';

type BaselaneTimelineProps = {
  timelineSteps: Array<Object>,
  defaultIndex: Array<Number>,
};

function BaselaneTimeline({ timelineSteps, defaultIndex = [-1] }: BaselaneTimelineProps) {
  return (
    <Box {...invoiceTimelineStyles}>
      <Accordion {...accordionStyles} {...{ defaultIndex, allowMultiple: true }}>
        {timelineSteps?.map((step) => {
          const {
            key,
            date,
            title,
            isExpandable,
            isImportant,
            isError,
            showSolidConnector,
            hideConnector,
            dotStatus,
            description,
          } = step ?? {};

          let dotIcon;
          if (dotStatus.showCircleDot) {
            dotIcon = <IconCircle color={isError ? '#C93A3A' : '#257ED0'} />;
          } else if (dotStatus.showOutlineDot) {
            dotIcon = <IconCircleOutline w={8} h={8} color={isError ? '#C93A3A' : '#257ED0'} />;
          } else if (dotStatus.showDashedDot) {
            dotIcon = <IconCircleDashed />;
          }

          return (
            <AccordionItem {...accordionItemStyles} key={key}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton {...accordionButtonStyles(isExpandable)}>
                    <Grid {...gridStyles}>
                      <GridItem {...timeStyles.container}>
                        <Text {...timeStyles.text}>{date && formatUTCToLocalDate(date)}</Text>
                      </GridItem>
                      <GridItem {...dotStyles.container}>{dotIcon}</GridItem>
                      <GridItem {...mainContentStyles.container}>
                        <HStack {...mainContentStyles.collapsed}>
                          <Box
                            {...mainContentStyles.title({
                              dotStatus,
                              isImportant,
                              isExpanded,
                              isError,
                            })}
                          >
                            {title}
                          </Box>
                          {isExpandable && (
                            <>
                              <Spacer />
                              <Box alignSelf="center">
                                {isExpanded ? (
                                  <IconChevronUp w={8} h={5} color="#3A4B5B" />
                                ) : (
                                  <IconChevronDown w={8} h={5} color="#3A4B5B" />
                                )}
                              </Box>
                            </>
                          )}
                        </HStack>
                        {isExpandable && (
                          <AccordionPanel {...mainContentStyles.expanded}>
                            <Box {...mainContentStyles.description}>{description}</Box>
                          </AccordionPanel>
                        )}
                      </GridItem>
                    </Grid>
                  </AccordionButton>

                  <Box {...connectorStyles(showSolidConnector, hideConnector)} />
                </>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
    </Box>
  );
}

export default BaselaneTimeline;
