import { gql } from '@apollo/client';

export const GET_CURRENT_WORKSPACE = gql`
  query currentWorkspace {
    currentWorkspace {
      id
      collaborators {
        id
        firstName
        lastName
        email
        lastLoggedIn
        status
        revokedBy
        authorizedForBanking
        note
        isOwner
      }
      name
      user {
        userMetadata {
          id
          referralLink
          propertyDocumentConfig
          metadata
          bankingTracker {
            name
            order
            state
          }
          rentCollectionTracker {
            name
            order
            state
          }
          bookkeepingTracker {
            name
            order
            state
          }
          bankMigrationState
          bankMigrationConsent
          bankMigrationConsentTimestamp
          dapyMigrationState
          serverTime
          onboardingSurvey
          invoiceProcessingFeePaidBy
        }
        id
        userKycStatus
        userKyc {
          alloyEntityId
          vouchedToken
          vouchedKycStatus
        }
        businessProfile
      }
    }
  }
`;

export const GET_USER_ACCESSIBLE_WORKSPACE = gql`
  query userAccessibleWorkspace {
    userAccessibleWorkspace {
      id
      internalUserId
      name
      email
      isOwner
      isActive
      authorizedForBanking
    }
  }
`;

export const ACCESS_WORKSPACE = gql`
  mutation accessWorkspace($workspaceId: Float!) {
    accessWorkspace(workspaceId: $workspaceId) {
      id
      user {
        id
      }
      collaborators {
        id
        firstName
        lastName
        email
        lastLoggedIn
        status
        revokedBy
        authorizedForBanking
        note
        isOwner
      }
      name
    }
  }
`;

export const UPDATE_USER_WORKSPACE = gql`
  mutation updateUserWorkspace($workspaceName: String!, $id: Float!) {
    updateUserWorkspace(workspaceName: $workspaceName, id: $id) {
      id
      name
    }
  }
`;

export const INVITE_COLLABORATOR = gql`
  mutation inviteCollaborator($input: CreateUserCollaboratorInput!) {
    inviteCollaborator(input: $input) {
      id
      firstName
      lastName
      email
      lastLoggedIn
      status
      revokedBy
      authorizedForBanking
      note
      isOwner
    }
  }
`;

export const LEAVE_WORKSPACE = gql`
  mutation leaveWorkspace($workspaceId: Float!) {
    leaveWorkspace(workspaceId: $workspaceId) {
      id
      user {
        id
      }
    }
  }
`;

export const DELETE_COLLABORATOR = gql`
  mutation deleteCollaborator($id: Float!) {
    deleteCollaborator(id: $id) {
      id
    }
  }
`;

export const REVOKE_COLLABORATOR_ACCESS = gql`
  mutation revokeCollaboratorAccess($id: Float!) {
    revokeCollaboratorAccess(id: $id) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const RESEND_COLLABORATOR_INVITE = gql`
  mutation resentCollaboratorInvite($id: Float!) {
    resentCollaboratorInvite(id: $id) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const UPDATE_COLLABORATOR_ACCESS = gql`
  mutation updateCollaboratorAccess($input: UpdateUserCollaboratorInput!) {
    updateCollaboratorAccess(input: $input) {
      id
      firstName
      lastName
      email
      lastLoggedIn
      status
      revokedBy
      authorizedForBanking
      note
      isOwner
    }
  }
`;

export const CONVERT_GUEST_TO_LANDLORD = gql`
  mutation convertGuestToLandlord {
    convertGuestToLandlord {
      id
      user {
        id
      }
      collaborators {
        id
        firstName
        lastName
        email
        lastLoggedIn
        status
        revokedBy
        authorizedForBanking
        note
        isOwner
      }
      name
    }
  }
`;

export const RESTORE_DEFAULT_WORKSPACE = gql`
  mutation restoreDefaultWorkspace {
    restoreDefaultWorkspace {
      id
    }
  }
`;
