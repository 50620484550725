import React from 'react';
import { useFormikContext } from 'formik';
import { Flex, Box, Text, Switch } from '@chakra-ui/react';
import PropertyDropdown from './PropertyDropdown';

const Property = () => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Box
      border="1px solid"
      borderColor="brand.darkBlue.200"
      borderRadius="4px"
      background="brand.darkBlue.50"
      p={2}
      mt={2}
    >
      <Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Text textStyle="sm" fontWeight="500">
            Property
          </Text>
          <Switch
            value="some value"
            isChecked={values.isPropertyOpen}
            onChange={() => setFieldValue('isPropertyOpen', !values.isPropertyOpen)}
          />
        </Flex>
      </Box>
      {values.isPropertyOpen && (
        <Box mt={3}>
          <PropertyDropdown />
        </Box>
      )}
    </Box>
  );
};

export default Property;
