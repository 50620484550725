import React, { Fragment } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Stack,
  Text,
} from '@chakra-ui/react';
import { IconChevronUp, IconChevronDown } from '@icons';
import { BaselaneDivider } from '@core/components/Shared/components';
import { cashflowTaxPackageStyles } from '../styles/faq.styles';

// Destructure Imported Styles
const {
  title,
  question,
  answer,
  disclaimer,
  wrapper,
  accordion,
  accordionbutton,
  accordioncontent,
  iconcontainer,
  divider,
} = cashflowTaxPackageStyles;

const rcDrawerConst = {
  title: 'FAQs',
  q1: 'What is the Tax Package?',
  q2: 'What transactions and attachments are included?',
  a1:
    'The Tax Package is a consolidated report of your property financials that can be easily shared with a tax preparer, investing partner, lender, or anyone requesting your financials. Select the time period and properties for which you want to run the report and click download. Two CSV files will be downloaded: a Net Operating Cashflow report and a ledger of transactions. Transaction attachments will be coming soon.',
  a1Disclaimer: 'Note: Allow permission to download multiple files if prompted by your browser.',
  a2:
    'All transactions tagged to the selected property and within the specified time period will be included in the Tax Package, regardless of whether they have been tagged to a category. Additionally, all attachments for filtered transactions will be included in the report.',
};

const rcDrawerComponents = () => {
  // FAQ Const
  const { title: titleText, q1, q2, a1, a1Disclaimer, a2 } = rcDrawerConst;

  return {
    title: <Text {...title}>{titleText}</Text>,
    q1: <Text {...question}>{q1}</Text>,
    q2: <Text {...question}>{q2}</Text>,
    a1: (
      <Stack {...answer}>
        <Text>{a1}</Text>
        <Text {...disclaimer}>{a1Disclaimer}</Text>
      </Stack>
    ),
    a2: (
      <Stack {...answer}>
        <Text>{a2}</Text>
      </Stack>
    ),
  };
};

export const getFAQComponent = () => {
  const { title: titleText, q1, q2, a1, a2 } = rcDrawerComponents();
  const pairs = [
    { key: '1', header: q1, content: a1 },
    { key: '2', header: q2, content: a2 },
  ];

  return (
    <>
      {/* Title for Mobile  */}
      {titleText}

      {/* Questions & Answers */}
      <Accordion {...wrapper} allowMultiple>
        {pairs.map((item) => (
          <Fragment key={item.key}>
            <AccordionItem {...accordion}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton {...accordionbutton}>
                    {item.header}
                    <Box {...iconcontainer}>
                      {isExpanded ? (
                        <IconChevronUp color="#3A4B5B" />
                      ) : (
                        <IconChevronDown color="#3A4B5B" />
                      )}
                    </Box>
                  </AccordionButton>
                  <AccordionPanel {...accordioncontent}>{item.content}</AccordionPanel>
                  <BaselaneDivider styles={divider} />
                </>
              )}
            </AccordionItem>
          </Fragment>
        ))}
      </Accordion>
    </>
  );
};
