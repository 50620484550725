const getQueryDownloadUrl = async (id, getDocumentDownloadUrl) => {
  let result = null;

  try {
    const response = await getDocumentDownloadUrl({
      variables: {
        id,
      },
    });

    if (response.errors?.length) {
      console.error('download error: ', response.errors);
    } else {
      result = response.data;
    }
  } catch (error) {
    console.error('download error: ', error);
  }

  return result;
};

export default getQueryDownloadUrl;
