import React from 'react';
import { Heading, Text, VStack, Box } from '@chakra-ui/react';
import { BaselaneButton, BaselaneCardNew } from '@shared/components';
import { IllustrationOtherError1, IllustrationOtherTenantScreening } from '@illustrations';

const EmptyState = ({ handleEmptyStateAction, active }: any) => {
  return (
    <BaselaneCardNew minH="240px" w="full" palette="empty">
      <VStack alignItems="center" w="100%" gap={0.5}>
        <Box mb={1.5}>
          {active ? <IllustrationOtherTenantScreening /> : <IllustrationOtherError1 />}
        </Box>
        <Heading size="headline-md">
          {active ? 'Start screening applicants' : 'Not accepting new applications'}
        </Heading>
        <Text textStyle="sm" mb="16px">
          {active
            ? 'Invite applicants by email or share the application link with them.'
            : 'You’ve turned off new applications for this screening.'}
        </Text>
        <BaselaneButton
          palette="primary"
          variant="filled"
          size="lg"
          onClick={handleEmptyStateAction}
        >
          {active ? 'Invite applicants' : 'Turn on applications'}
        </BaselaneButton>
      </VStack>
    </BaselaneCardNew>
  );
};

export default EmptyState;
