import React from 'react';
import {
  Icon16Sparkle,
  Icon16Accounts,
  Icon16LifetimeInterest,
  Icon16Dollarcrossed,
  Icon16Bolt,
} from '@icons/16px';

export const defaultComponentCopy = (apyInterestValue) => ({
  heading: 'Select a new receiving account',
  formLabel: 'Account to receive rent & fees',
  tooltipText: 'Only accounts that are eligible to collect rent are shown here.',
  baselaneAccountHeading: 'Open a Baselane Banking account',
  baselaneAccountDetails: [
    {
      id: 1,
      prefix: 'Free',
      text: 'No fee for tenant payments via ACH (bank transfer)',
      icon: <Icon16Dollarcrossed />,
    },
    {
      id: 2,
      prefix: 'Faster',
      text: 'Rent deposited in 2-3 business days with QuickPay',
      icon: <Icon16Bolt />,
    },
    {
      id: 3,
      prefix: 'Organized',
      text: 'Dedicated accounts for each unit / security deposit',
      icon: <Icon16Accounts />,
    },
    {
      id: 4,
      prefix: 'Rewarding',
      text: `Up to ${apyInterestValue ?? '--'}% APY on savings accounts`,
      icon: <Icon16LifetimeInterest />,
    },
    {
      id: 5,
      prefix: 'Integrated',
      text: 'Automated bookkeeping and tax prep',
      icon: <Icon16Sparkle />,
    },
  ],
  baselaneAccountButtonLabel: 'Create account',
  connectedAccountHeading: 'Connect your external account',
  connectedAccountDetails: '$2 fee for tenant payments via ACH (bank transfer)',
  connectedAccountButtonLabel: 'Connect external account',
  // Only overrides above copy if hideExternalAccounts is true
  hideExternalAccounts: {
    heading: 'Select or add a Baselane Banking account',
  },
});
