import React, { useEffect, useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import { Text, Box, Stack, ChakraProvider } from '@chakra-ui/react';
import LogRocket from 'logrocket';

import { useApolloClient, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { validateLogin } from '@features/Authentication/helpers/validation.helpers';
import {
  firebaseEmailSignIn,
  generateSessionCookie,
} from '@features/Authentication/helpers/firebase.helpers';
import { AuthenticationError, errorMessages } from '@features/Authentication/helpers/error.helpers';
import {
  clearUrlUtmData,
  deleteUtmCookies,
  getUtmInputs,
  identifyUser,
  storeUrlUtmData,
  trackUtmUser,
} from '@features/Authentication/helpers/tracking.helpers';
import { BaselaneButton, BaselaneLink } from '@shared/components';
import { DASHBOARD, FORGOT_PASSWORD, HOME } from '@routes';

import habitatTheme from '@core/themeHabitat';
import { LANDLORD_LOGIN } from '@core/apollo/queries';
import inActivityLogoutStorage from '@core/storage/inActivityLogoutStorage';
import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { reactNodeEnv } from '@core/constants/envVars';
import useIpAddress from '@shared/hooks/useIpAddress';
import { stackStyles } from '../styles/login.style';
import LoginFields from './fields/LoginFields';
import { useFirebaseOtp } from '../../../contexts/FirebaseOtpContext';

type LoginFormProps = {
  initialEmail: string,
  inviteCode: string,
};

const LoginForm = ({ initialEmail = '', inviteCode = '' }: LoginFormProps) => {
  const { verifyFirebaseOtp } = useFirebaseOtp();

  const { ipAddress } = useIpAddress();

  const [initialFormValues, setInitialFormValues] = useState({
    email: initialEmail, // Set initial value here
    password: '',
  });

  const formikRef = useRef();
  const client = useApolloClient();
  const location = useLocation();
  const navigate = useNavigate();

  const { checkGate } = useStatsigClient();

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  const { state } = location || {};
  const { shouldShowMessenger } = state || {};

  const [isLoading, setIsLoading] = useState(null);

  const [landlordLogin, { loading: isLandlordLoginLoading }] = useMutation(LANDLORD_LOGIN);

  // Fingerprint
  const { error: fingerprintError, getData: getDataFingerprint } = useVisitorData({
    extendedResult: true,
    tag: {
      env: reactNodeEnv,
    },
  });

  /**
   * Handler that executes when login form is submitted.
   * @param {Object} values the submitted form values.
   */
  const handleFormSubmit = async (values) => {
    const { email, password } = values;
    let requestId = null;
    // attempt to retrieve user's device fingerprint
    getDataFingerprint()
      .then((data) => {
        requestId = data?.requestId;
      })
      .catch((error) => {
        LogRocket.log(`getDataFingerprint error: ${JSON.stringify(error)}`);
      })
      .finally(() => {
        // attempt to log in with user's email + password
        firebaseEmailSignIn({
          email,
          password,
          verifyFirebaseOtp,
          handleSuccess: (credential, claims) => {
            // attempt to establish session (either user logged in immediately, or their Otp succeeded)
            credential.user
              .getIdToken()
              .then((token) => {
                generateSessionCookie({
                  token,
                  requestId,
                  handleSuccess: () => handleSessionSuccess(credential, claims, requestId),
                  handleError: (error) => {
                    navigate('something-went-wrong');
                  },
                });
              })
              .catch((error) => {
                LogRocket.log(
                  `landlordLogin (Custom Email) error: ${JSON.stringify(
                    error
                  )}, requestId:${requestId}`
                );
              });
          },
          handleError: (error) => {
            LogRocket.log(`signInWithCustomEmail error: ${JSON.stringify(error)}`);
            inActivityLogoutStorage.delete();
            localStorage.removeItem('redirectLogin');

            const { code } = error || {};
            const requestErrors = {};

            switch (code) {
              case AuthenticationError.invalidemail:
                requestErrors.email = errorMessages[code];
                break;
              case AuthenticationError.credential:
                requestErrors.credential = 'Invalid email or password';
                break;
              case AuthenticationError.usernotfound:
              case AuthenticationError.wrongpassword:
              case AuthenticationError.toomanyrequests:
              case AuthenticationError.userdisabled:
              case AuthenticationError.tenant:
                requestErrors.password = errorMessages[code];
                break;
              default:
                // do nothing - unhandled error
                break;
            }

            // set login form errors
            formikRef?.current?.setErrors({ ...formikRef?.current?.errors, ...requestErrors });
          },
        });
      });
  };

  /**
   * Handler that executes when a session is successfully established
   * @param {Object} values the submitted form values.
   */
  const handleSessionSuccess = (credential, claims, requestId) => {
    identifyUser(credential.user);
    const utmInputs = getUtmInputs(ipAddress, location, 'landlord_logged_in');

    landlordLogin({
      variables: {
        requestId,
        utmInputs,
        inviteCode,
      },
    })
      .then((response) => {
        if (!response?.error && response?.data?.landlordLogin?.id) {
          // login success
          // tracking event
          trackUtmUser(credential.user, 'landlord_logged_in', ipAddress, utmInputs);
          // delete utm data from storage, as it has been consumed
          clearUrlUtmData();
          // delete utm cookie data, as it has been consumed
          deleteUtmCookies();
          // Note: the following will reset the apollo client, and re-fetch the full user data,
          try {
            client.resetStore();
          } catch (error) {
            console.error('Error clearing Apollo Client store:', error);
          }
          if (checkGate(FEATURE_GATES.DASHBOARD_GATE)) {
            setIsLoading(false);
            navigate(DASHBOARD);
          } else {
            setIsLoading(false);
            navigate(HOME);
          }
        } else if (response?.error) {
          LogRocket.log(`landlordLogin (Custom Email) error: ${JSON.stringify(response?.error)}`);
        } else if (response?.errors) {
          if (
            response?.errors[0]?.message?.includes('Invite code not associated with the email') ||
            response?.errors[0]?.message?.includes('has been REVOKED')
          ) {
            navigate('/expired-invitation');
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        LogRocket.log(`landlordLogin (Custom Email) error: ${JSON.stringify(error)}`);
        setIsLoading(false);
      });
  };

  // Submit form when hitting 'enter'
  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      formikRef?.current?.handleSubmit();
    }
  };

  // Update the initial values when initialEmail changes
  useEffect(() => {
    setInitialFormValues((prevValues) => ({
      ...prevValues,
      email: initialEmail,
    }));
  }, [initialEmail]);

  // Start with intercom open (user clicked on "contact" link)
  // Start with messenger open (user clicked on "contact" link)
  useEffect(() => {
    if (shouldShowMessenger) {
      zendeskAPI('messenger', 'open');

      // erase state so that messenger doesn't keep opening. This method
      // of clearing state will not trigger a re-render
      // https://stackoverflow.com/a/66359848
    }
  }, [shouldShowMessenger]);

  // save url utm data, in case its lost before logging in
  useEffect(() => {
    if (ipAddress) {
      storeUrlUtmData(ipAddress, location);
    }
  }, [ipAddress]);

  // Log fingerprint error
  useEffect(() => {
    if (fingerprintError) {
      LogRocket.log(`fingerprint API error: ${JSON.stringify(fingerprintError)}`);
    }
  }, [fingerprintError]);

  return (
    <ChakraProvider theme={habitatTheme}>
      <Formik
        innerRef={formikRef}
        validate={validateLogin}
        validateOnChange
        initialValues={initialFormValues}
        onSubmit={handleFormSubmit}
        enableReinitialize // Allows reinitialization of form values when props change // not sure if this is the correct approch
      >
        {({ values, errors, handleSubmit, isValid }) => (
          <Form style={stackStyles}>
            <Stack w="100%" alignItems="flex-start" spacing={0.5} mb={1}>
              <LoginFields handleOnKeyDown={handleOnKeyDown} inviteCode />

              <Box color="brand.blue.800A">
                <BaselaneLink variant="primary" size="sm" to={FORGOT_PASSWORD}>
                  <Text as="span" textStyle="sm">
                    Forgot Your Password?
                  </Text>
                </BaselaneLink>
              </Box>
            </Stack>

            <Stack w="100%" alignItems="center">
              <BaselaneButton
                id="signInButton" // add for consultants for growth team
                variant="filled"
                palette="primary"
                isFullWidth
                size="lg"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
                isDisabled={!isValid}
                isLoading={isLandlordLoginLoading || isLoading}
              >
                Sign In
              </BaselaneButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </ChakraProvider>
  );
};

export default LoginForm;
