import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import {
  Box,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';

import { BaselaneButton, BaselaneButtonIcon, BaselaneFormErrorMessage } from '@shared/components';
import { Icon16Close } from '@icons/16px';
import { addedEmailContainerStyle } from '../styles';

const AddApplicantEmails = () => {
  const { values, handleChange, setFieldValue, status, setTouched } = useFormikContext();

  const optionalErrorMapper = { duplicate: 'Email already exists' };

  return (
    <FieldArray
      name="emails"
      render={(arrayHelpers) => {
        return (
          <Box width="100%">
            <Heading mb={1.5} size="headline-sm">
              Add applicant emails
            </Heading>

            <FormControl isInvalid={status === 'duplicate'} variant="isLast">
              <InputGroup>
                <Input
                  id="enteredEmail"
                  name="enteredEmail"
                  placeholder="Email address"
                  size="lg"
                  pr="70px" // same as add button + 10 px
                  textOverflow="ellipsis"
                  value={values.enteredEmail}
                  onChange={(e) => {
                    const trimmedEmail = e.target.value.trim();
                    handleChange(e);
                    setFieldValue('enteredEmail', trimmedEmail);
                  }}
                />
                <InputRightElement width="60px" height="100%" py={0.5} pr={0.5}>
                  <BaselaneButton
                    variant="filled"
                    palette="primary"
                    size="md"
                    isDisabled={status}
                    onClick={() => {
                      // add to list of tenant emails user wants to invite
                      arrayHelpers.push(values.enteredEmail);
                      // clear field after adding so user can enter in more emails
                      setFieldValue('enteredEmail', '');
                    }}
                  >
                    Add
                  </BaselaneButton>
                </InputRightElement>
              </InputGroup>
              <BaselaneFormErrorMessage>{optionalErrorMapper[status]}</BaselaneFormErrorMessage>
            </FormControl>

            {/* Added Email List */}
            {values.emails.map((email, index) => {
              return (
                <Stack key={email} {...addedEmailContainerStyle}>
                  <Text textStyle="sm" my={0.75} overflow="hidden">
                    {email}
                  </Text>
                  <BaselaneButtonIcon
                    variant="transparent"
                    palette="neutral"
                    icon={<Icon16Close />}
                    onClick={async () => {
                      // there is a race condition where element is not removed in time for
                      // the validation to occur
                      await arrayHelpers.remove(index);
                      setTouched('emails', true);
                    }}
                  />
                </Stack>
              );
            })}
          </Box>
        );
      }}
    />
  );
};

export default AddApplicantEmails;
