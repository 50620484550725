import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { IconCheck } from '@icons';
import {
  containerStyles,
  titleStyles,
  itemStyles,
  itemTextStyles,
} from '../styles/lendencyAdvantages.styles';

const LendencyAdvantages = ({ listData, listTitle }: { listData: Array, listTitle: String }) => {
  return (
    <Box {...containerStyles}>
      <Text {...titleStyles}>{listTitle}</Text>
      {listData.map((item) => (
        <HStack key={item.id} {...itemStyles}>
          <Box w="24px" h="24px">
            <IconCheck color="#257ED0" />
          </Box>

          <Text {...itemTextStyles}>{item.text}</Text>
        </HStack>
      ))}
    </Box>
  );
};

export default LendencyAdvantages;
