import React from 'react';
import { Outlet } from 'react-router-dom';
import { TwoFactorVerificationPopUp } from '@shared/components';
import { useFirebaseOtp } from '../../../../contexts/FirebaseOtpContext';

const FirebaseOtpLayoutWrapper = () => {
  const { props } = useFirebaseOtp();
  return (
    <>
      <TwoFactorVerificationPopUp {...{ ...props }} />
      <Outlet />
    </>
  );
};

export default FirebaseOtpLayoutWrapper;
