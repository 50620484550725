import { uniq } from 'lodash';

export const getPropertiesUnits = (info, leaseDetailProperties) =>
  Object.keys(info).length > 0
    ? Object.keys(info).map((propertyId) => {
        const propertyAndUnitsInfo = info[propertyId];
        const propertyWithId = leaseDetailProperties.find((property) => property.id === propertyId);
        const linkedUnits =
          propertyWithId &&
          propertyWithId.units &&
          propertyWithId.units.length > 0 &&
          propertyAndUnitsInfo.length > 0
            ? uniq(
                propertyAndUnitsInfo.map((unitId) =>
                  propertyWithId.units.length > 0
                    ? propertyWithId.units.find((unit) => unit.id === unitId)
                    : {}
                )
              )
            : [];
        return { property: propertyWithId, units: linkedUnits };
      })
    : { property: [], units: [] };
