import React from 'react';
import { Box } from '@chakra-ui/react';
import { bodyStyles } from './styles/app.styles';

/**
 * This layout container applies a global set of styles
 * for the purpose of inheritance.
 */

const GlobalLayoutWrapper = ({ children }: { children: any }) => (
  <>
    <Box id="recaptcha-container" />
    <Box {...bodyStyles}>{children}</Box>
  </>
);

export default GlobalLayoutWrapper;
