import React from 'react';
import { DatePickerInput } from '../components';
import {
  DisplayInputDefaultProps,
  DisplayInputSharedProps,
} from '../types/displayInputVariations.types';

const DisplayInputDefault = ({
  onOpen,
  handleClearAndApply,
  value,
  size,
  isDisabled,
  placeholder,
  className,
  hideInputIcon,
  hideClearButton,
}: DisplayInputDefaultProps & DisplayInputSharedProps) => {
  return (
    <DatePickerInput
      {...{
        className: `read-only-input ${className}`,
        size,
        value: value ?? '',
        onClick: onOpen,
        readOnly: true,
        placeholder,
        disabled: isDisabled,
        handleClearAndApply,
        hideInputIcon,
        hideClearButton,
      }}
    />
  );
};

DisplayInputDefault.defaultProps = {
  size: 'lg',
  isDisabled: false,
  placeholder: 'Select date',
  value: null,
  handleClearAndApply: null,
  className: '',
};

export default DisplayInputDefault;
