import React from 'react';
import {
  Box,
  Container,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  HStack,
  Spacer,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { Icon12Close } from '@icons/12px';
import BaselaneButton from '../BaselaneButton';
import BaselaneLink from '../BaselaneLink';
import BaselaneChip from '../BaselaneChip';
import { BaselaneButtonIcon } from '../BaselaneButtonIcon';
import { getDynamicIcon, getDynamicChipVariant } from './helpers/baselaneBanner.helpers';
import { baselaneBannerStyles } from './styles/baselaneBanner.styles';

type BaselaneBannerNewProps = () => {
  id?: string,
  title: string | ReactElement,
  body: any,
  variant?:
    | 'neutral-medium'
    | 'primary-medium'
    | 'success-medium'
    | 'warning-medium'
    | 'danger-medium'
    | 'neutral-high'
    | 'primary-high'
    | 'success-high'
    | 'warning-high'
    | 'danger-high',
  btnLabel?: string,
  isVertical?: boolean,
  bannerIconName?: React.Node,
  hasBannerIcon?: boolean,
  hasActionButton?: boolean,
  hasActionLink?: Boolean,
  linkActionStyles?: Object,
  hasCloseButton?: boolean,
  hasChip?: boolean,
  chipLabel?: string,
  onButtonClick?: Function,
  buttonLoading?: boolean,
  onCloseClick?: Function,
  styles?: Object,
};

const BaselaneBannerNew = ({
  id = 'baselane-banner-new',
  title,
  body,
  variant = 'primary-medium',
  btnLabel = 'Secondary',
  linkLabel = 'Link label',
  isVertical = false,
  bannerIconName = null,
  hasBannerIcon = true,
  hasActionButton = true,
  hasActionLink = false,
  linkActionStyles,
  hasCloseButton = true,
  hasChip = false,
  chipLabel = 'Chip',
  onButtonClick = () => {},
  onLinkClick = () => {},
  buttonLoading = false,
  onCloseClick = null,
  styles = {},
}: BaselaneBannerNewProps) => {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });
  const dynamicIconName = bannerIconName ?? getDynamicIcon({ variant });
  const chipVariant = getDynamicChipVariant({ variant });

  const {
    bannercontainer,
    innercontainer,
    textcontainer,
    iconcontainer,
    icon,
    chip,
    actionbtn,
    closebtn,
  } = baselaneBannerStyles({
    variant,
    isVertical,
  });

  return (
    isVisible && (
      <Container {...{ ...bannercontainer, ...styles?.container }} id={id}>
        <Alert {...{ variant: `banner-${variant}`, ...styles?.alert }}>
          <HStack {...innercontainer}>
            {hasBannerIcon && <AlertIcon as={dynamicIconName} {...icon} />}
            <Stack {...textcontainer}>
              <Stack gap={0}>
                <Box as="span">
                  <AlertTitle as="span" mr={hasChip ? 0.75 : 0}>
                    {title}
                  </AlertTitle>

                  {hasChip && (
                    <Box as="span" {...iconcontainer}>
                      <BaselaneChip label={chipLabel} variant={chipVariant} {...chip} />
                    </Box>
                  )}
                </Box>
                <AlertDescription>{body}</AlertDescription>
              </Stack>

              {!isVertical && <Spacer />}
              {hasActionButton && (
                <BaselaneButton
                  id={`${id}-button`}
                  {...actionbtn}
                  onClick={onButtonClick}
                  isLoading={buttonLoading}
                >
                  {btnLabel}
                </BaselaneButton>
              )}
              {hasActionLink && (
                <BaselaneLink
                  {...actionbtn}
                  onClick={onLinkClick}
                  customStyles={{ ...linkActionStyles }}
                >
                  {linkLabel}
                </BaselaneLink>
              )}
            </Stack>
          </HStack>

          {hasCloseButton && (
            <BaselaneButtonIcon
              icon={<Icon12Close color="currentColor" />}
              onClick={() => {
                onClose();
                if (onCloseClick) {
                  onCloseClick();
                }
              }}
              {...closebtn}
            />
          )}
        </Alert>
      </Container>
    )
  );
};

export default BaselaneBannerNew;
