import React from 'react';
import { Stack, HStack, Spacer, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { useCurrentStep, useTotalSteps, useUpcomingPayments } from '../../sendFundsStore';
import { steps, formStep } from '../../helpers/formHelpers';

const SharedHeader = () => {
  const currentStep = useCurrentStep();
  const totalSteps = useTotalSteps();
  const formik = useFormikContext();
  const { paymentMethodType = '' } = formik.values;
  const upcomingPayments = useUpcomingPayments();
  const isOneTimePayment = formik.values.scheduledPaymentType === 'one-time';

  const paymentDescription = () => {
    const isSameDayPayment = isOneTimePayment
      ? // Its one time payment. Checking if the selected date is today.
        DateTime.fromJSDate(formik.values.oneTimeTransfer.date).hasSame(DateTime.now(), 'day')
      : // Its recurring payment. Checking if the first upcoming payment date is today.
        DateTime.fromJSDate(new Date(upcomingPayments[0].transferDate)).hasSame(
          DateTime.now(),
          'day'
        );

    let description = '';
    if (paymentMethodType === 'ACH') {
      description = isSameDayPayment
        ? 'Same-day ACH payments (one-time or part of a recurring series) made after 3:00 PM ET, will be processed the following business day.'
        : '';
    }
    if (paymentMethodType === 'WIRE_TRANSFER') {
      description = isSameDayPayment
        ? 'Same-day wire payments (one-time or part of a recurring series) made after 4:45 PM ET will be processed the following business day.'
        : '';
    }
    if (paymentMethodType === 'CHECK_PAYMENT') {
      description = 'Checks are printed and delivered in 5-8 business days.';
    }
    return description;
  };
  const getSharedHeaderForCurrentStep = () => {
    if (currentStep === steps.SELECT_RECIPIENT) {
      return {
        title: 'Select recipient',
        description: null,
      };
    }
    if (currentStep === steps.SELECT_PAYMENT_METHOD) {
      return {
        title: 'Recipient details',
        description: null,
      };
    }
    if (currentStep === steps.PAYMENT_DETAILS) {
      return {
        title: 'Payment details',
        description: null,
      };
    }
    if (currentStep === steps.BOOKKEPPING_DETAILS) {
      return {
        title: 'More details',
      };
    }
    if (currentStep === steps.REVIEW_PAYMENT) {
      return {
        title: 'Review and send payment',
        description: paymentDescription(),
      };
    }

    return null;
  };
  const { title, description } = getSharedHeaderForCurrentStep() ?? {};

  return (
    <Stack gap={0.5} mb={3}>
      <HStack>
        <Text textStyle="headline-md" color="brand.neutral.900">
          {title}
        </Text>
        <Spacer />
        <Text textStyle="xs" color="brand.neutral.600">
          Step {`${formStep(currentStep)} of ${totalSteps - 1}`}
        </Text>
      </HStack>
      {description && (
        <Text textStyle="sm" color="brand.neutral.700">
          {description}
        </Text>
      )}
    </Stack>
  );
};

export default SharedHeader;
