import React, { useContext } from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { BaselaneButtonIcon, LimitedAccessTooltip } from '@shared/components';
import UserAccessContext from '@contexts/UserAccessContext';
import { getIsTokenExpired, getTokenData } from '@shared/helpers/otp.helpers';
import { extraSmallText, smallText } from './transfers.styles';
import { getStatementPdf, getStatementPeriod } from './statement.helper';
import { IconDownload } from '../../../../../Icons';

const handleStatementDownload = (
  e,
  statements,
  setBankId,
  setUnitStatementId,
  unitAPINonSensitiveToken,
  showErrorToast
) => {
  const [bkId, statementId] = e.currentTarget.value.split(',');

  setBankId(Number(bkId));
  setUnitStatementId(Number(statementId));

  const { nonSensitive } = getTokenData(bkId);
  const isNonSensitiveTokenExpired = getIsTokenExpired(bkId, true);

  if (isNonSensitiveTokenExpired) {
    unitAPINonSensitiveToken({ variables: { bankId: Number(bkId) } });
  } else {
    getStatementPdf(Number(statementId), nonSensitive.token, statements, showErrorToast);
  }
};

const getGridItems = ({
  statements,
  setBankId,
  setUnitStatementId,
  unitAPINonSensitiveToken,
  showErrorToast,
}) =>
  statements.reduce((items, item, index) => {
    const isLastRow = index === statements.length - 1;
    const borderStyles = isLastRow
      ? { border: 'none' }
      : { borderBottomWidth: '1px', borderColor: 'brand.darkBlue.150' };
    const { authorizedForBanking } = useContext(UserAccessContext);
    return items.concat([
      {
        styles: {
          ...borderStyles,
        },
        element: (
          <Stack spacing={0}>
            <Text {...smallText}>{item?.masterAccountName}</Text>
            {item?.accountName && <Text {...extraSmallText}>{item.accountName}</Text>}
          </Stack>
        ),
      },
      {
        styles: {
          ...borderStyles,
        },
        element: <Text {...smallText}>{getStatementPeriod(item.period)}</Text>,
      },
      {
        styles: {
          justifyContent: 'flex-end',
          ...borderStyles,
        },
        element: (
          <Box pr="12px">
            {!authorizedForBanking ? (
              <LimitedAccessTooltip label="With limited banking access, you cannot download statements.">
                <BaselaneButtonIcon
                  variant="outline"
                  palette="neutral"
                  size="sm"
                  value={[item.bankId, item.unitStatementId]}
                  icon={<IconDownload />}
                  isDisabled
                  onClick={() => {}}
                />
              </LimitedAccessTooltip>
            ) : (
              <BaselaneButtonIcon
                variant="outline"
                palette="neutral"
                size="sm"
                value={[item.bankId, item.unitStatementId]}
                icon={<IconDownload />}
                onClick={(e) => {
                  try {
                    handleStatementDownload(
                      e,
                      statements,
                      setBankId,
                      setUnitStatementId,
                      unitAPINonSensitiveToken,
                      showErrorToast
                    );
                  } catch (error) {
                    showErrorToast(error);
                  }
                }}
              />
            )}
          </Box>
        ),
      },
    ]);
  }, []);

export default getGridItems;
