import { React, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Icon16ChevronRight, Icon16ChevronLeft } from '@icons/16px';
import { BaselaneButton } from '@shared/components';
import { sendSegmentEventsForSteps } from '../../helpers/segmentEvents';
import {
  useSetCurrentStep,
  useSelectedPaymentMethod,
  useCurrentStep,
  useIsOneTimePayment,
  useIsCurrentStepDisabled,
} from '../../sendFundsStore';
import { steps } from '../../helpers/formHelpers';

const PaymentDetailsFooter = () => {
  const setCurrentStep = useSetCurrentStep();
  const currentStep = useCurrentStep();
  const selectedPaymentMethod = useSelectedPaymentMethod();
  const isOneTimePayment = useIsOneTimePayment();
  const isCurrentStepDisabled = useIsCurrentStepDisabled();
  const formik = useFormikContext();
  const { isValid, validateForm, setErrors, values } = formik;
  const handleGoBack = () => {
    setCurrentStep(steps.SELECT_PAYMENT_METHOD);
  };
  useEffect(() => {
    validateForm();
    setErrors({});
  }, []);
  return (
    <>
      <BaselaneButton
        size="md"
        variant="outline"
        palette="neutral"
        onClick={handleGoBack}
        leftIcon={<Icon16ChevronLeft />}
      >
        Back
      </BaselaneButton>
      <BaselaneButton
        id="save-and-continue-button"
        size="md"
        variant="filled"
        palette="primary"
        width="100%"
        ml={1.5}
        flex={1}
        onClick={() => {
          setCurrentStep(steps.BOOKKEPPING_DETAILS);
          sendSegmentEventsForSteps(values, currentStep, selectedPaymentMethod, isOneTimePayment);
        }}
        isDisabled={!isValid || isCurrentStepDisabled}
        rightIcon={<Icon16ChevronRight />}
      >
        Next
      </BaselaneButton>
    </>
  );
};
export default PaymentDetailsFooter;
