export const textOperators = [
  { id: '1', name: 'contains', operator: 'PARTIAL' },
  { id: '2', name: 'starts with', operator: 'STARTS_WITH' },
  { id: '3', name: 'exactly matches', operator: 'EXACT' },
];

export const matchTypeOperators = [
  { id: '1', name: 'ANY of the values', operator: 'OR' },
  { id: '2', name: 'ALL of the values', operator: 'AND' },
];

export const amountOperators = [
  { id: '1', name: 'equals', operator: 'EQUALS' },
  { id: '2', name: 'is greater than', operator: 'GREATER_THAN' },
  { id: '3', name: 'is less than', operator: 'LESS_THAN' },
  { id: '4', name: 'is in between', operator: 'BETWEEN' },
];
