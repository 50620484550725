import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useLazyQuery } from '@apollo/client';
import {
  BaselaneApplicationTypePopup,
  BaselaneButton,
  NoAccessTooltip,
  LimitedAccessTooltip,
} from '@shared/components';
import historyStateStorage from '@core/storage/historyStateStorage';
import { GET_KYC_URL } from '@core/components/NativeBankingPage/queries';
import SlLoader from '@core/components/Loader';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { UNIFIED_LB_PERSONAL_INFO, ADD_FUNDS_BANK_TRANSFER } from '@routes';
import UserAccessContext from '@contexts/UserAccessContext';
import { footerConfig } from './helpers/APYSectionConfig.helpers';

type APYSectionFooterProps = {
  config: Array<Object>,
  isUserOnPromo: Boolean,
  isEmptyState: Boolean,
  isUserEligibleForPromo: Boolean,
  loading: Boolean,
  maxTier: Object,
  userPromoApy: String,
  currentApy: String,
  hasCollectedRentWithinNeededPeriod: Boolean,
  baselaneBankBalance: Number,
  isUserInLastMonthOfPromo: Boolean,
};

const APYSectionFooter = ({
  config,
  isUserOnPromo,
  isEmptyState,
  isUserEligibleForPromo,
  loading,
  maxTier,
  userPromoApy,
  currentApy,
  hasCollectedRentWithinNeededPeriod,
  baselaneBankBalance,
  isUserInLastMonthOfPromo,
}: APYSectionFooterProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isWorkspaceOwner, authorizedForBanking } = useContext(UserAccessContext);
  const [getKYCUrl] = useLazyQuery(GET_KYC_URL, {
    onCompleted: ({ unitOnBoarding: unitBankUrl }) => window.open(unitBankUrl, '_self'),
    onError: (error) => console.error(error),
    fetchPolicy: 'no-cache',
  });

  const activeConfig = config?.find((c) => c.isActive);

  // Application Type Selector Popup
  const {
    isOpen: isBaselaneApplicationTypePopupOpen,
    onOpen: onBaselaneApplicationTypePopupOpen,
    onClose: onBaselaneApplicationTypePopupClose,
  } = useDisclosure();

  const openLBFlow = (type) => {
    const pathname = UNIFIED_LB_PERSONAL_INFO;
    const state = { from: location.pathname };

    if (type === 'business') {
      getKYCUrl();
    } else {
      historyStateStorage.write(state);
      navigate(pathname);
    }
  };

  const getAction = (type, amount) => {
    switch (type) {
      case 'addFunds': {
        return !authorizedForBanking ? (
          <Box display="inline-block">
            <LimitedAccessTooltip>
              <BaselaneButton
                variant="link"
                palette="primary"
                isDisabled={!authorizedForBanking}
                onClick={() => {
                  sendSegmentEvent('baselane_banking_home_apy_click_add_funds', {
                    amount,
                    available_balance: baselaneBankBalance,
                    collecting_rent: hasCollectedRentWithinNeededPeriod,
                    current_apy: currentApy ?? '',
                    next_month_apy: activeConfig?.value ?? '',
                  });
                  navigate(`${location.pathname}/${ADD_FUNDS_BANK_TRANSFER}`, {
                    state: { from: location, amount },
                  });
                }}
                size="sm"
                pullLeft
                styles={{
                  py: 0,
                  fontWeight: 'semibold',
                }}
              >
                Add {amount}
              </BaselaneButton>
            </LimitedAccessTooltip>
          </Box>
        ) : (
          <BaselaneButton
            variant="link"
            palette="primary"
            onClick={() => {
              sendSegmentEvent('baselane_banking_home_apy_click_add_funds', {
                amount,
                available_balance: baselaneBankBalance,
                collecting_rent: hasCollectedRentWithinNeededPeriod,
                current_apy: currentApy ?? '',
                next_month_apy: activeConfig?.value ?? '',
              });
              navigate(`${location.pathname}/${ADD_FUNDS_BANK_TRANSFER}`, {
                state: { from: location, amount },
              });
            }}
            size="sm"
            pullLeft
            styles={{
              py: 0,
              fontWeight: 'semibold',
            }}
          >
            Add {amount}
          </BaselaneButton>
        );
      }
      case 'unlockedMax': {
        return null;
      }
      case 'emptyStateEligible': {
        return null;
      }
      case 'emptyState': {
        return (
          <>
            {!isWorkspaceOwner ? (
              <NoAccessTooltip width="fit-content" display="inline-block">
                <BaselaneButton
                  variant="link"
                  palette="primary"
                  size="sm"
                  margin="0"
                  onClick={onBaselaneApplicationTypePopupOpen}
                  isDisabled={!isWorkspaceOwner}
                >
                  Open Baselane Banking Account
                </BaselaneButton>
              </NoAccessTooltip>
            ) : (
              <BaselaneButton
                variant="link"
                palette="primary"
                size="sm"
                margin="0"
                onClick={onBaselaneApplicationTypePopupOpen}
                isDisabled={!isWorkspaceOwner}
              >
                Open Baselane Banking Account
              </BaselaneButton>
            )}
            <BaselaneApplicationTypePopup
              handleActionButton={openLBFlow}
              isOpen={isBaselaneApplicationTypePopupOpen}
              onClose={onBaselaneApplicationTypePopupClose}
            />
          </>
        );
      }
      default: {
        return <SlLoader className="" styles={{ position: 'relative' }} />;
      }
    }
  };

  const onPromo = isUserOnPromo && !isUserInLastMonthOfPromo ? 'unlockedMax' : null;
  let emptyState = isEmptyState && isUserEligibleForPromo ? 'emptyStateEligible' : 'emptyState';
  if (loading) {
    emptyState = 'loading';
  }

  return (
    <Box mt={emptyState === 'emptyStateEligible' ? '12px' : '8px'} textStyle="xs">
      {getAction(activeConfig?.footerAction || onPromo || emptyState, activeConfig?.footerAmount)}
      <Box as="span">
        {footerConfig(maxTier, userPromoApy)[activeConfig?.footerAction || onPromo || emptyState]}
      </Box>
    </Box>
  );
};

export default APYSectionFooter;
