import React, { useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, useDisclosure } from '@chakra-ui/react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { UNIFIED_LB_PERSONAL_INFO } from '@routes';
import UserContext from '@contexts/UserContext';
import { BaselaneApplicationTypePopup } from '@shared/components';
import historyStateStorage from '@core/storage/historyStateStorage';
import { GET_KYC_URL } from '@core/components/NativeBankingPage/queries';
import { GET_PROPERTY_SUMMARY } from '@core/apollo/queries';
import useSuccessPropertyDrawer from '@pages/PropertiesPage/SuccessDrawer/hooks/useSuccessPropertyDrawer';
import useCurrentWorkspace from '@shared/hooks/useCurrentWorkspace';

import { GuideTrackersProps } from './trackers.types';
import RentCollection from './RentCollection';
import Banking from './Banking';
import BookKeeping from './BookKeeping';

const GuideTrackers = ({
  bankingTracker,
  rentCollectionTracker,
  bookkeepingTracker,
  refetchTrackers,
}: GuideTrackersProps) => {
  const updatedStepRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  const { user, refetchUser } = useContext(UserContext);
  const { userKycStatus, userKyc } = useCurrentWorkspace();

  const [getKYCUrl] = useLazyQuery(GET_KYC_URL, {
    onCompleted: ({ unitOnBoarding: unitBankUrl }) => window.open(unitBankUrl, '_self'),
    onError: (error) => console.error(error),
    fetchPolicy: 'no-cache',
  });
  const kycProps = {
    getKYCUrl,
  };

  const {
    isOpen: isBaselaneApplicationTypePopupOpen,
    onOpen: onBaselaneApplicationTypePopupOpen,
    onClose: onBaselaneApplicationTypePopupClose,
  } = useDisclosure();

  const handleStepUpdate = ({ isAPIUpdated = false, isDrawerClosed = false }) => {
    if (isAPIUpdated) {
      updatedStepRef.current = {
        ...updatedStepRef.current,
        isAPIUpdated: true,
      };
    }
    if (isDrawerClosed) {
      updatedStepRef.current = {
        ...updatedStepRef.current,
        isDrawerClosed: true,
      };
    }

    if (updatedStepRef.current?.isAPIUpdated && updatedStepRef.current?.isDrawerClosed) {
      refetchUser();
      refetchTrackers();
      updatedStepRef.current = {};
    }
  };

  const {
    error: propertiesSummaryError,
    loading: propertiesSummaryLoading,
    data: propertiesSummaryData,
  } = useQuery(GET_PROPERTY_SUMMARY);
  const { newProperty, setNewProperty, successDrawerRef } = useSuccessPropertyDrawer();
  const propertiesProps = {
    propertiesSummaryError,
    propertiesSummaryLoading,
    propertiesSummaryData,
    setNewProperty,
    successDrawerRef,
    newProperty,
  };

  const openLBFlow = (type) => {
    const pathname = UNIFIED_LB_PERSONAL_INFO;
    const state = { from: location?.pathname };

    if (type === 'business') {
      getKYCUrl();
    } else {
      historyStateStorage.write(state);
      navigate(pathname);
    }
  };

  return (
    <>
      <Stack direction={{ base: 'column', md: 'row' }} alignItems="stretch" spacing={2} mt={2}>
        <Banking
          data={bankingTracker}
          refetchTrackers={refetchTrackers}
          kycProps={kycProps}
          onBaselaneApplicationTypePopupOpen={onBaselaneApplicationTypePopupOpen}
        />
        <RentCollection
          data={rentCollectionTracker}
          userKycStatus={userKycStatus}
          handleStepUpdate={handleStepUpdate}
          refetchUser={refetchUser}
          refetchTrackers={refetchTrackers}
          propertiesProps={propertiesProps}
          userKyc={userKyc}
          user={user}
        />
        <BookKeeping
          data={bookkeepingTracker}
          handleStepUpdate={handleStepUpdate}
          refetchTrackers={refetchTrackers}
          propertiesProps={propertiesProps}
        />
      </Stack>

      <BaselaneApplicationTypePopup
        handleActionButton={openLBFlow}
        isOpen={isBaselaneApplicationTypePopupOpen}
        onClose={onBaselaneApplicationTypePopupClose}
      />
    </>
  );
};

export default GuideTrackers;
