// @flow
import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLazyQuery } from '@apollo/client';
import { Box, Stack, Text } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';
import { BaselaneCard, BaselaneErrorCard, BaselaneMessageCard } from '@shared/components';
import type { Sort } from '@shared/types/Sort';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { GET_TRANSACTIONS } from '@core/apollo/queries';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { useAutoTagRuleToast } from '@core/hooks/useAutoTagRuleToast';
import TransactionContext from '@contexts/TransactionContext';
import useBulkActionsStore from '@store/Transactions/bulkActionsStore';
import { NATIVE_BANK, TRANSACTIONS } from '@routes';
import type { Transaction } from './types';
import CategorizedTrxsBanner from './components/CategorizedTrxsBanner';
import FilterSummary from './components/FilterSummary';
import TransactionsDummyLoader from './components/TransactionsDummyLoader';
import TransactionsFilters from './components/TransactionsFilters';
import TransactionList from './TransactionList';
import ImportTrxModal from './ImportTrxModal';
import AutoTagPromoBanner from './autoTagPromotion/AutoTagPromoBanner';
import { transactionsStyles } from './styles/transactions.styles';
import AutoTagPromoModal from './autoTagPromotion/AutoTagPromoModal';
import AutoTagPromoSuccessModal from './autoTagPromotion/AutoTagPromoSuccessModal';

type TransactionsPageProps = {
  externalFilters?: Object,
  hideCategorizedTrxBanner?: boolean,
  hideFilters?: boolean,
  hideFilterSummary?: boolean,
  filterSummary?: Object,
  isExternalFilterLoading?: boolean,
  showDummyData?: boolean,
  styles?: Object,
  isEmbeddedInPage?: Boolean,
  isTableOnly?: boolean,
};

const TransactionsPage = ({
  externalFilters = {},
  hideCategorizedTrxBanner = false,
  hideFilters = false,
  hideFilterSummary = true,
  filterSummary = {},
  isExternalFilterLoading = false,
  showDummyData = false,
  styles = {},
  isEmbeddedInPage = false,
  isTableOnly = false,
}: TransactionsPageProps): any => {
  useAutoTagRuleToast();
  const { isMin899 } = useBreakPoints();

  const {
    accountsLoading,
    accountsError,
    accountsData,
    refetchAccountsData,
    accountMap,
    connectedAccountsFilterOptions,
    filters,
    setFilters,
    DEFAULT_FILTERS,
    isTransactionSummaryOrAccountsLoading,
  } = useContext(TransactionContext);

  const { checkGate } = useStatsigClient();

  const currentUrl = useLocation();
  const { pathname } = currentUrl;

  const [showImportTrxModal, setShowImportTrxModal] = useState(false);

  const [transactions, setTransactions] = useState(([]: Array<Transaction>));

  const connectedAccounts = accountsData?.bank?.filter((ba) => {
    const { isConnected, isExternal, unitAccount } = ba;
    return isConnected && !isExternal && unitAccount?.unitApplicationStatus === 'COMPLETED';
  });

  const isOutsideTrxPage =
    Object.keys(externalFilters).length > 0 || !pathname.includes(TRANSACTIONS);
  const { showBulkActions, setShowBulkActions } = useBulkActionsStore();
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [actionDeselectAll, setActionDeselectAll] = useState(false);
  const [showUncategorized, setShowUncategorized] = useState(false);
  const [hasManualTransactions, setHasManualTransactions] = useState(false);
  const [hasLoadedMore, setHasLoadedMore] = useState(false);
  const [currSort, setCurrSort] = useState({ direction: 'DESC', field: 'date' });
  const [hasDropdownClearedExternally, setHasDropdownClearedExternally] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    dates: { start: null, end: null },
    timePeriod: null,
    categories: null,
    appliedPreset: null,
    properties: [],
    accounts: [],
  });
  const [autoTagSuccessPromoModalState, setAutoTagSuccessPromoModalState] = useState({
    open: false,
    state: null,
  });

  useEffect(() => {
    if (currentUrl.state?.openSuccessModal) {
      console.log(' currentUrl.state?.modalState', currentUrl.state?.modalState);

      setAutoTagSuccessPromoModalState({
        open: true,
        state: currentUrl.state?.modalState,
      });
    }
  }, [currentUrl?.state]);

  const [
    getTransactions,
    { loading: transactionsLoading, error, data, networkStatus, refetch },
  ] = useLazyQuery(GET_TRANSACTIONS, {
    variables: {
      input: {
        ...filters,
        filter: { ...externalFilters, ...filters.filter },
      },
    },
  });

  useEffect(() => {
    // default filters include external ones when coming
    const defaultFilters =
      externalFilters && Object.keys(externalFilters).length > 0
        ? {
            ...DEFAULT_FILTERS,
            filter: {
              ...DEFAULT_FILTERS.filter,
              ...externalFilters,
            },
          }
        : DEFAULT_FILTERS;

    setFilters({
      ...defaultFilters,
      page: 1,
    });

    // when the component unmounts, set the filters back to DEFAULT_FILTERS
    // this fixes filters not resetting when changing pages
    return () => {
      setFilters(DEFAULT_FILTERS);
    };
  }, []);

  const [showHiddenTransactions, setShowHiddenTransactions] = useState(false);
  const [showOnlyWithAttachments, setShowOnlyWithAttachments] = useState(false);

  const [showingUncategorizedTransactions, setShowingUncategorizedTransactions] = useState(
    showUncategorized
  );
  const [inputValue, setInputValue] = useState(filters.filter.search);

  const errorComponent = <BaselaneErrorCard />;
  if (accountsError) {
    return errorComponent;
  }

  // Go through the Landlord's banks to find whether there is a bank that is importing Tx.
  const isImporting =
    accountsData &&
    accountsData?.bank?.some((bank) =>
      bank?.bankAccounts?.some((account) => account?.importTransaction?.enabled === true)
    );

  const handleSelectedTransactions = (trxs) => {
    setSelectedTransactions(trxs);
    if (trxs?.length > 0) {
      setShowBulkActions(true);
    } else {
      setShowBulkActions(false);
    }
  };

  // clear button functionality
  const handleClear = () => {
    const newFilters =
      externalFilters && Object.keys(externalFilters).length > 0
        ? {
            ...DEFAULT_FILTERS,
            filter: {
              ...DEFAULT_FILTERS.filter,
              ...externalFilters,
            },
          }
        : DEFAULT_FILTERS;

    handleSelectedTransactions([]);
    setShowHiddenTransactions(false);
    setShowOnlyWithAttachments(false);
    setShowUncategorized(false);
    setFilters(newFilters);
    setInputValue('');
    setSelectedFilters({
      dates: { start: null, end: null },
      timePeriod: null,
      categories: null,
      appliedPreset: null,
      properties: [],
      accounts: [],
    });
    setShowingUncategorizedTransactions(false);
    setHasDropdownClearedExternally(true);
    setActionDeselectAll(true);
  };

  const handleShowNeedsReviewTransactions = (isNeedsReview: boolean) => {
    const newFilters =
      externalFilters && Object.keys(externalFilters).length > 0
        ? {
            ...DEFAULT_FILTERS,
            filter: {
              ...DEFAULT_FILTERS.filter,
              ...externalFilters,
              ...(isNeedsReview && { isReviewedByUser: false }),
            },
          }
        : {
            ...DEFAULT_FILTERS,
            filter: {
              ...DEFAULT_FILTERS.filter,
              ...(isNeedsReview && { isReviewedByUser: false }),
            },
          };

    handleSelectedTransactions([]);
    setShowHiddenTransactions(false);
    setShowOnlyWithAttachments(false);
    setShowUncategorized(false);
    setFilters(newFilters);
    setInputValue('');
    setSelectedFilters({
      dates: { start: null, end: null },
      timePeriod: null,
      categories: null,
      appliedPreset: null,
      properties: [],
      accounts: [],
    });
    setShowingUncategorizedTransactions(false);
    setHasDropdownClearedExternally(true);
    setActionDeselectAll(true);
  };

  function handleSearch(
    searchTerm: string,
    searchId: Array,
    isCategorized: Boolean,
    currSorts: Object,
    accountFilter: Object,
    tagFilter: Object,
    propertyFilter: Object,
    toFilter: String,
    fromFilter: String
  ) {
    const filter = {
      ...filters.filter,
      search: searchTerm,
      searchId,
      isCategorized,
      bankAccountId: accountFilter,
      tagId: tagFilter,
      propertyId: propertyFilter,
      to: toFilter,
      from: fromFilter,
    };

    // Reset all selected trx and hide bulk actions - the search results may not include the selected trx.
    setActionDeselectAll(true);
    setSelectedTransactions([]);
    setShowBulkActions(false);

    const newFilters = {
      ...DEFAULT_FILTERS,
      filter,
      sort: currSorts,
    };
    handleSelectedTransactions([]);
    setFilters(newFilters);
  }

  const handleSort = (newSort: Sort) => {
    setCurrSort(newSort);

    const newFilters = {
      ...DEFAULT_FILTERS,
      filter: filters.filter,
      sort: newSort,
    };
    handleSelectedTransactions([]);
    setFilters(newFilters);
  };

  const handleShowUncategorizedTransactions = (showUncategorizedTransactions: boolean) => {
    let isCategorized;

    if (showUncategorizedTransactions) {
      isCategorized = null;
    } else {
      isCategorized = false;
    }

    // It's the property filter of "filters"
    const filter = { ...filters.filter, isCategorized };

    const newFilters = {
      ...DEFAULT_FILTERS,
      filter,
      sort: currSort,
    };

    setShowUncategorized(!showUncategorizedTransactions);
    handleSelectedTransactions([]);
    setFilters(newFilters);
  };

  const handleToggleHiddenTransactions = (showHidden: boolean) => {
    setShowHiddenTransactions(showHidden);
  };

  const handleToggleOnlyWithAttachments = (showOnlyTransactionsWithAttachments: boolean) => {
    const newFilters = {
      ...DEFAULT_FILTERS,
      filter: showOnlyTransactionsWithAttachments
        ? { ...filters.filter, isDocumentUploaded: true }
        : { ...filters.filter, isDocumentUploaded: null },
      sort: currSort,
    };

    handleSelectedTransactions([]);
    setFilters(newFilters);
    setShowOnlyWithAttachments(showOnlyTransactionsWithAttachments);
  };

  const handleLoadMore = () => {
    setHasLoadedMore(true);

    const newFilters = {
      ...filters,
      page: filters.page + 1,
    };

    setFilters(newFilters);
  };

  const handleDeselectTransactions = (allDeselected) => {
    if (allDeselected) {
      setShowBulkActions(false);
      setSelectedTransactions([]);
      setActionDeselectAll(true);
    }
  };

  const { container, list } =
    transactionsStyles(
      isMin899,
      isMobile,
      isEmbeddedInPage,
      isEmbeddedInPage ||
        ((transactionsLoading || isTransactionSummaryOrAccountsLoading) && filters.page === 1)
    ) ?? {};

  return (
    <Stack {...container}>
      {!isTableOnly && <AutoTagPromoBanner />}
      {!isTableOnly && checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) && (
        <AutoTagPromoModal hasBaselaneBank={connectedAccounts?.length > 0} />
      )}
      {!pathname.includes(NATIVE_BANK) && <Outlet />}
      {isMin899 && (
        <>
          {!isImporting &&
            hasManualTransactions &&
            !isOutsideTrxPage &&
            !accountsLoading &&
            !isTableOnly &&
            !checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) && (
              <BaselaneMessageCard
                iconName="info"
                iconColor="blue"
                borderColor="blue"
                title="Automatically Import Your Transactions"
                containerStyles={{
                  mb: '16px  !important',
                  mt: '0 !important',
                  position: 'relative',
                  top: '-8px',
                }}
                hasCloseButton
                onCloseButtonClick={() => setHasManualTransactions(false)}
              >
                <Text>
                  To start automatically importing your transactions, add a bank or credit card
                  account connection securely with Plaid™ and enable transaction importing.
                </Text>
              </BaselaneMessageCard>
            )}

          {!hideCategorizedTrxBanner &&
            !isTableOnly &&
            !checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) && (
              <CategorizedTrxsBanner {...{ isOutsideTrxPage }} />
            )}

          {!hideFilterSummary && !isTableOnly && (
            <FilterSummary {...{ transactions, filterSummary }} />
          )}
        </>
      )}

      <BaselaneCard
        styles={{
          height: 'auto',
          boxShadow: (isOutsideTrxPage || isEmbeddedInPage) && 'none',
          borderRadius: !isMin899 || isEmbeddedInPage ? 'none' : '8px',
          background: !isMin899 ? 'brand.blue.50' : 'brand.neutral.white',
        }}
      >
        <TransactionsFilters
          onToggleHiddenTransactions={handleToggleHiddenTransactions}
          onToggleOnlyWithAttachments={handleToggleOnlyWithAttachments}
          onShowUncategorizedTransactions={handleShowUncategorizedTransactions}
          onSearch={handleSearch}
          inputValue={inputValue}
          setInputValue={setInputValue}
          showUncategorized={showUncategorized}
          isOutsideTrxPage={isOutsideTrxPage}
          defaultFilters={DEFAULT_FILTERS}
          showHiddenTransactions={showHiddenTransactions}
          showOnlyWithAttachments={showOnlyWithAttachments}
          showBulkActions={showBulkActions}
          handleDeselectTransactions={handleDeselectTransactions}
          handleSelectedTransactions={handleSelectedTransactions}
          selectedTransactions={selectedTransactions}
          setSelectedTransactions={setSelectedTransactions}
          handleClear={handleClear}
          showingUncategorizedTransactions={showingUncategorizedTransactions}
          setShowingUncategorizedTransactions={setShowingUncategorizedTransactions}
          currSort={currSort}
          hasDropdownClearedExternally={hasDropdownClearedExternally}
          setHasDropdownClearedExternally={setHasDropdownClearedExternally}
          hideFilters={hideFilters}
          styles={styles}
          accountMap={accountMap}
          connectedAccountsFilterOptions={connectedAccountsFilterOptions}
          refetch={refetch}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          defaultPresetName="Net Operating Cashflow"
          data={data}
          transactionsLoading={transactionsLoading}
          setShowImportTrxModal={setShowImportTrxModal}
          handleShowNeedsReviewTransactions={handleShowNeedsReviewTransactions}
          isTableOnly={isTableOnly}
        />

        <Box {...list.container()} {...styles?.transactionsListContainerStyles}>
          {isExternalFilterLoading ? (
            <TransactionsDummyLoader
              {...{
                filters,
                externalFilters,
                actionDeselectAll,
                setActionDeselectAll,
                selectedTransactions,
                onSort: handleSort,
              }}
            />
          ) : (
            <TransactionList
              onSort={handleSort}
              onLoadMore={handleLoadMore}
              isOutsideTrxPage={isOutsideTrxPage}
              setShowImportTrxModal={setShowImportTrxModal}
              showHiddenTransactions={showHiddenTransactions}
              showOnlyWithAttachments={showOnlyWithAttachments}
              showUncategorized={showUncategorized}
              setHasManualTransactions={setHasManualTransactions}
              setSelectedTransactions={setSelectedTransactions}
              setActionDeselectAll={setActionDeselectAll}
              actionDeselectAll={actionDeselectAll}
              selectedTransactions={selectedTransactions}
              handleSelectedTransactions={handleSelectedTransactions}
              externalFilters={externalFilters}
              transactions={transactions}
              setTransactions={setTransactions}
              getTransactions={getTransactions}
              transactionsLoading={transactionsLoading}
              data={data}
              error={error}
              networkStatus={networkStatus}
              refetch={refetch}
              accountsLoading={accountsLoading}
              accountMap={accountMap}
              refetchAccountsData={refetchAccountsData}
              setHasLoadedMore={setHasLoadedMore}
              hasLoadedMore={hasLoadedMore}
              showDummyData={showDummyData}
              isTableOnly={isTableOnly}
            />
          )}
        </Box>
      </BaselaneCard>

      {checkGate(FEATURE_GATES.CSV_GATE) && (
        <ImportTrxModal
          isAlertOpen={showImportTrxModal}
          onAlertClose={() => setShowImportTrxModal(false)}
          onAddedPlaidSuccessfully={() => {
            setShowImportTrxModal(false);
            refetch ? refetch() : getTransactions();
          }}
        />
      )}
      <AutoTagPromoSuccessModal
        open={autoTagSuccessPromoModalState.open}
        state={autoTagSuccessPromoModalState.state}
        onClose={() => setAutoTagSuccessPromoModalState({ open: false, state: null })}
      />
    </Stack>
  );
};

export default TransactionsPage;
