import React, { useEffect, useState } from 'react';
import { VStack, Text, Skeleton } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import formatCurrency from '@core/utils/formatCurrency';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { GET_BANK_ACCOUNTS } from '../queries/condition-queries';
import {
  fieldMap,
  operatorMap,
  conditionKeys,
  bankAccountName,
  cardName,
} from '../helpers/autotaggingRules.helpers';

type ConditionsProps = {
  parentId: string,
  conditions: Array<Object>,
};

const Conditions = ({ parentId, conditions, ...rest }: ConditionsProps) => {
  const { data, loading: bankAccountsLoading } = useQuery(GET_BANK_ACCOUNTS, {
    fetchPolicy: 'no-cache',
  });

  const [bankAccounts, setBankAccounts] = useState([]);
  const [virtualCards, setVirtualCards] = useState([]);

  const { isMax767 } = useBreakPoints();

  useEffect(() => {
    if (data) {
      setBankAccounts(data.bankAccounts);
      const cards = data.bankAccounts.flatMap((account) => account.cards);
      setVirtualCards(cards);
    }
  }, [data]);

  return (
    <VStack
      key={`${parentId}-conditions`}
      gap={1.5}
      alignItems="flex-start"
      justifyContent="center"
      {...rest}
    >
      {conditions.map(
        ({ type, field, value, operator, textOperator, textValues, secondaryValue, matchType }) => (
          <Text key={`${parentId}-${field}`} whiteSpace="wrap">
            {isMax767 && <Text as="span">If </Text>}
            <Text as="span" textStyle={isMax767 ? 'headline-sm' : 'headline-md'}>
              {
                fieldMap[
                  field === conditionKeys.AMOUNT && value < 0
                    ? conditionKeys.AMOUNT_NEGATIVE
                    : field
                ]
              }
            </Text>{' '}
            <Text as="span" textStyle={isMax767 ? 'sm' : 'md'}>
              {
                operatorMap[
                  (field === conditionKeys.BANK_ACCOUNT_ID && conditionKeys.EQUALS) ||
                    (field === conditionKeys.VIRTUAL_CARD_ID && conditionKeys.EQUALS) ||
                    operator ||
                    textOperator
                ]
              }
            </Text>{' '}
            {value !== null && value !== undefined && (
              <Skeleton
                isLoaded={!bankAccountsLoading}
                as="span"
                display="inline-block"
                minW="200px"
              >
                <Text as="span" textStyle={isMax767 ? 'headline-sm' : 'headline-md'}>
                  {field === conditionKeys.AMOUNT && formatCurrency(Math.abs(value)).inDollars}
                  {field === conditionKeys.BANK_ACCOUNT_ID && bankAccountName(bankAccounts, value)}
                  {field === conditionKeys.VIRTUAL_CARD_ID && cardName(virtualCards, value)}
                </Text>
                {operator === conditionKeys.BETWEEN && secondaryValue && (
                  <>
                    <Text as="span" textStyle={isMax767 ? 'sm' : 'md'}>
                      {' '}
                      and{' '}
                    </Text>
                    <Text as="span" textStyle={isMax767 ? 'headline-sm' : 'headline-md'}>
                      {formatCurrency(Math.abs(secondaryValue)).inDollars}
                    </Text>
                  </>
                )}
              </Skeleton>
            )}
            {textValues &&
              textValues
                .join(`|${matchType?.toLowerCase()}|`)
                .split('|')
                .map((textValue) => {
                  let textStyle = 'headline-md';

                  if (textValue === 'or' || textValue === 'and') {
                    textStyle = 'md';
                  }

                  if (isMax767) {
                    textStyle = textStyle === 'md' ? 'sm' : 'headline-sm';
                  }

                  return (
                    <React.Fragment key={`${textValue}-${uuidv4()}`}>
                      <Text as="span" textStyle={textStyle}>
                        {textValue}
                      </Text>{' '}
                    </React.Fragment>
                  );
                })}
          </Text>
        )
      )}
    </VStack>
  );
};

export default Conditions;
