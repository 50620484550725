import React from 'react';
import { Text, Flex, Skeleton, useMediaQuery, FormControl } from '@chakra-ui/react';
import { useFormikContext, Field } from 'formik';
import { T1Dropdown, BaselaneFormErrorMessage, BaselaneFormLabel } from '@shared/components';

type BaselanePropertySelectProps = {
  properties: any[],
  loading: boolean,
  isDisabled: boolean,
  isRequired: boolean,
};

const BaselanePropertySelect = ({
  properties,
  loading,
  isDisabled = false,
  isRequired = false,
}: BaselanePropertySelectProps) => {
  const { isMax768 } = useMediaQuery();

  const {
    values,
    setFieldValue,
    errors,
    touched,
    setFieldTouched,
    setFieldError,
  } = useFormikContext();

  const dropdownPropertyItem = ({ item }) => (
    <Text textStyle="sm" width="100%" color="inherit" as="div" data-cy="property-item">
      <Flex justifyContent="space-between">
        <div>
          <div>{item.name}</div>
          <Text textStyle="xs">{`${item.address?.address}, ${item.address?.city}, ${item.address?.state} ${item.address?.zipCode}`}</Text>
        </div>
        {item.units.length > 1 && <div>{item.units.length} units</div>}
      </Flex>
    </Text>
  );

  const dropdownUnitItem = ({ item }) => (
    <Text textStyle="xs" width="100%" color="inherit" data-cy="unit-item">
      {item.name}
    </Text>
  );

  const getSelectedProperty = () => {
    const selected = properties.find((property) => property.id === values?.selectedProperty);
    return selected;
  };

  const getSelectedUnit = () => {
    const selected = properties
      .find((property) => property.id === values?.selectedProperty)
      ?.units?.find((unit) => unit.id === values?.selectedUnit);
    return selected;
  };

  const propertyUnits = () => {
    const id = values?.selectedProperty;
    return properties.find((p) => p.id === id)?.units?.filter((unitId) => unitId !== id);
  };

  return (
    <>
      <FormControl
        mb={0}
        isRequired={isRequired}
        isInvalid={errors.selectedProperty && touched.selectedProperty}
      >
        <BaselaneFormLabel>Property</BaselaneFormLabel>
        <Skeleton isLoaded={!loading}>
          <T1Dropdown
            classNames={['auto-width-dropdown', 'auto-select-input-width', 'input-form-lg']}
            title="property"
            placeholder="Select a property"
            itemRenderer={dropdownPropertyItem}
            selectedItem={getSelectedProperty()}
            data={properties}
            hideSearch
            isDisabled={isDisabled}
            handleSubmit={(selectedItemId) => {
              setFieldValue('selectedProperty', selectedItemId);
              setFieldValue('selectedUnit', '');
              setFieldTouched('selectedUnit', false);
              setFieldError('selectedProperty', false);
            }}
            onClose={() => {
              setFieldTouched('selectedProperty', true);
              if (!values?.selectedProperty) {
                setFieldError('selectedProperty', true);
              }
            }}
            isMobile={isMax768}
            hasError={errors.selectedProperty && touched.selectedProperty}
            showValueByFields={['name']}
          />

          {errors.selectedProperty && touched.selectedProperty && (
            <BaselaneFormErrorMessage>Please select a property</BaselaneFormErrorMessage>
          )}
        </Skeleton>
      </FormControl>
      {propertyUnits()?.length > 1 && (
        <FormControl
          m={0}
          isRequired={propertyUnits()?.length > 1}
          isInvalid={
            propertyUnits()?.length > 1 &&
            !values?.selectedUnit &&
            errors.selectedUnit &&
            touched.selectedUnit
          }
        >
          <BaselaneFormLabel>Unit</BaselaneFormLabel>
          <Skeleton isLoaded={!loading}>
            <Field
              name="selectedUnit"
              validate={(value) => {
                if (propertyUnits()?.length > 1 && !value) {
                  return 'Please select a unit';
                }
                return undefined;
              }}
            >
              {({ field, form }) => (
                <T1Dropdown
                  classNames={[
                    'auto-width-dropdown',
                    'auto-select-input-width',
                    'input-form-lg',
                    'auto-height',
                    'dateFilter-width-dropdown',
                  ]}
                  placeholder="Select a unit"
                  title="unit"
                  showValueByFields={['name']}
                  itemRenderer={dropdownUnitItem}
                  selectedItem={getSelectedUnit()}
                  data={propertyUnits()}
                  hideSearch
                  isDisabled={isDisabled}
                  handleSubmit={(selectedItemId) => {
                    setFieldValue('selectedUnit', selectedItemId);
                  }}
                  onClose={() => {
                    setFieldTouched('selectedUnit', true);
                    if (!values?.selectedUnit) {
                      setFieldError('selectedUnit', true);
                    }
                  }}
                  isMobile={isMax768}
                  hasError={errors.selectedUnit && touched.selectedUnit}
                />
              )}
            </Field>
          </Skeleton>
          {errors.selectedUnit && (
            <BaselaneFormErrorMessage>Please select a unit</BaselaneFormErrorMessage>
          )}
        </FormControl>
      )}
    </>
  );
};

export default BaselanePropertySelect;
