import { useLocation } from 'react-router-dom';

/**
 * A custom hook that allows debug conditions to be added to your code, and those conditions are triggered
 * using a url variable- ?debug, or ?foo&bar=1&debug
 *
 * @returns {isDebug:Boolean, data:Object} Boolean isDebug is turned on by adding ?debug, or ?foo&bar=1&debug to
 * the URL. Any other URL variables will be added to the data object in debug mode.
 */
const useUrlParams = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const getParamMap = () => {
    const data = {};
    for (const [key, value] of params) {
      data[key] = value;
    }
    return data;
  };

  return {
    params,
    getParamMap,
  };
};

export default useUrlParams;
