import React from 'react';
import { useFormikContext } from 'formik';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { useShallow } from 'zustand/react/shallow';

import { AccountDropdown, BaselaneFileUploader, DocumentCard } from '@shared/components';
import { getValidationMessage } from '@shared/components/BaselaneFileUploader/helpers/baselaneFileUploader.helpers';
import useCSVImportStore from '@store/CSVImport';

import { initialCSVTabList } from '../helpers';
import useIsFlowDirty from '../hooks/useIsFlowDirty';

const UploadCSVFields = ({ setCSVTabList }: { setCSVTabList: Function }) => {
  const { values, setFieldValue } = useFormikContext();

  const [onDeleteOfCSVFile] = useCSVImportStore(useShallow((state) => [state.onDeleteOfCSVFile]));

  const handleUrlCleanup = (objectUrl) => {
    // revoke the old object url to avoid using more memory than needed
    URL.revokeObjectURL(objectUrl);
  };

  const handleDeleteDocAlertOpen = () => {
    setFieldValue('CSVFile', null);
    onDeleteOfCSVFile();
    setCSVTabList(initialCSVTabList);
  };

  useIsFlowDirty();

  return (
    <>
      {values?.CSVFile ? (
        <DocumentCard
          doc={values.CSVFile}
          handleDeleteDocAlertOpen={handleDeleteDocAlertOpen}
          getDownloadUrl={() => {
            const objectUrl = URL.createObjectURL(values.CSVFile);

            setTimeout(() => {
              handleUrlCleanup(objectUrl);
            }, 1000);

            return { generateDocumentDownloadUrl: objectUrl };
          }}
        />
      ) : (
        <BaselaneFileUploader
          allowedFileType={{ enum: ['CSV'], text: ['CSV'] }}
          accept=".csv"
          handleFile={({ file, setErrorMsg, fileSizeMB }) => {
            // Note: See FileHandlers directory in BaselaneFileUploader for current image upload to documents query
            // probably don't need all that for this version
            if (file && fileSizeMB < 10) {
              setFieldValue('CSVFile', file);
            } else {
              const message = getValidationMessage({
                errorType: 'MAX_FILE_SIZE_ERROR',
                allowedFileType: { enum: ['CSV'], text: ['CSV'] },
              });

              setErrorMsg(message);
            }
          }}
        />
      )}

      <FormControl isRequired mb={0}>
        <FormLabel>Tag a bank account to the imported transactions</FormLabel>
        <AccountDropdown
          handleSubmit={(selectedValue) => {
            if (typeof selectedValue === 'object') {
              setFieldValue('account', null);
            } else {
              setFieldValue('account', { id: selectedValue });
            }
          }}
          formValues={values}
        />
      </FormControl>
    </>
  );
};

export default UploadCSVFields;
