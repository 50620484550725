import React, { useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  HStack,
  useDisclosure,
  Spacer,
} from '@chakra-ui/react';
import { useStatsigClient } from '@statsig/react-bindings';
import RightHeaderNav from '@shared/components/BaselaneHeader/components/RightHeaderNav';
import { BaselaneButtonIcon } from '@shared/components';
import { HOME, ONBOARDING } from '@routes';
import userContext from '@contexts/UserContext';
import { Icon16Close, Icon16BurgerMenu } from '@icons/16px';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';
import onNavMenuClickEvents from '@core/components/NavigationAuth/helpers/navEvent.helper';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import NavList from './NavList';
import {
  mobileNavHolderStyles,
  mobileDrawerStyles,
  mobileNavHeaderStyles,
} from './styles/mobilenav.styles';
import { logoContainerStyles, logoInnerContainerStyles } from './styles/nav.styles';

const MobileNav = () => {
  const { user } = useContext(userContext);
  const { pathname } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { checkGate } = useStatsigClient();

  return (
    <>
      {/* Header */}
      <HStack
        {...mobileNavHolderStyles}
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        {user && pathname !== ONBOARDING && (
          <BaselaneButtonIcon
            variant="transparent"
            palette="neutral"
            size="md"
            aria-label="Menu"
            icon={<Icon16BurgerMenu />}
            onClick={onOpen}
            styles={{ width: 'auto' }}
          />
        )}
        <Spacer />
        <RightHeaderNav />
      </HStack>

      {/* Side Nav */}
      <Drawer
        placement="left"
        onClose={onClose}
        isOpen={isOpen}
        autoFocus={false}
        trapFocus={false}
        size="newdawerxs"
      >
        <DrawerOverlay />
        <DrawerContent {...mobileDrawerStyles}>
          <DrawerHeader {...mobileNavHeaderStyles}>
            <HStack>
              {/* Logo */}
              <RouterLink to={HOME} onClick={onClose}>
                <Flex {...logoContainerStyles}>
                  <Flex {...logoInnerContainerStyles}>
                    <BaselaneFullLogo />
                  </Flex>
                </Flex>
              </RouterLink>
              <Spacer />
              <BaselaneButtonIcon
                variant="transparent"
                palette="neutral"
                size="sm"
                icon={<Icon16Close />}
                onClick={onClose}
                styles={{ mr: '16px !important' }}
              />
            </HStack>
          </DrawerHeader>
          <DrawerBody p={0}>
            <NavList
              isOpen={isOpen}
              onClick={(label) => {
                onNavMenuClickEvents(label);
                onClose();
              }}
              isMobileNav
              showTransfersPage={checkGate(FEATURE_GATES.TRANSFERS_PAYMENTS_PAGE)}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MobileNav;
