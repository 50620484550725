import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_USER_ACCESSIBLE_WORKSPACE } from '@core/apollo/queries';

const useUserAccessibleWorkspace = () => {
  const { loading: isLoading, data, refetch, error } = useQuery(GET_USER_ACCESSIBLE_WORKSPACE);

  const accessibleWorkspaceData = useMemo(() => data?.userAccessibleWorkspace ?? [], [
    data?.userAccessibleWorkspace,
  ]);

  return {
    accessibleWorkspaceData,
    isLoading,
    refetch,
    error,
    workspaceCount: accessibleWorkspaceData.length,
  };
};

export default useUserAccessibleWorkspace;
