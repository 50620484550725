import { React } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Text, Box, Divider, HStack, Spacer, useDisclosure, Stack } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { BaselaneDrawer, BaselaneButton, BaselaneCardNew } from '@shared/components';
import SlLoader from '@core/components/Loader';
import useBreakPoints from '@core/hooks/useBreakPoints';
import BaselaneChip from '@core/components/Shared/components/BaselaneChip';
import formatCurrency from '@core/utils/formatCurrency';
import PropertyCardItem from '@core/features/Property/PropertyCardItem';
import CategoryCardItem from '@core/features/Category/CategoryCardItem';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import checkIsTransfer from '@core/utils/checkIsTransfer.helper';
import useScheduledRecurringPaymentDetails from '../scheduled-payments/useScheduledRecurringPaymentDetails.hook';
import ScheduledTransferDetailsDescription from './ScheduledTransferDetailsDescription';
import { labelByTransferState, chipVariantByTransferState } from '../scheduled-payments/constants';
import CancelTransferModal from '../CancelTransferModal';
import CancelPaymentModal from '../CancelPaymentModal';

const ScheduledTransferDetails = () => {
  const { isMax576 } = useBreakPoints();
  const navigate = useNavigate();
  const { bankAccountsById } = useBankAccountsActive();
  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure();

  const getPaymentMethodLabel = (payment) => {
    let label = '';

    if (payment.type === 'TRANSFER_OUT' && payment.toTransferAccountId) {
      label = 'Transfer between';
    }
    if (payment.type === 'TRANSFER_OUT' && !payment.toTransferAccountId) {
      label = 'ACH to';
    }
    if (payment.type === 'CHECK_PAYMENT') {
      label = 'Mail a check to';
    }
    if (payment.type === 'WIRE_TRANSFER') {
      label = 'Wire to';
    }
    if (payment.type === 'INTERNAL_TRANSFER' || payment.type === 'TRANSFER_IN') {
      label = 'Transfer between';
    }

    return `${label}`;
  };

  const navigateBack = () => {
    navigate('..', { relative: 'path' });
  };

  // Th URL at this pointy may look like this: /transfers_payments/scheduled_payments/{scheduledPaymentId}/{transferId} with "scheduledPaymentId" be like {transferId-recurringPaymentId}
  const { scheduledPaymentId, transferId } = useParams();

  const recurringPaymentId = scheduledPaymentId.split('-')[1];

  // Split the URL to get the transferId. It's the second last element in the URL.
  const url = window.location.pathname.split('/');

  // The last one is the date of a specific payment (it acts as a unique identifier).
  const transferNextPaymentId = url[url.length - 1];

  const previousUrl = url.slice(0, url.length - 1).join('/');

  const { data, loading } = useScheduledRecurringPaymentDetails(recurringPaymentId);

  if (loading) {
    return (
      <Box>
        <SlLoader styles={{ position: 'absolute !important' }} />
      </Box>
    );
  }

  const foundTransfer = data?.transfers?.find((transfer) => transfer.id === transferNextPaymentId);

  const paymentMethodLabel = getPaymentMethodLabel(data);
  const isTransfer = checkIsTransfer(data);

  return (
    <>
      <BaselaneDrawer
        isOpen
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        title="Schedule details"
        closeEvent={navigateBack}
        onOverlayClick={navigateBack}
        closeOnOverlayClick={false}
        newDesignDrawer
      >
        <BaselaneCardNew palette="dark">
          <Stack w="100%" gap={1}>
            <Box display="flex">
              <Box flexGrow="1">
                <Text color="brand.neutral.600" textStyle="xs">
                  {paymentMethodLabel}
                </Text>
                <Text textStyle="sm-heavy">
                  {isTransfer ? 'Your accounts' : data.payeePaymentMethod?.payee?.name}
                </Text>
                <ScheduledTransferDetailsDescription recurrencePattern={data.recurrencePattern} />
              </Box>
              <Box display="flex" flexDirection="column" gap={1} alignItems="flex-end">
                <BaselaneChip
                  size="sm"
                  label={labelByTransferState[foundTransfer?.state]}
                  variant={chipVariantByTransferState[foundTransfer?.state]}
                />
                <Text textStyle="headline-lg">
                  {formatCurrency(foundTransfer?.amount).inDollars}
                </Text>
              </Box>
            </Box>
            <Divider my={1} opacity={1} color="brand.darkBlue.200" />
            {/* Fee */}
            {!isTransfer && (
              <Box display="flex" mt={0}>
                <Text textStyle="xs">Processing fee (per payment)</Text>
                <Spacer />
                <Stack gap={0}>
                  <Text textStyle="sm">
                    {data?.type === 'CHECK_PAYMENT'
                      ? formatCurrency(2).inDollars
                      : formatCurrency(0).inDollars}
                  </Text>
                </Stack>
              </Box>
            )}
            {/* From transfer account */}
            <Box display="flex" mt={0}>
              <Text textStyle="xs">From account</Text>
              <Spacer />
              <Box flexDirection="column">
                <Text textStyle="sm" textAlign="right">
                  {bankAccountsById[data.fromTransferAccount.bankAccountId]?.institutionName}
                </Text>
                <Text textStyle="xs" textAlign="right">
                  {bankAccountsById[data.fromTransferAccount.bankAccountId]?.name}
                </Text>
              </Box>
            </Box>
            {/* To transfer account */}
            {isTransfer && (
              <Box display="flex" mt={0}>
                <Text textStyle="xs">To account</Text>
                <Spacer />
                <Box flexDirection="column">
                  <Text textStyle="sm" textAlign="right">
                    {bankAccountsById[data.toTransferAccount.bankAccountId]?.institutionName}
                  </Text>
                  <Text textStyle="xs" textAlign="right">
                    {bankAccountsById[data.toTransferAccount.bankAccountId]?.name}
                  </Text>
                </Box>
              </Box>
            )}
            {/* Sent on */}
            {!isTransfer && (
              <Box display="flex" mt={2}>
                <Text textStyle="xs">Sent on</Text>
                <Spacer />
                <Text textStyle="sm">
                  {DateTime.fromISO(foundTransfer?.transferDate).toFormat('MMM d, yyyy')}
                </Text>
              </Box>
            )}
            {/* Bookkeeping */}
            {(data.propertyId || data.tagId) && (
              <Divider my={1} opacity={1} color="brand.darkBlue.200" />
            )}
            <PropertyCardItem propertyId={data.propertyId} unitId={data.unitId} />
            <CategoryCardItem tagId={data.tagId} />{' '}
            {data?.bookKeepingNote || data?.metadata?.bookKeepingNote ? (
              <HStack mt="8px">
                <Text textStyle="xs">Notes</Text>
                <Spacer />
                <Stack gap={0}>
                  <Text textStyle="sm" textAlign="right">
                    {data?.bookKeepingNote || data?.metadata?.bookKeepingNote}
                  </Text>
                </Stack>
              </HStack>
            ) : null}
          </Stack>
        </BaselaneCardNew>
        {/* Cancel button */}
        {foundTransfer?.state === 'TRANSFER_SCHEDULED' && (
          <Box w="100%" my="2">
            <BaselaneButton
              variant="outline"
              palette="danger"
              size="md"
              isFullWidth
              onClick={() => {
                onDeleteAlertOpen();
              }}
            >
              Cancel {isTransfer ? 'transfer' : 'payment'}
            </BaselaneButton>
          </Box>
        )}
      </BaselaneDrawer>

      {isDeleteAlertOpen && isTransfer ? (
        <CancelTransferModal
          handleClose={onDeleteAlertClose}
          navigateFrom={previousUrl}
          transferId={transferId}
        />
      ) : null}
      {isDeleteAlertOpen && !isTransfer ? (
        <CancelPaymentModal
          handleClose={onDeleteAlertClose}
          navigateFrom={previousUrl}
          transferId={transferId}
        />
      ) : null}
    </>
  );
};

export default ScheduledTransferDetails;
