import React, { useEffect, useState, useImperativeHandle } from 'react';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { Formik } from 'formik';
import { orderBy, pull, isEqual } from 'lodash';
import {
  Spacer,
  Stack,
  HStack,
  Text,
  Box,
  Grid,
  GridItem,
  Input,
  useDisclosure,
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import MaskedInput from 'react-text-mask';
import { GET_ZILLOW_DATA } from '@core/apollo/queries';
import { Icon16Delete } from '@icons/16px';
import IconCheck from '@icons/manual/IconCheck';
import {
  BaselaneDivider,
  CurrencyText,
  BaselaneButton,
  BaselaneButtonIcon,
  BaselaneSingleDatePicker,
} from '@shared/components';
import stripCurrency from '@core/utils/stripCurrency';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { formatDate } from '@core/utils/formatDate';
import {
  headerTitleStyles,
  headerRowStyles,
} from '@shared/components/BaselaneTable/styles/table.style';
import ZillowLogo from './ZillowLogo';
import { currencyMask } from './zillow.helpers';
import {
  getAddressObject,
  isAddressComplete,
  zillowLinkMapper,
} from '../../../helpers/detail.helper';
import {
  drawerMenuContentTitleStyle,
  drawerSmallTitle,
  zillowPrice,
  errorStyles,
} from '../../../styles/drawer.style';
import {
  formInputStyles,
  formInputComponentStyles,
  zillowAddressLinkStyles,
  zillowMarketValueStyles,
  zillowDisclaimerWrapperStyles,
  zillowDisclaimerStyles,
} from '../../../styles/form.style';

function ZillowSetUp({
  property,
  onBackClick,
  initialValues,
  isDirty,
  setIsDirty,
  setFormVariables,
  setHideFooter,
  zillowRef,
}: {
  property: Object,
  onBackClick: Function,
  detailsComplete: boolean,
  initialValues: Object,
  isDirty: boolean,
  setIsDirty: Function,
  setFormVariables: Function,
  setHideFooter: Function,
  zillowRef: Object,
}) {
  const { isMin768 } = useBreakPoints();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const tableHeader = [{ title: 'Date' }, { title: 'Market Value' }];

  const propertyMarketPrices = property?.marketPrice || [];
  const sortedPropertyMarketPrices = orderBy(propertyMarketPrices, ['date'], ['desc']);

  const initialMarketPrices = initialValues?.marketPrice || [];
  const sortedInitialMarketPrices = orderBy(initialMarketPrices, ['date'], ['desc']);
  const [marketPrices, setMarketPrices] = useState(sortedInitialMarketPrices);

  // states to store newPrice
  const [newPrice, setNewPrice] = useState({ date: null, price: null });
  const [price, setPrice] = useState(null);
  const [date, setDate] = useState(null);

  // states for errors
  const [showErrors, setShowErrors] = useState(false);
  const [showDateErrors, setShowDateError] = useState(false);

  // state for isItemLocked (empty first row)
  const [isItemLocked, setIsItemLocked] = useState(false);

  // set up address and check if data is available in zillow for the address user enters in details form
  const address = getAddressObject(initialValues);
  const completedAddressFields = isAddressComplete(address);

  // query for Zillow
  const [getZillowData, { data: { zillowData } = {}, loading, error }] = useLazyQuery(
    GET_ZILLOW_DATA
  );

  const checkIsUniqueDate = (d) => {
    const formattedDate = moment.utc(d).format();
    return !marketPrices?.some((p) => p.date === formattedDate);
  };

  const clearFields = () => {
    setIsItemLocked(false);
    setPrice(null);
    setDate(null);
    setNewPrice({ date: null, price: null });
  };

  const renderAddress = () => {
    const { address: streetAddress, unit, city, state, zipCode } = address;
    const addressUnit = unit ? `${unit},` : '';

    const handleZillowAddressClick = () => {
      const url = zillowData?.zillowURL;
      window.open(url, '_blank').focus();
    };

    const addressText = (
      <Box
        as="span"
        onClick={handleZillowAddressClick}
        {...zillowAddressLinkStyles}
      >{`${streetAddress}, ${addressUnit} ${city}, ${state} ${zipCode}`}</Box>
    );
    return addressText;
  };

  const handleShowDateError = (d) => {
    if (checkIsUniqueDate(d)) {
      setShowDateError(false);
    } else {
      setShowDateError(true);
    }
  };

  const handlePriceChange = (e) => {
    const priceToBeUpdated = e.target.value;
    setShowErrors(false);
    handleShowDateError(date);
    setPrice(priceToBeUpdated);

    setNewPrice({
      date: date ? moment.utc(date).format() : date,
      price: stripCurrency(priceToBeUpdated),
    });
  };

  const handleDateChange = ({ date: d, isFromApplyButton }) => {
    if (isMin768 || (!isMin768 && isFromApplyButton)) {
      const dateToBeUpdated = d;
      const formattedDateToBeUpdated = moment.utc(dateToBeUpdated).format();
      setShowErrors(false);
      handleShowDateError(dateToBeUpdated);
      setDate(dateToBeUpdated);

      setNewPrice({
        date: dateToBeUpdated ? formattedDateToBeUpdated : null,
        price: stripCurrency(price),
      });
    }
  };

  const handleOnBackButtonClick = () => {
    let sortedNewMarketPrices;
    if (isItemLocked && newPrice.date && newPrice.price) {
      const newMarketPrices = [...marketPrices, newPrice];
      sortedNewMarketPrices = orderBy(newMarketPrices, ['date'], ['desc']);
    } else {
      // if user clicked on delete or add without making any edits
      sortedNewMarketPrices = orderBy(marketPrices, ['date'], ['desc']);
    }
    const isSameArr = isEqual(sortedPropertyMarketPrices, sortedNewMarketPrices);
    if (!isSameArr) {
      setMarketPrices(sortedNewMarketPrices);
      setFormVariables({ marketPrice: sortedNewMarketPrices });
      setIsDirty({ ...isDirty, ...{ currentMarketValue: true } });
    } else {
      setMarketPrices(sortedPropertyMarketPrices);
      setFormVariables({ marketPrice: sortedPropertyMarketPrices });
      setIsDirty({ ...isDirty, ...{ currentMarketValue: false } });
    }

    setHideFooter(false);
    clearFields();
    onBackClick();
  };

  // exposing back button function to parent
  useImperativeHandle(zillowRef, () => ({
    handleOnBackButtonClick,
  }));

  const handleDeletePrice = (item) => {
    return () => {
      const newMarketPrices = [...marketPrices];
      pull(newMarketPrices, item);
      if (showDateErrors && newPrice.date === item.date) {
        setShowDateError(false);
      }
      setMarketPrices(newMarketPrices);
    };
  };

  const handleAddMarketPrice = () => {
    if (newPrice.date && newPrice.price) {
      const newMarketPrices = [...marketPrices, newPrice];
      const sortedNewMarketPrices = orderBy(newMarketPrices, ['date'], ['desc']);
      setMarketPrices(sortedNewMarketPrices);
      clearFields();
    } else {
      setShowErrors(true);
    }
  };

  const handlePasteZillowPrice = () => {
    const dateToBeUpdated = new Date();
    dateToBeUpdated.setHours(0, 0, 0);
    const formattedDateToBeUpdated = moment.utc(dateToBeUpdated).format();
    setShowErrors(false);
    handleShowDateError(dateToBeUpdated);
    setDate(dateToBeUpdated);
    setPrice(zillowData?.zillowMarketPrice);
    setNewPrice({
      date: formattedDateToBeUpdated,
      price: zillowData?.zillowMarketPrice,
    });
  };

  const handleZillowDisclaimerLinkClick = (linkKey) => {
    const url = zillowLinkMapper[linkKey];
    window.open(url, '_blank').focus();
  };

  const handleLockItem = () => {
    setIsItemLocked(true);
  };

  const handleLockedItemDelete = () => {
    clearFields();
  };

  useEffect(() => {
    if (completedAddressFields) {
      getZillowData({
        variables: {
          address,
        },
      });
    }
  }, [completedAddressFields]);

  return (
    <Box h="100%">
      <Stack direction="row" alignItems="flex-start">
        <Stack direction="row" alignItems="center">
          <Text {...drawerMenuContentTitleStyle} ml="13px !important">
            Market Value
          </Text>
        </Stack>
        <Spacer />
        <Stack direction="row" pr="32px">
          <Stack alignItems="flex-end">
            <Stack direction="row">
              <Box textAlign="right">
                <Text {...drawerSmallTitle}>Zestimate®</Text>
                <Box {...zillowPrice} fontWeight="medium" mt="4px">
                  {zillowData?.zillowMarketPrice && !loading && !error ? (
                    <CurrencyText
                      isRounded={false}
                      {...zillowMarketValueStyles}
                      negativeColor="brand.neutral.white"
                      amount={zillowData?.zillowMarketPrice}
                    />
                  ) : (
                    <Text fontSize="3xs">Not Available</Text>
                  )}
                </Box>
              </Box>
              <BaselaneDivider isVertical styles={{ mx: '32px !important' }} />
              <ZillowLogo />
            </Stack>
            {zillowData?.zillowMarketPrice && !loading && !error ? (
              <Text fontSize="3xs">See more details for {renderAddress()} on Zillow</Text>
            ) : (
              <Text fontSize="3xs">
                To enable Zestimate® please ensure your address is correct, including the Apartment
                or Unit
              </Text>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Box m="36px 32px 16px 0" display="flex" justifyContent="flex-end">
        <BaselaneButton
          variant="tonal"
          palette="primary"
          onClick={handleAddMarketPrice}
          isDisabled={
            showErrors || showDateErrors || !newPrice.date || !newPrice.price || !isItemLocked
          }
        >
          Add New Market Value
        </BaselaneButton>
      </Box>
      <Formik initialValues={initialValues} validateOnBlur>
        {() => (
          <Box id="zillowForm" mr="32px" h="calc(100% - 173px)">
            <Grid templateColumns="repeat(2, 1fr)" {...headerRowStyles}>
              {tableHeader.map((item) => {
                const key = uuidv4();
                return (
                  <GridItem key={key}>
                    <Text {...headerTitleStyles}>{item.title}</Text>
                  </GridItem>
                );
              })}
            </Grid>
            <Box
              position="relative"
              h="calc(100% - 88px)"
              top="0"
              className="test"
              overflow="auto"
              pt="22px"
            >
              <Grid templateColumns="repeat(2, 1fr)" px="45px" mb="20px">
                <GridItem {...formInputStyles}>
                  <BaselaneSingleDatePicker
                    {...{
                      id: 'date',
                      name: 'date',
                      maxDate: moment().toDate(),
                      date,
                      value: date ? `${formatDate(date, 'MMM DD, YYYY')}` : '',
                      dateFormat: 'MMM dd, yyyy',
                      handleCalendarClose: handleDateChange,
                      size: 'md',
                      years: { start: 2010, end: new Date().getFullYear() },
                      isOpen,
                      onOpen,
                      onClose,
                      hideInputIcon: true,
                      isDisabled: isItemLocked,
                      placement: 'bottom-start',
                      showInModal: !isMin768,
                      className: 'w-200',
                    }}
                  />
                </GridItem>
                <GridItem display="flex" justifyContent="space-between">
                  <Input
                    {...formInputComponentStyles}
                    as={MaskedInput}
                    value={price}
                    onChange={handlePriceChange}
                    placeholder="$"
                    mask={currencyMask}
                    readOnly={isItemLocked}
                    color={isItemLocked && 'brand.darkBlue.300'}
                  />

                  {zillowData?.zillowMarketPrice && !isItemLocked && (
                    <BaselaneButton
                      variant="outline"
                      palette="neutral"
                      onClick={handlePasteZillowPrice}
                      isDisabled={!showErrors && !showDateErrors && newPrice.date && newPrice.price}
                    >
                      Paste Zestimate®
                    </BaselaneButton>
                  )}

                  {isItemLocked ? (
                    <BaselaneButtonIcon
                      variant="outline"
                      palette="neutral"
                      icon={<Icon16Delete />}
                      onClick={handleLockedItemDelete}
                    />
                  ) : (
                    <BaselaneButtonIcon
                      variant="filled"
                      palette="primary"
                      icon={<IconCheck />}
                      onClick={handleLockItem}
                      isDisabled={showErrors || showDateErrors || !newPrice.date || !newPrice.price}
                    />
                  )}
                </GridItem>
              </Grid>
              {showErrors && (
                <Text {...errorStyles} pl="45px">
                  Please enter both fields
                </Text>
              )}
              {showDateErrors && (
                <Text {...errorStyles} pl="45px">
                  Please enter a unique date
                </Text>
              )}

              <BaselaneDivider styles={{ m: '0' }} />

              {marketPrices.length > 0 &&
                marketPrices.map((item) => {
                  const key = uuidv4();
                  return (
                    <Box key={key}>
                      <Grid templateColumns="repeat(2, 1fr)" px="45px" my="20px">
                        <GridItem>
                          <BaselaneSingleDatePicker
                            {...{
                              id: 'date',
                              name: 'date',
                              date: moment(item.date).toDate(),
                              dateFormat: 'MMM dd, yyyy',
                              size: 'md',
                              years: { start: 2010, end: new Date().getFullYear() },
                              hideInputIcon: true,
                              isDisabled: true,
                              placement: 'bottom-start',
                              className: 'w-200',
                            }}
                          />
                        </GridItem>
                        <GridItem>
                          <Stack direction="row">
                            <Input
                              {...formInputComponentStyles}
                              as={MaskedInput}
                              value={item.price}
                              placeholder="$"
                              mask={currencyMask}
                              readOnly
                            />
                            <Spacer />
                            <BaselaneButtonIcon
                              variant="tonal"
                              palette="primary"
                              icon={<Icon16Delete />}
                              onClick={handleDeletePrice(item)}
                            />
                          </Stack>
                        </GridItem>
                      </Grid>
                      <BaselaneDivider styles={{ m: '0' }} />
                    </Box>
                  );
                })}
            </Box>
          </Box>
        )}
      </Formik>
      <HStack {...zillowDisclaimerWrapperStyles}>
        <Text>© Zillow, Inc., 2006-2021. Use is subject to </Text>
        <Box
          as="span"
          {...zillowDisclaimerStyles}
          _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => handleZillowDisclaimerLinkClick('terms_of_use')}
        >
          Terms of Use
        </Box>
        <Box as="span" {...zillowDisclaimerStyles} color="inherit">
          |
        </Box>
        <Box
          as="span"
          {...zillowDisclaimerStyles}
          _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => handleZillowDisclaimerLinkClick('zestimate')}
        >
          What&apos;s a Zestimate?
        </Box>
      </HStack>
    </Box>
  );
}

export default ZillowSetUp;
