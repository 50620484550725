// @flow
import React, { useState } from 'react';
import { Tabs } from '@chakra-ui/react';
import UserProfileTabsList from './WorkspaceTabsList';
import UserProfileTabsPanels from './WorkspaceTabsPanels';

type UserProfileTabsProps = {
  tabIndex: Number,
  setTabIndex: Function,
  activeTabIndex: Number,
  searchParams: Number,
  setSearchParams: Function,
};

function UserProfileTabs({
  tabIndex,
  setTabIndex,
  activeTabIndex,
  searchParams,
  setSearchParams,
}: UserProfileTabsProps): any {
  const [isDirty, setIsDirty] = useState(false);
  const [isTabChange, setIsTabChange] = useState({ change: false, tab: 0 });

  const onTabChange = (index) => {
    if (isDirty) {
      setIsTabChange({ change: true, tab: index });
    } else {
      setIsTabChange({ change: false, tab: index });
      setTabIndex(index);
    }

    if (activeTabIndex) {
      searchParams.delete('activeTabIndex');
      setSearchParams(searchParams);
    }
  };

  return (
    <Tabs index={tabIndex} onChange={(index) => onTabChange(index)} h="inherit" isLazy>
      <UserProfileTabsList />
      <UserProfileTabsPanels
        tabIndex={tabIndex}
        isTabChange={isTabChange}
        setIsTabChange={setIsTabChange}
        setTabIndex={setTabIndex}
        setIsDirty={setIsDirty}
      />
    </Tabs>
  );
}

export default UserProfileTabs;
