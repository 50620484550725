import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Box,
  Flex,
  Heading,
  Skeleton,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { Icon16Filter, Icon16Sparkle } from '@icons/16px';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import useCurrentWorkspace from '@shared/hooks/useCurrentWorkspace';
import { AUTO_TAG_ONBOARDING, TRANSACTIONS } from '@routes';
import { BaselaneAlertNew, BaselaneButton } from '@shared/components';
import { IllustrationTada } from '@core/components/Illustrations';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { IconBankPlusNew } from '@core/components/Icons';
import { GET_CONNECTED_BANK_ACCOUNTS_LIGHT } from '@core/apollo/queries';

type Props = {
  hasBaselaneBank: Boolean,
};

const AutoTagPromoModal = ({ hasBaselaneBank }: Props) => {
  const { isMin768 } = useBreakPoints();
  const navigate = useNavigate();
  const { workspaceMetadata } = useCurrentWorkspace();
  const { metadata } = workspaceMetadata || {};
  const [open, setOpen] = useState(false);

  const { data, loading } = useQuery(GET_CONNECTED_BANK_ACCOUNTS_LIGHT, {
    fetchPolicy: 'cache-first',
  });

  const connectedAccounts = data?.bank?.filter((bankAccount) => {
    const { isConnected, isExternal, unitAccount } = bankAccount;
    return isConnected && !isExternal && unitAccount?.unitApplicationStatus === 'COMPLETED';
  });

  useEffect(() => {
    const suppressed = localStorage.getItem('autotagging-promo-modal-closed');
    if (!metadata?.hasSeenAutoTagPromo && !suppressed) {
      setOpen(true);
      sendSegmentEvent('autotagging_gtm_modal_opened', {
        hasBaselaneBank,
      });
    }
  }, []);

  return (
    <Modal
      isOpen={open}
      isCentered
      onClose={() => {
        setOpen(false);
        localStorage.setItem('autotagging-promo-modal-closed', true);
      }}
    >
      <ModalOverlay />
      <ModalContent minWidth={isMin768 ? '600px' : 'auto'}>
        <ModalHeader fontWeight="normal" pl={4} pr={4} pb={0} pt={3}>
          <IllustrationTada />
          <Heading size="headline-xl" mb={0.5} mt={0.5}>
            Fully automated bookkeeping is here
          </Heading>
          <Text textStyle="sm">
            Save hours every month with Baselane’s new and powerful auto-tagging features.
          </Text>
          <ModalCloseButton id="autotag-modal-close" />
        </ModalHeader>
        <ModalBody data-cy="popup-body" pl={4} pr={4} pt={4} pb={0}>
          <Skeleton isLoaded={!loading}>
            {connectedAccounts?.length === 0 && (
              <BaselaneAlertNew
                mb={4}
                visual="illustration"
                iconName={IconBankPlusNew}
                variant="warning"
                title="Open a free Baselane Banking account to unlock automated bookkeeping"
                body="You will not be able to use AI categorization or have full access to custom bookkeeping rules without a Baselane Banking account."
              />
            )}
          </Skeleton>
          <Flex
            border="1px solid"
            borderColor="brand.darkBlue.200"
            borderRadius="8px 8px 0px 0px"
            p={2}
            gap={2}
          >
            <Box mt={0.5}>
              <Icon16Filter />
            </Box>
            <Box>
              <Heading size="headline-md">Save time with AI categorization</Heading>
              <Text textStyle="sm">
                Get all your transactions automatically categorized using Baselane’s AI.
              </Text>
            </Box>
          </Flex>
          <Flex
            border="1px solid"
            borderColor="brand.darkBlue.200"
            borderTop="none"
            borderRadius="0px 0px 8px 8px"
            p={2}
            gap={2}
          >
            <Box mt={0.5}>
              <Icon16Sparkle />
            </Box>
            <Box>
              <Heading size="headline-md">Personalize it further with bookkeeping rules</Heading>
              <Text textStyle="sm">
                Set up custom rules to auto-tag transactions to specific categories and properties.
              </Text>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter pt={3} pb={3} pl={4} pr={4}>
          <BaselaneButton
            palette="primary"
            variant="filled"
            w="100%"
            size="lg"
            id="autotag-modal-continue"
            onClick={() => {
              navigate(`${TRANSACTIONS}/${AUTO_TAG_ONBOARDING}`);
              setOpen(false);
            }}
          >
            Continue
          </BaselaneButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AutoTagPromoModal;
