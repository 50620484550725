import React from 'react';
import { Skeleton, Stack, Tbody, Text } from '@chakra-ui/react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import {
  BaselaneChip,
  BaselaneResponsiveCell,
  BaselaneResponsiveCellAmountContent,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveTableSpacer,
} from '@shared/components';
import { LEASES } from '@routes';
import { getFormattedPaymentSummary } from '@pages/LeasesPage/PaymentSummary/helpers/paymentSummary.helpers';
import { GET_INVOICE_LIST } from '@pages/LeasesPage/queries';
import { truncatedText } from '@shared/styles/text.style';

import useBreakPoints from '@core/hooks/useBreakPoints';
import { getAmountColor } from '../helper';
import { mobileTableConfig, tableConfig } from './config';

type RCStatusTableProps = {
  isInvoiceSummaryLoading: boolean,
  invoiceSummaryData: Object,
};

const RCStatusTable = ({ isInvoiceSummaryLoading, invoiceSummaryData }: RCStatusTableProps) => {
  const navigate = useNavigate();

  const { isMin768 } = useBreakPoints();

  const [getLeaseInvoices] = useLazyQuery(GET_INVOICE_LIST, {
    variables: {
      input: { dateType: 'DUE_DATE' },
    },
  });

  const {
    invoiceCompletedSummary = {},
    invoiceOutstandingSummary = {},
    invoiceFailedSummary = {},
    invoiceOverdueSummary = {},
    invoiceProcessingSummary = {},
  } = invoiceSummaryData?.invoiceSummary ?? {};

  const paymentSummaryData = [
    { ...invoiceOutstandingSummary, state: 'PAYMENT_OUTSTANDING' },
    { ...invoiceProcessingSummary, state: 'PAYMENT_PROCESSING' },
    { ...invoiceOverdueSummary, state: 'PAYMENT_OVERDUE' },
    { ...invoiceCompletedSummary, state: 'PAYMENT_COMPLETE' },
  ];

  // add failed payment info if count is greater than 0
  if (invoiceFailedSummary?.count > 0) {
    paymentSummaryData.splice(2, 0, {
      ...invoiceFailedSummary,
      state: 'PAYMENT_FAILED',
    });
  }

  const handleOnRowClick = (input, filters) => {
    getLeaseInvoices({ variables: { input: input || {} } });
    navigate({
      pathname: LEASES,
      // utilizing current deeplink logic for payment summary drawers
      search: createSearchParams({
        state: filters.state,
        dateType: filters.dateType,
        date: filters.date,
      }).toString(),
    });
  };

  const tableItems = paymentSummaryData.map((item) => {
    return getFormattedPaymentSummary(item);
  });

  return (
    <BaselaneResponsiveTable
      config={{
        columns: isMin768 ? tableConfig : mobileTableConfig,
      }}
    >
      <Tbody>
        <BaselaneResponsiveTableRows
          customRow
          items={tableItems}
          renderItem={(
            { chip, formattedAmount, text, timeStatus, count, icon, input, segmentEvent, filters },
            index
          ) => {
            const isDisabled = formattedAmount === '$0.00';

            return isMin768 ? (
              <BaselaneResponsiveTableRow
                onClick={() => {
                  if (!isDisabled) {
                    handleOnRowClick(input, filters);
                  }
                }}
                showBorder={index !== tableItems.length - 1}
                isDisabled={isDisabled}
              >
                <BaselaneResponsiveCell configIndex={0}>
                  <Skeleton isLoaded={!isInvoiceSummaryLoading}>
                    {isDisabled ? (
                      <BaselaneChip
                        isDisabled
                        size="sm"
                        leftIcon={icon}
                        label={text}
                        variant="simple-neutral"
                      />
                    ) : (
                      chip
                    )}
                  </Skeleton>
                </BaselaneResponsiveCell>
                <BaselaneResponsiveCell
                  configIndex={1}
                  contentContainerStyles={{
                    justifyContent: 'flex-end',
                  }}
                >
                  <Skeleton isLoaded={!isInvoiceSummaryLoading}>
                    <BaselaneResponsiveCellAmountContent
                      textStyle="data-sm"
                      color={getAmountColor(isDisabled ? 'disabled' : text)}
                    >
                      {formattedAmount}
                    </BaselaneResponsiveCellAmountContent>
                  </Skeleton>
                </BaselaneResponsiveCell>
                <BaselaneResponsiveTableSpacer configIndex={2} />
                <BaselaneResponsiveCell configIndex={3} pr={0}>
                  <Skeleton isLoaded={!isInvoiceSummaryLoading} overflow="hidden">
                    <Text
                      textStyle="sm"
                      color={isDisabled ? 'brand.neutral.500' : 'brand.neutral.700'}
                      {...truncatedText}
                    >
                      {timeStatus} &#x2022; {count}
                    </Text>
                  </Skeleton>
                </BaselaneResponsiveCell>
              </BaselaneResponsiveTableRow>
            ) : (
              <BaselaneResponsiveTableRow
                onClick={() => {
                  if (!isDisabled) {
                    handleOnRowClick(input, filters);
                  }
                }}
                showBorder={index !== tableItems.length - 1}
                isDisabled={isDisabled}
                chevronCellStyles={{ display: 'none' }}
              >
                <BaselaneResponsiveCell
                  configIndex={0}
                  contentContainerStyles={{
                    justifyContent: 'space-between',
                  }}
                  pl={0}
                >
                  <Stack spacing={0.5} overflow="hidden">
                    <Skeleton isLoaded={!isInvoiceSummaryLoading}>
                      {isDisabled ? (
                        <BaselaneChip
                          isDisabled
                          size="sm"
                          leftIcon={icon}
                          label={text}
                          variant="simple-neutral"
                        />
                      ) : (
                        chip
                      )}
                    </Skeleton>
                    <Skeleton isLoaded={!isInvoiceSummaryLoading} overflow="hidden">
                      <Text
                        textStyle="xs"
                        color={isDisabled ? 'brand.neutral.500' : 'brand.neutral.700'}
                        {...truncatedText}
                      >
                        {timeStatus} &#x2022; {count}
                      </Text>
                    </Skeleton>
                  </Stack>

                  <Skeleton
                    isLoaded={!isInvoiceSummaryLoading}
                    overflow="hidden"
                    {...truncatedText}
                    flexShrink={0}
                    maxWidth="170px"
                  >
                    <BaselaneResponsiveCellAmountContent
                      textStyle="data-sm"
                      color={getAmountColor(isDisabled ? 'disabled' : text)}
                    >
                      {formattedAmount}
                    </BaselaneResponsiveCellAmountContent>
                  </Skeleton>
                </BaselaneResponsiveCell>
              </BaselaneResponsiveTableRow>
            );
          }}
        />
      </Tbody>
    </BaselaneResponsiveTable>
  );
};

export default RCStatusTable;
