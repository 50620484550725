import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useApolloClient, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { UNIFIED_RENT_COLLECTION_SUCCESS } from '@routes';
import HeaderNavWrapper from '@shared/layouts/HeaderNavWrapper';
import { AlertFooter, AlertHeader, BaselaneAlert } from '@shared/components';
import {
  CREATE_OR_UPDATE_LEASE,
  DELETE_LEASE,
  GET_PROPERTIES_AND_LEASES,
} from '@pages/LeasesPage/queries';
import useBreakPoints from '@core/hooks/useBreakPoints';
import SlLoader from '@core/components/Loader';
import useCreateLeaseVariables from '@pages/LeasesPage/LeaseSection/hooks/useCreateLeaseVariables';
import toasts from '@pages/LeasesPage/LeaseSection/helpers/toasts';
import {
  cleanUpPayments,
  cleanUpSave,
} from '@pages/LeasesPage/LeaseSection/helpers/createLease.helpers';
import { calculateTabIndex } from '@pages/UnifiedRentCollection/UnifiedLeaseUI/helpers/unifiedLeaseFlow.helpers';
import { leftHeaderContentStyles } from '@pages/UnifiedRentCollection/UnifiedLeaseUI/styles/unifiedLease.styles';

import useUrlParams from '@hooks/useUrlParams';
import ExistingDraftModal from './components/ExistingDraftModal';
import LeaseFormTabs from './LeaseFormTabs';
import { initialMenuState, LEASE_TABS } from './initialMenuState';

type UnifiedLeaseFlowProps = {
  bankProps: Object,
  sortedProperties: Array,
  singleUnitId: string,
  refetchLeaseDetail: Function,
  loadingObject: Object,
};

const UnifiedLeaseFlow = ({
  bankProps = ({}: { ... }),
  sortedProperties,
  singleUnitId,
  refetchLeaseDetail,
  loadingObject,
}: UnifiedLeaseFlowProps) => {
  const { cache: apolloCache } = useApolloClient();
  const { isMax768 } = useBreakPoints();
  const { params } = useUrlParams();

  const navigate = useNavigate();
  const [firstRun, setFirstRun] = useState(true);

  // Alert States
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure();

  // Existing Draft Modal State
  const [existingDraftModalOpen, setExistingDraftModalOpen] = useState(false);

  // Query unit id (for url param and determining unit)
  const [queryUnitId, setQueryUnitId] = useState(params.get('unit'));
  let queryUnit;
  const queryProperty = sortedProperties.find((property) => {
    queryUnit = property.units.find((foundUnit) => {
      return foundUnit.id === queryUnitId;
    });
    return !!queryUnit;
  });

  // If we refresh, locally stored unit
  let localStorageUnit;
  const localStorageUnitId = localStorage.getItem('unit') || queryUnitId;
  const localStorageProperty = sortedProperties.find((property) => {
    localStorageUnit = property.units.find((foundUnit) => {
      return foundUnit.id === localStorageUnitId;
    });
    return !!localStorageUnit;
  });

  // If there is only one property or unit, pre-select that one
  let singleAvailableUnit;
  const singleAvailableUnitId =
    sortedProperties.length === 1 && !singleUnitId
      ? sortedProperties[0].units[0]?.id
      : singleUnitId;
  const singleAvailableProperty =
    sortedProperties.length === 1 && !singleUnitId
      ? sortedProperties[0]
      : sortedProperties.find((property) => {
          singleAvailableUnit = property.units.find((foundUnit) => {
            return foundUnit.id === singleAvailableUnitId;
          });
          return !!singleAvailableUnit;
        });

  // delete or update Lease on B.E.
  const [setLeaseData] = useMutation(CREATE_OR_UPDATE_LEASE);
  const [deleteLease] = useMutation(DELETE_LEASE);

  // tab 0 states
  const [selectingUnitPanel, setSelectingUnitPanel] = useState(false);
  const [addingPropertyPanel, setAddingPropertyPanel] = useState(false);

  // set initial propertyUnit to queryId || storedId || first unit if there's only one
  const initialSelectedProperty = queryProperty || localStorageProperty || singleAvailableProperty;
  const initialSelectedUnit = queryUnit || localStorageUnit || singleAvailableUnit;
  const initialRadioValue = queryUnit?.id || initialSelectedUnit?.id;
  const [radioValue, setRadioValue] = useState(initialRadioValue);
  const [unit, setSelectedUnit] = useState(initialSelectedUnit);
  const [selectedProperty, setSelectedProperty] = useState(initialSelectedProperty);
  const isPropertyPanelValid = !!unit?.id;
  const [saving, setSaving] = useState(false);
  const [formInstance, setFormInstance] = useState(true);

  // clicked property, unit object, pre-alert / confirmation decision
  const [clickedPropertyUnit, setClickedPropertyUnit] = useState({
    clickedProperty: initialSelectedProperty,
    clickedUnit: initialSelectedUnit,
  });

  // use hook for variables and states
  const { variablesAndStates, helperFunctions, refVariables } = useCreateLeaseVariables(
    unit,
    initialMenuState,
    LEASE_TABS,
    bankProps,
    isPropertyPanelValid,
    false
  );

  const {
    unitState,
    variables,
    setLeaseVariables,
    existingTenantState,
    setExistingTenantState,
    tabIndex,
    setTabIndex,
    leaseId,
    setLeaseId,
    leaseMenuOptions,
    setLeaseMenuOptions,
    hasConnectedBank,
    setHasConnectedBank,
    showReceivingBAEmptyState,
    setShowReceivingBAEmptyState,
    leaseCreated,
    initialVariables,
    lateFeePayments,
  } = variablesAndStates;

  const {
    isSectionValid,
    resetValue,
    handleCreateLeaseError,
    saveTenantProfileId,
    handleCreateLeaseSetUp,
    setAllFormVariables: setFormVariables,
    sendTenantPendingEvent,
    resetCreateLeaseStates,
    setLateFeePayments,
  } = helperFunctions;

  const {
    addPropertyRef,
    leaseTermsRef,
    depositsFeesRef,
    invoicesRef,
    bankAccountsRef,
    tenantDetailsRef,
    confirmRef,
  } = refVariables;

  useEffect(() => {
    if (sortedProperties?.length === 0) {
      // double check once if sortedProperties is empty
      refetchLeaseDetail();
    } else {
      const updatedProperty = sortedProperties?.find(
        (property) => property.id === selectedProperty?.id
      );
      const updatedUnit = updatedProperty?.units.find((u) => u.id === unit?.id);
      if (updatedProperty && updatedUnit) {
        setSelectedProperty(updatedProperty);
        setSelectedUnit(updatedUnit);
      }
      if (clickedPropertyUnit) {
        const updatedClickedProperty = sortedProperties?.find(
          (property) => property.id === clickedPropertyUnit.clickedProperty?.id
        );
        const updatedClickedUnit = updatedClickedProperty?.units.find(
          (u) => u.id === clickedPropertyUnit.clickedUnit?.id
        );
        if (updatedClickedProperty && updatedClickedUnit) {
          setClickedPropertyUnit({
            clickedProperty: updatedClickedProperty,
            clickedUnit: updatedClickedUnit,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedProperties]);

  const { name } = unit || {};
  const {
    showCreateLeaseSuccessToast,
    showDeleteLeaseSuccessToast,
    showDeleteLeaseErrorToast,
    showLeaseReplacePropertyToast,
    showLeaseResumePropertyToast,
  } = toasts(name);

  function saveLease({
    newVariables = {},
    isCreateLeaseSave = false,
    isStartNewLease = false,
    isFromDelete = false,
    isFromModal = false,
    onFinishEvent = null,
    isFromQuery = false,
  } = {}) {
    // Only save after Continue, show modal if clicked on another lease than the one loaded
    const { clickedProperty, clickedUnit } = clickedPropertyUnit;
    if (
      !isFromDelete &&
      !isFromModal && // not from existing draft modal
      tabIndex === 0 &&
      leaseId &&
      (!clickedUnit?.lease?.id || (clickedUnit?.lease?.id && leaseId !== clickedUnit?.lease?.id))
    ) {
      setTabIndex(0);
      setExistingDraftModalOpen(true);
    } else {
      const result = cleanUpSave({
        variables,
        newVariables,
        isFromModal,
        isStartNewLease,
        clickedUnit: isFromQuery ? clickedUnit || queryUnit : clickedUnit,
        unit: isFromQuery ? unit || queryUnit : unit,
        tabIndex,
        LEASE_TABS,
        leaseId: isFromQuery ? leaseId || queryUnit?.lease?.id : leaseId,
      });

      // Check if user created a late fee payment
      const lateFeePayMent = lateFeePayments?.find((fee) => fee.name === 'LATE_FEE');

      // Check if user created a daily late fee payment
      const dailyLateFeePayment = lateFeePayments?.find((fee) => fee.name === 'DAILY_LATE_FEE');

      // Overwrite the late fee payment with the user-created one if it has data
      // Overwrite the daily late fee payment with the user-created one if it has data
      const newPayments = result?.payments?.map((payment) => {
        if (payment.name === 'LATE_FEE' && lateFeePayMent) {
          const {
            amount,
            lateFeeMetadata: { initialFeeValue },
          } = lateFeePayMent;

          if (
            (amount !== null || amount !== 0) &&
            (initialFeeValue !== null || initialFeeValue !== 0)
          ) {
            return lateFeePayMent;
          }
        }

        if (payment.name === 'DAILY_LATE_FEE' && dailyLateFeePayment) {
          const {
            amount,
            lateFeeMetadata: { dailyFeeValue },
          } = lateFeePayMent;

          if (
            (amount !== null || amount !== 0) &&
            (dailyFeeValue !== null || dailyFeeValue !== 0)
          ) {
            return dailyLateFeePayment;
          }
        }
        return payment;
      });

      const finalResult = { ...result, payments: newPayments };

      // always save lease to current selected unit.id, and leaseId if existing
      if (!saving && (unit?.id || result?.propertyUnitId)) {
        // make sure some useEffect cannot force double saves
        setSaving(true);

        // setLeaseData, and if I'm not adding payments, I don't have to add or clean them
        setLeaseData({
          variables: { ...finalResult },
        })
          .then((res) => {
            setSaving(false);
            if (res.errors?.length > 0) handleCreateLeaseError(res.errors);
            else {
              const resLease = res.data?.createOrUpdateLease || {};
              const { id, state, completedTags: cTags, payments } = resLease;

              if (state === 'CANCELED') {
                resetValue();
              } else {
                setLeaseId(id);
                isSectionValid(cTags);
                const leaseVariables = {
                  ...resLease,
                  payments: cleanUpPayments({ payments }),
                };
                // make sure there is no null invoicesConfirmed value
                if (cTags.indexOf('INVOICES')) delete leaseVariables.invoicesConfirmed;

                setLeaseVariables(leaseVariables);
                setTimeout(() => {
                  // timeout to make sure contents of saved (loaded) lease have been set
                  setExistingDraftModalOpen(false);
                }, 500);

                if (clickedUnit) {
                  setSelectedProperty(clickedProperty);
                  setSelectedUnit(clickedUnit || clickedProperty);
                  setRadioValue(clickedUnit.id?.toString());
                  setQueryUnitId(null);
                }
                if (tabIndex === 0 && (isFromModal || !isStartNewLease)) {
                  const newTabIndex = calculateTabIndex(
                    res.data?.createOrUpdateLease?.completedTags
                  );
                  if (newTabIndex !== tabIndex) {
                    // set correct tabIndex
                    setTabIndex(newTabIndex);
                  }
                }

                const tenantId = res.data?.createOrUpdateLease?.tenantProfileId;

                if (isCreateLeaseSave) {
                  // to clear cache so it can get updated values when going back to tenant page
                  // if this then gets converted to be in update function in apollo remove import for apolloCache
                  apolloCache.evict({
                    id: 'ROOT_QUERY',
                    fieldName: 'landLordTenantSummary',
                  });
                  apolloCache.gc();

                  sendTenantPendingEvent();
                  showCreateLeaseSuccessToast();
                  setLeaseVariables(initialVariables);
                  const inviteLink = res.data?.createOrUpdateLease?.tenantProfile?.inviteLink
                    ? res.data?.createOrUpdateLease?.tenantProfile?.inviteLink[0]?.link
                    : null;
                  const tenantInvite = {
                    unitTenantId: tenantId,
                    unitLeaseId: id,
                    inviteLink,
                  };
                  localStorage.removeItem('tenantInvite');
                  localStorage.setItem('tenantInvite', JSON.stringify(tenantInvite));
                  localStorage.removeItem('isActiveTenant');
                  localStorage.setItem(
                    'isActiveTenant',
                    res.data?.createOrUpdateLease?.tenantProfile?.status === 'ACTIVE'
                  );
                  navigate(UNIFIED_RENT_COLLECTION_SUCCESS);
                }

                refetchLeaseDetail({ fetchPolicy: 'network-only' });

                if (onFinishEvent) onFinishEvent();
              }
            }
          })
          .catch((error) => {
            setSaving(false);
            handleCreateLeaseError(error);
          });
      } else if (onFinishEvent) onFinishEvent();
    }
  }

  function handleDeleteLease(unitForWhichToDeleteLease, isFromDeleteButton) {
    if (unitForWhichToDeleteLease?.lease) {
      // unitForWhichToDeleteLease = Unit for which to overwrite lease, basically, so save after
      deleteLease({
        variables: { id: unitForWhichToDeleteLease.lease.id },
        update(cache) {
          // unit atm is already clicked and the new one, should be the old one!!!
          const oldUnit = cache.data.data[`PropertyUnit:${unitForWhichToDeleteLease.id}`];
          const newUnit = cloneDeep(oldUnit);
          newUnit.lease = null;
          cache.evict({
            id: `PropertyUnit:${unitForWhichToDeleteLease.id}`,
          });
          cache.gc();

          // eslint-disable-next-line no-return-assign,no-param-reassign
          return (cache.data.data[`PropertyUnit:${unitForWhichToDeleteLease.id}`] = newUnit);
        },
        refetchQueries: [
          {
            query: GET_PROPERTIES_AND_LEASES,
            variables: { input: { state: ['DRAFT', 'READY_TO_BE_REVIEWED'] } },
          },
        ],
      })
        .then(() => {
          if (!isFromDeleteButton) {
            // this is part of a replace so set selected to new unit but keep lease
            const { clickedProperty, clickedUnit } = clickedPropertyUnit;
            setSelectedProperty(clickedProperty);
            setSelectedUnit(clickedUnit || clickedProperty);
            setRadioValue(clickedUnit.id?.toString());
            setQueryUnitId(null);
            // save the lease in variables to the new propertyUnitId
            saveLease({
              newVariables: { ...variables, propertyUnitId: unitForWhichToDeleteLease.id },
              isCreateLeaseSave: false,
              isFromDelete: true,
            });
            setTabIndex(LEASE_TABS.PROPERTY + 1);
          } else {
            // we are deleting the draft in clickedUnit
            showDeleteLeaseSuccessToast();
            if (unitForWhichToDeleteLease.id === unit?.id) {
              // this was the in progress lease so also unset that one and reset everything
              setLeaseId(null);
              resetValue(true);
            }
          }
        })
        .catch(() => {
          showDeleteLeaseErrorToast();
        });
    }
  }

  // TODO: if removing this works remove commented out stuff
  useEffect(() => {
    // if user selected property and pressed continue, selectingUnitPanel is true and Property tab isValid
    if (selectingUnitPanel) {
      setLeaseMenuOptions(initialMenuState(unit?.lease?.completedTags, isPropertyPanelValid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectingUnitPanel]);

  useEffect(() => {
    // always store session id locally in case of tab refresh
    if (unit?.id) {
      localStorage.setItem('unit', unit.id);
    }
  }, [unit?.id]);

  useEffect(() => {
    // at init, if we have a query or local stored id'd unit with a lease
    if (
      initialSelectedUnit &&
      initialSelectedUnit.lease &&
      (queryUnit?.id || localStorageUnit?.id)
    ) {
      const newTabIndex = calculateTabIndex(initialSelectedUnit.lease.completedTags);
      // set correct tabIndex
      setTabIndex(newTabIndex);
      isSectionValid(initialSelectedUnit.lease.completedTags);

      // if we have a unit and a lease, and we are on a higher tabindex than lease terms
      // we need to load the lease details including payments in
      // to be able to delete them
      if (unit && unit.lease && newTabIndex > 1) {
        saveLease();
      }
    }

    // reset states
    if (unit && (unit?.lease?.state === 'SCHEDULED' || unit?.lease?.state === 'EXPIRED')) {
      setSelectedProperty(null);
      setSelectedUnit(null);
      setRadioValue(null);
      setQueryUnitId(null);
      resetCreateLeaseStates();
    }
    // at init, so not when every single variable used in this useEffect is changed or reloaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newQueryUnit;
    const newQueryProperty = sortedProperties.find((property) => {
      newQueryUnit = property.units.find((foundUnit) => {
        return foundUnit.id === queryUnitId;
      });
      return !!newQueryUnit;
    });
    if (firstRun && queryUnitId && newQueryUnit) {
      setSelectedUnit(newQueryUnit);
      setSelectedProperty(newQueryProperty);
      setRadioValue(newQueryUnit?.id);
      setClickedPropertyUnit({
        clickedProperty: newQueryProperty,
        clickedUnit: newQueryUnit,
      });

      if (newQueryUnit?.lease) {
        setTimeout(() => saveLease({ isFromQuery: true }), 200);
      } else {
        setTimeout(() => {
          saveLease({ isStartNewLease: true, isFromQuery: true });
          setTabIndex(1);
        }, 200);
      }
      setFirstRun(false);
    }
    // at init, so not when every single variable used in this useEffect is changed or reloaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryUnitId, sortedProperties]);

  function onClickBack() {
    // Clicking on back btn from the receiving BA empty state when user has connected bank accounts takes users back to the dropdowns view of the same tab
    if (tabIndex === LEASE_TABS.ACCOUNTS && showReceivingBAEmptyState && hasConnectedBank) {
      setShowReceivingBAEmptyState(false);
    }

    // Clicking on back btn prevents setTabIndex from the receiving BA empty state when user has connected bank accounts
    if (
      (tabIndex > 0 && tabIndex !== LEASE_TABS.ACCOUNTS) ||
      (tabIndex === LEASE_TABS.ACCOUNTS &&
        (!showReceivingBAEmptyState || (showReceivingBAEmptyState && !hasConnectedBank)))
    ) {
      setTabIndex(tabIndex - 1);
    }

    if (tabIndex <= 0) {
      setSelectingUnitPanel(false);
      setAddingPropertyPanel(false);
    }
  }

  function handlePropertyUnitSelected(clickedProperty, clickedUnit) {
    // this is a lot easier than feeding property and selectedUnit to the alerts
    setClickedPropertyUnit({ clickedProperty, clickedUnit });
    setSelectedProperty(clickedProperty);
    setRadioValue(clickedUnit?.id.toString());
    if (!leaseId && clickedUnit?.lease?.id) {
      // if there is no lease yet we stick with the first lease we encounter
      setSelectedUnit(clickedUnit);
      setQueryUnitId(null);
      setLeaseId(clickedUnit.lease.id);
    } else if (leaseId && clickedUnit?.lease?.id && !selectingUnitPanel) {
      setSelectedProperty(clickedProperty);
    }
  }

  const handleExistingDraft = (choice) => {
    const { clickedProperty, clickedUnit } = clickedPropertyUnit;
    // if user wants to continue with the newly selected unit and its lease (if any)
    if (choice === 'continue') {
      resetValue(true);
      setFormInstance(false);
      setExistingDraftModalOpen(false);
      setTimeout(() => {
        setFormInstance(true);
        if (clickedUnit.lease) {
          // if continuing with another unit with an existing lease
          setLeaseId(clickedUnit.lease.id);
          setSelectedProperty(clickedProperty);
          setSelectedUnit(clickedUnit || clickedProperty);
          setRadioValue(clickedUnit.id?.toString());
          setQueryUnitId(null);
          isSectionValid(clickedUnit.lease?.completedTags);
          showLeaseResumePropertyToast();
          saveLease({ isFromModal: true });
        } else {
          // if continuing with another unit without a lease, so starting a new lease
          saveLease({ isStartNewLease: true, isFromModal: true });
          setTabIndex(1);
        }
      }, 200);
    } else if (choice === 'replace') {
      setFormInstance(false);
      setLeaseVariables(initialVariables);
      setExistingDraftModalOpen(false);
      setTimeout(() => {
        setFormInstance(true);
        if (clickedUnit.lease?.id) {
          // first delete existing lease on the selected unit, then save (inside delete func)
          handleDeleteLease(clickedUnit);
        } else {
          // moving the in progress lease to a new empty unit
          saveLease({
            newVariables: { ...variables, propertyUnitId: clickedUnit.id },
            isCreateLeaseSave: false,
            isFromModal: true,
          });
          setTabIndex(1);
        }
        showLeaseReplacePropertyToast();
      }, 200);
    }
  };

  const handleAddedProperty = (property, selectedUnit) => {
    handlePropertyUnitSelected(property, selectedUnit);
    setAddingPropertyPanel(false);
    refetchLeaseDetail();
  };

  function handleCreateLease() {
    const newVariables = handleCreateLeaseSetUp();
    saveLease({ newVariables, isCreateLeaseSave: true });
  }

  function handleClose(leaseConfirmed: Boolean = false) {
    if (leaseConfirmed && !leaseCreated) {
      saveLease();
    }
  }

  const alertFooter = (
    <AlertFooter
      rightButtonText="Exit without Saving"
      rightButtonEvent={() => {
        handleClose();
        onAlertClose();
      }}
      leftButtonText="Continue Application"
      leftButtonEvent={onAlertClose}
    />
  );

  const alertDeleteFooter = (
    <AlertFooter
      rightButtonText="Yes, Delete Draft"
      rightButtonEvent={() => {
        handleDeleteLease(clickedPropertyUnit?.clickedUnit, true);
        onDeleteAlertClose();
      }}
      leftButtonText="No, Cancel"
      leftButtonEvent={onDeleteAlertClose}
    />
  );

  // Make sure the order of the refs follows the menu options and tab constants
  const refs = [
    addPropertyRef,
    leaseTermsRef,
    depositsFeesRef,
    invoicesRef,
    bankAccountsRef,
    tenantDetailsRef,
  ];
  const formRefs = isMax768 ? [...refs, confirmRef] : refs;

  return (
    <HeaderNavWrapper
      onClickBack={onClickBack}
      hideBackButton
      onAlertOpen={onAlertOpen}
      handleFinishEvent={saveLease}
      headerContent={<Text {...leftHeaderContentStyles}>Set Up Rent Collection</Text>}
    >
      {formInstance ? (
        <LeaseFormTabs
          leaseCreated={leaseCreated}
          leaseMenuOptions={leaseMenuOptions}
          unitState={unitState}
          saveTenantProfileId={saveTenantProfileId}
          formVariables={variables}
          setFormVariables={setFormVariables}
          setLateFeePayments={setLateFeePayments}
          unit={unit}
          selectedProperty={selectedProperty}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          formRefs={formRefs}
          existingTenantState={existingTenantState}
          setExistingTenantState={setExistingTenantState}
          bankProps={bankProps}
          handlePropertyUnitSelected={handlePropertyUnitSelected}
          handleCreateLease={handleCreateLease}
          saveLease={saveLease}
          sortedProperties={sortedProperties}
          radioValue={radioValue}
          setRadioValue={setRadioValue}
          hasConnectedBank={hasConnectedBank}
          setHasConnectedBank={setHasConnectedBank}
          showReceivingBAEmptyState={showReceivingBAEmptyState}
          setShowReceivingBAEmptyState={setShowReceivingBAEmptyState}
          selectingUnitPanel={selectingUnitPanel}
          setSelectingUnitPanel={setSelectingUnitPanel}
          addingPropertyPanel={addingPropertyPanel}
          setAddingPropertyPanel={setAddingPropertyPanel}
          handleAddedProperty={handleAddedProperty}
          handleDeleteLease={onDeleteAlertOpen}
          saving={saving}
          clickedPropertyUnit={clickedPropertyUnit}
          loadingObject={loadingObject}
        />
      ) : (
        <Box>
          <SlLoader />
        </Box>
      )}
      <BaselaneAlert
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        header={<AlertHeader title="You Have Unsaved Changes" />}
        body="Are you sure you want to exit without saving?"
        footer={alertFooter}
        size="xl"
      />
      <BaselaneAlert
        isOpen={isDeleteAlertOpen}
        onClose={onDeleteAlertClose}
        header={<AlertHeader title="Are you sure you want to delete the draft? " />}
        body="Your draft rent collection will be deleted. You will be able to set up a new rent collection for this property/unit."
        footer={alertDeleteFooter}
        size="xl"
      />
      {existingDraftModalOpen && (
        <ExistingDraftModal
          onCloseBtnClick={() => setExistingDraftModalOpen(false)}
          handleExistingDraft={handleExistingDraft}
          isNewEmpty={!clickedPropertyUnit?.clickedUnit?.lease}
        />
      )}
    </HeaderNavWrapper>
  );
};

export default UnifiedLeaseFlow;
