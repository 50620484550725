import React, { useMemo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  ChakraProvider,
  Box,
  Text,
  HStack,
  Stack,
  Heading,
  Checkbox,
  useToast,
  Skeleton,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import {
  BaselaneDrawer,
  BaselaneCardNew,
  BaselaneButton,
  BaselaneCardStack,
  BaselaneChip,
} from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import habitatTheme from '@core/themeHabitat';
import useLeaseAgreements from './hooks/useLeaseAgreements';
import { LINK_LEASE_AGREEMENT_TO_LEASE } from './queries';
import {
  documentStateVariants,
  formatLeaseAgreementDuration,
} from './helpers/leaseAgreement.helper';

const LeaseToLeaseAgreementDrawer = () => {
  const formikRef = useRef();
  const { isMax767 } = useBreakPoints();

  const navigate = useNavigate();
  const toast = useToast();

  const { propertyUnitId, leaseId } = useLocation().state || {};
  const { leaseAgreements, loading: leaseAgreementsLoading } = useLeaseAgreements();

  const filteredLeaseAgreementsByPropertyUnitId = useMemo(() => {
    return leaseAgreements.filter(
      (la) =>
        la.propertyUnitId === propertyUnitId &&
        ['SIGNING_IN_PROGRESS', 'SIGNING_COMPLETE'].includes(la.state)
    );
  }, [leaseAgreements, propertyUnitId]);

  const [linkLeaseAgreementToLease] = useMutation(LINK_LEASE_AGREEMENT_TO_LEASE);

  const handleCloseDrawer = () => {
    navigate(-1);
  };

  const initialCheckedIds = useMemo(() => {
    return (
      filteredLeaseAgreementsByPropertyUnitId
        ?.filter((la) => la.leases?.some((lease) => lease?.id === leaseId))
        .map((la) => la.id) ?? []
    );
  }, [filteredLeaseAgreementsByPropertyUnitId, leaseId]);

  return (
    <ChakraProvider theme={habitatTheme}>
      <Formik
        innerRef={(el) => {
          formikRef.current = el;
        }}
        initialValues={{
          selectedLeaseAgreement: filteredLeaseAgreementsByPropertyUnitId
            ? filteredLeaseAgreementsByPropertyUnitId.map((la) => ({
                leaseId,
                leaseAgreementId: la.id,
                isChecked: la.leases?.some((lease) => lease?.id === leaseId) ?? false,
              }))
            : [],
        }}
        enableReinitialize
        onSubmit={async (values) => {
          try {
            const currentCheckedIds = values.selectedLeaseAgreement
              .filter((la) => la.isChecked)
              .map((la) => la.leaseAgreementId);

            const initialSet = new Set(initialCheckedIds);
            const currentSet = new Set(currentCheckedIds);

            const link = [...currentSet]
              .filter((id) => !initialSet.has(id))
              .map((id) => ({ leaseAgreementId: id, leaseId }));

            const unLink = [...initialSet]
              .filter((id) => !currentSet.has(id))
              .map((id) => ({ leaseAgreementId: id, leaseId }));

            const result = await linkLeaseAgreementToLease({
              variables: {
                input: {
                  link,
                  unLink,
                },
              },
              refetchQueries: ['GET_LEASE_AGREEMENTS', 'GET_LEASES'],
            });

            if (result?.data?.linkLeaseAgreementToLease) {
              toast({
                position: 'bottom-left',
                description: 'Lease agreement linked successfully!',
                status: 'success',
                duration: 3000,
                isClosable: true,
              });
              navigate(-1);
            } else {
              toast({
                position: 'bottom-left',
                description: 'Error linking lease agreement.',
                status: 'error',
                duration: 3000,
                isClosable: true,
              });
            }
          } catch (error) {
            console.error('Error linking lease agreement:', error);
          }
        }}
      >
        {({ values, setFieldValue, initialValues }) => (
          <BaselaneDrawer
            isOpen
            size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
            title="Rent collection details"
            closeEvent={handleCloseDrawer}
            closeOnOverlayClick
            newDesignDrawer
            footer={
              <Stack direction="row" gap={2} w="100%">
                <BaselaneButton
                  variant="outline"
                  palette="primary"
                  size="md"
                  onClick={handleCloseDrawer}
                >
                  Cancel
                </BaselaneButton>
                <BaselaneButton
                  variant="filled"
                  palette="primary"
                  size="md"
                  isFullWidth
                  onClick={() => {
                    if (formikRef.current) {
                      formikRef.current.handleSubmit();
                    }
                  }}
                >
                  Attach lease agreements
                </BaselaneButton>
              </Stack>
            }
          >
            <Heading size="headline-md">Attach to rent collection</Heading>

            {leaseAgreementsLoading ? (
              <Skeleton height="20px" width="300px" mb={2} />
            ) : (
              <Text textStyle="sm" mb={2}>
                {filteredLeaseAgreementsByPropertyUnitId?.length} lease agreements on this property
              </Text>
            )}

            {leaseAgreementsLoading ? (
              <Skeleton height="20px" width="300px" mb={1.5} />
            ) : (
              <BaselaneCardStack
                id="lease-agreement-to-lease-drawer-cardstack"
                items={filteredLeaseAgreementsByPropertyUnitId.map((la, index) => ({
                  id: `lease-agreement-${la.leaseAgreementId}`,
                  content: (
                    <BaselaneCardNew variant="innerClickable" size="md">
                      <HStack gap={1} alignItems="flex-start" m={0}>
                        <Box height="100%" mt="2px">
                          <Checkbox
                            name={`selectedLeaseAgreement[${index}].isChecked`}
                            isChecked={values?.selectedLeaseAgreement?.[index]?.isChecked}
                            onChange={(e) => {
                              const newValue = e.target.checked;
                              const updatedAgreements = [...values.selectedLeaseAgreement];
                              updatedAgreements[index].isChecked = newValue;
                              setFieldValue('selectedLeaseAgreement', updatedAgreements);
                            }}
                          />
                        </Box>
                        <Stack gap={0} m={0}>
                          {leaseAgreementsLoading ? (
                            <Skeleton />
                          ) : (
                            <Heading size="headline-md">{`${la.documentName}`}</Heading>
                          )}
                          {leaseAgreementsLoading ? (
                            <Skeleton />
                          ) : (
                            <HStack>
                              <BaselaneChip
                                label={la?.state
                                  ?.replace(/_/g, ' ')
                                  ?.toLowerCase()
                                  ?.replace(/^\w/, (c) => c.toUpperCase())}
                                size="sm"
                                variant={documentStateVariants[la?.state] || 'simple-neutral'}
                              />
                              <Text textStyle="sm">
                                {formatLeaseAgreementDuration(
                                  la.startDate,
                                  la.endDate,
                                  la.leaseType
                                )}
                              </Text>
                            </HStack>
                          )}
                        </Stack>
                      </HStack>
                    </BaselaneCardNew>
                  ),
                }))}
              />
            )}
          </BaselaneDrawer>
        )}
      </Formik>
    </ChakraProvider>
  );
};

export default LeaseToLeaseAgreementDrawer;
