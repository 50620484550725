import React, { useEffect, useContext } from 'react';
import MaskedInput from 'react-text-mask';
import { FieldArray } from 'formik';
import { Box, HStack, Input } from '@chakra-ui/react';
import { Icon16PlusCircle, Icon16Delete } from '@icons/16px';
import useBreakPoints from '@core/hooks/useBreakPoints';
import {
  BaselaneButton,
  BaselaneButtonIcon,
  BaselaneDropdown,
  NoPropertyTooltip,
} from '@shared/components';
import BaselaneErrorText from '@shared/components/BaselaneErrorText';
import { currencyMask } from '@core/utils/masks';
import TransactionContext from '@contexts/TransactionContext';
import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { getOptionsWithSubCategories } from '@core/components/CashFlowPage/helpers/cashflow.helpers';
import {
  renderPropertyDropdownChildItem,
  renderPropertyDropdownParentItem,
} from '@shared/helpers/propertiesFilter.helpers';
import { formInputStyles } from '../styles/table.styles';
import { buttonStyles, splitTxInputContainerStyles } from '../styles/splitTrx.styles';

type TransactionSplitItemProps = {
  formikRef: any,
  errors: Object,
  touched: Object,
  values: Object,
  dirty: boolean,
  handleChange: Function,
  setSplitTrxDirty: Function,
  setDeletedSplitTransactions: Function,
  propertyOptions: Array<Object>,
};

const TransactionSplitItem = ({
  formikRef,
  errors,
  touched,
  values,
  dirty,
  handleChange,
  setSplitTrxDirty,
  setDeletedSplitTransactions,
  propertyOptions,
}: TransactionSplitItemProps) => {
  const { categoryMap, categoryIdsMap, categoryWithSubOptions, propertyIdsMap } = useContext(
    TransactionContext
  );
  const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);

  const { isMax767 } = useBreakPoints();

  const dropdownClasses = [
    'fontSize-xs',
    'fixed-width-dropdown',
    'select-auto-width',
    'placeholder-font-color-neutral-400',
  ];

  const propertyDropdownProps = {
    parentId: 'drawer-body',
    type: 'tier2',
    classNames: dropdownClasses,
    title: 'Property',
    placeholder: 'Select a Property',
  };

  const getSelectedProperty = (newPropertyId, unitId) => {
    let propertyId = newPropertyId;
    if (typeof propertyId === 'string') {
      [propertyId] = newPropertyId.split('-');
    }
    let selectedProperty = null;
    if (propertyId && unitId) {
      selectedProperty = {
        id: `${propertyId}-${unitId}`,
        showValueName: `${propertyIdsMap[`p-${propertyId}`]?.name}, ${
          propertyIdsMap[`u-${unitId}`]?.name
        }`,
      };
    } else if (propertyId && !unitId) {
      selectedProperty = {
        id: `${propertyId}`,
        showValueName: propertyIdsMap[`p-${propertyId}`]?.name,
      };
    }

    return selectedProperty;
  };

  useEffect(() => {
    setSplitTrxDirty(dirty);
  }, [values.splitTransactions]);

  return (
    <FieldArray name="splitTransactions">
      {({ remove, push }) => (
        <Box w="100%">
          {values.splitTransactions.map((splitTrx, i) => {
            const { tagId, propertyId, unitId } = splitTrx;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <HStack key={i} w="100%" spacing={0}>
                <HStack {...splitTxInputContainerStyles(values.splitTransactions.length, i)}>
                  {/* optional Category */}
                  <Box w="100%">
                    <BaselaneDropdown
                      parentId="drawer-body"
                      type="tier2-title"
                      title="Category"
                      placeholder="Select a Category"
                      showValueByFields={['name']}
                      classNames={dropdownClasses}
                      data={categoryOptions}
                      parentItemRenderer={itemRenderer}
                      childItemRenderer={itemRenderer}
                      isMulti={false}
                      selectedItem={
                        tagId ? { id: categoryIdsMap[tagId], name: categoryMap[tagId] } : null
                      }
                      handleSubmit={(newCategoryId) => {
                        const [parentId, subId] = newCategoryId?.split('-') || [];
                        formikRef.current.setFieldValue(
                          `splitTransactions.${i}.tagId`,
                          subId ?? parentId
                        );
                      }}
                    />
                  </Box>

                  {/* optional Property */}
                  <Box w="100%">
                    {propertyOptions?.length === 0 ? (
                      <NoPropertyTooltip>
                        <BaselaneDropdown
                          {...propertyDropdownProps}
                          isDisabled
                          selectedItem={null}
                          additionalProps={{
                            id: `splitTransactions.${i}.propertyId`,
                            name: `splitTransactions.${i}.propertyId`,
                          }}
                        />
                      </NoPropertyTooltip>
                    ) : (
                      <BaselaneDropdown
                        {...propertyDropdownProps}
                        data={propertyOptions}
                        hasFilterWrapper={false}
                        hideSearch={isMax767}
                        showValueByFields={['showValueName']}
                        parentItemRenderer={({ item }) => renderPropertyDropdownParentItem(item)}
                        childItemRenderer={({ item }) => renderPropertyDropdownChildItem(item)}
                        additionalProps={{
                          id: `splitTransactions.${i}.propertyId`,
                          name: `splitTransactions.${i}.propertyId`,
                        }}
                        selectedItem={getSelectedProperty(propertyId, unitId)}
                        handleSubmit={(newPropertyId) => {
                          const newUnitId = propertyOptions.find(
                            (option) => option.id === newPropertyId
                          )?.unitId;
                          formikRef.current.setFieldValue(
                            `splitTransactions.${i}.propertyId`,
                            newPropertyId
                          );
                          formikRef.current.setFieldValue(
                            `splitTransactions.${i}.unitId`,
                            newUnitId
                          );
                        }}
                      />
                    )}
                  </Box>

                  {/* required input */}
                  <Input
                    {...formInputStyles}
                    id={`splitTransactions.${i}.amount`}
                    name={`splitTransactions.${i}.amount`}
                    as={MaskedInput}
                    mask={currencyMask({ allowNegative: true })}
                    placeholder="$0.00"
                    value={splitTrx.amount}
                    onChange={(e) => {
                      handleChange(e);
                      formikRef.current.setFieldTouched(`splitTransactions.${i}.amount`, true);
                    }}
                  />
                </HStack>

                <Box {...buttonStyles(values.splitTransactions.length, i)}>
                  <BaselaneButtonIcon
                    variant="outline"
                    palette="neutral"
                    size="sm"
                    isDisabled={values.splitTransactions.length < 3}
                    icon={<Icon16Delete />}
                    onClick={() => {
                      remove(i);
                      setDeletedSplitTransactions((prevState) =>
                        prevState.concat([{ ...splitTrx, isDelete: true }])
                      );
                    }}
                    styles={{
                      visibility: values.splitTransactions.length < 3 ? 'hidden' : 'visible',
                    }}
                  />
                </Box>
              </HStack>
            );
          })}

          <HStack py="24px" position="relative" justifyContent="space-between">
            <BaselaneButton
              leftIcon={<Icon16PlusCircle />}
              variant="transparent"
              palette="primary"
              onClick={() => push({ tagId: '', propertyId: '', unitId: '', amount: '' })}
            >
              Add New Split
            </BaselaneButton>
            {errors?.splitTransactions &&
              touched?.splitTransactions?.length === values.splitTransactions.length && (
                <BaselaneErrorText
                  {...{
                    errors,
                    fieldKey: 'splitTransactions',
                  }}
                />
              )}
          </HStack>
        </Box>
      )}
    </FieldArray>
  );
};

export default TransactionSplitItem;
