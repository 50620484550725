/* eslint-disable react/prop-types */
import React from 'react';
import { Text, Tbody } from '@chakra-ui/react';
import LogRocket from 'logrocket';
import useBreakPoints from '@core/hooks/useBreakPoints';
import {
  BaselaneCardStack,
  BaselaneResponsiveCell,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
} from '@shared/components';
import { formatDate } from '@core/utils/formatDate';
import ReportHeader from '../../components/ReportHeader';

const Bankruptcy = ({ bankruptcyDetails = [] }) => {
  const { isMax767 } = useBreakPoints();
  const tableColumnConfig = {
    columns: [
      {
        isSortable: false,
        styles: {
          w: isMax767 ? '150px' : '320px',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
      {
        isSortable: false,
        styles: {
          w: '100%',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
    ],
  };

  if (!bankruptcyDetails.length) {
    return (
      <BaselaneCardStack
        id="no-bankruptcy-data-card"
        size="md"
        width="100%"
        items={[
          {
            id: 'header',
            content: <ReportHeader>Bankruptcy</ReportHeader>,
          },
          {
            id: 'no-bankruptcy',
            content: <Text textStyle="sm">No data available</Text>,
          },
        ]}
      />
    );
  }

  const isFieldNotObject = (field) => {
    if (typeof field !== 'object') {
      return true;
    }
    LogRocket.log('field is object or null error', field);
    return false;
  };

  return (
    <>
      {bankruptcyDetails.map((bankruptcy, index) => (
        <BaselaneCardStack
          id={`bankruptcy-card-${bankruptcyDetails?.case_number}`}
          key={`bankruptcy-card-${bankruptcyDetails?.case_number}`}
          size="md"
          items={[
            {
              id: 'header',
              content: <ReportHeader>Bankruptcy</ReportHeader>,
            },
            {
              id: 'bankruptcy-card-body',
              content: (
                <BaselaneResponsiveTable
                  id="review-columns"
                  config={tableColumnConfig}
                  maxW="600px"
                  tableContainerStyles={{
                    overflowY: 'initial',
                    overflowX: 'initial',
                  }}
                >
                  <Tbody>
                    <BaselaneResponsiveTableRows
                      items={[
                        {
                          heading: 'Date filed',
                          data: bankruptcyDetails?.date_filed
                            ? formatDate(bankruptcyDetails?.date_filed, 'YYYY-MM-DD')
                            : '--',
                        },
                        {
                          heading: 'Date reported',
                          data: bankruptcyDetails?.date_reported
                            ? formatDate(bankruptcyDetails?.date_reported, 'YYYY-MM-DD')
                            : '--',
                        },
                        {
                          heading: 'Filer',
                          data: bankruptcyDetails?.filer ?? '--',
                        },
                        {
                          heading: 'Type',
                          data: bankruptcyDetails?.type ?? '--',
                        },
                        {
                          heading: 'Industry code',
                          data: bankruptcyDetails?.industry_code ?? '--',
                        },
                        {
                          heading: 'Narrative codes',
                          data: bankruptcyDetails?.narrative_codes
                            ? bankruptcyDetails?.map((item) => item.description).join(', ')
                            : '--',
                        },
                        {
                          heading: 'Case number',
                          data: bankruptcyDetails?.case_number ?? '--',
                        },
                        {
                          heading: 'Prior intent or disposition code',
                          data: bankruptcyDetails?.prior_intent_or_disposition_code ?? '--',
                        },
                      ]}
                      customRow
                      renderItem={(row) => {
                        return (
                          <BaselaneResponsiveTableRow
                            key={row.heading}
                            chevronCellStyles={{ w: 0 }}
                            contentContainerStyles={{ border: 'none' }}
                          >
                            <BaselaneResponsiveCell configIndex={0} p={0}>
                              <Text as="span" textStyle="sm" color="brand.neutral.700">
                                {row.heading}
                              </Text>
                            </BaselaneResponsiveCell>
                            <BaselaneResponsiveCell configIndex={1} p={0}>
                              <Text textStyle="sm">
                                {isFieldNotObject(row.data) ? row.data : '--'}
                              </Text>
                            </BaselaneResponsiveCell>
                          </BaselaneResponsiveTableRow>
                        );
                      }}
                    />
                  </Tbody>
                </BaselaneResponsiveTable>
              ),
            },
          ]}
        />
      ))}
    </>
  );
};

export default Bankruptcy;
