import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { decode } from 'js-base64';
import { useStatsigClient } from '@statsig/react-bindings';
import { LOGIN_ERROR } from '@routes';
import { FEATURE_GATES } from '@core/constants/statsigKeys';

const useInvitationLink = () => {
  // State to hold the decoded invite data
  const [collaborator, setCollaborator] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const encodedId = params.get('id');
  const inviteCode = params.get('inviteCode');

  const navigate = useNavigate();

  const { checkGate } = useStatsigClient();

  const isMuaEnabled = checkGate(FEATURE_GATES.MUA_GATE);
  useEffect(() => {
    if (!inviteCode) {
      return;
    }
    try {
      // Validate if the string is a valid Base64 format
      const base64Regex = /^[A-Za-z0-9+/=]+$/;
      if (!base64Regex.test(encodedId)) {
        throw new Error('Invalid Base64 format.');
      }

      // Decode the Base64 string safely
      const decodedString = decode(encodedId);

      // Try to parse the decoded string as JSON
      const parsedData = JSON.parse(decodedString);

      // Handle different formats of parsed data
      const data = typeof parsedData === 'string' ? { email: parsedData } : parsedData;
      setCollaborator(data);
    } catch (error) {
      navigate(LOGIN_ERROR);
    }
  }, [location.search]);

  const isCollaborator = collaborator && isMuaEnabled;

  return { collaborator, isCollaborator, inviteCode };
};

export default useInvitationLink;
