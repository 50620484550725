import React, { useState, useRef, useEffect } from 'react';
import { Box, HStack, Spinner, Stack } from '@chakra-ui/react';
import { BaselaneErrorCard } from '@shared/components';

import CashFlowDataWrapper from '../CashFlowDataWrapper';
import CashFlowByTypeWrapper from '../CashFlowByTypeWrapper';
import CashFlowByTypePropertyUnitWrapper from '../CashFlowByTypePropertyUnitWrapper';
import TransactionsWrapper from '../TransactionsWrapper';
import { spinnerStyles } from '../styles/shared.styles';

type CashflowAnalyticsProps = {
  filters: Object,
  setActiveMonth: Function,
  activeMonth: string,
  activePropertyUnit: string,
  setActivePropertyUnit: Function,
  lastPreset?: String,
  hasNoData: boolean,
  loading: boolean,
  error: boolean,
  data: Object,
  refetch: Function,
  cashFlowPropertiesData: Array<Object>,
  dataType: string,
};

function CashflowAnalytics({
  filters,
  setActiveMonth,
  activeMonth,
  activePropertyUnit,
  setActivePropertyUnit,
  lastPreset = null,
  hasNoData,
  loading,
  error,
  data,
  refetch,
  cashFlowPropertiesData,
  dataType,
}: CashflowAnalyticsProps) {
  const { uncategorized, ...defaultTrxFilters } = filters.filter;

  const filterSummaryRef = useRef({});
  const [isCashFlowByTypeAnimating, setIsCashFlowByTypeAnimating] = useState(false);
  const [subFilters, setSubFilters] = useState({});
  const [trxFilters, setTrxFilters] = useState({
    ...defaultTrxFilters,
    isCategorized: !uncategorized,
  });

  const onAnimationStart = () => {
    setIsCashFlowByTypeAnimating(true);
  };

  const onAnimationComplete = () => {
    setIsCashFlowByTypeAnimating(false);
  };

  const handleSubFiltersUpdate = (subfilters) => {
    const {
      categorySelected,
      subCategorySelected,
      dateSelected,
      ...subFiltersSelected
    } = subfilters;

    filterSummaryRef.current = {
      ...filterSummaryRef.current,
      ...((categorySelected || categorySelected === null) && { categorySelected }),
      ...((subCategorySelected || subCategorySelected === null) && { subCategorySelected }),
      ...((dateSelected?.dateFormatted || dateSelected?.dateFormatted === null) && {
        dateSelected: dateSelected?.dateFormatted,
      }),
    };

    if (dateSelected) setActiveMonth(dateSelected?.date);
    setSubFilters({ ...subFilters, ...subFiltersSelected });
  };

  useEffect(() => {
    const hasUncategorized = Array.isArray(subFilters?.tagId)
      ? subFilters?.tagId?.find((id) => id === '0')
      : subFilters?.tagId === '0';
    const isCategorized = subFilters?.tagId ? !hasUncategorized : !uncategorized;

    setTrxFilters({ ...defaultTrxFilters, isCategorized, ...subFilters });
  }, [subFilters]);

  useEffect(() => {
    refetch();
    setActiveMonth(null);
    setActivePropertyUnit(null);
    setTrxFilters({
      ...defaultTrxFilters,
      isCategorized: !uncategorized,
    });
    filterSummaryRef.current = {};
    setSubFilters({});
  }, [filters, dataType]);

  if (loading) return <Spinner {...spinnerStyles} />;
  if (error) {
    if (error.message.includes('Failed to fetch')) {
      return <BaselaneErrorCard />;
    }
    return null;
  }

  return (
    <Stack {...{ pt: '24px' }}>
      <HStack alignItems="stretch">
        <Box w="62%">
          {dataType === 'month' && (
            <CashFlowDataWrapper
              {...{
                filters,
                data,
                lastPreset,
                handleSubFiltersUpdate,
                hasNoData,
              }}
              dataType={dataType}
              onSelectMonth={setActiveMonth}
              selectedMonth={activeMonth}
              onSelectPropertyUnit={setActivePropertyUnit}
              selectedPropertyUnit={null}
            />
          )}
          {dataType === 'property' && (
            <CashFlowDataWrapper
              {...{
                filters,
                data,
                lastPreset,
                handleSubFiltersUpdate,
                hasNoData,
              }}
              dataType={dataType}
              onSelectMonth={setActiveMonth}
              selectedMonth={null}
              onSelectPropertyUnit={setActivePropertyUnit}
              selectedPropertyUnit={activePropertyUnit}
              cashFlowPropertiesData={cashFlowPropertiesData}
            />
          )}
          {dataType === 'unit' && (
            <CashFlowDataWrapper
              {...{
                filters,
                data,
                lastPreset,
                handleSubFiltersUpdate,
                hasNoData,
              }}
              dataType={dataType}
              onSelectMonth={setActiveMonth}
              selectedMonth={null}
              onSelectPropertyUnit={setActivePropertyUnit}
              selectedPropertyUnit={activePropertyUnit}
              cashFlowPropertiesData={cashFlowPropertiesData}
            />
          )}
        </Box>
        <Box w="38%">
          {activePropertyUnit ? (
            <CashFlowByTypePropertyUnitWrapper
              selectedMonth={activeMonth}
              selectedPropertyUnit={activePropertyUnit}
              dataType={dataType}
              handleSubFiltersUpdate={(subFilterParams) => {
                if (!subFilterParams) setIsCashFlowByTypeAnimating(true);
                handleSubFiltersUpdate(subFilterParams);
              }}
              onAnimationStart={onAnimationStart}
              onAnimationComplete={onAnimationComplete}
              hasNoData={hasNoData}
            />
          ) : (
            <CashFlowByTypeWrapper
              selectedMonth={activeMonth}
              dataType={dataType}
              handleSubFiltersUpdate={(subFilterParams) => {
                if (!subFilterParams) setIsCashFlowByTypeAnimating(true);
                handleSubFiltersUpdate(subFilterParams);
              }}
              onAnimationStart={onAnimationStart}
              onAnimationComplete={onAnimationComplete}
              hasNoData={hasNoData}
            />
          )}
        </Box>
      </HStack>
      <TransactionsWrapper
        {...{
          trxFilters,
          filterSummary: filterSummaryRef.current,
          hasNoData,
          isCashFlowByTypeAnimating,
        }}
      />
    </Stack>
  );
}

export default CashflowAnalytics;
