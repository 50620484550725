import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  Box,
  ChakraProvider,
  Checkbox,
  Flex,
  HStack,
  Input,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';

import { useStatsigClient } from '@statsig/react-bindings';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import {
  CREATE_OR_UPDATE_ONBOARDING_SURVEY,
  UPDATE_USER,
} from '@core/components/UserProfilePage/queries';
import userContext from '@contexts/UserContext';
import { ONBOARDING_ADD_PROPERTY, ONBOARDING_TRIAGE } from '@routes';
import SlLoader from '@core/components/Loader';
import { SaveChangesBtn } from '@core/components/NativeBankingPage/components/IndividualInputEditButtons';
import { BaselaneButton, BaselaneButtonCard } from '@shared/components';
import habitatTheme from '@core/themeHabitat';
import { formInputStyles } from '@shared/styles/input.style';
import { FEATURE_GATES } from '@core/constants/statsigKeys';

import { buttonCardStyles, otherBoxStyles, titleTextStyles } from './styles/propertySurvey.styles';
import { onboardingSurvey } from '../../../utils/onboardingSurvey/index';

const PropertySurvey = () => {
  const { checkGate } = useStatsigClient();
  const navigate = useNavigate();
  const [updateSurvey] = useMutation(CREATE_OR_UPDATE_ONBOARDING_SURVEY);
  const [updateUser] = useMutation(UPDATE_USER);
  const [onboardingTriageSelection, setOnboardingTriageSelection] = useState();
  const [questionnaire, setQuestionnaire] = useState([]);
  const [questionnaireObject, setQuestionnaireObject] = useState({});
  const [isOtherEditState, setIsOtherEditState] = useState(-1);
  const [otherEditValue, setOtherEditValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [multipleAnswers, setMultipleAnswers] = useState([]);

  const { user, refetchUser, propertySurveyPanelIndex, setPropertySurveyPanelIndex } = useContext(
    userContext
  );

  const survey = onboardingSurvey(checkGate(FEATURE_GATES.ONBOARDING_ENTITY_QUESTION));
  const surveyQuestionsLength = survey.questions.length;

  const handleSelectionSave = () => {
    updateUser({
      variables: {
        metadata: {
          ...user?.metadata,
          onboardingTriageSelection,
        },
      },
    }).then(() => {
      const featureMap = {
        banking: 'landlord_banking',
        rentCollection: 'rent_collection',
        bookkeeping: 'bookkeeping',
      };
      sendSegmentEvent('onboarding_complete_path_selection', {
        feature: featureMap[onboardingTriageSelection],
      });
      sendSegmentEvent('onboarding_path_selection', {
        feature: featureMap[onboardingTriageSelection],
      });
      refetchUser();
    });
  };

  const setAnswer = (question, answer, newPage, eventString) => {
    if (eventString) {
      sendSegmentEvent(`onboarding_survey_${eventString}_continue`);
    }

    const isMultiSelect = Array.isArray(answer);
    let finalAnswer = answer.value || answer;

    if (isMultiSelect) {
      const allAnswers = survey.questions.find((q) => q.eventString === eventString).answers;

      const result = Object.fromEntries(
        allAnswers.map((item) => [item, multipleAnswers.includes(item)])
      );

      finalAnswer = JSON.stringify(result);
    }

    // updateSurvey call array
    const newQuestionnaire = questionnaire;
    newQuestionnaire.push({
      question,
      answer: finalAnswer,
    });

    if (answer.type === 'text') {
      newQuestionnaire.push({
        question: 'otherSource',
        answer: otherEditValue,
      });
    }
    setQuestionnaire(newQuestionnaire);
    // segment event object
    const newQuestionnaireObject = questionnaireObject;
    newQuestionnaireObject[question] = finalAnswer;
    if (answer.type === 'text') {
      newQuestionnaireObject.otherSource = otherEditValue;
    }
    setQuestionnaireObject(newQuestionnaireObject);
    setMultipleAnswers([]);
    if (newPage) {
      setPropertySurveyPanelIndex(propertySurveyPanelIndex + 1);
    } else if (answer.type === 'text') {
      handleOnSubmit(survey.version.default, answer.type);
    }
  };

  const handleOnSubmit = (version, type = 'multi') => {
    if (type === 'text') {
      sendSegmentEvent('onboarding_complete_property_survey', {
        version,
        questionnaire: questionnaireObject,
        otherSource: otherEditValue,
      });
    } else {
      sendSegmentEvent('onboarding_complete_property_survey', {
        version,
        questionnaire: questionnaireObject,
      });
    }
    updateSurvey({ variables: { version, questionnaire } })
      .then(() => {
        return handleSelectionSave();
      })
      .then(() => {
        if (checkGate(FEATURE_GATES.ONBOARDING_ADD_PROPERTY_GATE)) {
          navigate(ONBOARDING_ADD_PROPERTY);
        } else {
          navigate(ONBOARDING_TRIAGE);
        }
      });
  };

  const handleOnOtherChange = (e) => {
    setOtherEditValue(e.target.value);
  };

  const handleMultiSelectAnswerClick = (answer) => {
    const isChecked = multipleAnswers.includes(answer);
    const newAnswers = isChecked
      ? multipleAnswers.filter((x) => x !== answer)
      : [...multipleAnswers, answer];

    setMultipleAnswers(newAnswers);
  };

  return (
    <ChakraProvider theme={habitatTheme}>
      <Tabs index={propertySurveyPanelIndex} mt="0 !important">
        <TabPanels>
          {survey.questions.map((surveyQuestion, index) => (
            <TabPanel key={uuidv4()} padding="0 0 40px 0 !important" overflow="auto">
              {isLoading ? (
                <SlLoader />
              ) : (
                <Box mt={isMobile || index !== 0 ? '0' : '40px'} pb="32px">
                  <Text {...titleTextStyles(index !== 0)}>{surveyQuestion.question}</Text>
                  <Stack spacing={2}>
                    {surveyQuestion.answers.map((answer, answerIndex) => {
                      if (surveyQuestion.isMultiSelect) {
                        return (
                          <BaselaneButtonCard
                            id={`answer-${answer?.id || answer.replace(/\s+/g, '-').toLowerCase()}`}
                            key={uuidv4()}
                            type="clean"
                            buttonStyles={buttonCardStyles}
                            onClick={() => handleMultiSelectAnswerClick(answer)}
                          >
                            <Flex gap="8px">
                              <Checkbox
                                className="primary-500"
                                isChecked={multipleAnswers.includes(answer)}
                              />
                              <Text width="100%" fontWeight="500">
                                {answer}
                              </Text>
                            </Flex>
                          </BaselaneButtonCard>
                        );
                      }

                      return isOtherEditState !== answerIndex ? (
                        <BaselaneButtonCard
                          id={`answer-${answer?.id || answer.replace(/\s+/g, '-').toLowerCase()}`}
                          key={uuidv4()}
                          type="clean"
                          buttonStyles={buttonCardStyles}
                          onClick={() => {
                            if (answer.type === 'text') {
                              setIsOtherEditState(answerIndex);
                            } else {
                              setIsOtherEditState(-1);
                              if (surveyQuestion.eventString === 'onboarding_triage_selection') {
                                setOnboardingTriageSelection(answer.value);
                              }
                              setAnswer(
                                surveyQuestion.questionForMixpanel,
                                answer.value || answer,
                                index !== surveyQuestionsLength - 1,
                                surveyQuestion.eventString
                              );
                              if (index === surveyQuestionsLength - 1) {
                                setIsLoading(true);
                                handleOnSubmit(survey.version.default);
                              }
                            }
                          }}
                        >
                          <Text>{answer.label || answer}</Text>
                        </BaselaneButtonCard>
                      ) : (
                        <Box {...otherBoxStyles}>
                          <HStack spacing={1}>
                            <Text fontWeight="500">{answer.label || answer}</Text>
                            <Input
                              id="property-survey-other-answer"
                              name="other"
                              placeholder={
                                answer?.id === 'influencer'
                                  ? 'Enter Code or Coach name'
                                  : 'Type your answer'
                              }
                              value={otherEditValue}
                              onChange={handleOnOtherChange}
                              {...formInputStyles}
                              borderColor="brand.blue.700 !important"
                              bg="brand.neutral.white"
                              w="100%"
                              isRequired={
                                answer?.id !== 'influencer' ||
                                answer?.id !== 'podcast-or-webinar' ||
                                answer?.id !== 'other'
                              }
                              autoFocus
                              maxLength="150"
                            />
                            <SaveChangesBtn
                              variant="filled"
                              palette="primary"
                              size="lg"
                              isDisabled={
                                answer?.id !== 'influencer' &&
                                answer?.id !== 'podcast-or-webinar' &&
                                answer?.id !== 'other' &&
                                !otherEditValue
                              }
                              onClick={() => {
                                setIsOtherEditState(-1);
                                setAnswer(
                                  surveyQuestion.questionForMixpanel,
                                  answer,
                                  index !== surveyQuestionsLength - 1,
                                  surveyQuestion.eventString
                                );
                              }}
                            />
                          </HStack>
                        </Box>
                      );
                    })}
                  </Stack>
                  {surveyQuestion.isMultiSelect && (
                    <BaselaneButton
                      id="survey-multiple-choice-next"
                      variant="filled"
                      palette="primary"
                      size="lg"
                      isDisabled={multipleAnswers.length < 1}
                      {...{ marginTop: '32px' }}
                      onClick={() =>
                        setAnswer(
                          surveyQuestion.questionForMixpanel,
                          multipleAnswers,
                          index !== surveyQuestionsLength - 1,
                          surveyQuestion.eventString
                        )
                      }
                    >
                      Next
                    </BaselaneButton>
                  )}
                </Box>
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </ChakraProvider>
  );
};

export default PropertySurvey;
