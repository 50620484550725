import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Analytics from 'analytics';
import LogRocket from 'logrocket';
import * as ROUTES from '@routes';
import { reactNodeEnv, RLSandboxUrlV2, RLDefaultUrlV2 } from '@core/constants/envVars';
import { TITLE, TITLES_MAP } from '@core/constants/titles';
import sendSegmentPageEvent from '@core/utils/sendSegmentEvent/sendSegmentPageEvent';
import '@core/styles/customStyles.scss';

import MainRoutes from './routes/MainRoutes';
import AppWrapper from './components/wrappers/AppWrapper';
import GlobalLayoutWrapper from '../Shared/layouts/GlobalLayoutWrapper';
import StatsigWrapper from './components/wrappers/StatsigWrapper';

const App = () => {
  const location = useLocation();

  // Alpha 1306 track /login and /signup
  const analytics =
    reactNodeEnv === 'production'
      ? Analytics({
          app: 'baselane-app',
        })
      : null;

  // Modify and push messageId (event id) to FB Pixel tracking only
  const DMW1 = ({ payload, integration, next }) => {
    // eslint-disable-next-line no-param-reassign
    payload.obj.messageId = payload.obj.userId ? `${payload.obj.event}_${payload.obj.userId}` : '';
    next(payload);
  };
  window.analytics.addDestinationMiddleware('Facebook Pixel', DMW1);

  // Logrocket Setup
  useEffect(() => {
    if (reactNodeEnv === 'production') {
      LogRocket.init('oj1o2q/baselane');
    }
  }, []);

  /* Logic to execute based on location of the user in the application.
      - set browser tab text
      - analytics for login and sign up
   */
  useEffect(() => {
    // Set browser tab text
    const currentTitle = TITLES_MAP.find((item) => item.path === location.pathname);

    if (currentTitle?.title || currentTitle?.title === '') {
      document.title = currentTitle.title === '' ? TITLE : `${TITLE} | ${currentTitle.title}`;
    }

    // Google analytics for login and sign up
    if (analytics && (location.pathname === ROUTES.LOGIN || location.pathname === ROUTES.SIGN_UP)) {
      analytics.page();
    }

    // segment analytics
    if (location.pathname === ROUTES.LOGIN || location.pathname === ROUTES.SIGN_UP) {
      sendSegmentPageEvent();
    }
  }, [location]);

  useEffect(() => {
    if (RLSandboxUrlV2 || RLDefaultUrlV2) {
      const isNonDevEnv =
        reactNodeEnv === 'production' || reactNodeEnv === 'pre-prod' || reactNodeEnv === 'hotfix';
      const sourceV2 = isNonDevEnv ? RLDefaultUrlV2 : RLSandboxUrlV2;

      const scriptV2 = document.createElement('script');
      scriptV2.defer = true;
      scriptV2.type = 'module';
      scriptV2.src = sourceV2;

      document.head.appendChild(scriptV2);
      return () => {
        document.head.removeChild(scriptV2);
      };
    }

    throw new Error('RL URL is missing in environment variables, please check your configuration.');
  }, []);

  return (
    <AppWrapper>
      <GlobalLayoutWrapper>
        <StatsigWrapper>
          <MainRoutes />
        </StatsigWrapper>
      </GlobalLayoutWrapper>
    </AppWrapper>
  );
};

export default App;
