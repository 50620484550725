import React from 'react';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';
import IconWarningTriangleYellow from '@icons/manual/IconWarningTriangleYellow';

const ExpirationError = () => {
  const { isMax767 } = useBreakPoints();

  const logoContainerStyles = {
    backgroundColor: 'brand.neutral.white',
    minHeight: '100vh',
    height: '100vh',
    width: '100%',
    direction: 'column',
    alignItems: 'center',
  };

  const contentContainerStyles = () => ({
    alignItems: 'center',
    w: '100%',
    px: 4,
    py: 2,
    position: 'relative',
    justifyContent: 'space-between',
    mb: isMax767 ? 5 : '60px',
    borderBottom: '1px',
    borderColor: 'brand.darkBlue.150',
  });

  const title = 'This invite link is invalid or has expired';
  const subtitle = 'Please request a new link from the workspace owner.';

  return (
    <Box>
      <Flex {...logoContainerStyles}>
        <Stack {...contentContainerStyles(isMax767)}>
          <Box w="100%" color="brand.neutral.800A">
            <BaselaneFullLogo />
          </Box>
        </Stack>
        <Stack w="100%" h="50%" align="center" justifyContent="center">
          <Stack
            w="600px"
            align="center"
            justifyContent="center"
            spacing={1.5}
            p={3}
            textAlign="center"
          >
            <IconWarningTriangleYellow />
            <Text textStyle="headline-lg" mt={1}>
              {title}
            </Text>
            <Text textStyle="sm">{subtitle}</Text>
          </Stack>
        </Stack>
      </Flex>
    </Box>
  );
};

export default ExpirationError;
