import React from 'react';
import { useFormikContext } from 'formik';
import { Flex, Box, Text, Switch } from '@chakra-ui/react';
import CategoryDropdown from './CategoryDropdown';

type CategoryProps = {
  isDisabled?: boolean,
};

const Category = ({ isDisabled = false }: CategoryProps) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Box
      border="1px solid"
      borderColor="brand.darkBlue.200"
      borderRadius="4px"
      background="brand.darkBlue.50"
      p={2}
    >
      <Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Text
            textStyle="sm"
            fontWeight="500"
            color={isDisabled ? 'brand.neutral.400' : 'inherit'}
          >
            Category
          </Text>
          <Switch
            value="some value"
            isChecked={values.isCategoryOpen}
            onChange={() => setFieldValue('isCategoryOpen', !values.isCategoryOpen)}
            isDisabled={isDisabled}
          />
        </Flex>
      </Box>
      {values.isCategoryOpen && (
        <Box mt={3}>
          <CategoryDropdown />
        </Box>
      )}
    </Box>
  );
};

export default Category;
