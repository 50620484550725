import React from 'react';
import { Checkbox, FormControl, Input, Text, VStack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { REJECT_OR_APPROVE_REASON } from '../../helpers/tenantScreeningDecision.helpers';

const MultiReasonForm = () => {
  const {
    setFieldValue,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    setFieldTouched,
  } = useFormikContext();

  return (
    <VStack alignItems="flex-start" justifyContent="flex-start" w="full">
      {Object.keys(REJECT_OR_APPROVE_REASON).map((reasonKey) => {
        return (
          <React.Fragment key={reasonKey}>
            <FormControl name={reasonKey}>
              <Checkbox
                onChange={(e) => {
                  setFieldValue(reasonKey, e.target.checked);
                }}
              >
                <Text as="span" textStyle="sm">
                  {REJECT_OR_APPROVE_REASON[reasonKey]}
                </Text>
              </Checkbox>
            </FormControl>
            {reasonKey === 'other' && !!values?.other && (
              <FormControl
                isRequired
                isInvalid={
                  (!values.otherReason || !values.otherReason.length > 0) && touched.otherReason
                }
              >
                <Input
                  name="otherReason"
                  autoFocus
                  value={values.otherReason}
                  onChange={handleChange}
                  onBlur={(e) => {
                    setFieldTouched('otherReason', true);
                    handleBlur(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                />
              </FormControl>
            )}
          </React.Fragment>
        );
      })}
    </VStack>
  );
};

export default MultiReasonForm;
