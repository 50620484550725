import { React } from 'react';
import { useFormikContext } from 'formik';
import { T1WithTitleDropdown } from '@shared/components';
import { renderPaymentMethodItem, setPaymentMethodFormValues } from '../../../helpers/formHelpers';
import {
  useRecipient,
  useSetSelectedPaymentMethod,
  useSelectedPaymentMethod,
} from '../../../sendFundsStore';

type PaymentMethodsOptionsProps = {
  hasError: Boolean,
};

const PaymentMethodsOptions = ({ hasError }: PaymentMethodsOptionsProps) => {
  const formik = useFormikContext();
  const { values, setFieldValue, setFieldTouched } = formik;
  const { paymentMethodId } = values;
  const recipient = useRecipient();
  const setSelectedPaymentMethod = useSetSelectedPaymentMethod();
  const selectedPaymentMethod = useSelectedPaymentMethod();

  const handlePaymentMethodChoice = (paymentMethod) => {
    const selected = recipient?.paymentMethods
      .flatMap((method) => method.items)
      .filter((m) => m?.id === paymentMethod);

    setPaymentMethodFormValues(selected[0], setFieldValue, values?.name);
    // Reset payment details fields
    setFieldTouched('amount', false);
    setFieldTouched('fromTransferAccountId', false);
    setSelectedPaymentMethod(selected[0]);

    return selected[0]?.id;
  };

  const getSelectedMethodForDropDown = (paymentMethod) => {
    if (!paymentMethod) return '';
    const selected = recipient?.paymentMethods
      .flatMap((method) => method.items)
      .find((m) => m?.id === paymentMethod || m?.id === selectedPaymentMethod?.id);
    return selected || '';
  };

  const paymentMethodProps = () => ({
    styles: { 'data-cy': 'send-money-payment-method' },
    classNames: ['input-form-xl', 'is-full-width', 'account'],
    data: recipient?.paymentMethods,
    name: 'paymentMethodId',
    title: 'Select',
    showValueByFields: ['title'],
    itemRenderer: renderPaymentMethodItem,
    handleSubmit: (selectedValue) => {
      handlePaymentMethodChoice(selectedValue);
    },
    selectedItem: getSelectedMethodForDropDown(paymentMethodId),
    showDivider: true,
    hideSearch: true,
  });

  return <T1WithTitleDropdown hasError={hasError} {...paymentMethodProps()} />;
};

export default PaymentMethodsOptions;
