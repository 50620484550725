import stripCurrency from '@core/utils/stripCurrency';

import { validateTransferLimits } from '@hooks/useTransferLimitValidation';
import {
  recipientValidation,
  recipientCheckPaymentValidation,
  recipientWirePaymentValidation,
  recipientACHPaymentValidation,
} from '../../Recipients/helpers/validation.helpers';
import { isFutureDate, isSameDay, steps } from './formHelpers';

const validateRecipientField = ({ values }) => {
  const errors = {};

  const { payeeId } = values;

  if (!payeeId) {
    errors.payeeId = 'Select recipient';
  }

  return errors;
};

const validateDescriptionField = ({ values }) => {
  const alphanumericRegex = /^$|^[a-zA-Z0-9\s]+$/;
  const errors = {};
  if (!alphanumericRegex.test(values.description)) {
    errors.description = 'Only alphanumeric characters, are allowed';
  }

  return errors;
};

const validateSendOnDateField = ({ values }) => {
  const errors = {};
  const { oneTimeTransfer } = values;
  if (!oneTimeTransfer.date) {
    errors.oneTimeTransfer.date = 'Select date';
  }

  return errors;
};

const validateAmountField = ({ values, accountsMap }) => {
  const errors = {};
  const {
    amount = 0,
    fromTransferAccountId = -1,
    oneTimeTransfer,
    scheduledPaymentType,
    paymentMethodType,
  } = values;

  const formattedAmount = stripCurrency(amount);
  const selectedFromAccObj =
    fromTransferAccountId !== '' ? accountsMap?.[fromTransferAccountId] : {};
  const { availableBalance } = selectedFromAccObj || {};

  // Basic amount validation
  if (!formattedAmount || formattedAmount === 0) {
    errors.amount = 'Amount must be greater than $0.00';
    return errors;
  }

  // Wire transfer validation
  if (paymentMethodType === 'WIRE_TRANSFER' && formattedAmount < 100) {
    errors.amount = 'Amount must be greater than $100';
    return errors;
  }

  // Insufficient funds validation
  const isCurrentDateTransfer =
    (scheduledPaymentType === 'one-time' && !isFutureDate(oneTimeTransfer.date)) ||
    (scheduledPaymentType === 'recurring' && !isFutureDate(values?.recurringPayment?.startDate));

  if (
    formattedAmount > availableBalance &&
    isCurrentDateTransfer &&
    ['WIRE_TRANSFER', 'ACH'].includes(paymentMethodType)
  ) {
    errors.amount =
      'Insufficient funds to make this transfer, please edit the transfer amount to continue.';
    return errors;
  }

  // ACH limits validation - use the standalone function instead of the hook
  if (paymentMethodType === 'ACH') {
    const { dailyCreditTotal, monthlyCreditTotal, dailyCreditLimit, monthlyCreditLimit } =
      selectedFromAccObj?.limits ?? {};

    // Use validateTransferLimits from the imported function instead of the hook
    const validationResult = validateTransferLimits({
      dailyTotal: dailyCreditTotal,
      monthlyTotal: monthlyCreditTotal,
      dailyLimit: dailyCreditLimit,
      monthlyLimit: monthlyCreditLimit,
      amount: formattedAmount,
    });

    if (validationResult.hasError) errors.amount = validationResult.message;
  }

  // Check payment limits validation
  if (paymentMethodType === 'CHECK_PAYMENT') {
    const {
      checkPaymentDailyTotal,
      checkPaymentMonthlyTotal,
      checkPaymentDailyLimit,
      checkPaymentMonthlyLimit,
    } = selectedFromAccObj?.limits ?? {};

    const { hasError, message } = validateTransferLimits({
      dailyTotal: checkPaymentDailyTotal,
      monthlyTotal: checkPaymentMonthlyTotal,
      dailyLimit: checkPaymentDailyLimit,
      monthlyLimit: checkPaymentMonthlyLimit,
      amount: formattedAmount,
    });

    if (hasError) errors.amount = message;
  }

  return errors;
};

const validateFromAccountField = ({ values, accountsMap }) => {
  const errors = {};

  const { fromTransferAccountId, paymentMethodType } = values;
  const selectedFromAccObj =
    fromTransferAccountId !== '' ? accountsMap[fromTransferAccountId] : null;
  const { availableBalance } = selectedFromAccObj || {};
  if (fromTransferAccountId === '') {
    errors.fromTransferAccountId = 'Please select account';
  }

  if (
    paymentMethodType === 'CHECK_PAYMENT' &&
    ((isSameDay(values?.oneTimeTransfer?.date) && values?.scheduledPaymentType === 'one-time') ||
      (isSameDay(values?.recurringPayment?.startDate) &&
        values?.scheduledPaymentType === 'recurring'))
  ) {
    if (availableBalance < 2) {
      errors.fromTransferAccountId = 'Minimum balance of $2 needed to cover the check fee';
    }
  }

  return errors;
};

const validatePaymentMethodField = (values) => {
  const errors = {};

  const { paymentMethodId } = values;

  if (!paymentMethodId) {
    errors.paymentMethodId = 'Select paymentMethod';
  }

  return errors;
};

export const handleRecipientValidation = (values) => {
  let errors = {
    ...recipientValidation(values),
    ...validatePaymentMethodField(values),
  };

  if (values?.paymentMethodId === 'achPaymentMethods') {
    errors = { ...errors, ...recipientACHPaymentValidation(values) };
  }
  if (values?.paymentMethodId === 'wirePaymentMethods') {
    errors = { ...errors, ...recipientWirePaymentValidation(values) };
  }
  if (values?.paymentMethodId === 'checkPaymentMethods') {
    errors = { ...errors, ...recipientCheckPaymentValidation(values) };
  }

  return errors;
};

export const handlePaymentMethodValidation = (values, selectedPaymentMethod) => {
  let errors = {};
  if (values?.paymentMethodType === 'ACH' || selectedPaymentMethod?.type === 'ACH') {
    errors = { ...errors, ...recipientACHPaymentValidation(values) };
  }
  if (values?.paymentMethodType === 'WIRE_TRANSFER' || selectedPaymentMethod?.type === 'Wire') {
    errors = { ...errors, ...recipientWirePaymentValidation(values) };
  }
  if (values?.paymentMethodType === 'CHECK_PAYMENT' || selectedPaymentMethod?.type === 'Check') {
    errors = { ...errors, ...recipientCheckPaymentValidation(values) };
  }

  return errors;
};

export const handleFundsValidation = ({ values, accountsMap, currentStep }) => {
  if (currentStep === steps.ADD_RECIPIENT) {
    return handleRecipientValidation(values);
  }
  if (currentStep === steps.UPDATE_OR_ADD_PAYMENT_METHOD) {
    return handlePaymentMethodValidation(values);
  }
  const errors = {
    ...validateRecipientField({ values }),
    ...validateDescriptionField({ values }),
    ...validateSendOnDateField({ values }),
    ...validateAmountField({ values, accountsMap }),
    ...validateFromAccountField({
      values,
      accountsMap,
    }),
  };

  // Validation for recurring payment.
  const recurringPaymentErrors = {};
  if (values?.scheduledPaymentType === 'recurring') {
    if (values?.recurringPayment?.endBy === 'date' && values?.recurringPayment?.endDate) {
      if (values?.recurringPayment?.startDate > values?.recurringPayment?.endDate) {
        recurringPaymentErrors.endDate = 'End date must be greater than start date';
      }
    }
    if (!values?.recurringPayment?.repeatEvery?.value) {
      recurringPaymentErrors.repeatEvery = {
        value: 'Value is required',
      };
    }
    if (values?.recurringPayment?.repeatEvery?.value < 1) {
      recurringPaymentErrors.repeatEvery = {
        value: 'Value must be greater than 0',
      };
    }
    if (values?.recurringPayment?.endBy === 'numberOfPayments') {
      if (!values?.recurringPayment?.numberOfPayments) {
        recurringPaymentErrors.numberOfPayments = 'Number of payments is required';
      }
      if (values?.recurringPayment?.numberOfPayments < 1) {
        recurringPaymentErrors.numberOfPayments = 'Number of payments must be greater than 0';
      }
    }
  }

  // If there are errors in recurring payment, add them to the main errors object.
  if (Object.keys(recurringPaymentErrors).length) {
    errors.recurringPayment = recurringPaymentErrors;
  }
  return errors;
};
