// @flow
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';
import { useCurrentWorkspace, useUserAccessibleWorkspace } from '@shared/hooks';
import {
  Icon16Persons,
  Icon16Person,
  Icon16Plus,
  Icon16Logout,
  Icon16Newwindow,
  Icon16Chatbot,
} from '@icons/16px';
import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { USER_WORKSPACE, USER_PROFILE, CREATE_WORKSPACE } from '@routes';
import UserContext from '@contexts/UserContext';
import sendSegmentEvent, { createWorkspaceSegmentData } from '@core/utils/sendSegmentEvent';
import useLogout from '@hooks/useLogout';
import LoggedInUserInfo from './LoggedInUserInfo';
import WorkspaceInfo from './WorkspaceInfo';
import useSwitchWorkspace from './useSwitchWorkspace';
import BaselaneMenu from '../../BaselaneMenu';

const WorkspaceMenu = () => {
  const { checkGate } = useStatsigClient();
  const { accessibleWorkspaceData } = useUserAccessibleWorkspace();
  const { activeWorkspaceId, workspaceOwner } = useCurrentWorkspace();

  const workspaceName = accessibleWorkspaceData.find((w) => w.isActive)?.name;
  const activeWorkspaceIds = accessibleWorkspaceData
    .filter((workspace) => workspace.isActive)
    .map((workspace) => workspace.id);

  const [hasWorkspace, setHasWorkspace] = useState(false);

  const getWorkspaceInitial = () => {
    return workspaceName ? workspaceName.substring(0, 1) : '';
  };

  useEffect(() => {
    const ownerWorkspace = accessibleWorkspaceData.find((w) => w.isOwner);
    setHasWorkspace(!!ownerWorkspace);
  }, [accessibleWorkspaceData.map((w) => w.isOwner)]);

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  const navigate = useNavigate();
  const { logout } = useLogout();

  const switchWorkspace = useSwitchWorkspace();

  const handleProfilesClick = () => {
    navigate(USER_PROFILE);
  };
  const handleWorkspaceSettingsClick = () => {
    navigate(USER_WORKSPACE);
  };
  const handleWorkspaceClick = (w) => {
    if (w.isActive) return;
    switchWorkspace(w);
  };

  const { user } = useContext(UserContext);

  const handleConvertGuestToLandlordClick = () => {
    sendSegmentEvent('workspace_fe_create_workspace_clicked', {
      ...createWorkspaceSegmentData(workspaceOwner, activeWorkspaceId, workspaceName, user),
      workspace_ids: activeWorkspaceIds,
      workspace_count: accessibleWorkspaceData?.count,
    });
    navigate(CREATE_WORKSPACE);
  };
  const handleLogoutClick = () => {
    zendeskAPI('messenger', 'logoutUser');
    logout();
  };

  const userWorkspaces = accessibleWorkspaceData.map((w) => ({
    id: w.id,
    renderChildern: <WorkspaceInfo workspace={w} />,
    isSelected: w.isActive,
    onClick: () => handleWorkspaceClick(w),
    closeOnSelect: w.isActive && false,
    bg: w.active && 'none !important',
    as: w.active && 'Box',
  }));

  // Conditionally add "Create your workspace" option
  if (!hasWorkspace) {
    userWorkspaces.push({
      id: 'convert_guest_to_landlord',
      name: 'Create your workspace',
      onClick: handleConvertGuestToLandlordClick,
      icon: <Icon16Plus />,
      hasDivider: false,
    });
  }

  const menuItems = [
    {
      id: 'user_info',
      name: 'Workspace',
      as: 'div',
      renderChildern: <LoggedInUserInfo />,
      hasDivider: true,
      closeOnSelect: false,
      bg: 'none !important',
    },
    ...userWorkspaces,
    {
      id: 'workspace_setting',
      name: 'Workspace settings',
      onClick: handleWorkspaceSettingsClick,
      icon: <Icon16Persons />,
      hasDivider: true,
    },
    {
      id: 'profile_settings',
      name: 'Profile settings',
      onClick: handleProfilesClick,
      icon: <Icon16Person />,
    },
    {
      id: 'help_center',
      name: 'Help center',
      as: 'a',
      href: 'https://support.baselane.com/hc/en-us',
      target: '_blank',
      icon: <Icon16Newwindow />,
    },
    {
      id: 'support',
      name: 'Virtual assistant',
      onClick: () => {
        if (checkGate(FEATURE_GATES.HIDE_ZENDESK_BUBBLE)) {
          window.adaEmbed.toggle();
        } else {
          zendeskAPI('messenger', 'open');
        }
      },
      icon: <Icon16Chatbot />,
    },
    { id: 'log_out', name: 'Log out', onClick: handleLogoutClick, icon: <Icon16Logout /> },
  ];

  return (
    <BaselaneMenu
      listItems={menuItems}
      label={workspaceName}
      initial={getWorkspaceInitial()}
      placement="bottom-end"
      isProfile
    />
  );
};

export default WorkspaceMenu;
