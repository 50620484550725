import React from 'react';
import IllustrationSuccess from '@icons/manual/IllustrationSuccess';
import IllustrationError from '@icons/manual/IllustrationError';
import { IllustrationBankingApplicationinreview } from '@illustrations';

export const getDynamicIcon = ({ type }) => {
  let icon = null;

  switch (type) {
    case 'success':
      icon = <IllustrationSuccess />;
      break;

    case 'neutral':
      icon = <IllustrationBankingApplicationinreview width="60" height="60" />;
      break;

    case 'error':
      icon = <IllustrationError />;
      break;

    default:
      break;
  }

  return icon;
};
