import {
  Icon16Feesotherrevenue,
  Icon16Rents,
  Icon16Advertising,
  Icon16Autotravel,
  Icon16Cleaningmaintenance,
  Icon16Commissions,
  Icon16Depreciationexpensesordepletion,
  Icon16Insurance,
  Icon16Legalotherprofessionalfees,
  Icon16Managementfees,
  Icon16OtherOperatingExpenses,
  Icon16Repairs,
  Icon16Supplies,
  Icon16Taxes,
  Icon16Utilities,
  Icon16Capitalexpenditures,
  Icon16Mortgagepayments,
  Icon16Otherloanpayments,
  Icon16Downpayments,
  Icon16Mortgagedisbursementsreceived,
  Icon16Otherloandisbursementsreceived,
  Icon16Saleproceeds,
  Icon16Creditcardpayments,
  Icon16Escrowpayments,
  Icon16Interestreceived,
  Icon16Other,
  Icon16Ownercontributionsdistributions,
  Icon16Securitydeposits,
  Icon16Transfersbetweenaccounts,
  Icon16NonPropertyExpense,
  Icon16Interestearnedonsecuritydeposits,
  Icon16Recurring,
  Icon16DoubleArrowRight,
  Icon16Check,
  Icon16Sparkle,
} from '@icons/16px';

// /-& need to strip
export const iconmapping = {
  // revenue
  feesotherrevenue: Icon16Feesotherrevenue,
  rents: Icon16Rents,
  // operating expenses
  advertising: Icon16Advertising,
  autotravel: Icon16Autotravel,
  cleaningmaintenance: Icon16Cleaningmaintenance,
  commissions: Icon16Commissions,
  depreciationexpensesordepletion: Icon16Depreciationexpensesordepletion,
  insurance: Icon16Insurance,
  legalotherprofessionalfees: Icon16Legalotherprofessionalfees,
  managementfees: Icon16Managementfees,
  otheroperatingexpenses: Icon16OtherOperatingExpenses,
  repairs: Icon16Repairs,
  supplies: Icon16Supplies,
  taxes: Icon16Taxes,
  utilities: Icon16Utilities,
  // loan payments & capex
  capitalexpenditures: Icon16Capitalexpenditures,
  mortgageinterestpayments: Icon16Mortgagepayments, // will need new icon
  mortgagepayments: Icon16Mortgagepayments,
  mortgageprincipalpayments: Icon16Mortgagepayments, // will need new icon
  otherloaninterestpayments: Icon16Otherloanpayments, // will need new icon
  otherloanpayments: Icon16Otherloanpayments,
  otherloanprincipalpayments: Icon16Otherloanpayments, // will need new icon
  // property transactions
  downpayments: Icon16Downpayments,
  mortgagedisbursmentsreceived: Icon16Mortgagedisbursementsreceived,
  otherloandisbursementsrecieved: Icon16Otherloandisbursementsreceived,
  saleproceeds: Icon16Saleproceeds,
  // transfers & others
  creditcardpayments: Icon16Creditcardpayments,
  escrowpayments: Icon16Escrowpayments,
  interestreceived: Icon16Interestreceived,
  other: Icon16Other,
  ownercontributionsdistributions: Icon16Ownercontributionsdistributions,
  securitydepositinterestearned: Icon16Interestearnedonsecuritydeposits,
  securitydeposits: Icon16Securitydeposits,
  transfersbetweenaccounts: Icon16Transfersbetweenaccounts,
  // non-property expense
  nonpropertyexpense: Icon16NonPropertyExpense,
  rulemanual: Icon16Check,
  ruleai: Icon16Sparkle,
  rulecustom: Icon16DoubleArrowRight,
  ruletransfer: Icon16Recurring,
  rulesystem: Icon16Sparkle,
  ruledefault: Icon16Sparkle,
};
