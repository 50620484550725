import React from 'react';
import { Box, Flex, Text, Stack, Image, VStack } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { useLazyQuery } from '@apollo/client';
import { BaselaneCard, BaselaneModal, BaselaneButton } from '@shared/components';
import DesktopScreenShot from './assets/DesktopScreenShot.png';
import HalfIphone from './assets/HalfIphone.png';
import { GET_KYC_URL } from '../NativeBankingPage/queries';
import { cardArray } from './helpers/dashboard.helper';
import {
  popUpCardTextStyles,
  popUpCardStyles,
  popupMobileImageContainerStyles,
  popUpTitleTextStyles,
  popUpCardStackStyles,
  modalBodyStyles,
  modalContentStyles,
  popUpCardContainerStyles,
  mobilePopUpIconContainer,
  desktopContainerStyles,
  rightPopUpContentContainer,
  rightPopUpContentContainerWithoutButton,
  popupDesktopImageContainerStyles,
} from './styles/onboardingPopUp.styles';

type OnboardingPopUpProps = {
  handleHidePopUp: Function,
};

const OnboardingPopUp = ({ handleHidePopUp }: OnboardingPopUpProps) => {
  const [getKYCUrl] = useLazyQuery(GET_KYC_URL, {
    onCompleted: ({ unitOnBoarding }) => {
      window.open(unitOnBoarding, '_self');
    },
    fetchPolicy: 'no-cache',
  });

  const handleClick = () => {
    handleHidePopUp();
    getKYCUrl();
  };

  const handleClose = () => {
    handleHidePopUp();
  };

  const cards = cardArray(isMobile).map((card) => {
    return isMobile ? (
      <Flex key={card.text} {...popUpCardContainerStyles(isMobile)}>
        <Box {...mobilePopUpIconContainer}>{card.icon}</Box>
        <Text {...popUpCardTextStyles(isMobile)}>
          {card.text} {card?.text2}
        </Text>
      </Flex>
    ) : (
      <BaselaneCard key={card.text} styles={{ ...popUpCardStyles }}>
        <Flex {...popUpCardContainerStyles(isMobile)}>
          {card.icon}
          <Text {...popUpCardTextStyles(isMobile)}>
            {card.text}
            <br />
            {card?.text2}
          </Text>
        </Flex>
      </BaselaneCard>
    );
  });

  const baselaneButton = (
    <BaselaneButton variant="filled" palette="primary" onClick={handleClick}>
      Open a Banking Account
    </BaselaneButton>
  );

  return (
    <BaselaneModal
      closeOnOverlayClick={false}
      onCloseBtnClick={handleClose}
      defaultOpen
      showCloseButton
      showFooter={false}
      showHeader={false}
      showBody={false}
      modalContentStyles={modalContentStyles}
      modalBodyStyles={modalBodyStyles(isMobile)}
      size="3xl"
    >
      {isMobile ? (
        <>
          <Box {...popupMobileImageContainerStyles}>
            <Image src={HalfIphone} alt="Half Iphone" h="200px" w="319px" />
          </Box>
          <Text {...popUpTitleTextStyles(isMobile)}>Banking Reimagined for Landlords</Text>
          <VStack {...popUpCardStackStyles(isMobile)}>{cards}</VStack>
          {baselaneButton}
        </>
      ) : (
        <>
          <Flex {...desktopContainerStyles}>
            <Stack {...popUpCardStackStyles(isMobile)}>{cards}</Stack>
          </Flex>
          <Box {...rightPopUpContentContainer}>
            <Flex {...rightPopUpContentContainerWithoutButton}>
              <Text {...popUpTitleTextStyles(isMobile)}>Banking Reimagined for Landlords</Text>
              <Box {...popupDesktopImageContainerStyles}>
                <Image
                  src={DesktopScreenShot}
                  alt="Basleane App ScreenShot with card"
                  h="239px"
                  w="359px"
                />
              </Box>
            </Flex>
            {baselaneButton}
          </Box>
        </>
      )}
    </BaselaneModal>
  );
};

export default OnboardingPopUp;
