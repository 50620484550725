import React, { useState, useEffect, useRef } from 'react';

import { Formik } from 'formik';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import LogRocket from 'logrocket';
import { useMutation, useApolloClient } from '@apollo/client';
import {
  Text,
  Box,
  Stack,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  useDisclosure,
  FormControl,
  FormErrorMessage,
  Link,
  ChakraProvider,
} from '@chakra-ui/react';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { CREATE_USER } from '@core/apollo/queries';
import apolloClient from '@core/apollo/apolloClient';
import { IconExclamationCircleFilled } from '@icons';
import { Icon16Hide, Icon16Unhide, Icon16Info } from '@icons/16px';

import {
  BaselaneButton,
  BaselaneButtonIcon,
  BaselaneAlert,
  BaselaneAlertNew,
} from '@shared/components';

import inviteCodeStorage from '@core/storage/inviteCodeStorage';
import { getQueryParams } from '@core/Services/Firebase/firebase';
import { emailVerificaton } from '@core/Services/CreateAccount';
import * as ROUTES from '@routes';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import {
  AuthenticationError,
  emailAlreadyInUse,
  errorMessages,
  isMessageCardError,
} from '@features/Authentication/helpers/error.helpers';
import {
  passwordValidator,
  initialChecklist,
  initialFormValues,
  validateSignUp,
} from '@features/Authentication/helpers/validation.helpers';
import { firebaseEmailSignUp } from '@features/Authentication/helpers/firebase.helpers';
import {
  cleanupRedirects,
  clearUrlUtmData,
  deleteUtmCookies,
  getUtmInputs,
  saveSearch,
  storeUrlUtmData,
  trackUtmUser,
} from '@features/Authentication/helpers/tracking.helpers';
import { attemptToCreateUser } from '@features/Authentication/helpers/signup.helpers';

import { CREATE_USER_RETRY_COUNT_DEFAULT } from '@features/Authentication/helpers/constant.helpers';
import themeHabitat from '@core/themeHabitat';
import useCurrentWorkspace from '@shared/hooks/useCurrentWorkspace';
import useIpAddress from '@shared/hooks/useIpAddress';
import { reactNodeEnv } from '@core/constants/envVars';
import PasswordChecker from './PasswordChecker';
import PrivacyTerms from '../PrivacyTerms';
import inActivityLogoutStorage from '../../../../storage/inActivityLogoutStorage';

import { formStyle, inputStack, signupButtonStyles } from '../../styles/signup.styles';
import {
  inputContainerStyles,
  formlabelCustomized,
  formErrorStylesCustomized,
  formInputCustomized,
  firstLastNameContainerStyle,
} from '../../styles/createaccount.style';
import { signUpTestsFooterNote } from '../../styles/signupTests.styles';

type CreateAccountFormProps = {
  signUpVariant: String,
  initialEmail: String,
  isCollaborator: Boolean,
};

function CreateAccountForm({
  signUpVariant,
  initialEmail,
  isCollaborator,
}: CreateAccountFormProps) {
  const { ipAddress } = useIpAddress();

  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const formikRef = useRef();
  const initialPopoverFocusRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const [createUser] = useMutation(CREATE_USER);

  const { activeWorkspaceId, workspaceName, refetchCurrentWorkspace } = useCurrentWorkspace();

  const inviteCode = inviteCodeStorage.read('inviteCode');

  // Read utmInput from affiliate url
  const currLocation = useLocation();

  const [showChecklist, setShowChecklist] = useState(false);
  const [checklist, setChecklist] = useState(initialChecklist);
  const [isStrongPassword, setStrongPassword] = useState(false);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [initialEmailValue, setInitialEmailValue] = useState(initialEmail);

  // FingerprintJS
  const { error: fingerprintError, getData: getDataFingerprint } = useVisitorData({
    extendedResult: true,
    tag: {
      env: reactNodeEnv,
    },
  });

  const handlePasswordVisibility = () => setShow(!show);
  const weakPassword = () => Object.keys(checklist).find((item) => !checklist[item].isValid);

  const client = useApolloClient();

  const handleFormSubmit = (e) => {
    const { email, password, firstName, lastName } = e;

    sendSegmentEvent('sign_up_clicked', {
      enteredEmail: email,
      event_source: 'landlord_portal',
    });

    setIsLoading(true);
    let requestId = '';
    // attempt to retrieve user's device fingerprint
    getDataFingerprint()
      .then((data) => {
        requestId = data?.requestId;
      })
      .catch((error) => {
        LogRocket.log(`getDataFingerprint error: ${JSON.stringify(error)}`);
      })
      .finally(() => {
        const utmInputs = getUtmInputs(ipAddress, location, 'account_created');
        // attempt to log in with google account
        // this should happen with or without fingerprint data
        firebaseEmailSignUp({
          email,
          password,
          handleSuccess: (credential) => {
            attemptToCreateUser({
              firstName,
              lastName,
              credential,
              CREATE_USER_RETRY_COUNT_DEFAULT,
              requestId,
              utmInputs,
              location,
              inviteCode,
              createUser,
              setIsLoading,
              onSuccess: (res) => {
                const bodyParam = { email };
                // force refresh of token to ensure we have the latest claims
                emailVerificaton(bodyParam)
                  .then((data) => {
                    const { result } = data;
                    if (result === 'success') {
                      // createUser success

                      // tracking event
                      if (!isCollaborator) {
                        trackUtmUser(credential.user, 'account_created', ipAddress, utmInputs);
                      }

                      if (isCollaborator) {
                        const collaboratorProfileId =
                          getQueryParams(currLocation, 'collaborator_profile_id') ?? '';

                        refetchCurrentWorkspace().then(
                          sendSegmentEvent('workspace_fe_collaborator_signed_up', {
                            timestamp: new Date().valueOf(),
                            user_id: credential?.user?.uid,
                            workspace_id: activeWorkspaceId,
                            workspace_name: workspaceName,
                            role: 'GUEST',
                            invite_link: inviteCode,
                            collaborator_profile_id: collaboratorProfileId, // TODO: add collaborator_profile_id (associated with the invite link)
                          })
                        );
                      }

                      // reddit tracking
                      window?.rdt('track', 'SignUp');

                      try {
                        apolloClient.resetStore();
                      } catch (error) {
                        console.error('Error clearing Apollo Client store:', error);
                      }
                    } else if (inviteCode) {
                      onAlertOpen();
                    }

                    // delete utm cookie data, as it has been consumed
                    deleteUtmCookies();
                    // delete utm data from storage, as it has been consumed
                    clearUrlUtmData();
                  })
                  .then(() => {
                    // store search parameters in local storage
                    saveSearch();
                    // cleanup redirect data
                    cleanupRedirects();

                    navigate(ROUTES.ONBOARDING_PROPERTY_SURVEY);
                  })
                  .catch((err) => {
                    const { message } = err;
                    if (message.includes('Invalid or missing inviteCode')) {
                      onAlertOpen();
                    }
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });

                try {
                  client.resetStore();
                } catch (error) {
                  console.error('Error clearing Apollo Client store:', error);
                }
                navigate(ROUTES.HOME);
              },
              onQueryFail: (error) => {
                const { message } = error;
                if (
                  message?.includes('Invite code not associated with the email') ||
                  message?.includes('has been REVOKED')
                ) {
                  navigate('/expired-invitation');
                }
                if (message.includes('Invalid or missing inviteCode')) {
                  onAlertOpen();
                } else {
                  formikRef?.current?.setErrors({
                    ...formikRef?.current?.errors,
                    signUp: errorMessages[AuthenticationError.generic],
                  });
                }

                LogRocket.log(
                  `CreateUser (Email SignUp) error: ${JSON.stringify(
                    error
                  )}, requestId:${requestId}, email: ${credential?.user?.email}`
                );
              },
              onLastAttemptFail: (responseErrors) => {
                if (emailAlreadyInUse(responseErrors[0]?.message)) {
                  formikRef?.current?.setErrors({
                    ...formikRef?.current?.errors,
                    email: errorMessages[AuthenticationError.emailalreadyinuse],
                  });
                }
                LogRocket.log(
                  `CreateUser (Email SignUp) error: ${JSON.stringify(
                    responseErrors
                  )}, requestId:${requestId}, email: ${credential?.user?.email}`
                );
                sendSegmentEvent('baselane_signup_failed', {
                  enteredEmail: credential?.user?.email,
                  idNotFound: true,
                  event_source: 'landlord_portal',
                });
              },
            });
          },
          handleError: (error) => {
            setIsLoading(false);

            LogRocket.log(
              `signUpWithCustomEmail error: ${JSON.stringify(error)}, requestId:${requestId}`
            );

            inActivityLogoutStorage.delete();
            localStorage.removeItem('redirectLogin');

            const { code } = error || {};
            const requestErrors = {};

            switch (code) {
              case AuthenticationError.invalidemail:
              case AuthenticationError.emailalreadyinuse:
                requestErrors.email = errorMessages[code];
                break;
              case AuthenticationError.tenant:
                requestErrors.signUp = errorMessages[code];
                break;
              default:
                // do nothing - unhandled error
                break;
            }

            // set login form errors
            formikRef?.current?.setErrors({ ...formikRef?.current?.errors, ...requestErrors });
          },
        });
      });
  };

  const encodedEmail = getQueryParams(currLocation, 'email') ?? '';
  const decodedEmail = decodeURIComponent(encodedEmail) || initialEmailValue;
  const togglePasswordChecklistVisibility = () =>
    setShowChecklist((prevState) => {
      return !prevState;
    });

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      formikRef.current.handleSubmit();
    }
  };

  // Log fingerprint error
  useEffect(() => {
    if (fingerprintError) {
      LogRocket.log(`fingerprint API error: ${JSON.stringify(fingerprintError)}`);
    }
  }, [fingerprintError]);

  // save url utm data, in case it's lost before logging in
  useEffect(() => {
    if (ipAddress) {
      storeUrlUtmData(ipAddress, location);
    }
  }, [ipAddress]);

  useEffect(() => {
    if (!weakPassword()) {
      setStrongPassword(true);
      formikRef.current?.validateForm();
    }
  }, [checklist]);

  useEffect(() => {
    setInitialEmailValue(initialEmail);
  }, [initialEmail]);
  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        validate={(values) => validateSignUp(values, weakPassword, setStrongPassword)}
        initialValues={initialFormValues(decodedEmail)}
        onSubmit={handleFormSubmit}
      >
        {({ values, initialValues, handleSubmit, handleBlur, handleChange, errors, touched }) => (
          <Stack as="form" direction="row" {...formStyle}>
            <Stack {...inputStack}>
              {/* First Row: First Name */}
              <Stack {...firstLastNameContainerStyle(signUpVariant)}>
                <FormControl
                  {...{
                    ...inputContainerStyles,
                    w: { base: signUpVariant === 'C' ? '50%' : '100%', md: '50%', xl: '100%' },
                    mr: { base: '0px', md: '20px', xl: '0px' },
                  }}
                  isInvalid={errors.firstName && touched.firstName}
                >
                  <FormLabel htmlFor="firstName" {...formlabelCustomized}>
                    First name
                  </FormLabel>
                  <Input
                    {...formInputCustomized}
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleOnKeyDown}
                    placeholder="Enter first name"
                    maxLength="50"
                  />
                  <FormErrorMessage {...formErrorStylesCustomized}>
                    <Text as="span" {...{ mr: '8px' }}>
                      <IconExclamationCircleFilled />
                    </Text>
                    {errors.firstName}
                  </FormErrorMessage>
                </FormControl>

                {/* Second Row: Last Name */}
                <FormControl
                  {...{
                    ...inputContainerStyles,
                    w: { base: signUpVariant === 'C' ? '50%' : '100%', md: '50%', xl: '100%' },
                    mt: { base: signUpVariant === 'C' ? '0' : '12px', md: '0px', xl: '12px' },
                  }}
                  isInvalid={errors.lastName && touched.lastName}
                >
                  <FormLabel htmlFor="lastName" {...formlabelCustomized}>
                    Last name
                  </FormLabel>
                  <Input
                    {...formInputCustomized}
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleOnKeyDown}
                    placeholder="Enter last name"
                    maxLength="50"
                  />
                  <FormErrorMessage {...formErrorStylesCustomized}>
                    <Text as="span" {...{ mr: '8px' }}>
                      <IconExclamationCircleFilled />
                    </Text>
                    {errors.lastName}
                  </FormErrorMessage>
                </FormControl>
              </Stack>

              {/* Third Row: Email */}
              <FormControl
                {...inputContainerStyles}
                isInvalid={
                  errors.email &&
                  errors?.email !== errorMessages[AuthenticationError.emailalreadyinuse] &&
                  touched.email
                }
              >
                <FormLabel htmlFor="email" {...formlabelCustomized}>
                  Email
                </FormLabel>
                <Input
                  {...formInputCustomized}
                  id="email"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onKeyDown={handleOnKeyDown}
                  onChange={handleChange}
                  placeholder="Enter email"
                  isDisabled={isCollaborator}
                />
                <FormErrorMessage {...formErrorStylesCustomized}>
                  <Text as="span" {...{ mr: '8px' }}>
                    <IconExclamationCircleFilled />
                  </Text>
                  {errors.email}
                </FormErrorMessage>
              </FormControl>

              {/* Fourth Row: Password */}
              <Box
                {...{
                  ...inputContainerStyles,
                  position: 'relative',
                  w: '100%',
                  zIndex: '1',
                }}
              >
                <FormControl isInvalid={errors.password && touched.password}>
                  <FormLabel htmlFor="password" {...formlabelCustomized}>
                    Password
                  </FormLabel>
                  <InputGroup>
                    <Input
                      {...formInputCustomized}
                      ref={initialPopoverFocusRef}
                      id="password"
                      name="password"
                      placeholder="Enter Password"
                      value={values.password}
                      type={show ? 'text' : 'password'}
                      onFocus={togglePasswordChecklistVisibility}
                      onChange={(e) => {
                        const p = e.target.value;
                        const updatedChecklist = passwordValidator(p, checklist);
                        setChecklist(updatedChecklist);
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        togglePasswordChecklistVisibility();
                      }}
                      onKeyDown={handleOnKeyDown}
                    />
                    <InputRightElement h="100%">
                      <BaselaneButtonIcon
                        variant="transparent"
                        palette="neutral"
                        size="sm"
                        icon={show ? <Icon16Hide /> : <Icon16Unhide />}
                        isActive={show}
                        onClick={handlePasswordVisibility}
                      />
                    </InputRightElement>
                    {!isMobile && (
                      <PasswordChecker
                        {...{
                          initialPopoverFocusRef,
                          showChecklist,
                          checklist,
                          isStrongPassword,
                        }}
                      />
                    )}
                  </InputGroup>
                  <FormErrorMessage {...formErrorStylesCustomized}>
                    <Text as="span" {...{ mr: '8px' }}>
                      <IconExclamationCircleFilled />
                    </Text>
                    {errors.password}
                  </FormErrorMessage>
                  {isMobile && (
                    <PasswordChecker
                      {...{
                        initialPopoverFocusRef,
                        showChecklist,
                        checklist,
                        isStrongPassword,
                      }}
                    />
                  )}
                </FormControl>
              </Box>

              {isMessageCardError(errors) && (
                <ChakraProvider theme={themeHabitat}>
                  <Box w="full" mt={2}>
                    <BaselaneAlertNew
                      id={
                        errorMessages[AuthenticationError.emailalreadyinuse]
                          ? 'create-account-error-email-in-use'
                          : 'create-account-error-other'
                      }
                      variant="danger"
                      hasCloseButton={false}
                      visual="icon"
                      iconName={Icon16Info}
                      body={
                        <Stack>
                          <Box>
                            {errors?.email || errors?.signUp}{' '}
                            {errors?.email ===
                              errorMessages[AuthenticationError.emailalreadyinuse] && (
                              <Box as={isMobile ? 'span' : 'p'} mt="0 !important">
                                Sign in as a{' '}
                                <RouterLink to={ROUTES.LOGIN}>
                                  <Text as="span" textDecoration="underline">
                                    landlord
                                  </Text>
                                </RouterLink>{' '}
                                or{' '}
                                <Link
                                  href="https://tenant.baselane.com/"
                                  isExternal
                                  textDecoration="underline"
                                >
                                  tenant
                                </Link>
                              </Box>
                            )}
                          </Box>
                        </Stack>
                      }
                    />
                  </Box>
                </ChakraProvider>
              )}

              {/* Create Account Button */}
              <BaselaneButton
                id="signUpButton" // add for consultants for growth team
                variant="filled"
                palette="primary"
                size="lg"
                onClick={handleSubmit}
                onKeyDown={handleOnKeyDown}
                styles={{ ...signupButtonStyles, mt: '16px !important' }}
                isLoading={isLoading}
              >
                Get started - It’s free
              </BaselaneButton>

              <PrivacyTerms styles={signUpTestsFooterNote} />
            </Stack>
          </Stack>
        )}
      </Formik>
      <BaselaneAlert
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        closeOnOverlayClick
        isCentered
        header="Invalid Invite Code"
        body={
          <Text>
            The invite code entered is invalid. If you have been invited to a 1-year trial, please
            ensure the code matches the one provided to you by Baselane. Otherwise, you may sign up
            without an invite code for a 2-month trial.
          </Text>
        }
        footer={
          <BaselaneButton palette="primary" variant="filled" onClick={() => navigate('/signup')}>
            Return to Signup
          </BaselaneButton>
        }
      />
    </>
  );
}

export default CreateAccountForm;
