import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { BaselaneMenu } from '@shared/components';
import { CREATE_LEASE_AGREEMENT, NEW_LEASE_AGREEMENT } from '@core/constants/routes';
import { Icon16Upload, Icon16Notes } from '@icons/16px';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

const NewAgreementButton = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const entryPoint = pathname.split('/').pop();

  return (
    <BaselaneMenu
      buttonPalette="primary"
      buttonVariant="filled"
      size="lg"
      label="New Lease agreement"
      styles={{
        list: {
          maxWidth: '320px',
          zIndex: 11,
        },
      }}
      listItems={[
        {
          name: 'Draft and e-sign',
          description:
            'Customize your own state-specific lease agreement and e-sign it with tenants ($10)',
          onClick: () => {
            sendSegmentEvent('lease_agreement_fe_new_lease_clicked', { entry_point: entryPoint });
            sendSegmentEvent('lease_agreement_fe_creation_started', { entry_point: entryPoint });
            navigate(`/${CREATE_LEASE_AGREEMENT}`);
          },

          icon: (
            <Box>
              <Icon16Notes />
            </Box>
          ),
        },
        {
          name: 'Upload (e-sign optional)',
          description: 'Upload your lease agreement for easy reference and optionally e-sign',
          onClick: () => {
            sendSegmentEvent('lease_agreement_fe_upload_started', { entry_point: entryPoint });
            navigate(NEW_LEASE_AGREEMENT);
          },
          icon: (
            <Box>
              <Icon16Upload />
            </Box>
          ),
        },
      ]}
    />
  );
};

export default NewAgreementButton;
