import React, { useContext, useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { reload } from '@core/Services/Firebase/firebase';
import { BaselaneButton, VerifyEmailModal } from '@shared/components';
import OTPContext from '@core/contexts/EmailOtpContext';
import UserContext from '@contexts/UserContext';
import IconWarningTriangleYellow from '@icons/manual/IconWarningTriangleYellow';

type MustVerifyEmailAlertProps = {
  isDrawerAlertOpen: boolean,
  onAlertClose: Function,
};

const MustVerifyEmailAlert = ({ isDrawerAlertOpen, onAlertClose }: MustVerifyEmailAlertProps) => {
  const alertCancelRef = useRef(null);
  const { handleSendOtpRegisteredUser } = useContext(OTPContext);
  const { setEmailVerified, user } = useContext(UserContext);
  const [showEmailVerifyPopup, setEmailVerifyPopup] = useState(false);

  const onVerify = async () => {
    const emailVerifiedByFirebase = await reload();
    if (!emailVerifiedByFirebase) {
      setEmailVerifyPopup(true);
      handleSendOtpRegisteredUser({ isTriggeredFromModal: false });
    } else {
      setEmailVerified(true);
      onAlertClose();
    }
  };

  const onVerifyEmailOtpSuccess = async () => {
    const emailVerifiedByFirebase = await reload();
    if (emailVerifiedByFirebase) {
      setEmailVerifyPopup(false);
      setEmailVerified(true);
      onAlertClose();
    }
  };

  return (
    <>
      <AlertDialog
        leastDestructiveRef={alertCancelRef}
        onClose={onAlertClose}
        isOpen={isDrawerAlertOpen}
        isCentered
        closeOnEsc={false}
        closeOnOverlayClick={false}
        size="sm"
      >
        <AlertDialogOverlay />
        <AlertDialogContent p={4}>
          <Stack spacing={2}>
            <AlertDialogCloseButton />
            <IconWarningTriangleYellow />
            <Stack spacing={0.5}>
              <AlertDialogHeader p={0}>
                <Text textStyle="headline-lg">Verify your email to order checkbooks</Text>
              </AlertDialogHeader>
              <AlertDialogBody p={0}>
                <Stack textStyle="sm">
                  <Text>We&apos;ll send you a verification code.</Text>
                </Stack>
              </AlertDialogBody>
            </Stack>
          </Stack>
          <AlertDialogFooter p={0} mt={3}>
            <BaselaneButton
              size="md"
              variant="filled"
              palette="primary"
              onClick={onVerify}
              isFullWidth
            >
              Verify now
            </BaselaneButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {showEmailVerifyPopup && (
        <VerifyEmailModal
          email={user?.email}
          onVerifyEmailOtpSuccess={onVerifyEmailOtpSuccess}
          onCloseBtnClick={() => setEmailVerifyPopup(false)}
        />
      )}
    </>
  );
};

export default MustVerifyEmailAlert;
