import { orchestrationUrl } from '@core/constants/envVars';

export const getEnv = () => {
  const orchURL = orchestrationUrl.replace('https://', '');
  let env = 'develop';
  if (orchURL === 'orchestration.baselane.com/graphql') {
    env = 'production';
  } else if (orchURL === 'uat-orchestration.baselane.com/graphql') {
    env = 'uat';
  } else if (orchURL === 'pre-prod-orchestration.baselane.com/graphql') {
    env = 'pre-prod';
  } else if (orchURL === 'hotfix-orchestration.baselane.com/graphql') {
    env = 'hotfix';
  }

  return env;
};
