// @flow
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HStack, Spacer, Box, Text } from '@chakra-ui/react';
import { useStatsigClient } from '@statsig/react-bindings';
import { BaselaneButton, BaselaneChip, BaselaneMenu, BaselaneTabGroup } from '@shared/components';
import { BOOKKEEPING_RULES, NATIVE_BANK, TRANSACTIONS } from '@routes';
import TransactionContext from '@contexts/TransactionContext';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { Icon16Clear, Icon16Plus, Icon16Tasks, Icon16ShareiOS, Icon16Settings } from '@icons/16px';
import { getPropertyData } from '@shared/helpers/propertiesFilter.helpers';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

import {
  useIsAddTransactionDrawerOpen,
  useToggleAddTransactionDrawer,
} from '@store/Transactions/createTransactionDrawerStore';
import CreateManualTransactionDrawerNew from '../../CreateManualTransaction/CreateManualTransactionDrawer';
import TransactionsSearch from '../TransactionSearch';
import FilterDropdowns from './FilterDropdowns';
import TransactionsBulkActions from '../../TransactionsBulkActions';
import DownloadTrxs from './DownloadTrxs';
import Toggles from './Toggles';
import { getOptionsWithSubCategories } from '../../../CashFlowPage/helpers/cashflow.helpers';
import { compileSearchData } from '../TransactionSearch/helpers/search.helpers';

import {
  filtersFilterRowStyles,
  filterRowContainerStyles,
  filtersSecondRowStyles,
  filtersContainerStyles,
  bulkActionsTextStyles,
  filtersAndTogglesMobileContainerStyles,
} from './styles/transactionsFilters.styles';
import CreateManualTransactionButtonWrapper from '../../CreateManualTransaction/CreateManualTransactionButtonWrapper';

type Props = {
  onToggleHiddenTransactions: Function,
  onToggleOnlyWithAttachments: Function,
  onShowUncategorizedTransactions: Function,
  onSearch: Function,
  isOutsideTrxPage?: boolean,
  showHiddenTransactions: boolean,
  showOnlyWithAttachments: boolean,
  showBulkActions: boolean,
  selectedTransactions: Array<Object>,
  handleDeselectTransactions: Function,
  setSelectedTransactions: Function,
  handleSelectedTransactions: Function,
  handleClear: Function,
  setInputValue: Function,
  inputValue: string,
  showingUncategorizedTransactions: string,
  setShowingUncategorizedTransactions: Function,
  currSort: Object,
  hasDropdownClearedExternally?: boolean,
  setHasDropdownClearedExternally?: Function,
  hideFilters?: boolean,
  styles?: Object,
  connectedAccountsFilterOptions: Array<Object>,
  refetch: Function,
  selectedFilters: Object,
  setSelectedFilters: Function,
  data: Array<Object>,
  transactionsLoading: boolean,
  setShowImportTrxModal: Function,
  handleShowNeedsReviewTransactions: Function,
  isTableOnly: boolean,
};

function TransactionsFilters({
  onToggleHiddenTransactions,
  onToggleOnlyWithAttachments,
  onShowUncategorizedTransactions,
  onSearch,
  isOutsideTrxPage = false,
  showHiddenTransactions,
  showOnlyWithAttachments,
  showBulkActions,
  handleDeselectTransactions,
  handleSelectedTransactions,
  selectedTransactions,
  setSelectedTransactions,
  handleClear,
  setInputValue,
  inputValue,
  showingUncategorizedTransactions,
  setShowingUncategorizedTransactions,
  currSort,
  hasDropdownClearedExternally = false,
  setHasDropdownClearedExternally = () => {},
  hideFilters = false,
  styles = {},
  connectedAccountsFilterOptions,
  refetch,
  selectedFilters,
  setSelectedFilters,
  data,
  transactionsLoading,
  setShowImportTrxModal,
  handleShowNeedsReviewTransactions,
  isTableOnly = false,
}: Props): any {
  const { isMin768, isMin899 } = useBreakPoints();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { checkGate } = useStatsigClient();
  const {
    filters,
    setFilters,
    propertiesData,
    categoryWithSubOptions,
    totalUnreviewedTransactions,
  } = useContext(TransactionContext);

  const [selectedItem, setSelectedItem] = useState(null);
  const [hasActiveParentFilter, setHasActiveParentFilter] = useState(true);
  const [displayExportTransactions, setDisplayExportTransactions] = useState(false);
  const propertyOptions = getPropertyData(propertiesData);
  const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);
  const clientSearchableData = useMemo(
    () => compileSearchData(connectedAccountsFilterOptions, categoryOptions, propertyOptions),
    [connectedAccountsFilterOptions, categoryOptions, propertyOptions]
  );

  const isAddTransactionDrawerOpen = useIsAddTransactionDrawerOpen();
  const toggleAddTransactionDrawer = useToggleAddTransactionDrawer();

  const handleUncategroziedChange = () => {
    setShowingUncategorizedTransactions(!showingUncategorizedTransactions);
    onShowUncategorizedTransactions(showingUncategorizedTransactions);
  };

  /**
   * Resets dropdown state to "unselected" default
   * when the clear button is clicked on.
   */
  const onClearClick = () => {
    setHasActiveParentFilter(false);
  };

  const handleClickTab = (isNeedsReview: boolean) => {
    handleShowNeedsReviewTransactions(isNeedsReview);
  };

  const onImportTrxClick = () => {
    setShowImportTrxModal(true);
    sendSegmentEvent('import_transactions_started', {
      entry_point: 'transactions_page_navigation',
    });
  };

  const handleExportTransactions = () => {
    return setDisplayExportTransactions(true);
  };

  /**
   * Clear out the filter entirely if the global "clear" button is clicked
   */
  useEffect(() => {
    if (!hasDropdownClearedExternally) return;

    setSelectedItem(null);
    setSelectedFilters({
      ...selectedFilters,
      appliedPreset: null,
    });
  }, [hasDropdownClearedExternally]);

  const hasActiveFilter =
    selectedFilters?.accounts?.length > 0 ||
    selectedFilters?.appliedPreset ||
    (selectedFilters?.categories && selectedFilters?.categories?.length > 0) ||
    (selectedFilters?.dates?.start && selectedFilters?.dates?.end) ||
    selectedFilters?.timePeriod ||
    selectedFilters?.properties?.length > 0 ||
    showingUncategorizedTransactions;

  const filterDropdownAndTogglesProps = {
    hasDropdownClearedExternally,
    setHasDropdownClearedExternally,
    pathname,
    connectedAccountsFilterOptions,
    selectedItem,
    selectedFilters,
    hasActiveParentFilter,
    setHasActiveParentFilter,
    onClearClick,
    propertyOptions,
    showingUncategorizedTransactions,
    handleUncategroziedChange,
    showHiddenTransactions,
    onToggleHiddenTransactions,
    showOnlyWithAttachments,
    onToggleOnlyWithAttachments,
    categoryOptions,
    setSelectedFilters,
  };

  const displayActionsContainer =
    showBulkActions || (!showBulkActions && isMin899) || !isOutsideTrxPage;

  return (
    <Box {...{ ...filterRowContainerStyles, ...styles?.filterRowContainerStyles }}>
      {/* New tab group with Transactions and Transactions for review */}
      {checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) && pathname.includes(TRANSACTIONS) && (
        <BaselaneTabGroup
          tabs={[
            { key: 'transactions', name: 'All transactions' },
            {
              key: 'transactions-for-review',
              name: (
                <HStack>
                  <Text>Needs review</Text>
                  <BaselaneChip
                    label={totalUnreviewedTransactions > 99 ? '99+' : totalUnreviewedTransactions}
                    palette="neutral"
                    background="brand.darkBlue.100"
                    border="none"
                    size="sm"
                  />
                </HStack>
              ),
            },
          ]}
          isTabsOnly
          size="lg"
          onChange={(index) => handleClickTab(index === 1)}
        />
      )}
      {/* First row */}
      {displayActionsContainer && (
        <HStack {...filtersFilterRowStyles}>
          {showBulkActions && (
            <TransactionsBulkActions
              {...{
                handleDeselectTransactions,
                handleSelectedTransactions,
                selectedTransactions,
                setSelectedTransactions,
                refetch,
              }}
            />
          )}
          {!showBulkActions && isMin899 && !isTableOnly && (
            <Text {...bulkActionsTextStyles}>
              Tip: Use the checkboxes to select multiple transactions and edit them at once.
            </Text>
          )}

          {!isOutsideTrxPage && (
            <>
              {/* Autotagging enabled for mobile */}
              {displayExportTransactions &&
                checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) &&
                !isMin768 && (
                  <DownloadTrxs
                    showHiddenTransactions={showHiddenTransactions}
                    isPreventDownload={!data?.transaction?.length}
                    hideButton
                  />
                )}
              {/* Autotagging disabled */}
              {!checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) && (
                <DownloadTrxs
                  showHiddenTransactions={showHiddenTransactions}
                  isPreventDownload={!data?.transaction?.length}
                />
              )}
              {/* Autotagging enabled for desktop */}
              {checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) && isMin768 && (
                <>
                  <BaselaneButton
                    size="md"
                    variant="outline"
                    palette="neutral"
                    onClick={() => navigate(`../${BOOKKEEPING_RULES}`)}
                    ml="auto"
                  >
                    Manage rules
                  </BaselaneButton>
                  <DownloadTrxs
                    showHiddenTransactions={showHiddenTransactions}
                    isPreventDownload={!data?.transaction?.length}
                  />
                </>
              )}
              {checkGate(FEATURE_GATES.CSV_GATE) &&
                isMin768 &&
                !checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) && (
                  <BaselaneButton
                    size="md"
                    variant="outline"
                    palette="neutral"
                    onClick={() => onImportTrxClick()}
                  >
                    Import
                  </BaselaneButton>
                )}

              {/* Add Transaction */}
              {!pathname.includes(NATIVE_BANK) && !checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) && (
                <CreateManualTransactionButtonWrapper />
              )}

              {!pathname.includes(NATIVE_BANK) &&
                checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) &&
                !isMin768 && <CreateManualTransactionButtonWrapper />}

              {isAddTransactionDrawerOpen && (
                <CreateManualTransactionDrawerNew
                  isAddTransactionDrawerOpen={isAddTransactionDrawerOpen}
                  toggleAddTransactionDrawer={toggleAddTransactionDrawer}
                />
              )}
              {/* Autotagging enabled for mobile */}
              {!pathname.includes(NATIVE_BANK) &&
                checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) &&
                !isMin768 && (
                  <BaselaneMenu
                    buttonPalette="neutral"
                    buttonVariant="outline"
                    label="More"
                    listItems={[
                      {
                        name: 'Export transactions',
                        onClick: () => handleExportTransactions(),
                        icon: (
                          <Box>
                            <Icon16ShareiOS />
                          </Box>
                        ),
                      },
                      {
                        name: 'Manage rules',
                        onClick: () => navigate(`../${BOOKKEEPING_RULES}`),
                        icon: (
                          <Box>
                            <Icon16Settings />
                          </Box>
                        ),
                      },
                    ]}
                  />
                )}
              {/* Autotagging enabled for desktop */}
              {!pathname.includes(NATIVE_BANK) &&
                checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) &&
                isMin768 && (
                  <BaselaneMenu
                    label="Add"
                    listItems={[
                      {
                        name: 'Add a transaction',
                        onClick: toggleAddTransactionDrawer,
                        isDisabled: showBulkActions,
                        icon: (
                          <Box>
                            <Icon16Plus />
                          </Box>
                        ),
                      },
                      {
                        name: 'Import transactions',
                        onClick: () => onImportTrxClick(),
                        icon: (
                          <Box>
                            <Icon16Tasks />
                          </Box>
                        ),
                      },
                    ]}
                  />
                )}
            </>
          )}
        </HStack>
      )}

      {/* TODO: remove redundant wrapper once global card filters are added + clean up styles */}
      {/* Second row */}
      {!hideFilters && (
        <HStack w="100%">
          <HStack {...filtersSecondRowStyles(isOutsideTrxPage)}>
            {/* Filter Dropdowns + Toggles  */}
            {isMin899 ? (
              <>
                <HStack {...filtersContainerStyles} gap={2} flex="1 1 auto">
                  {/* Search bar */}
                  <TransactionsSearch
                    {...{
                      inputValue,
                      setInputValue,
                      updateSearchText: onSearch,
                      setFilters,
                      filters,
                      showingUncategorizedTransactions,
                      currSort,
                      clientSearchableData,
                    }}
                  />
                  <FilterDropdowns {...filterDropdownAndTogglesProps} />
                </HStack>
                <Toggles {...filterDropdownAndTogglesProps} />
              </>
            ) : (
              <>
                <HStack {...filtersContainerStyles} w="100%">
                  {/* Search bar */}
                  <TransactionsSearch
                    {...{
                      inputValue,
                      setInputValue,
                      updateSearchText: onSearch,
                      setFilters,
                      filters,
                      showingUncategorizedTransactions,
                      currSort,
                      clientSearchableData,
                    }}
                  />
                </HStack>
                <HStack
                  {...filtersSecondRowStyles(isOutsideTrxPage)}
                  {...filtersAndTogglesMobileContainerStyles}
                >
                  <FilterDropdowns {...filterDropdownAndTogglesProps} />
                  <Toggles {...filterDropdownAndTogglesProps} />
                </HStack>
              </>
            )}
          </HStack>
        </HStack>
      )}
      {/* Third row */}
      {hasActiveFilter && !transactionsLoading && (
        <HStack {...{ pt: 2, px: 2, textStyle: 'sm', color: 'brand.neutral.600' }}>
          <Text>Showing {data?.transaction?.length || 0} transactions</Text>
          <Spacer />
          <BaselaneButton
            leftIcon={<Icon16Clear />}
            variant="transparent"
            palette="primary"
            size="sm"
            onClick={handleClear}
          >
            Clear filters
          </BaselaneButton>
        </HStack>
      )}
    </Box>
  );
}

export default TransactionsFilters;
