import React from 'react';
import { Stack } from '@chakra-ui/react';
import BaselaneButton from '../BaselaneButton';
import { wrapperStyles } from './styles/toggle.styles';
import BaselaneButtonGroup from '../BaselaneButtonGroup';

type BaselaneButtonToggleProps = {
  firstLabel: string,
  secondLabel: string,
  firstId: string,
  secondId: string,
  activeButton: number,
  isDisabled: boolean,
  onClick: Function | null,
  onBlur: Function,
  hasNoData: boolean,
  hideIndex: number,
  isFullWidth: Boolean,
  size: string,
  palette: string,
  leftIcons: any,
  rightIcons: any,
  leftIsDisabled: boolean,
  rightIsDisabled: boolean,
  leftTooltipText: string,
  rightTooltipText: string,
};

function BaselaneButtonToggle({
  firstId,
  secondId,
  firstLabel = '',
  activeButton = 0,
  secondLabel = '',
  isDisabled = false,
  onClick = null,
  onBlur = () => {},
  hasNoData = false,
  hideIndex = null,
  isFullWidth,
  size,
  palette = 'neutral',
  leftIcons,
  rightIcons,
  leftIsDisabled,
  leftTooltipText,
  rightIsDisabled,
  rightTooltipText,
}: BaselaneButtonToggleProps): any {
  return (
    <Stack
      direction="row"
      {...wrapperStyles}
      w={isFullWidth ? '100%' : 'fit-content'}
      {...(hasNoData && { visibility: 'hidden' })}
    >
      <BaselaneButtonGroup styles={{ width: isFullWidth ? '100%' : 'fit-content' }} size={size}>
        <BaselaneButton
          isFullWidth={isFullWidth}
          size={size}
          variant="toggle"
          palette={palette}
          isDisabled={isDisabled || leftIsDisabled}
          isActive={activeButton === 0}
          onClick={(e) => {
            e.currentTarget?.focus();
            if (onClick !== null) {
              onClick(0);
            }
          }}
          id={firstId}
          name={firstId}
          onBlur={(e) => onBlur(e)}
          display={hideIndex === 0 && 'none'}
          leftIcon={leftIcons?.length > 1 ? leftIcons[0] : leftIcons}
          rightIcon={rightIcons?.length > 1 ? rightIcons[0] : rightIcons}
          tooltipText={leftTooltipText}
        >
          {firstLabel}
        </BaselaneButton>
        <BaselaneButton
          isFullWidth={isFullWidth}
          size={size}
          variant="toggle"
          palette={palette}
          isDisabled={isDisabled || rightIsDisabled}
          isActive={activeButton === 1}
          onClick={(e) => {
            e.currentTarget?.focus();
            if (onClick !== null) {
              onClick(1);
            }
          }}
          id={secondId}
          name={secondId}
          onBlur={(e) => onBlur(e)}
          display={hideIndex === 1 && 'none'}
          leftIcon={leftIcons?.length > 1 ? leftIcons[1] : leftIcons}
          rightIcon={rightIcons?.length > 1 ? rightIcons[1] : rightIcons}
          tooltipText={rightTooltipText}
        >
          {secondLabel}
        </BaselaneButton>
      </BaselaneButtonGroup>
    </Stack>
  );
}

export default BaselaneButtonToggle;
