import React, { useRef } from 'react';
import { HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { BaselaneList, BaselaneListItem } from '@shared/components';
import { IconChevronRight } from '@icons';
import useBreakPoints from '@core/hooks/useBreakPoints';

import RentCollection from './RentCollection';
import BaselaneBanking from './BaselaneBanking';
import Bookkeeping from './Bookkeeping';
import { listData } from '../../helpers/howToGuides.helpers';
import { containerStyles } from '../../styles/shared.styles';
import {
  titleStyles,
  listStyles,
  listItemContainerStyles,
  listItemStyles,
} from '../../styles/howToGuides.styles';

const HowToGuides = () => {
  const { isMax768: isMobile } = useBreakPoints();

  // Drawer Refs
  const howToRCDrawerRef = useRef();
  const howToBaselaneBankingDrawerRef = useRef();
  const howToBookkeepingDrawerRef = useRef();

  // List Items
  const listItemRenderer = (item) => {
    const { key, title } = item;
    const { containerStyle, labelStyle } = listItemStyles(isMobile);

    const onItemClick = () => {
      switch (key) {
        case 'rc':
          howToRCDrawerRef?.current.open();
          break;

        case 'baselane_banking':
          howToBaselaneBankingDrawerRef?.current.open();
          break;

        case 'bookkeeping':
          howToBookkeepingDrawerRef?.current.open();
          break;

        default:
          break;
      }
    };

    const label = (
      <HStack {...listItemContainerStyles}>
        <Text>{title}</Text>
        <Spacer />
        <IconChevronRight color="#3A4B5B" />
      </HStack>
    );

    return (
      <BaselaneListItem
        key={key}
        label={label}
        containerStyle={containerStyle}
        labelStyle={labelStyle}
        onClick={onItemClick}
      />
    );
  };

  return (
    <>
      <Stack {...containerStyles(isMobile)}>
        {/* Title */}
        <Text {...titleStyles(isMobile)}>How to Guides</Text>

        {/* List */}
        <BaselaneList data={listData} itemRenderer={listItemRenderer} listStyles={listStyles} />
      </Stack>

      {/* Drawers */}
      <RentCollection {...{ howToRCDrawerRef }} />
      <BaselaneBanking {...{ howToBaselaneBankingDrawerRef }} />
      <Bookkeeping {...{ howToBookkeepingDrawerRef }} />
    </>
  );
};

export default HowToGuides;
