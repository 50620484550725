import React from 'react';
import { HStack, Box, Text, Skeleton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { BOOKKEEPING_RULES_AI_CATEGORIZATION_SETTINGS } from '@core/constants/routes';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { BaselaneChip, BaselaneButton } from '@shared/components';
import { Icon16Check, Icon16ChevronRight } from '@icons/16px';
import { listItemStyles } from '@shared/components/BaselaneSortableList/styles/sortableList.styles';

import {
  priorityContainerStyles,
  priorityStyles,
  conditionStyles,
  actionStyles,
  ruleClickAreaStyles,
} from '../../styles/autotaggingRules.styles';

type AICategorizationRuleProps = {
  aiCategorizationEnabled: boolean,
  reorderLoading: boolean,
  rank: Number,
};

const AICategorizationRule = ({
  aiCategorizationEnabled,
  reorderLoading,
  rank,
}: AICategorizationRuleProps) => {
  const { isMax767 } = useBreakPoints();
  const navigate = useNavigate();

  return (
    <HStack w="full" alignItems="center" gap={2} {...{ ...listItemStyles, borderTopStyle: 'none' }}>
      <BaselaneButton
        variant="transparent"
        palette="neutral"
        size="lg"
        {...ruleClickAreaStyles}
        onClick={() => navigate(BOOKKEEPING_RULES_AI_CATEGORIZATION_SETTINGS)}
      >
        <Box w="20px" />
        {!isMax767 && aiCategorizationEnabled && (
          <Box {...priorityContainerStyles}>
            <Skeleton isLoaded={!reorderLoading} {...priorityStyles}>
              <Text textStyle="headline-md" {...priorityStyles}>
                {rank}
              </Text>
            </Skeleton>
          </Box>
        )}
        {!isMax767 && !aiCategorizationEnabled && <Box w={0} />}
        <HStack>
          <Text {...conditionStyles} as="span" textStyle="headline-md">
            Categorization AI
          </Text>
          <BaselaneChip
            label={aiCategorizationEnabled ? 'Enabled' : 'Disabled'}
            leftIcon={aiCategorizationEnabled ? Icon16Check : null}
            size="sm"
            variant={aiCategorizationEnabled ? 'simple-primary' : 'simple-neutral'}
          />
        </HStack>
        <Box {...actionStyles} />
        <Icon16ChevronRight />
      </BaselaneButton>
    </HStack>
  );
};

export default AICategorizationRule;
