import React, { useContext, useState, useEffect } from 'react';
import { Heading, Skeleton, Stack, Tbody, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  BaselaneButton,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveCell,
  BaselaneResponsiveCellTitleContent,
  BaselaneResponsiveCellAmountContent,
  BaselaneChip,
  BaselaneButtonToggle,
  BaselaneCardNew,
} from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { dateFormatter } from '@core/utils/formatDate';
import formatCurrency from '@core/utils/formatCurrency';
import { DASHBOARD, TRANSACTION, TRANSACTIONS } from '@routes';
import { DEFAULT_FILTERS_OPTIONS } from '@core/components/Transactions/services/FilterService';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { truncatedText } from '@shared/styles/text.style';
import TransactionContext from '@contexts/TransactionContext';

import { GET_TRANSACTIONS } from '@core/apollo/queries';
import { desktopTableConfig, dummyDataDesktop, mobileTableConfig, dummyDataMobile } from './config';

const TransactionInfo = () => {
  const { setFilters } = useContext(TransactionContext);
  const navigate = useNavigate();
  const { isMin768 } = useBreakPoints();

  const [toggleValue, setToggleValue] = useState(0);

  // Transaction Data
  const [trxFilter, setTrxFilter] = useState(DEFAULT_FILTERS_OPTIONS.dashboard);
  const { data: trxData, loading: trxDataLoading } = useQuery(GET_TRANSACTIONS, {
    variables: { input: trxFilter },
  });

  const cleanTrxList = trxData?.transaction.filter((t) => !t.hidden) ?? [];
  const trxList = isMin768 ? cleanTrxList.slice(0, 6) : cleanTrxList.slice(0, 3);

  const tableConfig = isMin768 ? desktopTableConfig : mobileTableConfig;

  let tableItems = isMin768 ? dummyDataDesktop : dummyDataMobile;
  if (!trxDataLoading) {
    tableItems = trxList;
  }
  const handleTrxOnClick = (id) => {
    sendSegmentEvent('temp_dashboard_view_transactions');
    navigate({ pathname: `${DASHBOARD}/${TRANSACTION}/${id}` });
  };

  // To update filters when in dashboard so cache updates can be completed properly
  useEffect(() => {
    if (trxFilter) {
      setFilters(trxFilter);
    }
  }, []);

  return (
    <Stack spacing={2}>
      <Heading size="headline-lg">Recent transactions</Heading>
      {isMin768 && (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BaselaneButtonToggle
            firstLabel="Baselane Banking"
            secondLabel="External accounts"
            activeButton={toggleValue}
            onClick={(value) => {
              setToggleValue(value);
              // update filter for trx query on dashboard page
              setTrxFilter((prevState) => {
                if (value === 0) {
                  return {
                    ...prevState,
                    filter: { ...prevState.filter, isExternal: false },
                  };
                }

                return {
                  ...prevState,
                  filter: { ...prevState.filter, isExternal: true },
                };
              });

              // update filters for trx page components
              if (value === 0) {
                setFilters({ ...trxFilter, filter: { ...trxFilter.filter, isExternal: false } });
              } else {
                setFilters({ ...trxFilter, filter: { ...trxFilter.filter, isExternal: true } });
              }
            }}
          />
          <BaselaneButton
            variant="link"
            palette="primary"
            size="lg"
            pullRight
            styles={{ textDecoration: 'none', fontWeight: 'medium', margin: 0 }}
            onClick={() => navigate({ pathname: TRANSACTIONS })}
          >
            View more
          </BaselaneButton>
        </Stack>
      )}
      {tableItems.length === 0 ? (
        <BaselaneCardNew
          variant="dashed"
          palette="empty"
          alignItems="center"
          justifyContent="center"
          height={isMin768 ? '400px' : '200px'}
        >
          <Text textStyle="sm">No transactions yet.</Text>
        </BaselaneCardNew>
      ) : (
        <BaselaneResponsiveTable
          config={{
            columns: tableConfig,
          }}
        >
          <Tbody>
            <BaselaneResponsiveTableRows
              customRow
              items={tableItems}
              renderItem={({ merchantName, name, date, amount, pending: isPending, id }, index) => {
                const { isNegative: isFormattedAmountNegative, inDollars: formattedAmount } =
                  formatCurrency(amount, true, false, true) || {};

                const formattedDate = dateFormatter(date);

                const descriptionTitle = merchantName || name;

                return isMin768 ? (
                  <BaselaneResponsiveTableRow
                    onClick={() => handleTrxOnClick(id)}
                    showBorder={index !== trxList.length - 1}
                  >
                    <BaselaneResponsiveCell configIndex={0}>
                      <Skeleton isLoaded={!trxDataLoading} overflow="hidden">
                        <Text textStyle="sm" color="brand.neutral.700">
                          {formattedDate}
                        </Text>
                      </Skeleton>
                    </BaselaneResponsiveCell>
                    <BaselaneResponsiveCell
                      configIndex={1}
                      contentContainerStyles={{ justifyContent: 'space-between' }}
                    >
                      <Skeleton isLoaded={!trxDataLoading} overflow="hidden">
                        <BaselaneResponsiveCellTitleContent
                          title={descriptionTitle}
                          rightTitleElement={
                            isPending && (
                              <BaselaneChip size="sm" label="Pending" variant="status-primary" />
                            )
                          }
                          titleSize="sm-heavy"
                        />
                      </Skeleton>
                      <Skeleton
                        isLoaded={!trxDataLoading}
                        overflow="hidden"
                        {...truncatedText}
                        flexShrink={0}
                        maxWidth="170px"
                      >
                        <BaselaneResponsiveCellAmountContent
                          textStyle="data-sm"
                          color={isFormattedAmountNegative ? 'brand.neutral.700' : 'green.800AA'}
                        >
                          {formattedAmount}
                        </BaselaneResponsiveCellAmountContent>
                      </Skeleton>
                    </BaselaneResponsiveCell>
                  </BaselaneResponsiveTableRow>
                ) : (
                  <BaselaneResponsiveTableRow
                    onClick={() => handleTrxOnClick(id)}
                    showBorder={index !== trxList.length - 1}
                    chevronCellStyles={{ display: 'none' }}
                  >
                    <BaselaneResponsiveCell
                      configIndex={0}
                      px={0}
                      contentContainerStyles={{ justifyContent: 'space-between' }}
                    >
                      <Skeleton isLoaded={!trxDataLoading} overflow="hidden">
                        <BaselaneResponsiveCellTitleContent
                          title={descriptionTitle}
                          subtitle={formattedDate}
                          rightTitleElement={
                            isPending && (
                              <BaselaneChip size="sm" label="Pending" variant="status-primary" />
                            )
                          }
                          titleSize="sm-heavy"
                        />
                      </Skeleton>
                      <Skeleton isLoaded={!trxDataLoading} overflow="hidden" flexShrink={0}>
                        <BaselaneResponsiveCellAmountContent
                          textStyle="data-sm"
                          color={isFormattedAmountNegative ? 'brand.neutral.700' : 'green.800AA'}
                        >
                          {formattedAmount}
                        </BaselaneResponsiveCellAmountContent>
                      </Skeleton>
                    </BaselaneResponsiveCell>
                  </BaselaneResponsiveTableRow>
                );
              }}
            />
          </Tbody>
        </BaselaneResponsiveTable>
      )}
    </Stack>
  );
};

export default TransactionInfo;
