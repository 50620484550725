import React, { useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useLocation,
  useSearchParams,
  Outlet,
} from 'react-router-dom';
import { Stack } from '@chakra-ui/react';
import { USER_PROFILE_UPDATE_ACCOUNTS } from '@routes';
import WorkspaceName from './WorkspaceTabs/components/WorkspaceName';
import UserProfileTabs from './WorkspaceTabs';
import { getTabsListItems } from './helpers/workspace.helpers';

function WorkspacePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(0);

  const activeTabIndex = searchParams.get('activeTabIndex');
  const numOfTabs = getTabsListItems().length;

  useEffect(() => {
    if (activeTabIndex) {
      const index = Number(activeTabIndex);

      if (index > numOfTabs - 1 || isNaN(index)) {
        setTabIndex(0);
      } else {
        setTabIndex(index);
      }
    }
  }, [activeTabIndex]);

  useEffect(() => {
    if (location.pathname === USER_PROFILE_UPDATE_ACCOUNTS && activeTabIndex === null) {
      navigate(
        {
          pathname: USER_PROFILE_UPDATE_ACCOUNTS,
          search: createSearchParams({ activeTabIndex: 3 }).toString(),
        },
        { replace: true }
      );
    }
  }, [location.pathname]);

  return (
    <Stack m={0}>
      <WorkspaceName />
      <UserProfileTabs
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        activeTabIndex={activeTabIndex}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
      <Outlet />
    </Stack>
  );
}

export default WorkspacePage;
