import React from 'react';
import { Skeleton } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';

import useBreakPoints from '@core/hooks/useBreakPoints';
import { GET_ACCOUNT_DROPDOWN_BANKS } from '@core/apollo/queries';
import { getConnectedAccountsFilterOptions } from '@contexts/TransactionContext/helpers';

import T1WithTitleDropdown from '../../T1-Title';
import DisplayInputDefaultAccount from '../../DisplayInputVariations/DisplayInputDefaultAccount';
import renderAccountDropdownItem from './renderAccountDropdownItem';

type AccountDropdownProps = {
  handleSubmit: Function,
  formValues: Object,
  hasError?: boolean,
  selectedItem?: Object,
};

const AccountDropdown = ({
  handleSubmit,
  formValues,
  hasError = false,
  selectedItem = null,
  ...rest
}: AccountDropdownProps) => {
  const { isMax768 } = useBreakPoints();
  const { data, loading } = useQuery(GET_ACCOUNT_DROPDOWN_BANKS, {
    fetchPolicy: 'cache-and-network',
    variables: { isConnectedAccount: true, isExternal: true, accountStatus: 'Open' },
  });

  const accountOtherOption = {
    id: 'other_option',
    items: [
      {
        id: 'manual',
        bankName: 'Manually Added',
      },
    ],
    title: 'Other',
  };

  const connectedAccountsFilterOptions = getConnectedAccountsFilterOptions(data);
  const connectAccountsExternalFilterOptions = connectedAccountsFilterOptions?.filter((item) => {
    return item.id === 'external_account';
  });
  const accountOptions = connectAccountsExternalFilterOptions?.concat(accountOtherOption);

  const getSelectedAccount = () => {
    const { account } = formValues || {};

    const { id } = account || {};
    if (id === 'manual') {
      return {
        id: 'manual',
        bankName: 'Manually Added',
        account: 'Manually Added',
      };
    }

    return (
      connectAccountsExternalFilterOptions?.[0]?.items &&
      connectAccountsExternalFilterOptions?.[0]?.items.find((a) => a.id === id)
    );
  };

  return (
    <Skeleton isLoaded={!loading}>
      <T1WithTitleDropdown
        hasError={hasError}
        additionalProps={{ id: 'account-dropdown' }}
        classNames={['input-form-md', 'is-full-width', 'account']}
        data={accountOptions}
        searchTerm={['account', 'bankName']}
        title="Account"
        showValueByFields={['bankName', 'account']}
        itemRenderer={renderAccountDropdownItem}
        handleSubmit={handleSubmit}
        isMulti={false}
        hasFilterWrapper={false}
        showDivider
        selectedItem={getSelectedAccount()}
        CustomDisplayInput={DisplayInputDefaultAccount}
        isMobile={isMax768}
        {...rest}
      />
    </Skeleton>
  );
};

export default AccountDropdown;
