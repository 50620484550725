import { useQuery } from '@apollo/client';
import { GET_TENANT_SCREENING_COMPLIANCE_RULES } from '@core/apollo/queries';

const useTenantScreeningComplianceRules = () => {
  const { loading, data, error } = useQuery(GET_TENANT_SCREENING_COMPLIANCE_RULES);

  return {
    loading,
    data,
    error,
  };
};

export default useTenantScreeningComplianceRules;
