import { gql } from '@apollo/client';

export const FRAGMENT_TRX_ITEM_DETAIL = gql`
  fragment TrxItemDetailObj on Transaction {
    id
    tagId
    date
    propertyId
    unitId
    note
  }
`;

export const FRAGMENT_PARENT_TRANSACTION = gql`
  fragment ParentTransactionObj on Transaction {
    parentTransaction {
      id
      isSplit
      hidden
      isDocumentUploaded
      amount
      merchantName
      propertyId
      unitId
      tagId
      date
      isDeleted
    }
  }
`;

export const FRAGMENT_TRANSACTION = gql`
  fragment TransactionObj on Transaction {
    ...TrxItemDetailObj
    description
    bankAccountId
    amount
    merchantName
    name
    pending
    time
    hidden
    isDeleted
    isExternal
    isManual
    isSplit
    parentId
    isReviewedByUser
    tagIdSource
    propertyTagIdSource
    tagRuleId
    propertyRuleId
    originalTransaction
    isDocumentUploaded
    ...ParentTransactionObj
  }
  ${FRAGMENT_TRX_ITEM_DETAIL}
  ${FRAGMENT_PARENT_TRANSACTION}
`;

export const GET_TRANSACTIONS = gql`
  query Transactions($input: SortsAndFilters) {
    transaction(input: $input) {
      ...TransactionObj
    }
  }
  ${FRAGMENT_TRANSACTION}
`;

export const UPDATE_TRANSACTIONS = gql`
  mutation UpdateTransaction($input: [UpdateTransaction!]) {
    updateTransactions(input: $input) {
      ...TransactionObj
    }
  }
  ${FRAGMENT_TRANSACTION}
`;

export const GET_TRANSACTIONS_SUMMARY = gql`
  query TransactionsSummary($input: TransactionSummaryFilter) {
    transactionSummary(input: $input) {
      totalUnreviewedTransactions
      totalUncategorized {
        amount
        count
        uncategorizedHiddenTxCount
        absoluteAmount
      }
      mtdUncategorized {
        amount
        count
        uncategorizedHiddenTxCount
      }
      total {
        count
        amount
        categorizedHiddenTxCount
        uncategorizedHiddenTxCount
      }
    }
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation createTransaction(
    $merchantName: String!
    $note: String!
    $tagId: ID
    $propertyId: ID
    $unitId: ID
    $date: String!
    $bankAccountId: ID
    $amount: Float!
    $isReviewedByUser: Boolean
  ) {
    createTransaction(
      input: {
        merchantName: $merchantName
        note: $note
        tagId: $tagId
        propertyId: $propertyId
        unitId: $unitId
        date: $date
        bankAccountId: $bankAccountId
        amount: $amount
        isReviewedByUser: $isReviewedByUser
      }
    ) {
      ...TrxItemDetailObj
      merchantName
      bankAccountId
      amount
      isManual
      __typename
    }
  }
  ${FRAGMENT_TRX_ITEM_DETAIL}
`;

export const GET_SLIM_TRANSACTIONS = gql`
  query Transactions($input: SortsAndFilters) {
    transaction(input: $input) {
      id
      bankAccountId
      amount
      tagId
      date
      name
      merchantName
      note
      propertyId
      hidden
      isDocumentUploaded
      isManual
    }
  }
`;
