import React, { useContext } from 'react';
import { Box } from '@chakra-ui/react';
import { BaselaneConditionalTooltip } from '@shared/components';
import UserContext from '@core/contexts/UserContext';

type DisableMoveMoneyProps = {
  tooltipText: String,
  renderItem: Function,
  styles?: Object,
};

/**
 * Conditionally enables a tooltip if the user's email is unverified
 * This is part of the 2024-07_sign_up_page_experiment
 */
const DisableMoveMoney = ({ tooltipText, renderItem, styles = {} }: DisableMoveMoneyProps) => {
  const { emailVerified } = useContext(UserContext);

  return (
    <Box flex="1" {...styles}>
      <BaselaneConditionalTooltip
        tooltipText={tooltipText}
        condition={!emailVerified}
        styles={{ textStyle: 'xs' }}
      >
        <Box>{renderItem(!emailVerified)}</Box>
      </BaselaneConditionalTooltip>
    </Box>
  );
};

export default DisableMoveMoney;
