import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, HStack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { useStatsigClient } from '@statsig/react-bindings';
import { UPDATE_USER } from '@core/components/UserProfilePage/queries';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { BaselaneButton, NoAccessTooltip } from '@shared/components';
import { useCurrentWorkspace } from '@shared/hooks';
import { DASHBOARD, UNIFIED_LANDLORD_BANKING, UNIFIED_BOOKKEEPING_ANALYTICS } from '@routes';
import { Icon16VerifiedBadge } from '@icons/16px';
import userContext from '@contexts/UserContext';
import { mobileFooterStyles } from '@core/components/OnboardingTriage/styles/onboardingTriage.styles';
import UserAccessContext from '@contexts/UserAccessContext';

import { DYNAMIC_CONFIGS } from '@core/constants/statsigKeys';
import InfoCardCarousel from './InfoCardCarousel';
import InfoCard from './InfoCard';
import BankingFirstSkipPopup from './BankingFirstSkipPopup';
import { getOnboardingInfoCards } from '../helpers/onboardingCardsHelper';

const OptionCardsScreens = ({ selection }: { selection: string }) => {
  const { isMin768 } = useBreakPoints();
  const navigate = useNavigate();
  const { workspaceMetadata } = useCurrentWorkspace();
  const { skippedBankingFirst, refetchUser, setSkippedBankingFirst } = useContext(userContext);

  const { getDynamicConfig } = useStatsigClient();
  const config = getDynamicConfig(DYNAMIC_CONFIGS.APY_INTEREST);
  const { isWorkspaceOwner } = useContext(UserAccessContext);
  const [updateUser] = useMutation(UPDATE_USER);

  const {
    isOpen: isBankingFirstSkipAlertOpen,
    onOpen: onBankingFirstSkipAlertOpen,
    onClose: onBankingFirstSkipAlertClose,
  } = useDisclosure();

  const [onboardingBankingFirstCards, setOnboardingBankingFirstCards] = useState([]);

  const { bankingTracker } = workspaceMetadata || {};
  const onboardingTriageSelection = selection;
  const isBankingFinished = (bankingTracker || [])[0]?.state === 'COMPLETED';

  const handleBankingFirstActionButton = () => {
    sendSegmentEvent('onboarding_baselane_overview_continue', {
      feature: onboardingTriageSelection,
    });
    if (!skippedBankingFirst && !isBankingFinished) {
      sendSegmentEvent('banking_first_bank_step_open_bank_account');
      navigate(UNIFIED_LANDLORD_BANKING);
    } else if (
      onboardingTriageSelection === 'bookkeeping' &&
      (skippedBankingFirst || isBankingFinished)
    ) {
      sendSegmentEvent('banking_first_bookkeeping_step_set_up');
      navigate(UNIFIED_BOOKKEEPING_ANALYTICS);
    }
  };

  const handleBankingFirstSkipButton = (type) => {
    sendSegmentEvent('onboarding_baselane_overview_skip', {
      feature: onboardingTriageSelection,
    });

    if (!skippedBankingFirst && !isBankingFinished) {
      sendSegmentEvent('banking_first_bank_step_click_skip');
    } else if (onboardingTriageSelection === 'bookkeeping') {
      sendSegmentEvent('banking_first_bookkeeping_step_click_skip');
    }

    if (type === 'bookkeeping') {
      sendSegmentEvent('onboarding_triage_completed', {
        feature: onboardingTriageSelection,
      });

      updateUser({
        variables: {
          onboardingTriageSelection: 'bookkeeping',
          onboardingCompleted: true,
        },
      }).then(() => {
        refetchUser().then(() => navigate(DASHBOARD));
      });
    } else {
      onBankingFirstSkipAlertOpen();
    }
  };

  useEffect(() => {
    setOnboardingBankingFirstCards(
      getOnboardingInfoCards(
        onboardingTriageSelection,
        isBankingFinished,
        skippedBankingFirst,
        config?.get('apy')
      )
    );
    if (onboardingTriageSelection === 'banking') {
      setSkippedBankingFirst(false);
      localStorage.removeItem('skippedBankingFirst');
    }
  }, [onboardingTriageSelection, skippedBankingFirst]);

  if (onboardingBankingFirstCards.length === 0) {
    return null;
  }

  return (
    <>
      <Flex direction="column" justifyContent="start" gap={isMin768 ? '48px' : '54px'} w="100%">
        <VStack gap="32px">
          <Box width="100%" display="flex" direction="row" justifyContent="center">
            {isMin768 ? (
              <HStack marginX="auto" gap="16px" px="16px">
                {onboardingBankingFirstCards?.map((item) => (
                  <InfoCard key={item.id} slide={item} isCarousel={false} />
                ))}
              </HStack>
            ) : (
              <InfoCardCarousel infoCards={onboardingBankingFirstCards} />
            )}
          </Box>
          {isMin768 && (
            <HStack>
              <Box>
                <Icon16VerifiedBadge />
              </Box>
              <Text textStyle="md">No hidden, monthly, or minimum balance fees</Text>
            </HStack>
          )}
        </VStack>
        <Flex
          direction="column"
          {...(isMin768 ? {} : mobileFooterStyles)}
          gap="16px"
          alignItems="center"
        >
          {!skippedBankingFirst &&
            !isBankingFinished &&
            (!isWorkspaceOwner ? (
              <NoAccessTooltip>
                <BaselaneButton
                  size="lg"
                  variant="filled"
                  palette="primary"
                  id="set-up-bookkeeping"
                  isDisabled={!isWorkspaceOwner}
                  onClick={handleBankingFirstActionButton}
                  isFullWidth={!isMin768}
                >
                  Open banking account
                </BaselaneButton>
              </NoAccessTooltip>
            ) : (
              <BaselaneButton
                size="lg"
                variant="filled"
                palette="primary"
                id="set-up-bookkeeping"
                onClick={handleBankingFirstActionButton}
                isFullWidth={!isMin768}
              >
                Open banking account
              </BaselaneButton>
            ))}

          {(skippedBankingFirst || isBankingFinished) && (
            <BaselaneButton
              size="lg"
              variant="filled"
              palette="primary"
              id="set-up-bookkeeping"
              onClick={handleBankingFirstActionButton}
              isFullWidth={!isMin768}
            >
              Set up bookkeeping
            </BaselaneButton>
          )}

          {(skippedBankingFirst || isBankingFinished) && (
            <BaselaneButton
              size="sm"
              variant="transparent"
              onClick={() => handleBankingFirstSkipButton(onboardingTriageSelection)}
            >
              Set up later
            </BaselaneButton>
          )}
          {!skippedBankingFirst && !isBankingFinished && (
            <BaselaneButton
              size="sm"
              variant="transparent"
              onClick={handleBankingFirstSkipButton}
              id="skip-for-now"
            >
              Skip for now
            </BaselaneButton>
          )}
        </Flex>
      </Flex>
      <BankingFirstSkipPopup
        isOpen={isBankingFirstSkipAlertOpen}
        onClose={onBankingFirstSkipAlertClose}
        onContinue={(isBankingClick) => {
          if (isBankingClick) {
            sendSegmentEvent('banking_first_skip_bank_click_open_bank_account');
            navigate(UNIFIED_LANDLORD_BANKING);
          } else {
            sendSegmentEvent('banking_first_skip_bank_click_continue_without');
            setSkippedBankingFirst(true);
            localStorage.setItem('skippedBankingFirst', 'true');
          }
        }}
      />
    </>
  );
};

export default OptionCardsScreens;
