import React, { useEffect, useState } from 'react';
import { Box, HStack, Image, Skeleton, Text, Tbody, VStack, Stack } from '@chakra-ui/react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { dateFormatter as formatDate } from '@core/utils/formatDate';
import useBreakPoints from '@core/hooks/useBreakPoints';

import {
  BaselaneCardNew,
  BaselaneCardStack,
  BaselaneResponsiveCell,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
} from '@shared/components';
import { Icon24Warning } from '@icons/24px';
import { Icon16Info } from '@icons/16px';
import { TENANT_SCREENING_CRIMINAL_REPORT } from '@core/constants/routes';
import cic from '../TenantScreeningEmptyState/assets/cic.svg';
import ReportHeader from '../components/ReportHeader';
import { isFieldNotObject } from '../helpers/tenantScreening.helpers';
import { getReportByid, isReportDataInvalid } from '../helpers/tenantScreeningReports.helpers';
import ReportUnavailableAlert from '../components/ReportUnavailableAlert';
import PendingEmptyState from './PendingEmptyState';

const CriminalReport = () => {
  const { isMax767 } = useBreakPoints();
  const tableColumnConfig = {
    columns: [
      {
        isSortable: false,
        styles: {
          w: { base: '100%', sm: '320px' },
          overflow: 'visible',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
      {
        isSortable: false,
        styles: {
          w: '100%',
          overflow: 'visible',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
    ],
  };
  const { verificationId, externalApplicantId } = useOutletContext();
  const { applicationId } = useParams();

  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const titleCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  useEffect(() => {
    if (applicationId && externalApplicantId && verificationId) {
      getReportByid(applicationId, externalApplicantId, verificationId)
        .then(({ data }) => {
          if (data?.name === TENANT_SCREENING_CRIMINAL_REPORT) setReportData(data);
        })
        .catch((error) => {
          console.error('Error fetching application:', error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [applicationId, externalApplicantId, verificationId]);

  const reportDataUsed = reportData; // you can use mock data here

  if (reportData?.status === 'pending' || isReportDataInvalid(reportData, verificationId)) {
    return reportData?.status === 'pending' ? (
      <PendingEmptyState />
    ) : (
      <ReportUnavailableAlert isLoading={isLoading} />
    );
  }

  return (
    <>
      <VStack alignItems="flex-start" gap={4}>
        {/* Summary of criminal report  */}
        <Stack
          gap={2}
          justifyContent="space-between"
          width="100%"
          direction={isMax767 ? 'column' : 'row'}
        >
          <VStack alignItems="flex-start" gap={0.5}>
            <Text color="brand.neutral.600" textStyle="sm">
              Criminal record
            </Text>
            <Skeleton isLoaded={reportData}>
              <Text color="brand.neutral.900" textStyle="data-xl">
                {reportDataUsed?.report?.summary?.total_criminal_records || '--'}
              </Text>
            </Skeleton>
          </VStack>
          <VStack alignItems="flex-start" gap={0.5}>
            <Text color="brand.neutral.600" textStyle="sm">
              Criminal traffic
            </Text>
            <Skeleton isLoaded={reportData}>
              <Text color="brand.neutral.900" textStyle="data-xl">
                {reportDataUsed?.report?.summary?.total_traffic_records || '--'}
              </Text>
            </Skeleton>
          </VStack>
          <VStack alignItems="flex-start" gap={0.5}>
            <Text color="brand.neutral.600" textStyle="sm">
              National sex offender
            </Text>
            <Skeleton isLoaded={reportData}>
              <Text color="brand.neutral.900" textStyle="data-xl">
                {reportDataUsed?.report?.summary?.total_sex_offender_records || '--'}
              </Text>
            </Skeleton>
          </VStack>
          <VStack alignItems="flex-start" gap={0.5}>
            <Text color="brand.neutral.600" textStyle="sm">
              Potential OFAC match
            </Text>
            <Skeleton isLoaded={reportData}>
              <Text color="brand.neutral.900" textStyle="data-xl">
                {reportDataUsed?.report?.summary?.total_ofac_records || '--'}
              </Text>
            </Skeleton>
          </VStack>
        </Stack>
        {reportDataUsed?.report?.results?.map((item) => (
          <BaselaneCardStack
            id={`criminal-report-card-stack-${uuidv4()}`}
            size="md"
            items={[
              {
                id: 'header',
                content: (
                  <ReportHeader
                    icon={
                      <Box as="span" color="red.800" mr={1}>
                        <Icon24Warning />
                      </Box>
                    }
                  >
                    <HStack justifyContent="space-between">
                      <Box>{item.subject?.category}</Box>
                      <Image src={cic} alt="CIC" />
                    </HStack>
                  </ReportHeader>
                ),
              },
              {
                id: `criminal-report-card-item-data-${uuidv4()}`,
                content: (
                  <VStack>
                    {item.subject.image_url && (
                      <Box w="100%" alignItems="left">
                        <Image
                          width="400px"
                          src={item.subject?.image_url}
                          alt="suspect photo"
                          rounded="md"
                        />
                      </Box>
                    )}
                    <BaselaneResponsiveTable
                      id="review-columns"
                      config={tableColumnConfig}
                      maxW="600px"
                      tableContainerStyles={{
                        overflowY: 'initial',
                        overflowX: 'initial',
                      }}
                    >
                      <Tbody>
                        <BaselaneResponsiveTableRows
                          items={[
                            { heading: 'Comments', data: item.case_details.comments || '--' },
                          ]}
                          customRow
                          renderItem={(row) => {
                            return (
                              <BaselaneResponsiveTableRow
                                key={row.heading}
                                chevronCellStyles={{ w: 0 }}
                                contentContainerStyles={{ border: 'none' }}
                              >
                                <BaselaneResponsiveCell configIndex={0} p={0}>
                                  <Text as="span" textStyle="sm" color="brand.neutral.700">
                                    {titleCase(row.heading)}
                                  </Text>
                                </BaselaneResponsiveCell>
                                <BaselaneResponsiveCell configIndex={1} p={0}>
                                  <Text
                                    textStyle="sm"
                                    color="brand.neutral.900"
                                    style={{ textWrap: 'wrap' }}
                                    w="100%"
                                  >
                                    {isFieldNotObject(row.data) ? row.data : '--'}
                                  </Text>
                                </BaselaneResponsiveCell>
                              </BaselaneResponsiveTableRow>
                            );
                          }}
                        />
                      </Tbody>
                    </BaselaneResponsiveTable>
                  </VStack>
                ),
              },
              {
                id: `criminal-report-card-item-data-${uuidv4()}`,
                content: (
                  <BaselaneResponsiveTable
                    id="review-columns"
                    config={tableColumnConfig}
                    maxW="600px"
                    tableContainerStyles={{
                      overflowY: 'initial',
                      overflowX: 'initial',
                    }}
                  >
                    <Tbody>
                      <BaselaneResponsiveTableRows
                        items={Object.keys(item.subject)
                          .filter((key) => key !== 'image_url')
                          .map((key) => ({
                            heading: key.replace('_', ' '),
                            data: item.subject[key] || '--',
                          }))}
                        customRow
                        renderItem={(row) => {
                          return (
                            <BaselaneResponsiveTableRow
                              key={row.heading}
                              chevronCellStyles={{ w: 0 }}
                              contentContainerStyles={{ border: 'none' }}
                            >
                              <BaselaneResponsiveCell configIndex={0} p={0}>
                                <Text as="span" textStyle="sm" color="brand.neutral.700">
                                  {titleCase(row.heading)}
                                </Text>
                              </BaselaneResponsiveCell>
                              <BaselaneResponsiveCell configIndex={1} p={0}>
                                <Text
                                  textStyle="sm"
                                  color="brand.neutral.900"
                                  style={{ textWrap: 'wrap' }}
                                  w="100%"
                                >
                                  {(() => {
                                    if (
                                      row.heading.toLowerCase().includes('dob') &&
                                      row.data !== '--'
                                    ) {
                                      return formatDate(row.data);
                                    }
                                    if (isFieldNotObject(row.data)) {
                                      return row.data;
                                    }
                                    return '--';
                                  })()}
                                </Text>
                              </BaselaneResponsiveCell>
                            </BaselaneResponsiveTableRow>
                          );
                        }}
                      />
                    </Tbody>
                  </BaselaneResponsiveTable>
                ),
              },
              {
                id: `criminal-report-card-item-data-${uuidv4()}`,
                content: (
                  <BaselaneResponsiveTable
                    id="review-columns"
                    config={tableColumnConfig}
                    maxW="600px"
                    tableContainerStyles={{
                      overflowY: 'initial',
                      overflowX: 'initial',
                    }}
                  >
                    <Tbody>
                      <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                        <BaselaneResponsiveCellTitle
                          title="Case details"
                          titleSize="headline-sm"
                          styles={{ titleTextStyle: { color: 'black' }, p: 0 }}
                          p={0}
                          configIndex={0}
                        />
                      </BaselaneResponsiveTableRow>
                      <BaselaneResponsiveTableRows
                        items={Object.keys(item.case_details).map((key) => ({
                          heading: key.replace('_', ' '),
                          data: item.case_details[key] || '--',
                        }))}
                        customRow
                        renderItem={(row) => {
                          return (
                            <BaselaneResponsiveTableRow
                              key={row.heading}
                              chevronCellStyles={{ w: 0 }}
                              contentContainerStyles={{ border: 'none' }}
                            >
                              <BaselaneResponsiveCell configIndex={0} p={0}>
                                <Text as="span" textStyle="sm" color="brand.neutral.700">
                                  {titleCase(row.heading)}
                                </Text>
                              </BaselaneResponsiveCell>
                              <BaselaneResponsiveCell configIndex={1} p={0} colSpan="3">
                                <Text
                                  textStyle="sm"
                                  color="brand.neutral.900"
                                  style={{ textWrap: 'wrap' }}
                                  w="100%"
                                >
                                  {isFieldNotObject(row.data) ? row.data : '--'}
                                </Text>
                              </BaselaneResponsiveCell>
                            </BaselaneResponsiveTableRow>
                          );
                        }}
                      />
                    </Tbody>
                  </BaselaneResponsiveTable>
                ),
              },
            ].concat(
              item.offenses?.map((offense, index) => ({
                id: `criminal-report-card-item-data-${uuidv4()}`,
                content: (
                  <BaselaneResponsiveTable
                    id="review-columns"
                    config={tableColumnConfig}
                    maxW="600px"
                    tableContainerStyles={{
                      overflowY: 'initial',
                      overflowX: 'initial',
                    }}
                  >
                    <Tbody>
                      <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                        <BaselaneResponsiveCellTitle
                          title={`Offense ${index + 1}`}
                          titleSize="headline-sm"
                          styles={{ titleTextStyle: { color: 'black' }, p: 0 }}
                          p={0}
                          configIndex={0}
                        />
                      </BaselaneResponsiveTableRow>
                      <BaselaneResponsiveTableRows
                        items={Object.keys(offense).map((key) => ({
                          heading: key.replace('_', ' '),
                          data: offense[key] || '--',
                        }))}
                        customRow
                        renderItem={(row) => {
                          return (
                            <BaselaneResponsiveTableRow
                              key={row.heading}
                              chevronCellStyles={{ w: 0 }}
                              contentContainerStyles={{ border: 'none' }}
                            >
                              <BaselaneResponsiveCell configIndex={0} p={0}>
                                <Text as="span" textStyle="sm" color="brand.neutral.700">
                                  {titleCase(row.heading)}
                                </Text>
                              </BaselaneResponsiveCell>
                              <BaselaneResponsiveCell configIndex={1} p={0} colSpan="2">
                                <Text
                                  textStyle="sm"
                                  color="brand.neutral.900"
                                  style={{ textWrap: 'wrap' }}
                                  w="100%"
                                >
                                  {(() => {
                                    if (
                                      row.heading.toLowerCase().includes('date') &&
                                      row.data !== '--'
                                    ) {
                                      return formatDate(row.data);
                                    }
                                    if (isFieldNotObject(row.data)) {
                                      return row.data;
                                    }
                                    return '--';
                                  })()}
                                </Text>
                              </BaselaneResponsiveCell>
                            </BaselaneResponsiveTableRow>
                          );
                        }}
                      />
                    </Tbody>
                  </BaselaneResponsiveTable>
                ),
              }))
            )}
          />
        ))}

        {reportData && reportData.report?.results?.length === 0 && (
          <BaselaneCardNew size="md" palette="dark" width="100%">
            <HStack spacing={2} width="100%">
              <Icon16Info />
              <Text color="brand.neutral.700" textStyle="sm">
                There is no information to display for this report.
              </Text>
            </HStack>
          </BaselaneCardNew>
        )}
      </VStack>
      <Outlet />
    </>
  );
};

export default CriminalReport;
