import React from 'react';
import { Box, Divider, Text, Spacer, HStack, Stack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useStatsigClient } from '@statsig/react-bindings';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import formatCurrency from '@core/utils/formatCurrency';
import BaselaneChip from '@core/components/Shared/components/BaselaneChip';
import UpcomingPaymentsAccordion from '../upcoming-payments/UpcomingPaymentsAccordion';
import Payee from './Payee';
import RecurringIcon from './RecurringIcon';
import Recurrance from './Recurrance';

import BookkeepingReview from './BookkeepingReview';
import Recipient from './PaymentMethodDetails';

const RecurringPaymentReview = () => {
  const { checkGate } = useStatsigClient();
  const isBankingFeatureEnabled = checkGate(FEATURE_GATES.BANKING_FEATURE_ANNOUNCEMENT_V1);

  const { values } = useFormikContext();
  const { bankAccountsById } = useBankAccountsActive();

  const getPaymentMethodLabel = (paymentMethodType) => {
    let label = '';

    if (paymentMethodType === 'TRANSFER_OUT') {
      label = 'ACH';
    }
    if (paymentMethodType === 'CHECK_PAYMENT') {
      label = 'Mail a check';
    }
    if (paymentMethodType === 'WIRE_TRANSFER') {
      label = 'Wire';
    }

    return `${label} to`;
  };

  const paymentMethodLabel = getPaymentMethodLabel(values.paymentMethodType);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box
        padding={2}
        bg="brand.darkBlue.50"
        border="1px solid"
        borderColor="brand.darkBlue.200"
        borderRadius="8px"
      >
        {/* Summary */}
        <Box display="flex">
          <Box flexGrow="1">
            <Text color="brand.neutral.600" textStyle="xs">
              {paymentMethodLabel}
            </Text>
            <Payee recipientId={values.payeeId} />
            <Recurrance />
          </Box>
          <Box display="flex" flexDirection="column" gap={1} alignItems="flex-end">
            <BaselaneChip size="md" label="Recurring" leftIcon={RecurringIcon} />
            <Text textStyle="data-lg">
              {formatCurrency(values?.amount ? values.amount?.replace(/,/g, '') : 0).inDollars}
            </Text>
          </Box>
        </Box>
        <UpcomingPaymentsAccordion mt={1} />
        <Divider my={1} opacity={1} color="brand.darkBlue.200" />
        <Box display="flex" mt={2}>
          <Text textStyle="xs">Processing fee (per payment)</Text>
          <Spacer />
          <HStack as="span" alignItems="flex-start" gap={1}>
            {values?.paymentMethodType === 'WIRE_TRANSFER' && (
              <BaselaneChip
                size="sm"
                label={isBankingFeatureEnabled ? 'Free until Q1 2025' : 'For a limited time'}
                variant="simple-neutral"
              />
            )}

            <Stack alignItems="flex-end" gap="0">
              <Text>
                {values?.paymentMethodType === 'CHECK_PAYMENT'
                  ? formatCurrency(2).inDollars
                  : formatCurrency(0).inDollars}
              </Text>
              {isBankingFeatureEnabled && values?.paymentMethodType === 'WIRE_TRANSFER' && (
                <Text textDecoration="line-through" textStyle="xs">
                  {formatCurrency(15).rounded}
                </Text>
              )}
            </Stack>
          </HStack>
        </Box>
        <Box display="flex" mt={1}>
          <Text textStyle="xs">From account</Text>
          <Spacer />
          <Box flexDirection="column">
            <Text textStyle="sm">{bankAccountsById[values.fromTransferAccountId]?.nickName}</Text>
            <Text textStyle="xs">{bankAccountsById[values.fromTransferAccountId]?.name}</Text>
          </Box>
        </Box>
        <Divider my={1} opacity={1} color="brand.darkBlue.200" />
        <Recipient />
      </Box>
      <BookkeepingReview />
    </Box>
  );
};

export default RecurringPaymentReview;
