import React from 'react';
import { Outlet } from 'react-router-dom';
import { UnitOtpProvider } from '../../../../contexts/UnitOtpContext';

/**
 * This wrapper provides a context for interacting with the Unit OTP UI.
 * It also inserts the needed UI into the page, should it need to be displayed
 * to the user.
 */

const UnitOtpWrapper = () => {
  return (
    <UnitOtpProvider>
      <Outlet />
    </UnitOtpProvider>
  );
};

export default UnitOtpWrapper;
