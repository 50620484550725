// @flow
import React, { useState, useContext } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { BaselaneDrawer, BaselaneErrorCard } from '@shared/components';
import SlLoader from '@core/components/Loader';
import useBreakPoints from '@core/hooks/useBreakPoints';
import InvoiceContext from '@contexts/InvoiceContext';
import QuickPayIconOrBadge from '@pages/LeasesPage/components/QuickPayIconOrBadge';
import DrawerBody from './DrawerBody';
import { getInvoicesForUnit, getPropertyPropsForInvoiceList } from '../helpers/invoice.helpers';
import { getTenantDetails } from '../helpers/unit.helpers';

type propTypeCheck = {
  isLeaseLevel?: boolean,
  handleInvoiceListDrawerClose: Function,
  leaseInvoicesError: Boolean,
  invoiceList: Object,
  setInvoiceList: Function,
  refetchLeaseInvoices: Function,
  refetchLease: Function,
  refetchInvoiceSummary: Function,
  invoiceListDrawerRef: any,
  invoiceDrawerActionBtnRef: any,
  invoiceTotal?: Object,
  setInvoiceTotal: Function,
  sortedLeases: Array<Object>,
  isDontCleanAtClose: boolean,
  bankProps: Object,
  tenantData: Object,
};

function Invoices({
  isLeaseLevel: isLeaseLevelLegacy = false,
  handleInvoiceListDrawerClose,
  leaseInvoicesError,
  invoiceList,
  setInvoiceList,
  refetchLeaseInvoices,
  refetchLease,
  refetchInvoiceSummary,
  invoiceListDrawerRef,
  invoiceDrawerActionBtnRef,
  invoiceTotal = {},
  setInvoiceTotal,
  sortedLeases,
  isDontCleanAtClose,
  bankProps,
  tenantData,
}: propTypeCheck): any {
  const { isMax767 } = useBreakPoints();
  const { leaseId } = JSON.parse(localStorage.getItem('filters')) ?? {};
  const isLeaseLevel = !!leaseId || isLeaseLevelLegacy;
  const { leaseInvoicesLoading } = useContext(InvoiceContext);
  const {
    propertyName,
    propertyAddress,
    unitName,
    hasMultipleUnits,
    tenantId,
    receivingAccountId,
    isQuickPay,
  } =
    getPropertyPropsForInvoiceList({
      leaseId,
      sortedLeases,
    }) ?? {};
  const { setInvoiceFilters, invoiceFilters } = useContext(InvoiceContext);
  const { firstName = '', lastName = '' } = tenantData
    ? getTenantDetails(tenantData?.landlordTenant, tenantId) || {}
    : {};
  const tenantFullName = `${firstName} ${lastName}`;

  const [hasUpdatedInvoice, setHasUpdatedInvoice] = useState(false);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [showFilterPopupAnimation, setShowFilterPopupAnimation] = useState({
    slidein: false,
    slideout: false,
  });

  const invoices = getInvoicesForUnit(invoiceList);

  const invoiceTitle = hasMultipleUnits
    ? `${propertyAddress?.address} · ${unitName}`
    : propertyAddress?.address;
  const invoiceSubTitle = (
    <HStack>
      <Text fontSize="14px">{tenantFullName}</Text>
      {isQuickPay && <QuickPayIconOrBadge />}
    </HStack>
  );

  const handleFilterPopupClose = () => {
    setShowFilterPopup(false);
    setShowFilterPopupAnimation(() => ({ slidein: false, slideout: true }));
  };

  const cleanup = () => {
    setInvoiceList();
    setHasUpdatedInvoice(false);
    setShowFilterPopup(false);
    setShowFilterPopupAnimation(() => ({ slidein: false, slideout: false }));
  };

  const overlayStyles = () => {
    let background = 'rgba(0,0,0,0.48)';
    // // formula reference: https://en.wikipedia.org/wiki/Alpha_compositing
    if (showFilterPopup) background = 'rgba(0,0,0,0.74)';
    else if (isLeaseLevel && isLeaseLevel === isLeaseLevelLegacy) background = 'transparent';
    return { background };
  };

  return (
    <BaselaneDrawer
      finalFocusRef={invoiceDrawerActionBtnRef}
      size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
      contentStyles={isMax767 && { top: 'auto !important' }}
      title="Invoices"
      hideBackText
      ref={invoiceListDrawerRef}
      newDesignDrawer
      closeEvent={() => {
        if (hasUpdatedInvoice) {
          refetchInvoiceSummary();
          refetchLease();
        }
        if (!isDontCleanAtClose) {
          cleanup();
        }
        handleInvoiceListDrawerClose();
      }}
      closeOnOverlayClick={false}
      overlayStyles={overlayStyles()}
      onOverlayClick={() => {
        if (!showFilterPopup) {
          if (hasUpdatedInvoice) {
            refetchInvoiceSummary();
            refetchLease();
          }
          cleanup();
          handleInvoiceListDrawerClose();
        } else {
          handleFilterPopupClose();
        }
      }}
    >
      {leaseInvoicesError && <BaselaneErrorCard />}
      {leaseInvoicesLoading && <SlLoader />}
      {!leaseInvoicesLoading && !leaseInvoicesError && (
        <DrawerBody
          isLeaseLevel={isLeaseLevel}
          invoiceTitle={invoiceTitle}
          invoiceSubTitle={invoiceSubTitle}
          invoices={invoices}
          setInvoiceList={setInvoiceList}
          invoiceTotal={invoiceTotal}
          setInvoiceTotal={setInvoiceTotal}
          refetchLeaseInvoices={refetchLeaseInvoices}
          hasUpdatedInvoice={hasUpdatedInvoice}
          setHasUpdatedInvoice={setHasUpdatedInvoice}
          sortedLeases={sortedLeases}
          hasMultipleUnits={hasMultipleUnits}
          showFilterPopup={showFilterPopup}
          setShowFilterPopup={setShowFilterPopup}
          handleFilterPopupClose={handleFilterPopupClose}
          showFilterPopupAnimation={showFilterPopupAnimation}
          setShowFilterPopupAnimation={setShowFilterPopupAnimation}
          propertyName={propertyName}
          unitName={unitName}
          bankProps={bankProps}
          tenantId={tenantId}
          receivingAccountId={receivingAccountId}
          leaseId={leaseId}
          setInvoiceFilters={setInvoiceFilters}
          invoiceFilters={invoiceFilters}
          leaseInvoicesLoading={leaseInvoicesLoading}
        />
      )}
    </BaselaneDrawer>
  );
}

export default Invoices;
