import React from 'react';
import { Heading, HStack, Skeleton, VStack } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  BaselaneAlertNew,
  BaselaneCardStack,
  BaselaneCardStackLayoutClickable,
} from '@shared/components';

import {
  IllustrationOtherLeaseDocument,
  IllustrationOtherSuccess,
  IllustrationRentcollection,
} from '@illustrations';

import { IllustrationOtherSuccessWarning } from '@illustrations/manual';

import {
  CREATE_LEASE_DOCUMENT,
  DASHBOARD,
  TENANT_SCREENING,
  UNIFIED_RENT_COLLECTION,
} from '@routes';

import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { GET_TENANT_SCREENING_BY_ID } from '@core/apollo/queries/tenantScreening';
import { generalRedirectToURC } from '@pages/UnifiedRentCollection/UnifiedLeaseUI/helpers/unifiedLeaseFlow.helpers';

type ApproveSuccessProps = {
  RCLink: String,
  isConditional: Boolean,
};

const ApproveSuccess = ({
  RCLink = UNIFIED_RENT_COLLECTION,
  isConditional = false,
}: ApproveSuccessProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const { screeningId, applicationId } = params;

  const { data: screeningData, loading } = useQuery(GET_TENANT_SCREENING_BY_ID, {
    fetchPolicy: 'cache-first',
    variables: { id: Number(screeningId) },
  });

  const {
    getTenantScreeningById: { applications },
  } = screeningData || { getTenantScreeningById: {} };

  const application = applications?.find((app) => app.id === applicationId);
  const { applicants } = application || {};

  const cardItems = [
    {
      content: (
        <BaselaneCardStackLayoutClickable
          icon={<IllustrationOtherLeaseDocument />}
          title="Create and e-sign a lease"
          onClick={() => {
            const state = { from: TENANT_SCREENING };
            const pathname = `${DASHBOARD}/${CREATE_LEASE_DOCUMENT}`;
            sendSegmentEvent('tenant_screening_fe_rocket_lawyer_clicked');
            generalRedirectToURC({ navigate, pathname, state });
          }}
          description="Create your lease document by answering a few quick questions, sign it online and send to tenants for signing."
        />
      ),
      id: 'tenant-screening-success-rocket-lawyer-link',
    },
    {
      content: (
        <BaselaneCardStackLayoutClickable
          icon={<IllustrationRentcollection />}
          title="Set up rent collection"
          onClick={() => {
            const state = { from: TENANT_SCREENING };
            const pathname = RCLink;
            sendSegmentEvent('tenant_screening_fe_rc_setup_clicked');
            generalRedirectToURC({ navigate, pathname, state });
          }}
          description="Fast & free rent collection with automated invoicing, reminders & late fees."
        />
      ),
      id: 'tenant-screening-success-rent-collection-link',
    },
  ];

  return (
    <VStack gap={2} alignItems="flex-start">
      <BaselaneAlertNew
        body={`The applicant${applicants?.length > 1 ? 's' : ''} will be notified by email.`}
        hasCloseButton={false}
        iconName={isConditional ? IllustrationOtherSuccessWarning : IllustrationOtherSuccess}
        onCloseClick={() => {}}
        title="Application approved"
        variant={isConditional ? 'warning' : 'success'}
        visual="illustration"
        mb={2}
      />
      <HStack>
        <Heading size="headline-xl">Next steps</Heading>
      </HStack>
      <Skeleton isLoaded={!loading} w="full">
        {cardItems.map((item, index) => (
          <BaselaneCardStack id="approve-success" items={[item]} mb="24px" />
        ))}
      </Skeleton>
    </VStack>
  );
};

export default ApproveSuccess;
