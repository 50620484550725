/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { Stack, HStack, Text, Spacer, Progress, Skeleton } from '@chakra-ui/react';
import { useStatsigClient } from '@statsig/react-bindings';
import { IconCheckCircle } from '@icons';
import useBreakPoints from '@core/hooks/useBreakPoints';
import DisableMoveMoney from '@core/components/DisableMoveMoney';
import UserContext from '@core/contexts/UserContext';
import { NoAccessTooltip } from '@shared/components';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { Completed, Inactive } from './Steps';
import StepsToggler from './StepsToggler';
import { STATE_ENUM } from '../helpers/trackers.helpers';
import {
  trackerContainerStyles,
  TRACKER_BAR_COLOR,
  trackerBarStyles,
  trackerBarTextStyles,
  trackerAllDoneTextStyles,
  trackerHeaderStyles,
  trackerHeaderRightStyles,
  trackerHeaderTitleStyles,
} from '../styles/tracker.styles';

type TrackerProps = {
  tracker: Object,
  steps: Object,
  isLoading: boolean,
  showSteps: boolean,
  setShowSteps: Function,
  showTooltip: Boolean,
};

const Tracker = ({
  tracker,
  steps,
  isLoading,
  showSteps,
  setShowSteps,
  showTooltip,
}: TrackerProps) => {
  const { data, getLinkTo } = tracker;
  const { isMax768: isMobile } = useBreakPoints();
  const { emailVerified } = useContext(UserContext);
  const { checkGate } = useStatsigClient();

  const stepsCompleted = steps.filter((step) => STATE_ENUM[step.state] === 'completed');
  const isDone = stepsCompleted.length === steps.length;
  const showProgressBar = (isMobile && !isDone) || !isMobile;
  const progress = stepsCompleted.length === 0 ? 0 : 100 / (steps.length / stepsCompleted.length);

  const getSteps = () => {
    const inProgStepFirstIndex = steps.findIndex((step) => STATE_ENUM[step.state] !== 'completed');

    return steps.map((step, index) => {
      const { state, name } = step;
      const isMoveMoneySteps = name === 'FUND_YOUR_ACCOUNT' || name === 'ACTIVATE_DEBIT_CARD';
      const isDisabled = STATE_ENUM[state] === 'disabled' || (isMoveMoneySteps && !emailVerified);

      return isMobile && !showSteps
        ? {
            ...step,
            show: index === inProgStepFirstIndex,
            isDisabled,
          }
        : {
            ...step,
            show: true,
            isDisabled,
          };
    });
  };

  return showTooltip ? (
    <Stack
      {...trackerContainerStyles(isMobile, showSteps, checkGate(FEATURE_GATES.DASHBOARD_GATE))}
    >
      <NoAccessTooltip>
        {/* Header */}
        <HStack {...trackerHeaderStyles(isMobile, isDone)}>
          {data.icon}
          <Stack {...trackerHeaderRightStyles}>
            <HStack>
              <Text {...trackerHeaderTitleStyles}>{data.title}</Text>
              {isDone && isMobile && (
                <>
                  <Spacer />
                  <Text {...trackerAllDoneTextStyles}>All done!</Text>
                  <IconCheckCircle color="#257ED0" />
                </>
              )}
            </HStack>

            {showProgressBar && (
              <HStack m="0 !important">
                <Progress value={progress} colorScheme={TRACKER_BAR_COLOR} {...trackerBarStyles} />
                <Text {...trackerBarTextStyles}>{Math.round(progress)}%</Text>
              </HStack>
            )}
          </Stack>
        </HStack>
        {/* Steps */}
        {getSteps(steps).map((element) => {
          const { name, state, show, isDisabled } = element;
          if (!show) return null;

          const stepState = STATE_ENUM[state];
          const step = data.elements?.[name];
          const status = step?.[stepState];
          const link = getLinkTo(name, stepState);
          const activeStep =
            stepState === 'completed' ? <Completed key={name} status={status} /> : link && link;
          const inactiveElement =
            name === 'FUND_YOUR_ACCOUNT' || name === 'ACTIVATE_DEBIT_CARD' ? (
              <DisableMoveMoney
                tooltipText={`Verify your email to ${
                  name === 'FUND_YOUR_ACCOUNT' ? 'add funds' : 'activate card'
                }`}
                styles={{ flex: 0 }}
                renderItem={(disabled) => <Inactive step={step} isDisabled={disabled} />}
              />
            ) : (
              <Inactive step={step} />
            );
          return (
            <Skeleton key={name} isLoaded={!isLoading}>
              {isDisabled ? inactiveElement : activeStep}
            </Skeleton>
          );
        })}
        {/* StepsTogger - Show only on Mobile */}
        {isMobile && !isDone && <StepsToggler {...{ showSteps, setShowSteps }} />}
      </NoAccessTooltip>
    </Stack>
  ) : (
    <Stack
      {...trackerContainerStyles(isMobile, showSteps, checkGate(FEATURE_GATES.DASHBOARD_GATE))}
    >
      {/* Header */}
      <HStack {...trackerHeaderStyles(isMobile, isDone)}>
        {data.icon}
        <Stack {...trackerHeaderRightStyles}>
          <HStack>
            <Text {...trackerHeaderTitleStyles}>{data.title}</Text>
            {isDone && isMobile && (
              <>
                <Spacer />
                <Text {...trackerAllDoneTextStyles}>All done!</Text>
                <IconCheckCircle color="#257ED0" />
              </>
            )}
          </HStack>

          {showProgressBar && (
            <HStack m="0 !important">
              <Progress value={progress} colorScheme={TRACKER_BAR_COLOR} {...trackerBarStyles} />
              <Text {...trackerBarTextStyles}>{Math.round(progress)}%</Text>
            </HStack>
          )}
        </Stack>
      </HStack>

      {/* Steps */}
      {getSteps(steps).map((element) => {
        const { name, state, show, isDisabled } = element;
        if (!show) return null;

        const stepState = STATE_ENUM[state];
        const step = data.elements?.[name];
        const status = step?.[stepState];
        const link = getLinkTo(name, stepState);
        const activeStep =
          stepState === 'completed' ? <Completed key={name} status={status} /> : link && link;
        const inactiveElement =
          name === 'FUND_YOUR_ACCOUNT' || name === 'ACTIVATE_DEBIT_CARD' ? (
            <DisableMoveMoney
              tooltipText={`Verify your email to ${
                name === 'FUND_YOUR_ACCOUNT' ? 'add funds' : 'activate card'
              }`}
              styles={{ flex: 0 }}
              renderItem={(disabled) => <Inactive step={step} isDisabled={disabled} />}
            />
          ) : (
            <Inactive step={step} />
          );
        return (
          <Skeleton key={name} isLoaded={!isLoading}>
            {isDisabled ? inactiveElement : activeStep}
          </Skeleton>
        );
      })}

      {/* StepsTogger - Show only on Mobile */}
      {isMobile && !isDone && <StepsToggler {...{ showSteps, setShowSteps }} />}
    </Stack>
  );
};

export default Tracker;
