import { useMutation, useApolloClient } from '@apollo/client';
import { ACCESS_WORKSPACE } from '@core/apollo/queries';

const useSwitchWorkspace = () => {
  const [accessWorkspace] = useMutation(ACCESS_WORKSPACE);
  const client = useApolloClient();

  const switchWorkspace = async (w) => {
    try {
      // Wait for the Apollo Client store to clear before proceeding
      try {
        await client.resetStore();
      } catch (error) {
        console.error('Error clearing Apollo Client store:', error);
      }

      // After the store is cleared, proceed with accessing the workspace
      const handleAccessWorkspace = await accessWorkspace({
        variables: {
          workspaceId: parseFloat(w.id),
        },
      }).then(() => {
        // Reload current page to refresh the data
        window.location.reload();
      });
      handleAccessWorkspace();
    } catch (error) {
      console.error('Error switching workspace:', error);
    }
  };

  return switchWorkspace;
};

export default useSwitchWorkspace;
