export const containerstyles = ({ isMinXL }) => {
  return {
    height: '100%',
    overflowY: 'auto',
  };
};

export const detailBox = {
  margin: '8px 0',
  bg: 'brand.darkBlue.50',
  border: 'solid 1pt',
  borderColor: 'brand.darkBlue.150',
  borderRadius: '4px',
  padding: '16px 0',
  overflowY: 'scroll',
};

export const textStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.600',
};

export const textStyles2 = {
  ...textStyles,
  color: 'brand.neutral.700',
  fontWeight: 'medium',
};

export const textStyles3 = {
  ...textStyles,
  color: 'brand.neutral.700',
  fontWeight: 'normal',
};

export const textStyles4 = {
  ...textStyles,
  color: 'red.800AA',
  fontWeight: 'normal',
  fontSize: '12px',
  lineHeight: '16px',
};

export const invoiceDetailsHeader = {
  ...textStyles2,
  mb: '16px',
  fontWeight: 'medium',
};

export const drawerFooterStyle = {
  h: '80px',
  p: '20px 16px',
  bg: 'brand.neutral.white',
  boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
  zIndex: 1,
  justifyContent: 'flex-start',
};
