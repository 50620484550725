import React from 'react';
import { useFormikContext } from 'formik';
import { Text } from '@chakra-ui/react';

import {
  BaselaneResponsiveCell,
  BaselaneResponsiveTableRow,
  T2WithTitleDropdown,
} from '@shared/components';
import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { DisplayInputDefaultCategoryItem } from '@core/components/Transactions/DisplayInputVariations';

import useIsFlowDirty from '../hooks/useIsFlowDirty';

type MapCategoryRowProps = {
  index: number,
  externalCategory: string,
  categoriesForDropdown: Array<Object>,
  categoriesForDropdownById: Object,
};

const MapCategoryRow = ({
  index,
  externalCategory,
  categoriesForDropdown,
  categoriesForDropdownById,
}: MapCategoryRowProps) => {
  const { values, setFieldValue } = useFormikContext();

  const categorySelected = (value) => {
    return value?.categoryId ? { id: value.categoryId, name: value.baselaneCategory } : null;
  };

  useIsFlowDirty();

  return (
    <BaselaneResponsiveTableRow showBorder={index < values.length - 1}>
      <BaselaneResponsiveCell>
        <Text textStyle="sm" color="brand.neutral.700">
          {externalCategory}
        </Text>
      </BaselaneResponsiveCell>
      <BaselaneResponsiveCell overflow="initial" contentContainerStyles={{ display: 'block' }}>
        <T2WithTitleDropdown
          additionalProps={{ id: 'category-dropdown' }}
          classNames={[
            'fixed-width-dropdown',
            'auto-select-input-width',
            values[index]?.subId ? 'input-form-md' : 'input-form-sm',
          ]}
          data={categoriesForDropdown}
          title="Category"
          showValueByFields={['name']}
          placeholder="Select category"
          parentItemRenderer={itemRenderer}
          childItemRenderer={itemRenderer}
          handleSubmit={(newId) => {
            const [parentId = null, subId = null] =
              newId && typeof newId !== 'object' ? newId.split('-') : [];

            setFieldValue(index, {
              ...values[index],
              externalCategory,
              baselaneCategory: categoriesForDropdownById?.[newId]?.name ?? null,
              categoryId: !parentId && !subId ? null : newId,
              parentId,
              subId,
            });
          }}
          selectedItem={categorySelected(values[index])}
          CustomDisplayInput={DisplayInputDefaultCategoryItem}
        />
      </BaselaneResponsiveCell>
    </BaselaneResponsiveTableRow>
  );
};

export default MapCategoryRow;
