import React, { useEffect, useState, useRef } from 'react';
import { Text, VStack, Box, HStack } from '@chakra-ui/react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_DOCUMENTS,
  GET_DOCUMENT_UPLOAD_URL,
  GET_DOCUMENT_DOWNLOAD_URL,
  DELETE_DOCUMENT,
} from '@core/apollo/queries';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { IconExclamationCircle } from '@icons';
import {
  AlertHeader,
  BaselaneAlert,
  BaselaneButton,
  BaselaneFileUploader,
  DocumentCard,
  getQueryDownloadUrl,
  handleUploadAndSaveImageAttachments,
} from '@shared/components';
import { docDeleteAlert } from '../styles/tenantScreening.styles';
import { getAdditionalDocsByApplicantId } from '../helpers/tenantScreeningReports.helpers';

const AdditionalDocuments = () => {
  const { isMinXL } = useBreakPoints();
  const { verificationId, applicantId } = useOutletContext();
  const { applicationId } = useParams();
  const cancelRef = useRef();

  const [reportData, setReportData] = useState(null);
  const [isDeleteDocAlertOpen, setIsDeleteDocAlertOpen] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState(null);

  // Queries
  const { data: landlordData, refetch: refetchDocuments } = useQuery(GET_DOCUMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: { entityId: applicantId },
  });

  const [getDocumentUploadUrl] = useLazyQuery(GET_DOCUMENT_UPLOAD_URL, {
    fetchPolicy: 'network-only',
  });

  const [getDocumentDownloadUrl] = useLazyQuery(GET_DOCUMENT_DOWNLOAD_URL, {
    fetchPolicy: 'cache-and-network',
  });

  const [deleteDocument, { loading: isDeleteDocLoading }] = useMutation(DELETE_DOCUMENT);

  const handleDeleteDocAlertOpen = ({ id }) => {
    setSelectedDocId(id);
    setIsDeleteDocAlertOpen(true);
  };

  const handleDeleteDocAlertClose = () => {
    setSelectedDocId(null);
    setIsDeleteDocAlertOpen(false);
  };

  const handleDeleteDoc = async () => {
    try {
      await deleteDocument({ variables: { id: selectedDocId } });
      onDeleteDocSuccess();
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const onDeleteDocSuccess = () => {
    handleDeleteDocAlertClose();
    setTimeout(() => {
      refetchDocuments();
    }, 2000);
  };

  const handleUploadSuccess = () => {
    setTimeout(() => {
      refetchDocuments();
    }, 1000);
  };

  const handleUploadFail = () => {
    // console.log('handleUploadFail', props);
    // or instead of passing this function,
    // add file min and max size
  };

  const deleteDocAlertFooter = (
    <HStack {...docDeleteAlert({ isMinXL }).container}>
      <BaselaneButton
        variant="outline"
        palette="neutral"
        size="lg"
        onClick={handleDeleteDocAlertClose}
      >
        Cancel
      </BaselaneButton>
      <BaselaneButton
        variant="filled"
        palette="danger"
        size="lg"
        onClick={handleDeleteDoc}
        isLoading={isDeleteDocLoading}
      >
        Delete
      </BaselaneButton>
    </HStack>
  );

  const getFileExtension = (filename) => {
    const parts = filename?.split('.');
    return parts?.length > 1 ? parts[parts.length - 1].trim().toUpperCase() : '';
  };

  const landlordDocItemsLength = landlordData?.fetchDocuments?.length;

  useEffect(() => {
    // refresh
  }, [landlordDocItemsLength]);

  useEffect(() => {
    if (applicantId && !reportData) {
      getAdditionalDocsByApplicantId(applicantId)
        .then(({ data }) => {
          setReportData(data);
        })
        .catch((error) => {
          console.error('Error fetching application:', error);
        });
    }
  }, [applicationId, applicantId, verificationId]);

  return (
    <>
      <VStack align="stretch" spacing={6} width="100%">
        <Box>
          <Text textStyle="headline-md" mb={2}>
            Added by applicant
          </Text>
          <VStack align="stretch" gap={1}>
            {reportData?.items?.map((doc) => (
              <DocumentCard
                key={doc.id}
                doc={{
                  id: doc.id,
                  name: doc.asset_name,
                  fileExtension: getFileExtension(doc.asset_name),
                  asset_url: doc.asset_url,
                }}
                description={doc.document_type?.replace('_', ' ')}
                isNewDesign
              />
            ))}
            {(!reportData?.items || reportData?.items?.length === 0) && (
              <Text fontSize="sm" color="brand.neutral.600">
                No documents added by applicant
              </Text>
            )}
          </VStack>
        </Box>

        <Box>
          <Text textStyle="headline-md" mb={2}>
            Added by you
          </Text>
          <VStack align="stretch">
            {landlordData?.fetchDocuments?.map((doc) => (
              <DocumentCard
                key={doc.id}
                doc={{
                  id: doc.id,
                  name: doc.name,
                  fileExtension: doc.fileExtension,
                }}
                handleDeleteDocAlertOpen={() => handleDeleteDocAlertOpen({ id: doc.id })}
                getDownloadUrl={async () => getQueryDownloadUrl(doc.id, getDocumentDownloadUrl)}
                isNewDesign
              />
            ))}
          </VStack>

          <Box mt={1}>
            <BaselaneFileUploader
              enableFileNameEditing
              documents={landlordData?.items || []}
              allowedFileType={{
                enum: ['PDF', 'JPEG', 'JPG', 'PNG'],
                text: ['PDF', 'JPG', 'PNG'],
              }}
              handleFile={(props) =>
                handleUploadAndSaveImageAttachments({
                  ...props,
                  getDocumentUploadUrl,
                  entityData: {
                    entityType: 'TENANT_SCREENING_APPLICANT',
                    entityId: applicantId,
                    entityDate: DateTime.now().toISO(),
                  },
                  onUploadSuccess: () => handleUploadSuccess(),
                  onUploadFail: () => handleUploadFail(props),
                })
              }
            />
          </Box>
        </Box>
      </VStack>
      <BaselaneAlert
        size={isMinXL ? 'xs' : '2xl'}
        showCloseButton
        isCentered
        isOpen={isDeleteDocAlertOpen}
        onClose={() => handleDeleteDocAlertClose()}
        leastDestructiveRef={cancelRef}
        header={
          <AlertHeader
            title="Are you sure you want to delete the attachment?"
            icon={<IconExclamationCircle w={20} h={20} color="#EA6868" />}
            iconBgStyles={{ bg: 'red.100' }}
            isHorizontalCenter
          />
        }
        footer={deleteDocAlertFooter}
        isMinXL={isMinXL}
      />
      <Outlet />
    </>
  );
};

export default AdditionalDocuments;
