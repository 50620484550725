import React, { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { checkEmail } from '@core/Services/Firebase/firebase';
import useReferralSource from '@pages/SignUpPage/useReferralSource';
import { BaselaneSpinner } from '@core/components/Shared/components';
import useOnboardingStore from '@core/store/Onboarding';
import { EMAIL_EXISTS, SIGN_UP } from '@core/constants/routes';
import DeferredSignupPropertySurvey from '@core/pages/DeferredSignupPropertySurvey';
import SignUpPage from '@core/pages/SignUpPage';

/**
 * Effectively checks for an email in the URL, checks if it already exists
 * and if not applies the experiment logic to signup flow.  Otherwise
 * allows normal flow through the outlet.
 */

const DeferredSignupLogic = () => {
  const { isReferral } = useReferralSource();
  const [searchParams] = useSearchParams();
  const encodedEmail = searchParams.get('email');

  const [isLoading, setIsLoading] = useState(true);
  const { email, setEmail } = useOnboardingStore();

  // URL email state
  const [emailExists, setEmailExists] = useState(false);

  // URL email logic
  useEffect(() => {
    if (encodedEmail && encodedEmail?.length) {
      const decodedEmail = decodeURIComponent(encodedEmail);
      setEmail(decodedEmail);
      checkEmail(decodedEmail)
        .then((signInMethods) => {
          setIsLoading(false);
          if (signInMethods.length) {
            setEmailExists(true);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log('invalid email:', error);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) return <BaselaneSpinner />;
  if (!isLoading && emailExists && !isReferral) {
    return <Navigate to={`${SIGN_UP}/${EMAIL_EXISTS}`} state={{ email }} />;
  }

  return encodedEmail ? <DeferredSignupPropertySurvey /> : <SignUpPage />;
};

export default DeferredSignupLogic;
