import React, { useEffect, useState } from 'react';
import { FormControl } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { useFormikContext } from 'formik';
import useBreakPoints from '@core/hooks/useBreakPoints';
import BaselaneFormLabel from '../../../BaselaneForm/BaselaneFormLabel';
import BaselaneFormErrorMessage from '../../../BaselaneForm/BaselaneFormErrorMessage';
import BaselaneFormHelperText from '../../../BaselaneForm/BaselaneFormHelperText';
import BaselaneInput from '../../../BaselaneInput';
import T1WithTitleDropdown from '../../../BaselaneDropdown-new/T1-Title';
import DropDownDisplayInputWithOutRightButton from '../../../BaselaneDropdown-new/DisplayInputVariations/DropDownDisplayInputWithOutRightButton';

import {
  currencyMask,
  renderAccountDropdownItemWithBalanceAndAccountType,
  handleFromAccountHandleSubmit,
  handleToAccountHandleSubmit,
  onlyCheckingAccounts,
  onlyBaselaneAccounts,
  isInAccountList,
} from '../../helpers';
import { formStyles } from '../../styles';

type Step1Props = {
  transferType: string,
  setTransferType: Function,
  getUpdateLinkToken: Function,
  fromDropdownList: Array,
  toDropdownList: Array,
  accountsMap: Object,
};

const Step1 = ({
  transferType,
  setTransferType,
  getUpdateLinkToken,
  fromDropdownList,
  toDropdownList,
  accountsMap,
}: Step1Props) => {
  const { isMax576 } = useBreakPoints();

  const [toAccounts, setToAccounts] = useState(toDropdownList);

  const [hasDropdownClearedExternally, setHasDropdownClearedExternally] = useState(false);

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
  } = useFormikContext();

  const { controlStyles } = formStyles;

  /**
   * To + From combination account filter business logic
   * see: https://baselane.atlassian.net/browse/OMEGA-4042
   */
  const applyFilterLogic = () => {
    const fromAccount = values?.fromTransferAccountId
      ? accountsMap[values?.fromTransferAccountId]
      : null;

    let filteredToAccounts = toDropdownList;

    const toAccount = values?.toTransferAccountId ? accountsMap[values?.toTransferAccountId] : null;

    switch (transferType) {
      case 'TRANSFER_IN':
        filteredToAccounts = [];
        toDropdownList.forEach((accountItem, index) => {
          const items = accountItem?.items
            ?.filter(onlyBaselaneAccounts)
            ?.filter(onlyCheckingAccounts);
          if (items && items?.length > 0) {
            filteredToAccounts[index] = {
              ...accountItem,
              items,
            };
          }
        });
        break;
      case 'TRANSFER_OUT':
      case 'INTERNAL':
        if (fromAccount?.accountSubType === 'savings') {
          filteredToAccounts = [];
          toDropdownList.forEach((accountItem) => {
            const items = accountItem?.items?.filter(onlyBaselaneAccounts);
            if (items && items?.length > 0) {
              filteredToAccounts.push({
                ...accountItem,
                items,
              });
            }
          });
        }
        break;
      default:
        // do nothing
        break;
    }

    // check if new filtered "to" accounts contain the currently selected one
    if (toAccount && !isInAccountList(toAccount, filteredToAccounts)) {
      // if not, reset the "to" account dropdown
      setFieldValue('toTransferAccountId', null);
      setHasDropdownClearedExternally(true);
      // also "untouch" the "to" field to undo any results of validation
      setFieldTouched('toTransferAccountId', false);
    }

    setToAccounts(filteredToAccounts);
  };

  useEffect(() => {
    applyFilterLogic();
  }, [transferType, values?.toTransferAccountId, values?.fromTransferAccountId, toDropdownList]);

  return (
    <>
      {/* Transfer From */}
      <FormControl
        id="transfer-funds-transfer-from-account"
        isInvalid={errors.fromTransferAccountId && touched.fromTransferAccountId}
        isRequired
        {...controlStyles}
      >
        <BaselaneFormLabel textStyle="sm" htmlFor="fromTransferAccountId">
          Transfer from
        </BaselaneFormLabel>
        <T1WithTitleDropdown
          data={fromDropdownList}
          placeholder="Select"
          title="Account"
          searchTerm={['name', 'nickName']}
          showValueByFields={['name', 'nickName']}
          itemRenderer={renderAccountDropdownItemWithBalanceAndAccountType}
          isMulti={false}
          hasFilterWrapper={false}
          CustomDisplayInput={DropDownDisplayInputWithOutRightButton}
          parentId="drawer-body"
          isMobile={isMax576}
          showDivider
          name="fromTransferAccountId"
          additionalProps={{ id: 'transfer-funds-transfer-from-dropdown' }}
          classNames={[
            'input-form-lg',
            'auto-select-input-width',
            ...(errors.fromTransferAccountId && touched.fromTransferAccountId
              ? ['input-invalid']
              : []),
          ]}
          selectedItem={accountsMap[values.fromTransferAccountId]}
          handleSubmit={(selectedValue) => {
            handleFromAccountHandleSubmit({
              values,
              selectedValue,
              setFieldTouched,
              setFieldValue,
              accountsMap,
              setTransferType,
              getUpdateLinkToken,
            });
          }}
        />
        {!errors.fromTransferAccountId && accountsMap[values.fromTransferAccountId]?.isExternal && (
          <BaselaneFormHelperText type="isError" customStyles={{ color: 'brand.neutral.600' }}>
            External transfers will take 3 business days
          </BaselaneFormHelperText>
        )}
        <BaselaneFormErrorMessage
          isInvalid={errors.fromTransferAccountId && touched.fromTransferAccountId}
        >
          {errors.fromTransferAccountId}
        </BaselaneFormErrorMessage>
      </FormControl>

      {/* Deposit To */}
      <FormControl
        id="transfer-funds-deposit-to-account"
        isInvalid={errors.toTransferAccountId && touched.toTransferAccountId}
        isRequired
        {...controlStyles}
      >
        <BaselaneFormLabel textStyle="sm" htmlFor="toTransferAccountId">
          Deposit to
        </BaselaneFormLabel>
        <T1WithTitleDropdown
          data={toAccounts}
          placeholder="Select"
          title="Account"
          searchTerm={['name', 'nickName']}
          showValueByFields={['name', 'nickName']}
          itemRenderer={renderAccountDropdownItemWithBalanceAndAccountType}
          isMulti={false}
          hasFilterWrapper={false}
          CustomDisplayInput={DropDownDisplayInputWithOutRightButton}
          parentId="drawer-body"
          isMobile={isMax576}
          showDivider
          name="toTransferAccountId"
          additionalProps={{ id: 'transfer-funds-deposit-to-dropdown' }}
          classNames={[
            'input-form-lg',
            'auto-select-input-width',
            ...(errors.toTransferAccountId && touched.toTransferAccountId ? ['input-invalid'] : []),
          ]}
          selectedItem={accountsMap[values.toTransferAccountId]}
          setHasDropdownClearedExternally={setHasDropdownClearedExternally}
          hasDropdownClearedExternally={hasDropdownClearedExternally}
          handleSubmit={(selectedValue) => {
            handleToAccountHandleSubmit({
              values,
              selectedValue,
              setFieldTouched,
              setFieldValue,
              accountsMap,
              setTransferType,
              getUpdateLinkToken,
            });
          }}
        />
        {!errors.toTransferAccountId &&
          (accountsMap[values.toTransferAccountId]?.isExternal ||
            accountsMap[values.toTransferAccountId]?.provider === 'MANUAL') && (
            <BaselaneFormHelperText type="isError" customStyles={{ color: 'brand.neutral.600' }}>
              External transfers will take 3 business days
            </BaselaneFormHelperText>
          )}
        <BaselaneFormErrorMessage
          isInvalid={errors.toTransferAccountId && touched.toTransferAccountId}
        >
          {errors.toTransferAccountId}
        </BaselaneFormErrorMessage>
      </FormControl>

      {/* Amount */}
      <FormControl
        id="transfer-funds-amount"
        isInvalid={errors.amount && touched.amount}
        isRequired
        {...controlStyles}
      >
        <BaselaneFormLabel textStyle="sm" htmlFor="amount">
          Amount
        </BaselaneFormLabel>
        <BaselaneInput
          id="amount"
          name="amount"
          value={values.amount}
          size="lg"
          prefix="$"
          as={MaskedInput}
          mask={currencyMask}
          onChange={(e) => {
            setFieldTouched('amount');
            handleChange(e);
          }}
          isInvalid={errors.amount && touched.amount}
          onBlur={handleBlur}
        />
        <BaselaneFormErrorMessage isInvalid={errors.amount && touched.amount}>
          {errors.amount}
        </BaselaneFormErrorMessage>
      </FormControl>
    </>
  );
};

export default Step1;
