/* eslint-disable no-return-assign */
// @flow
import React, { useEffect, useState } from 'react';
import { Stack, Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { emailVerify, getQueryParams } from '@core/Services/Firebase/firebase';

import IconCheckCircle from '@icons/manual/IconCheckCircle';
import IconWarning from '@icons/manual/IconWarning';
import { BaselaneHeaderLogoWrapper } from '@shared/layouts';
import {
  msgContainerStyle,
  circleStyleGreen,
  iconCardStyle,
} from '@shared/components/BaselaneErrorCard/styles/errorcard.style';

import customTheme from '@core/theme';
import VerificationCompletePage from './VerificationCompletePage';
import LinkExpiredPage from './LinkExpiredPage';
import SlLoader from '../Loader';

function VerifiedUserPage() {
  const [isUserVerified, setIsUserVerified] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const actionCode = getQueryParams(location, 'oobCode');
    emailVerify(actionCode)
      .then(() => {
        setIsUserVerified(true);
      })
      .catch(() => {
        setIsUserVerified(false);
      });
  }, []);

  let page = <SlLoader />;
  let icon = null;

  if (isUserVerified === true) {
    page = <VerificationCompletePage />;
    icon = <IconCheckCircle color={customTheme.colors.green['800AA']} />;
  }

  if (isUserVerified === false) {
    page = <LinkExpiredPage />;
    icon = <IconWarning />;
  }

  return (
    <BaselaneHeaderLogoWrapper>
      <Stack h="100%" overflow="auto" maxWidth="384px">
        <Box {...msgContainerStyle}>
          <Box {...circleStyleGreen} {...(!isUserVerified && { bg: 'brand.darkBlue.150' })}>
            <Box as="span" {...iconCardStyle} className="error-icon-wrapper">
              {icon}
            </Box>
          </Box>
          {page}
        </Box>
      </Stack>
    </BaselaneHeaderLogoWrapper>
  );
}

export default VerifiedUserPage;
