export const onboardingSurvey = (showOnbaordingEntityQuestion = false) => ({
  version: '1.6',
  questions: [
    {
      question: 'How many rental units (doors) do you own/manage today?',
      questionForMixpanel: 'How many rental units (doors) do you own/manage today?',
      eventString: 'number_of_doors',
      isMultiSelect: false,
      answers: ['0', '1-3', '4-10', '11+'],
    },
    {
      question: 'What type of real estate do you primarily own/manage?',
      questionForMixpanel: 'What type of real estate do you primarily own/manage?',
      eventString: 'type_of_property',
      isMultiSelect: false,
      answers: ['LTR', 'STR/MTR', 'Fix and Flip', 'Commercial'],
    },
    ...(showOnbaordingEntityQuestion
      ? [
          {
            question:
              'How many separate legal entities (like an LLC or partnership) do you use for your real estate?',
            questionForMixpanel:
              'How many separate legal entities (like an LLC or partnership) do you use for your real estate?',
            eventString: 'entity_count',
            isMultiSelect: false,
            answers: ["0 (I dont't have separate legal entities)", '1', '2', '3-4', '5+'],
          },
        ]
      : []),
    {
      question: 'How did you hear about us?',
      questionForMixpanel: 'How did you hear about us?',
      eventString: 'source',
      isMultiSelect: false,
      answers: [
        {
          id: 'search-engine',
          label: 'Search engine (Google, Bing)',
          value: 'Search engine',
        },
        {
          id: 'social-media',
          label: 'Facebook or Instagram',
          value: 'Facebook or Instagram',
        },
        {
          id: 'you-tube',
          label: 'YouTube',
          value: 'YouTube',
        },
        {
          id: 'friend-colleague',
          label: 'Friend or colleague',
          value: 'Friend or colleague',
        },
        {
          id: 'bigger-pockets',
          label: 'BiggerPockets',
          value: 'BiggerPockets',
        },
        {
          id: 'podcast-or-webinar',
          label: 'Podcast or Webinar',
          value: 'Podcast or Webinar',
          type: 'text',
        },
        {
          id: 'reddit',
          label: 'Reddit',
          value: 'Reddit',
        },
        {
          id: 'influencer',
          label: 'Real Estate Coach/Educator',
          value: 'Influencer',
          type: 'text',
        },
      ]
        .sort(() => Math.random() - 0.5)
        .concat([{ id: 'other', label: 'Other', value: 'Other', type: 'text' }]),
    },
  ],
});
