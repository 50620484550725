import React, { useState } from 'react';
import { ChakraProvider, useMediaQuery, useToast } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { Outlet, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useUnitOtp } from '@core/contexts/UnitOtpContext';
import useValidDates from '@hooks/useValidDates';
import habitatTheme from '@core/themeHabitat';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';
import TransferFundsDrawerFooter from './footer/TransferFundsDrawerFooter';
import TransferDetails from './steps/transfer-details/TransferDetails';
import TransferSchedule from './steps/transfer-schedule/TransferSchedule';
import BookkeepingDetails from './steps/book-keeping/BookkeepingDetails';
import ReviewAndTransfer from './steps/review-and-transfer/ReviewAndTransfer';
import Success from './steps/Success';
import steps from './steps';
import useCreateTransfer from './submit/useCreateTransfer';
import useAddRecurringPayment from './submit/useAddRecurringPayment';

const TransferFundsDrawer = () => {
  const navigate = useNavigate();
  const [isMax576] = useMediaQuery('(max-width: 576px)');
  const [currentStep, setCurrentStep] = React.useState(steps.TRANSFER_DETAILS);
  const { startDate, endDate } = useValidDates();
  const [xIdempotencyKey, setXIdempotencyKey] = React.useState(uuidv4());
  const { createTransfer, loading: createTransferIsLoading } = useCreateTransfer(xIdempotencyKey);
  const toast = useToast();

  const { addRecurringPayment, loading: addRecurringPaymentIsLoading } = useAddRecurringPayment(
    xIdempotencyKey
  );

  // Unit OTP
  const { isOtpRequired } = useUnitOtp();

  const [requireOtp, setRequireOtp] = useState(false);

  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Something went wrong. Please try again',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const closeDrawer = () => {
    navigate(-1);
  };

  const componentsByStep = {
    [steps.TRANSFER_DETAILS]: <TransferDetails />,
    [steps.TRANSFER_SCHEDULE]: <TransferSchedule />,
    [steps.BOOKKEEPING_DETAILS]: <BookkeepingDetails />,
    [steps.REVIEW_AND_TRANSFER]: <ReviewAndTransfer />,
    [steps.SUCCESS]: <Success />,
  };

  return (
    <ChakraProvider theme={habitatTheme}>
      <Formik
        validateOnChange
        validateOnBlur
        validate={(values) => {
          let errors = {};

          if (
            values.transferType === 'recurring' &&
            values.recurringTransfer.endBy === 'number-of-transfers' &&
            !values.recurringTransfer.numberOfTransfers
          ) {
            errors = {
              ...errors,
              recurringTransfer: {
                ...errors.recurringTransfer,
                numberOfTransfers: 'Number of transfers must be greater than 0',
              },
            };
          }

          if (values.transferType === 'recurring' && !values.recurringTransfer.repeatEvery.value) {
            errors = {
              ...errors,
              recurringTransfer: {
                ...errors.recurringTransfer,
                repeatEvery: {
                  value: 'Must be greater than 0',
                },
              },
            };
          }

          if (values.transferType === 'recurring' && !values.recurringTransfer.startDate) {
            errors = {
              ...errors,
              recurringTransfer: {
                ...errors.recurringTransfer,
                startDate: 'It is required',
              },
            };
          }

          return errors;
        }}
        initialValues={{
          transferFromAccountId: null,
          depositToAccountId: null,
          amount: '',
          transferType: 'one-time', // Possible values: 'one-time', 'recurring'.
          oneTimeTransfer: {
            date: new Date(),
          },
          recurringTransfer: {
            startDate,
            repeatEvery: {
              value: 1,
              unit: 'month', // Possible values: "month", "week"
            },
            repeatOnMonthDate: '28', // Possible values: 1-28 and "last" (for last day of the month)
            repeatOnWeekday: 'monday', // Possible values: "monday", "tuesday", "wednesday", "thursday", "friday"
            endBy: 'manual', // Possible values: "manual", "date", "number-of-transfers"
            endDate, // NOTE: This field has a meaning only when "endBy" is set to "date"!
            numberOfTransfers: 12, // NOTE: This field has a meaning only when "endBy" is set to "number-of-transfers"!
          },
          categoryId: '24', // Defaults to "24" (for "Transfer between accounts").
          propertyId: null,
          notes: '',
        }}
        onSubmit={
          // eslint-disable-next-line no-unused-vars
          (values) => {
            const isOneTimeTransfer = values.transferType === 'one-time';

            if (isOneTimeTransfer) {
              createTransfer(values)
                .then(() => {
                  setCurrentStep(steps.SUCCESS);
                })
                .catch((err) => {
                  if (isOtpRequired(err)) {
                    setRequireOtp(true);
                    return;
                  }
                  showErrorToast();
                  console.error('Error submitting the form', err);
                })
                .finally(() => {
                  setXIdempotencyKey(uuidv4());
                });
            } else {
              addRecurringPayment(values)
                .then(() => {
                  setCurrentStep(steps.SUCCESS);
                })
                .catch((response) => {
                  if (isOtpRequired(response)) {
                    setRequireOtp(true);
                    return;
                  }
                  showErrorToast();
                  console.error('Error submitting the form', response);
                })
                .finally(() => {
                  setXIdempotencyKey(uuidv4());
                });
            }
          }
        }
      >
        {({ resetForm }) => (
          <Form>
            <BaselaneDrawer
              isOpen
              size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
              title="Transfer between accounts"
              closeEvent={() => {
                resetForm();
                closeDrawer();
              }}
              onOverlayClick={() => {
                resetForm();
                closeDrawer();
              }}
              closeOnOverlayClick={false}
              newDesignDrawer
              footer={
                <TransferFundsDrawerFooter
                  currentStep={currentStep}
                  goToStep={setCurrentStep}
                  isLoading={createTransferIsLoading || addRecurringPaymentIsLoading}
                  requireOtp={requireOtp}
                  setRequireOtp={setRequireOtp}
                />
              }
            >
              {componentsByStep[currentStep]}
            </BaselaneDrawer>
          </Form>
        )}
      </Formik>
      <Outlet />
    </ChakraProvider>
  );
};

export default TransferFundsDrawer;
