export const DATA_CELL_TOTAL_WIDTH = 120;
export const TOTALS_COLUMN_MIN_WIDTH = 146;
export const HEADER_COLUMN_WIDTH = 292;
export const TABS_PANELS_PADDING = 24;

export const cellStyle = {
  flexBasis: '180px',
  padding: '0',
  border: 'none !important',
  width: '100%',
  minW: '100%',
  display: 'inline-block',
  h: '36px',
  maxH: '36px',
  verticalAlign: 'middle',
};

export const dataCellStyle = {
  w: `${DATA_CELL_TOTAL_WIDTH}px`,
  minW: `${DATA_CELL_TOTAL_WIDTH}px`,
  _notLast: {
    maxW: `${DATA_CELL_TOTAL_WIDTH}px`,
  },
  h: '36px',
  maxH: '36px',
  boxSizing: 'border-box',
  flexBasis: `${DATA_CELL_TOTAL_WIDTH}px`,
  p: '0 16px 0 0',
  border: 'none !important',
};

export const dataHeaderCellStyle = {
  ...dataCellStyle,
  verticalAlign: 'top',
  _last: {
    maxW: `${DATA_CELL_TOTAL_WIDTH}px`,
    minW: `${DATA_CELL_TOTAL_WIDTH}px`,
  },
};

export const rowStyle = {
  w: '100%',
  minW: '100%',
  h: '36px',
  maxH: '36px',
  display: 'inline-block',
  _notLast: {
    borderBottomWidth: '1px',
    borderBottomColor: 'brand.darkBlue.250',
    borderBottomStyle: 'solid',
  },
};

export const headerRowStyle = {
  ...rowStyle,
  h: '56px',
  maxH: '56px',
  backgroundColor: 'brand.neutral.white',
  _notLast: {
    borderBottomColor: 'transparent !important',
  },
};

export const highlightedRowStyle = {
  ...headerRowStyle,
  h: '48px',
  maxH: '48px',
  backgroundColor: 'brand.neutral.700',
  color: 'brand.neutral.white',
  _notLast: {},
};

const sharedFontStyle = {
  letterSpacing: '0 !important',
  whiteSpace: 'nowrap',
  color: 'brand.neutral.600',
  textAlign: 'right',
  maxW: `${DATA_CELL_TOTAL_WIDTH}px`,
};

export const fontStyle = {
  ...sharedFontStyle,
  textStyle: 'xs',
  color: 'brand.blue.800A',
  py: '10px',
};

export const totalFontStyle = {
  ...fontStyle,
  color: 'brand.neutral.900',
  textStyle: 'sm-heavy',
  py: 0,
};

export const headerFontStyle = {
  ...sharedFontStyle,
  textStyle: 'xs',
  py: '10px',
  color: 'brand.neutral.700',
  textTransform: 'capitalize',
  textOverflow: 'ellipsis',
};

export const headerTopFontStyle = {
  ...sharedFontStyle,
  textStyle: 'xs',
  py: '10px',
  color: 'brand.neutral.700',
  textTransform: 'capitalize',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const headerTotalFontStyle = {
  ...totalFontStyle,
  ...headerFontStyle,
};

export const accordionHeaderCellStyle = {
  ...dataCellStyle,
  w: '100%',
  maxW: '100%',
  minW: '100%',
  p: '0',
  display: 'inline-block',
};

export const accordionHeaderDataCellStyle = {
  ...dataCellStyle,
  w: `${DATA_CELL_TOTAL_WIDTH}px`,
  maxW: `${DATA_CELL_TOTAL_WIDTH}px`,
  minW: `${DATA_CELL_TOTAL_WIDTH}px`,
  display: 'inline-block',
  textTransform: 'capitalize',
};

export const highlightedHeaderCellStyle = {
  ...accordionHeaderCellStyle,
};

export const highlightedDataCellStyle = {
  ...accordionHeaderDataCellStyle,
};

export const accordionHeaderRowStyle = {
  ...headerRowStyle,
  backgroundColor: 'brand.blue.50',
  height: '48px',
  maxHeight: '48px',
  flexWrap: 'nowrap',
  p: 0,
};

export const accordionHeaderFontStyle = {
  ...headerFontStyle,
  textAlign: 'left',
  textStyle: 'sm',
  p: '14px 0 14px 14px',
};

export const highlightedHeaderFontStyle = {
  ...headerFontStyle,
  textAlign: 'left',
  textStyle: 'sm',
  p: '14px 0 14px 14px',
  color: 'brand.neutral.white',
};

export const accordionPanelStyle = {
  w: '100%',
  minW: '100%',
  display: 'block',
  p: 0,
};

export const headerColumnFontStyle = (isIndented) => {
  return {
    ...headerFontStyle,
    textAlign: 'left',
    color: 'brand.neutral.700',
    textTransform: 'capitalize',
    pl: isIndented ? '32px' : '16px',
  };
};

export const headerColumnTotalFontStyle = {
  ...headerColumnFontStyle(false),
  textStyle: 'sm-heavy',
  color: 'brand.neutral.700',
  textTransform: 'uppercase',
};

export const headerColumnStyles = {
  flex: '0 1 auto',
  width: `${HEADER_COLUMN_WIDTH}px`,
  minW: `${HEADER_COLUMN_WIDTH}px`,
  m: '0 !important',
  boxSizing: 'border-box',
  position: 'relative',
  gap: 0,
};

export const dataColumnStyles = {
  flex: '1',
  overflowX: 'auto',
  overflowY: 'hidden',
  alignItems: 'flex-start',
  m: '0 !important',
  boxSizing: 'border-box',
  position: 'relative',
  gap: 0,
};

export const totalsColumnStyles = (width = TOTALS_COLUMN_MIN_WIDTH) => {
  return {
    flexGrow: '0 1 auto',
    flexShrink: '0',
    w: `${width}px`,
    minW: `${width}px`,
    m: '0 !important',
    boxSizing: 'border-box',
    position: 'relative',
    gap: 0,
  };
};

export const tableStyles = (isHeader = false) => ({
  m: isHeader ? '0 !important' : '0 0 16px 0 !important',
  flexGrow: '0',
  width: '100%',
  border: 'none !important',
});

export const pseudoBorder = (direction = 'left', offset = 0) => {
  const borderStyle =
    direction === 'left'
      ? {
          borderLeftWidth: '1px',
          borderLeftColor: 'brand.darkBlue.250',
          borderLeftStyle: 'solid',
          left: `${offset}px`,
        }
      : {
          borderRightWidth: '1px',
          borderRightColor: 'brand.darkBlue.250',
          borderRightStyle: 'solid',
          right: `${offset}px`,
        };

  return {
    _before: {
      content: '""',
      position: 'absolute',
      display: 'block',
      h: '100%',
      w: '0px',
      zIndex: '1',
      ...borderStyle,
    },
  };
};

export const fixedHeaderContainerStyles = {
  position: 'fixed',
  backgroundColor: 'brand.neutral.white',
  zIndex: '3',
};

export const fixedHeaderStyles = {
  alignItems: 'flex-start',
  backgroundColor: 'brand.neutral.white',
  zIndex: '3',
  m: '0 !important',
  borderBottomWidth: '1px',
  borderBottomColor: 'brand.darkBlue.250',
  borderBottomStyle: 'solid',
  gap: 0,
};
