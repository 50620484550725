import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Box,
  Text,
  MenuDivider,
} from '@chakra-ui/react';
import { Icon16ChevronDown, Icon16ChevronUp } from '@icons/16px';
import { BaselaneButtonIcon } from '../BaselaneButtonIcon';
import BaselaneMenuProfileButton from './BaselaneMenuProfileButton';
import BaselaneButton from '../BaselaneButton';
import BaselaneChip from '../BaselaneChip';
import { menuStyles } from './menu.styles';

type BaselaneMenuProps = {
  id: String,
  label: String,
  onClick: Function,
  listItems: Array<any>,
  customButton: String,
  buttonVariant: String,
  buttonPalette: String,
  buttonSize: String,
  buttonIcon: ReactComponent,
  placement: String,
  isProfile: Boolean,
  isDisabled?: Boolean,
  profilePhotoUrl: String,
  initial: String,
  styles: Object,
};

function BaselaneMenu({
  id,
  label,
  onClick,
  listItems,
  customButton,
  buttonVariant = 'filled',
  buttonPalette = 'primary',
  buttonSize = 'md',
  buttonIcon = null,
  placement = 'bottom-start',
  isProfile = false,
  isDisabled = false,
  profilePhotoUrl = '',
  initial = '',
  styles = {},
}: BaselaneMenuProps) {
  const { divider, list, item: itemStyle } = menuStyles ?? {};
  const isButtonIcon = buttonIcon !== null;
  const typeOfCustomButton =
    customButton === 'BaselaneButtonIcon' ? BaselaneButtonIcon : BaselaneButton;
  return (
    <Menu placement={placement} autoSelect={false} gutter={6}>
      {({ isOpen }) => (
        <>
          {isProfile ? (
            <BaselaneMenuProfileButton
              isOpen={isOpen}
              label={label}
              initial={initial}
              profilePhotoUrl={profilePhotoUrl}
            />
          ) : (
            <MenuButton
              id={id}
              data-cy={id}
              isActive={isOpen}
              isDisabled={isDisabled}
              as={typeOfCustomButton}
              variant={buttonVariant}
              palette={buttonPalette}
              size={buttonSize}
              rightIcon={!isButtonIcon && (isOpen ? <Icon16ChevronUp /> : <Icon16ChevronDown />)}
              icon={buttonIcon}
              onClick={onClick}
              {...styles?.button}
            >
              {!isButtonIcon && label}
            </MenuButton>
          )}
          <MenuList {...{ ...list, ...styles?.list }}>
            {listItems?.map((item, index) => (
              <Box key={item?.id || index}>
                <MenuItem
                  {...itemStyle(item?.isDisabled)}
                  data-cy={item?.['data-cy']}
                  as={item?.as}
                  href={item?.href}
                  target={item?.target}
                  onClick={item?.onClick}
                  bg={item?.isSelected ? 'brand.blue.100 !important' : item?.bg}
                  closeOnSelect={item?.closeOnSelect}
                  isDisabled={item?.isDisabled}
                >
                  {item?.renderChildern ? (
                    item.renderChildern
                  ) : (
                    <Stack direction="isHorizontal" alignItems="top" gap={1.5}>
                      {item?.icon && (
                        <Box w="16px" paddingRight={1.5} paddingY="0.5">
                          {item?.icon}
                        </Box>
                      )}
                      <Box>
                        <Text textStyle="sm" lineHeight="24px">
                          {item.name}
                        </Text>
                        {item?.description && (
                          <Text textStyle="xs" color="brand.neutral.600">
                            {item?.description}
                          </Text>
                        )}
                      </Box>
                      {item?.hasNew && (
                        <Box paddingLeft="8px" paddingTop="2px">
                          <BaselaneChip size="sm" variant="simple-primary" label="New" />
                        </Box>
                      )}
                    </Stack>
                  )}
                </MenuItem>
                {item?.hasDivider && <MenuDivider {...divider} />}
              </Box>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
}

export default BaselaneMenu;
