import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Text, Checkbox, VStack, Skeleton, Heading, useToast } from '@chakra-ui/react';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import {
  BaselaneDrawer,
  BaselaneAlertNew,
  BaselaneDivider,
  BaselaneSpinner,
} from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { Icon16Info } from '@icons/16px';
import { UNIFIED_RENT_COLLECTION } from '@core/constants/routes';
import { GET_TENANT_SCREENINGS } from '@core/apollo/queries';
import {
  GET_TENANT_SCREENING_BY_ID,
  PROCESS_APPLICATION_DECISION,
} from '@core/apollo/queries/tenantScreening';
import Footer from './Footer';
import ApproveSuccess from './ApproveSuccess';
import { REPORT_STATUS_KEYS } from '../../helpers/tenantScreeningDetail.helpers';
import {
  APPLICATION_ACTION,
  getFormattedReasons,
  reasonInitialValues,
} from '../../helpers/tenantScreeningDecision.helpers';

import MultiReasonForm from './MultiReasonForm';
import ApplicationDetails from './ApplicationDetails';
import useProperties from '../../hooks/useProperties';

const ConditionallyApprove = () => {
  const { isMax576 } = useBreakPoints();
  const navigate = useNavigate();

  const [stopAcceptingApplicants, setStopAcceptingApplicants] = useState(true);
  const [isApproved, setIsApproved] = useState(false);
  const [unitId, setUnitId] = useState(null);

  const params = useParams();

  const { screeningId, applicationId, applicantId } = params;

  const [processApplicationDecision, { loading: loadingApproval }] = useMutation(
    PROCESS_APPLICATION_DECISION,
    {
      refetchQueries: [
        {
          // reload screenings to update
          query: GET_TENANT_SCREENINGS,
          variables: { input: { applicationLinkActive: null } },
        },
        {
          // reload page in background to update bannner
          fetchPolicy: 'network-only',
          query: GET_TENANT_SCREENING_BY_ID,
          variables: { id: Number(screeningId) },
        },
      ],
    }
  );

  const { data: screeningData, loading } = useQuery(GET_TENANT_SCREENING_BY_ID, {
    fetchPolicy: 'cache-first',
    variables: { id: Number(screeningId) },
  });

  const {
    getTenantScreeningById: { applications, propertyName, unitName },
  } = screeningData || { getTenantScreeningById: {} };

  const application = applications?.find((app) => app.id === applicationId);
  const { applicants } = application || {};

  const applicant = applicants?.find((applicantItem) => applicantItem?.id === applicantId);

  const { data: propertyData, getUnitIdFromScreeningId } = useProperties();

  const toast = useToast();

  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Something went wrong. Please try again.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  // dynamically generated footer, based on step and state of UI
  const renderFooter = (handleSubmit, other, otherReason) => {
    const isApproveDisabled =
      (!!other && (!otherReason || otherReason.length < 1)) || loadingApproval;

    return !isApproved ? (
      <Footer
        previousLabel="Cancel"
        onPrevious={() => navigate('..')}
        nextLabel="Conditionally approve"
        onNext={isApproveDisabled ? null : handleSubmit}
      />
    ) : (
      <Footer
        previousLabel="Not now"
        onPrevious={() => navigate('..')}
        nextLabel="Continue"
        onNext={() => navigate(`${UNIFIED_RENT_COLLECTION}${unitId && `?unit=${unitId}`}`)}
      />
    );
  };

  useEffect(() => {
    if (propertyData) {
      const properties = propertyData?.property ?? [];
      const currentUnitId = getUnitIdFromScreeningId(screeningId, properties);
      if (currentUnitId) {
        setUnitId(currentUnitId);
      }
    }
  }, [propertyData]);

  // generate display name
  useEffect(() => {
    if (application?.applicationStatus === REPORT_STATUS_KEYS.APPROVED_CONDITIONALLY)
      setIsApproved(true);
  }, [application]);

  const handleSubmitForm = (values) => {
    // action for conditionally approving application
    const action = APPLICATION_ACTION.CONDITIONALLY_APPROVE;
    const acceptNewApplicant = !stopAcceptingApplicants;
    // formatted reasons and "other" reason if needed
    const { reason, otherReason } = getFormattedReasons(values);
    // assemble the query inputs
    const input = {
      action,
      acceptNewApplicant,
      reason,
      ...(!!values.other && { otherReason }),
    };

    processApplicationDecision({
      variables: {
        applicationId: Number(applicationId),
        input,
      },
    })
      .then((res) => {
        if (res.errors) {
          showErrorToast();
        } else {
          setIsApproved(true);
        }
      })
      .catch((err) => {
        showErrorToast();
      });
  };

  return (
    <Formik onSubmit={handleSubmitForm} initialValues={reasonInitialValues}>
      {({ values, handleSubmit }) => (
        <BaselaneDrawer
          title="Conditionally approve application"
          size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
          isOpen
          onClose={() => navigate(-1)}
          newDesignDrawer
          footer={renderFooter(handleSubmit, values?.other, values?.otherReason)}
        >
          {!isApproved ? (
            <>
              {loadingApproval && <BaselaneSpinner />}
              {!loadingApproval && (
                <VStack gap={2} alignItems="flex-start">
                  <BaselaneAlertNew
                    variant="primary"
                    visual="icon"
                    iconName={Icon16Info}
                    title="Conditional approval"
                    body="If you’re approving with conditions (e.g. higher security deposit), please communicate them with the applicant(s)."
                  />
                  {/* Application info */}
                  <Skeleton isLoaded={!loading} w="full">
                    <ApplicationDetails
                      applicant={applicant}
                      applicants={applicants}
                      propertyName={propertyName}
                      unitName={unitName}
                    />
                  </Skeleton>
                  <BaselaneDivider my={1} />
                  <VStack alignItems="flex-start" justifyContent="flex-start">
                    <Checkbox
                      alignItems="flex-start"
                      isChecked={stopAcceptingApplicants}
                      onChange={(e) => {
                        setStopAcceptingApplicants(e.target.checked);
                      }}
                    >
                      <Text textStyle="sm" mt="-2px">
                        Stop accepting new applicants
                      </Text>
                      <Text textStyle="xs">
                        You can change this setting or set up a new screening anytime
                      </Text>
                    </Checkbox>
                  </VStack>
                  <BaselaneDivider my={1} />
                  {/* Reasons for declining form */}
                  <VStack gap={0.5} alignItems="flex-start" justifyContent="flex-start">
                    <Heading as="h3" size="headline-md">
                      Reason(s) for applying conditions
                    </Heading>
                    <Text textStyle="sm">
                      The applicant{applicants?.length > 1 ? 's' : ''} will get an email with the
                      selected reasons.
                    </Text>
                  </VStack>
                  <MultiReasonForm />
                </VStack>
              )}
            </>
          ) : (
            // Step 2
            <ApproveSuccess isConditional />
          )}
        </BaselaneDrawer>
      )}
    </Formik>
  );
};

export default ConditionallyApprove;
