// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=16096%3A174721
import React, { useContext, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Text,
  Stack,
  useToast,
  ChakraProvider,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import BanksContext from '@contexts/BanksContext';
import { BaselaneButton, BaselaneDrawer, BaselaneSelect } from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import stripCurrency from '@core/utils/stripCurrency';
import { currencyMask } from '@core/utils/masks/index';
import onDrawerClose from '@core/utils/onDrawerClose';
import SlLoader from '@core/components/Loader';
import habitatTheme from '@core/themeHabitat';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { useUnitOtp } from '@core/contexts/UnitOtpContext';
import RedeemSuccess from './RedeemSuccess';
import { GET_CARD_SUMMARY, REDEEM_CASHBACK } from '../../../../queries';
import { getFormattedCurrency } from '../helpers/currency.helpers';
import { getAccounts, menuOptionsConverter, MIN_REDEMPTION_AMOUNT } from './helpers/redeem.helpers';
import {
  redeemDrawerFormStyles,
  availableCashBackStyles,
  transferAmountLabelStyles,
  redeemToStyles,
} from './styles/redeem.styles';

function Redeem({ from }: { from: string }): any {
  const navigate = useNavigate();
  const { baselaneAccounts, refetchBankSummary } = useContext(BanksContext);

  // Unit OTP (only handles error if BE sends it)
  const { forceVerifyUnitOtp, isOtpRequired, ignore } = useUnitOtp();

  const bankIdFromAccount = (selectedId) => {
    let bankId;
    accounts.forEach((account) => {
      if (account?.id === selectedId) {
        bankId = account?.institution?.id;
      } else if (account?.subAccounts?.some((subAccount) => subAccount?.id === selectedId)) {
        bankId = account.institution.id;
      }
    });
    return bankId;
  };

  // API
  const { data, loading } = useQuery(GET_CARD_SUMMARY, {
    variables: {
      page: 1,
      pageSize: 99,
      filter: {
        includeHiddenCards: false,
      },
    },
  });

  const { cardSummary } = data || {};
  const { cardFinancials } = cardSummary || {};
  const { ytdCashBack } = cardFinancials || 0;

  const [redeemCashBack] = useMutation(REDEEM_CASHBACK, {
    refetchQueries: [
      {
        query: GET_CARD_SUMMARY,
        variables: {
          page: 1,
          pageSize: 99,
          filter: {
            includeHiddenCards: false,
          },
        },
        options: {
          awaitRefetchQueries: true,
        },
      },
    ],
  });

  const initialValues = { selectedRedeemTo: null, redemptionAmount: null };

  // Redeem Success Drawer
  const redeemSuccessDrawerRef = useRef(null);
  const onOpenRedeemSuccessDrawer = () => redeemSuccessDrawerRef.current?.open();
  const onCloseRedeemSuccessDrawer = () => {
    redeemSuccessDrawerRef.current?.close();
    onDrawerClose(navigate, from);
  };

  // Toaster
  const toast = useToast();
  const showErrorToast = () =>
    toast({
      description: 'Cashback redemption failed',
      status: 'error',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  const accounts = getAccounts(baselaneAccounts);
  const hasAccounts = !!accounts.length;
  const menuOptions = hasAccounts && menuOptionsConverter(accounts);

  const handleError = (errors) => {
    console.error(errors);
    // Show Toaster w/ Error Message
    showErrorToast();
  };

  const handleValidation = (values) => {
    const { selectedRedeemTo = null, redemptionAmount = 0 } = values ?? {};
    const errors = {};

    const redemptionAmountInNum = stripCurrency(redemptionAmount);

    // Validation for redeem to dropdown
    if (!selectedRedeemTo) errors.selectedRedeemTo = undefined;

    // Validations for redemption amount input
    if (redemptionAmountInNum === 0 || redemptionAmountInNum === null)
      errors.redemptionAmount = undefined;

    if (!!redemptionAmountInNum && redemptionAmountInNum < MIN_REDEMPTION_AMOUNT) {
      errors.redemptionAmount = 'Redemption amount has to be greater than $10.00';
    }

    if (redemptionAmountInNum > ytdCashBack) {
      errors.redemptionAmount = 'Redemption amount can not be greater than your total Cash Back.';
    }

    return errors;
  };

  const handleFormSubmit = (values) => {
    const { selectedRedeemTo, redemptionAmount } = values;
    const selectedRedeemToInNum = Number(selectedRedeemTo.id);
    const redemptionAmountInNum = stripCurrency(redemptionAmount);
    performCashbackRedemption(selectedRedeemToInNum, redemptionAmountInNum);
  };

  const performCashbackRedemption = (selectedRedeemToInNum, redemptionAmountInNum) => {
    redeemCashBack({
      variables: { accountId: selectedRedeemToInNum, amount: redemptionAmountInNum },
    })
      .then((response) => {
        const hasError = response?.errors?.length > 0;

        if (hasError) {
          if (isOtpRequired(response?.errors)) {
            const bankId = bankIdFromAccount(selectedRedeemToInNum.toString());
            forceVerifyUnitOtp(bankId)
              .then(() => performCashbackRedemption(selectedRedeemToInNum, redemptionAmountInNum))
              .catch(ignore);
          } else {
            handleError(response?.errors);
          }
        } else {
          // to be replace with better fix. This is added because when we call refetch
          // data is not updated properly yet
          setTimeout(() => {
            refetchBankSummary();
          }, 4500);
          const { amount } = response.data.cashbackRedemption ?? {};

          // Open Success Drawer
          onOpenRedeemSuccessDrawer();

          // Send Segment Events after Success
          sendSegmentEvent('baselane_bank_cashback_redeemed', {
            amount,
            remaining_balance: ytdCashBack - amount,
          });
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const { isMax767 } = useBreakPoints();

  return (
    <ChakraProvider theme={habitatTheme}>
      {/* Redeem Drawer */}

      <Formik
        initialValues={initialValues}
        validate={handleValidation}
        onSubmit={handleFormSubmit}
        validateOnMount
      >
        {({ values, handleSubmit, errors, handleChange, handleBlur, setFieldValue, isValid }) => (
          <BaselaneDrawer
            newDesignDrawer
            title="Redeem Cash Back"
            closeEvent={() => onDrawerClose(navigate, from)}
            isOpen
            size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
            footer={
              <BaselaneButton
                variant="filled"
                palette="primary"
                size="md"
                onClick={handleSubmit}
                isDisabled={!isValid}
              >
                Redeem Cash Back
              </BaselaneButton>
            }
          >
            <form noValidate style={redeemDrawerFormStyles}>
              {/* Transfer Amount */}
              <Stack spacing="1.5">
                <Text {...transferAmountLabelStyles}>Total Available Cash Back</Text>
                <Box {...availableCashBackStyles}>
                  {loading ? <SlLoader /> : getFormattedCurrency(ytdCashBack)}
                </Box>
              </Stack>

              {/* Redeem To */}
              <FormControl {...redeemToStyles} isRequired>
                <FormLabel htmlFor="selectedRedeemTo">Redeem To</FormLabel>
                <BaselaneSelect
                  id="selectedRedeemTo"
                  name="selectedRedeemTo"
                  menuOptions={menuOptions}
                  placeholder="Select Account"
                  selectedOption={values.selectedRedeemTo}
                  setSelectedOption={(selectedOption) =>
                    setFieldValue('selectedRedeemTo', selectedOption)
                  }
                  isDisabled={!hasAccounts}
                  itemStyles={{ h: '36px' }}
                  groupItemStyles={{ h: '36px' }}
                />
              </FormControl>

              {/* Redemption Amount */}
              <FormControl isInvalid={errors.redemptionAmount} isRequired>
                <FormLabel htmlFor="redemptionAmount">Redemption Amount</FormLabel>
                <Input
                  id="redemptionAmount"
                  name="redemptionAmount"
                  placeholder="$"
                  as={MaskedInput}
                  value={values.redemptionAmount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  mask={currencyMask()}
                  size="lg"
                />
                <FormErrorMessage>{errors.redemptionAmount}</FormErrorMessage>
              </FormControl>
            </form>
          </BaselaneDrawer>
        )}
      </Formik>

      {/* Redeem Success Drawer */}
      <RedeemSuccess
        redeemSuccessDrawerRef={redeemSuccessDrawerRef}
        onCloseRedeemSuccessDrawer={onCloseRedeemSuccessDrawer}
      />
    </ChakraProvider>
  );
}

export default Redeem;
