import React from 'react';
import { useFormikContext } from 'formik';
import { Heading, Text, Checkbox, FormControl, Box } from '@chakra-ui/react';

import { v4 as uuidv4 } from 'uuid';
import {
  BaselaneSummaryCard,
  BaselaneSummaryCardDefaultHeader,
  BaselaneSummaryCardListGroup,
  BaselaneSummaryCardListItem,
  BaselaneLink,
  BaselaneAlertNew,
} from '@shared/components';
import { Icon16Info } from '@icons/16px';
import formatCurrency from '@core/utils/formatCurrency';
import useProperties from '../../hooks/useProperties';
import { REPORT_PRICES, PRICE_MAP } from '../tenantScreening.helpers';

const ReviewScreening = ({
  showErrorBanner,
  isEditDrawer,
}: {
  showErrorBanner: Boolean,
  isEditDrawer: Boolean,
}) => {
  const { values, handleBlur, handleChange, errors } = useFormikContext();
  const { data } = useProperties();
  const properties = data?.property ?? [];
  const getSelectedProperty = () => {
    const selected = properties.find((property) => property.id === values?.selectedProperty);
    return selected?.name;
  };

  const getSelectedUnit = () => {
    const selected = properties
      .find((property) => property.id === values?.selectedProperty)
      ?.units?.find((unit) => unit.id === values?.selectedUnit);
    return selected?.name;
  };
  const getPropertyTitle = () => {
    const property = getSelectedProperty();
    const unit = getSelectedUnit();
    return unit ? `${property} - ${unit}` : `${property}`;
  };

  const calculateTotalCost = () => {
    let totalCost = REPORT_PRICES.BASIC_REPORTING;
    totalCost += values?.criminalReport ? PRICE_MAP.criminalReport : 0;
    totalCost += values?.evictionReport ? PRICE_MAP.evictionReport : 0;
    totalCost += values?.incomeVerification ? PRICE_MAP.incomeVerification : 0;
    return formatCurrency(totalCost).inDollars;
  };
  return (
    <>
      {showErrorBanner && (
        <BaselaneAlertNew
          variant="danger"
          title="Failed to setup screening"
          body="Please try again or contact support if the issue persists"
          visual="icon"
          iconName={Icon16Info}
          mb={1}
        />
      )}
      <Heading size="headline-md" mb={2}>
        Review details
      </Heading>
      <BaselaneSummaryCard
        {...{
          header: (
            <BaselaneSummaryCardDefaultHeader
              {...{
                title: getPropertyTitle(),
              }}
            />
          ),
          list: [
            {
              id: uuidv4(),
              items: [
                <BaselaneSummaryCardListGroup>
                  <BaselaneSummaryCardListItem
                    label="Basic reporting (Rental application, ID verification, credit report)"
                    value={formatCurrency(REPORT_PRICES.BASIC_REPORTING).inDollars}
                  />
                  <BaselaneSummaryCardListItem
                    label="Income verification"
                    value={formatCurrency(REPORT_PRICES.INCOME_VERIFICATION).inDollars}
                    isHidden={!values.incomeVerification}
                  />
                  <BaselaneSummaryCardListItem
                    label="Eviction report"
                    value={formatCurrency(REPORT_PRICES.EVICTION_REPORT).inDollars}
                    isHidden={!values.evictionReport}
                  />
                  <BaselaneSummaryCardListItem
                    label="Criminal report"
                    value={formatCurrency(REPORT_PRICES.CRIMINAL_REPORT).inDollars}
                    isHidden={!values.criminalReport}
                  />
                </BaselaneSummaryCardListGroup>,
                <BaselaneSummaryCardListGroup isDividerHidden>
                  <BaselaneSummaryCardListItem
                    label="Cost per application"
                    value={calculateTotalCost()}
                  />
                  <BaselaneSummaryCardListItem label="Paid by" value="Applicant" />
                </BaselaneSummaryCardListGroup>,
              ],
            },
          ],
        }}
      />
      {!isEditDrawer && (
        <Box mt={3}>
          <FormControl isInvalid={errors.term_one}>
            <Checkbox
              id="term_one"
              className="primary-500"
              name="term_one"
              isChecked={values.attested}
              onBlur={handleBlur}
              onChange={handleChange}
              display="flex"
              alignItems="flex-start"
            >
              <Box display="inline-block" lineHeight="1.2" mt={-0.5}>
                <Text textStyle="sm" mt={0}>
                  I understand that some reports are subject to federal, state, and local laws which
                  may limit or restrict the ability to return some records, and I agree to be
                  compliant with these laws when ordering or using these reports. For more
                  details,&nbsp;
                  <BaselaneLink
                    palette="neutral"
                    size="md"
                    href="https://support.baselane.com/hc/en-us/articles/31743083143451-Can-Baselane-help-me-screen-potential-tenants"
                    target="_blank"
                  >
                    see this article
                  </BaselaneLink>
                  .
                </Text>
              </Box>
            </Checkbox>
          </FormControl>
          <FormControl isInvalid={errors.term_two}>
            <Checkbox
              id="term_two"
              className="primary-500"
              name="term_two"
              isChecked={values.term_two}
              onBlur={handleBlur}
              onChange={handleChange}
              display="flex"
              alignItems="flex-start"
            >
              <Box display="inline-block" lineHeight="1.2" mt={-0.5}>
                <Text textStyle="sm" mt={0}>
                  I understand that Baselane is not allowed to store the credit report for more than
                  30 days.
                </Text>
              </Box>
            </Checkbox>
          </FormControl>
          <FormControl isInvalid={errors.term_three}>
            <Checkbox
              id="term_three"
              className="primary-500"
              name="term_three"
              isChecked={values.term_three}
              onBlur={handleBlur}
              onChange={handleChange}
              display="flex"
              alignItems="flex-start"
            >
              <Box display="inline-block" lineHeight="1.2" mt={-0.5}>
                <Text textStyle="sm" mt={0}>
                  I agree to&nbsp;
                  <BaselaneLink
                    palette="neutral"
                    size="md"
                    href="https://www.baselane.com/payment-policy/"
                    target="_blank"
                  >
                    Baselane’s terms
                  </BaselaneLink>
                  &nbsp;related to Tenant Screening services (section 5.2)
                </Text>
              </Box>
            </Checkbox>
          </FormControl>
        </Box>
      )}
    </>
  );
};

export default ReviewScreening;
