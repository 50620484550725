// @flow
import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useMediaQuery,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { IllustrationOtherInsights } from '@core/components/Illustrations';
import BaselaneButton from '../BaselaneButton';
import {
  modalContentStyles,
  modalHeaderStyles,
  modalBodyStyles,
  modalFooterStyles,
  primaryBtnStyles,
  secondaryBtnStyles,
} from './styles/modal.style';

type ButtonProps = {
  label: string,
  props: Object,
};

type BaselaneModalProps = {
  defaultOpen: boolean,
  btnText: string,
  btnVariant?: string,
  btnSize?: string,
  btnClick?: Function,
  onCloseBtnClick?: Function,
  modalHeadingStyles?: Object,
  modalTitleStyles?: Object,
  modalDescriptionStyles?: Object,
  modalContentStyles?: Object,
  modalBodyStyles?: Object,
  modalFooterStyles?: Object,
  closeOnOverlayClick: boolean,
  showCloseButton: boolean,
  closeBtnId?: string,
  id?: string,
  showHeader: boolean,
  showFooter: boolean,
  modalTitle: string,
  children?: Object,
  shouldBeClosed?: boolean,
  shouldBeOpened?: boolean,
  hasNoOverlay?: boolean,
  isCentered?: boolean,
  size?: string,
  palette?: String,
  hideButton?: boolean,
  description?: string,
  icon?: Function,
  primaryButton?: ButtonProps,
  secondaryButton?: ButtonProps,
  noIcon?: boolean,
};

function BaselaneModal({
  defaultOpen,
  btnText,
  btnVariant: variant,
  btnSize,
  btnClick,
  palette,
  onCloseBtnClick,
  modalHeadingStyles: mHeadingStyles,
  modalTitleStyles: mTitleStyles,
  modalDescriptionStyles: mDescriptionStyles,
  modalContentStyles: mContentStyles,
  modalBodyStyles: mBodyStyles,
  modalFooterStyles: mFooterStyles,
  closeOnOverlayClick,
  showCloseButton,
  closeBtnId,
  id,
  showHeader,
  showFooter,
  modalTitle,
  children,
  shouldBeClosed,
  shouldBeOpened,
  hasNoOverlay,
  isCentered,
  size,
  hideButton,
  description,
  icon,
  primaryButton,
  secondaryButton,
  noIcon,
}: BaselaneModalProps): any {
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: defaultOpen || shouldBeClosed,
  });

  const [isLandscape] = useMediaQuery(
    '(max-width: 824px) and (max-height: 541px) and (orientation: landscape)',
    {
      ssr: false,
    }
  );

  let paddingVal = showFooter ? 3 : 4;

  if (isMobile) {
    paddingVal = showFooter ? 2 : 3;
  }

  const buttonClick = () => {
    if (btnClick !== null) {
      onOpen();
      btnClick();
    } else {
      onOpen();
    }
  };

  useEffect(() => {
    if (shouldBeClosed) {
      onClose();
    }
  }, [shouldBeClosed]);

  useEffect(() => {
    if (shouldBeOpened) {
      onOpen();
    }
  }, [shouldBeOpened]);

  const handleClose = () => {
    onCloseBtnClick();
    onClose();
  };

  return (
    <>
      {!defaultOpen && !hideButton && (
        <BaselaneButton variant={variant} palette={palette} size={btnSize} onClick={buttonClick}>
          {btnText || 'Open'}
        </BaselaneButton>
      )}

      <Modal
        {...{ isOpen, isCentered }}
        onClose={handleClose}
        closeOnOverlayClick={closeOnOverlayClick}
        size={size}
        id={id}
      >
        {!hasNoOverlay && <ModalOverlay />}

        <ModalContent {...{ ...modalContentStyles(isLandscape), ...mContentStyles }}>
          {showHeader && (
            <Box p={paddingVal} {...{ ...modalHeaderStyles, ...mHeadingStyles }}>
              {!noIcon && <Box>{icon || <IllustrationOtherInsights />}</Box>}
              <ModalHeader p={0} {...mTitleStyles}>
                {modalTitle || ''}
              </ModalHeader>
              <Text fontSize="14px" lineHeight="20px" {...mDescriptionStyles}>
                {description}
              </Text>
            </Box>
          )}
          {showCloseButton && <ModalCloseButton id={closeBtnId} />}

          <ModalBody
            p={paddingVal}
            sx={showHeader && { pt: '0 !important' }}
            {...modalBodyStyles}
            {...mBodyStyles}
          >
            {children || null}
          </ModalBody>

          {showFooter ? (
            <ModalFooter w="100%" px={paddingVal} {...{ ...modalFooterStyles, ...mFooterStyles }}>
              {secondaryButton && (
                <Button {...{ ...secondaryBtnStyles, ...secondaryButton.props }}>
                  {secondaryButton.label}
                </Button>
              )}
              {primaryButton && (
                <Button {...{ ...primaryBtnStyles, ...primaryButton.props }}>
                  {primaryButton.label}
                </Button>
              )}
            </ModalFooter>
          ) : (
            (primaryButton || secondaryButton) && (
              <Flex px={paddingVal} pb={paddingVal} gap={1.5} justifyContent="space-between">
                {secondaryButton && (
                  <Button w="50%" {...{ ...secondaryBtnStyles, ...secondaryButton.props }}>
                    {secondaryButton.label}
                  </Button>
                )}
                {primaryButton && (
                  <Button w="50%" {...{ ...primaryBtnStyles, ...primaryButton.props }}>
                    {primaryButton.label}
                  </Button>
                )}
              </Flex>
            )
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

BaselaneModal.defaultProps = {
  children: null,
  shouldBeClosed: false,
  shouldBeOpened: false,
  hasNoOverlay: false,
  btnSize: 'md',
  btnVariant: 'filled',
  modalTitleStyles: ({}: { ... }),
  modalContentStyles: ({}: { ... }),
  modalBodyStyles: ({}: { ... }),
  btnClick: null,
  isCentered: true,
  size: 'xl',
  palette: 'primary',
  onCloseBtnClick: () => {},
  hideButton: false,
};

export default BaselaneModal;
