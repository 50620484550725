import React, { Fragment } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Flex,
  Image,
  Link,
  VStack,
  HStack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useStatsigClient } from '@statsig/react-bindings';
import { Icon16ChevronRight } from '@icons/16px';
import { DYNAMIC_CONFIGS } from '@core/constants/statsigKeys';
import { referralHowToArray, referralOnePlatformArray } from '../../helper/referralDetails.helper';
import familyHouse from '../../assets/family-house.png';
import {
  signupOnePlatformContainerStyles,
  signupHowToContainerStyles,
  signupHowToColumnContainerStyles,
  signupHowToTitleStyles,
  signupTermsLinkContainerStyles,
  signupTermsLinkTextStyles,
  signupReferralStepStyles,
  signupReferralStepTextStyles,
  signupOnePlatformColumnStyles,
  signupOnePlatformTitleStyles,
  signupOnePlatformSubtitleStyles,
  signupOnePlatformContentContainerStyles,
  signupOnePlatformColorBoxStyles,
  signupOnePlatformColorBoxTitleStyles,
  signupOnePlatformColorBoxTextStyles,
  signupOnePlatformPhotoOverlayStyles,
  signupOnePlatformPhotoOverlayTitleStyles,
  signupOnePlatformPhotoOverlayTextStyles,
} from '../../styles/signup.styles';

const ReferralDetails = () => {
  const [isDesktop] = useMediaQuery('(min-width: 1280px)', { ssr: false });

  const { getDynamicConfig } = useStatsigClient();
  const config = getDynamicConfig(DYNAMIC_CONFIGS.APY_INTEREST);

  const referralOnePlatformArrayInfo = referralOnePlatformArray(config?.get('apy'));

  return (
    <>
      <VStack {...signupHowToContainerStyles(isDesktop)}>
        <VStack {...signupHowToColumnContainerStyles}>
          <Box mb={{ base: '40px', md: '56px' }}>
            <Text {...signupHowToTitleStyles}>How it works</Text>
          </Box>
          <Flex direction={{ base: 'column', md: 'row' }} justifyContent="space-between" gap="24px">
            {referralHowToArray.map((step, index) => (
              <Flex
                direction="column"
                w={{ base: '100%', md: '30%' }}
                gap="16px"
                alignItems="start"
                key={step.id}
              >
                <Box {...signupReferralStepStyles}>{index + 1}</Box>
                <Flex direction="column" justifyContent="center">
                  <Text {...signupReferralStepTextStyles}>{step.text}</Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
          <Flex {...signupTermsLinkContainerStyles}>
            <Link
              href="https://www.baselane.com/baselane-rent-collection-referral-program-terms-conditions/"
              isExternal
            >
              <Text {...signupTermsLinkTextStyles}>View detailed terms</Text>
            </Link>
            <Icon16ChevronRight />
          </Flex>
        </VStack>
      </VStack>

      <VStack {...signupOnePlatformContainerStyles}>
        <VStack {...signupOnePlatformColumnStyles}>
          <Box mb={{ base: '24px', md: '56px' }}>
            <Text {...signupOnePlatformTitleStyles} mb={{ base: '16px', xl: '8px' }}>
              One platform for all your property banking & finances
            </Text>
            <Text {...signupOnePlatformSubtitleStyles}>
              Baselane&apos;s powerful financial tools put you in complete control of your rental
              finances.
            </Text>
          </Box>
          <HStack {...signupOnePlatformContentContainerStyles(isDesktop)}>
            {isDesktop && (
              <VStack width="100%" justifyContent="space-between" height="504px">
                {referralOnePlatformArrayInfo.map((item) => (
                  <HStack {...signupOnePlatformColorBoxStyles} bg={item.bg} key={item.id}>
                    <Flex mt="4px" minWidth="28px" justifyContent="center">
                      <Image
                        {...{
                          w: 'auto',
                          h: '28px',
                        }}
                        src={item.icon}
                        srcSet={`${item.iconLarge} 2x`}
                        alt={item.alt}
                      />
                    </Flex>
                    <VStack alignItems="left" ml="0 !important">
                      <Text {...signupOnePlatformColorBoxTitleStyles}>{item.title}</Text>
                      <Text {...signupOnePlatformColorBoxTextStyles}>{item.subtext}</Text>
                    </VStack>
                  </HStack>
                ))}
              </VStack>
            )}
            <Box position="relative" w="100%" h="100%" m="0 !important">
              <Image
                src={familyHouse}
                alt="Baselane, the solution for Landlords AND Tenants."
                w={{ base: 'calc(100% - 56px)', xl: '100%' }}
                borderRadius="8px"
                h={{ base: '100%', xl: '504px' }}
              />
              {!isDesktop && (
                <Accordion {...signupOnePlatformPhotoOverlayStyles} defaultIndex={[0]}>
                  {referralOnePlatformArrayInfo.map((item) => (
                    <Fragment key={item.id}>
                      <AccordionItem w="100%" alignItems="top" border="none !important">
                        {() => (
                          <>
                            <AccordionButton
                              padding="0 !important"
                              boxShadow="none !important"
                              mt="8px"
                            >
                              <Flex mr="8px" minWidth="28px" justifyContent="center">
                                <Image
                                  {...{
                                    w: 'auto',
                                    h: '20px',
                                  }}
                                  src={item.icon}
                                  alt={item.alt}
                                />
                              </Flex>
                              <VStack alignItems="left" ml="0 !important">
                                <Text {...signupOnePlatformPhotoOverlayTitleStyles}>
                                  {item.title}
                                </Text>
                              </VStack>
                            </AccordionButton>
                            <AccordionPanel {...signupOnePlatformPhotoOverlayTextStyles}>
                              {item.subtext}
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    </Fragment>
                  ))}
                </Accordion>
              )}
            </Box>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export default ReferralDetails;
