import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Center, Box } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { tableHeaderData } from './helpers/compareTable.helpers';
import { tdStyles, textStyles } from './styles/compareTable.styles';

type CompareTableProps = {
  title: string,
  mobileTitle: string,
  description: string,
  buttonProps: string,
  tableData: array,
};

function CompareTable({
  title,
  mobileTitle,
  description,
  buttonProps,
  tableData,
}: CompareTableProps) {
  const { isMin899: isDesktop } = useBreakPoints();

  return (
    <Table variant="unstyled">
      <Thead>
        <Tr backgroundColor="brand.neutral.100">
          {tableHeaderData.map((headerItem, index) => (
            <Th
              key={uuidv4()}
              align={headerItem.align}
              borderBottom="1px solid #F0F2F6"
              {...headerItem.styles}
              backgroundColor={index === 1 ? 'brand.neutral.100' : '#FFFFFF'}
            >
              {isDesktop ? headerItem.label : headerItem.mobileLabel}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {tableData?.map((row, rowIndex) => (
          <Tr key={uuidv4()}>
            {Object.values(row).map((value, colIndex) => (
              <Td
                key={uuidv4()}
                borderBottom="1px solid #F0F2F6"
                align={colIndex === 0 ? 'left' : 'center'}
                {...tdStyles}
                backgroundColor={colIndex === 1 ? 'brand.neutral.100' : '#FFFFFF'}
              >
                {colIndex === 0 ? (
                  <Box as="span" {...textStyles}>
                    {value}
                  </Box>
                ) : (
                  <Center {...textStyles}>{value}</Center>
                )}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

export default CompareTable;
