import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import { REFERRAL } from '@routes';
import {
  AlertHeader,
  BaselaneAlert,
  BaselaneButton,
  BaselaneButtonGroup,
  BaselaneCardNew,
} from '@shared/components';
import useCurrentWorkspace from '@shared/hooks/useCurrentWorkspace';
import IllustrationReferral from '@icons/manual/IllustrationReferral';
import { ReactComponent as Celebrate } from '@icons/32px/Celebrate.svg';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { UPDATE_USER_METADATA } from '@core/components/UserProfilePage/queries';

const ReferralPopup = () => {
  const [dismissLoading, setDismissLoading] = useState(false);
  const [continueLoading, setContinueLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();

  const [updateUserMetadata] = useMutation(UPDATE_USER_METADATA);
  const { workspaceMetadata } = useCurrentWorkspace();
  const { metadata } = workspaceMetadata || {};

  const updateReferralFlag = async () => {
    await updateUserMetadata({
      variables: { metadata: { ...metadata, hideReferralModal: true } },
      update: (cache, { data: { updateUser: updatedUser } }) => {
        cache.modify({
          id: cache.identify(updatedUser),
          fields: {
            metadata: () => updatedUser?.metadata,
          },
        });
      },
    }).catch((err) => console.debug(err));
  };

  const dismissPopup = () => {
    setDismissLoading(true);
    updateReferralFlag().then(() => {
      sendSegmentEvent('referral_modal_click_maybe_later', {
        goal_type: 'first_rc_received',
      });
    });
  };

  const referLandlord = () => {
    setContinueLoading(true);
    updateReferralFlag().then(() => {
      sendSegmentEvent('referral_modal_click_refer_now', {
        goal_type: 'first_rc_received',
      });
      navigate({ pathname: REFERRAL });
    });
  };

  useEffect(() => {
    if (!initialized) {
      sendSegmentEvent('referral_modal_pop_up');
      setInitialized(true);
    }
  }, [initialized]);

  return (
    <BaselaneAlert
      isOpen
      showCloseButton
      size="md"
      onClose={dismissPopup}
      header={
        <AlertHeader
          icon={<Celebrate />}
          title="Your first tenant payment is here"
          subtitle="Congratulations! Your payment has been deposited in your bank account."
          iconBgStyles={{ bg: 'transparent' }}
          iconContainerStyles={{ color: 'brand.neutral.900' }}
          isHorizontalCenter={false}
          isInline={false}
        />
      }
      body={
        <BaselaneCardNew size="md" variant="normal" palette="primary" width="100%">
          <Stack spacing={2} width="100%">
            <Stack direction="row" spacing={2}>
              <Box>
                <IllustrationReferral />
              </Box>
              <Stack spacing={0.5}>
                <Heading size="headline-md">Refer a landlord and earn $100</Heading>
                <Text textStyle="sm">
                  Earn $100 for every landlord you invite when they collect rent into their Baselane
                  Banking account. They get $100 too.
                </Text>
              </Stack>
            </Stack>
            <BaselaneButtonGroup size="sm">
              <BaselaneButton
                size="md"
                variant="outline"
                palette="neutral"
                isLoading={dismissLoading}
                isDisabled={continueLoading}
                onClick={() => dismissPopup()}
                flex="1"
              >
                Maybe later
              </BaselaneButton>
              <BaselaneButton
                size="md"
                variant="filled"
                palette="primary"
                isLoading={continueLoading}
                isDisabled={dismissLoading}
                onClick={() => referLandlord()}
                flex="1"
              >
                Refer now
              </BaselaneButton>
            </BaselaneButtonGroup>
          </Stack>
        </BaselaneCardNew>
      }
    />
  );
};

export default ReferralPopup;
