import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { DateTime } from 'luxon';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { Box, Stack, useDisclosure } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { useStatsigClient } from '@statsig/react-bindings';
import UserContext from '@contexts/UserContext';
import { EmailOtpProvider } from '@core/contexts/EmailOtpContext';
import {
  TRANSACTIONS,
  NB_ACCOUNTS,
  DASHBOARD,
  ANALYTICS_REPORTING,
  HOME,
  LEASES,
  GET_STARTED,
  BOOKKEEPING_RULES,
} from '@routes';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentPageEvent from '@core/utils/sendSegmentEvent/sendSegmentPageEvent';

import LandlordTaxModal from '@core/components/TaxModal/components/LandlordTaxModal';
import LandLordTaxBanner from '@core/components/TaxModal/components/LandLordTaxBanner';
import useTaxData from '@core/components/TaxModal/hooks/useTaxData';
/**
 * Note: There are sections of logic and components commented out below.
 * These were disabled on purpose as the fetchData function was implemented
 * in a way that we can no longer support.  The commented code is left below
 * so that when a new implementation is determined we can refactor it to work again.
 */

// import MissingUnitDataPopup from '@core/components/CDD/components/MissingUnitDataPopup';
// import { GET_BANK_SUMMARY } from '@core/components/CDD/queries/bank';
// import { fetchData } from '@core/components/CDD/helpers/dataCollection.helpers';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { GET_TENANT_SCREENINGS } from '@core/apollo/queries/tenantScreening';
import { useBanks, useCurrentWorkspace } from '@core/components/Shared/hooks';
import UnitDataBanner from '@core/components/CDD/components/UnitDataBanner';
import UnitDataPopup from '@core/components/CDD/components/UnitDataPopup';
import useGuideComplete from '@pages/Dashboard/SetupGuide/useGuideComplete.hook';
import { GET_PROPERTIES_PROMOTION } from '@core/pages/AutoTaggingRulesPage/queries/property-queries';
import { GET_AUTOTAG_RULES } from '@core/pages/AutoTaggingRulesPage/queries/autotag-queries';
import { getActionableProperties } from '@core/pages/AutoTaggingRulesPage/helpers/autotaggingRules.helpers';
import { ACTIVE_LEASES } from './queries/leases';
import { TRANSACTION_ALERTS, UnreadTransactionAlertInput } from './queries/alerts';
import ACHFeePromotionBanner from './ACHFeePromotionBanner';
import AutoTaggingGatingBanner from './AutoTaggingGatingBanner';
import AutoTaggingPropertyBanner from './AutoTaggingPropertyBanner';
import EmailVerificationBanner from './EmailVerificationBanner';
import GuideCompleteBanner from './GuideCompleteBanner';
import KYCBanner from './KYCBanner';
import QuickPayAnnounceBanner from './QuickPayAnnounceBanner';
import TransactionImportBanner from './TransactionImportBanner';

const Banner = () => {
  const { pathname = '' } = useLocation();

  const { checkGate } = useStatsigClient();

  const {
    user,
    emailVerified,
    isDesktopCheckDepositOpen,
    isTabletCheckDepositOpen,
    isMobileCheckDepositOpen,
  } = useContext(UserContext);
  const { workspaceMetadata } = useCurrentWorkspace();

  const { metadata } = workspaceMetadata ?? {};
  const { accountsLoading, accountsError, accountsData } = useBanks({});

  const { isGuideComplete, isGuideDismissed, isGuideLoading } = useGuideComplete();
  const showGuideCompleteBanner =
    checkGate(FEATURE_GATES.DASHBOARD_GATE) &&
    pathname.includes(DASHBOARD) &&
    isGuideComplete &&
    !isGuideLoading &&
    !isGuideDismissed;

  const connectedBaselaneAccounts = accountsData?.bank?.filter((bankAccount) => {
    const { isConnected, isExternal, unitAccount } = bankAccount;
    return isConnected && !isExternal && unitAccount?.unitApplicationStatus === 'COMPLETED';
  });

  const hasAllAccountsClosed = () => {
    if (!accountsLoading && !accountsError && accountsData) {
      const accounts = accountsData?.bank.flatMap((b) => b.bankAccounts || []);
      const subAccounts = accounts?.flatMap((a) => a?.subAccounts || []);
      const mergedAccounts = [...accounts, ...subAccounts];
      const closedAccounts = mergedAccounts.filter(
        (acc) => acc.bankPartner === 'BRB' && acc.accountStatus === 'Closed'
      );
      return mergedAccounts.filter((a) => a.bankPartner === 'BRB').length === closedAccounts.length;
    }
    return false;
  };

  useEffect(() => {
    hasAllAccountsClosed();
  }, [accountsData]);
  const { isMin899, isMin768: isDesktop } = useBreakPoints();

  // Landlord Tax Missing Data
  const { taxData } = useTaxData();
  const [isLandlordTaxModalOpen, setIsLandlordTaxModalOpen] = useState(false);
  // Landlord Tax Alert State
  const { onOpen: onLandlordTaxModalOpen } = useDisclosure();

  const onLandlordTaxModalClose = () => {
    setIsLandlordTaxModalOpen(false);
  };
  // CDD start
  // UnitMissingDataListPopup Alert State
  const {
    isOpen: isMissingUnitDataListPopupOpen,
    onOpen: onMissingUnitDataListPopupOpen,
  } = useDisclosure();

  // UnitDataPopup Alert State
  const {
    isOpen: isUnitDataPopupOpen,
    onOpen: onUnitDataPopupOpen,
    onClose: onUnitDataPopupClose,
  } = useDisclosure();

  // data collection popup
  const [banksThatNeedDataCollection /* , setBanksThatNeedDataCollection */] = useState([]);
  // const [isLoadingDataCollectionList, setIsLoadingDataCollectionList] = useState(true);
  const [daysToExpire, setDaysToExpire] = useState('');
  const [isExpired, setIsExpired] = useState(true);

  const [tenantScreenings, setTenantScreenings] = useState(null);

  /* const { data: bankSummaryData } = useQuery(GET_BANK_SUMMARY, {
    variables: { isExternal: false },
  }); */
  // ------------ CDD end

  const { data, loading, error } = useQuery(ACTIVE_LEASES);

  useEffect(() => {
    sendSegmentPageEvent();
  }, [pathname]);

  const { userKycStatus = null } = useCurrentWorkspace();
  const { userPromotion } = user ?? {};
  const { leaseDetail } = data || {};
  const { activeLease = 0 } = leaseDetail?.summary || {};

  const achFeePromotion = userPromotion?.find(
    (p) => p.promotion.type === 'FEES_WAIVER' && p.promotion.feeType === 'ACH_FEE'
  );

  const todaysDate = moment(new Date().toDateString());
  const userAchFeePromoStartDate = moment(achFeePromotion?.startDate);
  const userAchFeePromoEndDate = moment(achFeePromotion?.endDate);

  const isUserEligibleForAchFeePromo =
    achFeePromotion?.promotion &&
    todaysDate < userAchFeePromoEndDate &&
    todaysDate >= userAchFeePromoStartDate &&
    !leaseDetail?.isEmpty;

  const {
    data: transactionAlertsData,
    loading: transactionAlertsLoading,
    error: transactionAlertsError,
    refetch: transactionAlertsRefetch,
  } = useQuery(TRANSACTION_ALERTS, {
    fetchPolicy: 'no-cache',
    variables: { input: UnreadTransactionAlertInput },
  });

  const { alert } = transactionAlertsData || {}; // quick test data { alert: [ { id: '4' } ] };
  const { id: transactionAlertsId } = alert?.length > 0 ? alert[0] : {};

  const transactionImportBannerPages = [
    TRANSACTIONS,
    NB_ACCOUNTS,
    DASHBOARD,
    ANALYTICS_REPORTING,
    HOME,
    GET_STARTED,
  ];

  const shouldShowTransactionImportBanner = transactionImportBannerPages.some((page) =>
    pathname.includes(page)
  );

  const showTransactionImportBanner =
    !checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) &&
    shouldShowTransactionImportBanner &&
    !!transactionAlertsId &&
    !transactionAlertsLoading &&
    !transactionAlertsError;

  const showACHFeePromotionBanner =
    pathname.includes(LEASES) &&
    !metadata?.hideACHFeePromotionBanner &&
    isUserEligibleForAchFeePromo;

  const showQuickPayAnnounceBanner =
    checkGate(FEATURE_GATES.QUICK_PAY_ANNOUNCE_BANNER_GATE) &&
    pathname.includes(LEASES) &&
    localStorage.getItem('isQuickPayAnnounceBannerClosed') !== 'true';

  // CDD start
  // call unit data collection api to see if any baselane banks have incomplete information
  /*
    Note: fetchData is currently broken, this useEffect is
    intentionally disabled.

  useEffect(() => {
      const bankData = bankSummaryData?.bankSummary?.bank ?? [];

      if (bankData.length > 0) {
        console.debug('fetching information');
        fetchData({
          bankData,
          setBanksThatNeedDataCollection,
          setIsLoadingDataCollectionList,
        });
      }
    }, [bankSummaryData?.bankSummary?.bank]); */

  const handleRemindMeLaterClick = () => {
    onUnitDataPopupClose();
    localStorage.setItem('DCPopupFlag', false);
  };

  const handleLandlordTaxModalClick = () => {
    onLandlordTaxModalClose();
    localStorage.setItem('TaxPopupFlag', false);
  };

  const handleLandlordTaxModalRemindMeLaterClick = () => {
    onLandlordTaxModalClose();
    localStorage.setItem('TaxPopupFlag', false);
  };

  const handleUnitDataPopupClick = () => {
    onUnitDataPopupClose();
    onMissingUnitDataListPopupOpen();
    localStorage.setItem('DCPopupFlag', false);
  };

  /*
    Note: fetchData is currently broken, this is
    intentionally disabled.

    const handleMissingUnitDataListClose = () => {
    onMissingUnitDataListPopupClose();
    fetchData({
      bankData: bankSummaryData?.bankSummary?.bank ?? [],
      setBanksThatNeedDataCollection,
      setIsLoadingDataCollectionList,
    });
  }; */

  /*   const onRefreshStatus = () => {
    const bankData = bankSummaryData?.bankSummary?.bank ?? [];
    fetchData({
      bankData,
      setBanksThatNeedDataCollection,
      setIsLoadingDataCollectionList,
    });
  }; */

  useEffect(() => {
    const showUnitPopup = localStorage.getItem('DCPopupFlag');

    // setting expiration date
    const startDate = moment(Date.now()).utcOffset('-0400').format('YYYY-MM-DD HH:mm');
    const endDate = moment('2023-12-15 17:00');
    const isWarningExpired = endDate.diff(startDate, 'minutes');
    setDaysToExpire(endDate.diff(startDate, 'days'));
    setIsExpired(isWarningExpired);

    if (
      metadata?.hideBankMigrationSuccessModal &&
      isWarningExpired > 0 &&
      banksThatNeedDataCollection.length > 0 &&
      !isMissingUnitDataListPopupOpen &&
      !isUnitDataPopupOpen &&
      showUnitPopup !== 'false'
    ) {
      onUnitDataPopupOpen();
    }

    onLandlordTaxModalOpen();
  }, [user, banksThatNeedDataCollection, metadata]);
  // -------- CDD end

  const expirationDay = DateTime.fromISO('2024-12-31T23:59:00', { zone: 'UTC' }).setZone('UTC-4');
  // until overdue day we show the banner with count down days
  const overdueDay = DateTime.fromISO('2025-01-31T23:59:00', { zone: 'UTC' }).setZone('UTC-4');
  const hasMissingData = taxData?.length > 0;

  const today = DateTime.now();

  const isTaxModalExpired = today > overdueDay;

  useEffect(() => {
    const hasShownPopup = sessionStorage.getItem('TaxPopupFlag');
    if (
      !isTaxModalExpired &&
      taxData &&
      taxData.length > 0 && // Ensure there is tax data
      !hasShownPopup // Only show if the flag is not set
    ) {
      setIsLandlordTaxModalOpen(true);
      sessionStorage.setItem('TaxPopupFlag', 'true'); // Set the flag
    }
  }, [taxData]);

  /**
   * Note: Due to the Entities page containing a carousel that requires
   * no padding surrounding the scrollable cards, this flag exists to
   * remove said padding.
   */
  const isEntitiesPage = pathname.includes('/banking/accounts/baselane_');
  const isNewDashboardPage =
    pathname.includes(DASHBOARD) && checkGate(FEATURE_GATES.DASHBOARD_GATE);
  const isTransactionsMobilePage = pathname.includes('/transactions') && isMobile;
  const isExternalAccountsMobilePage = pathname.includes('/externalaccounts') && isMobile;
  const isPropertiesMobilePage = pathname.includes('/properties') && isMobile;
  const showMobileBanner =
    (!isDesktop && !isDesktopCheckDepositOpen) ||
    isMobileCheckDepositOpen ||
    isTabletCheckDepositOpen;

  // fetch all screenings, active or inactive
  const { data: tenantScreeningData } = useQuery(GET_TENANT_SCREENINGS, {
    fetchPolicy: 'network-only',
    variables: { input: { applicationLinkActive: null } },
  });

  useEffect(() => {
    const { getTenantScreenings: screenings } = tenantScreeningData || [];
    if (screenings) setTenantScreenings(screenings);
  }, [tenantScreeningData]);

  const hasTenantScreenings = tenantScreenings?.length > 0;
  const hasActiveLeases = !loading && !error && activeLease > 0;

  const showKYCBanner =
    !isPropertiesMobilePage &&
    !isExternalAccountsMobilePage &&
    !isTransactionsMobilePage &&
    !isEntitiesPage &&
    userKycStatus !== 'APPROVED' &&
    (hasActiveLeases || hasTenantScreenings);

  /**
   * Autotagging properties promotion
   */

  const [actionableProperties, setActionableProperties] = useState(null);
  const { data: propertiesData } = useQuery(GET_PROPERTIES_PROMOTION, {
    fetchPolicy: 'cache-first',
  });

  const { data: autoTagRulesData } = useQuery(GET_AUTOTAG_RULES, {
    variables: {
      filter: { isPropertyOrUnitRule: true },
    },
  });

  useEffect(() => {
    if (propertiesData && autoTagRulesData) {
      setActionableProperties(getActionableProperties(propertiesData, autoTagRulesData));
    }
  }, [propertiesData, autoTagRulesData]);

  return (
    <>
      {/* LandlordTaxModal modals */}
      <LandlordTaxModal
        isOpen={isLandlordTaxModalOpen}
        onClose={onLandlordTaxModalClose}
        handleLandlordTaxModalClick={handleLandlordTaxModalClick}
        handleLandlordTaxModalRemindMeLaterClick={handleLandlordTaxModalRemindMeLaterClick}
        expirationDay={expirationDay}
        overdueDay={overdueDay}
      />

      {/* CDD Popups */}
      <UnitDataPopup
        {...{
          isUnitDataPopupOpen,
          onUnitDataPopupClose,
          handleUnitDataPopupClick,
          handleRemindMeLaterClick,
          daysToExpire,
        }}
      />
      {/*

      Note: fetchData is currently broken, this component is
      intentionally disabled.

      <MissingUnitDataPopup
        {...{
          isMissingUnitDataListPopupOpen,
          handleMissingUnitDataListClose,
          banksThatNeedDataCollection,
          isLoadingDataCollectionList,
          onRefreshStatus,
        }}
      /> */}

      <Stack
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        {checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) &&
          pathname.includes(BOOKKEEPING_RULES) &&
          (!connectedBaselaneAccounts || connectedBaselaneAccounts.length < 1) && (
            <AutoTaggingGatingBanner />
          )}

        {checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) &&
          pathname.includes(BOOKKEEPING_RULES) &&
          !!connectedBaselaneAccounts &&
          connectedBaselaneAccounts.length > 0 &&
          actionableProperties?.length > 0 && (
            <AutoTaggingPropertyBanner unlinkedPropertiesCount={actionableProperties?.length} />
          )}

        {/* Missing Tax Data Banner */}
        {!isTaxModalExpired && hasMissingData && (
          <LandLordTaxBanner
            handleCTAClick={() => setIsLandlordTaxModalOpen(true)}
            expirationDay={expirationDay}
            overdueDay={overdueDay}
          />
        )}
        {/* CDD Banner */}
        {isExpired > 0 &&
          banksThatNeedDataCollection &&
          banksThatNeedDataCollection?.length > 0 && (
            <UnitDataBanner
              handleUnitDataPopupClick={handleUnitDataPopupClick}
              daysToExpire={daysToExpire}
            />
          )}

        {!emailVerified && connectedBaselaneAccounts?.length > 0 && (
          <EmailOtpProvider>
            <EmailVerificationBanner email={user?.email} />
          </EmailOtpProvider>
        )}
        {showACHFeePromotionBanner && <ACHFeePromotionBanner {...{ userAchFeePromoEndDate }} />}
        {showQuickPayAnnounceBanner && <QuickPayAnnounceBanner />}
        {showGuideCompleteBanner && <GuideCompleteBanner metadata={metadata} />}

        {showMobileBanner && showKYCBanner && (
          <Box
            {...{
              px: '16px',
              pt: '16px',
              position: 'relative',
              zIndex: 10,
            }}
          >
            <KYCBanner {...{ userKycStatus }} />
          </Box>
        )}
        {!showMobileBanner && (showKYCBanner || showTransactionImportBanner) && (
          <Box
            m={isDesktop && !isMin899 && '24px 16px 16px!important'}
            mx={isNewDashboardPage ? { base: 2, md: 3, '2lg': 4 } : 'inherit'}
            mt={isNewDashboardPage ? 3 : 'inherit'}
          >
            {showKYCBanner && <KYCBanner {...{ userKycStatus }} />}
            {showTransactionImportBanner && (
              <TransactionImportBanner
                id={transactionAlertsId}
                refetch={transactionAlertsRefetch}
              />
            )}
          </Box>
        )}
      </Stack>
    </>
  );
};

export default Banner;
