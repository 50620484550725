import { useState } from 'react';
import { unitApiUrl } from '@core/constants/envVars';

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const stripBase64Meta = (data) => {
  return data.split('data:image/jpeg;base64,').join('');
};

const useCheckImageUpload = () => {
  const [isLoading, setIsLoading] = useState(false);

  /**
   * @typedef UploadCheckImageParams
   * @type {Object}
   * @property {string} imageData - The base64 encoded image data.
   * @property {string} checkId - The id of the check related to the image.
   * @property {string} token - A valid token for the request.
   * @property {string} checkSide - The side of the check the image is for. Either 'front' or 'back'.
   */

  /**
   * Uploads a check image to the server.
   * @param {UploadCheckImageParams} params - The parameters as described by {@link UploadCheckImageParams}.
   * @returns {Promise<*>} Returns the response from the server after a successful upload.
   * @throws {Error} - If there is an error uploading the check image.
   */
  const uploadCheckImage = async ({ imageData, checkId, token, checkSide }) => {
    setIsLoading(true);
    const unitAPI = unitApiUrl.split('https://').join('');
    const requestUrl = `https://${unitAPI}/check-deposits/${checkId}/${checkSide}`;

    try {
      const response = await fetch(requestUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'image/jpeg',
          authorization: `Bearer ${token}`,
        },
        body: b64toBlob(stripBase64Meta(imageData), 'image/jpeg'),
      });

      setIsLoading(false);

      if (!response.ok) {
        throw new Error('Error uploading check image');
      }

      return response.json();
    } catch (error) {
      setIsLoading(false);
      throw new Error('Error uploading check image', error);
    }
  };

  return {
    uploadCheckImage,
    isLoading,
  };
};

export default useCheckImageUpload;
