import { gql } from '@apollo/client';

export const CREATE_OR_UPDATE_LEASE_AGREEMENT = gql`
  mutation createOrUpdateLeaseAgreement(
    $id: ID
    $propertyUnitId: ID
    $propertyId: ID
    $tenantProfileId: ID
    $tenantProfileMetadata: JSON
    $leaseType: LeaseType
    $startDate: String
    $endDate: String
    $amount: Float
    $rentCollectionStartDate: String
    $archived: Boolean
    $isAlreadySigned: Boolean
    $hostedPageId: String
    $inputType: LeaseAgreementInputType!
  ) {
    createOrUpdateLeaseAgreement(
      input: {
        id: $id
        propertyUnitId: $propertyUnitId
        propertyId: $propertyId
        tenantProfileId: $tenantProfileId
        tenantProfileMetadata: $tenantProfileMetadata
        leaseType: $leaseType
        startDate: $startDate
        endDate: $endDate
        amount: $amount
        rentCollectionStartDate: $rentCollectionStartDate
        archived: $archived
        isAlreadySigned: $isAlreadySigned
        hostedPageId: $hostedPageId
        inputType: $inputType
      }
    ) {
      id
      propertyUnitId
      tenantProfileId
      tenantProfileMetadata
      userId
      leaseType
      startDate
      endDate
      rentCollectionStartDate
      state
      completedTags
      archived
      amount
      serviceToken
      binderId
      interviewId
      ownerUpId
      templateId
      propertyId
      documentName
      lastEditedAt
      version
      propertyName
      propertyUnitName
      isArchived
      paymentStatus
    }
  }
`;
