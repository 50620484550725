import * as React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useLazyQuery, useMutation } from '@apollo/client';
import customTheme from '@core/theme';
import IconUIUpload from '@icons/manual/IconUIUpload';
import { IllustrationBankingApplicationawaitingdocuments } from '@illustrations';
import { DELETE_DRAFT, GET_KYC_URL } from '@core/components/NativeBankingPage/queries';
import BaselaneResponsiveBanner from './BaselaneResponsiveBanner';
import BaselaneResponsiveConfirmDeleteAlert from '../BaselaneResponsiveConfirmDeleteAlert';

type BaselaneResponsiveAccountAwaitingDocumentsBannerProps = {
  /**
   * @prop {Object} - Unit application object that contains data used to populate banner
   */
  unitApplication: Object,
  /**
   * @prop {Function} - Function that reloads application banner data after a delete
   */
  refetchBankList: Function,
};

/**
 * Renders a responsive banner for accounts in the 'awaiting documents' state.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveAccountAwaitingDocumentsBanner = ({
  unitApplication,
  refetchBankList,
  ...rest
}: BaselaneResponsiveAccountAwaitingDocumentsBannerProps) => {
  const { id: bankId, name: bankName } = unitApplication;

  const [getKYCUrl] = useLazyQuery(GET_KYC_URL, {
    onCompleted: ({ unitOnBoarding }) => {
      window.open(unitOnBoarding, '_blank');
    },
    fetchPolicy: 'no-cache',
  });

  const handleDocumentUploadClick = () => {
    getKYCUrl({ variables: { bankId } });
  };

  // TODO: This needs to be replaced with a "Soft Delete" Mutation once available
  // currently, it is not possible to delete an awaiting documents application
  const [deleteDraft] = useMutation(DELETE_DRAFT, {
    variables: { id: Number(bankId) },
    onCompleted: () => refetchBankList(),
    onError: (error) => console.error(error),
  });

  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: onConfirmDeleteOpen,
    onClose: onConfirmDeleteClose,
  } = useDisclosure();

  const handleConfirmDelete = () => {
    deleteDraft();
  };

  return (
    <>
      <BaselaneResponsiveBanner
        icon={<IllustrationBankingApplicationawaitingdocuments />}
        indicatorColor={customTheme.colors.yellow['100']}
        title={bankName}
        description="Almost there! As a final step, we require documentation to approve your application."
        onDismissClick={onConfirmDeleteOpen}
        dismissLabel="Delete"
        callToActionLeftIcon={<IconUIUpload w="16" h="16" />}
        callToActionLabel="Upload Documents"
        onCallToActionClick={handleDocumentUploadClick}
        {...rest}
      />
      <BaselaneResponsiveConfirmDeleteAlert
        isOpen={isConfirmDeleteOpen}
        onClose={onConfirmDeleteClose}
        onConfirmDeleteClick={() => {
          handleConfirmDelete();
        }}
        deletePromptText="Are you sure you want to delete the application?"
        confirmButtonLabel="Yes, Delete Draft"
      />
    </>
  );
};

export default BaselaneResponsiveAccountAwaitingDocumentsBanner;
