import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_LEASE_AGREEMENT } from '../queries/index';

export function useLeaseAgreement(leaseId) {
  const { data, loading, error, refetch } = useQuery(GET_LEASE_AGREEMENT, {
    variables: { id: leaseId },
    skip: !leaseId,
  });

  const leaseAgreement = useMemo(() => {
    return data?.leaseAgreement ?? null;
  }, [data]);

  return { leaseAgreement, loading, error, refetch };
}
