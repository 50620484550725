export const welcomeCardStyles = (isCarousel) => ({
  height: isCarousel ? 'auto' : '100%',
  minWidth: '0',
  border: '1px solid',
  borderColor: 'brand.darkBlue.200',
  p: '20px 24px',
  fontSize: '14px',
  lineHeight: '20px',
  maxWidth: isCarousel ? 'calc(100% - 32px)' : '365px',
  flex: isCarousel ? '0 0 100%' : 'none',
  width: 'calc((100% / 3) - 10px)',
});

export const welcomeCardContainerWrapperStyles = {
  position: 'relative',
  mt: { md: 0, base: 2 },
};

export const welcomeCustomStyles = {
  customCarouselWrapperStyles: { p: '0', pb: { base: '40px', xl: '0' }, w: '100%' },
  customSlideContainerStyles: { m: '0', gap: '8px', width: 'calc(100% - 16px)' },
  customArrowButtonContainerStyles: {
    justifyContent: { base: 'center', lg: 'space-between' },
    pt: '16px',
  },
};
