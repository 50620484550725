/**
 * These are the characteristics of the AI categorization rule.
 * The LLM rule is a special case of the autotag rule.
 * This rule is intetionally not sortable, and behaves differently from the other rules.
 */
export const AI_RULE_CHARACTERISTICS = Object.freeze({
  id: '99999999',
  description: 'LLM',
  conditions: [
    {
      type: 'BOOLEAN',
      field: 'LLM_ENABLED',
      operator: 'EQUALS',
      booleanValue: true, // ignored
      __typename: 'RuleCondition',
    },
  ],
  action: {
    tagId: null,
    propertyId: null,
    unitId: null,
    __typename: 'RuleAction',
  },
  __typename: 'AutotagRule',
});

/**
 * Used to generate the AI rule, should BE not return one from the API.
 */
export const AI_RULE_HARDCODED = {
  ...JSON.parse(JSON.stringify(AI_RULE_CHARACTERISTICS)),
  isActive: true,
};

/**
 * Checks if the rule is an AI categorization rule.
 * @param {AutotagRule} rule - The rule to check.
 * @returns {boolean} - True if the rule is an AI categorization rule, false otherwise.
 */
export const isAiCategorizationRule = (rule) => {
  return (
    rule.description === AI_RULE_CHARACTERISTICS.description &&
    rule.conditions[0].type === AI_RULE_CHARACTERISTICS.conditions[0].type &&
    rule.conditions[0].field === AI_RULE_CHARACTERISTICS.conditions[0].field &&
    rule.conditions[0].operator === AI_RULE_CHARACTERISTICS.conditions[0].operator
  );
};

export const CATEGORIZATION_TYPE = {
  NEW_AND_UNCATEGORIZED: 'newAndUncategorized',
  NEW_TRANSACTIONS_ONLY: 'newTransactionsOnly',
};
