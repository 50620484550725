import React, { useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

import { TabListComponent, TabsComponent } from '@shared/components';
import useCSVImportStore from '@store/CSVImport';

import TabListItem from './TabListItem';
import { indexToPathMapper, initialCSVTabList } from '../helpers';

const CSVFlow = () => {
  const navigate = useNavigate();

  const { CSVHeaderMapping, propertyListFromCSV, categoryListFromCSV } = useCSVImportStore(
    useShallow((state) => ({
      CSVHeaderMapping: state.CSVHeaderMapping,
      propertyListFromCSV: state.propertyListFromCSV,
      categoryListFromCSV: state.categoryListFromCSV,
    }))
  );

  const uploadFormikRef = useRef();
  const columnsFormikRef = useRef();
  const mapCategoriesFormikRef = useRef();
  const mapPropertiesFormikRef = useRef();

  const formikRefs = [
    uploadFormikRef,
    columnsFormikRef,
    mapCategoriesFormikRef,
    mapPropertiesFormikRef,
  ];

  const [tabIndex, setTabIndex] = useState(0);
  const [CSVTabList, setCSVTabList] = useState(initialCSVTabList);

  // Helper functions below for navigation logic and tablist state
  const handleDisableTab = (optionalTabIndex, shouldDisable = false) => {
    setCSVTabList((prevState) => {
      const clonedPreviousState = [...prevState];

      clonedPreviousState.splice(optionalTabIndex, 1, {
        ...clonedPreviousState[optionalTabIndex],
        isDisabled: shouldDisable,
        isValid: !shouldDisable,
      });
      return clonedPreviousState;
    });
  };

  const handleValidTab = (isContinue) => {
    setCSVTabList((prevState) => {
      const clonedPreviousState = [...prevState];
      if (isContinue) {
        clonedPreviousState.splice(tabIndex, 1, {
          ...clonedPreviousState[tabIndex],
          isValid: true,
        });
      }

      return clonedPreviousState;
    });
  };

  const handleOptionalTabNavigation = (isContinue, newIndex) => {
    let updatedIndex = newIndex;
    const { Category, Property } = CSVHeaderMapping;

    const categoryIsMappedAndHasData = Category && categoryListFromCSV?.length > 0;
    const propertyIsMappedAndHasData = Property && propertyListFromCSV?.length > 0;

    // always enable/disable optional tabs depending on if there is data + mapping
    handleDisableTab(2, !categoryIsMappedAndHasData);
    handleDisableTab(3, !propertyIsMappedAndHasData);

    // updating index
    if (!categoryIsMappedAndHasData && !propertyIsMappedAndHasData) {
      updatedIndex = isContinue ? newIndex + 2 : newIndex - 2;
    } else if (newIndex === 2 && !categoryIsMappedAndHasData) {
      updatedIndex = isContinue ? newIndex + 1 : newIndex - 1;
    } else if (newIndex === 3 && !propertyIsMappedAndHasData) {
      updatedIndex = isContinue ? newIndex + 1 : newIndex - 1;
    }

    handleValidTab(isContinue);
    return updatedIndex;
  };

  // Navigation with Previous and Continue buttons
  const handleFooterNavigation = (isContinue) => {
    let newIndex = isContinue ? tabIndex + 1 : tabIndex - 1;

    if (newIndex === 2 || newIndex === 3) {
      // return newIndex, if optional steps are skipped or not
      newIndex = handleOptionalTabNavigation(isContinue, newIndex);
    } else {
      handleValidTab(isContinue);
    }

    setTabIndex(newIndex);
    navigate(indexToPathMapper[newIndex]);
  };

  const handleTabClickChange = (clickedTabIndex) => {
    const { Category, Property } = CSVHeaderMapping;
    const categoryIsMappedAndHasData = Category && categoryListFromCSV?.length > 0;
    const propertyIsMappedAndHasData = Property && propertyListFromCSV?.length > 0;

    // get current active form to validate before moving to clicked tab
    const form = formikRefs[tabIndex];
    // if it is a tab with formik form
    if (form) {
      form?.current?.validateForm().then((errors) => {
        const hasNoErrors = Object.keys(errors).length === 0;
        if (hasNoErrors) {
          form?.current?.handleSubmit();
          // disable tabs if header is not mapped and there is no data
          if (clickedTabIndex === 2 && !categoryIsMappedAndHasData) {
            handleDisableTab(clickedTabIndex, true);
          } else if (clickedTabIndex === 3 && !propertyIsMappedAndHasData) {
            handleDisableTab(clickedTabIndex, true);
          } else if (clickedTabIndex === 4) {
            handleDisableTab(2, !categoryIsMappedAndHasData);
            handleDisableTab(3, !propertyIsMappedAndHasData);
            setTabIndex(clickedTabIndex);
            navigate(indexToPathMapper[clickedTabIndex]);
          } else {
            setTabIndex(clickedTabIndex);
            navigate(indexToPathMapper[clickedTabIndex]);
          }
        }
      });
    } else {
      // user clicked on from tab with no formik form
      setTabIndex(clickedTabIndex);
      navigate(indexToPathMapper[clickedTabIndex]);
    }
  };

  // Helper function to set tab isValid to false if there are any errors for the current tab in inner forms
  const handleTabValidation = () => {
    setCSVTabList((prevState) => {
      const clonedPreviousState = [...prevState];
      clonedPreviousState.splice(tabIndex, 1, {
        ...clonedPreviousState[tabIndex],
        isValid: false,
      });

      return clonedPreviousState;
    });
  };

  // TODO: to add variant for this style of tab when we work on adding override styles for tab components
  return (
    <TabsComponent
      tabIndex={tabIndex}
      handleTabChange={handleTabClickChange}
      orientation="vertical"
      styles={{ p: 0, overflow: 'hidden', display: 'flex', flexDirection: 'row' }}
    >
      <TabListComponent styles={{ pt: 6, px: 3, width: '387px' }}>
        {CSVTabList.map((tab, index) => {
          return (
            <TabListItem
              key={tab.key}
              tab={tab}
              index={index}
              CSVTabList={CSVTabList}
              tabIndex={tabIndex}
            />
          );
        })}
      </TabListComponent>

      {/*
      Using Box instead of Tab Panels because panels have logic with tabIndex so if we always have one panel the
      second "panel" get go to will be hidden because it is technically 0 when we are tabIndex of 1
      */}
      <Box minWidth="400px" overflow="hidden">
        <Outlet
          context={{
            handleFooterNavigation,
            handleTabValidation,
            setCSVTabList,
            formikRefs,
          }}
        />
      </Box>
    </TabsComponent>
  );
};

export default CSVFlow;
