import { useMutation, useQuery } from '@apollo/client';
import { GET_TDATA_RECORD, CAPTURE_USER_TDATA } from '../queries/landlordTax';

const useTaxData = () => {
  const { loading: taxDataLoading, error: taxDataError, data, refetch: taxRefetchData } = useQuery(
    GET_TDATA_RECORD
  );
  const taxData = data?.getTDataRecord;
  const [captureUserTData] = useMutation(CAPTURE_USER_TDATA);

  return {
    taxDataLoading,
    taxDataError,
    taxData,
    taxRefetchData,
    captureUserTData,
  };
};

export default useTaxData;
