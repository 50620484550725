import React from 'react';
import { Stack, Spacer, Text } from '@chakra-ui/react';
import { BaselaneSummaryCard } from '@shared/components';
import { BaselaneSummaryCardAmountHeader } from '@shared/components/BaselaneSummaryCard/Headers';

type Step2Props = {
  formikProps: Object,
  accountsMap: Object,
};

const Step2 = ({ formikProps, accountsMap }: Step2Props) => {
  const { values } = formikProps;

  const containerStyles = {
    height: 'calc(100% - 116px)',
    justifyContent: 'space-between',
    direction: 'column',
  };

  const amountValue = (value) => {
    if (value == null) {
      return 0;
    }
    if (typeof value === 'number') {
      return value;
    }
    if (typeof value === 'string') {
      return parseFloat(value?.replace(/,/g, '')) || 0;
    }
    return 0;
  };
  return (
    <Stack {...containerStyles}>
      <Stack>
        {/* First Card: amount, fromAccount, toAccount */}
        <BaselaneSummaryCard
          isCustom
          {...{
            header: (
              <BaselaneSummaryCardAmountHeader
                title="Your accounts"
                prefix="Transfer between"
                amount={amountValue(values.amount)}
              />
            ),
            list: [
              {
                items: [
                  {
                    label: 'From account',
                    value: accountsMap[values.fromTransferAccountId]?.institutionName,
                    subtext:
                      accountsMap[values.fromTransferAccountId]?.name ??
                      accountsMap[values.fromTransferAccountId]?.nickName,
                  },
                  {
                    label: 'To account',
                    value: accountsMap[values.toTransferAccountId]?.institutionName,
                    subtext:
                      accountsMap[values.toTransferAccountId]?.name ??
                      accountsMap[values.toTransferAccountId]?.nickName,
                  },
                ],
              },
            ],
          }}
        />{' '}
      </Stack>
      <Spacer />
      <Text textStyle="xs" color="brand.neutral.500">
        By clicking the &lsquo;Transfer&lsquo; button, I authorize Baselane to debit the above
        amount from my bank account on the date shown. If this transaction is for a future date, I
        acknowledge that this authorization will remain valid unless canceled through my Baselane
        online account at least 1 day before the transfer date. Once I authorize this payment, I
        understand there cannot be any changes or corrections.
      </Text>
    </Stack>
  );
};

export default Step2;
