import React from 'react';
import { useLocation } from 'react-router-dom';
import { Heading, Stack, Text } from '@chakra-ui/react';
import { LEASE_AGREEMENT } from '@core/constants/routes';
import { BaselaneAlertNew } from '@shared/components';
import { Icon16Info } from '@icons/16px';
import { useTabIndex, useTotalSteps } from '../leaseAgreementStore';

function Header({
  hasProperties,
  isUnitPanelVisible,
}: {
  hasProperties: boolean,
  isUnitPanelVisible: boolean,
}) {
  const location = useLocation();
  const { pathname } = location;
  const selectPropertyTitle = pathname.includes(LEASE_AGREEMENT)
    ? 'Select property to create lease'
    : 'Select the property to set up rent collection';
  const tabIndex = useTabIndex();
  const totalSteps = useTotalSteps();
  const textContent = [
    isUnitPanelVisible
      ? {
          stepIndicator: `STEP ${tabIndex + 1} of ${totalSteps}`,
          heading: 'Select the unit to create lease agreement document for',
          description:
            'To add or edit units, go to the Properties page, and then return to the lease agreement creation.',
        }
      : {
          stepIndicator: `STEP ${tabIndex + 1} of ${totalSteps}`,
          heading: !hasProperties ? 'Add your first property' : selectPropertyTitle,
          description:
            'For multi-unit properties, select the property, then continue to choose the unit.',
        },

    {
      stepIndicator: `STEP ${tabIndex + 1} of ${totalSteps}`,
      heading: 'Add basic lease details',
      alert: {
        title: 'Full customization in subsequent steps',
        body:
          'We will collect additional details related to late fees, pets, subletting, utilities, and more in subsequent steps to help you customize your lease agreement.',
      },
    },
    {
      stepIndicator: `STEP ${tabIndex + 1} of ${totalSteps}`,
      heading: 'Tenant Details',
      description:
        'We’ll just fill these details in the lease agreement. You can choose when to invite them to sign the lease.',
      alert: {
        title: 'Add primary tenant now',
        body: 'You can add more tenants in the subsequent steps.',
      },
    },
    {
      stepIndicator: `STEP ${tabIndex + 1} of ${totalSteps}`,
      heading: 'Review order',
    },
    {
      stepIndicator: `STEP ${tabIndex + 1} of ${totalSteps}`,
      heading: 'Create lease agreement',
      description:
        'Create your lease agreement by answering a few quick questions and send it to the tenant(s) for signing.',
    },
  ];

  const currentStep = textContent[tabIndex] || textContent[0]; // Fallback to prevent undefined errors

  return (
    <>
      <Stack gap={1} my="4">
        <Heading size="headline-xl" color="brand.neutral.900">
          {currentStep.heading}
        </Heading>
        {currentStep.description && (
          <Text textStyle="xs" color="brand.neutral.600">
            {currentStep.description}
          </Text>
        )}
      </Stack>
      {currentStep.alert && (
        <Stack mt="0" mb="4">
          <BaselaneAlertNew
            body={currentStep.alert.body}
            iconName={Icon16Info}
            title={currentStep.alert.title}
            variant="primary"
            visual="icon"
            hasCloseButton={false}
          />
        </Stack>
      )}
    </>
  );
}

export default Header;
