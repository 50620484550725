import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';
import {
  EDIT_ENTITY_ACCOUNT,
  EDIT_ENTITY_ADDRESS,
  ADD_VIRTUAL_ACCOUNT,
  ADD_VIRTUAL_CARD,
  ORDER_CHECKBOOK,
} from '@routes';
import { BaselaneMenu } from '@shared/components';
import {
  Icon16AddBankingAccount,
  Icon16AddVirtualCard,
  Icon16Edit,
  Icon16Mail,
  Icon16CheckPayment,
} from '@icons/16px';
import useBreakPoints from '@core/hooks/useBreakPoints';
import UserAccessContext from '@contexts/UserAccessContext';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import UserContext from '@core/contexts/UserContext';

const MoreMenu = () => {
  const navigate = useNavigate();
  const currLocation = useLocation();
  const { checkGate } = useStatsigClient();
  const { authorizedForBanking } = useContext(UserAccessContext);
  const { emailVerified } = useContext(UserContext);
  const { isMin768 } = useBreakPoints();

  const moreMenuListItems = [
    {
      id: 'entity_name',
      name: 'Edit entity name',
      icon: <Icon16Edit />,
      onClick: () => navigate({ pathname: `${currLocation.pathname}/${EDIT_ENTITY_ACCOUNT}` }),
    },
    {
      id: 'mailing_address',
      name: 'Edit mailing address',
      icon: <Icon16Mail />,
      onClick: () =>
        navigate({
          pathname: `${currLocation.pathname}/${EDIT_ENTITY_ACCOUNT}/${EDIT_ENTITY_ADDRESS}`,
        }),
      hasDivider: isMin768 && checkGate(FEATURE_GATES.CHECKBOOK_GATE),
    },
  ];

  const orderCheckbookListItem = {
    id: 'order_checkbook',
    name: 'Order a checkbook',
    icon: <Icon16CheckPayment />,
    onClick: () => navigate({ pathname: `${currLocation.pathname}/${ORDER_CHECKBOOK}` }),
    hasDivider: !isMin768,
    isDisabled: !emailVerified,
  };

  if (!isMin768) {
    const virtualMenuItems = [
      {
        id: 'virtual_account',
        name: 'Add virtual account',
        icon: <Icon16AddBankingAccount />,
        onClick: () => navigate({ pathname: `${currLocation.pathname}/${ADD_VIRTUAL_ACCOUNT}` }),
      },
      {
        id: 'virtual_card',
        name: 'Add virtual card',
        icon: <Icon16AddVirtualCard />,
        onClick: () => navigate({ pathname: `${currLocation.pathname}/${ADD_VIRTUAL_CARD}` }),
      },
      ...(checkGate(FEATURE_GATES.CHECKBOOK_GATE) ? [orderCheckbookListItem] : []),
    ];
    authorizedForBanking
      ? moreMenuListItems.unshift(...virtualMenuItems)
      : moreMenuListItems.unshift(...[]);
  } else if (checkGate(FEATURE_GATES.CHECKBOOK_GATE)) {
    moreMenuListItems.push(orderCheckbookListItem);
  }

  return (
    <BaselaneMenu
      label="More"
      buttonPalette="neutral"
      buttonVariant="outline"
      buttonSize={!isMin768 ? 'lg' : 'md'}
      listItems={moreMenuListItems}
    />
  );
};

export default MoreMenu;
