// @flow
import React, { useContext, useEffect, useRef, useState } from 'react';
import { HStack, Tbody, Text } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { formatDate } from '@core/utils/formatDate';
import formatCurrency from '@core/utils/formatCurrency';
import { IconExclamationCircleThin } from '@icons';
import QuickPayIconOrBadge from '@pages/LeasesPage/components/QuickPayIconOrBadge';
import {
  BaselaneResponsiveTable,
  BaselaneResponsiveCellText,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveTableHeader,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableEmptyState,
} from '@shared/components';
import InvoiceContext from '@contexts/InvoiceContext';
import customTheme from '@core/theme';
import ResendInviteButton from '@features/Tenant/ResendInviteButton';
import {
  getLeaseDuration,
  getRentCollectionRemainingTimeFor,
  getTenantDetails,
} from '../helpers/unit.helpers';
import { GET_SINGLE_LEASE } from '../queries';
import LeaseResponsiveDetail from '../LeaseResponsiveDetail';

type Props = {
  bankProps?: Object,
  hasBaselaneBank?: boolean,
  tenantData?: Object,
  tabName: string,
  sortedLeases: Array<Object>,
  invoiceListDrawerRef: any,
  selectedLeaseId: string,
  setSelectedLeaseId: Function,
  refreshLeases: Function,
};

function LeasesResponsiveList({
  bankProps = ({}: { ... }),
  hasBaselaneBank = false,
  tenantData = null,
  tabName,
  sortedLeases,
  invoiceListDrawerRef,
  selectedLeaseId,
  setSelectedLeaseId,
  refreshLeases,
}: Props): any {
  const { isMax576: showMobileUI, isMinXL: showTabletUI } = useBreakPoints();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { selectedLease, setSelectedLease, selectedUnit, selectedProperty } = useContext(
    InvoiceContext
  );

  const [fired, setFired] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const leaseDetailsDrawerRef = useRef(null);

  // Get single lease
  const [getSingleLease, { loading }] = useLazyQuery(GET_SINGLE_LEASE, {
    fetchPolicy: 'no-cache',
    skip: isInit || !selectedLeaseId || fired,
    onCompleted: ({ leases: { leases } }) => {
      if (leases?.length === 1) {
        setSelectedLease(leases[0]);
        setTimeout(() => {
          setFired(false);
        }, 500);
      }
    },
  });

  useEffect(() => {
    if (selectedLeaseId && !fired && !loading) {
      const forwardToUpdateAccounts =
        pathname.toLowerCase() === '/leases/updateaccounts' ? '/updateaccounts' : '';

      navigate(`/leases${forwardToUpdateAccounts}`, { state: { leaseId: selectedLeaseId } });
      setFired(true);
      getSingleLease({ variables: { input: { leaseId: selectedLeaseId } } });
      leaseDetailsDrawerRef?.current?.open();
    }
  }, [selectedLeaseId]);

  useEffect(() => {
    setIsInit(false);
  }, []);

  const onPropertyUnitLeaseClick = (lease, cardStatus) => {
    sendSegmentEvent('rc_page_click_rc_card', {
      leaseId: lease?.id,
      rc_state: tabName?.toLowerCase() || 'active',
      rc_card_status: cardStatus?.toLowerCase() || 'normal',
    });
    leaseDetailsDrawerRef?.current?.open();
    setSelectedLeaseId(lease?.id);
  };

  const headerItems = [
    { key: 'property', label: 'Property Nickname' },
    {
      key: 'tenant',
      label: 'Tenant',
    },
    {
      key: 'duration',
      label: 'Lease Duration',
    },
    {
      key: 'amount',
      label: 'Rent Amount',
    },
  ];

  return (
    <>
      {sortedLeases?.length > 0 && (
        <BaselaneResponsiveTable
          config={{
            columns: [
              {
                styles: {
                  width: { '2xl': '400px', xl: '360px', '2lg': '330px', lg: '280px', md: 'none' },
                  maxWidth: {
                    '2xl': '400px',
                    xl: '360px',
                    '2lg': '330px',
                    lg: '280px',
                    md: 'none',
                  },
                },
              },
              {
                styles: {
                  display: { sm: 'table-cell', base: 'none' },
                  width: { '2xl': '400px', xl: '300px', '2lg': '260px', lg: '240px', sm: 'auto' },
                  maxWidth: {
                    '2xl': '400px',
                    xl: '300px',
                    '2lg': '260px',
                    lg: '240px',
                    sm: 'none',
                  },
                },
              },
              {
                styles: {
                  display: { sm: 'table-cell', base: 'none' },
                  width: { '2xl': '400px', xl: '300px', '2lg': '260px', lg: '240px', sm: 'auto' },
                  maxWidth: {
                    '2xl': '400px',
                    xl: '300px',
                    '2lg': '260px',
                    lg: '240px',
                    sm: 'none',
                  },
                },
              },
              {
                styles: {
                  display: { '2lg': 'table-cell', base: 'none' },
                  width: { '2xl': '200px', '2lg': '160px', md: 'auto' },
                  maxWidth: { '2xl': '200px', '2lg': '160px', md: 'none' },
                  float: 'right',
                },
              },
            ],
          }}
        >
          {!showMobileUI && (
            <BaselaneResponsiveTableHeader
              items={headerItems}
              renderItem={(item, index) => (
                <BaselaneResponsiveTableHeading key={item.key} index={index} title={item.label} />
              )}
            />
          )}

          <BaselaneResponsiveTableEmptyState
            showEmptyState={sortedLeases?.length === 0}
            text="There are no leases"
          />

          <Tbody>
            <BaselaneResponsiveTableRows
              customRow
              items={sortedLeases}
              renderItem={(leaseData, index) => {
                const {
                  propertyName,
                  propertyUnitName,
                  tenantProfileId,
                  startDate,
                  endDate,
                  paymentState,
                  totalUnits,
                  rentAmount,
                  quickPay,
                } = leaseData;
                const archived = tabName?.toUpperCase() === 'ARCHIVED';

                const isOverdue = archived ? false : paymentState === 'OVERDUE';
                const isMultiUnit = totalUnits > 1;
                const isQuickPay = quickPay?.isQuickPayEligible || quickPay?.isQuickPayEnabled;

                // TODO: still need field to get tenant MonthToMonth
                const {
                  firstName = '',
                  lastName = '',
                  status: tenantStatus = '',
                  email,
                } = tenantData
                  ? getTenantDetails(tenantData?.landlordTenant, tenantProfileId, leaseData) || {}
                  : {};
                const tenantFullName = `${firstName} ${lastName}`;

                const leaseDuration = leaseData && getLeaseDuration(leaseData);

                const leaseRemainingTime = archived
                  ? 'Archived'
                  : getRentCollectionRemainingTimeFor(leaseData);
                const expired = leaseRemainingTime === 'Lease expired';

                const amount = rentAmount ? formatCurrency(rentAmount).inDollars : '\u2014';

                // TODO: calculate color ALSO for click function
                let indicatorColor = null;
                if (isOverdue) {
                  indicatorColor = customTheme.colors.red['500A'];
                }

                let cardStatus = 'normal';
                if (isOverdue) {
                  cardStatus = 'error';
                }

                const propertySubTitle =
                  ((isMultiUnit && propertyUnitName) ||
                    (!isMultiUnit && propertyUnitName && propertyUnitName !== propertyName)) &&
                  propertyUnitName;

                return (
                  /* Using a custom row, in order to set indicator and click handler */
                  <BaselaneResponsiveTableRow
                    id={propertyName?.replace(/\s/g, '')}
                    onClick={() => {
                      onPropertyUnitLeaseClick(leaseData, cardStatus);
                    }}
                    indicatorColor={indicatorColor}
                    contentContainerStyles={{
                      borderTopColor: index === 0 ? 'brand.darkBlue.100' : 'brand.neutral.white',
                    }}
                  >
                    {/* Property  */}
                    {showMobileUI ? (
                      <BaselaneResponsiveCellTitle
                        title={propertyName}
                        subtitle={propertySubTitle || <Text color="brand.neutral.white">.</Text>}
                        rightTitleElement={
                          isOverdue && (
                            <IconExclamationCircleThin color={customTheme.colors.red['900']} />
                          )
                        }
                        configIndex={0}
                        isVertical
                      >
                        <HStack>
                          <Text m="0 !important">{tenantFullName}</Text>
                          {isQuickPay && (
                            <QuickPayIconOrBadge
                              isIconOnly
                              isQuickPayEnabled={quickPay?.isQuickPayEnabled}
                            />
                          )}
                        </HStack>
                      </BaselaneResponsiveCellTitle>
                    ) : (
                      <BaselaneResponsiveCellTitle
                        title={propertyName}
                        subtitle={propertySubTitle}
                        rightTitleElement={
                          isOverdue && (
                            <IconExclamationCircleThin color={customTheme.colors.red['900']} />
                          )
                        }
                        configIndex={0}
                      />
                    )}
                    {/* Tenant  */}
                    <BaselaneResponsiveCellText
                      textObject={
                        <HStack gap="6px">
                          <Text m="0 !important">{tenantFullName}</Text>
                          {isQuickPay && (
                            <QuickPayIconOrBadge
                              isIconOnly
                              isQuickPayEnabled={quickPay?.isQuickPayEnabled}
                            />
                          )}
                        </HStack>
                      }
                      subtext={
                        !showTabletUI && tenantStatus === 'INVITE_SENT' && !archived && !expired ? (
                          <ResendInviteButton
                            tenantId={tenantProfileId}
                            variant="transparent"
                            palette="primary"
                            label="Resend Invite"
                            emailIcon
                            pullLeft
                          />
                        ) : (
                          !showTabletUI && email
                        )
                      }
                      configIndex={1}
                    />
                    {/* Lease Duration  */}
                    <BaselaneResponsiveCellText
                      text={(leaseRemainingTime ?? leaseRemainingTime) || 'Month-to-month'}
                      subtext={
                        !showTabletUI && leaseDuration === 'Month-to-month'
                          ? `${formatDate(startDate)} - ${
                              archived ? formatDate(endDate) : 'Ongoing'
                            }`
                          : !showTabletUI && leaseDuration
                      }
                      configIndex={2}
                    />
                    {/* Lease Amount  */}
                    <BaselaneResponsiveCellText text={amount} configIndex={3} />
                  </BaselaneResponsiveTableRow>
                );
              }}
            />
          </Tbody>
        </BaselaneResponsiveTable>
      )}

      <LeaseResponsiveDetail
        lease={selectedLease}
        leaseId={selectedLeaseId}
        setSelectedLeaseId={setSelectedLeaseId}
        bankProps={bankProps}
        hasBaselaneBank={hasBaselaneBank}
        invoiceListDrawerRef={invoiceListDrawerRef}
        refetchSingleLease={getSingleLease}
        unit={selectedUnit}
        property={selectedProperty}
        setLease={setSelectedLease}
        refreshLeases={refreshLeases}
        tenantData={tenantData}
        leaseDetailsDrawerRef={leaseDetailsDrawerRef}
        externalAccountCollectionCount={
          sortedLeases.filter((l) => l?.rentAndFeesBankAccount?.isExternal).length
        }
      />
    </>
  );
}

export default LeasesResponsiveList;
