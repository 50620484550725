import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Heading, VStack, HStack, Skeleton, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { useCurrentWorkspace } from '@core/components/Shared/hooks';
import { Icon24ArrowBack } from '@icons/24px';
import { BaselaneButtonIcon, BaselaneDivider } from '@shared/components';
import { TENANT_SCREENING } from '@core/constants/routes';
import ReportSummary from '../TenantScreeningReportDetails/ReportSummary';
import { pageContainerPadding } from '../styles/tenantScreening.styles';
import { REPORT_STATUS_KEYS } from '../helpers/tenantScreeningDetail.helpers';
import MakeDecision from '../TenantScreeningReportDetails/MakeDecision';
import ApplicantDropdown from '../TenantScreeningReportDetails/ApplicantDropdown';
import useApplicants from '../hooks/useApplicants';

const EXPIRATION_CUTOFF_DAYS = 30;

const ReportsSummary = () => {
  const { isMin899, isMax576 } = useBreakPoints();
  const navigate = useNavigate();
  const { userKycStatus = null } = useCurrentWorkspace();
  const { screeningId } = useParams() ?? {};

  const [isLoading, setIsLoading] = useState(true);
  const {
    screeningDataLoading,
    propertyName = '',
    unitName = '',
    application = {},
    numberOfApplicants = 0,
    applicantOptions = [],
    applicantOptionsWithSummary = [],
  } = useApplicants();

  const makeDecisionTooltipText =
    userKycStatus === 'APPROVED' ? (
      'A decision can’t be made until everyone in the group has submitted their applications.'
    ) : (
      <>
        <Text textStyle="xs-heavy">ID verification pending</Text>
        <Text textStyle="xs">Verify your identity to view the reports and make a decision.</Text>
      </>
    );

  const backToApplicationScreen = screeningId ? `/${TENANT_SCREENING}/${screeningId}/` : '/';

  useEffect(() => {
    if (!screeningDataLoading) {
      setIsLoading(false);
    }
  }, [screeningDataLoading]);

  return (
    <VStack alignItems="flex-start" w="full" gap={4} p={pageContainerPadding(isMax576, isMin899)}>
      <HStack
        sx={{
          '@media print': {
            gap: 0,
          },
        }}
        gap={2}
        w="full"
        flexWrap="wrap"
      >
        <Skeleton isLoaded={!isLoading} order={0}>
          <BaselaneButtonIcon
            icon={<Icon24ArrowBack />}
            onClick={() => navigate(backToApplicationScreen)}
            palette="neutral"
            size="lg"
            variant="outline"
            sx={{
              '@media print': {
                display: 'none',
              },
            }}
          />
        </Skeleton>

        <Box order={isMax576 ? 2 : 1} minW={isMax576 ? 'full' : 'auto'}>
          <Skeleton isLoaded={!isLoading}>
            <Heading size="headline-lg" as="h3">
              {applicantOptions[0]?.name ?? ''}
              {numberOfApplicants > 1 && `+ ${numberOfApplicants - 1}`}
            </Heading>
            <Text>{propertyName !== unitName ? unitName : ''}</Text>
          </Skeleton>
        </Box>

        <Box order={isMax576 ? 3 : 1} minW={isMax576 ? 'full' : 'auto'}>
          <Skeleton isLoaded={!isLoading} w="full" maxW={isMax576 ? 'full' : '260px'}>
            <ApplicantDropdown
              id="report-summary-dropdown"
              applicantOptions={applicantOptionsWithSummary}
              selectedApplicant={applicantOptionsWithSummary[0] ?? { id: '', name: '' }}
            />
          </Skeleton>
        </Box>

        {application?.applicationStatus &&
          ![
            REPORT_STATUS_KEYS.APPROVED,
            REPORT_STATUS_KEYS.APPROVED_CONDITIONALLY,
            REPORT_STATUS_KEYS.DECLINED,
          ].includes(application?.applicationStatus) && (
            <Box
              ml="auto"
              order={2}
              sx={{
                '@media print': {
                  display: 'none',
                },
              }}
            >
              <Skeleton isLoaded={!isLoading}>
                <MakeDecision
                  tooltipText={makeDecisionTooltipText}
                  screeningId={screeningId ?? ''}
                />
              </Skeleton>
            </Box>
          )}
      </HStack>
      <BaselaneDivider
        styles={{
          marginLeft: '-32px',
          boxSizing: 'contentBox',
          paddingRight: '64px',
          marginTop: '0',
          marginBottom: '0',
        }}
      />
      {applicantOptions.length > 0 &&
        applicantOptions.map((a) => {
          const isCreditScoreExpired =
            a.submissionDate &&
            EXPIRATION_CUTOFF_DAYS -
              Math.floor(DateTime.now().diff(DateTime.fromISO(a?.submissionDate), 'days').days) <=
              0;

          return (
            <ReportSummary key={a.id} applicant={a} creditScoreExpired={isCreditScoreExpired} />
          );
        })}
    </VStack>
  );
};

export default ReportsSummary;
