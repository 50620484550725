import React, { useRef } from 'react';
import { Stack, Text } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { AlertHeader, AlertFooter, BaselaneAlert } from '@shared/components';
import { IconHomeWarning } from '@icons';

const textStyle = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
  mt: '8px',
};
const textBoldStyle = { fontWeight: 'semiBold' };

type UnitDataPopupProps = {
  isUnitDataPopupOpen: Boolean,
  onUnitDataPopupClose: Function,
  handleUnitDataPopupClick: Function,
  handleRemindMeLaterClick: Function,
  daysToExpire: Number,
};

const UnitDataPopup = ({
  isUnitDataPopupOpen,
  onUnitDataPopupClose,
  handleUnitDataPopupClick,
  handleRemindMeLaterClick,
  daysToExpire,
}: UnitDataPopupProps) => {
  const cancelRef = useRef();
  const { isMinXL } = useBreakPoints();

  return (
    <BaselaneAlert
      {...{
        isOpen: isUnitDataPopupOpen,
        onClose: onUnitDataPopupClose,
        leastDestructiveRef: cancelRef,
        isCentered: true,
        showCloseButton: false,
        size: isMinXL ? 'sm' : 'lg',
        header: (
          <AlertHeader
            title="Information required for your Baselane Banking account(s)"
            icon={<IconHomeWarning />}
            iconBgStyles={{ backgroundColor: 'transparent' }}
            isInline={false}
            titleTextStyles={{ fontSize: isMinXL ? 'md' : '2xl' }}
          />
        ),
        body: (
          <>
            <Text {...textStyle} mt="0">
              From time to time, banking regulations require us to collect or confirm certain
              customer information.
            </Text>
            <Text {...textStyle} mt="16px">
              To prevent your account(s) from being closed, please provide this information before{' '}
              <Text {...textBoldStyle} as="span">
                Dec 15, 2023.
              </Text>
            </Text>
          </>
        ),
        footer: (
          <Stack
            direction={!isMinXL ? 'row' : 'column'}
            w="100%"
            spacing={isMinXL ? '8px' : '16px'}
          >
            <AlertFooter
              {...{
                leftButtonText: 'Complete requirements',
                rightButtonText: 'Remind me later',
                leftButtonProps: {
                  variant: 'filled',
                  palette: 'primary',
                  padding: '10px 24px!important',
                  width: isMinXL ? '100%' : 'auto',
                  size: isMinXL ? 'xl' : 'lg',
                },
                rightButtonProps: {
                  variant: 'transparent',
                  palette: 'primary',
                  width: isMinXL ? '100%' : 'auto',
                  paddingInlineStart: '20px!important',
                  paddingInlineEnd: '20px!important',
                  display: daysToExpire > 10 ? 'inline-flex' : 'none',
                  size: isMinXL ? 'xl' : 'lg',
                  id: 'remind-me-later',
                },
                leftButtonEvent: () => handleUnitDataPopupClick(),
                rightButtonEvent: () => handleRemindMeLaterClick(),
                cancelRef,
              }}
            />
          </Stack>
        ),
        bodyStyles: { m: '16px 0 0' },
        footerStyles: { m: isMinXL ? '24px 0 0' : '32px 0 0' },
        containerStyles: {
          m: '0 !important',
          p: isMinXL ? '16px!important' : '32px!important',
          borderRadius: '12px',
        },
      }}
    />
  );
};

export default UnitDataPopup;
