import React, { useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { useStatsigClient } from '@statsig/react-bindings';
import { BaselaneEmptyStateCard } from '@shared/components';
import { NATIVE_BANK } from '@routes';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import AddAccount from '../NativeBankingPage/MainContent/components/Accounts/components/AddAccount';

type EmptyStateProps = {
  noTransactionsFound: boolean,
  title: string,
  paragraph: string,
  styles?: Object,
  // used to override existing button action and
  // use the new external accounts flow
  isDirectToPlaid?: Boolean,
  onAddedPlaidSuccessfully?: Function,
  setShowImportTrxModal: Function,
};

function EmptyState({
  noTransactionsFound,
  title,
  paragraph,
  styles = {},
  isDirectToPlaid = false,
  onAddedPlaidSuccessfully = () => {},
  setShowImportTrxModal,
}: EmptyStateProps) {
  const { checkGate } = useStatsigClient();

  const onImportTrxClick = () => {
    setShowImportTrxModal(true);
    sendSegmentEvent('import_transactions_started', {
      entry_point: 'transactions_page_empty_state',
    });
  };

  const addExternalAccountDrawerRef = useRef();

  if (noTransactionsFound) {
    return (
      <BaselaneEmptyStateCard title={title} styles={{ boxShadow: 'none', h: '430px' }}>
        <BaselaneEmptyStateCard.Paragraph>{paragraph}</BaselaneEmptyStateCard.Paragraph>
      </BaselaneEmptyStateCard>
    );
  }

  return (
    <BaselaneEmptyStateCard
      title={
        checkGate(FEATURE_GATES.CSV_GATE)
          ? 'Keep all your property transactions in one place!'
          : 'Keep All Your Property Transactions in One Place'
      }
      styles={{ boxShadow: 'none', h: '350px', ...styles }}
    >
      <BaselaneEmptyStateCard.Paragraph>
        The Transactions page is your consolidated ledger for all your property transactions.
        {checkGate(FEATURE_GATES.CSV_GATE) ? <br /> : ' '}
        Keep your property finances organized by tagging transactions to Schedule E categories and
        specific properties.
      </BaselaneEmptyStateCard.Paragraph>
      {!checkGate(FEATURE_GATES.CSV_GATE) && (
        <BaselaneEmptyStateCard.Paragraph>
          Automatically import transactions by securely connecting external banks or credit cards
          with Plaid™.
        </BaselaneEmptyStateCard.Paragraph>
      )}

      {!isDirectToPlaid && (
        <BaselaneEmptyStateCard.Button route={NATIVE_BANK} variant="filled" palette="primary">
          Connect external account
        </BaselaneEmptyStateCard.Button>
      )}

      {isDirectToPlaid && checkGate(FEATURE_GATES.CSV_GATE) && (
        <BaselaneEmptyStateCard.Button
          variant="filled"
          palette="primary"
          onClick={() => onImportTrxClick()}
        >
          Import transactions
        </BaselaneEmptyStateCard.Button>
      )}
      {isDirectToPlaid && !checkGate(FEATURE_GATES.CSV_GATE) && (
        <Box position="relative">
          <AddAccount
            isDirectToPlaid
            hideButton
            isFromLease={false}
            isDirectToPlaidButtonProps={{
              variant: 'filled',
              palette: 'primary',
              size: 'lg',
            }}
            handleDrawerCloseExternally={onAddedPlaidSuccessfully}
            addExternalAccountDrawerRef={addExternalAccountDrawerRef}
            loaderStyles={{
              // override to place the loader at the center
              // of the screen as the accounts are added/reloaded
              w: '0px !important',
              h: '0px !important',
              top: '50%',
              left: '50%',
            }}
          />
        </Box>
      )}
    </BaselaneEmptyStateCard>
  );
}

export default EmptyState;
