import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HStack, Stack, Text, Box } from '@chakra-ui/react';

import { EDIT_ENTITY_ACCOUNT } from '@routes';
import {
  BaselaneButtonIcon,
  BaselaneTooltip,
  BaselaneButton,
  BaselaneDivider,
} from '@shared/components';
import { truncatedText } from '@shared/styles/text.style';
import BankEntityContext from '@contexts/BankEntityContext';
import { IconChevronLeft } from '@icons';
import formatCurrency from '@core/utils/formatCurrency';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { backButtonStyles, headerRightStyles, largeTextStyles } from '../header.styles';

const HeaderLeft = () => {
  const { account } = useContext(BankEntityContext);

  const navigate = useNavigate();
  const currLocation = useLocation();
  const { isMin1150 } = useBreakPoints();

  // dynamically setting maxWidth for better user experience with user being able to see the  whole bank name
  const [maxWidth, setMaxWidth] = useState('193px');
  const onResize = () => {
    const availableBalanceElement = document.getElementById('available-balance-element');
    const actionGroupButtons = document.getElementById('action-group-buttons');
    const availableBalanceElementX = availableBalanceElement?.getBoundingClientRect()?.x;
    const availableBalanceElementWidth = availableBalanceElement?.getBoundingClientRect()?.width;
    const actionGroupButtonsX = actionGroupButtons?.getBoundingClientRect()?.x;

    if (
      Math.round(availableBalanceElementX + availableBalanceElementWidth + 80) <
        Math.round(actionGroupButtonsX) &&
      maxWidth === '193px'
    ) {
      setMaxWidth(`${actionGroupButtonsX}px`);
    } else {
      setMaxWidth('calc(100% - 248px)');
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <HStack spacing="24px" w={!isMin1150 ? '100%' : 'calc(100% - 560px)'}>
      <BaselaneButtonIcon
        variant="outline"
        palette="neutral"
        icon={<IconChevronLeft />}
        {...backButtonStyles}
        onClick={() => navigate('/banking')}
      />

      <Stack spacing="4px" minWidth="193px" maxWidth={!isMin1150 ? 'calc(100% - 248px)' : maxWidth}>
        <BaselaneTooltip
          placement="top"
          label={account?.bankName}
          innerContainerStyles={{ ml: '0' }}
        >
          <Text {...largeTextStyles} {...truncatedText}>
            {account?.bankName}
          </Text>
        </BaselaneTooltip>
        <Box>
          <BaselaneButton
            id="baselane-account-entity-details-button"
            variant="transparent"
            palette="primary"
            size="sm"
            pullLeft
            onClick={() =>
              navigate({ pathname: `${currLocation.pathname}/${EDIT_ENTITY_ACCOUNT}` })
            }
          >
            Entity Details
          </BaselaneButton>
        </Box>
      </Stack>
      {isMin1150 && <BaselaneDivider isVertical />}
      <Stack id="available-balance-element" ml="24px" spacing="4px" minWidth="130px">
        <Text {...headerRightStyles.totalBalance.label}>Total Available Balance</Text>

        <Text
          {...headerRightStyles.totalBalance.value}
          color={account?.totalBalance < 0 ? 'red.800AA' : 'brand.neutral.700'}
        >
          {formatCurrency(account?.totalBalance).inDollars}
        </Text>
      </Stack>
    </HStack>
  );
};

export default HeaderLeft;
