import React, { useEffect } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { Box, Heading, HStack, Spacer, Text, Tbody, VStack, Skeleton } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import {
  BaselaneChip,
  BaselaneButton,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableHeader,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveCell,
} from '@shared/components';
import useLeaseAgreements from './hooks/useLeaseAgreements';
import EmptyState from './EmptyState';
import {
  leaseAgreementsListConfig,
  leaseAgreementsListTableHeaderItems,
  documentStateVariants,
  getLeaseButtonConfig,
  formatLeaseAgreementDuration,
  formatStateLabel,
} from './helpers/leaseAgreement.helper';
import NewAgreementButton from './components/NewAgreementButton';

const LeaseAgreement = () => {
  const { isMin768 } = useBreakPoints();
  const navigate = useNavigate();
  const location = useLocation();

  const { leaseAgreements, refetch, loading } = useLeaseAgreements();

  useEffect(() => {
    refetch();
  }, [location]);

  return (
    <Box w="100%" p={{ sm: 2, base: 3 }}>
      {!loading && leaseAgreements.length === 0 ? (
        <EmptyState />
      ) : (
        <>
          <HStack w="100%" pb={3}>
            <Heading size="headline-md">Your lease agreement</Heading>
            <Spacer />
            <NewAgreementButton />
          </HStack>
          <Skeleton isLoaded={!loading}>
            <BaselaneResponsiveTable id="member-list" config={leaseAgreementsListConfig}>
              <BaselaneResponsiveTableHeader
                items={leaseAgreementsListTableHeaderItems(isMin768)}
                chevronCellStyles={{ display: { md: 'table-cell', base: 'none' } }}
                renderItem={(item, index) => {
                  return (
                    <BaselaneResponsiveTableHeading
                      key={item.key}
                      index={index}
                      title={item.label}
                    />
                  );
                }}
              />
              <Tbody>
                <BaselaneResponsiveTableRows
                  customRow
                  items={leaseAgreements}
                  renderItem={(item, index) => {
                    const { label, variant, palette } = getLeaseButtonConfig(item.state);
                    return (
                      <BaselaneResponsiveTableRow
                        onClick={() => navigate(`${item?.id}`)}
                        id={`table-row-${index}`}
                        chevronCellStyles={{
                          display: { sm: 'table-cell', base: 'none' },
                        }}
                      >
                        <BaselaneResponsiveCell
                          configIndex={0}
                          p={0}
                          styles={{
                            justifyContent: 'flex-start',
                            colspan: { md: 1, base: 2 },
                          }}
                          {...{
                            h: '72px',
                            // _hover: { cursor: 'pointer' },
                          }}
                        >
                          <VStack gap="0" spacing={0} alignItems="flex-start">
                            <Text
                              as="div"
                              textStyle="sm"
                              fontWeight="500"
                              color="brand.neutral.900"
                            >
                              {item?.propertyUnit?.propertyName || ''}
                            </Text>
                            {item.propertyUnit?.isMultiUnitProperty && (
                              <Text textStyle="sm" color="brand.neutral.600">
                                {item?.propertyUnit?.name}
                              </Text>
                            )}
                          </VStack>
                        </BaselaneResponsiveCell>

                        {/* Lease agreement duration */}
                        {isMin768 && (
                          <BaselaneResponsiveCell configIndex={1} p={0} color="brand.neutral.900">
                            <Text textStyle="sm">
                              {formatLeaseAgreementDuration(
                                item.startDate,
                                item.endDate,
                                item.leaseType
                              )}
                            </Text>
                          </BaselaneResponsiveCell>
                        )}

                        {/* Status */}

                        <BaselaneResponsiveCell
                          configIndex={2}
                          p={0}
                          color="brand.neutral.900"
                          styles={{
                            colspan: { md: 1, base: 2 },
                          }}
                        >
                          <BaselaneChip
                            label={formatStateLabel(item.state)}
                            test
                            leftIcon={null}
                            onClick={null}
                            onDeleteClick={null}
                            size="sm"
                            minW="fit-content"
                            variant={documentStateVariants[item.state] || 'simple-neutral'}
                          />
                        </BaselaneResponsiveCell>

                        {isMin768 && (
                          <BaselaneResponsiveCell configIndex={3} p={0} color="brand.neutral.900">
                            <BaselaneButton variant={variant} palette={palette} size="md">
                              {label}
                            </BaselaneButton>
                          </BaselaneResponsiveCell>
                        )}
                      </BaselaneResponsiveTableRow>
                    );
                  }}
                />
              </Tbody>
            </BaselaneResponsiveTable>
          </Skeleton>
        </>
      )}
      <Outlet />
    </Box>
  );
};

export default LeaseAgreement;
