import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Heading, Flex, Text, Stack, Skeleton, SkeletonText } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import formatCurrency from '@core/utils/formatCurrency';
import {
  BaselaneCardNew,
  BaselaneDivider,
  BaselaneButton,
  BaselaneButtonIcon,
} from '@shared/components';
import { Icon16ChevronRight } from '@icons/16px';
import {
  TENANT_SCREENING,
  TENANT_SCREENING_APPLICANT,
  TENANT_SCREENING_APPLICATION,
} from '@core/constants/routes';
import { getReportsByApplicationId } from '../../helpers/tenantScreeningReports.helpers';
import SummaryCard from './SummaryCard';

type ReportSummaryProps = {
  applicant: String,
  creditScoreExpired: boolean,
};

const ReportSummary = ({ applicant, creditScoreExpired = false }: ReportSummaryProps) => {
  const { isMin899 } = useBreakPoints();
  const [reportSummaryData, setReportSummaryData] = useState(null);
  const navigate = useNavigate();
  const { screeningId, applicationId } = useParams();
  const { pathname } = useLocation();
  const appId = applicationId || applicant?.applicationId;
  useEffect(() => {
    if (!appId || !applicant) return;

    let isMounted = true;

    getReportsByApplicationId(appId)
      .then(({ data }) => {
        if (!isMounted) return;
        const summaryData = data?.applicants?.[0]?.report_summary || {};
        setReportSummaryData(summaryData);
      })
      .catch((error) => console.error('Error fetching application:', error));

    // eslint-disable-next-line consistent-return
    return () => {
      isMounted = false;
    }; // Cleanup function to prevent memory leaks
  }, [appId, applicant]);

  // **Extracted values from API response**
  const {
    identity_verification_status: identityVerificationStatus,
    gross_monthly_income: grossMonthlyIncome = {},
    verified_gross_monthly_income: verifiedGrossMonthlyIncome,
    verified_net_monthly_income: verifiedNetMonthlyIncome,
    verified_income_to_rent_ratio: verifiedIncomeToRentRatio,
    debt_income_ratio: debtIncomeRatio,
    credit_score: creditScore,
    criminal_records_count: criminalRecordsCount,
    eviction_records_count: evictionRecordsCount,
    dependents_count: dependentsCount = 0,
    pets_count: petsCount,
    vehicles_count: vehiclesCount = 0,
  } = reportSummaryData || {};

  const userData = useMemo(
    () => [
      { id: 'dependents', label: 'dependent', value: dependentsCount },
      { id: 'vehicles', label: 'vehicle', value: vehiclesCount },
      { id: 'pets', label: 'pet', value: petsCount },
    ],
    [dependentsCount, vehiclesCount, petsCount]
  );
  const incomeData = useMemo(
    () => [
      {
        id: 'gross-monthly-income',
        title: 'Gross monthly income',
        details: [
          {
            text: 'Verified:',
            value: verifiedGrossMonthlyIncome
              ? formatCurrency(verifiedGrossMonthlyIncome.cents / 100).inDollars
              : '--',
            size: 'small',
            isHeading: false,
          },
          {
            text: 'Self-reported:',
            value: grossMonthlyIncome
              ? formatCurrency(grossMonthlyIncome.cents / 100).inDollars
              : '--',
            size: 'small',
            isHeading: false,
          },
        ],
        route: 'income_verification',
      },
      {
        id: 'net-monthly-income',
        title: 'Net monthly income',
        details: [
          {
            text: 'Verified:',
            value: verifiedNetMonthlyIncome
              ? formatCurrency(verifiedNetMonthlyIncome.cents / 100).inDollars
              : '--',
            size: 'small',
            isHeading: false,
          },
        ],
        route: 'income_verification',
      },
      {
        id: 'gross-income-to-rent',
        title: 'Verified gross income to rent',
        details: [
          {
            value: verifiedIncomeToRentRatio ? `${verifiedIncomeToRentRatio}x` : '--',
            size: 'large',
            isHeading: true,
          },
        ],
        route: 'income_verification',
      },
      {
        id: 'debt-to-net-income',
        title: 'Debt to net income',
        details: [
          {
            value: debtIncomeRatio != null ? `${debtIncomeRatio}%` : '--',
            size: 'large',
            isHeading: true,
          },
        ],
        route: 'income_verification',
      },
    ],
    [
      verifiedGrossMonthlyIncome,
      verifiedNetMonthlyIncome,
      grossMonthlyIncome,
      verifiedIncomeToRentRatio,
      debtIncomeRatio,
    ]
  );

  const applicantScreeningData = [
    {
      id: 'id-verification',
      title: 'ID verification',
      details: [
        {
          value: identityVerificationStatus,
          size: 'large',
          chipText: identityVerificationStatus,
          isHeading: true,
        },
      ],
      route: 'identity_verification',
    },
    {
      id: 'credit-score',
      title: 'Credit score',
      details: [
        {
          value: !creditScoreExpired ? creditScore?.toString() ?? '--' : 'Expired',
          size: 'large',
          isHeading: true,
        },
      ],
      route: 'credit_report',
    },
    {
      id: 'criminal-records',
      title: 'Criminal records',
      details: [
        { value: criminalRecordsCount?.toString() ?? '--', size: 'large', isHeading: true },
      ],
      route: 'criminal_report',
    },
    {
      id: 'eviction-records',
      title: 'Eviction records',
      details: [
        {
          value: evictionRecordsCount?.toString() ?? '--',
          size: 'large',
          isHeading: true,
        },
      ],
      route: 'eviction_history',
    },
  ];

  const newPath = `/${TENANT_SCREENING}/${screeningId}/${TENANT_SCREENING_APPLICATION}/${applicationId}/${TENANT_SCREENING_APPLICANT}/${applicant?.id}/rental_application`;

  return (
    <BaselaneCardNew palette="empty" w="100%" flexDirection="column" border="solid 1px #DADFE6">
      {!reportSummaryData ? (
        <Stack width="100%" direction={isMin899 ? 'row' : 'column'} gap="2" my="2">
          <Flex justify="space-between" align="center" width="100%">
            <Skeleton height="20px" width="30%" />
            <SkeletonText noOfLines={1} skeletonHeight="26px" width="20%" />
          </Flex>
        </Stack>
      ) : (
        <Stack
          direction={isMin899 ? 'row' : 'row'}
          spacing={isMin899 ? 1 : 0}
          my="0"
          p="0"
          w="100%"
          justifyContent="space-between"
        >
          <Stack
            direction={isMin899 ? 'row' : 'column'}
            spacing={isMin899 ? 1 : 0}
            align="flex-start"
            mt="0.5"
            mb="1.5"
            mx="1"
            w="100%"
          >
            <Heading size="headline-lg" color="brand.neutral.900">
              {applicant?.applicantName || applicant?.name}
            </Heading>
            <Stack direction="row">
              {userData.map(({ id, label, value }) => (
                <Text textStyle="sm" my="2px" key={uuidv4()}>
                  {isMin899 && '•'}{' '}
                  {value > 0 ? `${value} ${label}${value > 1 ? 's' : ''}` : `0 ${label}s`}
                </Text>
              ))}
            </Stack>
          </Stack>
          {pathname.includes('reports_summary') &&
            (isMin899 ? (
              <BaselaneButton
                rightIcon={<Icon16ChevronRight />}
                onClick={() => navigate(newPath)}
                palette="primary"
                size="sm"
                variant="transparent"
                styles={{ m: '0', minWidth: 'auto' }}
              >
                View application
              </BaselaneButton>
            ) : (
              <BaselaneButtonIcon
                icon={<Icon16ChevronRight />}
                onClick={() => navigate(newPath)}
                palette="primary"
                size="lg"
                variant="transparent"
                styles={{ m: '0', minWidth: 'auto' }}
              />
            ))}
        </Stack>
      )}
      <BaselaneDivider
        styles={{
          my: '1',
          color: 'brand.darkBlue.100',
        }}
      />
      {!reportSummaryData ? (
        <Flex
          direction={isMin899 ? 'row' : 'column'}
          gap={isMin899 ? '1' : '3'}
          w="100%"
          my="2"
          paddingLeft="1"
        >
          {Array(4) // Render 3 skeleton placeholders
            .fill('')
            .map((_, index) => (
              <Stack key={uuidv4()} spacing={3} p={0}>
                <Skeleton height="24px" width="60%" />
                <SkeletonText noOfLines={2} spacing="4" skeletonHeight="16px" />
              </Stack>
            ))}
        </Flex>
      ) : (
        <Flex
          direction={isMin899 ? 'row' : 'column'}
          gap={isMin899 ? '1' : '3'}
          w="100%"
          my="2"
          paddingLeft="1"
        >
          {incomeData.map((item) => (
            <SummaryCard
              id={item.id}
              key={uuidv4()}
              title={item.title}
              details={item.details}
              route={item.route}
              applicationId={applicationId}
              applicantId={applicant?.id}
            />
          ))}
        </Flex>
      )}
      <BaselaneDivider styles={{ my: '1', color: 'brand.darkBlue.100' }} />
      {!reportSummaryData ? (
        <Flex
          direction="row"
          flexWrap="wrap"
          gap={isMin899 ? '1' : '3'}
          w="100%"
          mt="2"
          mb="0"
          paddingLeft="1"
        >
          {Array(4)
            .fill('')
            .map((_, index) => (
              <Stack key={uuidv4()} spacing={3} p={0}>
                <Skeleton height="24px" width="60%" />
                <SkeletonText noOfLines={2} spacing="4" skeletonHeight="16px" />
              </Stack>
            ))}
        </Flex>
      ) : (
        <Flex
          direction={isMin899 ? 'row' : 'column'}
          gap={isMin899 ? '1' : '3'}
          w="100%"
          mt="2"
          mb="0"
          paddingLeft="1"
        >
          {applicantScreeningData.map((item) => (
            <SummaryCard
              id={item.id}
              key={uuidv4()}
              title={item.title}
              details={item.details}
              route={item.route}
              applicationId={applicationId}
              applicantId={applicant?.id}
            />
          ))}
        </Flex>
      )}
    </BaselaneCardNew>
  );
};

export default ReportSummary;
