import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// Note: sizes of BaselaneCardStack are directly linked to sizes of BaselaneCard
import { sizes } from '../BaselaneCard/card';

const helpers = createMultiStyleConfigHelpers(['stackContainer', 'cardContainer']);

export const BaselaneCardStack = helpers.defineMultiStyleConfig({
  baseStyle: {
    stackContainer: {
      display: 'flex',
      position: 'relative',
      py: 0,
      borderColor: 'brand.darkBlue.200',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    cardContainer: {
      boxSizing: 'content-box',
      alignItems: 'center',
      _notLast: {
        borderBottom: 'solid 1px',
        borderColor: 'brand.darkBlue.100',
      },
      width: `100%`,
    },
  },
  sizes: {
    sm: {
      stackContainer: {
        px: sizes.sm.p,
      },
      cardContainer: {
        mx: -sizes.sm.p,
        px: sizes.sm.p,
        py: sizes.sm.p,
      },
    },
    md: {
      stackContainer: {
        px: sizes.md.p,
      },
      cardContainer: {
        mx: -sizes.md.p,
        px: sizes.md.p,
        py: sizes.md.p,
      },
    },
    lg: {
      stackContainer: {
        px: sizes.lg.p,
      },
      cardContainer: {
        mx: -sizes.lg.p,
        px: sizes.lg.p,
        py: sizes.lg.p,
      },
    },
  },
  defaultProps: {
    size: 'md',
    palette: 'light',
  },
});
