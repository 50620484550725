export const containerStyles = {
  p: { md: 3, base: 2 },
  width: '100%',
  position: 'relative',
};

export const accountsInfoCardsContainerStyles = {
  direction: 'row',
  spacing: 2,
  overflow: 'auto',
  css: {
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      height: '4px',
      background: '#E5E9EF',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#ACB8C7',
    },
  },
};
