import React from 'react';
import { Text } from '@chakra-ui/react';
import { TabComponent, TabNumber } from '@shared/components';
import TabIcon from '@shared/components/BaselaneFlow/Tabs/TabComponent/TabIcon';

type TabListItemProps = {
  tab: object,
  index: number,
  CSVTabList: Array<Object>,
  tabIndex: number,
};

const TabListItem = ({ tab, index, CSVTabList, tabIndex }: TabListItemProps) => {
  // custom handler to set disabled styles for tabs
  // used for TabComponent + TabNumber
  const handleIsOptionDisabled = () => {
    // case for last step specifically
    if (index === CSVTabList.length - 1) {
      const thereIsARequiredTabNotValid = CSVTabList.find(
        (t) => !t.isValid && !t.isOptional && t.key !== 'preview'
      );
      return !!thereIsARequiredTabNotValid;
    }

    return (
      (index > 0 && !CSVTabList[index - 1].isValid && !CSVTabList[index].isValid) ||
      (CSVTabList[index].isOptional && CSVTabList[index].isDisabled)
    );
  };

  return (
    <TabComponent
      key={tab.label}
      option={tab}
      index={index}
      menuOptions={CSVTabList}
      showDisabledStyles={handleIsOptionDisabled()}
    >
      {tab.isValid ? (
        <TabIcon
          menuOptions={CSVTabList}
          option={tab}
          index={index}
          tabIndex={tabIndex}
          showGreenCircle={tab.isValid}
        />
      ) : (
        <TabNumber
          menuOptions={CSVTabList}
          option={tab}
          index={index}
          handleIsOptionDisabled={handleIsOptionDisabled}
        />
      )}
      <Text ml={1} textStyle="sm">
        {tab.label}
      </Text>
    </TabComponent>
  );
};

export default TabListItem;
