// @flow
import React from 'react';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { BaselaneDrawer } from '@core/components/Shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { getLearnMoreDrawerConst } from './helpers/learnMoreDrawer.helpers';
import { INVOICE_STATUS_TYPES } from '../../constants';
import { getLearnMoreDrawerStyles } from './styles/learnMoreDrawer.styles';

type LearnMoreDrawerProps = {
  learnMoreDrawerRef: any,
};

const LearnMoreDrawer = ({ learnMoreDrawerRef }: LearnMoreDrawerProps) => {
  const { isMax767 } = useBreakPoints();

  const { DrawerBody } = BaselaneDrawer;

  const handleCloseLearnMoreDrawer = () => {
    learnMoreDrawerRef?.current.close();
  };

  // Destructure Imported Styles
  const {
    drawer,
    content: { titlesection, statuslist },
  } = getLearnMoreDrawerStyles();

  const { drawerTitle, contentTitle, description, statusList } = getLearnMoreDrawerConst;

  return (
    <BaselaneDrawer
      ref={learnMoreDrawerRef}
      title={drawerTitle}
      size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
      contentStyles={isMax767 && { top: 'auto !important' }}
      closeEvent={handleCloseLearnMoreDrawer}
      newDesignDrawer
    >
      <DrawerBody {...drawer}>
        <>
          {/* Content Title/Description */}
          <Stack {...titlesection.container}>
            <Text {...titlesection.title}>{contentTitle}</Text>
            <Text {...titlesection.description}>{description}</Text>
          </Stack>

          {/* Status List */}
          <Stack {...statuslist.listcontainer}>
            {statusList.map((item) => {
              const { icon, label } = INVOICE_STATUS_TYPES[item.key];

              return (
                <Stack {...statuslist.itemcontainer} key={item.key}>
                  <HStack {...statuslist.titlecontainer}>
                    <Box>{icon}</Box>
                    <Text {...statuslist.title}>{label}</Text>
                  </HStack>
                  <Text {...statuslist.description}>{item.description}</Text>
                </Stack>
              );
            })}
          </Stack>
        </>
      </DrawerBody>
    </BaselaneDrawer>
  );
};

export default LearnMoreDrawer;
