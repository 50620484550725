import React, { useContext, useState } from 'react';
import moment from 'moment/moment';
import { Box, HStack, useDisclosure } from '@chakra-ui/react';
import {
  BaselaneRangeDatePicker,
  NoPropertyTooltip,
  DisplayInputButton,
  T1Dropdown,
  T1WithTitleDropdown,
  T2Dropdown,
  T2WithTitleDropdown,
  DisplayInputDefaultDate,
  DisplayInputShowingSelectedNumberAccount,
  DisplayInputShowingSelectedNumberCategory,
  DisplayInputShowingSelectedNumberProperty,
  renderAccountDropdownItem,
} from '@shared/components';
import { DATE_OPTIONS } from '@shared/components/BaselaneDropdown-new/helpers/datepickerDropdown.helpers';
import { NATIVE_BANK } from '@routes';
import { formatDate } from '@core/utils/formatDate';
import { getOption } from '@shared/components/BaselaneDropdown/components/DatePicker/helpers/datepickerDropdown.helpers';
import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { propertyDropdownParentItemRenderer } from '@shared/helpers/propertiesFilter.helpers';
import TransactionContext from '@contexts/TransactionContext';
import formatDateTwoDigitDay from '@core/utils/formatDateTwoDigitDay';
import useBreakPoints from '@core/hooks/useBreakPoints';

import { dateData } from './helpers/transactionsFilters.helpers';
import {
  dropdownContainerStyles,
  filtersContainerStyles,
} from './styles/transactionsFilters.styles';

type FilterDropdownAndTogglesProps = {
  hasDropdownClearedExternally: boolean,
  setHasDropdownClearedExternally: Function,
  pathname: Object,
  connectedAccountsFilterOptions: Array<Object>,
  selectedItem: Object,
  selectedFilters: Object,
  onClearClick: Function,
  propertyOptions: Array<Object>,

  setSelectedFilters: Function,
  categoryOptions: Array<Object>,
};

const FilterDropdowns = ({
  hasDropdownClearedExternally,
  setHasDropdownClearedExternally,
  pathname,
  connectedAccountsFilterOptions,
  selectedItem,
  selectedFilters,
  onClearClick,
  propertyOptions,
  setSelectedFilters,
  categoryOptions,
}: FilterDropdownAndTogglesProps) => {
  const { isMax768 } = useBreakPoints();

  const { filters, setFilters, DEFAULT_FILTERS: defaultFilters } = useContext(TransactionContext);

  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [sDate, setSDate] = useState(null);
  const [eDate, setEDate] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDateFilterChange = ({
    startDate: selectedStartDate = null,
    endDate: selectedEndDate = null,
    dateFilter,
  }) => {
    const updatedFilters = {
      ...defaultFilters,
      filter: {
        ...filters.filter,
        from: selectedStartDate ? formatDate(selectedStartDate, 'YYYY-MM-DD') : null,
        to: selectedEndDate ? formatDate(selectedEndDate, 'YYYY-MM-DD') : null,
      },
      sort: { ...filters.sort },
    };

    const dateOption = getOption(
      new Date(moment(selectedStartDate)),
      new Date(moment(selectedEndDate))
    );

    setSelectedFilters({
      ...selectedFilters,
      timePeriod: dateOption,
      dates: {
        start: selectedStartDate ? new Date(moment(selectedStartDate).valueOf()) : null,
        end: selectedEndDate ? new Date(moment(selectedEndDate).valueOf()) : null,
      },
    });
    setSelectedDateFilter(dateFilter);
    setFilters(updatedFilters);

    if (dateFilter !== 'custom' && sDate !== null && eDate !== null) {
      setSDate(null);
      setEDate(null);
    }
  };

  const getDateSelectedItem = () => {
    if (
      selectedDateFilter === 'Custom' &&
      (selectedFilters.timePeriod?.from || selectedFilters.dates?.start) &&
      (selectedFilters.timePeriod?.to || selectedFilters.dates?.end)
    )
      return {
        id: 'custom',
        name: `${formatDateTwoDigitDay(
          selectedFilters.timePeriod?.from ?? selectedFilters.dates?.start
        )} - ${formatDateTwoDigitDay(
          selectedFilters.timePeriod?.to ?? selectedFilters.dates?.end
        )}`,
      };

    if (
      (selectedFilters.timePeriod?.from || selectedFilters.dates?.start) &&
      (selectedFilters.timePeriod?.to || selectedFilters.dates?.end)
    ) {
      const preset = DATE_OPTIONS.find((dateOpt) => {
        if (selectedDateFilter === null) {
          return dateOpt.label === 'Last 6 Months';
        }
        return dateOpt.label === selectedDateFilter;
      });
      return { id: preset.value, name: preset.label };
    }

    return null;
  };

  const handleAccountFilterChange = (selectedAccounts) => {
    const bankAccounts = selectedAccounts.map((cat) => cat.id);

    const updatedFilters = {
      ...defaultFilters,
      filter: {
        ...filters.filter,
        bankAccountId: bankAccounts.length > 0 ? bankAccounts : null,
      },
      sort: { ...filters.sort },
    };

    setSelectedFilters({ ...selectedFilters, accounts: selectedAccounts });
    setFilters(updatedFilters);
  };

  const handlePropertyFilterChange = (selectedProperties) => {
    const [p, u] = selectedProperties.reduce(
      (acc, trx) => {
        acc[trx.isChild ? 1 : 0].push(trx);
        return acc;
      },
      [[], []]
    );

    const properties = p.map((cat) => cat.id);
    const units = u.map((cat) => cat.unitId);
    const updatedFilters = {
      ...defaultFilters,
      filter: {
        ...filters.filter,
        propertyId: properties.length > 0 ? properties : null,
        unitId: units.length > 0 ? units : null,
      },
      sort: filters.sort,
    };

    setSelectedFilters({
      ...selectedFilters,
      properties: selectedProperties,
    });
    setFilters(updatedFilters);
  };

  /**
   * Triggered when category dropdown preset is applied.
   */
  const handleCategoryFilterChange = (selectedCategories) => {
    const tags = selectedCategories.map((cat) => {
      const [categoryId, subCategoryId] = cat.id.split('-');
      return subCategoryId ?? categoryId;
    });

    const updatedFilters = {
      ...defaultFilters,
      filter: {
        ...filters.filter,
        tagId: tags.length > 0 ? tags : null,
      },
      sort: { ...filters.sort },
    };

    setSelectedFilters({
      ...selectedFilters,
      categories: [...selectedCategories],
    });
    setFilters(updatedFilters);
  };

  const dateDropdownClassNames = isMax768
    ? ['auto-select-input-width', 'input-form-md', 'auto-height', 'dateFilter-width-dropdown']
    : [
        'auto-width-dropdown',
        'auto-select-input-width',
        'input-form-md',
        'auto-height',
        'dateFilter-width-dropdown',
      ];

  return (
    <>
      <HStack
        {...filtersContainerStyles}
        {...{ width: { lg: 'calc(100% - 244px)', base: 'initial' } }}
      >
        {/* Date */}
        <Box {...dropdownContainerStyles}>
          <T1Dropdown
            classNames={dateDropdownClassNames}
            title="Date"
            hideClearButton
            showValueByFields={['name']}
            itemRenderer={itemRenderer}
            selectedItem={getDateSelectedItem()}
            hasDropdownClearedExternally={hasDropdownClearedExternally}
            setHasDropdownClearedExternally={setHasDropdownClearedExternally}
            hideSearch
            CustomDisplayInput={DisplayInputDefaultDate}
            data={dateData(onOpen, isMax768)}
            handleSubmit={(datePreset) => {
              const datePresetData = DATE_OPTIONS.find((dateOpt) => {
                if (datePreset.length > 0) {
                  return dateOpt.value === datePreset;
                }
                return false;
              });
              handleDateFilterChange(
                datePresetData
                  ? {
                      startDate: datePresetData.from,
                      endDate: datePresetData.to,
                      dateFilter: datePresetData.label,
                    }
                  : {}
              );
            }}
            hasFilterWrapper={isMax768}
            isMobile={isMax768}
          />
        </Box>

        {/* Account */}
        {!pathname.includes(NATIVE_BANK) && (
          <Box {...dropdownContainerStyles}>
            <T1WithTitleDropdown
              additionalProps={{ id: 'account-filter-dropdown' }}
              classNames={['fixed-width-dropdown', 'auto-select-input-width', 'input-form-md']}
              data={connectedAccountsFilterOptions}
              searchTerm={['account', 'bankName']}
              title="Account"
              showValueByFields={['bankName', 'account']}
              itemRenderer={renderAccountDropdownItem}
              handleSubmit={handleAccountFilterChange}
              isMulti
              selectedItem={selectedFilters.accounts ?? []}
              hasDropdownClearedExternally={hasDropdownClearedExternally}
              setHasDropdownClearedExternally={setHasDropdownClearedExternally}
              showDivider
              CustomDisplayInput={DisplayInputShowingSelectedNumberAccount}
              hasFilterWrapper
              isMobile={isMax768}
            />
          </Box>
        )}

        {/* Category w/ sub category */}
        <Box {...dropdownContainerStyles}>
          <T2WithTitleDropdown
            additionalProps={{ id: 'category-filter-dropdown' }}
            classNames={['fixed-width-dropdown', 'auto-select-input-width', 'input-form-md']}
            data={categoryOptions}
            title="Category"
            showValueByFields={['name']}
            parentItemRenderer={itemRenderer}
            childItemRenderer={itemRenderer}
            handleSubmit={handleCategoryFilterChange}
            isMulti
            hasFilterWrapper
            hasDropdownClearedExternally={hasDropdownClearedExternally}
            setHasDropdownClearedExternally={setHasDropdownClearedExternally}
            selectedItem={selectedItem ?? selectedFilters.categories}
            onClearClick={onClearClick}
            CustomDisplayInput={DisplayInputShowingSelectedNumberCategory}
            isMobile={isMax768}
          />
        </Box>

        {/* Property */}
        <Box {...dropdownContainerStyles}>
          {propertyOptions?.length === 0 ? (
            <NoPropertyTooltip>
              <T2Dropdown
                isDisabled
                title="Property"
                CustomDisplayInput={DisplayInputShowingSelectedNumberProperty}
                classNames={['input-form-md']}
              />
            </NoPropertyTooltip>
          ) : (
            <T2Dropdown
              additionalProps={{ id: 'property-filter-dropdown' }}
              classNames={['fixed-width-dropdown', 'auto-select-input-width', 'input-form-md']}
              data={propertyOptions}
              title="Property"
              showValueByFields={['name']}
              parentItemRenderer={propertyDropdownParentItemRenderer}
              childItemRenderer={itemRenderer}
              handleSubmit={handlePropertyFilterChange}
              isMulti
              hasFilterWrapper
              selectedItem={selectedFilters.properties ?? []}
              hasDropdownClearedExternally={hasDropdownClearedExternally}
              setHasDropdownClearedExternally={setHasDropdownClearedExternally}
              CustomDisplayInput={DisplayInputShowingSelectedNumberProperty}
              isMobile={isMax768}
            />
          )}
        </Box>
      </HStack>

      {/* Range Datepicker modal */}
      <BaselaneRangeDatePicker
        showInModal
        startDate={sDate}
        endDate={eDate}
        years={{ start: 2000, end: new Date().getFullYear() }}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        value={
          sDate && eDate
            ? `${formatDate(sDate, 'MMM DD, YYYY')} - ${formatDate(eDate, 'MMM DD, YYYY')}`
            : ''
        }
        handleCalendarClose={({ startDate, endDate }) => {
          setSDate(startDate);
          setEDate(endDate);
          handleDateFilterChange({ startDate, endDate, dateFilter: 'Custom' });
        }}
        CustomDisplayInput={DisplayInputButton}
        customDisplayInputProps={{
          styles: { display: 'none' },
        }}
      />
    </>
  );
};

export default FilterDropdowns;
