import React from 'react';
import { Text } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import HeaderNavWrapper from '@shared/layouts/HeaderNavWrapper';
import { leftHeaderContentStyles } from '@pages/UnifiedRentCollection/UnifiedLeaseUI/styles/unifiedLease.styles';
import SuccessUI from './SuccessUI';

const UnifiedLeaseSuccess = () => {
  const { isMax768: isMobile } = useBreakPoints();

  return isMobile ? (
    <SuccessUI />
  ) : (
    <HeaderNavWrapper
      hideFinishLater
      headerContent={<Text {...leftHeaderContentStyles}>Set Up Rent Collection</Text>}
    >
      <SuccessUI />
    </HeaderNavWrapper>
  );
};

export default UnifiedLeaseSuccess;
