import React, { useContext, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Tbody, useDisclosure } from '@chakra-ui/react';
import {
  BaselaneResponsiveTable,
  BaselaneResponsiveCell,
  BaselaneResponsiveCellText,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTableLoader,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveTableHeader,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableEmptyState,
} from '@shared/components';
import { IconExclamationCircleThin } from '@icons';
import {
  IllustrationBankingBaselanedebitcardactive,
  IllustrationBankingBaselanedebitcardlocked,
} from '@illustrations';
import VirtualCardTriggerButton from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/AddVirtualCard/TriggerButton';
import formatCurrency from '@core/utils/formatCurrency';
import CardDetails from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/CardDetails';
import banksContext from '@contexts/BanksContext';
import BankEntityContext from '@contexts/BankEntityContext';
import customTheme from '@core/theme';
import DisableMoveMoney from '@core/components/DisableMoveMoney';
import ActivatePopup from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/ActivatePopup';
import ActivationSuccessPopup from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/ActivatePopup/ActivationSuccessPopup';
import useBreakPoints from '@core/hooks/useBreakPoints';
import UserAccessContext from '@contexts/UserAccessContext';
import { getBank } from '@core/components/NativeBankingPage/MainContent/helpers/banks.shared';
import { GET_CARD_SUMMARY } from '@core/components/NativeBankingPage/queries';
import { useUnitOtp } from '@core/contexts/UnitOtpContext';
import UserContext from '@contexts/UserContext';
import CardStatusBadge from './CardStatusBadge';
import { headerItems, cardTableConfig } from './helpers/cardTable.helpers';

const CardsTable = () => {
  const { baselaneConnectedAccounts } = useContext(banksContext);
  const { allBankAccountIdsFilter, activeFilter, account } = useContext(BankEntityContext);
  const { authorizedForBanking } = useContext(UserAccessContext);
  const { emailVerified } = useContext(UserContext);

  const cardFilters = () => {
    return {
      bankAccountId: activeFilter?.bankAccountId ?? allBankAccountIdsFilter,
    };
  };
  const cardsQueryItems = useQuery(GET_CARD_SUMMARY, {
    variables: {
      page: 1,
      pageSize: 99,
      filter: {
        ...cardFilters(),
        includeHiddenCards: false,
      },
    },
  });

  const { isMax576: showMobileUI, isMin899, isMin1150 } = useBreakPoints();
  const { 700: blue700 } = customTheme.colors.brand.blue;

  // Alert State
  const {
    isOpen: isActivatePopupOpen,
    onOpen: onActivatePopupOpen,
    onClose: onActivatePopupClose,
  } = useDisclosure();

  // Activation Success Popup
  const {
    isOpen: isActivationSuccessPopupOpen,
    onOpen: onActivationSuccessPopupOpen,
    onClose: onActivationSuccessPopupClose,
  } = useDisclosure();

  const { data = [], loading = false, refetch: refetchCards } = cardsQueryItems ?? {};

  const { cardSummary } = data || {};
  const { data: cardListData } = cardSummary || {};

  const [viewCardDetailsInfo, setViewCardDetailsInfo] = useState();

  // Unit OTP
  const { verifyUnitOtp, ignore } = useUnitOtp();

  // card details drawer ref + functions
  const cardDetailsDrawerRef = useRef(null);
  const onOpenCardDetailsDrawer = (card, bank) => {
    setViewCardDetailsInfo({
      bankInfo: { ...bank },
      card,
    });

    cardDetailsDrawerRef?.current?.open();
  };
  const onCloseCardDetailsDrawer = () => cardDetailsDrawerRef?.current?.close();

  const onActivateCardClick = (card, bank) => {
    const { bankId } = bank;
    setViewCardDetailsInfo({
      bankInfo: { ...bank },
      card,
    });

    verifyUnitOtp(bankId).then(onActivatePopupOpen).catch(ignore);
  };

  let colSpan = 2;
  if (isMin1150) {
    colSpan = 5;
  } else if (isMin899) {
    colSpan = 3;
  }

  return (
    <>
      <BaselaneResponsiveTable config={cardTableConfig}>
        {!showMobileUI && (
          <BaselaneResponsiveTableHeader
            items={headerItems}
            renderItem={(item, index) => (
              <BaselaneResponsiveTableHeading key={item.key} index={index} title={item.label} />
            )}
          />
        )}

        <BaselaneResponsiveTableLoader isLoading={loading} />

        <BaselaneResponsiveTableEmptyState
          showEmptyState={cardListData?.length === 0}
          text="There are no cards for this account"
          colSpan={colSpan}
        />

        {!loading && (
          <Tbody>
            <BaselaneResponsiveTableRows
              customRow
              items={cardListData}
              renderItem={(cardData, index) => {
                const {
                  isPhysical,
                  nickname,
                  accountName,
                  last4Digits,
                  cardStatus,
                  cardFinancials,
                  limit,
                } = cardData;

                const accountId = cardData.bankAccountId;
                const cardBank = getBank(baselaneConnectedAccounts, accountId);

                const cardBankAccount = cardBank?.bankAccounts?.reduce((accAccount, bAccount) => {
                  if (Number(bAccount.id) === cardData.bankAccountId) {
                    return bAccount;
                  }
                  const subAccount = bAccount?.subAccounts?.find(
                    (sbAccount) => Number(sbAccount.id) === cardData.bankAccountId
                  );

                  return subAccount ?? accAccount;
                }, {});

                const bankData = {
                  ...cardBank,
                  ...cardBankAccount,
                  bankId: cardBank?.id,
                  phoneNumber: cardBank?.unitAccount?.phoneNumber ?? '',
                  bankName: cardBank?.name ?? '',
                  nickName: cardBankAccount?.nickName || 'Main Account',
                };

                const isMonthlyLimit = limit?.frequency === 'MONTHLY';
                const moneySpent = isMonthlyLimit
                  ? cardFinancials?.mtdSpent
                  : cardFinancials?.dailySpent;
                const limitAmount = limit?.amount || 0;
                const progressPercent = limitAmount === 0 ? 0 : (moneySpent * 100) / limitAmount;
                const isExceeded = progressPercent >= 90;
                const hasNotEnoughBalance = bankData?.availableBalance < limitAmount;
                const hasBalanceWarning = hasNotEnoughBalance || isExceeded;

                const isLocked = cardStatus === 'Frozen';
                const isInactive = cardStatus === 'Inactive';

                let indicatorColor = null;
                if (isInactive) {
                  indicatorColor = blue700;
                } else if (hasBalanceWarning) {
                  indicatorColor = customTheme.colors.red['500A'];
                }

                const spendLimit = limit?.amount
                  ? `${formatCurrency(limit?.amount).inDollars} / ${
                      limit?.frequency === 'DAILY' ? 'day' : 'month'
                    }`
                  : `﹣`;

                return (
                  /* Using a custom row, in order to set indicator and click handler */
                  <BaselaneResponsiveTableRow
                    id={`card-row-${isPhysical ? 'physical' : 'virtual'}-${
                      isInactive ? 'inactive' : 'active'
                    }-${index}`}
                    isDisabled={!emailVerified}
                    onClick={() => {
                      if (!emailVerified) return;
                      if (isInactive) {
                        onActivateCardClick(cardData, bankData);
                      } else {
                        onOpenCardDetailsDrawer(cardData, bankData);
                      }
                    }}
                    indicatorColor={indicatorColor}
                  >
                    {/* Card  */}
                    {showMobileUI ? (
                      <BaselaneResponsiveCellTitle
                        title={nickname || accountName}
                        subtitle={`${isPhysical ? 'Physical Card' : 'Virtual Card'} ${last4Digits}`}
                        rightTitleElement={
                          <>
                            {isInactive && <IconExclamationCircleThin color={blue700} />}
                            {hasBalanceWarning && <IconExclamationCircleThin color={blue700} />}
                          </>
                        }
                        isVertical
                        styles={{ textContainer: { gap: 0 } }}
                        configIndex={0}
                      >
                        <Box m="0 !important">
                          <DisableMoveMoney
                            styles={{ flex: 0 }}
                            tooltipText="Verify your email to activate card"
                            renderItem={(isDisabled) => (
                              <CardStatusBadge
                                isInactive={isInactive}
                                isLocked={!isInactive && isLocked}
                                isDisabled={isDisabled}
                              />
                            )}
                          />
                        </Box>
                      </BaselaneResponsiveCellTitle>
                    ) : (
                      <BaselaneResponsiveCellTitle
                        title={nickname || accountName}
                        subtitle={`${isPhysical ? 'Physical Card' : 'Virtual Card'} ${last4Digits}`}
                        graphicElement={
                          isLocked ? (
                            <IllustrationBankingBaselanedebitcardlocked />
                          ) : (
                            <IllustrationBankingBaselanedebitcardactive />
                          )
                        }
                        rightTitleElement={
                          <>
                            {isInactive && <IconExclamationCircleThin color={blue700} />}
                            {hasBalanceWarning && (
                              <IconExclamationCircleThin color={customTheme.colors.red['900']} />
                            )}
                          </>
                        }
                        color="brand.neutral.900"
                        configIndex={0}
                      />
                    )}
                    {/* Linked Account  */}
                    <BaselaneResponsiveCellText
                      text={`${bankData?.nickName} - ${bankData?.accountNumber?.slice(-4)}`}
                      subtext={bankData?.accountName}
                      configIndex={1}
                    />
                    {/* Spend Limit  */}
                    <BaselaneResponsiveCellText text={spendLimit} configIndex={2} />
                    {/* Spent This Month  */}
                    <BaselaneResponsiveCellText
                      text={formatCurrency(cardFinancials?.mtdSpent).inDollars || `﹣`}
                      configIndex={3}
                    />
                    {/* Status  */}
                    <BaselaneResponsiveCell configIndex={4}>
                      <DisableMoveMoney
                        styles={{ flex: 0 }}
                        tooltipText="Verify your email to activate card"
                        renderItem={(isDisabled) => (
                          <CardStatusBadge
                            isInactive={isInactive}
                            isLocked={!isInactive && isLocked}
                            isDisabled={isDisabled}
                          />
                        )}
                      />
                    </BaselaneResponsiveCell>
                  </BaselaneResponsiveTableRow>
                );
              }}
            />
          </Tbody>
        )}
      </BaselaneResponsiveTable>
      {authorizedForBanking && (
        <VirtualCardTriggerButton
          type="responsiveButton"
          size={showMobileUI ? 'sm' : 'md'}
          iconName={showMobileUI ? 'plus' : 'addDottedRectangle'}
        />
      )}

      <CardDetails
        {...{
          hideTriggerButton: true,
          ...viewCardDetailsInfo,
          setViewCardDetailsInfo,
          onOpenCardDetailsDrawer,
          onCloseCardDetailsDrawer,
          cardDetailsDrawerRef,
          cardFilters,
        }}
      />

      <ActivatePopup
        {...{
          ...viewCardDetailsInfo,
          isActivatePopupOpen,
          onActivatePopupClose,
          onActivationSuccessPopupOpen,
        }}
      />
      <ActivationSuccessPopup
        {...{
          isActivationSuccessPopupOpen,
          onActivationSuccessPopupClose,
        }}
      />

      <Outlet context={{ account, refetch: refetchCards }} />
    </>
  );
};

export default CardsTable;
