import React from 'react';
import { IconPropsType } from '@core/components/Icons/manual/types/icon.types';

function IconPresent({ w, h, color }: IconPropsType) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 3.6V14M8 3.6H5.70179c-.33863 0-.66338-.13696-.90283-.38076C4.55952 2.97544 4.425 2.64478 4.425 2.3s.13452-.67544.37396-.91924C5.03841 1.13696 5.36316 1 5.70179 1 7.48929 1 8 3.6 8 3.6Zm0 0h2.2982c.3386 0 .6634-.13696.9028-.38076.2395-.2438.374-.57446.374-.91924s-.1345-.67544-.374-.91924C10.9616 1.13696 10.6368 1 10.2982 1 8.51071 1 8 3.6 8 3.6Zm5.2 3.25v5.07c0 .7281 0 1.0921-.1417 1.3702-.1246.2446-.3235.4435-.5681.5681C12.2121 14 11.8481 14 11.12 14H4.88c-.72807 0-1.0921 0-1.37019-.1417-.24461-.1246-.44348-.3235-.56812-.5681C2.8 13.0121 2.8 12.6481 2.8 11.92V6.85M1.5 4.64v1.17c0 .36403 0 .54605.07085.68509.06231.12231.16175.22175.28406.28406.13904.07085.32106.07085.68509.07085h10.92c.364 0 .5461 0 .6851-.07085.1223-.06231.2217-.16175.2841-.28406.0708-.13904.0708-.32106.0708-.68509V4.64c0-.36403 0-.54605-.0708-.68509-.0624-.12231-.1618-.22175-.2841-.28406C14.0061 3.6 13.824 3.6 13.46 3.6H2.54c-.36403 0-.54605 0-.68509.07085-.12231.06231-.22175.16175-.28406.28406C1.5 4.09395 1.5 4.27597 1.5 4.64Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IconPresent.defaultProps = {
  w: 16,
  h: 16,
  color: 'currentColor',
};

export default IconPresent;
