import React, { useState, useEffect } from 'react';
import { Text, Tbody, useToast } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { Icon16Download } from '@icons/16px';
import {
  BaselaneResponsiveTableHeader,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveCellText,
  BaselaneButtonIcon,
  BaselaneResponsiveTable,
  BaselaneErrorCard,
} from '@shared/components';
import {
  getNonSensitiveToken,
  getTokenData,
  getIsTokenExpired,
  setTokenData,
} from '@shared/helpers/otp.helpers';
import SlLoader from '../../../../Loader';
import EmptyState from './EmptyState';
import { getTaxFormPdf } from './helpers/taxFormDownload.helper';
import { GET_TAX_FORMS } from '../../../queries/taxForms';

const TaxFormsTable = () => {
  const [taxForms, setTaxForms] = useState([]);
  const toast = useToast();
  const [extFormId, setExtFormId] = useState(null);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const { data: taxFormsData, loading, error } = useQuery(GET_TAX_FORMS);

  useEffect(() => {
    if (taxFormsData?.taxForms) {
      setTaxForms(taxFormsData.taxForms);
    }
  }, [taxFormsData]);

  const showErrorToast = (err) => {
    toast({
      title: 'Error',
      description: err.message,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const onNonSensitiveTokenComplete = (tokenData) => {
    if (tokenData?.token) {
      setTokenData(selectedBankId, tokenData.token);
      getTaxFormPdf(extFormId, tokenData.token, taxForms, showErrorToast);
    }
  };

  const onNonSensitiveTokenError = (err) => {
    showErrorToast(err);
  };

  const [unitAPINonSensitiveToken] = getNonSensitiveToken(
    onNonSensitiveTokenComplete,
    onNonSensitiveTokenError
  );

  const handleDownload = (formId, bankId) => {
    setSelectedBankId(bankId);
    setExtFormId(formId);

    const { nonSensitive } = getTokenData(bankId);
    const isNonSensitiveTokenExpired = getIsTokenExpired(bankId, true);

    if (isNonSensitiveTokenExpired) {
      unitAPINonSensitiveToken({ variables: { bankId } });
    } else {
      getTaxFormPdf(formId, nonSensitive.token, taxForms, showErrorToast);
    }
  };

  const headerItems = [
    { key: 'formType', label: 'Entity' },
    { key: 'taxYear', label: 'Tax Year', justifyContent: 'flex-end' },
    {
      key: 'download',
      label: 'Download',
      justifyContent: 'flex-end',
    },
  ];

  const config = {
    columns: [
      {
        styles: {
          display: 'table-cell',
          p: '16px',
        },
      },
      {
        styles: {
          display: 'table-cell',
          width: '100px',
          p: '16px',
        },
      },
      {
        styles: {
          display: 'table-cell',
          width: '100px',
          p: '16px',
        },
      },
    ],
  };

  if (error) return <BaselaneErrorCard />;
  if (loading) return <SlLoader />;
  if (!taxForms?.length) return <EmptyState />;

  return (
    <BaselaneResponsiveTable id="taxFormsTable" config={config}>
      <BaselaneResponsiveTableHeader
        items={headerItems}
        renderItem={(item, index) => (
          <BaselaneResponsiveTableHeading
            key={item.key}
            index={index}
            title={item.label}
            {...{ height: 'fit-content' }}
            justifyContent={item.justifyContent}
          />
        )}
        chevronCellStyles={{ display: 'none' }}
      />

      <Tbody>
        <BaselaneResponsiveTableRows
          customRow
          items={taxForms || []}
          renderItem={({ id, entityName, taxYear, externalFormId, bankId }) => (
            <BaselaneResponsiveTableRow
              key={id}
              id={id}
              onClick={() => {
                try {
                  handleDownload(externalFormId, bankId);
                } catch (err) {
                  showErrorToast(err);
                }
              }}
              chevronCellStyles={{ display: 'none' }}
            >
              <BaselaneResponsiveCellTitle
                title={entityName}
                color="brand.neutral.900"
                configIndex={0}
                styles={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              />
              <BaselaneResponsiveCellText
                configIndex={1}
                styles={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignSelf: 'flex-end',
                }}
              >
                <Text textStyle="sm" color="brand.neutral.700">
                  {taxYear}
                </Text>
              </BaselaneResponsiveCellText>
              <BaselaneResponsiveCellText
                configIndex={2}
                styles={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <BaselaneButtonIcon
                  variant="outline"
                  palette="neutral"
                  size="lg"
                  icon={<Icon16Download />}
                  onClick={(e) => {
                    e.stopPropagation();
                    try {
                      handleDownload(externalFormId, bankId);
                    } catch (err) {
                      showErrorToast(err);
                    }
                  }}
                />
              </BaselaneResponsiveCellText>
            </BaselaneResponsiveTableRow>
          )}
        />
      </Tbody>
    </BaselaneResponsiveTable>
  );
};

export default TaxFormsTable;
