import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import {
  EmptyPage,
  FirebaseOtpLayoutWrapper,
  PageWrapper,
  UnitOtpLayoutWrapper,
} from '@shared/layouts';
import { Activity } from '@pages/Transfers/components/Activity/activity';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { ForgotPassword, ResetPassword } from '@pages/ForgotPassword';
import { Recipients } from '@pages/Transfers/components/Recipients';
import { Referral } from '@pages';
import { RocketLawyerDocumentDrawer } from '@core/components/Shared/components';
import { ScheduledPayments } from '@pages/Transfers/components/ScheduledPayments/index';
import { TransferDetails } from '@pages/Transfers/components/Activity/TransferDetails';
import { TransfersPage } from '@pages/Transfers/Transfers';
import { UpdateAllReceivingAccounts } from '@pages/LeasesPage/UpdateAllReceivingAccounts/UpdateAllReceivingAccounts';
import AccessDeniedAlert from '@core/components/App/components/AccessDeniedAlert';
import Accounts from '@core/components/NativeBankingPage/MainContent/components/Accounts';
import AddAccountDrawer from '@pages/BankEntity/AddAccount/AddAccountDrawer';
import AddAccountsDrawer from '@shared/components/AddAccountsDrawer';
import AddBaselaneAccount from '@core/components/NativeBankingPage/components/AddBaselaneAccount';
import AddFunds from '@pages/Transfers/components/AddFunds';
import AddRecipient from '@pages/Transfers/components/Recipients/AddRecipient';
import AddVirtualCard from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/AddVirtualCard';
import AICategorizationSettings from '@pages/AutoTaggingRulesPage/components/AICategorizationRule/AICategorizationSettings';
import AlternatePhonePage from '@core/components/PhoneVerification/AlternatePhonePage';
import AutoTaggingRulesPage from '@pages/AutoTaggingRulesPage';
import AutoTaggingPropertyDrawer from '@pages/AutoTaggingRulesPage/components/AutoTaggingPropertyDrawer';
import BankEntityPage from '@pages/BankEntity';
import BankTransfer from '@pages/Transfers/components/AddFunds/components/BankTransfer';
import Cards from '@core/components/NativeBankingPage/MainContent/components/Cards';
import CardsTable from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsTable';
import CashFlowNewPage from '@core/components/CashFlowPage';
import CheckbookDrawer from '@pages/BankEntity/OrderCheckbook';
import CheckDepositDrawer from '@pages/Transfers/components/AddFunds/check-deposit/CheckDepositDrawer';
import CollaboratorInfoPage from '@core/components/CollaboratorInfoPage';
import CreateAutoTagRuleDrawer from '@core/components/CreateAutoTagRuleDrawer';
import CreateWorkspace from '@core/components/WorkspacePage/WorkspaceTabs/components/CreateWorkspace';
import DashboardFeatureGateGuard from '@core/components/App/components/guards/DashboardFeatureGateGuard';
import DashboardRoutes from '@core/components/App/routes/DashboardRoutes';
import DeferredSignUpLogic from '@pages/DeferredSignupPropertySurvey/logic/DeferredSignupLogic';
import DigitalWallet from '@pages/Transfers/components/AddFunds/components/DigitalWallet';
import EditCollaboratorAccessDrawer from '@core/components/WorkspacePage/WorkspaceTabs/components/EditCollaboratorAccessDrawer';
import EditMailingAddressDrawer from '@pages/BankEntity/EditEntityDrawer/EditMailingAddress';
import EditMasterAccountDrawer from '@pages/BankEntity/EditEntityDrawer';
import EmailValidation from '@pages/DeferredSignupPropertySurvey/components/EmailValidation';
import ExpirationError from '@pages/LoginPage/components/ExpirationError';
import ExternalAccountsPage from '@pages/ExternalAccountsPage';
import GenericAlert from '@pages/LoginPage/components/GenericAlert';
import GetStartedPage from '@core/components/GetStartedPage';
import InactivityTimeout from '@features/Authentication/components/InactivityTimeout';
import InsurancePage from '@core/components/InsurancePage';
import InviteCollaboratorDrawer from '@core/components/WorkspacePage/WorkspaceTabs/InviteCollaborator/InviteCollaboratorDrawer';
import LeasesPage from '@pages/LeasesPage';
import LoansPage from '@pages/LoansPage';
import LoginError from '@pages/LoginPage/components/LoginError';
import LoginPage from '@pages/LoginPage';
import MaintenancePropertyDrawer from '@features/Property/MaintenancePropertyDrawer';
import MemberDetailsDrawer from '@core/components/WorkspacePage/WorkspaceTabs/MemberDetailsDrawer';
import MyAccountsPanel from '@pages/ExternalAccountsPage/components/MyAccountsPanel';
import NativeBankingPage from '@core/components/NativeBankingPage';
import OnboardingFlowsRoutes from '@core/components/App/routes/OnboardingFlowsRoutes';
import OwnerProfileEmailDrawer from '@core/components/WorkspacePage/WorkspaceTabs/components/VerifyWorkspaceOwner/OwnerProfileEmailDrawer';
import OwnerProfileNameDrawer from '@core/components/WorkspacePage/WorkspaceTabs/components/VerifyWorkspaceOwner/OwnerProfileNameDrawer';
import OwnerProfilePhoneDrawer from '@core/components/WorkspacePage/WorkspaceTabs/components/VerifyWorkspaceOwner/OwnerProfilePhoneDrawer';
import Partnerships from '@pages/Partnerships';
import PaymentMethodDelete from '@pages/Transfers/components/Recipients/components/modals/PaymentMethodDelete';
import PaymentMethodDetailsACH from '@pages/Transfers/components/Recipients/components/drawers/PaymentMethodDetailsACH';
import PaymentMethodDetailsCheck from '@pages/Transfers/components/Recipients/components/drawers/PaymentMethodDetailsCheck';
import PaymentMethodDetailsWire from '@pages/Transfers/components/Recipients/components/drawers/PaymentMethodDetailsWire';
import PortfolioKPIsPage from '@core/components/PortfolioKPIsPage';
import PropertiesPage from '@pages/PropertiesPage';
import PropertiesResponsivePage from '@pages/PropertiesResponsivePage';
import QuickPayLearnMoreDrawer from '@shared/components/QuickPayLearnMoreDrawer';
import RecipientDetails from '@pages/Transfers/components/Recipients/RecipientDetails';
import RecipientDetailsDelete from '@pages/Transfers/components/Recipients/components/modals/RecipientDetailsDelete';
import RecipientDetailsFormEdit from '@pages/Transfers/components/Recipients/components/RecipientDetailsFormEdit';
import Redeem from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsSummary/Redeem';
import RenterSignUpInfo from '@pages/DeferredSignupPropertySurvey/components/RenterSignUpInfo';
import ScheduledPaymentDetailsDrawer from '@pages/Transfers/components/ScheduledPayments/scheduled-payment-details/ScheduledPaymentDetailsDrawer';
import ScheduledTransferDetails from '@pages/Transfers/components/ScheduledPayments/scheduled-transfer-details/ScheduledTransferDetails';
import SendFunds from '@pages/Transfers/components/SendFunds';
import SessionExpiredMessage from '@pages/LoginPage/components/SessionExpiredMessage';
import Statements from '@core/components/NativeBankingPage/MainContent/components/Statements';
import TaxForms from '@core/components/NativeBankingPage/MainContent/components/TaxForms';
import TenantScreening from '@pages/TenantScreening';
import TenantsPage from '@pages/TenantsPage';
import TransactionDetailsDrawer from '@core/components/Transactions/transaction-details-drawer/TransactionDetailsDrawer';
import TransactionRoutes from '@core/components/App/routes/TransactionRoutes';
import TransactionsPage from '@core/components/Transactions';
import TransferFundsFeatureGuard from '@shared/components/TransferFundsDrawer/TransferFundsFeatureGuard';
import TransferMethodSelector from '@pages/Transfers/components/AddFunds/components/TransferMethodSelector';
import UnverifiedUserPage from '@core/components/UnverifiedUserPage';
import UpdateBankAccountDrawer from '@shared/components/UpdateBankAccountDrawer';
import useBreakPoints from '@core/hooks/useBreakPoints';
import UserProfilePage from '@core/components/UserProfilePage';
import VerifiedUserPage from '@core/components/VerifiedUserPage';
import WorkspaceNoAccess from '@pages/LoginPage/components/WorkspaceNoAccess';
import WorkspacePage from '@core/components/WorkspacePage';
import CreateLeaseAgreementPage from '@core/pages/LeaseAgreement/CreateLeaseAgreement/index';
import LeaseAgreement from '@core/pages/LeaseAgreement/index';
import LeaseAgreementDetailsDrawer from '@core/pages/LeaseAgreement/LeaseAgreementDetailsDrawer';
import LeaseAgreementToLeaseDrawer from '@pages/LeaseAgreement/LeaseAgreementToLeaseDrawer';
import LeaseToLeaseAgreementDrawer from '@pages/LeaseAgreement/LeaseToLeaseAgreementDrawer';
import {
  ACCESS_DENIED,
  ADD_ACCOUNT,
  ADD_BASELANE_BANK_ACCOUNT,
  ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS,
  ADD_FUNDS_BANK_TRANSFER,
  ADD_FUNDS_CHECK_DEPOSIT,
  ADD_FUNDS_DIGITAL_WALLET,
  ADD_FUNDS_TRANSFER_METHOD_SELECTOR,
  ADD_FUNDS,
  ADD_VIRTUAL_ACCOUNT,
  ADD_VIRTUAL_CARD,
  ANALYTICS_REPORTING,
  BOOKKEEPING_RULES_AI_CATEGORIZATION_SETTINGS,
  BOOKKEEPING_RULES_CREATE,
  BOOKKEEPING_RULES_ID,
  BOOKKEEPING_RULES_PROPERTY_RULES,
  BOOKKEEPING_RULES,
  CASH_FLOW_STATEMENT,
  CASH_FLOW_TAX_PACKAGE,
  CREATE_LEASE_DOCUMENT,
  CREATE_WORKSPACE,
  CSV_IMPORT,
  DASHBOARD,
  EA_MY_ACCOUNTS,
  EDIT_ENTITY_ACCOUNT,
  EDIT_ENTITY_ADDRESS,
  EMAIL_EXISTS,
  EXPIRED_INVITATION,
  EXTERNAL_ACCOUNTS,
  FORGOT_PASSWORD,
  GET_STARTED,
  HOME,
  INSURANCE,
  KPIS,
  LEASES_QUICKPAY_LEARN_MORE,
  NEW_LEASE_AGREEMENT,
  LEASES_UPDATE_ACCOUNTS,
  LEASES,
  LOANS,
  LOGIN_ERROR,
  LOGIN,
  MAINTENANCE_REQUEST,
  NATIVE_BANK,
  NB_ACCOUNTS,
  NB_BANK_ACCOUNT_CARDS,
  NB_BANK_ACCOUNT,
  NB_CARDS,
  NB_STATEMENT_DOCUMENTS,
  NB_TAX_FORMS,
  NO_WORKSPACE_ACCESS,
  ORDER_CHECKBOOK,
  PARTNERSHIPS,
  PHONE_REQUIRED,
  PROPERTIES,
  REDEEM_CASHBACK,
  REFERRAL,
  RESET_PASSWORD,
  SCHEDULED_PAYMENT_DETAILS,
  SCHEDULED_TRANSFER_DETAILS,
  SEND_FUNDS,
  SESSION_EXPIRED,
  SIGN_UP_AS_RENTER,
  SIGN_UP,
  SOMETHING_WENT_WRONG,
  TENANT_SCREENING,
  TENANTS,
  TRANSACTION_ID,
  TRANSACTION,
  TRANSACTIONS,
  TRANSFER_FUNDS,
  TRANSFERS_ACTIVITY,
  TRANSFERS_DETAILS,
  TRANSFERS_PAYMENT_METHOD_ACH,
  TRANSFERS_PAYMENT_METHOD_CHECK,
  TRANSFERS_PAYMENT_METHOD_DELETE,
  TRANSFERS_PAYMENT_METHOD_WIRE,
  TRANSFERS_PAYMENTS,
  TRANSFERS_RECIPIENT_ADD,
  TRANSFERS_RECIPIENT_DELETE,
  TRANSFERS_RECIPIENTS_DETAILS_EDIT,
  TRANSFERS_RECIPIENTS_DETAILS,
  TRANSFERS_RECIPIENTS,
  TRANSFERS_SCHEDULED,
  UNVERIFIED,
  USER_COLLABORATOR_INFO,
  USER_PROFILE_UPDATE_ACCOUNTS,
  USER_PROFILE,
  USER_WORKSPACE_INVITECOLLABORATOR,
  USER_WORKSPACE_MEMBER_DETAILS,
  USER_WORKSPACE_UPDATE_COLLABORATOR_ACCESS,
  USER_WORKSPACE,
  VERIFIED,
  WORKSPACE_OWNER_VERIFY_EMAIL,
  WORKSPACE_OWNER_VERIFY_NAME,
  WORKSPACE_OWNER_VERIFY_PHONE,
  CREATE_LEASE_AGREEMENT,
  LEASE_AGREEMENT,
  LEASE_AGREEMENT_DETAILS,
  LEASE_AGREEMENT_TO_LEASE,
  LEASE_TO_LEASE_AGREEMENT,
} from '@routes';

import NewLeaseAgreement from '@core/pages/LeaseAgreement/components/NewLeaseAgreement';
import AuthenticationGuard from '../components/guards/AuthenticationGuard';
import BankWrapper from '../components/wrappers/BankWrapper';
import CashFlowWrapper from '../components/wrappers/CashflowWrapper';
import CSVImportRoutes from './CSVImportRoutes';
import EmailInviteCodeLogic from '../components/logic/EmailInviteCodeLogic';
import FeatureGateGuard from '../components/guards/FeatureGateGuard';
import FirebaseOtpWrapper from '../components/wrappers/FirebaseOtpWrapper';
import HomepageGateGuard from '../components/guards/HomepageGateGuard';
import InitializeAnonymously from '../components/wrappers/InitializeAnonymously';
import InitializeWithUser from '../components/wrappers/InitializeWithUser';
import LoginNavigationLogic from '../components/logic/LoginNavigationLogic';
import OnboardingCompletedLogic from '../components/logic/OnboardingCompletedLogic';
import SignupTestRedirectLogic from '../components/logic/SignupTestRedirectLogic';
import TenantScreeningRoutes from './TenantScreeningRoutes';
import TransactionWrapper from '../components/wrappers/TransactionWrapper';
import UnitOtpWrapper from '../components/wrappers/UnitOtpWrapper';
import UserAccessWrapper from '../components/wrappers/UserAccessWrapper';
import UserWrapper from '../components/wrappers/UserWrapper';

/**
 * This is a react-router-dom routing file that defines the root of all the possible routes in the app.
 *
 * Note: This file should only contain nested <Routes> and <Route> components.
 * Display logic, business logic, data providers can be defined in various components
 * that are passed via the element property.
 *
 * See: App/components/guards, App/components/logic and App/components/wrappers
 */
const MainRoutes = () => {
  const { isMax767 } = useBreakPoints();

  return (
    <Routes>
      {/* Handle redirects for invite codes */}
      <Route element={<EmailInviteCodeLogic />}>
        {/* Public routes */}
        {/* Initialize third party scripts without user data */}
        <Route element={<InitializeAnonymously />}>
          <Route element={<EmptyPage />}>
            <Route element={<FirebaseOtpWrapper />}>
              <Route element={<FirebaseOtpLayoutWrapper />}>
                <Route exact path={LOGIN} element={<LoginPage />}>
                  <Route exact path={SOMETHING_WENT_WRONG} element={<GenericAlert />} />
                  <Route exact path={SESSION_EXPIRED} element={<SessionExpiredMessage />} />
                </Route>
              </Route>
            </Route>

            <Route path={SIGN_UP}>
              <Route exact path={SIGN_UP} element={<DeferredSignUpLogic />} />
              <Route exact path={EMAIL_EXISTS} element={<EmailValidation />} />
              <Route exact path={SIGN_UP_AS_RENTER} element={<RenterSignUpInfo />} />
            </Route>
            <Route exact path={FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route exact path={RESET_PASSWORD} element={<ResetPassword />} />
            <Route exact path={VERIFIED} element={<VerifiedUserPage />} />
            <Route exact path={UNVERIFIED} element={<UnverifiedUserPage />} />
          </Route>
        </Route>
        {/* Authenticated routes */}
        <Route element={<UserWrapper />}>
          <Route element={<FeatureGateGuard name={FEATURE_GATES.MUA_GATE} />}>
            <Route exact path={NO_WORKSPACE_ACCESS} element={<WorkspaceNoAccess />} />
            <Route exact path={USER_COLLABORATOR_INFO} element={<CollaboratorInfoPage />} />
            <Route exact path={ACCESS_DENIED} element={<AccessDeniedAlert />} />
          </Route>
          {/* Initialize third party scripts with user data */}
          <Route element={<InitializeWithUser />}>
            <Route element={<FeatureGateGuard name={FEATURE_GATES.MUA_GATE} />}>
              <Route exact path={LOGIN_ERROR} element={<LoginError />} />
              <Route exact path={EXPIRED_INVITATION} element={<ExpirationError />} />
              <Route path={CREATE_WORKSPACE} element={<CreateWorkspace />} />
            </Route>
            <Route element={<UserAccessWrapper />}>
              {/* Start FE inactivity timeout code */}
              <Route element={<InactivityTimeout />}>
                {/* Routes that require login for security */}
                <Route element={<AuthenticationGuard />}>
                  <Route element={<FeatureGateGuard name={FEATURE_GATES.LEASE_AGREEMENT_PAGE} />}>
                    <Route path={CREATE_LEASE_AGREEMENT} element={<CreateLeaseAgreementPage />}>
                      <Route
                        path={LEASE_AGREEMENT_TO_LEASE}
                        element={<LeaseAgreementToLeaseDrawer />}
                      />
                    </Route>
                  </Route>
                  {/* Logic for stored redirects */}
                  <Route element={<LoginNavigationLogic />}>
                    {/* Routes for onboarding */}
                    <Route element={<EmptyPage />}>
                      <Route exact path={PHONE_REQUIRED} element={<AlternatePhonePage />} />
                      <Route element={<SignupTestRedirectLogic />}>
                        <Route path="*" element={<OnboardingFlowsRoutes />} />
                      </Route>
                      <Route
                        path={CSV_IMPORT}
                        element={<FeatureGateGuard name={FEATURE_GATES.CSV_GATE} />}
                      >
                        <Route path="*" element={<CSVImportRoutes />} />
                      </Route>
                    </Route>
                    {/* Main Application routes, with navbar */}
                    <Route element={<PageWrapper />}>
                      <Route element={<TransactionWrapper />}>
                        <Route element={<BankWrapper />}>
                          <Route element={<UnitOtpWrapper />}>
                            <Route element={<UnitOtpLayoutWrapper />}>
                              <Route element={<SignupTestRedirectLogic />}>
                                <Route element={<CashFlowWrapper />}>
                                  <Route path={TRANSACTIONS} element={<TransactionsPage />}>
                                    <Route path="*" element={<TransactionRoutes />} />
                                  </Route>
                                </Route>
                                <Route element={<HomepageGateGuard />}>
                                  <Route path={HOME} element={<GetStartedPage />}>
                                    <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                                    <Route
                                      path={CREATE_LEASE_DOCUMENT}
                                      element={<RocketLawyerDocumentDrawer from={HOME} />}
                                    />
                                    <Route
                                      path={ADD_BASELANE_BANK_ACCOUNT}
                                      element={<AddBaselaneAccount from={HOME} />}
                                    >
                                      <Route
                                        path={ADD_VIRTUAL_ACCOUNT}
                                        element={
                                          <AddAccountDrawer
                                            from={`${HOME}/${ADD_BASELANE_BANK_ACCOUNT}`}
                                          />
                                        }
                                      />
                                    </Route>
                                    <Route
                                      path={TRANSFER_FUNDS}
                                      element={<TransferFundsFeatureGuard />}
                                    />
                                    <Route
                                      path={ADD_FUNDS_BANK_TRANSFER}
                                      element={<BankTransfer />}
                                    >
                                      <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                                    </Route>
                                  </Route>
                                  <Route exact path={GET_STARTED} element={<GetStartedPage />}>
                                    <Route
                                      path={ADD_BASELANE_BANK_ACCOUNT}
                                      element={<AddBaselaneAccount from={GET_STARTED} />}
                                    >
                                      <Route
                                        path={ADD_VIRTUAL_ACCOUNT}
                                        element={
                                          <AddAccountDrawer
                                            from={`${GET_STARTED}/${ADD_BASELANE_BANK_ACCOUNT}`}
                                          />
                                        }
                                      />
                                    </Route>
                                    <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                                    <Route
                                      path={ADD_FUNDS_BANK_TRANSFER}
                                      element={<BankTransfer />}
                                    >
                                      <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                                    </Route>
                                  </Route>
                                </Route>
                                <Route path={DASHBOARD} element={<DashboardFeatureGateGuard />}>
                                  <Route path="*" element={<DashboardRoutes />} />
                                </Route>

                                {/* Transfers */}
                                <Route path={TRANSFERS_PAYMENTS} element={<TransfersPage />}>
                                  {/* Transfers / Activity */}
                                  <Route path={TRANSFERS_ACTIVITY} element={<Activity />}>
                                    <Route path={TRANSFERS_DETAILS} element={<TransferDetails />} />
                                    {/* Move money menu routes 1 */}
                                    <Route path={SEND_FUNDS} element={<SendFunds />} />
                                    <Route
                                      path={TRANSFER_FUNDS}
                                      element={<TransferFundsFeatureGuard />}
                                    >
                                      <Route
                                        path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
                                        element={<DigitalWallet id={0} />}
                                      />
                                    </Route>
                                    <Route
                                      path={ADD_FUNDS}
                                      element={<AddFunds />}
                                      from={TRANSFERS_ACTIVITY}
                                    >
                                      <Route
                                        path={ADD_FUNDS_BANK_TRANSFER}
                                        element={<BankTransfer />}
                                      >
                                        <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                                      </Route>
                                      <Route
                                        path={ADD_FUNDS_CHECK_DEPOSIT}
                                        element={<CheckDepositDrawer />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
                                        element={<DigitalWallet id={0} />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_DIGITAL_WALLET}
                                        element={<DigitalWallet id={0} />}
                                      >
                                        <Route
                                          path={ADD_FUNDS_BANK_TRANSFER}
                                          element={<BankTransfer />}
                                        >
                                          <Route
                                            path={ADD_ACCOUNT}
                                            element={<AddAccountsDrawer />}
                                          />
                                        </Route>
                                        <Route
                                          path={ADD_FUNDS_CHECK_DEPOSIT}
                                          element={<CheckDepositDrawer />}
                                        />
                                      </Route>
                                      <Route
                                        path={ADD_FUNDS_TRANSFER_METHOD_SELECTOR}
                                        element={<TransferMethodSelector />}
                                      />
                                    </Route>
                                    {/* end of Move money menu routes 1 */}
                                  </Route>
                                  <Route path={TRANSFERS_RECIPIENTS} element={<Recipients />}>
                                    <Route path={SEND_FUNDS} element={<SendFunds />} />
                                    <Route
                                      path={TRANSFERS_RECIPIENT_ADD}
                                      element={
                                        <AddRecipient
                                          from={`/${TRANSFERS_PAYMENTS}/${TRANSFERS_RECIPIENTS}`}
                                        />
                                      }
                                    >
                                      <Route
                                        path={TRANSFERS_PAYMENT_METHOD_ACH}
                                        element={<PaymentMethodDetailsACH />}
                                      />
                                      <Route
                                        path={TRANSFERS_PAYMENT_METHOD_WIRE}
                                        element={<PaymentMethodDetailsWire />}
                                      />
                                      <Route
                                        path={TRANSFERS_PAYMENT_METHOD_CHECK}
                                        element={<PaymentMethodDetailsCheck />}
                                      />
                                    </Route>
                                    <Route
                                      path={TRANSFERS_RECIPIENTS_DETAILS}
                                      element={
                                        <RecipientDetails
                                          from={`/${TRANSFERS_PAYMENTS}/${TRANSFERS_RECIPIENTS}`}
                                        />
                                      }
                                    >
                                      <Route path={SEND_FUNDS} element={<SendFunds />} />
                                      <Route
                                        path={TRANSFERS_RECIPIENTS_DETAILS_EDIT}
                                        element={<RecipientDetailsFormEdit />}
                                      />
                                      <Route
                                        path={TRANSFERS_RECIPIENT_DELETE}
                                        element={<RecipientDetailsDelete />}
                                      />
                                      <Route
                                        path={TRANSFERS_PAYMENT_METHOD_ACH}
                                        element={<PaymentMethodDetailsACH />}
                                      >
                                        <Route
                                          path={TRANSFERS_PAYMENT_METHOD_DELETE}
                                          element={<PaymentMethodDelete />}
                                        />
                                        <Route
                                          path={TRANSFERS_RECIPIENT_DELETE}
                                          element={<RecipientDetailsDelete />}
                                        />
                                      </Route>
                                      <Route
                                        path={TRANSFERS_PAYMENT_METHOD_WIRE}
                                        element={<PaymentMethodDetailsWire />}
                                      >
                                        <Route
                                          path={TRANSFERS_PAYMENT_METHOD_DELETE}
                                          element={<PaymentMethodDelete />}
                                        />
                                        <Route
                                          path={TRANSFERS_RECIPIENT_DELETE}
                                          element={<RecipientDetailsDelete />}
                                        />
                                      </Route>
                                      <Route
                                        path={TRANSFERS_PAYMENT_METHOD_CHECK}
                                        element={<PaymentMethodDetailsCheck />}
                                      >
                                        <Route
                                          path={TRANSFERS_PAYMENT_METHOD_DELETE}
                                          element={<PaymentMethodDelete />}
                                        />
                                        <Route
                                          path={TRANSFERS_RECIPIENT_DELETE}
                                          element={<RecipientDetailsDelete />}
                                        />
                                      </Route>
                                    </Route>
                                  </Route>
                                  <Route path={TRANSFERS_SCHEDULED} element={<ScheduledPayments />}>
                                    <Route
                                      path={SCHEDULED_PAYMENT_DETAILS}
                                      element={
                                        <ScheduledPaymentDetailsDrawer
                                          from={`/${TRANSFERS_PAYMENTS}/${TRANSFERS_SCHEDULED}`}
                                        />
                                      }
                                    >
                                      <Route
                                        path={SCHEDULED_TRANSFER_DETAILS}
                                        element={<ScheduledTransferDetails />}
                                      />
                                    </Route>
                                    {/* Move money routes */}
                                    <Route path={SEND_FUNDS} element={<SendFunds />} />
                                    <Route
                                      path={TRANSFER_FUNDS}
                                      element={<TransferFundsFeatureGuard />}
                                    />
                                    <Route
                                      path={ADD_FUNDS}
                                      element={<AddFunds />}
                                      from={TRANSFERS_ACTIVITY}
                                    >
                                      <Route
                                        path={ADD_FUNDS_BANK_TRANSFER}
                                        element={<BankTransfer />}
                                      >
                                        <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                                      </Route>
                                      <Route
                                        path={ADD_FUNDS_CHECK_DEPOSIT}
                                        element={<CheckDepositDrawer />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
                                        element={<DigitalWallet id={parseInt('0', 10)} />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_DIGITAL_WALLET}
                                        element={<DigitalWallet id={parseInt('1', 10)} />}
                                      >
                                        <Route
                                          path={ADD_FUNDS_BANK_TRANSFER}
                                          element={<BankTransfer />}
                                        >
                                          <Route
                                            path={ADD_ACCOUNT}
                                            element={<AddAccountsDrawer />}
                                          />
                                        </Route>
                                        <Route
                                          path={ADD_FUNDS_CHECK_DEPOSIT}
                                          element={<CheckDepositDrawer />}
                                        />
                                      </Route>
                                      <Route
                                        path={ADD_FUNDS_TRANSFER_METHOD_SELECTOR}
                                        element={<TransferMethodSelector />}
                                      />
                                    </Route>
                                  </Route>
                                </Route>

                                {/* Baselane banking page */}
                                <Route path={NATIVE_BANK} element={<NativeBankingPage />}>
                                  <Route
                                    path={NATIVE_BANK}
                                    element={<Navigate to={NB_ACCOUNTS} />}
                                  />

                                  <Route path={NB_ACCOUNTS} element={<Accounts />}>
                                    <Route
                                      path={ADD_FUNDS_BANK_TRANSFER}
                                      element={<BankTransfer />}
                                    >
                                      <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                                    </Route>
                                    {/* Move money menu routes 2 */}
                                    <Route path={SEND_FUNDS} element={<SendFunds />} />
                                    <Route
                                      path={TRANSFER_FUNDS}
                                      element={<TransferFundsFeatureGuard />}
                                    />
                                    <Route path={ADD_FUNDS} element={<AddFunds />}>
                                      <Route
                                        path={ADD_FUNDS_BANK_TRANSFER}
                                        element={<BankTransfer />}
                                      >
                                        <Route
                                          path={ADD_ACCOUNT}
                                          element={<AddAccountsDrawer from={ANALYTICS_REPORTING} />}
                                        />
                                      </Route>
                                      <Route
                                        path={ADD_FUNDS_CHECK_DEPOSIT}
                                        element={<CheckDepositDrawer />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
                                        element={<DigitalWallet id={0} />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_DIGITAL_WALLET}
                                        element={<DigitalWallet id={0} />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_TRANSFER_METHOD_SELECTOR}
                                        element={<TransferMethodSelector />}
                                      />
                                    </Route>
                                    {/* end of Move money menu routes 2 */}
                                    <Route
                                      path={REDEEM_CASHBACK}
                                      element={<Redeem from={NB_ACCOUNTS} />}
                                    />
                                    <Route
                                      path={ADD_BASELANE_BANK_ACCOUNT}
                                      element={<AddBaselaneAccount from={NB_ACCOUNTS} />}
                                    >
                                      <Route
                                        path={ADD_VIRTUAL_ACCOUNT}
                                        element={
                                          <AddAccountDrawer
                                            from={`${NB_ACCOUNTS}/${ADD_BASELANE_BANK_ACCOUNT}`}
                                          />
                                        }
                                      />
                                    </Route>
                                  </Route>
                                  <Route path={NB_CARDS} element={<Cards />}>
                                    {/* Move money menu routes 3 */}
                                    <Route path={SEND_FUNDS} element={<SendFunds />} />
                                    <Route
                                      path={TRANSFER_FUNDS}
                                      element={<TransferFundsFeatureGuard />}
                                    />
                                    <Route path={ADD_FUNDS} element={<AddFunds />}>
                                      <Route
                                        path={ADD_FUNDS_BANK_TRANSFER}
                                        element={<BankTransfer />}
                                      >
                                        <Route
                                          path={ADD_ACCOUNT}
                                          element={
                                            <AddAccountsDrawer from={`${ANALYTICS_REPORTING}`} />
                                          }
                                        />
                                      </Route>
                                      <Route
                                        path={ADD_FUNDS_CHECK_DEPOSIT}
                                        element={<CheckDepositDrawer />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
                                        element={<DigitalWallet id={0} />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_DIGITAL_WALLET}
                                        element={<DigitalWallet id={0} />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_TRANSFER_METHOD_SELECTOR}
                                        element={<TransferMethodSelector />}
                                      />
                                    </Route>
                                    {/* end of Move money menu routes 3 */}
                                    <Route
                                      path={ADD_FUNDS_BANK_TRANSFER}
                                      element={<BankTransfer />}
                                    >
                                      <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                                    </Route>
                                    <Route path={ADD_VIRTUAL_CARD} element={<AddVirtualCard />} />
                                    <Route
                                      path={REDEEM_CASHBACK}
                                      element={<Redeem from={NB_CARDS} />}
                                    />
                                    <Route
                                      path={ADD_BASELANE_BANK_ACCOUNT}
                                      element={<AddBaselaneAccount from={NB_CARDS} />}
                                    >
                                      <Route
                                        path={ADD_VIRTUAL_ACCOUNT}
                                        element={
                                          <AddAccountDrawer
                                            from={`${NB_CARDS}/${ADD_BASELANE_BANK_ACCOUNT}`}
                                          />
                                        }
                                      />
                                    </Route>
                                  </Route>
                                  <Route path={NB_STATEMENT_DOCUMENTS} element={<Statements />}>
                                    {/* Move money menu routes 4 */}
                                    <Route path={SEND_FUNDS} element={<SendFunds />} />
                                    <Route
                                      path={TRANSFER_FUNDS}
                                      element={<TransferFundsFeatureGuard />}
                                    />
                                    <Route path={ADD_FUNDS} element={<AddFunds />}>
                                      <Route
                                        path={ADD_FUNDS_BANK_TRANSFER}
                                        element={<BankTransfer />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_CHECK_DEPOSIT}
                                        element={<CheckDepositDrawer />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
                                        element={<DigitalWallet id={0} />}
                                      >
                                        <Route
                                          path={ADD_FUNDS_BANK_TRANSFER}
                                          element={<BankTransfer />}
                                        />
                                        <Route
                                          path={ADD_FUNDS_CHECK_DEPOSIT}
                                          element={<CheckDepositDrawer />}
                                        />
                                      </Route>
                                      <Route
                                        path={ADD_FUNDS_DIGITAL_WALLET}
                                        element={<DigitalWallet id={0} />}
                                      >
                                        <Route
                                          path={ADD_FUNDS_BANK_TRANSFER}
                                          element={<BankTransfer />}
                                        />
                                        <Route
                                          path={ADD_FUNDS_CHECK_DEPOSIT}
                                          element={<CheckDepositDrawer />}
                                        />
                                      </Route>
                                      <Route
                                        path={ADD_FUNDS_TRANSFER_METHOD_SELECTOR}
                                        element={<TransferMethodSelector />}
                                      />
                                    </Route>
                                    {/* end of Move money menu routes 4 */}
                                    <Route
                                      path={ADD_FUNDS_BANK_TRANSFER}
                                      element={<BankTransfer />}
                                    >
                                      <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                                    </Route>
                                    <Route
                                      path={REDEEM_CASHBACK}
                                      element={<Redeem from={NB_STATEMENT_DOCUMENTS} />}
                                    />
                                    <Route
                                      path={ADD_BASELANE_BANK_ACCOUNT}
                                      element={<AddBaselaneAccount from={NB_STATEMENT_DOCUMENTS} />}
                                    >
                                      <Route
                                        path={ADD_VIRTUAL_ACCOUNT}
                                        element={
                                          <AddAccountDrawer
                                            from={`${NB_STATEMENT_DOCUMENTS}/${ADD_BASELANE_BANK_ACCOUNT}`}
                                          />
                                        }
                                      />
                                    </Route>
                                  </Route>
                                  <Route path={NB_TAX_FORMS} element={<TaxForms />}>
                                    {/* Move money menu routes 5 */}
                                    <Route path={SEND_FUNDS} element={<SendFunds />} />
                                    <Route
                                      path={TRANSFER_FUNDS}
                                      element={<TransferFundsFeatureGuard />}
                                    />
                                    <Route path={ADD_FUNDS} element={<AddFunds />}>
                                      <Route
                                        path={ADD_FUNDS_BANK_TRANSFER}
                                        element={<BankTransfer />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_CHECK_DEPOSIT}
                                        element={<CheckDepositDrawer />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
                                        element={<DigitalWallet id={0} />}
                                      >
                                        <Route
                                          path={ADD_FUNDS_BANK_TRANSFER}
                                          element={<BankTransfer />}
                                        />
                                        <Route
                                          path={ADD_FUNDS_CHECK_DEPOSIT}
                                          element={<CheckDepositDrawer />}
                                        />
                                      </Route>
                                      <Route
                                        path={ADD_FUNDS_DIGITAL_WALLET}
                                        element={<DigitalWallet id={0} />}
                                      >
                                        <Route
                                          path={ADD_FUNDS_BANK_TRANSFER}
                                          element={<BankTransfer />}
                                        />
                                        <Route
                                          path={ADD_FUNDS_CHECK_DEPOSIT}
                                          element={<CheckDepositDrawer />}
                                        />
                                      </Route>
                                      <Route
                                        path={ADD_FUNDS_TRANSFER_METHOD_SELECTOR}
                                        element={<TransferMethodSelector />}
                                      />
                                    </Route>
                                    {/* end of Move money menu routes 5 */}
                                    <Route
                                      path={ADD_FUNDS_BANK_TRANSFER}
                                      element={<BankTransfer />}
                                    >
                                      <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                                    </Route>
                                    <Route
                                      path={REDEEM_CASHBACK}
                                      element={<Redeem from={NB_TAX_FORMS} />}
                                    />
                                    <Route
                                      path={ADD_BASELANE_BANK_ACCOUNT}
                                      element={<AddBaselaneAccount from={NB_TAX_FORMS} />}
                                    >
                                      <Route
                                        path={ADD_VIRTUAL_ACCOUNT}
                                        element={
                                          <AddAccountDrawer
                                            from={`${NB_TAX_FORMS}/${ADD_BASELANE_BANK_ACCOUNT}`}
                                          />
                                        }
                                      />
                                    </Route>
                                  </Route>
                                </Route>
                                {/* Baselane entity page */}
                                <Route path={NB_BANK_ACCOUNT} element={<BankEntityPage />}>
                                  <Route
                                    path={`${TRANSACTION}/${TRANSACTION_ID}`}
                                    element={<TransactionDetailsDrawer from={NB_ACCOUNTS} />}
                                  />

                                  {/* Move money menu routes 6 */}
                                  <Route path={SEND_FUNDS} element={<SendFunds />} />
                                  <Route path={ADD_FUNDS} element={<AddFunds />}>
                                    <Route
                                      path={ADD_FUNDS_BANK_TRANSFER}
                                      element={<BankTransfer />}
                                    />
                                    <Route
                                      path={ADD_FUNDS_CHECK_DEPOSIT}
                                      element={<CheckDepositDrawer />}
                                    />
                                    <Route
                                      path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
                                      element={<DigitalWallet id={0} />}
                                    >
                                      <Route
                                        path={ADD_FUNDS_BANK_TRANSFER}
                                        element={<BankTransfer />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_CHECK_DEPOSIT}
                                        element={<CheckDepositDrawer />}
                                      />
                                    </Route>
                                    <Route
                                      path={ADD_FUNDS_DIGITAL_WALLET}
                                      element={<DigitalWallet id={0} />}
                                    >
                                      <Route
                                        path={ADD_FUNDS_BANK_TRANSFER}
                                        element={<BankTransfer />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_CHECK_DEPOSIT}
                                        element={<CheckDepositDrawer />}
                                      />
                                    </Route>
                                    <Route
                                      path={ADD_FUNDS_TRANSFER_METHOD_SELECTOR}
                                      element={<TransferMethodSelector />}
                                    />
                                  </Route>
                                  {/* end of Move money menu routes 6 */}
                                  <Route path={NB_BANK_ACCOUNT_CARDS} element={<CardsTable />}>
                                    {/* Move money menu routes 7 */}
                                    <Route path={SEND_FUNDS} element={<SendFunds />} />
                                    <Route
                                      path={TRANSFER_FUNDS}
                                      element={<TransferFundsFeatureGuard />}
                                    />
                                    <Route path={ADD_FUNDS} element={<AddFunds />}>
                                      <Route
                                        path={ADD_FUNDS_BANK_TRANSFER}
                                        element={<BankTransfer />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_CHECK_DEPOSIT}
                                        element={<CheckDepositDrawer />}
                                      />
                                      <Route
                                        path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
                                        element={<DigitalWallet id={0} />}
                                      >
                                        <Route
                                          path={ADD_FUNDS_BANK_TRANSFER}
                                          element={<BankTransfer />}
                                        />
                                        <Route
                                          path={ADD_FUNDS_CHECK_DEPOSIT}
                                          element={<CheckDepositDrawer />}
                                        />
                                      </Route>
                                      <Route
                                        path={ADD_FUNDS_DIGITAL_WALLET}
                                        element={<DigitalWallet id={1} />}
                                      >
                                        <Route
                                          path={ADD_FUNDS_BANK_TRANSFER}
                                          element={<BankTransfer />}
                                        />
                                        <Route
                                          path={ADD_FUNDS_CHECK_DEPOSIT}
                                          element={<CheckDepositDrawer />}
                                        />
                                      </Route>
                                      <Route
                                        path={ADD_FUNDS_TRANSFER_METHOD_SELECTOR}
                                        element={<TransferMethodSelector />}
                                      />
                                    </Route>
                                    {/* end of Move money menu routes 7 */}
                                    <Route
                                      path={ADD_FUNDS_BANK_TRANSFER}
                                      element={<BankTransfer />}
                                    >
                                      <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                                    </Route>
                                    <Route
                                      path={ORDER_CHECKBOOK}
                                      element={<CheckbookDrawer from={NB_BANK_ACCOUNT} />}
                                    />
                                    <Route
                                      path={EDIT_ENTITY_ACCOUNT}
                                      element={<EditMasterAccountDrawer />}
                                    >
                                      <Route
                                        path={EDIT_ENTITY_ADDRESS}
                                        element={<EditMailingAddressDrawer />}
                                      />
                                    </Route>
                                    <Route path={ADD_VIRTUAL_CARD} element={<AddVirtualCard />} />
                                    <Route
                                      path={ADD_VIRTUAL_ACCOUNT}
                                      element={<AddAccountDrawer />}
                                    />
                                  </Route>
                                  <Route
                                    path={ORDER_CHECKBOOK}
                                    element={<CheckbookDrawer from={NB_BANK_ACCOUNT} />}
                                  />
                                  <Route
                                    path={EDIT_ENTITY_ACCOUNT}
                                    element={<EditMasterAccountDrawer />}
                                  >
                                    <Route
                                      path={EDIT_ENTITY_ADDRESS}
                                      element={<EditMailingAddressDrawer />}
                                    />
                                  </Route>
                                  <Route path={ADD_VIRTUAL_CARD} element={<AddVirtualCard />} />
                                  <Route
                                    path={ADD_VIRTUAL_ACCOUNT}
                                    element={<AddAccountDrawer from={NB_BANK_ACCOUNT} />}
                                  />
                                  <Route
                                    path={TRANSFER_FUNDS}
                                    element={<TransferFundsFeatureGuard />}
                                  />
                                </Route>
                                <Route path={EXTERNAL_ACCOUNTS} element={<ExternalAccountsPage />}>
                                  <Route
                                    exact
                                    path={EA_MY_ACCOUNTS}
                                    element={<MyAccountsPanel />}
                                  />
                                </Route>
                                <Route path={LEASES} element={<LeasesPage />}>
                                  <Route
                                    path={LEASES_UPDATE_ACCOUNTS}
                                    element={<UpdateAllReceivingAccounts />}
                                  />
                                  <Route
                                    path={LEASES_QUICKPAY_LEARN_MORE}
                                    element={<QuickPayLearnMoreDrawer />}
                                  />
                                  <Route
                                    path={LEASE_TO_LEASE_AGREEMENT}
                                    element={<LeaseToLeaseAgreementDrawer />}
                                  />
                                </Route>
                                <Route
                                  exact
                                  path={PROPERTIES}
                                  element={
                                    isMax767 ? <PropertiesResponsivePage /> : <PropertiesPage />
                                  }
                                >
                                  <Route
                                    element={<FeatureGateGuard name={FEATURE_GATES.LATCHEL_GATE} />}
                                  >
                                    <Route
                                      path={MAINTENANCE_REQUEST}
                                      element={
                                        <MaintenancePropertyDrawer from={`/${PROPERTIES}`} />
                                      }
                                    />
                                  </Route>
                                </Route>
                                <Route element={<CashFlowWrapper />}>
                                  <Route path={ANALYTICS_REPORTING} element={<CashFlowNewPage />}>
                                    <Route
                                      path={`${TRANSACTION}/${TRANSACTION_ID}`}
                                      element={
                                        <TransactionDetailsDrawer from={ANALYTICS_REPORTING} />
                                      }
                                    >
                                      <Route
                                        path={BOOKKEEPING_RULES_ID}
                                        element={<CreateAutoTagRuleDrawer />}
                                      />
                                    </Route>
                                    <Route
                                      path={ADD_ACCOUNT}
                                      element={
                                        <AddAccountsDrawer from={`${ANALYTICS_REPORTING}`} />
                                      }
                                    />
                                  </Route>
                                  <Route
                                    exact
                                    path={CASH_FLOW_STATEMENT}
                                    element={<CashFlowNewPage />}
                                  >
                                    <Route
                                      path={`${TRANSACTION}/${TRANSACTION_ID}`}
                                      element={
                                        <TransactionDetailsDrawer from={ANALYTICS_REPORTING} />
                                      }
                                    />
                                  </Route>
                                  <Route
                                    exact
                                    path={CASH_FLOW_TAX_PACKAGE}
                                    element={<CashFlowNewPage />}
                                  />
                                </Route>
                                <Route
                                  element={
                                    <FeatureGateGuard name={FEATURE_GATES.TENANT_SCREENING_GATE} />
                                  }
                                >
                                  <Route path={TENANT_SCREENING} element={<TenantScreening />}>
                                    <Route path="*" element={<TenantScreeningRoutes />} />
                                  </Route>
                                </Route>
                                {/* Lease aggrement  */}
                                <Route
                                  element={
                                    <FeatureGateGuard name={FEATURE_GATES.LEASE_AGREEMENT_PAGE} />
                                  }
                                >
                                  <Route path={LEASE_AGREEMENT} element={<LeaseAgreement />}>
                                    <Route
                                      path={LEASE_AGREEMENT_DETAILS}
                                      element={<LeaseAgreementDetailsDrawer />}
                                    >
                                      <Route
                                        path={LEASE_AGREEMENT_TO_LEASE}
                                        element={<LeaseAgreementToLeaseDrawer />}
                                      />
                                    </Route>
                                    <Route
                                      path={NEW_LEASE_AGREEMENT}
                                      element={<NewLeaseAgreement />}
                                    />
                                    <Route
                                      path={LEASE_AGREEMENT_TO_LEASE}
                                      element={<LeaseAgreementToLeaseDrawer />}
                                    />
                                  </Route>
                                  <Route
                                    path={NEW_LEASE_AGREEMENT}
                                    element={<NewLeaseAgreement />}
                                  />
                                </Route>
                                {/* End of Lease aggrement  */}
                                <Route path={USER_PROFILE} element={<UserProfilePage />}>
                                  <Route
                                    path={USER_PROFILE_UPDATE_ACCOUNTS}
                                    element={<UpdateBankAccountDrawer />}
                                  />
                                </Route>
                                <Route element={<FeatureGateGuard name={FEATURE_GATES.MUA_GATE} />}>
                                  <Route path={USER_WORKSPACE} element={<WorkspacePage />}>
                                    <Route
                                      path={USER_PROFILE_UPDATE_ACCOUNTS}
                                      element={<UpdateBankAccountDrawer />}
                                    />
                                    <Route
                                      path={USER_WORKSPACE_INVITECOLLABORATOR}
                                      element={<InviteCollaboratorDrawer />}
                                    />

                                    <Route
                                      path={USER_WORKSPACE_MEMBER_DETAILS}
                                      element={<MemberDetailsDrawer />}
                                    >
                                      <Route
                                        path={USER_WORKSPACE_UPDATE_COLLABORATOR_ACCESS}
                                        element={<EditCollaboratorAccessDrawer />}
                                      />
                                    </Route>
                                    <Route
                                      path={WORKSPACE_OWNER_VERIFY_NAME}
                                      element={<OwnerProfileNameDrawer />}
                                    >
                                      <Route
                                        path={WORKSPACE_OWNER_VERIFY_EMAIL}
                                        element={<OwnerProfileEmailDrawer />}
                                      >
                                        <Route
                                          path={WORKSPACE_OWNER_VERIFY_PHONE}
                                          element={<OwnerProfilePhoneDrawer />}
                                        />
                                      </Route>
                                    </Route>
                                  </Route>
                                </Route>
                              </Route>
                              <Route
                                element={
                                  <FeatureGateGuard name={FEATURE_GATES.AUTO_TAGGING_GATE} />
                                }
                              >
                                <Route
                                  exact
                                  path={BOOKKEEPING_RULES}
                                  element={<AutoTaggingRulesPage />}
                                >
                                  <Route
                                    path={BOOKKEEPING_RULES_CREATE}
                                    element={<CreateAutoTagRuleDrawer />}
                                  />
                                  <Route
                                    path={BOOKKEEPING_RULES_ID}
                                    element={<CreateAutoTagRuleDrawer />}
                                  />
                                  <Route
                                    path={BOOKKEEPING_RULES_AI_CATEGORIZATION_SETTINGS}
                                    element={<AICategorizationSettings />}
                                  />
                                  <Route
                                    path={BOOKKEEPING_RULES_PROPERTY_RULES}
                                    element={<AutoTaggingPropertyDrawer />}
                                  />
                                  <Route
                                    path={ADD_BASELANE_BANK_ACCOUNT}
                                    element={<AddBaselaneAccount from={`/${BOOKKEEPING_RULES}`} />}
                                  >
                                    <Route
                                      path={ADD_VIRTUAL_ACCOUNT}
                                      // from is null so that "Send Funds" at the end of the flow
                                      // redirects to the banking page
                                      element={<AddAccountDrawer from={null} />}
                                    />
                                  </Route>
                                </Route>
                              </Route>
                            </Route>
                          </Route>
                        </Route>
                        <Route
                          element={
                            <FeatureGateGuard name={FEATURE_GATES.PROPERTY_METRICS_PAGE_GATE} />
                          }
                        >
                          <Route exact path={KPIS} element={<PortfolioKPIsPage />} />
                        </Route>
                        <Route exact path={TENANTS} element={<TenantsPage />} />
                        <Route
                          element={<FeatureGateGuard name={FEATURE_GATES.TENANT_SCREENING_GATE} />}
                        >
                          <Route exact path={TENANT_SCREENING} element={<TenantScreening />} />
                        </Route>
                        <Route exact path={REFERRAL} element={<Referral />} />
                        <Route exact path={INSURANCE} element={<InsurancePage />} />
                        <Route exact path={LOANS} element={<LoansPage />} />
                        <Route
                          element={<FeatureGateGuard name={FEATURE_GATES.PARTNERSHIPS_PAGE} />}
                        >
                          <Route exact path={PARTNERSHIPS} element={<Partnerships />}>
                            <Route element={<FeatureGateGuard name={FEATURE_GATES.LATCHEL_GATE} />}>
                              <Route
                                path={MAINTENANCE_REQUEST}
                                element={<MaintenancePropertyDrawer from={PARTNERSHIPS} />}
                              />
                            </Route>
                          </Route>
                        </Route>
                        {/**
                         * Catch-all
                         * This is where we end up if no specific route has been matched.
                         */}
                        <Route element={<OnboardingCompletedLogic />}>
                          <Route path="*" element={<Navigate to={LOGIN} />} />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default MainRoutes;
