import React, { useImperativeHandle } from 'react';
import {
  Box,
  Drawer,
  DrawerBody as Body,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter as Footer,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  forwardRef,
} from '@chakra-ui/react';
import { IconChevronLeft } from '@icons';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { Icon16Close } from '@core/components/Icons/16px';
import BaselaneMobileHeader from '../BaselaneMobileHeader';
import {
  drawerBodyStyles,
  mobileDrawerBodyStyles,
  drawerCloseBtnStyles,
  mobileDrawerHeaderCloseButtonStyles,
  drawerFooterStyles,
  drawerHeaderStyles,
  mobileDrawerHeaderStyles,
  newDrawerHeaderStyles,
  newDrawerHeaderCloseButtonStyle,
  newDrawerBodyStyles,
  newDrawerFooterStyles,
} from './styles/drawer.style';
import { truncatedText } from '../../styles/text.style';

type BaselaneDrawerProps = {
  title: string,
  header: any,
  footer: any,
  children: any,
  mobileHeaderChildren?: any,
  size?: string,
  placement?: string,
  onOverlayClick?: Function,
  finalFocusRef?: any,
  trapFocus?: boolean,
  showCloseButton?: boolean,
  showBackButton?: boolean,
  hideBackText?: boolean,
  isMobileHeader?: boolean,
  isMobileLogoHeader?: boolean,
  returnFocusOnClose?: boolean,
  openEvent?: Function,
  closeEvent?: Function,
  closeButtonEvent?: Function,
  hideOverlay?: boolean,
  overlayStyles?: Function,
  hasMobileShadow?: boolean,
  closeOnOverlayClick?: boolean,
  onClose?: Function,
  isOpen: boolean,
  newDesignDrawer: boolean,
  newDrawerUseDefaultBodyFooter: boolean,
};

const BaselaneDrawer = forwardRef(
  (
    {
      title,
      header,
      footer,
      children,
      mobileHeaderChildren,
      size = 'md',
      placement = 'right',
      finalFocusRef = null,
      trapFocus = false,
      showCloseButton = true,
      showBackButton = false,
      hideBackText = true,
      isMobileHeader = false,
      isMobileLogoHeader = false,
      returnFocusOnClose = true,
      openEvent,
      onOverlayClick,
      closeEvent,
      closeButtonEvent,
      hideOverlay = false,
      hideHeader = false,
      overlayStyles = {},
      contentStyles = {},
      hasMobileShadow,
      closeOnOverlayClick = true,
      onClose: onCloseFromProps,
      isOpen: isDrawerOpen = false,
      newDesignDrawer = false,
      newDrawerUseDefaultBodyFooter = false,
      newDrawerCustomFooterStyles,
    }: BaselaneDrawerProps,
    ref
  ) => {
    const { isMax767, isMinXL, isMax576, isMax768 } = useBreakPoints();

    // New Design drawer
    let responsiveStyleSize = 'min769';
    if (isMax576 || isMax767) responsiveStyleSize = 'max576';
    else if (isMax768) responsiveStyleSize = 'min577max768';

    const newPlacement = size === 'newdrawerfull' ? 'bottom' : 'right';

    let drawerHeaderStyle = isMinXL ? mobileDrawerHeaderStyles : drawerHeaderStyles;
    // if this is a NewDesign drawer
    if (newDesignDrawer) drawerHeaderStyle = newDrawerHeaderStyles[responsiveStyleSize];

    let drawerCloseBtnStyle = isMinXL ? mobileDrawerHeaderCloseButtonStyles : drawerCloseBtnStyles;
    if (newDesignDrawer) drawerCloseBtnStyle = newDrawerHeaderCloseButtonStyle;

    const { isOpen, onOpen, onClose } = useDisclosure();

    const onHandleDrawerClose = (e) => {
      onClose(e);
      closeEvent(e);
    };

    const onHandleDrawerOpen = (e) => {
      openEvent(e);
      onOpen(e);
    };

    const drawerProps = {
      isOpen: isDrawerOpen || isOpen,
      onClose: onCloseFromProps ?? onHandleDrawerClose,
      placement: newDesignDrawer ? newPlacement : placement,
      size,
      onOverlayClick,
      trapFocus,
      finalFocusRef,
      returnFocusOnClose,
      closeOnOverlayClick,
    };

    useImperativeHandle(ref, () => ({
      title,
      isOpen,
      close: onClose,
      open: (e) => onHandleDrawerOpen(e),
    }));

    return (
      /**
       * added scrollBehavior="inside" blockScrollOnMount={false} top fix scroll issues inside drawer
       * https://github.com/chakra-ui/chakra-ui/issues/1862
       */
      <Drawer scrollBehavior="inside" blockScrollOnMount={false} {...drawerProps}>
        <DrawerOverlay {...(overlayStyles && hideOverlay ? { bg: 'transparent' } : {})}>
          <DrawerContent {...contentStyles}>
            {!isMobileHeader && !isMobileLogoHeader && (
              <DrawerHeader {...drawerHeaderStyle} hidden={hideHeader}>
                {title && (
                  <Box w="100%" style={{ textAlign: 'left', ...truncatedText }}>
                    {title}
                  </Box>
                )}
                {header && (
                  <Box w="100%" style={{ textAlign: 'right' }}>
                    {header}
                  </Box>
                )}
                {showCloseButton && (
                  <DrawerCloseButton
                    id="drawer-close-button"
                    onClick={closeButtonEvent}
                    {...drawerCloseBtnStyle}
                  />
                )}
              </DrawerHeader>
            )}
            {showBackButton && (isMobileHeader || isMobileLogoHeader) && (
              <BaselaneMobileHeader
                title={title}
                actionLink={onHandleDrawerClose}
                hideBackText={hideBackText}
                linkIcon={<IconChevronLeft w="16" h="16" color="#0968BF" />}
                back={showBackButton}
                shadow={hasMobileShadow}
                onTop
                isMobileLogoHeader={isMobileLogoHeader}
                hidden={hideHeader}
              >
                {mobileHeaderChildren}
              </BaselaneMobileHeader>
            )}
            {showCloseButton && !showBackButton && (isMobileHeader || isMobileLogoHeader) && (
              <BaselaneMobileHeader
                title={title}
                actionLink={onHandleDrawerClose}
                hideBackText
                linkIcon={<Icon16Close />}
                back={false}
                shadow={hasMobileShadow}
                onTop
                isMobileLogoHeader={isMobileLogoHeader}
                hidden={hideHeader}
              >
                {mobileHeaderChildren}
              </BaselaneMobileHeader>
            )}
            {newDesignDrawer && !newDrawerUseDefaultBodyFooter ? (
              <>
                <Body id="drawer-body" {...newDrawerBodyStyles[responsiveStyleSize]}>
                  {children}
                </Body>
                {footer && (
                  <Footer
                    {...newDrawerFooterStyles[responsiveStyleSize]}
                    {...newDrawerCustomFooterStyles}
                  >
                    {footer}
                  </Footer>
                )}
              </>
            ) : (
              children
            )}
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    );
  }
);

BaselaneDrawer.DrawerBody = forwardRef(({ children, ...styles }: { children: any }, ref) => {
  const { isMinXL } = useBreakPoints();

  const drawerBodyStyle = isMinXL ? mobileDrawerBodyStyles : drawerBodyStyles;

  return (
    <Body ref={ref} {...{ ...drawerBodyStyle, ...styles }}>
      {children}
    </Body>
  );
});

BaselaneDrawer.DrawerFooter = ({ children, ...styles }: { children: any }) => (
  <Footer {...{ ...drawerFooterStyles, ...styles }}>{children}</Footer>
);

BaselaneDrawer.defaultProps = {
  size: 'md',
  header: null,
  placement: 'right',
  finalFocusRef: null,
  onOverlayClick: () => {},
  openEvent: () => {},
  closeEvent: () => {},
  closeButtonEvent: () => {},
  trapFocus: false,
  returnFocusOnClose: true,
  showCloseButton: true,
  showBackButton: false,
  hideMainHeader: false,
  closeOnOverlayClick: true,
  overlayStyles: {},
  isMobileLogoHeader: false,
};

export default BaselaneDrawer;
