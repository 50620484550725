export const REJECT_OR_APPROVE_REASON = {
  inadequateIncomeAssets: 'Inadequate income assets',
  unverifiedIncomeAssets: 'Unverified income assets',
  smokingHabits: 'Smoking habits',
  criminalRecordHistory: 'Criminal record history',
  nonPermittedPets: 'Non-permitted pets',
  rentalHistoryIsLacking: 'Rental history is lacking',
  historyOfNonPaymentOrTardiness: 'History of non-payment or tardiness',
  historyOfRentalPropertyDamage: 'History of rental property damage',
  applicationInfoMisrepresented: 'Application info misrepresented',
  incompleteApplication: 'Incomplete application',
  creditBackgroundCheckNotAuthorized: 'Credit/background check not authorized',
  creditScoreDoesntMeetRentingCriteria: 'Credit score doesn’t meet renting criteria',
  historyOfBankruptcy: 'History of bankruptcy',
  historyOfHousingCourtProceedings: 'History of housing court proceedings',
  negativeEmployerReference: 'Negative employer reference',
  other: 'Other (specify reason)',
};

export const reasonInitialValues = Object.keys(REJECT_OR_APPROVE_REASON).reduce(
  (result, currentKey) => {
    return {
      ...result,
      [currentKey]: false,
    };
  },
  { otherReason: '' }
);

export const getFormattedReasons = (values) => {
  const reason = [];
  Object.keys(values).forEach((key) => {
    if (values[key] === true) {
      reason.push(REJECT_OR_APPROVE_REASON_SUBMIT[key]);
    }
  });
  let otherReason = null;
  if (values?.other) {
    otherReason = values.otherReason;
  }

  return { reason, otherReason };
};

export const REJECT_OR_APPROVE_REASON_SUBMIT = {
  inadequateIncomeAssets: 'INADEQUATE_INCOME_ASSETS',
  unverifiedIncomeAssets: 'UNVERIFIED_INCOME_ASSETS',
  smokingHabits: 'SMOKING_HABITS',
  criminalRecordHistory: 'CRIMINAL_RECORD_HISTORY',
  nonPermittedPets: 'NON_PERMITTED_PETS',
  rentalHistoryIsLacking: 'RENTAL_HISTORY_IS_LACKING',
  historyOfNonPaymentOrTardiness: 'HISTORY_OF_NON_PAYMENT_OR_TARDINESS',
  historyOfRentalPropertyDamage: 'HISTORY_OF_RENTAL_PROPERTY_DAMAGE',
  applicationInfoMisrepresented: 'APPLICATION_INFO_MISREPRESENTED',
  incompleteApplication: 'INCOMPLETE_APPLICATION',
  creditBackgroundCheckNotAuthorized: 'CREDIT_BACKGROUND_CHECK_NOT_AUTHORIZED',
  creditScoreDoesntMeetRentingCriteria: 'CREDIT_SCORE_DOES_NOT_MEET_RENTING_CRITERIA',
  historyOfBankruptcy: 'HISTORY_OF_BANKRUPTCY',
  historyOfHousingCourtProceedings: 'HISTORY_OF_HOUSING_COURT_PROCEEDINGS',
  negativeEmployerReference: 'NEGATIVE_EMPLOYER_REFERENCE',
  other: 'OTHER',
};

/**
 * Application approve/decline BE action names
 */
export const APPLICATION_ACTION = {
  ACCEPT: 'ACCEPT',
  REJECT: 'REJECT',
  CONDITIONALLY_APPROVE: 'CONDITIONALLY_APPROVE',
};
