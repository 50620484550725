import React, { useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { VStack, Stack, Text, Box, Heading } from '@chakra-ui/react';
import userContext from '@contexts/UserContext';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import historyStateStorage from '@core/storage/historyStateStorage';
import useBreakPoints from '@core/hooks/useBreakPoints';
import CenterContentWrapper from '@shared/layouts/CenterContentWrapper';
import {
  subTextStyles,
  titleTextStyles,
  descriptionTextStyles,
  contentContainerStyles,
} from '@core/components/OnboardingTriage/styles/success.styles';
import WalletCreditCard from './WalletCreditCard';
import FooterButtons from './FooterButtons';

import { successConfig } from './helpers/successConfig.helpers';

type SuccessUIProps = {
  bankData: Object,
  baselaneBankBalance: Number,
  showDAPYBanner: Boolean,
  isFromRC: Boolean,
  userPromoApy: String,
};

const SuccessUI = ({
  bankData,
  baselaneBankBalance,
  showDAPYBanner,
  isFromRC,
  userPromoApy,
}: SuccessUIProps) => {
  const { bankAccounts, unitAccount } = bankData ?? {};
  const { unitApplicationStatus: status = null } = unitAccount ?? {};

  const { user } = useContext(userContext);
  const { isMin899 } = useBreakPoints();

  // if user opened lb flow from another flow from state will be an object
  const historyStateData = historyStateStorage.read('baselane-state');
  const fromStateFlow =
    typeof historyStateData?.from === 'string'
      ? historyStateData?.from
      : historyStateData?.from?.flow;

  // this is legacy Success, also showing denied or docs needed state
  const config = successConfig(user?.onboardingCompleted, fromStateFlow);
  const { icon, subText, title, description, leftBtn = null, rightBtn, segmentEventOnLoad } =
    config[status] ?? config.EMPTY_STATE;

  useEffect(() => {
    if (bankData && segmentEventOnLoad) {
      sendSegmentEvent(segmentEventOnLoad);
    }
  }, []);

  const mobileTitle = subText ? `${subText} ${title}` : title;

  const iconContainerStyles =
    (!isMin899 && {
      position: 'relative',
      top: '-20px',
      left: '32px',
      zIndex: '10',
      borderRadius: '50%',
      bg: 'brand.neutral.white',
      width: '40px',
      height: '40px',
      boxSizing: 'content-box',
      p: '4px',
    }) ||
    {};

  return (
    <CenterContentWrapper
      containerStyles={{
        mt: isMobile ? 2 : '38px',
      }}
      innerContainerStyles={{
        spacing: isMin899 ? '50px' : '0px',
      }}
    >
      <VStack {...contentContainerStyles(isMin899)}>
        {showDAPYBanner ? (
          <>
            <Stack maxWidth="370px" order={isMin899 ? '' : '2'}>
              <Box {...iconContainerStyles}>{icon}</Box>
              {!isMobile && subText && <Text {...subTextStyles}>{subText}</Text>}
              {title && <Heading {...titleTextStyles}>{isMobile ? mobileTitle : title}</Heading>}
              <Text {...descriptionTextStyles}>{description}</Text>
            </Stack>
            {status !== 'DENIED' && (
              <WalletCreditCard
                {...{
                  order: isMin899 ? '' : '1',
                  transform: isMin899 ? '' : 'translateY(15%) scale(0.85)',
                  status,
                  isPromotionalCard: true,
                  bankProps: {
                    accountNumber: bankAccounts?.[0]?.accountNumber || '',
                    value: baselaneBankBalance,
                    fullName: `${user?.firstName || ''} ${
                      user?.lastName ? ` ${user?.lastName}` : ''
                    }`,
                  },
                }}
              />
            )}
          </>
        ) : (
          <>
            <Stack maxWidth="370px">
              {icon}
              {!isMobile && subText && <Text {...subTextStyles}>{subText}</Text>}
              {title && <Text {...titleTextStyles}>{isMobile ? mobileTitle : title}</Text>}
              <Text {...descriptionTextStyles}>{description}</Text>
            </Stack>
            {status !== 'DENIED' && (
              <WalletCreditCard
                {...{
                  status,
                  bankProps: {
                    accountNumber: bankAccounts?.[0]?.accountNumber || '',
                    value: baselaneBankBalance,
                    fullName: `${user?.firstName || ''} ${
                      user?.lastName ? ` ${user?.lastName}` : ''
                    }`,
                  },
                }}
              />
            )}
          </>
        )}
      </VStack>
      <FooterButtons
        {...{
          leftBtn,
          rightBtn,
          bankId: bankData?.id,
          historyStateData,
          showDAPYBanner,
          userPromoApy,
          isFromRC,
        }}
      />
    </CenterContentWrapper>
  );
};

export default SuccessUI;
