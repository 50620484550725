import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useShallow } from 'zustand/react/shallow';
import { useOutletContext } from 'react-router-dom';
import { ListItem, Stack, Tbody, Text, Thead, UnorderedList } from '@chakra-ui/react';

import {
  BaselaneCardNew,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
  BaselaneSpinner,
} from '@shared/components';
import useCSVImportStore from '@store/CSVImport';
import useProperties from '@features/Property/useProperties';

import TabPanelLayout from '../components/TabPanelLayout';
import FooterNavigation from '../components/FooterNavigation';

import MapPropertyRow from './MapPropertyRow';
import { trimAndNormalizeWhitespace } from '../helpers';

const MapProperties = () => {
  const { loading, propertiesForDropdown, propertiesByIdForDropdownById } = useProperties();
  const { handleFooterNavigation, formikRefs } = useOutletContext();
  const formikRef = formikRefs[3];

  const { mappedProperties, propertyListFromCSV, setMappedPropertyValues } = useCSVImportStore(
    useShallow((state) => ({
      mappedProperties: state.mappedProperties,
      propertyListFromCSV: state.propertyListFromCSV,
      setMappedPropertyValues: state.setMappedPropertyValues,
    }))
  );

  const [initialPropertyData, setInitialPropertyData] = useState(mappedProperties);

  useEffect(() => {
    // map any that match Baselane properties exactly
    const uniqueMappedProperties = propertyListFromCSV?.map((propertyCSVName) => {
      const matchedProperty = propertiesForDropdown.find(
        (baselaneProperty) =>
          trimAndNormalizeWhitespace(baselaneProperty?.name).toLowerCase() ===
          trimAndNormalizeWhitespace(propertyCSVName).toLowerCase()
      );

      const existingBaselaneProperty = mappedProperties?.find((mappedCategory) => {
        return (
          mappedCategory?.externalProperty === propertyCSVName &&
          mappedCategory?.baselaneProperty !== null
        );
      });

      return {
        externalProperty: propertyCSVName,
        baselaneProperty: mappedProperties
          ? existingBaselaneProperty?.baselaneProperty
          : matchedProperty?.name || null,
      };
    });

    setInitialPropertyData(uniqueMappedProperties);
  }, [propertiesForDropdown, propertyListFromCSV]);

  // skip to next tab if we somehow get here with no property header mapped
  /*   if (!CSVHeaderMapping.Property || CSVHeaderMapping.Property === '') {
    handleFooterNavigation(true);
  } */

  const handleTabFormSubmit = (values) => {
    setMappedPropertyValues(values);
  };

  return loading || !initialPropertyData ? (
    <BaselaneSpinner />
  ) : (
    <Formik
      innerRef={formikRef}
      initialValues={initialPropertyData}
      onSubmit={handleTabFormSubmit}
      enableReinitialize
    >
      {({ values, handleSubmit }) => (
        <TabPanelLayout
          stepNumber={4}
          title="Map unrecognized properties (optional)"
          description={
            <Stack spacing={0.5}>
              <Text>
                Some &quot;Property&quot;values in the CSV don’t match your properties on Baselane.
              </Text>
              <UnorderedList spacing={0.5}>
                <ListItem>Select a Baselane property to replace them, or</ListItem>
                <ListItem>
                  Leave it blank, and the original property will be added to the Notes field for
                  reference
                </ListItem>
              </UnorderedList>
            </Stack>
          }
        >
          <BaselaneCardNew maxW="600px" p={0}>
            <BaselaneResponsiveTable
              id="map-categories"
              config={{ columns: [] }}
              tableContainerStyles={{
                overflowY: 'initial',
                overflowX: 'initial',
              }}
            >
              <Thead>
                <BaselaneResponsiveTableRow chevronCellStyles={{ w: 0 }}>
                  <BaselaneResponsiveCellTitle
                    title="CSV property"
                    titleSize="headline-xs"
                    styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                    configIndex={0}
                  />
                  <BaselaneResponsiveCellTitle
                    title="Baselane property"
                    titleSize="headline-xs"
                    styles={{ titleTextStyle: { color: 'brand.neutral.600' }, p: 0 }}
                    configIndex={1}
                  />
                </BaselaneResponsiveTableRow>
              </Thead>
              <Tbody>
                <BaselaneResponsiveTableRows
                  items={values}
                  customRow
                  renderItem={({ baselaneProperty, externalProperty }, index) => {
                    return (
                      <MapPropertyRow
                        key={externalProperty}
                        index={index}
                        externalProperty={externalProperty}
                        baselaneProperty={baselaneProperty}
                        propertiesForDropdown={propertiesForDropdown}
                        propertiesByIdForDropdownById={propertiesByIdForDropdownById}
                      />
                    );
                  }}
                />
              </Tbody>
            </BaselaneResponsiveTable>
          </BaselaneCardNew>
          <FooterNavigation
            handleFooterNavigation={handleFooterNavigation}
            isValid
            handleSubmit={handleSubmit}
          />
        </TabPanelLayout>
      )}
    </Formik>
  );
};

export default MapProperties;
