import customTheme from '@core/theme';

export const graphicsDefaultColors = {
  barOne: customTheme.colors.brand.blue['700'],
  barTwo: customTheme.colors.brand.blue['250'],
  lineOne: customTheme.colors.red['800AA'],
};

export const xTickStyle = (isMobile) => ({
  fontStyle: 'normal',
  fontWeight: '100',
  fontSize: isMobile ? '10px' : '11px',
  letterSpacing: '-0.12px',
  textTransform: 'capitalize',
  fill: customTheme.colors.brand.neutral['600'],
  textAnchor: 'middle',
});

export const yTickStyle = (isMobile) => ({
  fontStyle: 'normal',
  fontWeight: '100',
  fontSize: isMobile ? '10px' : '12px',
});

export const scrollingContainerStyle = {
  w: '100%',
  h: `100%`,
};
