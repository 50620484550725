import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './apollo/apolloClient';
import App from './components/App';
import './styles/fonts.scss';
import * as serviceWorker from './serviceWorker';
import { suppressDefaultPropsWarning } from './utils/supressDefaultPropsWarning';

suppressDefaultPropsWarning();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
