import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  ChakraProvider,
  HStack,
  Input,
  Progress,
  Stack,
  Flex,
  Checkbox,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';

import { useMutation } from '@apollo/client';
import { useStatsigClient } from '@statsig/react-bindings';
import { SaveChangesBtn } from '@core/components/NativeBankingPage/components/IndividualInputEditButtons';
import { progressBarStyles } from '@core/components/OnboardingTriage/PropertySurvey/styles/propertySurvey.styles';
import {
  BaselaneLink,
  BaselaneButtonCard,
  BaselaneDivider,
  BaselaneButton,
  BaselaneSpinner,
} from '@shared/components';
import habitatTheme from '@core/themeHabitat';
import { formInputStyles } from '@shared/styles/input.style';
import useOnboardingStore from '@core/store/Onboarding';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { CREATE_OR_UPDATE_ONBOARDING_SURVEY } from '@core/components/UserProfilePage/queries';
import { SIGN_UP_AS_RENTER } from '@core/constants/routes';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { buttonCardStyles, otherBoxStyles, titleTextStyles } from './styles/propertySurvey.styles';
import { onboardingSurvey } from '../../utils/onboardingSurvey/index';
import DeferredSignupPageHeader from './components/DeferredSignupPageHeader';
import DeferredPasswordSignUpForm from './components/DeferredPasswordSignUpForm';

const DeferredSignupPropertySurvey = () => {
  const { questionnaire, questionnaireObject, addResponse } = useOnboardingStore();

  const { checkGate } = useStatsigClient();
  const { questions, version } =
    onboardingSurvey(checkGate(FEATURE_GATES.ONBOARDING_ENTITY_QUESTION)) || {};

  const [step, setStep] = useState(0);
  const [isOtherEditState, setIsOtherEditState] = useState(-1);
  const [otherEditValue, setOtherEditValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [multipleAnswers, setMultipleAnswers] = useState([]);

  const [updateSurvey] = useMutation(CREATE_OR_UPDATE_ONBOARDING_SURVEY);

  const surveyQuestionsLength = questions.length;

  const handleMultiSelectAnswerClick = (answer) => {
    const isChecked = multipleAnswers.includes(answer);
    const newAnswers = isChecked
      ? multipleAnswers.filter((x) => x !== answer)
      : [...multipleAnswers, answer];

    setMultipleAnswers(newAnswers);
  };

  const handleSave = () => {
    setIsLoading(true);
    if (questionnaire?.otherSource) {
      sendSegmentEvent('onboarding_complete_property_survey', {
        version,
        questionnaire: questionnaireObject,
        otherSource: otherEditValue,
      });
    } else {
      sendSegmentEvent('onboarding_complete_property_survey', {
        version,
        questionnaire: questionnaireObject,
      });
    }
    return updateSurvey({ variables: { version, questionnaire } });
  };

  const setAnswer = (question, answer, newPage, eventString) => {
    if (eventString) {
      sendSegmentEvent(`onboarding_survey_${eventString}_continue`);
    }

    const isMultiSelect = Array.isArray(answer);
    let finalAnswer = answer.value || answer;

    if (isMultiSelect) {
      const allAnswers = questions.find((q) => q.eventString === eventString).answers;

      const result = Object.fromEntries(
        allAnswers.map((item) => [item, multipleAnswers.includes(item)])
      );

      finalAnswer = JSON.stringify(result);
    }

    addResponse(question, finalAnswer, otherEditValue);

    if (newPage) {
      setStep((prev) => prev + 1);
    }
  };

  const handleOnOtherChange = (e) => {
    setOtherEditValue(e.target.value);
  };

  return (
    <ChakraProvider theme={habitatTheme}>
      <VStack
        gap={0}
        justifyContent="flex-start"
        minHeight="none"
        bg="brand.neutral.white"
        minW="100%"
      >
        <DeferredSignupPageHeader />

        <Box h="100%" w="100%" px={2} pt={5}>
          {step <= questions.length - 1 && (
            <>
              <Progress
                {...progressBarStyles}
                value={((step + 1) / surveyQuestionsLength) * 100}
                maxW="640px"
                mx="auto"
                mb={3}
                colorScheme="scheme.blue"
              />
              <Tabs index={step} mt="0" mx="auto" maxW="640px">
                <TabPanels>
                  {questions.map((surveyQuestion, index) => {
                    return (
                      <TabPanel
                        key={surveyQuestion?.question}
                        padding="0!important"
                        overflow="auto"
                      >
                        {isLoading ? (
                          <BaselaneSpinner />
                        ) : (
                          <Box pb="32px">
                            <Text {...titleTextStyles(index !== 0)}>
                              {surveyQuestion?.question}
                            </Text>

                            <Stack spacing={2}>
                              {surveyQuestion.answers.map((answer, answerIndex) => {
                                if (surveyQuestion.isMultiSelect) {
                                  return (
                                    <BaselaneButtonCard
                                      id={`answer-${
                                        answer?.id || answer.replace(/\s+/g, '-').toLowerCase()
                                      }`}
                                      key={uuidv4()}
                                      type="clean"
                                      buttonStyles={buttonCardStyles}
                                      onClick={() => handleMultiSelectAnswerClick(answer)}
                                    >
                                      <Flex gap="8px">
                                        <Checkbox
                                          className="primary-500"
                                          isChecked={multipleAnswers.includes(answer)}
                                        />
                                        <Text width="100%" fontWeight="500">
                                          {answer}
                                        </Text>
                                      </Flex>
                                    </BaselaneButtonCard>
                                  );
                                }

                                return isOtherEditState !== answerIndex ? (
                                  <BaselaneButtonCard
                                    id={`answer-${
                                      answer?.id || answer.replace(/\s+/g, '-').toLowerCase()
                                    }`}
                                    key={typeof answer === 'string' ? answer : answer?.label}
                                    type="clean"
                                    buttonStyles={buttonCardStyles}
                                    onClick={() => {
                                      if (answer?.type === 'text') {
                                        setIsOtherEditState(answerIndex);
                                      } else {
                                        setIsOtherEditState(-1);
                                        setAnswer(
                                          surveyQuestion?.questionForMixpanel,
                                          answer?.value || answer,
                                          index !== questions.length,
                                          surveyQuestion?.eventString
                                        );
                                      }
                                    }}
                                  >
                                    <Text>{answer?.label || answer}</Text>
                                  </BaselaneButtonCard>
                                ) : (
                                  <Box
                                    {...otherBoxStyles}
                                    key={`${typeof answer === 'string' ? answer : answer?.label}`}
                                  >
                                    <HStack spacing={1}>
                                      <Text fontWeight="500">{answer?.label || answer}</Text>
                                      <Input
                                        id="property-survey-other-answer"
                                        name="other"
                                        placeholder={
                                          answer?.id === 'influencer'
                                            ? 'Enter Code or Coach name'
                                            : 'Type your answer'
                                        }
                                        value={otherEditValue}
                                        onChange={handleOnOtherChange}
                                        {...formInputStyles}
                                        borderColor="brand.blue.700 !important"
                                        bg="brand.neutral.white"
                                        w="100%"
                                        isRequired={
                                          answer?.id !== 'influencer' ||
                                          answer?.id !== 'podcast-or-webinar' ||
                                          answer?.id !== 'other'
                                        }
                                        autoFocus
                                        maxLength="150"
                                      />
                                      <SaveChangesBtn
                                        variant="filled"
                                        palette="primary"
                                        size="lg"
                                        isDisabled={
                                          answer?.id !== 'influencer' &&
                                          answer?.id !== 'podcast-or-webinar' &&
                                          answer?.id !== 'other' &&
                                          !otherEditValue
                                        }
                                        onClick={() => {
                                          setIsOtherEditState(-1);
                                          setAnswer(
                                            surveyQuestion?.questionForMixpanel,
                                            answer,
                                            index !== questions.length,
                                            surveyQuestion?.eventString
                                          );
                                        }}
                                      />
                                    </HStack>
                                  </Box>
                                );
                              })}
                            </Stack>
                            {surveyQuestion.isMultiSelect && (
                              <BaselaneButton
                                id="survey-multiple-choice-next"
                                variant="filled"
                                palette="primary"
                                size="lg"
                                isDisabled={multipleAnswers.length < 1}
                                {...{ marginTop: '32px' }}
                                onClick={() =>
                                  setAnswer(
                                    surveyQuestion.questionForMixpanel,
                                    multipleAnswers,
                                    index !== questions.length,
                                    surveyQuestion.eventString
                                  )
                                }
                              >
                                Next
                              </BaselaneButton>
                            )}
                            {index === 0 && (
                              <VStack>
                                <BaselaneDivider styles={{ my: 4, py: 0.5 }} />
                                <Box alignSelf="flex-start">
                                  <Text textStyle="md-heavy" as="span">
                                    Not a landlord?{' '}
                                  </Text>
                                  <BaselaneLink
                                    to={`${SIGN_UP_AS_RENTER}`}
                                    variant="primary"
                                    ml={1}
                                  >
                                    See how to sign up as a renter
                                  </BaselaneLink>
                                </Box>
                              </VStack>
                            )}
                          </Box>
                        )}
                      </TabPanel>
                    );
                  })}
                </TabPanels>
              </Tabs>
            </>
          )}
          {step === questions.length && <DeferredPasswordSignUpForm onUserCreated={handleSave} />}
        </Box>
      </VStack>
    </ChakraProvider>
  );
};

export default DeferredSignupPropertySurvey;
