import { gql } from '@apollo/client';

export const FRAGMENT_PROPERTY_DOCUMENT = gql`
  fragment PropertyDocument on PropertyDocument {
    id
    userId
    serviceToken
    binderId
    interviewId
    ownerUpId
    state
    templateId
    propertyId
    propertyUnitId
    propertyUnit {
      id
      name
      propertyId
      isDelete
      address
    }
    documentName
    lastEventTimestamp
    version
  }
`;

export const GET_PROPERTY_DOCUMENTS = gql`
  query PropertyDocument {
    propertyDocuments {
      ...PropertyDocument
    }
  }
  ${FRAGMENT_PROPERTY_DOCUMENT}
`;

export const CREATE_PROPERTY_DOCUMENT = gql`
  mutation createPropertyDocument($input: PropertyDocumentInput!) {
    createPropertyDocument(input: $input) {
      ...PropertyDocument
    }
  }
  ${FRAGMENT_PROPERTY_DOCUMENT}
`;

export const UPDATE_PROPERTY_DOCUMENT = gql`
  mutation updatePropertyDocument($input: UpdatePropertyDocumentInput!) {
    updatePropertyDocument(input: $input) {
      ...PropertyDocument
    }
  }
  ${FRAGMENT_PROPERTY_DOCUMENT}
`;
