export const bannerBackgroundStyles = {
  bg: 'brand.blue.200',
  borderRadius: 'md',
  justifyContent: 'space-between',
  flexDirection: { base: 'column-reverse', md: 'row' },
};

export const contentContainerStyles = {
  px: { lg: 5, xs: 2.5 },
};

// warning: it is not using conventional font styles
export const titleFontStyles = {
  fontSize: { xl: '46px', lg: '36px', base: '32px' },
  lineHeight: { lg: '56px', base: '40px' },
  fontWeight: 'bold',
  color: 'brand.neutral.800',
};

export const subTextFontStyles = {
  textStyle: { lg: 'lg', xs: 'md' },
  color: 'brand.neutral.800',
};

export const bannerContainerStyles = { flexDir: { xl: 'row', lg: 'row', xxs: 'column' } };
