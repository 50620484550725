import React from 'react';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';
import { Box, Stack, Text, Heading } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { BaselaneButton, BaselaneCard, BaselaneChip, BaselaneButtonIcon } from '@shared/components';
import { Icon16ChevronRight } from '@icons/16px';
import IconBankStatements from '@icons/manual/IconBankStatements';
import { relativePositionStyling } from '@core/components/CDD/styles/unitBanner.styles';
import { calculateDaysToExpire } from '../helpers/taxmodal.helpers';

type LandLordTaxBannerProps = {
  handleCTAClick: Function,
  expirationDay: DateTime,
  overdueDay: DateTime,
};

const LandLordTaxBanner = ({
  expirationDay,
  overdueDay,
  handleCTAClick,
}: LandLordTaxBannerProps) => {
  const { isMin768: isDesktop } = useBreakPoints();
  const location = useLocation();

  const shouldMove =
    location.pathname.includes('dashboard') || location.pathname.includes('partners');

  const bgColor = 'yellow.800AA';

  // Example Usage
  const today = DateTime.now();

  const daysToExpire = calculateDaysToExpire(today, expirationDay);
  const isOverdue = today > expirationDay;
  const isExpired = today > overdueDay;

  return (
    <Box
      {...relativePositionStyling(isDesktop && !shouldMove)}
      onClick={isDesktop ? () => {} : handleCTAClick}
    >
      <BaselaneCard>
        <Stack
          direction="row"
          spacing="1"
          justifyContent="space-between"
          py="2"
          px="3"
          alignItems="center"
          mb="1px"
          bg={bgColor}
        >
          <Stack direction={isDesktop ? 'row' : 'column'}>
            {isDesktop && (
              <Box>
                <IconBankStatements />
              </Box>
            )}
            <Box>
              <Stack direction={isDesktop ? 'row' : 'col'} width="100%">
                <Heading size="headline-md" color="white">
                  Information required for 1099-K
                </Heading>
                {isDesktop && !isExpired && (
                  <BaselaneChip
                    label={isOverdue ? 'Overdue' : `${daysToExpire} days left`}
                    leftIcon={null}
                    onClick={null}
                    onDeleteClick={null}
                    size="sm"
                    variant={isOverdue ? 'simple-danger' : 'simple-warning'}
                  />
                )}
              </Stack>
              {!isDesktop && !isExpired && (
                <BaselaneChip
                  label={isOverdue ? 'Overdue' : `${daysToExpire} days left`}
                  leftIcon={null}
                  onClick={null}
                  onDeleteClick={null}
                  size="sm"
                  variant={isOverdue ? 'simple-danger' : 'simple-warning'}
                />
              )}
              <Text textStyle="sm" color="white">
                We need a few additional details to generate the 1099-K form(s) for your 2024 rental
                income.
              </Text>
            </Box>
          </Stack>
          {isDesktop ? (
            <BaselaneButton
              variant="outline"
              palette="neutral"
              onClick={handleCTAClick}
              size="md"
              styles={{ flex: '0 0 auto', color: 'initial' }}
            >
              Submit information
            </BaselaneButton>
          ) : (
            <BaselaneButtonIcon
              variant="link"
              palette="neutral"
              size="sm"
              icon={<Icon16ChevronRight />}
            />
          )}
        </Stack>
      </BaselaneCard>
    </Box>
  );
};

export default LandLordTaxBanner;
