import userStorage from '@core/storage/userStorage';
import unitTokenTimeStorage from '../unitTokenTime';

export const manageStorageAfterLogout = () => {
  sessionStorage.setItem('hideOnboardingTracker', false);
  sessionStorage.setItem('hideCarousel', false);
  userStorage.delete();
  unitTokenTimeStorage.delete();

  // TODO: remove cdd
  localStorage.removeItem('DCPopupFlag');
};

// Clear localStorage on logout while preserving the stable ID set by Statsig
// this prevents a new user/id from being generated when a user logs out
// for other flags to keep, use this const
const filteredFlagsWeWantToKeep = ['isQuickPayAnnounceBannerClosed', 'guideCollapsed'];
export const manageLocalStorageAfterLogout = () => {
  const storageItemsToKeep = Object.keys(localStorage)
    .filter((key) => key.includes('STATSIG') || filteredFlagsWeWantToKeep.includes(key))
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: localStorage[key],
      }),
      {}
    );

  localStorage.clear();

  for (const storageKey in storageItemsToKeep) {
    if (Object.hasOwn(storageItemsToKeep, storageKey)) {
      localStorage.setItem(storageKey, storageItemsToKeep[storageKey]);
    }
  }
};
