import React from 'react';
import { Outlet } from 'react-router-dom';
import { TwoFactorVerificationPopUp } from '@core/components/Shared/components';
import { useUnitOtp } from '../../../../contexts/UnitOtpContext';

const UnitOtpLayoutWrapper = () => {
  const { props } = useUnitOtp();
  return (
    <>
      <TwoFactorVerificationPopUp {...props} />
      <Outlet />
    </>
  );
};

export default UnitOtpLayoutWrapper;
