import React, { useEffect } from 'react';
import { Text, Box, Stack } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import { isMobile } from 'react-device-detect';
import { useStatsigClient } from '@statsig/react-bindings';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';
import * as ROUTES from '@routes';
import { BaselaneLink } from '@shared/components';
import inActivityLogoutStorage from '@core/storage/inActivityLogoutStorage';
import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import useInvitationLink from '@pages/SignUpPage/useInvitationLink';
import { loginPageContainerStyles } from './styles/login.style';
import SignUpLoginButtons from './components/SignUpLoginButtons';
import LoginForm from './components/LoginForm';
import { headingStyles } from './styles/font.style';

function LoginPage() {
  const numbersToLogin = localStorage.getItem('redirectLogin');

  const { collaborator, isCollaborator, inviteCode } = useInvitationLink();

  // zendesk API access
  const zendeskAPI = useZendeskAPI();
  const { checkGate } = useStatsigClient();

  useEffect(() => {
    if (checkGate(FEATURE_GATES.HIDE_ZENDESK_BUBBLE)) {
      zendeskAPI('hideZendeskBubble', true);
    } else {
      zendeskAPI('hideZendeskBubble', false);
    }

    const [performanceObject] = performance.getEntriesByType('navigation');
    localStorage.setItem('redirectLogin', '1');
    if (
      (performanceObject.type === 'reload' ||
        performanceObject.type === 'navigate' ||
        performanceObject.type === 'back_forward') &&
      numbersToLogin &&
      numbersToLogin === '2'
    ) {
      inActivityLogoutStorage.delete();
      localStorage.removeItem('redirectLogin');
    } else {
      localStorage.setItem('redirectLogin', '2');
    }
  }, []);

  return (
    <Stack {...loginPageContainerStyles}>
      <Stack alignItems={{ md: 'flex-start', base: 'center' }}>
        <BaselaneFullLogo />
      </Stack>
      <Box width="100%" mt={isMobile ? '0px' : '95px !important'}>
        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
          {isCollaborator ? (
            <Stack gap={4} alignItems="center" textAlign="center" maxWidth="480px" mb="4">
              <Text textStyle="headline-xl">Join workspace</Text>
              <Text textStyle="md">
                <Box as="span" fontWeight="medium" mr="0.5">
                  {collaborator?.landlord?.name}
                </Box>
                has invited you to join their workspace on Baselane. Sign in as
                <Box as="span" fontWeight="medium" mx="0.5">
                  {collaborator?.email}
                </Box>
                to continue.
              </Text>
            </Stack>
          ) : (
            <Text {...headingStyles} maxWidth="784px" mb="32px !important">
              Welcome to Baselane
            </Text>
          )}
          <Outlet />
          <SignUpLoginButtons isLogin isMobile={isMobile} />
          <LoginForm initialEmail={collaborator?.email} inviteCode={inviteCode} />
          <Stack direction="row" my="24px !important" justifyContent="center" textStyle="sm">
            <Text>
              Don&apos;t have an account? &nbsp;{' '}
              <BaselaneLink variant="primary" size="md" to={ROUTES.SIGN_UP}>
                Sign Up
              </BaselaneLink>
            </Text>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
}

export default LoginPage;
