import React, { useContext, useEffect } from 'react';

import { useLocation, Outlet } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { use100vh } from 'react-div-100vh';
import { useStatsigClient } from '@statsig/react-bindings';

import * as ROUTES from '@core/constants/routes';
import UserContext from '@contexts/UserContext';
import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import Sidebar from '@core/components/Sidebar';
import MobileNav from '@core/components/NavigationAuth/MobileNav';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentPageEvent from '@core/utils/sendSegmentEvent/sendSegmentPageEvent';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import Banner from '../Banner';
import PageHeader from '../PageHeader';
import { pageContainerStyles, pageSettings } from './styles/pageWrapper.styles';

const PageWrapper = () => {
  const zendeskAPI = useZendeskAPI();

  const { checkGate } = useStatsigClient();

  const {
    user,
    isDesktopCheckDepositOpen,
    isTabletCheckDepositOpen,
    isMobileCheckDepositOpen,
  } = useContext(UserContext);

  const { isMin768: isDesktop } = useBreakPoints();

  const location = useLocation();
  const { pathname = '' } = location;

  const isHideScroll = pathname.indexOf('cashflow') !== -1;

  const isShowHorizontalScrollOnDesktop =
    !isDesktop && !isMobile && (pathname.includes(ROUTES.GET_STARTED) || pathname === ROUTES.HOME);

  useEffect(() => {
    sendSegmentPageEvent();
  }, [pathname]);

  useEffect(() => {
    if (checkGate(FEATURE_GATES.HIDE_ZENDESK_BUBBLE)) {
      zendeskAPI('hideZendeskBubble', true);
    } else {
      zendeskAPI('hideZendeskBubble', false);
    }
  }, []);

  const vhCalcMobile = use100vh();

  /**
   * Note: Due to the Entities page containing a carousel that requires
   * no padding surrounding the scrollable cards, this flag exists to
   * remove said padding.
   */
  const isEntitiesPage = pathname.includes(`${ROUTES.NB_ACCOUNTS}/baselane_`);
  const isPartnershipPage = pathname.includes(ROUTES.PARTNERSHIPS.split('/')[1]);
  const isDashboardPage = pathname.includes(ROUTES.DASHBOARD.split('/')[1]);
  const isPropertiesMobilePage = pathname.includes(`/${ROUTES.PROPERTIES}`) && isMobile;
  // for desktop
  const hidePagePadding =
    isEntitiesPage ||
    isPartnershipPage ||
    (checkGate(FEATURE_GATES.DASHBOARD_GATE) && isDashboardPage);

  const showMobileLayout =
    (!isDesktop && !isDesktopCheckDepositOpen) ||
    isMobileCheckDepositOpen ||
    isTabletCheckDepositOpen;

  return (
    <Stack {...pageSettings(user, isDesktop)}>
      {showMobileLayout ? <MobileNav /> : <PageHeader user={user} />}

      <Stack
        {...(showMobileLayout
          ? pageContainerStyles(user, false, false, true, !isDesktop, vhCalcMobile)
          : pageContainerStyles(
              user,
              isHideScroll,
              isShowHorizontalScrollOnDesktop,
              hidePagePadding || !isDesktop,
              !isDesktop,
              vhCalcMobile,
              isPropertiesMobilePage
            ))}
        sx={{
          '@media print': {
            padding: '0',
            overflowX: 'visible',
            overflowY: 'visible',
            height: 'auto',
            overscrollBehavior: 'none',
          },
        }}
        id="page-wrapper"
      >
        {!showMobileLayout && <Sidebar />}
        <Banner />
        <Outlet />
      </Stack>
    </Stack>
  );
};

export default PageWrapper;
