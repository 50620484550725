// @flow
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import formatCurrency from '@core/utils/formatCurrency';
import { formatDate } from '@core/utils/formatDate';
import { GET_SINGLE_INVOICE, GET_TENANT_PROFILE } from '@pages/LeasesPage/queries';
import { AlertHeader, BaselaneAlert, BaselaneDrawer } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import InvoiceContext from '@contexts/InvoiceContext';
import { INVOICE_STATUS_TYPES } from '../../../constants';
import { paymentTypeInvoiceStyles, statusLabelStyles } from '../../../styles/invoice.styles';
import type { Invoices as InvoicesType } from '../../../types';
import DrawerBody from './Body';
import { unsavedDetailsAlertFooter, initialValues } from '../../../helpers/invoiceDetails.helper';

type InvoiceDetailsProps = {
  invoiceDetails: InvoicesType,
  invoiceDetailsDrawerRef: any,
  handleInvoiceDetailsDrawerClose: Function,
  hasUpdatedInvoice: boolean,
  setHasUpdatedInvoice: Function,
  nextPaymentsClicked: boolean,
  setInvoiceList: Function,
  refreshInvoiceDetails: Function,
  refetchLeaseInvoices: Function,
  sortedLeases: Array<Object>,
  hasMultipleUnits: boolean,
  isLeaseLevel: boolean,
};

function InvoiceDetails({
  invoiceDetails: initialInvoiceDetails = {},
  invoiceDetailsDrawerRef,
  handleInvoiceDetailsDrawerClose,
  hasUpdatedInvoice,
  setHasUpdatedInvoice,
  nextPaymentsClicked,
  setInvoiceList,
  refreshInvoiceDetails: leaseRefreshInvoiceDetails,
  refetchLeaseInvoices = () => {},
  sortedLeases = [],
  hasMultipleUnits = false,
  isLeaseLevel = false,
}: InvoiceDetailsProps): any {
  const { isMax767 } = useBreakPoints();

  const { setInvoiceTotal, selectedInvoiceId, setSelectedInvoiceId } = useContext(InvoiceContext);

  const [isDirty, setIsDirty] = useState(false);
  const [editState, setEditState] = useState(false);
  const [isUnsavedChangesAlertOpen, setIsUnsavedChangesAlertOpen] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(initialInvoiceDetails);
  const onUnsavedChangesAlertOpen = () => setIsUnsavedChangesAlertOpen(true);
  const onUnsavedChangesAlertClose = () => setIsUnsavedChangesAlertOpen(false);

  const { propertyUnit } = invoiceDetails;
  const isPropertyDeleted = !propertyUnit?.propertyId && !propertyUnit?.unitId;

  const isMultiUnit = isLeaseLevel
    ? hasMultipleUnits
    : sortedLeases?.find((lease) => lease.id === invoiceDetails.leaseId && lease.totalUnits > 1);
  const lease = sortedLeases.find((l) => l.id === invoiceDetails.leaseId);

  const btnRef = useRef();
  const cancelUnsavedChangesAlertRef = useRef();
  const formikRef = useRef();

  const handleSetInvoiceDetails = (invoice) => {
    if (invoice) {
      const { id, state, dueDate, originalDueDate, description, amount } = invoice;

      const isDeleted = state === 'DELETED';
      const isCompleted = state === 'COMPLETED';
      const isProcessing = state === 'PROCESSING';
      const isScheduled = state === 'SCHEDULED';

      const paymentDate = invoice?.invoiceCharge?.paymentDate;
      const paymentTriggerType = invoice?.invoiceCharge?.paymentTriggerType;

      const pDate = moment(paymentDate).startOf('day').toISOString();
      const dDate = moment(dueDate).startOf('day').toISOString();

      const latePayment = (isProcessing || isCompleted) && pDate > dDate;
      const showAutoPayLabel = isScheduled && paymentTriggerType === 'autoPay';

      const { icon, label, color } = INVOICE_STATUS_TYPES[state] ?? {};

      const paymentType = (
        <>
          {description}
          <Box {...paymentTypeInvoiceStyles(isDeleted)}>{`#${id}`}</Box>
        </>
      );

      setInvoiceDetails({
        ...invoice,
        amount: formatCurrency(amount).inDollars,
        dueDate: moment(dueDate, moment.defaultFormat).toDate(),
        formattedDueDate: formatDate(dueDate),
        originalDueDate: moment(originalDueDate, moment.defaultFormat).toDate(),
        formattedOriginalDueDate: formatDate(originalDueDate),
        paymentType,
        paymentDate: formatDate(paymentDate, 'MMM D, YYYY'),
        state: showAutoPayLabel ? 'AUTOPAY' : state,
        stateElement: (
          <HStack alignItems="center">
            <Box>{icon}</Box>
            <Stack m="0 0 0 6px">
              <Text {...statusLabelStyles(color, isDeleted)} fontWeight="medium">
                {!showAutoPayLabel ? label : 'Auto-Pay'}
              </Text>
            </Stack>
          </HStack>
        ),
        latePayment,
        isQuickPaid: invoice?.isQuickPay,
        isPropertyDeleted,
        isMultiUnit,
      });
    }
  };

  const [
    getSingleInvoice,
    { loading: singleInvoiceLoading, error: singleInvoiceError },
  ] = useLazyQuery(GET_SINGLE_INVOICE, {
    fetchPolicy: 'cache-first',
    variables: {
      input: { dateType: 'DUE_DATE', invoiceId: selectedInvoiceId },
    },
    onCompleted: ({ invoiceList: invoiceListData }) => {
      const { invoices } = invoiceListData ?? {};
      const singleInvoiceDetails = invoices[0];
      // check if we got the details back, if so add them
      if (singleInvoiceDetails?.id === selectedInvoiceId) {
        handleSetInvoiceDetails(singleInvoiceDetails);
      }
    },
  });

  useEffect(() => {
    invoiceDetailsDrawerRef?.current?.open();
    getSingleInvoice();
    return () => {
      setSelectedInvoiceId(null);
      setHasUpdatedInvoice(false);
    };
  }, []);

  useEffect(() => {
    if (selectedInvoiceId && !singleInvoiceLoading && hasUpdatedInvoice) {
      getSingleInvoice();
      refetchLeaseInvoices();
    } else {
      setHasUpdatedInvoice(false);
    }
  }, [hasUpdatedInvoice]);

  const { loading: tenantLoading, data: tenantData, error: tenantError } = useQuery(
    GET_TENANT_PROFILE,
    {
      fetchPolicy: 'cache-and-network',
      skip: lease?.tenantProfileMetadata?.firstName,
    }
  );

  useEffect(() => {
    // when invoiceDetails change, we can open the drawer
    if (invoiceDetails?.id) {
      invoiceDetailsDrawerRef?.current?.open();
    }
  }, [invoiceDetails?.amount, tenantLoading, singleInvoiceLoading]);

  if (!invoiceDetails?.amount || singleInvoiceError || tenantError) return null;

  const { landlordTenant } = tenantData ?? {};
  const leaseTenant = lease?.tenantProfileMetadata?.firstName
    ? lease?.tenantProfileMetadata
    : landlordTenant.find(
        (tenant) =>
          tenant.id === invoiceDetails?.leaseTenantProfileId || tenant.id === lease?.tenantProfileId
      );

  const cleanUp = () => {
    setEditState(false);
    formikRef.current?.resetForm(initialValues(invoiceDetails));
  };

  const handleCancelCloseButton = (e) => {
    if (editState && isDirty) {
      cleanUp();
      if (isUnsavedChangesAlertOpen) onUnsavedChangesAlertClose();
      handleInvoiceDetailsDrawerClose(e);
    } else {
      handleInvoiceDetailsDrawerClose(e);
      onUnsavedChangesAlertClose();
    }
  };

  const handleCloseDrawer = () => {
    if (isDirty && editState) {
      onUnsavedChangesAlertOpen();
      handleInvoiceDetailsDrawerClose();
    } else {
      cleanUp();
      handleInvoiceDetailsDrawerClose();
    }
  };

  const refreshInvoiceDetails = (inv) => {
    setHasUpdatedInvoice(true);
    refetchLeaseInvoices().then((res) => {
      const { invoices, amount, count } = res?.data?.invoiceList ?? {};
      setInvoiceList(invoices);
      setInvoiceTotal({ amount, count });
      getSingleInvoice();
    });
  };

  return (
    <>
      <BaselaneDrawer
        finalFocusRef={btnRef}
        size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
        contentStyles={isMax767 && { top: 'auto !important' }}
        title="Invoice Details"
        hideBackText
        ref={invoiceDetailsDrawerRef}
        onClose={handleCloseDrawer}
        closeEvent={handleCloseDrawer}
        hideOverlay
        newDesignDrawer
      >
        <DrawerBody
          bankingAccount={lease?.rentAndFeesBankAccount}
          invoiceDetails={invoiceDetails}
          setIsDirty={setIsDirty}
          editState={editState}
          setEditState={setEditState}
          formikRef={formikRef}
          onClose={handleInvoiceDetailsDrawerClose}
          leaseTenant={leaseTenant}
          setHasUpdatedInvoice={setHasUpdatedInvoice}
          isPropertyDeleted={isPropertyDeleted}
          isMultiUnit={isMultiUnit}
          refreshInvoiceDetails={leaseRefreshInvoiceDetails || refreshInvoiceDetails}
          refetchLeaseInvoices={refetchLeaseInvoices}
          handleSetInvoiceDetails={handleSetInvoiceDetails}
          isQuickPay={lease?.isQuickPay}
        />
      </BaselaneDrawer>

      <BaselaneAlert
        isOpen={isUnsavedChangesAlertOpen}
        leastDestructiveRef={cancelUnsavedChangesAlertRef}
        onClose={onUnsavedChangesAlertClose}
        isCentered
        header={<AlertHeader title="You Have Unsaved Changes" />}
        body="Are you sure you want to exit without saving?"
        footer={unsavedDetailsAlertFooter(
          cancelUnsavedChangesAlertRef,
          onUnsavedChangesAlertClose,
          handleCancelCloseButton
        )}
      />
    </>
  );
}

export default InvoiceDetails;
