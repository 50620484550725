import React from 'react';
import { useFormikContext } from 'formik';
import { Stack } from '@chakra-ui/react';

import { BaselaneButton } from '@shared/components';

type FooterProps = { handleOnDrawerClose: Function };

const Footer = ({ handleOnDrawerClose }: FooterProps) => {
  const { handleSubmit, isValid, isSubmitting } = useFormikContext();

  return (
    <Stack direction="row" width="100%">
      <BaselaneButton variant="outline" palette="neutral" size="md" onClick={handleOnDrawerClose}>
        Cancel
      </BaselaneButton>
      <BaselaneButton
        type="submit"
        variant="filled"
        palette="primary"
        size="md"
        isDisabled={!isValid || isSubmitting}
        onClick={handleSubmit}
        flexGrow={1}
      >
        Send invitations
      </BaselaneButton>
    </Stack>
  );
};
export default Footer;
