// @flow
import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';

import UpdatedItem from './UpdatedItem';
import { getTransactionDataUpdates } from './helpers/transactionDataUpdate.helper';
import { titleStyles, descriptionStyles } from './styles/transactionDataUpdate.styles';

type TransactionDataUpdateProps = {
  transaction: Object,
  handleMerchantSubmit: Function,
  handleDateSubmit: Function,
  isLoading: boolean,
};

function TransactionDataUpdate({
  transaction,
  handleMerchantSubmit,
  handleDateSubmit,
  isLoading,
}: TransactionDataUpdateProps): any {
  const { isMinXL } = useBreakPoints();

  const { originalTransaction = {}, merchantName = '', date = null, pending = false } =
    transaction ?? {};
  const {
    merchantName: originalMerchantName = null,
    date: originalDate = null,
  } = originalTransaction;

  const transactionUpdates = getTransactionDataUpdates({
    merchantName,
    originalMerchantName,
    date,
    originalDate,
  });

  const [{ isUpdated: isMerchantUpdated }, { isUpdated: isTrxDateUpdated }] = transactionUpdates;

  return (
    (isMerchantUpdated || isTrxDateUpdated) &&
    !isLoading && (
      <Stack my={isMinXL ? '24px' : '32px'}>
        <Box mb="16px">
          <Text {...titleStyles}>Transaction data</Text>
          <Text {...descriptionStyles}>
            You made changes to this transaction&apos;s data. You can reset changes below.
          </Text>
        </Box>

        <Stack gap="16px" m="0 !important">
          {transactionUpdates.map((updatedData) => {
            const { title, original, updated, isUpdated, isOriginalNull } = updatedData;

            return (
              isUpdated && (
                <UpdatedItem
                  key={title}
                  title={title}
                  original={original}
                  updated={updated}
                  pending={pending}
                  handleMerchantSubmit={handleMerchantSubmit}
                  handleDateSubmit={handleDateSubmit}
                  isOriginalNull={isOriginalNull}
                />
              )
            );
          })}
        </Stack>
      </Stack>
    )
  );
}

export default TransactionDataUpdate;
