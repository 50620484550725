import { React, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { BaselaneButton } from '@shared/components';
import { Icon16ChevronRight, Icon16ChevronLeft } from '@icons/16px';
import { steps } from '../../helpers/formHelpers';
import { sendSegmentEventsForSteps } from '../../helpers/segmentEvents';
import {
  useSetCurrentStep,
  useIsCurrentStepLoading,
  useCurrentStep,
  useSelectedPaymentMethod,
  useIsOneTimePayment,
} from '../../sendFundsStore';

const UpdateOrAddPaymentMethodFooter = () => {
  const formik = useFormikContext();
  const { isValid, validateForm, submitForm, touched, values } = formik;
  const setCurrentStep = useSetCurrentStep();
  const isCurrentStepLoading = useIsCurrentStepLoading();
  const currentStep = useCurrentStep();
  const selectedPaymentMethod = useSelectedPaymentMethod();
  const isOneTimePayment = useIsOneTimePayment();
  const handleGoBack = () => {
    setCurrentStep(steps.SELECT_RECIPIENT);
  };
  useEffect(() => {
    validateForm();
  }, [values]);
  return (
    <>
      <BaselaneButton
        size="md"
        variant="outline"
        palette="neutral"
        onClick={handleGoBack}
        leftIcon={<Icon16ChevronLeft />}
      >
        Back
      </BaselaneButton>
      <BaselaneButton
        id="save-and-continue-button"
        size="md"
        variant="filled"
        palette="primary"
        width="100%"
        ml={1.5}
        flex={1}
        onClick={() => {
          if (touched) {
            submitForm();
          }
          sendSegmentEventsForSteps(values, currentStep, selectedPaymentMethod, isOneTimePayment);
        }}
        isDisabled={!isValid}
        rightIcon={<Icon16ChevronRight />}
        isLoading={isCurrentStepLoading}
      >
        Save and continue
      </BaselaneButton>
    </>
  );
};
export default UpdateOrAddPaymentMethodFooter;
