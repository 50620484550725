export const listItemContainerStyles = {
  alignItems: 'center',
  justifyContent: 'space-between',
  _notFirst: {
    mt: '36px',
  },
};

export const listItemLabelStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.600',
};

export const listItemValueStyles = {
  textStyle: 'headline-md',
  color: 'brand.neutral.700',
};

export const listContainerStyles = { gap: 0 };

export const dragHandleStyles = {
  gap: 0.25,
  w: '16px',
  h: '32px',
  my: -1,
  ml: -1.5,
  pl: 2,
  pr: 2,
  cursor: 'grab',
  alignItems: 'center',
  justifyContent: 'center',
};

export const listItemStyles = {
  borderWidth: '1px',
  borderColor: 'brand.darkBlue.100',
  borderBottomStyle: 'solid',
  borderTopStyle: 'none',
  borderLeftStyle: 'none',
  borderRightStyle: 'none',
  px: 1.5,
  py: 2.5,
  mt: '0 !important',
  backgroundColor: 'brand.neutral.white',
  position: 'relative',
  _last: {
    borderBottomStyle: 'solid',
  },
  touchaction: 'none', // disables default touch behavior, as we handle our own
};
