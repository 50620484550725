// @flow
import React from 'react';
import { Heading, HStack, Flex } from '@chakra-ui/react';
import { LocationDescriptor } from 'react-router-dom';
import { BaselaneButtonIcon } from '../../BaselaneButtonIcon';
import BaselaneButton from '../../BaselaneButton';
import {
  headerStyles,
  headingStyles,
  headerIconStyles as defaultHeaderIconStyles,
  headerIconContainerStyles,
  headingContainerStyles,
  actionLabelStyles,
} from '../styles/card.style';

/**
 * TODO: These interfaces should be globalized, as they can be used in other
 * mobile headers and such components that feature embedded action buttons.
 */

export interface Action {
  label: String;
  showLabel: Boolean;
  disabled: Boolean;
  icon?: Object;
}

export interface ActionHandler extends Action {
  onClick: Function;
}

export interface ActionRoute extends Action {
  to: LocationDescriptor;
}

const ActionButton = ({ action }: any) =>
  action.showLabel ? (
    <>
      {action.onClick && (
        <BaselaneButton
          variant="transparent"
          palette="neutral"
          isDisabled={action.disabled}
          onClick={action.onClick}
          rightIcon={action.icon}
        >
          <Flex {...actionLabelStyles}>{action.label}</Flex>
        </BaselaneButton>
      )}
      {action.to && (
        <BaselaneButton
          variant="transparent"
          palette="neutral"
          isDisabled={action.disabled}
          to={action.to}
          rightIcon={action?.icon}
        >
          <Flex {...actionLabelStyles}>{action.label}</Flex>
        </BaselaneButton>
      )}
    </>
  ) : (
    <>
      {action.onClick && (
        <BaselaneButtonIcon
          variant="transparent"
          palette="neutral"
          icon={action.icon}
          isDisabled={action.disabled}
          aria-label={action.label}
          onClick={action.onClick}
        />
      )}
      {action.to && (
        <BaselaneButtonIcon
          variant="transparent"
          palette="neutral"
          icon={action.icon}
          isDisabled={action.disabled}
          aria-label={action.label}
          to={action.to}
        />
      )}
    </>
  );

type Props = {
  icon?: Object,
  heading: String,
  action?: ActionHandler | ActionRoute,
};

function BaselaneResponsiveCardHeader({
  icon = null,
  action = null,
  heading,
  ...rest
}: Props): any {
  const HeaderIcon = icon;
  const iconDisabledStyles = action?.disabled ? { color: 'brand.darkBlue.300' } : {};
  const headingDisabledStyles = action?.disabled ? { color: 'brand.darkBlue.300' } : {};

  return (
    <HStack {...headerStyles} {...rest}>
      {HeaderIcon && (
        <Flex {...{ ...headerIconContainerStyles, ...iconDisabledStyles }}>
          <HeaderIcon {...defaultHeaderIconStyles} />
        </Flex>
      )}
      <HStack {...headingContainerStyles}>
        <Heading {...{ ...headingStyles, ...headingDisabledStyles }} width="100%">
          {heading}
        </Heading>
        {action && <ActionButton action={action} />}
      </HStack>
    </HStack>
  );
}

export default BaselaneResponsiveCardHeader;
