import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';
import useCurrentWorkspace from '@shared/hooks/useCurrentWorkspace';
import { TRANSACTIONS, AUTO_TAG_ONBOARDING } from '@routes';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { BaselaneAlertNew, BaselaneButton } from '@shared/components';
import { IllustrationAI } from '../../Illustrations';

const AutoTagPromoBanner = () => {
  const { isMax767 } = useBreakPoints();
  const { checkGate } = useStatsigClient();
  const location = useLocation();
  const navigate = useNavigate();
  const { workspaceMetadata } = useCurrentWorkspace();
  const { metadata } = workspaceMetadata || {};
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    if (!metadata?.hasSeenAutoTagPromo) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
  }, [location]);

  return checkGate(FEATURE_GATES.AUTO_TAGGING_GATE) && shouldShow ? (
    <BaselaneAlertNew
      styles={{ flexShrink: 0 }}
      variant="primary"
      visual="illustration"
      iconName={IllustrationAI}
      title="Fully automated bookkeeping is here"
      body=" Save hours every month with Baselane's new and powerful auto-tagging features"
      isVertical={isMax767}
      customButton={
        <BaselaneButton
          variant="filled"
          palette="primary"
          onClick={() => navigate(`${TRANSACTIONS}/${AUTO_TAG_ONBOARDING}`)}
        >
          Get started
        </BaselaneButton>
      }
    />
  ) : null;
};

export default AutoTagPromoBanner;
