import type { BarChartPropertyUnitDataSet, CashFlowByPropertyOrUnit } from '../../CashFlow/types';

function transformToPropertyUnitBarChartDataset(
  data: CashFlowByPropertyOrUnit[],
  { property: cashFlowPropertiesData },
  dataType
): BarChartPropertyUnitDataSet[] {
  return data
    ?.map(({ propertyId, propertyUnitId, propertyUnitUniqueId, expense = 0, income = 0 }) => {
      const netIncomeNumber = income + expense;
      let unit = null;

      const property =
        dataType === 'property' || propertyUnitId === null
          ? cashFlowPropertiesData.filter((p) => p.id === propertyId.toString())[0]
          : cashFlowPropertiesData.filter(
              (p) =>
                p.units.filter((u) => {
                  if (u.id === propertyUnitId?.toString()) {
                    unit = u;
                    return true;
                  }
                  return false;
                }).length > 0
            )[0];

      return {
        propertyUnit: unit ? propertyUnitUniqueId.toString() : propertyId.toString(),
        propertyUnitLabelObject: { propertyName: property?.name, unitName: unit?.name },
        propertyUnitLabel: unit ? `${property?.name}:::${unit?.name}` : property?.name,
        discriminator: unit ? propertyUnitUniqueId.toString() : propertyId.toString(),
        revenues: income,
        expenses: expense * -1, // TODO: Remove abs when BE return only positive numbers
        netIncome: parseFloat(netIncomeNumber.toFixed(2), 10),
      };
    })
    .sort((a, b) => a.propertyUnit - b.propertyUnit);
}

export default transformToPropertyUnitBarChartDataset;
