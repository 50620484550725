import React from 'react';
import {
  Icon12Calendar,
  Icon12Check,
  Icon12Close,
  Icon12InProgress,
  Icon12Overdue,
  Icon12Pending,
  Icon12Scheduled,
  Icon12Delete,
} from '@icons/12px';
import customTheme from '@theme';

export const INVOICE_STATUS_TYPES = {
  SCHEDULED: {
    label: 'Scheduled',
    icon: <Icon12Scheduled color={customTheme.colors.brand.blue['700']} />,
    color: 'brand.blue.700',
  },
  UPCOMING: {
    label: 'Upcoming',
    icon: <Icon12Calendar color={customTheme.colors.brand.blue['700']} />,
    color: 'brand.blue.700',
  },
  AWAITING_VERIFICATION: {
    label: 'Pending Verification',
    icon: <Icon12Pending color={customTheme.colors.brand.blue['700']} />,
    color: 'brand.blue.700',
  },
  OVERDUE: {
    label: 'Overdue',
    icon: <Icon12Overdue color={customTheme.colors.red['800AA']} />,
    color: 'red.800AA',
  },
  PROCESSING: {
    label: 'Processing',
    icon: <Icon12InProgress color={customTheme.colors.brand.blue['700']} />,
    color: 'brand.blue.700',
  },
  FAILED: {
    label: 'Failed',
    icon: <Icon12Close color={customTheme.colors.red['800AA']} />,
    color: 'red.800AA',
  },
  COMPLETED: {
    label: 'Completed',
    icon: <Icon12Check color={customTheme.colors.green['800AA']} />,
    color: 'green.800AA',
  },
  DELETED: {
    label: 'Deleted',
    icon: <Icon12Delete color={customTheme.colors.brand.darkBlue['300']} />,
    color: 'brand.darkBlue.300',
  },
};

export const UNPAID = ['AWAITING_VERIFICATION', 'OVERDUE', 'UPCOMING', 'FAILED', 'DELETED'];
