import React from 'react';
import {
  Box,
  Stack,
  HStack,
  Text,
  CircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/react';
import { IconChevronRight, IconWarningCircleOutline } from '@icons';
import { BaselaneButton } from '@shared/components';
import {
  inProgStepContainerStyles,
  stepTextStyles,
  stepDescriptionTextStyles,
  stepIconContainerStyles,
  stepTitleStyles,
  stepCircularProgressStyles,
  stepCircularProgressLabelStyles,
  rightChevronContainerStyles,
} from '../../styles/steps.styles';

type InProgressProps = {
  status: Object,
  handleTrackerInProgStepClick: Function,
  buttonLoading?: Boolean,
};

const InProgress = ({
  status,
  handleTrackerInProgStepClick,
  buttonLoading = false,
}: InProgressProps) => {
  const isWarning = status?.state === 'warning';
  const isAlert = status?.state === 'alert';

  return (
    <HStack {...inProgStepContainerStyles(status)} onClick={handleTrackerInProgStepClick}>
      {/* Status Icon */}
      <HStack alignItems="flex-start">
        <Stack {...stepIconContainerStyles}>
          {isWarning && <IconWarningCircleOutline color="#92630C" />}
          {isAlert && <IconWarningCircleOutline color="#C93A3A" />}
          {!isWarning && !isAlert && (
            <CircularProgress
              min={0}
              max={100}
              value={status?.hasNotStarted ? 0 : 25}
              size="17px"
              thickness="5px"
              trackColor="brand.darkBlue.250"
              capIsRound
              {...stepCircularProgressStyles}
            >
              <CircularProgressLabel {...stepCircularProgressLabelStyles} />
            </CircularProgress>
          )}
        </Stack>

        {/* Title + Description + Link Text */}
        <Box {...stepTitleStyles}>
          <Text {...stepTextStyles}>{status?.title}</Text>
          <Text {...stepDescriptionTextStyles}>{status?.description}</Text>
          {status?.linkText && (
            <BaselaneButton
              styles={{ alignSelf: 'flex-start' }}
              variant="transparent"
              palette="primary"
              pullLeft
              size="sm"
              isLoading={buttonLoading}
              onClick={() => {}}
            >
              {status.linkText}
            </BaselaneButton>
          )}
        </Box>
      </HStack>

      {/* Chevron Right */}
      {status?.linkText && (
        <Box {...rightChevronContainerStyles}>
          <IconChevronRight w="5" h="8" color="#192C3E" />
        </Box>
      )}
    </HStack>
  );
};

export default InProgress;
