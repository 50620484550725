import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';
import SlLoader from '@core/components/Loader';
import { IconArrowRight, IconDocumentCheck } from '@icons';
import { Icon16Close, Icon16Overdue, Icon16InProgress } from '@icons/16px';
import PaymentsWarningBanner from '@pages/LeasesPage/LeaseResponsiveDetail/PaymentsWarningBanner';
import InvoiceContext from '@contexts/InvoiceContext';
import { BaselaneButton, BaselaneCardNew } from '@shared/components';
import customTheme from '@theme';

import InvoiceDetails from '../../../InvoicesSection/DrawerBody/InvoiceDetails';
import { wordifyDate } from '../../formHelpers/leaseTermForm.helper';
import {
  leaseSectionHeader,
  leasePaymentsContainer,
  leasePaymentsEmptyContainer,
  leaseTermsLargeText,
  leaseSmallText,
  leaseSmallTextGray,
  leaseMediumText,
} from '../../../LeaseResponsiveDetail/styles/leaseResponsiveDetail.styles';

type PaymentsProps = {
  lease: Object,
  invoiceListDrawerRef: any,
  refetchSingleLease: Function,
};

function Payments({ lease, invoiceListDrawerRef, refetchSingleLease }: PaymentsProps) {
  const navigate = useNavigate();
  const title = 'Payments';

  const {
    invoiceList,
    getLeaseInvoices,
    refetchLeaseInvoices,
    setInvoiceList,
    selectedInvoiceId,
    setSelectedInvoiceId,
    selectedLeaseId,
    nextPaymentsClicked,
    setNextPaymentsClicked,
  } = useContext(InvoiceContext);
  const [formattedInvoiceDetails, setInvoiceDetails] = useState(null);
  const [invoiceListOpened, setInvoiceListOpened] = useState(false);
  const [hasUpdatedInvoice, setHasUpdatedInvoice] = useState(false);

  const invoiceDetailsDrawerRef = useRef();
  const invoiceDetailsDrawerActionBtnRef = useRef();

  const lastCompletedInvoice = lease?.invoiceDetails?.lastCompletedInvoice;
  const invoiceOverdueSummary = lease?.invoiceDetails?.invoiceOverdueSummary;
  const invoiceFailedSummary = lease?.invoiceDetails?.invoiceFailedSummary;
  const invoiceProcessingSummary = lease?.invoiceDetails?.invoiceProcessingSummary;

  // Logic for multiple amounts
  let multipleNextPaymentsTotalAmount = 0;
  lease?.invoiceDetails?.nextPayment?.forEach((item) => {
    multipleNextPaymentsTotalAmount += item.amount;
    return false;
  });
  const nextPayment = lease?.invoiceDetails?.nextPayment && lease?.invoiceDetails?.nextPayment[0];

  const refreshInvoiceDetails = () => {
    getLeaseInvoices({ variables: { input: { dateType: 'DUE_DATE', leaseId: lease?.id } } }).then(
      (res) => {
        let newInvoiceList = res.data?.invoiceList.invoices;
        if (nextPaymentsClicked) {
          const nextPaymentIdArray = [];
          lease.invoiceDetails?.nextPayment.forEach((item) => nextPaymentIdArray.push(item.id));
          newInvoiceList = res.data?.invoiceList.invoices.filter(
            (item) => nextPaymentIdArray.indexOf(item.id) > -1
          );
        }
        setInvoiceList(newInvoiceList);
      }
    );
  };
  const handleInvoiceDetailsDrawerOpen = (invoiceId) => {
    invoiceDetailsDrawerRef.current?.open();
    navigate('/leases', {
      state: {
        dateType: 'DUE_DATE',
        leaseId: lease?.id,
        invoiceId,
        noRefresh: true,
        noOpen: true,
      },
    });
  };

  const handleInvoiceListDrawerOpen = (state) => {
    const filter = {
      dateType: 'DUE_DATE',
      leaseId: selectedLeaseId,
    };
    if (state) filter.state = state;
    navigate('/leases', { state: { ...filter, noRefresh: true, noOpen: true } });
    getLeaseInvoices({ variables: { input: filter } }).then((res) => {
      const newInvoiceList = res.data?.invoiceList.invoices;
      setInvoiceList(newInvoiceList);
      invoiceListDrawerRef.current?.open();
      setInvoiceListOpened(true);
    });
  };

  const handleNextPaymentCardClick = () => {
    setSelectedInvoiceId(nextPayment?.id);
    setInvoiceDetails(nextPayment);
    // check if nextPayment is single or multiple
    if (lease?.invoiceDetails?.nextPayment?.length > 1) {
      setNextPaymentsClicked(true);
      getLeaseInvoices({ variables: { dateType: 'DUE_DATE', leaseId: lease?.id } }).then((res) => {
        const nextPaymentIdArray = [];
        lease?.invoiceDetails?.nextPayment.forEach((item) => nextPaymentIdArray.push(item.id));
        const newInvoiceList = res.data?.invoiceList.invoices.filter(
          (item) => nextPaymentIdArray.indexOf(item.id) > -1
        );
        navigate('/leases', {
          state: { dateType: 'DUE_DATE', leaseId: lease?.id, noRefresh: true },
        });
        setInvoiceList(newInvoiceList);
        invoiceListDrawerRef.current?.open();
      });
    } else if (invoiceList?.length === 0) {
      setNextPaymentsClicked(false);
      getLeaseInvoices({ variables: { dateType: 'DUE_DATE', leaseId: lease?.id } }).then((res) => {
        setNextPaymentsClicked(false);
        setInvoiceList(res.data?.invoiceList.invoices);
        handleInvoiceDetailsDrawerOpen(nextPayment?.id, res.data?.invoiceList.invoices);
      });
    } else {
      setNextPaymentsClicked(false);
      handleInvoiceDetailsDrawerOpen(nextPayment?.id);
    }
  };

  const handleLastPaymentCardClick = (id) => {
    invoiceDetailsDrawerRef?.current?.open();
    setSelectedInvoiceId(id);
    setInvoiceDetails(lastCompletedInvoice);
  };

  const handleInvoiceDetailsDrawerClose = () => {
    setSelectedInvoiceId(null);
    refetchSingleLease({ variables: { input: { leaseId: lease?.id } } }).then((response) => {
      const leaseRes = response.data?.leases[0];
      getLeaseInvoices({ variables: { input: { dateType: 'DUE_DATE', leaseId: lease?.id } } }).then(
        (res) => {
          let newInvoiceList = res.data?.invoiceList.invoices;
          if (nextPaymentsClicked) {
            const nextPaymentIdArray = [];
            leaseRes?.invoiceDetails?.nextPayment.forEach((item) =>
              nextPaymentIdArray.push(item.id)
            );
            newInvoiceList = res.data?.invoiceList.invoices.filter(
              (item) => nextPaymentIdArray.indexOf(item.id) > -1
            );
          }
          setInvoiceList(newInvoiceList);
          invoiceDetailsDrawerRef.current?.close();
        }
      );
    });
  };

  useEffect(() => {
    // if invoice list had been opened, and then changed after, we refresh the lease
    // this will refresh the payment warning and info banners counts and amounts
    if (invoiceListOpened && !invoiceListDrawerRef?.current?.isOpen) {
      refetchSingleLease({ variables: { input: { leaseId: lease?.id } } });
      setInvoiceListOpened(false);
    }
  }, [invoiceListOpened, invoiceListDrawerRef?.current?.isOpen]);

  return (
    <>
      <Flex mt={3} justifyContent="space-between">
        <Text {...leaseSectionHeader}>{title}</Text>
        <BaselaneButton
          variant="transparent"
          palette="primary"
          size="sm"
          id="view-all-invoices-button"
          onClick={() => handleInvoiceListDrawerOpen()}
        >
          View All Invoices
        </BaselaneButton>
      </Flex>
      {invoiceProcessingSummary?.count && (
        <PaymentsWarningBanner
          count={invoiceProcessingSummary.count}
          amount={invoiceProcessingSummary.amount}
          title="Processing Payment"
          icon={<Icon16InProgress color={customTheme.colors.brand.blue['800A']} />}
          action={() => handleInvoiceListDrawerOpen('PAYMENT_PROCESSING')}
        />
      )}
      {invoiceOverdueSummary?.count && (
        <PaymentsWarningBanner
          type="WARNING"
          count={invoiceOverdueSummary.count}
          amount={invoiceOverdueSummary.amount}
          title="Overdue Invoice"
          icon={<Icon16Overdue color={customTheme.colors.red['800AA']} />}
          action={() => handleInvoiceListDrawerOpen('PAYMENT_OVERDUE')}
        />
      )}
      {invoiceFailedSummary?.count && (
        <PaymentsWarningBanner
          type="WARNING"
          count={invoiceFailedSummary.count}
          amount={invoiceFailedSummary.amount}
          title="Failed Payment"
          icon={<Icon16Close color={customTheme.colors.red['800AA']} />}
          action={() => handleInvoiceListDrawerOpen('PAYMENT_FAILED')}
        />
      )}
      <Flex direction="row">
        {lastCompletedInvoice ? (
          <BaselaneCardNew
            variant="clickable"
            {...leasePaymentsContainer}
            onClick={() => handleLastPaymentCardClick(lastCompletedInvoice?.id)}
          >
            <Flex w="100%" direction="column" justifyContent="space-between">
              <Text {...leaseSmallText} mb="8px">
                Last Completed Payment
              </Text>
              <Text {...leaseSmallTextGray} mb="8px">
                Completed: {wordifyDate(lastCompletedInvoice?.invoiceCharge?.payoutArrivalDate)}
              </Text>
              <Text {...leaseTermsLargeText}>
                {formatCurrency(lastCompletedInvoice?.amount).inDollars}
              </Text>
              <Flex>
                <Text {...leaseSmallText}>Rent & Fees</Text>
                <Box m="8px 0 0 8px">
                  <IconArrowRight color="#257ED0" w={13.33} h={6.67} />
                </Box>
              </Flex>
            </Flex>
          </BaselaneCardNew>
        ) : (
          <BaselaneCardNew {...leasePaymentsEmptyContainer}>
            <Box m="14px 0 0 6px">
              <IconDocumentCheck />
            </Box>
            <Text {...leaseMediumText} mt="12px">
              No Completed Payments
            </Text>
          </BaselaneCardNew>
        )}

        {nextPayment && (
          <BaselaneCardNew
            variant="clickable"
            {...{ ...leasePaymentsContainer, ml: '1', maxHeight: '132px' }}
            onClick={() => (nextPaymentsClicked ? {} : handleNextPaymentCardClick())}
          >
            <Flex w="100%" direction="column" justifyContent="space-between">
              <Text {...leaseSmallText} mb="8px">
                Next Payment
              </Text>
              <Text {...leaseSmallTextGray} mb="8px">
                Due on: {wordifyDate(nextPayment?.dueDate)}
              </Text>
              <Text {...leaseTermsLargeText}>
                {formatCurrency(multipleNextPaymentsTotalAmount).inDollars}
              </Text>
              <Flex>
                <Text {...leaseSmallText}>Rent & Fees</Text>
                <Box m="8px 0 0 8px">
                  <IconArrowRight color="#257ED0" w={13.33} h={6.67} />
                </Box>
              </Flex>
            </Flex>
            {nextPaymentsClicked && (
              <SlLoader className="" styles={{ position: 'relative', top: '-50px' }} />
            )}
          </BaselaneCardNew>
        )}
        {!nextPayment && !nextPaymentsClicked && (
          <BaselaneCardNew {...leasePaymentsEmptyContainer} ml={1}>
            <Box m="14px 0 0 6px">
              <IconDocumentCheck />
            </Box>
            <Text {...leaseMediumText} mt="12px">
              No Outstanding Invoices
            </Text>
          </BaselaneCardNew>
        )}
      </Flex>

      {selectedInvoiceId && selectedInvoiceId === formattedInvoiceDetails?.id && (
        <InvoiceDetails
          setInvoiceList={setInvoiceList}
          invoiceDetailsDrawerRef={invoiceDetailsDrawerRef}
          invoiceDetailsDrawerActionBtnRef={invoiceDetailsDrawerActionBtnRef}
          handleInvoiceDetailsDrawerClose={handleInvoiceDetailsDrawerClose}
          isPropertyDeleted={formattedInvoiceDetails?.isPropertyDeleted}
          isMultiUnit={formattedInvoiceDetails?.isMultiUnit}
          refreshInvoiceDetails={refreshInvoiceDetails}
          lease={lease}
          hasUpdatedInvoice={hasUpdatedInvoice}
          setHasUpdatedInvoice={setHasUpdatedInvoice}
          refetchLeaseInvoices={refetchLeaseInvoices}
        />
      )}
    </>
  );
}

export default Payments;
