import React, { useContext, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';
import { Box, HStack, Text, RadioGroup, Radio, Spacer, Stack } from '@chakra-ui/react';
import {
  BaselaneButton,
  BaselaneDropdown,
  BaselaneTooltip,
  NoPropertyTooltip,
  T1Dropdown,
} from '@shared/components';
import CashFlowContext from '@contexts/CashFlowContext';
import IconInfo from '@icons/manual/IconInfo';
import IconX from '@icons/manual/IconX';
import {
  PRESETS,
  DEFAULT_TIME_PERIOD,
  defaultTimePeriod,
  PRESET_DESCRIPTIONS,
  calculateAnimationVariant,
} from '@shared/helpers/cashFlow.helpers';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { categoryOptionStyles } from '@core/components/Transactions/styles/categoryFilter.styles';
import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import {
  renderPropertyDropdownParentItem,
  renderPropertyDropdownChildItem,
} from '@core/components/Shared/helpers/propertiesFilter.helpers';
import { getOption } from '@shared/components/BaselaneDropdown/components/DatePicker/helpers/datepickerDropdown.helpers';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { ANALYTICS_REPORTING, CASH_FLOW_STATEMENT, CASH_FLOW_TAX_PACKAGE } from '@routes';
import {
  UNCATEGORIZED_ID,
  uncategorizedOption,
  getOptionsCategories,
  convertToDateObject,
} from '../helpers/cashflow.helpers';
import {
  containerStyles,
  customComponentWrapperStyles,
  customComponentTitleStyles,
  customComponentGroupStyles,
  customComponentRadioStyles,
  customComponentLabelStyles,
  centerAlignStyles,
} from './styles/filterSection.styles';

type FiltersSectionProps = {
  onSelectProperties: Function,
  onSelectCategories: Function,
  onSelectCategoryPreset?: Function,
  onSelectTimePeriod: Function,
  onSelectPreset?: Function,
  defaultPresetName?: String,
  presetOptions: Array<Object>,
  defaultIndex: Number,
  dataType: String,
  setDataType: Function,
};

function FiltersSection({
  onSelectProperties,
  onSelectCategories,
  onSelectCategoryPreset,
  onSelectTimePeriod,
  onSelectPreset,
  defaultPresetName,
  presetOptions,
  defaultIndex,
  dataType,
  setDataType,
}: FiltersSectionProps) {
  const { checkGate } = useStatsigClient();
  const isByPropertyUnitMenuVisible = checkGate(FEATURE_GATES.CASHFLOW_BY_PROPERTY_UNIT);
  const { properties: propertyDataOptions, categoryOptions, filters, persist } = useContext(
    CashFlowContext
  );

  const location = useLocation();

  const isCashFlowPage =
    location?.pathname.includes(CASH_FLOW_STATEMENT) ||
    location?.pathname === ANALYTICS_REPORTING ||
    location?.pathname === CASH_FLOW_TAX_PACKAGE;

  const isDisableAllFilters = defaultIndex === 2;

  const [showCustomCategories, setShowCustomCategories] = useState(!isCashFlowPage);

  const defaultPreset =
    defaultPresetName && presetOptions
      ? presetOptions?.find((option) => {
          return option?.name === defaultPresetName;
        })
      : null;

  const [stagedPreset, setStagedPreset] = useState(null);

  const [displayInputDate, setDisplayInputDate] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const [hasActiveParentFilter, setHasActiveParentFilter] = useState(true);
  const [presetCategories, setPresetCategories] = useState(defaultPreset);

  const optionsCategories = getOptionsCategories(categoryOptions);

  const getSelectedCategories = (tagIds) => {
    const selectedCategories = optionsCategories?.reduce((acc, item) => {
      const selectedTags = item.items.filter((i) => tagIds?.find((tagId) => tagId === i.id));
      return acc.concat(selectedTags);
    }, []);
    return selectedCategories;
  };

  /**
   * This is only used by Cash Flow Analytics
   */
  const handleDateFilterChange = (startDate, endDate, dateFilter) => {
    // Persist the date filter.
    persist.setTimePeriodDate({
      from: startDate,
      to: endDate,
    });

    onSelectTimePeriod({
      from: new Date(moment(startDate).valueOf()),
      to: new Date(moment(endDate).valueOf()),
    });

    onSelectPreset(dateFilter);

    sendSegmentEvent('cashflow_filter_date', {
      start_date: moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
      end_date: moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ'),
      date_filter: dateFilter,
    });
  };

  const [animationVariant, _setAnimationVariant] = useState(null);

  const setAnimationVariant = (value) => {
    if (animationVariant !== value) {
      _setAnimationVariant(value);
    }
  };

  /**
   * When category dropdown is closed, we downgrade animation
   * state so that it doesn't animate again when opening.
   */
  const handleDropdownClose = () => {
    if (animationVariant === 'open') setAnimationVariant('beginOpened');
    if (animationVariant === 'close') setAnimationVariant('beginClosed');
  };

  /**
   * Transforms dropdown based on which preset was clicked on.
   * @param {string} presetClicked Name of the preset that was clicked on
   */
  const handleCategoryPresetToggle = (presetClicked) => {
    const presetSelected = presetOptions?.find((preset) => preset?.name === presetClicked);

    setPresetCategories(presetSelected);
    setHasActiveParentFilter(true);
    const selectedItems = getSelectedCategories(presetSelected?.tagId);
    if (presetSelected.uncategorized && !selectedItems.includes(uncategorizedOption.items[0])) {
      selectedItems.push(uncategorizedOption.items[0]);
    }
    setShowCustomCategories(presetSelected.name === PRESETS.CUSTOM);
    setStagedPreset(presetSelected);
    setSelectedItem(selectedItems);
    setAnimationVariant(calculateAnimationVariant(presetSelected, false));
  };

  /**
   * Transforms dropdown based on which preset was chosen, but this can be called
   * from anywhere the state change is needed- including when initializing.
   *
   * @param {string} presetClicked Name of the preset that was clicked on
   * @param {boolean} isInitializing True if component should be treated as though is being initialized.
   */
  const applyCategoryPreset = (preset, isInitializing = false) => {
    setPresetCategories(preset);
    setHasActiveParentFilter(true);
    const selectedItems = getSelectedCategories(preset?.tagId);
    const processedPreset = preset;
    if (preset?.uncategorized) {
      selectedItems.push(uncategorizedOption.items[0]);
      processedPreset.tagId = [...(preset?.tagId || {}), uncategorizedOption.items[0].id];
    }
    setShowCustomCategories(preset?.name === PRESETS.CUSTOM);
    setStagedPreset(processedPreset);
    setSelectedItem(selectedItems);
    setAnimationVariant(calculateAnimationVariant(preset, isInitializing));
  };

  const isCurrentPreset = (preset) => presetCategories?.name === preset?.name;

  const getPresetOptionWithTooltip = (preset, tooltipText) => (
    <Radio
      key={preset?.name}
      value={preset?.name}
      isChecked={isCurrentPreset(preset)}
      {...customComponentRadioStyles}
    >
      <Box {...centerAlignStyles}>
        <Text {...customComponentLabelStyles} mr="12px">
          {preset?.name}
        </Text>
        <BaselaneTooltip label={tooltipText} styles={{ w: '250px' }}>
          <IconInfo width="16" height="16" color="#192C3E" />
        </BaselaneTooltip>
      </Box>
    </Radio>
  );

  const onClearRef = useRef();

  const onClear = () => {
    onClearRef?.current?.onClear();
  };

  const getPresetOptionSimple = (preset) => (
    <HStack key={`hstack-${preset?.name}`} justifyContent="space-between">
      <Radio
        key={`preset-option-${preset?.name}`}
        value={preset?.name}
        isChecked={isCurrentPreset(preset)}
        {...customComponentRadioStyles}
      >
        <Text key={`preset-label-${preset?.name}`} {...customComponentLabelStyles}>
          {preset?.name}
        </Text>
      </Radio>
      {isCashFlowPage && preset?.name === PRESETS.CUSTOM && isCurrentPreset(preset) && (
        <BaselaneButton
          variant="transparent"
          palette="neutral"
          leftIcon={<IconX color="#9BA9C1" />}
          onClick={onClear}
          {...{
            key: `preset-clear-${preset?.name}`,
          }}
        >
          Clear
        </BaselaneButton>
      )}
    </HStack>
  );

  const getPresetOption = (preset) => {
    if (PRESET_DESCRIPTIONS[preset?.name]) {
      return getPresetOptionWithTooltip(preset, PRESET_DESCRIPTIONS[preset?.name]);
    }
    return getPresetOptionSimple(preset);
  };

  const updateCategoryFilters = (filteredCategories) => {
    let filteredCategoriesWithUnCategorizedSelection = [...filteredCategories];
    let isUncategorizedSelected = false;

    const uncategorizedSelectedOption = filteredCategories.find(
      (cat) => cat.id === UNCATEGORIZED_ID
    );
    if (uncategorizedSelectedOption) {
      filteredCategoriesWithUnCategorizedSelection = filteredCategoriesWithUnCategorizedSelection.filter(
        (cat) => cat.id !== UNCATEGORIZED_ID
      );
      isUncategorizedSelected = true;
    }

    if (presetCategories?.name === PRESETS.CUSTOM) {
      presetCategories.tagId = filteredCategoriesWithUnCategorizedSelection.map(({ id }) => id);
      presetCategories.uncategorized = isUncategorizedSelected;
    }

    onSelectCategories(
      presetCategories,
      filteredCategoriesWithUnCategorizedSelection,
      isUncategorizedSelected
    );

    // overwrite persistent values, so that values match
    // current state when switching tabs
    persist.setCategoryOptions([...filteredCategoriesWithUnCategorizedSelection]);
    persist.setCategoryPreset(presetCategories);

    // overwrite persistent values, so that values match
    // current state when switching tabs
    if (stagedPreset) {
      persist.setHasActiveParentFilter(true);
      persist.setCategoryPreset(stagedPreset);
      onSelectCategoryPreset(stagedPreset);
    }
  };

  const getPropertiesUnits = (selectedPropertyData) => {
    const [p, u] = selectedPropertyData.reduce(
      (acc, property) => {
        acc[property.isChild ? 1 : 0].push(property);
        return acc;
      },
      [[], []]
    );

    const propertyIds = p.map((cat) => cat.id);
    const unitIds = u.map((cat) => cat.unitId);

    return { propertyIds, unitIds };
  };

  const handleProperyFilterChange = (selectedPropertyData) => {
    onSelectProperties(getPropertiesUnits(selectedPropertyData));
    persist.setPropertyData(selectedPropertyData);
  };

  const filterCustomComponent = (
    <Box {...{ ...customComponentWrapperStyles, pb: '12px' }}>
      <Text {...customComponentTitleStyles}>Presets Views</Text>
      <RadioGroup
        className="custom-radio primary-500"
        {...customComponentGroupStyles}
        value={presetCategories?.name}
        onChange={handleCategoryPresetToggle}
      >
        {presetOptions?.map((preset, index) => getPresetOption(preset, index))}
      </RadioGroup>
    </Box>
  );

  useEffect(() => {
    if (!persist.categoryPreset) {
      applyCategoryPreset(defaultPreset, true);
    } else {
      applyCategoryPreset(persist.categoryPreset, true);
      if (persist.categoryPreset?.name === PRESETS.CUSTOM) {
        setAnimationVariant('beginOpened');
      }
    }
  }, [persist.categoryPreset]);

  useEffect(() => {
    if (presetCategories && !persist.categoryPreset) {
      const defaultSelectedCategories = getSelectedCategories(defaultPreset.tagId);
      updateCategoryFilters(defaultSelectedCategories);
    }
  }, [presetCategories]);

  useEffect(() => {
    /**
     * Initialize Dropdowns with persistent
     * values if they are available.
     * (when filter is applied)
     */

    if (Object.keys(filters?.filter).length > 0) {
      // Date Filter
      if (filters?.filter?.to && filters?.filter?.from) {
        const option = getOption(
          new Date(moment(filters?.filter?.from)),
          new Date(moment(filters?.filter?.to))
        );
        if (option) {
          persist.setTimePeriodDate(option);
        }
      }

      // Category Filter
      if (filters?.filter?.tagId) {
        if (persist.categoryPreset) {
          setPresetCategories(persist.categoryPreset);
          setHasActiveParentFilter(persist.hasActiveParentFilter);
        } else {
          persist.setCategoryOptions(getSelectedCategories(filters?.filter?.tagId));
          persist.setHasActiveParentFilter(false);
        }
      }

      /**
       * Note: Property Filter does not have secondary UI effects
       */
    }
  }, [filters]);

  const dataTypeMenuItemRenderer = ({ item }) => (
    <Text id={item.id} textStyle="sm" style={{ textTransform: 'capitalize' }}>
      {item?.name}
    </Text>
  );

  const title = isCashFlowPage ? presetCategories?.name ?? defaultPresetName : 'Category';
  const placeholder = isCashFlowPage ? null : 'Category';

  return (
    <Stack direction="row" {...containerStyles}>
      {isByPropertyUnitMenuVisible && (
        <HStack spacing={1.5}>
          <Text textStyle="headline-md" style={{ textWrap: 'nowrap', color: 'black' }}>
            View by
          </Text>
          <T1Dropdown
            id="cashflow-by-type-menu"
            type="tier1"
            classNames={['input-form-md']}
            hideSearch
            showValueByFields={['name']}
            handleSubmit={(value) => setDataType(value)}
            isDisabled={location?.pathname === CASH_FLOW_TAX_PACKAGE}
            data={[
              {
                id: 'month',
                name: 'Month',
                value: 'month',
              },
              {
                id: 'property',
                name: 'Property',
                value: 'property',
              },
              {
                id: 'unit',
                name: 'Unit',
                value: 'unit',
              },
            ]}
            itemRenderer={(item) => dataTypeMenuItemRenderer(item)}
            selectedItem={{
              id: dataType,
              name: dataType.charAt(0).toUpperCase() + dataType.slice(1),
              value: dataType,
            }}
          />
        </HStack>
      )}
      <Spacer />
      <HStack spacing={1.5}>
        {/* Date Filter */}
        <BaselaneDropdown
          {...{
            type: 'date',
            title: 'Date',
            isMulti: true,
            hasFilterWrapper: true,
            handleSubmit: handleDateFilterChange,
            displayInputDate,
            setDisplayInputDate,
            defaultTimePeriod: DEFAULT_TIME_PERIOD,
            defaultTimePeriodDate: defaultTimePeriod,
            selectedStartDate:
              persist.timePeriodDate?.from ?? convertToDateObject(defaultTimePeriod?.from),
            selectedEndDate:
              persist.timePeriodDate?.to ?? convertToDateObject(defaultTimePeriod?.to),
            clearButtonText: 'Reset',
            isDisabled: isDisableAllFilters,
          }}
        />
        {/* Categories Filter */}
        <BaselaneDropdown
          {...{
            data: optionsCategories,
            title,
            placeholder,
            searchTitle: 'Category',
            showValueByFields: ['name'],
            itemRenderer,
            handleSubmit: updateCategoryFilters,
            optionStyles: categoryOptionStyles,
            selectedItem: selectedItem ?? persist.categoryOptions,
            isMulti: true,
            hasFilterWrapper: true,
            filterCustomComponent,
            hasActiveParentFilter: hasActiveParentFilter ?? persist.hasActiveParentFilter,
            setHasActiveParentFilter,
            showCustomCategories,
            animationVariant,
            handleDropdownClose,
            isDisabled: isDisableAllFilters,
            hasCheckboxes: true,
          }}
          onClearRef={isCashFlowPage ? onClearRef : null}
        />
        {/* Properties/Units Filter */}
        {propertyDataOptions?.length === 0 ? (
          <NoPropertyTooltip>
            <BaselaneDropdown
              {...{
                type: 'tier2',
                title: 'All Properties',
                placeholder: 'All Properties',
                selectedItem: null,
                isDisabled: true,
              }}
            />
          </NoPropertyTooltip>
        ) : (
          <BaselaneDropdown
            {...{
              type: 'tier2',
              data: propertyDataOptions,
              searchTerm: 'name',
              title: 'All Properties',
              showValueByFields: ['name'],
              parentItemRenderer: ({ item }) => renderPropertyDropdownParentItem(item),
              childItemRenderer: ({ item }) => renderPropertyDropdownChildItem(item),
              handleSubmit: handleProperyFilterChange,
              selectedItem: persist.propertyData ?? [],
              isMulti: true,
              hasFilterWrapper: true,
              hasDropdownClearedExternally: false,
              setHasDropdownClearedExternally: false,
              isDisabled: isDisableAllFilters,
            }}
          />
        )}
      </HStack>
    </Stack>
  );
}

FiltersSection.defaultProps = {
  onSelectPreset: () => {},
  onSelectCategoryPreset: () => {},
  defaultPresetName: null,
};

export default FiltersSection;
