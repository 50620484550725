import React from 'react';
import { Text, Stack, VStack } from '@chakra-ui/react';
import { lastFourDigits } from '@core/utils/formatAccountNumber';
import formatCurrency from '@core/utils/formatCurrency';

export const getAccountData = (account) => {
  if (!account) return {};

  const mainAccount = {
    id: account.id,
    accountName: account.accountName,
    nickName: account.nickName,
    accountSubType: account.accountSubType,
    accountNumber: account.accountNumber,
    availableBalance: account.availableBalance,
    checkBookAvailability: account.checkBookAvailability,
    mainAccount: true,
  };

  const subAccountsWithoutSavings = account.subAccounts.filter(
    (sub) => sub.accountSubType !== 'savings'
  );

  const subAccounts = subAccountsWithoutSavings.filter((sub) => {
    const {
      id,
      accountName,
      nickName,
      accountSubType,
      accountNumber,
      availableBalance,
      checkBookAvailability,
    } = sub;
    return {
      id,
      accountName,
      nickName,
      accountSubType,
      accountNumber,
      availableBalance,
      checkBookAvailability,
      mainAccount: false,
    };
  });

  return { mainAccount, subAccounts };
};

export const getCheckbookAccounts = (account) => {
  if (!account) return {};

  const { mainAccount, subAccounts } = getAccountData(account);

  const availableSubAccounts = subAccounts?.filter(
    (sub) => sub?.checkBookAvailability?.isAvailable
  );

  const accountItems = [];
  if (mainAccount?.checkBookAvailability?.isAvailable) accountItems.push(mainAccount);
  if (availableSubAccounts?.length) accountItems.push(...availableSubAccounts);

  if (!accountItems.length) return [];

  const item = {
    title: account.bankName,
    items: accountItems,
  };

  return [item];
};

export const accountItemRenderer = ({ item }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    w="100%"
    id={`account-item-${item?.accountSubType}`}
  >
    <VStack alignItems="flex-start" gap={0}>
      <Text textStyle="sm" color="brand.neutral.700">
        {item?.nickName}
      </Text>
      <Text textStyle="xs" color="brand.neutral.600">
        <Text as="span" textTransform="capitalize">
          {item.accountSubType}
        </Text>{' '}
        account - {lastFourDigits(item?.accountNumber)}
      </Text>
    </VStack>
    <Text>{formatCurrency(item?.availableBalance).inDollars}</Text>
  </Stack>
);

export const getSelectedAccount = (data, accountid) => {
  const bankAccount = data.find((d) => {
    return d?.items.find((item) => item.id === accountid);
  });
  return bankAccount?.items.find((item) => item.id === accountid);
};

export const formatAllAccountsData = (allAccounts) => {
  return allAccounts.map((entity) => {
    const { mainAccount, subAccounts } = getAccountData(entity);
    return {
      title: entity.bankName,
      items: [mainAccount, ...subAccounts],
    };
  });
};
