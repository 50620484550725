import React, { useState, useRef } from 'react';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { BaselaneAlert, BaselaneButton, BaselaneButtonGroup } from '@shared/components';
import IconExclamationCircle from '@icons/manual/IconExclamationCircle';

import {
  badgeIconBgStyles,
  badgeIconContainerStyles,
  titleTextStyles,
  headerStyles,
} from '../../../styles/archiveAlert.styles';
import {
  leaseEndArchiveHeader,
  leaseEndArchiveText,
} from '../../../LeaseResponsiveDetail/styles/leaseResponsiveDetail.styles';

function ArchiveLease({
  handleArchiveLease,
  isTablet,
  showCustomButton,
  customButtonProps,
}: {
  handleArchiveLease: Function,
  isTablet: boolean,
  showCustomButton?: boolean,
  customButtonProps?: Object,
}) {
  // alert for Archive
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const onAlertIsClose = () => setIsAlertOpen(false);
  const cancelRef = useRef();
  const handleAlertIsOpen = () => setIsAlertOpen(true);
  const handleAlertContinue = () => {
    handleArchiveLease();
    setIsAlertOpen(false);
  };
  const alertHeader = (
    <HStack {...headerStyles}>
      <Box {...badgeIconBgStyles}>
        <Box {...badgeIconContainerStyles}>
          <IconExclamationCircle />
        </Box>
      </Box>
      <Text {...titleTextStyles}>Are you sure you want to end & archive this rent collection?</Text>
    </HStack>
  );
  const alertFooter = (
    <BaselaneButtonGroup size="md">
      <BaselaneButton
        variant="outline"
        palette="neutral"
        size="md"
        ref={cancelRef}
        onClick={onAlertIsClose}
      >
        Cancel
      </BaselaneButton>
      <BaselaneButton
        id="end-archive-confirm-button"
        variant="filled"
        palette="danger"
        size="md"
        onClick={handleAlertContinue}
      >
        End & Archive Rent Collection
      </BaselaneButton>
    </BaselaneButtonGroup>
  );

  const alertMessage = (
    <Text lineHeight="20px">
      Any unpaid invoices will be canceled, and your tenant will be notified. Processing payments
      will proceed. This action can not be undone.
    </Text>
  );

  return (
    <>
      <BaselaneAlert
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertIsClose}
        isCentered
        header={alertHeader}
        body={alertMessage}
        footer={alertFooter}
        footerStyles={{ pt: '30px' }}
        size="xl"
      />
      {showCustomButton ? (
        <BaselaneButton {...customButtonProps} onClick={handleAlertIsOpen}>
          {customButtonProps.title}
        </BaselaneButton>
      ) : (
        <Flex justifyContent="space-between" direction={isTablet ? 'row' : 'column'}>
          <Box width={isTablet ? '60%' : '100%'} mb={2}>
            <Text {...leaseEndArchiveHeader}>End & Archive Rent Collection</Text>
            <Text {...leaseEndArchiveText}>
              Any unpaid invoices will be canceled, and your tenant will be notified. Processing
              payments will proceed.
            </Text>
          </Box>
          <BaselaneButton
            id="end-archive-button"
            onClick={handleAlertIsOpen}
            variant="filled"
            palette="danger"
            size="md"
          >
            End & Archive
          </BaselaneButton>
        </Flex>
      )}
    </>
  );
}

ArchiveLease.defaultProps = {
  showCustomButton: false,
  customButtonProps: {},
};

export default ArchiveLease;
