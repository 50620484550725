import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useStatsigClient } from '@statsig/react-bindings';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { LEASES_QUICKPAY_LEARN_MORE } from '@routes';
import { BaselaneLink, BaselaneToolpop } from '@shared/components';
import { IconLightningOutline } from '@icons';
import { FEATURE_GATES } from '@core/constants/statsigKeys';

type QuickPayIconOrBadgeProps = {
  isIconOnly?: boolean,
  isQuickPayEnabled?: boolean,
  isQuickPayEligible?: boolean,
  isQuickPayProcessed?: boolean,
  isDisabled?: boolean,
  label?: string,
};

const QuickPayIconOrBadge = ({
  isIconOnly = false,
  isQuickPayEnabled = false,
  isQuickPayEligible = false,
  isQuickPayProcessed = false,
  isDisabled = false,
  label = 'Quickpay eligible',
}: QuickPayIconOrBadgeProps) => {
  const { checkGate } = useStatsigClient();

  const location = useLocation() || {};
  const { state } = location || {};

  const { leaseId, invoiceId } = state || {};

  const originalState = {
    leaseId,
    invoiceId,
    noRefresh: true,
    noOpen: true,
  };
  const onButtonClick = (e) => {
    e.stopPropagation();
    sendSegmentEvent('quickpay_click_learn_more');
  };

  const quickpayTooltipTrigger = isIconOnly ? (
    <Box
      bg={isQuickPayEnabled ? 'green.100' : 'brand.neutral.150'}
      borderRadius="50"
      w="16px"
      h="16px"
      padding="2px"
    >
      <IconLightningOutline w="12px" h="12px" color={isQuickPayEnabled ? '#398311' : undefined} />
    </Box>
  ) : (
    <Flex
      bg={isQuickPayEnabled ? 'green.100' : 'brand.neutral.150'}
      borderRadius="50px"
      w="100%"
      h="20px"
      alignItems="center"
      whiteSpace="nowrap"
    >
      <Box padding="4px">
        <IconLightningOutline w="12px" h="12px" color={isQuickPayEnabled ? '#398311' : undefined} />
      </Box>
      <Box fontSize="14px" lineHeight="20px" padding="0 4px">
        {isQuickPayEnabled ? 'QuickPay enabled' : 'QuickPay eligible'}
      </Box>
    </Flex>
  );

  const quickpayTooltipText = isQuickPayProcessed ? (
    <>
      <Text as="b" fontWeight="500">
        Processed with QuickPay
      </Text>
      <Text textAlign="left" style={{ textWrap: 'wrap' }}>
        {isQuickPayProcessed
          ? 'Payments processed with QuickPay are typically received in your bank account in 2-3 business days.'
          : 'Rent payments will be accelerated and received in 2-3 business days vs. the standard 4-5 business days.'}
      </Text>
    </>
  ) : (
    <>
      <Text as="b" fontWeight="500">
        {isQuickPayEnabled ? 'QuickPay enabled' : 'Tenant eligible for QuickPay'}
      </Text>
      <Text textAlign="left" style={{ textWrap: 'wrap' }}>
        {isQuickPayEnabled
          ? 'Rent payments will be accelerated and received in 2-3 business days vs. the standard 4-5 business days.'
          : 'Accelerate your rent payments with QuickPay, and receive payments in 2-3 business days vs. 4-5 business days. To qualify for QuickPay, collect rent into your Baselane Banking account.'}
      </Text>
      {checkGate(FEATURE_GATES.QUICK_PAY_ANNOUNCE_BANNER_GATE) && (
        <BaselaneLink
          variant="reverse"
          to={LEASES_QUICKPAY_LEARN_MORE}
          state={{ from: location.pathname, originalState }}
          size="md"
          onClick={(e) => onButtonClick(e)}
        >
          Learn more
        </BaselaneLink>
      )}
    </>
  );

  return (
    <BaselaneToolpop
      label="Quickpay eligible"
      trigger={quickpayTooltipTrigger}
      isDisabled={isDisabled}
    >
      {quickpayTooltipText}
    </BaselaneToolpop>
  );
};

export default QuickPayIconOrBadge;
