import React, { useContext } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';

import { BaselaneGrid } from '@shared/components';
import BankEntityContext from '@contexts/BankEntityContext';
import AddAccountTrigger from '@pages/BankEntity/AddAccount/AddAccountTrigger';
import useBreakPoints from '@core/hooks/useBreakPoints';

import UserAccessContext from '@contexts/UserAccessContext';

import AccountCard from './AccountCard';
import CardCarousel from './CardCarousel';
import { gridStyles } from './styles/grid.styles';

const AccountCards = () => {
  const {
    account,
    activeFilter,
    setActiveFilter,
    allBankAccountIdsFilter,
    selectedBankAccountId,
    setSelectedBankAccountId,
  } = useContext(BankEntityContext);
  const { subAccounts, totalBalance } = account;

  const { isMin768 } = useBreakPoints();

  const { authorizedForBanking } = useContext(UserAccessContext);

  const connectedSubAccounts = subAccounts?.filter(
    (acc) => acc.accountStatus.toLowerCase() === 'open'
  );
  const sortedSubAccounts = connectedSubAccounts.sort(
    (a, b) => Number(a.createdAt) - Number(b.createdAt)
  );

  const updateActiveFilter = (accountid) => {
    const { bankAccountId = [] } = activeFilter || {};
    const aId = Number(accountid);
    const bankAccountSelected = selectedBankAccountId === accountid ? '' : accountid;
    setSelectedBankAccountId(bankAccountSelected);

    // to toggle to card that is active/not-active
    // if it is to untoggle, set accountIds to show all bank account ids
    const accountId =
      bankAccountId?.length === 1 && bankAccountId[0] === aId ? allBankAccountIdsFilter : [aId];
    const updateFilter = {
      bankAccountId: accountId,
    };
    setActiveFilter(updateFilter);
  };

  const mainAccountCard = (
    <AccountCard
      key={account.id}
      isMainAccount
      account={account}
      updateActiveFilter={updateActiveFilter}
    />
  );

  const subAccountCards = sortedSubAccounts.map((subAccount) => (
    <AccountCard key={subAccount.id} account={subAccount} updateActiveFilter={updateActiveFilter} />
  ));

  const addVirtualCard = authorizedForBanking ? (
    <AddAccountTrigger key="addsubaccountnonemptystate" type="card" />
  ) : null;

  return isMin768 ? (
    <Box px="32px">
      <Text color="brand.neutral.700" textStyle="headline-sm">
        Accounts
      </Text>
      <Box {...gridStyles.gridWrapper}>
        <BaselaneGrid
          gridStyles={gridStyles.grid}
          gridItems={[
            {
              element: mainAccountCard,
            },
            {
              element: (
                <HStack h="100%" gap="12px">
                  {subAccountCards}
                  {addVirtualCard}
                </HStack>
              ),
            },
          ]}
          templateColumns="repeat(1, 326px auto)"
          gap={0}
        />
      </Box>
    </Box>
  ) : (
    <Box mb={isMin768 ? '16px' : '24px'}>
      <CardCarousel
        cards={[mainAccountCard, ...subAccountCards, addVirtualCard]}
        updateActiveFilter={updateActiveFilter}
        mobileCardsData={[account, ...sortedSubAccounts]}
        totalBalance={totalBalance}
      />
    </Box>
  );
};

export default AccountCards;
