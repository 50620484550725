import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Stack, Text, Box, useDisclosure } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';

import IconFile from '@icons/manual/IconFile';
import { AlertFooter, AlertHeader, BaselaneAlert, BaselaneButton } from '@shared/components';
import { SortsAndFilters } from '@shared/types/Sort';
import UserContext from '@contexts/UserContext';
import { CASH_FLOW_STATEMENT } from '@routes';
import onDrawerClose from '@core/utils/onDrawerClose';
import TableRow from './components/TableRow';
import { MERGE_SPLIT_TRX } from '../queries';
import { updateCacheAfterMerge } from './helpers/updateCache.helper';
import { headerItems, parentTransactionItems } from './helpers/originalTransactionGridItems.helper';
import { tableBodyStyles, tableHeaderStyles, titleTextStyles } from './styles/table.styles';
import { SplitTransaction, Transaction } from '../types';

type OriginalTransactionProps = {
  transaction: Transaction,
  isEdit: boolean,
  filters: SortsAndFilters,
  splitTransactions: Array<SplitTransaction>,
  setHasUpdatedSplitTrx: Function,
  setHasDataUpdated: Function,
  from: string,
};

const OriginalTransaction = ({
  transaction,
  isEdit,
  filters,
  splitTransactions,
  setHasUpdatedSplitTrx,
  setHasDataUpdated,
  from,
}: OriginalTransactionProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const [mergeSplitTransactions, { loading }] = useMutation(MERGE_SPLIT_TRX);
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  const parentTxItems = parentTransactionItems(transaction);

  const handleMergeSplitTransactions = () => {
    if (transaction?.isSplit) {
      mergeSplitTransactions({
        variables: { id: Number(transaction.id) },
        update: (cache, { data: { mergeSplitTransaction: parentTrx } }) =>
          updateCacheAfterMerge({
            cache,
            parentTrx,
            splitTransactions,
            filters,
            user,
          }),
      }).then(() => {
        // close trx details drawer
        if (location?.pathname.includes(CASH_FLOW_STATEMENT)) {
          navigate(-1);
        } else {
          onDrawerClose(navigate, from);
        }

        setHasUpdatedSplitTrx(true);
        setHasDataUpdated(true);
      });
    }
    onAlertClose();
  };

  const alertFooter = (
    <AlertFooter
      rightButtonEvent={handleMergeSplitTransactions}
      leftButtonEvent={onAlertClose}
      leftButtonText="Cancel"
      rightButtonText="Merge Transaction"
    />
  );

  return (
    <Stack spacing="28px">
      <BaselaneAlert
        showCloseButton
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        header={<AlertHeader title="Do you want to merge this transaction?" />}
        body="By merging this transaction, all data, including category, property or unit, and notes, will be reverted to the original transaction. This action can not be reversed."
        footer={alertFooter}
        size="2xl"
      />

      <Stack direction="row" h="32px" align="center" justify="space-between">
        <Text {...titleTextStyles}>Original Transaction</Text>
        {isEdit && (
          <BaselaneButton
            variant="outline"
            palette="danger"
            leftIcon={<IconFile />}
            onClick={onAlertOpen}
            isLoading={loading}
          >
            Merge Transaction
          </BaselaneButton>
        )}
      </Stack>

      <Box>
        <TableRow {...{ items: headerItems }} {...tableHeaderStyles} />
        <TableRow {...{ items: parentTxItems }} {...tableBodyStyles} />
      </Box>
    </Stack>
  );
};

export default OriginalTransaction;
