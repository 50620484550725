import { create } from 'zustand';
import { steps } from './helpers/formHelpers';

const useSendFundsStore = create((set) => ({
  newRecipient: {
    name: undefined,
    email: undefined,
    notes: undefined,
    type: undefined,
    paymentMethods: {},
  },
  totalSteps: 6,
  transferType: '',
  currentStep: steps.SELECT_RECIPIENT,
  isCurrentStepDisabled: true,
  isCurrentStepLoading: false,
  currentStepFunction: () => {},
  setCurrentStep: (newStep) => set({ currentStep: newStep }),
  setIsCurrentStepDisabled: (isDisabled) => set({ isCurrentStepDisabled: isDisabled }),
  setIsCurrentStepLoading: (isloading) => set({ isCurrentStepLoading: isloading }),
  isOneTimePayment: true,
  isSameDayPayment: true,
  recipient: undefined,
  serverRecipient: undefined,
  selectedPaymentMethod: {},
  setIsOneTimePayment: (newValue) => set({ isOneTimePayment: newValue }),
  setIsSameDayPayment: (newValue) => set({ isSameDayPayment: newValue }),
  updateRecipient: (newRecipient) => set({ recipient: newRecipient }),
  updateServerRecipient: (newRecipient) => set({ serverRecipient: newRecipient }),
  createNewRecipient: (newValues) => set({ newRecipient: newValues }),
  setTransferType: (newValues) => set({ transferType: newValues }),
  setSelectedPaymentMethod: (newSelectedPaymentMethod) =>
    set({ selectedPaymentMethod: newSelectedPaymentMethod }),
  upcomingPayments: [],
  updateUpcomingPayments: (newUpcomingPayments) => set({ upcomingPayments: newUpcomingPayments }),
}));

export const useNewRecipient = () => useSendFundsStore((state) => state.newRecipient);
export const useTotalSteps = () => useSendFundsStore((state) => state.totalSteps);
export const useCurrentStep = () => useSendFundsStore((state) => state.currentStep);
export const useSetCurrentStep = () => useSendFundsStore((state) => state.setCurrentStep);
export const useIsCurrentStepDisabled = () =>
  useSendFundsStore((state) => state.isCurrentStepDisabled);
export const useIsCurrentStepLoading = () =>
  useSendFundsStore((state) => state.isCurrentStepLoading);
export const useCurrentStepFunction = () => useSendFundsStore((state) => state.currentStepFunction);
export const useSetIsCurrentStepDisabled = () =>
  useSendFundsStore((state) => state.setIsCurrentStepDisabled);
export const useSetIsCurrentStepLoading = () =>
  useSendFundsStore((state) => state.setIsCurrentStepLoading);
export const useTransferType = () => useSendFundsStore((state) => state.transferType);
export const useSetTransferType = () => useSendFundsStore((state) => state.setTransferType);
export const useIsOneTimePayment = () => useSendFundsStore((state) => state.isOneTimePayment);
export const useIsSameDayPayment = () => useSendFundsStore((state) => state.isSameDayPayment);
export const useRecipient = () => useSendFundsStore((state) => state.recipient);
export const useServerRecipient = () => useSendFundsStore((state) => state.serverRecipient);
export const useSelectedPaymentMethod = () =>
  useSendFundsStore((state) => state.selectedPaymentMethod);
export const useSetIsOneTimePayment = () => useSendFundsStore((state) => state.setIsOneTimePayment);
export const useSetIsSameDayPayment = () => useSendFundsStore((state) => state.setIsSameDayPayment);
export const useUpdateRecipient = () => useSendFundsStore((state) => state.updateRecipient);
export const useUpdateServerRecipient = () =>
  useSendFundsStore((state) => state.updateServerRecipient);
export const useCreateNewRecipient = () => useSendFundsStore((state) => state.createNewRecipient);
export const useSetSelectedPaymentMethod = () =>
  useSendFundsStore((state) => state.setSelectedPaymentMethod);
export const useUpcomingPayments = () => useSendFundsStore((state) => state.upcomingPayments);
export const useUpdateUpcomingPayments = () =>
  useSendFundsStore((state) => state.updateUpcomingPayments);
