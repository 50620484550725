import { useQuery } from '@apollo/client';
import {
  CASHFLOW_QUERY,
  PROPERTY_CASHFLOW_QUERY,
  PROPERTY_UNIT_CASHFLOW_QUERY,
} from '@core/apollo/queries';
import { cashFlowDummyData, cashFlowSummaryDummyData } from '@shared/helpers/dummyData.helpers';

const useCashFlow = ({ input, dataType }) => {
  let query = CASHFLOW_QUERY;
  if (dataType === 'property') {
    query = PROPERTY_CASHFLOW_QUERY;
  } else if (dataType === 'unit') {
    query = PROPERTY_UNIT_CASHFLOW_QUERY;
  }
  const {
    loading: cashFlowLoading,
    error: cashFlowError,
    data,
    refetch: cashFlowRefetch,
  } = useQuery(query, {
    variables: { input },
    onError: (error) => console.error(error),
  });

  const { cashFlow, propertyCashFlow, propertyUnitCashFlow } = data || {};
  const cashFlowData = cashFlow || propertyCashFlow || propertyUnitCashFlow;

  return {
    cashFlowLoading,
    cashFlowError,
    cashFlowData: { cashFlow: cashFlowData },
    cashFlowDummyData,
    cashFlowSummaryDummyData,
    cashFlowRefetch,
  };
};

export default useCashFlow;
