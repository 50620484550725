import React, { useEffect, useRef, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { sortBy } from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  GET_PROPERTY_DOCUMENTS,
  CREATE_PROPERTY_DOCUMENT,
  GET_PROPERTIES_DROPDOWN,
} from '@core/apollo/queries';
import { useCurrentWorkspace } from '@shared/hooks';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { sortWithString } from '@core/utils/sort';
import BaselaneDrawer from '../BaselaneDrawer';
import FirstStep from './components/FirstStep';
import LinkProperty from './components/LinkProperty';
import RocketLawyeriFrame from './components/RocketLawyeriFrame';
import Footer from './components/Footer';
import { showErrorToast } from './helpers/shared.helpers';
import { drawerStyles } from './styles/rocketLawyerDocumentDrawer.styles';

type RocketLawyerDocumentDrawerProps = {
  from: String,
};

const RocketLawyerDocumentDrawer = ({ from }: RocketLawyerDocumentDrawerProps) => {
  const { drawerRef } = useRef();
  const navigate = useNavigate();
  const { isMinXL, isMax768 } = useBreakPoints();

  const toast = useToast();
  const { workspaceMetadata } = useCurrentWorkspace();
  const { propertyDocumentConfig } = workspaceMetadata ?? {};

  // GET_PROPERTY_DOCS
  const { loading, error, data, refetch: refetchPropertyDocuments } = useQuery(
    GET_PROPERTY_DOCUMENTS
  );

  // CREATE_PROPERTY_DOC
  const [createPropertyDocument] = useMutation(CREATE_PROPERTY_DOCUMENT);

  // GET_PROPERTIES_DATA
  const { loading: propertiesLoading, error: propertiesError, data: propertiesData } = useQuery(
    GET_PROPERTIES_DROPDOWN
  );

  // State Vars
  const [search, setSearch] = useState('');
  const [showSelectProperty, setShowSelectProperty] = useState(false);
  const [showSelectUnit, setShowSelectUnit] = useState(false);
  const [showiFrame, setShowiFrame] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState();
  const [selectedUnit, setSelectedUnit] = useState();
  const [selectedPropertyDoc, setSelectedPropertyDoc] = useState();
  const [isDocReadyToSign, setIsDocReadyToSign] = useState();
  const [loadingObj, setLoadingObj] = useState({
    showLoading: false,
    caption: '',
  });

  // Helper Functions
  const showLoader = ({ caption }) => {
    setLoadingObj({ showLoading: true, caption });
  };

  const hideLoader = () => {
    setLoadingObj({ showLoading: false, caption: '' });
  };

  const cleanup = () => {
    setShowSelectProperty(false);
    setShowSelectUnit(false);
    setSelectedProperty(false);
    setSelectedUnit(false);
    setSearch('');
  };

  // UseEffects
  useEffect(() => {
    if (selectedPropertyDoc) {
      setIsDocReadyToSign(selectedPropertyDoc.state !== 'DRAFT');
    }
  }, [selectedPropertyDoc]);

  useEffect(() => {
    if (loading || propertiesLoading) {
      showLoader({ caption: 'Loading...' });
    } else {
      hideLoader();
    }
  }, [loading, propertiesLoading]);

  // Prepare Data for UI
  const { propertyDocuments } = data ?? {};
  const sortedDocuments = propertyDocuments
    ? sortBy(propertyDocuments, (doc) => Number(doc.id))
    : [];

  const properties = propertiesData?.property || [];
  const hasNoProperties = !properties?.length;
  const sortedProperties = properties && sortWithString(properties, 'name');

  if (error || propertiesError) return null;

  // Main Functions
  const handleDrawerClose = () => {
    cleanup();
    navigate(from);
  };

  const handleOpeniFrame = () => {
    cleanup();
    setShowiFrame(true);
  };

  const handleCloseiFrame = () => {
    showLoader({ caption: 'Loading...' });
    setShowiFrame(false);
    refetchPropertyDocuments().then(() => {
      hideLoader();
      setSelectedPropertyDoc(null);
      setIsDocReadyToSign(null);
    });
  };

  const handleCreateDocument = ({ input }) => {
    cleanup();
    showLoader({ caption: 'Creating document...' });
    createPropertyDocument({
      variables: {
        input,
      },
      onCompleted: ({ createPropertyDocument: newDoc }) => {
        setSelectedPropertyDoc(newDoc);
        handleOpeniFrame();
        // NOTE: setTimeout Prevents Documents UI showing up for a brief second
        setTimeout(() => {
          hideLoader();
        }, 500);
      },
      onError: (err) => {
        console.error(err);
        hideLoader();
        showErrorToast(toast);
      },
    });
  };

  const handleBackClick = () => {
    if (showSelectProperty) {
      cleanup();
      setShowSelectProperty(false);
    } else {
      setShowSelectUnit(false);
      setShowSelectProperty(true);
    }
  };

  const handleContinueClick = () => {
    if (showSelectProperty) {
      setShowSelectProperty(false);

      const propertyObj = sortedProperties.find((property) => property?.id === selectedProperty);
      const hasMultipleUnits = propertyObj?.units?.length > 1;

      if (hasMultipleUnits) {
        // Continue clicked from Select Property (Multiple Unit Property)
        setShowSelectUnit(true);
      } else {
        // Continue clicked from Select Property (Single Unit Property)
        handleCreateDocument({
          input: { propertyUnitId: selectedUnit, propertyId: selectedProperty },
        });
      }
    } else {
      // Continue clicked from Select Unit
      handleCreateDocument({
        input: { propertyUnitId: selectedUnit, propertyId: null },
      });
    }
  };

  // Footer
  const drawerFooter = !loadingObj.showLoading ? (
    <Footer
      {...{
        showSelectProperty,
        showSelectUnit,
        handleBackClick,
        handleContinueClick,
        selectedProperty,
        selectedUnit,
      }}
    />
  ) : null;

  return (
    <BaselaneDrawer
      ref={drawerRef}
      isOpen
      title="Create Lease Document"
      size={isMax768 ? 'full' : 'smd'}
      closeEvent={handleDrawerClose}
      onClose={handleDrawerClose}
      contentStyles={{ h: '100%' }}
      footer={drawerFooter}
      newDrawerCustomFooterStyles={
        showSelectProperty || showSelectUnit
          ? { ...drawerStyles.footer({ isMinXL }) }
          : { ...drawerStyles.logofooter({ isMinXL }) }
      }
      newDesignDrawer
      id="rocket-lawyer-drawer"
    >
      <>
        {showSelectProperty || showSelectUnit ? (
          // copy from here

          <LinkProperty
            {...{
              sortedProperties,
              showSelectUnit,
              selectedProperty,
              setSelectedProperty,
              selectedUnit,
              setSelectedUnit,
              search,
              setSearch,
            }}
          />
        ) : (
          <FirstStep
            {...{
              hasNoProperties,
              sortedProperties,
              setShowSelectProperty,
              setSelectedPropertyDoc,
              handleOpeniFrame,
              documents: sortedDocuments,
              propertyDocumentConfig,
              loadingObj,
            }}
          />
        )}

        {selectedPropertyDoc && (
          <RocketLawyeriFrame
            {...{
              showiFrame,
              handleCloseiFrame,
              isDocReadyToSign,
              setIsDocReadyToSign,
              selectedPropertyDoc,
            }}
          />
        )}
      </>
    </BaselaneDrawer>
  );
};

export default RocketLawyerDocumentDrawer;
