import { gql } from '@apollo/client';
import { FRAGMENT_INVOICE } from '@pages/LeasesPage/queries';

export const FRAGMENT_MINIMAL_BANK_ACCOUNT = gql`
  fragment MinimalBankAccountObj on BankAccount {
    id
    accountNumber
    accountStatus
    nickName
    accountName
    accountType
    accountSubType
    stripeBankAccountId
    isExternal
    bankAccountMetadata {
      payoutFailedReason {
        failureCode
        failureMessage
      }
      lastPayoutFailed
    }
  }
`;

export const FRAGMENT_MINIMAL_BANK_ACCOUNT_WITH_CARDS = gql`
  fragment MinimalBankAccountWithCardsObj on BankAccount {
    id
    accountNumber
    accountStatus
    nickName
    accountName
    accountType
    accountSubType
    stripeBankAccountId
    isExternal
    bankAccountMetadata {
      payoutFailedReason {
        failureCode
        failureMessage
      }
      lastPayoutFailed
    }
    cards {
      id
      externalId
      nickname
      nameOnCard
      isPhysical
      last4Digits
      cardStatus
    }
  }
`;

export const GET_BANKS = gql`
  query Bank(
    $isConnectedBank: Boolean
    $isConnectedAccount: Boolean
    $accountType: AccountType
    $isImporting: Boolean
    $isRentCollectionSupported: Boolean
    $accountStatus: BankAccountStatus
    $isManualAccount: Boolean
    $accountOwnershipType: AccountOwnershipType
  ) {
    bank(
      input: {
        isConnectedBank: $isConnectedBank
        isConnectedAccount: $isConnectedAccount
        accountType: $accountType
        isImporting: $isImporting
        isRentCollectionSupported: $isRentCollectionSupported
        accountStatus: $accountStatus
        isManualAccount: $isManualAccount
        accountOwnershipType: $accountOwnershipType
      }
    ) {
      id
      userId
      plaidInstitutionName
      isConnected
      isExternal
      rentCollectionSupported
      logo
      name
      unitAccount {
        unitApplicationStatus
      }
      bankAccounts {
        id
        accountNumber
        accountStatus
        routingNumber
        nickName
        accountName
        accountType
        accountSubType
        stripeBankAccountId
        updatedAt
        availableBalance
        currentBalance
        isConnected
        isExternal
        connectionState
        revokedByUser
        rentCollectionSupported
        bankAccountMetadata {
          payoutFailedReason {
            failureCode
            failureMessage
          }
          lastPayoutFailed
        }
        oldBRBAccountDetail {
          accountName
          accountNumber
          routingNumber
        }
        bankPartner
        subAccounts(
          input: { isConnectedAccount: $isConnectedAccount, accountStatus: $accountStatus }
        ) {
          id
          availableBalance
          accountNumber
          accountStatus
          routingNumber
          nickName
          accountName
          accountType
          accountSubType
          createdAt
          updatedAt
          currentBalance
          isConnected
          isExternal
          connectionState
          revokedByUser
          bankAccountMetadata {
            payoutFailedReason {
              failureCode
              failureMessage
            }
            lastPayoutFailed
          }
          stripeBankAccountId
          importTransaction {
            enabled
            importTransactionType
            updatedAt
          }
        }
        importTransaction {
          enabled
          importTransactionType
          updatedAt
        }
      }
    }
  }
`;

export const GET_ACCOUNT_DROPDOWN_BANKS = gql`
  query getAccountDropdownBanks(
    $isConnectedBank: Boolean
    $isConnectedAccount: Boolean
    $accountType: AccountType
    $accountStatus: BankAccountStatus
    $isExternal: Boolean
    $isManualAccount: Boolean
  ) {
    bank(
      input: {
        isConnectedBank: $isConnectedBank
        isConnectedAccount: $isConnectedAccount
        accountType: $accountType
        accountStatus: $accountStatus
        isExternal: $isExternal
        isManualAccount: $isManualAccount
      }
    ) {
      id
      userId
      plaidInstitutionName
      isExternal
      name
      unitAccount {
        unitApplicationStatus
      }
      bankAccounts {
        ...MinimalBankAccountObj
        subAccounts(
          input: { isConnectedAccount: $isConnectedAccount, accountStatus: $accountStatus }
        ) {
          ...MinimalBankAccountObj
        }
      }
    }
  }
  ${FRAGMENT_MINIMAL_BANK_ACCOUNT}
`;

export const GET_ACCOUNT_DROPDOWN_BANKS_WITH_CARDS = gql`
  query getAccountDropdownBanks(
    $isConnectedBank: Boolean
    $isConnectedAccount: Boolean
    $accountType: AccountType
    $accountStatus: BankAccountStatus
    $isExternal: Boolean
    $isManualAccount: Boolean
  ) {
    bank(
      input: {
        isConnectedBank: $isConnectedBank
        isConnectedAccount: $isConnectedAccount
        accountType: $accountType
        accountStatus: $accountStatus
        isExternal: $isExternal
        isManualAccount: $isManualAccount
      }
    ) {
      id
      userId
      plaidInstitutionName
      isExternal
      name
      unitAccount {
        unitApplicationStatus
      }
      bankAccounts {
        ...MinimalBankAccountWithCardsObj
        subAccounts(
          input: { isConnectedAccount: $isConnectedAccount, accountStatus: $accountStatus }
        ) {
          ...MinimalBankAccountWithCardsObj
        }
      }
    }
  }
  ${FRAGMENT_MINIMAL_BANK_ACCOUNT_WITH_CARDS}
`;

export const GET_LEASE_BANKS = gql`
  query Bank(
    $isConnectedBank: Boolean
    $isConnectedAccount: Boolean
    $accountType: AccountType
    $isImporting: Boolean
    $isRentCollectionSupported: Boolean
    $accountStatus: BankAccountStatus
    $isManualAccount: Boolean
    $accountOwnershipType: AccountOwnershipType
  ) {
    bank(
      input: {
        isConnectedBank: $isConnectedBank
        isConnectedAccount: $isConnectedAccount
        accountType: $accountType
        isImporting: $isImporting
        isRentCollectionSupported: $isRentCollectionSupported
        accountStatus: $accountStatus
        isManualAccount: $isManualAccount
        accountOwnershipType: $accountOwnershipType
      }
    ) {
      id
      userId
      plaidInstitutionName
      isConnected
      isExternal
      rentCollectionSupported
      logo
      name
      unitAccount {
        unitApplicationStatus
      }
      bankAccounts {
        id
        accountNumber
        accountStatus
        routingNumber
        nickName
        accountName
        accountType
        accountSubType
        stripeBankAccountId
        updatedAt
        availableBalance
        currentBalance
        isConnected
        isExternal
        connectionState
        revokedByUser
        rentCollectionSupported
        transactionSupported
        transferSupported
        cards {
          id
          externalId
          isPhysical
          nickname
          nameOnCard
        }
        bankAccountMetadata {
          payoutFailedReason {
            failureCode
            failureMessage
          }
          lastPayoutFailed
        }
        subAccounts(
          input: { isConnectedAccount: $isConnectedAccount, accountStatus: $accountStatus }
        ) {
          id
          availableBalance
          accountNumber
          accountStatus
          routingNumber
          nickName
          accountName
          accountType
          accountSubType
          createdAt
          updatedAt
          currentBalance
          isConnected
          isExternal
          connectionState
          revokedByUser
          bankAccountMetadata {
            payoutFailedReason {
              failureCode
              failureMessage
            }
            lastPayoutFailed
          }
          stripeBankAccountId
          autoTag {
            enabled
            propertyUnitId
            propertyId
          }
          importTransaction {
            enabled
            importTransactionType
            updatedAt
          }
        }
        autoTag {
          enabled
          propertyUnitId
          propertyId
        }
        importTransaction {
          enabled
          importTransactionType
          updatedAt
        }
      }
    }
  }
`;

export const CREATE_MANUAL_BANK_ACCOUNT = gql`
  mutation createManualBankAccount(
    $accountHolderType: AccountHolderType!
    $accountType: ManualBankAccountType!
    $accountNumber: String!
    $fullName: String!
    $routingNumber: String!
    $nickName: String
    $accountOwnershipType: AccountOwnershipType!
    $purpose: ManualBankAccountCreationPurpose
  ) {
    createManualBankAccount(
      input: {
        accountHolderType: $accountHolderType
        accountType: $accountType
        accountNumber: $accountNumber
        fullName: $fullName
        routingNumber: $routingNumber
        nickName: $nickName
        accountOwnershipType: $accountOwnershipType
        purpose: $purpose
      }
    ) {
      id
      accountNumber
      accountStatus
      routingNumber
      nickName
      accountName
      accountType
      accountSubType
      stripeBankAccountId
      updatedAt
      availableBalance
      currentBalance
      isConnected
      isExternal
      bankAccountMetadata {
        payoutFailedReason {
          failureCode
          failureMessage
        }
        lastPayoutFailed
      }
      subAccounts {
        id
        availableBalance
        accountNumber
        accountStatus
        routingNumber
        nickName
        accountName
        accountType
        accountSubType
        createdAt
        updatedAt
        currentBalance
        isConnected
        isExternal
        stripeBankAccountId
        bankAccountMetadata {
          payoutFailedReason {
            failureCode
            failureMessage
          }
          lastPayoutFailed
        }
        autoTag {
          enabled
          propertyUnitId
          propertyId
        }
        importTransaction {
          enabled
          importTransactionType
          updatedAt
        }
        linkedFor {
          linkFor
          property {
            id
            propertyUnitId
          }
        }
      }
      autoTag {
        enabled
        propertyUnitId
        propertyId
      }
      importTransaction {
        enabled
        importTransactionType
        updatedAt
      }
      linkedFor {
        linkFor
        property {
          id
          propertyUnitId
        }
      }
    }
  }
`;

export const GET_BANK_INFO = gql`
  query BankInfo($routingNumber: String!, $purpose: ManualBankAccountCreationPurpose) {
    bankInfo(routingNumber: $routingNumber, purpose: $purpose) {
      id
      name
    }
  }
`;

export const GET_SINGLE_BANK_ACCOUNT = gql`
  query SingleBankAccount($id: ID!) {
    bankAccount(id: $id) {
      id
      accountNumber
      nickName
      stripeBankAccountId
      bankAccountMetadata {
        payoutFailedReason {
          failureCode
          failureMessage
        }
        lastPayoutFailed
      }
      invoices {
        ...Invoices
      }
      leases {
        id
        state
        startDate
        endDate
        propertyName
        propertyAddress
        propertyUnitName
        propertyUnitId
        tenantProfileId
        leaseType
        paymentState
        totalUnits
        rentAmount
        rentAndFeesBankAccountId
      }
    }
  }
  ${FRAGMENT_INVOICE}
`;

export const UPDATE_BANK_ACCOUNT_FOR_LEASES_AND_INVOICES = gql`
  mutation updateBankAccountForLeasesAndInvoices(
    $currentBankAccountId: ID
    $newBankAccountId: ID!
    $modifyOnlyRentAndFeeAccount: Boolean
    $leaseIds: [ID!]
  ) {
    updateBankAccountForLeasesAndInvoices(
      currentBankAccountId: $currentBankAccountId
      newBankAccountId: $newBankAccountId
      modifyOnlyRentAndFeeAccount: $modifyOnlyRentAndFeeAccount
      leaseIds: $leaseIds
    )
  }
`;

export const GET_CONNECTED_BANK_ACCOUNTS_LIGHT = gql`
  query ConnectedBankAccounts {
    bank(input: { isConnectedBank: true, isConnectedAccount: true }) {
      id
      isConnected
      isExternal
      unitAccount {
        unitApplicationStatus
      }
    }
  }
`;
