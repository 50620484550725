import { React } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import {
  BaselaneInput,
  BaselaneFormLabel,
  BaselaneRadioGroup,
  BaselaneRadio,
} from '@shared/components';
import { PayeeType } from '../../helpers/constants.helpers';

type RecipientDetailsFieldsProps = {
  prefillName?: Function,
};

const RecipientDetailsFields = ({ prefillName }: RecipientDetailsFieldsProps) => {
  const {
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleBlur,
  } = useFormikContext();
  const typeOptions = [
    { id: PayeeType.INDIVIDUAL, name: 'Individual' },
    { id: PayeeType.BUSINESS, name: 'Business' },
  ];

  return (
    <>
      {/* Recipient Name */}
      <FormControl id="recipient-name" isRequired isInvalid={errors?.name && touched?.name}>
        <BaselaneFormLabel textStyle="sm">Recipient name</BaselaneFormLabel>
        <BaselaneInput
          size="lg"
          id="name"
          name="name"
          value={values?.name}
          type="text"
          onChange={(e) => {
            setFieldTouched('name');
            handleChange(e);
          }}
          onBlur={(e) => {
            prefillName(values?.name);
            handleBlur(e);
          }}
          maxLength={50}
        />
        <FormErrorMessage>{errors?.name}</FormErrorMessage>
      </FormControl>

      {/* Recipient Email */}
      <FormControl id="recipient-email" isInvalid={errors.email && touched.email}>
        <BaselaneFormLabel>Recipient email (optional)</BaselaneFormLabel>
        <BaselaneInput
          size="lg"
          id="email"
          name="email"
          value={values?.email}
          type="text"
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={(e) => {
            setFieldTouched('email');
            handleBlur(e);
          }}
          maxLength={50}
        />
        <FormErrorMessage>{errors.email}</FormErrorMessage>
      </FormControl>

      {/* Recipient type */}
      <FormControl id="recipient-type" isRequired isInvalid={errors.type && touched.type}>
        <BaselaneFormLabel>Recipient type</BaselaneFormLabel>
        <BaselaneRadioGroup
          direction="row"
          id="typeGroup"
          name="typeGroup"
          onChange={(e) => {
            setFieldTouched('type');
            setFieldValue('type', e);
            handleChange(e);
          }}
          onBlur={handleBlur}
          value={values?.type}
        >
          <>
            {typeOptions.map((option) => (
              <BaselaneRadio
                key={option.id}
                label={option.name}
                value={option.id}
                id={`type${option.name}`}
                name="type"
                checked={values?.type === option.id}
              />
            ))}
          </>
        </BaselaneRadioGroup>
      </FormControl>
    </>
  );
};

RecipientDetailsFields.defaultProps = {
  prefillName: () => {},
};

export default RecipientDetailsFields;
