// @flow
import React, { useRef, useEffect } from 'react';
import moment from 'moment';
import { orderBy } from 'lodash';
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';
import {
  FormLabel,
  Input,
  Text,
  Box,
  Stack,
  HStack,
  Select,
  FormControl,
  useDisclosure,
} from '@chakra-ui/react';
import IconEdit from '@icons/manual/IconEdit';
import AmountInput from '@shared/components/AmountInput';
import { BaselaneButton, BaselaneDivider, BaselaneSingleDatePicker } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { formatDate } from '@core/utils/formatDate';
import { percentageMask } from '@core/utils/masks';
import { formLabelStyles, formInputStyles } from '@shared/styles/input.style';
import { mortgageTermOptions } from '../../../helpers/financials.helper';
import {
  drawerMenuContentTitleStyle,
  drawerDescriptionStyles,
  financialsContainerStyles,
} from '../../../styles/drawer.style';
import { formSectionTitleStyles, dividerTitleStyles } from '../../../styles/form.style';

type FinancialsProps = {
  property: Object,
  setShowZillow: Function,
  setShowMortgage: Function,
  setFormVariables: Function,
  setHideFooter: Function,
  setIsDirty: Function,
  isDirty: boolean,
  initialValues: Object,
  marketPrice: Array<Object>,
  mortgageBalance: Array<Object>,
};

function Financials({
  property,
  setShowZillow,
  setShowMortgage,
  setFormVariables,
  setHideFooter,
  setIsDirty,
  isDirty,
  initialValues,
  marketPrice,
  mortgageBalance,
}: FinancialsProps): any {
  const formikRef = useRef();
  const { isMin768 } = useBreakPoints();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [latestMarketPrice] = orderBy(marketPrice, ['date'], ['desc']);
  const [latestMortgageBalance] = orderBy(mortgageBalance, ['date'], ['desc']);
  const lastEditedMarketPriceDate = formatDate(latestMarketPrice?.date);
  const lastEditedMortgageBalanceDate = formatDate(latestMortgageBalance?.date);

  const getMortgagePrincipalBalanceValue = (mortgagePrincipleBalance) => {
    if (property) {
      return latestMortgageBalance ? latestMortgageBalance.balance : '';
    }
    return mortgagePrincipleBalance;
  };

  useEffect(() => {
    setIsDirty({ ...isDirty, ...{ financials: formikRef.current?.dirty } });
  }, [formikRef.current?.values?.purchaseDate]);

  return (
    <Box h="100%" id="financials-tab">
      <Text {...drawerMenuContentTitleStyle} mb="28px">
        Property Financials (Optional)
      </Text>
      <Text {...drawerDescriptionStyles}>
        This information is used to calculate performance metrics for your properties. It is
        optional to enter but recommended to get the most value out of the Baselane platform.
      </Text>
      <Box {...financialsContainerStyles}>
        <Formik innerRef={formikRef} initialValues={initialValues}>
          {({ values, dirty, handleChange, handleBlur, setFieldValue }) => (
            <Stack spacing="0">
              {/* Property Details */}
              <Box>
                <HStack {...dividerTitleStyles}>
                  <Text {...formSectionTitleStyles}>Property Details</Text>
                  <BaselaneDivider styles={{ m: 0 }} />
                </HStack>

                <HStack spacing="36px">
                  {/* Purchase Price */}
                  <FormControl w="50%">
                    <FormLabel {...formLabelStyles.xs}>Purchase Price</FormLabel>
                    <AmountInput
                      type="text"
                      id="purchasePrice"
                      name="purchasePrice"
                      {...formInputStyles}
                      allowdecimal="false"
                      placeholder="$500,000"
                      onBlur={() => {
                        setIsDirty({ ...isDirty, ...{ financials: dirty } });
                        setFormVariables({ purchasePrice: values.purchasePrice });
                      }}
                    />
                  </FormControl>

                  {/* Purchase Date */}
                  <FormControl w="50%">
                    <FormLabel {...formLabelStyles.xs}>Purchase Date</FormLabel>
                    <BaselaneSingleDatePicker
                      {...{
                        id: 'date',
                        name: 'date',
                        placeholder: 'October 22, 2020',
                        maxDate: moment().toDate(),
                        date: values.purchaseDate,
                        value: values.purchaseDate
                          ? `${formatDate(values.purchaseDate, 'MMM DD, YYYY')}`
                          : '',
                        dateFormat: 'MMM dd, yyyy',
                        handleCalendarClose: async ({ date: newDate, isFromApplyButton }) => {
                          if (isMin768 || (!isMin768 && isFromApplyButton)) {
                            setFieldValue('purchaseDate', newDate);
                            setFormVariables({ purchaseDate: newDate });
                            await Promise.resolve();
                            setIsDirty({ ...isDirty, ...{ financials: dirty } });
                          }
                        },
                        years: { start: 2010, end: new Date().getFullYear() },
                        isOpen,
                        onOpen,
                        onClose,
                        hideInputIcon: true,
                        placement: 'bottom-end',
                        showInModal: !isMin768,
                      }}
                    />
                  </FormControl>
                </HStack>

                <HStack spacing="36px" mb="16px" mt="24px">
                  {/* Closing Costs */}
                  <FormControl w="50%">
                    <FormLabel {...formLabelStyles.xs}>Closing Costs</FormLabel>
                    <AmountInput
                      type="text"
                      id="closingCosts"
                      name="closingCosts"
                      {...formInputStyles}
                      allowdecimal="false"
                      placeholder="$500,000"
                      onBlur={() => {
                        setIsDirty({ ...isDirty, ...{ financials: dirty } });
                        setFormVariables({ closingCosts: values.closingCosts });
                      }}
                    />
                  </FormControl>

                  {/* Annual Property Taxes */}
                  <FormControl w="50%">
                    <FormLabel {...formLabelStyles.xs}>Annual Property Taxes</FormLabel>
                    <AmountInput
                      type="text"
                      id="annualPropertyTaxes"
                      name="annualPropertyTaxes"
                      {...formInputStyles}
                      allowdecimal="false"
                      placeholder="$1,000"
                      onBlur={() => {
                        setIsDirty({ ...isDirty, ...{ financials: dirty } });
                        setFormVariables({ annualPropertyTaxes: values.annualPropertyTaxes });
                      }}
                    />
                  </FormControl>
                </HStack>

                <HStack spacing="36px" mb="20px" mt="24px">
                  {/* Annual Property Insurance */}
                  <FormControl w="calc(50% - 18px)">
                    <FormLabel {...formLabelStyles.xs}>Annual Property Insurance</FormLabel>
                    <AmountInput
                      type="text"
                      id="annualPropertyInsurance"
                      name="annualPropertyInsurance"
                      {...formInputStyles}
                      allowdecimal="false"
                      placeholder="$1,000"
                      onBlur={() => {
                        setIsDirty({ ...isDirty, ...{ financials: dirty } });
                        setFormVariables({
                          annualPropertyInsurance: values.annualPropertyInsurance,
                        });
                      }}
                    />
                  </FormControl>
                </HStack>
              </Box>

              {/* Mortgage Details */}
              <Box mt="48px !important">
                <HStack {...dividerTitleStyles}>
                  <Text {...formSectionTitleStyles}>Mortgage Details</Text>
                  <BaselaneDivider styles={{ m: 0 }} />
                </HStack>

                <HStack align="center" mb="16px" spacing="36px">
                  {/* Down Payment */}
                  <FormControl w="50%">
                    <FormLabel {...formLabelStyles.xs}>Down Payment</FormLabel>
                    <AmountInput
                      type="text"
                      id="mortgageDownpayment"
                      {...formInputStyles}
                      name="mortgageDownpayment"
                      allowdecimal="true"
                      value={values.mortgageDownpayment}
                      onBlur={(e) => {
                        handleBlur(e);
                        setIsDirty({ ...isDirty, ...{ financials: dirty } });
                        setFormVariables({ mortgageDownpayment: values.mortgageDownpayment });
                      }}
                    />
                  </FormControl>

                  {/* Loan Terms */}
                  <FormControl w="50%">
                    <FormLabel {...formLabelStyles.xs}>Loan Terms</FormLabel>
                    <Select
                      {...formInputStyles}
                      p="0"
                      id="mortgageTerm"
                      name="mortgageTerm"
                      value={values.mortgageTerm}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        setIsDirty({ ...isDirty, ...{ financials: dirty } });
                        setFormVariables({ mortgageTerm: values.mortgageTerm });
                      }}
                      placeholder="Select Option"
                    >
                      {mortgageTermOptions.map((o) => (
                        <option key={o.id} value={o.id} defaultValue={values.mortgageTerm}>
                          {o.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </HStack>

                <HStack alignItems="flex-end" mt="24px" mb="16px" spacing="36px">
                  {/* Current Principle Balance */}
                  <FormControl w={property ? 'calc(50% - 18px)' : '50%'}>
                    <FormLabel {...formLabelStyles.xs}>Current Principal Balance</FormLabel>
                    <AmountInput
                      type="text"
                      {...formInputStyles}
                      id="mortgagePrincipleBalance"
                      name="mortgagePrincipleBalance"
                      allowdecimal="true"
                      value={getMortgagePrincipalBalanceValue(values.mortgagePrincipleBalance)}
                      onBlur={(e) => {
                        handleBlur(e);
                        setIsDirty({ ...isDirty, ...{ financials: dirty } });
                        setFormVariables({
                          mortgagePrincipleBalance: values.mortgagePrincipleBalance,
                        });
                      }}
                      readOnly={!!property}
                    />
                  </FormControl>

                  {/* Add Property ? Mortgage Interest Rate : Mortgage Details Button */}
                  {property ? (
                    <Box display="flex" justifyContent="center" h="48px">
                      {!mortgageBalance?.length ? (
                        <BaselaneButton
                          variant="outline"
                          palette="neutral"
                          size="lg"
                          onClick={() => {
                            setHideFooter(true);
                            setShowMortgage(true);
                          }}
                        >
                          Set Up
                        </BaselaneButton>
                      ) : (
                        <HStack>
                          <BaselaneButton
                            variant="outline"
                            palette="neutral"
                            size="lg"
                            leftIcon={<IconEdit />}
                            onClick={() => {
                              setHideFooter(true);
                              setShowMortgage(true);
                            }}
                          >
                            Edit
                          </BaselaneButton>
                          <Text color="brand.neutral.500" ml="10px" textStyle="headline-xs">
                            Latest Value: {lastEditedMortgageBalanceDate}
                          </Text>
                        </HStack>
                      )}
                    </Box>
                  ) : (
                    <FormControl w="50%">
                      <FormLabel {...formLabelStyles.xs}>Mortgage Interest Rate</FormLabel>
                      <Input
                        {...formInputStyles}
                        placeholder="0.000%"
                        as={MaskedInput}
                        mask={percentageMask({ decimalLimit: 3 })}
                        id="mortgageInterestRate"
                        name="mortgageInterestRate"
                        value={values.mortgageInterestRate}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          setIsDirty({ ...isDirty, ...{ financials: dirty } });
                          setFormVariables({ mortgageInterestRate: values.mortgageInterestRate });
                        }}
                      />
                    </FormControl>
                  )}
                </HStack>
              </Box>

              {/* Market Value */}
              <Box my="48px !important">
                <HStack {...dividerTitleStyles}>
                  <Text {...formSectionTitleStyles}>Market Value</Text>
                  <BaselaneDivider styles={{ m: 0 }} />
                </HStack>

                <HStack alignItems="flex-end" mb="20px" spacing="36px">
                  {/* Current Market Value */}
                  <FormControl w="calc(50% - 18px)">
                    <FormLabel {...formLabelStyles.xs}>Current Market Value</FormLabel>
                    <AmountInput
                      type="text"
                      id="currentMarketValue"
                      {...formInputStyles}
                      name="currentMarketValue"
                      allowdecimal="true"
                      value={latestMarketPrice ? latestMarketPrice?.price : ''}
                      readOnly
                    />
                  </FormControl>

                  {/* Market Value Details Button */}
                  <Box display="flex" justifyContent="center" h="48px">
                    {!marketPrice?.length ? (
                      <BaselaneButton
                        variant="outline"
                        palette="neutral"
                        size="lg"
                        onClick={() => {
                          setHideFooter(true);
                          setShowZillow(true);
                        }}
                      >
                        Set Up
                      </BaselaneButton>
                    ) : (
                      <HStack>
                        <BaselaneButton
                          variant="outline"
                          palette="neutral"
                          size="lg"
                          leftIcon={<IconEdit />}
                          onClick={() => {
                            setHideFooter(true);
                            setShowZillow(true);
                          }}
                        >
                          Edit
                        </BaselaneButton>
                        <Text color="brand.neutral.500" ml="10px" textStyle="headline-xs">
                          Latest Value: {lastEditedMarketPriceDate}
                        </Text>
                      </HStack>
                    )}
                  </Box>
                </HStack>
              </Box>
            </Stack>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default Financials;
