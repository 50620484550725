import React from 'react';
import { HStack, Text, Stack, Box } from '@chakra-ui/react';
import { Icon16Allcategories, Icon16Subcategory } from '@icons/16px';
import { iconmapping } from '@core/components/Transactions/DisplayInputVariations/iconmapping';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { truncatedText } from '@shared/styles/text.style';
import {
  categoryAndSubCategoryContainerStyles,
  contentContainerStyles,
  selectedItemsContentContainerStyles,
} from '@core/components/Transactions/DisplayInputVariations/styles/categoryInput.styles';
import Placeholder from '../../../Shared/components/BaselaneDropdown-new/DisplayInputVariations/Placeholders/Placeholder';

type DisplayInputDefaultCategoryContentProps = {
  selectedOptions: Array<Object>,
  placeholder: string,
  title: string,
  categoryMap: Array<Object>,
};

const DisplayInputDefaultCategoryContent = ({
  selectedOptions,
  placeholder,
  title,
  categoryMap,
  ...rest
}: DisplayInputDefaultCategoryContentProps) => {
  const { isMin899 } = useBreakPoints();

  // grab proper ids to show category and sub category separately
  const [categoryId = null, subCategoryId = null] = selectedOptions?.[0]?.id?.split('-') || [];
  const category = categoryId ? categoryMap[categoryId] : null;
  const subCategory = subCategoryId ? categoryMap[subCategoryId] : null;
  const strippedCategory = category
    ? category
        ?.replace(/ /g, '')
        .replace(/\/|-|&/g, '')
        .toLowerCase()
    : null;

  // grab icon associated to the parent category
  const CategoryIcon = strippedCategory ? iconmapping[strippedCategory] : strippedCategory;
  return (
    <HStack {...contentContainerStyles}>
      {selectedOptions?.length === 0 || !selectedOptions ? (
        <Placeholder
          {...{
            placeholder,
            title,
            color: 'brand.neutral.500',
            _hover: { color: 'brand.neutral.500' },
            textStyle: 'sm',
            ...rest,
          }}
        >
          <Box>
            <Icon16Allcategories />
          </Box>
        </Placeholder>
      ) : (
        <HStack {...selectedItemsContentContainerStyles} alignItems="center">
          {CategoryIcon && (
            <Box color="brand.neutral.500">
              <CategoryIcon />
            </Box>
          )}
          <Stack
            direction={!isMin899 ? 'row' : 'column'}
            {...categoryAndSubCategoryContainerStyles}
            {...truncatedText}
            alignItems="center"
            spacing={!isMin899 ? 1 : 0}
          >
            <Text w="100%" {...(!isMin899 ? {} : truncatedText)}>
              {category}
            </Text>
            {!isMin899 && subCategoryId && <Text>•</Text>}
            {subCategoryId && (
              <HStack className="subcategory" w="100%" overflow="hidden">
                {isMin899 && (
                  <Box color="brand.neutral.500">
                    <Icon16Subcategory />
                  </Box>
                )}

                <Text {...truncatedText}>{subCategory}</Text>
              </HStack>
            )}
          </Stack>
        </HStack>
      )}
    </HStack>
  );
};

export default DisplayInputDefaultCategoryContent;
