import React, { useRef, useEffect, useState, useContext } from 'react';
import { Text } from '@chakra-ui/react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';
import { DateTime } from 'luxon';
import { sortWithString } from '@core/utils/sort';
import { leftHeaderContentStyles } from '@pages/UnifiedRentCollection/UnifiedLeaseUI/styles/unifiedLease.styles';
import stripCurrency from '@core/utils/stripCurrency';
import { LEASE_AGREEMENT } from '@core/constants/routes';
import UserContext from '@contexts/UserContext';
import { GET_PROPERTIES } from '@core/apollo/queries';
import { GET_TENANT_ACTIVE_PROFILES } from '@pages/TenantsPage/queries/index';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import Header from '../components/Header';
import { initialFormValues, handleTenantStepValidation } from './helpers/leaseAgreementForm';
import LeaseAgreementFormTabs from './components/LeaseAgreementFormTabs';
import { CREATE_OR_UPDATE_LEASE_AGREEMENT } from './queries';

import {
  useSetLeaseAgreementId,
  useLeaseAgreementId,
  useSetIsLeaseTermStepValid,
  useSetIsTenantStepValid,
  useIsNewTenant,
  useSetIsNewTenant,
  useSelectedPropertyObject,
  useSetLeaseAgreementState,
  useSetLeaseAgreementPaymentStatus,
  useSetCompletedTags,
  useIsTenantPanelVisible,
  useExistingTenantsEmails,
  useHostedPageId,
  resetStore,
  useSetIsPropertyStepValid,
  useSetSelectedPropertyObject,
  useSetIsUnitStepValid,
  useSetShowServerTenantMessage,
} from './leaseAgreementStore';
import { useLeaseAgreement } from '../hooks/useLeaseAgreement';

const CreateLeaseAgreement = () => {
  const { user } = useContext(UserContext);
  const { isMax768: isMobile } = useBreakPoints();
  const setCompletedTags = useSetCompletedTags();
  const setIsPropertyStepValid = useSetIsPropertyStepValid();
  const [saveLeaseAgreement] = useMutation(CREATE_OR_UPDATE_LEASE_AGREEMENT);
  const leaseAgreementId = useLeaseAgreementId();
  const setLeaseAgreementId = useSetLeaseAgreementId();
  const setShowServerTenantMessage = useSetShowServerTenantMessage();
  const navigate = useNavigate();
  const isNewTenant = useIsNewTenant();
  const setIsNewTenant = useSetIsNewTenant();
  const setIsLeaseTermStepValid = useSetIsLeaseTermStepValid();
  const setIsTenantStepValid = useSetIsTenantStepValid();
  const selectedPropertyObject = useSelectedPropertyObject();
  const setLeaseAgreementState = useSetLeaseAgreementState();
  const setLeaseAgreementPaymentStatus = useSetLeaseAgreementPaymentStatus();
  const isTenantPanelVisible = useIsTenantPanelVisible();
  const existingTenantsEmails = useExistingTenantsEmails();
  const setSelectedPropertyObject = useSetSelectedPropertyObject();
  const setIsUnitStepValid = useSetIsUnitStepValid();
  const [searchParams] = useSearchParams();
  const draftId = searchParams.get('draftId');
  const [isEdit, setIsEdit] = useState(draftId);
  const hostedPageId = useHostedPageId();
  const [sortedProperties, setSortedProperties] = useState([]);
  // Query for landlord's properties
  const { error: propertiesError, loading: propertiesLoading, data: properties } = useQuery(
    GET_PROPERTIES
  );
  // Query for tenant data
  const { refetch: refetchTenantData } = useQuery(GET_TENANT_ACTIVE_PROFILES);
  useEffect(() => {
    setSortedProperties(sortWithString(properties?.property || [], 'name'));
  }, [properties, propertiesLoading]);
  const { leaseAgreement, loading, refetch } = useLeaseAgreement(
    leaseAgreementId || draftId || null
  );
  const handleFinishLaterEvent = () => {
    navigate(`/${LEASE_AGREEMENT}`);
    if (isEdit) {
      // refetch the lease agreement
      refetch();
    }
  };

  const resumeDraftFormValues = () => {
    // Update the store with the lease agreement data
    setCompletedTags(leaseAgreement?.completedTags);
    setIsLeaseTermStepValid(leaseAgreement?.completedTags.includes('LEASE_TERM'));
    setIsTenantStepValid(leaseAgreement?.completedTags.includes('TENANT_TAB'));
    setIsPropertyStepValid(leaseAgreement?.propertyUnitId || leaseAgreement?.propertyUnitId);

    if (
      (leaseAgreement?.propertyId || leaseAgreement?.propertyUnitId) &&
      properties &&
      !propertiesError &&
      !propertiesLoading
    ) {
      const selectedProperty = sortedProperties.find(
        (property) =>
          property.id === formikRef?.current?.values?.propertyId ||
          property.units?.some((unit) => unit.id === formikRef?.current?.values?.propertyUnitId)
      );
      setSelectedPropertyObject(selectedProperty);
      setIsUnitStepValid(
        selectedPropertyObject?.units?.length > 1 && leaseAgreement?.propertyUnitId
      );
    }
    return {
      id: leaseAgreement?.id,
      propertyId: leaseAgreement?.propertyId,
      propertyUnitId: leaseAgreement?.propertyUnitId,
      tenantProfileId: leaseAgreement?.tenantProfileId,
      isMonthToMonth: leaseAgreement?.leaseType === 'MONTHLY',
      leaseType: leaseAgreement?.leaseType,
      startDate: leaseAgreement?.startDate ? new Date(leaseAgreement?.startDate) : '',
      endDate: leaseAgreement?.endDate ? new Date(leaseAgreement?.endDate) : '',
      dueDays: leaseAgreement?.dueDays,
      completedTags: leaseAgreement?.completedTags,
      archived: leaseAgreement?.archived,
      monthlyRentAmount: leaseAgreement?.amount,
      rentCollectionStartDate: leaseAgreement?.rentCollectionStartDate,
      inputType: 'STANDARD',
      tenantProfileMetadata: {
        phoneNumber: '',
      },
    };
  };
  function handleSaveLeaseAgreement() {
    const selectedProperty = sortedProperties.find(
      (property) =>
        property.id === formikRef?.current?.values?.propertyId ||
        property.units?.some((unit) => unit.id === formikRef?.current?.values?.propertyUnitId)
    );
    setSelectedPropertyObject(selectedProperty);
    setIsEdit(false);
    const startDate = DateTime.fromISO(
      DateTime.fromJSDate(formikRef?.current?.values?.startDate).toISODate()
    ).toFormat('yyyy-MM-dd');
    const endDate = formikRef?.current?.values?.endDate
      ? DateTime.fromISO(
          DateTime.fromJSDate(formikRef?.current?.values?.endDate).toISODate()
        ).toFormat('yyyy-MM-dd')
      : null;
    saveLeaseAgreement({
      variables: {
        id: leaseAgreementId || formikRef?.current?.values?.id || null,
        inputType: 'STANDARD',
        propertyUnitId:
          selectedProperty?.units?.length === 1
            ? selectedProperty?.units[0]?.id
            : formikRef?.current?.values?.propertyUnitId,
        propertyId: formikRef?.current?.values?.propertyId,
        tenantProfileId: !isNewTenant ? formikRef?.current?.values?.tenantProfileId : null,
        leaseType: formikRef?.current?.values?.isMonthToMonth ? 'MONTHLY' : 'FIXED',
        startDate,
        endDate,
        amount: stripCurrency(formikRef?.current?.values?.monthlyRentAmount),
        rentCollectionStartDate:
          formikRef?.current?.values?.rentCollectionStartDate ||
          formikRef?.current?.values?.startDate ||
          null,
        hostedPageId,
        ...(isNewTenant && {
          tenantProfileMetadata: formikRef?.current?.values?.tenantProfileMetadata,
        }),
      },
    })
      .then((res) => {
        formikRef?.current?.setStatus({ success: res.data.createOrUpdateLeaseAgreement });
        setLeaseAgreementId(res.data.createOrUpdateLeaseAgreement.id);
        setCompletedTags(res.data.createOrUpdateLeaseAgreement.completedTags);

        if (res.data.createOrUpdateLeaseAgreement.completedTags.includes('LEASE_TERM')) {
          setIsLeaseTermStepValid(true);
        }
        if (res.data.createOrUpdateLeaseAgreement.tenantProfileId) {
          // refetch the tenant data
          refetchTenantData();
          formikRef?.current?.setFieldValue(
            'tenantProfileId',
            res.data.createOrUpdateLeaseAgreement.tenantProfileId
          );
          setIsNewTenant(false);
        }
        if (res.data.createOrUpdateLeaseAgreement.completedTags.includes('TENANT_TAB')) {
          setIsTenantStepValid(true);
        }
        setLeaseAgreementState(res.data.createOrUpdateLeaseAgreement.state);
        setLeaseAgreementPaymentStatus(res.data.createOrUpdateLeaseAgreement.paymentStatus);
        // refetch the lease agreement after saving
        refetch();
      })
      .catch((error) => {
        if (
          error.message.includes('Tenant already exists') ||
          error.message.includes('Landlord account')
        ) {
          setShowServerTenantMessage(true);
        }
      });
  }
  function handleValidation(values) {
    let errors = {};
    if (isTenantPanelVisible && isNewTenant) {
      errors = handleTenantStepValidation(values, existingTenantsEmails, user?.email);
    }
    // if (isLeaseTermPanelVisible) {
    //   errors = handleLeaseTermValidation(values);
    // }

    return errors;
  }

  const formikRef = useRef(null);
  const [initialValues, setInitialValues] = useState(
    isEdit ? resumeDraftFormValues(leaseAgreement) : initialFormValues
  );

  // Check if is resume draft
  useEffect(() => {
    if (draftId) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [draftId]);

  useEffect(() => {
    if (draftId) {
      setInitialValues(resumeDraftFormValues(leaseAgreement));
    }
  }, [leaseAgreementId, draftId, loading, leaseAgreement, isEdit]);

  // zendesk API access
  const zendeskAPI = useZendeskAPI();
  useEffect(() => {
    if (isMobile) {
      zendeskAPI('messenger', 'hide');
    }
  }, [isMobile]);

  useEffect(() => {
    resetStore();
    formikRef.current.setErrors({});
    formikRef.current.setTouched({});
    setInitialValues(isEdit ? resumeDraftFormValues() : initialFormValues);
  }, []);

  return (
    <Header
      hideBackButton
      handleFinishEvent={handleFinishLaterEvent}
      headerContent={<Text {...leftHeaderContentStyles}>Create lease agreement</Text>}
    >
      {initialValues && (
        <Formik
          key={JSON.stringify(initialValues)}
          enableReinitialize
          innerRef={formikRef}
          validateOnChange
          validateOnBlur
          validateOnMount={false}
          initialValues={initialValues}
          validate={handleValidation}
          onSubmit={handleSaveLeaseAgreement}
        >
          {() => {
            return <LeaseAgreementFormTabs />;
          }}
        </Formik>
      )}
    </Header>
  );
};

export default CreateLeaseAgreement;
