import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react';

import { LOGIN } from '@routes';
import { BaselaneAlertNew, BaselaneButton, BaselaneCardNew } from '@shared/components';
import { Icon16ChevronLeft, Icon16Mail } from '@icons/16px';
import { Icon24Home, Icon24Key } from '@icons/24px';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { pageWrapperStyles, cardStyles } from '@pages/SignUpPage/styles/emailValidation.styles';
import DeferredSignupPageHeader from './DeferredSignupPageHeader';

const EmailValidation = () => {
  const location = useLocation();
  const { state } = location || {};
  const { email } = state;

  const { isMax576, isMin768 } = useBreakPoints();
  const navigate = useNavigate();

  const goBackToMarketing = () => {
    window.location.href = 'https://www.baselane.com/';
  };
  const goToTenantPortal = () => {
    window.location.href = 'https://tenant.baselane.com/login';
  };
  const goToLandlordSignIn = () => {
    navigate(LOGIN);
  };

  return (
    <Stack>
      <DeferredSignupPageHeader />
      <Box {...pageWrapperStyles({ isMax576, isMin768 })}>
        <Box w="100%" maxW="480px">
          <BaselaneButton
            variant="transparent"
            palette="neutral"
            size="lg"
            leftIcon={<Icon16ChevronLeft />}
            onClick={() => goBackToMarketing()}
          >
            Back
          </BaselaneButton>
          <Box py={4}>
            <BaselaneAlertNew
              variant="warning"
              title="Email already registered"
              body={`Looks like you already have an account with the email address ${email}`}
              visual="icon"
              iconName={Icon16Mail}
            />
          </Box>

          <Heading size="headline-md" mb={1.5}>
            Sign in to your account
          </Heading>
          <Flex direction={isMax576 ? 'column' : 'row'} gap={1.5}>
            <BaselaneCardNew
              {...cardStyles}
              size="lg"
              variant="clickable"
              palette="light"
              onClick={() => goToLandlordSignIn()}
            >
              <Icon24Home />
              <Text textStyle="headline-md">Landlord sign-in</Text>
            </BaselaneCardNew>
            <BaselaneCardNew
              {...cardStyles}
              size="lg"
              variant="clickable"
              palette="light"
              onClick={() => goToTenantPortal()}
            >
              <Icon24Key />
              <Text textStyle="headline-md">Renter sign-in</Text>
            </BaselaneCardNew>
          </Flex>
        </Box>
      </Box>
    </Stack>
  );
};

export default EmailValidation;
