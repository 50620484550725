import React from 'react';
import { Heading, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { BaselaneButton } from '@shared/components';
import { LEASES } from '@routes';
import getPluralizedNoun from '@core/utils/getPluralizedNoun';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { getTotalNumberOfUnits } from '../helper';

type HeaderInfoProps = {
  isLeasesSummaryLoading: boolean,
  leaseSummaryData: Object,
};

const HeaderInfo = ({ isLeasesSummaryLoading, leaseSummaryData }: HeaderInfoProps) => {
  const navigate = useNavigate();

  const { isMin768 } = useBreakPoints();

  const { totalCount = 0, leases = [] } = leaseSummaryData?.leases ?? {};
  const totalNumberOfUnits = getTotalNumberOfUnits(leases);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
      <Stack spacing={0}>
        <Heading size="headline-xl">Rent collection</Heading>
        <Skeleton isLoaded={!isLeasesSummaryLoading}>
          <Text textStyle="sm">
            {getPluralizedNoun(totalCount, 'rent collection')} &#x2022;{' '}
            {getPluralizedNoun(totalNumberOfUnits, 'unit')}
          </Text>
        </Skeleton>
      </Stack>
      <BaselaneButton
        variant="link"
        palette="primary"
        size={isMin768 ? 'lg' : 'md'}
        styles={{ textDecoration: 'none', fontWeight: 'medium', margin: 0 }}
        onClick={() => {
          sendSegmentEvent('temp_view_all_rc');
          navigate(LEASES);
        }}
      >
        View all
      </BaselaneButton>
    </Stack>
  );
};

export default HeaderInfo;
