import { truncatedText } from '@core/components/Shared/styles/text.style';

export const shareLinkStyles = {
  container: {
    background: 'brand.neutral.white',
    color: 'brand.neutral.900',
    textStyle: 'sm',
    borderRadius: '6px',
    borderWidth: '1px',
    borderColor: 'brand.darkBlue.200',
    p: 0.5,
    w: '100%',
  },
  innerContainer: {
    justifyContent: 'space-between',
  },
  text: {
    pl: 1.5,
    pr: 1,
    ...truncatedText,
  },
};
