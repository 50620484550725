import { useLazyQuery } from '@apollo/client';
import { NON_SENSITIVE_TOKEN, USER_SENSITIVE_TOKEN, USER_VERIFICATION } from '@core/apollo/queries';
import unitTokenTimeStorage from '../../../storage/unitTokenTime';

const bufferTimeSec1Hour = 3600;

const getTodaysDateInSec = () => {
  const todayDate = new Date();
  return todayDate.getTime() / 1000;
};

const getTokenData = (bankId) => {
  const unitData = unitTokenTimeStorage.read(bankId);
  return unitData === '' ? unitData : JSON.parse(unitData);
};

const getExpiredTime = (tokenExpiresIn) =>
  getTodaysDateInSec() + Number(tokenExpiresIn) - bufferTimeSec1Hour;

const getExpiredUnitTokenTime = (bankId) => {
  const { sensitive } = getTokenData(bankId);
  return sensitive?.time || '';
};

const getNonSensitiveExpiredUnitTokenTime = (bankId) => {
  const { nonSensitive } = getTokenData(bankId);
  return nonSensitive?.time || '';
};

const getIsTokenValid = (unitSensitiveToken) => {
  const { expiresIn } = unitSensitiveToken ?? {};
  const expiredTime = getExpiredTime(expiresIn);
  return getTodaysDateInSec() < expiredTime;
};

const getIsTokenExpired = (bankId, isNonSensitive = false) => {
  const expiredUnitTokenTime = isNonSensitive
    ? getNonSensitiveExpiredUnitTokenTime(bankId)
    : getExpiredUnitTokenTime(bankId);
  const isTokenFresh = expiredUnitTokenTime !== '' && getTodaysDateInSec() < expiredUnitTokenTime;

  return !isTokenFresh;
};

const setTokenData = (unitToken, bankId, isSensitive = true) => {
  const { expiresIn, token } = unitToken ?? {};
  const { sensitive, nonSensitive } = getTokenData(bankId);
  const expiredTime = getExpiredTime(expiresIn);

  const sensitiveTokenObj = {
    sensitive: {
      time: expiredTime,
      token,
    },
    nonSensitive,
  };

  const nonSensitiveTokenObj = {
    nonSensitive: {
      time: expiredTime,
      token,
    },
    sensitive,
  };

  const tokenObj = isSensitive ? sensitiveTokenObj : nonSensitiveTokenObj;
  unitTokenTimeStorage.write(JSON.stringify(tokenObj), bankId);
};

const onGetUnitOTPVerificationError = (setOTPErrorCode) => setOTPErrorCode(200);

const handleOTPPopupOpen = (getOTP, bankId, stateFunctions) => {
  const {
    onOTPPopupOpen,
    setOTPErrorCode,
    setAPIVerification,
    setIsGetOTPLoading,
  } = stateFunctions;

  setIsGetOTPLoading(true);
  return getOTP({ variables: { bankId: Number(bankId) } })
    .catch(() => {
      onGetUnitOTPVerificationError(setOTPErrorCode);
    })
    .then((response) => {
      setAPIVerification(response.data.unitAPIVerification);
      onOTPPopupOpen();
    })
    .finally(() => {
      setIsGetOTPLoading(false);
    });
};

const onUserSensitiveTokenError = (setOTPErrorCode) => {
  setOTPErrorCode(100);
};

const getSensitiveToken = (props = {}) => {
  const { onUserSensitiveTokenComplete, stateFunctions } = props;
  const { setOTPErrorCode } = stateFunctions;
  return useLazyQuery(USER_SENSITIVE_TOKEN, {
    fetchPolicy: 'network-only',
    errorPolicy: 'none',
    onCompleted: (unitAPISensitiveTokenResponse) => {
      if (unitAPISensitiveTokenResponse) {
        onUserSensitiveTokenComplete(unitAPISensitiveTokenResponse.unitAPISensitiveToken);
      } else {
        onUserSensitiveTokenError(setOTPErrorCode);
      }
    },
    onError: () => {
      return onUserSensitiveTokenError(setOTPErrorCode);
    },
  });
};

const getNonSensitiveToken = (onNonSensitiveTokenComplete, onNonSensitiveTokenError) =>
  useLazyQuery(NON_SENSITIVE_TOKEN, {
    fetchPolicy: 'network-only',
    errorPolicy: 'none',
    onCompleted: (unitAPINonSensitiveTokenResponse) => {
      if (unitAPINonSensitiveTokenResponse) {
        onNonSensitiveTokenComplete(unitAPINonSensitiveTokenResponse.unitAPINonSensitiveToken);
      } else {
        onNonSensitiveTokenError(unitAPINonSensitiveTokenResponse);
      }
    },
    onError: onNonSensitiveTokenError,
  });

const getUnitOTPVerification = (setOTPErrorCode) =>
  useLazyQuery(USER_VERIFICATION, {
    fetchPolicy: 'network-only',
    errorPolicy: 'none',
    onCompleted: (unitAPIVerificationResponse) => {
      if (!unitAPIVerificationResponse) {
        onGetUnitOTPVerificationError(setOTPErrorCode);
      }
    },
    onError: (errors) => {
      console.error(errors);
      onGetUnitOTPVerificationError(setOTPErrorCode);
    },
  });

const handleOTPPopupClose = (states, stateFunctions) => {
  const { isOTPPopupOpen } = states;
  const { onOTPPopupClose } = stateFunctions;

  if (isOTPPopupOpen) {
    onOTPPopupClose();
  }
};

const handleNBOnVerifyClick = (otpCode, bankId, getUserSensitiveTokenData) => {
  const variables = { otpCode, bankId: Number(bankId) };
  getUserSensitiveTokenData({ variables });
};

export {
  getSensitiveToken,
  getNonSensitiveToken,
  getTokenData,
  setTokenData,
  getIsTokenExpired,
  getIsTokenValid,
  getUnitOTPVerification,
  getExpiredUnitTokenTime,
  handleOTPPopupOpen,
  handleOTPPopupClose,
  handleNBOnVerifyClick,
};
