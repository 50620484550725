import React from 'react';
import { Box, HStack, Spacer, Stack, Text, useMediaQuery } from '@chakra-ui/react';
import IconX from '@icons/manual/IconX';
import BaselaneButton from '../BaselaneButton';
import { BaselaneButtonIcon } from '../BaselaneButtonIcon';
import {
  filterCardContainerStyles,
  overlayStyles,
  popupStyles,
} from './styles/baselaneFilterCard.styles';

type BaselaneFilterCardProps = {
  showFilterPopup: boolean,
  showFilterPopupAnimation: Object,
  headerText: String,
  handleFilterPopupClose: Function,
  animationStyles: Object,
  customStyles: Object,
  handleSubmit: Function,
  isSubmitting: boolean,
  isSubmitDisabled: boolean,
  showFooter: boolean,
  showHeader: boolean,
  children: any,
  enableOnOverlayClose: boolean,
};

function BaselaneFilterCard({
  showFilterPopup,
  showFilterPopupAnimation,
  headerText = '',
  handleFilterPopupClose,
  animationStyles,
  customStyles = {},
  handleSubmit = () => {},
  isSubmitting = false,
  isSubmitDisabled = false,
  showFooter = true,
  showHeader = true,
  children,
  enableOnOverlayClose = true,
}: BaselaneFilterCardProps): any {
  // override isMinXL since storybook does not understand useLocation check in useBreakPoints func
  const [isMin899] = useMediaQuery('(min-width: 899px)', { ssr: false });

  const { slidein, slideout } = showFilterPopupAnimation;

  const { container, innercontainer, header, footer } = popupStyles({
    isMinXL: !isMin899,
    showFilterPopup,
  });

  const {
    filterCardContainer = {},
    overlay = {},
    popupContainer = {},
    popupInnerContainer = {},
    popupHeader = {},
    popupFooter = {},
  } = customStyles;

  return (
    <Box {...{ ...filterCardContainerStyles, ...filterCardContainer }}>
      {/* Drawer Overlay */}
      {showFilterPopup && (
        <Box
          onClick={() => (enableOnOverlayClose ? handleFilterPopupClose() : {})}
          {...{ ...overlayStyles(true), ...overlay }}
        />
      )}

      {/* Popup */}
      <Box {...{ ...container({ slidein, slideout, animationStyles }), ...popupContainer }}>
        <Stack {...{ ...innercontainer, ...popupInnerContainer }}>
          {/* Header */}
          {showHeader && (
            <HStack {...{ ...header.container, ...popupHeader }}>
              <BaselaneButtonIcon
                onClick={() => handleFilterPopupClose()}
                icon={<IconX />}
                variant="outline"
                palette="neutral"
              />
              <Text {...header.text}>{headerText}</Text>
            </HStack>
          )}

          {/* Filter Contents */}
          {children}
        </Stack>

        <Spacer />

        {/* Footer */}
        {showFooter && (
          <HStack {...{ ...footer.container, ...popupFooter }}>
            <BaselaneButton
              variant="filled"
              palette="primary"
              size="md"
              onClick={() => handleSubmit()}
              isLoading={isSubmitting}
              isDisabled={isSubmitDisabled}
            >
              Apply Filters
            </BaselaneButton>
          </HStack>
        )}
      </Box>
    </Box>
  );
}

export default BaselaneFilterCard;
