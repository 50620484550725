// @flow
import React from 'react';
import { Text } from '@chakra-ui/react';
import { BaselaneAlert, AlertHeader } from '@shared/components';
import IconCheckCircleOutline from '@icons/manual/IconCheckCircleOutline';
import '@shared/components/BaselaneErrorCard/styles/errorcard.style.scss';
import {
  badgeIconBgSuccessStyles,
  bodyTextStyles,
  badgeIconSuccessContainerStyles,
} from './styles/successAlert.styles';

type SuccessAlertProps = {
  alertCancelRef: any,
  alertIsOpen: Boolean,
  handleAlertOnClose: Function,
};

const SuccessAlert = ({ alertCancelRef, alertIsOpen, handleAlertOnClose }: SuccessAlertProps) => {
  const alertHeader = (
    <AlertHeader
      title="We just sent you a verification email"
      icon={<IconCheckCircleOutline />}
      iconBgStyles={badgeIconBgSuccessStyles}
      iconContainerStyles={badgeIconSuccessContainerStyles}
    />
  );

  const alertUpdateSuccessBody = (
    <Text {...bodyTextStyles}>
      Please check your inbox, and click on the link in the email to verify your account.
    </Text>
  );

  return (
    <BaselaneAlert
      isOpen={alertIsOpen}
      leastDestructiveRef={alertCancelRef}
      onClose={handleAlertOnClose}
      isCentered
      showCloseButton
      size="2xl"
      header={alertHeader}
      body={alertUpdateSuccessBody}
      footer={null}
      headerStyles={{ lineHeight: '32px' }}
      footerStyles={{ px: '32px', pb: '32px' }}
    />
  );
};

export default SuccessAlert;
