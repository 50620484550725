import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';
import { HStack } from '@chakra-ui/react';
import { SIGN_UP_AS_RENTER } from '@routes';
import { BaselaneMenu } from '@shared/components';
import useOnboardingStore from '@core/store/Onboarding';
import { Icon16Chatbot, Icon16Logout, Icon16Support } from '@core/components/Icons/16px';
import useBreakPoints from '@core/hooks/useBreakPoints';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';
import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import { FEATURE_GATES } from '@core/constants/statsigKeys';

type DeferredSignupPageHeaderProps = {
  children: ReactNode,
};

const DeferredSignupPageHeader = ({ children }: DeferredSignupPageHeaderProps) => {
  const { checkGate } = useStatsigClient();
  const { isMin768 } = useBreakPoints();
  const { email, step, lastStep } = useOnboardingStore();
  const { pathname } = useLocation();

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  const hideMenu = !email || step === lastStep || pathname.includes(SIGN_UP_AS_RENTER);

  const exit = () => {
    window.location.href = 'https://www.baselane.com/';
  };

  return (
    <HStack
      w="full"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid"
      borderBottomColor="brand.darkBlue.150"
      py={2}
      px={isMin768 ? 4 : 2}
      h="72px"
    >
      <BaselaneFullLogo />
      {!hideMenu && (
        <BaselaneMenu
          listItems={[
            {
              id: 'support',
              name: 'Support',
              as: 'a',
              href: 'https://support.baselane.com/hc/en-us',
              target: '_blank',
              icon: <Icon16Support />,
            },
            {
              id: 'support-messenger',
              name: 'Virtual assistant',
              onClick: () => {
                if (checkGate(FEATURE_GATES.HIDE_ZENDESK_BUBBLE)) {
                  window.adaEmbed.toggle();
                } else {
                  zendeskAPI('messenger', 'open');
                }
              },
              icon: <Icon16Chatbot />,
              hasDivider: true,
            },
            {
              name: 'Exit',
              onClick: exit,
              icon: <Icon16Logout />,
            },
          ]}
          label={email}
          profilePhotoUrl={null}
          isProfile
        />
      )}
      {children}
    </HStack>
  );
};

export default DeferredSignupPageHeader;
