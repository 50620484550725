import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import Logrocket from 'logrocket';
import { BaselaneButton } from '@core/components/Shared/components';
import { IconCheck, IconChevronLeft, IconChevronRight } from '@core/components/Icons';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { useUnitOtp } from '@core/contexts/UnitOtpContext';
import useTransfersAccountList from '../../../../../hooks/useTransfersAccountList';

type CheckDepositDrawerFooterProps = {
  steps: Record<string, number>,
  currentStep: number,
  isLoading: boolean,
  onNextClicked: (step: number) => void,
  isDisabled: boolean,
};

const CheckDepositDrawerFooter = ({
  steps,
  currentStep,
  isLoading,
  onNextClicked,
  isDisabled = false,
}: CheckDepositDrawerFooterProps) => {
  const navigate = useNavigate();
  const { values, submitForm } = useFormikContext();
  const { transferAccountsByBankAccountId } = useTransfersAccountList();

  const bankId = transferAccountsByBankAccountId[values.accountId]?.group?.id;
  // Unit OTP
  const { verifyUnitOtp, ignore } = useUnitOtp();
  const [isDepositInProgress, setIsDepositInProgress] = useState(false);

  const closeDrawer = () => {
    navigate(-1);
  };

  const handleDeposit = async () => {
    setIsDepositInProgress(true);
    verifyUnitOtp(bankId)
      .then(async () => {
        try {
          await submitForm();
        } catch (error) {
          Logrocket.log('Error submitting form', error);
        } finally {
          setIsDepositInProgress(false);
        }
      })
      .catch(ignore);
  };

  // The component to render in the footer. For each step, the footer will be different.
  let renderFooter = null;

  switch (currentStep) {
    case steps.SIGN_AND_ENDORSE_CHECK:
      renderFooter = (
        <Box display="flex" gap={2} width="100%">
          <BaselaneButton variant="outline" palette="neutral" size="md" onClick={closeDrawer}>
            Cancel
          </BaselaneButton>
          <BaselaneButton
            isDisabled={!values.writeOnBackOfCheck || !values.signTheCheck}
            isFullWidth
            variant="filled"
            palette="primary"
            size="md"
            rightIcon={<IconChevronRight />}
            onClick={() => {
              sendSegmentEvent('baselane_banking_check_deposit_complete_endorse');
              onNextClicked(steps.SCAN_A_CHECK);
            }}
          >
            Next
          </BaselaneButton>
        </Box>
      );
      break;
    case steps.SCAN_A_CHECK:
      renderFooter = (
        <Box display="flex" gap={2} width="100%">
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size="md"
            onClick={() => onNextClicked(steps.SIGN_AND_ENDORSE_CHECK)}
          >
            Back
          </BaselaneButton>
          <BaselaneButton
            isDisabled={
              !values.frontOfCheck || !values.backOfCheck || !values.amount || !values.accountId
            }
            isFullWidth
            variant="filled"
            palette="primary"
            size="md"
            rightIcon={<IconChevronRight />}
            onClick={() => {
              onNextClicked(steps.BOOKKEEPING_DETAILS);
              sendSegmentEvent('baselane_banking_check_deposit_complete_step1');
            }}
          >
            Next
          </BaselaneButton>
        </Box>
      );
      break;
    case steps.BOOKKEEPING_DETAILS:
      renderFooter = (
        <Box display="flex" gap={2} width="100%">
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size="md"
            onClick={() => onNextClicked(steps.SCAN_A_CHECK)}
          >
            Back
          </BaselaneButton>
          <BaselaneButton
            isFullWidth
            variant="filled"
            palette="primary"
            size="md"
            rightIcon={<IconChevronRight />}
            onClick={() => {
              onNextClicked(steps.REVIEW_AND_DEPOSIT);
              sendSegmentEvent('baselane_banking_check_deposit_complete_step2');
            }}
          >
            Next
          </BaselaneButton>
        </Box>
      );
      break;
    case steps.REVIEW_AND_DEPOSIT:
      renderFooter = (
        <Box display="flex" gap={2} width="100%">
          <BaselaneButton variant="outline" palette="neutral" size="md" onClick={closeDrawer}>
            Cancel
          </BaselaneButton>
          <BaselaneButton
            type="submit"
            isDisabled={isDepositInProgress}
            isFullWidth
            variant="filled"
            palette="primary"
            size="md"
            leftIcon={<IconCheck />}
            isLoading={isLoading || isDepositInProgress}
            onClick={handleDeposit}
          >
            Deposit
          </BaselaneButton>
        </Box>
      );
      break;
    case steps.SUCCESS:
      renderFooter = (
        <Box display="flex" gap={2} width="100%">
          <BaselaneButton variant="outline" palette="neutral" size="md" onClick={closeDrawer}>
            Done
          </BaselaneButton>
          <BaselaneButton
            isFullWidth
            variant="tonal"
            palette="primary"
            size="md"
            onClick={() => onNextClicked(steps.SIGN_AND_ENDORSE_CHECK)}
          >
            Deposit Another Check
          </BaselaneButton>
        </Box>
      );
      break;
    default:
      renderFooter = (
        <BaselaneButton variant="outline" leftIcon={<IconChevronLeft />} onClick={closeDrawer}>
          Back
        </BaselaneButton>
      );
      break;
  }

  return renderFooter;
};

export default CheckDepositDrawerFooter;
