import React from 'react';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { BaselaneHeaderCard, BaselaneLink, BaselaneDivider } from '@shared/components';
import { Icon16Info } from '@icons/16px';
import LendencyAdvantages from './components/LendencyAdvantages';
import InstantQuoteButton from '../InstantQuoteButton';
import {
  LENDENCY_ADVANTAGES_PART1,
  LENDENCY_ADVANTAGES_PART2,
} from './helpers/mainContent.helpers';
import {
  customHeaderStyles,
  hideStyles,
  headerLeftStyles,
  containerStyles,
  contentContainerStyles,
  bottomContentContainerStyles,
  bottomInnerContentContainerStyles,
  bottomTextStyles,
  iconContainer,
} from './styles/mainContent.styles';

const MainContent = () => {
  const headerLeft = (
    <Text>Grow your rental business by funding your next rental property deal!</Text>
  );

  return (
    <BaselaneHeaderCard
      headerLeft={headerLeft}
      customHeaderStyles={customHeaderStyles}
      headerLeftStyles={headerLeftStyles}
      containerStyles={containerStyles}
      spacerStyles={hideStyles}
      headerRightStyles={hideStyles}
    >
      <Stack {...contentContainerStyles}>
        <LendencyAdvantages
          listData={LENDENCY_ADVANTAGES_PART1}
          listTitle="Tailored solutions for every real estate strategy"
        />
        <BaselaneDivider isVertical />
        <LendencyAdvantages
          listData={LENDENCY_ADVANTAGES_PART2}
          listTitle="Grow your rental business by funding your next rental property deal fast!"
        />
      </Stack>
      <BaselaneDivider isHorizontal styles={{ marginTop: '3px !important' }} />
      {/* Footer */}
      <Stack {...bottomContentContainerStyles}>
        <InstantQuoteButton btnVariant="filled" palette="primary" btnSize="lg" />
        <HStack {...bottomInnerContentContainerStyles}>
          <Box {...iconContainer}>
            <Icon16Info color="#6C7884" />
          </Box>
          <Text {...bottomTextStyles}>
            New Product Alert! Put only 5% down for a 2-4 unit owner-occupied property. Email us at
            &nbsp;
            <BaselaneLink size="lg" variant="primary" href="mailto:support@baselane.com">
              success@baselane.com
            </BaselaneLink>
            &nbsp; to learn more.
          </Text>
        </HStack>
      </Stack>
    </BaselaneHeaderCard>
  );
};

export default MainContent;
