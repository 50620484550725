import React, { useEffect, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text, Box, useDisclosure } from '@chakra-ui/react';
import LogRocket from 'logrocket';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import {
  AuthenticationError,
  emailAlreadyInUse,
  errorMessages,
  userDidNotExist,
} from '@features/Authentication/helpers/error.helpers';
import {
  firebaseGoogleSignIn,
  generateSessionCookie,
} from '@features/Authentication/helpers/firebase.helpers';
import IconSmallGoogleLogo from '@icons/manual/IconSmallGoogleLogo';
import { BaselaneButton } from '@shared/components';
import inviteCodeStorage from '@core/storage/inviteCodeStorage';
import { reactNodeEnv } from '@core/constants/envVars';
import { formErrorStyles } from '@shared/styles/input.style';
import { CREATE_USER, LANDLORD_LOGIN } from '@core/apollo/queries';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import * as ROUTES from '@routes';
import {
  clearUrlUtmData,
  deleteUtmCookies,
  getUtmInputs,
  trackUtmUser,
  identifyUser,
  saveSearch,
  cleanupRedirects,
} from '@features/Authentication/helpers/tracking.helpers';
import { CREATE_USER_RETRY_COUNT_DEFAULT } from '@features/Authentication/helpers/constant.helpers';
import { attemptToCreateUser } from '@features/Authentication/helpers/signup.helpers';
import useInvitationLink from '@pages/SignUpPage/useInvitationLink';
import useIpAddress from '@shared/hooks/useIpAddress';
import { useFirebaseOtp } from '@core/contexts/FirebaseOtpContext';
import InviteCodeAlert from './components/InviteCodeAlert';
import { buttonStyles, textStyles } from './googleButton.style';

type GoogleButtonProps = {
  isLogin: Boolean,
  text: string,
  isMobile: boolean,
  onUserCreated?: Function,
};

function GoogleButton({ text, isMobile, isLogin, onUserCreated = null }: GoogleButtonProps) {
  const { verifyFirebaseOtp } = useFirebaseOtp();

  const [createUser] = useMutation(CREATE_USER);
  const [isLoading, setIsLoading] = useState(false);
  const client = useApolloClient();

  const location = useLocation();
  const navigate = useNavigate();

  // email invite
  const inviteCode = inviteCodeStorage.read('inviteCode');

  const [errors, setErrors] = useState({});

  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  const { ipAddress } = useIpAddress();

  const [landlordLogin, { loading: isLandlordLoginLoading }] = useMutation(LANDLORD_LOGIN);

  // Fingerprint
  const { error: fingerprintError, getData: getDataFingerprint } = useVisitorData({
    extendedResult: true,
    tag: {
      env: reactNodeEnv,
    },
  });

  const { isCollaborator, collaborator } = useInvitationLink();

  const handleInvalidCollaboratorEmail = (credentialEmail) => {
    if (isCollaborator && collaborator.email !== credentialEmail) {
      navigate(ROUTES.LOGIN_ERROR);
    }
  };

  /**
   * Signs a user on the backend and handles any resulting errors
   * @param {UserCredential} credential The firebase UserCredential object recieved from firebase login
   * @param {string} requestId The fingerprint request identifier is unique for every request, this can be null.
   */
  const signUserIn = (credential, requestId) => {
    // sign in
    credential.user
      .getIdToken()
      .then((token) => {
        generateSessionCookie({
          token,
          requestId,
          handleSuccess: (sessionResponse) => {
            identifyUser(credential.user);

            const utmInputs = getUtmInputs(ipAddress, location, 'landlord_logged_in');
            // login to backend
            landlordLogin({
              variables: {
                requestId,
                utmInputs,
                inviteCode,
              },
            })
              .then((response) => {
                if (!response?.errors && response?.data?.landlordLogin?.id) {
                  // login success
                  // tracking event
                  trackUtmUser(credential.user, 'landlord_logged_in', ipAddress, utmInputs);
                  // delete utm data from storage, as it has been consumed
                  clearUrlUtmData();
                  // delete utm cookie data, as it has been consumed
                  deleteUtmCookies();

                  // Note: the following will reset the apollo client, and re-fetch the full user data,
                  client.resetStore();
                  navigate(ROUTES.HOME);
                } else if (response?.errors) {
                  if (
                    response?.errors[0].message?.includes(
                      'Invite code not associated with the email'
                    ) ||
                    response?.errors[0].message?.includes('has been REVOKED')
                  ) {
                    navigate('/expired-invitation');
                  }
                  if (userDidNotExist(response?.errors[0].message)) {
                    setErrors({
                      ...errors,
                      googleSSO: errorMessages[AuthenticationError.nosuchuser],
                    });
                  }
                  LogRocket.log(
                    `landlordLogin (Google SSO) error: ${JSON.stringify(
                      response?.errors
                    )}, requestId:${requestId}, email: ${credential?.user?.email}`
                  );
                }
              })
              .catch((error) => {
                // generic error
                LogRocket.log(
                  `landlordLogin (Google SSO) error: ${JSON.stringify(
                    error
                  )}, requestId:${requestId}, email: ${credential?.user?.email}`
                );
              })
              .finally(() => {
                setIsLoading(false);
              });
          },
          handleError: (error) => {
            // TODO: Login should not progress from here once SessionCookie generation works
            // but there is a legitimate error, this should show a generic error message for now
            navigate('something-went-wrong');
          },
        });
      })
      .then(() => {});
  };

  /**
   * Begins the sign up process for a user that was authenticated with firebase
   * @param {UserCredential} credential The firebase UserCredential object received from firebase login
   * @param {string} requestId The fingerprint request identifier is unique for every request, this can be null.
   */
  const signUserUp = (credential, requestId) => {
    handleInvalidCollaboratorEmail(credential.user.email);
    const utmInputs = getUtmInputs(ipAddress, location, 'account_created');
    attemptToCreateUser({
      credential,
      CREATE_USER_RETRY_COUNT_DEFAULT,
      requestId,
      utmInputs,
      location,
      inviteCode,
      createUser,
      setIsLoading,
      onSuccess: () => {
        // createUser success
        // tracking event
        if (!isCollaborator) {
          trackUtmUser(credential.user, 'account_created', ipAddress, utmInputs);
        }

        // reddit tracking
        window?.rdt('track', 'SignUp');
        // delete utm data from storage, as it has been consumed
        clearUrlUtmData();
        // delete utm cookie data, as it has been consumed
        deleteUtmCookies();
        // store search parameters in local storage
        saveSearch();

        // cleanup redirect data
        cleanupRedirects();

        // custom user created fn for 2024-07_sign_up_page_experiment
        if (onUserCreated) {
          onUserCreated().then(() => {
            client.resetStore();
            navigate(ROUTES.ONBOARDING_TRIAGE); // skip phone page and go straight to onboarding triage
          });
        } else {
          client.resetStore();
          navigate(ROUTES.HOME);
        }
      },
      onQueryFail: (error) => {
        const { message } = error;

        if (
          message?.includes('Invite code not associated with the email') ||
          message?.includes('has been REVOKED')
        ) {
          navigate('/expired-invitation');
        }
        if (message.includes('Invalid or missing inviteCode')) {
          onAlertOpen();
        } else {
          setErrors({
            ...errors,
            googleSSO: errorMessages[AuthenticationError.generic],
          });
        }

        LogRocket.log(
          `CreateUser (Google SSO) error: ${JSON.stringify(
            errors
          )}, requestId:${requestId}, email: ${credential?.user?.email}`
        );
      },
      onLastAttemptFail: (responseErrors) => {
        if (emailAlreadyInUse(responseErrors[0]?.message)) {
          setErrors({
            ...errors,
            googleSSO: errorMessages[AuthenticationError.emailalreadyinuse],
          });
        }
        LogRocket.log(
          `CreateUser (Google SSO) error: ${JSON.stringify(
            errors
          )}, requestId:${requestId}, email: ${credential?.user?.email}`
        );
        sendSegmentEvent('baselane_signup_failed', {
          enteredEmail: credential?.user?.email,
          idNotFound: true,
          event_source: 'landlord_portal',
        });
      },
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    setErrors({});
    let requestId = null;
    // Fingerprint
    getDataFingerprint()
      .then((data) => {
        requestId = data?.requestId;
      })
      .catch((error) => {
        LogRocket.log(`getDataFingerprint error: ${JSON.stringify(error)}`);
      })
      .finally(() => {
        // attempt to log in with google account
        // this should happen with or without fingerprint data
        firebaseGoogleSignIn({
          verifyFirebaseOtp,
          handleSuccess: (credential) => {
            if (isLogin) {
              signUserIn(credential, requestId);
            } else {
              sendSegmentEvent('google_signup_clicked', {
                event_source: 'landlord_portal',
              });
              signUserUp(credential, requestId);
            }
          },
          handleError: (error) => {
            setIsLoading(false);
            if (error?.code === AuthenticationError.tenant) {
              setErrors({
                ...errors,
                googleSSO: errorMessages[AuthenticationError.tenant],
              });
            }
            if (isLogin) {
              LogRocket.log(
                `Google SSO firebase - signInWithGoogle (login form) error: ${JSON.stringify(
                  error
                )}, requestId:${requestId}`
              );
            } else {
              LogRocket.log(
                `Google SSO firebase - signInWithGoogle (sign in form) error: ${JSON.stringify(
                  error
                )}, requestId:${requestId}`
              );
            }
          },
          handleInvalidCollaboratorEmail,
        });
      });
  };
  // Log fingerprint error
  useEffect(() => {
    if (fingerprintError) {
      LogRocket.log(`fingerprint API error: ${JSON.stringify(fingerprintError)}`);
    }
  }, [fingerprintError]);

  // add for consultants for growth team
  const id = text && text.toLowerCase().includes('sign up') ? 'signUpButtonSSO' : 'signInButtonSSO';

  return (
    <>
      <BaselaneButton
        id={id}
        onClick={handleSubmit}
        isFullWidth
        variant="outline"
        palette="neutral"
        size={isMobile ? 'lg' : 'md'}
        leftIcon={<IconSmallGoogleLogo />}
        styles={buttonStyles(isLogin, isMobile)}
        isLoading={isLoading || isLandlordLoginLoading}
      >
        <Text {...textStyles}>
          <Box as="span" textTransform="capitalize">
            {`${text}`}{' '}
          </Box>
          with Google
        </Text>
      </BaselaneButton>
      {errors?.googleSSO && <Text {...formErrorStyles}>{errors?.googleSSO}</Text>}
      <InviteCodeAlert isOpen={isAlertOpen} onClose={onAlertClose} />
    </>
  );
}

export default GoogleButton;
