import React from 'react';
import { HStack } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { Icon16ArrowBack, Icon16ArrowForward } from '@icons/16px';
import { BaselaneButton } from '@shared/components';

import { getCurrentTabIndex, sendCSVSegmentEvent } from '../../helpers';

type FooterNavigationProps = {
  isValid: boolean,
  handleFooterNavigation: Function,
  handleSubmit: Function,
  isLoading?: boolean,
};

const FooterNavigation = ({
  isValid,
  handleFooterNavigation,
  handleSubmit,
  isLoading = false,
}: FooterNavigationProps) => {
  const location = useLocation();

  const currentTabIndex = getCurrentTabIndex(location);
  const showPrevious = currentTabIndex > 0;
  const isOnFinalTab = currentTabIndex === 4;

  return (
    <HStack pb={4}>
      {showPrevious && (
        <BaselaneButton
          variant="outline"
          palette="neutral"
          size="md"
          onClick={() => handleFooterNavigation(false)}
          leftIcon={<Icon16ArrowBack />}
        >
          Previous
        </BaselaneButton>
      )}
      <BaselaneButton
        variant="filled"
        palette="primary"
        size="md"
        isLoading={isLoading}
        onClick={(e) => {
          sendCSVSegmentEvent(currentTabIndex);
          if (isOnFinalTab) {
            handleSubmit(e);
          } else {
            handleSubmit(e);
            handleFooterNavigation(true);
          }
        }}
        rightIcon={isOnFinalTab ? null : <Icon16ArrowForward />}
        isDisabled={!isValid}
      >
        {isOnFinalTab ? 'Import transactions' : 'Continue'}
      </BaselaneButton>
    </HStack>
  );
};

export default FooterNavigation;
