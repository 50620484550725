import React from 'react';
import { isMobile } from 'react-device-detect';
import BaselaneButton from '@shared/components/BaselaneButton';
import BaselaneButtonGroup from '@shared/components/BaselaneButtonGroup';

type AlertFooterProps = {
  leftButtonText?: string,
  rightButtonText?: string,
  cancelRef?: any,
  buttonSize?: string,
  leftButtonEvent: Function,
  rightButtonEvent: Function,
  leftButtonProps?: Object,
  rightButtonProps?: Object,
};

const AlertFooter = ({
  leftButtonText,
  rightButtonText,
  cancelRef,
  buttonSize = 'md',
  leftButtonEvent,
  rightButtonEvent,
  leftButtonProps,
  rightButtonProps,
}: AlertFooterProps) => {
  return !isMobile ? (
    <BaselaneButtonGroup size="md">
      {leftButtonEvent && (
        <BaselaneButton
          variant={leftButtonProps?.variant}
          palette={leftButtonProps?.palette}
          ref={cancelRef}
          size={buttonSize}
          onClick={leftButtonEvent}
          id={leftButtonProps?.id ?? 'left-button'}
        >
          {leftButtonText}
        </BaselaneButton>
      )}
      {rightButtonEvent && (
        <BaselaneButton
          variant={rightButtonProps?.variant}
          palette={rightButtonProps?.palette}
          size={buttonSize}
          onClick={rightButtonEvent}
          id={rightButtonProps?.id ?? 'right-button'}
        >
          {rightButtonText}
        </BaselaneButton>
      )}
    </BaselaneButtonGroup>
  ) : (
    <BaselaneButtonGroup size="md">
      {leftButtonEvent && (
        <BaselaneButton
          variant={leftButtonProps?.variant}
          palette={leftButtonProps?.palette}
          ref={cancelRef}
          size={buttonSize}
          onClick={leftButtonEvent}
          id={leftButtonProps?.id ?? 'left-button'}
        >
          {leftButtonText}
        </BaselaneButton>
      )}
      {rightButtonEvent && (
        <BaselaneButton
          variant={rightButtonProps?.variant}
          palette={rightButtonProps?.palette}
          size={buttonSize}
          onClick={rightButtonEvent}
          id={rightButtonProps?.id ?? 'right-button'}
        >
          {rightButtonText}
        </BaselaneButton>
      )}
    </BaselaneButtonGroup>
  );
};

AlertFooter.defaultProps = {
  leftButtonText: 'Continue Editing',
  rightButtonText: 'Exit Without Saving',
  cancelRef: null,
  buttonSize: 'md',
  rightButtonProps: { variant: 'filled', palette: 'primary' },
  leftButtonProps: { variant: 'outline', palette: 'neutral' },
};

export default AlertFooter;
