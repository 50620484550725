import React from 'react';
import { Heading, Text, VStack, Box, Checkbox, FormControl, useToast } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { BaselaneCardNew, BaselaneButton } from '@shared/components';
import { IllustrationOtherLeaseDocument } from '@illustrations';
import {
  GET_TENANT_SCREENING_BY_ID,
  ATTEST_SCREENING_REPORTS,
} from '@core/apollo/queries/tenantScreening';
import { getAttestationText } from '../../helpers/tenantScreeningDetail.helpers';

const Attestation = ({
  screeningId,
  applicationId,
  report,
  address,
  setShowReport,
}: {
  screeningId: String,
  applicationId: String,
  report: Object,
  address: Object,
  setShowReport: Function,
}) => {
  const attestationText = getAttestationText(report, address);
  const toast = useToast();

  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Something went wrong. Please try again.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  // TODO add mutation
  const [attestTenantScreeningReports, { loading }] = useMutation(ATTEST_SCREENING_REPORTS, {
    refetchQueries: [
      {
        fetchPolicy: 'network-only',
        query: GET_TENANT_SCREENING_BY_ID,
        variables: { id: Number(screeningId), input: {} },
      },
    ],
  });
  const initialValues = {
    attested: false,
  };
  const handleValidation = (values) => {
    const errors = {};
    if (!values.attested) {
      errors.attested = 'error';
    }
    return errors;
  };
  const onSubmit = (values) => {
    const input = {
      reportType: report.type,
      attest: values.attested,
    };
    attestTenantScreeningReports({
      variables: {
        applicationId: Number(applicationId),
        input,
      },
    })
      .then((res) => {
        if (res.errors) {
          showErrorToast();
        } else {
          setShowReport(true);
        }
      })
      .catch((err) => {
        showErrorToast();
      });
  };
  return (
    <BaselaneCardNew minH="240px" w="full" palette="empty">
      <VStack alignItems="center" w="full" gap={0.5}>
        <Box mb={1.5}>
          <IllustrationOtherLeaseDocument />
        </Box>
        <Heading size="headline-md">{report?.name}</Heading>
        <Formik
          validate={(values) => handleValidation(values)}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ values, errors, touched, handleBlur, handleChange, isValid, dirty }) => (
            <Form align="center">
              <FormControl isInvalid={errors.attested && touched.attested}>
                <Checkbox
                  id="attested"
                  className="primary-500"
                  name="attested"
                  isChecked={values.attested}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <Text textStyle="sm">{attestationText}</Text>
                </Checkbox>
              </FormControl>
              <BaselaneButton
                mt={2}
                variant="filled"
                palette="primary"
                size="lg"
                isDisabled={(!isValid || !dirty) && !loading}
                onClick={() => onSubmit(values)}
                isLoading={loading}
              >
                View Report
              </BaselaneButton>
            </Form>
          )}
        </Formik>
      </VStack>
    </BaselaneCardNew>
  );
};

export default Attestation;
