// @flow
import React, { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { Stack, Box, Text, Input, FormLabel } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { BaselaneDivider } from '@shared/components';
import { phoneMaskWithCountryCode } from '@core/utils/masks/index';
import UserContext from '@contexts/UserContext';
import UserAccessContext from '@contexts/UserAccessContext';
import useCurrentWorkspace from '@core/components/Shared/hooks/useCurrentWorkspace';
import IDVerification from './components/IDVerification';
import LockInputWrapper from './components/LockInputWrapper';
import {
  titleStyles,
  formLabelStyles,
  formInputStyles,
} from '../../../styles/userProfileTabsPanels.styles';
import { profileContainerStyles, profileDataContainerStyles } from './styles/profile.styles';

function TabProfile(): any {
  const { user, refetchUser } = useContext(UserContext);
  const { isWorkspaceOwner } = useContext(UserAccessContext);
  const { email, firstName, lastName } = user;
  const { userKycStatus, userKyc } = useCurrentWorkspace();
  let { phoneNumber = '' } = user;
  const fullName = `${firstName} ${lastName || ''}`;
  if (phoneNumber?.length === 10) {
    phoneNumber = `+1${phoneNumber}`;
  }

  return (
    <Stack {...profileContainerStyles} flexDirection={isMobile ? 'column' : 'row'}>
      <Stack {...profileDataContainerStyles(isMobile)}>
        {isMobile && <IDVerification {...{ userKycStatus, userKyc, user, refetchUser }} />}
        {isMobile && <BaselaneDivider />}
        <Text {...titleStyles}>Profile Details</Text>
        {firstName && (
          <Box>
            <FormLabel htmlFor="fullName" {...formLabelStyles}>
              Full Name
            </FormLabel>
            <LockInputWrapper>
              <Input
                {...formInputStyles}
                id="fullName"
                name="fullName"
                isDisabled
                value={fullName}
              />
            </LockInputWrapper>
          </Box>
        )}
        <Box>
          <FormLabel htmlFor="emailAndUserName" {...formLabelStyles}>
            Email & Username
          </FormLabel>
          <LockInputWrapper>
            <Input
              {...formInputStyles}
              id="emailAndUserName"
              isDisabled
              name="emailAndUserName"
              value={email}
            />
          </LockInputWrapper>
        </Box>
        {phoneNumber && (
          <Box>
            <FormLabel htmlFor="telephoneNumber" {...formLabelStyles}>
              Phone Number
            </FormLabel>
            <LockInputWrapper>
              <Input
                {...formInputStyles}
                as={MaskedInput}
                mask={phoneMaskWithCountryCode('1')}
                id="telephoneNumber"
                name="telephoneNumber"
                isDisabled
                value={phoneNumber}
              />
            </LockInputWrapper>
          </Box>
        )}
      </Stack>

      {!isMobile && <BaselaneDivider isVertical />}

      {!isMobile && isWorkspaceOwner && (
        <IDVerification {...{ userKycStatus, userKyc, user, refetchUser }} />
      )}
    </Stack>
  );
}

export default TabProfile;
