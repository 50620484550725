import { formInputStyles } from '@shared/styles/input.style';

export const baselaneRadioCardListStyles = {
  listcontainer: {
    gap: '8px',
  },
  list: {
    card: {
      padding: '14px !important',
      background: 'brand.neutral.white',
      borderWidth: '1px',
      borderColor: '#C8D0DA !important',
      width: 'unset',
      borderRadius: '4px',
      m: '0 !important',
      gap: 0,
      h: 'auto',
      boxShadow: 'none',
    },
    text: {
      textStyle: 'sm',
      color: 'brand.neutral.600',
      marginLeft: '16px',
    },
  },
  search: {
    ...formInputStyles,
    h: '50px',
    lineHeight: '13px',
    p: '16px',
    mb: '16px',
    borderColor: 'brand.darkBlue.250',

    _placeholder: {
      color: 'brand.neutral.600',
    },

    _invalid: {
      borderColor: 'brand.darkBlue.150',
      _focus: {
        borderColor: 'brand.darkBlue.250',
        color: 'brand.neutral.700',
        boxShadow: 'none',
      },
    },

    _disabled: {
      color: 'brand.neutral.600',
      background: 'brand.darkBlue.100',
    },
  },
};
