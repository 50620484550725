// @flow
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Box,
  Text,
  Stack,
  Spacer,
  FormControl,
  DrawerFooter as Footer,
  ChakraProvider,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { Formik } from 'formik';
import UserContext from '@contexts/UserContext';
import useTwoFactor from '@shared/components/TwoFactorVerificationPopUp/hooks/useTwoFactor';
import {
  AlertHeader,
  BaselaneAlert,
  BaselaneButton,
  BaselaneButtonGroup,
  BaselaneReceivingAccounts,
  TwoFactorVerificationPopUp,
} from '@shared/components';
import AddBankAccount from '@core/components/AddBankAccount';
import { GET_UPDATE_PLAID_LINK_TOKEN } from '@core/apollo/queries';
import { getConnectedAccountsFilterOptions } from '@core/contexts/TransactionContext/helpers';
import { defaultComponentCopy } from '@shared/components/BaselaneReceivingAccounts/helpers/componentCopy';
import { Icon16Bolt } from '@icons/16px';
import habitatTheme from '@core/themeHabitat';
import type { Bank } from '../../../types';
import {
  getStripeBankAccountId,
  receivingBankFormValidation,
  receivingBankInitialValues,
  getBankAccounts,
  getAccountsOnly,
} from '../../formHelpers/receivingBank.helper';
import AccountFormWrapper from './AccountFormWrapper';
import { fieldContainer, generalFormContainerStyles } from '../../styles/shared.styles';
import { drawerFooterStyle, resyncLinkStyles } from '../../styles/receivingBankAccount.styles';
import {
  viewModeAlertIconBgStyles,
  viewModeAlertIconContainerStyles,
} from '../../../styles/review.styles';

type ReceivingBankAccountFormProps = {
  isFromResponsiveMode: boolean,
  setFormVariables: Function,
  handleBackClick: Function,
  leaseTermsRef: any,
  bankAccountsRef: any,
  bankProps?: Object,
  unit: Object,
  lease: Object,
  handleModifyReceivingBankAccounts: Function,
  setHasConnectedBank: Function,
  showReceivingBAEmptyState: boolean,
  setShowReceivingBAEmptyState: Function,
  isAccountsEdit: boolean,
  tabIndex: number,
  accountsPanelIndex: number,
  isLeasesPage?: boolean,
  hideExternalAccounts?: boolean,
};

function ReceivingBankAccountForm({
  isFromResponsiveMode,
  setFormVariables,
  handleBackClick,
  leaseTermsRef,
  bankAccountsRef,
  bankProps = {},
  unit,
  lease,
  handleModifyReceivingBankAccounts,
  setHasConnectedBank,
  showReceivingBAEmptyState,
  setShowReceivingBAEmptyState,
  isAccountsEdit,
  tabIndex,
  accountsPanelIndex,
  isLeasesPage = false,
  hideExternalAccounts = false,
}: ReceivingBankAccountFormProps): any {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const onAlertOpen = () => setIsAlertOpen(true);
  const onAlertClose = () => setIsAlertOpen(false);
  const cancelAlert = useRef();
  const leaseId = (lease || unit?.lease)?.id;

  // account phone OTP double check
  const { user } = useContext(UserContext);
  const { states, stateFunctions } = useTwoFactor(false);
  const {
    onOTPPopupOpen,
    onOTPPopupClose,
    setOTPErrorCode,
    handleSendText,
    handleVerifyOtp,
  } = stateFunctions;

  const { banksData, refetchBankAccounts } = bankProps || {};

  const [firstLoad, setFirstLoad] = useState(true);

  const banks: Bank[] = banksData ? banksData.bank : [];
  // get all accounts including unconnected ones (ITEM_LOGIN_REQUIRED)
  const accounts = getBankAccounts(banks, true);

  const accountsOptions = banksData && getConnectedAccountsFilterOptions(banksData);
  const connectedBanks = accountsOptions?.filter((option) => !!option.items.length);

  const connectedAccounts = getAccountsOnly(connectedBanks);

  const initialRentAndFeesBankAccountId = (lease || unit?.lease)?.rentAndFeesBankAccountId;
  const initialDepositBankAccountId = (lease || unit?.lease)?.depositBankAccountId;

  const [selectedAccount, setSelectedAccount] = useState(
    connectedAccounts.find((option) => option.id === initialRentAndFeesBankAccountId)
  );
  const [selectedDepositAccount, setSelectedDepositAccount] = useState(
    connectedAccounts.find((option) => option.id === initialDepositBankAccountId)
  );

  const [receivingPlaidLinkToken, setReceivingPlaidLinkToken] = useState(null);
  const [depositPlaidLinkToken, setDepositPlaidLinkToken] = useState(null);
  // for resync purposes
  const [getResyncToken] = useLazyQuery(GET_UPDATE_PLAID_LINK_TOKEN);

  const getAccountSubType = (value, accountsFromRefetch) => {
    const connectedBankAccounts = accountsFromRefetch ?? accounts;
    const [acc] = connectedBankAccounts.filter((a) => a.id === value);
    return acc?.accountSubType;
  };

  const getAccountType = (value, accountsFromRefetch) => {
    const connectedBankAccounts = accountsFromRefetch ?? accounts;
    const [acc] = connectedBankAccounts.filter((a) => a.id === value);
    return acc?.accountType;
  };

  const handleAccountChange = (bankAccount, bankAccountIds, accountsFromRefetch) => {
    const connectedBankAccounts = accountsFromRefetch ?? accounts;
    // field value from onChange
    const { rentAndFeesBankAccount, depositBankAccount } = bankAccount ?? {};
    const rentAndFeesBankAccountId =
      bankAccountIds?.rentAndFeesBankAccountId !== 'Select value'
        ? bankAccountIds?.rentAndFeesBankAccountId
        : null;
    const depositBankAccountId =
      bankAccountIds?.depositBankAccountId !== 'Select value'
        ? bankAccountIds?.depositBankAccountId
        : null;
    // rentAndFees values from before onChange
    const rentAndFees = connectedBankAccounts.filter(
      (account) => account.id === rentAndFeesBankAccountId
    );
    const hasRentAndFees = rentAndFees.length > 0;
    const rentAndFeesObj = hasRentAndFees ? rentAndFees[0] : null;

    // deposit values from before onChange
    const deposit = connectedBankAccounts.filter((account) => account.id === depositBankAccountId);
    const hasDeposit = deposit.length > 0;
    const depositObj = hasDeposit ? deposit[0] : null;

    let variables = {
      rentAndFeesBankAccount:
        rentAndFeesBankAccount ||
        (rentAndFeesObj
          ? {
              id: rentAndFeesObj?.id,
              accountSubType: rentAndFeesObj?.accountSubType,
              accountType: rentAndFeesObj?.accountType,
              stripeBankAccountId: rentAndFeesObj?.stripeBankAccountId,
              isExternal: rentAndFeesObj?.isExternal,
            }
          : rentAndFeesObj),
      depositBankAccount:
        depositBankAccount ||
        (hasDeposit
          ? {
              id: depositObj?.id,
              accountSubType: depositObj?.accountSubType,
              accountType: depositObj?.accountType,
              stripeBankAccountId: depositObj?.stripeBankAccountId,
              isExternal: depositObj?.isExternal,
            }
          : depositObj),
    };

    // if coming from 'View Details' > 'Edit'
    if (isFromResponsiveMode) {
      variables = {
        rentAndFeesBankAccountId: rentAndFeesBankAccount?.id || rentAndFeesObj?.id,
        depositBankAccountId: depositBankAccount?.id || depositObj?.id,
      };
    }

    if (connectedBankAccounts.length === 1) {
      // selection being auto-set, however, saving is still in progress atm so queue
      setTimeout(() => {
        setFormVariables(variables);
        bankAccountsRef.current?.validateForm();
      }, 1000);
    } else {
      setFormVariables(variables);

      // if connectionState === 'ITEM_LOGIN_REQUIRED'
      const connectAccount = bankAccount.rentAndFeesBankAccount || bankAccount.depositBankAccount;
      if (connectAccount.connectionState === 'ITEM_LOGIN_REQUIRED') {
        getResyncToken({
          variables: { bankAccountId: connectAccount?.id },
        }).then((res) => {
          if (bankAccount.rentAndFeesBankAccount) {
            setReceivingPlaidLinkToken(res?.data?.updatePlaidLinkToken);
          }
          if (bankAccount.depositBankAccount) {
            setDepositPlaidLinkToken(res?.data?.updatePlaidLinkToken);
          }
        });
      }
    }
  };

  const onAddManualAccountSuccess = () => {
    setHasConnectedBank(true);
    if (!isFromResponsiveMode) {
      setShowReceivingBAEmptyState(false);
    }
  };

  const handleCancelBankAccClick = () => {
    onAlertClose();
  };

  const handleSendTextSuccess = () => {
    // this just makes sure OTPErrorCode is false/empty as sending text to the number succeeded
    setOTPErrorCode(false);
  };

  const handleSendTextFail = (error) => {
    // just sets any error code, user can't change account using this phone
    setOTPErrorCode(error);
  };

  const handleVerifyOtpSuccess = () => {
    onOTPPopupClose();
    handleModifyReceivingBankAccounts();
  };

  const handleChangeBankAccClick = () => {
    onAlertClose();
    if (isFromResponsiveMode) {
      handleSendText(user.phoneNumber, false, handleSendTextSuccess, handleSendTextFail);
      onOTPPopupOpen();
    } else {
      handleModifyReceivingBankAccounts();
    }
  };

  const handleOnSaveClick = (dirty) => {
    if (dirty) {
      onAlertOpen();
    }
  };

  const handleRefetchBanks = () => {
    refetchBankAccounts().then(() => bankAccountsRef?.current?.validateForm());
  };

  // TODO: once new Leases is used, remove use of unit
  const showSecurityDepositDropdown =
    (!leaseTermsRef?.current?.values?.securityDepositPaid &&
      leaseTermsRef?.current?.values?.securityDeposit) ||
    (unit?.lease || lease)?.depositBankAccountId;

  useEffect(() => {
    if (tabIndex === accountsPanelIndex) {
      setFirstLoad(false);
      if (
        bankAccountsRef?.current &&
        !bankAccountsRef?.current?.touched?.rentAndFeesBankAccountId &&
        accounts &&
        accounts.length === 1
      ) {
        const { id } = accounts[0];
        const depId = showSecurityDepositDropdown ? id : '';
        const bankAccount = {
          rentAndFeesBankAccount: {
            id,
            accountSubType: getAccountSubType(id),
            accountType: getAccountType(id),
            stripeBankAccountId: getStripeBankAccountId(accounts, id),
          },
        };
        if (depId) {
          bankAccount.depositBankAccount = {
            id: depId,
            accountSubType: getAccountSubType(depId),
            accountType: getAccountType(depId),
            stripeBankAccountId: getStripeBankAccountId(accounts, depId),
          };
        }
        handleAccountChange(bankAccount, {
          rentAndFeesBankAccountId: id,
          depositBankAccountId: depId,
        });
        bankAccountsRef.current?.setFieldValue('rentAndFeesBankAccountId', id);
        setSelectedAccount(connectedAccounts.find((option) => option.id === id));
        if (depId) {
          bankAccountsRef.current?.setFieldValue('depositBankAccountId', depId);
          setSelectedDepositAccount(connectedAccounts.find((option) => option.id === id));
        }
      }
    }
  }, [tabIndex]);

  useEffect(() => {
    if (!selectedAccount && initialRentAndFeesBankAccountId) {
      setSelectedAccount(
        connectedAccounts.find((option) => option.id === initialRentAndFeesBankAccountId)
      );
    }
    if (!selectedDepositAccount && initialDepositBankAccountId) {
      setSelectedDepositAccount(
        connectedAccounts.find((option) => option.id === initialDepositBankAccountId)
      );
    }
  }, [
    initialRentAndFeesBankAccountId,
    initialDepositBankAccountId,
    selectedAccount,
    selectedDepositAccount,
    connectedAccounts,
    setSelectedAccount,
    setSelectedDepositAccount,
  ]);

  useEffect(() => {
    // onload of this ui get updated bank accounts
    // use case when users come back from another flow e.g sole prop flow and we want to get the
    // newly added baselane bank.
    // N.B. not needed for unified flow as leaseFormTabs already does this
    if (refetchBankAccounts && isFromResponsiveMode) {
      refetchBankAccounts();
    }
    // we only want to run this useEffect at the start
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Only set to null if length of accounts is 1
    setSelectedAccount(null);
    setSelectedDepositAccount(null);
  }, [leaseId]);

  const twoFactorVerificationProps = {
    ...states,
    ...stateFunctions,
    getOTP: () => handleSendText(user.phoneNumber, true, handleSendTextSuccess, handleSendTextFail),
    phoneNumber: user.phoneNumber,
    handleVerifyOnClick: (otpCode) =>
      handleVerifyOtp({ recipient: user.phoneNumber, code: otpCode }, handleVerifyOtpSuccess),
  };

  const alertHeader = (
    <AlertHeader
      title="Are You Sure You Want To Change Your Receiving Bank Account for This Rent Collection?"
      iconBgStyles={viewModeAlertIconBgStyles}
      iconContainerStyles={viewModeAlertIconContainerStyles}
    />
  );

  const alertFooter = (
    <Stack direction="row">
      <BaselaneButton
        variant="outline"
        palette="neutral"
        size="md"
        onClick={handleCancelBankAccClick}
      >
        Cancel
      </BaselaneButton>
      <BaselaneButton
        variant="filled"
        palette="primary"
        size="md"
        onClick={handleChangeBankAccClick}
      >
        Yes, change bank account
      </BaselaneButton>
    </Stack>
  );

  // flow, main, and params are for sole prop flow
  const from = isAccountsEdit
    ? {
        flow: '/leases',
        main: '/leases',
        params: {
          isFromUpdateAccount: true,
        },
      }
    : {
        flow: '/unified_rent_collection',
        main: '/leases',
        params: null,
      };

  // section is for manual acc flow
  const section = 'rent_collection';

  const baselaneAccountDetails = defaultComponentCopy?.baselaneAccountDetails?.map((item) => {
    if (item.id === 4) {
      return { ...item, text: `Automated bookkeeping for your rent` };
    }
    return item;
  });

  // The imported wrapper will provide some additional padding and a BaselaneDrawer for the inApp flow
  return (
    <AccountFormWrapper
      isFromResponsiveMode={isFromResponsiveMode}
      isAccountsEdit={isAccountsEdit}
      handleBackClick={handleBackClick}
    >
      {isFromResponsiveMode && <TwoFactorVerificationPopUp {...twoFactorVerificationProps} />}
      <Box {...generalFormContainerStyles} padding="0" overflowY="none">
        <ChakraProvider theme={habitatTheme}>
          <Formik
            innerRef={bankAccountsRef}
            validate={(values) =>
              receivingBankFormValidation(accounts, showSecurityDepositDropdown, values, firstLoad)
            }
            initialValues={receivingBankInitialValues(unit || { lease })}
            validateOnBlur
            validateOnMount
            enableReinitialize
          >
            {({
              values,
              setFieldTouched,
              setFieldValue,
              setValues,
              errors,
              touched,
              dirty,
              isValid,
            }) => (
              <>
                <FormControl
                  {...fieldContainer}
                  mb="24px"
                  w="100%"
                  isInvalid={
                    errors.rentAndFeesBankAccountId &&
                    (touched.rentAndFeesBankAccountId || values.rentAndFeesBankAccountId)
                  }
                >
                  <BaselaneReceivingAccounts
                    isFromResponsiveMode={isFromResponsiveMode}
                    baselaneAccountHeading={
                      isLeasesPage ? 'Supercharge your rent collection with Baselane Banking' : null
                    }
                    baselaneAccountDetails={isLeasesPage ? baselaneAccountDetails : null}
                    baselaneAccountButtonSiblings={
                      isLeasesPage ? (
                        <>
                          <Box color="brand.neutral.600">
                            <Icon16Bolt />
                          </Box>
                          <Text
                            {...{
                              color: 'brand.neutral.600',
                              textStyle: 'sm',
                              h: '100%',
                            }}
                          >
                            Only takes 2 minutes
                          </Text>
                        </>
                      ) : null
                    }
                    accounts={banksData ? connectedBanks : []}
                    hideExternalAccounts={hideExternalAccounts}
                    selectedAccount={selectedAccount}
                    onSelectAccount={(id) => {
                      const updatedAcc = connectedAccounts.find((account) => account.id === id);
                      setSelectedAccount(updatedAcc);
                      setFieldTouched('rentAndFeesBankAccountId');
                      setFieldValue('rentAndFeesBankAccountId', id);
                      handleAccountChange(
                        {
                          rentAndFeesBankAccount: {
                            id,
                            accountSubType: getAccountSubType(id),
                            accountType: getAccountType(id),
                            stripeBankAccountId: getStripeBankAccountId(accounts, id),
                            connectionState: updatedAcc.connectionState,
                            isExternal: updatedAcc.isExternal,
                          },
                        },
                        values
                      );
                    }}
                    refetchBankAccounts={() => {
                      return refetchBankAccounts().then(({ data }) => {
                        const updatedBanks: Bank[] = banksData ? data.bank : [];
                        const accountsFromRefetch = getBankAccounts(updatedBanks, true);
                        const updatedAccountsOptions =
                          data && getConnectedAccountsFilterOptions(data);
                        const updatedConnectedBanks = updatedAccountsOptions?.filter(
                          (option) => !!option.items.length
                        );
                        const updatedConnectedAccounts = getAccountsOnly(updatedConnectedBanks);
                        // if there's only one account, auto-select and trigger validation
                        if (updatedConnectedAccounts.length === 1) {
                          const { id } = updatedConnectedAccounts[0];
                          const updatedAcc = updatedConnectedAccounts.find(
                            (account) => account.id === id
                          );
                          setSelectedAccount(updatedAcc);
                          setFieldTouched('rentAndFeesBankAccountId');
                          setFieldValue('rentAndFeesBankAccountId', id);
                          handleAccountChange(
                            {
                              rentAndFeesBankAccount: {
                                id,
                                accountSubType: getAccountSubType(id, accountsFromRefetch),
                                accountType: getAccountType(id, accountsFromRefetch),
                                stripeBankAccountId: getStripeBankAccountId(
                                  accountsFromRefetch,
                                  id
                                ),
                                connectionState: updatedAcc.connectionState,
                              },
                            },
                            {
                              rentAndFeesBankAccountId: id,
                            },
                            accountsFromRefetch
                          );
                        }
                      });
                    }}
                    onAddManualAccountSuccess={onAddManualAccountSuccess}
                    from={from}
                    section={section}
                    hideHeading={!isFromResponsiveMode}
                    hideButtonAndCards={showSecurityDepositDropdown}
                    errorMessage={
                      errors.rentAndFeesBankAccountId === 'Disconnected' ? (
                        <Text>
                          {' '}
                          Account connection expired.{' '}
                          {receivingPlaidLinkToken && (
                            <AddBankAccount
                              bankAccId={selectedAccount?.id}
                              mode="RESYNC"
                              titleText="Resync your account"
                              size="sm"
                              variant="link"
                              palette="danger"
                              showCustomButton
                              hasIconLock={false}
                              hasRightChevronIcon={false}
                              styles={resyncLinkStyles}
                              containerStyles={{ as: 'span' }}
                              banks={banks}
                              state="ITEM_LOGIN_REQUIRED"
                              handleSuccessFn={(res) => {
                                const { connectionState } =
                                  res?.data?.reSyncExternalBankAccount ?? {};
                                if (connectionState === 'CONNECTED') {
                                  refetchBankAccounts().then(() => {
                                    bankAccountsRef?.current?.validateForm();
                                  });
                                }
                              }}
                              refetchFunction={handleRefetchBanks}
                              updateLinkToken={receivingPlaidLinkToken}
                            />
                          )}{' '}
                          {!receivingPlaidLinkToken && 'Resync '}
                          to receive payments.
                        </Text>
                      ) : (
                        errors.rentAndFeesBankAccountId
                      )
                    }
                  />
                </FormControl>

                {showSecurityDepositDropdown && (
                  <FormControl
                    {...fieldContainer}
                    mb="24px"
                    isInvalid={
                      errors.depositBankAccountId &&
                      (touched.depositBankAccountId || values.depositBankAccountId)
                    }
                  >
                    <BaselaneReceivingAccounts
                      isFromResponsiveMode={isFromResponsiveMode}
                      baselaneAccountHeading={
                        isLeasesPage
                          ? 'Supercharge your rent collection with Baselane Banking'
                          : null
                      }
                      baselaneAccountDetails={isLeasesPage ? baselaneAccountDetails : null}
                      baselaneAccountButtonSiblings={
                        isLeasesPage ? (
                          <>
                            <Box color="brand.neutral.600">
                              <Icon16Bolt />
                            </Box>
                            <Text
                              {...{
                                color: 'brand.neutral.600',
                                textStyle: 'sm',
                                h: '100%',
                              }}
                            >
                              Only takes 2 minutes
                            </Text>
                          </>
                        ) : null
                      }
                      accounts={banksData ? connectedBanks : []}
                      hideExternalAccounts={hideExternalAccounts}
                      hideSavingsAccounts={false}
                      selectedAccount={selectedDepositAccount}
                      onSelectAccount={(id) => {
                        const updatedAcc = connectedAccounts.find((account) => account.id === id);
                        setSelectedDepositAccount(updatedAcc);
                        setFieldTouched('depositBankAccountId');
                        setFieldValue('depositBankAccountId', id);
                        handleAccountChange(
                          {
                            depositBankAccount: {
                              id,
                              accountSubType: getAccountSubType(id),
                              accountType: getAccountType(id),
                              stripeBankAccountId: getStripeBankAccountId(accounts, id),
                              connectionState: updatedAcc.connectionState,
                              isExternal: updatedAcc.isExternal,
                            },
                          },
                          values
                        );
                      }}
                      refetchBankAccounts={() => {
                        refetchBankAccounts().then(({ data }) => {
                          const updatedBanks: Bank[] = banksData ? data.bank : [];
                          const accountsFromRefetch = getBankAccounts(updatedBanks, true);
                          const updatedAccountsOptions =
                            data && getConnectedAccountsFilterOptions(data);
                          const updatedConnectedBanks = updatedAccountsOptions?.filter(
                            (option) => !!option.items.length
                          );
                          const updatedConnectedAccounts = getAccountsOnly(updatedConnectedBanks);
                          // if there's only one account, auto-select and trigger validation
                          if (updatedConnectedAccounts.length === 1) {
                            const { id } = updatedConnectedAccounts[0];
                            const updatedAcc = updatedConnectedAccounts.find(
                              (account) => account.id === id
                            );
                            setSelectedAccount(updatedAcc);
                            setSelectedDepositAccount(updatedAcc);

                            setFieldTouched('rentAndFeesBankAccountId');
                            setFieldTouched('depositBankAccountId');

                            setValues({
                              ...values,
                              rentAndFeesBankAccountId: id,
                              depositBankAccountId: id,
                            });

                            handleAccountChange(
                              {
                                rentAndFeesBankAccount: {
                                  id,
                                  accountSubType: getAccountSubType(id, accountsFromRefetch),
                                  accountType: getAccountType(id, accountsFromRefetch),
                                  stripeBankAccountId: getStripeBankAccountId(
                                    accountsFromRefetch,
                                    id
                                  ),
                                  connectionState: updatedAcc.connectionState,
                                },
                                depositBankAccount: {
                                  id,
                                  accountSubType: getAccountSubType(id, accountsFromRefetch),
                                  accountType: getAccountType(id, accountsFromRefetch),
                                  stripeBankAccountId: getStripeBankAccountId(
                                    accountsFromRefetch,
                                    id
                                  ),
                                  connectionState: updatedAcc.connectionState,
                                },
                              },
                              {
                                rentAndFeesBankAccountId: id,
                                depositBankAccountId: id,
                              },
                              accountsFromRefetch
                            );
                          }
                        });
                      }}
                      onAddManualAccountSuccess={onAddManualAccountSuccess}
                      from={from}
                      section={section}
                      hideHeading
                      formLabel="Account to receive security deposit"
                      errorMessage={
                        errors.depositBankAccountId === 'Disconnected' ? (
                          <Text>
                            Account connection expired.{' '}
                            {depositPlaidLinkToken && (
                              <AddBankAccount
                                bankAccId={selectedDepositAccount?.id}
                                mode="RESYNC"
                                titleText="Resync your account"
                                size="sm"
                                variant="link"
                                palette="danger"
                                showCustomButton
                                hasIconLock={false}
                                hasRightChevronIcon={false}
                                styles={resyncLinkStyles}
                                containerStyles={{ as: 'span' }}
                                banks={banks}
                                state="ITEM_LOGIN_REQUIRED"
                                handleSuccessFn={(res) => {
                                  const { connectionState } =
                                    res?.data?.reSyncExternalBankAccount ?? {};
                                  if (connectionState === 'CONNECTED') {
                                    refetchBankAccounts().then(() => {
                                      bankAccountsRef?.current?.validateForm();
                                    });
                                  }
                                }}
                                refetchFunction={handleRefetchBanks}
                                updateLinkToken={depositPlaidLinkToken}
                              />
                            )}{' '}
                            {!depositPlaidLinkToken && 'Resync '}
                            to receive payments.
                          </Text>
                        ) : (
                          errors.depositBankAccountId
                        )
                      }
                    />
                  </FormControl>
                )}

                <Spacer />

                {isFromResponsiveMode && (
                  <Footer {...drawerFooterStyle(isMobile)}>
                    <BaselaneButtonGroup size="md">
                      <BaselaneButton
                        size="md"
                        variant="outline"
                        palette="neutral"
                        onClick={() =>
                          showReceivingBAEmptyState
                            ? setShowReceivingBAEmptyState(false)
                            : handleBackClick()
                        }
                      >
                        Cancel
                      </BaselaneButton>
                      {!showReceivingBAEmptyState && (
                        <BaselaneButton
                          size="md"
                          variant="filled"
                          palette="primary"
                          isDisabled={
                            !dirty ||
                            !isValid ||
                            errors.rentAndFeesBankAccountId ||
                            errors.depositBankAccountId
                          }
                          onClick={() => handleOnSaveClick(dirty && touched)}
                        >
                          Save Changes
                        </BaselaneButton>
                      )}
                    </BaselaneButtonGroup>
                  </Footer>
                )}
              </>
            )}
          </Formik>
        </ChakraProvider>

        <BaselaneAlert
          isOpen={isAlertOpen}
          onClose={onAlertClose}
          leastDestructiveRef={cancelAlert}
          isCentered
          header={alertHeader}
          body="Only upcoming and scheduled invoices will be reflected."
          footer={alertFooter}
          size="2xl"
          // Icon in header missaligns header text with body text. Adding left-padding to body to fix this.
          bodyStyles={{ pl: '37px' }}
        />
      </Box>
    </AccountFormWrapper>
  );
}

export default ReceivingBankAccountForm;
