import React, { useEffect } from 'react';
import {
  Step,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Box,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import useBreakPoints from '@core/hooks/useBreakPoints';
import { StepNumberInactive, StepComplete, StepNumberActive } from './StepStatusIndicator';

type BaselaneHorizontalStepperProps = {
  steps: Array<Object>,
  activeStep: string,
  setActiveStep: Function | null,
  isActiveStep: Function,
  isCompleteStep: Function | null,
  variant: string,
  isExpandedInMobile: boolean,
  isSpreadInMobile: boolean,
};

const BaselaneHorizontalStepper = ({
  steps,
  activeStep,
  setActiveStep = null,
  isActiveStep,
  isCompleteStep = null,
  variant = 'default',
  isExpandedInMobile = true,
  isSpreadInMobile = true,
  ...rest
}: BaselaneHorizontalStepperProps) => {
  const { isMax768: isMobile, isMin768: isDesktop } = useBreakPoints();

  const handleStepClick = (index) => {
    if (setActiveStep && variant === 'clickable') {
      setActiveStep(index);
    }
  };

  const styles = useMultiStyleConfig(`Stepper`, {
    variant,
    isSpreadInMobile: isSpreadInMobile && isMobile,
    isExpandedInMobile: isExpandedInMobile && isMobile,
    isMobile,
  });

  useEffect(() => {
    if (activeStep !== undefined && activeStep !== null && isMobile) {
      document
        .getElementById(`stepper-step--${activeStep}`)
        .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [activeStep]);

  return (
    <Stepper index={activeStep} variant={variant} {...styles.stepper} {...rest}>
      {steps.map((step, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          <Step
            id={`stepper-step--${index}`}
            {...styles.step}
            onClick={() => handleStepClick(index)}
            {...{ bgColor: isActiveStep(index) ? 'brand.darkBlue.100' : 'white' }}
          >
            <StepIndicator>
              <StepStatus
                complete={
                  <StepComplete
                    isComplete={isCompleteStep ? isCompleteStep(index) : false}
                    isActive={false}
                    variant={variant}
                    element={index + 1}
                  />
                }
                incomplete={<StepNumberInactive variant={variant} element={index + 1} />}
                active={
                  <StepNumberActive
                    element={
                      isCompleteStep && isCompleteStep(index) ? (
                        <StepComplete
                          isComplete={isCompleteStep ? isCompleteStep(index) : false}
                          isActive
                          variant={variant}
                        />
                      ) : (
                        index + 1
                      )
                    }
                  />
                }
              />
            </StepIndicator>

            {((isExpandedInMobile && isMobile) || isDesktop) && (
              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
              </Box>
            )}
          </Step>
          <StepSeparator {...styles.separator['&[data-orientation=horizontal]']} />
        </React.Fragment>
      ))}
    </Stepper>
  );
};

export default BaselaneHorizontalStepper;
