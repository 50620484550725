import { React } from 'react';
import { useFormikContext } from 'formik';
import SharedHeader from './SharedHeader';
import { useCurrentStep, useTotalSteps } from '../../sendFundsStore';
import SelectRecipient from '../FormSteps/SelectRecipientStep';
import SelectPaymentMethod from '../FormSteps/PaymentMethodStep/SelectPaymentMethod';
import PaymentDetails from '../FormSteps/PaymentDetailsStep/PaymentDetails';
import BookkeepingDetails from '../FormSteps/BookkeepingStep/BookkeepingDetails';
import ReviewScreen from '../FormSteps/ReviewStep';
import OneTimeSuccessScreen from '../FormSteps/OneTimeSuccessScreen';
import RecurringSuccessScreen from '../FormSteps/RecurringSuccessScreen';
import RecurringPaymentReview from '../FormSteps/PaymentDetailsStep/recurring-payment/review/RecurringPaymentReview';
import { steps } from '../../helpers/formHelpers';

const Body = () => {
  const currentStep = useCurrentStep();
  const totalSteps = useTotalSteps();
  const formik = useFormikContext();
  const renderDrawerBody = () => {
    if (currentStep === steps.SELECT_RECIPIENT) {
      return <SelectRecipient />;
    }
    if (
      currentStep === steps.SELECT_PAYMENT_METHOD ||
      currentStep === steps.ADD_RECIPIENT ||
      currentStep === steps.UPDATE_OR_ADD_PAYMENT_METHOD
    ) {
      return <SelectPaymentMethod />;
    }
    if (currentStep === steps.PAYMENT_DETAILS) {
      return <PaymentDetails />;
    }
    if (currentStep === steps.BOOKKEPPING_DETAILS) {
      return <BookkeepingDetails />;
    }
    if (currentStep === steps.REVIEW_PAYMENT) {
      return formik.values?.scheduledPaymentType === 'recurring' ? (
        <RecurringPaymentReview />
      ) : (
        <ReviewScreen />
      );
    }

    if (currentStep === steps.ONE_TIME_SUCCESS_SCREEN) {
      return <OneTimeSuccessScreen />;
    }

    if (currentStep === steps.RECCURING_SUCCESS_SCREEN) {
      return <RecurringSuccessScreen />;
    }

    return null;
  };

  return (
    <>
      {currentStep !== steps.SUCCESS_SCREEN && <SharedHeader {...{ currentStep, totalSteps }} />}
      {renderDrawerBody()}
    </>
  );
};

export default Body;
