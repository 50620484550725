import React from 'react';
import { Box, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { IconDocumentCheckOutline, IconDownload } from '@icons';
import { Icon16Delete, Icon16Download } from '@icons/16px';
import { Icon24Document } from '@icons/24px';
import { BaselaneButtonIcon } from '../../BaselaneButtonIcon';
import { documentCardStyles } from './styles/documentCard.styles';

type DocumentCardProps = {
  doc: Object,
  handleDeleteDocAlertOpen: Function,
  getDownloadUrl: Function,
  isNewDesign: boolean,
  description: string,
  documentTitle?: string,
};

// prettier-ignore
function DocumentCard({
  doc,
  handleDeleteDocAlertOpen,
  getDownloadUrl,
  isNewDesign = false,
  description,
  documentTitle,
}: DocumentCardProps): any {
  // Destructure 'document' Object
  const { id, name, fileExtension: fileExt } = doc ?? {};
  const fileExtension = fileExt || name?.split('.')?.[1] || '';

  const title = documentTitle || name;

  // Destructure Imported Styles
  const { card, textcontainer, nameStyle, typeStyle } = documentCardStyles(isNewDesign) ?? {};

  const downloadDocument = ({ url }) => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
  };

  // opens images and videos, downloads other files
  const handleDownloadOrOpen = ({ url }) => {
    window.open(url, '_blank', 'noopener');
  };

  // Handle Download
  const onDownloadDocument = async () => {
    const { generateDocumentDownloadUrl: url } = (await getDownloadUrl()) ?? {};

    if (url) {
      downloadDocument({ url });
    }
  };

  return (
    <Stack key={id} {...card}>
      <HStack>
        <Box>
          {isNewDesign ? <Icon24Document /> : <IconDocumentCheckOutline />}
        </Box>

        <Stack {...textcontainer}>
          <Text {...nameStyle}>{title}</Text>
          <Text {...typeStyle}>{fileExtension}{description ? ` - ${description}` : ''}</Text>
        </Stack>

        <Spacer />
        <BaselaneButtonIcon
          variant="outline"
          palette="neutral"
          styles={{
              width: 'fit-content',
          }}
          icon={isNewDesign ? <Icon16Download /> : <IconDownload />}
          onClick={getDownloadUrl ? onDownloadDocument : () => handleDownloadOrOpen({ url: doc.asset_url })}
        />
        {handleDeleteDocAlertOpen && (
          <BaselaneButtonIcon
            variant="outline"
            palette="danger"
            styles={{
              width: 'fit-content',
          }}
            icon={<Icon16Delete />}
            onClick={() => handleDeleteDocAlertOpen({ id: doc.id })}
          />
        )}
      </HStack>
    </Stack>
  );
}

export default DocumentCard;
