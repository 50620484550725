import process from 'process';
import React, { useContext, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Box, Stack, Spacer, Text, FormControl, FormLabel, useToast } from '@chakra-ui/react';
import { loadVGSCollect } from '@vgs/collect-js';
import { BaselaneAlert, BaselaneButton, BaselaneButtonGroup } from '@shared/components';
import customTheme from '@theme';
import BanksContext from '@contexts/BanksContext';
import { FRAGMENT_CARD_STATUS } from '../../../../../queries/fragments';
import {
  popupMessageStyles,
  titleStyles,
  popupFormLabelStyles,
  popupFormInputStyles,
  popupFooterContainerStyles,
} from '../styles/popup.styles';

type SetPINPopupProps = {
  card: Object,
  activateCardForm: Object,
  setActivateCardForm: Function,
  sensitiveToken: string,
  isSetPINPopupOpen: boolean,
  onSetPINPopupClose: Function,
  onActivatePopupClose: Function,
  onActivationSuccessPopupOpen: Function,
};

const SetPINPopup = ({
  card,
  activateCardForm,
  setActivateCardForm,
  sensitiveToken,
  isSetPINPopupOpen,
  onSetPINPopupClose,
  onActivatePopupClose,
  onActivationSuccessPopupOpen,
}: SetPINPopupProps) => {
  const { refetchBankSummary } = useContext(BanksContext);
  const { cache } = useApolloClient();

  const [isValid, setIsValid] = useState(false);
  const [form, setForm] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const id = Number(card?.externalId);

  const css = {
    ...popupFormInputStyles,
  };

  const handleSetPINPopupClose = () => {
    onSetPINPopupClose();
    setForm({});
  };

  const cleanup = () => {
    handleSetPINPopupClose();
    setActivateCardForm({});
    onActivatePopupClose();
  };

  const initForm = (vgsCollect) => {
    const formCreate = vgsCollect.init((field) => {
      const pin = field['data.attributes.pin'];

      const isFormValid = pin?.isValid;
      setIsValid(isFormValid);
    });

    formCreate.field('#cc-pin', {
      type: 'text',
      name: 'data.attributes.pin',
      successColor: customTheme.colors.brand.neutral['700'],
      errorColor: customTheme.colors.red['800AA'],
      placeholder: '1234',
      maxLength: 4,
      validations: ['required', '/^([0-9]{4,6})$/'],
      css,
    });

    setForm(formCreate);
  };

  const handleBackClick = () => {
    handleSetPINPopupClose();
  };

  const toast = useToast();
  const showErrorToast = (text) =>
    toast({
      description: text,
      status: 'error',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  const handleActivateClick = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Activate card
    activateCardForm.submit(
      `/cards/${id}/activate`,
      {
        mapDotToObject: 'true',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Authorization: `Bearer ${sensitiveToken}`,
        },
        cache: 'no-store',
      },
      (status) => {
        if (status === 200) {
          // TODO: Move set pin to an independent flow
          // Set pin
          form.submit(
            `/cards/${id}/secure-data/pin`,
            {
              mapDotToObject: 'true',
              headers: {
                'Content-Type': 'application/vnd.api+json',
                Authorization: `Bearer ${sensitiveToken}`,
              },
              cache: 'no-store',
            },
            (pinStatus) => {
              setIsLoading(false);
              if (pinStatus === 200) {
                refetchBankSummary();
                cache.writeFragment({
                  id: cache.identify(card),
                  fragment: FRAGMENT_CARD_STATUS,
                  data: {
                    cardStatus: 'Active',
                  },
                });
                cleanup();
                onActivationSuccessPopupOpen();
              } else {
                console.error(`Error submitting PIN: ${pinStatus}`);
                cleanup();
                showErrorToast('Failed to create pin');
              }
            },
            (error) => {
              console.error('Error submitting PIN', error);
              cleanup();
              showErrorToast('Failed to create pin');
              setIsLoading(false);
            }
          );
        } else {
          console.error(`Error submitting cvv/expiration: ${status}`);
          cleanup();
          showErrorToast('Failed to activate card');
          setIsLoading(false);
        }
      },
      (error) => {
        console.error('Error submitting cvv/expiration', error);
        cleanup();
        showErrorToast('Failed to activate card');
        setIsLoading(false);
      }
    );
  };

  const environment =
    process.env.REACT_APP_NODE_ENV === 'production' ||
    process.env.REACT_APP_NODE_ENV === 'pre-prod' ||
    process.env.REACT_APP_NODE_ENV === 'hotfix'
      ? 'live'
      : 'sandbox';

  // TODO: Before native bank launch. New vault id for production
  const vaultId =
    process.env.REACT_APP_NODE_ENV === 'production' ||
    process.env.REACT_APP_NODE_ENV === 'pre-prod' ||
    process.env.REACT_APP_NODE_ENV === 'hotfix'
      ? 'tnt8w6nrmbu'
      : 'tntazhyknp1';

  useEffect(() => {
    const loadForm = async () => {
      const vgsCollect = await loadVGSCollect({
        vaultId,
        environment,
        version: '2.4.0',
      }).catch((e) => {
        console.log(e);
      });
      initForm(vgsCollect);
    };

    if (isSetPINPopupOpen && Object.keys(form).length === 0) loadForm();
  }, [isSetPINPopupOpen]);

  const setPINPopupContent = (
    <Stack spacing={2}>
      <Box>
        <Text {...titleStyles}>Lets Set the PIN for Your Debit Card</Text>
        <Text {...popupMessageStyles}>Please select a four-digit PIN for your debit card.</Text>

        <FormControl mt="24px">
          <FormLabel {...popupFormLabelStyles}>PIN</FormLabel>
          <span id="cc-pin" />
        </FormControl>
      </Box>
    </Stack>
  );

  const setPINPopupFooter = (
    <Stack direction="row" {...popupFooterContainerStyles}>
      <Spacer />
      <BaselaneButtonGroup size="md">
        <BaselaneButton variant="outline" palette="neutral" size="md" onClick={handleBackClick}>
          Back
        </BaselaneButton>
        <BaselaneButton
          id="activate-card-pin-submit"
          variant="filled"
          palette="primary"
          size="md"
          onClick={handleActivateClick}
          isDisabled={!isValid || isLoading}
        >
          Activate
        </BaselaneButton>
      </BaselaneButtonGroup>
    </Stack>
  );

  return (
    <BaselaneAlert
      showCloseButton
      isOpen={isSetPINPopupOpen}
      onClose={handleSetPINPopupClose}
      isCentered
      body={setPINPopupContent}
      footer={setPINPopupFooter}
      containerStyles={{ h: '456px' }}
      overlayStyles={{ bg: 'none' }}
      size="xl"
    />
  );
};

export default SetPINPopup;
