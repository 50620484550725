// @flow
// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=2925%3A97142
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Text, Stack } from '@chakra-ui/react';

import { useStatsigClient } from '@statsig/react-bindings';
import { BaselaneList, BaselaneListItem } from '@shared/components';
import { DYNAMIC_CONFIGS } from '@core/constants/statsigKeys';
import { getList } from './emptyStateInfo';
import {
  noMarginStyles,
  smLightTitleStyles,
  listStyles,
  listItemStyles,
} from '../styles/emptyState.shared.styles';

const EmptyStateFeatureList = ({
  isInDrawer = false,
  isMobile = false,
}: {
  isInDrawer: boolean,
  isMobile: boolean,
}) => {
  const { getDynamicConfig } = useStatsigClient();
  const config = getDynamicConfig(DYNAMIC_CONFIGS.APY_INTEREST);

  // Renders list of features on the right section via BaselaneList component
  const listItemRenderer = (item) => {
    const key = uuidv4();
    const iconLabel = <item.label />;

    return (
      <BaselaneListItem
        key={key}
        label={iconLabel}
        value={item.value}
        containerStyle={{ ...listItemStyles.containerStyle }}
        labelStyle={{ ...listItemStyles.labelStyle, top: isMobile ? '4px' : '0px' }}
        valueStyle={{ ...listItemStyles.valueStyle }}
      />
    );
  };

  const list = getList(isMobile, config?.get('apy'));

  return (
    <Stack {...noMarginStyles}>
      <Text
        display={isMobile ? 'none' : 'block'}
        {...smLightTitleStyles}
        ml={isInDrawer ? '38px' : '75px'}
        mb="16px"
      >
        Features
      </Text>
      <BaselaneList
        data={list}
        itemRenderer={listItemRenderer}
        listStyles={(!isInDrawer && !isMobile && { ...listStyles }) || {}}
      />
    </Stack>
  );
};

export default EmptyStateFeatureList;
