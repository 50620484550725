import React from 'react';
import { useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { Flex, Text, Box, HStack, Spacer, Stack } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import IconInfo from '@icons/manual/IconInfo';
import IconSkeletonBar1 from '@icons/manual/IconSkeletonBar1';
import {
  BaselaneBadge,
  BaselaneDivider,
  BaselaneList,
  BaselaneListItem,
  BaselaneTitle,
  BaselaneTooltip,
  EmptyText,
  CurrencyText,
} from '@shared/components';
import isNumber from '@core/utils/validators/isNumber';
import isNil from '@core/utils/validators/isNil';
import {
  listItemValueStyles,
  listItemContainerStyles,
  listItemLabelStyles,
} from '@shared/components/BaselaneList/styles/list.style';
import { KPI_QUERY } from '@core/apollo/queries';
import {
  getCoreMetricsList,
  getYTDResultsList,
  getAssetLabelTooltip,
} from './portfolioKPIs.helper';
import {
  portfolioMetricsWidget,
  currencyTextStyle,
  listItemStyles,
  containerStyles,
} from './styles/responsive.style';

function PortfolioKPIs() {
  const { loading, error, data } = useQuery(KPI_QUERY);
  if (loading) return <p>Loading...</p>;
  if (error && !data) return <p>Error...</p>;

  if (isNil(data?.kpi?.summary)) return <div>Error!</div>; // TODO: Till BE fixes the issue

  const [coreMetrics, YTDResults] = [getCoreMetricsList(data), getYTDResultsList(data)];
  const getLabel = (label) => {
    return (
      <Flex alignItems="center">
        <Text mr="10px">{label}</Text>
      </Flex>
    );
  };

  const emptyAmountBox = () => (
    <Box>
      <Box display={!isMobile ? 'block' : 'none'}>
        <EmptyText />
      </Box>
      <Box pt="8px" display={!isMobile ? 'none' : 'block'}>
        <IconSkeletonBar1 height="20" width="72" borderRadius="12px" />
      </Box>
    </Box>
  );

  const listItemRenderer = (item) => {
    const key = uuidv4();
    const formats = {
      rounded: 'isRounded',
      percentage: 'isPercentage',
    };
    const format = formats[item.format];
    const valueProps = {
      amount: item.value,
      [format]: true,
      ...listItemValueStyles,
    };
    const value = isNumber(item.value) ? (
      <CurrencyText {...valueProps} {...currencyTextStyle} />
    ) : (
      emptyAmountBox()
    );

    return (
      <BaselaneListItem
        key={key}
        label={getLabel(item.label)}
        value={value}
        containerStyle={containerStyles(isMobile)}
        {...listItemStyles}
      />
    );
  };

  const renderYTDResults = () => {
    return YTDResults.map((item) => {
      const Icon = item.value;
      return (
        <HStack key={item.label} px="24px" {...listItemContainerStyles}>
          <Box {...listItemLabelStyles}>{item.label}</Box>
          <Icon width={item?.width} />
        </HStack>
      );
    });
  };

  const YTDTitle = (
    <HStack>
      <Text>Core Metrics</Text>
      <Spacer />
      <BaselaneBadge
        type="master"
        color="blue"
        text="Coming Soon"
        fontStyles={{ textStyle: 'xs-heavy' }}
      />
    </HStack>
  );

  return (
    <Box {...portfolioMetricsWidget(isMobile)}>
      <Flex alignItems="center" mb="24px" display={!isMobile ? 'flex' : 'none'}>
        <BaselaneTitle title="Asset Value" titleStyles={{ pr: '8px', mb: '0' }} />
        <BaselaneTooltip label={getAssetLabelTooltip()} styles={{ w: '250px' }}>
          <IconInfo width="16" height="16" color="#052765" />
        </BaselaneTooltip>
      </Flex>

      <BaselaneList data={coreMetrics} itemRenderer={listItemRenderer} />
      <Box display={!isMobile ? 'block' : 'none'}>
        <BaselaneDivider />
        <BaselaneTitle title={YTDTitle} titleStyles={{ mb: '24px' }} />
        {/* <BaselaneList data={YTDResults} itemRenderer={listItemRenderer} />
        NOTE: Replace below code with the line above when you want to bring back dynamic YTD */}
        <Stack>{renderYTDResults()}</Stack>
      </Box>
    </Box>
  );
}

export default PortfolioKPIs;
