import {
  Icon16ClipboardCheck,
  Icon16DocumentUser,
  Icon16Dashboard,
  Icon16BankingAccount,
  Icon16Accounts,
  Icon16Dollar,
  Icon16Analytics,
  Icon16Property,
  Icon16Rent,
  Icon16Persons,
  Icon16LineChart,
  Icon16Security,
  Icon16Otherloandisbursementsreceived,
  Icon16Transfersbetweenaccounts,
  Icon16Partnership,
  Icon16Document,
} from '@icons/16px';

const icons = {
  get_started: Icon16ClipboardCheck,
  dashboard: Icon16Dashboard,
  banks_cards: Icon16BankingAccount,
  external_accounts: Icon16Accounts,
  transactions: Icon16Dollar,
  analytics_reporting: Icon16Analytics,
  properties: Icon16Property,
  leases: Icon16Rent,
  tenants: Icon16Persons,
  tenant_screening: Icon16DocumentUser,
  property_analytics: Icon16LineChart,
  insurance: Icon16Security,
  loans: Icon16Otherloandisbursementsreceived,
  transfers: Icon16Transfersbetweenaccounts,
  partners: Icon16Partnership,
  lease_documents: Icon16Document,
};

export default icons;
