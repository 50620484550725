import React, { ReactNode } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useBreakPoints from '@core/hooks/useBreakPoints';
import T1 from '@shared/components/BaselaneDropdown-new/T1';
import {
  TENANT_SCREENING,
  TENANT_SCREENING_APPLICATION,
  TENANT_SCREENING_REPORTS_SUMMARY,
  TENANT_SCREENING_APPLICANT,
} from '@core/constants/routes';
import TenantScreeningApplicantDisplayInput from './TenantScreeningApplicantDisplayInput';
import TenantScreeningApplicantOption from './TenantScreeningApplicantOption';

type ApplicantDropdownProps = {
  id?: string,
  applicantOptions?: Array<any>,
  selectedApplicant?: any,
  verificationId?: string,
};

const ApplicantDropdown = ({
  id = 'applicant-dropdown',
  applicantOptions = [],
  selectedApplicant,
  ...rest
}: ApplicantDropdownProps): ReactNode => {
  const { isMin768 } = useBreakPoints();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { screeningId, applicationId } = useParams() ?? {};

  const applicantItemRenderer = ({ item = {} }) => {
    const { name = '', type = '' } = item;
    return <TenantScreeningApplicantOption applicantName={name} type={type} />;
  };

  const handleApplicantSelect = (
    selectedApplicantId = '',
    selectedOption = {},
    selectedApplicationId = ''
  ) => {
    if (!screeningId || !applicationId) return;

    if (selectedApplicantId === 'summary') {
      navigate(
        `/${TENANT_SCREENING}/${screeningId}/${TENANT_SCREENING_APPLICATION}/${applicationId}/${TENANT_SCREENING_REPORTS_SUMMARY}`
      );
      return;
    }

    if (
      !pathname.includes(TENANT_SCREENING_APPLICANT) &&
      !pathname.includes(TENANT_SCREENING_REPORTS_SUMMARY)
    ) {
      return;
    }

    const selectedApplicantPage = `/${TENANT_SCREENING}/${screeningId}/${TENANT_SCREENING_APPLICATION}/${applicationId}/${TENANT_SCREENING_APPLICANT}/${selectedApplicantId}`;
    navigate({ pathname: selectedApplicantPage });
  };

  return (
    <T1
      id={id}
      classNames={['new', 'auto-width-dropdown', 'is-full-width', 'auto-height', 'input-form-lg']}
      data={applicantOptions}
      itemRenderer={applicantItemRenderer}
      handleSubmit={(value) => handleApplicantSelect(value)}
      onClearClick={() => {}}
      hideSearch
      showValueByFields={['name', 'type']}
      selectedItem={selectedApplicant}
      CustomDisplayInput={TenantScreeningApplicantDisplayInput}
      isMobile={!isMin768}
      {...rest}
    />
  );
};

export default ApplicantDropdown;
