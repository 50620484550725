import {
  CSV_IMPORT,
  CSV_IMPORT_MAP_CATEGORIES,
  CSV_IMPORT_PREVIEW,
  CSV_IMPORT_REVIEW_COLUMNS,
  CSV_IMPORT_MAP_PROPERTIES,
  CSV_IMPORT_UPLOAD,
} from '@routes';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

export const getCurrentTabIndex = (location) => {
  const pagePath = location.pathname.split(`/${CSV_IMPORT}/`).pop();
  const index = indexToPathMapper.indexOf(pagePath);
  return index === -1 ? 0 : index;
};

export const sendCSVSegmentEvent = (currentTabIndex) => {
  switch (currentTabIndex) {
    case 0:
      return sendSegmentEvent('temp_csv_import_upload_file_continue');
    case 1:
      return sendSegmentEvent('temp_csv_import_map_columns_continue');
    case 2:
      return sendSegmentEvent('temp_csv_import_map_categories_continue');
    case 3:
      return sendSegmentEvent('temp_csv_import_map_properties_continue');
    default:
      return null;
  }
};

export const indexToPathMapper = [
  CSV_IMPORT_UPLOAD,
  CSV_IMPORT_REVIEW_COLUMNS,
  CSV_IMPORT_MAP_CATEGORIES,
  CSV_IMPORT_MAP_PROPERTIES,
  CSV_IMPORT_PREVIEW,
];

export const initialCSVTabList = [
  {
    id: 0,
    key: 'upload',
    label: 'Upload CSV',
    isValid: false,
    isDisabled: false,
    isOptional: false,
  },
  {
    id: 1,
    label: 'Review columns',
    key: 'review',
    isValid: false,
    isDisabled: false,
    isOptional: false,
  },
  {
    id: 2,
    key: 'category',
    label: 'Map categories (optional)',
    isValid: false,
    isDisabled: true,
    isOptional: true,
  },
  {
    id: 3,
    key: 'property',
    label: 'Map properties (optional)',
    isValid: false,
    isDisabled: true,
    isOptional: true,
  },
  {
    id: 4,
    label: 'Preview',
    key: 'preview',
    isValid: false,
    isDisabled: false,
    isOptional: false,
  },
];

// Formik helpers
export const initialCSVImportValues = Object.freeze({
  account: { id: 'manual', bankName: 'Manually Added', account: 'Manually Added' },
  CSVFile: null,
  CSVFileData: [],
  CSVHeaderMapping: {},
  mappedCategories: null,
  mappedProperties: null,
  propertyListFromCSV: [],
  categoryListFromCSV: [],
});

// Normalizes whitespace (multiple space characters are replaced with a single on)
// also trims whitespace off the ends, for the purpose of string matching
export const trimAndNormalizeWhitespace = (originalString) => {
  let modified = originalString.trim();
  modified = modified.split(' ').reduce((finalString, currentPiece) => {
    const finalPiece = currentPiece.trim().length > 0 ? ` ${currentPiece.trim()}` : ``;
    return `${finalString}${finalPiece}`;
  }, '');
  return modified;
};
