import React from 'react';
import { useStatsigClient } from '@statsig/react-bindings';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import TransferFundsDrawer from './TransferFundsDrawer';
import TransferFunds from '../TransferFunds';
/**
 * Component that renders the TransferFundsDrawer if the "recurring_scheduled_transfers_gate" is enabled, otherwise renders the TransferFunds component.
 * @returns {JSX.Element}
 */
const TransferFundsFeatureGuard = () => {
  const { checkGate } = useStatsigClient();

  return checkGate(FEATURE_GATES.RECURRING_SCHEDULED_TRANSFERS_GATE) ? (
    <TransferFundsDrawer />
  ) : (
    <TransferFunds />
  );
};

export default TransferFundsFeatureGuard;
