import React, { useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Heading, ChakraProvider, Text, useToast } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { BaselaneButton, BaselaneDivider, BaselaneDrawer } from '@shared/components';
import { Icon16Check } from '@icons/16px';
import useBreakPoints from '@core/hooks/useBreakPoints';
import onDrawerClose from '@core/utils/onDrawerClose';
import habitatTheme from '@core/themeHabitat';
import { ADD_PAYEE } from '@core/apollo/queries';
import PaymentMethodStack from '../components/PaymentMethodStack';
import RecipientDetailsFields from '../components/fields/RecipientDetailsFields';

import {
  hasAPaymentMethod,
  recipientValidation,
  completePaymentMethods,
} from '../helpers/validation.helpers';

import { restructureForQuery, prefillName, addPaymentTypeFields } from '../helpers/content.helpers';

type AddRecipientProps = {
  from?: string,
};

const AddRecipient = ({ from }: AddRecipientProps) => {
  const initialPaymentMethods = {
    achPaymentMethods: null,
    wirePaymentMethods: null,
    checkPaymentMethods: null,
  };

  const initialValues = { name: '', email: '' };

  const { isMax576 } = useBreakPoints();

  const navigate = useNavigate();

  const [paymentMethods, setPaymentMethods] = useState(initialPaymentMethods);

  const formikRef = useRef();

  const [addPayee, { loading: isRecipientSaving }] = useMutation(ADD_PAYEE);

  const toast = useToast();

  const showAddRecipientErrorToast = (message) =>
    toast({
      position: 'bottom-left',
      description: 'Error saving new recipient.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const saveRecipient = (values, { setErrors }) => {
    const fullPaymentMethods = completePaymentMethods(paymentMethods);

    const queryFormattedCompletePaymentMethods = addPaymentTypeFields(
      restructureForQuery(fullPaymentMethods)
    );

    const payee = {
      ...values,
      paymentMethods: queryFormattedCompletePaymentMethods,
    };

    addPayee({
      variables: {
        input: payee,
      },
    })
      .then((res) => {
        // close drawer with "success" state
        if (!res?.errors) {
          navigate(
            {
              pathname: `${from}`,
            },
            {
              replace: true,
              state: {
                isAddRecipientSuccessful: true,
              },
            }
          );
        } else if (res.errors[0].message.includes('already exists.')) {
          // show this error in the name field
          setErrors({
            name: `Recipient already exists, please use a different name.`,
          });
          // generic error toast- TODO: this may need to be displayed differently
          showAddRecipientErrorToast(res.errors[0].message);
        }
      })
      .catch((err) => {
        const { message } = err;
        if (message.includes('already exists.')) {
          // show this error in the name field
          setErrors({
            name: `Recipient already exists, please use a different name.`,
          });
          showAddRecipientErrorToast('Recipient already exists, please use a different name.');
        }
        // generic error toast- TODO: this may need to be displayed differently
        else {
          showAddRecipientErrorToast(message);
        }
      });
  };

  return (
    <ChakraProvider theme={habitatTheme}>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validateOnChange
        validateOnBlur
        validate={recipientValidation}
        onSubmit={saveRecipient}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
          errors,
          touched,
          dirty,
          isSubmitting,
          isValid,
        }) => (
          <Form>
            <BaselaneDrawer
              isOpen
              size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
              title="Add Recipient"
              footer={
                <>
                  <BaselaneButton
                    size="md"
                    variant="outline"
                    palette="neutral"
                    onClick={() => onDrawerClose(navigate, from)}
                  >
                    Cancel
                  </BaselaneButton>
                  <BaselaneButton
                    size="md"
                    variant="filled"
                    palette="primary"
                    onClick={handleSubmit}
                    w="100%"
                    ml={1.5}
                    isDisabled={
                      !isValid || !dirty || !hasAPaymentMethod(paymentMethods) || isRecipientSaving
                    }
                    leftIcon={<Icon16Check />}
                  >
                    Save recipient
                  </BaselaneButton>
                </>
              }
              closeEvent={() => onDrawerClose(navigate, from)}
              onOverlayClick={() => onDrawerClose(navigate, from)}
              closeOnOverlayClick={false}
              newDesignDrawer
            >
              <Heading as="h3" size="headline-md" fontWeight="medium" mb={3}>
                Recipient details
              </Heading>
              <RecipientDetailsFields
                prefillName={(name) => prefillName(name, setPaymentMethods)}
              />
              <BaselaneDivider styles={{ my: 3 }} />

              <Heading as="h3" size="headline-md" fontWeight="medium">
                Payment details
              </Heading>
              <Text textStyle="sm" mb={2}>
                Add one or more payment methods.
              </Text>
              <PaymentMethodStack
                paymentMethods={paymentMethods}
                setPaymentMethods={setPaymentMethods}
              />
            </BaselaneDrawer>
          </Form>
        )}
      </Formik>
      <Outlet />
    </ChakraProvider>
  );
};

AddRecipient.defaultProps = {
  from: null,
};

export default AddRecipient;
