/* eslint-disable react/prop-types */
import React from 'react';
import { Text, Tbody } from '@chakra-ui/react';
import useBreakPoints from '@core/hooks/useBreakPoints';
import {
  BaselaneCardStack,
  BaselaneResponsiveCell,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
} from '@shared/components';
import { formatDate } from '@core/utils/formatDate';
import ReportHeader from '../../components/ReportHeader';
import { isFieldNotObject } from '../../helpers/tenantScreening.helpers';

const ConsumerStatements = ({ consumerStatements = [] }) => {
  const { isMax767 } = useBreakPoints();
  const tableColumnConfig = {
    columns: [
      {
        isSortable: false,
        styles: {
          w: isMax767 ? '150px' : '320px',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
      {
        isSortable: false,
        styles: {
          w: '100%',
          verticalAlign: 'top',
          contentContainerStyles: {
            px: 1,
            py: 1,
          },
        },
      },
    ],
  };

  if (!consumerStatements.length) {
    return (
      <BaselaneCardStack
        id="no-consumer-statement-data-card"
        size="md"
        width="100%"
        items={[
          {
            id: 'header',
            content: <ReportHeader>Consumer statement</ReportHeader>,
          },
          {
            id: 'no-consumer-statement',
            content: <Text textStyle="sm">No data available</Text>,
          },
        ]}
      />
    );
  }

  return (
    <>
      {consumerStatements.map((statement, index) => (
        <BaselaneCardStack
          id={`consumer-statement-card-${statement?.statement}`}
          key={`consumer-statement-card-${statement?.statement}`}
          size="md"
          items={[
            {
              id: 'header',
              content: <ReportHeader>Consumer statement</ReportHeader>,
            },
            {
              id: 'consumer-statement-card-body',
              content: (
                <BaselaneResponsiveTable
                  id="review-columns"
                  config={tableColumnConfig}
                  maxW="600px"
                  tableContainerStyles={{
                    overflowY: 'initial',
                    overflowX: 'initial',
                  }}
                >
                  <Tbody>
                    <BaselaneResponsiveTableRows
                      items={[
                        {
                          heading: 'Statement',
                          data: statement?.statement ?? '--',
                        },
                        {
                          heading: 'Date reported',
                          data: statement?.date_reported
                            ? formatDate(statement?.date_reported, 'YYYY-MM-DD')
                            : '--',
                        },
                        {
                          heading: 'Date purged',
                          data: statement?.date_purged
                            ? formatDate(statement?.date_purged, 'YYYY-MM-DD')
                            : '--',
                        },
                      ]}
                      customRow
                      renderItem={(row) => {
                        return (
                          <BaselaneResponsiveTableRow
                            key={row.heading}
                            chevronCellStyles={{ w: 0 }}
                            contentContainerStyles={{ border: 'none' }}
                          >
                            <BaselaneResponsiveCell configIndex={0} p={0}>
                              <Text as="span" textStyle="sm" color="brand.neutral.700">
                                {row.heading}
                              </Text>
                            </BaselaneResponsiveCell>
                            <BaselaneResponsiveCell configIndex={1} p={0}>
                              <Text textStyle="sm">
                                {' '}
                                {isFieldNotObject(row.data) ? row.data : '--'}
                              </Text>
                            </BaselaneResponsiveCell>
                          </BaselaneResponsiveTableRow>
                        );
                      }}
                    />
                  </Tbody>
                </BaselaneResponsiveTable>
              ),
            },
          ]}
        />
      ))}
    </>
  );
};

export default ConsumerStatements;
