import { React } from 'react';
import { useFormikContext } from 'formik';
import { FormControl } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { useStatsigClient } from '@statsig/react-bindings';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import formatCurrency from '@core/utils/formatCurrency';
import useBreakPoints from '@core/hooks/useBreakPoints';
import stripCurrency from '@core/utils/stripCurrency';

import {
  BaselaneFormLabel,
  BaselaneFormErrorMessage,
  BaselaneInput,
  T1WithTitleDropdown,
  BaselaneFormHelperText,
  BaselaneDivider,
} from '@shared/components';
import DropDownDisplayInputWithOutRightButton from '@shared/components/BaselaneDropdown-new/DisplayInputVariations/DropDownDisplayInputWithOutRightButton';
import { renderAccountDropdownItemWithBalance } from '@shared/components/TransferFunds/helpers';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import SendOnDatepicker from '@core/components/Shared/components/TransferFundsDrawer/steps/transfer-schedule/SendOnDatepicker';
import { currencyMask, isFutureDate, isSameDay } from '../../../helpers/formHelpers';

import RecurringPayment from './recurring-payment/RecurringPayment';
import ScheduledPaymentTypeToggle from './ScheduledPaymentTypeToggle';

const PaymentDetails = () => {
  const formik = useFormikContext();

  const { isMax576 } = useBreakPoints();
  const sharedDropdownProps = {
    placeholder: 'Select',
    title: 'Account',
    searchTerm: ['name', 'nickName'],
    showValueByFields: ['name', 'nickName'],
    itemRenderer: renderAccountDropdownItemWithBalance,
    isMulti: false,
    hasFilterWrapper: false,
    hasCheckboxes: false,
    showDivider: false,
    CustomDisplayInput: DropDownDisplayInputWithOutRightButton,
    parentId: 'drawer-body',
    isMobile: isMax576,
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
  } = formik;

  const { bankAccountsById, formattedBaselaneAcc } = useBankAccountsActive({
    filterBySubType: ['checking'],
  });

  const selectedFromAccObj = bankAccountsById?.[values?.fromTransferAccountId] || {};
  const isCheckPayment = values?.paymentMethodType === 'CHECK_PAYMENT';
  const isAch = values?.paymentMethodType === 'ACH';
  const insufficientBalance =
    stripCurrency(values?.amount) > selectedFromAccObj?.availableBalance && selectedFromAccObj;

  const { checkPaymentDailyLimit, checkPaymentMonthlyLimit, dailyCreditLimit, monthlyCreditLimit } =
    selectedFromAccObj?.limits || {};

  const showOneTime = values.scheduledPaymentType === 'one-time';
  const showRecurring = values.scheduledPaymentType === 'recurring';

  // Statsig gates
  const { checkGate } = useStatsigClient();

  const allowScheduledCheck =
    ((isFutureDate(values?.oneTimeTransfer.date) && values?.scheduledPaymentType === 'one-time') ||
      (values?.scheduledPaymentType === 'recurring' &&
        isFutureDate(values?.recurringPayment?.startDate))) &&
    stripCurrency(selectedFromAccObj?.availableBalance) < 2 &&
    isCheckPayment;

  const isScheduledSameDay =
    (isSameDay(values?.oneTimeTransfer.date) && values?.scheduledPaymentType === 'one-time') ||
    (values?.scheduledPaymentType === 'recurring' &&
      isSameDay(values?.recurringPayment?.startDate));

  const getSelectedAccount = () => {
    return bankAccountsById?.[values?.fromTransferAccountId] || {};
  };

  return (
    <>
      {/* Amount */}
      <FormControl
        id="transfer-funds-amount"
        isInvalid={errors.amount && touched.amount}
        isRequired
      >
        <BaselaneFormLabel
          textStyle="sm"
          htmlFor="amount"
          tooltipText={
            isCheckPayment
              ? 'The amount will be deducted from your account when the recipient deposits the check.'
              : null
          }
        >
          Amount
        </BaselaneFormLabel>
        <BaselaneInput
          id="amount"
          name="amount"
          value={values.amount}
          size="lg"
          prefix="$"
          as={MaskedInput}
          mask={currencyMask}
          onChange={(e) => {
            setFieldTouched('amount');
            handleChange(e);
          }}
          isInvalid={errors.amount && touched.amount}
          onBlur={handleBlur}
        />
        <BaselaneFormErrorMessage isInvalid={errors.amount && touched.amount}>
          {errors.amount}
        </BaselaneFormErrorMessage>
      </FormControl>
      {/* Send From */}

      <FormControl
        id="send-funds-transfer-from-account"
        isInvalid={errors.fromTransferAccountId && touched.fromTransferAccountId}
        isRequired
      >
        <BaselaneFormLabel
          textStyle="sm"
          htmlFor="fromTransferAccountId"
          tooltipText={
            isCheckPayment
              ? 'Each account has daily and monthly limits for check payments. These limits will be verified when the check is deposited.'
              : null
          }
        >
          Send from
        </BaselaneFormLabel>
        <T1WithTitleDropdown
          {...{
            hideClearButton: true,
            hasError: errors.fromTransferAccountId && touched.fromTransferAccountId,
            name: 'fromTransferAccountId',
            additionalProps: { id: 'send-funds-send-from-dropdown' },
            classNames: [
              'input-form-lg',
              'auto-select-input-width',
              ...(errors.fromTransferAccountId && touched.fromTransferAccountId
                ? ['input-invalid']
                : []),
            ],
            data: formattedBaselaneAcc,
            selectedItem: values?.fromTransferAccountId ? getSelectedAccount() : '',
            handleSubmit: (selectedValue) => {
              setFieldTouched('fromTransferAccountId', true);
              setFieldValue(
                'fromTransferAccountId',
                Array.isArray(selectedValue) ? '' : selectedValue
              );
            },
            ...sharedDropdownProps,
          }}
        />

        {!errors.fromTransferAccountId && touched.fromTransferAccountId && (
          <>
            {((isCheckPayment && insufficientBalance && selectedFromAccObj) ||
              allowScheduledCheck) && (
              <BaselaneFormHelperText type="warning">
                Insufficient balance to cover the check amount and $2 processing fee. Make sure you
                have sufficient balance when the check is deposited.
              </BaselaneFormHelperText>
            )}
            {isCheckPayment && selectedFromAccObj && !insufficientBalance && (
              <BaselaneFormHelperText>{`Daily limit: ${
                formatCurrency(checkPaymentDailyLimit).inDollars
              } · Monthly limit: ${
                formatCurrency(checkPaymentMonthlyLimit).inDollars
              }`}</BaselaneFormHelperText>
            )}
            {isAch && selectedFromAccObj && isScheduledSameDay && (
              <BaselaneFormHelperText>{`Daily limit: ${
                formatCurrency(dailyCreditLimit).inDollars
              } · Monthly limit: ${
                formatCurrency(monthlyCreditLimit).inDollars
              }`}</BaselaneFormHelperText>
            )}
            {/* SCHEDULED payment msgs for one time */}
            {showOneTime &&
              checkGate(FEATURE_GATES.ONE_TIME_SCHEDULED_PAYMENT_GATE) &&
              insufficientBalance &&
              selectedFromAccObj &&
              isFutureDate(values?.oneTimeTransfer.date) &&
              !isCheckPayment && (
                <BaselaneFormHelperText type="warning">
                  Make sure the account has sufficient balance before the payment date.
                </BaselaneFormHelperText>
              )}
            {/* SCHEDULED payment msgs for recurring */}
            {checkGate(FEATURE_GATES.RECURRING_SCHEDULED_PAYMENT_GATE) &&
              showRecurring &&
              insufficientBalance &&
              selectedFromAccObj &&
              isFutureDate(values?.recurringPayment?.startDate) &&
              !isCheckPayment && (
                <BaselaneFormHelperText type="warning">
                  Make sure the account has sufficient balance before the payment date.
                </BaselaneFormHelperText>
              )}
          </>
        )}
        <BaselaneFormErrorMessage
          isInvalid={errors.fromTransferAccountId && touched.fromTransferAccountId}
        >
          {errors.fromTransferAccountId}
        </BaselaneFormErrorMessage>
      </FormControl>
      {checkGate(FEATURE_GATES.RECURRING_SCHEDULED_PAYMENT_GATE) && (
        <>
          <BaselaneDivider styles={{ my: 2 }} />
          <ScheduledPaymentTypeToggle />
        </>
      )}
      {showOneTime && checkGate(FEATURE_GATES.ONE_TIME_SCHEDULED_PAYMENT_GATE) && (
        <SendOnDatepicker />
      )}
      {showRecurring && checkGate(FEATURE_GATES.RECURRING_SCHEDULED_PAYMENT_GATE) && (
        <RecurringPayment />
      )}
    </>
  );
};

export default PaymentDetails;
