import { IconCompleted, IconProcessing, IconXCircle } from '@icons';

import IconUploadCircle from '@icons/manual/IconUploadCircle';

const STATUS_DESCRIPTION =
  'To collect rent through Baselane, we require you to verify your identity.';

const VERIFIED_STATUS_DESCRIPTION = 'Your account is enabled to collect rent.';

export const KYC_STATUS = Object.freeze({
  UNVERIFIED: {
    description: STATUS_DESCRIPTION,
  },
  APPROVED: {
    description: VERIFIED_STATUS_DESCRIPTION,
    icon: IconCompleted,
    badgeText: 'Verified',
    badgeTextColor: 'green.800AA',
  },
  PENDING: {
    description: STATUS_DESCRIPTION,
    icon: IconProcessing,
    badgeText: 'Processing',
    badgeTextColor: 'brand.blue.700',
    showLearnMoreButton: true,
  },
  DENIED: {
    description: STATUS_DESCRIPTION,
    icon: IconXCircle,
    badgeText: 'Rejected',
    badgeTextColor: 'red.800AA',
    showLearnMoreButton: true,
  },
  ADDITIONAL_INFO: {
    description: STATUS_DESCRIPTION,
    icon: IconUploadCircle,
    badgeText: 'Additional Information Required',
    badgeTextColor: 'brand.blue.700',
  },
  VOUCH_PENDING: {
    description: STATUS_DESCRIPTION,
    icon: IconUploadCircle,
    badgeText: 'Pending ID',
    badgeTextColor: 'brand.blue.700',
    showLearnMoreButton: false,
  },
});
