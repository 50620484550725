// @flow
import React from 'react';
import { HStack, ListItem, Text } from '@chakra-ui/react';

import { Reorder, useDragControls } from 'framer-motion';
import DragHandle from './DragHandle';
import { listItemStyles } from './styles/sortableList.styles';

type BaselaneSortableListItemProps = {
  isHandleDrag: Boolean,
  item: any,
  onTransitionEnd?: Function,
};

function BaselaneSortableListItem({
  isHandleDrag,
  item,
  onTransitionEnd = () => {},
  children,
  ...rest
}: PropsWithChildren<BaselaneSortableListItemProps>): any {
  const dragControls = useDragControls();
  /**
   * TODO: In the final component, we need to move these
   * styles and variants into the theme files and use
   * the appropriate functions to bring them in here.
   */

  const variants = {
    notDragging: {
      zIndex: 0,
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0)',
      cursor: isHandleDrag ? 'default !important' : 'grab !important',
    },
    dragging: {
      zIndex: 1,
      cursor: 'grabbing',
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
    },
  };

  return (
    <ListItem
      as={Reorder.Item}
      value={item}
      variants={variants}
      initial="notDragging"
      whileDrag="dragging"
      dragControls={dragControls}
      dragTransition={{ bounceStiffness: 600, onComplete: onTransitionEnd }}
      {...(isHandleDrag && { dragListener: false })}
      {...listItemStyles}
      {...{ cursor: isHandleDrag ? 'default' : 'grab' }}
      {...rest}
    >
      <HStack gap={2}>
        <DragHandle isHandleDrag={isHandleDrag} dragControls={dragControls} />
        <HStack w="full">{children || <Text>{item.content}</Text>}</HStack>
      </HStack>
    </ListItem>
  );
}

export default BaselaneSortableListItem;
