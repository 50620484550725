import React, { useEffect, useState } from 'react';
import { Box, Skeleton } from '@chakra-ui/react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { TENANT_SCREENING_CREDIT_REPORT } from '@core/constants/routes';
import { getReportByid, isReportDataInvalid } from '../../helpers/tenantScreeningReports.helpers';
import ReportUnavailableAlert from '../../components/ReportUnavailableAlert';
import Scores from './Scores';
import CreditScore from './CreditScore';
import AccountSummary from './AccountSummary';
import ConsumerInformation from './ConsumerInformation';
import Bankruptcy from './Bankruptcy';
import AccountsOverview from './AccountsOverview';
import EmploymentInformation from './EmploymentInformation';
import AlertsAndTriggers from './AlertsAndTriggers';
import ConsumerStatements from './ConsumerStatement';
import CollectionsSummary from './CollectionsSummary';
import ThirdPartyCollections from './ThirdPartyCollections';
import CreditInquiries from './CreditInquiries';
import HistoricalConsumerInformation from './HistoricalConsumerInformation';
import PendingEmptyState from '../PendingEmptyState';

const CreditReport = () => {
  const { verificationId, externalApplicantId } = useOutletContext();
  const { applicationId } = useParams();
  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (applicationId && externalApplicantId && verificationId) {
      getReportByid(applicationId, externalApplicantId, verificationId)
        .then(({ data }) => {
          if (data?.name === TENANT_SCREENING_CREDIT_REPORT) setReportData(data);
        })
        .catch((error) => {
          console.error('Error fetching application:', error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [applicationId, externalApplicantId, verificationId]);

  if (reportData?.status === 'pending' || isReportDataInvalid(reportData, verificationId)) {
    return reportData?.status === 'pending' ? (
      <PendingEmptyState />
    ) : (
      <ReportUnavailableAlert isLoading={isLoading} />
    );
  }

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={4}>
        <Skeleton isLoaded={reportData} width="100%">
          <Scores
            creditScore={reportData?.report?.score}
            pastDueAccounts={reportData?.report?.past_due_accounts?.length}
            balancePastDue={reportData?.report?.balance_past_due_amount}
            balanceOfCollections={reportData?.report?.collections?.reduce(
              (acc, collection) => acc + (collection?.balance ? collection.balance.cents / 100 : 0),
              0
            )}
            bankruptcy={reportData?.report?.bankruptcy_details?.length}
            debtBalance={reportData?.report?.account_overview?.reduce(
              (acc, account) => acc + (account?.balance ? account.balance.cents / 100 : 0),
              0
            )}
          />
        </Skeleton>
        <Skeleton isLoaded={reportData} width="100%">
          <CreditScore creditScore={reportData?.report?.score} />
        </Skeleton>
        <Skeleton isLoaded={reportData} width="100%">
          <AccountSummary
            accounts={reportData?.report?.account_overview}
            collections={reportData?.report?.collections}
            bankruptcyDetails={reportData?.report?.bankruptcy_details}
          />
        </Skeleton>
        <Skeleton isLoaded={reportData} width="100%">
          <ConsumerInformation
            personalData={reportData?.report?.personal_data}
            consumerInformation={reportData?.report?.consumer_information}
          />
        </Skeleton>
        <Skeleton isLoaded={reportData} width="100%">
          <Bankruptcy bankruptcyDetails={reportData?.report?.bankruptcy_details} />
        </Skeleton>
        <Skeleton isLoaded={reportData} width="100%">
          <AccountsOverview accountOverview={reportData?.report?.account_overview} />
        </Skeleton>
        <Skeleton isLoaded={reportData} width="100%">
          <EmploymentInformation
            employmentInformation={reportData?.report?.employment_information}
          />
        </Skeleton>
        <Skeleton isLoaded={reportData} width="100%">
          <AlertsAndTriggers alertsAndTriggers={reportData?.report?.alerts_and_triggers} />
        </Skeleton>
        <Skeleton isLoaded={reportData} width="100%">
          <ConsumerStatements consumerStatements={reportData?.report?.consumer_statements} />
        </Skeleton>
        <Skeleton isLoaded={reportData} width="100%">
          <CollectionsSummary collections={reportData?.report?.collections} />
        </Skeleton>
        <Skeleton isLoaded={reportData} width="100%">
          <ThirdPartyCollections collections={reportData?.report?.collections} />
        </Skeleton>
        <Skeleton isLoaded={reportData} width="100%">
          <CreditInquiries creditInquiries={reportData?.report?.credit_inquiries} />
        </Skeleton>
        <Skeleton isLoaded={reportData} width="100%">
          <HistoricalConsumerInformation
            historicalConsumerInformation={reportData?.report?.historical_consumer_information}
          />
        </Skeleton>
      </Box>
      <Outlet />
    </>
  );
};

export default CreditReport;
