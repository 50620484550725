import stripCurrency from '@core/utils/stripCurrency';

export const buildAutoTagRuleInput = (values) => {
  const conditions = [];

  if (values.isDescriptionOpen) {
    const openButNoValues = values.textValues.length === 0 && !values.text;

    if (!openButNoValues) {
      conditions.push({
        type: 'TEXT',
        field: 'TEXT_SEARCH',
        textOperator: values.textOperator.operator,
        textValues: values.text
          ? [...values.textValues.map(({ text }) => text), values.text]
          : values.textValues.map(({ text }) => text),
        matchType: values.matchType?.operator || 'OR',
      });
    }
  }

  if (values.isAccountCardOpen) {
    const openButNoValues = values.accountCard === 'ACCOUNT' ? !values.accountId : !values.cardId;

    if (!openButNoValues) {
      conditions.push({
        type: 'NUMERIC',
        field: values.accountCard === 'ACCOUNT' ? 'BANK_ACCOUNT_ID' : 'VIRTUAL_CARD_ID',
        value: values.accountCard === 'ACCOUNT' ? Number(values.accountId) : Number(values.cardId),
        operator: 'EQUALS',
      });
    }
  }

  if (values.isAmountOpen) {
    const openButNoValues =
      !values.amountValue ||
      (values.amountOperator.operator === 'BETWEEN' &&
        !values.amountValue &&
        !values.amountSecondaryValue);

    if (!openButNoValues) {
      conditions.push({
        type: 'NUMERIC',
        field: 'AMOUNT',
        operator: values.amountOperator.operator,
        value:
          values.moneyType === 'money-in'
            ? stripCurrency(values.amountValue)
            : -stripCurrency(values.amountValue),
        ...(values.amountOperator.operator === 'BETWEEN' && {
          secondaryValue:
            values.moneyType === 'money-in'
              ? stripCurrency(values.amountSecondaryValue)
              : -stripCurrency(values.amountSecondaryValue),
        }),
      });
    }
  }

  return {
    input: {
      conditions,
      action: {
        ...(!!values.isCategoryOpen && { tagId: values?.categoryId }),
        ...(!!values.isPropertyOpen && {
          propertyId: values.propertyId.includes('-')
            ? values.propertyId.split('-')[0]
            : values.propertyId,
          unitId: values.propertyId.includes('-') ? values.propertyId.split('-')[1] : null,
        }),
      },
      applyToTaggedTransactions: values.updateExistingTransactions,
    },
  };
};
