import React, { useEffect, useState } from 'react';
import { Checkbox, Skeleton, Text, useToast, VStack } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { BaselaneDrawer, BaselaneDivider, BaselaneSpinner } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';

import { GET_TENANT_SCREENINGS } from '@core/apollo/queries';
import { UNIFIED_RENT_COLLECTION } from '@core/constants/routes';

import {
  GET_TENANT_SCREENING_BY_ID,
  PROCESS_APPLICATION_DECISION,
} from '@core/apollo/queries/tenantScreening';
import { APPLICATION_ACTION } from '../../helpers/tenantScreeningDecision.helpers';

import Footer from './Footer';
import { REPORT_STATUS_KEYS } from '../../helpers/tenantScreeningDetail.helpers';
import ApproveSuccess from './ApproveSuccess';
import ApplicationDetails from './ApplicationDetails';
import useProperties from '../../hooks/useProperties';

const Approve = () => {
  const { isMax576 } = useBreakPoints();
  const navigate = useNavigate();

  const [stopAcceptingApplicants, setStopAcceptingApplicants] = useState(true);
  const [isApproved, setIsApproved] = useState(false);
  const [unitId, setUnitId] = useState(null);

  const params = useParams();
  const { screeningId, applicantId, applicationId } = params;

  const [processApplicationDecision, { loading: loadingApproval }] = useMutation(
    PROCESS_APPLICATION_DECISION,
    {
      refetchQueries: [
        {
          query: GET_TENANT_SCREENINGS,
          variables: { input: { applicationLinkActive: null } },
        },
        {
          fetchPolicy: 'network-only',
          query: GET_TENANT_SCREENING_BY_ID,
          variables: { id: Number(screeningId) },
        },
      ],
    }
  );

  const { data: screeningData, loading } = useQuery(GET_TENANT_SCREENING_BY_ID, {
    fetchPolicy: 'cache-first',
    variables: { id: Number(screeningId), input: {} },
  });

  const {
    getTenantScreeningById: { applications, propertyName, unitName },
  } = screeningData || { getTenantScreeningById: {} };

  const application = applications?.find((app) => app.id === applicationId);

  const { applicants } = application || {};

  const applicant = applicants?.find((applicantItem) => applicantItem?.id === applicantId);

  const { data: propertyData, getUnitIdFromScreeningId } = useProperties();

  const toast = useToast();

  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Something went wrong. Please try again.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const RCLink = `${UNIFIED_RENT_COLLECTION}${unitId && `?unit=${unitId}`}`;

  const renderFooter = () => {
    return !isApproved ? (
      <Footer
        previousLabel="Cancel"
        onPrevious={() => navigate('..')}
        nextLabel="Approve"
        onNext={loadingApproval ? null : handleApprove}
      />
    ) : null;
  };

  useEffect(() => {
    if (propertyData) {
      const properties = propertyData?.property ?? [];
      const currentUnitId = getUnitIdFromScreeningId(screeningId, properties);
      if (currentUnitId) {
        setUnitId(currentUnitId);
      }
    }
  }, [propertyData]);

  // generate display name
  useEffect(() => {
    if (application?.applicationStatus === REPORT_STATUS_KEYS.APPROVED) setIsApproved(true);
  }, [application]);

  const handleApprove = () => {
    const action = APPLICATION_ACTION.ACCEPT;
    const acceptNewApplicant = !stopAcceptingApplicants;

    const input = {
      action,
      acceptNewApplicant,
    };
    processApplicationDecision({
      variables: {
        applicationId: Number(applicationId),
        input,
      },
    })
      .then((res) => {
        if (res.errors) {
          showErrorToast();
        } else {
          setIsApproved(true);
        }
      })
      .catch((err) => {
        showErrorToast();
      });
  };

  return (
    <BaselaneDrawer
      title="Approve application"
      size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
      isOpen
      onClose={() => navigate(-1)}
      newDesignDrawer
      footer={renderFooter()}
    >
      {!isApproved ? (
        <>
          {loadingApproval && <BaselaneSpinner />}
          {!loadingApproval && (
            <VStack gap={2} alignItems="flex-start">
              <Skeleton isLoaded={!loading} w="full">
                <ApplicationDetails
                  applicant={applicant}
                  applicants={applicants}
                  propertyName={propertyName}
                  unitName={unitName}
                />
              </Skeleton>
              <BaselaneDivider my={1} />
              <VStack alignItems="flex-start" justifyContent="flex-start">
                <Checkbox
                  alignItems="flex-start"
                  isChecked={stopAcceptingApplicants}
                  onChange={(e) => {
                    setStopAcceptingApplicants(e.target.checked);
                  }}
                >
                  <Text textStyle="sm" mt="-2px">
                    Stop accepting new applicants
                  </Text>
                  <Text textStyle="xs">
                    You can change this setting or set up a new screening anytime
                  </Text>
                </Checkbox>
              </VStack>
            </VStack>
          )}
        </>
      ) : (
        // Step 2
        <ApproveSuccess RCLink={RCLink} />
      )}
    </BaselaneDrawer>
  );
};

export default Approve;
