import React from 'react';
import { Text, Fade } from '@chakra-ui/react';
import { BaselaneModal, BaselaneButton, BaselaneButtonGroup } from '@shared/components';

type DownloadTaxPackageModalProps = {
  isOpen: boolean,
  onCloseBtnClick: Function,
  onClose: Function,
  title: string,
  description: string,
  icon: Function,
  leftButtonEvent: Function,
  rightButtonEvent: Function,
  leftButtonText: string,
  rightButtonText: string,
  leftButtonProps: object,
  rightButtonProps: object,
};

const DownloadTaxPackageModal = ({
  isOpen,
  onCloseBtnClick,
  onClose,
  title,
  description,
  icon,
  leftButtonEvent,
  rightButtonEvent,
  leftButtonText,
  rightButtonText,
  leftButtonProps,
  rightButtonProps,
}: DownloadTaxPackageModalProps) => {
  return (
    <BaselaneModal
      size="sm"
      defaultOpen={isOpen}
      showCloseButton
      onCloseBtnClick={onCloseBtnClick}
      modalContentStyles={{ p: 3 }}
      modalHeadingStyles={{ p: 0 }}
      modalBodyStyles={{ textAlign: 'left', p: 0 }}
      closeOnOverlayClick={false}
      motionPreset="scale"
      onClose={onClose}
      icon={icon}
      showHeader
    >
      <Fade in={isOpen}>
        <Text color="brand.neutral.900" textStyle="headline-md" mt="2">
          {title}
        </Text>
        <Text color="brand.neutral.700" textStyle="sm" mb="3">
          {description}
        </Text>
        <BaselaneButtonGroup size="md" styles={{ width: '100%' }}>
          {leftButtonProps && (
            <BaselaneButton {...leftButtonProps} onClick={leftButtonEvent}>
              {leftButtonText}
            </BaselaneButton>
          )}
          {rightButtonProps && (
            <BaselaneButton {...rightButtonProps} onClick={rightButtonEvent}>
              {rightButtonText}
            </BaselaneButton>
          )}
        </BaselaneButtonGroup>
      </Fade>
    </BaselaneModal>
  );
};

export default DownloadTaxPackageModal;
