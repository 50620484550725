import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Box, HStack, Input, Progress, Stack, Text, Flex } from '@chakra-ui/react';

import { ReactComponent as UploadIcon } from '@icons/32px/upload.svg';
import IconWarningCircleOutline from '@icons/manual/IconWarningCircleOutline';
import formatBytes from '@core/utils/formatBytes';
import customTheme from '@theme';

import UpdateFileNamePopup from './UpdateFileNamePopup';
import {
  checkIsAllowedFileName,
  checkIsAllowedFileNameLength,
  checkIsAllowedFileType,
  checkIsUniqueFileName,
  getValidationMessage,
} from './helpers/baselaneFileUploader.helpers';
import { fileUploaderStyles } from './styles/baselaneFileUploader.styles';

type BaselaneFileUploaderProps = {
  id?: String,
  dragAndDropId?: String,
  accept?: String,
  allowedFileType: Object,
  handleFile: Function,
  documents?: Array,
  onChange?: Function,
  onClick?: Function,
  onDrop?: Function,
  onDragOver?: Function,
  onDragEnter?: Function,
  onDragLeave?: Function,
  enableFileNameEditing?: boolean,
};

function BaselaneFileUploader({
  id = 'file',
  dragAndDropId = 'drop_zone',
  accept = 'image/jpeg,image/png,.pdf',
  allowedFileType,
  handleFile,
  documents = [],
  onChange = null,
  onClick = null,
  onDrop = null,
  onDragOver = null,
  onDragEnter = null,
  onDragLeave = null,
  enableFileNameEditing = false,
}: BaselaneFileUploaderProps): any {
  // Local State Vars
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [fileNameErrorMsg, setFileNameErrorMsg] = useState(null);
  const [isFileNameUpdateAlertOpen, setIsFileNameUpdateAlertOpen] = useState(false);

  // Local Vars
  let counter = 0;

  // Helper Func
  const handleCleanFiles = () => {
    const fileUploader = document.querySelector(`#${id}`);
    fileUploader.value = '';
  };

  const cleanUpAfterFileUpload = () => {
    handleCleanFiles();
    setSelectedFile(null);
    setFileName(null);
    setIsUploading(false);
  };

  const getFileWithUpdatedName = ({ file, updatedFileName }) => {
    return new File([file], updatedFileName, {
      type: file.type,
    });
  };

  // Alert Helper Func
  const handleFileNameUpdateAlertOpen = () => setIsFileNameUpdateAlertOpen(true);
  const handleFileNameUpdateAlertClose = () => {
    handleCleanFiles();
    setSelectedFile(null);
    setFileNameErrorMsg(null);
    setIsFileNameUpdateAlertOpen(false);
  };

  const processFile = (file) => {
    const { kiloBytes, megaBytes } = formatBytes(file.size);

    // If dropped item is not allowed type, reject it
    const isAllowedFileType = checkIsAllowedFileType({
      allowedFileType: allowedFileType.enum,
      selectedFileType: file.type,
    });
    if (!isAllowedFileType) {
      const message = getValidationMessage({
        errorType: 'INVALID_FILE_TYPE',
        allowedFileType,
      });
      setErrorMsg(message);
    }

    // if file looks good, call APIs
    if (isAllowedFileType) {
      setErrorMsg(null);
      setSelectedFile({ file, fileSize: kiloBytes, fileSizeMB: megaBytes });
      if (enableFileNameEditing) {
        // show popup to edit file name
        handleFileNameUpdateAlertOpen();
      } else {
        // upload file right away
        handleUploadFile({ file, fileSize: kiloBytes, fileSizeMB: megaBytes });
      }
    }
  };

  const handleFiles = ({ files, isDroppedFile = false }) => {
    [...files].forEach((file) => {
      // If dropped item is not file, reject it
      if (isDroppedFile && file.kind === 'file') {
        const formattedFile = file.getAsFile();
        processFile(formattedFile);
      } else if (!isDroppedFile) {
        processFile(file);
      }
    });
  };

  const handleUploadFile = (uploadedFile) => {
    // grab value from param if enableFileNameEditing is false otherwise grab from state
    let chosenFile = uploadedFile;
    if (enableFileNameEditing) {
      chosenFile = selectedFile;
    }

    // Update File Name to Inputted Name
    const { file, fileSize, fileSizeMB } = chosenFile ?? {};
    const inputtedFileName = document.querySelector('#file-name')?.value;
    const trimmedInputtedFileName = inputtedFileName?.replace(/^\s+|\s+$/gm, '');

    const { name } = file ?? {};
    const indexOfDot = name?.lastIndexOf('.');
    const fileExtension = name?.slice(indexOfDot);

    const updatedFileName = enableFileNameEditing ? trimmedInputtedFileName + fileExtension : name;

    // If dropped item name contains special chars not allowed, reject it
    const isAllowedFileNameLength = checkIsAllowedFileNameLength({ name: updatedFileName });
    const isAllowedFileName = checkIsAllowedFileName({ name: updatedFileName });
    if (!isAllowedFileNameLength) {
      const message = getValidationMessage({ errorType: 'EMPTY_FILE_NAME' });
      setFileNameErrorMsg(message);
    } else if (!isAllowedFileName) {
      const message = getValidationMessage({ errorType: 'INVALID_FILE_NAME' });
      setFileNameErrorMsg(message);
    }

    // If fileList has a file with the same name as dropped item, reject it
    const isUniqueFileName = checkIsUniqueFileName({ documents, name: updatedFileName });
    if (!isUniqueFileName) {
      const message = getValidationMessage({ errorType: 'DUPLICATE_FILE_NAME' });
      setFileNameErrorMsg(message);
    }

    // Call APIs
    if (isAllowedFileNameLength && isAllowedFileName && isUniqueFileName) {
      setFileNameErrorMsg(null);
      setIsFileNameUpdateAlertOpen(false);
      const updatedFile = getFileWithUpdatedName({ file, updatedFileName });

      // See FileHandlers directory for the different versions on how we are handling various file formats
      handleFile({
        file: updatedFile,
        fileSize,
        fileSizeMB,
        setFileName,
        setIsUploading,
        cleanUpAfterFileUpload,
        setErrorMsg,
      });
    }
  };

  // Called when File is Selected
  const changeHandler = (ev) => {
    ev.preventDefault();

    const { files } = ev.target ?? {};

    if (files) {
      if (files.length > 1) {
        const message = getValidationMessage({ errorType: 'MAX_FILE_NUMBER_ERROR' });
        setErrorMsg(message);
      } else {
        handleFiles({ files });
      }
    }
  };

  // Called when Drag/Drop box is Clicked
  const clickHandler = (ev) => {
    ev.preventDefault();
    document.getElementById(id).click();
  };

  // Called when File is Dropped
  const dropHandler = async (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    const dropzone = document.querySelector(`#${dragAndDropId}`);
    dropzone?.classList?.remove('dragging');

    const data = ev.dataTransfer;

    if (data.items) {
      if (data.items.length > 1) {
        const message = getValidationMessage({ errorType: 'MAX_FILE_NUMBER_ERROR' });
        setErrorMsg(message);
      } else {
        handleFiles({ files: data.items, isDroppedFile: true });
      }
    }
  };

  const dragOverHandler = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  };

  const dragEnterHandler = (ev) => {
    ev.preventDefault();
    // eslint-disable-next-line no-plusplus, no-unused-vars
    counter++;
    const dropzone = document.querySelector(`#${dragAndDropId}`);
    dropzone?.classList?.add('dragging');
  };

  const dragLeaveHandler = (ev) => {
    ev.preventDefault();
    // eslint-disable-next-line no-plusplus, no-unused-vars
    counter--;
    if (counter === 0) {
      const dropzone = document.querySelector(`#${dragAndDropId}`);
      dropzone?.classList?.remove('dragging');
    }
  };

  const handleChange = onChange ?? changeHandler;
  const handleClick = onClick ?? clickHandler;
  const handleDrop = onDrop ?? dropHandler;
  const handleDragOver = onDragOver ?? dragOverHandler;
  const handleDragEnter = onDragEnter ?? dragEnterHandler;
  const handleDragLeave = onDragLeave ?? dragLeaveHandler;

  // Destructure Imported Styles
  const { input, dragAndDrop, error } = fileUploaderStyles ?? {};

  return (
    <Stack width="100%">
      <Input
        type="file"
        id={id}
        accept={accept}
        onChange={(event) => {
          handleChange(event);
        }}
        {...input({ dragAndDropId })}
      />

      {isUploading ? (
        <Box {...dragAndDrop.container({ isUploading })}>
          <Stack>
            <Text {...dragAndDrop.loading.filename}>{fileName}</Text>
            <Box {...dragAndDrop.loading.container}>
              <Progress
                size="xs"
                colorScheme="scheme.blue"
                isIndeterminate
                {...dragAndDrop.loading.bar}
              />
            </Box>
          </Stack>
        </Box>
      ) : (
        <Box
          id={dragAndDropId}
          onDrop={(event) => handleDrop(event)}
          onDragOver={(event) => handleDragOver(event)}
          onDragEnter={(event) => handleDragEnter(event)}
          onDragLeave={(event) => handleDragLeave(event)}
          onClick={(event) => handleClick(event)}
          {...dragAndDrop.container({ isUploading })}
        >
          <HStack gap={1.5}>
            <UploadIcon />
            <Flex direction="column">
              {isMobile && accept.includes('image') ? (
                <Text textStyle="sm">Take picture or upload file</Text>
              ) : (
                <Text textStyle="sm">
                  <Text as="u" color="brand.blue.800A">
                    Click to upload
                  </Text>{' '}
                  or drag file here
                </Text>
              )}

              <Text textStyle="xs">{`Supported file types: ${allowedFileType.text.join(
                ', '
              )}`}</Text>
            </Flex>
          </HStack>
        </Box>
      )}

      {errorMsg && (
        <HStack {...error.container}>
          <Box>
            <IconWarningCircleOutline w={13.33} h={13.33} color={customTheme.colors.red['800AA']} />
          </Box>

          <Text {...error.msg}>{errorMsg}</Text>
        </HStack>
      )}

      <UpdateFileNamePopup
        selectedFile={selectedFile}
        isFileNameUpdateAlertOpen={isFileNameUpdateAlertOpen}
        handleFileNameUpdateAlertClose={handleFileNameUpdateAlertClose}
        handleUploadFile={handleUploadFile}
        fileNameErrorMsg={fileNameErrorMsg}
      />
    </Stack>
  );
}

export default BaselaneFileUploader;
