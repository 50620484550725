import React, { useState, useEffect } from 'react';
import { HStack, Heading, Flex, Stack, Text, Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  BaselaneCardNew,
  BaselaneButton,
  BaselaneDivider,
  BaselaneCardStack,
  BaselaneChip,
  BaselaneCardStackLayoutClickable,
} from '@shared/components';
import { LEASE_AGREEMENT, LEASES, LEASE_AGREEMENT_TO_LEASE } from '@core/constants/routes';
import RocketLawyeriFrame from '@shared/components/RocketLawyerDocumentDrawer/components/RocketLawyeriFrame';
import { IconPoweredByRocketLawyer } from '@icons';
import { Icon16Document } from '@icons/16px';
import { IllustrationRCSetuprentcollection } from '@illustrations';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { panelFormResponsiveStyles } from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';
import { formatDate } from '@core/utils/formatDate';
import Header from '../Header';
import {
  useLeaseAgreementState,
  useLeaseAgreementId,
  useSetIsDraftLeaseStepValid,
} from '../../leaseAgreementStore';
import { useLeaseAgreement } from '../../../hooks/useLeaseAgreement';
import DisplayName from '../../../components/DisplayName';

const CreateLeaseAgreementStep = () => {
  const { isMax768 } = useBreakPoints();
  const setIsDraftLeaseStepValid = useSetIsDraftLeaseStepValid();
  const leaseAgreementId = useLeaseAgreementId();
  const { leaseAgreement, loading, refetch } = useLeaseAgreement(leaseAgreementId);
  const navigate = useNavigate();
  const leaseAgreementState = useLeaseAgreementState();
  const getAgreementStatus = (state) => {
    switch (state) {
      case 'READY_TO_CUSTOMIZE':
        return 'Ready to customize';
      case 'READY_TO_SIGN':
        return 'Ready to sign';
      case 'SIGNING_IN_PROGRESS':
        return 'Signing in progress';
      default:
        return 'Ready to customize';
    }
  };
  const [rlDocState, setRlDocState] = useState(leaseAgreementState);
  const [agreementStatus, setAgreementStatus] = useState(getAgreementStatus(rlDocState));

  const propertyDetails = [
    { title: 'Property', text: leaseAgreement?.propertyUnit?.propertyName },
    { title: 'Unit', text: leaseAgreement?.propertyUnit?.name },
    {
      title: 'Tenant',
      text: `${leaseAgreement?.tenantProfileMetadata?.firstName} ${leaseAgreement?.tenantProfileMetadata?.lastName}`,
    },
    {
      title: 'Duration',
      text: `${formatDate(leaseAgreement?.startDate)} - ${formatDate(leaseAgreement?.endDate)}`,
    },
  ];
  const aggrementDetails = [
    {
      title: 'Status',
      text: (
        <BaselaneChip
          size="sm"
          variant={rlDocState === 'SIGNING_IN_PROGRESS' ? 'simple-primary' : 'simple-neutral'}
          label={agreementStatus}
        />
      ),
    },
    {
      title: 'Last edited',
      text: `${formatDate(leaseAgreement?.lastEditedAt)}`,
    },
  ];

  const cardItems = [
    {
      content: (
        <BaselaneCardStackLayoutClickable
          icon={<IllustrationRCSetuprentcollection />}
          title="Set up rent collection"
          onClick={() => {
            navigate(`${LEASES}`);
          }}
          description="Fast & free rent collection with automated invoicing, reminders & late fees."
        />
      ),
      id: 'set-up-rent-collection',
    },
    {
      content: (
        <BaselaneCardStackLayoutClickable
          icon={<IllustrationRCSetuprentcollection />}
          title="Link existing rent collection"
          onClick={() => {
            navigate(`/${LEASE_AGREEMENT_TO_LEASE}`, {
              state: { leaseAgreementId: leaseAgreement.id },
            });
          }}
          description="If you've already set up rent collection for this lease, link them to easily access them later."
        />
      ),
      id: 'link-existing-rent-collection',
    },
  ];
  const [showiFrame, setShowiFrame] = useState(false);
  const [isDocReadyToSign, setIsDocReadyToSign] = useState(false);
  const handleCloseiFrame = () => {
    setShowiFrame(false);
    setSelectedPropertyDoc(null);
    refetch();
  };
  const [selectedPropertyDoc, setSelectedPropertyDoc] = useState(null);
  const handleStartSigning = async () => {
    setSelectedPropertyDoc(leaseAgreement);
    setShowiFrame(true);
  };
  useEffect(() => {
    if (
      leaseAgreement?.state === 'READY_TO_SIGN' ||
      leaseAgreement?.state === 'SIGNING_IN_PROGRESS'
    ) {
      setIsDocReadyToSign(true);
      setRlDocState(leaseAgreement?.state);
      setIsDraftLeaseStepValid(true);
    }
    setAgreementStatus(getAgreementStatus(leaseAgreement?.state));
  }, [leaseAgreement]);
  return (
    <Box {...panelFormResponsiveStyles(isMax768)}>
      <Header />
      <BaselaneCardNew palette="dark">
        <Flex direction="column" w="100%">
          <DisplayName
            label={leaseAgreement?.documentName}
            fieldName="documentName"
            initialValue={leaseAgreement?.documentName}
            leaseId={leaseAgreementId}
          />
          <BaselaneDivider my="1" />
          <Stack gap={0}>
            <Flex w="100%" direction="column" gap="1">
              {propertyDetails?.map((item, index) => (
                <HStack justifyContent="space-between" align="flex-start">
                  <Text textStyle="xs" color="brand.neutral.700">
                    {item.title}
                  </Text>
                  <Text textStyle="sm" color="brand.neutral.900">
                    {item.text}
                  </Text>
                </HStack>
              ))}
            </Flex>
          </Stack>
          <BaselaneDivider my="1" />
          <Stack gap="0">
            <Flex w="100%" direction="column" gap={1}>
              {aggrementDetails?.map((item, index) => (
                <HStack justifyContent="space-between" align="flex-start" mb="0.5">
                  <Text textStyle="xs" color="brand.neutral.700">
                    {item.title}
                  </Text>
                  <Text textStyle="sm" color="brand.neutral.900">
                    {item.text}
                  </Text>
                </HStack>
              ))}
            </Flex>
          </Stack>
        </Flex>
      </BaselaneCardNew>

      {rlDocState === 'READY_TO_CUSTOMIZE' && (
        <BaselaneButton
          mt="3"
          variant="filled"
          palette="primary"
          size="md"
          leftIcon={<Icon16Document />}
          isFullWidth
          w="100%"
          loading={loading}
          onClick={() => {
            handleStartSigning();
          }}
        >
          Customize lease
        </BaselaneButton>
      )}
      <Flex justifyContent="center" mt="3">
        <IconPoweredByRocketLawyer />
      </Flex>
      {selectedPropertyDoc && (
        <RocketLawyeriFrame
          {...{
            showiFrame,
            handleCloseiFrame,
            isDocReadyToSign,
            setIsDocReadyToSign,
            selectedPropertyDoc,
          }}
        />
      )}
      {rlDocState === 'SIGNING_IN_PROGRESS' && (
        <>
          <BaselaneDivider my="4" />
          <Heading size="headline-lg" color="brand.neutral.900" mb="2">
            Now let&apos;s set up your rent collection
          </Heading>
          <BaselaneCardStack items={cardItems} />
          <BaselaneButton
            variant="transparent"
            palette="primary"
            size="sm"
            mt="4"
            pullLeft
            onClick={() => {
              navigate(`/${LEASE_AGREEMENT}`);
            }}
          >
            Skip for now
          </BaselaneButton>
        </>
      )}
    </Box>
  );
};
export default CreateLeaseAgreementStep;
