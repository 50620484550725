import moment from 'moment';
import formatCurrency from '@core/utils/formatCurrency';
import { formatDate } from '@core/utils/formatDate';
import isNotNil from '@core/utils/validators/isNotNil';
import isNil from '@core/utils/validators/isNil';

const NOT_AVAILABLE_TENANT = {
  firstName: '\u2014',
  lastName: '',
  phoneNumber: '0000000000',
  email: '\u2014',
};

const getLeaseDuration = ({ startDate, endDate, leaseType }) => {
  return leaseType === 'FIXED' || leaseType === null
    ? ` ${moment(startDate).format('MMM D, YYYY')} - ${formatDate(endDate)}`
    : `Month-to-month`;
};

export function getLeaseRemainingTime(lease) {
  let remainingTime = '\u2014';

  if (isNil(lease.startDate) || isNil(lease.endDate)) {
    return remainingTime;
  }

  const startMomentDate = moment(lease.startDate);

  const endMomentDate = moment(lease.endDate);

  const currentMomentDate = moment(new Date().toDateString());

  if (lease.leaseType === 'MONTHLY') {
    remainingTime = '';
  } else if (currentMomentDate < startMomentDate) {
    const remainingMonths = startMomentDate.diff(currentMomentDate, 'months');

    if (!remainingMonths) {
      const remainingDays = startMomentDate.diff(currentMomentDate, 'days');

      remainingTime = `1st rent due in ${remainingDays} days`;
      if (remainingDays === 1) {
        remainingTime = `1st rent due in ${remainingDays} day`;
      }
      if (remainingTime === 0) {
        remainingTime = `1st rent due today`;
      }
    } else {
      remainingTime = `1st rent due in ${remainingMonths} months`;
    }
  } else if (currentMomentDate > endMomentDate) {
    remainingTime = 'Lease expired';
  } else {
    const remainingMonths = endMomentDate.diff(currentMomentDate, 'months');

    if (!remainingMonths) {
      const remainingDays = endMomentDate.diff(currentMomentDate, 'days');

      remainingTime =
        remainingDays === 1 ? `${remainingDays} day remaining` : `${remainingDays} days remaining`;
    } else {
      remainingTime = `${remainingMonths} mo. remaining`;
    }
  }

  return remainingTime;
}

/**
 * This function returns a text that describes the remaining time for rent collection.
 * The main difference between this function and the [getLeaseRemainingTime] is:
 * As starting point, this function uses the rent collection start date instead of the lease start date.
 * @param {Lease} lease
 * @returns {string} A text that describes the remaining time for rent collection.
 */
export const getRentCollectionRemainingTimeFor = (lease) => {
  const empty = '\u2014';

  if (isNil(lease.startDate) || isNil(lease.endDate)) {
    return empty;
  }

  // use original startDate for this function which is only used for items on leases page
  // otherwise for ongoing leases with a change effective it will display the change date
  const rentCollectionStartDate = moment(lease.startDate);

  const endMomentDate = moment(lease.endDate);

  const currentMomentDate = moment(new Date().toDateString());

  if (lease.leaseType === 'MONTHLY') {
    return '';
  }

  // Lease is expired.
  if (currentMomentDate.isAfter(endMomentDate, 'day')) {
    return 'Lease expired';
  }

  // Lease is active.
  if (currentMomentDate.isBetween(rentCollectionStartDate, endMomentDate, 'day', '[]')) {
    if (currentMomentDate.isSame(endMomentDate, 'day')) {
      return 'Ends today';
    }

    if (rentCollectionStartDate.isSame(currentMomentDate, 'day')) {
      return 'Starts today';
    }

    const remainingMonths = endMomentDate.diff(currentMomentDate, 'months');

    if (remainingMonths) {
      return `${remainingMonths} mo. remaining`;
    }

    const remainingDays = endMomentDate.diff(currentMomentDate, 'days');

    return remainingDays === 1
      ? `${remainingDays} day remaining`
      : `${remainingDays} days remaining`;
  }

  // Lease has not started yet.
  if (currentMomentDate.isBefore(rentCollectionStartDate, 'day')) {
    const remainingMonths = rentCollectionStartDate.diff(currentMomentDate, 'months');

    if (remainingMonths) {
      return `Starts in ${remainingMonths} mo.`;
    }

    const remainingDays = rentCollectionStartDate.diff(currentMomentDate, 'days');

    return `Starts in ${remainingDays} ${remainingDays === 1 ? 'day' : 'days'}`;
  }

  return empty;
};

// the formatPhoneNumber function is used in the TwofactorVerificationPopUp comp, OccupiedUnit comp
// and ArchivedLeases function and success help to format tenant phone number

export function formatPhoneNumber(phoneNumberString, useMask = false) {
  if (!phoneNumberString) return null;

  const cleaned = phoneNumberString.toString().replace(/\D/g, '');
  const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const firstMatch = useMask ? match[1].replace(/\S/g, 'X') : match[1];
    const secondMatch = useMask ? match[2].replace(/\S/g, 'X') : match[2];

    return `(${firstMatch}) ${secondMatch}-${match[3]}`;
  }
  return null;
}

function getMonthlyRentAndFees(payments) {
  const rentAndFees =
    payments &&
    payments.filter(
      (item) => (item.name.includes('FEE') && item.frequency === 'MONTHLY') || item.name === 'RENT'
    );

  let total = 0;

  if (isNotNil(rentAndFees) && rentAndFees.length > 0) {
    rentAndFees.forEach((item) => {
      total += item.amount;
    });
  }

  return total ? formatCurrency(total).inDollars : '\u2014';
}

function getTenantDetails(tenants, tenantProfileId, lease) {
  const foundTenant = tenants.find((tenant) => tenant.id === tenantProfileId);

  if (lease?.tenantProfileMetadata && Object.keys(lease?.tenantProfileMetadata).length > 0) {
    return lease?.tenantProfileMetadata;
  }

  if (isNil(foundTenant)) {
    return NOT_AVAILABLE_TENANT;
  }

  return foundTenant;
}

export { getLeaseDuration, getMonthlyRentAndFees, getTenantDetails };
