// @flow
import React from 'react';
import { Text, Flex } from '@chakra-ui/react';
import { formatDate } from '@core/utils/formatDate';
import { getStatusElement } from './helpers/transfers.helper';
import {
  currencyStyles,
  transferDateTextStyles,
  transferTypeTextStyles,
} from './styles/card.style';
import CurrencyText from '../CurrencyText';

import BaselaneResponsiveCard from './BaselaneResponsiveCard';

const Heading = ({ amount, drawerLink = null }: any) => (
  <Flex direction="row" justifyContent="space-between" w="100%">
    <CurrencyText
      amount={amount}
      isRounded={false}
      isPercentage={false}
      color="brand.neutral.700"
      negativeColor="brand.neutral.700"
      {...currencyStyles}
    />
    {drawerLink}
  </Flex>
);

const TransferType = ({ transfer }: any) => (
  <Text {...transferTypeTextStyles}>{transfer.typeName}</Text>
);

const TransferDate = ({ transfer }: any) => (
  <Text {...transferDateTextStyles}>{formatDate(transfer.transferDate)}</Text>
);

type Props = {
  transfer: Object,
  drawerLink?: any,
};

function BaselaneMobileTransferCard({ transfer, drawerLink, ...rest }: Props): any {
  return (
    <BaselaneResponsiveCard
      heading={<Heading amount={transfer.amount} drawerLink={drawerLink} w="100%" />}
      {...rest}
    >
      {transfer.typeName && <TransferType transfer={transfer} />}
      <Flex direction="row" justify="flex-start">
        <TransferDate transfer={transfer} />
        {transfer.status && getStatusElement(transfer.status)}
      </Flex>
    </BaselaneResponsiveCard>
  );
}

export default BaselaneMobileTransferCard;
