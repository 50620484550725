import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { BaselaneAlertNew, BaselaneButton } from '@shared/components';
import { Icon16Info } from '@icons/16px';
import { TRANSACTIONS } from '@routes';
import { READ_TRANSACTION_ALERTS } from './queries/alerts';

const TransactionImportBanner = ({ id, refetch }: { id: String, refetch: Function }) => {
  const [readAlert] = useMutation(READ_TRANSACTION_ALERTS);
  const navigate = useNavigate();

  const dismissBanner = (onComplete: Function) => {
    readAlert({ variables: { id } })
      .then(() => refetch())
      .finally(() => {
        if (onComplete && typeof onComplete === 'function') onComplete();
      });
  };

  const dismissBannerThenNavigate = () => {
    dismissBanner(() => {
      navigate(TRANSACTIONS);
    });
  };

  const rightElement = (
    <BaselaneButton variant="filled" palette="primary" onClick={dismissBannerThenNavigate}>
      Categorize Transactions
    </BaselaneButton>
  );

  return (
    <Box mb={3}>
      <BaselaneAlertNew
        iconName={Icon16Info}
        body="Categorize them to make sure that your dashboard is up to date."
        title="New transactions imported"
        visual="icon"
        customButton={rightElement}
        hasCloseButton
        onCloseClick={dismissBanner}
      />
    </Box>
  );
};

export default TransactionImportBanner;
