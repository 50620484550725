import React, { useState } from 'react';
import { InputGroup, Input, HStack } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { numOfDigitsMask } from '@core/utils/masks';
import { searchGroupStyles, searchStyles } from './styles/twofactor.styles';

type SingleInputComponentProps = {
  onChangeEvent: Function,
  onBlurEvent?: Function,
  onKeyDownEnter?: Function,
  isDisabled?: boolean,
};

const SingleInputComponent = ({
  onChangeEvent,
  onBlurEvent = () => {},
  onKeyDownEnter = () => {},
  isDisabled = false,
}: SingleInputComponentProps) => {
  const [value, setValue] = useState();

  const handleInput = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    onChangeEvent(newValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onKeyDownEnter();
    }
  };

  return (
    <HStack className="connectedInputs">
      <InputGroup {...searchGroupStyles}>
        <Input
          autoFocus
          id="otp-input"
          {...searchStyles}
          as={MaskedInput}
          mask={numOfDigitsMask(6)}
          value={value}
          onChange={(e) => handleInput(e)}
          onBlur={() => onBlurEvent(value)}
          pattern="\d*"
          inputMode="decimal"
          onKeyDown={handleKeyDown}
          isDisabled={isDisabled}
        />
      </InputGroup>
    </HStack>
  );
};

export default SingleInputComponent;
