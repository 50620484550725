import { fullSize, wFull } from './shared.styles';
import { truncatedText } from '../../../styles/text.style';

export const zero = {
  p: '0 !important',
  m: '0 !important',
};

export const defaultCellStyles = {
  py: 1.5,
  px: 2,
  overflow: 'hidden',
};

export const defaultContentContainerStyles = {
  ...fullSize,
  alignItems: 'center',
  justifyContent: 'flex-start',
};

export const subtextStyle = {
  color: 'brand.neutral.600',
  textStyle: 'xs',
  ...zero,
  ...wFull,
  ...truncatedText,
};

export const titleTextStyle = {
  size: 'headline-sm',
  color: 'brand.neutral.900',
  ...zero,
  ...truncatedText,
};

export const textStyle = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
  ...zero,
  ...wFull,
  ...truncatedText,
};

export const titleCellContainerStyle = ({ isVertical }) => {
  return isVertical
    ? {
        contentContainerStyles: {
          alignItems: 'flex-start',
          flexDir: 'column',
          gap: '6px',
        },
      }
    : {};
};

export const contentContainer = ({ isVertical }) => {
  return {
    ...wFull,
    ...zero,
    overflow: 'hidden',
    align: 'flex-start',
    gap: isVertical ? '6px' : 0,
  };
};

export const graphicElementContainer = {
  mr: '12px',
};

export const amountTextStyles = () => ({
  size: 'headline-sm',
  color: 'brand.neutral.700',
  ...zero,
  ...truncatedText,
});
