import React, { useContext, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { useMutation } from '@apollo/client';
import { Stack, useDisclosure, useToast } from '@chakra-ui/react';
import { emailVerificaton } from '@core/Services/CreateAccount';
import { reload } from '@core/Services/Firebase/firebase';
import { IconExclamationCircle } from '@icons';
import UserContext from '@contexts/UserContext';
import { InProgress } from '@pages/Dashboard/SetupGuide/GuideTrackers/Steps';
import PhoneVerification from '@core/components/PhoneVerification';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import useBreakPoints from '@core/hooks/useBreakPoints';
import useIpAddress from '@shared/hooks/useIpAddress';
import AlertHeader from '../AlertHeader';
import AlertFooter from '../AlertFooter';
import BaselaneAlert from '../BaselaneAlert';
import BaselaneButton from '../BaselaneButton';
import BaselaneDrawer from '../BaselaneDrawer';
import Form from './components/Form';
import Footer from './components/Footer';
import { USER_KYC } from './queries';
import {
  ALERT_TITLE,
  ALERT_BODY,
  ALERT_BUTTON1,
  ALERT_BUTTON2,
  EMAIL_ALERT_TITLE,
  EMAIL_ALERT_BODY,
  EMAIL_ALERT_BUTTON1,
  EMAIL_ALERT_BUTTON2,
} from './constants/kycForm.constants';
import { initialValues, getDataToSubmit } from './helpers/kycForm.helpers';
import { errorMessages } from '../TwoFactorVerificationPopUp/helpers/constant';
import { emailAlertIconBgStyles } from './styles/kycForm.styles';

type BaselaneKYCFormProps = {
  button?: Object,
  kycStatus: String,
  onKYCDrawerClose?: Function,
  handleKYCReturnStatusDrawerOpen: Function,
  isAutoOpen?: boolean,
  hideButton?: boolean,
  isGetStarted?: boolean,
  getStartedConfig?: Object,
  handleVerifyIdentitySuccess?: Function,
  handleKYCOnCloseCallback?: Function,
  handleVouchedDrawerOpen: Function,
};

const BaselaneKYCForm = ({
  onKYCDrawerClose = () => {},
  button = {
    text: 'Verify Identity',
    variant: 'filled',
    palette: 'primary',
    size: 'md',
    leftIcon: null,
  },
  kycStatus,
  handleKYCReturnStatusDrawerOpen,
  isAutoOpen = false,
  hideButton = false,
  isGetStarted = false,
  getStartedConfig = {},
  handleVerifyIdentitySuccess = () => {},
  handleKYCOnCloseCallback = null,
  handleVouchedDrawerOpen,
}: BaselaneKYCFormProps) => {
  const { user, refetchUser, setEmailVerified, setKycShowPopup } = useContext(UserContext);
  const { email, phoneNumber } = user || {};
  const { ipAddress } = useIpAddress();

  const { isMax767 } = useBreakPoints();

  // unsaved changes alert
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  // email verification alert
  const [isEmailAlertOpen, setIsEmailAlertOpen] = useState(false);
  const onEmailAlertClose = () => {
    setKycShowPopup(false);
    setIsEmailAlertOpen(false);
  };
  const onEmailAlertOpen = () => setIsEmailAlertOpen(true);

  const [isPhoneAlertOpen, setIsPhoneAlertOpen] = useState(false);

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [isLoadingSubmition, setIsLoadingSubmition] = useState(false);

  const drawerRef = useRef(null);
  const phoneRef = useRef(null);
  const cancelRef = useRef(null);

  const [userKYC, { loading: sendUserKYCLoading }] = useMutation(USER_KYC);

  const handleDrawerOpen = async () => {
    const emailVerified = await reload();

    if (!emailVerified) {
      onEmailAlertOpen();
      return;
    }

    setEmailVerified(emailVerified);
    drawerRef.current?.open();
    sendSegmentEvent('id_verification_start', { landlord_uuid: user.id });
  };

  const handleDrawerClose = () => {
    onKYCDrawerClose();
    setKycShowPopup(false);
    drawerRef.current?.close();
    refetchUser();
  };

  const [formVariables, setFormVariables] = useState({ ...initialValues, phoneNumber });

  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const { text, variant, palette, size, leftIcon } = button;

  const toast = useToast();
  const showSuccessToast = (description) => {
    toast({
      description,
      status: 'success',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });
  };

  const showErrorToast = (description) =>
    toast({
      description,
      status: 'error',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  useEffect(() => {
    // if this is buttonless KYC, just open form
    if (isAutoOpen) {
      handleDrawerOpen();
    }
  }, []);

  useEffect(() => {
    if (ipAddress && !formVariables.ip) {
      setFormVariables((prevState) => ({ ...prevState, ip: ipAddress }));
    }
  }, [ipAddress]);

  const cleanup = () => {
    setFormVariables(initialValues);
    setIsDirty(false);
    setIsValid(false);
  };

  const handleFormVariableUpdate = (newVariables) => {
    setFormVariables({ ...formVariables, ...newVariables });
  };

  const handleKYCFormClose = () => {
    handleDrawerClose();

    if (!handleKYCOnCloseCallback) {
      handleKYCReturnStatusDrawerOpen();
    } else {
      handleKYCOnCloseCallback();
    }
  };

  const checkAutoFillValues = () => {
    const { ip, ...rest } = formVariables;
    const inputValues = {
      firstName: document.querySelector('input[name="firstName"]')?.value,
      middleName: document.querySelector('input[name="middleName"]')?.value,
      lastName: document.querySelector('input[name="lastName"]')?.value,
      dob: document.querySelector('input[name="dob"]')?.value,
      ssn: document.querySelector('input[name="ssn"]')?.value?.replace(/[^0-9]/g, ''),
      address: document.querySelector('input[name="address"]')?.value,
      unitNumber: document.querySelector('input[name="unitNumber"]')?.value,
      city: document.querySelector('input[name="city"]')?.value,
      zipcode: document.querySelector('input[name="zipcode"]')?.value,
      state: document.querySelector('select[name="state"]')?.value,
    };

    if (!isEqual(rest, inputValues)) {
      setFormVariables({ ...inputValues, ip });
    }
  };

  useEffect(() => {
    if (kycStatus !== 'UNVERIFIED' && kycStatus !== 'PENDING' && kycStatus !== 'VOUCH_PENDING') {
      handleKYCFormClose();
    }
  }, [kycStatus]);

  const handleKYCSubmit = () => {
    getDataToSubmit(formVariables);
    userKYC({ variables: getDataToSubmit(formVariables) })
      .then(({ errors, data }) => {
        if (errors?.length >= 1) {
          setShowErrorBanner(true);
        } else {
          const { userKycStatus } = data.userKYC || '';
          setIsLoadingSubmition(true);
          cleanup();
          handleVerifyIdentitySuccess();

          if (userKycStatus === 'VOUCH_PENDING') {
            handleDrawerClose();
            handleVouchedDrawerOpen();
          } else {
            setIsLoadingSubmition(false);
            handleKYCFormClose();
          }
        }
      })
      .catch((err) => {
        console.error(err);
        showErrorToast(errorMessages[500]);
      });
  };

  const handleOnNextClick = () => {
    checkAutoFillValues();
    // if users are in the a/b test, trigger the phone OTP if they do not have a phone number verified
    if (!phoneNumber) {
      setIsPhoneAlertOpen(true);
    } else {
      handleKYCSubmit();
    }
  };

  const handleCloseWithoutSaving = () => {
    const isFormUntouched = !isDirty && !isValid;

    if (!isFormUntouched) {
      onAlertOpen();
    } else {
      cleanup();
      handleDrawerClose();
    }
  };

  const handleResendEmailVerificationClick = () => {
    const bodyParam = { email };
    emailVerificaton(bodyParam)
      .then((data) => {
        if (data?.message) {
          if (data.message.includes('TOO_MANY_ATTEMPTS_TRY_LATER')) {
            showSuccessToast('Verification email already sent. Please try again in few minutes.');
          } else {
            showErrorToast('Something went wrong. Please try again.');
          }
          return;
        }
        showSuccessToast('Email sent successfully.');
      })
      .catch(() => {
        showErrorToast('Something went wrong. Please try again.');
      });
    onEmailAlertClose();
  };

  const alertFooter = (
    <AlertFooter
      leftButtonText={ALERT_BUTTON1}
      leftButtonEvent={onAlertClose}
      rightButtonText={ALERT_BUTTON2}
      rightButtonEvent={() => {
        handleDrawerClose();
        onAlertClose();
        cleanup();
      }}
    />
  );

  const emailAlertFooter = (
    <Stack direction="row">
      <BaselaneButton variant="outline" palette="neutral" size="md" onClick={onEmailAlertClose}>
        {EMAIL_ALERT_BUTTON1}
      </BaselaneButton>
      <BaselaneButton
        variant="filled"
        palette="danger"
        size="md"
        onClick={handleResendEmailVerificationClick}
      >
        {EMAIL_ALERT_BUTTON2}
      </BaselaneButton>
    </Stack>
  );

  return (
    <>
      {!isAutoOpen && !hideButton && !isGetStarted && (
        <BaselaneButton
          id="kyc-verification-button"
          {...{ variant, palette, size, leftIcon }}
          onClick={handleDrawerOpen}
        >
          {text}
        </BaselaneButton>
      )}
      {isGetStarted && (
        <InProgress
          status={getStartedConfig.status}
          handleTrackerInProgStepClick={() => handleDrawerOpen()}
        />
      )}
      <BaselaneDrawer
        ref={drawerRef}
        size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
        title="Identity Verification"
        onClose={handleCloseWithoutSaving}
        closeEvent={handleCloseWithoutSaving}
        footer={
          <Footer
            {...{
              isDirty,
              isValid,
              handleOnNextClick,
              handleDrawerClose,
              handleCloseWithoutSaving,
              sendUserKYCLoading,
              isLoadingSubmition,
            }}
          />
        }
        newDesignDrawer
      >
        <Form
          {...{
            phoneRef,
            handleFormVariableUpdate,
            setIsDirty,
            setIsValid,
            showErrorBanner,
            setShowErrorBanner,
          }}
        />
      </BaselaneDrawer>

      <BaselaneAlert
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        header={<AlertHeader title={ALERT_TITLE} />}
        body={ALERT_BODY}
        footer={alertFooter}
        size="xl"
      />

      <BaselaneAlert
        isOpen={isEmailAlertOpen}
        onClose={onEmailAlertClose}
        leastDestructiveRef={cancelRef}
        isCentered
        header={
          <AlertHeader
            icon={<IconExclamationCircle w="24" h="24" color="#EA6868" />}
            iconBgStyles={emailAlertIconBgStyles}
            title={EMAIL_ALERT_TITLE}
          />
        }
        body={EMAIL_ALERT_BODY}
        footer={emailAlertFooter}
        size="2xl"
        showCloseButton
      />

      {isPhoneAlertOpen && (
        <PhoneVerification
          onCloseBtnClick={() => setIsPhoneAlertOpen(false)}
          onOTPSuccess={() => {
            setIsPhoneAlertOpen(false);
            handleKYCSubmit();
          }}
        />
      )}
    </>
  );
};

export default BaselaneKYCForm;
