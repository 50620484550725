import React, { useState } from 'react';
import {
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { formInputStyles } from '@shared/styles/input.style';
import { BaselaneButtonIcon, BaselaneFormLabel, BaselaneInput } from '@shared/components';
import { Icon16Hide, Icon16Unhide } from '@icons/16px';
import useInvitationLink from '@pages/SignUpPage/useInvitationLink';

type LoginFieldsProps = {
  handleOnKeyDown?: Function,
};

const LoginFields = ({ handleOnKeyDown }: LoginFieldsProps) => {
  const { values, touched, handleChange, setFieldTouched, errors } = useFormikContext();

  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () =>
    setShowPassword((currentShowPassword) => !currentShowPassword);

  const { isCollaborator } = useInvitationLink();
  return (
    <>
      <FormControl id="email-field" isRequired isInvalid={errors.email && touched.email}>
        <BaselaneFormLabel textStyle="sm" htmlFor="email">
          Email
        </BaselaneFormLabel>
        <BaselaneInput
          {...formInputStyles}
          id="email"
          name="email"
          value={values.email}
          onChange={(e) => {
            setFieldTouched('email');
            handleChange(e);
          }}
          isDisabled={isCollaborator}
          placeholder="Enter email"
          onKeyDown={handleOnKeyDown}
        />
        <FormErrorMessage>{errors.email}</FormErrorMessage>
      </FormControl>

      <FormControl id="password-field" isRequired isInvalid={errors.password && touched.password}>
        <BaselaneFormLabel textStyle="sm" htmlFor="password">
          Password
        </BaselaneFormLabel>
        <InputGroup>
          <BaselaneInput
            {...formInputStyles}
            id="password"
            name="password"
            value={values.password}
            onChange={(e) => {
              setFieldTouched('password');
              handleChange(e);
            }}
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter password"
            onKeyDown={handleOnKeyDown}
          />

          <InputRightElement h="48px">
            <BaselaneButtonIcon
              variant="transparent"
              palette="neutral"
              icon={showPassword ? <Icon16Hide /> : <Icon16Unhide />}
              isActive={showPassword}
              size="sm"
              onClick={handlePasswordVisibility}
            />
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>{errors.password}</FormErrorMessage>
        {errors.credential && (
          <Text textStyle="xs" color="red.500" mt="6px">
            {errors.credential}
          </Text>
        )}
      </FormControl>
    </>
  );
};

LoginFields.defaultProps = {
  handleOnKeyDown: () => {},
};

export default LoginFields;
