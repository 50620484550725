import { gql } from '@apollo/client';
import { FRAGMENT_USER_METADATA } from '@core/apollo/queries';

export const UPDATE_USER = gql`
  mutation updateUser(
    $firstName: String
    $lastName: String
    $onboardingCompleted: Boolean
    $metadata: JSON
    $businessProfile: JSON
  ) {
    updateUser(
      input: {
        firstName: $firstName
        lastName: $lastName
        onboardingCompleted: $onboardingCompleted
        metadata: $metadata
        businessProfile: $businessProfile
      }
    ) {
      firstName
      phoneNumber
      onboardingCompleted
      businessProfile
      phoneNumberVerified
      ...UserMetadata
    }
  }
  ${FRAGMENT_USER_METADATA}
`;

export const UPDATE_USER_WITHOUT_NAME = gql`
  mutation updateUser($onboardingCompleted: Boolean, $metadata: JSON, $businessProfile: JSON) {
    updateUser(
      input: {
        onboardingCompleted: $onboardingCompleted
        metadata: $metadata
        businessProfile: $businessProfile
      }
    ) {
      firstName
      phoneNumber
      onboardingCompleted
      businessProfile
      phoneNumberVerified
      ...UserMetadata
    }
  }
  ${FRAGMENT_USER_METADATA}
`;

export const UPDATE_USER_METADATA = gql`
  mutation updateUser($metadata: JSON) {
    updateUser(input: { metadata: $metadata }) {
      id
      ...UserMetadata
    }
  }
  ${FRAGMENT_USER_METADATA}
`;

export const CREATE_OR_UPDATE_ONBOARDING_SURVEY = gql`
  mutation createOrUpdateOnboardingSurvey($version: String, $questionnaire: [QuestionAnswer!]) {
    createOrUpdateOnboardingSurvey(input: { version: $version, questionnaire: $questionnaire }) {
      onboardingSurvey
    }
  }
`;

export const UPDATE_INVOICE_PROCESSING_FEE_PAID_BY = gql`
  mutation updateInvoiceProcessingFeePaidBy($invoiceProcessingFeePaidBy: String) {
    updateInvoiceProcessingFeesPaidBy(invoiceProcessingFeePaidBy: $invoiceProcessingFeePaidBy) {
      id
      userId
      invoiceProcessingFeePaidBy
    }
  }
`;
