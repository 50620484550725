import React, { useContext, useEffect, useState } from 'react';
import { Heading, Text, VStack, Box, Skeleton } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import {
  BaselaneAutoTagPropertyDropdown,
  BaselaneAutoTagCategoryDropdown,
  BaselaneToolpop,
  BaselaneCardNew,
} from '@shared/components';
import TransactionContext from '@contexts/TransactionContext';
import { getPropertyData } from '@shared/helpers/propertiesFilter.helpers';
import UserAccessContext from '@contexts/UserAccessContext';
import { Icon16Info, Icon16ChevronRight } from '@icons/16px';
import { BOOKKEEPING_RULES } from '@routes';
import useSaveAutoTagPropertyRule from './hooks/useSaveAutoTagPropertyRule';

type BookkeepingRulesSectionProps = {
  account?: Object,
  card?: Object,
  values: Object,
  handleSave: Function,
  isDisabled: boolean,
  autoSave: boolean,
  showCategory: boolean,
};

const BookkeepingRulesSection = ({
  account,
  card,
  values,
  handleSave,
  isDisabled,
  autoSave = true,
  showCategory = false,
  ...rest
}: BookkeepingRulesSectionProps) => {
  const navigate = useNavigate();

  const [dirty, setDirty] = useState(false);
  const [propertySelection, _setPropertySelection] = useState(null);
  const setPropertySelection = (selection) => {
    setDirty(true);
    _setPropertySelection(selection);
  };
  const [categorySelection, _setCategorySelection] = useState(null);
  const setCategorySelection = (selection) => {
    setDirty(true);
    _setCategorySelection(selection);
  };

  const {
    loading,
    saveAutoTagPropertyRule,
    applicableRules,
    isComplexOrMultipleRules,
  } = useSaveAutoTagPropertyRule({ accountId: account?.id, cardId: card?.id });

  const { authorizedForBanking } = useContext(UserAccessContext);

  const { propertiesData } = useContext(TransactionContext);
  const propertyOptions = getPropertyData(propertiesData);
  const hasNoPropertyOptions = propertyOptions?.length === 0;

  const currentPropertyValue =
    applicableRules?.length === 1 && autoSave
      ? {
          propertyId: applicableRules[0].action.propertyId,
          unitId: applicableRules[0].action.unitId,
        }
      : {
          propertyId: values?.propertyId,
          unitId: values?.unitId,
        };

  const currentCategoryId =
    applicableRules?.length === 1 && autoSave
      ? applicableRules[0].action.tagId
      : values?.categoryId;

  useEffect(() => {
    if (dirty) {
      if (autoSave) {
        // autosave the rule before updating the parent form
        saveAutoTagPropertyRule({
          propertySelection,
          categorySelection,
        });
      }

      // update the parent form
      handleSave({
        propertySelection,
        categorySelection,
      });
      setDirty(false);
    }
  }, [autoSave, dirty]);

  return (
    authorizedForBanking && (
      <Skeleton
        isLoaded={!loading}
        display="flex"
        flexDirection="column"
        gap={1}
        paddingLeft={hasNoPropertyOptions ? '2px' : '0'}
        w="full"
        {...rest}
      >
        <Heading as="h3" size="headline-md" display="flex" alignItems="center">
          Bookkeeping rules
          <BaselaneToolpop
            ml={1}
            styles={{
              maxW: '216px',
            }}
            placement="bottom"
            trigger={<Icon16Info />}
          >
            <Text textStyle="xs" maxW="216px">
              Automate your bookkeeping by having all transactions on this{' '}
              {account ? 'account' : 'card'} tagged to a specific property. You can customize it
              further on the{' '}
              <Link to={`/${BOOKKEEPING_RULES}`} style={{ textDecoration: 'underline' }}>
                bookkeeping rules page
              </Link>
              .
            </Text>
          </BaselaneToolpop>
        </Heading>
        {isComplexOrMultipleRules ? (
          <BaselaneCardNew
            variant="clickable"
            onClick={() => {
              navigate(`/${BOOKKEEPING_RULES}`);
            }}
          >
            <VStack alignItems="flex-start" gap={0.25}>
              <Heading as="h4" size="headline-sm">
                Multiple conditions
              </Heading>
              <Text textStyle="xs">View all the bookkeeping rules linked to this account.</Text>
            </VStack>
            <Box ml="auto">
              <Icon16ChevronRight />
            </Box>
          </BaselaneCardNew>
        ) : (
          <>
            <Text textStyle="xs">Auto-tag all transactions to a property or unit (optional)</Text>
            <BaselaneAutoTagPropertyDropdown
              values={currentPropertyValue}
              isDisabled={false}
              onChange={setPropertySelection}
            />
          </>
        )}
        {showCategory && !isComplexOrMultipleRules && (
          <Box mt={1} w="full">
            <Text textStyle="xs" mb={1}>
              Auto-tag all transactions to a category (optional)
            </Text>
            <BaselaneAutoTagCategoryDropdown
              categoryId={currentCategoryId}
              isDisabled={false}
              onChange={setCategorySelection}
            />
          </Box>
        )}
      </Skeleton>
    )
  );
};

export default BookkeepingRulesSection;
