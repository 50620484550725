import React from 'react';
import { Box, Flex, VStack } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import { SectionWrapper } from '@shared/layouts';

import BaselaneBankingCard from './BaselaneBankingCard';
import RCCard from './RCCard';
import CashflowCard from './CashflowCard';
import PartnersSection from './PartnersSection';
import SetupGuide from './SetupGuide';
import useGuideComplete from './SetupGuide/useGuideComplete.hook';

const Dashboard = () => {
  const { isGuideLoading, isGuideComplete, isGuideDismissed } = useGuideComplete();

  return (
    <Flex minH="100%" direction="column">
      <Outlet />
      {!isGuideDismissed && !isGuideComplete && !isGuideLoading && <SetupGuide />}
      <SectionWrapper
        pt={{ '2lg': 4, base: 3 }}
        pb={{ '2lg': 4, lg: 5, base: 3 }}
        display="flex"
        gap={{ '2lg': 4, lg: 3, base: 2 }}
        flexDirection={{ '2lg': 'row', base: 'column' }}
      >
        <VStack w={{ '2lg': '50%', base: '100%' }} h="100%" alignItems="flex-start">
          <BaselaneBankingCard />
        </VStack>
        <VStack
          w={{ '2lg': '50%', base: '100%' }}
          h="fit-content"
          gap={{ '2lg': 4, lg: 3, base: 2 }}
        >
          <RCCard />
          <CashflowCard />
        </VStack>
      </SectionWrapper>
      <Box
        px={{ '2lg': 4, base: 3 }}
        pt={{ '2lg': 4, base: 3 }}
        pb="88px"
        bgColor="brand.darkBlue.50"
        minH="276px"
        borderTop="1px solid"
        borderColor="brand.darkBlue.200"
      >
        <PartnersSection />
      </Box>
    </Flex>
  );
};

export default Dashboard;
