import React from 'react';

import {
  IllustrationBankingBusinessAccount,
  IllustrationBankingSolePropAccount,
} from '@illustrations';

import {
  IllustrationBankingBusinessAccountclosed,
  IllustrationBankingSolePropAccountclosed,
} from '@illustrations/manual';

type AccountGraphicElementProps = {
  /**
   * @prop {Boolean} - Switches on "closed" account styling when set to true.
   */
  isClosed: Boolean,
  /**
   * @prop {SoleProprietorship | null} - Determines which icon to show based on type of account.
   */
  applicationType?: 'SoleProprietorship' | null,
  /**
   * @prop {React.Node} - Able to set custom sole prop graphic element.
   */
  solePropGraphicElement?: React.Node,
  /**
   * @prop {React.Node} - Able to set custom business graphic element.
   */
  businessGraphicElement?: React.Node,
};

/**
 * Renders an Icon for Business or Sole Proprietor accounts.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const AccountGraphicElement = ({
  isClosed,
  applicationType,
  solePropGraphicElement,
  businessGraphicElement,
}: AccountGraphicElementProps) => {
  if (applicationType === 'SoleProprietorship') {
    return (
      solePropGraphicElement ||
      (isClosed ? (
        <IllustrationBankingSolePropAccountclosed />
      ) : (
        <IllustrationBankingSolePropAccount />
      ))
    );
  }
  return (
    businessGraphicElement ||
    (isClosed ? (
      <IllustrationBankingBusinessAccountclosed />
    ) : (
      <IllustrationBankingBusinessAccount />
    ))
  );
};

AccountGraphicElement.defaultProps = {
  applicationType: null,
  solePropGraphicElement: null,
  businessGraphicElement: null,
};

export default AccountGraphicElement;
