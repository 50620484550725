import { bodyBase } from '@core/theme/text';

export const descriptionStyles = (isDisabled) => ({
  ...bodyBase.xs,
  color: !isDisabled ? 'brand.neutral.600' : 'brand.neutral.400',
});

export const switchLabelStyles = (isDisabled) => ({
  ...bodyBase.sm,
  mb: '0',
  color: !isDisabled ? 'brand.neutral.700' : 'brand.neutral.400',
});
