import React from 'react';
import MaskedInput from 'react-text-mask';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { currencyMask } from '@core/utils/masks';
import { BaselaneSingleDatePicker } from '@shared/components';
import stripCurrency from '@core/utils/stripCurrency';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { formatDate } from '@core/utils/formatDate';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import ToggleWrapper from '../../components/ToggleWrapper';
import { fieldContainer } from '../../styles/shared.styles';

type Field = {
  label: string,
  type: string,
};
type Fields = {
  [key: string]: Field,
};

type ProratedRentSectionProps = {
  sectionInfo: Array<Fields>,
  sectionId: string,
  proratedRentId: string,
  handleChange: Function,
  handleBlur: Function,
  setFieldTouched: Function,
  setFieldValue: Function,
  setFormVariables: Function,
  values: Object,
  errors: Object,
  touched: Object,
};

function ProratedRentSection({
  sectionInfo = [],
  sectionId = '',
  proratedRentId = '',
  handleChange = () => {},
  handleBlur = () => {},
  setFieldTouched = () => {},
  setFieldValue = () => {},
  setFormVariables = () => {},
  values = {},
  errors = {},
  touched = {},
}: ProratedRentSectionProps) {
  const { isMin768 } = useBreakPoints();
  const years = { start: 2022, end: 2043 };
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCalendarClose = ({ date: dueDate, isFromApplyButton }) => {
    if (isMin768 || (!isMin768 && isFromApplyButton)) {
      setFieldValue('proratedRentDueDate', dueDate);
      const proratedRentDueDate = dueDate ? formatDate(dueDate, 'YYYY-MM-DD') : null;
      setFormVariables({
        payments: [{ id: proratedRentId, name: 'PRORATED_RENT', dueDate: proratedRentDueDate }],
      });
    }
  };

  const titleSwitch = (label) => (
    <ToggleWrapper.Toggle
      key="prorated_rent_switch"
      title={label}
      htmlFor={sectionId}
      switchProps={{
        id: sectionId,
        isChecked: values.proratedRent,
        value: values.proratedRent,
        onChange: (e) => {
          handleChange(e);
          setFormVariables({
            payments: [
              {
                id: proratedRentId,
                name: 'PRORATED_RENT',
                enabled: e.target.checked,
                amount: null,
                dueDate: null,
              },
            ],
          });

          if (!values.proratedRent) {
            setFieldTouched('proratedRentAmount', false);
            setFieldTouched('proratedRentDueDate', false);
            setFieldValue('proratedRentAmount', '');
            setFieldValue('proratedRentDueDate', null);
          }
        },
      }}
    />
  );
  const hasError =
    (errors.proratedRentDueDate && touched.proratedRentDueDate) ||
    (errors.proratedRentDueDateRequired && touched.proratedRentDueDate);

  return (
    <ToggleWrapper key="prorated_rent_container" showBottomBorder={false} isNested>
      {titleSwitch(sectionInfo.title.label)}
      {values.proratedRent ? (
        <ToggleWrapper.Content
          key="prorated_rent_switch_content"
          styles={{ spacing: '0', mt: isMobile ? '16px' : '24px', mb: '0' }}
        >
          <Stack direction={!isMobile ? 'row' : 'column'} spacing={isMobile ? '16px' : '24px'}>
            {/* Prorated Rent Amount */}
            <FormControl isInvalid={errors.proratedRentAmount && touched.proratedRentAmount}>
              <FormLabel {...formLabelStyles.xs} htmlFor={sectionInfo.amount.label}>
                {sectionInfo.amount.label}
              </FormLabel>
              <Input
                {...formInputStyles}
                {...sectionInfo.amount.props}
                value={values.proratedRentAmount}
                as={MaskedInput}
                mask={currencyMask()}
                placeholder="$"
                inputMode="decimal"
                onChange={(e) => {
                  setFieldTouched('proratedRentAmount', true);
                  handleChange(e);
                }}
                onBlur={(e) => {
                  const amount = stripCurrency(e.target.value);
                  setFormVariables({
                    payments: [
                      {
                        id: proratedRentId,
                        name: 'PRORATED_RENT',
                        amount,
                        dueDate: values.proratedRentDueDate,
                      },
                    ],
                  });
                  handleBlur(e);
                }}
              />
              <FormErrorMessage {...formErrorStyles}>{errors.proratedRentAmount}</FormErrorMessage>
            </FormControl>

            {/* Prorated Rent Due Date */}
            <FormControl
              {...fieldContainer}
              isInvalid={errors.proratedRentDueDate && touched.proratedRentDueDate}
            >
              <FormLabel {...formLabelStyles.xs} htmlFor={sectionInfo.dueDate.label}>
                {sectionInfo.dueDate.label}
              </FormLabel>
              <BaselaneSingleDatePicker
                {...{
                  ...sectionInfo.dueDate.props,
                  date: values.proratedRentDueDate,
                  value: values.proratedRentDueDate
                    ? `${formatDate(values.proratedRentDueDate, 'MMM DD, YYYY')}`
                    : '',
                  dateFormat: 'MMM dd, yyyy',
                  handleCalendarClose,
                  size: 'lg',
                  years,
                  isOpen,
                  onOpen,
                  onClose,
                  hideInputIcon: true,
                  fixedHeight: true,
                  placement: 'bottom-end',
                  showInModal: !isMin768,
                  className: `form-datepicker ${hasError ? 'has-error' : ''}`,
                }}
              />
              <FormErrorMessage {...formErrorStyles}>{errors.proratedRentDueDate}</FormErrorMessage>
            </FormControl>
          </Stack>
        </ToggleWrapper.Content>
      ) : null}
    </ToggleWrapper>
  );
}

export default ProratedRentSection;
