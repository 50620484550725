import React, { useContext } from 'react';
import { Stack, Heading, Text, Box, Skeleton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import MoveMoneyMenu from '@shared/components/MoveMoneyMenu';
import { BaselaneButton, BaselaneCardNew } from '@shared/components';
import { NB_ACCOUNTS } from '@routes';
import formatCurrency from '@core/utils/formatCurrency';
import {
  IllustrationBankingBusinessAccount,
  IllustrationBankingSolePropAccount,
} from '@illustrations/index';
import { Icon16ChevronRight } from '@icons/16px';
import getPluralizedNoun from '@core/utils/getPluralizedNoun';
import { truncatedText } from '@shared/styles/text.style';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import UserAccessContext from '@contexts/UserAccessContext';
import { getTotalNumberAvailableBalance, getTotalNumberOfAccounts } from '../helper';
import { accountsInfoCardsContainerStyles } from '../styles';

type AccountsInfoProps = {
  bankSummary: Object,
  totalNumberOfCards: number,
  bankDataLoading: boolean,
  cardDataLoading: boolean,
};

const AccountsInfo = ({
  bankSummary,
  totalNumberOfCards,
  bankDataLoading,
  cardDataLoading,
}: AccountsInfoProps) => {
  const { isMin768 } = useBreakPoints();
  const navigate = useNavigate();
  const { authorizedForBanking } = useContext(UserAccessContext);
  const { baselaneBankBalance = 0, bank: banks = [] } = bankSummary;
  return (
    <Stack spacing={2}>
      <Stack {...accountsInfoCardsContainerStyles}>
        {banks.length < 6 && banks.length !== 0 ? (
          banks.map((bank) => {
            const bankEntityInfo = bank?.bankAccounts?.[0] ?? {};
            const totalNumberOfEntityAvailableBalances = getTotalNumberAvailableBalance(
              bankEntityInfo
            );

            return (
              <BaselaneCardNew
                key={bank?.id ?? 'bank-entity-card'}
                variant="clickable"
                onClick={() => {
                  sendSegmentEvent('temp_dashboard_view_entity');
                  navigate(`${NB_ACCOUNTS}/baselane_${bankEntityInfo?.id}`);
                }}
                flex="1 0 284px"
                overflow="hidden"
                mb={1}
              >
                <Stack flexGrow={1} direction="row" spacing={2} overflow="hidden">
                  <Skeleton isLoaded={!(bankDataLoading && cardDataLoading)}>
                    <Box>
                      {bank?.unitAccount?.applicationType === 'SoleProprietorship' ? (
                        <IllustrationBankingSolePropAccount />
                      ) : (
                        <IllustrationBankingBusinessAccount />
                      )}
                    </Box>
                  </Skeleton>
                  <Stack spacing={0.5} overflow="hidden">
                    <Skeleton isLoaded={!(bankDataLoading && cardDataLoading)}>
                      <Heading size="headline-md" {...truncatedText}>
                        {bank?.name}
                      </Heading>
                    </Skeleton>
                    <Skeleton isLoaded={!(bankDataLoading && cardDataLoading)}>
                      <Text textStyle="sm">
                        {formatCurrency(totalNumberOfEntityAvailableBalances).inDollars}
                      </Text>
                    </Skeleton>
                  </Stack>
                </Stack>
                {banks.length === 1 && (
                  <Box>
                    <Icon16ChevronRight />
                  </Box>
                )}
              </BaselaneCardNew>
            );
          })
        ) : (
          <BaselaneCardNew variant="clickable" onClick={() => navigate({ pathname: NB_ACCOUNTS })}>
            <Stack flexGrow={1} spacing={0}>
              <Text textStyle="sm">Total Balance</Text>
              <Skeleton isLoaded={!bankDataLoading} width={bankDataLoading ? '25%' : 'initial'}>
                <Text textStyle="data-xl">{formatCurrency(baselaneBankBalance).inDollars}</Text>
              </Skeleton>
              <Skeleton isLoaded={!bankDataLoading} width={bankDataLoading ? '200px' : 'initial'}>
                <Text textStyle="sm">
                  {getPluralizedNoun(getTotalNumberOfAccounts(banks), 'account')} &#x2022;{' '}
                  {getPluralizedNoun(totalNumberOfCards, 'card')}
                </Text>
              </Skeleton>
            </Stack>
            <Box>
              <Icon16ChevronRight />
            </Box>
          </BaselaneCardNew>
        )}
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        {authorizedForBanking && (
          <MoveMoneyMenu buttonSize={isMin768 ? 'lg' : 'md'} entryPoint="dashboard" />
        )}
        <BaselaneButton
          variant="transparent"
          palette="primary"
          size={isMin768 ? 'lg' : 'md'}
          pullRight
          onClick={() => navigate({ pathname: NB_ACCOUNTS })}
        >
          View accounts
        </BaselaneButton>
      </Stack>
    </Stack>
  );
};

export default AccountsInfo;
