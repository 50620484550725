import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Box, Divider, Flex, Spacer, Stack, Text, useMediaQuery } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import {
  MODIFY_LEASE,
  GET_LEASE,
  GET_SINGLE_LEASE,
  GET_INVOICE_SUMMARY,
} from '@pages/LeasesPage/queries';
import { formatDate } from '@core/utils/formatDate';
import formatCurrency from '@core/utils/formatCurrency';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { IconEdit, IconExclamationCircle } from '@icons';
import {
  BaselaneButton,
  BaselaneDrawer,
  BaselaneHighlightedDataDisplay,
  BaselaneMessageCard,
} from '@shared/components';
import LeaseTermsForm from '@pages/LeasesPage/LeaseSection/forms/LeaseTermsForm';
import toasts from '@pages/LeasesPage/LeaseSection/helpers/toasts';
import { cleanUpPayments } from '@pages/LeasesPage/LeaseSection/helpers/createLease.helpers';
import { getEditLeaseFormArray } from '@pages/LeasesPage/LeaseSection/helpers/editLease.helpers';
import InvoiceContext from '@contexts/InvoiceContext';
import {
  DUE_DATE_OPTIONS,
  getFormInitialValues,
  wordifyDateDue,
  getInitialFeeBasedOnRent,
  getDailyFeeBasedOnRent,
  getMonthlyFeeBasedOnRent,
} from '../../formHelpers/leaseTermForm.helper';
import { getDepositAndFeesDataFormatted } from '../../formHelpers/depositsFeesReview.helper';
import LeaseTerms from './LeaseTerms';
import BoxEmptyState from '../../components/BoxEmptyState';
import {
  leaseDetailsHeader,
  leaseMediumText,
  newLeaseTermsText,
  newLeaseTermsTextDark,
  newLeaseTermsTextBold,
  leaseSmallTextGray,
  viewModeEditableHeaderStyles,
} from '../../../LeaseResponsiveDetail/styles/leaseResponsiveDetail.styles';
import PAYMENT_TYPE from '../../../models/PaymentTypeEnum';
import LateFeeSummarySection from './LateFeeSummarySection';

type LeaseTermsInAppProps = {
  variablesAndStates: Object,
  helperFunctions: Object,
  isAllowEdit: boolean,
  lease: Object,
  setLease: Function,
  isLeaseEdit: boolean,
  setIsLeaseEdit: Function,
};

function LeaseTermsInApp({
  variablesAndStates,
  helperFunctions,
  isAllowEdit = true,
  lease,
  setLease,
  isLeaseEdit: isEdit,
  setIsLeaseEdit: setIsEdit,
}: LeaseTermsInAppProps) {
  // since unit is phased out, we don't get invoiceList through useCreateLeaseVariables hook anymore
  const { invoiceList: invoicesForUnit } = useContext(InvoiceContext);
  const monthlyFeeFormRef = useRef();
  const editDrawerRef = useRef();
  const [isDesktop] = useMediaQuery('(min-width: 576px)', { ssr: false });
  const { isMax767 } = useBreakPoints();
  const [formInitialValues] = getFormInitialValues({ lease });
  const [lateFeeValues, setLateFeeValues] = useState();

  const { variables, setLeaseVariables } = variablesAndStates;

  const {
    monthlyRentAmount,
    dueDays,
    startDate,
    rentCollectionStartDate,
    endDate,
    isMonthToMonth,
    lateFeeAmount,
    initialFeeType,
    initialFeeAmount,
    initialFeePercent,
    initialDueDate,
    incrementAmountType,
    incrementAmount,
    incrementPercent,
    feeCapType,
    feeCapAmount,
    feeCapPercent,
    dailyFeesEnabled,
    initialFeeEnabled,
    lateFeeId,
    dailyLateFeeId,
  } = formInitialValues;

  const values = {
    initialFeeType,
    initialFeeAmount,
    initialFeePercent,
    initialDueDate,
    incrementAmountType,
    incrementAmount,
    incrementPercent,
    feeCapType,
    feeCapAmount,
    feeCapPercent,
    dailyFeesEnabled,
    initialFeeEnabled,
    lateFeeId,
    dailyLateFeeId,
    monthlyRentAmount,
  };

  const initialFeeBasedOnRent = getInitialFeeBasedOnRent(lateFeeValues || values);
  const dailyFeeBasedOnRent = getDailyFeeBasedOnRent(lateFeeValues || values);
  const monthlyFeeBasedOnRent = getMonthlyFeeBasedOnRent(lateFeeValues || values);

  const initialFee =
    (lateFeeValues || values)?.initialFeeType === 'FLAT'
      ? (lateFeeValues || values)?.initialFeeAmount
      : initialFeeBasedOnRent;
  const dailyFee =
    (lateFeeValues || values)?.incrementAmountType === 'FLAT'
      ? (lateFeeValues || values)?.incrementAmount
      : dailyFeeBasedOnRent;
  const monthlyFee =
    (lateFeeValues || values)?.feeCapType === 'FLAT'
      ? (lateFeeValues || values)?.feeCapAmount
      : monthlyFeeBasedOnRent;

  const initialFeePayment = {
    id: lateFeeValues?.lateFeeId,
    name: PAYMENT_TYPE.LATE_FEE,
    enabled: Boolean(initialFee),
    amount: initialFee,
    dueDays: lateFeeValues?.initialDueDate,
    lateFeeMetadata: {
      initialFeeType: lateFeeValues?.initialFeeType,
      initialFeeValue:
        lateFeeValues?.initialFeeType === 'FLAT'
          ? lateFeeValues?.initialFeeAmount
          : lateFeeValues?.initialFeePercent,
    },
  };
  const dailyFeePayment = {
    id: lateFeeValues?.dailyLateFeeId,
    name: PAYMENT_TYPE.DAILY_LATE_FEE,
    enabled: Boolean(dailyFee),
    amount: dailyFee,
    dueDays: null,
    lateFeeMetadata: {
      incrementFeeType: lateFeeValues?.incrementAmountType,
      incrementFeeValue:
        lateFeeValues?.incrementAmountType === 'FLAT'
          ? lateFeeValues?.incrementAmount
          : lateFeeValues?.incrementPercent,
      feeCapType: lateFeeValues?.feeCapType,
      feeCapValue:
        lateFeeValues?.feeCapType === 'FLAT'
          ? lateFeeValues?.feeCapAmount
          : Number(lateFeeValues?.feeCapPercent),
    },
  };

  const lateFeeHasChanges = () => {
    const late = lease?.payments?.find((payment) => payment.name === 'LATE_FEE');
    const daily = lease?.payments?.find((payment) => payment.name === 'DAILY_LATE_FEE');

    const hasChanges =
      late?.amount !== lateFeeValues?.initialFeeAmount ||
      late?.lateFeeMetadata?.initialFeeType !== lateFeeValues?.initialFeeType ||
      daily?.lateFeeMetadata?.incrementFeeType !== lateFeeValues?.incrementAmountType ||
      daily?.lateFeeMetadata?.incrementFeeValue !== lateFeeValues?.incrementAmount ||
      daily?.lateFeeMetadata?.feeCapType !== lateFeeValues?.feeCapType ||
      daily?.lateFeeMetadata?.feeCapValue !== lateFeeValues?.feeCapValue;

    return hasChanges;
  };

  // update Lease on B.E.
  const [setLeaseData] = useMutation(MODIFY_LEASE);

  const { setAllFormVariables, setLateFeePayments } = helperFunctions;

  const [isReview, setIsReview] = useState(false);
  const [saving, setSaving] = useState(false);

  const { showEditLeaseSuccessToast, showEditLeaseErrorToast } = toasts();

  const leaseTermsRef = useRef(null);

  const rDueDay = DUE_DATE_OPTIONS.find((option) => option.id === dueDays)?.label;
  const sDate = formatDate(startDate);
  const rsDate = formatDate(rentCollectionStartDate);
  const eDate = isMonthToMonth ? 'Month-to-month' : formatDate(endDate);

  // disable startDate field if today or in the past
  const isStartDateDisabled = moment(sDate) < moment();

  // Getting the fees
  const {
    dEnabled,
    dAmount,
    dDueDate,
    dStatus,
    lEnabled,
    lDueDate,
    lStatus,
    pEnabled,
    pAmount,
    pDueDate,
    fees,
  } = getDepositAndFeesDataFormatted(lease);

  // Atm the recurring monthly fee frequency says 'Recurring monthly' and dueDate 'Monthly'
  // but make sure that if one is dropped or changed this still works
  const recurringMonthlyFees = fees.filter(
    (fee) =>
      fee.dueDate.toLowerCase() === 'monthly' || fee.frequency.toLowerCase().includes('monthly')
  );

  // changed lease properties (as opposed to URC this is edit lease)
  // lease terms
  const changedStartDate = variables.startDate ? formatDate(variables.startDate) : null;
  let changedEndDate = null;
  if (variables.leaseType === 'MONTHLY') {
    changedEndDate = 'Month-to-month';
  } else if (variables.endDate) {
    changedEndDate = formatDate(variables.endDate);
  }
  const changedMonthlyRentAmount = variables.payments?.filter(
    (fee) => fee.name.toLowerCase() === 'rent'
  )[0]?.amount;
  const changedRecurringStartDate =
    variables.rentCollectionStartDate &&
    formInitialValues.rentCollectionStartDate !== variables.rentCollectionStartDate
      ? formatDate(variables.rentCollectionStartDate)
      : null;
  const hasChangedLeaseTerms = !!(changedStartDate || changedEndDate || changedMonthlyRentAmount);

  // enable warning banner if rentCollectionStartDate is more than one day into the future
  const isFutureStartDateBannerEnabled = moment()
    .add(1, 'months')
    .isSameOrBefore(changedRecurringStartDate);

  // changed late fee
  const hasChangedLateFee = lateFeeHasChanges();

  // changed recurring monthly fees
  const hasChangedRecurringMonthlyFees =
    variables.payments?.filter((fee) => {
      if (fee.name !== 'RENT' && fee.name !== 'LATE_FEE' && fee.frequency === 'MONTHLY') {
        let isChanged = false;
        if (!fee.id || fee.delete) {
          isChanged = true;
        }
        return isChanged;
      }
      return false;
    }).length > 0;

  // total has changed values check
  const hasChangedValues =
    hasChangedLeaseTerms || hasChangedLateFee || hasChangedRecurringMonthlyFees;

  const handleClickReview = () => {
    // set DEBUG to true to console.debug() most pertinent data
    const DEBUG = false;

    // monthly fee boxes are not part of default form, check these separately
    const monthlyFeesOpen = document.getElementById('monthly-fees-box');

    // monthly fees has its own form so that's why we need this for it (and not for late fees)
    if (monthlyFeesOpen) {
      // if monthly fee box is open and completely filled out, we allow footer Save click
      // this saves the open fee or catches any errors
      monthlyFeeFormRef?.current
        ?.submitForm()
        .then(() => {
          leaseTermsRef?.current?.validateForm().then((errors) => {
            // only advance if there is no errort
            if (Object.keys(errors).length === 0 && (hasChangedValues || monthlyFeesOpen)) {
              setIsReview(true);
            }
          });
        })
        .catch((errors) => DEBUG && console.log('errors', errors));
    } else {
      leaseTermsRef?.current?.validateForm().then((errors) => {
        // only advance if there is no errort
        if (Object.keys(errors).length === 0 && hasChangedValues) {
          setIsReview(true);
        }
      });
    }
  };

  const handleSetFormVariables = (newVariables) => {
    setAllFormVariables(newVariables);
  };

  function saveLease({ newVariables = {} } = {}) {
    const result = { ...variables, ...newVariables };

    // result.id = unit.lease.id;
    // this is different from unified RC
    if (result.id) {
      delete result.id;
    }
    // we shoulnd't be sending endDate as empty, ever unless we toggle to month-to-month
    if (result?.endDate === '') {
      delete result.endDate;
    }
    if (result.leaseType) {
      delete result.leaseType;
    }
    if (result.propertyUnitId) {
      delete result.propertyUnitId;
    }
    if (result.tenantProfileId) {
      delete result.tenantProfileId;
    }
    // make sure some useEffect cannot force double saves
    setSaving(true);

    const cleanedPayments = cleanUpPayments({ payments: variables.payments });
    const filteredPayments = cleanedPayments.filter(
      (payment) => payment.name !== 'LATE_FEE' && payment.name !== 'DAILY_LATE_FEE'
    );

    const payments =
      initialFeePayment.amount === 0
        ? filteredPayments
        : [...filteredPayments, initialFeePayment, dailyFeePayment];

    setLeaseData({
      variables: {
        id: lease.id,
        input: {
          ...result,
          payments,
        },
      },
      refetchQueries: [
        {
          query: GET_LEASE,
        },
        {
          query: GET_SINGLE_LEASE,
          variables: { input: { leaseId: lease.id } },
        },
        {
          query: GET_INVOICE_SUMMARY,
        },
      ],
    })
      .then((res) => {
        setSaving(false);
        if (res.errors?.length > 0) {
          showEditLeaseErrorToast();
        } else {
          const { state } = res.data?.modifyLease || {};
          if (state === 'CANCELED') {
            // probably not reset variables from helperFunctions, but point user at error?
            // resetValue();
          } else {
            // set lease details with the response from modifyLease
            setLease(res?.data?.modifyLease);
            showEditLeaseSuccessToast();
            setIsReview(false);
            setIsEdit(false);
          }
        }
      })
      .catch((error) => {
        setSaving(false);
        showEditLeaseErrorToast();
      });
  }

  const handleSave = () => {
    if (!saving) {
      saveLease();
    }
  };

  useEffect(() => {
    // add in existing late fee if any so change vars picks it up then only display if changed
    setLeaseVariables({ ...variables, payments: formInitialValues.additionalFeesFields });
  }, [lease]);

  useEffect(
    () => () => {
      if (editDrawerRef?.current) {
        editDrawerRef.current.open();
      }
    },
    [editDrawerRef, isEdit]
  );

  const editLeaseFormArray = getEditLeaseFormArray(variables);
  const reversedEditLeaseFormArray = [...editLeaseFormArray];
  reversedEditLeaseFormArray.reverse();

  const handleKeyDown = (e) => {
    if (
      !e.shiftKey &&
      e.key === 'Tab' &&
      editLeaseFormArray.indexOf(e.target.id) !== editLeaseFormArray.length - 1
    ) {
      let tabbed = false;
      editLeaseFormArray.forEach((item, index) => {
        const nextFieldInForm = item;
        if (
          !tabbed &&
          index > editLeaseFormArray.indexOf(e.target.id) &&
          index < editLeaseFormArray.length
        ) {
          if (document.getElementById(nextFieldInForm)) {
            document.getElementById(nextFieldInForm)?.focus();
            tabbed = true;
          }
        }
      });
    } else if (!e.shiftKey && e.key === 'Tab') {
      document.getElementById(editLeaseFormArray[0])?.focus();
    } else if (e.shiftKey && e.key === 'Tab' && editLeaseFormArray.indexOf(e.target.id) > 0) {
      let tabbed = false;
      reversedEditLeaseFormArray.forEach((item, index) => {
        const nextFieldInForm = item;
        if (!tabbed && index > reversedEditLeaseFormArray.indexOf(e.target.id)) {
          if (document.getElementById(nextFieldInForm)) {
            document.getElementById(nextFieldInForm)?.focus();
            tabbed = true;
          }
        }
      });
    }
  };

  const leaseTermsSummaryConfig = {
    items: [
      {
        id: 'start-date-review',
        key: (
          <Flex alignItems="center">
            <Text mr="8px">Lease start date</Text>
          </Flex>
        ),
        value: changedStartDate || sDate,
        subValue: changedStartDate && `Original: ${sDate}`,
      },
      {
        id: 'end-date-review',
        key: (
          <Flex alignItems="center">
            <Text mr="8px">Lease end date</Text>
          </Flex>
        ),
        value: changedEndDate || eDate,
        subValue: changedEndDate && `Original: ${eDate}`,
      },
      {
        id: 'rent-amount-review',
        key: 'Monthly rent amount',
        value: changedMonthlyRentAmount
          ? formatCurrency(changedMonthlyRentAmount).inDollars
          : monthlyRentAmount,
        subValue: changedMonthlyRentAmount && `Original: ${monthlyRentAmount}`,
      },
      {
        id: 'rent-due-on-review',
        key: 'Rent is due on',
        value: rDueDay,
        subValue: null,
      },
      {
        id: 'recurring-rent-start-review',
        key: 'Changes effective from',
        value: changedRecurringStartDate || rsDate,
      },
    ],
    styles: {
      parent: {
        bg: 'brand.blue.100',
        mb: '16px',
        p: '8px 16px 16px',
      },
    },
  };

  const leaseTermsSummarySection =
    leaseTermsSummaryConfig.items.length > 0 ? (
      <BaselaneHighlightedDataDisplay configuration={leaseTermsSummaryConfig} />
    ) : null;

  const reviewedRecurringMonthlyFeesConfig = {
    title: 'Recurring monthly fees with rent',
    items: variables.payments
      ?.filter(
        (fee) => fee.name !== 'RENT' && fee.name !== 'LATE_FEE' && fee.frequency === 'MONTHLY'
      )
      .map((fee) => {
        let existingFee = null;
        recurringMonthlyFees.forEach((originalFee) => {
          if (fee.id === originalFee.id) {
            existingFee = fee;
          }
        });
        if (existingFee) {
          return {
            key: fee.description,
            value: formatCurrency(fee.amount).inDollars,
            subValue: existingFee.delete ? 'Deleted' : null,
          };
        }
        return {
          key: fee.description,
          value: formatCurrency(fee.amount).inDollars,
          subValue: 'New',
        };
      }),
    styles: {
      parent: {
        bg: 'brand.blue.100',
        mb: '16px',
        p: '12px 16px 16px',
      },
    },
    hasMobileTitle: true,
  };

  const recurringMonthlyFeesSection = (
    <BaselaneHighlightedDataDisplay configuration={reviewedRecurringMonthlyFeesConfig} />
  );

  // New list type
  let recurringMonthlyFeesTotal = 0;
  const monthlyRentBreakdown = recurringMonthlyFees.map((fee, index) => {
    const { amount, description } = fee;
    recurringMonthlyFeesTotal += Number(amount.replace(/\$|,/g, ''));
    return {
      id: index + 1,
      label: description,
      value: amount,
    };
  });
  monthlyRentBreakdown.unshift({
    id: 0,
    label: 'Monthly rent',
    value: monthlyRentAmount,
  });
  const monthlyRentBreakdownTotal =
    recurringMonthlyFeesTotal +
    Number(monthlyRentAmount.replace(/\$|,/g, '')) +
    Number(lateFeeAmount.replace(/\$|,/g, ''));

  const oneTimeFee = fees.filter((fee) => fee.frequency.toLowerCase() === 'one time');

  const hasNoDepositsFees = !dEnabled && !lEnabled && !pEnabled && oneTimeFee.length === 0;

  // Empty Deposits Fees
  const emptyDepositsFees = (
    <BoxEmptyState text="No deposits or one-time payments included in this rent collection." />
  );

  const feeWithoutDate = (label, amount, total) => (
    <Flex direction="row" justifyContent="space-between" key="id">
      <Text {...newLeaseTermsText}>{label}</Text>
      <Text {...(total ? newLeaseTermsTextBold : newLeaseTermsTextDark)}>{amount}</Text>
    </Flex>
  );

  const feeWithDateOrStatus = (label, dueDate, amount, status, hasDivider = true) => (
    <Box key={uuidv4()}>
      <Flex direction="row" justifyContent="space-between">
        <Stack>
          <Text {...newLeaseTermsText}>{label}</Text>
          {status === 'Yes' ? (
            <Flex mt="0 !important">
              <Box m="3px 2px 0 1px">
                <IconExclamationCircle color="#6C7884" w={13.33} h={13.33} />
              </Box>
              <Text {...leaseSmallTextGray} ml="4px" mt="0 !important">
                Collected outside of Baselane
              </Text>
            </Flex>
          ) : (
            <Text {...leaseSmallTextGray} mt="0 !important">
              Due on: {dueDate}
            </Text>
          )}
        </Stack>
        <Text {...newLeaseTermsTextBold}>{amount}</Text>
      </Flex>
      {hasDivider && <Divider h="1px" bg="brand.darkBlue.150" mt="16px" mb="16px" />}
    </Box>
  );

  const LeftIcon = <IconEdit />;

  const { DrawerBody } = BaselaneDrawer;

  return (
    <>
      <Flex direction="column" {...viewModeEditableHeaderStyles(isDesktop)}>
        <Box w="100%">
          <Flex direction="row" justifyContent="space-between">
            <Text {...leaseDetailsHeader}>Rent collection terms</Text>
          </Flex>
          <Spacer h={isDesktop ? '36px' : '24px'} />
          {isAllowEdit && (
            <BaselaneButton
              id="edit-lease-button"
              variant="outline"
              palette="neutral"
              size="md"
              onClick={() => setIsEdit(true)}
              leftIcon={LeftIcon}
            >
              Edit Terms
            </BaselaneButton>
          )}
          <Box mt={isDesktop ? '30px' : '28px'} mb={isDesktop ? '32px' : '22px'}>
            <LeaseTerms {...{ lease, viewMode: true, isInactive: true, hasTitle: false }} />
          </Box>
          <Text {...leaseMediumText} mb="26px">
            Monthly rent breakdown
          </Text>
          {monthlyRentBreakdown.map((item, index) => (
            <Box key={item.id}>
              {feeWithoutDate(item.label, item.value, false)}
              {index !== monthlyRentBreakdown.length - 1 && <Spacer h="20px" />}
            </Box>
          ))}
          <Divider h="1px" bg="brand.darkBlue.150" mt="14px" mb="16px" />
          {feeWithoutDate('Total', formatCurrency(monthlyRentBreakdownTotal).inDollars, true)}
          {initialFee ? (
            <Flex my={1} py={1} alignItems="center" justifyContent="space-between" w="100%">
              <Text textStyle="sm">Late fee</Text>
              <Box>
                <Text textStyle="sm" textAlign="right">
                  {values.initialFeeType === 'FLAT'
                    ? formatCurrency(initialFee).inDollars
                    : `${values.initialFeePercent}% rent`}{' '}
                  if late by {values.initialDueDate} days
                </Text>
                {dailyFee ? (
                  <Text textStyle="sm" textAlign="right">
                    {values.incrementAmountType === 'FLAT'
                      ? `+${formatCurrency(dailyFee).inDollars}`
                      : `+${values.incrementPercent}% rent`}{' '}
                    per additional day
                  </Text>
                ) : null}
                {monthlyFee ? (
                  <Text textStyle="sm" textAlign="right">
                    (up to{' '}
                    {values.feeCapType === 'FLAT'
                      ? `${formatCurrency(monthlyFee).inDollars} per month`
                      : `${values.feeCapPercent}% per month`}
                    )
                  </Text>
                ) : null}
              </Box>
            </Flex>
          ) : null}
          {hasNoDepositsFees ? (
            <Box m="44px 0 64px">{emptyDepositsFees}</Box>
          ) : (
            <Box m={isDesktop ? '44px 0 64px' : '36px 0 64px'}>
              <Text {...leaseMediumText} mb={isDesktop ? '26px' : '16px'}>
                One Time Fees
              </Text>
              {dEnabled && feeWithDateOrStatus('Security deposit', dDueDate, dAmount, dStatus)}
              {lEnabled &&
                feeWithDateOrStatus("Last month's rent", lDueDate, monthlyRentAmount, lStatus)}
              {pEnabled && feeWithDateOrStatus('Prorated rent', pDueDate, pAmount)}
              {oneTimeFee.length > 0 &&
                oneTimeFee.map((item, index) =>
                  feeWithDateOrStatus(
                    item.description,
                    item.dueDate,
                    item.amount,
                    false,
                    index < oneTimeFee.length - 1
                  )
                )}
            </Box>
          )}
        </Box>
      </Flex>
      <BaselaneDrawer
        id={isReview ? 'review-lease-terms-section' : 'edit-lease-terms-section'}
        onKeyDown={(e) => handleKeyDown(e)}
        height="100%"
        isOpen={isEdit}
        title="Rent collection details"
        onClose={() => setIsEdit(false)}
        closeEvent={() => setIsEdit(false)}
        hideOverlay
        size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
        contentStyles={isMax767 && { top: 'auto !important' }}
        newDesignDrawer
        hideBackText
        footer={
          <Stack direction="row" width="100%" gap={1}>
            {(isDesktop || isReview) && (
              <BaselaneButton
                variant={isDesktop ? 'outline' : 'transparent'}
                palette="neutral"
                size="md"
                id={isReview ? 'back_button' : 'cancel_button'}
                onClick={
                  isReview
                    ? () => setIsReview(false)
                    : () => {
                        setIsEdit(false);
                        setLeaseVariables({
                          id: variables.id,
                          propertyUnitId: variables.propertyUnitId,
                          payments: formInitialValues.additionalFeesFields,
                        });
                      }
                }
              >
                {isReview ? 'Back to Edit' : 'Cancel'}
              </BaselaneButton>
            )}
            <BaselaneButton
              variant="filled"
              palette="primary"
              size="md"
              id={isReview ? 'save-button' : 'review-button'}
              onClick={isReview ? () => handleSave() : () => handleClickReview()}
              isDisabled={saving || !hasChangedValues || !variables.rentCollectionStartDate}
            >
              Save Changes
            </BaselaneButton>
          </Stack>
        }
      >
        <DrawerBody p={0}>
          <Box h="100%">
            <Stack direction="row" {...viewModeEditableHeaderStyles(isDesktop)}>
              <Text {...leaseDetailsHeader}>
                {isReview ? 'Review changes' : 'Edit rent collection terms'}
              </Text>
            </Stack>
            <Box
              position={isReview ? 'absolute' : 'relative'}
              top={isReview ? '-10000px' : '0px'}
              height={isReview ? '1px' : 'happy'}
              pb="120px"
            >
              <LeaseTermsForm
                {...{
                  formVariables: variables,
                  setLateFeePayments,
                  setFormVariables: handleSetFormVariables,
                  leaseTermsRef,
                  activeTab: 1,
                  saveLease,
                  lease,
                  unifiedRC: false,
                  isStartDateDisabled,
                  invoicesForUnit,
                  monthlyFeeFormRef,
                  showBottomBorder: false,
                  onLateFeeValuesChange: setLateFeeValues,
                }}
              />
            </Box>
            {isReview && (
              <Box pb="120px">
                {isFutureStartDateBannerEnabled && (
                  <BaselaneMessageCard
                    iconName={isDesktop ? 'info' : ''}
                    text={`Changes will take effect starting on the ${wordifyDateDue(
                      changedRecurringStartDate
                    )} rent collection cycle.
                  No updates will be made to invoices with processing payments.`}
                    borderColor="blue"
                    iconColor="blue"
                    containerStyles={{ mb: isDesktop ? '32px' : '16px' }}
                    textStyles={{
                      fontWeight: isDesktop ? 'medium' : 'normal',
                      color: isDesktop ? 'brand.neutral.700' : 'brand.neutral.600',
                    }}
                    isVertical
                  />
                )}
                <Text {...newLeaseTermsTextBold} mb={isDesktop ? '0' : '16px'}>
                  Summary of changes
                </Text>
                {leaseTermsSummarySection}
                {(!!recurringMonthlyFees.length || hasChangedRecurringMonthlyFees) &&
                  recurringMonthlyFeesSection}
                {lateFeeValues && hasChangedLateFee && (
                  <LateFeeSummarySection data={lateFeeValues} />
                )}
              </Box>
            )}
          </Box>
        </DrawerBody>
      </BaselaneDrawer>
    </>
  );
}

export default LeaseTermsInApp;
