import React, { useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  Text,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { USER_WORKSPACE } from '@routes';
import { REVOKE_COLLABORATOR_ACCESS, GET_CURRENT_WORKSPACE } from '@core/apollo/queries';
import { BaselaneButton } from '@shared/components';

type RevokeInviteAlertProps = {
  isAlertOpen: boolean,
  onAlertClose: Function,
  memberId: Number,
};

const RevokeInviteAlert = ({ isAlertOpen, onAlertClose, memberId }: RevokeInviteAlertProps) => {
  const navigate = useNavigate();
  const alertCancelRef = useRef(null);
  const [revokeAccess] = useMutation(REVOKE_COLLABORATOR_ACCESS, {
    refetchQueries: [GET_CURRENT_WORKSPACE],
  });
  const toast = useToast();
  const showSuccessToast = async () =>
    toast({
      position: 'bottom-left',
      description: 'Revoked Invite',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Error revoking invite',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleRevokeMemberInvite = () => {
    onAlertClose();
    revokeAccess({
      variables: {
        id: memberId,
      },
    })
      .then((res) => {
        if (res.data) {
          showSuccessToast();
          setTimeout(() => {
            onAlertClose();
            navigate({
              pathname: USER_WORKSPACE,
            });
          });
        }
        if (res.errors) {
          showErrorToast();
        }
      })
      .catch((err) => showErrorToast());
  };
  return (
    <AlertDialog
      leastDestructiveRef={alertCancelRef}
      onClose={onAlertClose}
      isOpen={isAlertOpen}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="sm"
    >
      <AlertDialogOverlay />
      <AlertDialogContent p={4} minHeight="168px" maxHeight="calc(100% - 100px)" overflow="auto">
        <Stack spacing={2} mb="3">
          <Stack spacing={0.5}>
            <AlertDialogHeader p={0}>
              <Text textStyle="headline-lg">Revoking invite to this member?</Text>
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody p={0}>
              <Text textStyle="sm">
                They will no longer be able to join your workspace unless you invite them again.
              </Text>
            </AlertDialogBody>
          </Stack>
          ;
        </Stack>
        <Stack direction="row" w="100%" spacing={1.5}>
          <BaselaneButton
            size="md"
            variant="outline"
            palette="neutral"
            onClick={onAlertClose}
            isFullWidth
          >
            Cancel
          </BaselaneButton>
          <BaselaneButton
            size="md"
            variant="filled"
            palette="danger"
            onClick={() => {
              handleRevokeMemberInvite();
            }}
            isFullWidth
          >
            Revoke invite
          </BaselaneButton>
        </Stack>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RevokeInviteAlert;
