import React, { forwardRef } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import ParentListItem from '../../ParentListItem';
import CheckboxListItem from '../../CheckboxListItem';
import DropdownContentWrapper from '../../DropdownContentWrapper';

import { setIsSelectedItem } from '../../helpers/dropdown.shared.helpers';
import { t1Search } from '../../utils/search';

type ListOfOptionsWithCheckboxesProps = {
  handleListItemClick: Function,
  options: Array<any>,
  selectedStagedOptions: Array<any>,
  itemRenderer: Function,
  hasFilterWrapper: boolean,
  searchTerm: Array<string>,
  search: string,
  showDivider: boolean,
};

const ListOfOptionsWithCheckboxes = forwardRef(
  (
    {
      handleListItemClick,
      options,
      selectedStagedOptions,
      itemRenderer,
      hasFilterWrapper,
      searchTerm,
      search,
      showDivider,
    }: ListOfOptionsWithCheckboxesProps,
    ref
  ) => {
    return (
      <DropdownContentWrapper {...{ hasFilterWrapper, ref }}>
        {options.map((option, index) => {
          const availableItems = t1Search({ options: option.items, searchTerm, search });

          return (
            <ParentListItem
              key={`parent-${option.title}`}
              title={option.title}
              showDivider={showDivider}
              index={index}
              listItem={
                <Box
                  key={option.title}
                  {...{
                    textStyle: 'headline-xs',
                  }}
                >
                  {option.title}
                </Box>
              }
            >
              {availableItems?.map((item, innerIndex) => (
                <HStack key={item.id} align="center">
                  <CheckboxListItem
                    tabIndex="-1"
                    className="element"
                    key={item.id}
                    id={item.id}
                    onChange={(e) => handleListItemClick(item, e)}
                    isChecked={setIsSelectedItem(item, selectedStagedOptions)}
                  >
                    {itemRenderer({ item, parentItem: option, index: innerIndex, search })}
                  </CheckboxListItem>
                </HStack>
              ))}
            </ParentListItem>
          );
        })}
      </DropdownContentWrapper>
    );
  }
);

export default ListOfOptionsWithCheckboxes;
