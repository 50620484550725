// @flow
import React, { useRef, useState } from 'react';
import { List } from '@chakra-ui/react';
import { Reorder } from 'framer-motion';
import BaselaneSortableListItem from './BaselaneSortableListItem';

type BaselaneSortableListProps = {
  items: Array<Object>,
  setItems: Function,
  isHandleDrag: Boolean,
  onReorder: Function,
  renderItem?: Function,
};

const hasOrderChanged = (oldArray, newArray) => {
  if (oldArray.length !== newArray.length) return true;
  return oldArray.some((item, index) => item.id !== newArray[index].id);
};

function BaselaneSortableList({
  items = [],
  setItems = () => {},
  isHandleDrag = false,
  onReorder = () => {},
  renderItem = null,
  ...rest
}: BaselaneSortableListProps): any {
  const [previousItems, setPreviousItems] = useState([...(items || [])]);
  const listRef = useRef(null);

  const onReorderItems = (values) => {
    if (hasOrderChanged(items, values)) {
      setItems(values);
    }
  };

  const onPointerUp = () => {};

  const onTransitionEnd = () => {
    if (hasOrderChanged(previousItems, items)) {
      onReorder(items);
      setPreviousItems(items);
    }
  };

  const listStyles = {
    display: 'flex',
    gap: 0,
    flexDirection: 'column',
    position: 'relative',
  };
  return (
    items?.length > 0 && (
      <List
        ref={listRef}
        as={Reorder.Group}
        axis="y"
        layoutScroll
        values={items}
        onReorder={onReorderItems}
        onPointerUp={onPointerUp}
        {...listStyles}
        {...rest}
      >
        {renderItem
          ? items?.map((value, index, array) => renderItem(value, onTransitionEnd, index, array))
          : items?.map((item) => {
              return (
                <BaselaneSortableListItem
                  key={item.id}
                  onTransitionEnd={onTransitionEnd}
                  isHandleDrag={isHandleDrag}
                  item={item}
                />
              );
            })}
      </List>
    )
  );
}

export default BaselaneSortableList;
