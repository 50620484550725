// @flow
import React, { useRef } from 'react';
import { Formik } from 'formik';
import { ChakraProvider, Stack, useToast, Box } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { UPDATE_COLLABORATOR_ACCESS } from '@core/apollo/queries';
import { useCurrentWorkspace } from '@shared/hooks';
import useBreakPoints from '@core/hooks/useBreakPoints';
import habitatTheme from '@core/themeHabitat';
import onDrawerClose from '@core/utils/onDrawerClose';
import {
  BaselaneButton,
  BaselaneDrawer,
  BaselaneSummaryCard,
  BaselaneSummaryCardDefaultHeader,
} from '@shared/components';
import InviteCollaboratorAccess from '../InviteCollaborator/InviteCollaboratorAccess';

function EditCollaboratorAccessDrawer(): any {
  const { memberId } = useParams();
  const { refetchCurrentWorkspace, collaborators } = useCurrentWorkspace();
  const [updateCollaboratorAccess] = useMutation(UPDATE_COLLABORATOR_ACCESS);
  const { isMax576 } = useBreakPoints();
  const navigate = useNavigate();

  const member = memberId
    ? collaborators?.find((m) => m.id === Number(memberId))
    : collaborators?.find((m) => m.isOwner === true);

  const isOwner = member?.isOwner;
  const formikRef = useRef(null);

  const initialValues = { authorizedForBanking: member?.authorizedForBanking ? '1' : '2' };

  const displayName =
    member?.firstName && member?.lastName
      ? `${member.firstName} ${member.lastName}`.trim()
      : member?.lastName || member?.email || '';

  // Toast
  const toast = useToast();
  const showSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Updated collaborator access',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Error updating collaborator access',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleValidation = (values) => {
    const errors = {};

    if (values.authorizedForBanking === '-1') {
      errors.authorizedForBanking = 'You need to choose banking access';
    }
    return errors;
  };

  const handleDrawerClose = () => {
    navigate(-1);
  };

  const handleSubmit = (values) => {
    updateCollaboratorAccess({
      variables: {
        input: {
          id: Number(memberId),
          authorizedForBanking: values?.authorizedForBanking === '1',
        },
      },
    })
      .then((res) => {
        if (res.data) {
          showSuccessToast();
          refetchCurrentWorkspace();
        }

        if (res.errors) {
          showErrorToast();
        }
      })
      .catch((err) => {
        showErrorToast();
      });

    setTimeout(() => 2000);
    navigate(-2);
  };

  return (
    <ChakraProvider theme={habitatTheme}>
      <Formik
        innerRef={formikRef}
        validateOnChange
        validateOnBlur
        validateOnMount
        initialValues={initialValues}
        validate={handleValidation}
      >
        {(formikProps) => {
          const { dirty, values, touched, errors } = formikProps;

          return (
            <BaselaneDrawer
              isOpen
              size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
              title="Edit access"
              closeEvent={() => onDrawerClose(navigate)}
              onOverlayClick={() => onDrawerClose(navigate)}
              hideOverlay
              newDesignDrawer
              footer={
                <>
                  <BaselaneButton
                    size="md"
                    variant="outline"
                    palette="neutral"
                    onClick={() => handleDrawerClose({ dirty })}
                  >
                    Cancel
                  </BaselaneButton>
                  <BaselaneButton
                    size="md"
                    variant="filled"
                    palette="primary"
                    width="100%"
                    ml={1.5}
                    flex={1}
                    onClick={() => handleSubmit(values)}
                    isDisabled={
                      !values?.authorizedForBanking ||
                      (errors.authorizedForBanking && touched.authorizedForBanking)
                    }
                  >
                    Save
                  </BaselaneButton>
                </>
              }
            >
              <Stack>
                <Box mb="3">
                  <BaselaneSummaryCard
                    isCustom
                    header={
                      <BaselaneSummaryCardDefaultHeader
                        title={displayName}
                        subtitle={member?.email}
                      />
                    }
                    list={[
                      {
                        id: 'member-detail-id',
                        items: [
                          {
                            id: 'role-id',
                            label: 'Role',
                            value: isOwner ? 'Owner' : 'Collaborator',
                          },
                        ],
                      },
                    ]}
                  />
                </Box>

                <InviteCollaboratorAccess from="EditCollaboratorAccessDrawer" />
              </Stack>
            </BaselaneDrawer>
          );
        }}
      </Formik>
    </ChakraProvider>
  );
}

export default EditCollaboratorAccessDrawer;
