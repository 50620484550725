import { cssVar, defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

const baseStyle = defineStyle({
  [$startColor.variable]: 'colors.brand.neutral.100',
  [$endColor.variable]: 'colors.brand.neutral.300',
  background: $startColor.reference,
  borderColor: $endColor.reference,
  opacity: 0.7,
  borderRadius: 'sm',
});

export const Skeleton = defineStyleConfig({
  baseStyle,
});
