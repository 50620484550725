import React from 'react';
import { Stack, Heading, Text, VStack, HStack, Box } from '@chakra-ui/react';
import { Icon24Invite } from '@icons/24px';
import { BaselaneCardNew } from '@shared/components';
import FormWrapper from '../Form/FormWrapper';
import useInvitationLink from '../../useInvitationLink';

import { signupColumnContainerStyles } from '../../styles/signup.styles';
// import { signupColumnContainerStyles, signupHeadingStyles } from '../../styles/signup.styles';
import {
  // signupTestsPrimaryColumnStyles,
  signupTestsSecondaryColumnStyles,
} from '../../styles/signupTests.styles';

type SignUpCollaboratorProps = {
  referrer: String,
  isReferral: Boolean,
  signUpVariant: String,
};

const SignUpCollaborator = ({ referrer, isReferral, signUpVariant }: SignUpCollaboratorProps) => {
  const { collaborator } = useInvitationLink();

  const signupHeadingStyles = {
    color: 'white',
    fontSize: { base: '4xl', xl: '6xl' },
    lineHeight: { base: '40px', xl: '56px' },
    w: { base: '100%', l: '500px', xl: '564px' },
    maxW: { base: '100%', xl: '600px' },
    mt: '0px !important',
    fontWeight: '500',
    letterSpacing: '-1.28px',
    align: { base: 'center', xl: 'left' },
    mr: { base: '5', xl: '0' },
    spacing: '4',
    textAlign: { base: 'center', xl: 'left' },
  };

  const signupTestsPrimaryColumnStyles = {
    h: '100%',
    maxW: { base: '100%', xl: '560px' },
    w: '100%',
    boxSizing: 'border-box',
    justifyContent: 'center',
  };

  return (
    <Stack
      {...signupColumnContainerStyles}
      pt={{ base: 1.5, lg: 2, xl: 8 }}
      pb={{ base: 3, lg: 4, xl: 10 }}
      justifyContent="space-between"
      gap={0}
      h="calc(100vh - 65px)"
    >
      <VStack {...signupTestsPrimaryColumnStyles}>
        <Heading {...signupHeadingStyles}>Create an account to join workspace</Heading>
        <BaselaneCardNew
          size="md"
          variant="normal"
          palette="primary"
          width="100%"
          bg="brand.darkBlue.800A"
          borderColor="brand.darkBlue.800A"
          mt={3}
          mb={6}
        >
          <HStack spacing={1.5} align="left">
            <Box
              bg="#E1FEF2"
              borderRadius="full"
              w="48px"
              h="48px"
              alignContent="center"
              align="center"
            >
              <Icon24Invite />
            </Box>
            <Stack spacing="0" textAlign="left">
              <Text textStyle="headline-md" color="white">
                {collaborator?.landlord?.name} invited you to join their workspace
              </Text>
              <Text textStyle="sm" color="brand.darkBlue.300">
                Create an account for{' '}
                <Text as="span" textStyle="sm">
                  {collaborator?.email}
                </Text>{' '}
                to accept invitation{' '}
              </Text>
            </Stack>
          </HStack>
        </BaselaneCardNew>
      </VStack>
      <VStack
        {...signupTestsSecondaryColumnStyles}
        minH={{ base: '100%', xl: '690px' }}
        justifyContent="space-between"
      >
        <FormWrapper isReferral initialEmail={collaborator?.email} isCollaborator />
      </VStack>
    </Stack>
  );
};

export default SignUpCollaborator;
