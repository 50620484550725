import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { FormControl, useDisclosure } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { BaselaneSingleDatePicker, BaselaneFormErrorMessage } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import useValidDates from '@hooks/useValidDates';

const EndDateDatepicker = () => {
  const { isMin768 } = useBreakPoints();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { excludedDates, getValidDate, endDate } = useValidDates();
  const { values, setFieldValue } = useFormikContext();
  const [minDate, setMinDate] = React.useState(endDate);

  useEffect(() => {
    const endDateIsBeforeStartDate =
      DateTime.fromJSDate(values.recurringTransfer.endDate).diff(
        DateTime.fromJSDate(values.recurringTransfer.startDate),
        'days'
      ).days < 0;

    const isSameDay = DateTime.fromJSDate(values.recurringTransfer.startDate).hasSame(
      DateTime.fromJSDate(values.recurringTransfer.endDate),
      'day'
    );

    if (endDateIsBeforeStartDate || isSameDay) {
      // Calculating a new valid end date.
      const newEndDate = getValidDate(
        DateTime.fromJSDate(values.recurringTransfer.startDate).plus({ days: 1 })
      ).toJSDate();

      // Setting the new end date.
      setFieldValue('recurringTransfer.endDate', newEndDate);
      setMinDate(newEndDate);
    }
  }, [values.recurringTransfer.startDate]);

  const isWeekday = (date) => {
    const day = date.getDay(date);
    return day !== 0 && day !== 6;
  };

  return (
    <Field name="recurringTransfer.endDate">
      {({ field, form }) => {
        return (
          <FormControl
            m={0}
            isRequired
            isInvalid={
              form.errors?.recurringTransfer?.endDate &&
              (form.touched?.recurringTransfer?.endDate || form.touched?.recurringTransfer?.endBy)
            }
            maxWidth="150px"
          >
            <BaselaneSingleDatePicker
              id="recurringTransfer.endDate"
              name="End date"
              minDate={minDate}
              date={field.value}
              value={
                field.value ? DateTime.fromJSDate(field.value).toFormat('MMM d, yyyy') : undefined
              } // Why? datepicker supposed to take care of the format but in mobile it breaks :/  "MMM dd yyyy"
              handleCalendarClose={({ date, isFromApplyButton }) => {
                if (isMin768 || (!isMin768 && isFromApplyButton)) {
                  form.setFieldValue('recurringTransfer.endDate', date);
                  // Setting the field as touched explicitly.
                  form.setTouched({
                    ...form.touched,
                    recurringTransfer: { ...form.touched?.recurringTransfer, endDate: true },
                  });
                }
              }}
              size="sm"
              years={{ start: new Date().getFullYear(), end: new Date().getFullYear() + 5 }}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              hideInputIcon={false}
              isDisabled={form.values?.recurringTransfer?.endBy !== 'date'}
              fixedHeight
              placement="bottom-end"
              showInModal={!isMin768}
              filterDate={isWeekday}
              excludedDates={excludedDates}
              hideClearButton
            />
            <BaselaneFormErrorMessage
              isInvalid={
                form.errors?.recurringTransfer?.endDate &&
                (form.touched?.recurringTransfer?.endDate || form.touched?.recurringTransfer?.endBy)
              }
            >
              {form.errors?.recurringTransfer?.endDate}
            </BaselaneFormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default EndDateDatepicker;
