import React, { useState } from 'react';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { useShallow } from 'zustand/react/shallow';

import useCheckbookStore from '@store/Checkbook';
import { BaselaneDrawer } from '@shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { ORDER_CHECKBOOK } from '@pages/BankEntity/queries';

import SelectAccount from './SelectAccount';
import CheckPreview from './CheckPreview';
import MailingAddress from './MailingAddress';
import Review from './Review';
import Footer from '../DrawerFooter';
import StepHeader from '../StepHeader';
import { initialFormValues, handleValidation, formattedValues } from '../helpers/form.helper';
import useEntityByIdQuery from '../../shared/useEntityByIdQuery';

type CheckbookStepsProps = {
  xIdempotencyKey: String,
};

const CheckbookSteps = ({ xIdempotencyKey }: CheckbookStepsProps) => {
  const { state: routerState } = useLocation();
  const [hasSubmissionError, setSubmissionError] = useState(false);
  const [step, selectedMailing, setFormSuccess] = useCheckbookStore(
    useShallow((state) => [state.step, state.selectedMailing, state.setFormSuccess])
  );

  const { refetchEntity } = useEntityByIdQuery();
  const { DrawerBody, DrawerFooter } = BaselaneDrawer;
  const { isMax576 } = useBreakPoints();

  const [orderCheckbook] = useMutation(ORDER_CHECKBOOK);

  const currentStep = () => {
    switch (step) {
      case 1:
        return <SelectAccount />;
      case 2:
        return <CheckPreview />;
      case 3:
        return <MailingAddress />;
      case 4:
        return <Review hasSubmissionError={hasSubmissionError} />;
      default:
        return <SelectAccount />;
    }
  };

  const handleSubmit = async (values, actions) => {
    setSubmissionError(false);

    const variables = formattedValues(values);

    const checkbook = await orderCheckbook({
      context: {
        headers: {
          'x-idempotency-key': xIdempotencyKey,
        },
      },
      variables,
    }).catch(() => {
      actions.setSubmitting(false);
      setSubmissionError(true);
    });

    if (checkbook) {
      actions.setSubmitting(false);
      if (checkbook.errors && checkbook.errors?.length) {
        setSubmissionError(true);
      } else {
        refetchEntity();
        setFormSuccess(true);
      }
    }
  };

  const { customCheckbookInitialValues = null } = routerState ?? {};
  const initialFormikValues = {
    ...initialFormValues,
    ...(customCheckbookInitialValues ?? {}),
  };
  return (
    <Formik
      initialValues={initialFormikValues}
      validate={(values) => handleValidation(values, selectedMailing === 'other')}
      onSubmit={handleSubmit}
      validateOnChange
      validateOnMount
      enableReinitialize
    >
      {() => (
        <>
          <DrawerBody p={isMax576 ? 2 : 3}>
            <StepHeader />
            <Box pt={3}>{currentStep()}</Box>
          </DrawerBody>

          <DrawerFooter
            px={isMax576 ? 2 : 3}
            py={2}
            w="100%"
            boxShadow="none"
            borderTop="1px solid"
            borderColor="brand.darkBlue.200"
          >
            <Footer />
          </DrawerFooter>
        </>
      )}
    </Formik>
  );
};

export default CheckbookSteps;
