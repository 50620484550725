import React, { forwardRef } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Text,
} from '@chakra-ui/react';
import { Icon16ChevronDown, Icon16ChevronUp } from '@icons/16px';
import CheckboxListItem from '../../CheckboxListItem';
import DropdownContentWrapper from '../../DropdownContentWrapper';

import {
  getChildCounterVisualStyles,
  setIsSelectedItem,
} from '../../helpers/dropdown.shared.helpers';
import { t2Search } from '../../utils/search';
import { getParentChildren } from '../../utils/children';
import {
  accordionButtonStyles,
  accordionChildCheckboxContainerStyles,
  accordionPanelStyles,
  accordionSelectAllItemContainerStyles,
  accordionSelectAllTextStyles,
  accordionToggleContainerStyles,
  accordionToggleCounterContainerStyles,
  accordionToggleIconContainerStyles,
  accordionToggleTextStyles,
} from '../../styles/accordion.styles';

type ListOfCategoryAndSubcategoryOptionsProps = {
  options: Array<any>,
  selectedStagedOptions: Array<any>,
  parentItemRenderer: Function,
  childItemRenderer: Function,
  hasFilterWrapper: boolean,
  search: string,
  handleListItemClick: Function,
  handleSelectAll: Function,
  childrenSelectAllState: Object,
};

const ListOfParentAndChildrenCheckboxes = forwardRef(
  (
    {
      options,
      selectedStagedOptions,
      parentItemRenderer,
      childItemRenderer,
      hasFilterWrapper,
      search,
      handleListItemClick,
      handleSelectAll,
      childrenSelectAllState,
    }: ListOfCategoryAndSubcategoryOptionsProps,
    ref
  ) => {
    const shouldOpenAccordion = { ...(search ? { index: [0] } : {}) };

    return (
      <DropdownContentWrapper hasFilterWrapper={hasFilterWrapper} ref={ref}>
        {t2Search({ options, search }).reduce((acc, item, index, allItems) => {
          const { id, isChild, hasChildren } = item;
          const parentChildren = hasChildren ? getParentChildren(allItems, id) : [];

          if (isChild) {
            return acc;
          }

          acc.push(
            hasChildren ? (
              <Accordion key={item.id} allowMultiple {...shouldOpenAccordion}>
                <AccordionItem border="none">
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton {...accordionButtonStyles}>
                        <Box
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          overflow="hidden"
                          w="100%"
                        >
                          <CheckboxListItem
                            tabIndex="-1"
                            className="element"
                            id={item.id}
                            isChecked={setIsSelectedItem(item, selectedStagedOptions)}
                            onChange={(e) => {
                              e.stopPropagation();
                              handleSelectAll(
                                parentChildren,
                                item,
                                true, // isParentOnChange
                                isExpanded,
                                !setIsSelectedItem(item, selectedStagedOptions)
                              );
                            }}
                          >
                            {parentItemRenderer({ item, search })}
                          </CheckboxListItem>
                        </Box>

                        <HStack
                          {...accordionToggleContainerStyles(
                            search,
                            childrenSelectAllState?.[item.id]
                          )}
                        >
                          <HStack
                            {...getChildCounterVisualStyles(
                              childrenSelectAllState?.[item.id],
                              isExpanded
                            )}
                            {...accordionToggleCounterContainerStyles}
                          >
                            {childrenSelectAllState?.[item.id]?.length > 0 && (
                              <Text {...accordionToggleTextStyles}>
                                {childrenSelectAllState[item.id].length}
                              </Text>
                            )}
                            <HStack {...accordionToggleIconContainerStyles}>
                              {isExpanded ? <Icon16ChevronUp /> : <Icon16ChevronDown />}
                            </HStack>
                          </HStack>
                        </HStack>
                      </AccordionButton>
                      <AccordionPanel {...accordionPanelStyles}>
                        {!search && (
                          <HStack {...accordionSelectAllItemContainerStyles}>
                            <CheckboxListItem
                              tabIndex="-1"
                              className="element"
                              id={item.id}
                              isChecked={
                                childrenSelectAllState?.[item.id]?.length === parentChildren.length
                              }
                              isIndeterminate={
                                childrenSelectAllState?.[item.id]?.length > 0 &&
                                childrenSelectAllState?.[item.id]?.length !== parentChildren.length
                              }
                              onChange={() => {
                                handleSelectAll(parentChildren, item);
                              }}
                            >
                              <Text {...accordionSelectAllTextStyles}>SELECT ALL</Text>
                            </CheckboxListItem>
                          </HStack>
                        )}
                        {parentChildren.map((pChild) => (
                          <HStack key={pChild.id} {...accordionChildCheckboxContainerStyles}>
                            <CheckboxListItem
                              tabIndex="-1"
                              className="element"
                              id={pChild.id}
                              isChecked={setIsSelectedItem(pChild, selectedStagedOptions)}
                              isIndeterminate={false}
                              onChange={(e) => {
                                handleListItemClick(pChild, e);
                              }}
                            >
                              {childItemRenderer({ item: pChild, search })}
                            </CheckboxListItem>
                          </HStack>
                        ))}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            ) : (
              <HStack key={item.id} align="center">
                <CheckboxListItem
                  tabIndex="-1"
                  className="element"
                  id={item.id}
                  isChecked={setIsSelectedItem(item, selectedStagedOptions)}
                  isIndeterminate={false}
                  onChange={(e) => {
                    handleListItemClick(item, e);
                  }}
                >
                  {parentItemRenderer({ item, search })}
                </CheckboxListItem>
              </HStack>
            )
          );

          return acc;
        }, [])}
      </DropdownContentWrapper>
    );
  }
);

export default ListOfParentAndChildrenCheckboxes;
