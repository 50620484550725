import { React, useRef, useState } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { Formik } from 'formik';
import LogRocket from 'logrocket';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/client';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import {
  passwordValidator,
  initialChecklist,
  PW_ERROR,
} from '@features/Authentication/helpers/validation.helpers';
import {
  BaselaneButton,
  BaselaneButtonIcon,
  BaselaneDivider,
  BaselaneSpinner,
} from '@core/components/Shared/components';
import { reactNodeEnv } from '@core/constants/envVars';
import useOnboardingStore from '@core/store/Onboarding';
import { Icon16ArrowForward, Icon16Hide, Icon16Unhide } from '@core/components/Icons/16px';
import PasswordChecker from '@pages/SignUpPage/components/Form/PasswordChecker';
import { IconExclamationCircleFilled } from '@core/components/Icons';
import GoogleButton from '@core/features/Authentication/components/GoogleButton';
import { dividerStyles } from '@pages/LoginPage/styles/font.style';
import PrivacyTerms from '@pages/SignUpPage/components/PrivacyTerms';
import { signUpTestsFooterNote } from '@pages/SignUpPage/styles/signupTests.styles';

import {
  cleanupRedirects,
  clearUrlUtmData,
  deleteUtmCookies,
  getUtmInputs,
  saveSearch,
  trackUtmUser,
} from '@core/features/Authentication/helpers/tracking.helpers';
import { firebaseEmailSignUp } from '@core/features/Authentication/helpers/firebase.helpers';
import { attemptToCreateUser } from '@core/features/Authentication/helpers/signup.helpers';
import { CREATE_USER_RETRY_COUNT_DEFAULT } from '@features/Authentication/helpers/constant.helpers';
import { CREATE_USER_DEFERRED } from '@core/apollo/queries';
import { emailVerificaton } from '@core/Services/CreateAccount';
import { ONBOARDING_TRIAGE } from '@core/constants/routes';
import useInvitationLink from '@pages/SignUpPage/useInvitationLink';
import {
  AuthenticationError,
  emailAlreadyInUse,
  errorMessages,
} from '@core/features/Authentication/helpers/error.helpers';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import inActivityLogoutStorage from '@core/storage/inActivityLogoutStorage';
import useBreakPoints from '@core/hooks/useBreakPoints';
import useIpAddress from '@shared/hooks/useIpAddress';
import {
  formErrorStylesCustomized,
  formInputCustomized,
} from '../../SignUpPage/styles/createaccount.style';

type DeferredPasswordSignUpFormProps = {
  id: string,
  onUserCreated: Function,
};

const DeferredPasswordSignUpForm = ({
  id = 'deferredPasswordSignup',
  onUserCreated,
  ...rest
}: DeferredPasswordSignUpFormProps): any => {
  // Fingerprint
  const { getData: getDataFingerprint } = useVisitorData({
    extendedResult: true,
    tag: {
      env: reactNodeEnv,
    },
  });
  const { isCollaborator } = useInvitationLink();
  const { ipAddress } = useIpAddress();
  const formikRef = useRef();
  const initialPopoverFocusRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const client = useApolloClient();

  const [createUser] = useMutation(CREATE_USER_DEFERRED);

  const [showChecklist, setShowChecklist] = useState(false);
  const [checklist, setChecklist] = useState(initialChecklist);
  const [isStrongPassword, setStrongPassword] = useState(false);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { email } = useOnboardingStore();

  const { isMin768 } = useBreakPoints();

  const handlePasswordVisibility = () => setShow(!show);
  const weakPassword = () => Object.keys(checklist).find((item) => !checklist[item].isValid);

  const togglePasswordChecklistVisibility = () =>
    setShowChecklist((prevState) => {
      return !prevState;
    });

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      formikRef.current.handleSubmit();
    }
  };

  const validateSignUp = (values, weakPasswordv, setStrongPasswordv) => {
    const { password, email: emailInput } = values;
    const errors = {};

    if (weakPasswordv()) {
      setStrongPasswordv(false);
      errors.password = PW_ERROR.weakPw;
    }

    if (password === '') {
      errors.password = PW_ERROR.pwRequired;
    }

    if (!emailInput) {
      errors.email = 'Please enter Email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailInput?.trim())) {
      errors.email = 'Invalid email address';
    }

    return errors;
  };

  const handleFormSubmit = (e) => {
    const { email: emailInput, password } = e;

    sendSegmentEvent('sign_up_clicked', {
      enteredEmail: emailInput,
      event_source: 'landlord_portal',
    });

    setIsLoading(true);
    let requestId = '';
    // Fingerprint
    // attempt to retrieve user's device fingerprint
    getDataFingerprint()
      .then((data) => {
        requestId = data?.requestId;
      })
      .catch((error) => {
        LogRocket.log(`getDataFingerprint error: ${JSON.stringify(error)}`);
      })
      .finally(() => {
        const utmInputs = getUtmInputs(ipAddress, location, 'account_created');
        // attempt to log in with google account
        // this should happen with or without fingerprint data
        firebaseEmailSignUp({
          email: emailInput,
          password,
          handleSuccess: (credential) => {
            attemptToCreateUser({
              credential,
              CREATE_USER_RETRY_COUNT_DEFAULT,
              requestId,
              utmInputs,
              location,
              // inviteCode,
              createUser,
              setIsLoading,
              onSuccess: async () => {
                const bodyParam = { email: emailInput };
                // force refresh of token to ensure we have the latest claims
                emailVerificaton(bodyParam)
                  .then((data) => {
                    const { result } = data;
                    if (result === 'success') {
                      // createUser success

                      // tracking event
                      if (!isCollaborator) {
                        trackUtmUser(credential.user, 'account_created', ipAddress, utmInputs);
                      }

                      // reddit tracking
                      window?.rdt('track', 'SignUp');

                      try {
                        client.resetStore();
                      } catch (error) {
                        console.error('Error clearing Apollo Client store:', error);
                      }
                    }

                    // delete utm cookie data, as it has been consumed
                    deleteUtmCookies();
                    // delete utm data from storage, as it has been consumed
                    clearUrlUtmData();
                  })
                  .then((data) => {
                    // store search parameters in local storage
                    saveSearch();
                    // cleanup redirect data
                    cleanupRedirects();
                    onUserCreated(data).then(() => {
                      navigate(ONBOARDING_TRIAGE); // skip phone page and go straight to onboarding triage
                    });
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
                client.resetStore();
              },
              onQueryFail: (error) => {
                formikRef?.current?.setErrors({
                  ...formikRef?.current?.errors,
                  signUp: errorMessages[AuthenticationError.generic],
                });

                LogRocket.log(
                  `CreateUser (Email SignUp) error: ${JSON.stringify(
                    error
                  )}, requestId:${requestId}, email: ${credential?.user?.email}`
                );
              },
              onLastAttemptFail: (responseErrors) => {
                if (emailAlreadyInUse(responseErrors[0]?.message)) {
                  formikRef?.current?.setErrors({
                    ...formikRef?.current?.errors,
                    email: errorMessages[AuthenticationError.emailalreadyinuse],
                  });
                }
                LogRocket.log(
                  `CreateUser (Email SignUp) error: ${JSON.stringify(
                    responseErrors
                  )}, requestId:${requestId}, email: ${credential?.user?.email}`
                );
                sendSegmentEvent('baselane_signup_failed', {
                  enteredEmail: credential?.user?.email,
                  idNotFound: true,
                  event_source: 'landlord_portal',
                });
              },
            });
          },
          handleError: (error) => {
            setIsLoading(false);

            LogRocket.log(
              `signUpWithCustomEmail error: ${JSON.stringify(error)}, requestId:${requestId}`
            );

            inActivityLogoutStorage.delete();
            localStorage.removeItem('redirectLogin');

            const { code } = error || {};
            const requestErrors = {};

            switch (code) {
              case AuthenticationError.invalidemail:
              case AuthenticationError.emailalreadyinuse:
                requestErrors.email = errorMessages[code];
                break;
              case AuthenticationError.tenant:
                requestErrors.signUp = errorMessages[code];
                break;
              default:
                // do nothing - unhandled error
                break;
            }

            // set login form errors
            formikRef?.current?.setErrors({ ...formikRef?.current?.errors, ...requestErrors });
          },
        });
      });
  };

  return (
    <HStack id={id} w="full" justifyContent="center" mt={isMin768 ? 5 : 0}>
      <Formik
        innerRef={formikRef}
        validate={(values) => validateSignUp(values, weakPassword, setStrongPassword)}
        initialValues={{ email, password: '' }}
        onSubmit={handleFormSubmit}
      >
        {({ isValid, dirty, values, handleSubmit, handleBlur, handleChange, errors, touched }) => (
          <>
            {isLoading && <BaselaneSpinner />}
            {!isLoading && (
              <VStack maxW="480px" w="full" gap={3} alignItems="flex-start">
                <Heading as="h2" size="headline-xl">
                  Let&apos;s create your account to continue
                </Heading>
                <GoogleButton
                  isLogin={false}
                  text="Sign in"
                  isMobile={isMobile}
                  onUserCreated={onUserCreated}
                />
                <HStack w="full">
                  <BaselaneDivider
                    styles={{
                      ...dividerStyles,
                      my: '0px !important',
                      bg: 'brand.neutral.200',
                      borderColor: 'brand.neutral.200',
                    }}
                  />
                  <Text
                    whiteSpace="nowrap"
                    fontSize={{ xxs: 'sm', xs: 'sm', sm: 'sm', md: 'sm', lg: 'md', xl: 'md' }}
                    color="brand.neutral.700"
                  >
                    Or
                  </Text>
                  <BaselaneDivider styles={{ ...dividerStyles, my: '0px !important' }} />
                </HStack>
                <FormControl isInvalid={errors.email && touched.email} mb="0">
                  <FormLabel htmlFor="email" textStyle="xs">
                    Email
                  </FormLabel>
                  <Input
                    {...formInputCustomized}
                    id="email"
                    name="email"
                    value={values?.email}
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    onKeyDown={handleOnKeyDown}
                  />
                  <FormErrorMessage {...formErrorStylesCustomized}>
                    <Text as="span" {...{ mr: '8px' }}>
                      <IconExclamationCircleFilled />
                    </Text>
                    {errors.email}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.password && touched.password} mb="0">
                  <FormLabel htmlFor="password" textStyle="xs">
                    Password
                  </FormLabel>
                  <InputGroup>
                    <Input
                      {...formInputCustomized}
                      ref={initialPopoverFocusRef}
                      id="password"
                      name="password"
                      value={values?.password}
                      type={show ? 'text' : 'password'}
                      onFocus={togglePasswordChecklistVisibility}
                      onChange={(e) => {
                        const p = e.target.value;
                        const updatedChecklist = passwordValidator(p, checklist);
                        setChecklist(updatedChecklist);
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        togglePasswordChecklistVisibility();
                      }}
                      onKeyDown={handleOnKeyDown}
                    />
                    <InputRightElement h="100%">
                      <BaselaneButtonIcon
                        variant="transparent"
                        palette="neutral"
                        size="sm"
                        icon={show ? <Icon16Hide /> : <Icon16Unhide />}
                        isActive={show}
                        onClick={handlePasswordVisibility}
                      />
                    </InputRightElement>
                    {!isMobile && (
                      <PasswordChecker
                        {...{
                          initialPopoverFocusRef,
                          showChecklist,
                          checklist,
                          isStrongPassword,
                        }}
                      />
                    )}
                  </InputGroup>
                  <FormErrorMessage {...formErrorStylesCustomized}>
                    <Text as="span" {...{ mr: '8px' }}>
                      <IconExclamationCircleFilled />
                    </Text>
                    {errors.password}
                  </FormErrorMessage>
                  {isMobile && (
                    <PasswordChecker
                      {...{
                        initialPopoverFocusRef,
                        showChecklist,
                        checklist,
                        isStrongPassword,
                      }}
                    />
                  )}
                </FormControl>
                <BaselaneButton
                  size="md"
                  palette="primary"
                  variant="filled"
                  onClick={handleSubmit}
                  rightIcon={<Icon16ArrowForward />}
                  isDisabled={!isValid || !dirty}
                >
                  Continue
                </BaselaneButton>
                <PrivacyTerms styles={{ ...signUpTestsFooterNote, mt: '0 !important' }} />
              </VStack>
            )}
          </>
        )}
      </Formik>
    </HStack>
  );
};

export default DeferredPasswordSignUpForm;
