import React from 'react';
import { HStack, Tbody, Text, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useBreakPoints from '@core/hooks/useBreakPoints';
import {
  BaselaneResponsiveTableRows,
  BaselaneResponsiveCell,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveCellAmountContent,
} from '@core/components/Shared/components';
import formatCurrency from '@core/utils/formatCurrency';
import {
  formatTranferType,
  describePaymentSchedule,
  describePaymentScheduleForMobile,
  describePaymentDate,
  paymentStatusFormatter,
} from '../helpers/helpers';
import { recipientsTableBodyStyles } from '../../Recipients/styles';

type TableBodyProps = { payments: Array<Object> };

export function TableBody({ payments = () => {} }: TableBodyProps) {
  const {
    sharedCellStyles,
    nameStyles,
    paymentMethodsStyles,
    lastPaidStyles,
  } = recipientsTableBodyStyles;

  const navigate = useNavigate();

  const handlePaymentClick = (payment) => {
    const path = payment.recurringPaymentId
      ? `${payment.transferId}-${payment.recurringPaymentId}`
      : `${payment.transferId}`;
    navigate(path);
  };

  const { isMin1150, isMin768, isMax576 } = useBreakPoints();

  const formatSubTitle = (
    isTransferIn = false,
    bankName = '',
    email = '',
    paymentMethodType = '',
    transferType = '',
    recurrencePattern = '',
    transferDate = ''
  ) => {
    let subtitle = isTransferIn ? bankName : email;

    subtitle = subtitle || '';

    if (!isMin768) {
      subtitle = describePaymentScheduleForMobile(transferType, recurrencePattern, transferDate);
    }

    return subtitle;
  };

  const formatBankName = (name, accountNumber) => {
    if (accountNumber == null) {
      return `${name}`;
    }
    return `${name} - ${accountNumber.slice(-4)}`;
  };

  return (
    payments?.length > 0 && (
      <Tbody>
        <BaselaneResponsiveTableRows
          customRow
          items={payments}
          renderItem={(payment, index) => {
            const {
              name,
              email,
              amount,
              transferDate,
              totalNumberOfPayments,
              transferType,
              totalProcessedTransfers,
              recurrencePattern,
              paymentMethodType,
              nextPaymentDate,
              accountNumber,
              bankName,
              toTransferAccountId,
            } = payment;

            const isTransferIn =
              paymentMethodType === 'TRANSFER_IN' || paymentMethodType === 'INTERNAL_TRANSFER';

            return (
              <BaselaneResponsiveTableRow
                onClick={() => handlePaymentClick(payment)}
                id={`table-row-${index}`}
                chevronCellStyles={{ display: { sm: 'table-cell', base: 'none' } }}
              >
                {/* Recipient */}
                <BaselaneResponsiveCellTitle
                  preheader={
                    !isMin1150 &&
                    formatTranferType({ paymentMethodType, isMobile: true, toTransferAccountId })
                  }
                  title={isTransferIn ? formatBankName(name, accountNumber) : name}
                  subtitle={formatSubTitle(
                    isTransferIn,
                    bankName,
                    email,
                    paymentMethodType,
                    transferType,
                    recurrencePattern,
                    transferDate
                  )}
                  configIndex={0}
                  styles={{
                    ...nameStyles.styles,
                    fontWeight: 'normal',
                  }}
                  {...sharedCellStyles}
                />

                {/* Payment Methods */}
                <BaselaneResponsiveCell configIndex={1} {...sharedCellStyles}>
                  <HStack>
                    <Text {...paymentMethodsStyles.text}>
                      {formatTranferType({ paymentMethodType, toTransferAccountId })}
                    </Text>
                  </HStack>
                </BaselaneResponsiveCell>

                {/* Schedule */}
                <BaselaneResponsiveCell configIndex={2} {...sharedCellStyles}>
                  <Stack {...lastPaidStyles.container}>
                    <Text {...lastPaidStyles.text} alignItems="right">
                      {describePaymentSchedule(transferType, recurrencePattern)}
                    </Text>

                    <Text {...lastPaidStyles.subtext}>
                      {describePaymentDate(transferDate, nextPaymentDate)}
                    </Text>
                  </Stack>
                </BaselaneResponsiveCell>

                {/* Amount */}
                <BaselaneResponsiveCell configIndex={3} {...sharedCellStyles}>
                  <BaselaneResponsiveCellAmountContent
                    textStyle="data-sm"
                    color="brand.neutral.700"
                  >
                    <Text>{formatCurrency(amount).inDollars}</Text>
                  </BaselaneResponsiveCellAmountContent>
                </BaselaneResponsiveCell>

                {/* Status */}

                {isMax576 ? (
                  <BaselaneResponsiveCellTitle
                    title={formatCurrency(amount).inDollars}
                    subtitle={paymentStatusFormatter(
                      transferType,
                      totalProcessedTransfers,
                      totalNumberOfPayments
                    )}
                    configIndex={4}
                    styles={{
                      ...nameStyles.styles,
                      fontWeight: 'normal',
                      width: 'auto!important',
                    }}
                    {...sharedCellStyles}
                  />
                ) : (
                  <BaselaneResponsiveCell configIndex={4} {...sharedCellStyles}>
                    <HStack>
                      <Text {...paymentMethodsStyles.text}>
                        {paymentStatusFormatter(
                          transferType,
                          totalProcessedTransfers,
                          totalNumberOfPayments
                        )}
                      </Text>
                    </HStack>
                  </BaselaneResponsiveCell>
                )}
              </BaselaneResponsiveTableRow>
            );
          }}
        />
      </Tbody>
    )
  );
}
