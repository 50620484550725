import React, { useContext, useRef, useState, FunctionComponent, useEffect } from 'react';
import {
  Box,
  HStack,
  Text,
  Heading,
  VStack,
  FormControl,
  useDisclosure,
  FormErrorMessage,
  Stack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';
import { useQuery } from '@apollo/client';
import { Icon12Check, Icon12Error } from '@icons/12px';
import {
  Icon16Dollar,
  Icon16Dollarcrossed,
  Icon16Plus,
  Icon16PlusCircle,
  Icon16Calendar,
} from '@icons/16px';
import { Icon24Dollar, Icon24History, Icon24Bolt } from '@icons/24px';
import {
  IllustrationBankingNewaccount,
  IllustrationBankingConnectExternalAccountWithPlaid,
} from '@illustrations';
import useBreakPoints from '@core/hooks/useBreakPoints';
import BanksContext from '@contexts/BanksContext';
import { UNIFIED_LB_PERSONAL_INFO } from '@core/constants/routes';
import historyStateStorage from '@core/storage/historyStateStorage';
import FormLabelWithTooltip from '@pages/LeasesPage/LeaseSection/components/FormLabelWithTooltip';
import UserAccessContext from '@contexts/UserAccessContext';
import { DYNAMIC_CONFIGS, EXPERIMENTS } from '@core/constants/statsigKeys';
import renderAccountDropdownItem from '@shared/components/BaselaneDropdown-new/ReusableDropdowns/AccountDropdown/renderAccountDropdownItem';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { iconContainerTopStyles } from '../../styles/typePopupButtons.styles';
import BaselaneButton from '../BaselaneButton';
import BaselaneChip from '../BaselaneChip';
import NoAccessTooltip from '../NoAccessTooltip';
import LimitedAcccessTooltip from '../LimitedAccessTooltip';
import T1WithTitle from '../BaselaneDropdown-new/T1-Title';
import { BaselaneCardNew } from '../BaselaneCard-new';
import BaselaneApplicationTypePopup from '../BaselaneApplicationTypePopup';
import BaselaneExternalBankingTypePopup from '../BaselaneExternalBankingTypePopup';
import AddAccountManuallyDrawer from '../AddAccountManuallyDrawer';
import { defaultComponentCopy } from './helpers/componentCopy';
import { getAccountsForDropDown } from './helpers/baselaneReceivingAccounts.helpers';
import { cardDisabledStyles } from './styles/receivingAccounts.style';
import { GET_USER } from '../../../../apollo/queries/user';

type Item = {
  id: String,
  bankName: String,
  account: String,
  // needed below props for an error state
  connectionState: 'CONNECTED' | 'ITEM_LOGIN_REQUIRED' | null,
  stripeBankAccountId: String,
  bankAccountMetadata: {
    payoutFailedReason: {
      failureCode: String,
      failureMessage: String,
    },
    lastPayoutFailed: Boolean,
  },
};

type Account = {
  id: String,
  items: Array<Item>,
  title: String,
};

type Props = {
  isFromResponsiveMode: Boolean,
  accounts: Array<Account>,
  selectedAccount?: Item,
  isBaselaneAccountDisabled?: Boolean,
  isExternalAccountDisabled?: Boolean,
  refetchBankAccounts?: Function,
  hideExternalAccounts?: Boolean,
  hideSavingsAccounts?: Boolean,
  // event handlers
  onSelectAccount?: Function,
  onActionButtonClick?: Function,
  onAddConnectedAccountSuccess?: Function,
  onAddManualAccountSuccess?: Function,
  from?: String,
  section?: String,
  errorMessage?: String,
  hideHeading?: Boolean,
  hideButtonAndCards?: Boolean,
  // copy overrides
  heading?: String,
  formLabel?: String,
  tooltipText?: String,
  baselaneAccountHeading?: String,
  baselaneAccountDetails?: Array<Any>,
  baselaneAccountButtonLabel?: String,
  baselaneAccountButtonSiblings?: FunctionComponent,
  connectedAccountHeading?: String,
  connectedAccountDetails?: String,
  connectedAccountButtonLabel?: String,
};

const BaselaneReceivingAccounts = ({
  isFromResponsiveMode,
  accounts,
  selectedAccount = null,
  isBaselaneAccountDisabled = false,
  isExternalAccountDisabled = false,
  refetchBankAccounts = () => {},
  hideExternalAccounts = false,
  hideSavingsAccounts = true,
  onSelectAccount = () => {},
  onActionButtonClick = () => {},
  onAddConnectedAccountSuccess = () => {},
  onAddManualAccountSuccess = () => {},
  from = {},
  section = null,
  errorMessage = null,
  hideHeading = false,
  hideButtonAndCards = false,
  heading = null,
  formLabel = null,
  tooltipText = null,
  baselaneAccountHeading = null,
  baselaneAccountDetails = null,
  baselaneAccountButtonLabel = null,
  baselaneAccountButtonSiblings = null,
  connectedAccountHeading = null,
  connectedAccountDetails = null,
  connectedAccountButtonLabel = null,
}: Props) => {
  const { getExperiment } = useStatsigClient();
  const experiment =
    getExperiment(EXPERIMENTS.RC_ADD_BANK_ACCOUNT_TO_COLLECT_RENT_EXPERIMENT) || {};

  const {
    value: { flow_name: experimentFlow },
  } = experiment;

  const addExternalAccountDrawerRef = useRef();
  const addAccountManuallyDrawerRef = useRef();
  const { authorizedForBanking, isWorkspaceOwner } = useContext(UserAccessContext);
  const { getDynamicConfig } = useStatsigClient();
  const config = getDynamicConfig(DYNAMIC_CONFIGS.APY_INTEREST);

  const [showAllAccountTypes, setShowAllAccountTypes] = useState(false);

  const { getKYCUrl } = useContext(BanksContext);

  const navigate = useNavigate();

  const isRentCollectionFlow = from?.flow?.includes('unified_rent_collection');
  const result = useQuery(GET_USER);
  const isOnboardingFlow = result?.data?.user?.onboardingCompleted === false;

  const hasBaselaneAccount = accounts.reduce(
    (prev, current) => prev || current.id === 'baselane_account',
    false
  );

  const hasExternalAccount = accounts.reduce(
    (prev, current) => prev || current.id === 'external_account',
    false
  );

  const formattedAccounts = getAccountsForDropDown({
    accounts,
    hideExternalAccounts,
    hideSavingsAccounts,
  });

  const showAddAccountButtonAndDropdown = hideExternalAccounts
    ? hasBaselaneAccount
    : hasBaselaneAccount || hasExternalAccount;

  const hasOnlyExternalAccount = hasExternalAccount && !hasBaselaneAccount;

  const {
    isOpen: isBaselaneAccountPopupOpen,
    onOpen: onBaselaneAccountPopupOpen,
    onClose: onBaselaneAccountPopupClose,
  } = useDisclosure();

  const {
    isOpen: isExternalAccountPopupOpen,
    onOpen: onExternalAccountPopupOpen,
    onClose: onExternalAccountPopupClose,
  } = useDisclosure();

  const { isMax768 } = useBreakPoints();

  const handleOpenBaselaneBankingPopup = () => {
    const eventName = `${
      isOnboardingFlow ? 'onboarding' : 'baselane'
    }_rc_click_create_account_or_connect_external`;
    sendSegmentEvent(eventName, {
      bank_type: 'baselane',
    });
    onBaselaneAccountPopupOpen();
  };

  const handleCloseBaselaneBankingPopup = () => {
    onBaselaneAccountPopupClose();
    setShowAllAccountTypes(false);
  };

  const handleOpenExternalBankingPopup = () => {
    const eventName = `${
      isOnboardingFlow ? 'onboarding' : 'baselane'
    }_rc_click_create_account_or_connect_external`;
    sendSegmentEvent(eventName, {
      bank_type: 'external',
    });
    onExternalAccountPopupOpen();
  };

  const handleCloseExternalAccountPopup = () => {
    onExternalAccountPopupClose();
    setShowAllAccountTypes(false);
  };

  const handleDropdownSubmit = (selected) => {
    onSelectAccount(selected);
  };

  const handleAddAccountManuallySuccess = (account) => {
    onAddManualAccountSuccess(account);
    refetchBankAccounts();
  };

  const handleAddConnectedAccountSuccess = (account) => {
    onAddConnectedAccountSuccess(account);
  };

  const handleActionButton = (type) => {
    if (isRentCollectionFlow) {
      sendSegmentEvent('baselane_click_connect_account', {
        type,
      });
    }
    onActionButtonClick(type);
    switch (type) {
      case 'manual':
        handleCloseExternalAccountPopup();
        addAccountManuallyDrawerRef?.current?.open();
        break;
      case 'business':
        historyStateStorage.write({ from });
        getKYCUrl();
        break;
      case 'sole':
        historyStateStorage.write({ from });
        navigate(UNIFIED_LB_PERSONAL_INFO);
        break;
      default:
        break;
    }
  };

  const handleAddAccountButtonClick = () => {
    if (hideExternalAccounts) {
      handleOpenBaselaneBankingPopup();
    } else if (hasOnlyExternalAccount) {
      handleOpenBaselaneBankingPopup();
    } else {
      setShowAllAccountTypes(true);
    }
  };

  const {
    hideExternalAccounts: defaultHideExternalAccounts,
    heading: defaultHeading,
    formLabel: defaultFormLabel,
    tooltipText: defaultTooltipText,
    baselaneAccountHeading: defaultBaselaneAccountHeading,
    baselaneAccountDetails: defaultBaselaneAccountDetails,
    baselaneAccountButtonLabel: defaultBaselaneAccountButtonLabel,
    connectedAccountHeading: defaultConnectedAccountHeading,
    connectedAccountDetails: defaultConnectedAccountDetails,
    connectedAccountButtonLabel: defaultConnectAccountButtonLabel,
  } = defaultComponentCopy(config?.get('apy'));

  const dynamicAPYvalue = `${config?.get('apy') ?? '--'} %`;

  const componentCopy = {
    heading:
      heading ||
      (hideExternalAccounts || !hasBaselaneAccount
        ? defaultHideExternalAccounts?.heading
        : defaultHeading),
    formLabel: formLabel || defaultFormLabel,
    tooltipText: tooltipText || defaultTooltipText,
    baselaneAccountHeading: baselaneAccountHeading || defaultBaselaneAccountHeading,
    baselaneAccountDetails: baselaneAccountDetails || defaultBaselaneAccountDetails,
    baselaneAccountButtonLabel: baselaneAccountButtonLabel || defaultBaselaneAccountButtonLabel,
    connectedAccountHeading: connectedAccountHeading || defaultConnectedAccountHeading,
    connectedAccountDetails: connectedAccountDetails || defaultConnectedAccountDetails,
    connectedAccountButtonLabel: connectedAccountButtonLabel || defaultConnectAccountButtonLabel,
  };

  const handleLinkedAccountsDrawerClose = (isClosed) => {
    // for some reason this function will be called BEFORE drawer closes so adding check
    if (isClosed) {
      handleCloseExternalAccountPopup();
    }
  };

  useEffect(() => {
    const eventName = `${
      isOnboardingFlow ? 'onboarding' : 'baselane'
    }_rc_receiving_bank_step_loaded`;
    sendSegmentEvent(eventName, {
      variant: experimentFlow,
      isEmptyState: !(accounts.length > 0),
    });
  }, []);

  return (
    <>
      <BaselaneApplicationTypePopup
        handleActionButton={handleActionButton}
        isOpen={isBaselaneAccountPopupOpen}
        onClose={handleCloseBaselaneBankingPopup}
      />
      <BaselaneExternalBankingTypePopup
        handleActionButton={handleActionButton}
        isOpen={isExternalAccountPopupOpen}
        onClose={handleCloseExternalAccountPopup}
        linkedAccountsDrawerOnClose={handleLinkedAccountsDrawerClose}
        addAccountProps={{
          isFromLease: true,
          isDirectToPlaid: true,
          hideButton: true,
          handleAddAccountSuccessCallback: handleAddConnectedAccountSuccess,
          refetchLeaseBankAccounts: refetchBankAccounts,
          addExternalAccountDrawerRef,
        }}
      />
      <AddAccountManuallyDrawer
        {...{
          addAccountManuallyDrawerRef,
          handleAddAccountManuallySuccess,
          from: { section: section ?? from },
        }}
      />
      <VStack alignItems="flex-start" gap={2}>
        {!hideHeading && (
          <Text textStyle="sm" fontWeight="medium">
            {componentCopy?.heading}
          </Text>
        )}
        {accounts?.length > 0 && showAddAccountButtonAndDropdown && (
          <FormControl mb={0} isInvalid={errorMessage} w="100%">
            <FormLabelWithTooltip
              htmlFor="account-dropdown"
              text={componentCopy?.formLabel}
              tooltipText={componentCopy?.tooltipText}
            />
            <T1WithTitle
              additionalProps={{ id: 'account-dropdown' }}
              classNames={[
                'new',
                'input-form-md',
                'auto-width-dropdown',
                'select-auto-width',
                'account',
              ]}
              data={formattedAccounts}
              selectedItem={selectedAccount}
              searchTerm={['account', 'bankName']}
              showValueByFields={['bankName', 'account']}
              itemRenderer={renderAccountDropdownItem}
              handleSubmit={handleDropdownSubmit}
              isMulti={false}
              hasFilterWrapper={false}
              hasCheckboxes={false}
              showDivider={false}
              showTitleInSearch={false}
              hideClearButton
              parentId={isFromResponsiveMode ? 'drawer-body' : 'page-feature'}
              placeholder="Select account"
              title="account"
              hasError={errorMessage}
              dropdownPosition="bottom"
              isMobile={isMax768}
            />
            <FormErrorMessage ml={0}>
              <Box as="span" mr={0.5}>
                <Icon12Error />
              </Box>
              {errorMessage}
            </FormErrorMessage>
          </FormControl>
        )}

        {showAddAccountButtonAndDropdown && !showAllAccountTypes && hasOnlyExternalAccount && (
          <BaselaneButton
            leftIcon={<Icon16PlusCircle />}
            variant="transparent"
            palette="primary"
            size="sm"
            onClick={handleOpenExternalBankingPopup}
          >
            Add a new account
          </BaselaneButton>
        )}

        {!hideButtonAndCards && (
          <>
            {(!hasBaselaneAccount || showAllAccountTypes) &&
              (experimentFlow === 'Test' ? (
                <BaselaneCardNew size="lg" w="full" palette="primary" position="relative" mt="1.5">
                  {' '}
                  <Box position="absolute" top="-16px" right="24px">
                    <BaselaneChip label="Recommended" size="md" variant="simple-primary" />
                  </Box>
                  <Stack width="100%" gap="2">
                    <HStack>
                      <IllustrationBankingConnectExternalAccountWithPlaid />
                      <Stack gap="0">
                        <Heading size="headline-md" fontWeight="medium">
                          Baselane Banking
                        </Heading>
                        <Text textStyle="sm">72% of landlords choose to bank with Baselane.</Text>
                      </Stack>
                    </HStack>
                    <HStack alignItems="center">
                      <Box w="24px" h="24px">
                        <Icon16Dollarcrossed color="#0968BF" />
                      </Box>
                      <Box>
                        <Heading size="headline-md" color="brand.blue.800A">
                          Free
                        </Heading>
                        <Text textStyle="sm">No tenant or landlord payment fees</Text>
                      </Box>
                    </HStack>
                    <HStack alignItems="center">
                      <Box w="24px" h="24px" color="brand.neutral.700">
                        <Icon24Bolt color="#0968BF" />
                      </Box>
                      <Box>
                        <Heading size="headline-md" color="brand.blue.800A">
                          Fast
                        </Heading>
                        <Text textStyle="sm">Rent deposited in 2 business days</Text>
                      </Box>
                    </HStack>

                    <Stack>
                      <HStack>
                        <Icon12Check color="brand.colors.neutral.600" />
                        <Text textStyle="xs" color="brand.colors.neutral.600">
                          Dedicated accounts for each unit / security deposit
                        </Text>
                      </HStack>
                      <HStack>
                        <Icon12Check color="brand.colors.neutral.600" />
                        <Text textStyle="xs" color="brand.colors.neutral.600">
                          Up to {dynamicAPYvalue} APY on savings accounts
                        </Text>
                      </HStack>
                      <HStack>
                        <Icon12Check color="brand.colors.neutral.600" />
                        <Text textStyle="xs" color="brand.colors.neutral.600">
                          Automated bookkeeping and tax prep
                        </Text>
                      </HStack>
                    </Stack>
                    <BaselaneButton
                      size="md"
                      variant="filled"
                      palette="primary"
                      id="bb-account-button"
                      onClick={handleOpenBaselaneBankingPopup}
                      isDisabled={isBaselaneAccountDisabled || !isWorkspaceOwner}
                    >
                      Create account
                    </BaselaneButton>
                  </Stack>
                </BaselaneCardNew>
              ) : (
                <BaselaneCardNew
                  size="lg"
                  w="full"
                  {...{ ...cardDisabledStyles(isBaselaneAccountDisabled) }}
                >
                  <HStack>
                    <VStack {...iconContainerTopStyles}>
                      <IllustrationBankingNewaccount />
                    </VStack>
                    <VStack alignItems="flex-start" justifyContent="flex-start" gap={1}>
                      <Heading size="headline-md" fontWeight="medium" mb={0.5}>
                        {componentCopy?.baselaneAccountHeading} here heading
                      </Heading>
                      {componentCopy?.baselaneAccountDetails?.map((item, index) => (
                        <React.Fragment key={`${item.id}-frag`}>
                          <HStack key={item.id} alignItems="center">
                            <Box
                              key={`${item.id}-icon`}
                              {...{ w: '16px', h: '16px', color: 'brand.neutral.700' }}
                            >
                              {item.icon}
                            </Box>
                            <Text textStyle="headline-sm">{item.prefix}</Text>
                            {item.prefix ? <Text textStyle="sm">—</Text> : null}
                            <Text key={`${item.id}-text`} textStyle="sm">
                              {item.text}
                            </Text>
                          </HStack>
                        </React.Fragment>
                      ))}
                      <HStack h="100%" alignItems="center" mt={3} flexWrap="wrap">
                        {!isWorkspaceOwner ? (
                          <NoAccessTooltip>
                            <BaselaneButton
                              id="baselane-banking-button"
                              size="lg"
                              variant="filled"
                              palette="primary"
                              w={isMax768 ? 'full' : 'auto'}
                              leftIcon={<Icon16Plus />}
                              onClick={handleOpenBaselaneBankingPopup}
                              isDisabled={isBaselaneAccountDisabled || !isWorkspaceOwner}
                              mr={1}
                            >
                              {componentCopy?.baselaneAccountButtonLabel}
                            </BaselaneButton>
                          </NoAccessTooltip>
                        ) : (
                          <BaselaneButton
                            id="baselane-banking-button"
                            size="lg"
                            variant="filled"
                            palette="primary"
                            w={isMax768 ? 'full' : 'auto'}
                            leftIcon={<Icon16Plus />}
                            onClick={handleOpenBaselaneBankingPopup}
                            isDisabled={isBaselaneAccountDisabled || !isWorkspaceOwner}
                            mr={1}
                          >
                            {componentCopy?.baselaneAccountButtonLabel}
                          </BaselaneButton>
                        )}

                        {baselaneAccountButtonSiblings}
                      </HStack>
                    </VStack>
                  </HStack>
                </BaselaneCardNew>
              ))}
            {((!hasExternalAccount && !hasBaselaneAccount && !hideExternalAccounts) ||
              showAllAccountTypes) && (
              <BaselaneCardNew
                size="lg"
                w="full"
                {...cardDisabledStyles(isBaselaneAccountDisabled)}
              >
                {experimentFlow === 'Test' ? (
                  <Stack width="100%" gap="1.5">
                    <HStack>
                      <IllustrationBankingConnectExternalAccountWithPlaid />
                      <Stack gap="0">
                        <Heading size="headline-md" fontWeight="medium">
                          External Banking
                        </Heading>
                        <Text textStyle="sm">Connect an external account.</Text>
                      </Stack>
                    </HStack>
                    <HStack alignItems="center">
                      <Box w="24px" h="24px" color="brand.neutral.700">
                        <Icon24Dollar />
                      </Box>
                      <Box>
                        <Heading size="headline-md">Fees</Heading>
                        <Text textStyle="sm">$2 fee for payments via ACH (bank transfer)</Text>
                      </Box>
                    </HStack>
                    <HStack alignItems="center">
                      <Box w="24px" h="24px" color="brand.neutral.700">
                        <Icon24History />
                      </Box>
                      <Box>
                        <Heading size="headline-md">Slower</Heading>
                        <Text textStyle="sm">Rent deposited in 5 business days</Text>
                      </Box>
                    </HStack>
                    <BaselaneButton
                      variant="outline"
                      size="md"
                      palette="primary"
                      mt={2}
                      id="external-account-button"
                      onClick={handleOpenExternalBankingPopup}
                      isDisabled={isExternalAccountDisabled}
                    >
                      {componentCopy?.connectedAccountButtonLabel}
                    </BaselaneButton>
                  </Stack>
                ) : (
                  <HStack>
                    <VStack {...iconContainerTopStyles}>
                      <IllustrationBankingConnectExternalAccountWithPlaid />
                    </VStack>
                    <VStack alignItems="flex-start" gap={1}>
                      <Heading size="headline-md" fontWeight="medium">
                        {componentCopy?.connectedAccountHeading}
                      </Heading>
                      <HStack alignItems="center">
                        <Box
                          {...{
                            w: '16px',
                            h: '16px',
                            color: 'brand.neutral.700',
                          }}
                        >
                          <Icon16Dollar />
                        </Box>
                        <Text textStyle="sm">{componentCopy?.connectedAccountDetails}</Text>
                      </HStack>
                      <HStack alignItems="center">
                        <Icon16Calendar />
                        <Text textStyle="sm">
                          Rent deposited in 4-5 business days (not QuickPay eligible)
                        </Text>
                      </HStack>
                      <BaselaneButton
                        variant="tonal"
                        size="md"
                        palette="primary"
                        id="external-account-button"
                        onClick={handleOpenExternalBankingPopup}
                        isDisabled={isExternalAccountDisabled}
                      >
                        {componentCopy?.connectedAccountButtonLabel}
                      </BaselaneButton>
                    </VStack>
                  </HStack>
                )}
              </BaselaneCardNew>
            )}
            {showAddAccountButtonAndDropdown && !showAllAccountTypes && hasOnlyExternalAccount && (
              <BaselaneButton
                leftIcon={<Icon16PlusCircle />}
                variant="transparent"
                palette="primary"
                size="sm"
                onClick={handleOpenExternalBankingPopup}
              >
                Connect an external account
              </BaselaneButton>
            )}
            {showAddAccountButtonAndDropdown &&
              !showAllAccountTypes &&
              !hasOnlyExternalAccount &&
              !authorizedForBanking && (
                <LimitedAcccessTooltip>
                  <BaselaneButton
                    leftIcon={<Icon16PlusCircle />}
                    variant="transparent"
                    palette="primary"
                    size="sm"
                    isDisabled={!authorizedForBanking}
                    onClick={handleAddAccountButtonClick}
                  >
                    Add a new account
                  </BaselaneButton>
                </LimitedAcccessTooltip>
              )}
            {showAddAccountButtonAndDropdown &&
              !showAllAccountTypes &&
              !hasOnlyExternalAccount &&
              authorizedForBanking && (
                <BaselaneButton
                  leftIcon={<Icon16PlusCircle />}
                  variant="transparent"
                  palette="primary"
                  size="sm"
                  onClick={handleAddAccountButtonClick}
                >
                  Add a new account
                </BaselaneButton>
              )}
          </>
        )}
      </VStack>
    </>
  );
};

export default BaselaneReceivingAccounts;
