import React from 'react';
import { Text } from '@chakra-ui/react';
import { BaselaneRadio } from '@shared/components';
import { addressTextStyles } from '@shared/components/BaselaneResponsiveCard/styles/card.style';

type Props = {
  propertyId: number,
  propertyAddress: Object,
  propertyUnitsLength: number,
  selectedPropertyId: number,
};

const Address = ({ propertyAddress }: any) => (
  <Text noOfLines={[1, 2]} {...addressTextStyles}>
    {propertyAddress?.address}, {propertyAddress?.city}, {propertyAddress?.state}{' '}
    {propertyAddress?.zipCode}
  </Text>
);
const PropertyCard = ({
  propertyId,
  propertyAddress,
  propertyUnitsLength,
  selectedPropertyId,
}: Props) => {
  const isChecked = Number(selectedPropertyId) === Number(propertyId);
  const description = propertyUnitsLength > 1 ? `${propertyUnitsLength} units` : undefined;

  if (!propertyId) return null;

  return (
    <BaselaneRadio
      value={Number(propertyId)}
      isChecked={isChecked}
      label={propertyAddress && <Address {...{ propertyAddress }} />}
      description={description}
    />
  );
};

export default PropertyCard;
