import process from 'process';
import { useNavigate } from 'react-router-dom';
import { auth } from '@core/Services/Firebase/firebase';
import {
  manageStorageAfterLogout,
  manageLocalStorageAfterLogout,
} from '@core/storage/helpers/cleanup.helpers';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { LOGIN, SESSION_EXPIRED } from '@routes';

/**
 * Hook for logging out the user from the backend & frontend
 * This should be used for any interaction that requires logging out the user
 */
const useLogout = () => {
  const navigate = useNavigate();

  /**
   * Logs out the user from the backend
   * Note: This should not be called directly, but rather through the logout function
   * @returns {Promise<void>}
   */
  const logoutOnBackend = () => {
    const [orchestrationUrl] = process.env.REACT_APP_ORCHESTRATION_URL?.split('/graphql') || [];
    const requestUrl = `${orchestrationUrl}/api/logout`;

    return fetch(requestUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  };

  /**
   * Logs out the user from the frontend
   * @param {boolean} isSessionExpired - Whether the session has expired, this determines if the user is redirected to the session expired route
   * @returns {Promise<void>}
   */
  const logout = (isSessionExpired = false) => {
    logoutOnBackend().then(() => {
      // Sign out the user from Firebase
      auth.signOut().then(() => {
        sendSegmentEvent('landlord_logged_out', {
          user_type: 'landlord',
          event_source: 'landlord_portal',
        });

        manageStorageAfterLogout();
        manageLocalStorageAfterLogout();
        sessionStorage.clear();

        if (window?.analytics?.user) {
          window.analytics.user().reset();
        }

        // Redirect the user to the login page (with or without the session expired route)
        const logoutDestination = isSessionExpired ? `${LOGIN}/${SESSION_EXPIRED}` : LOGIN;
        navigate(logoutDestination);
      });
    });
  };

  return {
    logout,
  };
};

export default useLogout;
