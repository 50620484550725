import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Box,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { Icon16Check } from '@icons/16px';
import { BaselaneButton } from '@shared/components';
import { BOOKKEEPING_RULES } from '@routes';
import useBreakPoints from '@core/hooks/useBreakPoints';

type AutoTagPromoSuccessModalProps = {
  open: Boolean,
  state?: string,
  onClose: Function,
};

const AutoTagPromoSuccessModal = ({ open, onClose, state }: AutoTagPromoSuccessModalProps) => {
  const { isMin768 } = useBreakPoints();
  const navigate = useNavigate();

  return (
    <Modal isOpen={open} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth={isMin768 ? '600px' : 'auto'}>
        <ModalHeader fontWeight="normal" pl={0} pr={0} pb={0} pt={3}>
          <ModalCloseButton />
          {state && (
            <Box
              border="1px solid"
              borderColor="green.200"
              bg="green.100"
              borderRadius="6px"
              p={1.5}
              mt={0}
              mr={6}
              ml={3}
              mb={3}
            >
              <Heading size="headline-sm" mb={0.5}>
                AI categorization enabled
              </Heading>
              <Flex gap={1} alignItems="center">
                <Icon16Check />
                <Text textStyle="sm">All new transactions will be auto-categorized</Text>
              </Flex>
              {state === 'newAndUncategorized' && (
                <Flex gap={1} alignItems="center">
                  <Icon16Check />
                  <Text textStyle="sm">
                    Existing uncategorized transactions will be auto-categorized
                  </Text>
                </Flex>
              )}
            </Box>
          )}
        </ModalHeader>
        <ModalBody data-cy="popup-body" pl={4} pr={4} pt={0} pb={0}>
          <Heading size="headline-lg" mb={0.5}>
            Personalize it further with bookkeeping rules
          </Heading>
          <Text textStyle="sm">
            Set up custom rules to auto-tag transactions to specific categories and properties.
          </Text>
          {!state && (
            <Text textStyle="sm">
              You can also manage AI Categorization within the bookkeeping rules page.
            </Text>
          )}
        </ModalBody>
        <ModalFooter pt={3} pb={3} pl={4} pr={4}>
          <BaselaneButton
            palette="primary"
            variant="filled"
            w="100%"
            size="lg"
            onClick={() => {
              onClose();
              navigate(`/${BOOKKEEPING_RULES}`);
              sendSegmentEvent('autotagging_gtm_modal_ai_settings_complete', {
                enabled: state === 'newTransactionsOnly' || state === 'newAndUncategorized',
                existing: state === 'newAndUncategorized',
              });
            }}
          >
            Continue
          </BaselaneButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AutoTagPromoSuccessModal;
