import process from 'process';

export const zendeskUserKey =
  process && process.env.REACT_APP_ZENDESK_USER_KEY === undefined
    ? ''
    : process.env.REACT_APP_ZENDESK_USER_KEY;

export const statsigClientKey =
  process && process.env.REACT_APP_STATSIG_CLIENT_KEY === undefined
    ? ''
    : process.env.REACT_APP_STATSIG_CLIENT_KEY;

export const serviceBellApiKey =
  process && process.env.REACT_APP_SERVICEBELL_API_KEY === undefined
    ? ''
    : process.env.REACT_APP_SERVICEBELL_API_KEY;

export const customerIOApiKey =
  process && process.env.REACT_APP_CUSTOMER_IO_API_KEY === undefined
    ? ''
    : process.env.REACT_APP_CUSTOMER_IO_API_KEY;

export const customerIOSiteId =
  process && process.env.REACT_APP_CUSTOMER_IO_SITE_ID === undefined
    ? ''
    : process.env.REACT_APP_CUSTOMER_IO_SITE_ID;

export const latchelPartnerKey =
  process && process.env.REACT_APP_LATCHEL_PARTNER_KEY === undefined
    ? ''
    : process.env.REACT_APP_LATCHEL_PARTNER_KEY;

export const firebaseWebKey =
  process && process.env.REACT_APP_FIREBASE_WEB_KEY === undefined
    ? 'eyJhcGlLZXkiOiIxIn0=' // random apiKey as backup
    : process.env.REACT_APP_FIREBASE_WEB_KEY;

export const achFeeEnabledFeatureFlag =
  process && process.env.REACT_APP_ACH_FEE_ENABLED_FEATURE_FLAG === undefined
    ? 'false'
    : process.env.REACT_APP_ACH_FEE_ENABLED_FEATURE_FLAG;

export const fingerPrintAPIKey =
  process && process.env.REACT_APP_FINGERPRINT_API_KEY === undefined
    ? ''
    : process.env.REACT_APP_FINGERPRINT_API_KEY;

export const fingerPrintAPIEndpoint =
  process && process.env.REACT_APP_FINGERPRINT_API_ENDPOINT === undefined
    ? ''
    : process.env.REACT_APP_FINGERPRINT_API_ENDPOINT;

export const redditPixelId =
  process && process.env.REACT_APP_REDDIT_PIXEL_ID === undefined
    ? null
    : process.env.REACT_APP_REDDIT_PIXEL_ID;

export const reactNodeEnv =
  process && process.env.REACT_APP_NODE_ENV === undefined
    ? process.env.NODE_ENV
    : process.env.REACT_APP_NODE_ENV;

export const RLSandboxUrl =
  process && process.env.REACT_APP_RL_SANDBOX_URL === undefined
    ? ''
    : process.env.REACT_APP_RL_SANDBOX_URL;

export const RLSandboxUrlV2 =
  process && process.env.REACT_APP_RL_SANDBOX_URL_V2 === undefined
    ? ''
    : process.env.REACT_APP_RL_SANDBOX_URL_V2;

export const RLDefaultUrl =
  process && process.env.REACT_APP_RL_URL === undefined ? '' : process.env.REACT_APP_RL_URL;

export const RLDefaultUrlV2 =
  process && process.env.REACT_APP_RL_URL_V2 === undefined ? '' : process.env.REACT_APP_RL_URL_V2;

export const unitApiUrl =
  process && process.env.REACT_APP_UNIT_SERVICE_URL === undefined
    ? ''
    : process.env.REACT_APP_UNIT_SERVICE_URL;

export const orchestrationUrl =
  process && process.env.REACT_APP_ORCHESTRATION_URL === undefined
    ? ''
    : process.env.REACT_APP_ORCHESTRATION_URL;

export const inactivitySeconds =
  process && process.env.REACT_APP_INACTIVITY_TIMEOUT_DELAY_SECONDS === undefined
    ? null
    : process.env.REACT_APP_INACTIVITY_TIMEOUT_DELAY_SECONDS;

export const vouchedId =
  process && process.env.REACT_APP_VOUCHED === undefined ? '' : process.env.REACT_APP_VOUCHED;

export const recaptchaSiteKey =
  process && process.env.REACT_APP_RECAPTCHA_SITE_KEY === undefined
    ? ''
    : process.env.REACT_APP_RECAPTCHA_SITE_KEY;
