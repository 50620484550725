// @flow
import React from 'react';
import { AccordionItem, AccordionButton, AccordionPanel, HStack, Box } from '@chakra-ui/react';
import IconCarretDown from '@icons/manual/IconCarretDown';
import IconCarretUp from '@icons/manual/IconCarretUp';
import {
  iconStyles,
  headerStyles,
  buttonStyles,
  iconContainerStyles,
  contentStyles,
} from './styles/accordion.style';

type Props = {
  headerElements: any,
  contentColumnTitleElements?: any,
  contentElements: any,
  allowToggle?: Boolean,
  allowMultiple?: Boolean,
  defaultIndex?: any,
  containerStyles?: Object,
  headerStyles?: Object,
  buttonStyles?: Object,
  contentColumnTitleStyles?: Object,
  contentStyles?: Object,
  expandedContentStyles?: Object,
  iconContainerStyles?: Object,
  iconStyles?: Object,
  IconUp?: any,
  IconDown?: any,
  contentHasTopBorder?: boolean,
  id?: string,
};

const BaselaneAccordion = ({
  headerElements,
  contentColumnTitleElements,
  contentElements,
  containerStyles,
  buttonStyles: bStyles,
  contentStyles: acStyles,
  expandedContentStyles: ecStyles,
  contentColumnTitleStyles: cTitleStyles,
  iconContainerStyles: icStyles,
  iconStyles: icoStyles,
  IconUp = IconCarretUp,
  IconDown = IconCarretDown,
  contentHasTopBorder,
  id,
}: Props): any => {
  const accordionContainerStyles = {
    ...headerStyles,
    ...containerStyles,
  };
  const accordionButtonStyles = (isExpanded) => {
    return {
      ...buttonStyles,
      borderBottomLeftRadius: isExpanded ? 0 : '4px',
      borderBottomRightRadius: isExpanded ? 0 : '4px',
      ...bStyles,
    };
  };

  const accordionContentStyles = (isExpanded) => {
    const resolvedContentStyles = contentHasTopBorder ? contentStyles : null;

    return {
      ...resolvedContentStyles,
      ...acStyles,
      ...(isExpanded && ecStyles),
    };
  };

  const accordionIconContainerStyles = {
    ...iconContainerStyles,
    ...icStyles,
  };
  const accordionIconStyles = {
    ...iconStyles,
    ...icoStyles,
  };

  // NOTE: if using button inside AccordionButton/headerElements. You will need to use event.stopPropogation for now. Will need to confirm UI/UX with designer.
  return (
    <AccordionItem id={id} {...accordionContainerStyles}>
      {({ isExpanded }) => (
        <>
          <AccordionButton as={Box} {...accordionButtonStyles(isExpanded)}>
            <HStack {...accordionIconContainerStyles}>
              {isExpanded ? (
                <IconUp {...accordionIconStyles} />
              ) : (
                <IconDown {...accordionIconStyles} />
              )}
            </HStack>
            {headerElements}
          </AccordionButton>

          <AccordionPanel {...accordionContentStyles(isExpanded)}>
            <Box {...cTitleStyles}>{contentColumnTitleElements}</Box>
            {contentElements}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

BaselaneAccordion.defaultProps = {
  allowToggle: false,
  allowMultiple: false,
  defaultIndex: Array,
  IconUp: IconCarretUp,
  IconDown: IconCarretDown,
  contentColumnTitleElements: null,
  containerStyles: Object,
  headerStyles: Object,
  buttonStyles: Object,
  contentStyles: Object,
  expandedContentStyles: Object,
  iconContainerStyles: Object,
  contentColumnTitleStyles: Object,
  iconStyles: Object,
  contentHasTopBorder: true,
  id: '',
};

export default BaselaneAccordion;
