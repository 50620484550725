import moment from 'moment';
import { isSameDay, getSplitPropertyUnitId, parseTagId } from './formHelpers';

export const sendFundsMutationInput = (values, accountsMap) => {
  let input = {};
  const { propertyId, unitId } = getSplitPropertyUnitId(values.propertyId);
  if (values?.paymentMethodType === 'ACH' || values?.paymentMethodType === 'TRANSFER_OUT') {
    input = {
      fromTransferAccountId: accountsMap[values.fromTransferAccountId]?.transferAccountId,
      payeeId: +values.payeeId,
      paymentMethodId: +values.paymentMethodId,
      amount: parseFloat(values.amount?.replace(/,/g, '')), // It comes as string, that may have commas, so we need to convert it to float.
      bookKeepingNote: values?.note, // The true "Note" field maps to "bookKeepingNote" in the backend.
      type: 'TRANSFER_OUT',
      tagId: parseTagId(values.tagId),
      propertyId,
      unitId,
      sameDay: isSameDay(values.oneTimeTransfer.date),
      transferDate: moment(values.oneTimeTransfer.date).format('YYYY-MM-DD'),
    };
  }
  if (values?.paymentMethodType === 'WIRE_TRANSFER') {
    input = {
      amount: parseFloat(values.amount?.replace(/,/g, '')), // It comes as string, that may have commas, so we need to convert it to float.
      fromTransferAccountId: accountsMap[values.fromTransferAccountId]?.transferAccountId,
      propertyId,
      tagId: parseTagId(values.tagId),
      unitId,
      payeeId: +values.payeeId,
      bookKeepingNote: values?.note, // The true "Note" field maps to "bookKeepingNote" in the backend.
      note: values?.description, // The true "Description" field maps to "note" in the backend.
      type: 'WIRE_TRANSFER',
      paymentMethodId: +values.paymentMethodId,
      sameDay: isSameDay(values.oneTimeTransfer.date),
      transferDate: moment(values.oneTimeTransfer.date).format('YYYY-MM-DD'),
    };
  }
  if (values?.paymentMethodType === 'CHECK_PAYMENT') {
    input = {
      amount: parseFloat(values.amount?.replace(/,/g, '')), // It comes as string, that may have commas, so we need to convert it to float.
      fromTransferAccountId: accountsMap[values.fromTransferAccountId]?.transferAccountId,
      propertyId,
      tagId: parseTagId(values.tagId),
      unitId,
      payeeId: +values.payeeId,
      bookKeepingNote: values?.note, // The true "Note" field maps to "bookKeepingNote" in the backend.
      note: values?.description, // The true "Description" field maps to "note" in the backend.
      type: 'CHECK_PAYMENT',
      paymentMethodId: +values.paymentMethodId,
      sameDay: isSameDay(values.oneTimeTransfer.date),
      transferDate: moment(values.oneTimeTransfer.date).format('YYYY-MM-DD'),
    };
  }
  return input;
};
