import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';
import { reload } from '@core/Services/Firebase/firebase';
import { DASHBOARD, PARTNERSHIPS } from '@routes';
import { BaselaneBannerNew, VerifyEmailModal } from '@shared/components';
import { relativePositionStyling } from '@shared/styles/banner.style';
import OTPContext from '@core/contexts/EmailOtpContext';
import UserContext from '@contexts/UserContext';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { IconVerifyEmail } from '@icons';
import { FEATURE_GATES } from '@core/constants/statsigKeys';

type EmailVerificationBannerProps = {
  email: String,
};

const BannerIcon = () => <IconVerifyEmail color="currentColor" fillColor="#F9BC3B" />;

const EmailVerificationBanner = ({ email }: EmailVerificationBannerProps) => {
  const { handleSendOtpRegisteredUser } = useContext(OTPContext);
  const { setEmailVerified } = useContext(UserContext);
  const [showEmailVerifyPopup, setEmailVerifyPopup] = useState(false);

  const { checkGate } = useStatsigClient();

  const { pathname } = useLocation();
  const { isMin768: isDesktop } = useBreakPoints();
  // Due to how the Entities and Partners page are structured, the banner does not need relative positioning
  const isRouteExcludedFromRelativePositioning =
    pathname.includes('/banking/accounts/baselane_') ||
    pathname.includes(PARTNERSHIPS) ||
    (pathname.includes(DASHBOARD) && checkGate(FEATURE_GATES.DASHBOARD_GATE));

  const onButtonClick = async () => {
    const emailVerifiedByFirebase = await reload();
    if (!emailVerifiedByFirebase) {
      setEmailVerifyPopup(true);
      handleSendOtpRegisteredUser({ isTriggeredFromModal: false });
    } else {
      setEmailVerified(true);
    }
  };

  const onVerifyEmailOtpSuccess = async () => {
    const emailVerifiedByFirebase = await reload();
    if (emailVerifiedByFirebase) {
      setEmailVerifyPopup(false);
      setEmailVerified(true);
    }
  };

  return (
    <>
      <BaselaneBannerNew
        id="verify-email-banner"
        title="Verify your email to move money"
        body="We'll send you a verification code"
        btnLabel="Verify now"
        variant="warning-high"
        styles={relativePositionStyling(isDesktop && !isRouteExcludedFromRelativePositioning)}
        isVertical={!isDesktop}
        hasCloseButton={false}
        bannerIconName={BannerIcon}
        onButtonClick={onButtonClick}
      />
      {showEmailVerifyPopup && (
        <VerifyEmailModal
          email={email}
          onVerifyEmailOtpSuccess={onVerifyEmailOtpSuccess}
          onCloseBtnClick={() => setEmailVerifyPopup(false)}
        />
      )}
    </>
  );
};

export default EmailVerificationBanner;
