import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useShallow } from 'zustand/react/shallow';
import { TRANSACTIONS } from '@routes';
import { BaselaneButton } from '@shared/components';
import useCSVImportStore from '@store/CSVImport';
import { IllustrationOtherInprogress } from '@illustrations';

type SuccessModalProps = {
  isOpen: Boolean,
  onClose: Function,
};

const SuccessModal = ({ isOpen, onClose }: SuccessModalProps) => {
  const alertCancelRef = useRef(null);
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const { setToInitialValues } = useCSVImportStore(
    useShallow((state) => ({ setToInitialValues: state.setToInitialValues }))
  );

  const onClickContinue = () => {
    setLoading(true);
    setToInitialValues();
    onClose();
    navigate(TRANSACTIONS);
  };

  return (
    <AlertDialog
      leastDestructiveRef={alertCancelRef}
      isOpen={isOpen}
      isCentered
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="sm"
    >
      <AlertDialogOverlay />
      <AlertDialogContent p={4}>
        <Stack spacing={2} mb={3}>
          <IllustrationOtherInprogress />
          <Stack spacing={0.25}>
            <Heading size="headline-lg">Transactions are being imported</Heading>
            <Text textStyle="sm">You will receive an email once it&apos;s complete.</Text>
          </Stack>
        </Stack>

        <BaselaneButton
          size="md"
          variant="filled"
          palette="primary"
          isLoading={isLoading}
          onClick={onClickContinue}
        >
          Continue
        </BaselaneButton>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default SuccessModal;
