import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation, Outlet, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { ChakraProvider, VStack, useToast } from '@chakra-ui/react';

import { useMutation } from '@apollo/client';
import { BaselaneButton, BaselaneDrawer } from '@core/components/Shared/components';
import useBreakPoints from '@core/hooks/useBreakPoints';
import onDrawerClose from '@core/utils/onDrawerClose';
import habitatTheme from '@core/themeHabitat';
import { Icon16Delete } from '@core/components/Icons/16px';
import {
  ADD_PAYEE_PAYMENT_METHOD_CHECK,
  GET_PAYEES,
  UPDATE_PAYEE_PAYMENT_METHOD_CHECK,
} from '@core/apollo/queries';
import {
  TRANSFERS_RECIPIENT_DELETE,
  TRANSFERS_PAYMENT_METHOD_DELETE,
} from '@core/constants/routes';
import { recipientCheckPaymentValidation } from '../../helpers/validation.helpers';
import PaymentMethodFieldsCheck from '../fields/PaymentMethodFieldsCheck';
import { onSubmitPaymentMethodCheck } from '../../helpers/submission.helpers';

const PaymentMethodDetailsCheck = () => {
  const { isMax576 } = useBreakPoints();

  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location;
  const { from, isOnlyPaymentMethod } = state || '';
  const { paymentMethods } = state || {};
  const { checkPaymentMethods } = paymentMethods || {};
  const { id, isDeletable } = checkPaymentMethods || {};
  const { recipientId } = useParams();

  const formikRef = useRef();

  /* Note: initial form state. State passed to the location is applied if available. */
  const initialValues = {
    id,
    name: checkPaymentMethods?.name || '',
    address: checkPaymentMethods?.address || '',
    city: checkPaymentMethods?.city || '',
    state: checkPaymentMethods?.state || '',
    zipcode: checkPaymentMethods?.zipcode || '',
    unit: checkPaymentMethods?.unit || '',
  };

  const [updatePaymentMethod, { loading: isUpdateCheckPaymentMethodSaving }] = useMutation(
    UPDATE_PAYEE_PAYMENT_METHOD_CHECK,
    {
      refetchQueries: [GET_PAYEES],
    }
  );

  const [addPaymentMethod, { loading: isAddPaymentMethodSaving }] = useMutation(
    ADD_PAYEE_PAYMENT_METHOD_CHECK,
    {
      refetchQueries: [GET_PAYEES],
    }
  );

  const toast = useToast();

  const showUpdatePaymentMethodErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Error saving payment method.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const onSubmit = onSubmitPaymentMethodCheck(
    from,
    id,
    recipientId,
    paymentMethods,
    navigate,
    updatePaymentMethod,
    addPaymentMethod,
    showUpdatePaymentMethodErrorToast
  );

  const handleDelete = () => {
    if (isOnlyPaymentMethod) {
      navigate(TRANSFERS_RECIPIENT_DELETE, { state: { from: location?.pathname } });
    } else {
      navigate(TRANSFERS_PAYMENT_METHOD_DELETE, {
        state: { from: location?.pathname, paymentId: id },
      });
    }
  };

  // If there is no "from" then the page was reloaded and
  // there is no way to restore or use the data reliably
  // go back to the last place in history
  useEffect(() => {
    if (!from) {
      onDrawerClose(navigate);
    }
  }, [from]);

  return (
    <ChakraProvider theme={habitatTheme}>
      <Formik
        innerRef={formikRef}
        validateOnChange
        validateOnBlur
        initialValues={initialValues}
        validate={recipientCheckPaymentValidation}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
          errors,
          touched,
          dirty,
          isSubmitting,
          isValid,
        }) => (
          <Form>
            <BaselaneDrawer
              isOpen
              size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
              title="Check Details"
              hideOverlay
              footer={
                <>
                  <BaselaneButton
                    size="md"
                    variant="outline"
                    palette="neutral"
                    onClick={() => onDrawerClose(navigate, from)}
                  >
                    Cancel
                  </BaselaneButton>
                  <BaselaneButton
                    size="md"
                    variant="filled"
                    palette="primary"
                    onClick={handleSubmit}
                    w="100%"
                    ml={1.5}
                    isDisabled={
                      !isValid ||
                      !dirty ||
                      isUpdateCheckPaymentMethodSaving ||
                      isAddPaymentMethodSaving
                    }
                  >
                    Save
                  </BaselaneButton>
                </>
              }
              closeEvent={() => onDrawerClose(navigate, from)}
              onOverlayClick={() => onDrawerClose(navigate, from)}
              closeOnOverlayClick={false}
              newDesignDrawer
            >
              <VStack
                h="full"
                alignItems="flex-start"
                justifyContent={id ? 'space-between' : 'flex-start'}
                ap={0}
              >
                <PaymentMethodFieldsCheck />
                {id && (
                  <BaselaneButton
                    variant="transparent"
                    palette="danger"
                    leftIcon={<Icon16Delete />}
                    onClick={handleDelete}
                    size="md"
                    styles={{ mt: 'auto' }}
                    isDisabled={!isDeletable}
                    tooltipText={
                      !isDeletable &&
                      'Payment method cannot be deleted as there is a payment scheduled for it'
                    }
                  >
                    Delete payment method
                  </BaselaneButton>
                )}
              </VStack>
            </BaselaneDrawer>
          </Form>
        )}
      </Formik>
      <Outlet />
    </ChakraProvider>
  );
};

export default PaymentMethodDetailsCheck;
