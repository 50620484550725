import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';
import { TRANSACTIONS } from '@routes';
import AddAccountDrawer from '@core/components/NativeBankingPage/Summary/AddAccountDrawer';

import { FEATURE_GATES } from '@core/constants/statsigKeys';
import Tracker from './Tracker';
import { InProgress } from './Steps';
import { getTrackerData, getLinkStatus } from '../helpers/bookkeeping.helpers';
import { getPropertyLink } from '../helpers/links/addProperty.helpers';

type BookKeepingProps = {
  data: Object,
  handleStepUpdate: Function,
  refetchUser: Function,
  propertiesProps: Object,
  palette: String,
  variant: String,
};

const BookKeeping = ({
  data,
  handleStepUpdate,
  refetchUser,
  propertiesProps,
  palette,
  variant,
}: BookKeepingProps) => {
  const navigate = useNavigate();

  const [showSteps, setShowSteps] = useState(false);
  const { checkGate } = useStatsigClient();

  const { propertiesSummaryError, propertiesSummaryLoading } = propertiesProps ?? {};
  const isLoading = propertiesSummaryLoading;
  const hasError = propertiesSummaryError;

  const trackerData = getTrackerData();
  const LINKS = getLinkStatus(trackerData.elements);

  const getImportTrxsLink = (status, state) => {
    return (
      <AddAccountDrawer
        {...{
          handleExternalAccountDrawerCloseExternally: () => refetchUser(),
          buttonSize: 'lg',
        }}
        key={`import_trxs_bookkeeping_${state}`}
        isGetStarted
        getStartedConfig={{ status }}
        showOnlyPopup={false}
        palette={palette}
        variant={variant}
      />
    );
  };

  const getCategorizeFirstTrxLink = (status, state) => {
    return (
      <InProgress
        status={status}
        key={`categorize_first_transactions_bookkeeping_${state}`}
        handleTrackerInProgStepClick={() => navigate(TRANSACTIONS)}
      />
    );
  };

  const getLinkTo = (step, state) => {
    const status = state === 'disabled' ? LINKS[step].not_started : LINKS[step][state];
    const fromPage = checkGate(FEATURE_GATES.DASHBOARD_GATE) ? 'dashboard' : 'get_started';
    switch (step) {
      case 'ADD_PROPERTY':
        return getPropertyLink({
          key: `add_property_bookkeeping_${state}`,
          from: { page: fromPage, section: 'bookkeeping_tracker', configs: { status } },
          handleStepUpdate,
          refetchUser,
          propertiesProps,
        });

      case 'IMPORT_TRANSACTIONS':
        return getImportTrxsLink(status, state);

      case 'CATEGORIZE_FIRST_TRANSACTION':
        return getCategorizeFirstTrxLink(status, state);

      default:
        return null;
    }
  };

  return (
    <Tracker
      tracker={{ data: trackerData, getLinkTo }}
      steps={data}
      isLoading={isLoading}
      hasError={hasError}
      showSteps={showSteps}
      setShowSteps={setShowSteps}
      key="bookkeeping"
    />
  );
};

export default BookKeeping;
