import React, { useState } from 'react';
import { Box, ChakraProvider, Heading, Input, Stack } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import BaselaneAlert from '@shared/components/BaselaneAlert';
import BaselaneButton from '@shared/components/BaselaneButton';
import BaselaneButtonGroup from '@shared/components/BaselaneButtonGroup';
import BaselaneRadio from '@shared/components/BaselaneRadio';
import BaselaneRadioGroup from '@shared/components/BaselaneRadioGroup';
import useBreakPoints from '@core/hooks/useBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import habitatTheme from '@core/themeHabitat';
import { IconQuestion } from '@icons';
import { formInputStyles } from '@shared/styles/input.style';

import {
  headerStyles,
  bodyStyles,
  footerStyles,
  containerStyles,
  focusStyles,
} from './styles/cancelSurvey.styles';

type CancelSurveyProps = {
  headerIcon: React.FunctionComponent,
  header: React.FunctionComponent,
  surveyConfig: Object,
  segmentEvent: string,
  footer: React.FunctionComponent,
  isOpen: Boolean,
  setIsOpen: Boolean,
  onSubmit: Function,
  buttonLoading: Boolean,
};

const CancelSurvey = ({
  headerIcon,
  header,
  surveyConfig = null,
  segmentEvent,
  footer,
  isOpen,
  setIsOpen,
  onSubmit,
  buttonLoading,
}: CancelSurveyProps) => {
  const { isMinXL } = useBreakPoints();
  const [surveyValue, setSurveyValue] = useState(null);
  const [otherAnswer, setOtherAnswer] = useState(null);

  const handleContinue = () => {
    if (surveyValue !== 'Other') {
      sendSegmentEvent(segmentEvent, {
        reason: surveyValue,
      });
    } else {
      sendSegmentEvent(segmentEvent, {
        reason: 'Other',
        otherReason: otherAnswer,
      });
    }
    onSubmit();
  };

  const handleSkip = () => {
    sendSegmentEvent(segmentEvent, {
      reason: 'Skipped',
    });
    onSubmit();
  };

  const defaultHeaderIcon = (
    <Box marginLeft="4px">
      <IconQuestion />
    </Box>
  );

  const defaultHeader = (
    <Heading color="brand.neutral.900" size="headline-lg" marginBottom="8px">
      {header || 'A quick question'}
    </Heading>
  );

  const defaultTitle = surveyConfig?.question ? (
    <Heading color="brand.neutral.900" size="headline-sm" marginBottom="12px">
      {surveyConfig?.question}
    </Heading>
  ) : null;

  const defaultFooter = (
    <Stack p="12px">
      <BaselaneButtonGroup size="md">
        <BaselaneButton variant="outline" palette="neutral" size="md" onClick={handleSkip}>
          Skip
        </BaselaneButton>
        <BaselaneButton
          variant="filled"
          palette="primary"
          size="md"
          isDisabled={!surveyValue || (surveyValue === 'Other' && !otherAnswer)}
          isLoading={buttonLoading}
          onClick={handleContinue}
        >
          Continue
        </BaselaneButton>
      </BaselaneButtonGroup>
    </Stack>
  );

  return (
    <BaselaneAlert
      {...{
        isOpen,
        onClose: () => setIsOpen(false),
        closeOnOverlayClick: true,
        isCentered: true,
        showCloseButton: false,
        size: isMinXL ? 'sm' : 'md',
        header: headerIcon || defaultHeaderIcon,
        headerStyles,
        body: (
          <Stack p="16px 16px 8px">
            {defaultHeader}
            <Box>
              <BaselaneRadioGroup
                name="CancelSurvey"
                onChange={(e) => {
                  if (e !== 'Other') {
                    setOtherAnswer('');
                  }
                  setSurveyValue(e);
                }}
                title={defaultTitle || ''}
                value={surveyValue}
                defaultValue={surveyValue}
                gap={1.5}
              >
                {surveyConfig?.answers?.map((question) => (
                  <>
                    <BaselaneRadio
                      defaultChecked={false}
                      key={uuidv4()}
                      value={question.label}
                      label={question.label}
                      sx={focusStyles}
                      isChecked={surveyValue === question.label}
                      mb={0.5}
                    />
                    {question.value === 'other' && (
                      <ChakraProvider theme={habitatTheme}>
                        <Input
                          {...formInputStyles}
                          width="auto"
                          marginLeft="24px"
                          id="finish-later-reason-other-answer"
                          name="finish-later-reason-other-answer"
                          value={otherAnswer}
                          onFocus={() => setSurveyValue('Other')}
                          onChange={(e) => {
                            setSurveyValue('Other');
                            setOtherAnswer(e.target.value);
                          }}
                          placeholder="Please specify"
                          maxLength="2000"
                        />
                      </ChakraProvider>
                    )}
                  </>
                ))}
              </BaselaneRadioGroup>
            </Box>
          </Stack>
        ),
        bodyStyles: bodyStyles(isMinXL),
        footer: footer || defaultFooter,
        footerStyles: footerStyles(isMinXL),
        containerStyles: containerStyles(isMinXL),
      }}
    />
  );
};

export default CancelSurvey;
