import process from 'process';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import turnBaseModelArrayToMap from '@core/utils/turnBaseModelArrayToMap';
import { GET_PROPERTIES_DROPDOWN, UPDATE_TRANSACTIONS, GET_BANKS } from '@core/apollo/queries';
import { useTags, useTransactionsSummary } from '@shared/hooks';
import { transactionsDummyData } from '@shared/helpers/dummyData.helpers';
import sortCategories from '@core/utils/sort/sortCategories';
import type { BaseModel } from '@shared/types';
import { ANALYTICS_REPORTING, NB_ACCOUNTS } from '@routes';
import {
  getAccountMap,
  getAllAccountsMap,
  getConnectedAccountOptions,
  getConnectedAccountsFilterOptions,
  getConnectedExtManualAccOptions,
} from './helpers';
import getBaseModel from '../../utils/baseModel';
import { DEFAULT_FILTERS_OPTIONS } from '../../components/Transactions/services/FilterService';
import {
  buildPresentationProperties,
  createPropertyIdsMap,
} from '../../components/Transactions/helpers/propertyInput.helper';

const TransactionContext = React.createContext({});

export const TransactionProvider = ({ children }: any) => {
  const currentUrl = useLocation();
  const { pathname } = currentUrl;

  const isCashFlowPage = pathname === ANALYTICS_REPORTING;

  // detection of entity page
  const pathSegments = pathname.split('/');
  pathSegments.shift(); // eliminate empty first element
  const finalSegment = pathSegments[pathSegments.length - 1];
  const isBankingEntityPage =
    pathname.includes(NB_ACCOUNTS) && finalSegment && finalSegment.includes('baselane_');

  // if we are on the banking entity page, extract the baselane account id from the URL
  const baselaneEntityId = isBankingEntityPage ? finalSegment.split('baselane_')[1] : null;

  const load20 = isCashFlowPage; // set pageLimit to 20: load 20 trxs at a time
  const loadOption = load20 ? 'load20' : 'default';
  const isNativeBanking = window.location.href.indexOf('/banking') > -1;

  const filterOption = isNativeBanking ? 'nativeBanking' : loadOption;

  const DEFAULT_FILTERS = DEFAULT_FILTERS_OPTIONS[filterOption];

  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [summaryFilter, setSummaryFilter] = useState({});
  const [hasDataUpdated, setHasDataUpdated] = useState(false);
  const [hasUpdatedSplitTrx, setHasUpdatedSplitTrx] = useState(false);

  const { loading: propertiesLoading, error: propertiesError, data: propertiesData } = useQuery(
    GET_PROPERTIES_DROPDOWN
  );

  const [updateTransactions, { loading: updateTransactionLoading }] = useMutation(
    UPDATE_TRANSACTIONS,
    {
      onCompleted: () => {
        if (!isCashFlowPage && filters.filter !== DEFAULT_FILTERS.filter) setHasDataUpdated(true);
        else if (isCashFlowPage) setHasDataUpdated(true);
      },
    }
  );

  const {
    loading: accountsLoading,
    error: accountsError,
    data: accountsData,
    refetch: refetchAccountsData,
  } = useQuery(GET_BANKS, {
    fetchPolicy: 'no-cache',
  });

  const {
    loading: connectedAccountsLoading,
    error: connectedAccountsError,
    data: connectedAccountsData,
    refetch: refetchConnectedAccountsData,
  } = useQuery(GET_BANKS, {
    fetchPolicy: 'no-cache',
    variables: { isConnectedAccount: true },
  });

  // tags
  const {
    transactionsSummaryLoading,
    transactionsSummaryError,
    transactionsSummaryData,
    refetchTransactionsSummaryData,
    startPollingTransactionsSummary,
    stopPollingTransactionsSummary,
    mtdUncategorizedCount,
    mtdUncategorizedUnhiddenTxCount,
    uncategorizedAmount,
    uncategorizedAbsoluteAmount,
    uncategorizedHiddenTxCount,
    uncategorizedCountData,
    categorizedTxCount,
    categorizedTxCountInPercentage,
    categorizedHiddenTxCount,
    totalUncategorizedHiddenTxCount,
    totalTransactionCount,
    totalUnreviewedTransactions,
  } = useTransactionsSummary({ input: summaryFilter });

  const {
    tagsLoading,
    tagsError,
    categoryOptions,
    categoryMap,
    categoryWithSubOptions,
    categoryIdsMap,
  } = useTags();

  const accountMap = getAccountMap(accountsData);
  const allAccountsMap = getAllAccountsMap(accountsData);
  const getAccountById = (id) => {
    const { bankAccounts = [] } =
      accountsData?.bank?.find((b) => {
        return b.bankAccounts.find((acc) => acc.id === id);
      }) ?? {};
    return bankAccounts?.find((acc) => acc.id === id);
  };

  const connectedAccountOptions = getConnectedAccountOptions(connectedAccountsData);
  const connectedAccountsFilterOptions = getConnectedAccountsFilterOptions(
    connectedAccountsData,
    baselaneEntityId
  );

  const connectedExtManualAccOptions = getConnectedExtManualAccOptions(connectedAccountsData);

  const propertyOptions: Array<BaseModel> = (propertiesData?.property || []).map(getBaseModel);
  const propertyMap = turnBaseModelArrayToMap(propertyOptions);

  const propertiesFromResponse = propertiesData?.property || [];
  const initalPresentationProperties = buildPresentationProperties(propertiesFromResponse);
  const idsMap = createPropertyIdsMap(initalPresentationProperties);

  const [presentationProperties, setPresentationProperties] = useState(
    initalPresentationProperties
  );

  const [propertyIdsMap, setPropertyIdsMap] = useState(idsMap);

  const state = useMemo(() => {
    const newState = { loading: false, error: false };

    // check loading and error state to show proper ui state
    newState.loading =
      connectedAccountsLoading ||
      accountsLoading ||
      propertiesLoading ||
      transactionsSummaryLoading ||
      tagsLoading;

    newState.error =
      connectedAccountsError ||
      propertiesError ||
      accountsError ||
      transactionsSummaryError ||
      tagsError;

    // check loading and error state to show proper ui state
    newState.accountsLoading = accountsLoading;
    newState.transactionsSummaryLoading = transactionsSummaryLoading;
    newState.transactionsSummaryError = transactionsSummaryError;
    newState.accountsError = accountsError;

    if (transactionsSummaryData) {
      newState.uncategorizedAmount = uncategorizedAmount;
      newState.totalTransactionCount = totalTransactionCount;
      newState.totalHiddenTransactionCount =
        totalUncategorizedHiddenTxCount + categorizedHiddenTxCount;
      newState.uncategorizedCount = uncategorizedCountData;
      newState.totalUncategorizedHiddenTxCount = totalUncategorizedHiddenTxCount;
      newState.uncategorizedHiddenTxCount = uncategorizedHiddenTxCount;
      newState.uncategorizedAbsoluteAmount = uncategorizedAbsoluteAmount;
      newState.mtdUncategorizedCount = mtdUncategorizedCount;
      newState.mtdUncategorizedUnhiddenTxCount = mtdUncategorizedUnhiddenTxCount;
      newState.categorizedTxCount = categorizedTxCount;
      newState.categorizedHiddenTxCount = categorizedHiddenTxCount;
      newState.categorizedTxCountInPercentage = categorizedTxCountInPercentage;
      newState.totalUnreviewedTransactions = totalUnreviewedTransactions;
      newState.refetchTransactionsSummaryData = refetchTransactionsSummaryData;
    }

    newState.filters = filters;
    newState.setFilters = setFilters;
    newState.summaryFilter = summaryFilter;
    newState.setSummaryFilter = setSummaryFilter;
    newState.DEFAULT_FILTERS = DEFAULT_FILTERS;
    newState.transactionsDummyData = transactionsDummyData;

    newState.hasDataUpdated = hasDataUpdated;
    newState.setHasDataUpdated = setHasDataUpdated;
    newState.updateTransactionLoading = updateTransactionLoading;
    newState.updateTransactions = updateTransactions;

    newState.hasDataUpdated = hasDataUpdated;
    newState.setHasDataUpdated = setHasDataUpdated;
    newState.updateTransactionLoading = updateTransactionLoading;
    newState.updateTransactions = updateTransactions;
    newState.hasUpdatedSplitTrx = hasUpdatedSplitTrx;
    newState.setHasUpdatedSplitTrx = setHasUpdatedSplitTrx;

    if (!(tagsLoading && tagsError)) {
      newState.categoryOptions = sortCategories(categoryOptions);
      newState.categoryWithSubOptions = sortCategories(categoryWithSubOptions);
      newState.categoryMap = categoryMap;
      newState.categoryIdsMap = categoryIdsMap;
    } else if (process.env.NODE_ENV === 'master') {
      console.log('Categories not ready.'); // Ansh: adding this for possible race condition debugging in UAT
    }

    if (!(propertiesLoading && propertiesError)) {
      newState.propertiesData = propertiesData?.property || [];
      newState.propertyOptions = propertyOptions;
      newState.propertyMap = propertyMap;
      newState.presentationProperties = presentationProperties;
      newState.initialProperties = initalPresentationProperties;
      newState.propertyIdsMap = propertyIdsMap;
      newState.setPresentationProperties = setPresentationProperties;
      newState.setPropertyIdsMap = setPropertyIdsMap;
    }

    if (!(accountsLoading && accountsError)) {
      newState.accountMap = accountMap; // here or in helper
      newState.allAccountsMap = allAccountsMap;
      newState.getAccountById = getAccountById;
      newState.refetchAccountsData = refetchAccountsData;
      newState.startPollingTransactionsSummary = startPollingTransactionsSummary;
      newState.stopPollingTransactionsSummary = stopPollingTransactionsSummary;
      newState.accountsData = accountsData;
    }

    if (!(connectedAccountsLoading && connectedAccountsError)) {
      newState.connectedAccountsData = connectedAccountsData?.bank || [];
      newState.connectedAccountOptions = connectedAccountOptions;
      newState.connectedAccountsFilterOptions = connectedAccountsFilterOptions;
      newState.connectedExtManualAccOptions = connectedExtManualAccOptions;
      newState.refetchConnectedAccountsData = refetchConnectedAccountsData;
    }

    newState.isTransactionSummaryOrAccountsLoading = transactionsSummaryLoading || accountsLoading;

    return newState;
  }, [
    DEFAULT_FILTERS,
    accountMap,
    accountsData,
    accountsError,
    accountsLoading,
    allAccountsMap,
    categorizedHiddenTxCount,
    categorizedTxCount,
    categorizedTxCountInPercentage,
    categoryIdsMap,
    categoryMap,
    categoryOptions,
    categoryWithSubOptions,
    connectedAccountOptions,
    connectedAccountsData?.bank,
    connectedAccountsError,
    connectedAccountsFilterOptions,
    connectedAccountsLoading,
    connectedExtManualAccOptions,
    filters,
    getAccountById,
    hasDataUpdated,
    initalPresentationProperties,
    mtdUncategorizedCount,
    mtdUncategorizedUnhiddenTxCount,
    presentationProperties,
    propertiesData?.property,
    propertiesError,
    propertiesLoading,
    propertyIdsMap,
    propertyMap,
    propertyOptions,
    refetchAccountsData,
    refetchConnectedAccountsData,
    refetchTransactionsSummaryData,
    startPollingTransactionsSummary,
    stopPollingTransactionsSummary,
    summaryFilter,
    tagsError,
    tagsLoading,
    totalTransactionCount,
    totalUncategorizedHiddenTxCount,
    transactionsSummaryData,
    transactionsSummaryError,
    transactionsSummaryLoading,
    uncategorizedAbsoluteAmount,
    uncategorizedAmount,
    uncategorizedCountData,
    uncategorizedHiddenTxCount,
    updateTransactionLoading,
    updateTransactions,
    hasUpdatedSplitTrx,
    setHasUpdatedSplitTrx,
  ]);

  useEffect(() => {
    refetchTransactionsSummaryData();
  }, [summaryFilter, presentationProperties, refetchTransactionsSummaryData]);

  return <TransactionContext.Provider value={state}>{children}</TransactionContext.Provider>;
};
export const TransactionConsumer = TransactionContext.Consumer;
export default TransactionContext;
