import React from 'react';
import { Heading, Stack, VStack } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CREATE_LEASE_DOCUMENT, INSURANCE, PARTNERSHIPS } from '@core/constants/routes';
import {
  IllustrationOtherInsurance,
  IllustrationOtherLeaseDocument,
} from '@core/components/Illustrations';
import { BaselaneButton } from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import DashboardPartnerCard from './DashboardPartnerCard';

const PartnersSection = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  return (
    <VStack alignItems="flex-start" gap={3} pb={4}>
      <Heading w="full" size="headline-xl" mb={{ base: 0, lg: 1 }}>
        Get more done with our trusted partners
      </Heading>
      <Stack w="full" flexDirection={{ base: 'column', lg: 'row' }} gap={3}>
        <DashboardPartnerCard
          Icon={IllustrationOtherInsurance}
          title="Landlord insurance that is right for you"
          body="Get the right protection for your rental property without breaking the bank. Receive a personalized quote instantly."
          onClick={() => {
            sendSegmentEvent('dashboard_click_insurance_cta');
            navigate(INSURANCE);
          }}
        />
        <DashboardPartnerCard
          Icon={IllustrationOtherLeaseDocument}
          title="Fastest way to create & sign lease documents"
          body="Create your lease document by answering a few quick questions, sign it online and send to tenants for signing. All in one place."
          onClick={() => {
            const title = pathname.substring(1);
            sendSegmentEvent('rocketlawyer_click_button', { title });
            navigate(CREATE_LEASE_DOCUMENT);
          }}
        />
      </Stack>
      <BaselaneButton
        variant="link"
        palette="primary"
        size="lg"
        textDecoration="none"
        fontWeight="medium"
        mr={0}
        onClick={() => {
          navigate({ pathname: PARTNERSHIPS });
        }}
      >
        View all partners
      </BaselaneButton>
    </VStack>
  );
};

export default PartnersSection;
