import React, { PropsWithChildren, ReactNode } from 'react';
import { Heading } from '@chakra-ui/react';
import BaselaneCardNew from '@shared/components/BaselaneCard-new/BaselaneCardNew';

type ReportHeaderProps = {
  icon: ReactNode,
};

const ReportHeader = ({ icon, children }: PropsWithChildren<ReportHeaderProps>) => {
  return (
    <BaselaneCardNew variant="inner" size="md" palette="dark">
      {icon}
      <Heading size="headline-md" w="full">
        {children}
      </Heading>
    </BaselaneCardNew>
  );
};

export default ReportHeader;
