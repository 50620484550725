import React from 'react';
import { useFormikContext } from 'formik';
import { Flex, Box, Text, Switch, FormControl, Input } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { currencyMask } from '@core/utils/masks';
import { BaselaneRadioGroup, BaselaneRadio, T1Dropdown } from '@shared/components';
import { Highlighted } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { amountOperators } from './constants';

type AmountProps = {
  isDisabled?: boolean,
};

const Amount = ({ isDisabled = false }: AmountProps) => {
  const { values, setFieldValue } = useFormikContext();

  const amountMask = {
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const itemRenderer = ({ item, search }) => (
    <Box textStyle="sm" color="inherit">
      <Highlighted text={item?.name} highlight={search} />
    </Box>
  );

  return (
    <Box
      border="1px solid"
      borderColor="brand.darkBlue.200"
      borderRadius="4px"
      background="brand.darkBlue.50"
      p={2}
      mt={2}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text textStyle="sm" fontWeight="500" color={isDisabled ? 'brand.neutral.400' : 'inherit'}>
          Amount
        </Text>
        <Switch
          value="some value"
          isChecked={values.isAmountOpen}
          onChange={() => setFieldValue('isAmountOpen', !values.isAmountOpen)}
          isDisabled={isDisabled}
        />
      </Flex>
      {values.isAmountOpen && !isDisabled && (
        <Box mt={3}>
          <FormControl id="amount">
            <BaselaneRadioGroup
              defaultValue="money-in"
              id="typeGroup"
              name="typeGroup"
              onChange={(type) => {
                setFieldValue('moneyType', type);
              }}
              value={values.moneyType}
            >
              <Flex gap={2}>
                <BaselaneRadio key="1" label="Money in" value="money-in" name="amount" />
                <BaselaneRadio key="2" label="Money out" value="money-out" name="amount" />
              </Flex>
            </BaselaneRadioGroup>
          </FormControl>
          <Box mt={2}>
            <T1Dropdown
              classNames={[
                'input-form-md',
                'auto-width-dropdown',
                'select-auto-width',
                'auto-select-input-width',
                'input-form-md',
                'auto-height',
              ]}
              hideSearch
              data={amountOperators}
              showValueByFields={['name']}
              itemRenderer={itemRenderer}
              handleSubmit={(value) => {
                setFieldValue(
                  'amountOperator',
                  amountOperators.find((item) => item.id === value)
                );
              }}
              selectedItem={amountOperators.find((item) => item.id === values.amountOperator?.id)}
            />
            <Box mt={2}>
              {values.amountOperator.operator === 'BETWEEN' ? (
                <Flex alignItems="center" gap={2}>
                  <Input
                    id="amount"
                    name="amount"
                    as={MaskedInput}
                    mask={currencyMask(amountMask)}
                    placeholder="$"
                    value={values.amountValue}
                    onChange={(e) => {
                      setFieldValue('amountValue', e.target.value);
                    }}
                    size="sm"
                    height="40px"
                    bg="white"
                  />
                  <Text textStyle="sm">and</Text>
                  <Input
                    id="amount"
                    name="amount"
                    as={MaskedInput}
                    mask={currencyMask(amountMask)}
                    placeholder="$"
                    value={values.amountSecondaryValue}
                    onChange={(e) => {
                      setFieldValue('amountSecondaryValue', e.target.value);
                    }}
                    size="sm"
                    height="40px"
                    bg="white"
                  />
                </Flex>
              ) : (
                <Input
                  id="amount"
                  name="amount"
                  as={MaskedInput}
                  mask={currencyMask(amountMask)}
                  placeholder="$"
                  value={values.amountValue}
                  onChange={(e) => {
                    setFieldValue('amountValue', e.target.value);
                  }}
                  size="sm"
                  height="40px"
                  bg="white"
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Amount;
