// @flow
import React, { useContext, useRef, useState } from 'react';
import BanksContext from '@contexts/BanksContext';
import BaselaneButton from '@shared/components/BaselaneButton';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';
import AddBankAccount from '@core/components/AddBankAccount';
import ActionBox from '@core/components/GetStartedPage/components/QuickActions/ActionBox';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import SlLoader from '@core/components/Loader';

import DrawerAction from './components/DrawerAction';
import SetUpThirdPartyView from './components/SetUpThirdPartyView';
import AddAccountMobileProps from './types/AddAccountType';
import LinkedAccountsDrawer from '../LinkedAccountsDrawer';
import UIOverlayLoader from '../UIOverlayLoader';
import { initialPlaidMessage } from './helpers/addAccount.helper';

// TODO: should this be moved to the most outermost component folder? since it is being used in summary and inside external accounts
function AddAccountMobile({
  buttonStyles,
  buttonProps,
  helperFunction,
  hideButton,
  isFromAddFunds,
  isFromLease,
  refetchLeaseBankAccounts = () => {},
  refetchTransferAccountsList,
  isDirectToPlaid,
  isDirectToPlaidButtonProps,
  from = { page: '', section: '', configs: {} },
  handleDrawerCloseExternally,
  handleAddAccountSuccessCallback,
  isPlaidButtonLoading,
  setIsPlaidButtonLoading,
  addExternalAccountDrawerRef,
  linkedAccountsDrawerOnClose,
  setIsOpenLinkedAccountDrawer = () => {},
}: AddAccountMobileProps) {
  const { banks, refetchBankSummary, linkedAccountsDrawerRef } = useContext(BanksContext);

  // drawer states and references
  const drawerFinalFocusRef = useRef();

  const [connectedExternalBank, setConnectedExternalBank] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [plaidText, setPlaidText] = useState(initialPlaidMessage);
  const [reconnectedBankIds, setReconnecteBankIds] = useState(null);

  // see if it's from a different page
  const { page, section, configs } = from ?? {};
  const isGetStarted = page === 'get_started' && section === 'quick_actions';

  const initView = () => {
    setPlaidText(initialPlaidMessage);
  };

  const onPlaidConfirmationCancel = () => {
    setPlaidText(initialPlaidMessage);
  };

  const handleDrawerClose = () => {
    initView();
    helperFunction();
  };

  const handleSuccessFn = (res) => {
    onPlaidConfirmationCancel();
    setConnectedExternalBank(res.data.linkBankWithUser);
    setIsOpenLinkedAccountDrawer(true);
    refetchLeaseBankAccounts();
    if (!banks || banks?.length === 0) {
      // only trigger on first bank account
      sendSegmentEvent('any_bank_added_fe', {
        title: document.title,
      });
      // reddit tracking
      window?.rdt('track', 'AddToCart');
    }
    sendSegmentEvent('landlord_bank_account_added_fe', {
      title: document.title,
    });
    handleAddAccountSuccessCallback();
    addExternalAccountDrawerRef?.current?.close();
    linkedAccountsDrawerRef?.current?.open();
    setIsLoading(false);
    linkedAccountsDrawerOnClose();
  };

  const handleOnConnectExternalBankClick = () => {
    if (isFromAddFunds) {
      sendSegmentEvent('baselane_banking_add_ach_click_add_account', { hasCounterparty: true });
    }
    addExternalAccountDrawerRef?.current.open();
  };

  const onPlaidConfirmationClose = () => {
    setIsLoading(true);
  };

  const onClose = () => {
    setIsLoading(false);
    setReconnecteBankIds(null);

    if (isDirectToPlaid) {
      helperFunction();
    }
    linkedAccountsDrawerRef?.current?.close();
    setTimeout(() => {
      handleDrawerCloseExternally();
    }, 10);
  };

  const handleLinkedAccountsDrawerClose = () => {
    setIsLoading(true);

    if (isFromLease) {
      refetchLeaseBankAccounts()?.then(() => {
        refetchBankSummary().then(() => {
          onClose();
        });
      });
    } else if (isFromAddFunds) {
      refetchTransferAccountsList().then(() => {
        refetchBankSummary().then(() => {
          onClose();
        });
      });
    } else {
      refetchBankSummary().then(() => {
        onClose();
      });
    }
    setTimeout(() => setIsOpenLinkedAccountDrawer(false), 100);
  };

  const handleResyncFn = (res) => {
    onPlaidConfirmationCancel();
    setReconnecteBankIds((prevState) => {
      if (prevState) {
        return [...prevState, res.data.reSyncExternalBankAccount.id];
      }
      return [res.data.reSyncExternalBankAccount.id];
    });

    setIsLoading(false);
  };

  const title = 'Connect Accounts';

  const drawerButton = isGetStarted ? (
    <ActionBox action={{ ...configs, onClick: handleOnConnectExternalBankClick }} />
  ) : (
    <BaselaneButton
      onClick={() => handleOnConnectExternalBankClick()}
      styles={buttonStyles}
      {...buttonProps}
    >
      {buttonProps?.text}
    </BaselaneButton>
  );

  const { DrawerBody } = BaselaneDrawer;
  return (
    <>
      {!hideButton && drawerButton}

      {isDirectToPlaid && (
        <AddBankAccount
          titleText={plaidText.error ? 'Connect Different Account' : 'Start'}
          handleSuccessFn={handleSuccessFn}
          setPlaidText={setPlaidText}
          size="md"
          hasRightChevronIcon
          onPlaidConfirmationClose={onPlaidConfirmationClose}
          setIsPlaidButtonLoading={setIsPlaidButtonLoading}
          type="buttonCard"
          {...isDirectToPlaidButtonProps}
        />
      )}

      {isDirectToPlaid && (isLoading || isPlaidButtonLoading) && <UIOverlayLoader />}

      <LinkedAccountsDrawer
        {...{
          linkedAccountsDrawerRef,
          connectedExternalBank,
          handleLinkedAccountsDrawerClose,
          isLoading,
          handleResyncFn,
          reconnectedBankIds,
          setIsLoading,
        }}
      />

      <BaselaneDrawer
        ref={addExternalAccountDrawerRef}
        finalFocusRef={drawerFinalFocusRef}
        title={title}
        closeEvent={handleDrawerClose}
        size="newdrawerfull"
        newDesignDrawer
        footer={
          <DrawerAction
            {...{
              banks,
              handleSuccessFn,
              plaidText,
              setPlaidText,
              onPlaidConfirmationClose,
              isLoading,
            }}
          />
        }
      >
        <DrawerBody p="24px 0px">
          {isLoading ? (
            <SlLoader />
          ) : (
            <SetUpThirdPartyView
              thirdParty="plaid"
              title={plaidText.title}
              bodyText={plaidText.message}
              iconColor={plaidText.iconColor}
              icon={plaidText.icon}
            />
          )}
        </DrawerBody>
      </BaselaneDrawer>
    </>
  );
}

AddAccountMobile.defaultProps = {
  buttonStyles: { ml: '32px !important' },
  buttonProps: {
    variant: 'filled',
    palette: 'primary',
    size: 'lg',
    text: 'Connect External Account',
  },
  helperFunction: () => {},
  addExternalAccountDrawerRef: null,
  hideButton: false,
  isFromLease: false,
  isDirectToPlaid: false,
  from: { page: '', section: '', configs: ({}: { ... }) },
  handleDrawerCloseExternally: () => {},
  handleAddAccountSuccessCallback: () => {},
  isPlaidButtonLoading: false,
  setIsPlaidButtonLoading: () => {},
  isDirectToPlaidButtonProps: ({}: { ... }),
};

export default AddAccountMobile;
