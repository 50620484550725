import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { steps } from './formHelpers';

export const sendSegmentEvents = (path) => {
  if (path.includes('transfers_payments')) {
    if (path.includes('activity')) {
      sendSegmentEvent('baselane_banking_send_funds_started', {
        entry_point: 'activity_tab',
      });
    }
  }
  if (path.includes('banking')) {
    sendSegmentEvent('baselane_banking_send_funds_started', {
      entry_point: 'baselane_banking',
    });
  }
  if (path.includes('dashboard')) {
    sendSegmentEvent('baselane_banking_send_funds_started', {
      entry_point: 'baselane_dashboard',
    });
  }
};

// Segment Events
export const sendSegmentEventsForSteps = (
  values,
  currentStep,
  selectedPaymentMethod,
  isOneTimePayment
) => {
  if (currentStep === steps.SELECT_RECIPIENT) {
    if (values?.payeeId) {
      sendSegmentEvent('baselane_banking_step_1_complete', {
        saved_recipient: true,
      });
    }
  }
  if (currentStep === steps.SELECT_PAYMENT_METHOD) {
    if (selectedPaymentMethod?.status === 'saved') {
      sendSegmentEvent('baselane_banking_step_2_complete', {
        saved_payment_method: true,
        payment_method_type: selectedPaymentMethod?.type,
      });
    } else {
      sendSegmentEvent('baselane_banking_step_2_complete', {
        saved_payment_method: false,
        payment_method_type: selectedPaymentMethod?.type,
      });
    }
  }
  if (currentStep === steps.PAYMENT_DETAILS) {
    if (isOneTimePayment) {
      sendSegmentEvent('baselane_banking_step_3_complete', {
        payment_frequency: 'one_time',
      });
    } else {
      sendSegmentEvent('baselane_banking_step_3_complete', {
        payment_frequency: 'recurring',
      });
    }
  }
  if (currentStep === steps.BOOKKEPPING_DETAILS) {
    if (values?.description && selectedPaymentMethod?.type === 'Check') {
      sendSegmentEvent('baselane_banking_step_4_complete', {
        saved_payment_method: true,
        memo: values?.description,
      });
    } else if (values?.description && selectedPaymentMethod?.type === 'Wire') {
      sendSegmentEvent('baselane_banking_step_4_complete', {
        saved_payment_method: true,
        message_to_recipient: values?.description,
      });
    } else {
      sendSegmentEvent('baselane_banking_step_4_complete', {
        saved_payment_method: true,
      });
    }
  }
};
