import React, { useEffect, useRef, useState } from 'react';
import { HStack } from '@chakra-ui/react';
import { DatePickerModal, DatePickerBase } from '../components';
import { DisplayInputDefault } from '../DisplayInputVariations';
import {
  checkIsCurrentMonthYear,
  showYearMonthIntoView,
  handleDefaultArrowKeyDown,
} from '../helpers/shared.helpers';
import {
  BaselaneSingleDatePickerProps,
  BaselaneSharedDatePickerProps,
} from '../types/datePicker.types';

// setting min/maxDate to null disables arrow key navigation
// seems to be a bug from the react-datepicker library
// https://stackoverflow.com/questions/63558051/cant-make-keyboard-arrows-work-on-react-datepicker
const BaselaneSingleDatePicker = ({
  placement = 'top-start',
  portalId = 'root',
  years = {
    start: null,
    end: null,
  },
  placeholder = 'Select date',
  handleChange = () => {},
  handleCalendarClose = () => {},
  handleKeyDown = () => {},
  handleFocus = () => {},
  minDate = undefined,
  maxDate = undefined,
  isDisabled = false,
  datePickerRef = null,
  className = '',
  calendarClassName = '',
  hideInputIcon = false,
  size = 'lg',
  value = '',
  showInModal = false,
  dateFormat = 'MMM dd, yyyy',
  modalTitle = 'Select a date',
  hideTodayButton = false,
  CustomDisplayInput = null,
  customDisplayInputProps = {},
  date,
  isOpen,
  onOpen,
  onClose,
  hideClearButton,
  isInsideForm,
  ...rest
}: BaselaneSingleDatePickerProps & BaselaneSharedDatePickerProps) => {
  const singleDatePickerRef = useRef();
  const pickerRef = datePickerRef ?? singleDatePickerRef;
  const [showMonthYearSelector, setShowMonthYearSelector] = useState(false);
  const [showTodayButton, setShowTodayButton] = useState(!hideTodayButton);
  const [selectedFullDate, setSelectedFullDate] = useState(date);
  const [isCalendarOpen, setIsCalendarOpen] = useState();

  const handleBackToTodayClick = () => {
    pickerRef.current?.calendar?.instanceRef.changeMonthYear(new Date());
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const focusedDate = pickerRef.current?.calendar?.props?.preSelection;

    // NOTE: React-datepicker does not trigger monthChange on keydown navigation
    // Below code is needed to show/hide today button + calculating popper position
    if (key === 'ArrowLeft' || key === 'ArrowRight' || key === 'ArrowUp' || key === 'ArrowDown') {
      const newFocusedDate = focusedDate ? new Date(focusedDate) : selectedFullDate;

      if (focusedDate) {
        handleDefaultArrowKeyDown({ key, dateOnFocus: newFocusedDate });
      }

      if (!hideTodayButton) {
        checkIsCurrentMonthYear({
          dateToBeChecked: newFocusedDate,
          setShowTodayButton,
        });
      }
    }

    if (handleKeyDown) {
      handleKeyDown(e);
    }
  };

  const onChange = (val) => {
    let selectedDate = val;

    if (!maxDate && selectedDate && years.end) {
      if (selectedDate.getFullYear() > years.end) {
        selectedDate = new Date();
        selectedDate.setFullYear(years.end);
      }
    }

    showYearMonthIntoView({ showMonthYearSelector });

    if (handleChange) {
      handleChange(selectedDate);
    }

    setSelectedFullDate(selectedDate);
  };

  const onFocus = (e) => {
    if (handleFocus) {
      handleFocus(e);
    }
  };

  const onBlur = () => {
    pickerRef.current?.setOpen(true);
  };

  const onCalendarOpen = (dateToBeChecked) => {
    setIsCalendarOpen(true);
    if (!hideTodayButton) {
      checkIsCurrentMonthYear({
        dateToBeChecked,
        setShowTodayButton,
      });
    }
  };

  const onCalendarClose = () => {
    setIsCalendarOpen(false);
    setShowMonthYearSelector(false);
    if (selectedFullDate === null) setSelectedFullDate(date);
    if (handleCalendarClose && selectedFullDate !== null) {
      handleCalendarClose({ date: selectedFullDate, isFromApplyButton: false });
    }
  };

  const onCalendarCloseAndApplyInModal = () => {
    setShowMonthYearSelector(false);

    if (handleCalendarClose) {
      handleCalendarClose({ date: selectedFullDate, isFromApplyButton: true });
    }
  };

  const onModalClose = () => {
    setSelectedFullDate(date);
    onClose();
  };

  const onModalApplyAndClose = () => {
    onClose();
    onCalendarCloseAndApplyInModal();
  };

  const handleClearAndApply = () => {
    handleCalendarClose({ date: null, isFromApplyButton: true });
  };

  useEffect(() => {
    setSelectedFullDate(date);
  }, [date]);

  useEffect(() => {
    if (showMonthYearSelector) {
      showYearMonthIntoView({ showMonthYearSelector });
    }
  }, [showMonthYearSelector]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        pickerRef.current?.setOpen(true);
      }, 50);
    }
  }, [isOpen, pickerRef]);

  const popperProps = {
    popperPlacement: placement,
  };
  const customDisplayFormInputProps = {
    isCalendarOpen,
    isInsideForm,
  };
  const sharedProps = {
    portalId: null,
    selected: selectedFullDate,
    pickerRef,
    placeholderText: placeholder,
    years,
    size,
    minDate,
    maxDate,
    disabled: isDisabled,
    fixedHeight: true,
    autoComplete: 'off',
    handleFocus,
    onKeyDown,
    onChange,
    onFocus,
    onCalendarOpen: () => onCalendarOpen(selectedFullDate),
    onCalendarClose,
    dateFormat,
    hideInputIcon,
    showInModal,
    showMonthYearSelector,
    setShowMonthYearSelector,
    showTodayButton,
    setShowTodayButton,
    hideTodayButton,
    handleBackToTodayClick,
    className,
    calendarClassName: `baselane-datepicker-calendar ${
      showInModal ? 'range-datepicker-modal-calendar' : ''
    } ${calendarClassName}`,
    ...rest,
  };

  const displayInputProps = {
    onOpen,
    isDisabled,
  };

  return showInModal ? (
    <>
      {CustomDisplayInput ? (
        <CustomDisplayInput
          {...{
            ...displayInputProps,
            ...customDisplayInputProps,
          }}
        />
      ) : (
        <DisplayInputDefault
          {...{
            ...displayInputProps,
            value,
            size,
            placeholder,
            className,
            handleClearAndApply,
            hideInputIcon,
            hideClearButton,
            readOnly: true,
          }}
        />
      )}
      <DatePickerModal
        {...{
          isDisabled,
          isOpen,
          date: selectedFullDate,
          modalTitle,
          handleClose: onModalClose,
          handleApplyAndClose: onModalApplyAndClose,
        }}
      >
        <HStack px={2} py={0} m={0}>
          <DatePickerBase
            {...{
              ...sharedProps,
              onBlur,
              size: 'md',
              onClickOutside: () => pickerRef.current?.setOpen(true),
              shouldCloseOnSelect: false,
              inputLabel: 'Select date',
              className: '',
            }}
          />
        </HStack>
      </DatePickerModal>
    </>
  ) : (
    <DatePickerBase
      {...{
        ...popperProps,
        ...sharedProps,
        ...customDisplayFormInputProps,
      }}
    />
  );
};

BaselaneSingleDatePicker.defaultProps = {
  placement: 'top-start',
  portalId: 'root',
  years: {
    start: null,
    end: null,
  },
  placeholder: 'Select date',
  handleChange: () => {},
  handleCalendarClose: () => {},
  handleKeyDown: () => {},
  handleFocus: () => {},
  // setting min/maxDate to null disables arrow key navigation
  // seems to be a bug from the react-datepicker library
  // https://stackoverflow.com/questions/63558051/cant-make-keyboard-arrows-work-on-react-datepicker
  minDate: undefined,
  maxDate: undefined,
  isDisabled: false,
  datePickerRef: null,
  className: '',
  calendarClassName: '',
  hideInputIcon: false,
  size: 'lg',
  value: '',
  showInModal: false,
  dateFormat: 'MMM dd, yyyy',
  modalTitle: 'Select a date',
  hideTodayButton: false,
  CustomDisplayInput: null,
  customDisplayInputProps: {},
};

export default BaselaneSingleDatePicker;
