import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';
import { DASHBOARD } from '@routes';
import { FEATURE_GATES } from '@core/constants/statsigKeys';

const HomepageGateGuard = () => {
  const { checkGate } = useStatsigClient();

  return checkGate(FEATURE_GATES.DASHBOARD_GATE) ? <Navigate to={DASHBOARD} replace /> : <Outlet />;
};

export default HomepageGateGuard;
