import React from 'react';
import { isMobile } from 'react-device-detect';
import { Flex, Text } from '@chakra-ui/react';
import IconInfo from '@icons/manual/IconInfo';
import { BaselaneTooltip, BaselaneHighlightedDataDisplay } from '@shared/components';

import LeaseSection from './LeaseSection';
import {
  getDepositAndFeesDataFormatted,
  statusFormat,
} from '../../formHelpers/depositsFeesReview.helper';

import BoxEmptyState from '../../components/BoxEmptyState';

type DepositsFeesProps = {
  lease: Object,
  setTabIndex: Function,
  leaseCreated: boolean,
  id: string,
};

function DepositsFees({ lease, setTabIndex, leaseCreated, id }: DepositsFeesProps) {
  const {
    dEnabled,
    dAmount,
    dDueDate,
    dStatus,
    lEnabled,
    lDueDate,
    lStatus,
    pEnabled,
    pAmount,
    pDueDate,
    fees,
  } = getDepositAndFeesDataFormatted(lease);

  const oneTimeFee = fees.filter((fee) => fee.frequency.toLowerCase() === 'one time');

  const hasNoDepositsFees = !dEnabled && !lEnabled && !pEnabled && oneTimeFee.length === 0;

  const title = 'Deposits & one-time fees';

  const dTooltipText = (
    <Text textAlign="center">
      By marking as paid, no invoice to
      <br />
      collect this deposit will be generated.
      <br />
      However, a record of this payment will be
      <br /> available for you and your tenant.
    </Text>
  );

  const lTooltipText = (
    <Text textAlign="center">
      By marking as paid, no invoice to
      <br />
      collect this payment will be generated.
      <br />
      However, a record of this payment will be
      <br /> available for you and your tenant.
    </Text>
  );

  // Security Deposits
  const securityDepositConfig = {
    title: 'Collect security deposit',
    items: [
      {
        key: 'Security deposit amount',
        value: dAmount,
      },
      {
        key: 'Security deposit due date',
        value: dDueDate,
      },
      {
        key: (
          <Flex alignItems="center">
            <Text mr="8px">Mark as paid</Text>

            {!isMobile && (
              <BaselaneTooltip label={dTooltipText} styles={{ w: '303px' }} placement="top">
                <IconInfo width="16" height="16" color="#3A4B5B" _hover={{ color: '#192C3E' }} />
              </BaselaneTooltip>
            )}
          </Flex>
        ),
        value: isMobile ? statusFormat(dStatus) : dStatus,
      },
    ],
  };
  // Security Deposits
  const mobileSecurityDepositConfig = {
    title: 'Collect security deposit',
    items: [
      {
        key: 'Security deposit amount',
        value: dAmount,
      },
      {
        key: (
          <Flex alignItems="center">
            <Text mr="8px">Security deposit status</Text>
          </Flex>
        ),
        value: isMobile ? statusFormat(dStatus) : dStatus,
      },
    ],
  };
  const securityDepositSection = (
    <BaselaneHighlightedDataDisplay
      configuration={isMobile ? mobileSecurityDepositConfig : securityDepositConfig}
      hasBorder={isMobile && (lEnabled || !!oneTimeFee.length)}
    />
  );

  // Last Month's Rent
  const lastMonthRentConfig = {
    title: 'Collect last month’s rent',
    items: [
      {
        key: 'Last month rent due date',
        value: lDueDate,
      },
      {
        key: (
          <Flex alignItems="center">
            <Text mr="8px">Mark as paid</Text>
            <BaselaneTooltip label={lTooltipText} styles={{ w: '303px' }} placement="top">
              <IconInfo width="16" height="16" color="#3A4B5B" _hover={{ color: '#192C3E' }} />
            </BaselaneTooltip>
          </Flex>
        ),
        value: lStatus,
      },
    ],
  };
  const mobileLastMonthRentConfig = {
    title: 'Collect last month’s rent',
    items: [
      {
        key: 'Last month rent due date',
        value: lDueDate,
      },
      {
        key: (
          <Flex alignItems="center">
            <Text mr="8px">Last month rent status</Text>
          </Flex>
        ),
        value: isMobile ? statusFormat(lStatus) : lStatus,
      },
    ],
  };
  const lastMonthRentSection = (
    <BaselaneHighlightedDataDisplay
      configuration={isMobile ? mobileLastMonthRentConfig : lastMonthRentConfig}
      hasBorder={isMobile && !!oneTimeFee.length}
    />
  );

  // Prorated Rent
  const proratedRentConfig = {
    title: 'Collected prorated rent',
    items: [
      {
        key: 'Prorated rent amount',
        value: pAmount,
      },
      {
        key: 'Prorated rent due date',
        value: pDueDate,
      },
    ],
  };
  const proratedRentSection = (
    <BaselaneHighlightedDataDisplay
      configuration={proratedRentConfig}
      hasBorder={isMobile && (dEnabled || lEnabled || !!oneTimeFee.length)}
    />
  );

  // One-time Fees
  const oneTimeFeeConfig = {
    title: 'One-time fees',
    items: oneTimeFee.map((fee) => {
      const { amount, description, dueDate } = fee;
      return {
        items: [
          {
            key: description,
            value: amount,
          },
          {
            key: 'Fee due date',
            value: dueDate,
          },
        ],
      };
    }),
    styles: { nested: { _notFirst: { mt: '32px !important' } } },
  };
  const oneTimeFeeSection = <BaselaneHighlightedDataDisplay configuration={oneTimeFeeConfig} />;

  // Empty Deposits Fees
  const emptyDepositsFees = (
    <BoxEmptyState text="No deposits or one-time payments included in this rent collection." />
  );

  return (
    <LeaseSection
      {...{
        id,
        title,
        setTabIndex,
        leaseCreated,
        hasNoDepositsFees,
        emptyDepositsFees,
      }}
    >
      {isMobile && pEnabled && proratedRentSection}
      {dEnabled && securityDepositSection}
      {lEnabled && lastMonthRentSection}
      {!isMobile && pEnabled && proratedRentSection}
      {!!oneTimeFee.length && oneTimeFeeSection}
    </LeaseSection>
  );
}

export default DepositsFees;
