import React from 'react';
import BaselaneTooltip from './BaselaneTooltip';

const NoAccessTooltip = ({ children, ...rest }: { children: React.Node }) => {
  return (
    <BaselaneTooltip
      label="Only the owner of this workspace can open a Baselane Banking account for a new entity."
      placement="top"
      styles={{ width: '243px', align: 'left', borderRadius: 'sm' }}
      {...rest}
    >
      {children}
    </BaselaneTooltip>
  );
};

export default NoAccessTooltip;
