import { publicIpv4 } from 'public-ip';
import { useEffect, useState } from 'react';

export const UNKNOWN_IP_ADDRESS = null;

/**
 * Fetches user's external IP address
 * Uses our custom hosted api first, if that fails falls back to public-ip
 * and if that also fails it will return UNKNOWN_IP_ADDRESS
 */
const useIpAddress = () => {
  const [ipAddress, setIpAddress] = useState(null);
  useEffect(() => {
    (async () => {
      let ip = null;
      let fallbackIp = null;
      try {
        const response = await fetch('https://dev-orchestration.baselane.com/api/clientIp');
        if (response.ok) {
          const { ip: baselaneIp } = await response.json();
          ip = baselaneIp;
        }
      } catch (error) {
        fallbackIp = await publicIpv4();
      }
      setIpAddress(ip || fallbackIp || UNKNOWN_IP_ADDRESS);
    })();
  }, []);

  return { ipAddress };
};

export default useIpAddress;
