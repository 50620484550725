import React from 'react';
import { HStack, useDisclosure } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { BaselaneButton } from '@shared/components';
import { Icon16Edit, Icon16Split, Icon16Hide, Icon16Unhide, Icon16Delete } from '../../Icons/16px';
import { UPDATE_TRANSACTION } from '../queries/transactions';
import DeleteTransactionAlertDialog from './DeleteTransactionAlertDialog';

const TransactionActions = ({
  transaction,
  onEdit,
  onSplit,
  from,
  isLoading: isDataLoading = false,
}) => {
  const { id, pending: isPending, hidden: isHidden, isExternal } = transaction ?? {};

  const isSplit = !!transaction?.parentId;
  const {
    isOpen: deleteDialogIsOpen,
    onOpen: openDeleteDialog,
    onClose: closeDeleteDialog,
  } = useDisclosure();

  const [updateTransaction, { loading: updateInProgress }] = useMutation(UPDATE_TRANSACTION);

  const { isMin577 } = useBreakPoints();

  const isLoading = updateInProgress || isDataLoading;

  return (
    <HStack mt={2} gap={1}>
      {isSplit ? null : (
        <BaselaneButton
          isDisabled={isPending || !isExternal || isLoading}
          variant="outline"
          palette="neutral"
          size="md"
          flex="1 1 25%"
          leftIcon={<Icon16Edit />}
          onClick={onEdit}
        >
          Edit
        </BaselaneButton>
      )}
      {isMin577 && (
        <BaselaneButton
          isDisabled={isPending || isLoading}
          variant="outline"
          palette="neutral"
          size="md"
          flex="1 1 25%"
          leftIcon={<Icon16Split />}
          onClick={onSplit}
        >
          {isSplit ? 'View Split' : 'Split'}
        </BaselaneButton>
      )}
      <BaselaneButton
        isLoading={updateInProgress}
        isDisabled={isPending || isLoading}
        variant="outline"
        palette="neutral"
        size="md"
        flex="1 1 25%"
        leftIcon={isHidden ? <Icon16Unhide /> : <Icon16Hide />}
        onClick={() =>
          updateTransaction({
            variables: {
              input: {
                id,
                hidden: !isHidden,
              },
            },
          })
        }
      >
        {isHidden ? 'Unhide' : 'Hide'}
      </BaselaneButton>
      {isSplit ? null : (
        <BaselaneButton
          isDisabled={isPending || isLoading || !isExternal}
          variant="outline"
          palette="danger"
          size="md"
          flex="1 1 25%"
          leftIcon={<Icon16Delete />}
          onClick={openDeleteDialog}
        >
          Delete
        </BaselaneButton>
      )}
      <DeleteTransactionAlertDialog
        isOpen={deleteDialogIsOpen}
        onClose={closeDeleteDialog}
        transactionId={id}
        from={from}
      />
    </HStack>
  );
};

TransactionActions.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string,
    hidden: PropTypes.bool,
    pending: PropTypes.bool,
    parentId: PropTypes.number,
    isManual: PropTypes.bool,
    isExternal: PropTypes.bool,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onSplit: PropTypes.func.isRequired,
  from: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TransactionActions;
