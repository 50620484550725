// @flow
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Text, Stack, Spacer, useMediaQuery } from '@chakra-ui/react';
import { BaselaneButton, BaselaneCard, BaselaneMenu } from '@shared/components';
import MoveMoneyMenu from '@core/components/Shared/components/MoveMoneyMenu';
import { GET_ACTIVE_LEASES } from '@core/components/Properties/queries';
import { getQueryParams } from '@core/Services/Firebase/firebase';
import { Icon16AddBankingAccount, Icon16CashBack } from '@icons/16px';
import { ADD_BASELANE_BANK_ACCOUNT, REDEEM_CASHBACK } from '@routes';
import UserAccessContext from '@contexts/UserAccessContext';
import useCurrentApy from '@core/components/Shared/hooks/useCurrentApy';
import { GET_CARD_SUMMARY } from '../queries/cards';
import MakeTransferOrPaymentDrawer from '../MainContent/components/MakeTransferOrPaymentDrawer';
import AddFundsDrawer from '../MainContent/components/AddFundsDrawer';
import BanksContext from '../../../contexts/BanksContext';
import useAddFundsOpen from '../MainContent/hooks/useAddFundsOpen';
import Overview from './Overview';
import {
  headerLeftStyles,
  headerRightStyles,
  summaryContainerStyles,
  summaryInnerContainerStyles,
} from './styles/newsummary.styles';

type SummaryProps = {
  isTransfersPaymentsPageFeatureFlagged: boolean,
};

function Summary({ isTransfersPaymentsPageFeatureFlagged }: SummaryProps): any {
  const [isLargerThan899] = useMediaQuery('(min-width: 899px)', { ssr: false });
  const [isLargerThan576] = useMediaQuery('(min-width: 576px)', { ssr: false });
  const { isWorkspaceOwner, authorizedForBanking } = useContext(UserAccessContext);
  const navigate = useNavigate();
  const {
    banks,
    refetchBankSummary,
    hasActiveBaselaneAccounts,
    hasVisibleApplications,
    rest,
  } = useContext(BanksContext);

  const { data } = useQuery(GET_CARD_SUMMARY, {
    variables: {
      page: 1,
      pageSize: 99,
      filter: {
        includeHiddenCards: false,
      },
    },
  });

  const { cardSummary } = data || {};
  const { cardFinancials } = cardSummary || {};
  const { ytdCashBack } = cardFinancials || 0;
  const {
    lifetimeCashback = 0,
    monthlyInflow = 0,
    monthlyOutflow = 0,
    lifetimeInterest = 0,
    receivedSuccessfulRentPayment = false,
    baselaneBankBalance,
    lastMonthActivity,
  } = rest || {};
  const { addFundsRef } = useAddFundsOpen();
  const sendFundsRef = useRef();

  const { currentApy } = useCurrentApy();

  // load summary to tally active leases
  const { data: leaseSummaryData } = useQuery(GET_ACTIVE_LEASES);

  // Determines the showing/hiding of rent collection banners and widget header CTA
  // setHasCollectedRentWithinNeededPeriod - should be set to false when RC has started but NO payments have been made
  const [isRentCollectionStarted, setIsRentCollectionStarted] = useState(false);
  const [hasCollectedRentWithinNeededPeriod, setHasCollectedRentWithinNeededPeriod] = useState(
    false
  );

  const currLocation = useLocation();
  const search = currLocation?.search;
  const openAddFundsDrawer = search?.includes('add_funds');
  const addFundsParam = getQueryParams(currLocation, 'add_funds');

  const openSendFundsDrawer = search?.includes('send_funds');
  const sendFundsParam = getQueryParams(currLocation, 'send_funds');

  if (openAddFundsDrawer && hasActiveBaselaneAccounts) {
    // using late timeouts to make sure the refs are set
    // https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#late_timeouts
    setTimeout(() => {
      // open main drawer first, so the 4 internal drawers are available
      addFundsRef.current?.onOpenAddFundsDrawer();
      switch (addFundsParam) {
        case 'bank_transfer':
          addFundsRef.current?.onOpenBankTransferDrawer();
          break;
        case 'check_deposit':
          addFundsRef.current?.onOpenCheckDepositDrawer();
          break;
        case 'external_transfer':
          addFundsRef.current?.onOpenEducationalDrawer(0);
          break;
        case 'third_party':
          addFundsRef.current?.onOpenEducationalDrawer(1);
          break;
        default:
          // do nothing
          break;
      }
    }, 0);
  }

  if (openSendFundsDrawer && hasActiveBaselaneAccounts) {
    // using late timeouts to make sure the refs are set
    // https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#late_timeouts
    setTimeout(() => {
      // open main drawer first, so the 4 internal drawers are available
      sendFundsRef?.current?.onOpenMakeTransferOrPaymentDrawer();
      if (sendFundsParam === 'check_payment') {
        sendFundsRef?.current?.onOpenMailCheckDrawer();
      }
    }, 0);
  }

  useEffect(() => {
    if (
      leaseSummaryData?.leaseDetail?.summary?.activeLease &&
      Number(leaseSummaryData?.leaseDetail?.summary?.activeLease) > 0
    ) {
      setIsRentCollectionStarted(true);
    }
  }, [leaseSummaryData]);

  useEffect(() => {
    if (receivedSuccessfulRentPayment !== undefined && receivedSuccessfulRentPayment !== null) {
      setHasCollectedRentWithinNeededPeriod(receivedSuccessfulRentPayment);
    }
  }, [receivedSuccessfulRentPayment]);

  const headerRightContent = (
    <Stack direction="row" gap={2} alignItems="center">
      {hasActiveBaselaneAccounts &&
        (isTransfersPaymentsPageFeatureFlagged && authorizedForBanking ? (
          <MoveMoneyMenu entryPoint="baselane_banking" />
        ) : (
          authorizedForBanking && (
            <>
              <AddFundsDrawer
                {...{
                  banks,
                  refetchBankSummary,
                  ref: addFundsRef,
                  buttonProps: { variant: 'filled', palette: 'primary', size: 'md' },
                }}
              />
              <MakeTransferOrPaymentDrawer
                {...{
                  banks,
                  refetchBankSummary,
                  ref: sendFundsRef,
                  buttonProps: { variant: 'filled', palette: 'primary', size: 'md' },
                }}
              />
            </>
          )
        ))}
      {isWorkspaceOwner && (
        <BaselaneButton
          id="add-bank-account"
          variant="outline"
          palette="neutral"
          size="md"
          onClick={() =>
            navigate({
              pathname: `${currLocation.pathname}/${ADD_BASELANE_BANK_ACCOUNT}`,
            })
          }
        >
          Add account
        </BaselaneButton>
      )}
    </Stack>
  );

  const overviewElements = (
    <BaselaneCard
      styles={{
        position: 'relative',
        height: { base: 'auto', sm: 'initial' },
        w: '100%',
        bg: 'brand.neutral.white',
        borderRadius: 'md',
        border: '1px solid #DADFE6',
        boxShadow: 'none',
      }}
    >
      <Overview
        {...{
          baselaneBankBalance,
          ytdCashBack,
          lifetimeCashback,
          monthlyInflow,
          monthlyOutflow,
          lifetimeInterest,
          hasActiveBaselaneAccounts,
          isLargerThan899,
          isLargerThan576,
          isRentCollectionStarted,
          hasCollectedRentWithinNeededPeriod,
          addFundsRef,
          banks,
          lastMonthActivity,
          currentApy,
        }}
      />
    </BaselaneCard>
  );

  const moreMenuListItems = [
    {
      id: 'cash_back',
      name: 'Redeem cash back',
      icon: <Icon16CashBack />,
      onClick: () => navigate({ pathname: `${currLocation.pathname}/${REDEEM_CASHBACK}` }),
      hasDivider: authorizedForBanking,
    },
    {
      id: 'banking_account',
      name: 'Add account',
      icon: <Icon16AddBankingAccount />,
      onClick: () =>
        navigate({
          pathname: `${currLocation.pathname}/${ADD_BASELANE_BANK_ACCOUNT}`,
        }),
    },
  ];

  const authorizedMenuListItems = authorizedForBanking
    ? moreMenuListItems
    : moreMenuListItems.slice(0, -1);

  return (
    <Stack {...summaryContainerStyles}>
      <Stack
        direction="row"
        justifyContent="space-between"
        {...summaryInnerContainerStyles(isLargerThan899)}
      >
        <Stack direction="row" {...headerLeftStyles(isLargerThan899, isLargerThan576)}>
          <Text>Summary</Text>
        </Stack>

        {isLargerThan576 && (
          <>
            <Spacer />
            <Stack direction="row" {...headerRightStyles}>
              {headerRightContent}
            </Stack>
          </>
        )}
      </Stack>

      {isLargerThan576 ? (
        <Stack direction="row" mt="0!important">
          {overviewElements}
        </Stack>
      ) : (
        <Box m="0!important">
          {overviewElements}
          {(hasActiveBaselaneAccounts || hasVisibleApplications) && (
            <Stack direction="row" mt={2.5} gap={2}>
              {authorizedForBanking && (
                <MoveMoneyMenu
                  buttonSize="lg"
                  styles={{ button: { flex: 1, width: '100%' } }}
                  entryPoint="baselane_banking"
                />
              )}
              <BaselaneMenu
                label="More"
                buttonPalette="neutral"
                buttonVariant="outline"
                buttonSize="lg"
                listItems={authorizedMenuListItems}
              />
            </Stack>
          )}
        </Box>
      )}
    </Stack>
  );
}

export default Summary;
