import { getValidationMessage } from '@shared/components/BaselaneFileUploader/helpers/baselaneFileUploader.helpers';

const displayBEValidationMessage = ({ errors, setErrorMsg }) => {
  Array.from(errors).forEach((e) => {
    const code = e.extensions.exception.response.messageCode;

    if (
      code === 'MAX_FILE_SIZE_ERROR' ||
      code === 'DUPLICATE_FILE_NAME' ||
      code === 'MAX_DOCUMENT_UPLOAD_LIMIT_REACHED'
    ) {
      const message = getValidationMessage({ errorType: code });
      setErrorMsg(message);
    } else {
      const message = getValidationMessage({ errorType: 'GENERIC' });
      setErrorMsg(message);
    }
  });
};

const handleUploadDocumentError = ({
  docId,
  response,
  setErrorMsg,
  cleanUpAfterFileUpload,
  onUploadFail,
}) => {
  const { ok, status, statusText, type, url: responseUrl, bodyUsed } = response ?? {};
  const message = getValidationMessage({ errorType: 'GENERIC' });
  setErrorMsg(message);
  onUploadFail({
    docId,
    failureReason: {
      status,
      ok,
      statusText,
      type,
      url: responseUrl,
      bodyUsed,
    },
  });
  cleanUpAfterFileUpload();
};

// API Calls
const getUploadUrl = async ({
  file,
  fileSize,
  entityData,
  cleanUpAfterFileUpload,
  setErrorMsg,
  getDocumentUploadUrl,
  documentTitle,
}) => {
  let result = null;

  const { name: filename, type } = file;
  const fileExtension = type?.split('/')[1]?.toUpperCase();
  const { entityType, entityId, entityDate, documentUploadPurpose } = entityData ?? {};

  try {
    const response = await getDocumentUploadUrl({
      variables: {
        input: {
          filename: documentTitle ? `${documentTitle}.${fileExtension}` : filename,
          fileExtension,
          fileSize,
          entityType,
          entityId,
          documentUploadPurpose,
          entityDate,
        },
      },
    });

    if (response.errors?.length) {
      displayBEValidationMessage({ errors: response.errors, setErrorMsg });
      cleanUpAfterFileUpload();
    } else {
      result = response.data;
    }
  } catch (error) {
    const message = getValidationMessage({ errorType: 'GENERIC' });
    setErrorMsg(message);
    cleanUpAfterFileUpload();
  }

  return result;
};

const uploadDocument = async ({
  docId,
  url,
  file,
  name,
  entityData,
  setErrorMsg,
  cleanUpAfterFileUpload,
  onUploadFail,
}) => {
  let result = null;

  const { entityId } = entityData ?? {};

  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/octet-stream',
        'Content-disposition': `attachment; filename=${entityId}_${name}`,
      },
      body: file,
    });

    if (response.status === 200) {
      result = response;
    } else {
      handleUploadDocumentError({
        docId,
        response,
        setErrorMsg,
        cleanUpAfterFileUpload,
        onUploadFail,
      });
    }
  } catch (error) {
    handleUploadDocumentError({
      docId,
      response: error,
      setErrorMsg,
      cleanUpAfterFileUpload,
      onUploadFail,
    });
  }

  return result;
};

/**
 * Handle Files and Call APIs
 * @param file - will be passed through BaselaneFileUploader
 * @param fileSize - will be passed through BaselaneFileUploader
 * @param setFileName - will be passed through BaselaneFileUploader
 * @param setIsUploading - will be passed through BaselaneFileUploader
 * @param cleanUpAfterFileUpload - will be passed through BaselaneFileUploader
 * @param setErrorMsg - will be passed through BaselaneFileUploader
 *
 * @param entityData - will be passed through as a prop to this function
 * @param onUploadSuccess - will be passed through as a prop to this function
 * @param onUploadFail - will be passed through as a prop to this function
 * @param getDocumentUploadUrl - will be passed through as a prop to this function
 * @returns {Promise<void>}
 */
const handleUploadAndSaveImageAttachments = async ({
  file,
  fileSize,
  setFileName,
  setIsUploading,
  cleanUpAfterFileUpload,
  setErrorMsg,
  entityData,
  onUploadSuccess,
  onUploadFail,
  getDocumentUploadUrl,
  documentTitle,
}) => {
  setFileName(documentTitle || file.name);
  setIsUploading(true);

  const { generateDocumentUploadUrl } =
    (await getUploadUrl({
      file,
      fileSize,
      entityData,
      cleanUpAfterFileUpload,
      setErrorMsg,
      getDocumentUploadUrl,
      documentTitle,
    })) ?? {};
  const { id: docId, url, ...rest } = generateDocumentUploadUrl ?? {};

  if (url) {
    const uploadedDocument = await uploadDocument({
      docId,
      url,
      file,
      name: rest?.name,
      entityData,
      setErrorMsg,
      onUploadFail,
      cleanUpAfterFileUpload,
    });
    if (uploadedDocument) {
      onUploadSuccess({ newDocument: { id: docId, ...rest } });
      cleanUpAfterFileUpload();
    }
  }
};

export default handleUploadAndSaveImageAttachments;
