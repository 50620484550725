import process from 'process';
import React, { useEffect, useState } from 'react';
import { loadVGSCollect } from '@vgs/collect-js';
import { Box, Stack, Spacer, Text, FormControl, FormLabel, useDisclosure } from '@chakra-ui/react';
import { BaselaneAlert, BaselaneButton } from '@shared/components';
import customTheme from '@theme';
import { getTokenData } from '@shared/helpers/otp.helpers';
import SetPINPopup from './SetPINPopup';
import {
  popupMessageStyles,
  titleStyles,
  popupFormLabelStyles,
  popupFormInputStyles,
  popupFooterContainerStyles,
} from '../styles/popup.styles';

type ActivatePopupProps = {
  card: Object,
  bankInfo: Object,
  isActivatePopupOpen: boolean,
  onActivatePopupClose: Function,
  onActivationSuccessPopupOpen: Function,
};

const css = {
  ...popupFormInputStyles,
};

const ActivatePopup = ({
  card,
  bankInfo,
  isActivatePopupOpen,
  onActivatePopupClose,
  onActivationSuccessPopupOpen,
}: ActivatePopupProps) => {
  const { bankId } = bankInfo ?? {};
  const [form, setForm] = useState({});
  const [isValid, setIsValid] = useState(false);

  // set PIN popup states
  const {
    isOpen: isSetPINPopupOpen,
    onOpen: onSetPINPopupOpen,
    onClose: onSetPINPopupClose,
  } = useDisclosure();

  const { sensitive } = getTokenData(bankId);
  const sensitiveToken = sensitive?.token || '';

  const cleanup = () => {
    setForm({});
  };

  const handleActivatePopupClose = (e) => {
    onActivatePopupClose(e);
    cleanup();
  };

  const initForm = (vgsCollect) => {
    const formCreate = vgsCollect.init((fields) => {
      const ccv2 = fields['data.attributes.cvv2'];
      const expiry = fields['data.attributes.expirationDate'];

      const isFormValid = ccv2?.isValid && expiry?.isValid;
      setIsValid(isFormValid);
    });

    formCreate.field('#card-cvc', {
      type: 'card-security-code',
      name: 'data.attributes.cvv2',
      successColor: customTheme.colors.brand.neutral['700'],
      errorColor: customTheme.colors.red['800AA'],
      placeholder: '123',
      maxLength: 3,
      validations: ['required', 'validCardSecurityCode'],
      css,
    });

    formCreate.field('#card-expiry', {
      type: 'card-expiration-date',
      name: 'data.attributes.expirationDate',
      autoComplete: 'cc-exp',
      successColor: customTheme.colors.brand.neutral['700'],
      errorColor: customTheme.colors.red['800AA'],
      yearLength: '2',
      placeholder: 'MM / YY',
      validations: ['required', 'validCardExpirationDate'],
      serializers: [formCreate.SERIALIZERS.replace('(\\d{2})\\s\\/\\s(\\d{2})', '20$2-$1')],
      css,
    });
    setForm(formCreate);
  };

  const environment =
    process.env.REACT_APP_NODE_ENV === 'production' ||
    process.env.REACT_APP_NODE_ENV === 'pre-prod' ||
    process.env.REACT_APP_NODE_ENV === 'hotfix'
      ? 'live'
      : 'sandbox';

  // TODO: Before native bank launch. New vault id for production
  const vaultId =
    process.env.REACT_APP_NODE_ENV === 'production' ||
    process.env.REACT_APP_NODE_ENV === 'pre-prod' ||
    process.env.REACT_APP_NODE_ENV === 'hotfix'
      ? 'tnt8w6nrmbu'
      : 'tntazhyknp1';

  useEffect(() => {
    const loadForm = async () => {
      const vgsCollect = await loadVGSCollect({
        vaultId,
        environment,
        version: '2.4.0',
      }).catch((e) => {
        console.log(e);
      });
      initForm(vgsCollect);
    };

    if (isActivatePopupOpen && Object.keys(form).length === 0) loadForm();
  }, [isActivatePopupOpen]);

  const activatePopupContent = (
    <Stack spacing={2} className="vgs">
      <Box>
        <Text {...titleStyles}>Let&apos;s Activate Your Debit Card</Text>
        <Text {...popupMessageStyles}>
          Please use the CCV2 and Expiration date found on your debit card.
        </Text>
        <FormControl mt="24px">
          <FormLabel {...popupFormLabelStyles}>CCV2 (3 Digit Security Code)</FormLabel>
          <div id="card-cvc" />
        </FormControl>
        <FormControl mt="18px">
          <FormLabel {...popupFormLabelStyles}>Expiration Date</FormLabel>
          <div id="card-expiry" />
        </FormControl>
      </Box>
    </Stack>
  );

  const activatePopupFooter = (
    <Stack direction="row" {...popupFooterContainerStyles}>
      <Spacer />
      <Stack direction="row" align="center" spacing={2}>
        <BaselaneButton
          variant="outline"
          palette="neutral"
          size="md"
          onClick={handleActivatePopupClose}
        >
          Cancel
        </BaselaneButton>
        <BaselaneButton
          variant="filled"
          palette="primary"
          size="md"
          onClick={onSetPINPopupOpen}
          isDisabled={!isValid}
          id="activate-card-code-date-submit"
        >
          Next
        </BaselaneButton>
      </Stack>
    </Stack>
  );

  return (
    <>
      <BaselaneAlert
        showCloseButton
        isOpen={isActivatePopupOpen}
        onClose={handleActivatePopupClose}
        isCentered
        body={activatePopupContent}
        footer={activatePopupFooter}
        size="xl"
      />
      <SetPINPopup
        {...{
          card,
          activateCardForm: form,
          setActivateCardForm: setForm,
          sensitiveToken,
          isSetPINPopupOpen,
          onSetPINPopupClose,
          onActivatePopupClose,
          onActivationSuccessPopupOpen,
        }}
      />
    </>
  );
};

export default ActivatePopup;
