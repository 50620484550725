/**
 * Initializes and provides the API for the Zendesk Messenger UI
 */

import React, { useEffect, useState } from 'react';
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';
import { useSearchParams } from 'react-router-dom';
import { BaselaneSpinner } from '@core/components/Shared/components';
import useUTMParams from '@shared/hooks/useUTMParams';
import { statsigClientKey } from '@core/constants/envVars';
import useIpAddress from '@shared/hooks/useIpAddress';
import { getStatsigEnviroment } from '../../../helpers/statsig.helpers';

const StatsigWrapper = ({ children }: any) => {
  const [searchParams] = useSearchParams();

  const { ipAddress } = useIpAddress();

  const {
    // eslint-disable-next-line camelcase
    utm_source,
    // eslint-disable-next-line camelcase
    utm_campaign,
    irclickid,
    partnernid,
  } = useUTMParams();

  const [customId, setCustomId] = useState(null);

  const statsigUser = {
    userAgent: navigator.userAgent,
    custom: {
      // eslint-disable-next-line camelcase
      utm_source,
      // eslint-disable-next-line camelcase
      utm_campaign,
      irclickid,
      partnernid,
    },
    customIDs: { 'Custom User ID': '0' },
  };

  const statsigOptions: StatsigOptions = {
    environment: { tier: getStatsigEnviroment() },
    networkConfig: {
      api: 'https://gvpymyol6u4bmvy22k2u56ljcm0hkejz.lambda-url.us-east-1.on.aws/v1',
    },
  };

  const { client, isLoading } = useClientAsyncInit(statsigClientKey, statsigUser, statsigOptions);

  // This business logic is currently being used for 2024-07_sign_up_page_experiment
  // which requires stableID to be used as a customID to continue the experiment seamlessly

  // If ref="brand" is in the URL *AND* we don't have a user object,
  // we will not render the app until the customID is established from the stableID
  // otherwise, we proceed with available data
  const waitForCustomId = searchParams.get('ref') === 'brand';
  const [statsigUserReady, setStatsigUserReady] = useState(!waitForCustomId);

  /**
   * Retrieve stableId and set customId if needed.
   */
  useEffect(() => {
    if (client && waitForCustomId && !customId) {
      const { stableID } = client.getContext();
      setCustomId(stableID);
    }
  }, [client]);

  /**
   * Update statsig client user with the ipAddress if detected
   */
  useEffect(() => {
    const { user } = client.getContext();
    if (ipAddress && ipAddress !== user.ip) {
      const updatedUser = {
        ...user,
        ip: ipAddress,
      };
      // updates statsig with new data synchronously
      client.updateUserSync(updatedUser);
    }
  }, [ipAddress]);

  /**
   * Update statsig client user with the customId if one is
   * available and we are expecting one
   */
  useEffect(() => {
    const { user } = client.getContext();
    if (
      (waitForCustomId && customId && !user?.customIDs) ||
      user?.customIDs['Custom User ID'] === '0'
    ) {
      const updatedUser = {
        ...user,
        customIDs: { 'Custom User ID': customId },
      };
      // updates statsig with new data synchronously
      client.updateUserSync(updatedUser);
      setStatsigUserReady(true);
    }
  }, [customId]);

  return !ipAddress || isLoading ? (
    // loader shows when no ip address detected yet, or while Statsig is initializing
    <BaselaneSpinner />
  ) : (
    <StatsigProvider client={client} loadingComponent={<BaselaneSpinner />}>
      {/* loader shows when waiting for custom id to be generated from stable-id */}
      {waitForCustomId && !statsigUserReady ? <BaselaneSpinner /> : children}
    </StatsigProvider>
  );
};

export default StatsigWrapper;
