export const desktopTableConfig = [
  {
    styles: {
      width: '120px',
    },
  },
  {
    styles: {
      width: 'auto',
    },
  },
];

export const mobileTableConfig = [
  {
    styles: {
      width: '100%',
    },
  },
];

export const dummyDataDesktop = [{}, {}, {}, {}, {}, {}];
export const dummyDataMobile = [{}, {}, {}];
