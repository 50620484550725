import { gql } from '@apollo/client';

export const GET_AUTOTAG_RULES = gql`
  query AutotagRules($filter: AutotagRuleFilter) {
    autotagRules(filter: $filter) {
      id
      rank
      description
      isActive
      conditions
      action {
        tagId
        propertyId
        unitId
      }
    }
  }
`;

export const REORDER_AUTOTAG_RULES = gql`
  mutation ReorderAutotagRules($rules: [ReorderAutotagRuleInput!]!) {
    reorderAutotagRules(input: $rules) {
      id
      rank
      description
      isActive
      conditions
      action {
        tagId
        propertyId
        unitId
      }
    }
  }
`;

export const CREATE_AUTOTAG_LLM = gql`
  mutation CreateAutotagLLM($applyToTaggedTransactions: Boolean!) {
    createAutotagRule(
      input: {
        description: "LLM"
        conditions: [{ type: BOOLEAN, field: LLM_ENABLED, operator: EQUALS, booleanValue: true }]
        action: { tagId: 0, propertyId: null, unitId: null }
        applyToTaggedTransactions: $applyToTaggedTransactions
      }
    ) {
      id
    }
  }
`;

export const UPDATE_AUTOTAG_LLM = gql`
  mutation updateAutotagRule($id: ID!, $isActive: Boolean!, $applyToTaggedTransactions: Boolean!) {
    updateAutotagRule(
      input: { id: $id, isActive: $isActive, applyToTaggedTransactions: $applyToTaggedTransactions }
    ) {
      id
    }
  }
`;
