import React, { useState, useRef } from 'react';
import { Formik } from 'formik';
import { Box, HStack, FormControl, Text } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { Icon16Edit, Icon16Check, Icon16Close } from '@icons/16px';
import {
  BaselaneFormLabel,
  BaselaneInput,
  BaselaneFormErrorMessage,
  BaselaneButtonIcon,
} from '@shared/components';
import { CREATE_OR_UPDATE_LEASE_AGREEMENT } from '../queries';

type DisplayNameProps = {
  label: string,
  fieldName: string,
  initialValue: string,
  leaseId: string,
  isLAManuallyUploaded?: boolean,
};

const DisplayName = ({
  label,
  fieldName,
  initialValue,
  leaseId,
  isLAManuallyUploaded = false,
}: DisplayNameProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const formikRef = useRef();

  const [updateLeaseAgreement] = useMutation(CREATE_OR_UPDATE_LEASE_AGREEMENT);

  const handleSubmit = (values) => {
    setIsEditing(false);
    updateLeaseAgreement({
      variables: {
        id: leaseId,
        documentName: values.documentName,
        inputType: isLAManuallyUploaded ? 'UPLOAD' : 'STANDARD',
      },
    }).catch((error) => console.error('Update failed:', error));
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      formikRef.current.handleSubmit();
    }
  };

  return (
    <FormControl>
      {!isEditing ? (
        <Box display="flex" alignItems="center">
          <Box flexGrow="1">
            <Text textStyle="sm-heavy">{label}</Text>
            <Text textStyle="xs" color="brand.neutral.600">
              Lease Agreement
            </Text>
          </Box>
          <Box display="flex" gap={1} alignItems="flex-end">
            <BaselaneButtonIcon
              icon={<Icon16Edit color="brand.neutral.600" />}
              variant="transparent"
              palette="primary"
              onClick={() => setIsEditing(true)}
            />
          </Box>
        </Box>
      ) : (
        <Formik
          innerRef={formikRef}
          initialValues={{ documentName: initialValue }}
          validate={(values) => {
            const errors = {};
            if (!values.documentName.trim()) {
              errors.documentName = 'Lease agreement title';
            }

            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, errors, touched, submitForm }) => (
            <FormControl mb={0} isRequired isInvalid={errors.documentName && touched.documentName}>
              <BaselaneFormLabel isRequired textStyle="sm" htmlFor="documentName">
                Lease agreement title
              </BaselaneFormLabel>
              <HStack>
                <Box flexGrow="1">
                  <BaselaneInput
                    id="documentName"
                    name="documentName"
                    value={values.documentName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleOnKeyDown}
                    bg="brand.neutral.white"
                    maxLength="50"
                    w="100%"
                  />
                </Box>
                <Box display="flex" flexDirection="row" gap={1} alignItems="flex-end">
                  <BaselaneButtonIcon
                    icon={<Icon16Close />}
                    variant="outline"
                    palette="neutral"
                    onClick={() => setIsEditing(false)}
                  />
                  <BaselaneButtonIcon
                    icon={<Icon16Check />}
                    variant="filled"
                    palette="primary"
                    isDisabled={!!errors.documentName && touched.documentName}
                    onClick={submitForm}
                  />
                </Box>
              </HStack>
              <BaselaneFormErrorMessage>{errors.documentName}</BaselaneFormErrorMessage>
            </FormControl>
          )}
        </Formik>
      )}
    </FormControl>
  );
};

export default DisplayName;
