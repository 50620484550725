import React, { useContext, useMemo } from 'react';
import { useStatsigClient } from '@statsig/react-bindings';
import { useUserAccessibleWorkspace } from '@core/components/Shared/hooks';
import UserContext from '@contexts/UserContext';
import { FEATURE_GATES } from '@core/constants/statsigKeys';

const UserAccessContext = React.createContext({});

export const UserAccessProvider = ({ children }: any) => {
  const { checkGate } = useStatsigClient();
  const { user } = useContext(UserContext);
  // TODO Remove mua check once is live
  const isMuaEnabled = checkGate(FEATURE_GATES.MUA_GATE);
  const { accessibleWorkspaceData } = useUserAccessibleWorkspace();

  const state = useMemo(() => {
    const newState = {};

    if (accessibleWorkspaceData) {
      const activeWorkspace = accessibleWorkspaceData.find((w) => w.isActive === true);
      newState.isWorkspaceOwner = activeWorkspace?.isOwner || !isMuaEnabled;
      newState.authorizedForBanking = activeWorkspace?.authorizedForBanking || !isMuaEnabled;

      return newState;
    }

    return newState;
  }, [accessibleWorkspaceData, user]);

  return <UserAccessContext.Provider value={state}>{children}</UserAccessContext.Provider>;
};
export const UserAccessConsumer = UserAccessContext.Consumer;

export const useUserAccessContext = () => {
  const userAccess = useContext(UserAccessContext);

  if (!userAccess) throw new Error('useUserAccessContext must be used within a UserAccessProvider');

  return userAccess;
};

export default UserAccessContext;
