import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LogRocket from 'logrocket';
import { Input, FormLabel, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { BaselaneMobileCheckUploader, BaselaneDropdown } from '@shared/components';
import stripCurrency from '@core/utils/stripCurrency';
import formatCurrency from '@core/utils/formatCurrency';
import BanksContext from '@contexts/BanksContext';
import UserContext from '@contexts/UserContext';
import SlLoader from '@core/components/Loader';
import { formatDate } from '@core/utils/formatDate';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { accountItemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';

import { GET_TRANSFERS_ACCOUNT_LIST } from '../../../queries';
import { ACCOUNT_TYPES } from '../BankTransfer/helpers/transfers.helper';
import { menuOptionsConverter } from '../../helpers/accounts.shared.helpers';
import { errorText } from '../BankTransfer/styles/bankTransfer.styles';

type CheckDepositProps = {
  transferType: string,
  setIsValidBankTransfer: Function,
  setTransfer: Function,
  checkFrontImageData: any,
  setCheckFrontImageData: Function,
  checkBackImageData: any,
  setCheckBackImageData: Function,
  selectedAccountTo: any,
  setSelectedAccountTo: Function,
  transferAmount: any,
  setTransferAmount: Function,
  setAmountErrors: Function,
  amountErrors: any,
  handleUnsupportedBrowserError: Function,
  frontRotated180: boolean,
  setFrontRotated180: Function,
  backRotated180: boolean,
  setBackRotated180: Function,
};

function CheckDeposit({
  transferType,
  setIsValidBankTransfer,
  setTransfer,
  checkFrontImageData,
  setCheckFrontImageData,
  checkBackImageData,
  setCheckBackImageData,
  selectedAccountTo,
  setSelectedAccountTo,
  transferAmount,
  setTransferAmount,
  setAmountErrors,
  amountErrors,
  handleUnsupportedBrowserError,
  frontRotated180,
  setFrontRotated180,
  backRotated180,
  setBackRotated180,
}: CheckDepositProps) {
  // to set initial check deposit type mobile portrait or desktop / large mobile landscape
  const { isMin768: isDesktop, isMax576: isMobile } = useBreakPoints();
  const isTablet = !isDesktop && !isMobile;

  const { loading, error, data } = useQuery(GET_TRANSFERS_ACCOUNT_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      transferType,
    },
  });

  // needed for when we are in check-deposit and don't want to refresh
  const {
    isDesktopCheckDepositOpen,
    isTabletCheckDepositOpen,
    isMobileCheckDepositOpen,
    setIsDesktopCheckDepositOpen,
    setIsTabletCheckDepositOpen,
    setIsMobileCheckDepositOpen,
  } = useContext(UserContext);

  const { loading: bankLoading, banks } = useContext(BanksContext);

  // aliases
  const errors = amountErrors;
  const setErrors = (e) => {
    setAmountErrors(e);
  };

  const inputMaskOptions = {
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
    allowDecimal: true,
  };

  const currencyMask = createNumberMask(inputMaskOptions);

  const menuOptionsToAccount =
    data &&
    menuOptionsConverter(
      data?.transferAccountList?.toAccount,
      ACCOUNT_TYPES[transferType]?.to?.isExternal
    );

  const [transferDate] = useState(new Date());

  const [dailyCheckLimit, setDailyCheckLimit] = useState();
  const [dailyCheckTotal, setDailyCheckTotal] = useState();
  const [monthlyCheckLimit, setMonthlyCheckLimit] = useState();
  const [monthlyCheckTotal, setMonthlyCheckTotal] = useState();

  const handleErrors = (e) => {
    const checkMonthlyLimit = parseFloat(monthlyCheckLimit, 10);
    const checkMonthlyTotal = parseFloat(monthlyCheckTotal, 10);
    const checkDailyLimit = parseFloat(dailyCheckLimit, 10);
    const checkDailyTotal = parseFloat(dailyCheckTotal, 10);

    const remainingDailyAmount = checkDailyLimit - checkDailyTotal;
    const remainingMonthlyAmount = checkMonthlyLimit - checkMonthlyTotal;

    const amount = parseFloat(stripCurrency(e), 10) || 0;

    if (!stripCurrency(e)) {
      setErrors('Required');
      setIsValidBankTransfer(false);
    } else if (stripCurrency(e) === 0) {
      setErrors('Required');
    } else if (amount > checkMonthlyLimit) {
      setErrors(
        `This check cannot be deposited as it exceeds the ${
          formatCurrency(checkMonthlyLimit).inDollars
        } monthly limit. Contact us for an exception.`
      );
    } else if (amount > remainingMonthlyAmount) {
      setErrors(
        `Depositing this check at this time exceeds the ${
          formatCurrency(checkMonthlyLimit).inDollars
        } monthly limit. Please try again later.`
      );
    } else if (amount > checkDailyLimit) {
      setErrors(
        `This check cannot be deposited as it exceeds the ${
          formatCurrency(checkDailyLimit).inDollars
        } daily limit. Contact us for an exception.`
      );
    } else if (amount > remainingDailyAmount) {
      setErrors(
        `Depositing this check at this time exceeds the ${
          formatCurrency(checkDailyLimit).inDollars
        } daily limit. Please try again later.`
      );
    } else {
      setIsValidBankTransfer(true);
      setErrors('');
    }
  };

  useEffect(() => {
    setIsValidBankTransfer(false);

    const validTransferCheck = selectedAccountTo && !selectedAccountTo.isAccountFraud;

    if (transferDate && transferAmount && selectedAccountTo) {
      if (!validTransferCheck) {
        setIsValidBankTransfer(false);
      } else if (!errors && !selectedAccountTo?.isAccountFraud) {
        setIsValidBankTransfer(true);
      }
    }

    const transfer = { transferType };

    if (selectedAccountTo) {
      transfer.selectedAccountTo = selectedAccountTo;
      transfer.selectedAccountTo.id = selectedAccountTo.accountId;
    }
    if (transferDate) transfer.transferDate = formatDate(transferDate);
    if (transferAmount) transfer.transferAmount = transferAmount;

    LogRocket.log(`Transfer data: ${transfer}`);
    setTransfer(transfer);
  }, [selectedAccountTo, transferAmount, transferDate]);

  useEffect(() => {
    const selectedAccountId = selectedAccountTo?.bankAccountId;

    const selectedAccount = banks
      .filter((item) => item.id === selectedAccountTo?.groupId)
      .reduce((acc, item) => {
        item.bankAccounts.forEach((ba) => {
          if (selectedAccountId === Number(ba.id)) {
            acc.push(ba);
          }

          ba.subAccounts.forEach((sa) => {
            if (selectedAccountId === Number(sa.id)) {
              acc.push(sa);
            }
          });
        });

        return acc;
      }, []);

    const transferLimits = selectedAccount[0]?.limits;

    setDailyCheckLimit(transferLimits?.checkDepositDailyLimit);
    setDailyCheckTotal(transferLimits?.checkDepositDailyTotal);
    setMonthlyCheckLimit(transferLimits?.checkDepositMonthlyLimit);
    setMonthlyCheckTotal(transferLimits?.checkDepositMonthlyTotal);
  }, [transferAmount, selectedAccountTo]);

  const currLocation = useLocation();
  const search = currLocation?.search;
  const openAddFundsDrawer = search?.includes('add_funds');

  useEffect(() => {
    // isCheckDepositOpen is used to block mobile refresh on rotate
    // let parent know that we don't want to be responsive
    if (
      !isDesktopCheckDepositOpen &&
      !isTabletCheckDepositOpen &&
      !isMobileCheckDepositOpen &&
      !openAddFundsDrawer
    ) {
      setIsDesktopCheckDepositOpen(isDesktop);
      setIsTabletCheckDepositOpen(isTablet);
      setIsMobileCheckDepositOpen(isMobile);
    }
    // clean up when component is destroyed
    return () => {
      setIsDesktopCheckDepositOpen(false);
      setIsTabletCheckDepositOpen(false);
      setIsMobileCheckDepositOpen(false);
    };
  }, []);

  if (error) {
    LogRocket.log(error);
    return null;
  }

  if (loading || bankLoading) {
    return (
      <SlLoader
        styles={{
          left: 'unset !important',
        }}
      />
    );
  }

  const classNames = [
    'input-form-xl',
    'fontSize-sm',
    'auto-width-dropdown',
    'disable-max-width',
    'auto-width',
  ];

  return (
    <>
      {/* Front & Back of Check */}
      <BaselaneMobileCheckUploader
        {...{
          checkFrontImageData,
          setCheckFrontImageData,
          checkBackImageData,
          setCheckBackImageData,
          handleUnsupportedBrowserError,
          frontRotated180,
          setFrontRotated180,
          backRotated180,
          setBackRotated180,
        }}
      />
      {/* Deposit To */}
      <FormControl isInvalid={false}>
        <FormLabel mb="8px !important" lineHeight="20px !important">
          Deposit To
        </FormLabel>
        <BaselaneDropdown
          {...{
            isDisabled: menuOptionsToAccount.length === 0,
            classNames,
            data: menuOptionsToAccount,
            title: 'Deposit To',
            parentId: 'transfer-drawer',
            placeholder: 'Select Account',
            showValueByFields: ['name'],
            itemRenderer: accountItemRenderer,
            showSelectedRightElement: true,
            selectedRightElementValue: selectedAccountTo?.value ?? '',
            handleSubmit: (selectedToId) => {
              const selectedItem = menuOptionsToAccount.reduce((acc, group) => {
                const getSelectedItem = group.items.find(
                  (groupItem) => groupItem.id === selectedToId
                );
                if (getSelectedItem) {
                  return getSelectedItem;
                }
                return acc;
              }, []);

              LogRocket.log(`Account selection: ${selectedItem}`);
              setSelectedAccountTo(selectedItem);
            },
            selectedItem: selectedAccountTo,
          }}
        />
      </FormControl>
      {/* Deposit Amount */}
      <FormControl isInvalid={errors}>
        <FormLabel mb="8px !important" lineHeight="20px !important">
          Check Amount
        </FormLabel>
        <Input
          as={MaskedInput}
          value={transferAmount}
          onChange={(e) => {
            const amount = e.target.value;
            setTransferAmount(amount);
            handleErrors(amount);
          }}
          mask={currencyMask}
          placeholder="Enter Amount"
          size="lg"
        />
        <FormErrorMessage {...errorText}>{errors}</FormErrorMessage>
      </FormControl>
    </>
  );
}

export default CheckDeposit;
