import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useApolloClient } from '@apollo/client';
import { Box, Flex, Text, HStack, Stack, Image } from '@chakra-ui/react';
import UserContext from '@contexts/UserContext';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { useUserAccessibleWorkspace } from '@shared/hooks';
import { ONBOARDING, SIGN_UP, DASHBOARD } from '@routes';
import { CONVERT_GUEST_TO_LANDLORD } from '@core/apollo/queries';
import { BaselaneButton, BaselaneCarousel } from '@shared/components';
import HeaderNavWrapper from '@shared/layouts/HeaderNavWrapper';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';
import { Icon16ChevronRight } from '@icons/16px';
import { mobileFooterStyles } from '@core/components/OnboardingTriage/styles/onboardingTriage.styles';
import { trackUtmUser, getUtmInputs } from '@features/Authentication/helpers/tracking.helpers';
import useIpAddress from '@shared/hooks/useIpAddress';
import { welcomeCardArray } from '../../helpers/workspace.helpers';

import {
  welcomeCardContainerWrapperStyles,
  welcomeCustomStyles,
} from '../../styles/userWelcomeCards.style';

export const InfoCard = ({ slide }: InfoCardProps) => {
  return (
    <Image
      src={slide.icon}
      alt={slide.alt}
      height="337px" // Sets the height of the image
      width="256px" // Sets the width of the image
      objectFit="cover" // Ensures the image covers the container while maintaining aspect ratio
      borderRadius="md" // Adds medium rounded corners
    />
  );
};

const CreateWorkspace = () => {
  const { isMin768 } = useBreakPoints();
  const { refetch: refetchAccessibleWorkspaceData, workspaceCount } = useUserAccessibleWorkspace();
  const { user, refetchUser } = useContext(UserContext);
  const client = useApolloClient();
  const navigate = useNavigate();
  const [convertGuestToLandlord] = useMutation(CONVERT_GUEST_TO_LANDLORD);
  const { ipAddress } = useIpAddress();
  const location = useLocation();
  const utmInputs = getUtmInputs(ipAddress, location, 'account_created');

  const handleConvertGuestToLandlord = () => {
    convertGuestToLandlord().then(async () => {
      try {
        await client.resetStore();
      } catch (error) {
        console.error('Error clearing Apollo Client store:', error);
      }
      trackUtmUser(user, 'account_created', ipAddress, utmInputs);
      refetchUser();
      refetchAccessibleWorkspaceData();
      navigate(ONBOARDING);
    });
  };

  if (user?.role === 'LANDLORD') {
    navigate(DASHBOARD);
  }

  return user?.role === 'GUEST' ? (
    <>
      <HeaderNavWrapper
        hideBackButton
        hideFinishLater
        headerContent={<BaselaneFullLogo />}
        containerCustomStyles={{ height: '85px', minHeight: '85px' }}
      />
      <Stack>
        {/* Main Content */}
        <Flex direction="row" height="100%" {...welcomeCardContainerWrapperStyles}>
          <Flex direction="column" justifyContent="start" gap={isMin768 ? '48px' : '54px'} w="100%">
            <Stack gap="32px">
              {isMin768 && (
                <Stack m={8} gap={2} justifyContent="center">
                  <Text textStyle="headline-2xl" align="center">
                    Create your own Baselane workspace
                  </Text>
                  <Text textStyle="md" align="center">
                    Set up your own banking, rent collection, bookkeeping, and more.
                  </Text>
                </Stack>
              )}
              <Box width="100%" justifyContent="center">
                {isMin768 ? (
                  <HStack gap={2} width="1104px" m="auto">
                    {welcomeCardArray.map((item) => (
                      <Image
                        key={item.id} // Changed to a unique key, assuming `item.alt` might not be unique
                        src={item.icon}
                        alt={item.alt}
                        height="337px"
                        width="256px"
                        objectFit="cover"
                        borderRadius="md"
                      />
                    ))}
                  </HStack>
                ) : (
                  <BaselaneCarousel
                    showToggleBesideIndicator
                    slides={welcomeCardArray}
                    SlideElement={InfoCard}
                    styles={welcomeCustomStyles}
                  />
                )}
              </Box>
            </Stack>

            {/* Footer Section */}
            <Flex
              direction="column"
              {...(!isMin768 && mobileFooterStyles)}
              gap="16px"
              alignItems="center"
            >
              <BaselaneButton
                size="md"
                variant="filled"
                palette="primary"
                id="create-workspace-convert-user-to-guest"
                isFullWidth={!isMin768}
                onClick={handleConvertGuestToLandlord}
                rightIcon={<Icon16ChevronRight />}
              >
                Continue
              </BaselaneButton>
              <BaselaneButton
                size="sm"
                variant="transparent"
                onClick={() => {
                  workspaceCount > 0 ? navigate(-1) : navigate(SIGN_UP);
                }}
              >
                Cancel
              </BaselaneButton>
            </Flex>
          </Flex>
        </Flex>
      </Stack>
    </>
  ) : null;
};

export default CreateWorkspace;
