// @flow
import React, { useContext, useEffect, useMemo, useState, memo } from 'react';
import * as _ from 'lodash';
import CashFlowContext from '@contexts/CashFlowContext';
import CashFlowByType from './CashFlowByType';
import {
  getSubOptionsCategories,
  getMappedCategories,
  getCategories,
} from './helpers/cashflow.helpers';

type CashFlowByTypeWrapperProps = {
  handleSubFiltersUpdate: Function,
  selectedMonth: string,
  hasNoData: boolean,
  onAnimationStart?: Function,
  onAnimationComplete?: Function,
};

function CashFlowByTypeWrapper({
  handleSubFiltersUpdate,
  selectedMonth,
  hasNoData,
  onAnimationStart = () => {},
  onAnimationComplete = () => {},
}: CashFlowByTypeWrapperProps): any {
  const {
    filters,
    dataType,
    cashFlowData,
    cashFlowDummyData,
    categoryMap,
    categoryWithSubOptions,
  } = useContext(CashFlowContext);

  const data = hasNoData ? cashFlowDummyData : cashFlowData;

  const { byCategory: byCategoryData } = data.cashFlow;
  const byCategoryMonth = selectedMonth ? data.cashFlow.byCategoryMonth : [];
  const [byCategory, setByCategory] = useState([]);

  const month = _.find(byCategoryMonth, (item) => _.includes(item.date, selectedMonth));
  const getCategoriesByMonth = () => (month ? month.categories : []);

  const subOptionsCategories = useMemo(() => getSubOptionsCategories(categoryWithSubOptions), []);
  const mappedCategories = useMemo(() => getMappedCategories(categoryMap), []);
  const categories = getCategories(byCategory);

  useEffect(() => {
    let updatedByCategory = [];
    if (dataType === 'month') {
      updatedByCategory = selectedMonth === null ? byCategoryData : getCategoriesByMonth();
      setByCategory(updatedByCategory);
    } else if (dataType === 'property') {
      updatedByCategory = byCategoryData;
      setTimeout(() => setByCategory(updatedByCategory), 500);
    } else if (dataType === 'unit') {
      updatedByCategory = byCategoryData;
      setTimeout(() => setByCategory(updatedByCategory), 500);
    }
  }, [data, selectedMonth]);

  return categories?.length > 0 ? (
    <CashFlowByType
      {...{
        byCategory,
        handleSubFiltersUpdate,
        filters,
        subOptionsCategories,
        categoryWithSubOptions,
        mappedCategories,
        categories,
        onAnimationStart,
        onAnimationComplete,
      }}
    />
  ) : null;
}

const areEqual = (prevProp, nextProp) => {
  return _.isEqual(
    {
      data: prevProp.data,
      filters: prevProp.filters,
      selectedMonth: prevProp.selectedMonth,
      hasNoData: prevProp.hasNoData,
    },
    {
      data: nextProp.data,
      filters: nextProp.filters,
      selectedMonth: nextProp.selectedMonth,
      hasNoData: nextProp.hasNoData,
    }
  );
};

const CashFlowByTypeWrapperMemo = memo(CashFlowByTypeWrapper, areEqual);

export default CashFlowByTypeWrapperMemo;
