import React from 'react';
import { Box, Text, List, ListIcon, ListItem } from '@chakra-ui/react';
import IconCheckCircleOutline from '@icons/manual/IconCheckCircleOutline';
import { listItemStyles, listItemTextStyles } from '../styles/addEditBank.styles';

function PlaidMessage() {
  return (
    <Box textStyle="xs">
      <Text>
        Connect{' '}
        <Box as="span" fontWeight="medium">
          BANK ACCOUNTS
        </Box>{' '}
        to:
      </Text>
      <List m="16px 0px 24px">
        <ListItem color="brand.blue.700" {...listItemStyles}>
          <ListIcon as={IconCheckCircleOutline} />
          <Text {...listItemTextStyles}>Collect rent, fees & security deposits</Text>
        </ListItem>
        <ListItem color="brand.blue.700" {...listItemStyles}>
          <ListIcon as={IconCheckCircleOutline} />
          <Text {...listItemTextStyles}>Import transactions</Text>
        </ListItem>
        <ListItem color="brand.blue.700" {...listItemStyles}>
          <ListIcon as={IconCheckCircleOutline} />
          <Text {...listItemTextStyles}>Fund a Baselane Banking account</Text>
        </ListItem>
      </List>
      <Text>
        Connect{' '}
        <Box as="span" fontWeight="medium">
          CREDIT CARDS
        </Box>{' '}
        to:
      </Text>
      <List m="16px 0px 32px">
        <ListItem color="brand.blue.700" style={{ display: 'flex', direction: 'row' }}>
          <ListIcon as={IconCheckCircleOutline} />
          <Text {...listItemTextStyles}>Import transactions</Text>
        </ListItem>
      </List>
    </Box>
  );
}

export default PlaidMessage;
