import React from 'react';
import { Heading, VStack, HStack, Box } from '@chakra-ui/react';
import { BaselaneAlertNew, BaselaneButton, BaselaneCardNew } from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { Icon16Dollarcrossed } from '@icons/16px';
import BasicReporting from './BasicReporting';
import EnhancedReporting from './EnhancedReporting';

type ReportsProps = {
  isEditDrawer: Boolean,
};

const Reports = ({ isEditDrawer }: ReportsProps) => {
  const onViewSampleReports = () => {
    window.open(
      'https://www.baselane.com/wp-content/uploads/2024/12/Sample-Report.pdf',
      '_blank',
      'noopener'
    );
    sendSegmentEvent('tenant_screening_sample_report_download_clicked', {});
  };

  return (
    <VStack alignItems="flex-start" gap={1.5}>
      <HStack w="full">
        <Heading size="headline-md" mb={0}>
          Reporting options
        </Heading>
      </HStack>
      <BaselaneAlertNew
        variant="primary"
        visual="icon"
        iconName={Icon16Dollarcrossed}
        title="Free for landlords"
        body="The prices shown below are charged to the applicant."
      />
      <BaselaneCardNew size="md" variant="normal" width="100%">
        <Box>
          <Heading size="headline-lg" mb={2}>
            Basic reporting
          </Heading>
          <BasicReporting />
        </Box>
      </BaselaneCardNew>
      <BaselaneCardNew>
        <Box>
          <Heading size="headline-lg" mb={2}>
            Enhanced reporting
          </Heading>
          <EnhancedReporting isEditDrawer={isEditDrawer} />
        </Box>
      </BaselaneCardNew>
      <BaselaneButton
        label="Standard Button"
        palette="primary"
        size="lg"
        onClick={onViewSampleReports}
        variant="tonal"
        w="full"
        mt={1}
        id="view-sample-report"
      >
        Download sample report
      </BaselaneButton>
    </VStack>
  );
};

export default Reports;
