import React, { useEffect, useLayoutEffect, useState, useContext } from 'react';
import {
  VStack,
  HStack,
  Box,
  Text,
  Heading,
  Stack,
  Skeleton,
  Spinner,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  BaselaneButtonIcon,
  BaselaneDivider,
  BaselaneAlertNew,
  BaselaneButton,
} from '@shared/components';
import { Icon24ArrowBack } from '@icons/24px';
import useBreakPoints from '@core/hooks/useBreakPoints';
import formatPhoneNumber from '@core/utils/formatPhoneNumber';
import { dateFormatter } from '@core/utils/formatDate';

import { Icon16Info } from '@core/components/Icons/16px';

import {
  TENANT_SCREENING_APPLICANT,
  TENANT_SCREENING_APPLICATION,
  TENANT_SCREENING_APPROVE,
  TENANT_SCREENING_CONDITIONALLY_APPROVE,
  TENANT_SCREENING_DECLINE,
  TENANT_SCREENING_REPORT_DETAILS_DEFAULT,
} from '@core/constants/routes';

import {
  IllustrationOtherSuccess,
  IllustrationOtherIdentityverificationfailed,
  IllustrationOtherError1,
} from '@illustrations';

import { IllustrationOtherSuccessWarning, IllustrationHistory } from '@illustrations/manual';

import { useCurrentWorkspace } from '@core/components/Shared/hooks';
import UserAccessContext from '@contexts/UserAccessContext';
import EmptyState from './EmptyState';
import Attestation from './Attestation';
import ApplicantDropdown from './ApplicantDropdown';
import ReportDropdown from './ReportDropdown';

import {
  DEFAULT_REPORT_ROUTE,
  DEFAULT_REPORTS,
  getApplicantDisplayName,
  REPORT_STATUS_KEYS,
  REPORT_TYPE_ROUTE,
  REPORT_TYPES,
  REPORT_TYPE_KEYS,
  VERIFICATION_ID_UNAVAILABLE,
  isAttestationRequired,
  REPORT_MENU_ORDER,
} from '../helpers/tenantScreeningDetail.helpers';
import { getReportsByApplicationId } from '../helpers/tenantScreeningReports.helpers';
import StatusChip from '../components/StatusChip';
import ApprovedBannerActions from '../components/ApprovedBannerActions';
import { pageContainerPadding, innerContainerStyles } from '../styles/tenantScreening.styles';
import MakeDecision from './MakeDecision';
import useTenantScreeningComplianceRules from '../hooks/useTenantScreeningComplianceRules';
import ReportSummary from './ReportSummary/index';
import useApplicants from '../hooks/useApplicants';

const EXPIRATION_CUTOFF_DAYS = 30;
const WARNING_TRIGGER_DAYS = 3;
const APPROVAL_ACTION_BUTTON_DAYS = 60;

const TenantScreeninReportDetails = () => {
  const { isMax576, isMin899, isLargerThan1280 } = useBreakPoints();
  const [applicantDisplayName, setApplicantDisplayName] = useState();
  const [isDecisionPossible, setIsDecisionPossible] = useState(false);
  const [hideSkeleton, setHideSkeleton] = useState(false);
  const { isWorkspaceOwner } = useContext(UserAccessContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const params = useParams();
  const { screeningId, applicantId, applicationId } = params;
  const lastSegment = pathname.split('/').pop();

  const [verifications, setVerifications] = useState(null);
  const [verificationId, setVerificationId] = useState(null);
  const [secondaryVerificationId, setSecondaryVerificationId] = useState(null);
  const [moveInDate, setMoveInDate] = useState('');

  const {
    screeningData,
    screeningDataLoading,
    applications,
    propertyName,
    unitName,
    selectedReports,
    application,
    applicants,
    applicantOptions,
    applicantOptionsWithSummary,
  } = useApplicants();

  const applicant = applicants?.find((applicantItem) => applicantItem?.id === applicantId);

  const expiresIn =
    EXPIRATION_CUTOFF_DAYS -
    Math.floor(DateTime.now().diff(DateTime.fromISO(applicant?.submissionDate), 'days').days);
  const showApprovalBannerActionButtons =
    APPROVAL_ACTION_BUTTON_DAYS -
      Math.floor(DateTime.now().diff(DateTime.fromISO(application?.decisionDate), 'days').days) >
    0;

  // applicant dropdown initialization
  const isGroupApplication = applicants?.length > 1;

  const selectedApplicant = Array.isArray(applicantOptions)
    ? applicantOptions.find((option) => option?.id && option.id === applicant?.id) ?? null
    : null;

  const backToApplicantsScreen = pathname.split(TENANT_SCREENING_APPLICATION)[0];

  const isDefaultReport = lastSegment === applicantId;

  const isMakeDecisionDrawerOpen =
    pathname.includes(TENANT_SCREENING_APPROVE) ||
    pathname.includes(TENANT_SCREENING_CONDITIONALLY_APPROVE) ||
    pathname.includes(TENANT_SCREENING_DECLINE);

  const hasNoApplicantId =
    !Number(applicantId) || applicantId === TENANT_SCREENING_REPORT_DETAILS_DEFAULT;

  const reportTypes = [
    ...DEFAULT_REPORTS,
    ...Object.keys(selectedReports || []).filter((reportKey) => {
      return DEFAULT_REPORTS.indexOf(reportKey) === -1 ? selectedReports[reportKey] : false;
    }),
  ];

  // sort the reports into pre-defined order
  reportTypes.sort((reportTypeA, reportTypeB) => {
    return REPORT_MENU_ORDER.indexOf(reportTypeA) - REPORT_MENU_ORDER.indexOf(reportTypeB);
  });

  const reportOptions = reportTypes?.map((reportKey) => {
    return {
      id: reportKey,
      route: REPORT_TYPE_ROUTE[reportKey],
      name: REPORT_TYPES[reportKey],
      type: Object.keys(REPORT_TYPE_KEYS).find((key) => REPORT_TYPE_KEYS[key] === reportKey),
    };
  });

  // if any make decision drawers are open, the report name is the second last item in the path
  const pathSegments = pathname.split('/');
  const reportSegment = isMakeDecisionDrawerOpen
    ? pathSegments[pathSegments.length - 2]
    : lastSegment;

  const currentReportRoute = isDefaultReport
    ? REPORT_TYPE_ROUTE[DEFAULT_REPORT_ROUTE]
    : reportSegment;
  const selectedReport = reportOptions?.find((option) => option.route === currentReportRoute);
  const isCreditReport = REPORT_TYPE_KEYS.CREDIT_REPORT === selectedReport?.id;
  const creditScoreExpired = applicant?.submissionDate && expiresIn <= 0;
  const showExpirationInfo = isCreditReport && !creditScoreExpired;

  // retrieve all report verifications for the selected user
  useEffect(() => {
    if (applicationId && applicant) {
      getReportsByApplicationId(applicationId)
        .then(({ data }) => {
          const { applicants: boomApplicants } = data || {};
          const { verifications: boomVerifications } =
            boomApplicants?.find(
              (boomApplicant) => boomApplicant.id === applicant?.externalApplicantId
            ) || [];
          if (boomVerifications) setVerifications(boomVerifications);
          setMoveInDate(data.desired_move_in_date);
        })
        .catch((e) => {
          // can't render so let's go back to screening list
          navigate(`${backToApplicantsScreen}`);
        });
    }
  }, [applicationId, applicant]);

  // handle verifications being loaded from boom
  useEffect(() => {
    if (verifications) {
      const { id: currentVerificationId } =
        verifications.find((verification) => verification.name === currentReportRoute) || {};
      if (currentVerificationId) {
        setVerificationId(currentVerificationId);
        if (currentReportRoute === REPORT_TYPE_ROUTE.incomeVerification) {
          const { id: currentSecondaryVerificationId } =
            verifications.find(
              (verification) => verification.name === 'verified_income_verification'
            ) || {};
          setSecondaryVerificationId(currentSecondaryVerificationId);
        }
      } else {
        setVerificationId(VERIFICATION_ID_UNAVAILABLE);
      }
    }
  }, [verifications, currentReportRoute]);

  // handle data scenarios where rendering will be impossible,
  // therefore navigation to somewhere else is necessary
  useEffect(() => {
    if ((!applicants || applicants?.length < 1) && !screeningDataLoading)
      navigate(`${backToApplicantsScreen}`);

    // no applicant id, so need to redirect to first applicant
    if (hasNoApplicantId && applicants?.length > 0) {
      navigate(
        `${backToApplicantsScreen}${TENANT_SCREENING_APPLICATION}/${applicationId}/${TENANT_SCREENING_APPLICANT}/${applicants[0].id}`
      );
    }

    // redirect to default report if none selected and available
    if (isDefaultReport && applicant?.status !== REPORT_STATUS_KEYS.IN_PROGRESS) {
      navigate({
        pathname: DEFAULT_REPORT_ROUTE,
      });
    }
  }, [applicants, hasNoApplicantId, isDefaultReport, applicant, verificationId]);

  useLayoutEffect(() => {
    const isRedirecting = hasNoApplicantId || isDefaultReport;
    if (!isRedirecting && !screeningDataLoading && verificationId) {
      setHideSkeleton(true);
    }
  }, [hasNoApplicantId, isDefaultReport, screeningDataLoading, verificationId]);

  // generate display name
  useEffect(() => {
    setApplicantDisplayName(getApplicantDisplayName(applicants, applicant, isMin899));
    // Logic that enables/disables the "Make Decision" menu
    setIsDecisionPossible(
      applicants?.reduce((accumulator, currentApplicant) => {
        return accumulator && currentApplicant.applicantStatus === REPORT_STATUS_KEYS.COMPLETED;
      }, true)
    );
  }, [applicants, applicant, isMin899]);

  const { userKycStatus = null } = useCurrentWorkspace();

  const makeDecisionTooltipText =
    userKycStatus === 'APPROVED' ? (
      'A decision can’t be made until everyone in the group has submitted their applications.'
    ) : (
      <>
        <Text textStyle="xs-heavy">ID verification pending</Text>
        <Text textStyle="xs">Verify your identity to view the reports and make a decision.</Text>
      </>
    );

  // Attestation logic
  const { loading: loadingRules, data: rules } = useTenantScreeningComplianceRules();

  const userConsentForThisReport = applications
    ?.find((app) => app.id === applicationId)
    ?.userConsent?.find((item) => item?.report === selectedReport?.type);
  const [, setShowReport] = useState(userConsentForThisReport?.attested);
  function renderContent() {
    if (loadingRules) {
      return <Spinner />;
    }
    if (
      isAttestationRequired(
        screeningData,
        rules,
        selectedReport,
        userConsentForThisReport?.attested
      )
    ) {
      return (
        <Attestation
          screeningId={screeningId}
          applicationId={applicationId}
          report={selectedReport}
          address={screeningData?.getTenantScreeningById?.address}
          setShowReport={setShowReport}
        />
      );
    }

    if (applicant?.applicantStatus === REPORT_STATUS_KEYS.IN_PROGRESS) {
      return <EmptyState />;
    }

    if (isCreditReport && applicant?.submissionDate && expiresIn <= 0) {
      return (
        <Flex
          h="280px"
          alignItems="center"
          justifyContent="center"
          padding={2}
          borderRadius="8px"
          border="1px dashed #DADFE6"
          backgroundColor="brand.neutral.50"
        >
          <VStack rowGap={0}>
            <IllustrationHistory />
            <Heading size="headline-md" as="h3" mt={2} mb={0.5}>
              Report expired
            </Heading>
            <Text textStyle="sm" color="brand.neutral.600" textAlign="center">
              For compliance, Baselane is only allowed to store the credit report data for 30 days.
            </Text>
          </VStack>
        </Flex>
      );
    }

    return (
      <Outlet
        context={{
          verificationId,
          secondaryVerificationId,
          applicantId,
          externalApplicantId: applicant?.externalApplicantId,
          property: {
            propertyName,
            unitName,
            moveInDate,
            address: screeningData?.getTenantScreeningById?.address,
            amount: screeningData?.getTenantScreeningById?.amount,
            leaseAvailableDate: screeningData?.getTenantScreeningById?.leaseAvailableDate,
          },
        }}
      />
    );
  }

  const reportRightActions = () => {
    return (
      <Skeleton
        isLoaded={hideSkeleton}
        ml={isMax576 ? 0 : 'auto'}
        order={isMax576 ? -1 : 1}
        width="100%"
      >
        <Stack
          alignItems={isMax576 ? 'flex-start' : 'flex-end'}
          rowGap={0}
          direction="row"
          width={isMax576 && '100%'}
          justifyContent={isMax576 && 'space-between'}
        >
          <Text textStyle="sm" color="brand.neutral.600" alignSelf="center">
            {`Report date: ${
              applicant?.submissionDate ? dateFormatter(applicant?.submissionDate) : '--'
            }`}
          </Text>
          {showExpirationInfo && (
            <Text
              textStyle="sm"
              color={
                applicant?.submissionDate && expiresIn <= WARNING_TRIGGER_DAYS
                  ? 'red.700'
                  : 'brand.neutral.600'
              }
              display="flex"
              gap={1}
              alignItems="center"
              alignSelf="center"
            >
              Expires in {applicant?.submissionDate ? expiresIn : '--'} days
              <Tooltip
                hasArrow
                label="For compliance, Baselane is only allowed to store the credit report for 30 days. Please make sure to process the information before it expires."
                placement="top"
              >
                <Icon16Info />
              </Tooltip>
            </Text>
          )}
          <BaselaneButton
            variant="outline"
            pallete="neutral"
            onClick={() => window.print()}
            alignSelf={isMax576 && 'flex-end'}
            sx={{
              '@media print': {
                display: 'none',
              },
            }}
          >
            Print report
          </BaselaneButton>
        </Stack>
      </Skeleton>
    );
  };

  return (
    <VStack alignItems="flex-start" w="full" gap={4} p={pageContainerPadding(isMax576, isMin899)}>
      <HStack
        sx={{
          '@media print': {
            gap: 0,
          },
        }}
        gap={2}
        w="full"
        flexWrap="wrap"
      >
        <Skeleton isLoaded={hideSkeleton} order={0}>
          <BaselaneButtonIcon
            icon={<Icon24ArrowBack />}
            onClick={() => {
              navigate(backToApplicantsScreen);
            }}
            palette="neutral"
            size="lg"
            variant="outline"
            sx={{
              '@media print': {
                display: 'none',
              },
            }}
          />
        </Skeleton>

        <Box order={isMax576 ? 3 : 1} minW={isMax576 ? 'full' : 'auto'}>
          <Skeleton isLoaded={hideSkeleton}>
            <Heading size="headline-lg" as="h3">
              {applicantDisplayName}
            </Heading>
            <Text>
              {propertyName} {propertyName !== unitName ? unitName : ''}
            </Text>
          </Skeleton>
        </Box>

        {isGroupApplication && (
          <Box order={isMax576 ? 4 : 1} minW={isMax576 ? 'full' : '288px'}>
            <Skeleton isLoaded={hideSkeleton} w="full" maxW={isMax576 ? 'full' : '260px'}>
              {' '}
              {/**
               * Applicant Selection, shows only in group applications
               */}
              <ApplicantDropdown
                id="applicant-dropdown"
                isGroupApplication={isGroupApplication}
                applicantOptions={applicantOptionsWithSummary}
                selectedApplicant={selectedApplicant}
              />
            </Skeleton>
          </Box>
        )}

        {application?.applicationStatus !== REPORT_STATUS_KEYS.APPROVED &&
          application?.applicationStatus !== REPORT_STATUS_KEYS.APPROVED_CONDITIONALLY &&
          application?.applicationStatus !== REPORT_STATUS_KEYS.DECLINED && (
            <Box
              ml="auto"
              order={2}
              sx={{
                '@media print': {
                  display: 'none',
                },
              }}
            >
              <Skeleton isLoaded={hideSkeleton}>
                <MakeDecision
                  isDecisionPossible={isDecisionPossible && userKycStatus === 'APPROVED'}
                  tooltipText={makeDecisionTooltipText}
                  screeningId={screeningId}
                />
              </Skeleton>
            </Box>
          )}
      </HStack>

      {!pathname.includes('reports_summary') && (
        <Box
          width="100%"
          sx={{
            '@media print': {
              display: 'none',
            },
          }}
        >
          {application?.applicationStatus === REPORT_STATUS_KEYS.APPROVED && (
            <BaselaneAlertNew
              hasCloseButton={false}
              iconName={IllustrationOtherSuccess}
              title={`Application was approved on ${dateFormatter(application?.decisionDate)}`}
              variant="success"
              visual="illustration"
              isTextVertical={false}
              mb={2}
              styles={innerContainerStyles(isMin899)}
              customButton={
                showApprovalBannerActionButtons ? (
                  <ApprovedBannerActions screeningId={screeningId} />
                ) : null
              }
            />
          )}
          {application?.applicationStatus === REPORT_STATUS_KEYS.APPROVED_CONDITIONALLY && (
            <BaselaneAlertNew
              hasCloseButton={false}
              iconName={IllustrationOtherSuccessWarning}
              title={`Application was conditionally approved on ${dateFormatter(
                application?.decisionDate
              )}`}
              variant="warning"
              visual="illustration"
              isTextVertical={false}
              mb={2}
              styles={innerContainerStyles(isMin899)}
              customButton={
                showApprovalBannerActionButtons ? (
                  <ApprovedBannerActions screeningId={screeningId} />
                ) : null
              }
            />
          )}
          {application?.applicationStatus === REPORT_STATUS_KEYS.DECLINED && (
            <BaselaneAlertNew
              hasCloseButton={false}
              iconName={IllustrationOtherError1}
              title={`Application was declined on ${dateFormatter(application?.decisionDate)}`}
              variant="danger"
              visual="illustration"
              isTextVertical={false}
              mb={2}
            />
          )}
        </Box>
      )}

      {pathname.includes('reports_summary') ? (
        <Outlet />
      ) : (
        <>
          <Stack flexDirection={isMax576 ? 'column' : 'row'} gap={isMax576 ? 2 : 4}>
            <Box>
              <Heading size="headline-sm" as="h3">
                Email
              </Heading>
              <Skeleton isLoaded={hideSkeleton}>
                <Text textStyle="sm">{applicant?.emailId}</Text>
              </Skeleton>
            </Box>
            <Box>
              <Heading size="headline-sm" as="h3">
                Phone
              </Heading>
              <Skeleton isLoaded={hideSkeleton}>
                <Text textStyle="sm">{formatPhoneNumber(applicant?.phoneNumber) || '-'}</Text>
              </Skeleton>
            </Box>
            <Box>
              <Heading size="headline-sm" as="h3">
                {applicant?.applicantStatus === 'COMPLETED' ? 'Submission date' : 'Status'}
              </Heading>
              <Skeleton isLoaded={hideSkeleton}>
                {applicant?.applicantStatus === 'COMPLETED' ? (
                  <Text textStyle="sm">{dateFormatter(applicant?.submissionDate)}</Text>
                ) : (
                  <StatusChip status={applicant?.applicantStatus} />
                )}
              </Skeleton>
            </Box>
          </Stack>

          <ReportSummary applicant={selectedApplicant} creditScoreExpired={creditScoreExpired} />
        </>
      )}

      {!pathname.includes('reports_summary') && (
        <BaselaneDivider
          styles={{
            marginLeft: '-32px',
            boxSizing: 'contentBox',
            paddingRight: '64px',
            marginTop: '0px',
            marginBottom: '0px',
          }}
        />
      )}
      {!pathname.includes('reports_summary') &&
        (userKycStatus !== 'APPROVED' ? (
          <EmptyState
            icon={<IllustrationOtherIdentityverificationfailed />}
            title={
              isWorkspaceOwner
                ? 'Verify your identity to view reports'
                : 'Identity verification required to view reports'
            }
            description={
              isWorkspaceOwner
                ? 'Reports are ready to access once you complete the ID verification.'
                : 'To view tenant screening reports, the workspace owner needs to verify their identity.'
            }
          />
        ) : (
          <Box width="100%">
            {isLargerThan1280 ? (
              <Stack flexDirection="row" w="100%" gap={3} mt={-4}>
                {/**
                 * Report Selection, defaults to  REPORT_TYPE_ROUTE[DEFAULT_REPORT]
                 */}
                <Skeleton
                  isLoaded={hideSkeleton}
                  w="full"
                  maxW={isMax576 ? 'full' : '260px'}
                  order={0}
                  mt={3}
                  sx={{
                    '@media print': {
                      display: 'none',
                    },
                  }}
                >
                  <ReportDropdown
                    id="report-dropdown"
                    reportOptions={reportOptions}
                    selectedReport={selectedReport}
                    verifications={verifications}
                  />
                </Skeleton>
                <BaselaneDivider
                  isVertical
                  styles={{ width: '1px', height: 'auto', 'background-color': 'brand.neutral.200' }}
                  sx={{
                    '@media print': {
                      display: 'none',
                    },
                  }}
                />
                <Box width="100%" mt={3}>
                  {(!isCreditReport || showExpirationInfo) && (
                    <Stack
                      w="100%"
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Heading size="headline-2xl">
                        {selectedReport?.name}
                        {selectedReport?.id === 'housingHistory' && (
                          <Text as="span" ml={0.5} color="brand.neutral.400">
                            (self-reported)
                          </Text>
                        )}
                      </Heading>
                      <Box>{reportRightActions()}</Box>
                    </Stack>
                  )}
                  <Skeleton isLoaded={hideSkeleton} w="100%" mt={3}>
                    {renderContent()}
                  </Skeleton>
                </Box>
              </Stack>
            ) : (
              <Box>
                {applicant?.applicantStatus !== REPORT_STATUS_KEYS.IN_PROGRESS && (
                  <Stack w="full" gap={2}>
                    {/**
                     * Report Selection, defaults to  REPORT_TYPE_ROUTE[DEFAULT_REPORT]
                     */}
                    <Skeleton
                      isLoaded={hideSkeleton}
                      w="full"
                      maxW={isMax576 ? 'full' : '260px'}
                      order={0}
                      mb={3}
                    >
                      <ReportDropdown
                        id="report-dropdown"
                        reportOptions={reportOptions}
                        selectedReport={selectedReport}
                        verifications={verifications}
                      />
                    </Skeleton>
                    {(!isCreditReport || showExpirationInfo) && (
                      <Stack
                        flexDirection={isMax576 ? 'column' : 'row'}
                        w="full"
                        gap={2}
                        justifyContent="space-between"
                        mb={4}
                      >
                        <Heading size={isMax576 ? 'headline-xl' : 'headline-2xl'}>
                          {selectedReport?.name}
                          {selectedReport?.id === 'housingHistory' && (
                            <Text as="span" ml={0.5} color="brand.neutral.400">
                              (self-reported)
                            </Text>
                          )}
                        </Heading>
                        <Box>{reportRightActions()}</Box>
                      </Stack>
                    )}
                  </Stack>
                )}
                <Skeleton isLoaded={hideSkeleton} w="full">
                  {renderContent()}
                </Skeleton>
              </Box>
            )}
          </Box>
        ))}
    </VStack>
  );
};

export default TenantScreeninReportDetails;
