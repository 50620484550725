// @flow
// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=1820%3A83853
import React from 'react';
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';
import {
  ChakraProvider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';
import {
  BaselaneButton,
  BaselaneAutoCompleteAddress,
  BaselaneAddress,
  BaselaneButtonGroup,
} from '@shared/components';
import habitatTheme from '@core/themeHabitat';
import { formInputStyles } from '@shared/styles/input.style';
import { zipcodeMask } from '@core/utils/masks';
import useBreakPoints from '@core/hooks/useBreakPoints';
import { xsFormLabelStyles, formErrorStyles, drawerFooterStyles } from '../styles/body.styles';

type BodyProps = {
  mailingAddress: Object,
  isDirty: boolean,
  formikRef: any,
  handleValidation: Function,
  handleFormVariableUpdate: Function,
  handleSubmit: Function,
  setIsDirty: Function,
  isFormValid: boolean,
  onClose: Function,
  isSubmitting: boolean,
};

const Body = ({
  mailingAddress,
  isDirty,
  formikRef,
  handleValidation,
  handleFormVariableUpdate,
  handleSubmit,
  setIsDirty,
  isFormValid,
  onClose,
  isSubmitting,
}: BodyProps) => {
  const { isMax576 } = useBreakPoints();
  const { DrawerBody, DrawerFooter } = BaselaneDrawer;

  const { city, postalCode, state, street, unit } = mailingAddress || {};
  const initialValues = {
    address: street,
    unitNumber: unit === null ? '' : unit,
    city,
    state,
    zipcode: postalCode,
  };

  return (
    <ChakraProvider theme={habitatTheme}>
      <DrawerBody p={isMax576 ? '16px' : '24px'}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validate={handleValidation}
          validateOnChange={false}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            dirty,
            isValid,
            values,
            handleChange,
            handleBlur,
            setFieldTouched,
            errors,
            touched,
          }) => (
            <form noValidate>
              <BaselaneAutoCompleteAddress
                {...{
                  values,
                  errors,
                  touched,
                  isValid,
                  handleChange,
                  handleBlur,
                  setFormVariables: handleFormVariableUpdate,
                  formInputStyles,
                  formErrorStyles,
                  setIsDirty: () => setIsDirty(dirty),
                }}
                formLabelStyles={xsFormLabelStyles}
              />
              {/* Unit Number */}
              <FormControl position="relative" isInvalid={errors.unitNumber && touched.unitNumber}>
                <FormLabel htmlFor="unitNumber" {...xsFormLabelStyles}>
                  Apartment, Unit, Suite, or Floor #
                </FormLabel>
                <Input
                  {...formInputStyles}
                  id="unitNumber"
                  name="unitNumber"
                  value={values.unitNumber}
                  placeholder="e.g. Floor 1"
                  onChange={handleChange}
                  onBlur={(e) => {
                    handleBlur(e);
                    setIsDirty(dirty);
                    handleFormVariableUpdate({ unit: e.target.value });
                  }}
                />
                <FormErrorMessage {...formErrorStyles}>
                  <Text>{errors.unitNumber}</Text>
                </FormErrorMessage>
              </FormControl>

              <Stack direction="row" spacing={1}>
                {/* City */}
                <FormControl
                  position="relative"
                  width="49%"
                  mr="8px"
                  isInvalid={errors.city && touched.city}
                  isRequired
                >
                  <FormLabel htmlFor="city" {...xsFormLabelStyles}>
                    City
                  </FormLabel>
                  <Input
                    {...formInputStyles}
                    id="city"
                    name="city"
                    value={values.city}
                    placeholder="e.g. Philadelphia"
                    onChange={handleChange}
                    onBlur={(e) => {
                      handleBlur(e);
                      setIsDirty(dirty);
                    }}
                  />
                  <FormErrorMessage {...formErrorStyles}>
                    <Text>{errors.city}</Text>
                  </FormErrorMessage>
                </FormControl>

                {/* State */}
                <FormControl
                  position="relative"
                  w="25%"
                  minW="92px"
                  isInvalid={errors.state && touched.state}
                  isRequired
                >
                  <FormLabel htmlFor="state" {...xsFormLabelStyles}>
                    State
                  </FormLabel>
                  <Select
                    {...formInputStyles}
                    p="0"
                    id="state"
                    name="state"
                    value={values.state}
                    placeholder="Select state"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      setFieldTouched('state', true);
                      handleBlur(e);
                      setIsDirty(dirty);
                    }}
                  >
                    <BaselaneAddress.StateOptions />
                  </Select>
                  <FormErrorMessage {...formErrorStyles}>
                    <Text>{errors.state}</Text>
                  </FormErrorMessage>
                </FormControl>

                {/* Zip Code */}
                <FormControl
                  position="relative"
                  w="25%"
                  minW="80px"
                  isInvalid={errors.zipcode && touched.zipcode}
                  isRequired
                >
                  <FormLabel htmlFor="zipcode" {...xsFormLabelStyles}>
                    Zip Code
                  </FormLabel>
                  <Input
                    {...formInputStyles}
                    as={MaskedInput}
                    mask={zipcodeMask}
                    id="zipcode"
                    name="zipcode"
                    value={values.zipcode}
                    placeholder="e.g. 19123"
                    onChange={handleChange}
                    type="text"
                    onBlur={(e) => {
                      handleBlur(e);
                      setIsDirty(dirty);
                    }}
                  />
                  <FormErrorMessage {...formErrorStyles}>
                    <Text>{errors.zipcode}</Text>
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </form>
          )}
        </Formik>
      </DrawerBody>

      <DrawerFooter {...drawerFooterStyles}>
        <BaselaneButtonGroup size="md" styles={{ width: '100%' }}>
          <BaselaneButton size="md" variant="outline" palette="neutral" onClick={onClose}>
            Cancel
          </BaselaneButton>
          <BaselaneButton
            id="entity-details-save-mailing-address"
            size="md"
            variant="filled"
            palette="primary"
            isDisabled={!isFormValid || !isDirty}
            isLoading={isSubmitting}
            onClick={(e) => formikRef.current.handleSubmit(e)}
            width="100%"
          >
            Save changes
          </BaselaneButton>
        </BaselaneButtonGroup>
      </DrawerFooter>
    </ChakraProvider>
  );
};

export default Body;
