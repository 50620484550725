// @flow
import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { Box, FormControl, HStack, Text, Stack, Spacer } from '@chakra-ui/react';
import {
  BaselaneDivider,
  BaselaneRadioGroup,
  BaselaneRadio,
  BaselaneLink,
  BaselaneAlertNew,
} from '@shared/components';
import { Icon24Key, Icon24Details, Icon24BankingAccount } from '@icons/24px';
import { Icon16Newwindow, Icon16Info } from '@icons/16px';
import { invitationSteps } from '../../helpers/workspace.helpers';

export const innercontainer = {
  flex: 1,
  gap: 1.5,
  alignItems: 'flex-start',
};

export const textcontainer = {
  w: '100%',
  ml: 0,
  gap: 1.5,
  flexDir: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
};

type InviteCollaboratorAccessProps = { from: String, showServerError?: Boolean, bodyText?: String };

function InviteCollaboratorAccess({
  from,
  showServerError,
  bodyText,
}: InviteCollaboratorAccessProps): any {
  const url =
    'https://support.baselane.com/hc/en-us/articles/29873891217307-How-to-Manage-Multi-User-Access-in-Your-Baselane-Workspace';
  const formik = useFormikContext();
  const { values, setFieldValue, handleChange, handleBlur } = formik;
  const [currentStep] = useState(invitationSteps.COLLABORATOR_ACCESS);
  const totalSteps = 2;

  return (
    <>
      <HStack>
        <Text textStyle="headline-md" color="brand.neutral.900">
          Collaborator access
        </Text>
        <Spacer />
        {from !== 'EditCollaboratorAccessDrawer' && (
          <Text textStyle="xs" color="brand.neutral.600">
            {`${currentStep} of ${totalSteps}`}
          </Text>
        )}
      </HStack>
      <Stack mb={3}>
        <Text textStyle="sm">Select what your collaborator can access in your workspace. </Text>
        <BaselaneLink
          size="sm"
          variant="primary"
          rightIcon={<Icon16Newwindow />}
          href={url}
          isExternal
          target="_blank"
        >
          Learn more
        </BaselaneLink>
      </Stack>
      {showServerError && (
        <BaselaneAlertNew
          variant="danger"
          visual="icon"
          iconName={Icon16Info}
          title="Failed to invite this collaborator"
          body={bodyText}
        />
      )}
      <Stack mt={3} mb={5}>
        <HStack justifyContent="space-between" gap="2">
          <Icon24Key />
          <Stack {...textcontainer}>
            <Text textStyle="headline-sm">Rent collection</Text>
            <Text textStyle="sm" color="brand.neutral.500">
              Full access
            </Text>
          </Stack>
        </HStack>
        <BaselaneDivider styles={{ my: 1, color: 'brand.darkBlue.100' }} />
        <HStack justifyContent="space-between" gap="2">
          <Icon24Details />
          <Stack {...textcontainer}>
            <Text textStyle="headline-sm">Bookkeeping</Text>
            <Text textStyle="sm" color="brand.neutral.500">
              Full access
            </Text>
          </Stack>
        </HStack>
        <BaselaneDivider styles={{ my: 1, color: 'brand.darkBlue.100' }} />
        <HStack {...innercontainer}>
          <Box>
            <Icon24BankingAccount />
          </Box>
          <Stack>
            <FormControl id="authorizedForBanking">
              <Text textStyle="headline-sm">Banking</Text>
              <BaselaneRadioGroup
                defaultValue="1"
                id="typeGroup"
                name="typeGroup"
                onChange={(e) => {
                  setFieldValue('authorizedForBanking', e);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values?.authorizedForBanking}
              >
                <>
                  <BaselaneRadio
                    key="1"
                    label="Standard access"
                    value="1"
                    description="Move money, manage cards, and open new virtual accounts, but cannot open accounts for a new entity."
                    name="authorizedForBanking"
                  />
                  <BaselaneRadio
                    key="2"
                    label="Limited access"
                    value="2"
                    description="View accounts and balances, and tag transactions, but cannot move money, access card details, or make account changes. "
                    name="authorizedForBanking"
                  />
                </>
              </BaselaneRadioGroup>
            </FormControl>
          </Stack>
        </HStack>
      </Stack>
      <Text textStyle="sm" color="brand.neutral.500">
        *More role-based access controls coming soon
      </Text>
    </>
  );
}

export default InviteCollaboratorAccess;
