import React, { useEffect, useState, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_INVOICE_LIST, GET_LEASE } from '@pages/LeasesPage/queries';

const InvoiceContext = React.createContext({});

export const InvoiceProvider = ({ children }: any) => {
  const [invoiceList, setInvoiceList] = useState();
  const [invoiceFilters, setInvoiceFilters] = useState();
  const [invoiceTotal, setInvoiceTotal] = useState();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [selectedLeaseId, setSelectedLeaseId] = useState(null);
  const [leaseDetailOpen, setLeaseDetailOpen] = useState(false);
  const [nextPaymentsClicked, setNextPaymentsClicked] = useState(false);
  const [selectedLease, setSelectedLease] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [sortedProperties, setSortedProperties] = useState([]);
  const [tenantStatus, setTenantStatus] = useState(null);

  useEffect(() => {
    if (selectedLease) {
      sortedProperties.forEach((property) => {
        const { units } = property;

        if (units.find((unit) => unit.id === selectedLease.propertyUnitId)) {
          setSelectedUnit(units.find((unit) => unit.id === selectedLease.propertyUnitId));
          setSelectedProperty(property);
        }
      });
    }
  }, [selectedLease?.id]);

  const [
    getLeaseInvoices,
    { loading: leaseInvoicesLoading, error: leaseInvoicesError, refetch: refetchLeaseInvoices },
  ] = useLazyQuery(GET_INVOICE_LIST, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: selectedLeaseId
        ? { dateType: 'DUE_DATE', leaseId: selectedLeaseId }
        : { dateType: 'DUE_DATE' },
    },
    onCompleted: ({ invoiceList: invoiceListData }) => {
      const { invoices: invoicesData, amount, count } = invoiceListData ?? {};
      let newInvoiceList = invoicesData;
      if (nextPaymentsClicked) {
        const nextPaymentIdArray = [];
        selectedLease?.invoiceDetails?.nextPayment.forEach((item) =>
          nextPaymentIdArray.push(item.id)
        );
        newInvoiceList = invoicesData.filter((item) => nextPaymentIdArray.indexOf(item.id) > -1);
      }
      setInvoiceList(newInvoiceList);
      setInvoiceTotal({ amount, count });
    },
  });

  const [getLease] = useLazyQuery(GET_LEASE, { fetchPolicy: 'cache-and-network' });

  const state = useMemo(() => {
    const newState = {};
    newState.leaseInvoicesLoading = leaseInvoicesLoading;
    newState.leaseInvoicesError = leaseInvoicesError;
    newState.refetchLeaseInvoices = refetchLeaseInvoices;
    newState.getLeaseInvoices = getLeaseInvoices;
    newState.getLease = getLease;
    newState.invoiceList = invoiceList;
    newState.invoiceFilters = invoiceFilters;
    newState.invoiceTotal = invoiceTotal;
    newState.setInvoiceList = setInvoiceList;
    newState.setInvoiceFilters = setInvoiceFilters;
    newState.setInvoiceTotal = setInvoiceTotal;
    newState.selectedInvoiceId = selectedInvoiceId;
    newState.setSelectedInvoiceId = setSelectedInvoiceId;
    newState.selectedLeaseId = selectedLeaseId;
    newState.setSelectedLeaseId = setSelectedLeaseId;
    newState.leaseDetailOpen = leaseDetailOpen;
    newState.setLeaseDetailOpen = setLeaseDetailOpen;
    newState.nextPaymentsClicked = nextPaymentsClicked;
    newState.setNextPaymentsClicked = setNextPaymentsClicked;
    newState.selectedLease = selectedLease;
    newState.setSelectedLease = setSelectedLease;
    newState.selectedUnit = selectedUnit;
    newState.selectedProperty = selectedProperty;
    newState.setSortedProperties = setSortedProperties;
    newState.tenantStatus = tenantStatus;
    newState.setTenantStatus = setTenantStatus;

    return newState;
  }, [
    leaseInvoicesLoading,
    leaseInvoicesError,
    invoiceList,
    selectedLease,
    invoiceTotal,
    selectedInvoiceId,
    selectedLeaseId,
    leaseDetailOpen,
    nextPaymentsClicked,
    selectedLease,
    selectedUnit,
    selectedProperty,
    tenantStatus,
    getLease,
    getLeaseInvoices,
    refetchLeaseInvoices,
    invoiceFilters,
    setInvoiceFilters,
  ]);
  return <InvoiceContext.Provider value={state}>{children}</InvoiceContext.Provider>;
};

export const InvoiceConsumer = InvoiceContext.Consumer;
export default InvoiceContext;
