import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { HStack, Box } from '@chakra-ui/react';
import IconLock from '@icons/manual/IconLock';
import BaselaneAlert from '../BaselaneAlert';
import AlertHeader from '../AlertHeader';
import ExternalBankingButtons from './ExternalBankingButtons';

type BaselaneExternalBankingTypePopupProps = {
  handleActionButton: Function,
  isOpen: Function,
  onClose: Function,
  addAccountProps?: Object,
  buttonStyles?: Object,
  overlayStyles?: Object,
  containerStyles?: Object,
  children?: any,
  helperFunction?: Function,
  linkedAccountsDrawerOnClose?: Function,
};

const BaselaneExternalBankingTypePopup = ({
  handleActionButton,
  isOpen,
  onClose,
  addAccountProps,
  buttonStyles,
  children,
  overlayStyles,
  containerStyles,
  helperFunction,
  linkedAccountsDrawerOnClose,
}: BaselaneExternalBankingTypePopupProps) => {
  const [isPlaidButtonLoading, setIsPlaidButtonLoading] = useState(true);

  const header = (
    <AlertHeader
      {...{
        title: 'How would you like to add an External Account?',
        icon: null,
        subtitle: (
          <HStack>
            {!isMobile && <IconLock w={11.25} h={12.5} color="#3A4B5B" />}
            <Box>Your information is protected with 256-bit SSL encryption.</Box>
          </HStack>
        ),
        isInline: false,
      }}
    />
  );

  return (
    <BaselaneAlert
      {...{
        isOpen,
        onClose,
        size: '2xl',
        header,
        showCloseButton: true,
        overlayStyles,
        containerStyles,
        trapFocus: false,
      }}
      body={
        children || (
          <ExternalBankingButtons
            {...{
              handleActionButton,
              buttonStyles,
              isPlaidButtonLoading,
              setIsPlaidButtonLoading,
              addAccountProps,
              helperFunction,
              linkedAccountsDrawerOnClose,
            }}
          />
        )
      }
    />
  );
};

BaselaneExternalBankingTypePopup.defaultProps = {
  addAccountProps: {},
  buttonStyles: {},
  overlayStyles: {},
  containerStyles: {},
  children: null,
  helperFunction: () => {},
  linkedAccountsDrawerOnClose: () => {},
};

export default BaselaneExternalBankingTypePopup;
