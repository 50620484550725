import React, { useContext, useRef } from 'react';
import { toNumber } from 'lodash';
import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  ChakraProvider,
  useToast,
  Box,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import moment from 'moment';
import { gql, useMutation } from '@apollo/client';
import {
  useIsDrawerAlertOpen,
  useOnAlertOpen,
  useOnAlertClose,
} from '@store/Transactions/createTransactionDrawerStore';
import habitatTheme from '@core/themeHabitat';
import {
  BaselaneButton,
  BaselaneDivider,
  T2WithTitleDropdown,
  T2Dropdown,
  BaselaneDrawer,
  UnsavedChangesAlert,
  BaselaneFormErrorMessage,
  BaselaneTextArea,
  NoPropertyTooltip,
  BaselaneCardNew,
  T1Dropdown,
} from '@shared/components';
import stripCurrency from '@core/utils/stripCurrency';
import { CREATE_TRANSACTION, GET_TRANSACTIONS_SUMMARY } from '@core/apollo/queries';
import TransactionContext from '@contexts/TransactionContext';
import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import BaselaneAmount from '@shared/components/BaselaneAmount';
import useBreakPoints from '@core/hooks/useBreakPoints';
import AccountDropdown from '@shared/components/BaselaneDropdown-new/ReusableDropdowns/AccountDropdown';
import {
  getPropertyData,
  renderPropertyDropdownParentItem,
} from '@shared/helpers/propertiesFilter.helpers';
import DateInputField from './components/DateInputField';

import {
  DisplayInputDefaultCategoryItem,
  DisplayInputDefaultPropertyItem,
} from '../DisplayInputVariations';
import { getOptionsWithSubCategories } from '../../CashFlowPage/helpers/cashflow.helpers';

import { getInitialValues } from '../services/CreateTransactionFormService';
import {
  reviewStatusItemRenderer,
  reviewStatusOptions,
  selectedReviewStatusItemRenderer,
} from '../DisplayInputVariations/reviewStatusVariations';

function CreateManualTransactionDrawerNew({
  isAddTransactionDrawerOpen,
  toggleAddTransactionDrawer,
}: {
  isAddTransactionDrawerOpen: Boolean,
  toggleAddTransactionDrawer: Function,
}): any {
  const { isMax768 } = useBreakPoints();

  const isDrawerAlertOpen = useIsDrawerAlertOpen();
  const onAlertOpen = useOnAlertOpen();
  const onAlertClose = useOnAlertClose();

  const {
    categoryIdsMap,
    categoryMap,
    propertiesData,
    propertyIdsMap,
    summaryFilter,
    categoryWithSubOptions,
  } = useContext(TransactionContext);

  const formikRef = useRef();

  const propertyOptions = getPropertyData(propertiesData);
  const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);
  const initialValues = getInitialValues();

  // Toaster
  const toast = useToast();
  const showSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: `Your transaction has been added successfully.`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  const [createNewTransaction] = useMutation(CREATE_TRANSACTION, {
    update(cache, { data: { createTransaction } }) {
      cache.modify({
        fields: {
          transaction(existingTransactions = []) {
            const newTodoRef = cache.writeFragment({
              data: createTransaction,
              fragment: gql`
                fragment NewTransaction on Transactions {
                  id
                  amount
                  bankAccountId
                  date
                  id
                  merchantName
                  note
                  propertyId
                  tagId
                  unitId
                }
              `,
            });
            return [...existingTransactions, newTodoRef];
          },
        },
      });

      if (!createTransaction.tagId) {
        const res = cache.readQuery({
          query: GET_TRANSACTIONS_SUMMARY,
          variables: { input: summaryFilter },
        });

        if (res) {
          cache.writeQuery({
            query: GET_TRANSACTIONS_SUMMARY,
            data: {
              transactionSummary: {
                ...res.transactionSummary,
                totalUncategorized: {
                  ...res.transactionSummary.totalUncategorized,
                  count: res.transactionSummary.totalUncategorized.count + 1,
                  absoluteAmount:
                    res.transactionSummary.totalUncategorized.absoluteAmount +
                    Math.abs(createTransaction.amount),
                },
              },
            },
            variables: { input: summaryFilter },
          });
        }
      }
    },
  });

  const handleCloseDrawer = (dirty) => {
    if (dirty) {
      onAlertOpen();
    } else {
      formikRef?.current.resetForm({
        values: initialValues,
      });
      toggleAddTransactionDrawer();
    }
  };

  function submitForm(newValues, setSubmitting) {
    const {
      account,
      date: trxDate,
      description,
      amount,
      category,
      property,
      notes,
      reviewStatus,
    } = newValues;
    const [categoryId, subTagId] = category && category.id.split('-');
    const [propertyId, unitId] = property && property.id.split('-');
    const transformedTransaction = {
      note: notes,
      date: moment(trxDate).format('YYYY-MM-DD'),
      amount:
        newValues.amountSign === 'out'
          ? -toNumber(stripCurrency(amount))
          : +toNumber(stripCurrency(amount)),
      tagId: subTagId ? +subTagId : +categoryId,
      bankAccountId: account === null ? null : +account.id,
      propertyId: +propertyId,
      unitId: unitId && +unitId,
      merchantName: description,
      isReviewedByUser: reviewStatus ? reviewStatus !== 'needs_review' : false,
    };
    setSubmitting(true);
    createNewTransaction({
      variables: { ...transformedTransaction },
    }).then(
      () => {
        showSuccessToast();
        setSubmitting(false);
        handleCloseDrawer();
      },
      (err) => {
        console.error(err);
        setSubmitting(false);
      }
    );
  }

  const getSelectedProperty = (values) => {
    const { property } = values;
    const { propertyId, unitId } = property || {};
    let selectedProperty = null;
    if (propertyId && unitId) {
      selectedProperty = {
        id: `${propertyId}-${unitId}`,
        showValueName: `${propertyIdsMap[`p-${propertyId}`]?.name}, ${
          propertyIdsMap[`u-${unitId}`]?.name
        }`,
      };
    } else if (propertyId && !unitId) {
      selectedProperty = {
        id: `${propertyId}`,
        showValueName: propertyIdsMap[`p-${propertyId}`]?.name,
      };
    }

    return selectedProperty;
  };
  const getSelectedCategory = (values) => {
    return values?.categoryId && values?.categoryId !== ''
      ? {
          id: categoryIdsMap[values.categoryId],
          name: categoryMap[values.categoryId],
        }
      : null;
  };

  const categoryDropdownProps = (values: Object, setFieldValue: Function) => ({
    additionalProps: { id: 'category-dropdown' },
    classNames: ['input-form-md', 'is-full-width'],
    data: categoryOptions,
    title: 'Category',
    showValueByFields: ['name'],
    placeholder: 'Select category',
    parentItemRenderer: itemRenderer,
    childItemRenderer: itemRenderer,
    handleSubmit: (selectedValue) => {
      setFieldValue('category', { id: selectedValue });
    },
    selectedItem: getSelectedCategory(values), // selectedItem ?? selectedFilters.categories,
    isMulti: false,
    hasFilterWrapper: false,
    CustomDisplayInput: DisplayInputDefaultCategoryItem,
    isMobile: isMax768,
  });
  const propertyDropdownProps = (values: Object, setFieldValue: Function) => ({
    additionalProps: { id: 'property-dropdown' },
    classNames: ['input-form-md', 'is-full-width'],
    data: propertyOptions,
    searchTerm: 'name',
    title: 'Property',
    placeholder: 'Select property',
    showValueByFields: ['name'],
    parentItemRenderer: ({ item }) => renderPropertyDropdownParentItem(item),
    childItemRenderer: itemRenderer,
    handleSubmit: (selectedValue) => {
      setFieldValue('property', { id: selectedValue });
    },
    isMulti: false,
    hasFilterWrapper: false,
    selectedItem: getSelectedProperty(values),
    CustomDisplayInput: DisplayInputDefaultPropertyItem,
    isMobile: isMax768,
  });

  const handleValidation = (values) => {
    const errors = {};

    if (!values.description) {
      errors.description = 'Required';
    }
    if (!values.account || values.account.id.length === 0) {
      errors.account = 'Required';
    }
    if (!values.amount) {
      errors.amount = 'Required';
    }
    if (!values.date) {
      errors.date = 'Required';
    }

    return errors;
  };

  return (
    <ChakraProvider theme={habitatTheme}>
      <Formik
        innerRef={formikRef}
        validateOnChange
        validateOnBlur
        initialValues={initialValues}
        validate={(values) => handleValidation(values)}
        onSubmit={(values, rest) => {
          const { setSubmitting } = rest;
          submitForm(values, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          touched,
          dirty,
        }) => (
          <Form>
            <BaselaneDrawer
              title="Add Transaction"
              size={isMax768 ? 'newdrawerfull' : 'newdrawersm'}
              onClose={() => handleCloseDrawer(dirty)}
              isOpen={isAddTransactionDrawerOpen}
              footer={
                <HStack spacing={1.5}>
                  {/* Cancel */}
                  <BaselaneButton
                    size="md"
                    variant="outline"
                    palette="neutral"
                    onClick={() => {
                      handleCloseDrawer(dirty);
                    }}
                  >
                    Cancel
                  </BaselaneButton>
                  {/* Confirm */}
                  <BaselaneButton
                    id="save-transaction-button"
                    size="md"
                    variant="filled"
                    palette="primary"
                    onClick={() => {
                      handleSubmit();
                    }}
                    isDisabled={isSubmitting}
                  >
                    Save
                  </BaselaneButton>
                </HStack>
              }
              newDesignDrawer
            >
              <BaselaneCardNew palette="dark">
                <Box width="100%">
                  {/* Description */}
                  <FormControl isInvalid={errors.description && touched.description} isRequired>
                    <FormLabel htmlFor="description">Short name</FormLabel>
                    <Input
                      id="description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      placeholder="Short name"
                    />
                    <BaselaneFormErrorMessage isInvalid={errors.description && touched.description}>
                      {errors.description}
                    </BaselaneFormErrorMessage>
                  </FormControl>

                  {/* Amount field */}
                  <FormControl isInvalid={errors.amount && touched.amount} isRequired>
                    <FormLabel htmlFor="amount">Amount</FormLabel>
                    <BaselaneAmount
                      isInvalid={errors.amount && touched.amount}
                      dropdownProps={{
                        id: 'amountSign',
                        name: 'amountSign',
                        value: values.amountSign,
                      }}
                      inputProps={{
                        id: 'amount',
                        name: 'amount',
                        value: values.amount,
                      }}
                      onChange={handleChange}
                    />
                    <BaselaneFormErrorMessage isInvalid={errors.amount && touched.amount}>
                      {errors.amount}
                    </BaselaneFormErrorMessage>
                  </FormControl>

                  {/* Account field */}
                  <FormControl isInvalid={errors.account && touched.account} isRequired>
                    <FormLabel htmlFor="Account">Account</FormLabel>
                    <AccountDropdown
                      handleSubmit={(selectedValue) => {
                        setFieldValue('account', { id: selectedValue });
                      }}
                      formValues={values}
                    />
                    <BaselaneFormErrorMessage isInvalid={errors.account && touched.account}>
                      {errors.account}
                    </BaselaneFormErrorMessage>
                  </FormControl>

                  {/* Date field */}
                  <FormControl isInvalid={errors.date && touched.date} isRequired variant="isLast">
                    <FormLabel htmlFor="Date">Date</FormLabel>
                    <DateInputField
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                    />
                    <BaselaneFormErrorMessage isInvalid={errors.date && touched.date}>
                      {errors.date}
                    </BaselaneFormErrorMessage>
                  </FormControl>
                </Box>
              </BaselaneCardNew>

              <BaselaneDivider my={1.5} />

              {/* Category */}
              <FormControl>
                <FormLabel>Category</FormLabel>
                <T2WithTitleDropdown {...categoryDropdownProps(values, setFieldValue)} />
              </FormControl>

              {/* Property */}
              <FormControl>
                <FormLabel>Property</FormLabel>
                {propertyOptions?.length === 0 ? (
                  <NoPropertyTooltip>
                    <T2Dropdown {...propertyDropdownProps(values, setFieldValue)} isDisabled />
                  </NoPropertyTooltip>
                ) : (
                  <T2Dropdown {...propertyDropdownProps(values, setFieldValue)} />
                )}
              </FormControl>

              {/* Review Status */}
              <FormControl>
                <FormLabel>Review Status</FormLabel>
                <T1Dropdown
                  additionalProps={{ id: 'add-review-status-dropdown' }}
                  classNames={['input-form-md', 'is-full-width']}
                  data={reviewStatusOptions ?? []}
                  title="Review Status"
                  type="tier1"
                  showValueByFields={['name']}
                  itemRenderer={reviewStatusItemRenderer}
                  CustomDisplayInput={() => {
                    // Find the selected status object based on formik value
                    const selectedStatus = values.reviewStatus
                      ? reviewStatusOptions.find((option) => option.id === values.reviewStatus)
                      : reviewStatusOptions[0];

                    return selectedReviewStatusItemRenderer({
                      item: selectedStatus,
                    });
                  }}
                  placeholder="Select review status"
                  handleSubmit={(selectedValue) => {
                    setFieldValue('reviewStatus', selectedValue);
                  }}
                  selectedItem={values.reviewStatus || reviewStatusOptions[0].id}
                  isMulti={false}
                />
              </FormControl>

              {/* Notes */}
              <FormControl>
                <FormLabel htmlFor="notes">Notes</FormLabel>
                <BaselaneTextArea
                  size="sm"
                  id="notes"
                  name="notes"
                  placeholder="Add Note..."
                  value={values.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
            </BaselaneDrawer>
          </Form>
        )}
      </Formik>
      <UnsavedChangesAlert
        {...{
          isDrawerAlertOpen,
          onAlertClose,
          onAlertOpen,
          onAlertContinue: (e) => {
            onAlertClose();
            handleCloseDrawer();
          },
        }}
      />
    </ChakraProvider>
  );
}

export default CreateManualTransactionDrawerNew;
